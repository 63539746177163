import { PlainMessage } from '@bufbuild/protobuf'
import { FaQuestionCircle } from 'react-icons/fa'
import { GenerateKeywordsResponsePb } from '../utils/proto'
import Caption from './Caption'
import Tooltip from './Tooltip'

export function GeneratedKeywords(props: {
  isFetching: boolean
  generatedKeywords?: PlainMessage<
    GenerateKeywordsResponsePb['keywords'][number]
  >[]
}) {
  return (
    <div className="flex flex-1 flex-wrap justify-between gap-2">
      <div className="text-xl font-bold">
        <span>Generated Keywords</span>
      </div>
      <Tooltip
        tooltip={
          <>
            This table contains every unique keyword generated by your current
            keyword source configuration along with useful stats to understand
            keyword distribution.
          </>
        }
      >
        <Caption>
          <FaQuestionCircle className="inline" /> What are generated keywords?
        </Caption>
      </Tooltip>
    </div>
  )
}
