import { twMerge } from 'tailwind-merge'
import { withProps } from '../utils/withProps'
import Card from './Card'

export const Tabs = withProps(Card)(({ className, ...props }) => ({
  ...props,
  className: twMerge(
    `inline-flex max-w-full flex-wrap gap-px border border-gray-500/20 p-0 !shadow-none`,
    `overflow-hidden`,
    className
  ),
}))

export const tabStyles = twMerge(
  `inline-flex flex-wrap items-center gap-1 px-2 py-1
  text-sm font-bold text-blue-700`,
  `shadow-[1px_1px_0_0px_rgba(0,0,0,.05)] dark:text-white dark:shadow-[1px_1px_0_0px_rgba(255,255,255,.1)]`,
  `hover:bg-gray-500/10 dark:hover:bg-gray-500/30`,
  `[&.active]:bg-blue-500 [&.active]:text-white`,
  'transition'
)

export const Tab = withProps('button')(({ className, ...props }) => ({
  ...props,
  className: twMerge(tabStyles, className),
}))
