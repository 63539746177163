import axios from 'axios'
import { useQuery } from 'react-query'
import { KeywordSourceBase } from '../hooks/keywordSources'
import { DataModel2Pb } from './proto'
import { dataModelFromPb } from './dataModels'

export type KeywordSourceAutomotive = KeywordSourceBase

export function useAutomotiveModelQuery() {
  return useQuery({
    queryKey: ['model', 'automotive'],
    queryFn: () =>
      axios.get('/auto_datamodel.json').then(d => {
        return dataModelFromPb(d.data as DataModel2Pb)
      }),
    staleTime: Infinity,
    cacheTime: Infinity,
  })
}
