import * as React from 'react'
import { useQuery } from 'react-query'
import { useProjects } from '../hooks/projects'
import { useActiveWorkspaceId } from '../hooks/workspaces'
import { fetchKeywordSources } from '../utils/Api'
import {
  KEYWORD_SOURCE_TYPE_BASIC,
  queryKeyTeamsKeywordSources,
} from '../utils/Constants'
import Loader from './Loader'
import QueryGate from './QueryGate'
import {
  SerpUsageCalculator,
  SerpUsageCalculatorRow,
} from './SerpUsageCalculator'
import { KeywordSourceConfigTypePb } from '../utils/proto'

export function WorkspaceSerpUsageCalculator() {
  const activeWorkspaceId = useActiveWorkspaceId()
  const teamsQuery = useProjects()
  const teams = teamsQuery.data

  const teamKeywordSourcesQuery = useQuery(
    [queryKeyTeamsKeywordSources, teams?.map(team => team.id)],
    async () => {
      await new Promise(r => setTimeout(r, 1000))
      return Promise.all(
        teams!.map(async team => ({
          team,
          keywordSources: await fetchKeywordSources(team.id, activeWorkspaceId),
        }))
      )
    },
    {
      enabled: Boolean(teams),
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: 1,
    }
  )

  const initialSchedules = React.useMemo(() => {
    const foundSchedules = new Set<string>()
    teamKeywordSourcesQuery.data?.map(teamKeywordSources =>
      teamKeywordSources.keywordSources.forEach(keywordSource =>
        keywordSource.schedules.map(schedule => foundSchedules.add(schedule.id))
      )
    )
    return Array.from(foundSchedules.values())
  }, [teamKeywordSourcesQuery.data])

  const initialRows = React.useMemo(():
    | undefined
    | SerpUsageCalculatorRow[] => {
    return teamKeywordSourcesQuery.data
      ?.map(teamKeywordSource => {
        return teamKeywordSource.keywordSources.map(keywordSource => {
          const isBasic = keywordSource.type === KeywordSourceConfigTypePb.BASIC
          return {
            projectName: `${teamKeywordSource.team.name} - ${keywordSource.name}`,
            type: isBasic ? ('phrase' as const) : ('keyword' as const),
            // @ts-expect-error  // Property 'uniqueDeviceCount' does not exist on typ... Remove this comment to see the full error message
            deviceCount: Number(keywordSource.uniqueDeviceCount ?? 1),
            // @ts-expect-error  // Property 'uniqueLocaleCount' does not exist on typ... Remove this comment to see the full error message
            localeCount: Number(keywordSource.uniqueLocaleCount ?? 1),
            scheduleCounts: initialSchedules.map(schedule => {
              const foundSchedule = keywordSource.schedules.find(
                d => d.id === schedule
              )

              if (foundSchedule) {
                return Number(
                  isBasic
                    ? // @ts-expect-error  // Property 'uniquePhraseCount' does not exist on typ... Remove this comment to see the full error message
                      keywordSource.uniquePhraseCount
                    : keywordSource.keywordCount
                )
              }

              return 0
            }),
          }
        })
      })
      .flat()
  }, [initialSchedules, teamKeywordSourcesQuery.data])

  return (
    <QueryGate
      query={teamKeywordSourcesQuery}
      loader={
        <div className="relative">
          <div className="opacity-20">
            <SerpUsageCalculator />
          </div>
          <div className="absolute left-0 right-0 top-20 flex items-center justify-center">
            <Loader className="m-20 text-4xl" />
          </div>
        </div>
      }
    >
      <SerpUsageCalculator
        initialRows={initialRows}
        initialSchedules={initialSchedules}
      />
    </QueryGate>
  )
}
