import { css, cx } from '@emotion/css'
import * as React from 'react'
import { FaCircle } from 'react-icons/fa'
import { UseQueryResult } from 'react-query'
import { twMerge } from 'tailwind-merge'
import MetricInfo from '../components/MetricInfo'

import { last } from '../utils'
import { getDataColor } from '../utils/DataColors'
import { formatChange } from '../utils/Format'
import {
  Metric,
  MetricId,
  MetricPostAggregation,
  getMetricFormatter,
  metricsById,
} from '../utils/Metrics'
import { twConfig } from '../utils/tailwind'
import AspectRatio from './AspectRatio'
import BoxPlaceholder from './BoxPlaceholder'
import TimeSeriesChart from './TimeSeriesChart'

export default function MetricsBar({
  dataQuery,
  metricIds,
  metricId: focusMetricId,
  formatSeriesLabel = d => d.label,
  setState,
  postAggregation,
  summary = true,
}: {
  dataQuery: UseQueryResult<any>
  metricIds: MetricId[]
  metricId: Metric['id']
  formatSeriesLabel: (series: any, all: any) => React.ReactNode
  setState: any
  postAggregation: MetricPostAggregation
  summary: boolean
}) {
  const datumsLength = dataQuery.data.series?.length
    ? Math.max(...dataQuery.data.series.map((d: any) => d.data?.length))
    : 0

  return (
    <div
      className={twMerge(
        `no-scrollbar md:scrollbar flex overflow-x-auto overflow-y-visible`,
        dataQuery.isFetching ? 'opacity-50' : ''
      )}
    >
      {metricIds
        .filter(d => d !== 'top_ranking_results')
        .map((metricId, i) => {
          const onMetricFocusChange = (id: any) => {
            setState((old: any) => ({
              ...old,
              metricId: metric.id,
            }))
          }

          const isActive = metricId === focusMetricId
          const metric = metricsById[metricId]
          const metricFormatter = getMetricFormatter(metricId)

          return (
            <button
              key={i}
              onClick={() => onMetricFocusChange(metricId)}
              className={twMerge(
                `
                  ease z-0 inline-block flex
                  min-w-[110px] flex-1 flex-col
                  items-stretch divide-y divide-gray-50 border-r
                border-gray-50 text-left transition
                  duration-200 last:border-r-0 hover:z-10
                  dark:divide-gray-850
                  dark:border-gray-850`,
                css`
                  &:hover {
                    box-shadow: inset 0 3px 0 0
                        ${twConfig.theme.colors.gray['200']},
                      0 3px 20px rgba(0, 0, 0, 0.2);
                  }
                `,
                isActive && `divide-gray-500/20 bg-gray-500 bg-opacity-5`,
                isActive &&
                  css`
                    box-shadow: inset 0 3px 0 0
                      ${twConfig.theme.colors.blue['600']};
                  `
              )}
            >
              <div className="flex flex-1 items-center gap-4 p-2 text-left text-sm">
                <MetricInfo metricId={metricId}>{metric.label}</MetricInfo>
              </div>
              <div
                onClick={() => onMetricFocusChange(metric)}
                className={twMerge(
                  `divide-y divide-gray-50 text-left dark:divide-gray-850`,
                  isActive && `divide-gray-500/20`
                )}
              >
                <AspectRatio ratio={[5, 1]} className={`min-h-12`}>
                  <MetricHistoryChart
                    {...{
                      sparkline: true,
                      dataQuery,
                      postAggregation,
                      metricId,
                      metricIds,
                      formatSeriesLabel,
                    }}
                  />
                </AspectRatio>
                {!summary ? (
                  <div
                    className={twMerge(
                      `divide-y divide-gray-50 text-sm dark:divide-gray-800`,
                      isActive && `divide-gray-500/20`
                    )}
                  >
                    <div className="font-mono flex items-center gap-2 p-2 leading-none">
                      {datumsLength === 1 ? (
                        <FaCircle
                          className="inline-block"
                          style={{
                            color: getDataColor(i),
                            textShadow: `0 0 20px rgba(0,0,0,.2)`,
                          }}
                        />
                      ) : null}
                      {dataQuery.isLoading ? (
                        <BoxPlaceholder />
                      ) : dataQuery.data?.series?.length ? (
                        metricFormatter(
                          // @ts-expect-error  // Object is of type 'unknown'.
                          last(dataQuery.data.series[0].data)[metric.id][
                            `agg_${metric.aggregations[0]}`
                          ].total?.value
                        )
                      ) : null}
                    </div>
                    {datumsLength === 1 ? null : (
                      <div className="p-2 leading-none">
                        {dataQuery.isLoading ? (
                          <BoxPlaceholder />
                        ) : dataQuery.data?.series?.length ? (
                          formatChange(
                            (metric.inverted ? -1 : 1) *
                              // @ts-expect-error  // Object is of type 'unknown'.
                              last(dataQuery.data.series[0].data)[metric.id][
                                `agg_${metric.aggregations[0]}`
                              ].total.change,
                            {
                              format: (d, opts) =>
                                metricFormatter(d, opts) as string,
                            }
                          )
                        ) : null}
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            </button>
          )
        })}
    </div>
  )
}

function MetricHistoryChart({
  dataQuery,
  metricId,
  metricIds,
  postAggregation,
  formatSeriesLabel,
  ...props
}: {
  dataQuery: UseQueryResult<any>
  metricId: MetricId
  postAggregation: PostAggregation
  formatSeriesLabel: any
  metricIds: MetricId[]
  onClickSerie?: (serie: unknown) => void
  onClickDatum?: (datum: unknown) => void
}) {
  return (
    <TimeSeriesChart
      {...{
        ...props,
        isLoading: dataQuery.isLoading,
        isFetching: dataQuery.isFetching,
        isError: dataQuery.isError,
        error: dataQuery.error,
        displayPostAggregation: postAggregation,
        metricId,
        subAggregation: 'total',
        limit: 2,
        data: React.useMemo(() => {
          return dataQuery.data?.series?.map((series: any) => {
            const multi = dataQuery.data?.series?.length !== 1
            return {
              ...series,
              color: !multi
                ? getDataColor(metricIds.findIndex(d => d === metricId))
                : series.color,
              label: formatSeriesLabel(series, dataQuery.data?.series),
            }
          })
        }, [dataQuery.data?.series, formatSeriesLabel, metricId, metricIds]),
      }}
    />
  )
}
