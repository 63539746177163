import { useNavigate } from '@tanstack/react-router'
import { FaAngleRight } from 'react-icons/fa'
import { useMutation, useQueryClient } from 'react-query'
import Card from '../../../components/Card'
import { CreatePlanForm } from '../../../components/CreatePlanForm'
import Link from '../../../components/Link'
import useErrorPopup from '../../../hooks/useErrorPopup'
import useLoading from '../../../hooks/useLoading'
import usePopup from '../../../hooks/usePopup'
import useToast from '../../../hooks/useToast'
import { queryKeyBillingPlans } from '../../../utils/Constants'
import { PlanPb, PlansClient } from '../../../utils/proto'

export function AdminNewPlan() {
  const toast = useToast()
  const [, setLoading] = useLoading()
  const queryClient = useQueryClient()
  const popup = usePopup()
  const navigate = useNavigate()

  const createPlanMutation = useMutation({
    mutationFn: (plan: PlanPb) =>
      PlansClient.updatePlan({
        allowMissing: true,
        plan,
      }),
    onMutate: () => {
      setLoading(true)
    },
    onSuccess: async () => {
      toast({
        message: 'Plan Created',
        color: 'green-500',
      })
      await queryClient.invalidateQueries(queryKeyBillingPlans)
      navigate({
        to: '../',
      })
    },
    onError: async (err: any) => {
      console.error(err)
      await popup({
        color: 'red-500',
        title: 'Failed to create plan!',
        message: (
          <pre className="overflow-hidden whitespace-normal text-left text-sm font-bold text-red-500">
            <code>{JSON.stringify(err, null, 2)}</code>
          </pre>
        ),
      })
    },
    onSettled: () => {
      setLoading(false)
    },
  })

  return (
    <Card>
      <div className="dark:divide-y-800 divide-y divide-gray-100">
        <div className="flex items-center p-2 text-2xl font-bold">
          <Link to="../">Plans</Link>
          <FaAngleRight />
          <div>Create New Plan</div>
        </div>
        <div className="p-2">
          <CreatePlanForm onSubmit={createPlanMutation.mutate} />
        </div>
      </div>
    </Card>
  )
}
