import { PlainMessage, Timestamp, proto3 } from '@bufbuild/protobuf'
import { useNavigate, useSearch } from '@tanstack/react-router'
import moment from 'moment'
import * as React from 'react'
import { useForm } from 'react-form'
import { BiCreditCard } from 'react-icons/bi'
import { FaCheck } from 'react-icons/fa'
import { useMutation, useQueryClient } from 'react-query'

import { Workspace } from '../../../openapi'
import Button from '../../components/Button'
import Card from '../../components/Card'
import Clickable from '../../components/Clickable'
import DateField from '../../components/DateField'
import LabelWrap from '../../components/LabelWrap'
import Link from '../../components/Link'
import NumberField from '../../components/NumberField'
import QueryGate from '../../components/QueryGate'
import Select from '../../components/Select'
import SelectField from '../../components/SelectField'
import Spinner from '../../components/Spinner'
import StripeCard from '../../components/StripeCard'
import {
  TableCell,
  TableEl,
  TableRow,
  TableWrapInner,
  TableWrapOuter,
} from '../../components/Table'
import TextField from '../../components/TextField'
import { formatCardType, useAccount } from '../../hooks/account'
import { useChangeSubscriptionPlan, usePlansQuery } from '../../hooks/plans'
import useLoading from '../../hooks/useLoading'
import usePopup from '../../hooks/usePopup'
import useToast from '../../hooks/useToast'
import { useWorkspacesQuery } from '../../hooks/workspaces'
import { uniqBy } from '../../utils'
import { queryKeyBillingPlans } from '../../utils/Constants'
import { formatCurrency, formatNumber } from '../../utils/Format'
import { moneyToFloat } from '../../utils/money'
import { dateToDatePb, datePbToDate } from '../../utils/protoDate'
import { PartialByKey } from '../../utils/types'
import {
  SubscriptionsClient,
  AccountPb,
  AccountViewPb,
  CancelReasonPb,
  ChangeSubscriptionRequestPb,
  ChangeTimingPb,
  PeriodPb,
  ProductFamilyPb,
  SubscriptionStatePb,
  UsageTypePb,
} from '../../utils/proto'

export function AdminSubscriptions() {
  const workspacesQuery = useWorkspacesQuery()
  const { workspaceId } = useSearch()
  const navigate = useNavigate()

  const workspace = React.useMemo(
    () => workspacesQuery.data?.find(d => d.id === workspaceId),
    [workspaceId, workspacesQuery.data]
  )

  const workspacesOptions = React.useMemo(
    () =>
      workspacesQuery.data?.map(workspace => ({
        label: workspace.name,
        value: workspace.id,
      })) ?? [],
    [workspacesQuery.data]
  )

  return (
    <div className="space-y-4">
      <Card className="dark:divide-y-800 divide-y divide-gray-100 p-0">
        <div className="flex items-center p-2 text-2xl font-bold">
          Subscriptions
        </div>
        <QueryGate query={workspacesQuery}>
          {() => (
            <>
              <div className="p-2">
                <LabelWrap label="Workspace">
                  <Select
                    // @ts-expect-error  // Type 'unknown' is not assignable to type 'number |... Remove this comment to see the full error message
                    value={workspaceId}
                    options={workspacesOptions}
                    onChange={(val: any) =>
                      navigate({ search: d => ({ ...d, workspaceId: val }) })
                    }
                  />
                </LabelWrap>
              </div>
            </>
          )}
        </QueryGate>
      </Card>
      {workspace ? (
        <WorkspaceSubscription workspace={workspace} />
      ) : (
        <Card className="p-2 italic">
          Select a workspace from the list above.
        </Card>
      )}
    </div>
  )
}

function WorkspaceSubscription(props: { workspace: Workspace }) {
  const accountQuery = useAccount({
    workspaceId: props.workspace.id,
    view: AccountViewPb.FULL,
  })

  const account = accountQuery.data
  const subscription = account?.subscriptions[0]
  const meteredSubscription = subscription?.meteredSubscriptions[0]

  return (
    <>
      <Card>
        <QueryGate query={accountQuery}>
          {!subscription ? null : (
            <div className="divide-y divide-gray-500/20">
              {/* <div className="text-xl font-bold">{subscription}</div> */}
              <TableWrapOuter className="text-base">
                <TableWrapInner className="flex divide-x-2 divide-gray-500/20">
                  <TableEl>
                    <tbody>
                      <TableRow>
                        <TableCell>Plan</TableCell>
                        <TableCell>
                          <Link to={`../plans/${subscription?.planId}`}>
                            {subscription?.name}
                          </Link>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>State</TableCell>
                        <TableCell className="text-sm">
                          {
                            proto3
                              .getEnumType(SubscriptionStatePb)
                              .findNumber(subscription!.state)?.name
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Base Period</TableCell>
                        <TableCell className="text-sm">
                          {
                            proto3
                              .getEnumType(PeriodPb)
                              .findNumber(subscription!.basePeriod)?.name
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Base Price</TableCell>
                        <TableCell>
                          {formatCurrency(
                            moneyToFloat(subscription?.basePrice)
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Included SERPs</TableCell>
                        <TableCell>
                          {formatNumber(
                            Number(meteredSubscription!.includedQuantity)
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Overage Period</TableCell>
                        <TableCell className="text-sm">
                          {
                            proto3
                              .getEnumType(PeriodPb)
                              .findNumber(subscription!.overagePeriod)?.name
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Overage Price</TableCell>
                        <TableCell>
                          $
                          {formatNumber(
                            moneyToFloat(meteredSubscription!.unitPrice),
                            { precision: 7 }
                          )}{' '}
                          ($
                          {moneyToFloat(meteredSubscription!.unitPrice) *
                            1000}{' '}
                          / 1k)
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Consumed SERPs</TableCell>
                        <TableCell>
                          {formatNumber(
                            Number(meteredSubscription!.consumedQuantity)
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Coupon SERPs</TableCell>
                        <TableCell>
                          {formatNumber(
                            Number(meteredSubscription!.couponQuantity)
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>PO Number</TableCell>
                        <TableCell>{subscription?.poNumber}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Cancel Reason</TableCell>
                        <TableCell className="text-sm">
                          {subscription?.cancelRequestedAt ||
                          subscription?.canceledAt
                            ? proto3
                                .getEnumType(CancelReasonPb)
                                .findNumber(subscription?.cancelReason)?.name
                            : ''}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Coupons</TableCell>
                        <TableCell>
                          <div className="space-y-2">
                            {subscription?.coupons.map(coupon => {
                              return (
                                <div key={coupon.id}>
                                  {coupon.name} ({coupon.id})
                                </div>
                              )
                            })}
                          </div>
                        </TableCell>
                      </TableRow>
                    </tbody>
                  </TableEl>
                  <TableEl>
                    <tbody>
                      <TableRow>
                        <TableCell>Created At</TableCell>
                        <TableCell>
                          {subscription?.createdAt
                            ? moment(subscription?.createdAt?.toDate()).format(
                                'lll'
                              )
                            : ''}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Activated At</TableCell>
                        <TableCell>
                          {subscription?.activatedAt
                            ? moment(
                                subscription?.activatedAt?.toDate()
                              ).format('lll')
                            : ''}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Trial Start</TableCell>
                        <TableCell>
                          {subscription?.trialStart
                            ? moment(
                                datePbToDate(subscription?.trialStart)
                              ).format('lll')
                            : ''}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Trial End</TableCell>
                        <TableCell>
                          {subscription?.trialEnd
                            ? moment(
                                datePbToDate(subscription?.trialEnd)
                              ).format('lll')
                            : ''}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Cancel Requested At</TableCell>
                        <TableCell>
                          {subscription?.cancelRequestedAt
                            ? moment(
                                subscription?.cancelRequestedAt?.toDate()
                              ).format('lll')
                            : ''}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Cancelled At</TableCell>
                        <TableCell>
                          {subscription?.canceledAt
                            ? moment(subscription?.canceledAt?.toDate()).format(
                                'lll'
                              )
                            : ''}
                        </TableCell>
                      </TableRow>
                    </tbody>
                  </TableEl>
                </TableWrapInner>
              </TableWrapOuter>
            </div>
          )}
        </QueryGate>
      </Card>
      <Card className="divide-y divide-gray-500/20 p-0">
        <div className="flex items-center p-2 text-xl font-bold">
          Payment Info
        </div>
        <div className="p-2">
          <QueryGate query={accountQuery}>
            <div className="text-2xl">
              {account?.paymentMethod?.details.case === 'card' ? (
                <div>
                  <div className="flex items-center gap-2 whitespace-nowrap">
                    <BiCreditCard className="text-2xl text-blue-500" />
                    <span className="">
                      {formatCardType(
                        account?.paymentMethod.details.value.type
                      ).toUpperCase()}
                    </span>
                    <span className="text-sm">**** **** ****</span>
                    <span className="font-bold">
                      {account?.paymentMethod.details.value.last4}
                    </span>
                  </div>
                </div>
              ) : (
                <div className="italic opacity-50">No card added yet.</div>
              )}
            </div>
            <div className="h-1" />
            <StripeCard
              workspaceId={Number(account?.workspaceId)}
              button={
                <Button size="base" color="green-500">
                  Update Credit Card
                </Button>
              }
            />
          </QueryGate>
        </div>
      </Card>
      <Card className="divide-y divide-gray-500/20 p-0">
        <div className="flex items-center p-2 text-xl font-bold">
          Edit Subscription
        </div>
        <div className="p-2">
          <QueryGate query={accountQuery}>
            <EditSubscription account={account!} />
          </QueryGate>
        </div>
      </Card>
      <Card className="divide-y divide-gray-500/20 p-0">
        <div className="flex items-center p-2 text-xl font-bold">
          Edit Trial
        </div>
        <div className="p-2">
          <QueryGate query={accountQuery}>
            <EditTrial account={account!} />
          </QueryGate>
        </div>
      </Card>
    </>
  )
}

function EditSubscription({ account }: { account: AccountPb }) {
  const subscription = account.subscriptions[0]!

  const changeSubscriptionPlanMutation = useChangeSubscriptionPlan()

  const defaultValues = React.useMemo(
    () => ({
      planId: subscription.planId,
      basePeriod: subscription.basePeriod,
      overagePeriod: subscription.overagePeriod,
    }),
    [subscription.basePeriod, subscription.overagePeriod, subscription.planId]
  )

  return (
    <EditSubscriptionForm
      defaultValues={defaultValues}
      onSubmit={values => {
        changeSubscriptionPlanMutation.mutateAsync({
          ...values,
          productFamily: ProductFamilyPb.STANDARD,
          workspaceId: account.workspaceId,
          couponCodes: values.couponCode ? [values.couponCode] : [],
          changeTiming: ChangeTimingPb.UNSPECIFIED,
          startDate: values.startDate
            ? dateToDatePb(values.startDate)
            : undefined,
        })
      }}
    />
  )
}

interface SubscriptionFormValues
  extends PartialByKey<
    Pick<
      PlainMessage<ChangeSubscriptionRequestPb>,
      'planId' | 'basePeriod' | 'overagePeriod' | 'poNumber' | 'changeTiming'
    >,
    'changeTiming' | 'poNumber'
  > {
  startDate?: Date
  couponCode?: string
}

export function EditSubscriptionForm(props: {
  defaultValues: SubscriptionFormValues
  onSubmit: (values: SubscriptionFormValues) => void
}) {
  const {
    Form,
    values: { planId, basePeriod, startDate },
    setFieldValue,
    meta: { isSubmitting },
  } = useForm({
    defaultValues: React.useMemo(
      () => ({
        ...props.defaultValues,
        changeTiming: ChangeTimingPb.UNSPECIFIED,
      }),
      [props.defaultValues]
    ),
    // @ts-expect-error  // Type '(values: SubscriptionFormValues) => void' is... Remove this comment to see the full error message
    onSubmit: props.onSubmit,
  })

  const plansQuery = usePlansQuery({ includeCustom: true })

  const planOptions = React.useMemo(() => {
    return (
      plansQuery.data?.plans.map(plan => ({
        label: plan.name,
        value: plan.planId,
      })) ?? []
    )
  }, [plansQuery.data?.plans])

  const basePeriodOptions = React.useMemo(
    () =>
      uniqBy(
        plansQuery.data?.plans
          .find(d => d.planId === planId)
          ?.periodPlans.map(d => ({
            label: d.basePeriod === PeriodPb.YEARLY ? 'Yearly' : 'Monthly',
            value: d.basePeriod,
          })) ?? [],
        d => d.value
      ),
    [planId, plansQuery.data?.plans]
  )

  React.useEffect(() => {
    setFieldValue('basePeriod', basePeriodOptions[0]?.value)
  }, [basePeriodOptions, setFieldValue])

  const overagePeriodOptions = React.useMemo(
    () =>
      uniqBy(
        plansQuery.data?.plans
          .find(d => d.planId === planId)
          ?.periodPlans.filter(d => d.basePeriod === basePeriod)
          .map(d => ({
            label: d.overagePeriod === PeriodPb.YEARLY ? 'Yearly' : 'Monthly',
            value: d.overagePeriod,
          })) ?? [],
        d => d.value
      ),
    [basePeriod, planId, plansQuery.data?.plans]
  )

  React.useEffect(() => {
    setFieldValue('overagePeriod', overagePeriodOptions[0]?.value)
  }, [overagePeriodOptions, setFieldValue])

  const changeTimingOptions = React.useMemo(() => {
    return [
      { value: ChangeTimingPb.END_OF_TERM, label: 'End of Term' },
      { value: ChangeTimingPb.IMMEDIATE, label: 'Immediately' },
      { value: ChangeTimingPb.UNSPECIFIED, label: 'Unspecified' },
    ]
  }, [])

  return (
    <Form>
      <div className="flex flex-wrap gap-2">
        <div className="min-w-[250px] flex-1">
          <SelectField field="planId" options={planOptions} label="Plan" />
        </div>
        <div className="min-w-[250px] flex-1">
          <SelectField
            field="basePeriod"
            options={basePeriodOptions}
            label="Base Period"
          />
        </div>
        <div className="min-w-[250px] flex-1">
          <SelectField
            field="overagePeriod"
            options={overagePeriodOptions}
            label="Overage Period"
          />
        </div>
      </div>
      <div className="h-2" />
      <div className="flex flex-wrap gap-2">
        <div className="min-w-[250px] flex-1">
          <DateField field="startDate" label="Start Date" />
          {startDate ? (
            <Clickable
              onClick={() => setFieldValue('startDate', undefined)}
              className="text-xs font-bold text-blue-500"
            >
              Clear
            </Clickable>
          ) : null}
        </div>
        <div className="min-w-[250px] flex-1">
          <TextField field="couponCode" label="Coupon Code" />
        </div>
        <div className="min-w-[250px] flex-1">
          <TextField field="poNumber" label="PO Number" />
        </div>
      </div>
      <div className="h-2" />
      <div className="flex">
        <div className="min-w-[250px]">
          <SelectField
            field="changeTiming"
            options={changeTimingOptions}
            label="Change Timing"
          />
        </div>
      </div>
      <div className="h-2" />
      <Button
        type="submit"
        color="green-500"
        size="base"
        disabled={isSubmitting}
        className="flex items-center gap-2"
      >
        {isSubmitting ? (
          <>
            <Spinner color="white" /> Changing Subscription...
          </>
        ) : (
          <>
            <FaCheck className="inline" /> Change Subscription
          </>
        )}
      </Button>
    </Form>
  )
}

function EditTrial({ account }: { account: AccountPb }) {
  const subscription = account.subscriptions[0]!
  const meteredSubscription = subscription.meteredSubscriptions[0]!

  const toast = useToast()
  const [, setLoading] = useLoading()
  const queryClient = useQueryClient()
  const popup = usePopup()

  const saveTrialMutation = useMutation({
    mutationFn: ({
      trialUsage,
      trialEndDate,
    }: {
      trialUsage: number
      trialEndDate: Date
    }) =>
      SubscriptionsClient.modifyTrial({
        workspaceId: account.workspaceId,
        productFamily: ProductFamilyPb.STANDARD,
        trialUsage: [
          {
            productFamily: ProductFamilyPb.STANDARD,
            quantity: BigInt(trialUsage),
            usageType: UsageTypePb.SERP,
          },
        ],
        trialEndDate: Timestamp.fromDate(trialEndDate),
      }),
    onMutate: () => {
      setLoading(true)
    },
    onSuccess: async () => {
      toast({
        message: 'Trial Updated',
        color: 'green-500',
      })
      await queryClient.invalidateQueries(queryKeyBillingPlans)
    },
    onError: async (err: any) => {
      console.error(err)
      await popup({
        color: 'red-500',
        title: 'Failed to update trial!',
        message: (
          <pre className="overflow-hidden whitespace-normal text-left text-sm font-bold text-red-500">
            <code>{JSON.stringify(err, null, 2)}</code>
          </pre>
        ),
      })
    },
    onSettled: () => {
      setLoading(false)
    },
  })

  const trialEndDate = datePbToDate(subscription.trialEnd)

  const defaultTrialEndDate = moment(trialEndDate).isBefore(moment())
    ? moment().startOf('day').add('days', 14).toDate()
    : trialEndDate

  const defaultValues = React.useMemo(
    () => ({
      trialUsage: Number(meteredSubscription.includedQuantity),
      trialEndDate: defaultTrialEndDate,
    }),
    [defaultTrialEndDate, meteredSubscription.includedQuantity]
  )

  return (
    <EditTrialForm
      defaultValues={defaultValues}
      onSubmit={saveTrialMutation.mutateAsync}
    />
  )
}

export function EditTrialForm(props: {
  defaultValues: {
    trialUsage: number
    trialEndDate: Date
  }
  onSubmit: (values: { trialUsage: number; trialEndDate: Date }) => void
}) {
  const {
    Form,
    meta: { isSubmitting },
  } = useForm({
    defaultValues: props.defaultValues,
    // @ts-expect-error  // Type '(values: { trialUsage: number; trialEndDate:... Remove this comment to see the full error message
    onSubmit: props.onSubmit,
  })

  return (
    <Form>
      <div className="flex flex-wrap gap-2">
        <div className="min-w-[250px] flex-1">
          <DateField field="trialEndDate" label="Trial End Date" required />
        </div>
        <div className="min-w-[250px] flex-1">
          {/* @ts-expect-error  //TODO: */}
          <NumberField
            field="trialUsage"
            label="Trial SERP Limit"
            placeholder="10,000"
            required
          />
        </div>
      </div>
      <div className="h-2" />
      <Button
        type="submit"
        color="green-500"
        size="base"
        disabled={isSubmitting}
        className="flex items-center gap-2"
      >
        {isSubmitting ? (
          <>
            <Spinner color="white" /> Updating Trial...
          </>
        ) : (
          <>
            <FaCheck className="inline" /> Update Trial
          </>
        )}
      </Button>
    </Form>
  )
}
