import { useSearchState } from '../../components/useSearchState'

export type TopicsTab =
  | undefined
  | 'manage'
  | 'overview'
  | 'mappedUrLs'
  | 'keywords'
  | 'phrases'
  | 'devices'
  | 'locations'
  | 'languages'
  | 'paa'
  | 'shareOfVoice'
  | 'serpFeatures'

const path = 'topicsTab'

const useDeps = (search: Partial<LocationGenerics['Search']>) => [
  search.workspaceId,
  search.projectId,
]

export function useTopicsTab() {
  return useSearchState<TopicsTab>({
    path,
    useDefaultValue: () => 'manage',
    useDeps,
  })
}

export const topicsTabOptions = [
  { label: 'Manage', id: 'manage' },
  { label: 'Overview', id: 'overview' },
  { label: 'Mapped URLs', id: 'mappedUrLs' },
  { label: 'Keywords', id: 'keywords' },
  { label: 'Phrases', id: 'phrases' },
  { label: 'Devices', id: 'devices' },
  { label: 'Locations', id: 'locations' },
  { label: 'Languages', id: 'languages' },
  { label: 'PAA', id: 'paa' },
  { label: 'Share of Voice', id: 'shareOfVoice' },
  { label: 'SERP Features', id: 'serpFeatures' },
] satisfies {
  id: TopicsTab
  label: string
  disabled?: boolean
}[]
