import { twMerge } from 'tailwind-merge'
import { withProps } from '../utils/withProps'

//

const BoxPlaceholder = withProps('div')(({ className, ...props }) => ({
  ...props,
  className: twMerge(
    `light-box-placeholder dark:dark-box-placeholder
    h-[1em]
    rounded`,
    className
  ),
  style: {
    animation: `BackgroundPositionWave 2.5s ease-in-out infinite`,
    backgroundSize: `400% 400%`,
  },
}))

export default BoxPlaceholder
