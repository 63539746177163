/* tslint:disable */
/* eslint-disable */
/**
 * Nozzle API
 * An API to interact with Nozzle
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: developers@nozzle.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Brand
 */
export interface Brand {
    /**
     * 
     * @type {string}
     * @memberof Brand
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Brand
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Brand
     */
    deletedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Brand
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Brand
     */
    versionId?: string;
    /**
     * 
     * @type {string}
     * @memberof Brand
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Brand
     */
    type: BrandTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Brand
     */
    reputationImpact: number;
    /**
     * 
     * @type {Array<BrandProperty>}
     * @memberof Brand
     */
    properties: Array<BrandProperty>;
    /**
     * 
     * @type {string}
     * @memberof Brand
     */
    domain?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum BrandTypeEnum {
    Owned = 'Owned',
    Friendly = 'Friendly',
    Neutral = 'Neutral',
    CompetitorDirect = 'Competitor (Direct)',
    CompetitorIndirect = 'Competitor (Indirect)',
    CompetitorReplacement = 'Competitor (Replacement)'
}

/**
 * 
 * @export
 * @interface BrandProperty
 */
export interface BrandProperty {
    /**
     * 
     * @type {string}
     * @memberof BrandProperty
     */
    name: string;
    /**
     * 
     * @type {any}
     * @memberof BrandProperty
     */
    condition: any | null;
    /**
     * 
     * @type {string}
     * @memberof BrandProperty
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof BrandProperty
     */
    value: string;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {Array<Reward>}
     * @memberof InlineResponse200
     */
    data?: Array<Reward>;
}
/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 
     * @type {Reward}
     * @memberof InlineResponse2001
     */
    data?: Reward;
}
/**
 * 
 * @export
 * @interface Profile
 */
export interface Profile {
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    picture: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    givenName?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    familyName?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    lastWorkspaceId?: string;
    /**
     * 
     * @type {Array<UserWorkspace>}
     * @memberof Profile
     */
    userWorkspaces: Array<UserWorkspace>;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    isAdmin?: boolean;
}
/**
 * 
 * @export
 * @interface Reward
 */
export interface Reward {
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    workspaceId?: string;
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    urlLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    type: RewardTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    submittedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    approvedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    deniedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    availableAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    availableUntil?: string;
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    canceledAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    deletedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    submittedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    approvedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    deniedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    canceledByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    createdByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    deletedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    status: RewardStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    inputLabel: string;
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    inputPlaceholder: string;
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    inputValue?: string;
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    deniedReason?: string;
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    help: string;
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    terms: string;
    /**
     * 
     * @type {number}
     * @memberof Reward
     */
    priority: number;
    /**
     * 
     * @type {Array<RewardPerk>}
     * @memberof Reward
     */
    perks: Array<RewardPerk>;
}

/**
    * @export
    * @enum {string}
    */
export enum RewardTypeEnum {
    Review = 'review',
    Social = 'social',
    Onboarding = 'onboarding'
}
/**
    * @export
    * @enum {string}
    */
export enum RewardStatusEnum {
    Unsubmitted = 'unsubmitted',
    Submitted = 'submitted',
    Approved = 'approved',
    Denied = 'denied'
}

/**
 * 
 * @export
 * @interface RewardPerk
 */
export interface RewardPerk {
    /**
     * 
     * @type {string}
     * @memberof RewardPerk
     */
    type?: RewardPerkTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof RewardPerk
     */
    value?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum RewardPerkTypeEnum {
    Pulls = 'pulls',
    TrialDay = 'trialDay'
}

/**
 * 
 * @export
 * @interface SubmitRewardRequest
 */
export interface SubmitRewardRequest {
    /**
     * 
     * @type {string}
     * @memberof SubmitRewardRequest
     */
    workspaceId: string;
    /**
     * 
     * @type {string}
     * @memberof SubmitRewardRequest
     */
    inputValue: string;
}
/**
 * 
 * @export
 * @interface SubmitRewardRequest1
 */
export interface SubmitRewardRequest1 {
    /**
     * 
     * @type {string}
     * @memberof SubmitRewardRequest1
     */
    workspaceId: string;
}
/**
 * 
 * @export
 * @interface UserWorkspace
 */
export interface UserWorkspace {
    /**
     * 
     * @type {string}
     * @memberof UserWorkspace
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserWorkspace
     */
    picture: string;
    /**
     * 
     * @type {string}
     * @memberof UserWorkspace
     */
    roleId: string;
    /**
     * 
     * @type {string}
     * @memberof UserWorkspace
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof UserWorkspace
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof UserWorkspace
     */
    deletedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof UserWorkspace
     */
    givenName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserWorkspace
     */
    familyName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserWorkspace
     */
    displayName?: string;
    /**
     * 
     * @type {number}
     * @memberof UserWorkspace
     */
    lastLoggedInAt: number;
    /**
     * 
     * @type {number}
     * @memberof UserWorkspace
     */
    firstLoggedInAt: number;
    /**
     * 
     * @type {Workspace}
     * @memberof UserWorkspace
     */
    workspace: Workspace;
}
/**
 * 
 * @export
 * @interface Workspace
 */
export interface Workspace {
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    deletedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    versionId: string;
}

/**
 * NozzleAdminApi - axios parameter creator
 * @export
 */
export const NozzleAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Cancels a reward submission
         * @param {number} rewardId 
         * @param {SubmitRewardRequest1} submitRewardRequest1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelReward: async (rewardId: number, submitRewardRequest1: SubmitRewardRequest1, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'rewardId' is not null or undefined
            assertParamExists('cancelReward', 'rewardId', rewardId)
            // verify required parameter 'submitRewardRequest1' is not null or undefined
            assertParamExists('cancelReward', 'submitRewardRequest1', submitRewardRequest1)
            const localVarPath = `/rewards/{rewardId}:cancel`
                .replace(`{${"rewardId"}}`, encodeURIComponent(String(rewardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(submitRewardRequest1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches a list of rewards for a user or user\'s workspace
         * @param {number} [workspaceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchRewards: async (workspaceId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/rewards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (workspaceId !== undefined) {
                localVarQueryParameter['workspaceId'] = workspaceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Submits a reward for review
         * @param {number} rewardId 
         * @param {SubmitRewardRequest} submitRewardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitReward: async (rewardId: number, submitRewardRequest: SubmitRewardRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'rewardId' is not null or undefined
            assertParamExists('submitReward', 'rewardId', rewardId)
            // verify required parameter 'submitRewardRequest' is not null or undefined
            assertParamExists('submitReward', 'submitRewardRequest', submitRewardRequest)
            const localVarPath = `/rewards/{rewardId}:submit`
                .replace(`{${"rewardId"}}`, encodeURIComponent(String(rewardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(submitRewardRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NozzleAdminApi - functional programming interface
 * @export
 */
export const NozzleAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NozzleAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * Cancels a reward submission
         * @param {number} rewardId 
         * @param {SubmitRewardRequest1} submitRewardRequest1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelReward(rewardId: number, submitRewardRequest1: SubmitRewardRequest1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelReward(rewardId, submitRewardRequest1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetches a list of rewards for a user or user\'s workspace
         * @param {number} [workspaceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchRewards(workspaceId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchRewards(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Submits a reward for review
         * @param {number} rewardId 
         * @param {SubmitRewardRequest} submitRewardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitReward(rewardId: number, submitRewardRequest: SubmitRewardRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitReward(rewardId, submitRewardRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NozzleAdminApi - factory interface
 * @export
 */
export const NozzleAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NozzleAdminApiFp(configuration)
    return {
        /**
         * Cancels a reward submission
         * @param {number} rewardId 
         * @param {SubmitRewardRequest1} submitRewardRequest1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelReward(rewardId: number, submitRewardRequest1: SubmitRewardRequest1, options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.cancelReward(rewardId, submitRewardRequest1, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches a list of rewards for a user or user\'s workspace
         * @param {number} [workspaceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchRewards(workspaceId?: number, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.fetchRewards(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Submits a reward for review
         * @param {number} rewardId 
         * @param {SubmitRewardRequest} submitRewardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitReward(rewardId: number, submitRewardRequest: SubmitRewardRequest, options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.submitReward(rewardId, submitRewardRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NozzleAdminApi - object-oriented interface
 * @export
 * @class NozzleAdminApi
 * @extends {BaseAPI}
 */
export class NozzleAdminApi extends BaseAPI {
    /**
     * Cancels a reward submission
     * @param {number} rewardId 
     * @param {SubmitRewardRequest1} submitRewardRequest1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NozzleAdminApi
     */
    public cancelReward(rewardId: number, submitRewardRequest1: SubmitRewardRequest1, options?: any) {
        return NozzleAdminApiFp(this.configuration).cancelReward(rewardId, submitRewardRequest1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches a list of rewards for a user or user\'s workspace
     * @param {number} [workspaceId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NozzleAdminApi
     */
    public fetchRewards(workspaceId?: number, options?: any) {
        return NozzleAdminApiFp(this.configuration).fetchRewards(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Submits a reward for review
     * @param {number} rewardId 
     * @param {SubmitRewardRequest} submitRewardRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NozzleAdminApi
     */
    public submitReward(rewardId: number, submitRewardRequest: SubmitRewardRequest, options?: any) {
        return NozzleAdminApiFp(this.configuration).submitReward(rewardId, submitRewardRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


