import { useSearch } from '@tanstack/react-router'
import { FaAngleRight, FaCheck, FaInfo } from 'react-icons/fa'
import { twMerge } from 'tailwind-merge'
import Card from '../../components/Card'
import { Head } from '../../components/Head'
import Link from '../../components/Link'
import { BarButton, ButtonBar } from '../../components/ButtonBar'
import { ClustersComp } from './Clusters'
import { ButtonPlain } from '../../components/ButtonPlain'
import Anchor from '../../components/Anchor'
import useLocalState from '../../hooks/useLocalState'

import { openHubSpotChat } from '../../utils/Analytics'
import { ProjectPicker } from '../../components/ProjectPicker'

export type TopicClusterTab = undefined | 'clusters'

const tabs = [
  {
    label: 'Topics',
    id: undefined,
    disabled: true,
  },
  {
    label: 'Clusters',
    id: 'clusters',
    disabled: false,
  },
] as const

export function TopicClusters() {
  const search = useSearch()
  const topicClusterTab =
    (search.topicClusterTab as TopicClusterTab) ?? 'clusters'

  const [clusterDismissVersion, setClusterDismissVersion] = useLocalState(
    'clusterDismissVersion',
    0
  )

  const currentClusterDismissVersion = 2

  return (
    <div className="space-y-2 p-2">
      <Head>
        <title>Topic Clusters</title>
      </Head>
      <Card className="flex items-center gap-1 text-2xl font-bold">
        <ProjectPicker />
        <FaAngleRight />
        <span>Topic Clusters</span>
        {currentClusterDismissVersion === clusterDismissVersion ? (
          <ButtonPlain
            className="ml-auto flex items-center bg-white text-xs text-gray-700 dark:bg-gray-800 dark:text-white"
            onClick={() => setClusterDismissVersion(0)}
          >
            <FaInfo /> Show Welcome Message
          </ButtonPlain>
        ) : null}
      </Card>
      {clusterDismissVersion < currentClusterDismissVersion ? (
        <div className="space-y-2">
          <div className="grid gap-2 md:grid-cols-2">
            <div className="flex flex-col gap-2">
              <Card className="flex-1 space-y-4 p-4">
                <div className="text-2xl font-bold">
                  Welcome to Topic Clusters!
                </div>
                <div>
                  Your clusters will be available shortly within the next 5 - 10
                  minutes. While you wait, please watch our walkthrough demo to
                  learn how to get the most out of your clusters! Don't worry,
                  we'll let you know when your topics are done and ready to
                  explore.
                </div>
                <div className=""></div>
                <div className="aspect-w-16 aspect-h-9 w-full">
                  <iframe
                    src="https://www.youtube.com/embed/JnQNqounqNU?ab_channel=Nozzle"
                    title="Nozzle - Topic Clusters Demo"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  />
                </div>
              </Card>
            </div>
            <div className="flex flex-col gap-2">
              <Card className="flex-1 space-y-4 p-4">
                <div className="text-lg font-bold">
                  Help us shape the future of topical clustering!
                </div>
                <div>
                  <strong>Topic clusters</strong> are currently in{' '}
                  <div className="inline-block rounded-md border border-cyan-500 bg-white px-1.5 text-[.7rem] font-bold uppercase text-cyan-500 dark:bg-gray-900">
                    beta
                  </div>{' '}
                  and <strong>rapidly improving</strong> every day from feedback
                  from users like you.
                </div>
                <ButtonPlain
                  className="bg-gray-100 dark:bg-gray-800"
                  onClick={openHubSpotChat}
                >
                  Leave Feedback
                </ButtonPlain>
              </Card>
              <Card className="flex-1 space-y-2 p-4">
                <div className="text-lg font-bold">
                  Familiar with other Keyword Clustering tools?
                </div>
                <div>Check out all of our comparison guides:</div>
                <ul className="list-disc">
                  <li>
                    <Anchor
                      href="https://nozzle.io/nozzle-vs-keyword-insights"
                      target="_blank"
                    >
                      Nozzle vs Keyword Insights
                    </Anchor>{' '}
                  </li>
                  <li>
                    <Anchor
                      href="https://nozzle.io/nozzle-vs-se-ranking"
                      target="_blank"
                    >
                      Nozzle vs SE Ranking
                    </Anchor>{' '}
                  </li>
                  <li>
                    <Anchor
                      href="https://nozzle.io/nozzle-vs-keyword-cupid/"
                      target="_blank"
                    >
                      Nozzle vs Keyword Cupid
                    </Anchor>{' '}
                  </li>
                  <li>
                    <Anchor
                      href="https://nozzle.io/nozzle-vs-keyclusters/"
                      target="_blank"
                    >
                      Nozzle vs KeyClusters
                    </Anchor>{' '}
                  </li>
                  <li>
                    <Anchor
                      href="https://nozzle.io/nozzle-vs-clusterai/"
                      target="_blank"
                    >
                      Nozzle vs ClusterAi
                    </Anchor>{' '}
                  </li>
                </ul>
              </Card>
              <Card className="flex-1 space-y-2 p-4">
                <div className="text-lg font-bold">Topics are coming soon!</div>
                <div>
                  Grouping keyword clusters into larger topics will enable a
                  more streamlined analysis of clustering data and simplify the
                  process of identifying topical trends and content gaps and
                  also aid in making informed strategic decisions. Stay tuned
                  for the update!
                </div>
              </Card>
            </div>
          </div>
          <div className="text-center">
            <ButtonPlain
              className="bg-green-500"
              onClick={() =>
                setClusterDismissVersion(currentClusterDismissVersion)
              }
            >
              <FaCheck /> Hide
            </ButtonPlain>
          </div>
        </div>
      ) : null}
      <ClustersComp />
      {/* {topicClusterTab === 'clusters' ? <ClustersComp /> : null} */}
    </div>
  )
}
