import { useQuery } from 'react-query'
import { useActiveWorkspaceId } from '../hooks/workspaces'
import { queryKeyInvoice, queryKeyInvoices } from '../utils/Constants'
import { InvoiceStatePb, InvoicesClient } from '../utils/proto'

export function useInvoice(opts: { id: string; workspaceId?: string }) {
  const activeWorkspaceId = useActiveWorkspaceId()!
  return useQuery([queryKeyInvoice], () =>
    InvoicesClient.getInvoice({
      workspaceId: BigInt(opts.workspaceId ?? activeWorkspaceId),
      invoiceId: opts.id,
    })
  )
}

export function useInvoices() {
  const workspaceId = useActiveWorkspaceId()!
  return useQuery(
    [queryKeyInvoices],
    () =>
      InvoicesClient.listInvoices({
        workspaceId: BigInt(workspaceId),
      }),
    { staleTime: Infinity }
  )
}

export function formatInvoiceState(value: InvoiceStatePb) {
  return {
    [InvoiceStatePb.PENDING]: 'Pending',
    [InvoiceStatePb.NOT_PAID]: 'Not Paid',
    [InvoiceStatePb.PAID]: 'Paid',
    [InvoiceStatePb.PAYMENT_DUE]: 'Payment Due',
    [InvoiceStatePb.POSTED]: 'Posted',
    [InvoiceStatePb.UNSPECIFIED]: 'Unknown',
    [InvoiceStatePb.VOIDED]: 'Voided',
  }[value]
}
