import * as React from 'react'
import { FaClock } from 'react-icons/fa'
import { IoIosWarning } from 'react-icons/io'
import { twMerge } from 'tailwind-merge'
import useDateRangePickerUI from '../hooks/useDateRangePickerUI'
import useGetLatest from '../hooks/useGetLatest'
import useQuickOptions from '../hooks/useQuickOptions'
import useTimeRangeState, { makeDefaultTimeRange } from '../hooks/useTimeRanges'
import { QUICK_OPTION_PRIORITY_SECONDARY_ACTION } from '../utils/Constants'
import { TimeRangeQuickPicks } from '../utils/TimeRangeQuickPicks'
import Tooltip from './Tooltip'

export default function DashboardTimeRange({
  className,
  ...props
}: React.HTMLAttributes<HTMLButtonElement>) {
  const timeRangeState = useTimeRangeState()

  const value = React.useMemo(() => {
    return [timeRangeState.state[0].start, timeRangeState.state[0].end]
  }, [timeRangeState.state])

  const { textValue, getInputProps, Render } = useDateRangePickerUI({
    label: 'Time Ranges',
    value,
    range: true,
    align: 'right',
    onChange: (newValue: any) => {
      timeRangeState.setState([
        {
          start: newValue[0],
          end: newValue[1],
        },
      ])
    },
    quickPicks: TimeRangeQuickPicks,
  })

  const { onClick } = getInputProps()

  const getOnClick = useGetLatest(onClick)

  useQuickOptions(
    React.useMemo(
      () => [
        {
          label: `Dashboard: Change Time Range`,
          priority: QUICK_OPTION_PRIORITY_SECONDARY_ACTION,
          value: getOnClick(),
        },
      ],
      [getOnClick]
    )
  )

  const isNotLatest = timeRangeState.state[0].end !== makeDefaultTimeRange().end

  return (
    <>
      <div className={twMerge('flex flex-wrap items-center gap-2', className)}>
        {isNotLatest ? (
          <Tooltip
            tooltip={
              <>
                Your current date range does{' '}
                <strong>not contain the latest available data</strong>. Click
                this icon to reset the date range to the most recent 30 days.
              </>
            }
            className="flex items-center"
          >
            <button
              onClick={() => {
                timeRangeState.setState([makeDefaultTimeRange()])
              }}
              className="text-xl text-gray-500 opacity-70"
            >
              <IoIosWarning className="animate-pulse" />
            </button>
          </Tooltip>
        ) : null}
        <button
          type="button"
          onClick={onClick}
          className={twMerge(
            `
        flex cursor-pointer
        flex-nowrap items-center gap-2
        whitespace-nowrap rounded-md
        bg-blue-600 px-3 py-1 text-sm
        text-white
        `
          )}
          {...props}
        >
          <FaClock className="inline" />
          <span>{textValue}</span>
        </button>
      </div>
      <Render />
    </>
  )
}
