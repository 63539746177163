import * as React from 'react'
import { GiPartyPopper } from 'react-icons/gi'
import { twMerge } from 'tailwind-merge'
import { Auth } from '../components/Auth'
import Button from '../components/Button'
import Link from '../components/Link'
import { useProfileQuery } from '../hooks/profile'
import useAuth from '../hooks/useAuth'
import { twConfig } from '../utils/tailwind'

//

export default function Login() {
  const [auth] = useAuth()
  const profileQuery = useProfileQuery()
  const [{ stage }] = useAuth()

  const showLogin = auth.stage !== 'loggedIn' || profileQuery.isLoading

  return (
    <div
      className={twMerge(
        'dark',
        `pointer-events-none absolute inset-0 z-[10000] overflow-auto text-white opacity-0 transition duration-500`,
        showLogin && `pointer-events-auto opacity-100 transition-none`
      )}
      style={{
        background: `radial-gradient(
          ${twConfig.theme.colors.blue[700]},
            ${twConfig.theme.colors.blue[900]} 90%
        )`,
      }}
    >
      <div className="mx-auto mt-[10vh]">
        <div className="flex flex-col items-center p-4">
          <img
            src="/img/nozzle-white.svg"
            alt=""
            className="w-[50vw] max-w-[220px]"
          />
          <div className="h-8" />
          <div className="mx-auto max-w-[400px] text-center text-4xl font-thin text-white">
            The <span className="font-black">most powerful</span> rank tracker{' '}
            <span className="font-black">on the planet</span>.
          </div>
          <div className="h-12" />
          <div className="flex flex-col items-center rounded-xl bg-black bg-opacity-30 p-8">
            {stage === 'loggedOut' ? (
              <>
                <div>
                  <Link to="/sign-up">
                    <Button
                      size="lg"
                      color="green-600"
                      className="flex items-center gap-2 font-bold"
                    >
                      <div>
                        <GiPartyPopper className="text-4xl" />
                      </div>
                      <div className="flex flex-col items-start">
                        <div className="tracking-wide">Start Free Trial</div>
                        <div className="text-sm font-normal tracking-tight">
                          No credit card required!
                        </div>
                      </div>
                    </Button>
                  </Link>
                </div>
                <div className="h-6" />
                <div className="flex w-full items-center gap-2">
                  <div className="h-[1px] flex-1 bg-white opacity-30" />
                  <div className="text-white opacity-70">OR</div>
                  <div className="h-[1px] flex-1 bg-white opacity-30" />
                </div>
                <div className="h-6" />
              </>
            ) : null}
            <Auth hideProfile />
          </div>
        </div>
      </div>
      <div className="h-20" />
    </div>
  )
}
