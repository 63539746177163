import * as React from 'react'
import Card from '../../../components/Card'
import Table from '../../../components/Table'
import { useTable } from '../../../hooks/useTable'
import { ClusterPb } from '../../../utils/proto'
import { domainsColumns } from './domainsColumns'

export function ShareOfVoice(props: { cluster: ClusterPb }) {
  const data = React.useMemo(() => {
    return props.cluster.metricsByRollup
  }, [props.cluster.metricsByRollup])

  const table = useTable({
    data,
    columns: domainsColumns,
    initialState: React.useMemo(
      () => ({
        pagination: {
          pageSize: 50,
        },
      }),
      []
    ),
  })

  return (
    <Card className="divide-y divide-gray-500/20 p-0">
      <div className="p-2 text-lg font-bold">Domains</div>
      <div className="divide-x divide-gray-100 dark:divide-gray-850">
        <div className="col-span-2 min-w-0 flex-1">
          <Table table={table} />
        </div>
      </div>
    </Card>
  )
}
