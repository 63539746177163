import { twMerge } from 'tailwind-merge'
import { withProps } from '../utils/withProps'

export const anchorStyles = `
  text-blue-600 dark:text-blue-400
  font-bold
  cursor-pointer
`

const Anchor = withProps('a')(({ className, ...props }) => ({
  ...props,
  className: twMerge(anchorStyles, className),
}))

export default Anchor
