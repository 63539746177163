import moment from 'moment'
import * as React from 'react'
import {
  FaCheck,
  FaExclamationTriangle,
  FaInfoCircle,
  FaRegBell,
  FaTimes,
} from 'react-icons/fa'
import { IoIosFlash } from 'react-icons/io'
import { twMerge } from 'tailwind-merge'
import { useProfileQuery } from '../hooks/profile'
import useClickOutside from '../hooks/useClickOutside'
import useNavigate from '../hooks/useNavigate'
import useToast from '../hooks/useToast'
import useZustand from '../hooks/useZustand'
import { useActiveWorkspaceId } from '../hooks/workspaces'
import { sortBy } from '../utils'
import { onEventSubscription } from '../utils/Api'

const noAlerts = [
  {
    color: 'blue-500',
    message: "No alerts here. You're up to date!",
    createdAt: 'now',
    id: Math.random(),
  },
  {
    color: 'blue-500',
    message: "No, seriously, you're up to date.",
    createdAt: 'now',
    id: Math.random(),
  },
  {
    color: 'blue-500',
    message: '...you really want a notification this badly?',
    createdAt: 'now',
    id: Math.random(),
  },
  {
    color: 'blue-500',
    message:
      'Alright, fine. Uhhh, you have successfully read all of your notifications! Now go be productive!',
    createdAt: 'now',
    id: Math.random(),
  },
]

export default function Alerts(props: any) {
  const [showAlerts, setStore] = useZustand(state => state.showAlerts)
  const [alerts] = useZustand(d => d.alerts)
  const profileQuery = useProfileQuery()

  const workspaceId = useActiveWorkspaceId()!
  const toast = useToast()
  const navigate = useNavigate()
  let sortedAlerts = sortBy(alerts, (d: any) => new Date(d.createdAt))
  // const hasUnseenAlerts = sortedAlerts.find(d => !d.seen)

  sortedAlerts = React.useMemo(
    () => (sortedAlerts.length ? sortedAlerts : noAlerts),
    [sortedAlerts]
  )

  const close = () =>
    setStore(old => {
      old.showAlerts = false
    })

  const elRef = React.useRef()
  useClickOutside(elRef, close)

  // React.useEffect(() => {
  //   return onEventSubscription(profileQuery.data!.id!, async (event: any) => {
  //     if (import.meta.env.DEV) {
  //       console.log('socket event', event)
  //     }
  //   })
  // })

  return (
    <div
      ref={elRef}
      {...props}
      className={twMerge(
        `absolute -right-2 top-14 z-20
          translate-x-full transform
          transition duration-200 ease-out`,
        showAlerts &&
          `
              right-2 translate-x-0
              opacity-100
            `
      )}
    >
      <button
        className={twMerge(
          `absolute left-0 top-4 transform
            transition duration-200`,
          showAlerts &&
            `-translate-x-8
          delay-300`
        )}
        onClick={close}
      >
        <div
          className="
          dark:border-gray-700) flex
          h-8 w-8
          items-center
          justify-center rounded-l-lg bg-gray-200 text-center text-white
          shadow-xl hover:bg-red-500 dark:border dark:border-r-0
          dark:bg-gray-900"
        >
          <FaTimes className="inline" />
        </div>
      </button>

      <div
        className={twMerge(
          `
            relative max-w-sm
            list-none divide-y divide-blue-100
            overflow-hidden rounded-lg bg-white
            opacity-50 shadow-xl
            transition duration-200
            ease-out

            dark:divide-blue-750 dark:border dark:border-blue-700 dark:bg-blue-850
            md:max-w-md
          `,
          showAlerts && `opacity-100`
        )}
      >
        <div className="flex items-center gap-2 p-2 font-bold">
          <FaRegBell className="inline" /> <span>Alerts</span>
        </div>
        {[...sortedAlerts].map((alert: any) => (
          <Alert alert={alert} key={alert.id || alert.createdAt} />
        ))}
      </div>
    </div>
  )
}

function Alert({ alert }: any) {
  return (
    <div onClick={alert.onClick} className="flex items-center">
      {alert.type === 'success' ? (
        <FaCheck className="ml-4 inline text-2xl text-green-500" />
      ) : alert.type === 'danger' ? (
        <FaExclamationTriangle className="ml-4 inline text-2xl text-red-500" />
      ) : alert.type === 'warning' ? (
        <IoIosFlash className="ml-4 text-2xl text-yellow-500 " />
      ) : (
        <FaInfoCircle className="ml-4 inline text-2xl text-blue-500" />
      )}
      <div className="px-4 py-2">
        <div>{alert.message}</div>
        <div className="text-sm opacity-50">
          {alert.createdAt !== 'now'
            ? moment(new Date(alert.createdAt)).fromNow()
            : moment().fromNow()}
        </div>
      </div>
    </div>
  )
}
