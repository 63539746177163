import * as React from 'react'
import useGetLatest from './useGetLatest'

export default function useStableCallback<T extends (...args: any[]) => any>(
  fn: T
): T {
  const getFn = useGetLatest(fn)
  const fnRef = React.useRef((...args: any[]) => getFn()(...args))
  return fnRef.current as T
}
