import { createColumnHelper } from '@tanstack/react-table'
import * as React from 'react'
import { FaEdit, FaTimes, FaTimesCircle } from 'react-icons/fa'
import Button from '../components/Button'
import Select from '../components/Select'
import useConfirm from '../hooks/useConfirm'
import { useTable } from '../hooks/useTable'
import { GroupPhrase, Keyword } from '../utils/Api'
import { NO_GROUP_NAME } from '../utils/Constants'
import { dedupeKeywords } from '../utils/Phrases'
import Clickable from './Clickable'
import Table from './Table'

//

const columnHelper = createColumnHelper<GroupPhrase>()

export default function JsonKeywordsGroups({
  keywords,
  onKeywordsChange,
}: any) {
  const confirm = useConfirm()

  const info = React.useMemo(() => dedupeKeywords(keywords), [keywords])

  const groupOptions = React.useMemo(
    () =>
      Object.keys(info.groups).map(d => ({
        value: d,
        label: d,
      })),
    [info.groups]
  )

  const tableData = React.useMemo<GroupPhrase[]>(
    () =>
      Object.entries(info.groups).map(([group, phrases]: any) => {
        return {
          group,
          phraseCount: phrases.length,
        }
      }),
    [info.groups]
  )

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('group', {
        header: 'Keyword Group',
        filterFn: 'arrIncludesSome',
      }),
      columnHelper.accessor('phraseCount', {
        header: 'Keyword Phrases',
      }),
      columnHelper.display({
        id: 'utils',
        header: 'Actions',
        cell: props => (
          <div className="flex items-center gap-2">
            {props.row.original.group !== NO_GROUP_NAME ? (
              <>
                <Select
                  options={groupOptions}
                  onChange={async value => {
                    const confirmed = await confirm({
                      title: `Are you sure you want to move/rename this group?`,
                    })

                    if (confirmed) {
                      keywords.forEach((keyword: any) => {
                        keyword.groups = keyword.groups
                          ? keyword.groups.map((group: any) =>
                              group === props.row.original.group ? value : group
                            )
                          : []
                      })
                      onKeywordsChange([...keywords])
                    }
                  }}
                  create
                  value={''}
                  className="inline-flex"
                >
                  {({ onClick }: any) => (
                    <Clickable onClick={onClick}>
                      <FaEdit className="inline" /> Move / Rename
                    </Clickable>
                  )}
                </Select>{' '}
                <Clickable
                  onClick={async () => {
                    keywords.forEach((keyword: any) => {
                      keyword.groups = keyword.groups
                        ? keyword.groups.filter(
                            (group: any) => group !== props.row.original.group
                          )
                        : []
                    })
                    onKeywordsChange([...keywords])
                  }}
                >
                  <FaTimes className="inline" /> Remove Group
                </Clickable>
                {/* <Clickable
                  onClick={async () => {
                    const toRemove = tableData
                      .filter(d => {
                        return d.phraseCount < 200
                      })
                      .map(d => d.group)

                    onKeywordsChange(
                      [...keywords].map((keyword: any) => {
                        keyword.groups = keyword.groups.filter(
                          (group: any) => !toRemove.includes(group)
                        )
                        return keyword
                      })
                    )
                    // }
                  }}
                >
                  <FaTimes className="inline" /> Clean
                </Clickable> */}
              </>
            ) : null}
          </div>
        ),
      }),
    ],
    [groupOptions, confirm, keywords, onKeywordsChange]
  )

  const table = useTable({
    data: tableData,
    columns,
    initialState: {
      pagination: {
        pageSize: 10,
      },
      sorting: [{ id: 'group', desc: false }],
    },
    showToolbar: true,
  })

  return (
    <div className="rounded-lg border border-gray-200 dark:border-gray-800">
      <Table table={table} />
    </div>
  )
}
