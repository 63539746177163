import * as React from 'react'
import useConfirm from '../hooks/useConfirm'
import useZustand from '../hooks/useZustand'
import { fetchUser } from '../utils/Api'
import { useProfileQuery } from './profile'
import { useActiveWorkspaceId } from './workspaces'

//

let confirmedPromise: null | Promise<unknown>

export type NozzleEventType =
  | 'TYPE_CREATE'
  | 'TYPE_UPDATE'
  | 'TYPE_DELETE'
  | 'me_updated'
export type NozzleEvent = {
  type: NozzleEventType
  kind:
    | 'Profile'
    | 'User'
    | 'ApiToken'
    | 'Invite'
    | 'Team'
    | 'Brand'
    | 'KeywordSource'
    | 'Segment'
    | 'Billing'
    | 'ClusterJob'
}

export default function useEventFilter(
  kind: string,
  label: string,
  fn: (event: NozzleEvent) => void
) {
  const activeWorkspaceId = useActiveWorkspaceId()
  const profileQuery = useProfileQuery()

  const [entityLeases] = useZustand(state => state.entityLeases)

  const entityLeasesRef = React.useRef(entityLeases)
  entityLeasesRef.current = entityLeases

  const confirm = useConfirm()

  const [queue, setQueue] = React.useState<NozzleEvent[]>([])

  const queueRef = React.useRef(queue)
  queueRef.current = queue

  const fireEvent = React.useCallback(
    event => {
      console.info(`Firing Event: ${event.kind} ${event.type}`, event)
      fn(event)
    },
    [fn]
  )

  const flushQueue = React.useCallback(() => {
    setQueue([])
    queueRef.current.forEach(fireEvent)
  }, [fireEvent])

  const handleEvent = React.useCallback(
    async event => {
      const entityLeases = entityLeasesRef.current

      const entityLeasesKind = entityLeases[kind]

      const queuePaused =
        entityLeasesKind &&
        Object.keys(entityLeasesKind).some(
          entityId => entityLeasesKind[entityId]?.length
        )

      const idIsPaused = entityLeasesKind?.[event.kindId]?.length

      if (String(profileQuery.data?.id) !== String(event.userId)) {
        if (
          idIsPaused &&
          (event.type === 'TYPE_DELETE' || event.type === 'TYPE_UPDATE')
        ) {
          let userName = 'Someone else'
          try {
            // @ts-expect-error  // Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
            const user = await fetchUser(event.userId, activeWorkspaceId)
            userName = user.displayName || user.email
          } catch (err) {
            console.error(err)
          }

          let confirmOptions = {
            title: `${userName} has made changes to this ${label}!`,
            message: `What do you want to do?`,
          }

          if (event.type === 'TYPE_DELETE') {
            confirmOptions = {
              title: `${userName} has deleted this ${label}!`,
              message: `What do you want to do?`,
            }
          }

          if (!confirmedPromise) {
            confirmedPromise = confirm({
              ...confirmOptions,
              cancelText: 'Discard My Changes',
              confirmText: 'Keep Editing',
            })
          }
          const confirmed = await confirmedPromise
          confirmedPromise = null

          if (confirmed) {
            setQueue(old => [...old, event])
            return
          }
          flushQueue()
          fireEvent(event)
          return
        } else if (queuePaused) {
          setQueue(old => [...old, event])
          return
        }
      }
      flushQueue()
      fireEvent(event)
    },
    [
      activeWorkspaceId,
      confirm,
      fireEvent,
      flushQueue,
      kind,
      label,
      profileQuery.data?.id,
    ]
  )

  return handleEvent
}
