import * as React from 'react'
import { FaPlus, FaTimes } from 'react-icons/fa'
import { formatLocale, renderLocale } from '../utils/locales'
import Clickable from './Clickable'
import Loader from './Loader'
import { LocalePicker } from './LocalePicker'
import { useLocalesByIdQuery } from '../utils/locales'
import { ListInput } from './ListInput'

export function LocalesInput(props: {
  value: number[]
  onChange?: (value: number[]) => void
  onBlur?: (e: any) => void
  error?: any
  isTouched?: boolean
  isValidating?: boolean
  setTouched?: (touched: boolean) => void
  required?: boolean
}) {
  const value = React.useMemo(() => {
    return props.value || []
  }, [props.value])

  const localesByIdQuery = useLocalesByIdQuery({ localeIds: value })

  return (
    <ListInput
      items={props.value}
      onChange={items => props.onChange?.(items)}
      renderItem={({ item, setItems }) => {
        const localeId = item
        const locale = localesByIdQuery.data?.[item]

        return (
          <div key={localeId} className="flex justify-between">
            <div className="p-1.5">
              {!locale ? <Loader /> : renderLocale(locale)}
            </div>
            <Clickable
              className="p-2 opacity-30 hover:text-red-500 hover:opacity-100 dark:text-white"
              onClick={() => {
                setItems(prev => prev.filter(v => v !== localeId))
              }}
            >
              <FaTimes />
            </Clickable>
          </div>
        )
      }}
      renderNoneFound={() => <div className="p-1.5">No locales selected</div>}
      renderAfterList={({ setItems }) => {
        return (
          <LocalePicker
            {...{
              excludeLocaleIds: value,
              onChange: (next: any) => {
                // Dedupe locales
                setItems?.(prev => [...new Set([...prev, next]).values()])
              },
              onBlur: (e: Event) => {
                props.setTouched?.(true)
                props.onBlur?.(e)
              },
            }}
          >
            {({ onClick }: any) => (
              <Clickable
                onClick={onClick}
                className="flex items-center gap-1 p-2 text-sm !font-bold text-blue-500"
              >
                <FaPlus /> Add Locale
              </Clickable>
            )}
          </LocalePicker>
        )
      }}
    />
  )
}
