import * as React from 'react'
import useDarkMode from '../hooks/useDarkMode'
import useZustand from '../hooks/useZustand'
import LocalStorage from '../utils/LocalStorage'

export const ThemeModeProvider = ({ children }: any) => {
  const { themeMode } = useThemeMode()

  React.useLayoutEffect(() => {
    document.getElementsByTagName('HTML')[0].classList.remove('dark')

    document.getElementsByTagName('HTML')[0].classList.remove('light')

    document.getElementsByTagName('HTML')[0].classList.add(themeMode)
    LocalStorage.set('themeMode', themeMode)
  }, [themeMode])

  return children
}

export const useThemeMode = () => {
  const autoDarkMode = useDarkMode()
  const [preThemeMode, setStore] = useZustand(state => state.themeMode)

  const themeMode =
    preThemeMode === 'auto' ? (autoDarkMode ? 'dark' : 'light') : preThemeMode

  const setThemeMode = React.useCallback(
    toSet => {
      setStore(old => {
        old.themeMode =
          typeof toSet === 'function' ? toSet(old.themeMode) : toSet
      })
    },
    [setStore]
  )

  return { preThemeMode, themeMode, setThemeMode }
}
