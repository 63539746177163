import * as React from 'react'
import { createColumnHelper } from '@tanstack/react-table'
import { useActiveWorkspaceId } from '../../../hooks/workspaces'
import { useTopicQuery } from '../../../utils/topics'
import { useTable } from '../../../hooks/useTable'
import Table from '../../../components/Table'
import Card from '../../../components/Card'

export function TopicKeywords({
  topicId,
  projectId,
}: {
  topicId: undefined | string
  projectId: undefined | string
}) {
  const workspaceId = useActiveWorkspaceId()
  const topicQuery = useTopicQuery({
    workspaceId,
    projectId,
    topicId,
  })

  return <TopicKeywordsTable />
}

function TopicKeywordsTable() {
  const tableData = React.useMemo(() => {
    return []
  }, [])

  const columns = React.useMemo(() => {
    const helper = createColumnHelper<any>()

    return [
      helper.display({
        header: 'Keyword',
      }),
    ]
  }, [])

  const table = useTable({
    data: tableData,
    columns,
  })

  return (
    <>
      <Card>
        <Table table={table} />
      </Card>
    </>
  )
}
