import * as React from 'react'
import useZustand from '../hooks/useZustand'

export default function useModal() {
  const [, setStore] = useZustand()

  return React.useCallback(
    (Component, { maskClose, onClose, ...rest } = {}) => {
      const modal = {
        ...rest,
        id: Date.now(),
        Component,
        close: () => {
          setStore(draft => {
            draft.modals = draft.modals.filter((d: any) => d.id !== modal.id)
          })
          if (onClose) onClose()
        },
        maskClose,
      }

      setStore(draft => {
        draft.modals = [...draft.modals, modal]
      })

      return modal
    },
    [setStore]
  )
}
