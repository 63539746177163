import * as React from 'react'
import { useForm } from 'react-form'
import { FaCheck } from 'react-icons/fa'
import { Profile } from '../../../openapi'
import Button from '../../components/Button'
import Spinner from '../../components/Spinner'
import TextField from '../../components/TextField'
import { useProfileQuery } from '../../hooks/profile'
import usePauseEvents from '../../hooks/usePauseEvents'
import Validate from '../../utils/Validate'

export default function ProfileForm({
  onSubmit,
  showUserId = true,
  paaOnboarding,
}: {
  onSubmit: (profile: Profile) => void
  showUserId?: boolean
  paaOnboarding?: boolean
}) {
  const profileQuery = useProfileQuery()

  usePauseEvents('profile', null, true)

  const {
    Form,
    meta: { isSubmitting },
  } = useForm({
    // @ts-expect-error  // Type 'Profile | undefined' is not assignable to ty... Remove this comment to see the full error message
    defaultValues: profileQuery.data,
    // @ts-expect-error  // Type '(profile: Profile) => void' is not assignabl... Remove this comment to see the full error message
    onSubmit,
  })

  return (
    <Form>
      <div className="flex flex-wrap gap-2">
        <TextField
          field="givenName"
          validate={Validate.required('First name is required')}
          label="First Name"
          placeholder="John"
          autoComplete="given-name"
          className="w-80 flex-none"
        />
        <TextField
          field="familyName"
          validate={Validate.required('Last name is required')}
          label="Last Name"
          placeholder="Smith"
          autoComplete="family-name"
          className="w-80 flex-none"
        />
      </div>
      <div className="h-2" />
      {paaOnboarding ? null : (
        <>
          <TextField
            field="displayName"
            validate={Validate.required('A display name is required')}
            label="Display Name"
            placeholder="John Smith"
            autoComplete="name"
            className="w-80 flex-none"
          />
          <div className="h-2" />
        </>
      )}
      {showUserId ? (
        <>
          <TextField
            field="id"
            label="User ID"
            placeholder="-"
            disabled
            className="w-80"
          />
          <div className="h-2" />
        </>
      ) : null}
      <TextField
        field="email"
        // validate={Validate.email()}
        label="Email"
        placeholder="johnsmith@gmail.com"
        disabled
        // autoComplete="email"
        className="w-80"
      />
      <div className="h-4" />
      <Button type="submit" size="base" color="blue-500">
        {isSubmitting ? (
          <>
            <Spinner color="white" />{' '}
            {paaOnboarding ? 'Saving' : 'Updating Profile'}
          </>
        ) : (
          <>
            <FaCheck className="inline" />{' '}
            {paaOnboarding ? 'Save' : 'Update Profile'}
          </>
        )}
      </Button>{' '}
    </Form>
  )
}
