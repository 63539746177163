import * as React from 'react'

export const modalContext = React.createContext<{
  id: string
  close: () => void
  maskClose: boolean
  active: boolean
}>(null!)

export default function useModalContext() {
  return React.useContext(modalContext)
}
