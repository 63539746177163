import * as React from 'react'
import { useColumnsQuery } from './columns'

export function useResultColumnsQuery() {
  const columnsQuery = useColumnsQuery()

  const data = React.useMemo(() => {
    return (columnsQuery.data?.columnsByStage.result ?? []).map((column: any) => ({
      ...column,
      label: column.label.replace('Result › ', '')
    }));
  }, [columnsQuery.data])

  return {
    ...columnsQuery,
    data,
  }
}

export function getResultLabel(result: any) {
  const url = result.url?.url
  const title = result.title?.text
  const shortUrl = result.url?.url?.split('://').reverse()[0]

  const tooltipLabel =
    result.title?.text || result.url?.url ? (
      <div>
        <strong>{title}</strong>
        <br />
        {url}
      </div>
    ) : undefined

  return {
    label: title || shortUrl || '',
    tooltipLabel,
  }
}
