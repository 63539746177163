import React from 'react'
import { FaAngleRight, FaCaretDown, FaInfoCircle } from 'react-icons/fa'
import { twMerge } from 'tailwind-merge'
import Card from '../../components/Card'
import Clickable, { ClickablePlain } from '../../components/Clickable'
import DashboardContainer from '../../components/DashboardContainer'
import DashboardHeader from '../../components/DashboardHeader'
import DashboardTimeRange from '../../components/DashboardTimeRange'
import { GoogleSearchConsoleChart } from '../../components/GoogleSearchConsoleChart'
import { GoogleSearchConsoleTable } from '../../components/GoogleSearchConsoleTable'
import Head from '../../components/Head'
import QueryGate from '../../components/QueryGate'
import Select from '../../components/Select'
import TablePlaceholder from '../../components/TablePlaceholder'
import Tooltip from '../../components/Tooltip'
import { useGoogleOptionQuery } from '../../hooks/useGoogleOptionsQuery'
import useTimeRangeState from '../../hooks/useTimeRanges'
import { countryOptions } from '../../options/countryOptions'
import { openHubSpotChat } from '../../utils/Analytics'
import { getDataColor } from '../../utils/DataColors'
import { useSearchConsoleSites } from '../../utils/gapi'
import { MdCircle, MdOutlineCircle } from 'react-icons/md'
import { useActiveWorkspaceId } from '../../hooks/workspaces'

export const deviceLabels = [
  {
    label: 'Desktop',
    value: 'DESKTOP',
  },
  {
    label: 'Mobile',
    value: 'MOBILE',
  },

  {
    label: 'Tablet',
    value: 'TABLET',
  },
]

const groupByOptions = [
  {
    label: 'Keyword Phrase',
    value: 'QUERY',
  },
  {
    label: 'URL',
    value: 'PAGE',
  },

  {
    label: 'Device',
    value: 'DEVICE',
  },

  {
    label: 'Search Appearance',
    value: 'SEARCH_APPEARANCE',
  },
]

export const appearanceLabels = [
  {
    label: 'Good page experience',
    value: 'PAGE_EXPERIENCE',
  },
  {
    label: 'Web Light results',
    value: 'WEBLITE',
  },

  {
    label: 'Videos',
    value: 'VIDEO',
  },
]

const metrics = [
  { label: 'Clicks', value: 'clicks' },
  { label: 'Impressions', value: 'impressions' },
  { label: 'CTR', value: 'ctr' },
  { label: 'Rank (position)', value: 'position' },
] as const

export function GoogleSearchConsole() {
  const workspaceId = useActiveWorkspaceId()!
  const searchConsoleSitesQuery = useSearchConsoleSites({
    workspaceId,
  })

  const [property, setProperty] = React.useState<undefined | string>()
  const [deviceFilter, setDeviceFilter] = React.useState<undefined | string>()
  const [countryFilter, setCountryFilter] = React.useState<undefined | string>()
  const [appearanceFilter, setAppearanceFilter] = React.useState<
    undefined | string
  >()
  const [urlFilter, setUrlFilter] = React.useState<undefined | string>()
  const [groupBy, setGroupBy] = React.useState<string>('QUERY')

  const timeRangeState = useTimeRangeState()

  const propertyOptions = React.useMemo(
    () =>
      searchConsoleSitesQuery.data?.map((site: any) => {
        return {
          label: site.siteUrl.includes('sc-domain:')
            ? `${site.siteUrl.split(':')[1]} (Domain)`
            : site.siteUrl,
          value: site.siteUrl,
        }
      }),
    [searchConsoleSitesQuery]
  )

  const countryOptionsQuery = useGoogleOptionQuery({
    option: 'COUNTRY',
    // @ts-expect-error  // Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    domain: property,
    timeRanges: timeRangeState.state[0],
    country: countryFilter,
    device: deviceFilter,
    appearance: appearanceFilter,
  })

  const deviceOptionsQuery = useGoogleOptionQuery({
    option: 'DEVICE',
    // @ts-expect-error  // Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    domain: property,
    timeRanges: timeRangeState.state[0],
    country: countryFilter,
    device: deviceFilter,
    appearance: appearanceFilter,
  })

  const appearanceOptionsQuery = useGoogleOptionQuery({
    option: 'SEARCH_APPEARANCE',
    // @ts-expect-error  // Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    domain: property,
    timeRanges: timeRangeState.state[0],
    country: countryFilter,
    device: deviceFilter,
    appearance: appearanceFilter,
  })

  const urlOptionsQuery = useGoogleOptionQuery({
    option: 'PAGE',
    // @ts-expect-error  // Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    domain: property,
    timeRanges: timeRangeState.state[0],
    country: countryFilter,
    device: deviceFilter,
    appearance: appearanceFilter,
  })

  React.useEffect(() => {
    if (propertyOptions && !property) {
      setProperty(
        propertyOptions?.find((option: any) =>
          option.value.includes('sc-domain:')
        )?.value || propertyOptions[0]?.value
      )
    }
  }, [propertyOptions, property])

  const allCountryOptions = React.useMemo(
    () =>
      countryOptionsQuery.data?.result?.rows
        ?.map((option: any) => {
          const label = countryOptions.find(
            d => d.alpha3Code === option.keys[0]
          )?.label
          if (label) {
            return {
              label,
              value: option.keys[0],
            }
          }
          return undefined
        })
        .filter((option: any) => option !== undefined) || [],
    [countryOptionsQuery.data?.result?.rows]
  )

  const alpha3CountryOptions = React.useMemo(
    () =>
      countryOptionsQuery.data
        ? [{ label: 'All Countries', value: null }, ...allCountryOptions]
        : [],
    [countryOptionsQuery.data, allCountryOptions]
  )

  const allDeviceOptions =
    deviceOptionsQuery.data?.result?.rows
      ?.map((option: any) => {
        const label = deviceLabels.find(d => d.value === option.keys[0])?.label
        if (label) {
          return {
            label,
            value: option.keys[0],
          }
        }
        return undefined
      })
      .filter((option: any) => option !== undefined) || []
  const deviceOptions = [
    { label: 'All Devices', value: null },
    ...allDeviceOptions,
  ]

  const allAppearanceOptions =
    appearanceOptionsQuery.data?.result?.rows
      ?.map((option: any) => {
        return {
          label: appearanceLabels.find(v => v.value === option.keys[0])?.label,
          value: option.keys[0],
        }
      })
      .filter((option: any) => option !== undefined) || []

  const appearanceOptions = [
    { label: 'All Appearances', value: null },
    ...allAppearanceOptions,
  ]

  const allUrlOptions =
    urlOptionsQuery.data?.result?.rows
      ?.map((option: any) => {
        return {
          label: option.keys[0],
          value: option.keys[0],
        }
      })
      .filter((option: any) => option !== undefined) || []
  const urlOptions = [{ label: 'All URLs', value: null }, ...allUrlOptions]
  const [filter, setFilter] = React.useState({
    clicks: true,
    impressions: true,
    ctr: true,
    position: true,
  })

  return (
    <DashboardContainer>
      <Head>
        <title>Google Search Console</title>
      </Head>
      {!propertyOptions?.length && !searchConsoleSitesQuery.isLoading ? (
        <Card>
          {' '}
          <div className="p-2 text-lg">
            Oh no! It looks like the login you provided to Google Search Console
            did not find any properties. Please refresh to try again or{' '}
            <Clickable onClick={() => openHubSpotChat()}>
              click here to start a new chat
            </Clickable>{' '}
            with support (or use the chat bubble at the bottom-right of the
            screen).
          </div>
        </Card>
      ) : (
        <>
          <Card className="divide-y divide-gray-500/20 p-0 ">
            <div className="p-2">
              <DashboardHeader right={<DashboardTimeRange />}>
                <div className="flex flex-wrap items-center gap-2">
                  <QueryGate query={searchConsoleSitesQuery}>
                    {() => (
                      <Select
                        options={propertyOptions}
                        value={property}
                        onChange={value => setProperty(value)}
                        className="inline-flex"
                      >
                        {({ selectedOption, onClick }: any) => (
                          <div className="inline-flex items-center gap-2 whitespace-nowrap">
                            <Tooltip tooltip={`Change Active Property`}>
                              <Clickable onClick={onClick}>
                                {selectedOption
                                  ? `${selectedOption.label}`
                                  : 'Select a property...'}
                                <FaCaretDown className="inline" />
                              </Clickable>
                            </Tooltip>
                          </div>
                        )}
                      </Select>
                    )}
                  </QueryGate>
                  <FaAngleRight className="inline" />
                  <span>Google Search Console</span>
                </div>
              </DashboardHeader>
            </div>
            <div className="p-2">
              <div className="flex flex-wrap items-start gap-2">
                <div className="flex flex-1 flex-wrap items-center gap-2  ">
                  <div className="flex flex-grow items-center gap-2">
                    <div className="pt-0.5 text-base font-bold">Country</div>
                    <Select
                      placeholder={`Select a country...`}
                      options={alpha3CountryOptions}
                      value={countryFilter}
                      onChange={value => setCountryFilter(value)}
                      className="flex-grow"
                    />
                  </div>
                  <div className="flex flex-grow items-center gap-2">
                    <div className="pt-0.5 text-base font-bold">Device</div>
                    <Select
                      placeholder={`Select a device...`}
                      options={deviceOptions}
                      value={deviceFilter}
                      onChange={value => setDeviceFilter(value)}
                      className="flex-grow"
                    />
                  </div>
                  <div className="flex flex-grow items-center gap-2">
                    <div className="whitespace-nowrap pt-0.5 text-base font-bold">
                      Search Appearance
                    </div>
                    <Select
                      placeholder={`Select a search appearance...`}
                      options={appearanceOptions}
                      value={appearanceFilter}
                      onChange={value => setAppearanceFilter(value)}
                      className="flex-grow"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap gap-4 p-2 ">
              <div className=" flex w-full items-center  gap-2">
                <div className="pt-0.5 text-base font-bold">URL</div>
                <Select
                  placeholder={`Select a URL...`}
                  options={urlOptions}
                  className="flex-grow"
                  value={urlFilter}
                  onChange={value => setUrlFilter(value)}
                />
              </div>
            </div>
          </Card>
          <Card className="divide-y divide-gray-500/20 p-0">
            <div className="m-0 flex flex-wrap items-center gap-2 p-2 text-lg font-semibold ">
              <span>History</span>

              <Tooltip
                tooltip={
                  'The latest Google Search Console data over the selected timeRange with the above filters.'
                }
              >
                <FaInfoCircle className="text-base text-gray-500 opacity-50" />
              </Tooltip>
            </div>
            <div className="h-96 w-full">
              {/* @ts-expect-error  //TODO: */}
              <GoogleSearchConsoleChart
                {...{
                  property,
                  timeRanges: timeRangeState.state[0],
                  device: deviceFilter,
                  country: countryFilter,
                  appearance: appearanceFilter,
                  url: urlFilter,
                  metrics,
                  filter,
                }}
              />
            </div>
            <div className="flex flex-wrap justify-center py-4 text-xs">
              {metrics.map((metric, i) => (
                <span className="inline-block px-1 py-0.5" key={i}>
                  <span className="flex items-center gap-1">
                    <ClickablePlain
                      onClick={() =>
                        setFilter(old => ({
                          ...old,
                          [metric.value]: !old[metric.value],
                        }))
                      }
                      className="flex items-center gap-1 text-sm"
                    >
                      <span style={{ color: getDataColor(i) }}>
                        {filter[metric.value] ? (
                          <MdCircle />
                        ) : (
                          <MdOutlineCircle />
                        )}
                      </span>
                      <span className="label">{metric.label}</span>
                    </ClickablePlain>
                  </span>
                </span>
              ))}
            </div>
            <div className="divide-x divide-gray-500/20 bg-gray-50 dark:bg-gray-900">
              {groupByOptions.map(option => {
                return (
                  <button
                    key={option.value}
                    onClick={() => {
                      setGroupBy(option.value)
                    }}
                    className={twMerge(
                      `px-3 py-2 text-base`,
                      'hover:bg-blue-500 hover:text-white',
                      option.value === groupBy
                        ? 'bg-white font-bold dark:bg-gray-800'
                        : ''
                    )}
                  >
                    By {option.label}
                  </button>
                )
              })}
            </div>
            {property ? (
              // @ts-expect-error  // Type '{ property: string; timeRanges: TimeRange | ... Remove this comment to see the full error message
              <GoogleSearchConsoleTable
                {...{
                  property,
                  timeRanges: timeRangeState.state[0],
                  device: deviceFilter,
                  country: countryFilter,
                  appearance: appearanceFilter,
                  url: urlFilter,
                  groupByOptions,
                  groupBy,
                }}
              />
            ) : (
              <TablePlaceholder
                style={{
                  borderWidth: 0,
                  borderTopWidth: 1,
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                  rows: 10,
                }}
              />
            )}
          </Card>
        </>
      )}
    </DashboardContainer>
  )
}
