import { useMatch, useNavigate } from '@tanstack/react-router'
import QueryGate from '../../components/QueryGate'
import {
  useRemoveProjectById,
  useSaveProject,
  useProject,
  useCreateProject,
} from '../../hooks/projects'
import useConfirm from '../../hooks/useConfirm'
import { ProjectForm } from './ProjectForm'
import Container from '../../components/Container'
import Card from '../../components/Card'
import { FaAngleRight } from 'react-icons/fa'
import Link from '../../components/Link'
import { Tab, Tabs } from '../../components/Tabs'

//
export function Project({ isNew }: { isNew?: boolean }) {
  const { projectId } = useMatch().params
  const projectQuery = useProject({ id: isNew ? null : projectId })
  const saveTeam = useSaveProject()
  const removeTeamById = useRemoveProjectById()
  const confirm = useConfirm()
  const navigate = useNavigate()

  const createProject = useCreateProject()

  const onSubmit = async (values: any) => {
    const newTeam = isNew ? await createProject(values) : await saveTeam(values)

    if (isNew) {
      navigate({ to: `/projects/${newTeam.id}` })
    }
  }

  const onRemove = async (id: string) => {
    const confirmed = await confirm({
      color: 'red-500',
      message: 'This Project will be deleted!',
    })

    if (confirmed) {
      await removeTeamById(id)
      navigate({ to: '/' })
    }
  }

  return (
    <Container className="space-y-2">
      <Card className="flex items-center gap-2">
        <Link to="/projects" className="pl-2 text-xl font-bold">
          Projects
        </Link>
        <FaAngleRight />
        <div className="text-xl font-bold">
          {isNew ? 'New Project' : projectQuery.data?.name ?? ''}
        </div>
      </Card>
      <Tabs>
        <Link>
          <Tab className="active">Project Info</Tab>
        </Link>
        <Link
          to="/keyword-manager"
          search={prev => ({ ...prev, projectId: projectQuery.data?.id })}
        >
          <Tab>Manage Keywords</Tab>
        </Link>
        <Link
          to="/brands"
          search={prev => ({ ...prev, projectId: projectQuery.data?.id })}
        >
          <Tab>Brands</Tab>
        </Link>
        <Link
          to="/segments"
          search={prev => ({ ...prev, projectId: projectQuery.data?.id })}
        >
          <Tab>Segments</Tab>
        </Link>
      </Tabs>
      <Card>
        {isNew ? (
          <ProjectForm
            {...{
              onSubmit,
            }}
          />
        ) : (
          <QueryGate query={projectQuery}>
            {() => {
              return (
                <ProjectForm
                  {...{
                    project: projectQuery.data,
                    onSubmit,
                    onRemove: () => onRemove(projectQuery.data!.id),
                  }}
                />
              )
            }}
          </QueryGate>
        )}
      </Card>
    </Container>
  )
}
