import * as React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  MappedKeywordPb,
  TopicPb,
  TopicViewOptionsPb,
  TopicViewPb,
  TopicsClient,
} from './proto'
import useToast from '../hooks/useToast'
import useErrorPopup from '../hooks/useErrorPopup'
import MeiliSearch, { Index, SearchParams } from 'meilisearch'
import { getAccessToken } from '../utils/Api'
import { useActiveWorkspaceId } from '../hooks/workspaces'
import { nozzleCacheClient } from './nozzleCacheClient'

export const TOPIC_KEY = 'topics'

export type MeiliSearchTopic = {
  ancestors: string[]
  created_at: number
  description: string
  labels: null
  mapped_keyword_ids: string[]
  mapped_urls_by_brand: any[]
  name: string
  parent_topic_id: string
  priority: number
  project_id: string
  topic_id: string
  updated_at: number
  version_id: string
  workspace_id: string
  workspace_id__project_id__topic_id: string
}

export async function getTopicsSearchIndex({
  workspaceId,
}: {
  workspaceId: string
}) {
  const token = await getAccessToken()
  const client = new MeiliSearch({
    host: 'https://api.nozzle.io/meilisearch/topics',
    apiKey: token,
    requestConfig: {
      headers: {
        'nz-workspace-id': workspaceId,
      },
    },
  })

  return client.index<MeiliSearchTopic>('topics')
}

export async function getTopicsSearch({
  workspaceId,
  search,
  options,
}: {
  workspaceId: string
  search: string
  options?: SearchParams
}) {
  const index = await getTopicsSearchIndex({
    workspaceId,
  })

  return index.search<MeiliSearchTopic>(search, options)
}

export function useTopicsSearchIndex() {
  const activeWorkspaceId = useActiveWorkspaceId()

  const [searchIndex, setSearchIndex] = React.useState<
    undefined | Index<MeiliSearchTopic>
  >()

  const nearest5Minute = Math.floor(Date.now() / 300000) * 300000

  React.useEffect(() => {
    getTopicsSearchIndex({
      workspaceId: activeWorkspaceId,
    }).then(d => {
      setSearchIndex(d)
    })
  }, [nearest5Minute, activeWorkspaceId])

  return searchIndex
}

export type FetchTopicsOptions = {
  workspaceId: string
  projectId: string
  descendantDepth?: number
  rootOnly?: boolean
}

export function useTopicsQuery(opts: FetchTopicsOptions) {
  return useQuery({
    queryKey: [TOPIC_KEY, opts],
    queryFn: () =>
      TopicsClient.listTopics({
        workspaceId: BigInt(opts.workspaceId),
        projectId: BigInt(opts.projectId),
        view: TopicViewPb.BASIC,
        rootOnly: opts.rootOnly ?? false,
        viewOptions: new TopicViewOptionsPb({
          view: TopicViewPb.BASIC,
          descendentDepth: opts.descendantDepth ?? 0,
        }),
      }),
    enabled: !!opts.workspaceId && !!opts.projectId,
  })
}

export type FetchTopicOptions = {
  workspaceId: string
  projectId: string
  topicId: string
}

export function fetchTopicById(opts: FetchTopicOptions) {
  return TopicsClient.getTopic({
    workspaceId: BigInt(opts.workspaceId),
    projectId: BigInt(opts.projectId),
    view: TopicViewPb.BASIC,
    topicId: BigInt(opts.topicId),
    viewOptions: new TopicViewOptionsPb({
      ancestorDepth: -1,
    }),
  })
}

export function useTopicQuery(opts: Partial<FetchTopicOptions>) {
  return useQuery({
    queryKey: [TOPIC_KEY, opts.topicId, opts],
    queryFn: () => fetchTopicById(opts as FetchTopicOptions),
    enabled: !!opts.workspaceId && !!opts.projectId && !!opts.topicId,
  })
}

export function useUpdateTopicMutation() {
  const queryClient = useQueryClient()
  const toast = useToast()
  const errorPopup = useErrorPopup()

  return useMutation(TopicsClient.updateTopic, {
    onSuccess: () => {
      queryClient.invalidateQueries(TOPIC_KEY)
      toast({
        color: 'green-500',
        message: 'Topic updated',
      })
    },
    onError: err => {
      errorPopup(err)
    },
  })
}

export function useRemoveTopicByIdMutation() {
  const queryClient = useQueryClient()
  const toast = useToast()
  const errorPopup = useErrorPopup()

  return useMutation(TopicsClient.deleteTopic, {
    onSuccess: () => {
      queryClient.invalidateQueries(TOPIC_KEY)
      toast({
        color: 'green-500',
        message: 'Topic removed',
      })
    },
    onError: err => {
      errorPopup(err)
    },
  })
}

export function meilisearchTopicToTopicPb(topic: MeiliSearchTopic) {
  return new TopicPb({
    name: topic.name,
    id: BigInt(topic.topic_id),
    projectId: BigInt(topic.project_id),
    mappedKeywords: topic.mapped_keyword_ids.map(
      id =>
        new MappedKeywordPb({
          keywordId: BigInt(id),
        })
    ),
  })
}

export function useTopicsIndexQuery(opts: {
  projectId: string
  topicIds: string[]
}) {
  const activeWorkspaceId = useActiveWorkspaceId()
  const searchIndex = useTopicsSearchIndex()
  const topicIds = opts.topicIds?.filter(Boolean)

  return useQuery({
    queryKey: ['topics', topicIds],
    queryFn: async () => {
      const existing = await Promise.all(
        topicIds.map(async topicId => {
          return [
            topicId,
            (await nozzleCacheClient.get([
              'topic',
              [activeWorkspaceId, opts.projectId, topicId].join('-'),
            ])) as TopicPb,
          ] as const
        })
      )

      const shouldRequestList = existing
        .filter(([d, existing]) => !existing)
        .map(([d]) => d)

      const requested = shouldRequestList.length
        ? (
            await getTopicsSearch({
              workspaceId: activeWorkspaceId,
              search: '',
              options: {
                limit: 999999999,
                filter: `workspace_id=${activeWorkspaceId} AND project_id=${
                  opts.projectId
                } AND topic_id IN [${shouldRequestList.join(',')}]`,
              },
            })
          ).hits
        : []

      const result = await Promise.all(
        existing.map(async ([d, existing], i) => {
          if (existing) {
            return existing
          }

          const found = meilisearchTopicToTopicPb(
            requested.find(dd => dd.topic_id === d)!
          )

          if (found) {
            nozzleCacheClient.set(
              ['topic', [activeWorkspaceId, opts.projectId, d].join('-')],
              found,
              {
                ttl: Date.now() + 1000 * 60 * 5, // 5 minutes
              }
            )
          }

          return found
        })
      )

      return result
    },
    enabled: Boolean(searchIndex?.getDocument),
    keepPreviousData: true,
    retry: false,
  })
}

export function useTopicsByIdQuery(opts: {
  projectId: string
  topicIds: string[]
}) {
  const topicsQuery = useTopicsIndexQuery(opts)

  return useQuery({
    queryKey: [
      'topicsById',
      opts.topicIds,
      {
        topicsQuery: topicsQuery.dataUpdatedAt,
      },
    ],
    queryFn: () => {
      const byId: Record<string, TopicPb> = {}

      topicsQuery.data?.forEach(topic => {
        if (topic) {
          byId[topic.id.toString()] = topic
        }
      })

      return byId
    },
    staleTime: Infinity,
    cacheTime: 0,
    enabled: topicsQuery.isSuccess,
  })
}

export function useTopicSearchQuery(options: {
  search: string
  projectId: string
  filter?: string
  limit?: number
}) {
  const workspaceId = useActiveWorkspaceId()

  return useQuery(
    ['topic-search', options],
    async () => {
      return TopicsClient.listTopics({
        workspaceId: BigInt(workspaceId),
        projectId: BigInt(options.projectId),
        // search: options.search || '',
        // options: {
        //   filter: [`project_id=${options.projectId}`, options.filter]
        //     .filter(Boolean)
        //     .join(' AND '),
        //   limit: options.limit ?? 100,
        // },
      }).then(d => d.topics)
    },
    {
      enabled: Boolean(workspaceId && options.projectId),
      keepPreviousData: true,
    }
  )
}

export function useBatchUpdateTopicsMutation() {
  const queryClient = useQueryClient()
  const toast = useToast()
  const errorPopup = useErrorPopup()

  return useMutation(TopicsClient.batchUpdateTopics, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(TOPIC_KEY)
      toast({
        color: 'green-500',
        message: 'Topics updated',
      })
    },
    onError: err => {
      errorPopup(err)
    },
  })
}
export function useBatchRemoveTopicsByIdMutation() {
  const queryClient = useQueryClient()
  const toast = useToast()
  const errorPopup = useErrorPopup()

  return useMutation(TopicsClient.batchDeleteTopics, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(TOPIC_KEY)
      toast({
        color: 'green-500',
        message: 'Topics removed',
      })
    },
    onError: err => {
      errorPopup(err)
    },
  })
}

export function useMergeWithTopicIdMutation() {
  const activeWorkspaceId = useActiveWorkspaceId()
  const queryClient = useQueryClient()
  const toast = useToast()
  const errorPopup = useErrorPopup()

  return useMutation(
    async ({
      projectId,
      topics,
      topicId,
    }: {
      projectId: string
      topics: TopicPb[]
      topicId: string
    }) => {
      const topicRes = await fetchTopicById({
        workspaceId: activeWorkspaceId,
        projectId,
        topicId,
      })

      const topic = topicRes.topic!

      topic.mappedKeywords.push(...topics.map(d => d.mappedKeywords).flat())

      await Promise.all([
        TopicsClient.updateTopic({
          allowMissing: true,
          topic,
        }),
        TopicsClient.batchDeleteTopics({
          workspaceId: BigInt(activeWorkspaceId),
          projectId: BigInt(projectId),
          topicIds: topics.map(d => d.id),
        }),
      ])
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(TOPIC_KEY)
        toast({
          color: 'green-500',
          message: 'Topics merged into topic',
        })
      },
      onError: err => {
        errorPopup(err)
      },
    }
  )
}

export async function fetchRelatedTopics({
  workspaceId,
  projectId,
  keywordIds,
  excludeTopicIds,
}: {
  workspaceId: string
  projectId: string
  keywordIds: string[]
  excludeTopicIds?: string[]
}) {
  const index = await getTopicsSearchIndex({
    workspaceId,
  })

  return (
    await index.search('', {
      filter: `workspace_id=${workspaceId} AND project_id=${projectId} AND topic_id NOT IN [${(
        excludeTopicIds ?? []
      ).join(',')}] AND mapped_keyword_ids IN [${keywordIds.join(',')}]`,
      limit: 100000000,
    })
  ).hits
    .map(d => meilisearchTopicToTopicPb(d))
    .map(d => {
      return {
        ...d,
        commonKeywords: d.mappedKeywords.filter(mappedKeyword =>
          keywordIds.includes(String(mappedKeyword.keywordId))
        ),
      }
    })
}

export function useRelatedTopicsQuery({
  excludeTopicIds,
  keywordIds,
  projectId,
}: {
  projectId: string
  excludeTopicIds?: string[]
  keywordIds: string[]
}) {
  const workspaceId = useActiveWorkspaceId()

  return useQuery({
    queryKey: ['related-topics', keywordIds, excludeTopicIds, projectId],
    queryFn: () =>
      fetchRelatedTopics({
        workspaceId,
        projectId,
        keywordIds,
        excludeTopicIds,
      }),
  })
}

export async function deleteFromRelatedTopics({
  workspaceId,
  projectId,
  topicId,
  sourceKeywordIds,
  deleteKeywordIds,
}: {
  workspaceId: string
  projectId: string
  topicId: undefined | string
  sourceKeywordIds: string[]
  deleteKeywordIds: string[]
}) {
  const relatedTopics = await fetchRelatedTopics({
    workspaceId,
    projectId,
    keywordIds: sourceKeywordIds,
    excludeTopicIds: [topicId].filter(Boolean),
  })

  await TopicsClient.batchUpdateTopics({
    topics: relatedTopics.map(
      topic =>
        new TopicPb({
          workspaceId: BigInt(workspaceId),
          projectId: BigInt(projectId),
          id: topic.id,
          mappedKeywords: topic.mappedKeywords.filter(
            mappedKeyword =>
              !deleteKeywordIds.includes(String(mappedKeyword.keywordId))
          ),
        })
    ),
  })
}

export function useDeleteFromRelatedTopicsMutation() {
  const activeWorkspaceId = useActiveWorkspaceId()
  const queryClient = useQueryClient()
  const toast = useToast()
  const errorPopup = useErrorPopup()

  return useMutation(
    async (
      options: Omit<
        Parameters<typeof deleteFromRelatedTopics>[0],
        'workspaceId'
      >
    ) => {
      await deleteFromRelatedTopics({
        ...options,
        workspaceId: activeWorkspaceId,
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(TOPIC_KEY)
        toast({
          color: 'green-500',
          message: 'Keywords removed from related topics',
        })
      },
      onError: err => {
        errorPopup(err)
      },
    }
  )
}
