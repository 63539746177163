import * as React from 'react'

export default function useStrictEffect(fn: () => void, deps: any[]) {
  const previousRef = React.useRef<any[]>()

  const unsubRef = React.useRef<any>()

  React.useEffect(() => {
    if (deps.some((d, i) => d !== previousRef.current?.[i])) {
      unsubRef.current?.()
      unsubRef.current = fn()
    }

    previousRef.current = deps
  })

  React.useEffect(() => {
    return () => unsubRef.current?.()
  }, [])
}
