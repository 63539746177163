//
import Card from '../../components/Card'
import Container from '../../components/Container'
import WorkspaceForm from '../../components/WorkspaceForm'
import useConfirm from '../../hooks/useConfirm'
import useNavigate from '../../hooks/useNavigate'
import {
  useActiveWorkspaceId,
  useRemoveWorkspaceById,
  useSaveWorkspace,
  useWorkspace,
} from '../../hooks/workspaces'

export function Settings() {
  const saveWorkspace = useSaveWorkspace()
  const confirm = useConfirm()
  const removeWorkspaceById = useRemoveWorkspaceById()
  const navigate = useNavigate()
  const activeWorkspaceId = useActiveWorkspaceId()
  const workspaceQuery = useWorkspace({
    id: activeWorkspaceId,
  })

  const onRemove = async () => {
    const confirmed = await confirm({
      color: 'red-500',
      message: 'This Workspace will be deleted!',
    })
    if (confirmed) {
      // @ts-expect-error  // Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
      removeWorkspaceById(workspaceQuery.data?.id)
      navigate({ to: '../../' })
    }
  }

  return (
    <Container className="space-y-2">
      <Card>
        <div className="flex items-center gap-2 pl-2 text-xl font-bold">
          Workspace Settings
        </div>
      </Card>
      <Card>
        <WorkspaceForm
          {...{
            workspace: workspaceQuery.data,
            onSubmit: saveWorkspace,
            onRemove,
          }}
        />
      </Card>
    </Container>
  )
}
