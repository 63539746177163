import * as React from 'react'
import { UseQueryResult } from 'react-query'
import { Gate } from './Gate'

type Props<TData> = {
  query: UseQueryResult<TData>
  children?: React.ReactNode | (() => React.ReactNode)
  small?: boolean
  className?: string
  loader?: any
}

export default function QueryGate<TData>({
  query,
  small,
  children,
  className,
  loader,
}: Props<TData>): any {
  if (!query) {
    return null
  }

  return (
    <Gate
      {...{
        isLoading: query.isIdle || query.isLoading,
        error: query.isError ? query.error : undefined,
        onRetry: query.refetch,
        small,
        children,
        className,
        loader,
      }}
    />
  )
}
