// @generated by protoc-gen-connect-web v0.11.0 with parameter "target=ts"
// @generated from file nozzle/billing/v1/overages.proto (package nozzle.billing.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { UpdateMaxOverageRequest, UpdateMaxOverageResponse } from "./overages_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * OveragesService provides APIs for interacting with billing subscriptions.
 *
 * @generated from service nozzle.billing.v1.OveragesService
 */
export const OveragesService = {
  typeName: "nozzle.billing.v1.OveragesService",
  methods: {
    /**
     * SetMaxOverage defines maximum spend per workspace, product families and usage type. There are multiple tiers of
     * max overage that all work together. From the lowest scope to the highest, here are the tiers:
     *
     * 1. Single ProductFamily and UsageType:
     *    - Quantity: The most basic limit is to restrict the number of units of a UsageType that can
     *      be used before the workspace is blocked. This is the most common use case, and is the only
     *      tier that supports quantity limits.
     *
     * 2. Single ProductFamily:
     *    - This will set the max overage for all UsageTypes in the ProductFamily. Quantity cannot be set, as
     *      it is not possible to calculate a quantity limit across all UsageTypes.
     *
     * 3. Workspace across all ProductFamilies:
     *    - $ Amount: Like #2, but for the entire workspace. This is defined by setting both ProductFamily
     *      and UsageType to be UNSPECIFIED.
     *
     * All three tiers can be set at the same time, and the most restrictive limit will be applied. For example,
     *
     * @generated from rpc nozzle.billing.v1.OveragesService.UpdateMaxOverage
     */
    updateMaxOverage: {
      name: "UpdateMaxOverage",
      I: UpdateMaxOverageRequest,
      O: UpdateMaxOverageResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

