import * as React from 'react'
import { splitFormProps, useField } from 'react-form'
import Select from '../components/Select'
import LabelWrap from './LabelWrap'

//
type Props = {
  onChange?: (e: any) => void
  field?: string
  label?: string
  placeholder?: string
  disabled?: boolean
  options?: unknown
  validate?: (e: any) => void
  multi?: boolean
  create?: boolean
  adjusting?: boolean
  sort?: boolean
  className?: string
  id?: string
}

export default React.forwardRef(function SelectField(
  { onChange, label, ...props }: Props,
  ref
) {
  const [field, formProps, rest] = splitFormProps(props)
  const {
    setValue,
    value,
    setMeta,
    meta: { error, isTouched },
    // @ts-expect-error  // Argument of type '{ field?: string | undefined; pl... Remove this comment to see the full error message
  } = useField(field, formProps)

  const renderProps = {
    ...rest,
    value: value === undefined && props.multi ? [] : value,
    error: isTouched && error,
    onChange: (value: any) => {
      setValue(value)
      if (onChange) {
        onChange(value)
      }
    },
    onBlur: () => {
      setMeta({ isTouched: true })
    },
  }

  return (
    <LabelWrap label={label} error={isTouched && error}>
      <Select ref={ref} {...(renderProps as any)} />
    </LabelWrap>
  )
})
