export const languageOptions = [
  {
    label: 'Abkhazian',
    value: 'ab',
    // family: 'Northwest Caucasian',
    // Code_639_2T: "abk",
    // Code_639_2B: "abk",
  },
  {
    label: 'Afar',
    value: 'aa',
    // family: 'Afro-Asiatic',
    // Code_639_2T: "aar",
    // Code_639_2B: "aar",
  },
  {
    label: 'Afrikaans',
    value: 'af',
    // family: 'Indo-European',
    // Code_639_2T: "afr",
    // Code_639_2B: "afr",
  },
  {
    label: 'Akan',
    value: 'ak',
    // family: 'Niger–Congo',
    // Code_639_2T: "aka",
    // Code_639_2B: "aka",
  },
  {
    label: 'Albanian',
    value: 'sq',
    // family: 'Indo-European',
    // Code_639_2T: "sqi",
    // Code_639_2B: "alb",
  },
  {
    label: 'Amharic',
    value: 'am',
    // family: 'Afro-Asiatic',
    // Code_639_2T: "amh",
    // Code_639_2B: "amh",
  },
  {
    label: 'Arabic',
    value: 'ar',
    // family: 'Afro-Asiatic',
    // Code_639_2T: "ara",
    // Code_639_2B: "ara",
  },
  {
    label: 'Aragonese',
    value: 'an',
    // family: 'Indo-European',
    // Code_639_2T: "arg",
    // Code_639_2B: "arg",
  },
  {
    label: 'Armenian',
    value: 'hy',
    // family: 'Indo-European',
    // Code_639_2T: "hye",
    // Code_639_2B: "arm",
  },
  {
    label: 'Assamese',
    value: 'as',
    // family: 'Indo-European',
    // Code_639_2T: "asm",
    // Code_639_2B: "asm",
  },
  {
    label: 'Avaric',
    value: 'av',
    // family: 'Northeast Caucasian',
    // Code_639_2T: "ava",
    // Code_639_2B: "ava",
  },
  {
    label: 'Avestan',
    value: 'ae',
    // family: 'Indo-European',
    // Code_639_2T: "ave",
    // Code_639_2B: "ave",
  },
  {
    label: 'Aymara',
    value: 'ay',
    // family: 'Aymaran',
    // Code_639_2T: "aym",
    // Code_639_2B: "aym",
  },
  {
    label: 'Azerbaijani',
    value: 'az',
    // family: 'Turkic',
    // Code_639_2T: "aze",
    // Code_639_2B: "aze",
  },
  {
    label: 'Bambara',
    value: 'bm',
    // family: 'Niger–Congo',
    // Code_639_2T: "bam",
    // Code_639_2B: "bam",
  },
  {
    label: 'Bashkir',
    value: 'ba',
    // family: 'Turkic',
    // Code_639_2T: "bak",
    // Code_639_2B: "bak",
  },
  {
    label: 'Basque',
    value: 'eu',
    // family: 'Language isolate',
    // Code_639_2T: "eus",
    // Code_639_2B: "baq",
  },
  {
    label: 'Belarusian',
    value: 'be',
    // family: 'Indo-European',
    // Code_639_2T: "bel",
    // Code_639_2B: "bel",
  },
  {
    label: 'Bengali',
    value: 'bn',
    // family: 'Indo-European',
    // Code_639_2T: "ben",
    // Code_639_2B: "ben",
  },
  {
    label: 'Bihari languages',
    value: 'bh',
    // family: 'Indo-European',
    // Code_639_2T: "bih",
    // Code_639_2B: "bih",
  },
  {
    label: 'Bislama',
    value: 'bi',
    // family: 'Creole',
    // Code_639_2T: "bis",
    // Code_639_2B: "bis",
  },
  {
    label: 'Bosnian',
    value: 'bs',
    // family: 'Indo-European',
    // Code_639_2T: "bos",
    // Code_639_2B: "bos",
  },
  {
    label: 'Breton',
    value: 'br',
    // family: 'Indo-European',
    // Code_639_2T: "bre",
    // Code_639_2B: "bre",
  },
  {
    label: 'Bulgarian',
    value: 'bg',
    // family: 'Indo-European',
    // Code_639_2T: "bul",
    // Code_639_2B: "bul",
  },
  {
    label: 'Burmese',
    value: 'my',
    // family: 'Sino-Tibetan',
    // Code_639_2T: "mya",
    // Code_639_2B: "bur",
  },
  {
    label: 'Catalan, Valencian',
    value: 'ca',
    // family: 'Indo-European',
    // Code_639_2T: "cat",
    // Code_639_2B: "cat",
  },
  {
    label: 'Chamorro',
    value: 'ch',
    // family: 'Austronesian',
    // Code_639_2T: "cha",
    // Code_639_2B: "cha",
  },
  {
    label: 'Chechen',
    value: 'ce',
    // family: 'Northeast Caucasian',
    // Code_639_2T: "che",
    // Code_639_2B: "che",
  },
  {
    label: 'Chichewa, Chewa, Nyanja',
    value: 'ny',
    // family: 'Niger–Congo',
    // Code_639_2T: "nya",
    // Code_639_2B: "nya",
  },
  {
    label: 'Chinese',
    value: 'zh',
    // family: 'Sino-Tibetan',
    // Code_639_2T: "zho",
    // Code_639_2B: "chi",
  },
  {
    label: 'Chuvash',
    value: 'cv',
    // family: 'Turkic',
    // Code_639_2T: "chv",
    // Code_639_2B: "chv",
  },
  {
    label: 'Cornish',
    value: 'kw',
    // family: 'Indo-European',
    // Code_639_2T: "cor",
    // Code_639_2B: "cor",
  },
  {
    label: 'Corsican',
    value: 'co',
    // family: 'Indo-European',
    // Code_639_2T: "cos",
    // Code_639_2B: "cos",
  },
  {
    label: 'Cree',
    value: 'cr',
    // family: 'Algonquian',
    // Code_639_2T: "cre",
    // Code_639_2B: "cre",
  },
  {
    label: 'Croatian',
    value: 'hr',
    // family: 'Indo-European',
    // Code_639_2T: "hrv",
    // Code_639_2B: "hrv",
  },
  {
    label: 'Czech',
    value: 'cs',
    // family: 'Indo-European',
    // Code_639_2T: "ces",
    // Code_639_2B: "cze",
  },
  {
    label: 'Danish',
    value: 'da',
    // family: 'Indo-European',
    // Code_639_2T: "dan",
    // Code_639_2B: "dan",
  },
  {
    label: 'Divehi, Dhivehi, Maldivian',
    value: 'dv',
    // family: 'Indo-European',
    // Code_639_2T: "div",
    // Code_639_2B: "div",
  },
  {
    label: 'Dutch, Flemish',
    value: 'nl',
    // family: 'Indo-European',
    // Code_639_2T: "nld",
    // Code_639_2B: "dut",
  },
  {
    label: 'Dzongkha',
    value: 'dz',
    // family: 'Sino-Tibetan',
    // Code_639_2T: "dzo",
    // Code_639_2B: "dzo",
  },
  {
    label: 'English',
    value: 'en',
    // family: 'Indo-European',
    // Code_639_2T: "eng",
    // Code_639_2B: "eng",
  },
  {
    label: 'Esperanto',
    value: 'eo',
    // family: 'Constructed',
    // Code_639_2T: "epo",
    // Code_639_2B: "epo",
  },
  {
    label: 'Estonian',
    value: 'et',
    // family: 'Uralic',
    // Code_639_2T: "est",
    // Code_639_2B: "est",
  },
  {
    label: 'Ewe',
    value: 'ee',
    // family: 'Niger–Congo',
    // Code_639_2T: "ewe",
    // Code_639_2B: "ewe",
  },
  {
    label: 'Faroese',
    value: 'fo',
    // family: 'Indo-European',
    // Code_639_2T: "fao",
    // Code_639_2B: "fao",
  },
  {
    label: 'Fijian',
    value: 'fj',
    // family: 'Austronesian',
    // Code_639_2T: "fij",
    // Code_639_2B: "fij",
  },
  {
    label: 'Finnish',
    value: 'fi',
    // family: 'Uralic',
    // Code_639_2T: "fin",
    // Code_639_2B: "fin",
  },
  {
    label: 'French',
    value: 'fr',
    // family: 'Indo-European',
    // Code_639_2T: "fra",
    // Code_639_2B: "fre",
  },
  {
    label: 'Fulah',
    value: 'ff',
    // family: 'Niger–Congo',
    // Code_639_2T: "ful",
    // Code_639_2B: "ful",
  },
  {
    label: 'Galician',
    value: 'gl',
    // family: 'Indo-European',
    // Code_639_2T: "glg",
    // Code_639_2B: "glg",
  },
  {
    label: 'Georgian',
    value: 'ka',
    // family: 'Kartvelian',
    // Code_639_2T: "kat",
    // Code_639_2B: "geo",
  },
  {
    label: 'German',
    value: 'de',
    // family: 'Indo-European',
    // Code_639_2T: "deu",
    // Code_639_2B: "ger",
  },
  {
    label: 'Greek, Modern (1453-)',
    value: 'el',
    // family: 'Indo-European',
    // Code_639_2T: "ell",
    // Code_639_2B: "gre",
  },
  {
    label: 'Guarani',
    value: 'gn',
    // family: 'Tupian',
    // Code_639_2T: "grn",
    // Code_639_2B: "grn",
  },
  {
    label: 'Gujarati',
    value: 'gu',
    // family: 'Indo-European',
    // Code_639_2T: "guj",
    // Code_639_2B: "guj",
  },
  {
    label: 'Haitian, Haitian Creole',
    value: 'ht',
    // family: 'Creole',
    // Code_639_2T: "hat",
    // Code_639_2B: "hat",
  },
  {
    label: 'Hausa',
    value: 'ha',
    // family: 'Afro-Asiatic',
    // Code_639_2T: "hau",
    // Code_639_2B: "hau",
  },
  {
    label: 'Hebrew',
    value: 'he',
    // family: 'Afro-Asiatic',
    // Code_639_2T: "heb",
    // Code_639_2B: "heb",
  },
  {
    label: 'Herero',
    value: 'hz',
    // family: 'Niger–Congo',
    // Code_639_2T: "her",
    // Code_639_2B: "her",
  },
  {
    label: 'Hindi',
    value: 'hi',
    // family: 'Indo-European',
    // Code_639_2T: "hin",
    // Code_639_2B: "hin",
  },
  {
    label: 'Hiri Motu',
    value: 'ho',
    // family: 'Austronesian',
    // Code_639_2T: "hmo",
    // Code_639_2B: "hmo",
  },
  {
    label: 'Hungarian',
    value: 'hu',
    // family: 'Uralic',
    // Code_639_2T: "hun",
    // Code_639_2B: "hun",
  },
  {
    label: 'Interlingua (International Auxiliary Language Association)',
    value: 'ia',
    // family: 'Constructed',
    // Code_639_2T: "ina",
    // Code_639_2B: "ina",
  },
  {
    label: 'Indonesian',
    value: 'id',
    // family: 'Austronesian',
    // Code_639_2T: "ind",
    // Code_639_2B: "ind",
  },
  {
    label: 'Interlingue, Occidental',
    value: 'ie',
    // family: 'Constructed',
    // Code_639_2T: "ile",
    // Code_639_2B: "ile",
  },
  {
    label: 'Irish',
    value: 'ga',
    // family: 'Indo-European',
    // Code_639_2T: "gle",
    // Code_639_2B: "gle",
  },
  {
    label: 'Igbo',
    value: 'ig',
    // family: 'Niger–Congo',
    // Code_639_2T: "ibo",
    // Code_639_2B: "ibo",
  },
  {
    label: 'Inupiaq',
    value: 'ik',
    // family: 'Eskimo–Aleut',
    // Code_639_2T: "ipk",
    // Code_639_2B: "ipk",
  },
  {
    label: 'Ido',
    value: 'io',
    // family: 'Constructed',
    // Code_639_2T: "ido",
    // Code_639_2B: "ido",
  },
  {
    label: 'Icelandic',
    value: 'is',
    // family: 'Indo-European',
    // Code_639_2T: "isl",
    // Code_639_2B: "ice",
  },
  {
    label: 'Italian',
    value: 'it',
    // family: 'Indo-European',
    // Code_639_2T: "ita",
    // Code_639_2B: "ita",
  },
  {
    label: 'Inuktitut',
    value: 'iu',
    // family: 'Eskimo–Aleut',
    // Code_639_2T: "iku",
    // Code_639_2B: "iku",
  },
  {
    label: 'Japanese',
    value: 'ja',
    // family: 'Japonic',
    // Code_639_2T: "jpn",
    // Code_639_2B: "jpn",
  },
  {
    label: 'Javanese',
    value: 'jv',
    // family: 'Austronesian',
    // Code_639_2T: "jav",
    // Code_639_2B: "jav",
  },
  {
    label: 'Kalaallisut, Greenlandic',
    value: 'kl',
    // family: 'Eskimo–Aleut',
    // Code_639_2T: "kal",
    // Code_639_2B: "kal",
  },
  {
    label: 'Kannada',
    value: 'kn',
    // family: 'Dravidian',
    // Code_639_2T: "kan",
    // Code_639_2B: "kan",
  },
  {
    label: 'Kanuri',
    value: 'kr',
    // family: 'Nilo-Saharan',
    // Code_639_2T: "kau",
    // Code_639_2B: "kau",
  },
  {
    label: 'Kashmiri',
    value: 'ks',
    // family: 'Indo-European',
    // Code_639_2T: "kas",
    // Code_639_2B: "kas",
  },
  {
    label: 'Kazakh',
    value: 'kk',
    // family: 'Turkic',
    // Code_639_2T: "kaz",
    // Code_639_2B: "kaz",
  },
  {
    label: 'Central Khmer',
    value: 'km',
    // family: 'Austroasiatic',
    // Code_639_2T: "khm",
    // Code_639_2B: "khm",
  },
  {
    label: 'Kikuyu, Gikuyu',
    value: 'ki',
    // family: 'Niger–Congo',
    // Code_639_2T: "kik",
    // Code_639_2B: "kik",
  },
  {
    label: 'Kinyarwanda',
    value: 'rw',
    // family: 'Niger–Congo',
    // Code_639_2T: "kin",
    // Code_639_2B: "kin",
  },
  {
    label: 'Kirghiz, Kyrgyz',
    value: 'ky',
    // family: 'Turkic',
    // Code_639_2T: "kir",
    // Code_639_2B: "kir",
  },
  {
    label: 'Komi',
    value: 'kv',
    // family: 'Uralic',
    // Code_639_2T: "kom",
    // Code_639_2B: "kom",
  },
  {
    label: 'Kongo',
    value: 'kg',
    // family: 'Niger–Congo',
    // Code_639_2T: "kon",
    // Code_639_2B: "kon",
  },
  {
    label: 'Korean',
    value: 'ko',
    // family: 'Koreanic',
    // Code_639_2T: "kor",
    // Code_639_2B: "kor",
  },
  {
    label: 'Kurdish',
    value: 'ku',
    // family: 'Indo-European',
    // Code_639_2T: "kur",
    // Code_639_2B: "kur",
  },
  {
    label: 'Kuanyama, Kwanyama',
    value: 'kj',
    // family: 'Niger–Congo',
    // Code_639_2T: "kua",
    // Code_639_2B: "kua",
  },
  {
    label: 'Latin',
    value: 'la',
    // family: 'Indo-European',
    // Code_639_2T: "lat",
    // Code_639_2B: "lat",
  },
  {
    label: 'Luxembourgish, Letzeburgesch',
    value: 'lb',
    // family: 'Indo-European',
    // Code_639_2T: "ltz",
    // Code_639_2B: "ltz",
  },
  {
    label: 'Ganda',
    value: 'lg',
    // family: 'Niger–Congo',
    // Code_639_2T: "lug",
    // Code_639_2B: "lug",
  },
  {
    label: 'Limburgan, Limburger, Limburgish',
    value: 'li',
    // family: 'Indo-European',
    // Code_639_2T: "lim",
    // Code_639_2B: "lim",
  },
  {
    label: 'Lingala',
    value: 'ln',
    // family: 'Niger–Congo',
    // Code_639_2T: "lin",
    // Code_639_2B: "lin",
  },
  {
    label: 'Lao',
    value: 'lo',
    // family: 'Tai–Kadai',
    // Code_639_2T: "lao",
    // Code_639_2B: "lao",
  },
  {
    label: 'Lithuanian',
    value: 'lt',
    // family: 'Indo-European',
    // Code_639_2T: "lit",
    // Code_639_2B: "lit",
  },
  {
    label: 'Luba-Katanga',
    value: 'lu',
    // family: 'Niger–Congo',
    // Code_639_2T: "lub",
    // Code_639_2B: "lub",
  },
  {
    label: 'Latvian',
    value: 'lv',
    // family: 'Indo-European',
    // Code_639_2T: "lav",
    // Code_639_2B: "lav",
  },
  {
    label: 'Manx',
    value: 'gv',
    // family: 'Indo-European',
    // Code_639_2T: "glv",
    // Code_639_2B: "glv",
  },
  {
    label: 'Macedonian',
    value: 'mk',
    // family: 'Indo-European',
    // Code_639_2T: "mkd",
    // Code_639_2B: "mac",
  },
  {
    label: 'Malagasy',
    value: 'mg',
    // family: 'Austronesian',
    // Code_639_2T: "mlg",
    // Code_639_2B: "mlg",
  },
  {
    label: 'Malay',
    value: 'ms',
    // family: 'Austronesian',
    // Code_639_2T: "msa",
    // Code_639_2B: "may",
  },
  {
    label: 'Malayalam',
    value: 'ml',
    // family: 'Dravidian',
    // Code_639_2T: "mal",
    // Code_639_2B: "mal",
  },
  {
    label: 'Maltese',
    value: 'mt',
    // family: 'Afro-Asiatic',
    // Code_639_2T: "mlt",
    // Code_639_2B: "mlt",
  },
  {
    label: 'Maori',
    value: 'mi',
    // family: 'Austronesian',
    // Code_639_2T: "mri",
    // Code_639_2B: "mao",
  },
  {
    label: 'Marathi',
    value: 'mr',
    // family: 'Indo-European',
    // Code_639_2T: "mar",
    // Code_639_2B: "mar",
  },
  {
    label: 'Marshallese',
    value: 'mh',
    // family: 'Austronesian',
    // Code_639_2T: "mah",
    // Code_639_2B: "mah",
  },
  {
    label: 'Mongolian',
    value: 'mn',
    // family: 'Mongolic',
    // Code_639_2T: "mon",
    // Code_639_2B: "mon",
  },
  {
    label: 'Nauru',
    value: 'na',
    // family: 'Austronesian',
    // Code_639_2T: "nau",
    // Code_639_2B: "nau",
  },
  {
    label: 'Navajo, Navaho',
    value: 'nv',
    // family: 'Dené–Yeniseian',
    // Code_639_2T: "nav",
    // Code_639_2B: "nav",
  },
  {
    label: 'North Ndebele',
    value: 'nd',
    // family: 'Niger–Congo',
    // Code_639_2T: "nde",
    // Code_639_2B: "nde",
  },
  {
    label: 'Nepali',
    value: 'ne',
    // family: 'Indo-European',
    // Code_639_2T: "nep",
    // Code_639_2B: "nep",
  },
  {
    label: 'Ndonga',
    value: 'ng',
    // family: 'Niger–Congo',
    // Code_639_2T: "ndo",
    // Code_639_2B: "ndo",
  },
  {
    label: 'Norwegian Bokmål',
    value: 'nb',
    // family: 'Indo-European',
    // Code_639_2T: "nob",
    // Code_639_2B: "nob",
  },
  {
    label: 'Norwegian Nynorsk',
    value: 'nn',
    // family: 'Indo-European',
    // Code_639_2T: "nno",
    // Code_639_2B: "nno",
  },
  {
    label: 'Norwegian',
    value: 'no',
    // family: 'Indo-European',
    // Code_639_2T: "nor",
    // Code_639_2B: "nor",
  },
  {
    label: 'Sichuan Yi, Nuosu',
    value: 'ii',
    // family: 'Sino-Tibetan',
    // Code_639_2T: "iii",
    // Code_639_2B: "iii",
  },
  {
    label: 'South Ndebele',
    value: 'nr',
    // family: 'Niger–Congo',
    // Code_639_2T: "nbl",
    // Code_639_2B: "nbl",
  },
  {
    label: 'Occitan',
    value: 'oc',
    // family: 'Indo-European',
    // Code_639_2T: "oci",
    // Code_639_2B: "oci",
  },
  {
    label: 'Ojibwa',
    value: 'oj',
    // family: 'Algonquian',
    // Code_639_2T: "oji",
    // Code_639_2B: "oji",
  },
  {
    label:
      'Church Slavic, Old Slavonic, Church Slavonic, Old Bulgarian, Old Church Slavonic',
    // family: 'Indo-European',
    value: 'cu',
    // Code_639_2T: "chu",
    // Code_639_2B: "chu",
  },
  {
    label: 'Oromo',
    value: 'om',
    // family: 'Afro-Asiatic',
    // Code_639_2T: "orm",
    // Code_639_2B: "orm",
  },
  {
    label: 'Oriya',
    value: 'or',
    // family: 'Indo-European',
    // Code_639_2T: "ori",
    // Code_639_2B: "ori",
  },
  {
    label: 'Ossetian, Ossetic',
    value: 'os',
    // family: 'Indo-European',
    // Code_639_2T: "oss",
    // Code_639_2B: "oss",
  },
  {
    label: 'Punjabi, Panjabi',
    value: 'pa',
    // family: 'Indo-European',
    // Code_639_2T: "pan",
    // Code_639_2B: "pan",
  },
  {
    label: 'Pali',
    value: 'pi',
    // family: 'Indo-European',
    // Code_639_2T: "pli",
    // Code_639_2B: "pli",
  },
  {
    label: 'Persian',
    value: 'fa',
    // family: 'Indo-European',
    // Code_639_2T: "fas",
    // Code_639_2B: "per",
  },
  {
    label: 'Polish',
    value: 'pl',
    // family: 'Indo-European',
    // Code_639_2T: "pol",
    // Code_639_2B: "pol",
  },
  {
    label: 'Pashto, Pushto',
    value: 'ps',
    // family: 'Indo-European',
    // Code_639_2T: "pus",
    // Code_639_2B: "pus",
  },
  {
    label: 'Portuguese',
    value: 'pt',
    // family: 'Indo-European',
    // Code_639_2T: "por",
    // Code_639_2B: "por",
  },
  {
    label: 'Quechua',
    value: 'qu',
    // family: 'Quechuan',
    // Code_639_2T: "que",
    // Code_639_2B: "que",
  },
  {
    label: 'Romansh',
    value: 'rm',
    // family: 'Indo-European',
    // Code_639_2T: "roh",
    // Code_639_2B: "roh",
  },
  {
    label: 'Rundi',
    value: 'rn',
    // family: 'Niger–Congo',
    // Code_639_2T: "run",
    // Code_639_2B: "run",
  },
  {
    label: 'Romanian, Moldavian, Moldovan',
    value: 'ro',
    // family: 'Indo-European',
    // Code_639_2T: "ron",
    // Code_639_2B: "rum",
  },
  {
    label: 'Russian',
    value: 'ru',
    // family: 'Indo-European',
    // Code_639_2T: "rus",
    // Code_639_2B: "rus",
  },
  {
    label: 'Sanskrit',
    value: 'sa',
    // family: 'Indo-European',
    // Code_639_2T: "san",
    // Code_639_2B: "san",
  },
  {
    label: 'Sardinian',
    value: 'sc',
    // family: 'Indo-European',
    // Code_639_2T: "srd",
    // Code_639_2B: "srd",
  },
  {
    label: 'Sindhi',
    value: 'sd',
    // family: 'Indo-European',
    // Code_639_2T: "snd",
    // Code_639_2B: "snd",
  },
  {
    label: 'Northern Sami',
    value: 'se',
    // family: 'Uralic',
    // Code_639_2T: "sme",
    // Code_639_2B: "sme",
  },
  {
    label: 'Samoan',
    value: 'sm',
    // family: 'Austronesian',
    // Code_639_2T: "smo",
    // Code_639_2B: "smo",
  },
  {
    label: 'Sango',
    value: 'sg',
    // family: 'Creole',
    // Code_639_2T: "sag",
    // Code_639_2B: "sag",
  },
  {
    label: 'Serbian',
    value: 'sr',
    // family: 'Indo-European',
    // Code_639_2T: "srp",
    // Code_639_2B: "srp",
  },
  {
    label: 'Gaelic, Scottish Gaelic',
    value: 'gd',
    // family: 'Indo-European',
    // Code_639_2T: "gla",
    // Code_639_2B: "gla",
  },
  {
    label: 'Shona',
    value: 'sn',
    // family: 'Niger–Congo',
    // Code_639_2T: "sna",
    // Code_639_2B: "sna",
  },
  {
    label: 'Sinhala, Sinhalese',
    value: 'si',
    // family: 'Indo-European',
    // Code_639_2T: "sin",
    // Code_639_2B: "sin",
  },
  {
    label: 'Slovak',
    value: 'sk',
    // family: 'Indo-European',
    // Code_639_2T: "slk",
    // Code_639_2B: "slo",
  },
  {
    label: 'Slovenian',
    value: 'sl',
    // family: 'Indo-European',
    // Code_639_2T: "slv",
    // Code_639_2B: "slv",
  },
  {
    label: 'Somali',
    value: 'so',
    // family: 'Afro-Asiatic',
    // Code_639_2T: "som",
    // Code_639_2B: "som",
  },
  {
    label: 'Southern Sotho',
    value: 'st',
    // family: 'Niger–Congo',
    // Code_639_2T: "sot",
    // Code_639_2B: "sot",
  },
  {
    label: 'Spanish, Castilian',
    value: 'es',
    // family: 'Indo-European',
    // Code_639_2T: "spa",
    // Code_639_2B: "spa",
  },
  {
    label: 'Sundanese',
    value: 'su',
    // family: 'Austronesian',
    // Code_639_2T: "sun",
    // Code_639_2B: "sun",
  },
  {
    label: 'Swahili',
    value: 'sw',
    // family: 'Niger–Congo',
    // Code_639_2T: "swa",
    // Code_639_2B: "swa",
  },
  {
    label: 'Swati',
    value: 'ss',
    // family: 'Niger–Congo',
    // Code_639_2T: "ssw",
    // Code_639_2B: "ssw",
  },
  {
    label: 'Swedish',
    value: 'sv',
    // family: 'Indo-European',
    // Code_639_2T: "swe",
    // Code_639_2B: "swe",
  },
  {
    label: 'Tamil',
    value: 'ta',
    // family: 'Dravidian',
    // Code_639_2T: "tam",
    // Code_639_2B: "tam",
  },
  {
    label: 'Telugu',
    value: 'te',
    // family: 'Dravidian',
    // Code_639_2T: "tel",
    // Code_639_2B: "tel",
  },
  {
    label: 'Tajik',
    value: 'tg',
    // family: 'Indo-European',
    // Code_639_2T: "tgk",
    // Code_639_2B: "tgk",
  },
  {
    label: 'Thai',
    value: 'th',
    // family: 'Tai–Kadai',
    // Code_639_2T: "tha",
    // Code_639_2B: "tha",
  },
  {
    label: 'Tigrinya',
    value: 'ti',
    // family: 'Afro-Asiatic',
    // Code_639_2T: "tir",
    // Code_639_2B: "tir",
  },
  {
    label: 'Tibetan',
    value: 'bo',
    // family: 'Sino-Tibetan',
    // Code_639_2T: "bod",
    // Code_639_2B: "tib",
  },
  {
    label: 'Turkmen',
    value: 'tk',
    // family: 'Turkic',
    // Code_639_2T: "tuk",
    // Code_639_2B: "tuk",
  },
  {
    label: 'Tagalog',
    value: 'tl',
    // family: 'Austronesian',
    // Code_639_2T: "tgl",
    // Code_639_2B: "tgl",
  },
  {
    label: 'Tswana',
    value: 'tn',
    // family: 'Niger–Congo',
    // Code_639_2T: "tsn",
    // Code_639_2B: "tsn",
  },
  {
    label: 'Tonga (Tonga Islands)',
    value: 'to',
    // family: 'Austronesian',
    // Code_639_2T: "ton",
    // Code_639_2B: "ton",
  },
  {
    label: 'Turkish',
    value: 'tr',
    // family: 'Turkic',
    // Code_639_2T: "tur",
    // Code_639_2B: "tur",
  },
  {
    label: 'Tsonga',
    value: 'ts',
    // family: 'Niger–Congo',
    // Code_639_2T: "tso",
    // Code_639_2B: "tso",
  },
  {
    label: 'Tatar',
    value: 'tt',
    // family: 'Turkic',
    // Code_639_2T: "tat",
    // Code_639_2B: "tat",
  },
  {
    label: 'Twi',
    value: 'cx',
    // family: 'Niger–Congo',
    // Code_639_2T: "twi",
    // Code_639_2B: "twi",
  },
  {
    label: 'Tahitian',
    value: 'ty',
    // family: 'Austronesian',
    // Code_639_2T: "tah",
    // Code_639_2B: "tah",
  },
  {
    label: 'Uighur, Uyghur',
    value: 'ug',
    // family: 'Turkic',
    // Code_639_2T: "uig",
    // Code_639_2B: "uig",
  },
  {
    label: 'Ukrainian',
    value: 'uk',
    // family: 'Indo-European',
    // Code_639_2T: "ukr",
    // Code_639_2B: "ukr",
  },
  {
    label: 'Urdu',
    value: 'ur',
    // family: 'Indo-European',
    // Code_639_2T: "urd",
    // Code_639_2B: "urd",
  },
  {
    label: 'Uzbek',
    value: 'uz',
    // family: 'Turkic',
    // Code_639_2T: "uzb",
    // Code_639_2B: "uzb",
  },
  {
    label: 'Venda',
    value: 've',
    // family: 'Niger–Congo',
    // Code_639_2T: "ven",
    // Code_639_2B: "ven",
  },
  {
    label: 'Vietnamese',
    value: 'vi',
    // family: 'Austroasiatic',
    // Code_639_2T: "vie",
    // Code_639_2B: "vie",
  },
  {
    label: 'Volapük',
    value: 'vo',
    // family: 'Constructed',
    // Code_639_2T: "vol",
    // Code_639_2B: "vol",
  },
  {
    label: 'Walloon',
    value: 'wa',
    // family: 'Indo-European',
    // Code_639_2T: "wln",
    // Code_639_2B: "wln",
  },
  {
    label: 'Welsh',
    value: 'cy',
    // family: 'Indo-European',
    // Code_639_2T: "cym",
    // Code_639_2B: "wel",
  },
  {
    label: 'Wolof',
    value: 'wo',
    // family: 'Niger–Congo',
    // Code_639_2T: "wol",
    // Code_639_2B: "wol",
  },
  {
    label: 'Western Frisian',
    value: 'fy',
    // family: 'Indo-European',
    // Code_639_2T: "fry",
    // Code_639_2B: "fry",
  },
  {
    label: 'Xhosa',
    value: 'xh',
    // family: 'Niger–Congo',
    // Code_639_2T: "xho",
    // Code_639_2B: "xho",
  },
  {
    label: 'Yiddish',
    value: 'yi',
    // family: 'Indo-European',
    // Code_639_2T: "yid",
    // Code_639_2B: "yid",
  },
  {
    label: 'Yoruba',
    value: 'yo',
    // family: 'Niger–Congo',
    // Code_639_2T: "yor",
    // Code_639_2B: "yor",
  },
  {
    label: 'Zhuang, Chuang',
    value: 'za',
    // family: 'Tai–Kadai',
    // Code_639_2T: "zha",
    // Code_639_2B: "zha",
  },
  {
    label: 'Zulu',
    value: 'zu',
    // family: 'Niger–Congo',
    // Code_639_2T: "zul",
    // Code_639_2B: "zul",
  },
]
