import * as React from 'react'
import { UseQueryOptions, useQuery, useQueryClient } from 'react-query'
import {
  GroupByOverTimeQueryOptions,
  postReportsGroupByOverTimeExport as getExportGroupByOverTime,
  postReportsGroupByOverTime,
} from '../utils/Api'
import { queryKeyQueryTimeSeries } from '../utils/Constants'
import { getDataColor } from '../utils/DataColors'
import { allQueryFacets } from '../utils/queryFacets'

//
import { useCreateJob } from './jobs'
import { useGetProject } from './projects'
import useGetLatest from './useGetLatest'
import useToast from './useToast'

export default function useGroupByOverTimeQuery(
  options: GroupByOverTimeQueryOptions,
  config?: { enabled?: boolean; staleTime?: number }
) {
  const { enabled = true, ...restOptions } = config ?? {}
  // const lagHash = hashForLag(options)
  // const getPreviousLagHash = useGetPrevious(lagHash)
  // const previousLagHash = getPreviousLagHash()
  // const keepPreviousData = previousLagHash === lagHash
  const keepPreviousData = true

  const query = useQuery(
    [queryKeyQueryTimeSeries, options],
    () => postReportsGroupByOverTime(options),
    {
      staleTime: 1000 * 60 * 5,
      refetchOnWindowFocus: false,
      retry: 3,
      keepPreviousData,
      enabled: Boolean(options.teamId && enabled),
      ...restOptions,
    }
  )

  const orderBy = options.orderBy[0]
  const newPayload = {
    ...options,
    orderBy: [
      {
        ...orderBy,
        offset: orderBy.offset + orderBy.limit,
      },
    ],
  }

  // @ts-expect-error  // Argument of type '{ orderBy: { offset: number; met... Remove this comment to see the full error message
  const prefetchNextPage = usePrefetchGroupByOverTimeQuery(newPayload)

  React.useEffect(() => {
    if (
      query.data &&
      (orderBy.offset + orderBy.limit < query.data?.rowCount ?? 0)
    ) {
      prefetchNextPage()
    }
  }, [orderBy.limit, orderBy.offset, prefetchNextPage, query.data])

  const seriesData = query.data?.series

  const series = React.useMemo(
    () =>
      query.isSuccess
        ? seriesData.map((serie: any, i: number) => {
            return {
              ...serie,
              color: getDataColor(i),
            }
          })
        : undefined,
    [query.isSuccess, seriesData]
  )

  return {
    ...query,
    data: {
      ...query.data,
      series,
    } as typeof query.data,
  } as typeof query
}

export function usePrefetchGroupByOverTimeQuery(
  options: GroupByOverTimeQueryOptions,
  config?: UseQueryOptions
) {
  const queryClient = useQueryClient()
  const getOptions = useGetLatest(options)
  const getConfig = useGetLatest(config)

  return React.useCallback(() => {
    queryClient.prefetchQuery({
      queryKey: [queryKeyQueryTimeSeries, getOptions()],
      queryFn: () => postReportsGroupByOverTime(getOptions()),
      staleTime: 1000 * 60 * 5,
      retry: 0,
      ...getConfig(),
    })
  }, [getConfig, getOptions, queryClient])
}

export function useDownloadGroupByOverTimeQuery(
  options?: GroupByOverTimeQueryOptions
) {
  const getTeam = useGetProject()
  const createJob = useCreateJob()
  const getOptions = useGetLatest(options)
  const toast = useToast()

  return React.useCallback(async () => {
    try {
      toast({
        color: 'blue-500',
        message: `Your data export is processing and will be ready shortly. We will notify you here when it's ready!`,
      })

      // @ts-expect-error  // Argument of type '{ export: "csv"; workspaceId?: n... Remove this comment to see the full error message
      const jobInfo = await getExportGroupByOverTime({
        ...getOptions(),
        export: 'csv',
      })

      // @ts-expect-error  // Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
      const team = await getTeam({ id: options?.teamId })

      const rollupFacet = allQueryFacets.find(
        d => d.rollupId === jobInfo.rollupBy
      )!

      const groupByFacet = allQueryFacets.find(
        d => d.rollupId === jobInfo.groupBy
      )!

      createJob({
        ...jobInfo,
        name: `Export - ${team.name} - ${rollupFacet.label} Metrics by ${groupByFacet.label} (${jobInfo.rowCount} rows)`,
      })
    } catch (e) {
      await new Promise(resolve => setTimeout(resolve, 3000))
      console.error(e)
      toast({
        color: 'red-500',
        message: `There was an error exporting your data. Please contact support or try again later.`,
      })
    }
  }, [createJob, getOptions, getTeam, options?.teamId, toast])
}

// function hashForLag({
//   groupBy,
//   orderBy,
// }: Pick<GroupByOverTimeQueryOptions, 'groupBy' | 'orderBy'>) {
//   return [
//     groupBy,
//     orderBy?.[0]?.limit,
//     orderBy?.[0]?.offset,
//     orderBy?.[0]?.desc,
//     orderBy?.[0]?.desc,
//     JSON.stringify(orderBy?.[0], (key, value) => {
//       if (['limit', 'offset', 'desc', 'postAggregation'].includes(key)) {
//         return undefined
//       }
//       return value
//     }),
//   ].join('_')
// }
