import { css, cx } from '@emotion/css'
import * as React from 'react'
import { FaInfo, FaQuestion } from 'react-icons/fa'
import { twMerge } from 'tailwind-merge'
import Button from '../components/Button'
import useZustand from '../hooks/useZustand'
import { getTextColor } from '../utils'
import { colorify } from '../utils/tailwind'
import { ButtonPlain } from './ButtonPlain'

export default function Popups() {
  const [popups] = useZustand(state => state.popups)

  return (
    <div className="pointer-events-none absolute inset-0 z-[20000]">
      <div className="popups">
        {popups.map((popupWithColor: any) => {
          const [{ lightColor, darkColor }, popup] = colorify({
            color: 'gray-500',
            ...popupWithColor,
          })
          return (
            <div key={popup.id} className="absolute inset-0">
              <div
                className={twMerge(
                  `absolute inset-0 bg-black bg-opacity-20`
                  // css({
                  //   backdropFilter: 'blur(4px)',
                  // })
                )}
              />
              <div className="pointer-events-auto absolute inset-0 flex items-center justify-center">
                <div className="relative max-h-[90%] w-[500px] max-w-[90%]">
                  <div
                    className="absolute left-0 top-0 flex h-7 w-7 items-center
                      justify-center rounded-br-xl rounded-tl-2xl
                      bg-gray-100 text-xs text-blue-500 dark:bg-gray-800 dark:text-white
                      "
                  >
                    {popup.isConfirm ? <FaQuestion /> : <FaInfo />}
                  </div>
                  <div
                    className="overflow-y-auto overflow-x-hidden rounded-2xl
                      bg-white p-4
                shadow-2xl dark:border dark:border-gray-800 dark:bg-gray-900"
                  >
                    {popup.title && (
                      <div className="text-center text-2xl">
                        <span>{popup.title}</span>
                      </div>
                    )}
                    {popup.message && (
                      <div className="text-center text-base">
                        <div className="h-4" />
                        <span>{popup.message}</span>
                      </div>
                    )}
                    {popup.subMessage && (
                      <div className="text-center text-sm">
                        <div className="h-4" />
                        <div>{popup.subMessage}</div>
                      </div>
                    )}
                    <div className="h-4" />
                    <div className="flex flex-wrap items-center justify-center gap-2">
                      {popup.isConfirm ? (
                        <>
                          <ButtonPlain
                            className="hover:red-500 bg-gray-500"
                            onClick={popup.cancel}
                            autoFocus
                          >
                            <span>{popup.cancelText}</span>
                          </ButtonPlain>{' '}
                          <ButtonPlain
                            className="bg-green-500"
                            onClick={popup.confirm}
                          >
                            <span>{popup.confirmText}</span>
                          </ButtonPlain>
                        </>
                      ) : (
                        <ButtonPlain
                          className="bg-blue-500"
                          onClick={popup.confirm}
                        >
                          <span>{popup.confirmText}</span>
                        </ButtonPlain>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
