import { useMatch } from '@tanstack/react-router'
import * as React from 'react'
import { useForm } from 'react-form'
import { FaCheck } from 'react-icons/fa'
import Button from '../../components/Button'
import QueryGate from '../../components/QueryGate'
import Spinner from '../../components/Spinner'
import TextField from '../../components/TextField'
import { useApiToken, useSaveApiToken } from '../../hooks/apiTokens'
import usePauseEvents from '../../hooks/usePauseEvents'
import { ApiToken as ApiTokenType } from '../../utils/Api'
import Card from '../../components/Card'

//

export function ApiToken({ isNew = false }) {
  const {
    params: { tokenId },
  } = useMatch()

  const apiTokenQuery = useApiToken({
    id: isNew ? null : Number(tokenId),
  })

  const { data: token } = apiTokenQuery

  const saveTokenMutation = useSaveApiToken()

  usePauseEvents('token', token ? token.id : null, !!token)

  const defaultValues = React.useMemo<ApiTokenType>(
    () =>
      token || {
        id: 0,
        name: '',
        prefix: '',
        workspaceId: 0,
      },
    [token]
  )

  const {
    Form,
    meta: { isSubmitting },
  } = useForm({
    defaultValues,
    onSubmit: saveTokenMutation.mutateAsync,
  })

  const renderForm = () => (
    <Form>
      <TextField
        field="name"
        label="Token Name"
        placeholder="Token"
        autoFocus
      />
      <div className="h-2" />
      {token ? (
        <>
          <TextField field="id" label="Token ID" placeholder="-" disabled />
          <div className="h-2" />
        </>
      ) : null}
      <TextField field="key" label="Key" placeholder="Key" disabled />
      <div className="h-4" />
      <Button type="submit" size="base" color="green-500">
        {isSubmitting ? (
          <span>
            <Spinner color="white" /> Saving API Token
          </span>
        ) : isNew ? (
          <span>
            <FaCheck className="inline" /> Create API Token
          </span>
        ) : (
          <span>
            <FaCheck className="inline" /> Save API Token
          </span>
        )}
      </Button>{' '}
    </Form>
  )

  return (
    <Card>
      {isNew ? (
        renderForm()
      ) : (
        <QueryGate query={apiTokenQuery}>{renderForm}</QueryGate>
      )}
    </Card>
  )
}
