import * as React from 'react'
import { Updater, deepDiff, functionalUpdate } from '../utils'
import useStableCallback from './useStableCallback'

export function useDraftState<T>(opts: {
  value: T
  onSubmit?: (value: T) => void
}) {
  const [draft, setDraft_] = React.useState(opts.value)

  const isDirty = React.useMemo(
    () => deepDiff(opts.value, draft),
    [draft, opts.value]
  )

  const setDraft = React.useCallback(
    (updater: Updater<T>, submit?: boolean) => {
      if (submit) {
        opts.onSubmit?.(functionalUpdate(updater, opts.value))
      } else {
        setDraft_(updater)
      }
    },
    [opts]
  )

  const submit = useStableCallback(() => opts.onSubmit?.(draft))

  const reset = React.useCallback(() => setDraft_(opts.value), [opts.value])

  React.useEffect(() => {
    reset()
  }, [reset])

  return { draft, setDraft, submit, reset, isDirty }
}
