// @generated by protoc-gen-connect-web v0.11.0 with parameter "target=ts"
// @generated from file nozzle/billing/v1/invoice.proto (package nozzle.billing.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetInvoicePdfRequest, GetInvoicePdfResponse, GetInvoiceRequest, GetInvoiceResponse, ListInvoicesRequest, ListInvoicesResponse } from "./invoice_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * InvoicesService provides APIs for interacting with billing invoices.
 *
 * @generated from service nozzle.billing.v1.InvoicesService
 */
export const InvoicesService = {
  typeName: "nozzle.billing.v1.InvoicesService",
  methods: {
    /**
     * ListInvoices retrieves all the default invoices.
     *
     * @generated from rpc nozzle.billing.v1.InvoicesService.ListInvoices
     */
    listInvoices: {
      name: "ListInvoices",
      I: ListInvoicesRequest,
      O: ListInvoicesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetInvoice retrieves all the default invoices.
     *
     * @generated from rpc nozzle.billing.v1.InvoicesService.GetInvoice
     */
    getInvoice: {
      name: "GetInvoice",
      I: GetInvoiceRequest,
      O: GetInvoiceResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetInvoicePdf returns a PDF URL for the given invoice. The returned URL is only
     * valid for 60 minutes. This is not suitable for pre-generating links for a list of
     * invoices, so only call this dynamically when the user requests a PDF or if they
     * are on a single invoice view.
     *
     * @generated from rpc nozzle.billing.v1.InvoicesService.GetInvoicePdf
     */
    getInvoicePdf: {
      name: "GetInvoicePdf",
      I: GetInvoicePdfRequest,
      O: GetInvoicePdfResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

