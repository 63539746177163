import { twMerge } from 'tailwind-merge'
import { FaExpand } from 'react-icons/fa'
import { Updater } from '../utils'
import Tooltip from './Tooltip'
import { ButtonPlain } from './ButtonPlain'

export type ExpandOptionsState = {
  expanded: boolean
}

export function ExpandOptions<TState extends ExpandOptionsState>({
  state,
  setState,
}: {
  state: ExpandOptionsState
  setState: (updater: Updater<TState>) => void
}) {
  return (
    <Tooltip
      tooltip={state.expanded ? 'Minimize' : 'Expand'}
      className="flex items-center"
    >
      <ButtonPlain
        className={twMerge(
          'bg-gray-500/10',
          'hover:bg-blue-500 hover:text-white',
          state.expanded && 'bg-gray-500/30'
        )}
        onClick={() =>
          setState(old => ({
            ...old,
            expanded: !old.expanded,
          }))
        }
      >
        <FaExpand />
      </ButtonPlain>
    </Tooltip>
  )
}
