import * as React from 'react'
import { splitFormProps, useField } from 'react-form'
import LabeledToggle from '../components/LabeledToggle'

//

export default React.forwardRef(function ToggleField(
  // @ts-expect-error  // Property 'onChange' does not exist on type '{}'.
  { onChange, ...props },
  ref
) {
  const [field, formProps, rest] = splitFormProps(props)

  const {
    setValue,
    value,
    setMeta,
    meta: { error, isTouched, isValidating },
  } = useField(field, formProps)

  return (
    <LabeledToggle
      ref={ref}
      // @ts-expect-error  // Type '{ ref: Ref<unknown>; onChange: (value: any) ... Remove this comment to see the full error message
      onChange={(value: any) => {
        setValue(value)
        if (onChange) {
          onChange(value)
        }
      }}
      onBlur={() => setMeta({ isTouched: true })}
      value={value}
      error={isTouched && error}
      isValidating={isValidating}
      {...rest}
    />
  )
})
