import * as React from 'react'
import { FaCheckCircle } from 'react-icons/fa'
import { twMerge } from 'tailwind-merge'
import { usePublicPlansQuery } from '../hooks/plans'
import { multiSortBy } from '../utils'
import { formatNumber } from '../utils/Format'
import { moneyToFloat } from '../utils/money'
import { PeriodPb, ProductFamilyPb } from '../utils/proto'
import Button from './Button'
import Card from './Card'
import Loader from './Loader'

const enterprisePlan = {
  name: 'Enterprise',
  periodPlans: [],
  planId: '',
  productFamily: ProductFamilyPb.STANDARD,
}

export const PricingCards = React.forwardRef(
  (_props: any, ref: React.Ref<HTMLDivElement>) => {
    // const search = useSearch() as {
    //   disableHubspot?: boolean
    // }

    const [period, setPeriod] = React.useState(PeriodPb.YEARLY)
    const [category, setCategory] = React.useState<'smb' | 'enterprise'>('smb')

    const plansQuery = usePublicPlansQuery()

    const sortedPlans = React.useMemo(
      () => [
        ...multiSortBy(
          [...(plansQuery.data?.plans ?? [])],
          [
            d =>
              moneyToFloat(
                d.periodPlans?.find(d => d.basePeriod === PeriodPb.YEARLY)
                  ?.basePrice
              ),
          ]
        ),
        enterprisePlan,
      ],
      [plansQuery.data?.plans]
    )

    const visiblePlans = React.useMemo(
      () =>
        category === 'smb' ? sortedPlans.slice(0, 4) : sortedPlans.slice(4),
      [sortedPlans, category]
    )

    return plansQuery.isLoading ? (
      <div className="flex h-64 items-center justify-center">
        <Loader className="text-4xl" />
      </div>
    ) : (
      <div className="space-y-6 pb-4" ref={ref}>
        <div className="mx-auto flex max-w-[600px] flex-wrap justify-around gap-6">
          <div className="flex gap-2">
            <Button
              size="lg"
              color={period === PeriodPb.MONTHLY ? 'blue-500' : 'gray-200'}
              hoverColor="blue-600"
              onClick={() => setPeriod(PeriodPb.MONTHLY)}
            >
              Monthly
            </Button>
            <Button
              size="lg"
              color={period === PeriodPb.YEARLY ? 'blue-500' : 'gray-200'}
              hoverColor="blue-600"
              onClick={() => setPeriod(PeriodPb.YEARLY)}
            >
              Yearly
            </Button>
          </div>
          <div className="flex gap-2">
            <Button
              size="lg"
              color={category === 'smb' ? 'blue-500' : 'gray-200'}
              hoverColor="blue-600"
              onClick={() => setCategory('smb')}
            >
              SMB
            </Button>
            <Button
              size="lg"
              color={category === 'enterprise' ? 'blue-500' : 'gray-200'}
              hoverColor="blue-600"
              onClick={() => setCategory('enterprise')}
            >
              Enterprise
            </Button>
          </div>
        </div>
        <div className="mx-auto grid max-w-[1400px] gap-2 sm:grid-cols-2 md:grid-cols-4 md:gap-0">
          {visiblePlans.map((plan, i) => {
            const periodPlan = plan.periodPlans?.find(
              d => d.basePeriod === period
            )

            return (
              <Card
                className={twMerge(
                  'flex flex-1 flex-col items-center overflow-hidden rounded-lg p-0 text-white shadow-lg',
                  i === 1
                    ? 'z-30 bg-blue-700'
                    : i === 3
                    ? 'z-10 bg-blue-500 md:my-8'
                    : 'z-20 bg-blue-600 md:my-4',
                  i === 0
                    ? 'md:rounded-r-none'
                    : [2, 3].includes(i)
                    ? 'md:rounded-l-none'
                    : ''
                )}
              >
                <div
                  className={twMerge(
                    'p-6 text-2xl font-bold',
                    i === 1 ? 'md:pb-8' : i !== 3 ? 'md:pb-4' : ''
                  )}
                >
                  {plan.name}
                </div>
                <div className="flex flex-col items-center gap-1 p-4">
                  <div className="flex items-end gap-1 p-2">
                    <div className="text-4xl">
                      $
                      {formatNumber(
                        moneyToFloat(periodPlan?.basePrice) /
                          (period === PeriodPb.YEARLY ? 12 : 1)
                      )}
                    </div>
                    <div>/ mo</div>
                  </div>
                  <div className="text-sm opacity-50">
                    Billed {period === PeriodPb.YEARLY ? 'Yearly' : 'Monthly'}
                  </div>
                  <div className="font-bold opacity-70">
                    {formatNumber(
                      Number(periodPlan?.meteredPlans[0]?.includedQuantity)
                    )}{' '}
                    SERPs Included
                  </div>
                </div>
                <div className="flex flex-col items-center gap-1 p-4">
                  <div className="text-sm opacity-50">Overage Pricing:</div>
                  <div className="font-bold opacity-70">
                    $
                    {formatNumber(
                      moneyToFloat(periodPlan?.meteredPlans[0]?.unitPrice) *
                        1000,
                      { precision: 2 }
                    )}{' '}
                    per 1,000 SERPs
                  </div>
                </div>
                <div className="flex items-center gap-2 p-4">
                  <FaCheckCircle /> All Features Included
                </div>
                <a
                  target="_parent"
                  href={`https://app.nozzle.io/sign-up?planId=${
                    plan.planId
                  }&period=${
                    period === PeriodPb.YEARLY ? 'yearly' : 'monthly'
                  }`}
                  className={twMerge(
                    'mt-auto block w-full p-3 text-center text-xl',
                    i === 1
                      ? 'bg-green-500 bg-opacity-90 hover:bg-green-600'
                      : i === 3
                      ? 'bg-blue-600 hover:bg-blue-700 '
                      : 'bg-blue-700 hover:bg-blue-800 '
                  )}
                >
                  {plan.name !== 'Enterprise'
                    ? 'Start Trial'
                    : 'Request a Quote'}
                </a>
              </Card>
            )
          })}
        </div>
      </div>
    )
  }
)
