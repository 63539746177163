import { createColumnHelper } from '@tanstack/react-table'
import * as React from 'react'
import { FaPlus, FaTimes } from 'react-icons/fa'
import useGetLatest from '../hooks/useGetLatest'
import { renderLocale, useLocalesQuery } from '../utils/locales'
import { Locale } from '../utils/locales'
import { useMostFrequentScheduleOptions } from '../hooks/useMostFrequentScheduleOptions'
import { useTable } from '../hooks/useTable'
import { Updater, functionalUpdate } from '../utils'
import { formatLocale } from '../utils/locales'
import { LocaleModelAlias, LocaleModelNz } from '../utils/locales'
import { ScheduleConfig } from '../utils/schedules'
import Clickable from './Clickable'
import { PlainInput } from './Input'
import Loader from './Loader'
import { LocalePicker } from './LocalePicker'
import Select from './Select'
import Table from './Table'

//

const columnHelper = createColumnHelper<LocaleModelNz & LocaleModelAlias>()

export function LocalesModelInput(props: {
  value: LocaleModelNz[]
  onChange?: (value: LocaleModelNz[]) => void
  onBlur?: (e: any) => void
  schedules?: ScheduleConfig[]
}) {
  const value = React.useMemo((): LocaleModelNz[] => {
    return props.value || []
  }, [props.value])

  const localesByIdQuery = useLocalesQuery({
    localeIds: value.map(d => d.localeId),
  })

  const getUpdateRow = useGetLatest(
    (index: number, updater: Updater<LocaleModelNz>) => {
      props.onChange?.(
        value.map((d, i) => {
          if (i === index) {
            return functionalUpdate(updater, d)
          }

          return d
        })
      )
    }
  )

  const getUpdateSubRow = useGetLatest(
    (
      parentIndex: number,
      index: number,
      updater: Updater<LocaleModelAlias>
    ) => {
      props.onChange?.(
        value.map((d, i) => {
          if (i === parentIndex) {
            return {
              ...d,
              aliases: d.aliases?.map((d, i) => {
                if (i === index) {
                  return functionalUpdate(updater, d as any)
                }

                return d
              }),
            }
          }

          return d
        })
      )
    }
  )

  const mostFrequentScheduleOptions = useMostFrequentScheduleOptions({
    schedules: props.schedules,
  })

  const getMostFrequentScheduleOptions = useGetLatest(
    mostFrequentScheduleOptions
  )

  const createLocaleRow = (locale: Locale): LocaleModelNz => {
    return {
      localeId: locale.locale_id,
      aliases: locale.location.split(',')[0]
        ? [
            {
              alias: locale?.location.split(',')[0]!.toLocaleLowerCase(),
              groups: [],
              mostFrequentScheduleId: '',
            },
          ]
        : [],
      mostFrequentScheduleId: null,
      groups: [],
    }
  }

  React.useEffect(() => {
    if (
      value.find(row => {
        return !getMostFrequentScheduleOptions().find(
          d => d.value === row.mostFrequentScheduleId
        )
      })
    ) {
      props.onChange?.(
        value.map(row => {
          if (
            getMostFrequentScheduleOptions().find(
              d => d.value === row.mostFrequentScheduleId
            )
          ) {
            return { ...row, mostFrequentScheduleId: null }
          }

          return row
        })
      )
    }
  }, [props.schedules])

  const columns = React.useMemo(
    () =>
      [
        columnHelper.accessor(
          (row, index) => {
            return localesByIdQuery.data
              ? formatLocale(localesByIdQuery.data[index])
              : row.localeId
          },
          {
            header: 'Locale',
            filterFn: 'fuzzy',
            meta: {
              tight: true,
            },
            cell: ({ row }) => {
              return row.depth > 0 ? null : (
                <div className="flex items-center">
                  {!localesByIdQuery.data ? (
                    <Loader />
                  ) : (
                    <LocalePicker
                      onChange={(_, nextLocale) => {
                        if (nextLocale) {
                          getUpdateRow()(row.index, d => ({
                            ...createLocaleRow(nextLocale),
                            groups: d.groups,
                            mostFrequentScheduleId: d.mostFrequentScheduleId,
                          }))
                        }
                      }}
                    >
                      {({ onClick }: any) => (
                        <Clickable
                          onClick={onClick}
                          className="text-black dark:text-white"
                        >
                          {renderLocale(
                            localesByIdQuery.data?.find(
                              d => d?.locale_id === row.original.localeId
                            )
                          )}
                        </Clickable>
                      )}
                    </LocalePicker>
                  )}
                </div>
              )
            },
          }
        ),
        columnHelper.accessor(row => row.alias, {
          header: 'Alias',
          meta: {
            tight: true,
            getCellProps: () => {
              return {
                className: 'p-0',
              }
            },
          },
          cell: cellProps => {
            if (cellProps.row.depth === 0) {
              return null
            }

            return (
              <PlainInput
                enableDraft
                value={cellProps.getValue()}
                onChange={e => {
                  getUpdateSubRow()(
                    cellProps.row.parentRow!.index,
                    cellProps.row.index,
                    d => ({
                      ...d,
                      // @ts-expect-error  // Property 'value' does not exist on type 'EventTarg... Remove this comment to see the full error message
                      alias: String(e.target.value).toLocaleLowerCase(),
                    })
                  )
                }}
                placeholder="Enter an alias..."
                className="w-full min-w-36"
              />
            )
          },
        }),
        columnHelper.accessor(row => row.groups, {
          header: 'Keyword Groups',
          filterFn: 'arrIncludesAll',
          getUniqueValues: row => row.groups ?? [],
          meta: {
            tight: true,
            getCellProps: () => ({
              className: 'p-0',
            }),
          },
          cell: function Cell(cellProps) {
            return (
              <Select
                value={cellProps.getValue()}
                enableDraft
                // options={groupOptions}
                multi
                create
                placeholder="Add keyword groups..."
                className="min-w-56"
                onChange={value => {
                  if (cellProps.row.depth === 0) {
                    getUpdateRow()(cellProps.row.index, d => ({
                      ...d,
                      groups: value,
                    }))
                  } else {
                    getUpdateSubRow()(
                      cellProps.row.parentRow!.index,
                      cellProps.row.index,
                      d => ({
                        ...d,
                        groups: value,
                      })
                    )
                  }
                }}
                inputProps={{
                  Input: PlainInput,
                }}
              />
            )
          },
        }),
        mostFrequentScheduleOptions.length > 1
          ? columnHelper.accessor(
              row =>
                mostFrequentScheduleOptions.find(
                  d => d.value === row.mostFrequentScheduleId
                )?.label,
              {
                header: 'Most Frequent Schedule',
                filterFn: 'arrIncludesSome',
                meta: {
                  tight: true,
                  getCellProps: () => ({
                    className: 'p-0',
                  }),
                },
                cell: cellProps => {
                  return (
                    <Select
                      options={mostFrequentScheduleOptions}
                      value={cellProps.row.original.mostFrequentScheduleId}
                      className="min-w-52"
                      placeholder="Select a schedule..."
                      onChange={value => {
                        if (cellProps.row.depth === 0) {
                          getUpdateRow()(cellProps.row.index, d => ({
                            ...d,
                            mostFrequentScheduleId: value,
                          }))
                        } else {
                          getUpdateSubRow()(
                            cellProps.row.parentRow!.index,
                            cellProps.row.index,
                            d => ({
                              ...d,
                              mostFrequentScheduleId: value,
                            })
                          )
                        }
                      }}
                      inputProps={{
                        Input: PlainInput,
                      }}
                    />
                  )
                },
              }
            )
          : null,
        columnHelper.display({
          header: 'Options',
          meta: {
            // tight: true,
            getHeaderProps: () => ({
              className: 'w-full flex justify-end',
            }),
          },
          cell: cellProps => {
            return (
              <div>
                <div className="flex justify-end gap-4">
                  <div>
                    {cellProps.row.depth === 0 ? (
                      <Clickable
                        className="flex items-center gap-1 text-xs font-bold"
                        onClick={() => {
                          getUpdateRow()(
                            cellProps.row.index,
                            d =>
                              ({
                                ...d,
                                aliases: [
                                  {
                                    alias: '',
                                  },
                                  ...(d.aliases ?? []),
                                ],
                              } as LocaleModelNz)
                          )
                        }}
                      >
                        <FaPlus /> Add Alias
                      </Clickable>
                    ) : null}
                  </div>
                  <Clickable
                    onClick={() => {
                      if (cellProps.row.depth === 0) {
                        return props.onChange?.(
                          value.filter((_, i) => i !== cellProps.row.index)
                        )
                      }

                      getUpdateRow()(cellProps.row.parentRow!.index, d => {
                        const aliases = d.aliases?.filter(
                          (_, i) => i !== cellProps.row.index
                        )

                        return {
                          ...d,
                          aliases,
                        }
                      })
                    }}
                  >
                    <FaTimes className="opacity-30 hover:text-red-500 hover:opacity-100" />
                  </Clickable>
                </div>
              </div>
            )
          },
        }),
      ].filter(Boolean),
    [mostFrequentScheduleOptions, localesByIdQuery.data]
  )

  const table = useTable({
    columns,
    // @ts-expect-error  // Type 'LocaleModelNz[]' is not assignable to type '... Remove this comment to see the full error message
    data: value,
    showToolbar: true,

    counter: {
      totalName: 'locales',
    },
    // @ts-expect-error  // Type '(row: LocaleModelNz & LocaleModelAlias) => L... Remove this comment to see the full error message
    getSubRows: row => row.aliases,
    getRowId: (row, i, parent) =>
      parent
        ? `${parent?.original.localeId}_${i}_${row.alias}`
        : `${row.localeId}`,
    state: {
      expanded: true,
    },
  })

  return (
    <>
      <div className="divide-y divide-gray-500/20 rounded border border-gray-200 dark:border-gray-800">
        <Table table={table} />
        <LocalePicker
          {...{
            excludeLocaleIds: value.map(d => d.localeId),
            onChange: (next: any, locale) => {
              // @ts-expect-error  // Argument of type 'Locale | undefined' is not assig... Remove this comment to see the full error message
              props.onChange?.([...value, createLocaleRow(locale)])
            },
            onBlur: (e: Event) => {
              props.onBlur?.(e)
            },
            className: 'flex',
          }}
        >
          {({ onClick }: any) => (
            <Clickable
              onClick={onClick}
              className="inline-flex items-center gap-1 p-2 text-sm !font-bold text-blue-500"
            >
              <FaPlus /> Add Locale
            </Clickable>
          )}
        </LocalePicker>
      </div>
    </>
  )
}
