import { useMutation, useQueryClient } from 'react-query'
import useLoading from '../hooks/useLoading'
import useToast from '../hooks/useToast'
import { patchProfile } from '../utils/Api'
import { queryKeyProfile } from '../utils/Constants'
import useErrorPopup from './useErrorPopup'

export default function useSaveProfile() {
  const toast = useToast()
  const errorPopup = useErrorPopup()
  const [, setLoading] = useLoading()
  const queryClient = useQueryClient()

  const { mutateAsync } = useMutation(patchProfile, {
    onMutate: () => {
      setLoading(true)
    },
    onSuccess: async data => {
      toast({
        message: 'Profile Updated',
        color: 'green-500',
      })
      queryClient.setQueryData(queryKeyProfile, data)
      await queryClient.invalidateQueries(queryKeyProfile)
    },
    onError: err => {
      console.error(err)
      errorPopup('Failed to save profile.')
    },
    onSettled: () => {
      setLoading(false)
    },
  })

  return mutateAsync
}
