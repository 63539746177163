import { BsPhone, BsTablet } from 'react-icons/bs'
import { FiMonitor } from 'react-icons/fi'
import { DevicePb } from '../utils/proto'

export const deviceOptions = [
  {
    label: 'Desktop',
    value: 'd',
    altValue: 'desktop',
    icon: <FiMonitor className="inline-block text-blue-500" />,
  },
  {
    label: 'Tablet (Android)',
    value: 't',
    altValue: 'tablet',
    icon: <BsTablet className="inline-block text-green-500" />,
  },
  {
    label: 'Tablet (iOS)',
    value: 'p',
    altValue: 'ipad',
    icon: <BsTablet className="inline-block text-green-500" />,
  },
  {
    label: 'Mobile (Android)',
    value: 'm',
    altValue: 'mobile',
    icon: <BsPhone className="inline-block text-red-500" />,
  },
  {
    label: 'Mobile (iOS)',
    value: 'i',
    altValue: 'iphone',
    icon: <BsPhone className="inline-block text-red-500" />,
  },
]

export const deviceOptionsV2 = [
  {
    label: 'Desktop',
    value: DevicePb.DESKTOP_WINDOWS,
    icon: <FiMonitor className="inline-block text-blue-500" />,
  },
  {
    label: 'Tablet (Android)',
    value: DevicePb.TABLET_ANDROID,
    icon: <BsTablet className="inline-block text-green-500" />,
  },
  {
    label: 'Tablet (iOS)',
    value: DevicePb.TABLET_IOS,
    icon: <BsTablet className="inline-block text-green-500" />,
  },
  {
    label: 'Mobile (Android)',
    value: DevicePb.MOBILE_ANDROID,
    icon: <BsPhone className="inline-block text-red-500" />,
  },
  {
    label: 'Mobile (iOS)',
    value: DevicePb.MOBILE_IOS,
    icon: <BsPhone className="inline-block text-red-500" />,
  },
]
