// @ts-nocheck

import * as React from 'react'
import ReactDOM from 'react-dom'

//
import useClickOutside from '../../hooks/useClickOutside'
import useRect from '../useRect'

export type Side =
  | 'left'
  | 'right'
  | 'top'
  | 'bottom'
  | 'top left'
  | 'top right'
  | 'bottom left'
  | 'bottom right'
  | 'left bottom'
  | 'left top'
  | 'right bottom'
  | 'right top'

const defaultPortalStyle = {
  pointerEvents: 'none',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  'z-index': 9999999,
}

// These are the keys used internally to look up and measure
// different sides of a bounding box within another
const sideSchemas = {
  left: {
    side: 'left',
    startKey: 'left',
    lengthKey: 'width',
    crossStartKey: 'top',
    crossLengthKey: 'height',
    fromEnd: false,
  },
  right: {
    side: 'right',
    startKey: 'left',
    lengthKey: 'width',
    crossStartKey: 'top',
    crossLengthKey: 'height',
    fromEnd: true,
  },
  top: {
    side: 'top',
    startKey: 'top',
    lengthKey: 'height',
    crossStartKey: 'left',
    crossLengthKey: 'width',
    fromEnd: false,
  },
  bottom: {
    side: 'bottom',
    startKey: 'top',
    lengthKey: 'height',
    crossStartKey: 'left',
    crossLengthKey: 'width',
    fromEnd: true,
  },
}

// This is the final Tootlip component. It's a render prop
// that lets you attach handlers to elements, and render a tooltip
// anchored to them in relation to the parent portal container (either the only
// one defined or the one referenced by Id).
export function Sticker({
  side = 'top',
  unmountDelay = 0,
  allowOverflow = false,
  show: userShow,
  portalId = 'ReactStickPortal',
  useLargest,
  render,
  children,
}: {
  side: Side | Side[]
  unmountDelay?: number
  allowOverflow?: boolean
  show?: boolean
  portalId?: string
  useLargest?: boolean
  render?: (props: {
    show: boolean
    side: Side
    getProps: any
    toggleShow: (set?: boolean) => void
  }) => any
  children: any
}) {
  // eslint-disable-next-line prefer-const
  let [show, setShow] = React.useState(false)
  // eslint-disable-next-line prefer-const
  let [shouldMountTooltip, setShouldMountTooltip] = React.useState(false)

  const portalRef = React.useRef<HTMLElement>()
  const anchorRef = React.useRef<HTMLElement>()
  const tooltipRef = React.useRef<HTMLElement>()

  show = userShow || show || false

  const portalDims = useOuterRect(portalRef, show)
  const anchorDims = useOuterRect(anchorRef, show)
  const tooltipDims = useOuterRect(tooltipRef, shouldMountTooltip)

  shouldMountTooltip = show || shouldMountTooltip

  const toggleShow = (set: any) => {
    setShow(old => {
      if (typeof set !== 'undefined') {
        return set
      }
      return !old
    })
  }

  const sides = React.useMemo(() => {
    return (Array.isArray(side) ? side : [side]).map(alignStr => {
      const [side, align = 'center'] = alignStr.split(' ')
      const incompatibleSide = !['top', 'right', 'bottom', 'left'].find(
        d => side === d
      )

      if (incompatibleSide) {
        throw new Error(
          `react-sticker: "${side}" is not a valid side! Must be one of ['top', 'right', 'bottom', 'left'].`
        )
      }

      const incompatibleAlign = ![
        'center',
        'start',
        'end',
        'top',
        'right',
        'bottom',
        'left',
      ].find(d => align === d)

      if (incompatibleAlign) {
        throw new Error(
          `react-sticker: "${align}" is not a valid side-alignment! Must be one of ['center', 'start', 'end', 'top', 'right', 'bottom', 'left'].`
        )
      }

      return [side, align]
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(side)])

  // Get the portal element ready
  React.useEffect(() => {
    if (typeof document !== 'undefined') {
      let node = document.getElementById(portalId)
      if (!node) {
        node = document.createElement('div')
        node.id = portalId
        document.body.appendChild(node)
        Object.assign(node.style, defaultPortalStyle)
      }
      portalRef.current = node
    }
  })

  // Handle mounting/unmounting side effects
  React.useEffect(() => {
    if (show) {
      setShouldMountTooltip(true)
    } else {
      // The unmount delay is optional and is usually only necessary if you have
      // animations that need to run before you unmount the tooltip component itself.
      const timeout = setTimeout(() => {
        setShouldMountTooltip(false)
      }, unmountDelay)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [show, unmountDelay])

  const isUsingToggleRef = React.useRef<boolean>()
  isUsingToggleRef.current = false

  useClickOutside(
    tooltipRef,
    () => isUsingToggleRef.current && toggleShow(false)
  )

  return (
    <React.Fragment>
      {children({
        show, // Simple enough
        toggleShow, // This is a manual toggle function that just flips the bool
        // getProps is just a decorator function that ONLY attaches the ref
        // to the anchor to tracks it's dimensions
        getProps: ({ refKey = 'ref', ...rest } = {}) => {
          return {
            [refKey]: anchorRef,
            ...rest,
          }
        },
        // getHoverableProps is like getProps, but it automatically
        // sets up hover and touch events to show the tooltip for you.
        // really handy most of the time.
        getToggleProps<TProps>(
          {
            refKey = 'ref',
            onClick,
            ...rest
          }: { onClick?: any; refKey?: string } & TProps = {} as TProps
        ) {
          isUsingToggleRef.current = true
          return {
            [refKey]: anchorRef,
            onClick: (e: any) => {
              if (onClick) {
                onClick(e)
              }
              toggleShow(true)
            },
            ...rest,
          }
        },
        getHoverableProps<TProps>(
          {
            refKey = 'ref',
            onMouseEnter,
            onMouseLeave,
            onTouchStart,
            onTouchEnd,
            ...rest
          }: {
            refKey?: string
            onMouseEnter?: any
            onMouseLeave?: any
            onTouchStart?: any
            onTouchEnd?: any
          } & TProps = {} as TProps
        ) {
          return {
            [refKey]: anchorRef,
            onMouseEnter: (e: any) => {
              if (onMouseEnter) {
                onMouseEnter(e)
              }
              toggleShow(true)
            },
            onMouseLeave: (e: any) => {
              if (onMouseLeave) {
                onMouseLeave(e)
              }
              toggleShow(false)
            },
            onTouchStart: (e: any) => {
              if (onTouchStart) {
                onTouchStart(e)
              }
              toggleShow(true)
            },
            onTouchEnd: (e: any) => {
              if (onTouchEnd) {
                onTouchEnd(e)
              }
              toggleShow(false)
            },
            ...rest,
          }
        },
      })}
      {shouldMountTooltip
        ? ReactDOM.createPortal(
            (() => {
              // IF we have all of the dimensions needed to calculate
              // fits, then calculate the fit
              const ready = portalDims && tooltipDims && anchorDims

              const fit = ready
                ? fitOnBestSide({
                    portalDims,
                    tooltipDims,
                    anchorDims,
                    sides,
                    allowOverflow,
                    useLargest,
                  })
                : {}

              const getProps = function <TProps>(
                {
                  ref,
                  style = {},
                  ...rest
                }: {
                  ref?: React.MutableRefObject<HTMLElement>
                  style?: React.StyleHTMLAttributes<HTMLElement>
                } & TProps = {} as TProps
              ) {
                return {
                  ref: (el: any) => {
                    if (ref) {
                      ref.current = el
                    }
                    tooltipRef.current = el
                  },
                  style: {
                    position: 'absolute',
                    visibility: ready ? 'visible' : 'hidden',
                    // The fit styles are applied here from the best fit
                    ...fit.style,
                    // Users can ultimately override any style still.
                    ...style,
                  },
                  ...rest,
                }
              }

              // @ts-expect-error  // Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
              return render({
                show,
                side: fit.side,
                getProps,
                toggleShow,
              })
            })(),
            // @ts-expect-error  // Argument of type 'HTMLElement | undefined' is not ... Remove this comment to see the full error message
            portalRef.current
          )
        : // If there isn't a resolved portal, or the tooltip isn't mounted,
          // render nothing.
          null}
    </React.Fragment>
  )
}

function useOuterRect(ref: any, enable: boolean) {
  let rect = useRect(ref.current, enable)

  // @ts-expect-error  // Type 'DOMRect | null' is not assignable to type 'D... Remove this comment to see the full error message
  rect = React.useMemo(() => {
    if (!ref.current || !rect) {
      return null
    }

    const styles = window.getComputedStyle(ref.current)

    return {
      x: rect.x,
      y: rect.y,
      width:
        rect.width + parseInt(styles.marginLeft) + parseInt(styles.marginRight),
      height:
        rect.height +
        parseInt(styles.marginTop) +
        parseInt(styles.marginBottom),
      top: rect.top,
      right: rect.right,
      bottom: rect.bottom,
      left: rect.left,
    } as DOMRect
  }, [rect, ref])

  return rect
}

// This function selects the best side for the tooltip by using
// the ranked fits.
function fitOnBestSide({
  portalDims,
  tooltipDims,
  anchorDims,
  sides,
  allowOverflow,
  useLargest,
}: any) {
  // @ts-expect-error  // Binding element 'side' implicitly has an 'any' typ... Remove this comment to see the full error message
  const fits = sides.map(([side, align]) =>
    measureFit({
      // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      ...sideSchemas[side],
      align,
      portalDims,
      tooltipDims,
      anchorDims,
      allowOverflow,
    })
  )

  if (useLargest) {
    fits.sort((a: any, b: any) => b.fit - a.fit)
    return fits[0]
  }

  return fits.find((fit: any) => fit.fit >= 1) || fits[0]
}

// This function takes a side and bunch of calculated dimensions from
// the portal, tooltip and target. Then it returns
// the percentage fit and the style to achieve this specific fit
function measureFit({
  side,
  align,
  startKey,
  lengthKey,
  crossStartKey,
  crossLengthKey,
  fromEnd,
  portalDims,
  tooltipDims,
  anchorDims,
  allowOverflow,
}: any) {
  const parentStart = portalDims[startKey]
  const parentLength = portalDims[lengthKey]
  const crossParentStart = portalDims[crossStartKey]
  const crossParentLength = portalDims[crossLengthKey]
  const anchorStart = anchorDims[startKey] - portalDims[startKey]
  const anchorLength = anchorDims[lengthKey]
  const crossAnchorStart = anchorDims[crossStartKey]
  const crossAnchorLength = anchorDims[crossLengthKey]
  const crossAnchorWidth = anchorDims[crossLengthKey]
  const targetLength = tooltipDims[lengthKey]
  const crossTargetLength = tooltipDims[crossLengthKey]

  let targetStart
  let fit

  if (!fromEnd) {
    targetStart = anchorStart - targetLength
    fit = Math.min(anchorStart / targetLength)
  } else {
    targetStart = anchorStart + anchorLength
    fit = (parentLength - (anchorStart + anchorLength)) / targetLength
  }

  if (!allowOverflow) {
    targetStart = Math.max(parentStart, Math.min(targetStart, parentLength))
  }

  let crossTargetStart

  if (startKey === 'left') {
    if (align === 'top') {
      align = 'start'
    } else if (align === 'bottom') {
      align = 'end'
    }
  } else {
    if (align === 'left') {
      align = 'start'
    } else if (align === 'right') {
      align = 'end'
    }
  }

  if (!['start', 'center', 'end'].includes(align)) {
    align = 'center'
  }

  if (align === 'start') {
    crossTargetStart = crossAnchorStart
  } else if (align === 'end') {
    crossTargetStart = crossAnchorStart + crossAnchorWidth - crossTargetLength
  } else {
    crossTargetStart =
      crossAnchorStart + crossAnchorLength / 2 - crossTargetLength / 2
  }

  crossTargetStart = Math.max(
    crossParentStart,
    Math.min(crossTargetStart, crossParentLength - crossTargetLength)
  )

  return {
    side,
    align,
    startKey,
    lengthKey,
    crossStartKey,
    crossLengthKey,
    fromEnd,
    portalDims,
    tooltipDims,
    anchorDims,
    allowOverflow,
    fit,
    style: {
      [startKey]: targetStart,
      [crossStartKey]: crossTargetStart,
    },
  }
}
