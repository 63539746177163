/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'
import ErrorComp from './Error'
import Loader from './Loader'

//

type Props = {
  isLoading: boolean
  error: unknown
  onRetry?: () => void
  small?: boolean
  children?: React.ReactNode | (() => React.ReactNode)
  className?: string
  loader?: any
}

export function Gate({
  isLoading,
  error,
  onRetry,
  children,
  small,
  loader,
  className,
}: Props): any {
  isLoading = Array.isArray(isLoading) ? isLoading.some(Boolean) : !!isLoading

  const hasError = Array.isArray(error) ? error.some(Boolean) : !!error

  return isLoading ? (
    loader || (
      <div className={className}>
        <Loader />
      </div>
    )
  ) : hasError ? (
    <div className={className}>
      <ErrorComp error={error} small={small} onRetry={onRetry} />
    </div>
  ) : typeof children === 'function' ? (
    children()
  ) : (
    children
  )
}
