import { matchSorter, rankings } from 'match-sorter'
import { twMerge } from 'tailwind-merge'
import * as React from 'react'
import { BsList, BsListNested } from 'react-icons/bs'
import { FaCaretDown, FaPlus, FaSearch, FaTimes } from 'react-icons/fa'
import { useQueries } from 'react-query'
import useControlledTable from '../hooks/useControlledTable'
import { useActiveWorkspaceId } from '../hooks/workspaces'
import { Updater, sortBy, uniqBy } from '../utils'
import { fetchSerps } from '../utils/Api'
import { queryKeyReportsKeywordSerp } from '../utils/Constants'
import { formatLimitedText } from '../utils/Format'
import { getResultLabel, useResultColumnsQuery } from '../utils/Results'
import { useThemeMode } from '../utils/Theme'
import { twConfig } from '../utils/tailwind'
import Anchor from './Anchor'
import Card from './Card'
import InlineLabeledInput from './InlineLabeledInput'
import Loader from './Loader'
import NoData from './NoData'
import Pager from './Pager'
import Select from './Select'
import Table from './Table'
import Tooltip from './Tooltip'
import { ButtonPlain } from './ButtonPlain'
import { SelectOption } from '../hooks/useSelect'

export type SerpWidgetState = {
  isNozzleVisionEnabled?: boolean
  rankingIds: (string | null)[]
  resultSearchTerm: string
  zoom: number
  sortById: string
  desc: boolean
  offset: number
  limit: number
  expandItemResults: boolean
  resultColumnGroup: (typeof resultColumnGroupOptions)[number]['value']
  resultColumnIds: (typeof resultColumnGroupOptions)[number]['columns'][number][]
  serpResultColumnGroup: (typeof resultColumnGroupOptions)[number]['value']
  serpResultColumnIds: (typeof resultColumnGroupOptions)[number]['columns'][number][]
}

export const resultColumnGroupOptions = [
  {
    label: 'Summary',
    value: 'summary',
    columns: [
      'result__rank',
      'result__paid_adjusted_rank',
      'result__measurements__pixels_from_top',
      'result__measurements__percentage_of_viewport',
      'result__nozzle_metrics__estimated_traffic',
      'result__nozzle_metrics__click_through_rate',
    ],
  },
  {
    label: 'Ranks',
    value: 'ranks',
    columns: [
      'result__rank',
      'result__item_rank',
      'result__column_rank',
      'result__paid_adjusted_rank',
    ],
  },
  {
    label: 'Pixels',
    value: 'pixels',
    columns: [
      'result__measurements__pixels_from_top',
      'result__measurements__percentage_of_viewport',
      'result__measurements__percentage_of_dom',
    ],
  },
  {
    label: 'Clicks',
    value: 'clicks',
    columns: [
      'result__nozzle_metrics__click_through_rate',
      'result__nozzle_metrics__estimated_traffic',
      'result__nozzle_metrics__ppc_value',
    ],
  },
  {
    label: 'Related',
    value: 'related',
    columns: [
      'result__related_phrase__phrase',
      'result__people_also_ask__is_people_also_ask',
      'result__people_also_search_for__is_people_also_search_for',
      'result__related_search__is_related_search',
      'result__related_phrase__is_related_phrase_misc',
      'result__faq__is_faq',
    ],
  },
  {
    label: 'Search Intent',
    value: 'searchIntent',
    columns: [
      'result__search_intent__traditional__informational__score',
      'result__search_intent__traditional__navigational__score',
      'result__search_intent__traditional__commercial__score',
      'result__search_intent__traditional__transactional__score',
    ],
  },
  {
    label: 'Custom',
    value: 'custom',
    columns: [],
  },
]

export function SerpResultsTables({
  desc,
  expandItemResults,
  isLoading,
  limit,
  offset,
  rankingIds,
  rankingOptions,
  resultColumnGroup,
  resultColumnIds,
  resultSearchTerm,
  setDesc,
  setExpandItemResults,
  setLimit,
  setOffset,
  setRankingIds,
  setResultColumnGroup,
  setResultColumnIds,
  setResultSearchTerm,
  setSortById,
  sortById,
}: SerpWidgetState & {
  isLoading: boolean
  rankingOptions: undefined | SelectOption<string>[]
  setDesc: (desc: Updater<boolean>) => void
  setExpandItemResults: (expandItemResults: Updater<boolean>) => void
  setLimit: (limit: Updater<number>) => void
  setOffset: (offset: Updater<number | null>) => void
  setRankingIds: (rankingIds: Updater<(string | null)[]>) => void
  setResultColumnGroup: (resultColumnGroup: Updater<string>) => void
  setResultColumnIds: (resultColumnGroup: Updater<string[]>) => void
  setResultSearchTerm: (resultSearchTerm: Updater<string>) => void
  setSortById: (sortById: Updater<string>) => void
}) {
  const activeWorkspaceId = useActiveWorkspaceId()
  const serpHtmlScrollsRef = React.useRef<HTMLDivElement[]>([])
  const serpTableScrollsRef = React.useRef<HTMLDivElement[]>([])
  const serpTableActiveElRef = React.useRef<null | HTMLDivElement>(null)

  const [resultSearchTermDraft, setResultSearchTermDraft] =
    React.useState(resultSearchTerm)

  React.useEffect(() => {
    setResultSearchTermDraft(resultSearchTerm)
  }, [resultSearchTerm])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setResultSearchTerm(resultSearchTermDraft || '')
    }, 500)

    return () => clearTimeout(timeout)
  }, [resultSearchTermDraft, setResultSearchTerm])

  const keywordSerpQueries = useQueries(
    rankingIds?.map(rankingId => {
      const rankingParams = rankingId && {
        workspaceId: activeWorkspaceId,
        rankingId,
      }

      return {
        queryKey: [queryKeyReportsKeywordSerp, rankingParams],
        queryFn: () => fetchSerps(rankingParams),
        staleTime: Infinity,
        enabled: !!rankingParams,
      }
    }) ?? []
  )

  const addComparison = () => {
    if (!rankingOptions) return

    serpHtmlScrollsRef.current.forEach(pane => (pane.scrollLeft = 0))
    serpTableScrollsRef.current.forEach(pane => (pane.scrollLeft = 0))

    setRankingIds(prev => [
      rankingOptions.find(d => !prev?.includes(d.value))?.value ?? null,
      ...(prev ?? []),
    ])
  }

  const maxResults = React.useMemo(() => {
    return Math.max(
      ...keywordSerpQueries
        .filter(d => d.data)
        .map(d => d.data.ranking.results.length)
    )
  }, [keywordSerpQueries])

  React.useEffect(() => {
    if (
      !rankingIds?.length &&
      rankingOptions?.length &&
      rankingOptions?.[0]?.value
    ) {
      setRankingIds([rankingOptions[0].value])
    }
  }, [rankingOptions, rankingIds?.length])

  React.useEffect(() => {
    if (
      rankingIds?.length &&
      rankingOptions?.[0] &&
      rankingIds.some(id => !rankingOptions.find(d => d.value === id))
    ) {
      setRankingIds([rankingOptions[0].value])
    }
  })

  const rankingSelectorElement = rankingOptions?.length ? (
    <div className="flex divide-x-2 divide-gray-500/20">
      {rankingIds?.map((rankingId, index) => {
        return (
          <div
            key={rankingId || index}
            className="flex flex-1 items-center justify-between gap-2 p-2"
          >
            <Select
              options={rankingOptions}
              create
              // @ts-expect-error  // Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
              value={rankingId}
              onChange={(val: any) =>
                setRankingIds(prev =>
                  prev.map((d, i) => (i === index ? val : d))
                )
              }
            >
              {({ onClick, selectedOption }: any) => (
                <ButtonPlain className="bg-gray-500" onClick={onClick}>
                  {selectedOption?.label
                    ? selectedOption.label
                    : 'Select a requested date/time...'}{' '}
                  <FaCaretDown className="inline" />
                </ButtonPlain>
              )}
            </Select>

            {rankingIds?.length > 1 ? (
              <ButtonPlain
                className="bg-gray-100 hover:bg-red-500 dark:bg-gray-800"
                onClick={() =>
                  setRankingIds(prev => prev.filter((d, i) => i !== index))
                }
              >
                <FaTimes className="inline" />
              </ButtonPlain>
            ) : null}
          </div>
        )
      }) ?? null}
    </div>
  ) : null

  const compareHistoryButton = rankingOptions?.length ? (
    <ButtonPlain className="bg-green-500" onClick={addComparison}>
      <FaPlus className="inline" /> Compare History
    </ButtonPlain>
  ) : null

  const viewButton = (
    <Select
      options={resultColumnGroupOptions}
      value={resultColumnGroup}
      onChange={setResultColumnGroup}
    >
      {({ onClick, selectedOption }: any) => (
        <ButtonPlain className="bg-gray-200 dark:bg-gray-800" onClick={onClick}>
          <span>
            View: <strong>{selectedOption.label}</strong>
          </span>
          <FaCaretDown className="inline" />
        </ButtonPlain>
      )}
    </Select>
  )

  const resultColumnsQuery = useResultColumnsQuery()

  const addColumnsButton = (
    <Select
      multi
      options={resultColumnsQuery.data}
      value={resultColumnIds}
      onChange={(vals: any) => {
        setResultColumnGroup('custom')
        setResultColumnIds(vals)
      }}
    >
      {({ onClick, selectedOption }: any) => (
        <ButtonPlain className="bg-gray-100 dark:bg-gray-800" onClick={onClick}>
          {resultColumnGroup === 'custom' ? (
            !selectedOption?.length ? (
              <>
                Select Columns... <FaPlus className="inline" />
              </>
            ) : (
              <>
                {formatLimitedText(
                  selectedOption.map((d: any) => d.label).join(', '),
                  40
                )}
                <FaCaretDown className="inline" />
              </>
            )
          ) : (
            <span className="flex items-center gap-2">
              <FaPlus className="inline" />
              <div>Add Columns</div>
            </span>
          )}
        </ButtonPlain>
      )}
    </Select>
  )

  const columnsButtons = (
    <div className="flex gap-1">
      {viewButton}
      {addColumnsButton}
    </div>
  )

  const searchInput = (
    <InlineLabeledInput
      label={<FaSearch className="inline" />}
      placeholder="e.g. PAA, My brand, etc"
      value={resultSearchTermDraft}
      onChange={e =>
        setResultSearchTermDraft((e.target as HTMLInputElement).value)
      }
      className="w-56 text-sm"
    />
  )

  const expandCollapseButton = (
    <ButtonPlain
      className={twMerge(
        expandItemResults
          ? 'blue-500 hover:gray-500'
          : 'hover:blue-600 bg-gray-100 dark:bg-gray-800'
      )}
      onClick={() => setExpandItemResults(prev => !prev)}
    >
      {expandItemResults ? (
        <>
          <BsList className="inline" /> Collapse Items
        </>
      ) : (
        <>
          <BsListNested className="inline" /> Expand Items
        </>
      )}
    </ButtonPlain>
  )

  return (
    <div className="divide-y divide-gray-500/20">
      <div className="flex items-center gap-2 rounded-b-sm rounded-t-lg bg-white p-2 dark:bg-gray-900">
        <div className="font-bold">
          <span>SERP Results</span>
        </div>
        {rankingOptions?.length ? compareHistoryButton : null}
        {columnsButtons}
        {searchInput}
        {expandCollapseButton}
      </div>
      {isLoading ? (
        <div className="flex items-center justify-center p-4">
          <Loader className="text-2xl" />
        </div>
      ) : !rankingIds?.[0] ? (
        <Card>
          <NoData />
        </Card>
      ) : (
        <>
          {rankingSelectorElement}
          <div className="flex divide-x-2 divide-gray-500/20">
            {rankingIds?.map((rankingId, index) => {
              return (
                <ResultTable
                  {...{
                    key: rankingId,
                    sortById,
                    desc,
                    offset,
                    limit,
                    serpTableScrollsRef,
                    serpTableActiveElRef,
                    resultSearchTerm,
                    resultColumnIds,
                    expandItemResults,
                    results: keywordSerpQueries[index]?.data?.ranking.results,
                    setDesc,
                    setSortById,
                  }}
                />
              )
            }) ?? null}
          </div>
          <Pager
            offset={offset}
            limit={limit}
            size={maxResults}
            setOffset={setOffset}
            setLimit={setLimit}
            className="p-2"
          />
        </>
      )}
    </div>
  )
}

function ResultTable({
  desc,
  expandItemResults,
  limit,
  offset,
  resultColumnIds,
  results,
  resultSearchTerm,
  serpTableActiveElRef,
  serpTableScrollsRef,
  setDesc,
  setSortById,
  sortById,
}: {
  desc: boolean
  expandItemResults?: boolean
  limit: number
  offset: number
  resultColumnIds?: string[]
  results: unknown[]
  resultSearchTerm?: string
  serpTableActiveElRef: React.MutableRefObject<null | HTMLDivElement>
  serpTableScrollsRef: React.MutableRefObject<HTMLDivElement[]>
  setDesc: (desc: Updater<boolean>) => void
  setSortById: (sortById: Updater<string>) => void
  sortById: string
}) {
  const { themeMode } = useThemeMode()
  const resultColumnsQuery = useResultColumnsQuery()

  const resultColumns = React.useMemo(
    () =>
      resultColumnIds
        ?.map(id => resultColumnsQuery.data.find((d: any) => d.id === id))
        .filter(Boolean) ?? [],
    [resultColumnIds, resultColumnsQuery.data]
  )

  const tableData = React.useMemo(() => {
    const resultColumn = resultColumnsQuery.data.find(
      (d: any) => d.id === sortById
    )

    const preResults: any = (results ?? []).filter((result: any) =>
      result?.measurements?.is_visible === false ? false : true
    )

    let filtered = resultSearchTerm
      ? filterResultByTerm(preResults, resultSearchTerm, resultColumns)
      : preResults

    filtered = expandItemResults
      ? filtered
      : filtered.filter((d: any) => d.item_rank === 0)

    const sorted = sortBy(
      filtered,
      resultColumn
        ? d => resultColumn.accessor({ result: d })
        : d => d[sortById]
    )

    if (desc) {
      sorted.reverse()
    }

    if (resultColumn?.inverted) {
      sorted.reverse()
    }

    const data = sorted.slice(limit * offset, limit * offset + limit)

    return data
  }, [
    desc,
    limit,
    offset,
    resultColumns,
    resultColumnsQuery.data,
    resultSearchTerm,
    results,
    sortById,
    expandItemResults,
  ])

  const tableSortBy = React.useMemo(() => {
    return [
      {
        id: sortById,
        desc,
      },
    ]
  }, [desc, sortById])

  const onSortByChange = React.useCallback(
    updater => {
      const { id, desc } = updater(tableSortBy)[0]
      const [sortById] = id.split('Chang')
      setSortById(sortById)
      setDesc(desc)
    },
    [tableSortBy]
  )

  const requiredLabelCount = React.useMemo(() => {
    let requiredLabelCount = 0

    const findUniqueLabels = () => {
      const labels = resultColumns?.map(focusResultColumn => {
        const column = resultColumnsQuery.data?.find(
          (d: any) => d.id === focusResultColumn.id
        )

        return [...column.labels].reverse()[requiredLabelCount]
      })

      const uniqueLabels = uniqBy(labels as any)

      if (uniqueLabels.length !== labels?.length) {
        requiredLabelCount++
        findUniqueLabels()
      }
    }

    findUniqueLabels()

    return requiredLabelCount
  }, [resultColumns, resultColumnsQuery.data])

  const columns = React.useMemo(
    () => [
      {
        header: 'Result Type',
        enableSorting: false,
        meta: {
          tight: true,
        },
        cell: (props: any) => {
          const original = props.row.original

          const features = [
            ['Featured Snippet', 'featured_snippet__is_featured_snippet'],
            ['Organic', 'organic__is_organic'],
            ['Paid', 'paid__is_paid'],
            ['Ad', 'ad__is_ad'],
            ['People Also Ask', 'people_also_ask__is_people_also_ask'],
            ['FAQ', 'faq__is_faq'],
            ['Video', 'video__is_video'],
            ['Top Story', 'top_story__is_top_story'],
            // ['Local Pack', 'local__local_pack'],
            ['Local', 'local__is_local'],
            ['Map', 'map__is_map'],
            ['Image', 'image__is_image'],
            ['Product', 'product__is_product'],
            ['Job', 'job__is_job'],
            ['About', 'about__is_about'],
            ['Podcast', 'podcast__is_podcast'],
            [
              'People Also Search For',
              'people_also_search_for__is_people_also_search_for',
            ],
            ['App', 'app__is_app'],
            ['According To', 'according_to__is_according_to'],
            ['Available On', 'available_on__is_available_on'],
            ['Best of', 'best__is_best'],
            ['Book', 'book__is_book'],
            ['Ebook', 'book__is_ebook'],
            ['Audiobook', 'book__is_audiobook'],
            ['Author', 'book__is_author'],
            ['Book Preview', 'book__is_book_preview'],
            ['Borrow Ebook', 'book__is_borrow_ebook'],
            ['Get Book', 'book__is_get_book'],
            ['Chart', 'chart__is_chart'],
            ['College', 'college__is_college'],
            ['Comparison', 'comparison__is_comparison'],
            ['Currency Converter', 'converter__is_currency_converter'],
            ['Unit Converter', 'converter__is_unit_converter'],
            ['Exchange Rate', 'converter__is_exchange_rate'],
            ['Destination', 'destination__is_destination'],
            ['Dictionary', 'dictionary__is_dictionary'],
            ['Direct Answer', 'direct_answer__is_direct_answer'],
            ['Discover More', 'discover_more__is_discover_more'],
            ['Event', 'event__is_event'],
            ['Related Event', 'event__is_related_event'],
            ['Expanded Sitelink', 'sitelink__is_expanded_sitelink'],
            ['Finance', 'finance__is_finance'],
            ['Market Ticker', 'finance__is_market_ticker'],
            ['Stock Market', 'finance__is_stock_market'],
            ['Flight', 'flight__is_flight'],
            ['Found on the Web', 'found_on_the_web__is_found_on_the_web'],
            ['Hotel', 'hotel__is_hotel'],
            ['How To', 'how_to__is_how_to'],
            ['Map Travel', 'map_travel__is_map_travel'],
            ['Medical', 'medical__is_medical'],
            ['Music', 'music__is_music'],
            ['Recipe', 'recipe__is_recipe'],
            ['Research', 'research__is_research'],
            ['Review', 'review__is_review'],
            ['Showtimes', 'showtimes__is_showtimes'],
            ['Song', 'music__is_song'],
            ['Sport', 'sport__is_sport'],
            ['Top Rated', 'top_rated__is_top_rated'],
            ['Top Result', 'top_result__is_top_result'],
            ['Twitter', 'twitter__is_twitter'],
            ['Movie Rating', 'entertainment__is_movie_rating'],
            ['On TV Soon', 'enterainment__is_on_tv_soon'],
            ['Cast', 'entertainment__is_cast'],
            ['Watch Movie', 'entertainment__is_watch_movie'],
            ['Watch Show', 'entertainment__is_watch_show'],
            ['Episode', 'entertainment__is_episode'],
            ['Trailer Clip', 'entertainment__is_trailer_clip'],
            ['Based on the Book', 'entertainment__is_based_on_the_book'],
            ['Related Search', 'related_search__is_related_search'],
            ['Did You Mean', 'search_modifier__has_did_mean'],
            ['Showing Results For', 'search_modifier__has_showing_results_for'],
            ['Results Near', 'search_modifier__has_results_near'],
            [
              'Language Preferences',
              'search_modifier__has_language_preferences',
            ],
            [
              'Explicit Content Warning',
              'search_modifier__has_explicit_content_warning',
            ],
            ['See Results About', 'search_modifier__has_see_results_about'],
            ['Refine By Button', 'refine_by__is_refine_by'],
            ['More Button', 'more__is_more'],
            ['Sitelink', 'sitelink__is_sitelink'],
            ['Has Image', 'image__has_image'],
            ['Indented', 'emphasis__is_indented'],
          ]

          let foundFeatures = features.filter(([_, columnId]) => {
            const path = columnId?.split('__')
            let cursor = original
            path?.some(part => {
              if (!cursor[part]) {
                cursor = undefined
                return true
              }

              cursor = cursor[part]
            })

            return cursor
          })

          if (original.layout?.is_pack) {
            ;(
              [
                ['local', 'Local', 'Local Pack'],
                ['image', 'Image', 'Image Pack'],
                ['video', 'Video', 'Video Pack'],
                ['product', 'Product', 'Product Pack'],
                ['hotel', 'Hotel', 'Hotel Pack'],
              ] as const
            ).forEach(([key, feature, packName]) => {
              if (original[key]?.[`is_${key}`]) {
                foundFeatures = foundFeatures.map(d =>
                  d[0] === feature ? [packName] : d
                )
              }
            })
          }

          return <div>{foundFeatures.map(d => d[0]).join(', ')}</div>
        },
      },
      {
        header: 'URL',
        enableSorting: false,
        meta: {
          tight: true,
        },
        cell: (props: any) => {
          const url = props.row.original.url?.url
          const shortUrl =
            url?.replace('https://', '').replace('http://', '') ?? ''
          const source_text = props.row.original.content_source?.source_text

          return (
            <Tooltip
              tooltip={
                <div className="space-y-1">
                  {source_text !== url ? (
                    <div className="text-sm font-bold">{source_text}</div>
                  ) : null}
                  <div>{url}</div>
                </div>
              }
              className="block overflow-hidden overflow-ellipsis"
            >
              {shortUrl?.length > 50
                ? shortUrl.substring(0, 50) + '...'
                : shortUrl}
            </Tooltip>
          )
        },
      },
      {
        header: 'Displayed Title',
        accessorFn: (row: any) => row.url?.url,
        enableSorting: false,
        meta: {
          tight: true,
        },
        cell: (props: any) => {
          const { label, tooltipLabel } = getResultLabel(props.row.original)

          return (
            <Tooltip
              tooltip={tooltipLabel}
              className="block overflow-hidden overflow-ellipsis"
            >
              <Anchor
                href={props.row.original.url?.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {label?.length > 50 ? label.substring(0, 47) + '...' : label}
              </Anchor>
            </Tooltip>
          )
        },
      },
      ...resultColumns.map(column => ({
        header: [...column.labels]
          .reverse()
          .slice(0, requiredLabelCount + 1)
          .reverse()
          .join(' › '),
        id: column.id,
        accessorFn: (result: any) => column.accessor({ result }),
        meta: {
          tight: true,
        },
        cell: (props: any) => (
          <div
            style={{
              textAlign: 'right',
            }}
          >
            {column.render(props.getValue()) ?? null}
          </div>
        ),
      })),
    ],
    [resultColumns]
  )

  const [el, setEl] = React.useState<HTMLDivElement>(null!)

  React.useEffect(() => {
    if (el) {
      serpTableScrollsRef?.current?.push(el)

      return () => {
        serpTableScrollsRef.current = serpTableScrollsRef.current.filter(
          d => d !== el
        )
      }
    }
  }, [el, serpTableScrollsRef])

  const table = useControlledTable({
    data: tableData,
    columns,
    enableMultiSort: false,
    state: {
      sorting: tableSortBy,
    },
    onSortingChange: onSortByChange,
    showToolbar: false,
    getRowProps: props => ({
      style: {
        background:
          props.row.original.rank % 2 === 0
            ? themeMode === 'dark'
              ? twConfig.theme.colors.gray['850']
              : twConfig.theme.colors.gray['100']
            : themeMode === 'dark'
            ? twConfig.theme.colors.gray['900']
            : 'white',
      },
    }),
    getTableWrapProps: () => ({
      ref: setEl,
      onMouseEnter: () => {
        serpTableActiveElRef.current = el
      },
      onMouseLeave: () => {
        serpTableActiveElRef.current = null
      },
      onScroll: e => {
        if (serpTableActiveElRef.current !== e.currentTarget) {
          return
        }

        serpTableScrollsRef.current.forEach((otherTableEl: any) => {
          if (otherTableEl !== serpTableActiveElRef.current) {
            otherTableEl.scrollLeft = serpTableActiveElRef.current?.scrollLeft
          }
        })
      },
    }),
  })

  return (
    <div className="flex-1 overflow-x-auto">
      <Table table={table} />
    </div>
  )
}

export function filterResultByTerm<
  T extends {
    url?: {
      url: string
    }
    title?: {
      text: string
    }
    description?: {
      text: string
    }
  }
>(
  results: T[],
  term: string,
  additionalColumns: {
    accessorFn: (opts: { result: T }) => string
  }[] = []
) {
  return !term
    ? results
    : matchSorter(results, term, {
        threshold: rankings.CONTAINS,
        sorter: d => d,
        keys: [
          // @ts-expect-error  // Type '(result: T) => string | undefined' is not as... Remove this comment to see the full error message
          result => result.url?.url,
          // @ts-expect-error  // Type '(result: T) => string | undefined' is not as... Remove this comment to see the full error message
          result => result.title?.text,
          // @ts-expect-error  // Type '(result: T) => string | undefined' is not as... Remove this comment to see the full error message
          result => result.description?.text,
          ...additionalColumns.map(
            // @ts-expect-error  // Property 'accessor' does not exist on type '{ acce... Remove this comment to see the full error message
            column => (result: any) => column.accessor({ result })
          ),
        ],
      })
}
