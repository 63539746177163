import { twMerge } from 'tailwind-merge'
import { withProps } from '../utils/withProps'

export const Dropzone = withProps('div')<{
  dropped: boolean
  isDragAccept: boolean
  isDragReject: boolean
  isDragActive: boolean
}>(
  ({
    className,
    dropped,
    isDragAccept,
    isDragReject,
    isDragActive,
    ...props
  }) => ({
    ...props,
    className: twMerge(
      `flex border-2 border-dashed border-gray-300
      transition-all ease-in-out dark:border-gray-700`,
      !dropped && 'cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800',
      isDragAccept
        ? `border-green-500`
        : isDragReject
        ? `border-red-500`
        : isDragActive
        ? `border-blue-500`
        : `border-gray-300`,
      className
    ),
  })
)
