import { Outlet, useMatchRoute } from '@tanstack/react-router'
import * as React from 'react'
import Alerts from '../components/Alerts'
import Jobs from '../components/Jobs'
import Loader from '../components/Loader'
import { Onboarding } from '../components/Onboarding'
import QuickPanel from '../components/QuickPanel'
import { useSidebarItems } from '../components/Sidebar'
import { useProfileQuery } from '../hooks/profile'
import { useProjects } from '../hooks/projects'
import useAuth from '../hooks/useAuth'
import useConfirm from '../hooks/useConfirm'
import useLogout from '../hooks/useLogout'
import useNavigate from '../hooks/useNavigate'
import useQuickOptions from '../hooks/useQuickOptions'
import {
  QUICK_OPTION_PRIORITY_DASHBOARDS,
  QUICK_OPTION_PRIORITY_LOG_OUT,
  QUICK_OPTION_PRIORITY_MENU_ITEM,
  QUICK_OPTION_PRIORITY_SWITCH_WORKSPACE,
  QUICK_OPTION_PRIORITY_PROJECTS,
  QUICK_OPTION_PRIORITY_UTILITY,
  QUICK_OPTION_PRIORITY_WORKSPACE,
  QUICK_OPTION_PRIORITY_SWITCH_PROJECT,
} from '../utils/Constants'
import { useThemeMode } from '../utils/Theme'
import { getColumns } from '../utils/columns'
import Login from './Login'
import { useActiveWorkspace, useEnsureWorkspace } from '../hooks/workspaces'
import { FaQuestionCircle } from 'react-icons/fa'
import { useQuery } from 'react-query'
import Clickable from '../components/Clickable'
import { Modal, ModalContent, ModalTitle } from '../components/Modals'
import Select from '../components/Select'
import useModal from '../hooks/useModal'
import useModalContext from '../hooks/useModalContext'
import { useActiveWorkspaceId } from '../hooks/workspaces'
import { sortBy } from '../utils'
import { fetchLogin } from '../utils/Api'
import { useRemoveUserById } from '../hooks/users'

export function AuthRoot() {
  const [{ stage }] = useAuth()
  const profileQuery = useProfileQuery()

  // useSearchParameterReset()
  useSetupQuickOptions()
  useWorkspaceLogin()
  useWorkspaceInvitations()
  useEnsureWorkspace()

  // const showBanner = useShowBanner()

  // React.useEffect(() => {
  //   const banner = showBanner({
  //     color: 'yellow-500',
  //     message: (
  //       <div className="p-1 text-sm">
  //         Due to some Google changes over the weekend, some metrics may be
  //         erroneously low from Sept 9-11. We are reparsing the data and expect
  //         your metrics to be fixed by Sept 12 with no data loss. Please contact
  //         support if you have any further concerns or questions.
  //       </div>
  //     ),
  //   })

  //   return banner.close
  // }, [showBanner])

  // React.useEffect(() => {
  //   if (profileQuery.isError && stage === 'loggedIn') {
  //     const handle = async () => {
  //       profileQuery.remove()
  //       logout()

  //       const confirmed = await confirm({
  //         title: 'Something went wrong! 😢',
  //         message:
  //           (profileQuery.error as any)?.response?.status === 404
  //             ? `You do not belong to this workspace. Please choose a workspace you belong to or contact support.`
  //             : `Something went wrong while logging in. Please try again or contact support.`,
  //         confirmText: 'Start New Support Chat',
  //         cancelText: 'Dismiss',
  //       })

  //       if (confirmed) {
  //         openHubSpotChat()
  //       }
  //     }
  //     handle()
  //   }
  // }, [confirm, logout, profileQuery, stage])

  React.useEffect(() => {
    if (stage === 'loggedIn') {
      try {
        getColumns()
      } catch (err) {}
    }
  }, [stage])

  useActiveWorkspace()

  return (
    <>
      <Login />
      {profileQuery.isLoading || !profileQuery.data ? (
        <div className="flex h-full w-full items-center justify-center">
          <div className="flex flex-col items-center gap-4">
            <Loader className="text-4xl" />
          </div>
        </div>
      ) : !profileQuery.data?.userWorkspaces?.length &&
        !profileQuery.isFetching ? (
        <Onboarding />
      ) : (
        <>
          <Outlet />
          <Alerts />
          <Jobs />
          <QuickPanel />
        </>
      )}
    </>
  )
}

// function useSearchParameterReset() {
//   const navigate = useNavigate()
//   const search = useSearch()

//   const previousSearch = useGetPrevious(search)
//   React.useLayoutEffect(() => {
//     const previous = previousSearch() as any

//     let changed = new Set<string>()

//     const recurse = (obj: any) => {
//       Object.keys(obj).forEach(key => {
//         if (
//           previous?.[key] &&
//           search?.[key] &&
//           previous?.[key] !== search?.[key]
//         ) {
//           const value = obj[key]
//           if (typeof value === 'object') {
//             changed = new Set([...changed.values(), ...Object.keys(value)])
//             recurse(value)
//           }
//         }
//       })
//     }

//     recurse(searchParamDependencyTree)

//     if (changed.size) {
//       navigate({
//         search: prev => ({
//           ...prev,
//           ...Object.fromEntries([...changed.values()].map(d => [d, undefined])),
//         }),
//       })
//     }
//   })
// }

function useSetupQuickOptions() {
  const navigate = useNavigate()
  const confirm = useConfirm()
  const logout = useLogout()
  const projectsQuery = useProjects()
  const { setThemeMode } = useThemeMode()
  const profileQuery = useProfileQuery()

  const sidebarItems = useSidebarItems()

  useQuickOptions(
    React.useMemo(
      () =>
        [
          // Dashboards
          {
            label: 'View Projects',
            priority: QUICK_OPTION_PRIORITY_DASHBOARDS,
            value: () => {
              navigate({ to: `/projects` })
            },
          },
          ...sidebarItems.flatMap(group =>
            group.items.map(item => {
              return {
                label: `${group.label} > ${item.label}`,
                priority: QUICK_OPTION_PRIORITY_MENU_ITEM,
                value: () => {
                  navigate({
                    to: item.to,
                    search: item.search as any,
                  })
                },
              }
            })
          ),
          // Teams
          // {
          //   label:
          //     projectsQuery.status === 'success' && projectsQuery.data?.length > 1
          //       ? 'Projects'
          //       : 'Manage Keywords',
          //   priority: QUICK_OPTION_PRIORITY_WORKSPACE,
          //   value: () => {
          //     navigate({ to: `/` })
          //   },
          // },
          ...(projectsQuery.data || [])
            .map(project => [
              {
                label: `Switch To Project: ${project.name}`,
                priority: QUICK_OPTION_PRIORITY_SWITCH_PROJECT,
                value: () =>
                  navigate({
                    // to: `projects/${project.id}`,
                    search: prev => ({
                      ...prev,
                      projectId: project.id,
                    }),
                  }),
              },
              {
                label: `Manage Project: ${project.name}`,
                priority: QUICK_OPTION_PRIORITY_PROJECTS,
                value: () =>
                  navigate({
                    to: `projects/${project.id}`,
                    search: prev => ({
                      ...prev,
                      projectId: project.id,
                    }),
                  }),
              },
              {
                label: `Manage Project: ${project.name} - Keyword Sources`,
                priority: QUICK_OPTION_PRIORITY_PROJECTS,
                value: () =>
                  navigate({
                    to: `/keyword-sources`,
                    search: prev => ({
                      ...prev,
                      projectId: project.id,
                    }),
                  }),
              },
              {
                label: `Manage Project: ${project.name} - Brands`,
                priority: QUICK_OPTION_PRIORITY_PROJECTS,
                value: () =>
                  navigate({
                    to: `/brands`,
                    search: prev => ({
                      ...prev,
                      projectId: project.id,
                    }),
                  }),
              },
              {
                label: `Manage Project: ${project.name} - Segments`,
                priority: QUICK_OPTION_PRIORITY_PROJECTS,
                value: () =>
                  navigate({
                    to: `/segments`,
                    search: prev => ({
                      ...prev,
                      projectId: project.id,
                    }),
                  }),
              },
            ])
            .flat(),
          {
            label: 'Manage Workspace Settings',
            priority: QUICK_OPTION_PRIORITY_WORKSPACE,
            value: () => {
              navigate({
                to: `/settings`,
              })
            },
          },
          {
            label: 'Manage Billing',
            priority: QUICK_OPTION_PRIORITY_WORKSPACE,
            value: () => {
              navigate({
                to: `/billing`,
              })
            },
          },
          {
            label: 'Manage Users',
            priority: QUICK_OPTION_PRIORITY_WORKSPACE,
            value: () => {
              navigate({ to: `/users/` })
            },
          },
          {
            label: 'Invite Users',
            priority: QUICK_OPTION_PRIORITY_WORKSPACE,
            value: () => {
              navigate({ to: `/invite` })
            },
          },
          {
            label: 'Manage Workspaces',
            priority: QUICK_OPTION_PRIORITY_WORKSPACE,
            value: () => {
              navigate({ to: `/workspaces` })
            },
          },
          ...['dark', 'light', 'auto']
            .map(d => ({
              label: `Switch Theme: ${d}`,
              priority: QUICK_OPTION_PRIORITY_UTILITY,
              value: () => {
                setThemeMode(d)
              },
            }))
            .flat(),
          profileQuery.data?.isAdmin
            ? {
                label: 'Admin',
                priority: QUICK_OPTION_PRIORITY_UTILITY,
                value: () => {
                  navigate({ to: `/admin` })
                },
              }
            : null,
          {
            label: 'Log Out',
            priority: QUICK_OPTION_PRIORITY_LOG_OUT,
            value: async () => {
              const confirmed = await confirm({
                message: 'Are you sure you want to log out?',
              })

              if (confirmed) {
                logout()
              }
            },
          },
        ].filter(Boolean),
      [
        confirm,
        logout,
        navigate,
        profileQuery.data?.isAdmin,
        setThemeMode,
        sidebarItems,
        projectsQuery.data,
      ]
    )
  )

  useQuickOptions(
    React.useMemo(
      () =>
        [
          // workspaces
          ...(profileQuery.data?.userWorkspaces || []).map(userWorkspace => ({
            label: `Switch Workspace: ${userWorkspace.workspace.name} (${userWorkspace.workspace.slug})`,
            priority: QUICK_OPTION_PRIORITY_SWITCH_WORKSPACE,
            value: () => {
              navigate({
                search: prev => ({
                  ...prev,
                  workspace: undefined,
                  workspaceSlug: undefined,
                  workspaceId: userWorkspace.workspace.id,
                }),
              })
            },
          })),
        ].filter(Boolean),
      [navigate, profileQuery.data?.userWorkspaces]
    )
  )
}

function useWorkspaceLogin() {
  const workspaceId = useActiveWorkspaceId()
  const profileQuery = useProfileQuery()
  const showModal = useModal()

  useQuery({
    queryKey: ['login', { userId: profileQuery.data?.id, workspaceId }],
    queryFn: () => fetchLogin(workspaceId),
    enabled: Boolean(workspaceId && profileQuery.data?.id),
  })

  const showWorkspaceSelection = React.useMemo(
    () =>
      profileQuery.data &&
      profileQuery.data.userWorkspaces.length &&
      (!workspaceId ||
        !profileQuery.data.userWorkspaces.find(
          d => d.workspace.id === workspaceId
        )),
    [profileQuery.data, workspaceId]
  )

  React.useEffect(() => {
    if (showWorkspaceSelection) {
      const modal = showModal(() => <DontBelongModal />)

      return () => modal.close()
    }
  }, [showModal, showWorkspaceSelection])
}

function DontBelongModal() {
  const profileQuery = useProfileQuery()
  const { close } = useModalContext()
  const navigate = useNavigate()
  const logout = useLogout()
  const userWorkspaces = profileQuery.data?.userWorkspaces

  const workspacesOptions = React.useMemo(() => {
    return sortBy(
      (userWorkspaces ?? []).map(d => ({
        value: d.workspace,
        label: d.workspace.name,
      })),
      d => d.label.toLowerCase()
    )
  }, [userWorkspaces])

  return (
    <Modal>
      <ModalTitle>Oh No!</ModalTitle>
      <ModalContent>
        <div>
          <div className="h-4" />
          <div className="text-center">
            <FaQuestionCircle className="inline-block text-blue-500" /> You do
            not belong to this workspace. Please choose a workspace that you
            belong to.
          </div>
          <div className="h-2" />
          <div className="mx-auto w-[300px] rounded-lg border border-gray-700">
            <Select
              options={workspacesOptions}
              inline
              onChange={value => {
                navigate({ to: `/`, search: { workspaceId: value.id } })
                close()
              }}
            />
          </div>
          <div className="h-2" />
          <div className="text-center text-sm">
            Can't find your workspace?{' '}
            <Clickable
              className="text-blue-300"
              onClick={() => {
                logout()
                close()
              }}
            >
              Switch accounts
            </Clickable>
          </div>
          <div className="h-8" />
        </div>
      </ModalContent>
    </Modal>
  )
}

function useWorkspaceInvitations() {
  const profileQuery = useProfileQuery()
  const confirm = useConfirm()
  const navigate = useNavigate()

  const newUserWorkspace = React.useMemo(() => {
    if (profileQuery.data?.isAdmin) {
      return undefined
    }

    const newUserWorkspaces = profileQuery.data?.userWorkspaces.filter(
      userWorkspace => {
        return !userWorkspace.firstLoggedInAt && !userWorkspace.deletedAt
      }
    )
    return newUserWorkspaces?.[0]
  }, [profileQuery.data?.isAdmin, profileQuery.data?.userWorkspaces])

  const removeUserById = useRemoveUserById({
    workspaceId: newUserWorkspace?.workspace.id,
  })

  React.useEffect(() => {
    async function run() {
      if (!newUserWorkspace) {
        return
      }

      const confirmed = await confirm({
        color: 'green-500',
        title: (
          <>
            You've been invited to the{' '}
            <strong>{newUserWorkspace.workspace.name}</strong> workspace!
          </>
        ),
        message: `You can either accept or deny this invitation. What will you do?!`,
        confirmText: 'Accept',
        cancelText: 'Deny',
      })

      if (confirmed) {
        navigate({
          to: '/',
          search: { workspaceId: newUserWorkspace.workspace.id },
        })
      } else {
        await removeUserById.mutateAsync(newUserWorkspace.userId)
      }
    }

    run()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirm, navigate, newUserWorkspace, removeUserById.mutateAsync])
}
