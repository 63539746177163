import * as React from 'react'

//
export default function useGetPrevious(value: any) {
  const ref = React.useRef()
  const previousRef = React.useRef()
  previousRef.current = ref.current

  React.useEffect(() => {
    ref.current = value
  })

  const fnRef = React.useRef(() => previousRef.current)

  return fnRef.current
}
