import { twMerge } from 'tailwind-merge'
import { useKeywordOptionsQuery } from '../hooks/keywords'
import Loader from './Loader'
import Select from './Select'
import { formatKeywordV1 } from '../utils/Format'
import InlineLabeledInput from './InlineLabeledInput'
import Tooltip from './Tooltip'
import { FaInfoCircle } from 'react-icons/fa'
import copyToClipboard from '../utils/copyToClipboard'
import useToast from '../hooks/useToast'
import { useKeywordIdState } from '../utils/searchParams'

export default function KeywordPicker({ className }: { className?: string }) {
  const keywordIdState = useKeywordIdState()
  const keywordOptionsQuery = useKeywordOptionsQuery()
  const toast = useToast()

  return keywordOptionsQuery.isLoading ? (
    <Loader color="gray-500" />
  ) : (
    <>
      <Select
        options={keywordOptionsQuery.data}
        value={keywordIdState.state}
        onChange={value => keywordIdState.setState(value)}
        placeholder="Select a keyword..."
        className={twMerge(`flex-auto text-sm`, className)}
        inlineLabel="Segment"
        renderOption={(option: { label: string; keyword?: any }) => {
          return option.keyword ? (
            <div>{formatKeywordV1(option.keyword, { tooltip: false })}</div>
          ) : (
            option.label
          )
        }}
        children={({ onClick, selectedOption }) => {
          return (
            <InlineLabeledInput
              label="Keyword"
              renderInput={
                <span>
                  {selectedOption.keyword
                    ? formatKeywordV1(selectedOption.keyword, {
                        flexWrap: true,
                      })
                    : 'Select a Keyword...'}
                </span>
              }
              onClick={onClick}
            />
          )
        }}
      />
      {keywordIdState.state ? (
        <Tooltip
          tooltip={
            <div className="space-y-1">
              <div>
                Keyord ID: <strong>{keywordIdState.state}</strong>
              </div>
              <div className="italic">(Click to Copy)</div>
            </div>
          }
        >
          <button
            onClick={() => {
              copyToClipboard(keywordIdState.state)
              toast({
                message: 'Keyword ID copied to clipboard.',
              })
            }}
            className="flex items-center gap-1 text-xs text-blue-500"
          >
            <FaInfoCircle /> Keyword ID
          </button>
        </Tooltip>
      ) : null}
    </>
  )
}

// <span className="px-2 text-lg ">Keyword ID:</span>
// <Input disabled={true} value={keywordIdState.value} />
