import * as React from 'react'
import { useKeywordGroupOptionsQuery } from '../hooks/keywords'
import { KeywordGroupAllKeywords } from '../utils/Constants'
import Button from './Button'
import Loader from './Loader'
import Select from './Select'
import Tooltip from './Tooltip'
import { useKeywordGroupState } from '../utils/searchParams'

type PropTypes = {
  multi?: boolean
}

export default function KeywordGroupPicker({ multi }: PropTypes) {
  const keywordGroupState = useKeywordGroupState()
  const keywordGroupOptionsQuery = useKeywordGroupOptionsQuery()

  return keywordGroupOptionsQuery.isLoading ? (
    <Loader color="gray-500" />
  ) : (
    <>
      {keywordGroupState.state !== KeywordGroupAllKeywords ? (
        <Tooltip
          tooltip={
            <>
              Reset the keyword group back to the default (
              {KeywordGroupAllKeywords}).
            </>
          }
        >
          <Button
            size="xs"
            color="yellow-500"
            onClick={() =>
              keywordGroupState.setState(KeywordGroupAllKeywords, {
                replace: true,
              })
            }
          >
            Reset
          </Button>
        </Tooltip>
      ) : null}
      <Select
        multi={multi}
        create={keywordGroupOptionsQuery.isLoading}
        options={keywordGroupOptionsQuery.data}
        value={keywordGroupState.state}
        onChange={value =>
          keywordGroupState.setState(value, {
            replace: true,
          })
        }
        placeholder="Select a keyword group..."
        className="flex-auto text-sm"
        inlineLabel="Keyword Group"
      />
    </>
  )
}
