import * as React from 'react'
import { formatSlug } from '../utils/Format'

//

export default function useSlugGeneration(
  enable: any,
  nameField: any,
  slugField: any
) {
  React.useEffect(() => {
    if (!enable) {
      return
    }

    const autoSlug = formatSlug(nameField.value)

    if (slugField.value !== autoSlug) {
      slugField.setValue(autoSlug, { isTouched: false })
    }
  }, [
    enable,
    nameField.value,
    slugField,
    slugField.meta.isTouched,
    slugField.value,
  ])
}
