import { MoneyPb } from "./proto"


export function moneyToFloat(money?: MoneyPb) {
  if (!money) {
    return 0
  }

  return Number(money.units) + Number(money.nanos) / 1e9
}

export function floatToMoney(float: number, currencyCode = 'usd') {
  return new MoneyPb({
    units: BigInt(unitsFromFloat(float)),
    nanos: nanosFromFloat(float),
    currencyCode,
  })
}

export function unitsFromFloat(float: number) {
  if (float === undefined) {
    return 0
  }
  return Math.floor(float)
}

export function nanosFromFloat(float: number) {
  if (float === undefined) {
    return 0
  }
  return Math.round((float - unitsFromFloat(float)) * 1e9)
}
