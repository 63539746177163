import * as React from 'react'
import { useForm } from 'react-form'
import { FaInfoCircle, FaPlus, FaSearch, FaTimesCircle } from 'react-icons/fa'
import { Brand } from '../../openapi'
import Condition from '../components/Condition'
import {
  HeaderRow,
  TableCell,
  TableEl,
  TableRow,
  TableWrapInner,
  TableWrapOuter,
} from '../components/Table'
import { propertyTypes } from '../containers/brands/BrandForm'
import useModalContext from '../hooks/useModalContext'
import { fetchBrandInfo } from '../utils/Api'
import { BRAND_PROPERTY_TYPE_CUSTOM } from '../utils/Constants'
import Validate from '../utils/Validate'
import Button from './Button'
import Caption from './Caption'
import { Modal, ModalContent, ModalTitle } from './Modals'
import Spinner from './Spinner'
import TextField from './TextField'

export default function BrandSearchModal({
  onChange,
  cancelText,
}: {
  onChange: (brand: Brand) => void
  cancelText?: React.ReactNode
}) {
  const { close } = useModalContext()
  const [brandInfo, setBrandInfo] = React.useState<any>(null)
  const [loading, setLoading] = React.useState(false)
  const [searched, setSearched] = React.useState(false)

  // @ts-expect-error  // Property 'getFieldValue' does not exist on type '{... Remove this comment to see the full error message
  const { Form, getFieldValue } = useForm({
    onSubmit: async values => {
      setLoading(true)
      // @ts-expect-error  // Object is of type 'unknown'.
      const brandInfo = await fetchBrandInfo(values.domain)

      setBrandInfo(brandInfo)
      setLoading(false)
      setSearched(true)
    },
  })

  const addBrandInfo = () => {
    onChange(brandInfo)
    close()
  }

  return (
    <Modal>
      <ModalTitle>Add Brand Websites</ModalTitle>
      <ModalContent>
        <div className="space-y-4 p-8">
          <Form className="space-y-2">
            <div>
              <TextField
                label="Domain"
                field="domain"
                placeholder="Enter a Domain..."
                validate={Validate.required(
                  'A domain is required in order to search'
                )}
              />
              <Caption>
                <FaInfoCircle className="inline" /> Search to find websites that
                are associated with a domain. If you'd rather enter them
                manually, just press skip.
              </Caption>
            </div>
            <div className="flex gap-2">
              <Button
                size="sm"
                color="blue-500"
                className="mr-1"
                type="submit"
                disabled={getFieldValue('domain') == null}
              >
                {loading ? (
                  <Spinner color="white" />
                ) : (
                  <>
                    <FaSearch className="inline" /> Search
                  </>
                )}
              </Button>
            </div>
          </Form>
          {brandInfo?.properties?.length ? (
            <>
              <TableWrapOuter>
                <TableWrapInner>
                  <TableEl>
                    <HeaderRow>
                      <th>Type</th>
                      <th>Name</th>
                      <th>Configuration</th>
                    </HeaderRow>
                    <tbody>
                      {brandInfo?.properties?.map(
                        (property: any, index: any) => {
                          const propertyType = propertyTypes.find(
                            d => d.type === property.type
                          ) as any

                          return (
                            <TableRow key={index}>
                              <TableCell tight={false}>
                                {propertyType.name}
                              </TableCell>
                              <TableCell tight={false}>
                                {property.name}
                              </TableCell>
                              <TableCell tight={false}>
                                {(() => {
                                  if (
                                    property.type === BRAND_PROPERTY_TYPE_CUSTOM
                                  ) {
                                    return (
                                      <Condition
                                        {...{
                                          condition: property.condition,
                                          stage: 'result',
                                          // onChange: newCondition =>
                                          //   form.setFieldValue(
                                          //     `properties[${index}].condition`,
                                          //     newCondition
                                          //   ),
                                          // teamId: props.teamId,
                                          canRemove: true,
                                          transactional: false,
                                          // error: validateCondition()(
                                          //   property.condition
                                          // ),
                                        }}
                                      />
                                    )
                                  }

                                  return property.value
                                })()}
                              </TableCell>
                            </TableRow>
                          )
                        }
                      )}
                    </tbody>
                  </TableEl>
                </TableWrapInner>
              </TableWrapOuter>
            </>
          ) : searched ? (
            <div>No websites have been found.</div>
          ) : null}
          <div>
            <Button
              size="base"
              color="green-500"
              className="mr-1"
              onClick={() => addBrandInfo()}
              disabled={!brandInfo?.properties?.length}
            >
              <FaPlus className="inline" />
              Add Properties
            </Button>
            <Button
              size="base"
              color="gray-500"
              hoverColor="red-500"
              onClick={close}
            >
              <FaTimesCircle className="inline" />
              {cancelText ?? 'Cancel'}
            </Button>
          </div>
        </div>
      </ModalContent>
    </Modal>
  )
}
