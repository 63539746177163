import * as React from 'react'
import { Navigate } from '@tanstack/react-router'
import { useProfileQuery } from '../hooks/profile'

export function Home() {
  const profileQuery = useProfileQuery()

  if (profileQuery.isLoading) {
    return null
  }

  if (profileQuery.data) {
    return <Navigate to="/projects" />
  }

  return null
}
