import { twMerge } from 'tailwind-merge'
import { withProps } from '../utils/withProps'

export const Badge = withProps('div')(({ className, ...rest }) => {
  return {
    ...rest,
    className: twMerge(
      `
      mx-[0.25em] inline-flex items-center justify-center
      rounded p-[.35em] text-black opacity-30 transition
      duration-1000 ease-in-out [font-size:.6em] [box-shadow:0_0_0_1px_rgba(0,0,0,.2)]
      [transform:scale(1.3)] hover:opacity-100 dark:text-white
      dark:[box-shadow:0_0_0_1px_rgba(255,255,255,.2)]
      `,
      className
    ),
  }
})

// font-size: 0.6em;
// box-shadow: rgb(0 0 0 / 20%) 0px 0px 0px 1px;
// display: inline-flex;
// align-items: center;
// justify-content: center;
// padding-left: 0.15rem;
// padding-right: 0.15rem;
// aspect-ratio: 1 / 1;
// border-radius: 0.25rem;
// --tw-text-opacity: 1;
// color: rgba(9,14,16,var(--tw-text-opacity));
// opacity: 0.3;
// transform: scale(1.3);
// margin: 0 0.25rem;
