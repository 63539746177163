import { useForm } from '@tanstack/react-form'
import { LimitDepth } from '../../utils/types'
import { useMatch, useSearch } from '@tanstack/react-router'
import * as React from 'react'
import { FaInfoCircle } from 'react-icons/fa'
import { useQuery } from 'react-query'
import Caption from '../../components/Caption'
import { DevicesModelInput } from '../../components/DevicesModelInput'
import ErrorBoundary from '../../components/ErrorBoundary'
import { GeneratedKeywords } from '../../components/GeneratedKeywords'
import {
  DevicesHelp,
  LocalesHelp,
  ScheduleHelp,
} from '../../components/KeywordSourceKeywordsBasic'
import { KeywordSourceButtons } from '../../components/KeywordSourceSaveButton'
import LabelWrap from '../../components/LabelWrap'
import LabeledInput from '../../components/LabeledInput'
import { LocalesModelInput } from '../../components/LocalesModelInput'
import { PhraseGroupsDataModelInput } from '../../components/PhraseGroupsDataModelInput'
import { PhraseTemplatesInput } from '../../components/PhraseTemplatesInput'
import { SchedulesInput } from '../../components/SchedulesInput'
import {
  KeywordSourceWithPhrases,
  deviceModelsToPb,
  deviceModelFromPb,
  localeModelsToPb,
  localePbToModel,
  phraseTemplateFromPb,
  phraseTemplatesToPb,
  scheduleConfigsToPb,
  useSaveKeywordSourceV2,
  useValidateKeywordSource,
  useRemoveKeywordSourceById,
} from '../../hooks/keywordSources'
import useAsyncDebounce from '../../hooks/useAsyncDebounce'
import usePauseEvents from '../../hooks/usePauseEvents'
import useToast from '../../hooks/useToast'
import { useActiveWorkspaceId } from '../../hooks/workspaces'
import Validate from '../../utils/Validate'
import { rruleToScheduleConfig } from '../../utils/schedules'
import { GeneratedKeywordsTableV2 } from './GeneratedKeywordsTableV2'
import {
  DataModel2Pb,
  DevicePb,
  GenerateKeywordsRequestPb,
  KeywordSourceDataModelConfig2Pb,
  KeywordSourcePb,
  KeywordSourcesClient,
} from '../../utils/proto'
import { dataModelFromPb } from '../../utils/dataModels'
import { ModelPhraseGroup } from '../../utils/Phrases'
import { LocationGenerics } from '../../LocationGenerics'
import useConfirm from '../../hooks/useConfirm'
import { useActiveProjectIdState } from '../../utils/searchParams'
import Card from '../../components/Card'

//

const defaultValues: KeywordSourceWithPhrases = {
  name: '',
  schedules: [
    {
      id: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
    },
    {
      id: 'FREQ=WEEKLY;BYDAY=MO,WE,FR;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
    },
    {
      id: 'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
    },
    {
      id: 'FREQ=MONTHLY;BYMONTHDAY=1,15;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
    },
    {
      id: 'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
    },
  ],
  devices: [
    {
      device: DevicePb.DESKTOP_WINDOWS,
      mostFrequentScheduleId:
        'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
    },
    {
      device: DevicePb.MOBILE_IOS,
      mostFrequentScheduleId: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
    },
  ],
  locales: [
    {
      localeId: 38283,
      aliases: [
        {
          alias: 'ny',
          groups: ['City Abbreviation'],
          mostFrequentScheduleId:
            'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
        },
        {
          alias: 'nyc',
          groups: ['City Abbreviation'],
          mostFrequentScheduleId:
            'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
        },
        {
          alias: 'new york',
          groups: [],
          mostFrequentScheduleId: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
        },
      ],
      mostFrequentScheduleId: null,
    },
    {
      localeId: 14964,
      mostFrequentScheduleId: null,
      aliases: [
        {
          alias: 'london',
          mostFrequentScheduleId:
            'FREQ=WEEKLY;BYDAY=MO,WE,FR;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
        },
      ],
    },
    {
      localeId: 36430,
      aliases: [
        {
          alias: 'la',
          groups: ['City Abbreviation'],
          mostFrequentScheduleId: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
        },
        {
          alias: 'los angeles',
          groups: [],
          mostFrequentScheduleId:
            'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
        },
      ],
      mostFrequentScheduleId: null,
      groups: [],
    },
  ],
  phraseGroups: [
    {
      phrase: 'coffee',
      groups: ['Coffee'],
      mostFrequentScheduleId: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
    },
    {
      phrase: 'pizza',
      groups: ['Pizza'],
      mostFrequentScheduleId: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
    },
  ],
  phraseTemplates: [
    {
      template: '{{phrase}}',
      groups: ['No Template'],
      mostFrequentScheduleId: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
    },
    {
      template: '{{phrase}} near me',
      groups: ['Near Me'],
      mostFrequentScheduleId:
        'FREQ=WEEKLY;BYDAY=MO,WE,FR;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
    },
    {
      template: '{{phrase}} in {{locale}}',
      groups: ['In locale', 'Locale'],
      mostFrequentScheduleId:
        'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
    },
    {
      template: '{{phrase}} near {{locale}}',
      groups: ['Near Locale', 'Locale'],
      mostFrequentScheduleId:
        'FREQ=MONTHLY;BYMONTHDAY=1,15;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
    },
  ],
}

function createDataModelConfig(keywordSource: KeywordSourceWithPhrases) {
  return new KeywordSourceDataModelConfig2Pb({
    phraseTemplates: phraseTemplatesToPb(keywordSource.phraseTemplates),
    deviceModels: deviceModelsToPb(keywordSource.devices),
    localeModels: localeModelsToPb(keywordSource.locales),
    keywordSourceDataModel: new DataModel2Pb({
      hierarchy: [
        {
          name: 'Phrase',
          templateSlug: 'phrase',
        },
      ],
      entries: keywordSource.phraseGroups.map(d => {
        return {
          name: d.phrase,
          values: [
            {
              value: d.phrase,
              keywordGroups: d.groups,
            },
          ],
        }
      }),
    }),
  })
}

export function KeywordSourceTemplate(props: {
  keywordSource?: KeywordSourcePb
}) {
  const projectId = useActiveProjectIdState().state
  const activeWorkspaceId = useActiveWorkspaceId()!
  const saveKeywordSourceV2 = useSaveKeywordSourceV2()
  const validateKeywordSource = useValidateKeywordSource()
  usePauseEvents('keywordSource', props.keywordSource?.id, true)
  const debounce = useAsyncDebounce()
  const toast = useToast()
  const removeKeywordSourceById = useRemoveKeywordSourceById()
  const confirm = useConfirm()

  const form = useForm<LimitDepth<KeywordSourceWithPhrases>>({
    defaultValues: React.useMemo(() => {
      const dataModel = (
        props.keywordSource?.config.value as KeywordSourceDataModelConfig2Pb
      )?.keywordSourceDataModel

      return props.keywordSource
        ? {
            id: Number(props.keywordSource.id),
            name: props.keywordSource.name,
            devices:
              props.keywordSource.config.value?.deviceModels.map(
                deviceModelFromPb
              ) ?? [],
            locales:
              props.keywordSource.config.value?.localeModels.map(
                localePbToModel
              ) ?? [],
            phraseTemplates:
              props.keywordSource.config.value?.phraseTemplates.map(
                phraseTemplateFromPb
              ) ?? [],
            schedules:
              props.keywordSource.schedules.map(d =>
                rruleToScheduleConfig(d.rrule as any)
              ) ?? [],
            phraseGroups:
              dataModel?.entries?.map((value): ModelPhraseGroup => {
                return {
                  phrase: value.values[0]?.value as string,
                  mostFrequentScheduleId: value.values[0]?.mostFrequentSchedule
                    ?.rrule as string,
                  groups: value.values[0]?.keywordGroups ?? [],
                }
              }) ?? [],
          }
        : defaultValues
    }, [props.keywordSource]),
    validate: values => {
      return validateKeywordSource({
        values,
        onHourlyWarningDismissed: () => {
          form.setFieldValue(
            'schedules',
            values.schedules?.filter(
              schedule => schedule?.id !== 'FREQ=HOURLY;BYMINUTE=0;BYSECOND=0'
            )
          )
        },
      })
    },
    onInvalidSubmit: () => {
      toast({
        color: 'red-500',
        message:
          form.state.formError ||
          'Some fields are invalid. Please check the form and try again.',
      })
    },
    onSubmit: async (keywordSource: KeywordSourceWithPhrases) => {
      return saveKeywordSourceV2(
        new KeywordSourcePb({
          id: keywordSource.id ? BigInt(keywordSource.id) : undefined,
          name: keywordSource.name,
          projectId: BigInt(projectId),
          workspaceId: BigInt(activeWorkspaceId),
          schedules: scheduleConfigsToPb(keywordSource.schedules),
          config: {
            case: 'keywordSourceConfig',
            value: createDataModelConfig(keywordSource),
          },
        })
      )
    },
  })

  // Listen to the schedules field
  const schedules = form.useStore(d => d.values.schedules)

  const keyDown: React.KeyboardEventHandler<HTMLFormElement> = e => {
    if (e.key === 'Enter' && e.currentTarget?.type !== 'textarea') {
      e.preventDefault()
    }
  }

  return (
    <form.Form onKeyDown={keyDown} className="space-y-2">
      {/* <pre className="text-xs">{JSON.stringify(values, null, 2)}</pre> */}
      <Card className="flex flex-col gap-16 p-4">
        <div>
          <div className="flex gap-2 [&>*]:flex-1">
            <div>
              <form.Field
                name="name"
                validate={Validate.required(
                  'A Keyword Source name is required.'
                )}
                children={field => {
                  return (
                    <LabeledInput
                      label="Keyword Source Name"
                      placeholder="e.g. US English Mobile"
                      error={field.state.meta.touchedError}
                      {...field.getInputProps()}
                    />
                  )
                }}
              />
              <div className="h-2" />
              <Caption className="font-normal">
                <FaInfoCircle className="inline" /> It's helpful to be specific
                when giving your keyword source a name. For example, “US English
                Mobile”, “France - French - Desktop & Mobile”, etc. Don’t worry,
                you can change it later.
              </Caption>
              <div className="h-2" />
            </div>
            {/* {values.id ? (
              <div>
                <TextField
                  name="id"
                  label="Keyword Source ID"
                  placeholder="-"
                  disabled
                />
              </div>
            ) : null} */}
          </div>
        </div>
      </Card>
      <Card className="space-y-2 p-2">
        <div className="flex flex-wrap justify-between gap-2">
          <div className="text-xl font-bold">Schedules</div>
          <ScheduleHelp />
        </div>
        <form.Field
          name="schedules"
          children={field => {
            return (
              <LabelWrap
                label="Schedules"
                error={field.state.meta.touchedError}
              >
                <SchedulesInput {...field.getChangeProps()} />
              </LabelWrap>
            )
          }}
        />
      </Card>
      <Card className="space-y-2 p-2">
        <div className="flex flex-wrap justify-between gap-2">
          <div className="text-xl font-bold">Devices</div>
          <DevicesHelp />
        </div>
        <form.Field
          name="devices"
          validate={devices =>
            !devices?.length && 'At least one device is required.'
          }
          children={field => {
            return (
              <LabelWrap error={field.state.meta.touchedError}>
                <DevicesModelInput
                  schedules={schedules}
                  {...field.getChangeProps()}
                />
              </LabelWrap>
            )
          }}
        />
      </Card>
      <Card className="space-y-2 p-2">
        <div className="flex flex-wrap justify-between gap-2">
          <div className="text-xl font-bold">Locales</div>
          <LocalesHelp />
        </div>
        <form.Field
          name="locales"
          validate={locales =>
            !locales?.length && 'At least one locale is required.'
          }
          children={field => {
            return (
              <LabelWrap error={field.state.meta.touchedError}>
                <LocalesModelInput
                  schedules={schedules}
                  {...field.getChangeProps()}
                />
              </LabelWrap>
            )
          }}
        />
      </Card>
      <Card className="space-y-2 p-2">
        <div className="flex flex-wrap justify-between gap-2">
          <div className="text-xl font-bold">Phrases</div>
          {/* <PhrasesHelp /> */}
        </div>
        <form.Field
          name="phraseGroups"
          children={field => {
            return (
              <LabelWrap error={field.state.meta.touchedError}>
                <PhraseGroupsDataModelInput
                  schedules={schedules}
                  {...field.getChangeProps()}
                />
              </LabelWrap>
            )
          }}
        />
      </Card>
      <Card className="space-y-2 p-2">
        <div className="flex flex-wrap justify-between gap-2">
          <div className="text-xl font-bold">Templates</div>
          {/* <TemplatesHelp /> */}
        </div>
        <form.Field
          name="phraseTemplates"
          children={field => {
            return (
              <LabelWrap error={field.state.meta.touchedError}>
                <PhraseTemplatesInput
                  schedules={schedules}
                  {...field.getChangeProps()}
                />
              </LabelWrap>
            )
          }}
        />
      </Card>

      <form.Subscribe
        selector={state => state.values}
        children={function Children(values) {
          const generateKeywordsPayload = React.useMemo(
            () =>
              new GenerateKeywordsRequestPb({
                workspaceId: BigInt(activeWorkspaceId),
                schedules: scheduleConfigsToPb(values.schedules),
                config: {
                  case: 'keywordSourceConfig',
                  value: createDataModelConfig(values),
                },
              }),
            [values]
          )

          const generateKeywordsQuery = useQuery(
            [
              'generateKeywords',
              { workspaceId: activeWorkspaceId, projectId, values },
            ],
            () =>
              debounce(
                () =>
                  KeywordSourcesClient.generateKeywords(
                    generateKeywordsPayload
                  ),
                3000
              ),
            {
              keepPreviousData: true,
            }
          )

          const generatedKeywords = React.useMemo(() => {
            return generateKeywordsQuery.data?.keywords ?? []
          }, [generateKeywordsQuery.data?.keywords])

          return (
            <Card className="p-2">
              <div className="space-y-2">
                <div className="flex flex-wrap justify-between gap-2">
                  <GeneratedKeywords
                    generatedKeywords={generatedKeywords}
                    isFetching={generateKeywordsQuery.isFetching}
                  />
                </div>
                <div>
                  <ErrorBoundary>
                    <GeneratedKeywordsTableV2
                      generatedKeywords={generatedKeywords}
                      isFetching={generateKeywordsQuery.isFetching}
                    />
                  </ErrorBoundary>
                </div>
              </div>
            </Card>
          )
        }}
      />

      <Card className="sticky bottom-0 z-[1000000] bg-white p-4 dark:bg-gray-900">
        <form.Subscribe
          selector={state => ({
            canSubmit: state.canSubmit,
            isSubmitting: state.isSubmitting,
          })}
          children={({ canSubmit, isSubmitting }) => (
            <div className="item-center flex flex-wrap gap-2">
              <KeywordSourceButtons
                canSubmit={canSubmit}
                hasPhrases
                isSubmitting={isSubmitting}
                onRemove={
                  props.keywordSource?.id
                    ? async () => {
                        if (
                          await confirm({
                            title:
                              'Are you sure you want to delete this source?',
                            message: `This will permanently delete this keyword source and all of its keywords. This action cannot be undone.`,
                            confirmText: 'Yes, Delete',
                            cancelText: 'Cancel',
                          })
                        ) {
                          removeKeywordSourceById(props.keywordSource!.id)
                        }
                      }
                    : undefined
                }
              />
            </div>
          )}
        />
      </Card>
    </form.Form>
  )
}
