import { useNavigate, useSearch } from '@tanstack/react-router'
import { createColumnHelper } from '@tanstack/react-table'
import { default as moment } from 'moment'
import * as React from 'react'
import { FaCaretDown, FaEdit, FaPlus, FaTrashAlt } from 'react-icons/fa'
import { useMutation, useQuery } from 'react-query'

import { ButtonPlain } from '../../components/ButtonPlain'
import { default as Card } from '../../components/Card'
import { default as Link } from '../../components/Link'
import Select from '../../components/Select'
import Spinner from '../../components/Spinner'
import Table from '../../components/Table'
import { default as Tooltip } from '../../components/Tooltip'
import { TopicPicker } from '../../components/TopicPicker'
import { default as useConfirm } from '../../hooks/useConfirm'
import { useTable } from '../../hooks/useTable'
import { useActiveWorkspaceId } from '../../hooks/workspaces'
import { formatPriority, priorityOptions } from '../../options/priority'
import { formatNumber } from '../../utils/Format'
import { TopicPb } from '../../utils/proto'
import {
  TOPIC_KEY,
  fetchTopicById,
  useBatchRemoveTopicsByIdMutation,
  useBatchUpdateTopicsMutation,
  useMergeWithTopicIdMutation,
  useTopicsQuery,
} from '../../utils/topics'

export function TopicsTable({
  workspaceId,
  projectId,
}: {
  workspaceId: string
  projectId: string
}) {
  const search = useSearch()
  const confirm = useConfirm()
  const navigate = useNavigate()

  const expandedTopicIds = React.useMemo(
    () => search.expandedTopicIds ?? {},
    [search.expandedTopicIds]
  )

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<TopicPb>()

    return [
      columnHelper.accessor(row => row.name, {
        header: 'Topic',
        cell: props => {
          const row = props.row.original
          return <Link to={`/topics/${row.id}`}>{props.getValue()}</Link>
        },
        meta: {
          checkbox: true,
          expander: true,
        },
      }),
      columnHelper.accessor(row => formatPriority(row.priority), {
        header: 'Priority',
      }),
      columnHelper.accessor(row => row.mappedUrlsByBrand.length, {
        header: 'Mapped Urls',
        cell: props => formatNumber(props.getValue()),
      }),
      columnHelper.accessor(row => row.mappedKeywords.length, {
        header: 'Mapped Keywords',
        cell: props => formatNumber(props.getValue()),
      }),
      columnHelper.accessor(row => String(row.id), {
        header: 'ID',
      }),
      columnHelper.accessor(row => row.updatedAt?.toDate(), {
        header: 'Last Updated',
        cell: props => {
          const value = moment(props.getValue())
          return (
            <Tooltip tooltip={value.format('lll')}>{value.fromNow()}</Tooltip>
          )
        },
      }),
      columnHelper.accessor(row => row.createdAt?.toDate(), {
        header: 'Date Created',
        cell: props => {
          const value = moment(props.getValue())
          return (
            <Tooltip tooltip={value.format('lll')}>{value.fromNow()}</Tooltip>
          )
        },
      }),
    ]
  }, [])

  // const filtersPb = filtersPbFromSearch<
  //   Record<string, ListTopicsFiltersPb[keyof ListTopicsFiltersPb]>
  // >(monitoredUrlFiltersList, search.monitoredUrlsFilters || {}, {
  //   searchKey: 'search',
  // })

  const topicsQuery = useTopicsQuery({
    workspaceId,
    projectId,
    rootOnly: true,
    // view: TopicViewPb.BASIC,
    descendantDepth: -1,
    // viewOptions: new TopicViewOptionsPb({
    //   ancestorDepth: 0,
    //   descendentDepth: 1,
    // }),
    // filters: {
    //   search: 'blog',
    // },
  })

  const fullTopicsQuery = useQuery({
    queryKey: [
      TOPIC_KEY,
      'fullTopics',
      {
        workspaceId,
        projectId,
        // view: TopicViewPb.BASIC,
        expandedTopicIds,
        updatedAt: topicsQuery.dataUpdatedAt,
      },
    ],
    keepPreviousData: true,
    queryFn: async () => {
      const recurseForData = async (items: TopicPb[]): Promise<TopicPb[]> => {
        return Promise.all(
          items.map(async d => {
            const isExpanded = expandedTopicIds[String(d.id)]

            if (!d.children?.length && isExpanded) {
              d = await fetchTopicById({
                topicId: String(d.id),
                projectId,
                workspaceId,
                // view: TopicViewPb.BASIC,
                // viewOptions: new TopicViewOptionsPb({
                //   ancestorDepth: 0,
                //   descendentDepth: 1,
                // }),
              }).then(d => d.topic!)
            }

            if (d.children) {
              d.children = await recurseForData(d.children)
            }

            return d
          })
        )

        return items
      }

      return await recurseForData(topicsQuery.data?.topics || [])
    },
  })

  const tableData = React.useMemo(
    () => fullTopicsQuery.data ?? [],
    [fullTopicsQuery.data]
  )

  const table = useTable({
    data: tableData,
    columns,
    getSubRows: d => d.children,
    selectChildrenMode: 'groupedRows',
    showFilters: false,
    showGroupBy: false,
    virtualize: true,
    manualSorting: true,
    manualFiltering: true,
    manualPagination: true,
    enableSortingRemoval: false,
    initialState: {
      sorting: [
        { id: 'type', desc: true },
        { id: 'name', desc: false },
      ],
    },
  })

  const selectedRows = table.getSelectedRowModel().flatRows

  const batchUpdateTopicsMutation = useBatchUpdateTopicsMutation()

  const batchUpdate = async <T extends keyof TopicPb>(
    field: T,
    value: TopicPb[T]
  ) => {
    await batchUpdateTopicsMutation.mutateAsync({
      topics: selectedRows.map(d => ({
        ...d.original,
        [field]: value,
      })),
    })
  }

  const batchRemoveTopicsByIdMutation = useBatchRemoveTopicsByIdMutation()

  const batchDelete = async () => {
    if (
      await confirm({
        message: `Are you sure you want to remove ${formatNumber(
          selectedRows.length
        )} topics?`,
      })
    ) {
      await batchRemoveTopicsByIdMutation.mutateAsync({
        workspaceId: BigInt(workspaceId),
        topicIds: selectedRows.map(d => d.original.id),
      })
      // table.resetRowSelection()
    }
  }

  return (
    <Card className="flex min-h-0 flex-1 flex-col divide-y divide-gray-500/20 p-0">
      <div className="flex flex-wrap items-center gap-2 p-2">
        <Link to="/topics/new">
          <ButtonPlain className="bg-green-500">
            <FaPlus className="inline" /> Topic
          </ButtonPlain>
        </Link>
        {/* <Link to="/topics/import">
          <ButtonPlain className="bg-blue-500">
            <TbPackageImport className="inline" /> Import
          </ButtonPlain>
        </Link> */}
        {/* <ButtonPlain
          className="bg-gray-500"
          onClick={() => showAutoParentUrlsModal()}
        >
          <TbWand /> Auto-Parent URLs
        </ButtonPlain> */}
      </div>
      <Table
        table={table}
        beforeTable={
          selectedRows.length ? (
            <div className="flex items-center divide-x divide-gray-500/20">
              <div className="px-3 py-2">
                <strong>{formatNumber(selectedRows.length)}</strong> items
                selected
              </div>
              <div className="flex flex-wrap items-center gap-2 px-3 py-2">
                <ButtonPlain
                  className="border border-red-500 bg-transparent text-xs text-red-500"
                  onClick={batchDelete}
                >
                  <FaTrashAlt /> Delete
                </ButtonPlain>
                <TopicPicker
                  projectId={projectId}
                  onChange={async value => {
                    batchUpdate('parentTopicId', BigInt(value))
                  }}
                  children={({ onClick }: any) => (
                    <ButtonPlain
                      className="bg-gray-500/20 text-xs text-black hover:bg-gray-500/30 dark:text-white"
                      onClick={onClick}
                    >
                      Parent Topic <FaCaretDown />
                    </ButtonPlain>
                  )}
                />
                <Select
                  options={priorityOptions}
                  onChange={async value => {
                    batchUpdate('priority', value)
                  }}
                  children={({ onClick }) => (
                    <ButtonPlain
                      className="bg-gray-500/20 text-xs text-black hover:bg-gray-500/30 dark:text-white"
                      onClick={onClick}
                    >
                      Priority <FaCaretDown />
                    </ButtonPlain>
                  )}
                />
              </div>
            </div>
          ) : null
        }
      />
    </Card>
  )
}

export function TopicsTableOld() {
  const search = useSearch()
  const projectId = search.projectId!
  const activeWorkspaceId = useActiveWorkspaceId()!

  const topicsQuery = useTopicsQuery({
    workspaceId: activeWorkspaceId,
    projectId,
    descendantDepth: -1,
    rootOnly: true,
  })

  const tableRows = React.useMemo(() => {
    return topicsQuery.data?.topics ?? []
  }, [topicsQuery.data?.topics])

  const confirm = useConfirm()

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<TopicPb>()

    return [
      columnHelper.accessor('name', {
        id: 'Name',
        header: 'Topic Name',
        filterFn: 'fuzzy',
        meta: {
          checkbox: true,
          expander: true,
        },
        cell: props => (
          <Link to={`/topics/${props.row.original.id}`}>
            {props.getValue()}
          </Link>
        ),
      }),
      columnHelper.accessor(d => formatPriority(d.priority), {
        id: 'Priority',
        header: 'Priority',
        filterFn: 'arrIncludesSome',
        meta: {
          showQuickFilter: true,
        },
      }),
      columnHelper.accessor(d => d.mappedUrlsByBrand.length, {
        id: 'Mapped Urls',
        header: 'Mapped Urls',
        filterFn: 'inNumberRange',
      }),
      columnHelper.accessor(d => d.mappedKeywords.length, {
        id: 'Mapped Keywords',
        header: 'Mapped Keywords',
        filterFn: 'inNumberRange',
      }),
      columnHelper.accessor('id', {
        id: 'ID',
        header: 'ID',
      }),
      columnHelper.accessor(d => d.updatedAt?.toDate(), {
        id: 'Last Updated',
        header: 'Last Updated',
        cell: props => (
          <Tooltip tooltip={moment(props.getValue()).format('lll')}>
            {moment(props.getValue()).fromNow()}
          </Tooltip>
        ),
      }),
      columnHelper.accessor(d => d.createdAt?.toDate(), {
        id: 'Date Created',
        header: 'Date Created',
        cell: props => (
          <Tooltip tooltip={moment(props.getValue()).format('lll')}>
            {moment(props.getValue()).fromNow()}
          </Tooltip>
        ),
      }),
    ]
  }, [])

  const getSubRows = React.useCallback((d: any) => d.children, [])

  const table = useTable({
    data: tableRows,
    getSubRows,
    columns,
    selectChildrenMode: 'groupedRows',
    initialState: {
      expanded: true,
      pagination: {
        pageSize: 20,
      },
      sorting: [{ id: 'Name', desc: false }],
    },
    defaultColumn: React.useMemo(
      () => ({
        aggregatedCell: () => null,
      }),
      []
    ),
  })

  const selectedRows = table.getSelectedRowModel().flatRows

  const batchUpdateTopicsMutation = useBatchUpdateTopicsMutation()

  const batchUpdateField = async <T extends keyof TopicPb>(
    field: T,
    value: TopicPb[T]
  ) => {
    await batchUpdateTopicsMutation.mutateAsync({
      topics: selectedRows.map(d => ({
        ...d.original,
        [field]: value,
      })),
    })
  }

  const batchUpdateParentTopicIdMutation = useMutation((topicId: string) =>
    batchUpdateField('parentTopicId', BigInt(topicId))
  )

  const batchUpdatePriorityMutation = useMutation((priority: number) =>
    batchUpdateField('priority', priority)
  )

  const batchRemoveTopicsByIdMutation = useBatchRemoveTopicsByIdMutation()

  const mergeWithTopicIdMutation = useMergeWithTopicIdMutation()

  return (
    <div className="space-y-2 py-4">
      <div className="px-4">
        <Card className="divide-y divide-gray-500/20 p-0">
          <div className="flex items-center justify-between gap-4 p-2">
            <Link to="/topics/new">
              <ButtonPlain className="bg-green-500">
                <FaPlus className="inline" /> Create Topic
              </ButtonPlain>
            </Link>
          </div>
          <Table
            table={table}
            beforeTable={
              selectedRows.length ? (
                <div className="flex items-center divide-x divide-gray-500/20">
                  <div className="px-3 py-2">
                    <strong>{formatNumber(selectedRows.length)}</strong> items
                    selected
                  </div>
                  <div className="flex flex-wrap items-center gap-2 px-3 py-2">
                    <ButtonPlain
                      className="border border-red-500 bg-transparent text-xs text-red-500"
                      onClick={async () => {
                        if (
                          await confirm({
                            message: `Are you sure you want to remove ${formatNumber(
                              selectedRows.length
                            )} topics?`,
                          })
                        ) {
                          await batchRemoveTopicsByIdMutation.mutateAsync({
                            workspaceId: BigInt(activeWorkspaceId),
                            topicIds: selectedRows.map(d => d.original.id),
                          })
                          table.resetRowSelection()
                        }
                      }}
                    >
                      {batchRemoveTopicsByIdMutation.isLoading ? (
                        <Spinner />
                      ) : (
                        <FaTrashAlt />
                      )}{' '}
                      Delete
                    </ButtonPlain>
                    <TopicPicker
                      projectId={projectId}
                      onChange={value =>
                        batchUpdateParentTopicIdMutation.mutate(value)
                      }
                      children={({ onClick }: any) => (
                        <ButtonPlain
                          className="bg-gray-500/20 text-xs text-black hover:bg-gray-500/30 dark:text-white"
                          onClick={onClick}
                        >
                          {batchUpdatePriorityMutation.isLoading ? (
                            <Spinner />
                          ) : (
                            <FaEdit />
                          )}{' '}
                          Parent URL <FaCaretDown />
                        </ButtonPlain>
                      )}
                    />
                    <Select
                      options={priorityOptions}
                      onChange={batchUpdatePriorityMutation.mutate}
                      children={({ onClick }) => (
                        <ButtonPlain
                          className="bg-gray-500/20 text-xs text-black hover:bg-gray-500/30 dark:text-white"
                          onClick={onClick}
                        >
                          {batchUpdatePriorityMutation.isLoading ? (
                            <Spinner />
                          ) : (
                            <FaEdit />
                          )}{' '}
                          Priority <FaCaretDown />
                        </ButtonPlain>
                      )}
                    />
                    <TopicPicker
                      projectId={projectId}
                      onChange={value =>
                        mergeWithTopicIdMutation.mutate({
                          projectId,
                          topics: selectedRows.map(d => d.original),
                          topicId: value,
                        })
                      }
                      children={({ onClick }: any) => (
                        <ButtonPlain
                          className="bg-gray-500/20 text-xs text-black hover:bg-gray-500/30 dark:text-white"
                          onClick={onClick}
                        >
                          {mergeWithTopicIdMutation.isLoading ? (
                            <Spinner />
                          ) : (
                            <FaEdit />
                          )}{' '}
                          Merge With Topic <FaCaretDown />
                        </ButtonPlain>
                      )}
                    />
                  </div>
                </div>
              ) : null
            }
          />
          {/* <ForceTree tableRows={tableRows} /> */}
        </Card>
      </div>
    </div>
  )
}
// function ForceTree({ tableRows }: { tableRows: TopicPb[] }) {
//   const workspaceId = useActiveWorkspaceId()!
//   const search = useSearch()
//   const projectId = search.projectId!

//   const projectQuery = useProject({
//     workspaceId,
//     id: projectId,
//   })

//   const root = React.useMemo(
//     () =>
//       d3.hierarchy(
//         tableRows?.length === 1
//           ? tableRows[0]!
//           : ({
//               name: projectQuery.data?.name ?? 'Root',
//               children: tableRows,
//             } as TopicPb)
//       ),
//     [projectQuery.data?.name, tableRows]
//   )

//   const navigate = useNavigate()

//   return (
//     <Card className="divide-y divide-gray-500/20 p-0">
//       <div className="p-2 text-sm font-bold">Topics Structure</div>
//       <div className="aspect-[2/1]">
//         <ForceDirectedGraph
//           key={workspaceId + projectId}
//           root={root}
//           label={d => (d === root.data ? ['Root', d.name] : ['Topic', d.name])}
//           className="h-full w-full"
//           // nodeRadius={d => ['Child Topics', d.children.length]}
//           nodeFill={d => ['Priority', formatPriority(d.priority)]}
//           onDatumClick={d => {
//             navigate({
//               to: `/topics/${d.id}`,
//             })
//           }}
//         />
//       </div>
//     </Card>
//   )
// }
