import moment from 'moment'
import * as React from 'react'
import Input from './Input'

export interface DateInputProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'onChange' | 'value'
  > {
  value: Date | undefined
  onChange?: (
    value: Date | undefined,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void
  allowUndefined?: boolean
  decimals?: number
}

export default function DateInput({
  value,
  onChange,
  allowUndefined = false,
  decimals,
  ...rest
}: DateInputProps) {
  const dateStr = React.useMemo(
    () => moment(value as any).format('YYYY-MM-DD'),
    [value]
  )

  return (
    // @ts-expect-error  // Type '{ type: "date"; value: string; onChange: (e:... Remove this comment to see the full error message
    <Input
      {...rest}
      type="date"
      value={dateStr}
      onChange={e => {
        // @ts-expect-error  // Argument of type 'ChangeEvent<HTMLInputElement> | ... Remove this comment to see the full error message
        onChange?.(moment(e.currentTarget.value).toDate(), e)
      }}
    />
  )
}
