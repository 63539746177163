import * as React from 'react'
import { FaCheck, FaMagic, FaPlus, FaTimesCircle, FaUndo } from 'react-icons/fa'
import Button from '../components/Button'
import Condition from '../components/Condition'
import Select from '../components/Select'
import useConfirm from '../hooks/useConfirm'
import useGetLatest from '../hooks/useGetLatest'
import {
  deepEqual,
  filterObj,
  functionalUpdate,
  mapObjValues,
  someObj,
} from '../utils'
import {
  conditionStageOptions,
  flattenCondition,
  makeAllCondition,
  makeColumnCondition,
} from '../utils/Conditions'
import { useColumnsQuery } from '../utils/columns'

//

export default function Conditions({
  conditions: userConditions,
  errors,
  onChange,
  transactional = true,
  workspaceId,
  teamId: projectId,
  canEdit,
}: any) {
  const columnsQuery = useColumnsQuery()

  const getOnChange = useGetLatest(onChange)

  userConditions = React.useMemo(() => userConditions ?? {}, [userConditions])

  const [_conditions, _setConditions] = React.useState(userConditions)

  const conditions = React.useMemo(
    () => (transactional ? _conditions : userConditions),
    [_conditions, transactional, userConditions]
  )

  const cleanConditions = (conditions: any) => {
    return filterObj(
      conditions,
      condition =>
        condition &&
        (condition.matchType === 'expression' ||
          condition.conditions.filter(Boolean)?.length)
    )
  }

  const setConditions = React.useCallback(
    (updater: any) => {
      if (transactional) {
        _setConditions((old: any) => cleanConditions(updater(old)))
      } else {
        getOnChange()?.(cleanConditions(functionalUpdate(updater, conditions)))
      }
    },
    [conditions, getOnChange, transactional]
  )

  const conditionsRef = React.useRef()
  conditionsRef.current = conditions

  const confirm = useConfirm()

  const apply = () => {
    onChange(conditions)
  }

  const onAddStageCondition = (stage: any, condition: any) => {
    setConditions((old: any) => {
      return {
        ...old,
        [stage]: makeAllCondition([old[stage], condition]),
      }
    })
  }

  const onConditionChange = (stage: any, updater: any) => {
    setConditions((old: any) => {
      return {
        ...old,
        [stage]: functionalUpdate(updater, old[stage]),
      }
    })
  }

  const cancel = () => {
    setConditions({})
  }

  const clearAll = async () => {
    const confirmed = await confirm({
      message: `Are you sure you want to clear all conditions?`,
    })
    if (confirmed) {
      cancel()
    }
  }

  const prettifyConditions = () => {
    setConditions((old: any) => {
      return mapObjValues(old, d => flattenCondition(d, { removeEmpty: true }))
    })
  }

  const hasErrors = React.useMemo(() => {
    const recurse = (error: any) => {
      if (Array.isArray(error)) {
        return error.some(recurse)
      }

      return !Object.values(error?.conditions ?? {}).every(
        el => el === undefined
      )
    }

    if (errors) {
      return someObj(errors, recurse)
    }
  }, [errors])

  const changed = React.useMemo(
    () => !deepEqual(conditions, userConditions),
    [conditions, userConditions]
  )

  const showClearAll = someObj(conditions, Boolean)

  // React.useLayoutEffect(() => {
  //   if (
  //     someObj(
  //       conditions,
  //       condition =>
  //         condition &&
  //         condition.matchType !== 'expression' &&
  //         !condition.conditions?.length
  //     )
  //   ) {
  //     setConditions(old =>
  //       filterObj(
  //         old,
  //         condition =>
  //           condition &&
  //           (condition.matchType === 'expression' ||
  //             condition.conditions?.length)
  //       )
  //     )
  //   }
  // })

  return (
    <div className="space-y-4">
      {!someObj(conditions, Boolean) && changed ? (
        <div>No conditions, yet...</div>
      ) : (
        conditionStageOptions.map(stageOption => {
          return conditions[stageOption.value] ? (
            <div key={stageOption.value} className="relative">
              <div className="text-sm font-bold">
                {stageOption.label} Conditions
              </div>
              <Condition
                condition={conditions[stageOption.value]}
                error={errors[stageOption.value]}
                onChange={(condition: any) =>
                  onConditionChange(stageOption.value, condition)
                }
                stage={stageOption.value}
                workspaceId={workspaceId}
                projectId={projectId}
                canEdit={canEdit}
              />
            </div>
          ) : null
        })
      )}
      {canEdit ?? true ? (
        <div className="flex flex-wrap items-center gap-2">
          <Select
            value={null}
            options={columnsQuery.data?.columns}
            onChange={value =>
              onAddStageCondition(
                columnsQuery?.data.getStageByColumnId(value),
                // @ts-expect-error  // Expected 3 arguments, but got 1.
                makeColumnCondition(value)
              )
            }
          >
            {({ onClick }: any) => (
              <Button
                size="sm"
                color={['blue-400', 'blue-700']}
                onClick={onClick}
              >
                <FaPlus className="inline" /> Add Condition
              </Button>
            )}
          </Select>
          {someObj(conditions, d => d) ? (
            <Button
              size="sm"
              color={['gray-400', 'gray-600']}
              onClick={prettifyConditions}
            >
              <FaMagic className="inline" /> Prettify
            </Button>
          ) : null}
          {transactional && changed ? (
            <>
              <Button
                size="sm"
                color="green-500"
                onClick={apply}
                disabled={hasErrors}
              >
                <FaCheck className="inline" /> Apply
              </Button>
              <Button size="sm" color="red-500" onClick={cancel}>
                <FaTimesCircle className="inline" /> Cancel
              </Button>
            </>
          ) : showClearAll ? (
            <Button
              size="sm"
              color={['gray-400', 'gray-600']}
              onClick={clearAll}
              disabled={hasErrors}
            >
              <FaUndo className="inline" /> Clear All
            </Button>
          ) : null}
        </div>
      ) : null}
    </div>
  )
}
