import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  ApiToken,
  deletApiToken,
  fetchApiToken,
  fetchApiTokens,
  onApiTokensChangeSubscription,
  patchApiToken,
} from '../utils/Api'
import { queryKeyApiTokens, queryKeyUsers } from '../utils/Constants'
import useErrorPopup from './useErrorPopup'
import useLoading from './useLoading'
import { useQuerySubscription } from './useQuerySubscription'
import useToast from './useToast'
import { useActiveWorkspaceId } from './workspaces'

export function useApiToken({ id }: { id: null | number }) {
  const workspaceId = useActiveWorkspaceId()

  useQuerySubscription({
    kind: queryKeyApiTokens as string,
    label: 'API Key',
    subscriber: onApiTokensChangeSubscription,
  })

  return useQuery(
    [queryKeyApiTokens, id, workspaceId],
    () => fetchApiToken(id!),
    {
      enabled: Boolean(workspaceId && id),
    }
  )
}

export function useApiTokens() {
  const workspaceId = useActiveWorkspaceId()

  useQuerySubscription({
    kind: queryKeyApiTokens as string,
    label: 'API Key',
    subscriber: onApiTokensChangeSubscription,
  })

  return useQuery(
    [queryKeyApiTokens, workspaceId],
    () => fetchApiTokens(workspaceId),
    {
      enabled: !!workspaceId,
    }
  )
}

export function useSaveApiToken() {
  const workspaceId = useActiveWorkspaceId()
  const toast = useToast()
  const errorPopup = useErrorPopup()
  const [, setLoading] = useLoading()
  const queryClient = useQueryClient()

  return useMutation(
    (payload: ApiToken) =>
      patchApiToken({
        ...payload,
        workspaceId,
      }),
    {
      onMutate: () => {
        setLoading(true)
      },
      onSuccess: async data => {
        toast({
          message: 'API Token Saved',
          color: 'green-500',
        })
        await queryClient.invalidateQueries('apiTokens')
        queryClient.setQueryData(['apiKey', data.id], data)
      },
      onError: err => {
        console.error(err)
        errorPopup('Failed to save API Token.')
      },
      onSettled: () => {
        setLoading(false)
      },
    }
  )
}

//

export function useRemoveApiTokenById() {
  const toast = useToast()
  const errorPopup = useErrorPopup()
  const workspaceId = useActiveWorkspaceId()
  const queryClient = useQueryClient()

  return useMutation(
    (apiKeyId: string) => deletApiToken(apiKeyId, workspaceId),
    {
      onSuccess: async () => {
        toast({
          message: 'API Token deleted',
          color: 'green-500',
        })
        await queryClient.invalidateQueries(queryKeyUsers)
      },
      onError: err => {
        console.error(err)
        errorPopup('Failed to remove API Token.')
      },
    }
  )
}
