import { NoInfer } from '@tanstack/react-virtual'

type Entry<T = unknown> = {
  args: readonly any[]
  value: T
  timeoutId?: ReturnType<typeof setTimeout>
}

export default function makeMemoCache<T, TArgs extends readonly any[]>(
  fn: (...args: TArgs) => T,
  { cacheTime = 1000 * 60 } = {}
) {
  let cache: Entry[] = []

  const scheduleGarbageCollection = (entry: Entry) => {
    clearTimeout(entry.timeoutId)
    entry.timeoutId = setTimeout(() => {
      cache = cache.filter(d => d !== entry)
    }, cacheTime)
  }

  return function memo(...args: NoInfer<TArgs>): T {
    const found = cache.find(
      entry =>
        entry.args.length === args.length &&
        !entry.args.some((dep, i) => dep !== args[i])
    )

    if (found) {
      scheduleGarbageCollection(found)
      return found.value as T
    }

    const value = fn(...args)

    const newEntry: Entry<T> = {
      args,
      value,
    }

    cache.push(newEntry)
    scheduleGarbageCollection(newEntry)

    return value
  }
}
