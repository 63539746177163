import { createColumnHelper } from '@tanstack/react-table'
import * as React from 'react'
import { FaPlus } from 'react-icons/fa'
import Card from '../../components/Card'
import Link from '../../components/Link'
import QueryGate from '../../components/QueryGate'
import Separator from '../../components/Separator'
import Table from '../../components/Table'
import { useApiTokens } from '../../hooks/apiTokens'
import { useTable } from '../../hooks/useTable'
import { ApiToken } from '../../utils/Api'
import { ButtonPlain } from '../../components/ButtonPlain'

//

const columnHelper = createColumnHelper<ApiToken>()

export function ApiTokens() {
  const apiTokensQuery = useApiTokens()

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Token Name',
        filterFn: 'fuzzy',
        cell: ({ getValue, row: { original } }) => (
          <Link to={`./${original.id}`}>{getValue()}</Link>
        ),
      }),
      columnHelper.accessor('id', {
        header: 'Token ID',
        filterFn: 'fuzzy',
      }),
      columnHelper.accessor('prefix', {
        header: 'Key Prefix',
        cell: props => <>{props.getValue()}... </>,
      }),
    ],
    []
  )

  const tableData = React.useMemo(
    () => apiTokensQuery.data ?? [],
    [apiTokensQuery.data]
  )

  const table = useTable({
    data: tableData,
    columns,
    initialState: {
      sorting: [{ id: 'name', desc: false }],
    },
  })

  return (
    <Card className="p-0">
      <div className="p-2">
        <Link to="new">
          <ButtonPlain className="bg-green-500">
            <FaPlus className="inline" /> Create API Token
          </ButtonPlain>
        </Link>
      </div>
      <Separator />
      <QueryGate query={apiTokensQuery}>
        {() =>
          apiTokensQuery.data?.length ? (
            <Table table={table} />
          ) : (
            <div className="p-2 text-sm italic opacity-50">
              No tokens have been created yet.
            </div>
          )
        }
      </QueryGate>
    </Card>
  )
}
