import moment from 'moment'
import { useQuery } from 'react-query'
import { queryKeyGooglePhrases } from '../utils/Constants'
import { TimeRange } from './useTimeRanges'

export function useGooglePhrasesQuery({
  property,
  timeRanges,
  device,
  country,
  appearance,
  url,
  groupBy,
}: {
  property: string
  timeRanges: TimeRange
  device: string
  country: string
  appearance?: string
  url?: string
  groupBy: string
}) {
  let dimensionFilterGroups = [{ filters: [] }]

  if (device) {
    dimensionFilterGroups[0].filters.push({
      dimension: 'DEVICE',
      operator: 'EQUALS',
      expression: device,
    })
  }

  if (country) {
    dimensionFilterGroups[0].filters.push({
      dimension: 'COUNTRY',
      operator: 'EQUALS',
      expression: country,
    })
  }

  if (appearance) {
    dimensionFilterGroups[0].filters.push({
      dimension: 'SEARCH_APPEARANCE',
      operator: 'EQUALS',
      expression: appearance,
    })
  }

  if (url) {
    dimensionFilterGroups[0].filters.push({
      dimension: 'PAGE',
      operator: 'EQUALS',
      expression: url,
    })
  }

  if (!dimensionFilterGroups[0].filters.length) {
    // @ts-expect-error  // Type 'null' is not assignable to type '{ filters: ... Remove this comment to see the full error message
    dimensionFilterGroups = null
  }

  const pageSize = 25000
  const rowLimit = 500000

  return useQuery(
    [
      queryKeyGooglePhrases,
      property,
      timeRanges,
      device,
      country,
      appearance,
      url,
      groupBy,
    ],
    async () => {
      if (property) {
        let allRows: any = []

        const tryPage = async (page: number) => {
          const { result } =
            // @ts-expect-error  // Property 'gapi' does not exist on type 'Window & t... Remove this comment to see the full error message
            await window.gapi.client.webmasters.searchanalytics.query({
              siteUrl: property,
              resource: {
                startDate: moment
                  .unix(timeRanges.start)
                  .add(1, 'day')
                  .format('YYYY-MM-DD'),
                endDate: moment
                  .unix(timeRanges.end)
                  .add(1, 'day')
                  .format('YYYY-MM-DD'),
                rowLimit: pageSize,
                dataState: 'all',
                startRow: pageSize * page,
                dimensions: [groupBy],
                dimensionFilterGroups,
              },
            })

          allRows = allRows.concat(result.rows)

          if (result?.rows?.length === pageSize && allRows.length < rowLimit) {
            await tryPage(page + 1)
          }
        }

        await tryPage(0)

        return allRows[0] ? allRows : []
      }
    },
    {
      keepPreviousData: true,
      enabled: !!property,
    }
  )
}
