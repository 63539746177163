import { DatePb } from './proto'

export function datePbToDate(protoDate?: DatePb) {
  if (!protoDate) {
    return new Date(0)
  }

  return new Date(
    protoDate.year,
    Math.min(Math.max(protoDate.month - 1, 0), 11),
    Math.min(Math.max(protoDate.day, 1), 31)
  )
}

export function dateToDatePb(date: Date): DatePb {
  return new DatePb({
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
  })
}
