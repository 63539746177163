import { useNavigate, useSearch } from '@tanstack/react-router'
import * as React from 'react'
import { Updater, functionalUpdate } from '../../utils'

import { UseQueryResult, useMutation, useQueryClient } from 'react-query'
import Select from '../../components/Select'
import {
  ClusterClient,
  ListClustersFacetsResponsePb,
  ListClustersFiltersPb,
  PartialMessage,
} from '../../utils/proto'
import useErrorPopup from '../../hooks/useErrorPopup'
import useToast from '../../hooks/useToast'
import { updateClusters } from '../../utils/clusters'
import { queryKeyClusters } from '../../utils/Constants'
import { MdLoop } from 'react-icons/md'
import { twMerge } from 'tailwind-merge'
import { useProfileQuery } from '../../hooks/profile'
import { ButtonPlain } from '../../components/ButtonPlain'
import { TbPackageExport } from 'react-icons/tb'
import { createGoogleSpreadsheet, getGapiToken } from '../../utils/gapi'
import { useActiveWorkspace } from '../../hooks/workspaces'
import { useProjectOptionsQuery } from '../../hooks/projects'
import Spinner from '../../components/Spinner'
import { LuWand2 } from 'react-icons/lu'
import { filtersPbFromSearch } from '../../components/FacetFilters'
import { clusterListFilterList } from './Clusters'

export function useFocusDomainOptions(
  facetsResponse?: ListClustersFacetsResponsePb
) {
  return React.useMemo(
    () =>
      facetsResponse?.focusedRollups?.facets.map(d => ({
        label: d.label,
        value: Number(d.id),
      })),
    [facetsResponse?.focusedRollups?.facets]
  )
}

export function useFocusDomain(facetsResponse?: ListClustersFacetsResponsePb) {
  const { clustersFocusDomainId } = useSearch()
  const navigate = useNavigate()
  const focusDomainOptions = useFocusDomainOptions(facetsResponse)

  React.useEffect(() => {
    if (
      focusDomainOptions &&
      !focusDomainOptions.find(d => d.value === clustersFocusDomainId)
    ) {
      navigate({
        search: d => ({
          ...d,
          clustersFocusDomainId: focusDomainOptions[0]?.value,
        }),
        replace: true,
      })
    }
  }, [clustersFocusDomainId, focusDomainOptions, navigate])

  return [
    React.useMemo(() => {
      return clustersFocusDomainId || focusDomainOptions?.[0]?.value
    }, [clustersFocusDomainId, focusDomainOptions]),
    (updater: Updater<string>) => {
      navigate({
        search: prev => ({
          ...prev,
          clustersFocusDomainId: functionalUpdate(
            updater,
            prev!.clustersFocusDomainId!
          ),
        }),
        replace: true,
      })
    },
  ] as const
}

export function useUpdateClustersMutation({
  workspaceId,
  projectId,
  clusterJobId,
}: {
  workspaceId: string
  projectId: string
  clusterJobId: string
}) {
  const errorPopup = useErrorPopup()
  const toast = useToast()
  const queryClient = useQueryClient()

  return useMutation(
    async (includedDomains?: string[]) => {
      const res = await updateClusters({
        workspaceId,
        projectId,
        clusterJobId,
        includedDomains,
        waitForCompletion: true,
      })

      if (!res.operation?.done) {
        throw new Error(`Encountered unexpected operation error`)
      }
    },
    {
      onError: err => {
        console.error(err)
        errorPopup('There was a problem generating your clusters.')
      },
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeyClusters])
        toast({
          color: 'green-500',
          message:
            'Clustering was successful! Please wait a few seconds for them to download and appear.',
        })
      },
    }
  )
}

export function ClusteringFocusComp({
  facetsQuery,
  workspaceId,
  projectId,
  clusterJobId,
  onAutoGenerateTopics,
}: {
  facetsQuery: UseQueryResult<ListClustersFacetsResponsePb>
  workspaceId: string
  projectId: string
  clusterJobId: string
  onAutoGenerateTopics: () => void
}) {
  const focusDomainOptions = useFocusDomainOptions(facetsQuery.data)
  const [focusDomainId, setFocusDomain] = useFocusDomain(facetsQuery.data)
  const workspaceName = useActiveWorkspace()?.name
  const projectOptionsQuery = useProjectOptionsQuery()
  const [domainId] = useFocusDomain()
  const focusDomain = focusDomainOptions?.find(d => d.value === domainId)?.label
  const { clusterListFilters } = useSearch()
  const toast = useToast()
  const isAdmin = useProfileQuery().data?.isAdmin

  const projectName = projectOptionsQuery.data?.find(
    d => d.value === projectId
  )?.label

  // const isAdmin = useProfileQuery().data?.isAdmin ?? false

  // const confirm = useConfirm()

  const updateClustersMutation = useUpdateClustersMutation({
    workspaceId,
    projectId,
    clusterJobId,
  })

  const regenerate = async () => {
    // const confirmed = await confirm({
    //   title: 'Regenerate Clusters',
    //   message:
    //     'This may take anywhere between 60 seconds and 15 minutes depending on the number of keywords in this project.',
    //   confirmText: 'Regenerate',
    // })

    // if (confirmed) {
    updateClustersMutation.mutate(
      facetsQuery.data?.domains?.facets.map(m => m.label) ?? []
    )
    // }
  }

  const exportToSheetsMutation = useMutation(
    async (opts?: { domainId?: string }) => {
      const name = `Nozzle Clusters Export - ${workspaceName} - ${projectName} - ${focusDomain} - ${new Date().toLocaleString()}`
      const domainId = opts?.domainId

      const sheet = await createGoogleSpreadsheet({ title: name })
      const spreadsheetId = sheet.spreadsheetId
      const accessToken = (await getGapiToken()).access_token

      const filters = filtersPbFromSearch<ListClustersFiltersPb>(
        clusterListFilterList,
        clusterListFilters || {}
      )

      await ClusterClient.exportClusters({
        waitForCompletion: true,
        workspaceId: BigInt(workspaceId),
        projectId: BigInt(projectId),
        name,
        domainId: domainId ? BigInt(domainId) : undefined,
        filters,
        options: {
          case: 'googleSheets',
          value: {
            accessToken,
            spreadsheetId,
          },
        },
      })
    },
    {
      onError: () => {
        toast({
          color: 'red-500',
          message: 'There was a problem exporting to Google Sheets',
        })
      },
      onSuccess: () => {
        toast({
          color: 'green-500',
          message: 'Exported to Google Sheets!',
        })
      },
    }
  )

  return (
    <div className="flex items-center gap-2 p-2">
      <Select
        inlineLabel="Focus Domain"
        options={focusDomainOptions}
        className="inline-block"
        value={focusDomainId}
        onChange={setFocusDomain}
      />
      {/* {showAddDomain ? (
        <></>
      ) : (
        <Button
          size="xs"
          color={['gray-200', 'gray-700']}
          onClick={() => setShowAddDomain(true)}
        >
          Add Domain
        </Button>
      )} */}
      <ButtonPlain
        className="bg-blue-500"
        onClick={() => {
          if (updateClustersMutation.isLoading) return
          regenerate()
        }}
      >
        <MdLoop
          className={twMerge(
            'text-base',
            updateClustersMutation.isLoading &&
              'animate-spin [animation-direction:reverse]'
          )}
        />{' '}
        {updateClustersMutation.isLoading ? (
          <>Checking for new clusters</>
        ) : (
          'Check for new clusters'
        )}
      </ButtonPlain>
      {isAdmin ? (
        <div className="ml-auto flex flex-wrap gap-2">
          <ButtonPlain className="bg-gray-500" onClick={onAutoGenerateTopics}>
            <LuWand2 />
            <span>Auto-Generate Topics</span>
            {exportToSheetsMutation.isLoading ? <Spinner /> : null}
          </ButtonPlain>
          <ButtonPlain
            className="ml-auto bg-gray-500"
            onClick={() => {
              exportToSheetsMutation.mutate({ domainId })
            }}
          >
            <TbPackageExport />
            <span>Export to Google Sheets</span>
            {exportToSheetsMutation.isLoading ? <Spinner /> : null}
          </ButtonPlain>
        </div>
      ) : null}
    </div>
  )
}
