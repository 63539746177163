import * as Sentry from '@sentry/react'
import axios, { AxiosResponse } from 'axios'
import { createClient } from 'contentful'
import moment from 'moment'
import Pusher, { Channel } from 'pusher-js'
// @ts-expect-error  // Could not find a declaration file for module 'swim... Remove this comment to see the full error message
import { createPool } from 'swimmer'
// @ts-expect-error  // Could not find a declaration file for module 'uuid... Remove this comment to see the full error message
import { v4 as uuidv4 } from 'uuid'

import {
  Brand,
  BrandTypeEnum,
  NozzleAdminApiFactory,
  Profile,
  Workspace,
} from '../../openapi'
import { NozzleEvent } from '../hooks/useEventFilter'
import { sortBy } from '../utils'
import LocalStorage from '../utils/LocalStorage'
import {
  BRAND_PROPERTY_TYPE_DOMAIN,
  KEYWORD_SOURCE_TYPE_AUTOMOTIVE,
  KEYWORD_SOURCE_TYPE_BASIC,
  KEYWORD_SOURCE_TYPE_CITY,
  KEYWORD_SOURCE_TYPE_JSON,
  KEYWORD_SOURCE_TYPE_TEMPLATE,
  UTC_FORMAT,
} from './Constants'
import { MetricId, MetricPermutation } from './Metrics'
import { QueryFacet } from './queryFacets'
import {
  ScheduleConfig,
  rruleToScheduleConfig,
  scheduleConfigToFullRRule,
} from './schedules'
import {
  KeywordSourceConfigTypePb,
  KeywordSourcePb,
  KeywordSourcesClient,
} from './proto'

//

export type AuthClaims = { token: string; expirationTime: string }

let claims: AuthClaims
let debugWorkspace: Workspace | undefined
let debugUserId: number

export function setClaims(c: AuthClaims) {
  if (import.meta.env.DEV) {
    localStorage.setItem('dev_claims', JSON.stringify(c))
  }

  claims = c
}

export function setDebugWorkspace(workspace: Workspace) {
  debugWorkspace = workspace
}

export function setDebugUserId(id: number) {
  debugUserId = id
}

export async function getAccessToken() {
  const run = async () => {
    if (
      claims?.expirationTime &&
      new Date(claims?.expirationTime).valueOf() < Date.now()
    ) {
      await new Promise(resolve => setTimeout(resolve, 1000))
      await run()
    }

    return claims?.token ? claims.token : ''
  }

  return await run()
}

//

const baseURL = (() => {
  if (typeof document !== 'undefined') {
    const customBaseURL = localStorage.getItem('api_base_url')
    if (customBaseURL?.length) {
      return customBaseURL
    }
  }
  return 'https://api.nozzle.app'
})()

// REST API
const client = axios.create({
  baseURL,
})

const betterErrorStack = (d: { response: AxiosResponse; stack: string }) => {
  if (d.response) {
    d.stack = d.response.data
      ? `Message: ${d.response.data.message || 'N/A'}
        
Errors: ${
          d.response.data?.errors
            ? JSON.stringify(d.response.data.errors, null, 2)
            : 'N/A'
        }

Data: ${
          d.response.data?.data
            ? JSON.stringify(d.response.data.data, null, 2)
            : 'N/A'
        }

Stack Trace:

${d.stack}
`
      : d.stack
  }

  return Promise.reject(d)
}

client.interceptors.request.use(async config => {
  config.headers.authorization = `Bearer ${await getAccessToken()}`
  const requestId = uuidv4()
  config.headers['x-request-id'] = requestId
  Sentry.setTag('requestId', requestId)
  config.headers['x-request-start'] = Date.now() / 1000
  config.params = config.params || {}

  config.params.debugWorkspaceId = debugWorkspace?.id
  config.params.debugWorkspaceSlug = debugWorkspace?.slug
  config.params.debugUserId = debugUserId

  if (!import.meta.env.PROD || LocalStorage.get('debug')) {
    config.params.debug = true
  }

  return config
}, betterErrorStack)

client.interceptors.response.use(
  d => {
    Sentry.setTag('requestId', undefined)
    return d
  },
  (...args) => {
    Sentry.setTag('requestId', undefined)
    return betterErrorStack(...args)
  }
)

export const NozzleClient = NozzleAdminApiFactory(
  {
    isJsonMime: undefined!,
    accessToken: async () => `Bearer ${await getAccessToken()}`,
  },
  baseURL,
  client
)

// Socket API

// Enable pusher logging - don't include this in production
Pusher.logToConsole = false

const pusher = new Pusher('3e454a1fc12760bcff73', {
  forceTLS: true,
})

let channel: Channel

function getChannelForUserID(userId: number) {
  if (!channel) {
    const newChannel = pusher.subscribe(`user_${userId}`)
    // newChannel.bind('crud', data => {
    //   console.info(`Received Crud Update: ${data.kind} ${data.type}`, data)
    // })
    channel = newChannel
  }
  return channel
}

export function subscribeToCrud(
  userId: number,
  callback: (event: NozzleEvent) => void
) {
  if (!userId) {
    throw new Error('A User ID is required to subscribe to pusher events.')
  }

  channel = getChannelForUserID(userId)

  const channelCallback = (data: NozzleEvent) => {
    return callback(data)
  }

  channel.bind('crud', channelCallback)

  return () => {
    channel.unbind('crud', channelCallback)
  }
}

// Events

export function onEventSubscription(
  userId: number,
  callback: (data: unknown) => void
) {
  if (!userId) {
    return () => {
      //
    }
  }
  return subscribeToCrud(userId, callback)
}

// Profile

export async function fetchProfile() {
  return client.get<{ data: Profile }>('/profile').then(res => res.data.data)
}

export async function patchProfile(payload: Profile) {
  const {
    data: { data },
  } = await client.patch<{ data: Profile }>(`/profile`, payload)
  return data
}

// Login

export async function fetchLogin(workspaceId: string) {
  return client
    .get('/login', { params: { workspaceId } })
    .then(res => res.data.data)
}

export async function getSleekToken() {
  const {
    data: { data },
  } = await client.get('/login/sleekplan')
  return data
}

// Columns

export async function fetchColumns() {
  const {
    data: { data },
  } = await client.get('/reports/columns')
  return data
}

// Workspaces

export async function fetchWorkspace(id: number) {
  const {
    data: { data },
  } = await client.get(`/workspaces/${id}`)
  return data
}

export async function postWorkspace(
  payload: Workspace,
  params?: { appsumoLicenseId: string; appsumoActivationEmail: string }
) {
  const {
    data: { data },
  } = await client.post('/workspaces', payload, {
    params,
  })
  return data
}

export async function patchWorkspace(payload: Workspace) {
  const {
    data: { data },
  } = await client.put(`/workspaces/${payload.id}`, payload)
  return data
}

export async function deleteWorkspace(id: number) {
  const {
    data: { data },
  } = await client.delete(`/workspaces/${id}`)
  return data
}

// Users

export function onUsersChangeSubscription(
  userId: number,
  callback: (data: unknown) => void
) {
  if (!userId) {
    return () => {
      //
    }
  }
  return subscribeToCrud(userId, event => {
    if (event.kind === 'User') {
      callback(event)
    }
  })
}

export async function fetchUsers(workspaceId: string) {
  const {
    data: { data },
  } = await client.get<{ data: User[] }>('/users', {
    params: { workspaceId },
  })
  return data
}

export type User = {
  createdAt?: number
  id: string
  displayName?: string
  givenName?: string
  familyName?: string
  email: string
  deletedAt?: string
  workspaceId?: string
}

export async function fetchUser(id: number, workspaceId: string) {
  const {
    data: { data },
  } = await client.get<{ data: User }>(`/users/${id}`, {
    params: {
      workspaceId,
    },
  })
  return data
}

export async function postUser(payload: User, workspaceId: string) {
  const {
    data: { data },
  } = await client.post<{ data: User }>('/users', payload, {
    params: {
      workspaceId,
    },
  })
  return data
}

export async function patchUser(payload: User, workspaceId: string) {
  const {
    data: { data },
  } = await client.patch(`/users/${payload.id}`, payload, {
    params: {
      workspaceId,
    },
  })
  return data
}

export async function deleteUser(id: string, workspaceId: string) {
  const {
    data: { data },
  } = await client.delete(`/users/${id}`, {
    params: {
      workspaceId,
    },
  })
  return data
}

//Api Keys

export type ApiToken = {
  workspaceId: string
  id: string
  name: string
  prefix: string
}

export function onApiTokensChangeSubscription(
  id: number,
  callback: (data: unknown) => void
) {
  if (!id) {
    return () => {
      //
    }
  }
  return subscribeToCrud(id, event => {
    if (event.kind === 'ApiToken') {
      callback(event)
    }
  })
}

export async function fetchApiTokens(workspaceId: string) {
  const {
    data: { data },
  } = await client.get<{ data: ApiToken[] }>('/apiKeys', {
    params: { workspaceId },
  })
  return data
}

export async function fetchApiToken(id: number) {
  const {
    data: { data },
  } = await client.get<{ data: ApiToken }>(`/users/${id}`)
  return data
}

export async function patchApiToken(payload: ApiToken) {
  const {
    data: { data },
  } = await client.put<{ data: ApiToken }>(`/apiKey/${payload.id}`, payload)
  return data
}

export async function deletApiToken(id: string, workspaceId: string) {
  const {
    data: { data },
  } = await client.delete(`/apiKeys/${id}`, {
    params: {
      workspaceId,
    },
  })
  return data
}

// Teams

export type Project = {
  id: string
  name: string
  workspaceId: string
  slug: string
  versionId: string
  description: string
  image: string
  currentPulls: number
  maxPulls: number
  usageStatus: string
  usageStatusUpdatedAt: string
  kind: string
  createdAt: string
  updatedAt: string
  deletedAt: string
}

export function onTeamsChangeSubscription(
  userId: number,
  callback: (data: unknown) => void
) {
  if (!userId) {
    return () => {
      //
    }
  }
  return subscribeToCrud(userId, event => {
    if (event.kind === 'Team') {
      callback(event)
    }
  })
}

export async function fetchTeams(workspaceId: string) {
  const {
    data: { data },
  } = await client.get<{ data: Project[] }>('/teams', {
    params: { workspaceId },
  })
  return data
}

export async function fetchTeam(id: number, workspaceId: string) {
  const {
    data: { data },
  } = await client.get<{
    data: Project
  }>(`/teams/${id}`, {
    params: { workspaceId },
  })
  return data
}

export async function postTeam(payload: {
  name: string
  workspaceId: string
  slug: string
}) {
  const {
    data: { data },
  } = await client.post('/teams', payload)

  return data as Project
}

export async function patchTeam(payload: Project) {
  const {
    data: { data },
  } = await client.put(`/teams/${payload.id}`, payload)

  return data
}

export async function deleteTeam(id: string) {
  const {
    data: { data },
  } = await client.delete(`/teams/${id}`)

  return data
}

// Brands

export function onBrandsChangeSubscription(
  userId: number,
  callback: (data: unknown) => void
) {
  if (!userId) {
    return () => {
      //
    }
  }
  return subscribeToCrud(userId, event => {
    if (event.kind === 'Brand') {
      callback(event)
    }
  })
}

export async function fetchBrands(teamId: string, workspaceId: string) {
  const {
    data: { data },
  } = await client.get<{ data: Brand[] }>('/brands', {
    params: { workspaceId, teamId },
  })
  return data
}

export async function fetchBrand(id: string, workspaceId: string) {
  const {
    data: { data },
  } = await client.get<{ data: Brand }>(`/brands/${id}`, {
    params: { workspaceId },
  })
  return data
}

export async function postBrand(payload: Brand) {
  const {
    data: { data },
  } = await client.post<{ data: Brand }>('/brands', payload)
  return data
}

export async function patchBrand(payload: Brand) {
  const {
    data: { data },
  } = await client.put<{ data: Brand }>(`/brands/${payload.id}`, payload)
  return data
}

export async function deleteBrand(id: string) {
  const {
    data: { data },
  } = await client.delete(`/brands/${id}`)
  return data
}

export async function fetchBrandInfo(href: string) {
  const domainMatch = href.match(
    /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?=]+)/im
  )

  const domain = domainMatch?.[1]

  try {
    const {
      data: { data },
    } = await client.get(`/brands:suggest`, {
      params: { domain: domain ?? null },
    })

    return {
      ...data,
      mode: 'simple',
      reputationImpact: '0',
      id: undefined,
      versionId: undefined,
      createdAt: undefined,
      deletedAt: undefined,
      updatedAt: undefined,
      teamId: undefined,
      workspaceId: undefined,
      type: undefined,
    }
  } catch (err) {
    console.error(
      `Failed to fetch domain for ${domain}. Proceeding with naive dummy domain.`
    )
    return {
      name: domain,
      reputationImpact: '0',
      type: undefined!,
      properties: [
        {
          name: domain,
          type: BRAND_PROPERTY_TYPE_DOMAIN,
          value: domain,
          condition: {
            columnId: 'result__url__domain',
            matchType: 'expression',
            operator: '=',
            values: [domain],
          },
        },
      ],
    }
  }
}

export type YoutubeLookup = {
  channels?: YoutubeChannel[] | null
  videos?: YoutubeVideo[] | null
}
export type YoutubeChannel = {
  etag: string
  id: string
  kind: string
  snippet: YoutubeChannelSnippet
}
export type YoutubeChannelSnippet = {
  country: string
  description: string
  localized: YoutubeSnippetLocalized
  publishedAt: string
  thumbnails: YoutubeThumbnails
  title: string
}
export type YoutubeSnippetLocalized = {
  description: string
  title: string
}
export type YoutubeThumbnails = {
  default: YoutubeThumbnailResolution
  high: YoutubeThumbnailResolution
  medium: YoutubeThumbnailResolution
}
export type YoutubeThumbnailResolution = {
  height: number
  url: string
  width: number
}
export type YoutubeVideo = {
  etag: string
  id: YoutubeVideoId
  kind: string
  snippet: YoutubeVideoSnippet
}
export type YoutubeVideoId = {
  kind: string
  videoId: string
}
export type YoutubeVideoSnippet = {
  channelId: string
  channelTitle: string
  description?: string | null
  liveBroadcastContent: string
  publishedAt: string
  thumbnails: YoutubeThumbnails
  title: string
}

export async function fetchYoutubeDomains(username: string) {
  const {
    data: { data },
  } = await client.get<{
    data: YoutubeLookup
  }>(`/integrations/youtube`, {
    params: { username },
  })
  return data
}

// KeywordSources

export type KeywordSource = {
  id: string
  workspaceId: string
  updatedAt: string
  createdAt: string
  name: string
  type:
    | KeywordSourceConfigTypePb
    | typeof KEYWORD_SOURCE_TYPE_BASIC
    | typeof KEYWORD_SOURCE_TYPE_CITY
    | typeof KEYWORD_SOURCE_TYPE_JSON
    | typeof KEYWORD_SOURCE_TYPE_TEMPLATE
    | typeof KEYWORD_SOURCE_TYPE_AUTOMOTIVE
  keywordCount: number
  schedules: ScheduleConfig[]
  config?: KeywordSourceConfig
}

export type KeywordSourceConfig = {
  jsonKeywords?: unknown[]
  phraseGroups?: PhraseGroup[]
}

export type DeviceCode = 'd' | 't' | 'p' | 'm' | 'i'

export type Keyword = {
  phrase: string
  device: DeviceCode
  localeId: number
  groups: string[]
}

export type AdvancedKeyword = {
  phrase: string
  devices: DeviceCode[]
  localeIds: number[]
  groups: string[]
}

export type PhraseGroup = {
  phrase: string
  groups?: string[]
  displayGroups?: string[]
}

export type PhraseGroupExt = {
  groups: Set<string>
  displayGroups: Set<string>
}

export type GroupPhrase = {
  group: string
  phraseCount: number
}

function inKeywordSource<T extends KeywordSourcePb>(keywordSource: T) {
  return {
    ...keywordSource,
    id: Number(keywordSource.id),
    projectId: Number(keywordSource.projectId),
    createdAt: keywordSource.createdAt?.toDate?.() || keywordSource.createdAt,
    updatedAt: keywordSource.updatedAt?.toDate?.() || keywordSource.updatedAt,
    type: keywordSource.type ?? 'template',
    schedules: keywordSource.schedules.map(s => rruleToScheduleConfig(s.rrule)),
  } as unknown as KeywordSource
}

function outKeywordSource(keywordSource: any) {
  return {
    ...keywordSource,
    schedules: keywordSource.schedules.map((d: any) => ({
      rrule: scheduleConfigToFullRRule(d),
    })),
  } as KeywordSource
}

export async function fetchKeywordSources(
  projectId: string,
  workspaceId: string
) {
  const res = await KeywordSourcesClient.listKeywordSources({
    workspaceId: BigInt(workspaceId),
    projectId: BigInt(projectId),
  })

  return res.keywordSources.map(inKeywordSource)
}

export async function fetchKeywordSource(
  id: string,
  workspaceId: string
): Promise<KeywordSourcePb> {
  let data
  try {
    const res = await client.get(`/keywordSources/${id}`, {
      params: { workspaceId },
    })
    data = res.data.data

    if (data.type === 'invalid') {
      throw new Error('Invalid keyword source')
    }

    // @ts-expect-error  // Type 'KeywordSource' is missing the following prop... Remove this comment to see the full error message
    return inKeywordSource(data)
  } catch (err) {
    const res = await KeywordSourcesClient.getKeywordSource({
      workspaceId: BigInt(workspaceId),
      keywordSourceId: BigInt(id),
    })

    data = res.keywordSource
  }

  return data as any
}

export async function postKeywordSource(payload: KeywordSource) {
  payload = outKeywordSource(payload)

  const {
    data: { data },
  } = await client.post<{ data: KeywordSource }>('/keywordSources', payload)

  // @ts-expect-error  // Argument of type 'import("/Users/tannerlinsley/Git... Remove this comment to see the full error message
  return inKeywordSource(data) as KeywordSource
}

export async function patchKeywordSource(payload: KeywordSource) {
  payload = outKeywordSource(payload)

  const {
    data: { data },
  } = await client.put<{ data: KeywordSource }>(
    `/keywordSources/${payload.id}`,
    payload
  )

  // @ts-expect-error  // Argument of type 'import("/Users/tannerlinsley/Git... Remove this comment to see the full error message
  return inKeywordSource(data) as KeywordSource
}

export async function deleteKeywordSource(id: string) {
  const {
    data: { data },
  } = await client.delete(`/keywordSources/${id}`)
  return data
}

export async function postGenerateKeywordSourceKeywords(
  payload: KeywordSource
) {
  const {
    data: { data },
  } = await client.post('/keywordSources:generateKeywords', payload)

  return data
}

export async function postKeywordSourcesConvert(payload: KeywordSource) {
  const {
    data: { data },
  } = await client.post('/keywordSources:convert', payload)

  return data
}

// Keywords

export async function fetchDashboardKeywords(params: unknown) {
  const {
    data: { data },
  } = await client.post('/reports/keywords', params)
  return data
}

// Segments

export type Segment = {
  id: string
  name: string
  updatedAt: number
  createdAt: number
  teamId?: string
  description: string
}

export function onSegmentsChangeSubscription(
  userId: number,
  callback: (data: unknown) => void
) {
  if (!userId) {
    return () => {
      //
    }
  }
  return subscribeToCrud(userId, event => {
    if (event.kind === 'Segment') {
      callback(event)
    }
  })
}

export async function fetchSegments(teamId: string, workspaceId: string) {
  const {
    data: { data },
  } = await client.get('/segments', {
    params: { workspaceId, teamId },
  })
  return data
}

export async function fetchSegment(id: string, workspaceId: string) {
  const {
    data: { data },
  } = await client.get(`/segments/${id}`, {
    params: { workspaceId },
  })
  return data
}

export async function postSegment(payload: Segment) {
  const {
    data: { data },
  } = await client.post('/segments', payload)
  return data
}

export async function patchSegment(payload: Segment) {
  const {
    data: { data },
  } = await client.put(`/segments/${payload.id}`, payload)
  return data
}

export async function deleteSegment(id: string) {
  const {
    data: { data },
  } = await client.delete(`/segments/${id}`)
  return data
}

export async function fetchViaProxy(url: string) {
  return client.get('/proxy', {
    params: { url },
  })
}

export async function getProxyRequest(url: never, auth?: never) {
  const { data } = await client.get('/proxy', {
    params: { url },
    headers: { 'nz-proxy-authorization': auth },
  })

  return data
}

export async function postProxyRequest(
  url: never,
  auth: never,
  bodyData: never
) {
  const { data } = await client.post(
    `/proxy?url=${url}`,

    { url, ...bodyData },
    { headers: { 'nz-proxy-authorization': auth } }
  )

  return data
}

// Query Meta

export async function fetchReportsTopDomains(payload: unknown) {
  const res = await client.post('/reports/top/domains', payload)

  // if (res.headers['nz-row-count']) {
  //   res.data.data.rowCount = Number(res.headers['nz-row-count'])
  // }

  return res.data.data
}

export async function fetchReportsTopHosts(payload: unknown) {
  const res = await client.post('/reports/top/hosts', payload)

  // if (res.headers['nz-row-count']) {
  //   res.data.data.rowCount = Number(res.headers['nz-row-count'])
  // }

  return res.data.data
}

export async function fetchReportsTopUrls(payload: unknown) {
  const res = await client.post('/reports/top/urls', payload)

  // if (res.headers['nz-row-count']) {
  //   res.data.data.rowCount = Number(res.headers['nz-row-count'])
  // }

  return res.data.data
}

// Queries

export type GroupByOverTimeQueryOptions = {
  workspaceId: string | undefined
  teamId: number | undefined
  start: number | undefined
  end: number | undefined
  groupBy: QueryFacet['filterKey'] | undefined
  rollupBy: QueryFacet['filterKey'] | undefined
  filters: GroupByQueryFilters | undefined
  orderBy: GroupByOverTimeOrderBy[] | undefined
  includedMetrics: MetricPermutation<MetricId>[] | undefined
  samples: number | undefined
}

export type GroupByOverTimeOrderBy = {
  metric: MetricPermutation<MetricId> | undefined
  limit: number | undefined
  offset: number | undefined
  desc: boolean | undefined
}

export type GroupByQueryFilters = {
  brandIds?: (string | undefined)[]
  brandTypes?: (string | undefined)[]
  brandProperties?: GroupByQueryBrandProperty[]
  domainIds?: (string | undefined)[]
  hostIds?: (string | undefined)[]
  urlIds?: (string | undefined)[]
  keywordIds?: (string | undefined)[]
  segmentIds: (string | undefined)[] | undefined
  keywordGroups: (string | undefined)[] | undefined
}

export type GroupByQueryBrandProperty = {
  brandId: number
  propertyName: string
}

export type GroupByQueryResponse = {
  rowCount?: number
  group_by_label: string
  series: GroupByQuerySeries[]
}

export type GroupByQuerySeries = {
  label: string
  data: GroupByQueryDatum[]
  keyword?: {
    keyword_id: number
  }
  segment?: {
    segment_id: string
  }
  brand?: {
    name: string
    brand_id: number
    brand_type: BrandTypeEnum
    brand_version_id: number
    description: string
    properties: {
      name: string
      brand_property_hash: number
    }[]
  }
  brand_type_data?: {
    brand_type: BrandTypeEnum
  }
  brand_property?: {
    brand_id: number
    property_name: string
    brand_description: string
    brand_name: string
    brand_type: BrandTypeEnum
    brand_version_id: number
    reputation_impact: number
  }
  domain_data?: {
    domain: string
    domain_id: number
  }
  host_data?: {
    host: string
    host_id: number
  }
  url?: {
    result__url__url: string
    result__url__url_id: number
  }
  keyword_group?: {
    keyword_group: string
  }
  color?: string
}

export type GroupByQueryDatum = {
  adwords_search_volume?: unknown
  click_through_rate?: unknown
  estimated_traffic?: unknown
  percentage_of_dom?: unknown
  percentage_of_viewport?: unknown
  ppc_value?: unknown
  requested?: string
  results?: unknown
  top_pixels_from_top?: unknown
  top_rank?: unknown
  top_ranking_results?: unknown
  unique_keywords?: unknown
  unique_urls?: unknown
}

const reportsPool = createPool({
  concurrency: 20,
})

export async function postReportsGroupByOverTime(
  payload: GroupByOverTimeQueryOptions
) {
  const res = await reportsPool.add(() =>
    client.post<{
      data: GroupByQueryResponse
    }>(
      '/reports/groupByOverTime',
      {
        ...payload,
        start: moment.unix(payload.start).utc().format(UTC_FORMAT),
        end: moment.unix(payload.end).utc().endOf('day').format(UTC_FORMAT),
      },
      {
        params: {
          v: 2,
          hint_groupBy: payload.groupBy ?? 'none',
        },
      }
    )
  )

  if (res.headers['nz-row-count']) {
    res.data.data.rowCount = Number(res.headers['nz-row-count'])
  }

  return res.data.data as GroupByQueryResponse
}

type GroupByOverTimeExportResponse = {
  status: 'pending' | 'completed' | 'failed'
  workspaceId: number
  teamId: number
  rollupBy: string
  groupBy: string
  jobId: string
  exportFilepath: string
  rowCount: number
  userId: number
  exportToken: string
}

export async function postReportsGroupByOverTimeExport(
  payload: GroupByOverTimeQueryOptions & { export: 'csv' }
) {
  const res = await client.post<{
    data: GroupByOverTimeExportResponse
  }>(
    '/reports/groupByOverTime',
    {
      ...payload,
      start: moment.unix(payload.start).utc().format(UTC_FORMAT),
      end: moment.unix(payload.end).utc().endOf('day').format(UTC_FORMAT),
    },
    {
      params: {
        v: 2,
        hint_groupBy: payload.groupBy ?? 'none',
      },
    }
  )

  return res.data.data
}

type GroupByOverTimeQueryQueryJobOptions = {
  exportToken: string
}

type GroupByQueryJobResponse = GroupByOverTimeExportResponse & {
  urls: JobUrl[]
}

export type JobUrl = {
  url: string
  urlExpiresAt: string
  dataExpiresAt: string
}

export async function fetchReportsGroupByOverTimeJob(
  options: GroupByOverTimeQueryQueryJobOptions
) {
  const res = await client.get<{
    data: GroupByQueryJobResponse
  }>('/reports/groupByOverTime', {
    params: {
      exportToken: options.exportToken,
    },
  })

  return res.data.data
}

// Pulls

export type Pull = {
  keywordId: number
  pullId: number
  rankingId: number
  requested: string
  requestedAt: string
  workspaceId: string
}

export async function fetchPulls(params: unknown) {
  const {
    data: { data },
  } = await client.get<{ data: Pull[] }>(`/pulls`, {
    params,
  })
  return data
}

// Serps

type GetKeywordSerpQueryResponse = any

export async function fetchSerps(params: unknown) {
  const {
    data: { data },
  } = await client.get<GetKeywordSerpQueryResponse>('/serps', {
    params,
  })

  return data
}

export async function fetchSerpsHtml(params: unknown) {
  const { data } = await client.get('/serps/html', {
    params,
  })

  return data
}

//Billing

type BillingCard = {
  id: string
  brand: string
  last4: number
  addressPostalCode: number
}

export async function approveInvoice(opts: {
  invoiceId: number
  workspaceId: string
}) {
  const {
    data: { data },
  } = await client.post(`/invoices/${opts.invoiceId}:approve`, {
    params: { workspaceId: opts.workspaceId },
  })
  return data
}

export async function voidInvoice(opts: {
  invoiceId: number
  workspaceId: string
}) {
  const {
    data: { data },
  } = await client.post(`/invoices/${opts.invoiceId}:void`, {
    params: { workspaceId: opts.workspaceId },
  })
  return data
}

export async function payInvoice(opts: {
  invoiceId: number
  workspaceId: string
}) {
  const {
    data: { data },
  } = await client.post(`/invoices/${opts.invoiceId}:pay`, {
    params: { workspaceId: opts.workspaceId },
  })
  return data
}

export async function markPaidInvoice(opts: {
  invoiceId: number
  workspaceId: string
}) {
  const {
    data: { data },
  } = await client.post(`/invoices/${opts.invoiceId}:markPaid`, {
    params: { workspaceId: opts.workspaceId },
  })
  return data
}

export function onBillingAccountChangeSubscription(
  userId: number,
  callback: (data: unknown) => void
) {
  if (!userId) {
    return () => {
      //
    }
  }
  return subscribeToCrud(userId, event => {
    if (event.kind === 'Billing') {
      callback(event)
    }
  })
}

const contentfulClient = createClient({
  space: 'z8uwv83tofbw',
  accessToken:
    '1f346953eb0bf8aa1b57c5ac3d1abe12e8b723054e5961d8a71d410e52f6dc8f',
})

export async function fetchMetrics() {
  const { items } = await contentfulClient.getEntries({
    content_type: 'metrics',
    limit: 1000,
  })
  const metrics = items.map(normalizeMetrics)
  return {
    metrics: sortBy(metrics, d => d.fields.name),
  }
}
const normalizeMetrics = (metric: any) => {
  return {
    ...metric,
  }
}

export type AccurankerDomain = {
  id: string
  domain: string
  display_name: string
}

export async function fetchAccurankerDomains(apiKey: string) {
  const { data } = await client.get<AccurankerDomain[]>('/proxy', {
    params: {
      url: 'http://app.accuranker.com/api/v4/domains?fields=id,display_name,domain',
    },
    headers: { 'nz-proxy-authorization': `token ${apiKey}` },
  })

  return data
}

export type AccurankerKeyword = {
  search_engine: {
    name: string
  }
  search_location: string
  search_locale: {
    region: string
    locale: string
  }
  search_type: 1 | 2 | 3
}

export async function fetchAccurankerKeywords(
  projectId: number,
  apiKey: string
) {
  const { data } = await client.get<AccurankerKeyword[]>('/proxy', {
    params: {
      url: `http://app.accuranker.com/api/v4/domains/${projectId}/keywords?fields=id,keyword,search_volume.search_volume,ranks,search_engine.name,search_locale.id,search_locale.locale,search_locale.region,country_code,search_location,search_type,tags,starred,preferred_landing_page.path`,
    },
    headers: { 'nz-proxy-authorization': `token ${apiKey}` },
  })

  return data
}

export type AccurankerBrandCompetitor = {
  domain: string
  display_name: string
}

export type AccurankerBrand = {
  domain: string
  display_name: string
  competitors: AccurankerBrandCompetitor[]
}

export async function fetchAccurankerBrands(apiKey: string) {
  const { data } = await client.get<AccurankerBrand[]>('/proxy', {
    params: {
      url: 'http://app.accuranker.com/api/v4/domains?fields=id,display_name,domain,google_business_name,competitors.domain,competitors.display_name,competitors.google_business_name',
    },
    headers: { 'nz-proxy-authorization': `token ${apiKey}` },
  })

  return data
}

export async function fetchProxyUrl(url: string) {
  const { data } = await client.get('/proxy', {
    params: {
      url,
    },
  })

  return data as string
}

//
//
//
