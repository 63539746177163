import * as React from 'react'
import useZustand from './useZustand'

export type PopupOptions = {
  title?: string
  message?: React.ReactNode
  subMessage?: React.ReactNode
  cancelText?: string
  dismissText?: string
  isConfirm?: boolean
  confirmText?: string
}

export default function usePopup() {
  const [, setStore] = useZustand()
  return React.useCallback(
    (options: PopupOptions) =>
      new Promise(resolve => {
        const popup = {
          ...options,
          id: Date.now(),
          title:
            options.title || (options.isConfirm ? 'Are you sure?' : 'Hey!'),
          message: options.message || <span />,
          subMessage: options.subMessage || <span />,
          cancelText: options.isConfirm
            ? typeof options.cancelText === 'undefined'
              ? 'Cancel'
              : options.cancelText
            : typeof options.dismissText === 'undefined'
            ? 'Dismiss'
            : options.dismissText,
          cancel: () => {
            if (options.isConfirm) {
              resolve(false)
            } else {
              // @ts-expect-error  // Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
              resolve()
            }
            setStore(draft => {
              // @ts-expect-error  // Object is of type 'unknown'.
              draft.popups = draft.popups.filter(d => d.id !== popup.id)
            })
          },
          // For confirmation dialogs
          isConfirm: options.isConfirm,
          confirmText:
            typeof options.confirmText !== 'undefined'
              ? options.confirmText
              : options.isConfirm
              ? 'Yes'
              : 'OK',
          confirm: () => {
            resolve(true)
            setStore(draft => {
              // @ts-expect-error  // Object is of type 'unknown'.
              draft.popups = draft.popups.filter(d => d.id !== popup.id)
            })
          },
        }
        setStore(draft => {
          draft.popups = [...draft.popups, popup]
        })
      }),
    [setStore]
  )
}
