import * as React from 'react'
import { useQueryClient } from 'react-query'
import { useDebouncedState } from '../hooks/useDebouncedState'
import Select from './Select'
import {
  ListMonitoredUrlsFiltersPb,
  MonitoredUrlPb,
  MonitoredUrlViewPb,
  PlainMessage,
} from '../utils/proto'
import {
  useMonitoredUrlsByIdQuery,
  useMonitoredUrlsQuery,
} from '../utils/monitoredUrls'
import { ButtonPlain } from './ButtonPlain'
import { FaPlus } from 'react-icons/fa'
import { TbPackageImport } from 'react-icons/tb'
import useModal from '../hooks/useModal'
import { AddMonitoredUrlModal } from '../containers/monitored-urls/MonitoredUrl'
import { useActiveWorkspaceId } from '../hooks/workspaces'

type MonitoredUrlOption = {
  value: string
  label: string
  meta: any
}

export function MonitoredUrlPicker({
  projectId,
  filters,
  value,
  onChange,
  onBlur,
  setTouched,
  excludeMonitoredUrlIds,
  allowEmpty,
  ...rest
}: {
  projectId: string
  filters?: Partial<PlainMessage<ListMonitoredUrlsFiltersPb>>
  children?: any
  value?: string
  onChange?: (value: string, monitoredUrl?: MonitoredUrlPb) => void
  onBlur?: (e: any) => void
  setTouched?: (touched: boolean) => void
  excludeMonitoredUrlIds?: string[]
  allowEmpty?: boolean
} & Omit<
  React.ComponentProps<typeof Select>,
  'options' | 'value' | 'onChange'
>) {
  const workspaceId = useActiveWorkspaceId()
  const queryClient = useQueryClient()

  const [search, debouncedSearch, setSearch] = useDebouncedState({
    initialValue: '',
  })

  const monitoredUrlsByIdQuery = useMonitoredUrlsByIdQuery({
    projectId,
    monitoredUrlIds: value ? [value] : [],
  })

  const monitoredUrlsQuery = useMonitoredUrlsQuery({
    workspaceId,
    projectId,
    rootOnly: false,
    view: MonitoredUrlViewPb.SEARCH,
    filters: {
      ...filters,
      search: debouncedSearch,
    },
  })

  const options = React.useMemo((): MonitoredUrlOption[] => {
    const options = [
      ...(monitoredUrlsQuery.data?.monitoredUrls ?? []),
      value &&
      !monitoredUrlsQuery.data?.monitoredUrls?.find(d => String(d.id) === value)
        ? monitoredUrlsByIdQuery.data?.[value]
        : undefined,
    ]
      ?.filter(Boolean)
      .map(monitoredUrl => ({
        value: String(monitoredUrl.id),
        label: monitoredUrl.url?.url ?? 'N/A',
        meta: monitoredUrl,
      }))

    if (allowEmpty && (!search || !value)) {
      options.unshift({ value: '', label: 'None', meta: undefined! })
    }

    return options
  }, [
    allowEmpty,
    monitoredUrlsQuery.data,
    monitoredUrlsByIdQuery.data,
    search,
    value,
  ])

  const filteredOptions = React.useMemo(() => {
    if (excludeMonitoredUrlIds) {
      return options.filter(d => !excludeMonitoredUrlIds?.includes(d.value))
    }

    return options
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, excludeMonitoredUrlIds])

  const showModal = useModal()

  const showAddUrlModal = () => {
    const modal = showModal(() => (
      <AddMonitoredUrlModal
        defaultValues={{
          brandId: BigInt(projectId),
        }}
        onSuccess={res => {
          modal.close()
          onChange?.(String(res.monitoredUrl?.id), res.monitoredUrl)
        }}
        onCancel={() => modal.close()}
      />
    ))
  }

  const showImportUrlsModal = () => {
    const modal = showModal(() => (
      <AddMonitoredUrlModal onSuccess={modal.close} onCancel={modal.close} />
    ))
  }

  return (
    <Select
      {...{
        closeOnSelect: rest.closeOnSelect ?? (value ? true : false),
        placeholder: 'Search for a Monitored URL...',
        className: 'inline-block',
        sort: false,
        ...rest,
        isLoading:
          search !== debouncedSearch ||
          monitoredUrlsQuery.isFetching ||
          monitoredUrlsByIdQuery.isLoading,
        onInputChange: (event: any) => setSearch(event.target.value),
        options: filteredOptions,
        value,
        onChange: (next: any) => {
          const monitoredUrl = monitoredUrlsQuery.data?.monitoredUrls?.find(
            d => d.id === next
          )

          queryClient.setQueryData(
            ['monitoredUrl', Number(next)],
            monitoredUrl,
            {
              updatedAt: Date.now() + 1000 * 60 * 60 * 24,
            }
          )
          // setSearch('')
          onChange?.(next, monitoredUrl as any)
        },
        onBlur: (e: Event) => {
          setTouched?.(true)
          onBlur?.(e)
        },
        renderOption: option => {
          return option.label
        },
        afterOptions: (
          <div className="flex gap-1 p-2">
            {/* <ButtonPlain
              className="bg-gray-100 text-xs hover:bg-blue-500 hover:text-white dark:bg-gray-800"
              onClick={addAll}
            >
              <FaPlus /> Add All ({formatNumber(filteredOptions.length)})
            </ButtonPlain> */}
            <ButtonPlain
              className="bg-gray-100 text-xs hover:bg-blue-500 hover:text-white dark:bg-gray-800"
              onClick={showAddUrlModal}
            >
              <FaPlus /> Add New URL
            </ButtonPlain>
            <ButtonPlain
              className="bg-gray-100 text-xs hover:bg-blue-500 hover:text-white dark:bg-gray-800"
              onClick={showImportUrlsModal}
            >
              <TbPackageImport /> Import URLs
            </ButtonPlain>
          </div>
        ),
      }}
    />
  )
}
