import * as React from 'react'
import {
  FaCaretDown,
  FaCaretRight,
  FaCheck,
  FaMinusCircle,
  FaSearch,
  FaTimes,
} from 'react-icons/fa'
import { twMerge } from 'tailwind-merge'
import { ButtonPlain } from './ButtonPlain'
import { ClickablePlain } from './Clickable'
import { PlainInput } from './Input'
import NumberInput from './NumberInput'
import { NumberInputWrap } from './NumberInputWrap'
import Select from './Select'
import { SelectOption } from '../hooks/useSelect'
import { Updater, UpdaterFn, last, uniqBy } from '../utils'
import { FacetPb, FacetValuePb } from '../utils/proto'
import { formatNumber } from '../utils/Format'
import { ObservablePlot } from './ObservablePlot'
import * as Plot from '@observablehq/plot'
import { extent } from 'd3-array'
import Loader from './Loader'
import { useDraftState } from '../hooks/useDraftState'
import Tooltip from './Tooltip'
// import { UiFilter } from './FacetFilters'

export type AnyFacets = Record<PropertyKey, FacetPb>

export type FacetsState = Record<string, unknown>

export type UiFilter = {
  id: string
  label: string
  ui: 'range' | 'multi-select' | 'text'
  type?: 'number' | 'string'
  keys: [string, string]
  facetKey: string
  showBins?: boolean
}

export function FacetFilters({
  facets,
  filters,
  filterState,
  onFilterStateChange,
  isLoading,
  labelPlural,
  searchKey,
}: {
  facets: any
  filters: UiFilter[]
  filterState: FacetsState | undefined
  onFilterStateChange: (updater: Updater<FacetsState | undefined>) => void
  isLoading: boolean
  labelPlural: string
  searchKey?: string
}) {
  const [filterVisibility, setFilterVisibility] = React.useState<string[]>([])

  const visibleFilters = React.useMemo(() => {
    return filters.filter(filter => filterVisibility.includes(filter.id))
  }, [filterVisibility, filters])

  const optionsById = React.useMemo(() => {
    const byId: Record<string, SelectOption[]> = {}

    filters.forEach(filter => {
      if (filter.facetKey) {
        byId[filter.id] = (facets?.[filter.facetKey]?.facets ?? []).map(
          (d: FacetValuePb) => ({
            label: `${d.label} (${formatNumber(d.count)})`,
            value: filter.type === 'number' ? `${Number(d.id)}` : d.label,
          })
        )
      }
    })

    return byId
  }, [facets, filters])

  const clearAllFilters = () => {
    onFilterStateChange(() => ({}))
  }

  const isAnyActive = React.useMemo(() => {
    return filters.some(filter => {
      return (
        filterState?.[filter.keys[0]] !== undefined ||
        filterState?.[filter.keys[1]] !== undefined
      )
    })
  }, [filterState, filters])

  return (
    <div className="divide-y divide-gray-500/20">
      <div className="flex w-full items-center gap-2 p-2 text-sm font-bold">
        {searchKey ? (
          <label className="flex flex-1 items-center">
            <div className="px-1">
              <FaSearch />
            </div>
            <PlainInput
              enableDraft
              placeholder="Search..."
              value={filterState?.search || ''}
              onChange={e => {
                onFilterStateChange(prev => ({
                  ...prev,
                  search: e.target.value,
                }))
              }}
              className="flex-1"
            />
          </label>
        ) : (
          <div className="flex-1">Filters</div>
        )}
        {isLoading ? <Loader /> : null}
        {isAnyActive ? (
          <ClickablePlain
            onClick={() => {
              clearAllFilters()
            }}
            className="flex items-center gap-1 text-sm text-yellow-500 hover:text-red-500"
          >
            Clear All
          </ClickablePlain>
        ) : null}
      </div>
      <div className="divide-y divide-gray-500/10">
        {filters.map(filter => (
          <Filter
            key={filter.id}
            filter={filter}
            filterState={filterState}
            optionsById={optionsById}
            visibleFilters={visibleFilters}
            facets={facets}
            setFilterVisibility={setFilterVisibility}
            labelPlural={labelPlural}
            onFilterStateChange={onFilterStateChange}
            isLoading={isLoading}
          />
        ))}
      </div>
    </div>
  )
}

function Filter({
  filter,
  facets,
  filterState,
  labelPlural,
  optionsById,
  setFilterVisibility,
  visibleFilters,
  isLoading,
  onFilterStateChange,
}: {
  filter: UiFilter
  filterState: FacetsState | undefined
  optionsById: Record<string, SelectOption[]>
  visibleFilters: UiFilter[]
  facets: undefined | AnyFacets
  setFilterVisibility: React.Dispatch<React.SetStateAction<string[]>>
  labelPlural: string
  onFilterStateChange: (updater: Updater<FacetsState | undefined>) => void
  isLoading: boolean
}) {
  const value = React.useMemo(() => {
    return [filterState?.[filter.keys[0]], filterState?.[filter.keys[1]]]
  }, [filter.keys, filterState])

  const draftState = useDraftState<[any, any]>({
    value: value as any,
    onSubmit: value => {
      onFilterStateChange(prev => ({
        ...prev,
        [filter.keys[0]]: value[0],
        [filter.keys[1]]: value[1],
      }))
    },
  })

  const isDirty = draftState.isDirty
  const options = optionsById[filter.id]
  const isVisible = visibleFilters.find(d => d.id === filter.id)
  const inclusiveIsArray = Array.isArray(value[0])
  const exclusiveIsArray = Array.isArray(value[1])
  const inclusiveLength = (
    inclusiveIsArray ? (value[0] as []) || [] : []
  ).filter(d => d !== undefined).length
  const exclusiveLength = (
    exclusiveIsArray ? (value[1] as []) || [] : []
  ).filter(d => d !== undefined).length
  const inclusiveNotUndefined = value[0] !== undefined
  const exclusiveNotUndefined = value[1] !== undefined

  const isActive =
    (inclusiveIsArray ? inclusiveLength : inclusiveNotUndefined) ||
    (exclusiveIsArray ? exclusiveLength : exclusiveNotUndefined)

  // const totalLength =
  //   inclusiveIsArray || exclusiveIsArray
  //     ? inclusiveLength + exclusiveLength
  //     : (inclusiveNotUndefined ? 1 : 0) + (exclusiveNotUndefined ? 1 : 0)

  const facetOptions = (facets?.[filter.facetKey]?.facets ?? []).slice(0, 20)

  const plot = (() => {
    const domain = extent(facetOptions, d => Number(d.count)) as [
      number,
      number
    ]

    const getBetween =
      <T,>(items: [T, T]) =>
      (d: FacetValuePb): T => {
        const between = (() => {
          if (!draftState.draft[0] && !draftState.draft[1]) {
            return false
          }

          if (filter.ui === 'range') {
            const logicalMin = d.logicalMin
            const logicalMax =
              last(facetOptions) === d ? Infinity : d.logicalMax

            return (
              logicalMin >= ((draftState.draft[0] as number) ?? -Infinity) &&
              logicalMax <= ((draftState.draft[1] as number) ?? Infinity)
            )
          }

          if (filter.ui === 'multi-select') {
            const value = filter.type === 'number' ? `${Number(d.id)}` : d.label
            return draftState.draft[0]?.includes(value)
          }
        })()

        return between ? items[0] : items[1]
      }

    return (
      <ObservablePlot<FacetValuePb>
        className="h-full w-full cursor-pointer"
        // onPointerEnter={() => {
        //   setIsChartHovered(true)
        // }}
        // onPointerLeave={() => {
        //   setIsChartHovered(false)
        // }}
        options={{
          ...(!isActive && {
            inset: 0,
            padding: 0,
          }),
          x: {
            axis: null,
            label: filter.label,
            type: 'band',
            domain: facetOptions.map(d => d.label),
            ...(!isActive && {
              paddingInner: 0.2,
              paddingOuter: 0,
              inset: 0,
            }),
          },
          y: {
            axis: null,
            label: labelPlural,
          },
          marks: [
            ...(isActive
              ? [
                  Plot.ruleY([0], {
                    opacity: 0.2,
                  }),
                ]
              : []),
            Plot.barY(facetOptions, {
              x: d => d.label,
              y: d =>
                Math.max(
                  Number(d.count),
                  domain[0] + (domain[1] - domain[0]) * 0.1
                ),
              fill: getBetween(['#0C6A8A', '#888']),
              fillOpacity: getBetween([1, 0.3]),
            }),
            Plot.barY(facetOptions, {
              x: d => d.label,
              y: d => Number(d.count),
              fill: getBetween(['#0C6A8A', '#888']),
              fillOpacity: getBetween([1, 0.3]),
            }),
            ...(isVisible
              ? [
                  Plot.text(facetOptions, {
                    x: d => d.label,
                    y: d => Number(d.count),
                    rotate: 90,
                    fontSize: 8,
                    textAnchor: 'start',
                    dy: 4,
                    clip: true,
                    fill: getBetween(['white', 'currentColor']),
                    fillOpacity: getBetween([1, 0.6]),
                    text: d => d.label,
                  }),
                ]
              : []),
            // Plot.tip(
            //   facetOptions,
            //   Plot.pointerX({
            //     x: d => d.label,
            //     y: d => Number(d.count),
            //   })
            // ),
            Plot.crosshairX(facetOptions, {
              x: d => d.label,
              y: d => Number(d.count),
              textStrokeWidth: 10,
              // ruleStrokeOpacity: getBetween([1, 0.5]),
            }),
          ],
        }}
        onTipClick={(d, e) => {
          const value = filter.type === 'number' ? `${Number(d.id)}` : d.label

          // setIsChartHovered(false)
          if (filter.ui === 'range') {
            const logicalMin = d.logicalMin
            const logicalMax =
              d === last(facetOptions) ? undefined : d.logicalMax

            if (e.shiftKey) {
              draftState.setDraft(prev => [prev[0], logicalMax])
            } else {
              if (
                draftState.draft[0] === logicalMin &&
                draftState.draft[1] === logicalMax
              ) {
                draftState.setDraft([undefined, undefined])
              } else {
                draftState.setDraft([logicalMin, logicalMax])
              }
            }
          } else if (filter.ui === 'multi-select') {
            if (e.shiftKey) {
              const startId = draftState.draft[0]?.[0] as undefined | string

              if (!startId) {
                draftState.setDraft(prev => [
                  options?.slice(
                    0,
                    options.findIndex(d => d.value === value)
                  ),
                  prev[1],
                ])
              } else {
                draftState.setDraft(prev => [
                  options
                    ?.slice(
                      options.findIndex(d => d.value === startId),
                      (options.findIndex(d => d.value === value) ?? -1) + 1
                    )
                    .map(d => d.value),
                  prev[1],
                ])
              }
            } else if (e.metaKey || e.ctrlKey) {
              const prevIds = (draftState.draft[0] ?? []) as string[]
              const found = prevIds.includes(value)

              draftState.setDraft(prev => [
                found ? prevIds.filter(d => d !== value) : [...prevIds, value],
                prev[1],
              ])
            } else {
              const prevIds = (draftState.draft[0] ?? []) as string[]
              const found = prevIds.includes(value)

              if (found) {
                draftState.setDraft(prev => [
                  prevIds.filter(d => d !== value),
                  prev[1],
                ])
              } else {
                draftState.setDraft([[value], undefined])
              }
            }
          }
        }}
      />
    )
  })()

  return (
    <div
      key={filter.label}
      className="z-0 w-full p-1 text-sm hover:relative hover:z-10"
    >
      <div className="flex w-full items-center">
        <button
          className="group flex flex-1 items-center gap-1 p-2"
          onClick={() => {
            if (isVisible) {
              setFilterVisibility(prev => prev.filter(d => d !== filter.id))
            } else {
              setFilterVisibility(prev => [...prev, filter.id])
            }
          }}
        >
          {isVisible ? (
            <FaCaretDown className="opacity-60" />
          ) : (
            <FaCaretRight className="opacity-30" />
          )}
          <div className="text-left font-bold group-hover:text-blue-400">
            {filter.label}
          </div>
          {filter.ui === 'multi-select' && options?.length ? (
            <div className="ml-1 rounded-full border px-1 text-xs font-bold opacity-50">
              {formatNumber(options.length)}
            </div>
          ) : null}
        </button>
        {isActive ? (
          <Tooltip
            tooltip="Remove Filter"
            className="flex items-center justify-center"
          >
            <ClickablePlain
              onClick={() => {
                draftState.setDraft([undefined, undefined], true)
              }}
              className="mx-2 text-yellow-500 hover:text-red-500"
            >
              <FaMinusCircle />
            </ClickablePlain>
          </Tooltip>
        ) : null}
        {!isVisible && isDirty ? (
          <div className="flex flex-wrap gap-2 ">
            <Tooltip tooltip="Confirm">
              <button
                type="button"
                className="flex items-center justify-center text-green-500"
                onClick={() => {
                  draftState.submit()
                }}
              >
                <FaCheck />
              </button>
            </Tooltip>
            <Tooltip tooltip="Cancel">
              <button
                type="button"
                className="flex items-center justify-center text-red-500"
                onClick={() => {
                  draftState.reset()
                }}
              >
                <FaTimes />
              </button>
            </Tooltip>
          </div>
        ) : null}
        {!isVisible && filter.showBins
          ? (() => {
              if (isLoading) {
                return (
                  <Loader
                    color="gray-500"
                    className="mx-2 text-[.4rem] opacity-30"
                  />
                )
              }

              if (filter.showBins) {
                return (
                  <div
                    className={twMerge(
                      'h-[30px] self-stretch p-1 opacity-40 transition-opacity hover:opacity-100',
                      isActive && 'opacity-80'
                    )}
                    style={{
                      width: Math.min(
                        uniqBy(facetOptions, d => d.label).length * 10,
                        80
                      ),
                    }}
                  >
                    {plot}
                  </div>
                )
              }
            })()
          : null}
      </div>
      {isVisible ? (
        <div className="w-full space-y-2 p-1">
          {(() => {
            if (filter.showBins) {
              return (
                <div
                  className="h-[50px] max-w-full"
                  style={{
                    width: Math.min(
                      uniqBy(facetOptions, d => d.label).length * 30
                    ),
                  }}
                >
                  {plot}
                </div>
              )
            }
          })()}
          <div
            className={twMerge(
              'transition-opacity duration-200'
              // isChartHovered && 'opacity-0'
            )}
          >
            {filter.ui === 'range' ? (
              <RangeFilter
                facets={facets}
                filter={filter}
                value={draftState.draft}
                onChange={draftState.setDraft}
              />
            ) : filter.ui === 'multi-select' ? (
              <MultiSelectFilter
                value={draftState.draft}
                onChange={draftState.setDraft}
                options={options}
                filter={filter}
              />
            ) : filter.ui === 'text' ? (
              <TextFilter
                value={draftState.draft}
                onChange={draftState.setDraft}
                options={options}
                filter={filter}
              />
            ) : null}
          </div>
          {isDirty ? (
            <div className="flex flex-wrap gap-2">
              <ButtonPlain
                className="flex items-center gap-1 bg-green-500 text-xs"
                onClick={() => {
                  draftState.submit()
                }}
              >
                <FaCheck /> Apply
              </ButtonPlain>
              <ButtonPlain
                className="flex items-center gap-1 bg-red-500 text-xs"
                onClick={() => {
                  draftState.reset()
                }}
              >
                <FaTimes /> Cancel
              </ButtonPlain>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  )
}

function RangeFilter({
  facets,
  filter,
  value,
  onChange,
}: {
  facets: undefined | AnyFacets
  value: [number, number]
  filter: UiFilter
  onChange: (updater: UpdaterFn<[number, number]>) => void
}): React.ReactNode {
  return (
    <div className="flex flex-wrap gap-1">
      <NumberInputWrap className="flex-1">
        <NumberInput
          value={value[0]}
          placeholder={`Min (${formatNumber(
            facets?.[filter.facetKey]?.min ?? NaN
          )})`}
          onChange={(value: any) => {
            onChange(prev => [value, prev[1]])
          }}
          // Input={PlainInput}
          min={facets?.[filter.facetKey]?.min}
          max={facets?.[filter.facetKey]?.max}
          allowUndefined
          className="w-full text-xs"
        />
      </NumberInputWrap>
      <NumberInputWrap className="flex-1">
        <NumberInput
          value={value[1]}
          placeholder={`Max (${formatNumber(
            facets?.[filter.facetKey]?.max ?? NaN
          )})`}
          onChange={(value: any) => {
            onChange(prev => [prev[0], value])
          }}
          // Input={PlainInput}
          min={facets?.[filter.facetKey]?.min}
          max={facets?.[filter.facetKey]?.max}
          allowUndefined
          className="w-full text-xs"
        />
      </NumberInputWrap>
    </div>
  )
}

function MultiSelectFilter({
  value,
  onChange,
  options,
  filter,
}: {
  value: [string[] | undefined, string[] | undefined]
  onChange: React.Dispatch<
    React.SetStateAction<[string[] | undefined, string[] | undefined]>
  >
  options: SelectOption[] | undefined
  filter: UiFilter
}): React.ReactNode {
  return (
    <div className="flex w-full gap-1">
      <Select
        value={value[0]}
        options={options}
        multi={!!options}
        create
        onChange={(value: any) => onChange(prev => [value, prev[1]])}
        // CustomInput={PlainInput}
        placeholder={`Include ${filter.label}...`}
        className="flex-1"
        inputProps={{
          className: 'text-xs',
        }}
      />
      <Select
        value={value[1]}
        options={options}
        multi={!!options}
        create
        onChange={(value: any) => onChange(prev => [prev[0], value])}
        // CustomInput={PlainInput}
        placeholder={`Exclude ${filter.label}...`}
        className="flex-[0_1_90px]"
        inputProps={{
          className: 'min-w-0 text-xs',
        }}
      />
    </div>
  )
}

function TextFilter({
  value,
  onChange,
  filter,
}: {
  value: [string[] | undefined, string[] | undefined]
  onChange: React.Dispatch<
    React.SetStateAction<[string[] | undefined, string[] | undefined]>
  >
  options: SelectOption[] | undefined
  filter: UiFilter
}) {
  return (
    <div className="flex w-full gap-1">
      <Select
        value={value[0]}
        multi
        create
        placeholder={`Include ${filter.label}...`}
        onChange={(value: any) => onChange(prev => [value, prev[1]])}
        // CustomInput={PlainInput}
        className="flex-1"
        inputProps={{
          className: 'text-xs',
        }}
      />
      <Select
        value={value[1]}
        multi
        create
        placeholder={`Exclude ${filter.label}...`}
        onChange={(value: any) => onChange(prev => [prev[0], value])}
        // CustomInput={PlainInput}
        className="flex-[0_1_90px]"
        inputProps={{
          className: 'text-xs',
        }}
      />
    </div>
  )
}
export function filtersPbFromSearch<TFiltersPb>(
  filterList: UiFilter[],
  searchFilters: Record<string, any>,
  options?: {
    searchKey?: string
  }
): TFiltersPb {
  const filters = {} as any

  if (options?.searchKey) {
    filters[options.searchKey] = searchFilters?.search
  }

  filterList.forEach(filter => {
    if (filter.ui === 'range') {
      let min = searchFilters?.[filter.keys[0]] as undefined | number
      let max = searchFilters?.[filter.keys[1]] as undefined | number

      if (
        (typeof min === 'number' ? min : -Infinity) >
        (typeof max === 'number' ? max : Infinity)
      ) {
        const temp = min
        min = max
        max = temp
      }

      if (typeof min === 'number') {
        filters[filter.keys[0]] = min
      }
      if (typeof max === 'number') {
        filters[filter.keys[1]] = max
      }
    }

    if (filter.ui === 'multi-select') {
      const include = searchFilters?.[filter.keys[0]]
      const exclude = searchFilters?.[filter.keys[1]]

      if (Array.isArray(include)) {
        filters[filter.keys[0]] = include.map(d =>
          filter.type === 'number' ? BigInt(d as any) : d
        ) as string[]
      }
      if (Array.isArray(exclude)) {
        filters[filter.keys[1]] = exclude.map(d =>
          filter.type === 'number' ? BigInt(d as any) : d
        ) as string[]
      }
    }

    if (filter.ui === 'text') {
      const include = searchFilters?.[filter.keys[0]]
      const exclude = searchFilters?.[filter.keys[1]]

      if (include !== undefined) {
        filters[filter.keys[0]] = include as string
      }
      if (exclude !== undefined) {
        filters[filter.keys[1]] = exclude as string
      }
    }
  })

  return filters
}
