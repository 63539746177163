import * as React from 'react'
import { useDropzone } from 'react-dropzone'
import { useField } from 'react-form'
import { FaCheckCircle, FaInfoCircle } from 'react-icons/fa'
import Button from '../components/Button'
import { Dropzone } from '../components/Dropzone'
import Input from '../components/Input'
import JsonPhrases from '../components/JsonPhrases'
import usePopup from '../hooks/usePopup'
import useToast from '../hooks/useToast'
import { Keyword, KeywordSource } from '../utils/Api'
import Excel from '../utils/Excel'
import Card from './Card'

//

const defaultConfigValue: any = []

export default function KeywordSourceKeywordsAdvanced(props: {
  keywordSource: KeywordSource
  generatedKeywords: Keyword[]
  isGeneratingKeywords: boolean
}) {
  const { value: jsonKeywords, setValue: setJsonKeywords } = useField(
    'config.jsonKeywords',
    {
      defaultValue: defaultConfigValue,
    }
  )

  const toast = useToast()
  const popup = usePopup()

  const downloadTemplate = () => {
    Excel.saveToFile(
      'keywords',
      jsonToExcel([
        {
          phrase: 'Sample Keyword Phrase 1',
          devices: ['d', 'm'],
          localeIds: ['44249'],
          groups: ['Keyword Group 1', 'Keyword Group 2', 'Keyword Group 3'],
        },
        {
          phrase: 'Sample Keyword Phrase 2',
          devices: ['d', 'm'],
          localeIds: ['44249', '38283'],
          groups: ['Keyword Group 2', 'Keyword Group 4'],
        },
      ])
    )
  }

  const onDropAccepted = async (acceptedFiles: any) => {
    const [acceptedFile] = acceptedFiles
    const data = await Excel.importFromFile(acceptedFile)
    const json = excelToJson(data)

    setJsonKeywords(json)
    setDropped(acceptedFile)

    toast({
      color: 'green-500',
      message: `Imported ${json.length} rows`,
    })
  }

  const onDropRejected = async () => {
    popup({
      color: 'red-500',
      title: 'File Not Accepted!',
      message: `Files must be of type .csv, .xls, or .xlsx. Please try a different file.`,
    })
  }
  const [dropped, setDropped] = React.useState(false) as any
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    multiple: false,
    accept:
      '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
    onDropAccepted,
    onDropRejected,
    noDrag: dropped,
    noClick: dropped,
  })

  return (
    <>
      <JsonPhrases keywords={jsonKeywords} onKeywordsChange={setJsonKeywords} />
      <Card className="space-y-1 p-2">
        <div className="text-xl font-bold">Import Keywords </div>
        <div>
          <Button
            size="xs"
            color={['gray-200', 'gray-800']}
            onClick={downloadTemplate}
            className="mb-1 mr-1"
          >
            Download Template
          </Button>
        </div>
        <Dropzone
          {...getRootProps({
            isDragActive,
            isDragAccept,
            isDragReject,
            dropped,
          })}
          className="flex flex-col gap-2 divide-y divide-gray-500/20"
        >
          <div className="p-2">
            <FaInfoCircle className="inline text-blue-500" /> Drop an Excel file
            or click to upload
          </div>
          {dropped ? (
            <div className="p-2">
              <FaCheckCircle className="inline text-green-500" /> Successfully
              uploaded <strong>{dropped.path}</strong>
            </div>
          ) : null}

          <Input {...getInputProps()} />
        </Dropzone>
      </Card>
    </>
  )
}

function excelToJson(excel: any) {
  return excel
    .map(({ phrase, localeIds, devices, groups }: any) => {
      localeIds = String(localeIds)
      return {
        phrase,
        localeIds: !Array.isArray(localeIds)
          ? localeIds?.split(';').map((d: any) => parseInt(d.trim()))
          : localeIds,
        devices: String(devices)
          ?.split(';')
          .map(d => d.trim()),
        groups: String(groups)
          ?.split(';')
          .map(d => d.trim()),
      }
    })
    .filter((d: any) => d.phrase)
}

function jsonToExcel(json: any) {
  return json.map(({ phrase, localeIds, devices, groups }: any) => ({
    phrase,
    localeIds: localeIds.join('; '),
    devices: devices.join('; '),
    groups: groups.join('; '),
  }))
}
