import * as React from 'react'
import { useField } from 'react-form'
import { FaPlus } from 'react-icons/fa'
import Button from '../components/Button'
import LabelWrap from '../components/LabelWrap'
import Removable from '../components/Removable'
import SelectField from '../components/SelectField'
import TextField from '../components/TextField'
import ToggleField from '../components/ToggleField'
import { LocalesField } from './LocalesField'

//

const defaultValue = [
  {
    template: '{phrase}',
    trackBaseLocale: true,
    additionalGroups: ['no city'],
  },
  {
    template: '{phrase} in {city}',
    trackBaseLocale: true,
    additionalGroups: ['in city'],
  },
  {
    template: '{phrase} near me',
    trackBaseLocale: true,
    additionalGroups: ['near me'],
  },
  {
    template: '{phrase} in {city} near me',
    trackBaseLocale: true,
    additionalGroups: ['in city near me'],
  },
]

const localesFilterOnlyCity = (d: any) => d.type === 'City'
const dimensionsFilterNoType = (d: any) => d.id !== 'type'

export default function PhraseLocaleTemplatesField({
  field,
  label = 'Phrase Templates',
  defaultLocaleIds,
}: any) {
  // @ts-expect-error  // Property 'FieldScope' does not exist on type 'Fiel... Remove this comment to see the full error message
  const { FieldScope, value, pushValue, removeValue } = useField(field, {
    defaultValue,
  })

  const handleAddPhrase = () => {
    pushValue({
      template: '',
      trackBaseLocale: true,
      additionalGroups: [],
    })
  }

  return (
    <>
      <LocalesField
        field="localeIds"
        label="Base City Locales"
        placeholder="Select City Locales..."
        filter={localesFilterOnlyCity}
        filterDimensions={dimensionsFilterNoType}
        defaultValue={defaultLocaleIds}
      />

      <div className="h-2" />
      <LabelWrap label={label}>
        <FieldScope>
          {value.map((phraseTemplate, index) => (
            <Removable key={index} onRemove={() => removeValue(index)} inset>
              <div className="flex flex-wrap gap-4">
                <div className="w-[100px] flex-1">
                  <TextField
                    field={`${index}.template`}
                    label="Phrase Template"
                  />
                </div>
                <div className="w-[180px] text-center">
                  <ToggleField
                    // @ts-expect-error  // Type '{ field: string; label: string; }' is not as... Remove this comment to see the full error message
                    field={`${index}.trackBaseLocale`}
                    label="Track From Base City Locale"
                  />
                </div>
                <div className="w-[100px] flex-1">
                  <SelectField
                    field={`${index}.additionalGroups`}
                    multi
                    create
                    label="Additional Keyword Groups"
                    placeholder="Add groups..."
                  />
                </div>
              </div>
            </Removable>
          ))}
          <div className="h-2" />
          <Button size="sm" onClick={handleAddPhrase} color="gray-500">
            <FaPlus className="inline" /> Add Phrase Template
          </Button>
        </FieldScope>
      </LabelWrap>
    </>
  )
}
