import { createColumnHelper, RowSelection } from '@tanstack/react-table'
import * as React from 'react'
import { FaEdit, FaTrashAlt } from 'react-icons/fa'
import Button from '../components/Button'
import Select from '../components/Select'
import useConfirm from '../hooks/useConfirm'
import { renderLocale, useLocalesQuery } from '../utils/locales'
import { useTable } from '../hooks/useTable'
import { NO_GROUP_NAME } from '../utils/Constants'
import { formatLocale } from '../utils/locales'
import { dedupeKeywords } from '../utils/Phrases'
import Clickable from './Clickable'
import { Gate } from './Gate'
import { LocaleInput } from './LocaleInput'
import { LocalePicker } from './LocalePicker'
import QueryGate from './QueryGate'
import Table from './Table'

type LocaleCount = {
  localeId: string
  phraseCount: number
}

//

const columnHelper = createColumnHelper<LocaleCount>()

export default function JsonKeywordsLocales({
  keywords,
  onKeywordsChange,
}: any) {
  const confirm = useConfirm()

  const info = React.useMemo(() => dedupeKeywords(keywords), [keywords])

  const localeIds = React.useMemo(
    () => Object.keys(info.localeIds),
    [info.localeIds]
  )

  const localesByIdQuery = useLocalesQuery({
    localeIds,
  })

  const tableData = React.useMemo<LocaleCount[]>(
    () =>
      Object.entries(info.localeIds).map(([localeId, phrases]: any) => {
        return {
          localeId,
          phraseCount: phrases.length,
        }
      }),
    [info.localeIds]
  )

  const columns = React.useMemo(
    () => [
      columnHelper.accessor(
        row => {
          return localesByIdQuery.data
            ? formatLocale(
                // @ts-expect-error  // Argument of type 'Locale | undefined' is not assig... Remove this comment to see the full error message
                localesByIdQuery.data.find(d => d.locale_id == row.localeId)
              )
            : row.localeId
        },
        {
          id: 'locale',
          header: 'Locale',
          filterFn: 'arrIncludesSome',
          cell: props => {
            return (
              <QueryGate query={localesByIdQuery}>
                {() => {
                  return renderLocale(
                    localesByIdQuery.data.find(
                      d => d.locale_id == props.row.original.localeId
                    )
                  )
                }}
              </QueryGate>
            )
          },
        }
      ),
      columnHelper.accessor('phraseCount', {
        header: 'Keyword Phrases',
      }),
      columnHelper.display({
        id: 'actions',
        header: 'Actions',
        cell: props => (
          <div className="flex items-center gap-2">
            {props.row.original.localeId !== NO_GROUP_NAME ? (
              <>
                <LocalePicker
                  onChange={async value => {
                    const confirmed = await confirm({
                      message: `This will change all of the phrases in this row to this locale.`,
                    })

                    if (confirmed) {
                      keywords.forEach((keyword: any) => {
                        keyword.localeIds = keyword.localeIds
                          ? keyword.localeIds.map((localeId: any) =>
                              localeId === props.row.original.localeId
                                ? value
                                : localeId
                            )
                          : []
                      })
                      onKeywordsChange([...keywords])
                    }
                  }}
                  // @ts-expect-error  // Type '{ children: ({ onClick }: any) => Element; o... Remove this comment to see the full error message
                  className="inline-flex"
                >
                  {({ onClick }: any) => (
                    <Clickable onClick={onClick}>
                      <FaEdit className="inline" /> Change Locale
                    </Clickable>
                  )}
                </LocalePicker>{' '}
                <Clickable
                  onClick={async () => {
                    const confirmed = await confirm({
                      message: `This will delete any keyword phrases from this source that have this locale, regardless if they are using other locales.`,
                    })

                    if (confirmed) {
                      onKeywordsChange(
                        keywords.filter((keyword: any) => {
                          return !(keyword.localeIds || []).find(
                            (localeId: any) =>
                              localeId === props.row.original.localeId
                          )
                        })
                      )
                    }
                  }}
                >
                  <FaTrashAlt className="inline" /> Remove Keyword Phrases
                </Clickable>
              </>
            ) : null}
          </div>
        ),
      }),
    ],
    [localesByIdQuery.data, confirm, keywords, onKeywordsChange]
  )

  const table = useTable({
    columns,
    data: tableData,
    initialState: {
      pagination: {
        pageSize: 10,
      },
      sorting: [{ id: 'locale', desc: false }],
    },
    showToolbar: true,
  })

  return (
    <div className="rounded-lg border border-gray-200 dark:border-gray-800">
      <Table table={table} />
    </div>
  )
}
