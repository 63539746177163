import * as React from 'react'
import { splitFormProps, useField } from 'react-form'
import LabeledInput from '../components/LabeledInput'
import { NumberInputProps } from './NumberInput'

//

export interface NumberFieldProps extends NumberInputProps {
  field?: string
  label?: React.ReactNode
  validate?: (e: any) => void
  filterValue?: (str?: string) => string
  inputProps?: any
}

export default React.forwardRef(function TextField(
  { onChange, ...props }: NumberFieldProps,
  ref
) {
  const [field, formProps, rest] = splitFormProps(props)
  const {
    value,
    meta: { error, isTouched, isValidating },
    setValue,
    setMeta,
    // @ts-expect-error  // Argument of type '{ field?: string | undefined; la... Remove this comment to see the full error message
  } = useField(field, formProps)

  return (
    <LabeledInput
      ref={ref}
      onChange={e => {
        // @ts-expect-error  // Property 'value' does not exist on type 'EventTarg... Remove this comment to see the full error message
        setValue(e.target.value)
        if (onChange) {
          // @ts-expect-error  // Argument of type 'ChangeEvent<HTMLInputElement> | ... Remove this comment to see the full error message
          onChange(e)
        }
      }}
      // @ts-expect-error  // Type 'FocusEventHandler<HTMLInputElement>' is not ... Remove this comment to see the full error message
      onBlur={() => setMeta({ isTouched: true })}
      // @ts-expect-error  // 'value' is specified more than once, so this usage... Remove this comment to see the full error message
      value={value || ''}
      error={isTouched && error}
      isValidating={isValidating}
      {...rest}
    />
  )
})
