import { DisplayColumnDef } from '@tanstack/react-table'
import * as React from 'react'
import { IndeterminateCheckbox } from './IndeterminateCheckbox'

export const createCheckboxColumn = (): any =>
  ({
    id: 'selection',
    header: ({ table }) => (
      <label className="flex cursor-pointer items-center px-2 text-center">
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      </label>
    ),
    cell: ({ row }) => (
      <label className="flex cursor-pointer items-center px-2 text-center">
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </label>
    ),
    meta: {
      tight: true,
      getHeaderProps: () => ({
        className: 'p-0',
      }),
      getCellProps: () => ({
        className: 'p-0',
      }),
    },
  } as DisplayColumnDef<unknown, unknown>)
