import { useMatch } from '@tanstack/react-router'
import * as React from 'react'
import { useField, useForm } from 'react-form'
import { FaCaretDown, FaInfoCircle, FaQuestionCircle } from 'react-icons/fa'
import Caption from '../../components/Caption'
import Clickable from '../../components/Clickable'
import KeywordSourceKeywordsAdvanced from '../../components/KeywordSourceKeywordsAdvanced'
import { KeywordSourceBasic } from '../../components/KeywordSourceKeywordsBasic'
import { KeywordSourceCity } from '../../components/KeywordSourceKeywordsCity'
import { KeywordSourceButtons } from '../../components/KeywordSourceSaveButton'
import Select from '../../components/Select'
import Separator from '../../components/Separator'
import TextField from '../../components/TextField'
import Tooltip from '../../components/Tooltip'
import { TotalTrackedKeywords } from '../../components/TotalTrackedKeywords'
import {
  useConvertKeywordSourceKeywords,
  useCreateKeywordSource,
  useGenerateKeywordSourceKeywords,
  useRemoveKeywordSourceById,
  useSaveKeywordSource,
} from '../../hooks/keywordSources'
import useAsyncDebounce from '../../hooks/useAsyncDebounce'
import useConfirm from '../../hooks/useConfirm'
import useErrorPopup from '../../hooks/useErrorPopup'
import useNavigate from '../../hooks/useNavigate'
import usePauseEvents from '../../hooks/usePauseEvents'
import usePopup from '../../hooks/usePopup'
import useSearch from '../../hooks/useSearch'
import { useActiveWorkspaceId } from '../../hooks/workspaces'
import { keywordSourceTypeOptions } from '../../options/keywordSourceTypeOptions'
import { scheduleOptions } from '../../options/scheduleOptions'
import { KeywordSource } from '../../utils/Api'
import {
  KEYWORD_SOURCE_TYPE_BASIC,
  KEYWORD_SOURCE_TYPE_CITY,
  KEYWORD_SOURCE_TYPE_JSON,
} from '../../utils/Constants'
import Validate from '../../utils/Validate'
import { GeneratedKeywordsTable } from './GeneratedKeywordsTable'
import { LocationGenerics } from '../../LocationGenerics'
import TextArea from '../../components/TextAreaInput'
import LabelWrap from '../../components/LabelWrap'
import { useActiveProjectIdState } from '../../utils/searchParams'
import Card from '../../components/Card'

//

export function KeywordSourceV1(props: {
  keywordSource?: KeywordSource | Partial<KeywordSource>
}) {
  const projectId = useActiveProjectIdState().state
  const saveKeywordSource = useSaveKeywordSource()
  const saveSilentKeywordSource = useSaveKeywordSource({ silent: true })
  const confirm = useConfirm()
  const [isGeneratingKeywords, setIsGeneratingKeywords] = React.useState(false)
  const [generatedKeywords, setGeneratedKeywords] = React.useState([])
  const [isDirty, setIsDirty] = React.useState(false)
  const workspaceId = useActiveWorkspaceId()
  const createKeywordSource = useCreateKeywordSource()
  const createSilentKeywordSource = useCreateKeywordSource({ silent: true })
  const { sourceType } = useSearch()
  const popup = usePopup()
  const debounce = useAsyncDebounce()
  const generateKeywordSourceKeywords = useGenerateKeywordSourceKeywords()
  const convertKeywordSourceKeywords = useConvertKeywordSourceKeywords()
  const navigate = useNavigate()
  const errorPopup = useErrorPopup()
  const removeKeywordSourceById = useRemoveKeywordSourceById()

  const onSubmit = async (values: any) => {
    // Require a name
    if (!values.name) {
      const confirmed = await popup({
        title: 'Name Required!',
        message: `This Keyword Source requires a name. Please give this Keyword Source a name and try saving again.`,
      })
      if (confirmed) {
        return
      }
    }

    // Require devices and locales
    if (values.type === 'basic') {
      if (!values.config.devices?.length || !values.config.localeIds?.length) {
        const confirmed = await confirm({
          title: 'Your current configuration is missing devices or locales!',
          message:
            'At least one device and locale is required to pull keywords. Are you sure you want to save without any Devices or Locales?',
        })
        if (!confirmed) {
          return
        }
      }
    }

    // Make sure we finish adding phrases
    if (isDirty) {
      const confirmed = await confirm({
        message:
          'You have entered new keyword phrases that have not been added yet.',
      })
      if (!confirmed) {
        return
      }
      setIsDirty(false)
    }

    // Make sure keywords are configured
    if (values.config.jsonKeywords?.length - generatedKeywords?.length > 0) {
      const confirmed = await confirm({
        message: `There are ${
          values.config.jsonKeywords.length - generatedKeywords.length
        } keywords not configured correctly. Are you sure you want to continue? `,
      })
      if (!confirmed) {
        return
      }
    }

    // Ensure we have schedules set
    if (!values.schedules.length) {
      const confirmed = await confirm({
        message: `There are currently no schedules entered for this keyword source. Are you sure you want to save without scheduling pulls?`,
      })
      if (!confirmed) {
        return
      }
    }

    // Warn about hourly rankings
    if (
      values.schedules.find(
        (schedule: any) => schedule?.id === 'FREQ=HOURLY;BYMINUTE=0;BYSECOND=0'
      )
    ) {
      const confirmed = await confirm({
        title: 'Are you sure you want to monitor your rankings hourly?',
        message: `This could potentially result in a large amount of daily usage, starting today. Are you sure you want to proceed?`,
        confirmText: "Yes, I'm Sure",
        cancelText: 'Cancel',
      })

      if (!confirmed) {
        setFieldValue(
          'schedules',
          values.schedules.filter(
            (schedule: any) =>
              schedule?.id !== 'FREQ=HOURLY;BYMINUTE=0;BYSECOND=0'
          )
        )

        return
      }
    }

    try {
      let newkeywordsSource: any

      const isDailyOrFaster = scheduleOptions
        .filter(d => d.dailyOrFaster)
        .map(d => d.value)
        .includes(values.schedules[0]?.id)

      if (
        !isDailyOrFaster &&
        (await confirm({
          title:
            'In addition to your configured schedules, would you like us to process these keywords immediately?',
          message: `Any overlap with keywords already pulled today will not be double charged.`,
          cancelText: 'No. Wait for the next scheduled time.',
          confirmText:
            'Yes. Process immediately, then use my schedules as normal.',
        }))
      ) {
        // this daily ensures it will start a fetch immediately
        const valuesWithDaily = Object.assign({}, values, {
          schedules: [
            ...values.schedules,
            { id: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0' },
          ],
        })

        // Create or update the keyword source
        if (props.keywordSource?.id) {
          newkeywordsSource = await saveSilentKeywordSource(valuesWithDaily)
        } else {
          newkeywordsSource = await createSilentKeywordSource({
            workspaceId,
            teamId: projectId,
            ...valuesWithDaily,
          })
        }

        // Remove the temporary schedule
        newkeywordsSource.schedules?.pop()

        // Wait for 1 second to avoid version collisions
        await new Promise(resolve => setTimeout(resolve, 1000))

        // Resave the keyword source
        await saveKeywordSource(newkeywordsSource)

        if (!props.keywordSource) {
          await new Promise(resolve => setTimeout(resolve, 500))
          navigate({
            to: `/keyword-sources/${newkeywordsSource.id}`,
          })
        }
      } else {
        if (props.keywordSource?.id) {
          newkeywordsSource = await saveKeywordSource(values)
        } else {
          newkeywordsSource = await createKeywordSource({
            workspaceId,
            teamId: projectId,
            ...values,
          })

          await new Promise(resolve => setTimeout(resolve, 500))
          navigate({
            to: `/keyword-sources/${newkeywordsSource.id}`,
          })
        }
      }
      setFieldMeta('schedules', old => ({ ...old, isTouched: false }))
    } catch (err) {
      console.error(err)
      errorPopup('Failed to save Keyword Source.')
    }
  }

  usePauseEvents('keywordSource', props.keywordSource?.id, true)

  const defaultValues = React.useMemo(() => {
    const keywordSource =
      props.keywordSource ?? ({ config: {}, type: sourceType } as KeywordSource)

    return keywordSource
  }, [props.keywordSource, sourceType])

  const {
    Form,
    values,
    setValues,
    meta: { isSubmitting, canSubmit },
    formContext,
    setFieldValue,
    setFieldMeta,
  } = useForm({
    defaultValues,
    onSubmit: async values => {
      await onSubmit(values)
    },
  })

  React.useEffect(() => {
    setIsGeneratingKeywords(true)
    debounce(async () => {
      try {
        setGeneratedKeywords(await generateKeywordSourceKeywords(values))
      } finally {
        setIsGeneratingKeywords(false)
      }
    }, 3000)
  }, [debounce, generateKeywordSourceKeywords, values])

  const { value: keywordSourceType, setValue: setKeywordSourceType } = useField(
    'type',
    {
      defaultValue: KEYWORD_SOURCE_TYPE_BASIC,
      formContext,
    }
  )

  const keyDown: React.KeyboardEventHandler<HTMLFormElement> = e => {
    if (e.key === 'Enter' && e.currentTarget?.type !== 'textarea') {
      e.preventDefault()
    }
  }

  const { setValue: setConfig } = useField('config', {
    formContext,
  })

  const hasPhrases =
    keywordSourceType === KEYWORD_SOURCE_TYPE_JSON
      ? values.config?.jsonKeywords?.length
      : values.config?.phraseGroups?.length

  return (
    <Form onKeyDown={keyDown} className="space-y-2">
      <Card className="p-2">
        <div className="flex flex-wrap items-center justify-between gap-2">
          <div className="flex items-center gap-2 text-xl font-bold">
            <div>Mode: </div>
            <Select
              value={keywordSourceType}
              onChange={async newType => {
                if (newType !== keywordSourceType) {
                  if (props.keywordSource && newType !== 'city') {
                    const newKeywordSource = await convertKeywordSourceKeywords(
                      props.keywordSource,
                      newType
                    )
                    if (
                      props.keywordSource?.keywordCount !==
                      newKeywordSource?.keywordCount
                    ) {
                      const confirmed = await confirm({
                        message: `There were ${
                          props.keywordSource?.keywordCount
                        } keywords in ${
                          keywordSourceTypeOptions.find(
                            (option: any) => option.value === keywordSourceType
                          ).label
                        } config, and ${
                          newKeywordSource?.keywordCount
                        } keywords in the ${
                          keywordSourceTypeOptions.find(
                            (option: any) =>
                              option.value === newKeywordSource.type
                          ).label
                        } config. Any unsaved changes will lost.`,
                      })
                      if (!confirmed) {
                        return
                      }
                    }

                    setValues({
                      ...newKeywordSource,
                      id: props.keywordSource.id,
                    })
                  } else if (props.keywordSource && newType === 'city') {
                    const confirmed = await confirm({
                      message:
                        'Conversion to City Generated Keywords is not yet supported. You will lose your current source configuration and keywords!',
                    })

                    if (!confirmed) {
                      return
                    }
                    // @ts-expect-error  // Expected 1 arguments, but got 2.
                    setKeywordSourceType(newType, { isTouched: false })
                    setConfig(
                      (old: any) => ({
                        phraseGroups: old.phraseGroups,
                      }),
                      // @ts-expect-error  // Expected 1 arguments, but got 2.
                      { isTouched: false }
                    )
                  } else {
                    // @ts-expect-error  // Expected 1 arguments, but got 2.
                    setKeywordSourceType(newType, { isTouched: false })
                  }
                }
              }}
              options={keywordSourceTypeOptions}
            >
              {({ onClick, selectedOption }: any) => (
                <Clickable onClick={onClick}>
                  <div className="underline">
                    {selectedOption.label} <FaCaretDown className="inline" />
                  </div>
                </Clickable>
              )}
            </Select>
          </div>
          <Tooltip
            tooltip={
              keywordSourceType === KEYWORD_SOURCE_TYPE_BASIC ? (
                <div>
                  The “Basic Keywords” mode multiplies all your keyword phrases,
                  devices, and locales together to produce a final set of
                  keywords. For most keyword sources, this mode is sufficient.
                </div>
              ) : keywordSourceType === KEYWORD_SOURCE_TYPE_CITY ? (
                <div>
                  The "Template Generator" mode is specifically tailored for
                  generating geo-specific keyword phrase combinations. It
                  multiples keyword phrases and devices against city-phrase
                  templates to produce keywords that both include the city in
                  the keyword phrase and also originate from the city when
                  searched.
                </div>
              ) : keywordSourceType === KEYWORD_SOURCE_TYPE_JSON ? (
                <div>
                  The "Advanced Keyword" mode allows you to manage your keywords
                  manually via a granular Table and even by importing and
                  exporting Excel files. It also includes support for multiple
                  in-cell values using the ";" delimiter!
                </div>
              ) : null
            }
          >
            <Caption className="flex items-center gap-1">
              <FaQuestionCircle className="inline" /> What are keyword source
              modes?
            </Caption>
          </Tooltip>
        </div>
      </Card>
      <Card className="space-y-4 p-2">
        <div className="text-xl font-bold">Keyword Source Name</div>
        <div className="flex gap-2 [&>*]:flex-1">
          <div>
            <TextField
              field="name"
              label="Name"
              placeholder="e.g. US English
                Mobile"
              validate={Validate.required('A Keyword Source name is required.')}
            />
            <div className="h-2" />
            <Caption className="font-normal">
              <FaInfoCircle className="inline" /> It's helpful to be specific
              when giving your keyword source a name. For example, “US English
              Mobile”, “France - French - Desktop & Mobile”, etc. Don’t worry,
              you can change it later.
            </Caption>
            <div className="h-2" />
          </div>
          {values.id ? (
            <div>
              <TextField
                field="id"
                label="Keyword Source ID"
                placeholder="-"
                disabled
              />
            </div>
          ) : null}
        </div>
      </Card>
      {props.keywordSource?.description ? (
        <div className="p-2">
          <LabelWrap label="Description">
            <TextArea
              value={props.keywordSource?.description}
              label="Description"
              placeholder="A useful description of this project..."
              rows={Math.min(
                props.keywordSource?.description.split('\n').length + 1,
                20
              )}
            />
          </LabelWrap>
        </div>
      ) : null}
      {keywordSourceType === KEYWORD_SOURCE_TYPE_BASIC ? (
        <KeywordSourceBasic
          keywordSource={props.keywordSource}
          generatedKeywords={generatedKeywords}
          isGeneratingKeywords={isGeneratingKeywords}
        />
      ) : keywordSourceType === KEYWORD_SOURCE_TYPE_JSON ? (
        <KeywordSourceKeywordsAdvanced
          // @ts-expect-error  // Type 'KeywordSource | undefined' is not assignable... Remove this comment to see the full error message
          keywordSource={props.keywordSource}
          generatedKeywords={generatedKeywords}
          isGeneratingKeywords={isGeneratingKeywords}
        />
      ) : keywordSourceType === KEYWORD_SOURCE_TYPE_CITY ? (
        <KeywordSourceCity
          // @ts-expect-error  // Type 'KeywordSource | undefined' is not assignable... Remove this comment to see the full error message
          keywordSource={props.keywordSource}
          generatedKeywords={generatedKeywords}
          isGeneratingKeywords={isGeneratingKeywords}
        />
      ) : null}
      <Card className="space-y-2 p-2">
        <TotalTrackedKeywords
          generatedKeywords={generatedKeywords}
          isFetching={isGeneratingKeywords}
        />
        <GeneratedKeywordsTable
          generatedKeywords={generatedKeywords}
          isFetching={isGeneratingKeywords}
        />
      </Card>
      <Card className="sticky bottom-2 z-[1000000] flex flex-wrap items-center gap-2 p-2">
        <KeywordSourceButtons
          canSubmit={canSubmit}
          hasPhrases={hasPhrases as any}
          isSubmitting={isSubmitting}
          onRemove={
            props.keywordSource?.id
              ? async () => {
                  if (
                    await confirm({
                      title: 'Are you sure you want to delete this source?',
                      message: `This will permanently delete this keyword source and all of its keywords. This action cannot be undone.`,
                      confirmText: 'Yes, Delete',
                      cancelText: 'Cancel',
                    })
                  ) {
                    await removeKeywordSourceById(props.keywordSource!.id)
                    navigate({
                      to: `/keyword-manager`,
                    })
                  }
                }
              : undefined
          }
        />
      </Card>
    </Form>
  )
}
