const name = `nozzle_cache`
const version = 2
const objStoreKey = 'nozzle_object_store'

type Result = {
  id: string
  value: any
  ttl?: number
  updatedAt: number
}

export class NozzleCacheClient {
  private db!: Promise<IDBDatabase>

  async init() {
    if (this.db) return await this.db

    const openRequest = indexedDB.open(name, version)

    this.db = new Promise<IDBDatabase>((resolve, reject) => {
      openRequest.onerror = () => reject(openRequest.error)

      openRequest.onsuccess = () => {
        resolve(openRequest.result)

        this.clean()

        setInterval(() => {
          this.clean()
        }, 5 * 60 * 1000)
      }

      openRequest.onupgradeneeded = event => {
        const db: IDBDatabase = (event.target as IDBOpenDBRequest).result
        if (!db.objectStoreNames.contains(objStoreKey)) {
          db.createObjectStore(objStoreKey, { keyPath: 'id' })
        }
      }
    })

    return await this.db
  }

  async clean() {
    const db = await this.init()
    const transaction = db.transaction(objStoreKey, 'readonly')
    const store = transaction.objectStore(objStoreKey)

    // Get all the keys in the object store
    const request = store.getAllKeys()

    request.onsuccess = (e: any) => {
      const keys = e.target.result as string[]
      keys.forEach(async key => {
        const record = await this.getRecord(key)

        if (getExpired(record)) {
          console.info('Removing expired record', record)
          await this.delete(key)
        }
      })
    }

    request.onerror = function (e: any) {
      console.error('Error getting keys', e.target.error)
    }
  }

  async set<T>(key: any, val: any, opts: { ttl: number }): Promise<T> {
    const db = await this.init()

    return new Promise((resolve, reject) => {
      const transaction = db.transaction(objStoreKey, 'readwrite')
      const store = transaction.objectStore(objStoreKey)
      store.put({
        id: getKey(key),
        value: val,
        ttl: opts?.ttl,
        updatedAt: Date.now(),
      })

      transaction.oncomplete = () => resolve(val)
      transaction.onerror = () => reject(transaction.error)
    })
  }

  async deleteRecord(key: any) {
    const db = await this.init()

    return new Promise((resolve, reject) => {
      const transaction = db.transaction(objStoreKey, 'readwrite')
      const store = transaction.objectStore(objStoreKey)
      store.delete(getKey(key))

      transaction.oncomplete = () => resolve(undefined)
      transaction.onerror = () => reject(transaction.error)
    })
  }
  async delete(key: any) {
    return this.deleteRecord(getKey(key))
  }

  async getRecord(key: string): Promise<Result> {
    const db = await this.init()

    return new Promise((resolve, reject) => {
      const transaction = db.transaction(objStoreKey, 'readonly')
      const store = transaction.objectStore(objStoreKey)
      const request = store.get(key)

      transaction.oncomplete = () => {
        if (!request.result) {
          return resolve({
            id: key,
            value: undefined,
            ttl: undefined,
            updatedAt: 0,
          })
        }

        if (getExpired(request.result)) {
          this.deleteRecord(key)

          return resolve({
            id: key,
            value: undefined,
            ttl: undefined,
            updatedAt: 0,
          })
        }

        resolve(request.result)
      }
      transaction.onerror = () => reject(transaction.error)
    })
  }

  async get<T>(key: any): Promise<T | undefined> {
    return (await this.getRecord(getKey(key))).value
  }
}

function getKey(key: any) {
  return typeof key === 'string' ? key : JSON.stringify(key)
}

function getExpired(result: Result) {
  const age = Date.now() - result.updatedAt
  return result.ttl !== undefined && age > (result.ttl ?? Infinity)
}

export const nozzleCacheClient = new NozzleCacheClient()
