import moment from 'moment'
import * as React from 'react'
import { AxisOptions } from 'react-charts'
import { FaCircle } from 'react-icons/fa'
import { UseQueryResult } from 'react-query'
import { twMerge } from 'tailwind-merge'
import useGetLatest from '../hooks/useGetLatest'
import { TableDisplayMode } from '../utils/tableDisplayMode'

import { rankGroupOptions } from '../options/rankGroupOptions'
import { tableDisplayModeOptions } from '../utils/tableDisplayMode'
import { Updater, getDataValue, last } from '../utils'
import { GroupByQueryResponse, GroupByQuerySeries } from '../utils/Api'
import { getRankGroupColor } from '../utils/DataColors'
import { formatLimitedText, formatPostAggregation } from '../utils/Format'
import {
  MetricId,
  MetricPermutation,
  MetricPostAggregation,
  getMetricChangeFormatter,
  getMetricFormatter,
  getMetricRenderer,
  metricHasRankGroups,
  metricPermutation,
  metricsById,
} from '../utils/Metrics'
import {
  QueryFacetId,
  allQueryFacets,
  queryFacetsById,
} from '../utils/queryFacets'
import AspectRatio from './AspectRatio'
import Chart from './Chart'
import ChartPlaceholder from './ChartPlaceholder'
import Clickable from './Clickable'
import ControlledQueryTable, {
  ControlledQueryTableState,
} from './ControlledQueryTable'
import { Counter } from './Counter'
import ErrorComp from './Error'
import Pager from './Pager'
import SeriesLegend from './SeriesLegend'
import TimeSeriesChart from './TimeSeriesChart'
import Tooltip from './Tooltip'
import { sum } from 'd3-array'

export interface RankGroupsWidgetState extends ControlledQueryTableState {
  displayPostAggregation: MetricPostAggregation
  limit: number
  offset: number
  tableDisplayMode: TableDisplayMode
}

export function RankGroupsWidget<TState extends RankGroupsWidgetState>({
  metricIds,
  setState,
  dataQuery,
  groupBy,
  hidePagination,
  onClickSerie,

  metricId: focusMetricId,
  displayPostAggregation,
  limit,
  tableDisplayMode,
  sortByMetricPermutation,
  desc,
  offset,
}: RankGroupsWidgetState & {
  metricIds: MetricId[]
  setState: (updater: Updater<TState>) => void
  dataQuery: UseQueryResult<GroupByQueryResponse>
  groupBy: QueryFacetId

  hidePagination?: boolean
  onClickSerie?: (series: GroupByQuerySeries) => void
  renderSeriesLabel?: (serie: GroupByQuerySeries) => any
}) {
  const groupByFacet = queryFacetsById[groupBy]

  const isMultiSeries = (dataQuery.data?.series?.length ?? 0) > 1

  const focusMetric = metricsById[focusMetricId]

  return !metricHasRankGroups(focusMetricId) ? (
    <div className="p-4 text-center text-sm">
      Rank group information for the metric <strong>{focusMetric.label}</strong>{' '}
      is not applicable{' '}
      <Tooltip tooltip="Rank Group information by nature cannot be calculated for columns that are already rank/position specific (e.g. Rank, CTR, Pixels From Top)">
        <Clickable>Why?</Clickable>
      </Tooltip>
      . Please select a different metric (e.g.{' '}
      <Clickable
        onClick={() => {
          setState(old => ({
            ...old,
            metricId: 'estimated_traffic',
          }))
        }}
      >
        Estimated Traffic
      </Clickable>{' '}
      ) or display mode (e.g.{' '}
      <Clickable
        onClick={() => {
          setState(old => ({
            ...old,
            displayMode: 'summary',
          }))
        }}
      >
        Summary
      </Clickable>
      )
    </div>
  ) : (
    <>
      {isMultiSeries && groupBy === 'keyword' ? null : (
        <div className="flex flex-wrap gap-4 p-2">
          <div
            className={twMerge(
              'flex-[1_1_600px]',
              dataQuery.isFetching ? 'opacity-50' : ''
            )}
          >
            {isMultiSeries ? (
              <RankGroupBarChart
                {...{
                  dataQuery,
                  groupBy,
                  displayPostAggregation,
                  focusMetricId,
                  limit,
                  onClickSerie,
                }}
              />
            ) : (
              <RankGroupHistoryChart
                {...{
                  dataQuery,
                  groupBy,
                  onClickSerie,
                  focusMetricId,
                  displayPostAggregation,
                  showLegends: true,
                }}
              />
            )}
          </div>
        </div>
      )}
      <div className="divide-x divide-gray-500/20 bg-gray-50 dark:bg-gray-900">
        {tableDisplayModeOptions
          .filter(d => (isMultiSeries ? d.value === 'summary' : true))
          .map(option => {
            return (
              <button
                key={option.value}
                onClick={() => {
                  setState(old => {
                    return {
                      ...old,
                      tableDisplayMode: option.value,
                    }
                  })
                }}
                className={twMerge(
                  `px-2 py-1 text-sm hover:bg-blue-500 hover:text-white`,
                  option.value === tableDisplayMode ||
                    (isMultiSeries && option.value === 'summary')
                    ? 'bg-white font-bold dark:bg-gray-800'
                    : ''
                )}
              >
                {option.label}
              </button>
            )
          })}
      </div>

      {tableDisplayMode === 'summary' ? (
        isMultiSeries ? (
          <SummaryTable
            {...{
              dataQuery,
              metricIds,
              displayPostAggregation,
              sortByMetricPermutation,
              desc,
              setState,
              focusMetricId,
              label: groupByFacet.label,
              groupBy,
              limit,
              onClickSerie,
            }}
          />
        ) : (
          <SingleSummaryTable
            {...{
              dataQuery,
              metricIds,
              displayPostAggregation,
              sortByMetricPermutation,
              desc,
              setState,
              focusMetricId,
              label: groupByFacet.label,
              groupBy,
              limit,
              onClickSerie,
            }}
          />
        )
      ) : (
        <HistoryTable
          {...{
            dataQuery,
            metricIds,
            groupBy,
            displayPostAggregation,
            sortByMetricPermutation,
            desc,
            setState,
            focusMetricId,
            label: groupByFacet.label,
            limit,
            onClickSerie,
          }}
        />
      )}
      {!hidePagination ? (
        <>
          <div className="p-1">
            <Counter
              {...{
                compact: true,
                isLoading: dataQuery.isFetching,
                count: dataQuery.data?.series?.length,
                totalCount: dataQuery.data?.rowCount,
                totalName: groupByFacet.labelPlural,
              }}
            />
          </div>
          <div className="p-1">
            <Pager
              compact
              offset={offset}
              limit={limit}
              size={dataQuery.data?.rowCount ?? 0}
              setOffset={offset =>
                setState(old => ({
                  ...old,
                  offset,
                }))
              }
              setLimit={limit =>
                setState(old => ({
                  ...old,
                  limit,
                }))
              }
            />
          </div>
        </>
      ) : null}
    </>
  )
}

function RankGroupBarChart({
  dataQuery,
  focusMetricId,
  displayPostAggregation,
  limit,
  // groupBy,
  onClickSerie,
}: {
  dataQuery: UseQueryResult<GroupByQueryResponse>
  focusMetricId: MetricId
  displayPostAggregation: MetricPostAggregation
  limit: number
  // groupBy: QueryFacetId
  onClickSerie?: (series: GroupByQuerySeries) => void
  renderSeriesLabel?: (serie: GroupByQuerySeries) => any
}) {
  // const groupByFacet = queryFacetsById[groupBy]
  const focusMetric = metricsById[focusMetricId]

  const barChartHeight = (dataQuery.data?.series?.length || 0) * 20

  // const formatSeriesLabel = React.useCallback(
  //   (series, all) => {
  //     const label = groupByFacet.formatSeriesLabel(series, all)
  //     return label.length > 50 ? `${label.substring(0, 30)}...` : label
  //   },
  //   [groupByFacet]
  // )

  const data = React.useMemo(
    () =>
      dataQuery.data?.series?.length
        ? rankGroupOptions.map((option, i) => ({
            label: option.label,
            color: getRankGroupColor(i),
            data: dataQuery.data?.series
              .map(serie => {
                const secondary = getDataValue(
                  last(serie.data),
                  metricPermutation(focusMetricId, {
                    subAggregation: option.value,
                    postAggregation: displayPostAggregation,
                  })
                )

                return {
                  primary: serie.label,
                  secondary,
                }
              })
              .reverse(),
          }))
        : [],
    [dataQuery.data?.series, focusMetricId, displayPostAggregation]
  )

  const primaryAxis = React.useMemo(
    (): AxisOptions<any> => ({
      scaleType: 'band',
      position: 'left',
      getValue: d => d.primary,
      showGrid: false,
      formatters: {
        scale: d => formatLimitedText(d, 40, { fromPercentage: 0.5 }),
        tooltip: d => d as string,
      },
    }),
    []
  )

  const secondaryAxes = React.useMemo(
    (): AxisOptions<any>[] => [
      {
        scaleType: 'linear',
        getValue: d => d.secondary,
        invert: focusMetric.inverted,
        elementType: 'bar',
        position: 'bottom',
        min: 0,
        stacked: true,
        formatters: {
          scale:
            displayPostAggregation === 'change'
              ? d =>
                  getMetricChangeFormatter(focusMetricId)(d, {
                    string: true,
                  }) as string
              : d =>
                  getMetricFormatter(focusMetricId)(d, {
                    string: true,
                  }) as string,
          tooltip:
            displayPostAggregation === 'change'
              ? d => getMetricChangeFormatter(focusMetricId)(d)
              : d => getMetricRenderer(focusMetricId)(d),
        },
      },
    ],
    [displayPostAggregation, focusMetric.inverted, focusMetricId]
  )

  return (
    <div
      style={{
        height: `${barChartHeight}px`,
        position: 'relative',
      }}
      className="min-h-36"
    >
      {dataQuery.status === 'loading' ? (
        <ChartPlaceholder
          type="bar"
          series={rankGroupOptions.length}
          datums={limit}
          stacked
        />
      ) : dataQuery.status === 'error' ? (
        <ErrorComp error={dataQuery.error} />
      ) : (
        <Chart
          {...{
            options: {
              data,
              primaryAxis,
              secondaryAxes,
              getSeriesStyle: series => ({
                color: getRankGroupColor(series.index),
              }),
              onClickDatum: onClickSerie
                ? datum => {
                    // @ts-expect-error  // Object is possibly 'null'.
                    onClickSerie(datum.originalSeries as any)
                  }
                : undefined,
            },
            style: {
              opacity: dataQuery.isFetching ? 0.5 : 1,
            },
          }}
        />
      )}
    </div>
  )
}

function RankGroupHistoryChart({
  dataQuery,
  groupBy,
  onClickSerie,
  displayPostAggregation,
  focusMetricId,
  showLegends,
}: {
  dataQuery: UseQueryResult<GroupByQueryResponse>
  groupBy: QueryFacetId
  onClickSerie?: (series: GroupByQuerySeries) => void
  renderSeriesLabel?: (serie: GroupByQuerySeries) => any
  focusMetricId: MetricId
  displayPostAggregation: MetricPostAggregation
  showLegends: boolean
}) {
  const data = React.useMemo(
    () => [
      ...(dataQuery.data
        ? rankGroupOptions.map((option, i) => ({
            label: option.label,
            color: getRankGroupColor(i),
            data: dataQuery.data?.series?.[0]?.data.map(d => ({
              ...d,
              rankGroup: option.value,
            })),
          }))
        : []),
    ],
    [dataQuery.data]
  )

  return (
    <>
      <AspectRatio ratio={[5, 1]} className="min-h-48">
        {dataQuery.status === 'loading' ? (
          <ChartPlaceholder
            type="area"
            series={rankGroupOptions.length}
            stacked
          />
        ) : dataQuery.status === 'error' ? (
          <ErrorComp error={dataQuery.error} />
        ) : (
          // @ts-expect-error  // Type '{ metricId: "top_rank" | "top_ranking_result... Remove this comment to see the full error message
          <TimeSeriesChart
            {...{
              metricId: focusMetricId,
              subAggregation: datum => (datum as any).rankGroup,
              displayPostAggregation,
              isLoading: dataQuery.isLoading,
              isError: dataQuery.isError,
              error: dataQuery.error,
              data,
              tooltip: true,
              chartType: 'area',
              onClickDatum: onClickSerie
                ? datum => {
                    // @ts-expect-error  // Object is possibly 'null'.
                    onClickSerie(datum.originalSeries as any)
                  }
                : undefined,
              // getSeriesStyle: series => ({
              //   strokeWidth: 1,
              //   area: {
              //     opacity: 0.3,
              //   },
              // }),
            }}
          />
        )}
      </AspectRatio>
      {showLegends ? (
        <>
          <SeriesLegend
            {...{
              query: dataQuery,
              groupBy,
            }}
          />
        </>
      ) : null}
    </>
  )
}

function SummaryTable<TState extends ControlledQueryTableState>({
  dataQuery,
  metricIds,
  displayPostAggregation,
  sortByMetricPermutation,
  desc,
  setState,
  focusMetricId,
  label,
  groupBy,
  limit,
  onClickSerie,
  renderSeriesLabel,
}: {
  dataQuery: UseQueryResult<any>
  metricIds: MetricId[]
  displayPostAggregation: MetricPostAggregation
  focusMetricId: MetricId
  sortByMetricPermutation: MetricPermutation<MetricId>
  desc: boolean
  setState: (updater: Updater<TState>) => void
  label: string
  groupBy: QueryFacetId
  limit: number
  onClickSerie?: (series: GroupByQuerySeries) => void
  renderSeriesLabel?: (serie: GroupByQuerySeries) => any
}) {
  const focusMetric = metricsById[focusMetricId]
  const focusMetricChangeFormatter = getMetricChangeFormatter(focusMetricId)
  const focusMetricRender = getMetricRenderer(focusMetricId)
  const queryFacet = allQueryFacets.find(d => d.id === groupBy)

  const getOnClickSerie = useGetLatest(onClickSerie)

  const columns = React.useMemo(
    () => [
      {
        id: 'circle',
        enableSorting: false,
        cell: (props: any) => {
          const value = queryFacet?.renderSeriesLabel(
            props.row.original,
            [props.row.original],
            {}
          )

          return (
            <Tooltip
              tooltip={value}
              getTooltipProps={() => ({ className: 'max-w-full' })}
              onClick={() => getOnClickSerie()?.(props.row.original)}
            >
              <FaCircle
                style={{
                  color: props.row.original.color,
                }}
              />
            </Tooltip>
          )
        },

        getHeaderProps: () => ({
          className: `sticky left-0 z-10`,
        }),
        getCellProps: () => ({
          className: `sticky left-0 z-10`,
        }),
      },
      {
        id: 'label',
        header: label,
        enableSorting: false,
        cell: (props: any) => {
          const value = queryFacet?.renderSeriesLabel(
            props.row.original,
            [props.row.original],
            {
              short: true,
            }
          )

          if (renderSeriesLabel) {
            return React.cloneElement(renderSeriesLabel(props.row.original), {
              children: value,
            })
          }

          return value
        },
      },
      {
        id: '1 - 3',
        header: (
          <div className="flex items-center gap-1">
            <div
              className="h-3 w-8 rounded-full"
              style={{
                backgroundImage: `linear-gradient(to right, ${getRankGroupColor(
                  0
                )}, ${getRankGroupColor(2)})`,
              }}
            />
            <span>1 - 3</span>
          </div>
        ),
        cell: (props: any) => {
          const value = sum(
            ['rank_group_1', 'rank_group_2', 'rank_group_3'] as const,
            d => {
              return getDataValue(last(props.row.original.data), {
                id: focusMetricId,
                aggregation: focusMetric.aggregations[0],
                subAggregation: d,
                postAggregation: displayPostAggregation,
              })
            }
          )
          return (
            <div>
              {(displayPostAggregation === 'change'
                ? focusMetricChangeFormatter
                : focusMetricRender)(value)}
            </div>
          )
        },
        enableSorting: false,
        getCellProps: ({ className }: any) => ({
          className: twMerge(`text-right`, className),
        }),
      },
      {
        id: '1 - 10',
        header: (
          <div className="flex items-center gap-1">
            <div
              className="h-3 w-8 rounded-full"
              style={{
                backgroundImage: `linear-gradient(to right, ${getRankGroupColor(
                  0
                )}, ${getRankGroupColor(4)})`,
              }}
            />
            <span>1 - 10</span>
          </div>
        ),
        cell: (props: any) => {
          const value = sum(
            [
              'rank_group_1',
              'rank_group_2',
              'rank_group_3',
              'rank_group_4_6',
              'rank_group_7_10',
            ] as const,
            d => {
              return getDataValue(last(props.row.original.data), {
                id: focusMetricId,
                aggregation: focusMetric.aggregations[0],
                subAggregation: d,
                postAggregation: displayPostAggregation,
              })
            }
          )
          return (
            <div>
              {(displayPostAggregation === 'change'
                ? focusMetricChangeFormatter
                : focusMetricRender)(value)}
            </div>
          )
        },
        enableSorting: false,
        getCellProps: ({ className }: any) => ({
          className: twMerge(`text-right`, className),
        }),
      },
      ...rankGroupOptions.map((rankGroupOption, index) => {
        return {
          id: rankGroupOption.value,
          header: (
            <div className="flex items-center gap-1">
              <FaCircle
                className="inline"
                style={{
                  color: getRankGroupColor(index),
                }}
              />
              <span>{rankGroupOption.label}</span>
            </div>
          ),
          enableSorting: false,

          getCellProps: ({ className }: any) => ({
            className: twMerge(`text-right`, className),
          }),
          cell: (props: any) => (
            <div>
              {(displayPostAggregation === 'change'
                ? focusMetricChangeFormatter
                : focusMetricRender)(
                getDataValue(last(props.row.original.data), {
                  id: focusMetricId,
                  aggregation: focusMetric.aggregations[0],
                  subAggregation: rankGroupOption.value,
                  postAggregation: displayPostAggregation,
                })
              )}
            </div>
          ),
        }
      }),
    ],
    [
      label,
      queryFacet,
      getOnClickSerie,
      renderSeriesLabel,
      displayPostAggregation,
      focusMetricChangeFormatter,
      focusMetricRender,
      focusMetricId,
      focusMetric.aggregations,
    ]
  )

  return (
    <div className="w-full">
      <div className="overflow-auto">
        <ControlledQueryTable
          {...{
            dataQuery,
            sortByMetricPermutation,
            desc,
            setState,
            columns,
            metricIds,
            compact: true,
            limit,
          }}
        />
      </div>
    </div>
  )
}

function SingleSummaryTable<TState extends ControlledQueryTableState>({
  dataQuery: preDataQuery,
  metricIds,
  displayPostAggregation,
  sortByMetricPermutation,
  desc,
  setState,
  focusMetricId,
  limit,
  renderSeriesLabel,
}: {
  dataQuery: UseQueryResult<any>
  metricIds: MetricId[]
  displayPostAggregation: MetricPostAggregation
  focusMetricId: MetricId
  sortByMetricPermutation: MetricPermutation<MetricId>
  desc: boolean
  setState: (updater: Updater<TState>) => void
  limit: number
  renderSeriesLabel?: (serie: GroupByQuerySeries) => any
}) {
  const focusMetric = metricsById[focusMetricId]
  const focusMetricChangeFormatter = getMetricChangeFormatter(focusMetricId)
  const focusMetricRender = getMetricRenderer(focusMetricId)

  const dataQuery = React.useMemo(() => {
    return {
      ...preDataQuery,
      data: preDataQuery.data
        ? {
            ...preDataQuery.data,
            series: preDataQuery.data?.series?.[0]
              ? [
                  preDataQuery.data?.series?.[0],
                  ...rankGroupOptions.map((option, i) => ({
                    ...preDataQuery.data?.series?.[0],
                    label: option.label,
                    color: getRankGroupColor(i),
                    rankGroup: option.value,
                  })),
                ]
              : [],
          }
        : undefined,
    }
  }, [preDataQuery])

  const columns = React.useMemo(
    () => [
      {
        id: 'circle',
        enableSorting: false,
        cell: (props: any) =>
          props.row.original.rankGroup ? (
            <FaCircle
              className="inline"
              style={{
                color: props.row.original.color,
              }}
            />
          ) : null,
      },
      {
        id: 'group',
        header: '',
        enableSorting: false,
        cell: (props: any) => {
          return (
            <div>
              {props.row.original.rankGroup ? (
                <div className="flex items-center gap-1">
                  <span>
                    {
                      rankGroupOptions.find(
                        d => d.value === props.row.original.rankGroup
                      )?.label
                    }
                  </span>
                </div>
              ) : (
                (() => {
                  if (renderSeriesLabel) {
                    return React.cloneElement(
                      renderSeriesLabel(props.row.original),
                      {
                        children: 'Total',
                      }
                    )
                  }

                  return 'Total'
                })()
              )}
            </div>
          )
        },
        getHeaderProps: () => ({
          className: `sticky left-0 z-10`,
        }),
        getCellProps: () => ({
          className: `sticky left-0 z-10`,
        }),
      },
      ...focusMetric.postAggregations?.map(postAggregation => {
        const isActive = postAggregation === displayPostAggregation
        return {
          id: `postAggregation-${postAggregation}`,
          header: formatPostAggregation(postAggregation),
          enableSorting: false,

          getHeaderProps: () => ({
            className: twMerge(isActive && `font-black`),
          }),
          getCellProps: () => ({
            className: twMerge(isActive && `font-bold`),
          }),
          cell: (props: any) => (
            <div>
              {(postAggregation === 'change'
                ? focusMetricChangeFormatter
                : focusMetricRender)(
                getDataValue(last(props.row.original.data), {
                  id: focusMetricId,
                  aggregation: focusMetric.aggregations[0],
                  subAggregation: props.row.original.rankGroup ?? 'total',
                  postAggregation,
                })
              )}
            </div>
          ),
        }
      }),
    ],
    [
      focusMetric.postAggregations,
      focusMetric.aggregations,
      renderSeriesLabel,
      displayPostAggregation,
      focusMetricChangeFormatter,
      focusMetricRender,
      focusMetricId,
    ]
  )

  return (
    <div className="w-full">
      <div className="overflow-auto">
        <ControlledQueryTable
          {...{
            dataQuery,
            sortByMetricPermutation,
            desc,
            setState,
            columns,
            metricIds,
            compact: true,
            limit,
            getRowProps: ({ row }) =>
              !row.index
                ? {
                    className: `border-b-4`,
                  }
                : {},
          }}
        />
      </div>
    </div>
  )
}

function HistoryTable<TState extends ControlledQueryTableState>({
  dataQuery: preDataQuery,
  metricIds,
  displayPostAggregation,
  sortByMetricPermutation,
  desc,
  setState,
  focusMetricId,
  label,
  groupBy,
  limit,
  renderSeriesLabel,
}: {
  dataQuery: UseQueryResult<any>
  metricIds: MetricId[]
  displayPostAggregation: MetricPostAggregation
  focusMetricId: MetricId
  sortByMetricPermutation: MetricPermutation<MetricId>
  desc: boolean
  setState: (updater: Updater<TState>) => void
  label: string
  groupBy: QueryFacetId
  limit: number
  renderSeriesLabel?: (serie: GroupByQuerySeries) => any
}) {
  const focusMetric = metricsById[focusMetricId]
  const queryFacet = allQueryFacets.find(d => d.id === groupBy)
  const isMultiSeries = preDataQuery?.data?.series?.length > 1

  const dataQuery = React.useMemo(() => {
    return {
      ...preDataQuery,
      data: preDataQuery.data
        ? {
            ...preDataQuery.data,
            series: [
              ...preDataQuery.data?.series?.map((serie: any) => ({
                ...serie,

                data: serie.data.map((d: any) => ({
                  ...d,

                  value: getDataValue(d, {
                    id: focusMetricId,
                    aggregation: focusMetric.aggregations[0],
                    subAggregation: 'total',
                    postAggregation: displayPostAggregation,
                  }),
                })),
              })),
              ...(!isMultiSeries
                ? rankGroupOptions.map((option, i) => ({
                    ...preDataQuery.data?.series[0],
                    label: option.label,
                    color: getRankGroupColor(i),
                    rankGroup: option.value,
                    data: preDataQuery.data?.series[0].data.map((d: any) => ({
                      ...d,

                      value: getDataValue(d, {
                        id: focusMetricId,
                        aggregation: focusMetric.aggregations[0],
                        subAggregation: option.value,
                        postAggregation: displayPostAggregation,
                      }),
                    })),
                  }))
                : []),
            ],
          }
        : undefined,
    }
  }, [
    displayPostAggregation,
    focusMetric.aggregations,
    focusMetricId,
    isMultiSeries,
    preDataQuery,
  ])

  const columns = React.useMemo(
    () => [
      {
        id: 'circle',
        enableSorting: false,
        cell: (props: any) =>
          !isMultiSeries ? (
            !props.row.original.rankGroup ? null : (
              <FaCircle
                style={{
                  color: props.row.original.color,
                }}
              />
            )
          ) : null,
      },
      {
        id: 'group',
        header: isMultiSeries ? label : '',
        enableSorting: false,
        cell: (props: any) => {
          const value = queryFacet?.renderSeriesLabel(
            props.row.original,
            [props.row.original],
            {
              short: true,
            }
          )

          return isMultiSeries ? (
            (() => {
              if (renderSeriesLabel) {
                return React.cloneElement(
                  renderSeriesLabel(props.row.original),
                  {
                    children: value,
                  }
                )
              }

              return value
            })()
          ) : (
            <div>
              {props.row.original.rankGroup ? (
                <div className="flex items-center gap-1">
                  <span>
                    {
                      rankGroupOptions.find(
                        d => d.value === props.row.original.rankGroup
                      )?.label
                    }
                  </span>
                </div>
              ) : (
                (() => {
                  if (renderSeriesLabel) {
                    return React.cloneElement(
                      renderSeriesLabel(props.row.original),
                      {
                        children: 'Total',
                      }
                    )
                  }

                  return 'Total'
                })()
              )}
            </div>
          )
        },
        getHeaderProps: () => ({
          className: `sticky left-0 z-10`,
        }),
        getCellProps: () => ({
          className: `sticky left-0 z-10`,
        }),
      },
      // @ts-expect-error  // Parameter 'd' implicitly has an 'any' type.
      ...(dataQuery.data?.series?.[0]?.data.map((d, i, all) => ({
        id: `${moment.utc(d.requested).format('l')}`,
        header: `${moment.utc(d.requested).format('l')}`,
        accessorFn: (d: any) => d.data[i]?.value,
        enableSorting: false,

        cell: (props: any) => (
          <div
            className={twMerge(
              `text-right`,
              i === all.length - 1 && `font-bold`
            )}
          >
            {(displayPostAggregation === 'change'
              ? getMetricChangeFormatter(focusMetricId)
              : getMetricRenderer(focusMetricId))(props.getValue())}
          </div>
        ),
      })) || []),
    ],
    [
      isMultiSeries,
      label,
      dataQuery.data?.series,
      queryFacet,
      renderSeriesLabel,
      displayPostAggregation,
      focusMetricId,
    ]
  )

  return (
    <div className="w-full">
      <div className="overflow-x-auto">
        <ControlledQueryTable
          {...{
            dataQuery,
            sortByMetricPermutation,
            desc,
            setState,
            columns,
            metricIds,
            compact: true,
            limit,
            getRowProps: !isMultiSeries
              ? row =>
                  !row.index
                    ? {
                        className: `border-b-4`,
                      }
                    : {}
              : undefined,
          }}
        />
      </div>
    </div>
  )
}
