import * as React from 'react'
import { useForm } from 'react-form'
import { FaInfoCircle, FaPlus, FaTimesCircle } from 'react-icons/fa'
import Button from '../components/Button'
import Caption from '../components/Caption'
import SelectField from '../components/SelectField'
import TextAreaField from '../components/TextAreaField'
import useModalContext from '../hooks/useModalContext'
import { dedupeKeywords } from '../utils/Phrases'
import Validate from '../utils/Validate'
import DevicesField from './DevicesField'
import { LocalesField } from './LocalesField'
import { Modal, ModalContent, ModalTitle } from './Modals'

//

const defaultAddFormValues = {
  phrases: '',
  groups: [],
}

export default function JsonPhraseGroupsModal({
  keywords,
  onKeywordsChange,
}: any) {
  const { close } = useModalContext()
  const {
    Form: AddKeywordForm,
    // @ts-expect-error  // Property 'handleSubmit' does not exist on type '{ ... Remove this comment to see the full error message
    handleSubmit,
    meta: { canSubmit },
  } = useForm({
    defaultValues: defaultAddFormValues,
    // @ts-expect-error  // Type '({ phrases, groups: globalGroups, devices, l... Remove this comment to see the full error message
    onSubmit: ({ phrases, groups: globalGroups, devices, localeIds }) => {
      onKeywordsChange(
        dedupeKeywords([
          ...keywords,
          ...phrases
            .split('\n')
            .map(d => d.trim())
            .filter(Boolean)
            .map(line => {
              const [phrase, ...groups] = line.split(';').map(d => d.trim())
              return {
                phrase,
                groups: [...groups, ...globalGroups],
                devices,
                localeIds,
              }
            }),
        ]).keywords
      )
      close()
    },
  })

  const info = React.useMemo(() => dedupeKeywords(keywords || []), [keywords])

  const groupOptions = React.useMemo(
    () =>
      Object.keys(info.groups).map(d => ({
        value: d,
        label: d,
      })),
    [info.groups]
  )

  return (
    <Modal>
      <ModalTitle>Add Keywords</ModalTitle>
      <ModalContent>
        <div className="p-8">
          <AddKeywordForm className="space-y-2">
            <TextAreaField
              label="Keyword Phrases"
              field="phrases"
              placeholder={[
                'Keyword phrase 1',
                'Keyword phrase 2',
                'Keyword phrase 3',
              ].join(`\n`)}
              rows={6}
              // @ts-expect-error  // Argument of type '{ required: boolean; }' is not a... Remove this comment to see the full error message
              validate={Validate.keywordSourcePhrases({ required: true })}
            />
            <SelectField
              field="groups"
              label="Keyword Groups"
              options={groupOptions}
              multi
              create
              placeholder="Tag these keywords to groups..."
            />
            <DevicesField field="devices" required />
            <LocalesField field="localeIds" required />
            <div className="h-4" />

            <Button
              size="base"
              color="green-500"
              onClick={() => handleSubmit()}
              disabled={!canSubmit}
              className="mr-1"
            >
              <FaPlus className="inline" /> Add Keywords
            </Button>

            <Button
              size="base"
              color="gray-500"
              hoverColor="red-500"
              onClick={close}
            >
              <FaTimesCircle className="inline" /> Cancel
            </Button>
          </AddKeywordForm>

          <div className="h-2" />
          <Caption>
            <FaInfoCircle className="inline" /> The form above can be used to
            add new keyword phrases to this keyword source.
          </Caption>
          <div className="h-4" />
        </div>
      </ModalContent>
    </Modal>
  )
}
