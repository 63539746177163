import { twMerge } from 'tailwind-merge'
import { useSearch } from '@tanstack/react-router'
import * as React from 'react'
import Link from '../../components/Link'

import { useActiveWorkspaceId } from '../../hooks/workspaces'
import { Tab, Tabs } from '../../components/Tabs'
import { topicsTabOptions, useTopicsTab } from './useTopicsTab'
import { TopicsTable } from './TopicsTable'
import { Head } from '../../components/Head'
import Card from '../../components/Card'
import { ProjectPicker } from '../../components/ProjectPicker'
import RollupPicker from '../../components/RollupPicker'
import { FaAngleRight } from 'react-icons/fa'
import { TopicsFilters } from './TopicsFilters'

export type TopicTab = undefined | 'tree' | 'flat'

export const topicsLabel = <div className="text-xl font-bold">Topics</div>
export const backToTopics = <Link to="/topics">{topicsLabel}</Link>

export function Topics() {
  const workspaceId = useActiveWorkspaceId()!
  const search = useSearch()
  const projectId = search.projectId!
  const topicsTab = useTopicsTab()

  return (
    <div className="flex min-h-0 flex-1 flex-col space-y-2 p-2">
      <Head>
        <title>Topics</title>
      </Head>
      <Card className="flex flex-wrap items-center gap-2">
        <ProjectPicker />
        <RollupPicker allowDomainsAndUrls={false} />
        <FaAngleRight />
        {topicsLabel}
      </Card>
      <div className="flex min-h-0 flex-1 gap-2 overflow-hidden">
        <TopicsFilters workspaceId={workspaceId} projectId={projectId} />
        <div className="flex min-h-0 w-full flex-1 flex-col gap-2 overflow-hidden">
          <Tabs>
            {topicsTabOptions.map(tab => {
              return (
                <topicsTab.Link key={tab.id} value={tab.id}>
                  <Tab
                    className={twMerge(topicsTab.state === tab.id && 'active')}
                  >
                    {tab.label}
                  </Tab>
                </topicsTab.Link>
              )
            })}
            {/* <Tab>Overview</Tab> */}
          </Tabs>
          {topicsTab.state === 'manage' ? (
            <TopicsTable workspaceId={workspaceId} projectId={projectId} />
          ) : null}
          {/* <ForceTree
            parentMonitoredUrl={monitoredUrlQuery.data?.monitoredUrl}
            tableRows={tableRows}
          /> */}
        </div>
      </div>
    </div>
  )
}
