import * as React from 'react'
import { splitFormProps, useField } from 'react-form'
import Validate from '../utils/Validate'
import LabelWrap from './LabelWrap'
import { LocalesInput } from './LocalesInput'

//

const ourDefaultValue: number[] = []

export function LocalesField(props: any) {
  const [
    field = 'localeIds',
    {
      defaultValue = ourDefaultValue,
      validate = Validate.required('At least one locale is required.'),
      ...fieldOptions
    },
    nonFieldRest,
  ] = splitFormProps(props)

  const {
    value,
    setValue,
    meta: { error, isTouched, isValidating },
    setMeta,
  } = useField(field, {
    ...fieldOptions,
    validate,
    defaultValue,
  })

  return (
    <LabelWrap label={props.label} error={isTouched && error}>
      <LocalesInput
        {...{
          value,
          onChange: setValue,
          error,
          isTouched,
          isValidating,
          setTouched: () => setMeta({ isTouched: true }),
          ...nonFieldRest,
        }}
      />
    </LabelWrap>
  )
}
