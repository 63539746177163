// @generated by protoc-gen-es v1.3.0 with parameter "target=ts"
// @generated from file nozzle/billing/v1/account.proto (package nozzle.billing.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { CreateSubscriptionRequest, MeteredSubscription, Subscription, SubscriptionState } from "./subscription_pb.js";
import { Money } from "../../../google/type/money_pb.js";
import { Date } from "../../../google/type/date_pb.js";
import { PaymentMethod } from "./payment_pb.js";
import { PhoneNumber } from "../../../google/type/phone_number_pb.js";
import { PostalAddress } from "../../../google/type/postal_address_pb.js";
import { ProductFamily, UsageType } from "./usage_pb.js";

/**
 * AccountView determines which fields of the Account are returned in the response.
 *
 * @generated from enum nozzle.billing.v1.AccountView
 */
export enum AccountView {
  /**
   * Will default to ACCOUNT_VIEW_BASIC.
   *
   * @generated from enum value: ACCOUNT_VIEW_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * This only returns the current state of the subscription, including the current plan, trial
   * status, and current term. Fields returned include:
   * - workspace_id
   * - version_id
   * - state
   * - current_term_start
   * - current_term_end
   * - trial_start
   * - trial_end
   * - created_at
   * - updated_at
   *
   * @generated from enum value: ACCOUNT_VIEW_BASIC = 1;
   */
  BASIC = 1,

  /**
   * This will return the full subscription with all data. This is a heavy request, relying
   * on multiple external api calls, so don't use this unless you need it.
   *
   * @generated from enum value: ACCOUNT_VIEW_FULL = 2;
   */
  FULL = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(AccountView)
proto3.util.setEnumType(AccountView, "nozzle.billing.v1.AccountView", [
  { no: 0, name: "ACCOUNT_VIEW_UNSPECIFIED" },
  { no: 1, name: "ACCOUNT_VIEW_BASIC" },
  { no: 2, name: "ACCOUNT_VIEW_FULL" },
]);

/**
 * AddressValidationState determines if the address is valid.
 *
 * @generated from enum nozzle.billing.v1.AddressValidationState
 */
export enum AddressValidationState {
  /**
   * @generated from enum value: ADDRESS_VALIDATION_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * Address is not yet validated.
   *
   * @generated from enum value: ADDRESS_VALIDATION_STATE_NOT_VALIDATED = 1;
   */
  NOT_VALIDATED = 1,

  /**
   * Address was validated successfully.
   *
   * @generated from enum value: ADDRESS_VALIDATION_STATE_VALID = 2;
   */
  VALID = 2,

  /**
   * The address is valid for taxability but has not been validated for shipping.
   *
   * @generated from enum value: ADDRESS_VALIDATION_STATE_PARTIALLY_VALID = 3;
   */
  PARTIALLY_VALID = 3,

  /**
   * Address is invalid.
   *
   * @generated from enum value: ADDRESS_VALIDATION_STATE_INVALID = 4;
   */
  INVALID = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(AddressValidationState)
proto3.util.setEnumType(AddressValidationState, "nozzle.billing.v1.AddressValidationState", [
  { no: 0, name: "ADDRESS_VALIDATION_STATE_UNSPECIFIED" },
  { no: 1, name: "ADDRESS_VALIDATION_STATE_NOT_VALIDATED" },
  { no: 2, name: "ADDRESS_VALIDATION_STATE_VALID" },
  { no: 3, name: "ADDRESS_VALIDATION_STATE_PARTIALLY_VALID" },
  { no: 4, name: "ADDRESS_VALIDATION_STATE_INVALID" },
]);

/**
 * CreateAccountRequest is the request for CreateAccount.
 *
 * @generated from message nozzle.billing.v1.CreateAccountRequest
 */
export class CreateAccountRequest extends Message<CreateAccountRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * if no Subscription is provided, it will default to Basic.
   *
   * @generated from field: repeated nozzle.billing.v1.CreateSubscriptionRequest create_subscription_requests = 2;
   */
  createSubscriptionRequests: CreateSubscriptionRequest[] = [];

  constructor(data?: PartialMessage<CreateAccountRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.CreateAccountRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "create_subscription_requests", kind: "message", T: CreateSubscriptionRequest, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateAccountRequest {
    return new CreateAccountRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateAccountRequest {
    return new CreateAccountRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateAccountRequest {
    return new CreateAccountRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateAccountRequest | PlainMessage<CreateAccountRequest> | undefined, b: CreateAccountRequest | PlainMessage<CreateAccountRequest> | undefined): boolean {
    return proto3.util.equals(CreateAccountRequest, a, b);
  }
}

/**
 * CreateAccountResponse is the response for CreateAccount.
 *
 * @generated from message nozzle.billing.v1.CreateAccountResponse
 */
export class CreateAccountResponse extends Message<CreateAccountResponse> {
  /**
   * @generated from field: nozzle.billing.v1.Account account = 1;
   */
  account?: Account;

  constructor(data?: PartialMessage<CreateAccountResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.CreateAccountResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "account", kind: "message", T: Account },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateAccountResponse {
    return new CreateAccountResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateAccountResponse {
    return new CreateAccountResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateAccountResponse {
    return new CreateAccountResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateAccountResponse | PlainMessage<CreateAccountResponse> | undefined, b: CreateAccountResponse | PlainMessage<CreateAccountResponse> | undefined): boolean {
    return proto3.util.equals(CreateAccountResponse, a, b);
  }
}

/**
 * GetAccountRequest is the request for GetAccount.
 *
 * @generated from message nozzle.billing.v1.GetAccountRequest
 */
export class GetAccountRequest extends Message<GetAccountRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: nozzle.billing.v1.AccountView view = 2;
   */
  view = AccountView.UNSPECIFIED;

  constructor(data?: PartialMessage<GetAccountRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.GetAccountRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "view", kind: "enum", T: proto3.getEnumType(AccountView) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAccountRequest {
    return new GetAccountRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAccountRequest {
    return new GetAccountRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAccountRequest {
    return new GetAccountRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAccountRequest | PlainMessage<GetAccountRequest> | undefined, b: GetAccountRequest | PlainMessage<GetAccountRequest> | undefined): boolean {
    return proto3.util.equals(GetAccountRequest, a, b);
  }
}

/**
 * GetAccountResponse is the response for GetAccount.
 *
 * @generated from message nozzle.billing.v1.GetAccountResponse
 */
export class GetAccountResponse extends Message<GetAccountResponse> {
  /**
   * @generated from field: nozzle.billing.v1.Account account = 1;
   */
  account?: Account;

  constructor(data?: PartialMessage<GetAccountResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.GetAccountResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "account", kind: "message", T: Account },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAccountResponse {
    return new GetAccountResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAccountResponse {
    return new GetAccountResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAccountResponse {
    return new GetAccountResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAccountResponse | PlainMessage<GetAccountResponse> | undefined, b: GetAccountResponse | PlainMessage<GetAccountResponse> | undefined): boolean {
    return proto3.util.equals(GetAccountResponse, a, b);
  }
}

/**
 * SyncAccountRequest is the request for SyncAccount.
 *
 * @generated from message nozzle.billing.v1.SyncAccountRequest
 */
export class SyncAccountRequest extends Message<SyncAccountRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  constructor(data?: PartialMessage<SyncAccountRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.SyncAccountRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SyncAccountRequest {
    return new SyncAccountRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SyncAccountRequest {
    return new SyncAccountRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SyncAccountRequest {
    return new SyncAccountRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SyncAccountRequest | PlainMessage<SyncAccountRequest> | undefined, b: SyncAccountRequest | PlainMessage<SyncAccountRequest> | undefined): boolean {
    return proto3.util.equals(SyncAccountRequest, a, b);
  }
}

/**
 * SyncAccountResponse is the response for SyncAccount.
 *
 * @generated from message nozzle.billing.v1.SyncAccountResponse
 */
export class SyncAccountResponse extends Message<SyncAccountResponse> {
  constructor(data?: PartialMessage<SyncAccountResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.SyncAccountResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SyncAccountResponse {
    return new SyncAccountResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SyncAccountResponse {
    return new SyncAccountResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SyncAccountResponse {
    return new SyncAccountResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SyncAccountResponse | PlainMessage<SyncAccountResponse> | undefined, b: SyncAccountResponse | PlainMessage<SyncAccountResponse> | undefined): boolean {
    return proto3.util.equals(SyncAccountResponse, a, b);
  }
}

/**
 * UpdateBillingDetailsRequest is the request for UpdateBillingDetails.
 *
 * @generated from message nozzle.billing.v1.UpdateBillingDetailsRequest
 */
export class UpdateBillingDetailsRequest extends Message<UpdateBillingDetailsRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: nozzle.billing.v1.BillingDetails billing_details = 2;
   */
  billingDetails?: BillingDetails;

  constructor(data?: PartialMessage<UpdateBillingDetailsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.UpdateBillingDetailsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "billing_details", kind: "message", T: BillingDetails },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateBillingDetailsRequest {
    return new UpdateBillingDetailsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateBillingDetailsRequest {
    return new UpdateBillingDetailsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateBillingDetailsRequest {
    return new UpdateBillingDetailsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateBillingDetailsRequest | PlainMessage<UpdateBillingDetailsRequest> | undefined, b: UpdateBillingDetailsRequest | PlainMessage<UpdateBillingDetailsRequest> | undefined): boolean {
    return proto3.util.equals(UpdateBillingDetailsRequest, a, b);
  }
}

/**
 * UpdateBillingDetailsResponse is the response for UpdateBillingDetails.
 *
 * @generated from message nozzle.billing.v1.UpdateBillingDetailsResponse
 */
export class UpdateBillingDetailsResponse extends Message<UpdateBillingDetailsResponse> {
  /**
   * @generated from field: nozzle.billing.v1.BillingDetails billing_details = 2;
   */
  billingDetails?: BillingDetails;

  constructor(data?: PartialMessage<UpdateBillingDetailsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.UpdateBillingDetailsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "billing_details", kind: "message", T: BillingDetails },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateBillingDetailsResponse {
    return new UpdateBillingDetailsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateBillingDetailsResponse {
    return new UpdateBillingDetailsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateBillingDetailsResponse {
    return new UpdateBillingDetailsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateBillingDetailsResponse | PlainMessage<UpdateBillingDetailsResponse> | undefined, b: UpdateBillingDetailsResponse | PlainMessage<UpdateBillingDetailsResponse> | undefined): boolean {
    return proto3.util.equals(UpdateBillingDetailsResponse, a, b);
  }
}

/**
 * ListTaxIdTypesRequest is the request for ListTaxIdTypes.
 *
 * @generated from message nozzle.billing.v1.ListTaxIdTypesRequest
 */
export class ListTaxIdTypesRequest extends Message<ListTaxIdTypesRequest> {
  constructor(data?: PartialMessage<ListTaxIdTypesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.ListTaxIdTypesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListTaxIdTypesRequest {
    return new ListTaxIdTypesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListTaxIdTypesRequest {
    return new ListTaxIdTypesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListTaxIdTypesRequest {
    return new ListTaxIdTypesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListTaxIdTypesRequest | PlainMessage<ListTaxIdTypesRequest> | undefined, b: ListTaxIdTypesRequest | PlainMessage<ListTaxIdTypesRequest> | undefined): boolean {
    return proto3.util.equals(ListTaxIdTypesRequest, a, b);
  }
}

/**
 * ListTaxIdTypesResponse is the response for ListTaxIdTypes.
 *
 * @generated from message nozzle.billing.v1.ListTaxIdTypesResponse
 */
export class ListTaxIdTypesResponse extends Message<ListTaxIdTypesResponse> {
  /**
   * @generated from field: repeated nozzle.billing.v1.TaxIdType tax_id_types = 1;
   */
  taxIdTypes: TaxIdType[] = [];

  constructor(data?: PartialMessage<ListTaxIdTypesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.ListTaxIdTypesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tax_id_types", kind: "message", T: TaxIdType, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListTaxIdTypesResponse {
    return new ListTaxIdTypesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListTaxIdTypesResponse {
    return new ListTaxIdTypesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListTaxIdTypesResponse {
    return new ListTaxIdTypesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListTaxIdTypesResponse | PlainMessage<ListTaxIdTypesResponse> | undefined, b: ListTaxIdTypesResponse | PlainMessage<ListTaxIdTypesResponse> | undefined): boolean {
    return proto3.util.equals(ListTaxIdTypesResponse, a, b);
  }
}

/**
 * Account represents the billing subscription for a workspace. There is a 1:1 relationship
 * with a workspace.
 *
 * @generated from message nozzle.billing.v1.Account
 */
export class Account extends Message<Account> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: int64 version_id = 2;
   */
  versionId = protoInt64.zero;

  /**
   * This defines the maximum overage spend that can be incurred before no further usage
   * is allowed. This amount goes across all
   *
   * @generated from field: google.type.Money max_overage_amount = 23;
   */
  maxOverageAmount?: Money;

  /**
   * @generated from field: google.type.Money current_overage_amount = 24;
   */
  currentOverageAmount?: Money;

  /**
   * There is up to one active subscription per product family, and thus the product family
   * serves as the primary key for the subscription.
   *
   * @generated from field: repeated nozzle.billing.v1.Subscription subscriptions = 4;
   */
  subscriptions: Subscription[] = [];

  /**
   * The day of the month that billing runs. This starts out as the day that
   * the workspace was created. If it converts into a paying workspace, the day
   * is reset to the day the subscription begins. This can be changed in the future,
   * but we will add a separate RPC to change the day.
   *
   * @generated from field: int32 billing_day_of_month = 6;
   */
  billingDayOfMonth = 0;

  /**
   * @generated from field: google.type.Date current_term_start = 7;
   */
  currentTermStart?: Date;

  /**
   * @generated from field: google.type.Date current_term_end = 8;
   */
  currentTermEnd?: Date;

  /**
   * @generated from field: google.type.Date current_overage_term_start = 25;
   */
  currentOverageTermStart?: Date;

  /**
   * @generated from field: google.type.Date current_overage_term_end = 26;
   */
  currentOverageTermEnd?: Date;

  /**
   * @generated from field: google.type.Date next_billing_at = 9;
   */
  nextBillingAt?: Date;

  /**
   * @generated from field: nozzle.billing.v1.PaymentMethod payment_method = 16;
   */
  paymentMethod?: PaymentMethod;

  /**
   * @generated from field: nozzle.billing.v1.BillingDetails billing_details = 17;
   */
  billingDetails?: BillingDetails;

  /**
   * a list of all the billing contacts attached to the subscription.
   *
   * @generated from field: repeated nozzle.billing.v1.Contact contacts = 18;
   */
  contacts: Contact[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 21;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 22;
   */
  updatedAt?: Timestamp;

  constructor(data?: PartialMessage<Account>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.Account";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "version_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 23, name: "max_overage_amount", kind: "message", T: Money },
    { no: 24, name: "current_overage_amount", kind: "message", T: Money },
    { no: 4, name: "subscriptions", kind: "message", T: Subscription, repeated: true },
    { no: 6, name: "billing_day_of_month", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "current_term_start", kind: "message", T: Date },
    { no: 8, name: "current_term_end", kind: "message", T: Date },
    { no: 25, name: "current_overage_term_start", kind: "message", T: Date },
    { no: 26, name: "current_overage_term_end", kind: "message", T: Date },
    { no: 9, name: "next_billing_at", kind: "message", T: Date },
    { no: 16, name: "payment_method", kind: "message", T: PaymentMethod },
    { no: 17, name: "billing_details", kind: "message", T: BillingDetails },
    { no: 18, name: "contacts", kind: "message", T: Contact, repeated: true },
    { no: 21, name: "created_at", kind: "message", T: Timestamp },
    { no: 22, name: "updated_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Account {
    return new Account().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Account {
    return new Account().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Account {
    return new Account().fromJsonString(jsonString, options);
  }

  static equals(a: Account | PlainMessage<Account> | undefined, b: Account | PlainMessage<Account> | undefined): boolean {
    return proto3.util.equals(Account, a, b);
  }
}

/**
 * BillingDetails contains the billing information for a subscription.
 *
 * @generated from message nozzle.billing.v1.BillingDetails
 */
export class BillingDetails extends Message<BillingDetails> {
  /**
   * @generated from field: string company = 2;
   */
  company = "";

  /**
   * @generated from field: string given_name = 3;
   */
  givenName = "";

  /**
   * @generated from field: string family_name = 4;
   */
  familyName = "";

  /**
   * @generated from field: string email = 5;
   */
  email = "";

  /**
   * @generated from field: google.type.PhoneNumber phone = 6;
   */
  phone?: PhoneNumber;

  /**
   * @generated from field: google.type.PostalAddress address = 7;
   */
  address?: PostalAddress;

  /**
   * @generated from field: nozzle.billing.v1.AddressValidationState address_validation_state = 8;
   */
  addressValidationState = AddressValidationState.UNSPECIFIED;

  /**
   * oneof tax_id_type_id AND tax_id OR no_tax_id must be set
   *
   * must match a valid id returned from ListTaxIdTypes
   *
   * @generated from field: int32 tax_id_type_id = 9;
   */
  taxIdTypeId = 0;

  /**
   * @generated from field: string tax_id = 10;
   */
  taxId = "";

  /**
   * this means the user has no tax id or has opted out of providing it
   *
   * @generated from field: bool no_tax_id = 11;
   */
  noTaxId = false;

  constructor(data?: PartialMessage<BillingDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.BillingDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "company", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "given_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "family_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "phone", kind: "message", T: PhoneNumber },
    { no: 7, name: "address", kind: "message", T: PostalAddress },
    { no: 8, name: "address_validation_state", kind: "enum", T: proto3.getEnumType(AddressValidationState) },
    { no: 9, name: "tax_id_type_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "tax_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "no_tax_id", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BillingDetails {
    return new BillingDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BillingDetails {
    return new BillingDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BillingDetails {
    return new BillingDetails().fromJsonString(jsonString, options);
  }

  static equals(a: BillingDetails | PlainMessage<BillingDetails> | undefined, b: BillingDetails | PlainMessage<BillingDetails> | undefined): boolean {
    return proto3.util.equals(BillingDetails, a, b);
  }
}

/**
 * CreateContactRequest is the request for CreateContact.
 *
 * @generated from message nozzle.billing.v1.CreateContactRequest
 */
export class CreateContactRequest extends Message<CreateContactRequest> {
  /**
   * @generated from field: nozzle.billing.v1.Contact contact = 1;
   */
  contact?: Contact;

  constructor(data?: PartialMessage<CreateContactRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.CreateContactRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "contact", kind: "message", T: Contact },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateContactRequest {
    return new CreateContactRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateContactRequest {
    return new CreateContactRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateContactRequest {
    return new CreateContactRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateContactRequest | PlainMessage<CreateContactRequest> | undefined, b: CreateContactRequest | PlainMessage<CreateContactRequest> | undefined): boolean {
    return proto3.util.equals(CreateContactRequest, a, b);
  }
}

/**
 * CreateContactResponse is the response for CreateContact.
 *
 * @generated from message nozzle.billing.v1.CreateContactResponse
 */
export class CreateContactResponse extends Message<CreateContactResponse> {
  /**
   * @generated from field: nozzle.billing.v1.Contact contact = 1;
   */
  contact?: Contact;

  constructor(data?: PartialMessage<CreateContactResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.CreateContactResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "contact", kind: "message", T: Contact },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateContactResponse {
    return new CreateContactResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateContactResponse {
    return new CreateContactResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateContactResponse {
    return new CreateContactResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateContactResponse | PlainMessage<CreateContactResponse> | undefined, b: CreateContactResponse | PlainMessage<CreateContactResponse> | undefined): boolean {
    return proto3.util.equals(CreateContactResponse, a, b);
  }
}

/**
 * UpdateContactRequest is the request for UpdateContact.
 *
 * @generated from message nozzle.billing.v1.UpdateContactRequest
 */
export class UpdateContactRequest extends Message<UpdateContactRequest> {
  /**
   * @generated from field: nozzle.billing.v1.Contact contact = 1;
   */
  contact?: Contact;

  /**
   * @generated from field: bool allow_missing = 2;
   */
  allowMissing = false;

  constructor(data?: PartialMessage<UpdateContactRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.UpdateContactRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "contact", kind: "message", T: Contact },
    { no: 2, name: "allow_missing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateContactRequest {
    return new UpdateContactRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateContactRequest {
    return new UpdateContactRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateContactRequest {
    return new UpdateContactRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateContactRequest | PlainMessage<UpdateContactRequest> | undefined, b: UpdateContactRequest | PlainMessage<UpdateContactRequest> | undefined): boolean {
    return proto3.util.equals(UpdateContactRequest, a, b);
  }
}

/**
 * UpdateContactResponse is the response for UpdateContact.
 *
 * @generated from message nozzle.billing.v1.UpdateContactResponse
 */
export class UpdateContactResponse extends Message<UpdateContactResponse> {
  /**
   * @generated from field: nozzle.billing.v1.Contact contact = 1;
   */
  contact?: Contact;

  constructor(data?: PartialMessage<UpdateContactResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.UpdateContactResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "contact", kind: "message", T: Contact },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateContactResponse {
    return new UpdateContactResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateContactResponse {
    return new UpdateContactResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateContactResponse {
    return new UpdateContactResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateContactResponse | PlainMessage<UpdateContactResponse> | undefined, b: UpdateContactResponse | PlainMessage<UpdateContactResponse> | undefined): boolean {
    return proto3.util.equals(UpdateContactResponse, a, b);
  }
}

/**
 * DeleteContactRequest is the request for DeleteContact.
 *
 * @generated from message nozzle.billing.v1.DeleteContactRequest
 */
export class DeleteContactRequest extends Message<DeleteContactRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: int64 contact_id = 2;
   */
  contactId = protoInt64.zero;

  /**
   * @generated from field: bool allow_missing = 3;
   */
  allowMissing = false;

  constructor(data?: PartialMessage<DeleteContactRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.DeleteContactRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "contact_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "allow_missing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteContactRequest {
    return new DeleteContactRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteContactRequest {
    return new DeleteContactRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteContactRequest {
    return new DeleteContactRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteContactRequest | PlainMessage<DeleteContactRequest> | undefined, b: DeleteContactRequest | PlainMessage<DeleteContactRequest> | undefined): boolean {
    return proto3.util.equals(DeleteContactRequest, a, b);
  }
}

/**
 * DeleteContactResponse is the response for DeleteContact.
 *
 * @generated from message nozzle.billing.v1.DeleteContactResponse
 */
export class DeleteContactResponse extends Message<DeleteContactResponse> {
  constructor(data?: PartialMessage<DeleteContactResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.DeleteContactResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteContactResponse {
    return new DeleteContactResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteContactResponse {
    return new DeleteContactResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteContactResponse {
    return new DeleteContactResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteContactResponse | PlainMessage<DeleteContactResponse> | undefined, b: DeleteContactResponse | PlainMessage<DeleteContactResponse> | undefined): boolean {
    return proto3.util.equals(DeleteContactResponse, a, b);
  }
}

/**
 * Contact represents a single invoice type.
 *
 * @generated from message nozzle.billing.v1.Contact
 */
export class Contact extends Message<Contact> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: int64 contact_id = 2;
   */
  contactId = protoInt64.zero;

  /**
   * @generated from field: string given_name = 3;
   */
  givenName = "";

  /**
   * @generated from field: string family_name = 4;
   */
  familyName = "";

  /**
   * @generated from field: string email = 5;
   */
  email = "";

  /**
   * @generated from field: google.type.PhoneNumber phone = 6;
   */
  phone?: PhoneNumber;

  constructor(data?: PartialMessage<Contact>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.Contact";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "contact_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "given_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "family_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "phone", kind: "message", T: PhoneNumber },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Contact {
    return new Contact().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Contact {
    return new Contact().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Contact {
    return new Contact().fromJsonString(jsonString, options);
  }

  static equals(a: Contact | PlainMessage<Contact> | undefined, b: Contact | PlainMessage<Contact> | undefined): boolean {
    return proto3.util.equals(Contact, a, b);
  }
}

/**
 * TaxIdType represents the tax requirements for a specific location.
 *
 * @generated from message nozzle.billing.v1.TaxIdType
 */
export class TaxIdType extends Message<TaxIdType> {
  /**
   * @generated from field: int32 id = 1;
   */
  id = 0;

  /**
   * @generated from field: string type_id = 2;
   */
  typeId = "";

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * @generated from field: string country_code = 4;
   */
  countryCode = "";

  /**
   * @generated from field: string example = 5;
   */
  example = "";

  constructor(data?: PartialMessage<TaxIdType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.TaxIdType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "type_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "country_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "example", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TaxIdType {
    return new TaxIdType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TaxIdType {
    return new TaxIdType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TaxIdType {
    return new TaxIdType().fromJsonString(jsonString, options);
  }

  static equals(a: TaxIdType | PlainMessage<TaxIdType> | undefined, b: TaxIdType | PlainMessage<TaxIdType> | undefined): boolean {
    return proto3.util.equals(TaxIdType, a, b);
  }
}

/**
 * CanIUseRequest is the request for CanIUse.
 *
 * @generated from message nozzle.billing.v1.CanIUseRequest
 */
export class CanIUseRequest extends Message<CanIUseRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: int64 project_id = 2;
   */
  projectId = protoInt64.zero;

  /**
   * @generated from field: nozzle.billing.v1.ProductFamily product_family = 3;
   */
  productFamily = ProductFamily.UNSPECIFIED;

  /**
   * @generated from field: nozzle.billing.v1.UsageType usage_type = 4;
   */
  usageType = UsageType.UNSPECIFIED;

  constructor(data?: PartialMessage<CanIUseRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.CanIUseRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "project_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "product_family", kind: "enum", T: proto3.getEnumType(ProductFamily) },
    { no: 4, name: "usage_type", kind: "enum", T: proto3.getEnumType(UsageType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CanIUseRequest {
    return new CanIUseRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CanIUseRequest {
    return new CanIUseRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CanIUseRequest {
    return new CanIUseRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CanIUseRequest | PlainMessage<CanIUseRequest> | undefined, b: CanIUseRequest | PlainMessage<CanIUseRequest> | undefined): boolean {
    return proto3.util.equals(CanIUseRequest, a, b);
  }
}

/**
 * CanIUseResponse is the response for CanIUse.
 *
 * @generated from message nozzle.billing.v1.CanIUseResponse
 */
export class CanIUseResponse extends Message<CanIUseResponse> {
  /**
   * @generated from field: bool can_i_use = 1;
   */
  canIUse = false;

  /**
   * @generated from field: string reason = 2;
   */
  reason = "";

  /**
   * @generated from field: bool usage_exceeded = 3;
   */
  usageExceeded = false;

  /**
   * @generated from field: bool is_appsumo = 4;
   */
  isAppsumo = false;

  /**
   * Business Pro (Display Name)
   *
   * @generated from field: string subscription_name = 5;
   */
  subscriptionName = "";

  /**
   * @generated from field: google.type.Money base_price = 6;
   */
  basePrice?: Money;

  /**
   * @generated from field: nozzle.billing.v1.MeteredSubscription metered_subscription = 7;
   */
  meteredSubscription?: MeteredSubscription;

  /**
   * @generated from field: google.protobuf.Timestamp canceled_at = 8;
   */
  canceledAt?: Timestamp;

  /**
   * @generated from field: nozzle.billing.v1.SubscriptionState state = 9;
   */
  state = SubscriptionState.UNSPECIFIED;

  /**
   * @generated from field: string slack_channel = 10;
   */
  slackChannel = "";

  constructor(data?: PartialMessage<CanIUseResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.CanIUseResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "can_i_use", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "usage_exceeded", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "is_appsumo", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "subscription_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "base_price", kind: "message", T: Money },
    { no: 7, name: "metered_subscription", kind: "message", T: MeteredSubscription },
    { no: 8, name: "canceled_at", kind: "message", T: Timestamp },
    { no: 9, name: "state", kind: "enum", T: proto3.getEnumType(SubscriptionState) },
    { no: 10, name: "slack_channel", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CanIUseResponse {
    return new CanIUseResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CanIUseResponse {
    return new CanIUseResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CanIUseResponse {
    return new CanIUseResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CanIUseResponse | PlainMessage<CanIUseResponse> | undefined, b: CanIUseResponse | PlainMessage<CanIUseResponse> | undefined): boolean {
    return proto3.util.equals(CanIUseResponse, a, b);
  }
}

