import { MetricId, MetricPermutation, metricsById } from '../utils/Metrics'

function allPossibleCases({
  aggregation,
  subAggregation,
  postAggregation,
  id
}: any) {
  const result: any = []
  aggregation
    .filter((agg: any) => !['min', 'max', 'p25', 'p50', 'p75'].includes(agg))
    .forEach((agg: any) => subAggregation.forEach((sub: any) => postAggregation.forEach((post: any) => {
    result.push({
      id,
      aggregation: agg,
      subAggregation: sub,
      postAggregation: post,
    })
  })
  )
    )

  return result
}

const removedMetrics = [
  'phrase',
  'engine',
  'device',
  'country',
  'language',
  'location_type',
  'location',
]

type Profile = {
  label: string
  value: MetricPermutation<MetricId>[]
}

export const exportProfileOptions: Profile[] = [
  {
    label: 'All Data',
    value: Object.entries(metricsById).flatMap(metric => {
      if (removedMetrics.includes(metric[1].id)) {
        return []
      }
      return allPossibleCases({
        aggregation: metric[1].aggregations,
        subAggregation: metric[1].subAggregations,
        postAggregation: metric[1].postAggregations,
        id: metric[1].id,
      })
    }),
  },
  {
    label: 'Basic Ranking Data',
    value: [
      {
        id: 'top_rank',
        aggregation: 'avg',
        subAggregation: 'total',
        postAggregation: 'value',
      },
      {
        id: 'top_rank',
        aggregation: 'avg',
        subAggregation: 'total',
        postAggregation: 'change',
      },
      {
        id: 'top_rank',
        aggregation: 'avg',
        subAggregation: 'total',
        postAggregation: 'best',
      },
      {
        id: 'top_rank',
        aggregation: 'avg',
        subAggregation: 'total',
        postAggregation: 'worst',
      },

      {
        id: 'adwords_search_volume',
        aggregation: 'sum',
        subAggregation: 'total',
        postAggregation: 'value',
      },
      {
        id: 'top_ranking_results',
        aggregation: 'any',
        subAggregation: 'any',
        postAggregation: 'value',
      },
    ],
  },
  {
    label: 'All Main Metrics',
    value: [
      {
        id: 'top_rank',
        aggregation: 'avg',
        subAggregation: 'total',
        postAggregation: 'value',
      },
      {
        id: 'top_rank',
        aggregation: 'avg',
        subAggregation: 'total',
        postAggregation: 'change',
      },
      {
        id: 'adwords_search_volume',
        aggregation: 'sum',
        subAggregation: 'total',
        postAggregation: 'value',
      },
      {
        id: 'top_ranking_results',
        aggregation: 'any',
        subAggregation: 'any',
        postAggregation: 'value',
      },
      {
        id: 'top_pixels_from_top',
        aggregation: 'avg',
        subAggregation: 'total',
        postAggregation: 'value',
      },
      {
        id: 'top_pixels_from_top',
        aggregation: 'avg',
        subAggregation: 'total',
        postAggregation: 'change',
      },
      {
        id: 'click_through_rate',
        aggregation: 'avg',
        subAggregation: 'total',
        postAggregation: 'value',
      },
      {
        id: 'click_through_rate',
        aggregation: 'avg',
        subAggregation: 'total',
        postAggregation: 'change',
      },
      {
        id: 'percentage_of_viewport',
        aggregation: 'avg',
        subAggregation: 'total',
        postAggregation: 'value',
      },
      {
        id: 'percentage_of_viewport',
        aggregation: 'avg',
        subAggregation: 'total',
        postAggregation: 'change',
      },
      {
        id: 'percentage_of_dom',
        aggregation: 'avg',
        subAggregation: 'total',
        postAggregation: 'value',
      },
      {
        id: 'percentage_of_dom',
        aggregation: 'avg',
        subAggregation: 'total',
        postAggregation: 'change',
      },
      {
        id: 'estimated_traffic',
        aggregation: 'sum',
        subAggregation: 'total',
        postAggregation: 'value',
      },
      {
        id: 'estimated_traffic',
        aggregation: 'sum',
        subAggregation: 'total',
        postAggregation: 'change',
      },
      {
        id: 'ppc_value',
        aggregation: 'sum',
        subAggregation: 'total',
        postAggregation: 'value',
      },
      {
        id: 'ppc_value',
        aggregation: 'sum',
        subAggregation: 'total',
        postAggregation: 'change',
      },
      {
        id: 'unique_urls',
        aggregation: 'count',
        subAggregation: 'total',
        postAggregation: 'value',
      },
      {
        id: 'unique_urls',
        aggregation: 'count',
        subAggregation: 'total',
        postAggregation: 'change',
      },
      {
        id: 'results',
        aggregation: 'count',
        subAggregation: 'total',
        postAggregation: 'value',
      },
      {
        id: 'results',
        aggregation: 'count',
        subAggregation: 'total',
        postAggregation: 'change',
      },
    ],
  },
  {
    label: 'Local',
    value: [
      {
        id: 'top_rank',
        aggregation: 'avg',
        subAggregation: 'total',
        postAggregation: 'value',
      },
      {
        id: 'top_rank',
        aggregation: 'avg',
        subAggregation: 'total',
        postAggregation: 'change',
      },
      {
        id: 'top_ranking_results',
        aggregation: 'any',
        subAggregation: 'any',
        postAggregation: 'value',
      },
      {
        id: 'adwords_search_volume',
        aggregation: 'sum',
        subAggregation: 'total',
        postAggregation: 'value',
      },
      {
        id: 'top_ranking_results',
        aggregation: 'any',
        subAggregation: 'any',
        postAggregation: 'value',
      },
    ],
  },
  {
    label: 'Title Tags',
    value: [
      {
        id: 'top_rank',
        aggregation: 'avg',
        subAggregation: 'total',
        postAggregation: 'value',
      },
      {
        id: 'top_ranking_results',
        aggregation: 'any',
        subAggregation: 'any',
        postAggregation: 'value',
      },
    ],
  },
]
