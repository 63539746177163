import { useNavigate, useSearch } from '@tanstack/react-router'
// @ts-ignore
import { twMerge } from 'tailwind-merge'
import * as React from 'react'
import { FaAngleRight, FaCheck } from 'react-icons/fa'
import Card from '../../components/Card'
import { Head } from '../../components/Head'
import { ButtonPlain } from '../../components/ButtonPlain'

import {
  UpdateMonitoredUrlsFromTextMutationOptions,
  useUpdateMonitoredUrlsFromDomainMutation,
  useUpdateMonitoredUrlsFromSitemapMutation,
  useUpdateMonitoredUrlsFromTextMutation,
} from '../../utils/monitoredUrls'
import { useActiveWorkspaceId } from '../../hooks/workspaces'
import { BackToMonitoredUrls } from './MonitoredUrls'
import { useForm } from '@tanstack/react-form'
import { LimitDepth } from '../../utils/types'
import LabeledInput from '../../components/LabeledInput'
import Validate from '../../utils/Validate'
import {
  UpdateMonitoredUrlsFromSitemapRequestPb,
  MonitoredUrlPb,
} from '../../utils/proto'
import { ProjectPicker } from '../../components/ProjectPicker'
import { BrandPicker } from '../../components/BrandPicker'
import { BRAND_TYPE_OWNED } from '../../utils/Constants'
import { Tab, Tabs } from '../../components/Tabs'
import Spinner from '../../components/Spinner'
import TextArea from '../../components/TextAreaInput'
import LabelWrap from '../../components/LabelWrap'
import Link from '../../components/Link'

const tabOptions = [
  {
    id: 'domain',
    label: 'Domain',
  },
  {
    id: 'sitemap',
    label: 'Sitemap',
  },
  {
    id: 'text',
    label: 'Text List',
  },
] as const

export function MonitoredUrlsImport() {
  const navigate = useNavigate()

  const updateMonitoredUrlsFromDomainMutation =
    useUpdateMonitoredUrlsFromDomainMutation()

  const updateMonitoredUrlsFromSitemapMutation =
    useUpdateMonitoredUrlsFromSitemapMutation()

  const updateMonitoredUrlsFromTextMutation =
    useUpdateMonitoredUrlsFromTextMutation()

  const activeTabId = useSearch().monitoredUrlsImportTabId ?? 'domain'

  return (
    <div className="space-y-2 py-4">
      <Head>
        <title>Monitored URLs</title>
      </Head>
      <div className="px-4">
        <Card className="flex flex-wrap items-center gap-2">
          <ProjectPicker />
          <FaAngleRight />
          <BackToMonitoredUrls />
          <FaAngleRight />
          <div className="text-xl font-bold">Import Monitored URLs</div>
        </Card>
      </div>
      <div className="px-4">
        <Tabs>
          {tabOptions.map((tab, index) => {
            return (
              <Link
                search={prev => ({
                  ...prev,
                  monitoredUrlsImportTabId: tab.id,
                })}
              >
                <Tab
                  key={index}
                  className={twMerge(tab.id === activeTabId && 'active')}
                >
                  {tab.label}
                </Tab>
              </Link>
            )
          })}
        </Tabs>
      </div>
      <div className="px-4">
        <Card className="">
          {activeTabId === 'domain' ? (
            <MonitoredUrlImportDomainForm
              onSubmit={async values => {
                await updateMonitoredUrlsFromDomainMutation.mutateAsync(values)
                navigate({
                  to: '/monitored-urls',
                })
              }}
            />
          ) : activeTabId === 'sitemap' ? (
            <MonitoredUrlImportSitemapForm
              onSubmit={async values => {
                await updateMonitoredUrlsFromSitemapMutation.mutateAsync(values)
                navigate({
                  to: '/monitored-urls',
                })
              }}
            />
          ) : (
            <MonitoredUrlImportTextForm
              onSubmit={async values => {
                await updateMonitoredUrlsFromTextMutation.mutateAsync(values)
                navigate({
                  to: '/monitored-urls',
                })
              }}
            />
          )}
        </Card>
      </div>
    </div>
  )
}

type DomainFormValues = {
  workspaceId: string
  projectId: string
  brandId: string
  domain: string
}

function MonitoredUrlImportDomainForm(props: {
  defaultValues?: DomainFormValues
  onSubmit?: (values: DomainFormValues) => void
}) {
  const workspaceId = useActiveWorkspaceId()
  const projectId = useSearch().projectId!

  const form = useForm<DomainFormValues>({
    defaultValues: React.useMemo(
      () => ({ workspaceId, projectId, brandId: '', domain: '' }),
      [projectId, workspaceId]
    ),
    onSubmit: props.onSubmit as any,
  })

  return (
    <div className="space-y-2">
      <form.Form className="space-y-2">
        <form.Field
          name="brandId"
          validate={Validate.required()}
          children={field => {
            return (
              <BrandPicker
                label="Brand"
                placeholder="Select a brand..."
                error={field.state.meta.touchedError}
                value={String(field.getValue() || '')}
                onChange={next => field.setValue(String(next))}
                filterOptions={options => {
                  return options.filter(
                    option => option.brand.type === BRAND_TYPE_OWNED
                  )
                }}
                className="flex-1"
                closeOnSelect
              />
            )
          }}
        />
        <form.Field
          name="domain"
          validate={Validate.required()}
          children={field => {
            return (
              <LabeledInput
                label="Domain"
                placeholder="e.g. https://mysite.com"
                error={field.state.meta.touchedError}
                {...field.getInputProps({
                  // @ts-ignore
                  required: true,
                })}
              />
            )
          }}
        />
        <div className="mt-2 flex gap-2">
          <form.Subscribe
            selector={s => s.isSubmitting}
            children={submitting => {
              return (
                <ButtonPlain
                  className="bg-green-500"
                  type="submit"
                  disabled={submitting}
                >
                  {submitting ? (
                    <>
                      <Spinner /> Importing URLs...
                    </>
                  ) : (
                    <>
                      <FaCheck /> Import URLs
                    </>
                  )}
                </ButtonPlain>
              )
            }}
          />
        </div>
      </form.Form>
    </div>
  )
}

function MonitoredUrlImportSitemapForm(props: {
  defaultValues?: UpdateMonitoredUrlsFromSitemapRequestPb
  onSubmit?: (values: UpdateMonitoredUrlsFromSitemapRequestPb) => void
}) {
  const search = useSearch()
  const activeWorkspaceId = useActiveWorkspaceId()

  const form = useForm<LimitDepth<UpdateMonitoredUrlsFromSitemapRequestPb>>({
    defaultValues: React.useMemo(
      () =>
        new UpdateMonitoredUrlsFromSitemapRequestPb({
          sitemapUrl: '',
          allowMissing: true,
          ...props.defaultValues,
          monitoredUrlTemplate: new MonitoredUrlPb({
            workspaceId: BigInt(activeWorkspaceId!),
            projectId: BigInt(search.projectId!),
            brandId: BigInt(0),
            ...props.defaultValues?.monitoredUrlTemplate,
          }),
        }) as LimitDepth<UpdateMonitoredUrlsFromSitemapRequestPb>,
      [activeWorkspaceId, props.defaultValues, search.projectId]
    ),
    onSubmit: props.onSubmit as any,
  })

  return (
    <div className="space-y-2">
      <form.Form className="space-y-2">
        <form.Field
          name="monitoredUrlTemplate.brandId"
          validate={Validate.required()}
          children={field => {
            return (
              <BrandPicker
                label="Brand"
                placeholder="Select a brand..."
                error={field.state.meta.touchedError}
                value={String(field.getValue() || '')}
                onChange={next => field.setValue(BigInt(next))}
                filterOptions={options => {
                  return options.filter(
                    option => option.brand.type === BRAND_TYPE_OWNED
                  )
                }}
                className="flex-1"
                closeOnSelect
              />
            )
          }}
        />
        <form.Field
          name="sitemapUrl"
          validate={Validate.required()}
          children={field => {
            return (
              <LabeledInput
                label="Sitemap URL"
                placeholder="e.g. https://mysite.com/sitemap.xml"
                error={field.state.meta.touchedError}
                {...field.getInputProps({
                  // @ts-ignore
                  required: true,
                })}
              />
            )
          }}
        />
        <div className="mt-2 flex gap-2">
          <ButtonPlain className="bg-green-500" type="submit">
            <FaCheck /> Import URLs
          </ButtonPlain>
        </div>
      </form.Form>
    </div>
  )
}

function MonitoredUrlImportTextForm(props: {
  defaultValues?: UpdateMonitoredUrlsFromTextMutationOptions
  onSubmit?: (values: UpdateMonitoredUrlsFromTextMutationOptions) => void
}) {
  const workspaceId = useActiveWorkspaceId()
  const projectId = useSearch().projectId!
  const textAreaRef = React.useRef<HTMLTextAreaElement>(null)

  const form = useForm<UpdateMonitoredUrlsFromTextMutationOptions>({
    defaultValues: React.useMemo(
      () => ({ workspaceId, projectId, brandId: '', urlsText: '' }),
      [projectId, workspaceId]
    ),
    onSubmit: values =>
      props.onSubmit?.({
        ...values,
        urlsText: textAreaRef.current!.value,
      }),
  })

  return (
    <div className="space-y-2">
      <form.Form className="space-y-2">
        <form.Field
          name="brandId"
          validate={Validate.required()}
          children={field => {
            return (
              <BrandPicker
                label="Brand"
                placeholder="Select a brand..."
                error={field.state.meta.touchedError}
                value={String(field.getValue() || '')}
                onChange={next => field.setValue(String(next))}
                filterOptions={options => {
                  return options.filter(
                    option => option.brand.type === BRAND_TYPE_OWNED
                  )
                }}
                className="flex-1"
                closeOnSelect
              />
            )
          }}
        />
        <form.Field
          name="urlsText"
          validate={Validate.required()}
          children={field => {
            return (
              <LabelWrap label="URLs">
                <TextArea
                  ref={textAreaRef}
                  placeholder={[
                    'e.g.',
                    '',
                    // A list of 10 different fake URLs from a make-believe blog site with varying path depths and slugs
                    'https://mysite.com/',
                    'https://mysite.com/about',
                    'https://mysite.com/blog',
                    'https://mysite.com/blog/1',
                    'https://mysite.com/blog/2',
                    'https://mysite.com/blog/categories',
                    'https://mysite.com/blog/categories/1',
                    'https://mysite.com/blog/categories/2',
                  ].join('\n')}
                  error={field.state.meta.touchedError}
                  type="url"
                  initialValue=""
                  // {...field.getInputProps({
                  //   // @ts-ignore
                  //   required: true,
                  // })}
                  rows={Math.max(
                    10,
                    Math.min(field.state.value.split('\n').length, 50)
                  )}
                  className="text-sm"
                />
              </LabelWrap>
            )
          }}
        />
        <div className="mt-2 flex gap-2">
          <form.Subscribe
            selector={s => s.isSubmitting}
            children={submitting => {
              return (
                <ButtonPlain
                  className="bg-green-500"
                  type="submit"
                  disabled={submitting}
                >
                  {submitting ? (
                    <>
                      <Spinner /> Importing URLs...
                    </>
                  ) : (
                    <>
                      <FaCheck /> Import URLs
                    </>
                  )}
                </ButtonPlain>
              )
            }}
          />
        </div>
      </form.Form>
    </div>
  )
}
