import {
  BRAND_TYPE_COMPETITOR_DIRECT,
  BRAND_TYPE_COMPETITOR_INDIRECT,
  BRAND_TYPE_COMPETITOR_REPLACEMENT,
  BRAND_TYPE_FRIENDLY,
  BRAND_TYPE_NEGATIVE,
  BRAND_TYPE_NEUTRAL,
  BRAND_TYPE_OWNED,
  BRAND_TYPE_POSITIVE,
} from '../utils/Constants'

export const brandTypeOptions = [
  { value: BRAND_TYPE_OWNED, label: 'Owned' },
  { value: BRAND_TYPE_FRIENDLY, label: 'Friendly' },
  { value: BRAND_TYPE_NEUTRAL, label: 'Neutral' },
  { value: BRAND_TYPE_COMPETITOR_DIRECT, label: 'Competitor (Direct)' },
  { value: BRAND_TYPE_COMPETITOR_INDIRECT, label: 'Competitor (Indirect)' },
  {
    value: BRAND_TYPE_COMPETITOR_REPLACEMENT,
    label: 'Competitor (Replacement)',
  },
  { value: BRAND_TYPE_POSITIVE, label: 'Positive' },
  { value: BRAND_TYPE_NEGATIVE, label: 'Negative' },
]
