// @generated by protoc-gen-connect-web v0.11.0 with parameter "target=ts"
// @generated from file nozzle/billing/v1/account.proto (package nozzle.billing.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CanIUseRequest, CanIUseResponse, CreateAccountRequest, CreateAccountResponse, CreateContactRequest, CreateContactResponse, DeleteContactRequest, DeleteContactResponse, GetAccountRequest, GetAccountResponse, ListTaxIdTypesRequest, ListTaxIdTypesResponse, SyncAccountRequest, SyncAccountResponse, UpdateBillingDetailsRequest, UpdateBillingDetailsResponse, UpdateContactRequest, UpdateContactResponse } from "./account_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * AccountsService provides APIs for interacting with billing subscriptions.
 *
 * @generated from service nozzle.billing.v1.AccountsService
 */
export const AccountsService = {
  typeName: "nozzle.billing.v1.AccountsService",
  methods: {
    /**
     * CreateAccount creates a new billing account
     *
     * @generated from rpc nozzle.billing.v1.AccountsService.CreateAccount
     */
    createAccount: {
      name: "CreateAccount",
      I: CreateAccountRequest,
      O: CreateAccountResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetAccount retrieves a billing subscription.
     *
     * @generated from rpc nozzle.billing.v1.AccountsService.GetAccount
     */
    getAccount: {
      name: "GetAccount",
      I: GetAccountRequest,
      O: GetAccountResponse,
      kind: MethodKind.Unary,
    },
    /**
     * SyncAccount syncs various account data with the external billing provider.
     *
     * @generated from rpc nozzle.billing.v1.AccountsService.SyncAccount
     */
    syncAccount: {
      name: "SyncAccount",
      I: SyncAccountRequest,
      O: SyncAccountResponse,
      kind: MethodKind.Unary,
    },
    /**
     * UpdateBillingDetails updates the billing details on an account ex. address.
     *
     * @generated from rpc nozzle.billing.v1.AccountsService.UpdateBillingDetails
     */
    updateBillingDetails: {
      name: "UpdateBillingDetails",
      I: UpdateBillingDetailsRequest,
      O: UpdateBillingDetailsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ListTaxIdTypes returns all the valid tax id types per country.
     *
     * @generated from rpc nozzle.billing.v1.AccountsService.ListTaxIdTypes
     */
    listTaxIdTypes: {
      name: "ListTaxIdTypes",
      I: ListTaxIdTypesRequest,
      O: ListTaxIdTypesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * CreateContact creates the provided contact.
     *
     * @generated from rpc nozzle.billing.v1.AccountsService.CreateContact
     */
    createContact: {
      name: "CreateContact",
      I: CreateContactRequest,
      O: CreateContactResponse,
      kind: MethodKind.Unary,
    },
    /**
     * UpdateContact updates the specified contact.
     *
     * @generated from rpc nozzle.billing.v1.AccountsService.UpdateContact
     */
    updateContact: {
      name: "UpdateContact",
      I: UpdateContactRequest,
      O: UpdateContactResponse,
      kind: MethodKind.Unary,
    },
    /**
     * DeleteContact deletes the specified contact.
     *
     * @generated from rpc nozzle.billing.v1.AccountsService.DeleteContact
     */
    deleteContact: {
      name: "DeleteContact",
      I: DeleteContactRequest,
      O: DeleteContactResponse,
      kind: MethodKind.Unary,
    },
    /**
     * CanIUse makes a decision about continued usage of a product.
     * TODO: this is terribly named, but was completed under duress.
     *
     * @generated from rpc nozzle.billing.v1.AccountsService.CanIUse
     */
    canIUse: {
      name: "CanIUse",
      I: CanIUseRequest,
      O: CanIUseResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

