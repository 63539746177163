import * as React from 'react'
import useZustand from '../hooks/useZustand'

export default function useLoading() {
  const [isLoading, setStore] = useZustand(state => state.isLoading)

  const setLoading = React.useCallback(
    updater => {
      setStore(old => ({
        ...old,
        isLoading:
          typeof updater === 'function' ? updater(old.isLoading) : updater,
      }))
    },
    [setStore]
  )

  return [isLoading, setLoading] as const
}
