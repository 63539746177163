import { ClusterClient, ClusterViewPb, ListClustersFiltersPb } from './proto'

export async function updateClusters(opts: {
  workspaceId: string
  projectId: string
  clusterJobId: string
  includedDomains?: string[]
  waitForCompletion: boolean
}) {
  const res = await ClusterClient.updateClusters({
    workspaceId: BigInt(opts.workspaceId),
    projectId: BigInt(opts.projectId),
    includedDomains: opts.includedDomains,
    allowMissing: true,
    waitForCompletion: opts.waitForCompletion,
  })
  await new Promise(r => setTimeout(r, 5000))
  return res
}

export type FetchClustersOpts = {
  workspaceId: string
  projectId: string
  clusterJobId: string
  filters: Partial<ListClustersFiltersPb>
  domainId?: string
  pageSize?: number
  pageToken?: string
  view: ClusterViewPb
  maxSubRows?: number
}

export async function fetchClusters(opts: FetchClustersOpts) {
  return await ClusterClient.listClusters({
    workspaceId: BigInt(opts.workspaceId),
    projectId: BigInt(opts.projectId),
    filters: opts.filters,
    domainId: opts.domainId ? BigInt(opts.domainId) : undefined,
    pageSize: opts.pageSize,
    pageToken: opts.pageToken,
    view: opts.view,
  })
}

export async function fetchClusterFacets(opts: FetchClustersOpts) {
  return await ClusterClient.listClustersFacets({
    workspaceId: BigInt(opts.workspaceId),
    projectId: BigInt(opts.projectId),
    filters: opts.filters,
    domainId: opts.domainId ? BigInt(opts.domainId) : undefined,
  })
}

export async function fetchCluster(opts: {
  workspaceId: string
  projectId: string
  clusterId: string
}) {
  return await ClusterClient.getCluster({
    workspaceId: BigInt(opts.workspaceId),
    projectId: BigInt(opts.projectId),
    clusterId: BigInt(opts.clusterId),
  }).then(d => d.cluster)
}
