import * as React from 'react'
import { RouterProvider } from '@tanstack/react-router'
import { QueryClient, QueryClientProvider } from 'react-query'
import './utils/Firebase'
import { ThemeModeProvider } from './utils/Theme'
import { router } from './Router'
import ErrorBoundary from './components/ErrorBoundary'

// Add the css and disabled attributes to all dom elements
declare module 'react' {
  interface Attributes {
    disabled?: any
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000,
      refetchOnWindowFocus: false,
      queryKeyHashFn: key =>
        JSON.stringify(key, (k, v) =>
          typeof v === 'object' && v !== null
            ? Object.fromEntries(
                Object.keys(v)
                  .sort()
                  .map(key => [key, v[key]])
              )
            : typeof v === 'bigint'
            ? String(v)
            : v
        ),
    },
  },
})

export default function App() {
  return (
    <React.StrictMode>
      <ErrorBoundary>
        <ThemeModeProvider>
          <QueryClientProvider client={queryClient} contextSharing={true}>
            <RouterProvider router={router} />
          </QueryClientProvider>
        </ThemeModeProvider>
      </ErrorBoundary>
    </React.StrictMode>
  )
}
