import { useNavigate, useSearch } from '@tanstack/react-router'
import * as React from 'react'
import { FaAngleRight, FaCaretDown, FaPlus } from 'react-icons/fa'
import { useMutation, useQuery } from 'react-query'
import { twMerge } from 'tailwind-merge'
import Card from '../../../components/Card'
import { Head } from '../../../components/Head'
import Link from '../../../components/Link'
import QueryGate from '../../../components/QueryGate'
import { getDataColor } from '../../../utils/DataColors'
import { fetchCluster, fetchClusters } from '../../../utils/clusters'
import { clusterMetricsList, renderKeyword } from '../metrics'
import {
  ClusterPb,
  ClusterViewPb,
  MappedKeywordPb,
  TopicPb,
} from '../../../utils/proto'
import Clickable from '../../../components/Clickable'
import Select from '../../../components/Select'
import Loader from '../../../components/Loader'
import { queryKeyClusters } from '../../../utils/Constants'
import { URLs } from './URLs'
import { PAA } from './PAA'
import { FAQ } from './FAQ'
import { ShareOfVoice } from './ShareOfVoice'
import { Keywords } from './Keywords'
import { SerpFeatures } from './SerpFeatures'
import { Categories } from './Categories'
import { Entities } from './Entities'
import Tooltip from '../../../components/Tooltip'
import { IframeProxy } from '../../../components/ClusterUrl'
import { formatNumber } from '../../../utils/Format'
import { useLocalesByIdQuery } from '../../../utils/locales'
import { ButtonPlain } from '../../../components/ButtonPlain'
import useModal from '../../../hooks/useModal'
import { CreateTopicModal } from '../../topics/Topic'
import { useProfileQuery } from '../../../hooks/profile'
import Spinner from '../../../components/Spinner'
import { Tabs, Tab } from '../../../components/Tabs'

export type ClusterPanelId =
  | 'overview'
  | 'urls'
  | 'paa'
  | 'faq'
  | 'keywords'
  | 'entities'
  | 'categories'
  | 'share-of-voice'
  | 'serp-features'
  | 'titles'
  | 'descriptions'
// | 'pasf'

const panels: { label: string; id: ClusterPanelId }[] = [
  { label: 'Overview', id: 'overview' },
  { label: 'Keywords', id: 'keywords' },
  { label: 'URLs', id: 'urls' },
  { label: 'Entities', id: 'entities' },
  { label: 'Categories', id: 'categories' },
  { label: 'PAA', id: 'paa' },
  { label: 'FAQ', id: 'faq' },
  { label: 'Share of Voice', id: 'share-of-voice' },
  { label: 'SERP Features', id: 'serp-features' },
  // { label: 'Titles', id: 'titles' },
  // { label: 'Descriptions', id: 'descriptions' },
  // { label: 'PASF', id: 'pasf' },
]

export function ClusterComp(props: {
  workspaceId: string
  projectId: string
  clusterJobId: string
  clusterId: string
  focusDomain?: string
}) {
  const { workspaceId, projectId, clusterId, focusDomain } = props
  const navigate = useNavigate()
  const showModal = useModal()
  const profileQuery = useProfileQuery()

  const search = useSearch()
  const clusterPanel = (search.clusterPanel as ClusterPanelId) || 'overview'

  const fetchClustersPayload = React.useMemo(() => {
    return {
      workspaceId,
      projectId: projectId!,
      clusterJobId: props.clusterJobId,
      filters: {},
      view: ClusterViewPb.BASIC,
      pageSize: 10000,
    }
  }, [projectId, props.clusterJobId, workspaceId])

  const clustersQuery = useQuery({
    queryKey: [queryKeyClusters, fetchClustersPayload],
    enabled: Boolean(workspaceId && projectId && props.clusterJobId),
    queryFn: async () => {
      // try to fetch from local storage first. If not present, store it there
      // const cacheKey = JSON.stringify([queryKeyClusters, fetchClustersPayload])

      // const cached = await nozzleCacheClient.get<ListClustersResponsePb>(
      //   cacheKey
      // )

      // if (cached) return cached

      const res = await fetchClusters(fetchClustersPayload)

      // nozzleCacheClient.set(cacheKey, res, {
      //   ttl: 30 * 60 * 1000,
      // })

      return res
    },
    staleTime: Infinity,
    cacheTime: Infinity,
    keepPreviousData: true,
  })

  const clusterQuery = useQuery({
    queryKey: [queryKeyClusters, clusterId, { workspaceId, projectId }],
    enabled: Boolean(workspaceId && projectId && clusterId),
    queryFn: () =>
      fetchCluster({
        workspaceId,
        projectId,
        clusterId,
      }),
    retry: false,
  })

  const clusterOptions = React.useMemo(() => {
    return clustersQuery.data?.clusters.map(cluster => {
      return {
        label: `${cluster.name} (${Number(cluster.clusterId)})`,
        value: Number(cluster.clusterId),
      }
    })
  }, [clustersQuery.data?.clusters])

  const cluster = clusterQuery.data!

  const getKeywordIds = async () => {
    return cluster.metricsByKeyword.map(d => String(d.keyword!.keywordId))
  }

  const createTopicMutation = useMutation(async () => {
    const uniqueKeywordIds = await getKeywordIds()

    const modal = showModal(() => (
      <CreateTopicModal
        defaultTopic={
          new TopicPb({
            name: cluster.name,
            mappedKeywords: uniqueKeywordIds.map(keywordId => {
              return new MappedKeywordPb({
                keywordId: BigInt(keywordId),
              })
            }),
          })
        }
        onSuccess={modal.close}
      />
    ))
  })

  const addToTopicMutation = useMutation(async () => {
    const uniqueKeywordIds = await getKeywordIds()

    const modal = showModal(() => (
      <CreateTopicModal
        defaultTab="existing"
        defaultTopic={
          new TopicPb({
            name: cluster.name,
            mappedKeywords: uniqueKeywordIds.map(keywordId => {
              return new MappedKeywordPb({
                keywordId: BigInt(keywordId),
              })
            }),
          })
        }
        onSuccess={modal.close}
      />
    ))
  })

  return (
    <div className="space-y-2">
      <Head>
        <title>
          Keyword Clustering - {clusterQuery.data?.name || 'Loading...'}
        </title>
      </Head>

      <Card className="flex items-center gap-1 text-lg font-bold">
        <Link search={d => ({ ...d, clusterId: undefined })}>Clusters</Link>
        <FaAngleRight />
        {clusterQuery.isFetching ? (
          <Loader className="mx-2" />
        ) : (
          <Select
            options={clusterOptions}
            onChange={value => {
              navigate({
                search: d => ({
                  ...d,
                  clusterId: value,
                  urlId: undefined,
                  clusterUrlPanelId: undefined,
                  keywordId: undefined,
                }),
              })
            }}
            children={({ onClick }) => {
              return (
                <Clickable
                  onClick={onClick}
                  className="flex items-center gap-1"
                >
                  {cluster?.name} <FaCaretDown />
                </Clickable>
              )
            }}
          />
        )}
        <div className="ml-auto flex flex-wrap items-center gap-2">
          {profileQuery.data?.isAdmin && cluster?.metricsByKeyword.length ? (
            <div className="flex flex-wrap items-center gap-2">
              <ButtonPlain
                className="bg-gray-500/20 text-xs text-black hover:bg-gray-500/30 dark:text-white"
                onClick={() => createTopicMutation.mutate()}
              >
                {createTopicMutation.isLoading ? (
                  <>
                    <Spinner /> Preparing...
                  </>
                ) : (
                  <>
                    <FaPlus /> Create Topic
                  </>
                )}
              </ButtonPlain>
              <ButtonPlain
                className="bg-gray-500/20 text-xs text-black hover:bg-gray-500/30 dark:text-white"
                onClick={() => addToTopicMutation.mutate()}
              >
                {addToTopicMutation.isLoading ? (
                  <>
                    <Spinner /> Preparing...
                  </>
                ) : (
                  <>
                    <FaPlus /> Add to Topic
                  </>
                )}
              </ButtonPlain>
            </div>
          ) : null}
        </div>
      </Card>
      <QueryGate
        query={clusterQuery}
        children={() => {
          return (
            <>
              <Tabs>
                {panels.map((panel, i) => {
                  return (
                    <Link
                      key={i}
                      search={d => ({
                        ...d,
                        clusterPanel: panel.id,
                        keywordId: undefined,
                        urlId: undefined,
                        clusterUrlPanelId: undefined,
                      })}
                    >
                      <Tab
                        className={twMerge(
                          panel.id === clusterPanel && 'active'
                        )}
                      >
                        {panel.label}
                      </Tab>
                    </Link>
                  )
                })}
              </Tabs>
              <div>
                {clusterQuery.isLoading ? (
                  <Card className="flex items-center justify-center">
                    <div className="space-y-2 p-4 text-center">
                      <div className="text-sm font-bold">Loading Cluster</div>
                      <Loader className="text-2xl" />
                    </div>
                  </Card>
                ) : (
                  <div className="space-y-2">
                    {(() => {
                      if (clusterPanel === 'overview') {
                        return <Overview cluster={cluster} />
                      } else if (clusterPanel === 'urls') {
                        return <URLs cluster={cluster} />
                      } else if (clusterPanel === 'paa') {
                        return <PAA cluster={cluster} />
                      } else if (clusterPanel === 'faq') {
                        return <FAQ cluster={cluster} />
                      } else if (clusterPanel === 'share-of-voice') {
                        return <ShareOfVoice cluster={cluster} />
                      } else if (clusterPanel === 'keywords') {
                        return <Keywords cluster={cluster} />
                      } else if (clusterPanel === 'serp-features') {
                        return <SerpFeatures cluster={cluster} />
                      } else if (clusterPanel === 'categories') {
                        return <Categories cluster={cluster} />
                      } else if (clusterPanel === 'entities') {
                        return (
                          <Entities
                            cluster={cluster}
                            focusDomain={focusDomain}
                          />
                        )
                        // } else if (clusterPanel === 'descriptions') {
                        //   return <Descriptions cluster={cluster} />
                        // } else if (clusterPanel === 'titles') {
                        //   return <Titles cluster={cluster} />
                        // } else if (clusterPanel === 'pasf') {
                        //   return <PASF cluster={cluster} />
                      } else {
                        return 'Invalid Panel! Please select a panel from the tabs above.'
                      }
                    })()}
                  </div>
                )}
              </div>
            </>
          )
        }}
      />
    </div>
  )
}

function Overview({ cluster }: { cluster: ClusterPb }) {
  const { clusterMetrics } = cluster

  const localesByIdQuery = useLocalesByIdQuery({
    localeIds: cluster.metricsByKeyword.map(d => Number(d.keyword?.localeId)),
  })

  const keywordLimit = 10

  const remainingKeywords = Math.max(cluster.keywordCount - keywordLimit, 0)

  return (
    <>
      <div className="grid items-stretch gap-2 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-6">
        {[
          {
            label: 'URLs',
            value: cluster.urlCount,
          },
          {
            label: 'Keywords',
            value: cluster.keywordCount,
          },
          ...clusterMetricsList.map((metric, i) => {
            return {
              label: metric.label,
              value: metric.getRenderedValue(clusterMetrics![metric.id]!),
            }
          }),
        ].map((metric, i) => {
          return (
            <Card
              key={metric.label}
              className={twMerge(
                'flex flex-1 flex-col items-center justify-between gap-2 p-1 px-2 font-bold',
                'md:p-2',
                '!border-y-0 !border-l-4 !border-r-0 dark:shadow dark:shadow-gray-800'
              )}
              style={{
                borderColor: getDataColor(i),
              }}
            >
              <div className="text-sm uppercase opacity-80">{metric.label}</div>
              <div className="text-lg md:text-xl lg:text-3xl">
                {metric.value}
              </div>
            </Card>
          )
        })}
      </div>
      <div className="grid items-stretch gap-2 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-6">
        {(
          [
            { label: 'Entities', value: cluster.metricsByEntity.length },
            { label: 'Categories', value: cluster.metricsByCategory.length },
          ] as const
        ).map((item, i) => {
          return (
            <Link key={i}>
              <Card
                className={twMerge(
                  'flex flex-col items-center justify-between gap-2 p-1 px-2 font-bold',
                  'md:p-2',
                  'dark:shadow dark:shadow-gray-800',
                  'hover:bg-blue-500 hover:text-white'
                )}
              >
                <div className="text-sm uppercase opacity-80">{item.label}</div>
                <div className="text-lg md:text-xl lg:text-3xl">
                  {item.value}
                </div>
              </Card>
            </Link>
          )
        })}
      </div>
      <div className="flex flex-wrap gap-2">
        <Card className="min-w-[200px] flex-1 divide-y divide-gray-500/20 p-0">
          <Link
            className="inline-block p-2 text-lg font-bold"
            search={prev => ({
              ...prev,
              clusterId: String(cluster.clusterId),
              clusterPanel: 'keywords',
              keywordId: undefined,
            })}
          >
            Keywords ({formatNumber(cluster.keywordCount)})
          </Link>
          <div className="divide-y divide-gray-500/20">
            <div className="divide-y divide-gray-500/20 text-sm">
              {cluster.metricsByKeyword
                .slice(0, keywordLimit)
                .map((byKeyword, i) => {
                  return (
                    <Tooltip
                      key={i}
                      tooltip={renderKeyword(
                        byKeyword.keyword,
                        localesByIdQuery.data,
                        {
                          wrap: false,
                        }
                      )}
                      className="block overflow-hidden whitespace-nowrap px-2 py-1"
                    >
                      <Link
                        key={String(byKeyword.keyword?.keywordId) || i}
                        search={prev => ({
                          ...prev,
                          clusterId: String(cluster.clusterId),
                          clusterPanel: 'keywords',
                          keywordId: String(byKeyword.keyword?.keywordId),
                        })}
                      >
                        {renderKeyword(
                          byKeyword.keyword,
                          localesByIdQuery.data,
                          {
                            wrap: false,
                          }
                        )}
                      </Link>
                    </Tooltip>
                  )
                })}
              {remainingKeywords ? (
                <Link
                  search={prev => ({
                    ...prev,
                    clusterId: String(cluster.clusterId),
                    clusterPanel: 'keywords',
                  })}
                  className="block w-full px-2 py-1 text-left font-bold"
                >
                  ...{formatNumber(remainingKeywords)} more
                </Link>
              ) : null}
            </div>
          </div>
        </Card>
        <Card className="flex-auto divide-y divide-gray-500/20 p-0">
          <Link
            className="inline-block p-2 text-lg font-bold"
            search={prev => ({
              ...prev,
              clusterId: String(cluster.clusterId),
              clusterPanel: 'urls',
              urlId: undefined,
            })}
          >
            URLs ({formatNumber(cluster.urlCount)})
          </Link>
          <div className="grid divide-x divide-gray-500/20 overflow-x-auto p-0 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
            {cluster.clusterUrls.map(clusterUrl => {
              return (
                <Tooltip
                  key={clusterUrl.url?.url}
                  tooltip={clusterUrl.url?.url}
                  className="flex-[1_1_200px]"
                >
                  <Link
                    search={prev => ({
                      ...prev,
                      clusterPanel: 'urls',
                      urlId: clusterUrl.url?.urlId,
                      clusterUrlPanelId: undefined,
                    })}
                    className="flex-none divide-y divide-gray-500/20 !text-inherit"
                  >
                    <div className="block overflow-hidden text-ellipsis whitespace-nowrap px-2 py-1 text-xs font-bold text-blue-500 dark:text-blue-400">
                      {clusterUrl.page?.title ?? clusterUrl.url?.url}
                    </div>
                    <div className="relative h-[400px] w-full overflow-hidden">
                      <IframeProxy
                        src={clusterUrl.url?.url}
                        showOpenFallback={false}
                        className="pointer-events-none absolute left-0 top-0 h-[800px] w-[200%] origin-top-left scale-50"
                      />
                    </div>
                  </Link>
                </Tooltip>
              )
            })}
          </div>
        </Card>
      </div>
      {/* <Card className="divide-y divide-gray-500/20 p-0">
        <Link
          className="inline-block p-2 text-lg font-bold"
          search={prev => ({
            ...prev,
            clusterId: Number(cluster.clusterId),
            clusterPanel: 'keywords',
            keywordId: undefined,
          })}
        >
          ({formatNumber(cluster.keywordCount)})
        </Link>
        <div className="divide-y divide-gray-500/20">
          <div className="divide-y divide-gray-500/20 text-sm">
            {cluster.metricsByKeyword
              .slice(0, keywordLimit)
              .map((byKeyword, i) => {
                return (
                  <Tooltip
                    key={i}
                    tooltip={renderKeyword(
                      byKeyword.keyword,
                      localesByIdQuery.data,
                      {
                        wrap: false,
                      }
                    )}
                    className="block overflow-hidden whitespace-nowrap px-2 py-1"
                  >
                    <Link
                      key={Number(byKeyword.keyword?.keywordId) || i}
                      search={prev => ({
                        ...prev,
                        clusterId: Number(cluster.clusterId),
                        clusterPanel: 'keywords',
                        keywordId: Number(byKeyword.keyword?.keywordId),
                      })}
                    >
                      {renderKeyword(byKeyword.keyword, localesByIdQuery.data, {
                        wrap: false,
                      })}
                    </Link>
                  </Tooltip>
                )
              })}
            {remainingKeywords ? (
              <Link
                search={prev => ({
                  ...prev,
                  clusterId: Number(cluster.clusterId),
                  clusterPanel: 'keywords',
                })}
                className="block w-full px-2 py-1 text-left font-bold"
              >
                ...{formatNumber(remainingKeywords)} more
              </Link>
            ) : null}
          </div>
        </div>
      </Card> */}
    </>
  )
}
