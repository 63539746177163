import { twMerge } from 'tailwind-merge'
import { withProps } from '../utils/withProps'

//

const getInputStyles = (error: any) => `
    text-sm flex-auto max-w-full min-h-[5.5rem]
    px-2 py-1
    bg-gray-50 dark:bg-gray-800
    text-black dark:text-white
    rounded-md
    border border-solid border-gray-200 dark:border-gray-800

    focus:bg-white focus:dark:bg-gray-850
    placeholder:text-gray-400

  ${error && `border-red-500`};

  
`

//

export const TextArea = withProps('textarea')(
  ({ className, error, ...props }) => ({
    ...props,
    className: twMerge(getInputStyles(error), className),
  })
)

export default TextArea
