import { useNavigate, useSearch } from '@tanstack/react-router'
import * as React from 'react'
import {
  FaAngleRight,
  FaCheckCircle,
  FaInfoCircle,
  FaPlus,
  FaTimesCircle,
} from 'react-icons/fa'
import Card from '../../components/Card'
import { Head } from '../../components/Head'
import Link from '../../components/Link'
import { ClickablePlain } from '../../components/Clickable'
import { ButtonPlain } from '../../components/ButtonPlain'
import { useActiveWorkspaceId } from '../../hooks/workspaces'
import Table from '../../components/Table'
import { useTable } from '../../hooks/useTable'
import { createColumnHelper } from '@tanstack/react-table'
import { KeywordSourcePb } from '../../utils/proto'
import moment from 'moment'
import Tooltip from '../../components/Tooltip'
import { ProjectPicker } from '../../components/ProjectPicker'
import Select from '../../components/Select'
import { GoogleSearchConsoleModal } from '../../components/GoogleSearchConsoleModal'
import { ImportSourceOptions } from '../../options/ImportSourceOptions'
import { BiImport } from 'react-icons/bi'
import QueryGate from '../../components/QueryGate'
import { sum } from 'd3-array'
import {
  useConvertKeywordSourceKeywords,
  useGenerateKeywordSourceKeywords,
  useKeywordSources,
} from '../../hooks/keywordSources'
import { useProfileQuery } from '../../hooks/profile'
import useModal from '../../hooks/useModal'
import {
  keywordSourceTypeOptionsAlpha,
  keywordSourceTypeOptions,
} from '../../options/keywordSourceTypeOptions'
import {
  KEYWORD_SOURCE_TYPE_JSON,
  KEYWORD_SOURCE_TYPE_BASIC,
} from '../../utils/Constants'
import { dedupePhraseGroups } from '../../utils/Phrases'
import Caption from '../../components/Caption'
import useZustand from '../../hooks/useZustand'
import { formatSchedule, scheduleConfigFromOption } from '../../utils/schedules'
import { formatNumber } from '../../utils/Format'
import { scheduleOptions } from '../../options/scheduleOptions'
import { useActiveProjectIdState } from '../../utils/searchParams'

export type TopicTab = undefined | 'clusters'

export const keywordManagerLabel = (
  <div className="text-xl font-bold">Keyword Manager</div>
)
export const backToKeywordManager = (
  <Link to="/keyword-manager">{keywordManagerLabel}</Link>
)

const columnHelper = createColumnHelper<KeywordSourcePb>()

export function KeywordManager() {
  const projectId = useActiveProjectIdState().state
  const profileQuery = useProfileQuery()
  const convertKeywordSourceKeywords = useConvertKeywordSourceKeywords()
  const generateKeywordSourceKeywords = useGenerateKeywordSourceKeywords()
  const workspaceId = useActiveWorkspaceId()
  const keywordSourcesQuery = useKeywordSources({
    projectId,
  })

  const resolvedKeywordSourceTypeOptions = profileQuery.data?.isAdmin
    ? keywordSourceTypeOptionsAlpha
    : keywordSourceTypeOptions

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('name', {
        id: 'Name',
        header: 'Keyword Source Name',
        filterFn: 'fuzzy',
        cell: props => (
          <Link to={`/keyword-sources/${props.row.original.id}`}>
            {props.getValue()}
          </Link>
        ),
      }),
      // columnHelper.accessor('type', {
      //   header: 'Mode',
      //   cell: props =>
      //     props.getValue()
      //       ? formatOption(resolvedKeywordSourceTypeOptions, props.getValue())
      //       : '-',
      // }),
      columnHelper.accessor('keywordCount', {
        id: 'Keyword Count',
        header: 'Total Keywords',
        cell: props => formatNumber(Number(props.getValue())),
        filterFn: 'inNumberRange',
        footer: props => {
          return (
            <span>
              {formatNumber(
                sum(props.table.getPrePaginationRowModel().rows, (row: any) =>
                  Number(row.original.keywordCount)
                )
              )}
            </span>
          )
        },
      }),
      columnHelper.accessor(
        d =>
          d.schedules.length
            ? d.schedules.map(dd => formatSchedule(dd.id)).join(', ')
            : '-',
        {
          id: 'Scheduled',
          header: 'Scheduled',
          filterFn: 'arrIncludesSome',
          meta: {
            showQuickFilter: true,
          },
        }
      ),
      columnHelper.accessor('id', {
        id: 'ID',
        header: 'ID',
        filterFn: 'equals',
      }),
      columnHelper.accessor(
        d =>
          d.updatedAt?.toDate
            ? d.updatedAt?.toDate()
            : new Date((d as any).updatedAt),
        {
          id: 'Updated At',
          header: 'Last Updated',
          filterFn: 'equals',
          meta: {
            tight: true,
          },
          cell: props => (
            <Tooltip tooltip={moment(props.getValue()).format('lll')}>
              {moment(props.getValue()).fromNow()}
            </Tooltip>
          ),
        }
      ),
      columnHelper.accessor(
        d =>
          d.createdAt?.toDate
            ? d.createdAt?.toDate()
            : new Date((d as any).createdAt),
        {
          id: 'Created At',
          header: 'Date Created',
          filterFn: 'equals',
          meta: {
            tight: true,
          },
          cell: props => (
            <Tooltip tooltip={moment(props.getValue()).format('lll')}>
              {moment(props.getValue()).fromNow()}
            </Tooltip>
          ),
        }
      ),
      columnHelper.accessor(
        d => (d.schedules.length && d.keywordCount ? 'Active' : 'Inactive'),
        {
          id: 'Status',
          filterFn: 'arrIncludesSome',
          header: 'Status',
          meta: {
            tight: true,
            showQuickFilter: true,
          },
          cell: ({ getValue, row: { original } }) => {
            return (
              <Tooltip
                tooltip={
                  !original.schedules.length
                    ? 'Missing Schedule'
                    : !original.keywordCount
                    ? 'Missing Keywords'
                    : 'Success'
                }
              >
                {getValue() ? (
                  <FaCheckCircle className="mx-auto text-green-500" />
                ) : (
                  <FaTimesCircle className="mx-auto text-red-500" />
                )}
              </Tooltip>
            )
          },
        }
      ),
    ],
    []
  )

  const tableData = React.useMemo(
    () => keywordSourcesQuery.data ?? [],
    [keywordSourcesQuery.data]
  )

  const table = useTable({
    // @ts-expect-error  // Type 'import("/Users/tannerlinsley/GitHub/app/src/... Remove this comment to see the full error message
    data: tableData,
    columns,
    initialState: {
      pagination: {
        pageSize: 20,
      },
      sorting: [{ id: 'Name', desc: false }],
    },
  })

  const navigate = useNavigate()

  const showModal = useModal()

  const accurankerSubmit = async (values: any) => {
    const jsonKeywords = values.map((value: any) => {
      return {
        phrase: value.original.keyword,
        devices: [value.original.device],
        localeIds: [value.original.locale.locale_id],
        groups: [
          ...(value.original.tags ? value.original.tags : []),
          value.original.starred ? 'Starred' : null,
          value.original.preferred_landing_page?.path
            ? `Landing Page: ${value.original.preferred_landing_page?.path}`
            : null,
        ].filter(n => n),
      }
    })

    const keywordSource = {
      type: KEYWORD_SOURCE_TYPE_JSON,
      name: `Accuranker Import - ${moment().format('YYYY-MM-DD HH:mm')} `,
      schedules: [{ rrule: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0' }],
      workspaceId,
      teamId: projectId,
      config: {
        jsonKeywords,
      },
    }

    const advancedKeywords = await generateKeywordSourceKeywords(keywordSource)

    const basicKeywordSource = await convertKeywordSourceKeywords(
      keywordSource,
      KEYWORD_SOURCE_TYPE_BASIC
    )

    if (basicKeywordSource.keywordCount === advancedKeywords.length) {
      return navigate({
        to: `/keyword-sources/new`,
        search: {
          sourceType: 'basic',
          keywordSource: basicKeywordSource,
        },
      })
    }

    navigate({
      to: `/keyword-sources/new`,
      search: {
        sourceType: 'json',
        keywordSource: { ...keywordSource, type: KEYWORD_SOURCE_TYPE_JSON },
      },
    })
  }

  const rankWatchSubmit = async (values: any) => {
    const jsonKeywords = values.map((value: any) => {
      return {
        phrase: value.original.keyword,
        devices: [value.original.device],
        localeIds: [value.original.locale.locale_id],
      }
    })

    const keywordSource = {
      type: KEYWORD_SOURCE_TYPE_JSON,
      name: `RankWatch Import - ${moment().format('YYYY-MM-DD HH:mm')} `,
      schedules: [{ rrule: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0' }],
      workspaceId,
      teamId: projectId,
      config: {
        jsonKeywords,
      },
    }

    const advancedKeywords = await generateKeywordSourceKeywords(keywordSource)

    const basicKeywordSource = await convertKeywordSourceKeywords(
      keywordSource,
      KEYWORD_SOURCE_TYPE_BASIC
    )

    if (basicKeywordSource.keywordCount === advancedKeywords.length) {
      return navigate({
        to: `/keyword-sources/new`,
        search: {
          sourceType: 'basic',
          keywordSource: basicKeywordSource,
        },
      })
    }

    navigate({
      to: `/keyword-sources/new`,
      search: {
        sourceType: 'json',
        keywordSource: { ...keywordSource, type: KEYWORD_SOURCE_TYPE_JSON },
      },
    })
  }

  const onSubmit = async (
    selectedRows: any,
    globalGroups: any,
    source: any
  ) => {
    const keywordSource = {
      type: KEYWORD_SOURCE_TYPE_BASIC,
      name: `${source} Import - ${moment().format('YYYY-MM-DD HH:mm')} `,
      schedules: [
        scheduleConfigFromOption(
          scheduleOptions.find(d => d.label === 'Daily')!
        ),
      ],
      workspaceId,
      teamId: projectId,
      config: {
        phraseMode: 'phrases',
        phraseGroups: dedupePhraseGroups([
          ...selectedRows.map((row: any) => ({
            phrase: row.phrase,
            groups: [...globalGroups, ...row.groups],
          })),
          // .map((row: any) =>
          //   source === 'Keyword.com'
          //     ? row.original.kw
          //     : source === 'SEOmonitor'
          //     ? row.original.keyword_data.name
          //     : source === 'GSC'
          //     ? row.original.key
          //     : row.original.keyword
          // )
          // .map((line: any) => {
          //   const [phrase, ...groups] = line
          //     .split(';')
          //     .map((d: any) => d.trim())
          //   return {
          //     phrase,
          //     groups: [...groups, ...globalGroups],
          //   }
          // }),
        ]).phraseGroups,
        devices: ['d'],
        localeIds: [44249],
      },
    }

    navigate({
      to: `/keyword-sources/new`,
      search: {
        sourceType: 'basic',
        keywordSource,
      },
    })
  }

  return (
    <div className="space-y-2 p-2">
      <Head>
        <title>Keyword Manager</title>
      </Head>
      <Card className="flex flex-wrap items-center gap-2">
        <ProjectPicker />
        <FaAngleRight />
        {keywordManagerLabel}
      </Card>
      <Card className="divide-y divide-gray-500/20 p-0">
        <div className="flex items-center gap-2 p-2">
          <Select
            options={resolvedKeywordSourceTypeOptions}
            className="inline-flex"
            onChange={value =>
              navigate({
                to: '/keyword-sources/new',
                search: { sourceType: value },
              })
            }
          >
            {({ onClick }: any) => (
              <ButtonPlain className="bg-green-500" onClick={onClick}>
                <FaPlus className="inline" />
                Create Keyword Source
              </ButtonPlain>
            )}
          </Select>{' '}
          <Select
            options={ImportSourceOptions.filter(
              (option: any) => option.value !== 'serpstat'
            )}
            className="inline-flex"
            onChange={value =>
              showModal(
                () =>
                  // value === 'accuranker' ? (
                  //   <AccurankerModal onSubmit={accurankerSubmit} />
                  // ) :
                  value === 'googleSearchConsole' ? (
                    <GoogleSearchConsoleModal
                      onSubmit={onSubmit}
                      allowMoreAdding={false}
                    />
                  ) : null
                // : value === 'rankWatch' ? (
                //   <RankWatchModal onSubmit={rankWatchSubmit} />
                // ) : value === 'seoMonitor' ? (
                //   <SeoMonitorModal
                //     onSubmit={onSubmit}
                //     allowMoreAdding={false}
                //   />
                // ) : value === 'keyword' ? (
                //   <KeywordDotComModal
                //     onSubmit={onSubmit}
                //     allowMoreAdding={false}
                //   />
                // ) : (
                //   <SerpWooModal onSubmit={onSubmit} allowMoreAdding={false} />
                // )
              )
            }
          >
            {({ onClick }: any) => (
              <ButtonPlain className="bg-gray-500" onClick={onClick}>
                <BiImport className="inline" />
                Import from Source
              </ButtonPlain>
            )}
          </Select>{' '}
          <KeywordSourceHelp />
        </div>
        <QueryGate query={keywordSourcesQuery}>
          {() => (
            <>
              {!tableData.length ? (
                <div className="p-4 italic opacity-50">
                  No keyword sources have been added yet.
                </div>
              ) : (
                <Table table={table} />
              )}
            </>
          )}
        </QueryGate>
      </Card>
    </div>
  )
}

export function KeywordSourceHelp() {
  const [, setStore] = useZustand(state => state.helpUrl)

  const openKeywordSourceDocs = () => {
    setStore(old => {
      old.showHelp = true
      old.helpUrl = 'https://help.nozzle.io/how-do-i-add-keywords'
    })
  }

  return (
    <Tooltip
      tooltip={
        <>
          A keyword source is a set of keywords with a shared tracking schedule
          and keyword mode. Create new keyword sources when you want to track
          new keywords on a different schedule or use a different keyword mode.
          Otherwise, use keyword groups to organize your keywords.{' '}
          <span className="underline">Click to learn more</span>
        </>
      }
    >
      <ClickablePlain onClick={openKeywordSourceDocs}>
        <Caption className="flex items-center gap-1">
          <FaInfoCircle className="inline" /> What is a keyword source?
        </Caption>
      </ClickablePlain>
    </Tooltip>
  )
}
