import { formatKeywordV1, formatNumber } from '../utils/Format'
import makeMemoCache from './makeMemoCache'

export const facetKeywords = makeMemoCache(keywords => {
  const sourceFacetKeys = [
    'country',
    'country_code',
    'device',
    'engine',
    'keyword_groups',
    'keyword_id',
    'keyword_sources',
    'language',
    'locale_id',
    'location',
    'location_id',
    'location_type',
    'phrase',
  ]

  const facets = {}

  const addToFacet = (key: any, value: any, label = '(none)', keyword: any) => {
    // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    facets[key] = facets[key] || {}
    // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    facets[key][value] = facets[key][value] || {
      label,
      keywords: [],
    }
    // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    facets[key][value].keywords.push(keyword)
  }

  keywords.forEach((keyword: any) => {
    sourceFacetKeys.forEach(sourceKey => {
      if (sourceKey === 'keyword_sources') {
        if (keyword[sourceKey]?.length) {
          keyword[sourceKey].forEach((keywordSource: any) => {
            addToFacet(
              'keyword_source_id',
              keywordSource.keyword_source_id,
              `${keywordSource.keyword_source_name || '(No Name)'} - ${
                keywordSource.keyword_source_id
              }`,
              keyword
            )
            addToFacet(
              'keyword_source_version_id',
              keywordSource.keyword_source_version_id,
              keywordSource.keyword_source_version_id,
              keyword
            )
          })
        }
      } else if (sourceKey === 'keyword_groups') {
        if (keyword[sourceKey]?.length) {
          keyword[sourceKey].forEach((value: any) => {
            addToFacet('keyword_group', value, value, keyword)
          })
        }
      } else if (sourceKey === 'keyword_id') {
        addToFacet(
          sourceKey,
          keyword[sourceKey],
          formatKeywordV1(keyword, { string: true }) as string,
          keyword
        )
      } else if (sourceKey === 'location_id') {
        addToFacet(
          sourceKey,
          keyword[sourceKey],
          keyword.location || keyword.country,
          keyword
        )
      } else if (sourceKey === 'locale_id') {
        addToFacet(
          sourceKey,
          keyword[sourceKey],
          formatKeywordV1(keyword, { string: true }) as string,
          keyword
        )
      } else {
        addToFacet(sourceKey, keyword[sourceKey], keyword[sourceKey], keyword)
      }
    })
  })

  Object.keys(facets).forEach(sourceKey => {
    // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    facets[sourceKey] = Object.keys(facets[sourceKey])
      .map(value => {
        // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        let { label, keywords } = facets[sourceKey][value]

        if (keywords.length > 1) {
          label = `${label} (${formatNumber(keywords.length, {
            short: false,
          })} keywords)`
        }

        return {
          value,
          label,
          keywords,
        }
      })
      .sort((a, b) => b.keywords.length - a.keywords.length)
  })

  return facets
})

// country: "us"
// device: "d"
// engine: "g"
// keyword_groups: (4) ["Daily - 5 (no keyword group)", "Daily - 4 (no keyword group)", "Daily - 5 (no keyword group)", "Daily - 4 (no keyword group)"]
// keyword_id: 495002
// keyword_source_ids: (2) [251175807554134, 228731335962834]
// language: "en"
// locale_id: 31874
// location: "Draper, Utah"
// location_id: 23350
// location_type: "City"
// phrase: "locksmith service"
