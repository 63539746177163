import moment from 'moment'
import * as React from 'react'
import { useForm } from 'react-form'
import { BiImport } from 'react-icons/bi'
import { FaInfoCircle, FaTimesCircle } from 'react-icons/fa'
import useConfirm from '../hooks/useConfirm'
import { useGoogleOptionQuery } from '../hooks/useGoogleOptionsQuery'
import { useGooglePhrasesQuery } from '../hooks/useGooglePhrases'
import { useSearchConsoleSites } from '../utils/gapi'
import useModalContext from '../hooks/useModalContext'
import { useTable } from '../hooks/useTable'
import { countryOptions } from '../options/countryOptions'
import { formatNumber, formatPercentage } from '../utils/Format'
import { forbiddenPhrases } from '../utils/Validate'
import Button from './Button'
import LabelWrap from './LabelWrap'
import { Modal, ModalContent, ModalTitle } from './Modals'
import QueryGate from './QueryGate'
import Select from './Select'
import SelectField from './SelectField'
import Table from './Table'
import { createCheckboxColumn } from './CheckboxColumn'
import Tooltip from './Tooltip'
import UsageTimeRange from './UsageTimeRange'

//

const defaultAddFormValues = {
  phrases: '',
  groups: [`GSC Import - ${moment().format('YYYY-MM-DD HH:mm')}`],
}

const deviceObjects = [
  {
    label: 'Desktop',
    value: 'DESKTOP',
  },
  {
    label: 'Mobile',
    value: 'MOBILE',
  },

  {
    label: 'Tablet',
    value: 'TABLET',
  },
]

export function GoogleSearchConsoleModal({
  onSubmit,
  groupOptions,
  allowMoreAdding = true,
}: {
  onSubmit: any
  groupOptions?: any
  allowMoreAdding?: boolean
}) {
  const [domain, setDomain] = React.useState()
  const [deviceFilter, setDeviceFilter] = React.useState(null)
  const [countryFilter, setCountryFilter] = React.useState(null)
  const [selectedRows, setSelectedRows] = React.useState<
    {
      phrase: string
      groups: string[]
    }[]
  >([])
  const minDate = moment.utc().subtract(3, 'month').startOf('day').unix()
  const maxDate = moment.utc().startOf('day').unix()
  const [timeRanges, setTimeRanges] = React.useState({
    start: minDate,
    end: maxDate,
  })
  const sitesQuery = useSearchConsoleSites()
  const countryOptionsQuery = useGoogleOptionQuery({
    option: 'COUNTRY',
    // @ts-expect-error  // Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
    domain,
    timeRanges,
    // @ts-expect-error  // Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
    country: countryFilter,
    // @ts-expect-error  // Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
    device: deviceFilter,
  })
  const deviceOptionsQuery = useGoogleOptionQuery({
    option: 'DEVICE',
    // @ts-expect-error  // Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
    domain,
    timeRanges,
    // @ts-expect-error  // Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
    country: countryFilter,
    // @ts-expect-error  // Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
    device: deviceFilter,
  })

  const { close } = useModalContext()

  const confirm = useConfirm()
  // @ts-expect-error  // Property 'handleSubmit' does not exist on type '{ ... Remove this comment to see the full error message
  const { Form, handleSubmit } = useForm({
    defaultValues: defaultAddFormValues,
    onSubmit: async values => {
      onSubmit(
        selectedRows.map(row => ({
          phrase: row.key,
          groups: [],
        })),
        values.groups,
        'GSC'
      )

      if (allowMoreAdding) {
        const confirmed = await confirm({
          title: 'Phrases Imported',
          color: 'green-500',
          message: `Would you like to continue selecting more keyword phrases to import?`,
          cancelText: "No. I'm done",
        })
        if (!confirmed) {
          close()
        }
      } else {
        close()
      }
    },
  })

  const domainOptions = React.useMemo(
    () =>
      sitesQuery.data?.map((site: any) => {
        return {
          label: site.siteUrl.includes('sc-domain:')
            ? `${site.siteUrl.split(':')[1]} (Domain)`
            : site.siteUrl,
          value: site.siteUrl,
        }
      }),
    [sitesQuery]
  )

  React.useEffect(() => {
    if (domainOptions && !domain) {
      setDomain(
        domainOptions?.find((option: any) =>
          option.value.includes('sc-domain:')
        )?.value || domainOptions[0].value
      )
    }
  }, [domain, domainOptions])

  const allCountryOptions = React.useMemo(
    () =>
      countryOptionsQuery.data?.result?.rows
        ?.map((option: any) => {
          const label = countryOptions.find(
            d => d.alpha3Code === option.keys[0]
          )?.label
          if (label) {
            return {
              label: `${label} (${formatNumber(option.clicks)} clicks)`,
              value: option.keys[0],
            }
          } else {
            return undefined
          }
        })
        .filter((option: any) => option !== undefined) || [],
    [countryOptionsQuery.data?.result?.rows]
  )

  const alpha3CountryOptions = React.useMemo(
    () =>
      countryOptionsQuery.data
        ? [{ label: 'All Countries', value: null }, ...allCountryOptions]
        : [],
    [countryOptionsQuery.data, allCountryOptions]
  )

  const allDeviceOptions =
    deviceOptionsQuery.data?.result?.rows
      ?.map((option: any) => {
        const label = deviceObjects.find(d => d.value === option.keys[0])?.label
        if (label) {
          return {
            label: `${label} (${formatNumber(option.clicks)} clicks)`,
            value: option.keys[0],
          }
        } else {
          return undefined
        }
      })
      .filter((option: any) => option !== undefined) || []
  const deviceOptions = [
    { label: 'All Devices', value: null },
    ...allDeviceOptions,
  ]

  return (
    <Modal id="searchConsoleImportModal">
      <ModalTitle>Import from Search Console</ModalTitle>
      <ModalContent>
        <div className="p-8">
          <div className="text-lg">
            Import Keyword Phrases{' '}
            <Tooltip
              tooltip={
                <div>
                  <FaInfoCircle className="inline" /> Import your keyword
                  phrases from Google Search Console by selecting them from the
                  table below. You can assign them all to a keyword group by
                  using the Keyword Group bar below.
                </div>
              }
            >
              <FaInfoCircle className="inline" />{' '}
            </Tooltip>
          </div>

          <div className="h-4" />
          <div className="flex items-end gap-2">
            <div className="text-lg font-bold">Filters:</div>
            <QueryGate query={sitesQuery}>
              {() => (
                <LabelWrap label="Property">
                  <Select
                    id="propertySelector"
                    placeholder="Select a property..."
                    options={domainOptions}
                    onChange={value => setDomain(value)}
                    value={domain}
                  />
                </LabelWrap>
              )}
            </QueryGate>

            <LabelWrap label="Country">
              <Select
                id="countrySelector"
                placeholder="Select a country..."
                options={alpha3CountryOptions}
                value={countryFilter}
                onChange={value => setCountryFilter(value)}
              />
            </LabelWrap>

            <LabelWrap label="Device">
              <Select
                id="deviceSelector"
                placeholder="Select a device..."
                options={deviceOptions}
                value={deviceFilter}
                onChange={value => setDeviceFilter(value)}
              />
            </LabelWrap>

            <UsageTimeRange
              timeRanges={timeRanges}
              setTimeRanges={setTimeRanges}
            />
          </div>

          <div className="h-4" />

          <Form>
            <div className="h-4" />
            {domain ? (
              <>
                <GoogleSearchConsoleTable
                  domain={domain}
                  setSelectedRows={setSelectedRows}
                  timeRanges={timeRanges}
                  device={deviceFilter}
                  country={countryFilter}
                />
                <div className="h-4" />

                <SelectField
                  field="groups"
                  id="groupsSelector"
                  label="Keyword Groups"
                  options={groupOptions}
                  multi
                  adjusting
                  create
                  placeholder="Assign your keyword phrases to a keyword group..."
                />
              </>
            ) : null}
            <div className="h-4" />

            <Button
              size="base"
              color="green-500"
              onClick={handleSubmit}
              className="mr-1"
              disabled={!selectedRows.length}
              id="importButton"
            >
              <BiImport className="inline" /> Import Keyword Phrases
            </Button>

            <Button
              size="base"
              color="gray-500"
              hoverColor="red-500"
              onClick={close}
            >
              <FaTimesCircle className="inline" /> Cancel
            </Button>
          </Form>
        </div>
      </ModalContent>
    </Modal>
  )
}

function GoogleSearchConsoleTable({
  domain,
  setSelectedRows,
  timeRanges,
  device,
  country,
}: any) {
  const phrasesQuery = useGooglePhrasesQuery({
    property: domain,
    timeRanges,
    device,
    country,
    groupBy: 'QUERY',
  })

  const data = React.useMemo(
    () =>
      (
        phrasesQuery.data?.map((row: any) => {
          return {
            key: row.keys[0],
            clicks: Number(row.clicks),
            ctr: Number(row.ctr),
            impressions: Number(row.impressions),
            position: Number(row.position),
          }
        }) ?? []
      ).filter(
        (row: any) =>
          !row.key.includes(';') &&
          !forbiddenPhrases.some(phrase => row.key.includes(phrase))
      ),
    [phrasesQuery.data]
  )

  const columns = React.useMemo(
    () => [
      createCheckboxColumn(),
      {
        header: 'Keyword Phrases (Queries)',
        accessorKey: 'key',
        filterFn: 'fuzzy',
        cell: (props: any) => (
          <div className="w-80 truncate">
            <Tooltip tooltip={props.getValue()}>{props.getValue()}</Tooltip>
          </div>
        ),
      },
      {
        header: 'Clicks',
        accessorKey: 'clicks',
        filterFn: 'inNumberRange',
        cell: (props: any) => (
          <div className="text-right">{formatNumber(props.getValue())}</div>
        ),
        sortDescFirst: true,
        meta: { tight: true },
      },
      {
        header: 'Impressions',
        accessorKey: 'impressions',
        filterFn: 'inNumberRange',
        cell: (props: any) => (
          <div className="text-right">{formatNumber(props.getValue())}</div>
        ),
        sortDescFirst: true,
        meta: { tight: true },
      },
      {
        header: 'CTR',
        filterFn: 'inNumberRange',
        accessorFn: (row: any) => row.ctr * 100,
        cell: (props: any) => (
          <div className="text-right">
            {formatPercentage(props.getValue() / 100)}
          </div>
        ),
        sortDescFirst: true,
        meta: { tight: true },
      },
      {
        header: 'Rank (Position)',
        accessorKey: 'position',
        filterFn: 'inNumberRange',
        cell: (props: any) => (
          <div className="text-right">
            {formatNumber(props.getValue(), { precision: 1 })}
          </div>
        ),
        meta: { tight: true },
        sortDescFirst: true,
        invertSorting: true,
      },
    ],
    []
  )

  const table = useTable({
    data,
    // @ts-expect-error  // Type '((ColumnDefBase<unknown, unknown> & StringHe... Remove this comment to see the full error message
    columns,
    initialState: {
      sorting: [{ id: 'clicks', desc: true }],
    },
  })

  const selectedRows = table.getSelectedRowModel().rows

  React.useEffect(() => {
    setSelectedRows(selectedRows.map(d => d.original))
  }, [selectedRows, setSelectedRows])

  return (
    <QueryGate query={phrasesQuery}>
      {() => (
        <div className="rounded-lg border border-gray-100 dark:border-gray-800">
          <Table
            table={table}
            className={phrasesQuery.isFetching ? 'opacity-30' : ''}
          />
          <div className="p-2">
            <span disabled={!selectedRows.length}>
              <strong>{formatNumber(selectedRows.length)}</strong> Phrases
              selected &nbsp;
            </span>
          </div>
        </div>
      )}
    </QueryGate>
  )
}
