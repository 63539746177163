import { captureException } from '@sentry/react'
import * as React from 'react'
import ErrorComp from '../components/Error'

class ErrorBoundaryRender extends React.Component {
  state = {
    error: false,
    info: false,
  }
  componentDidCatch(error: any, info: any) {
    // Sentry Integration
    const componentStack = info.componentStack
    const errorBoundaryError = new Error(error.message)
    errorBoundaryError.name = `React ErrorBoundary ${errorBoundaryError.name}`
    errorBoundaryError.stack = componentStack
    error.cause = errorBoundaryError
    captureException(error, { contexts: { react: { componentStack } } })

    this.setState({
      error,
      info,
    })
  }
  reset = () => {
    this.setState({
      error: false,
      info: false,
    })
  }
  render() {
    return (this.props.children as any)({ ...this.state, reset: this.reset })
  }
}

export default function ErrorBoundary(props: {
  children?: React.ReactNode
  small?: boolean
}) {
  return (
    <ErrorBoundaryRender>
      {(state: any) => {
        return (
          <ErrorBoundaryInner {...state} small={props.small}>
            {props.children ?? null}
          </ErrorBoundaryInner>
        )
      }}
    </ErrorBoundaryRender>
  );
}

function ErrorBoundaryInner(props: any) {
  React.useEffect(() => {
    window.addEventListener('pushstate', props.reset)
    window.addEventListener('popstate', props.reset)

    return () => {
      window.removeEventListener('pushstate', props.reset)
      window.removeEventListener('popstate', props.reset)
    }
  }, [props.reset])

  React.useEffect(() => {
    if (props.error) {
      console.error(props.error)
    }
  }, [props.error])

  if (props.error) {
    return (
      <div className="p-2">
        <ErrorComp
          {...{
            error: props.error,
            info: props.info,
            small: props.small,
            onRetry: props.reset,
          }}
        />
      </div>
    )
  }

  return props.children
}
