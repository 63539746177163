import preval from 'babel-plugin-preval/macro'
import type { Config } from 'tailwindcss'

type Color = string | LightDarkColor

type LightDarkColor = [string, string]

export type ColorifyOptions = {
  color: Color
  hoverColor?: Color
}

export type ColorifyColors = {
  color: LightDarkColor
  hoverColor: LightDarkColor
  lightColor: string
  darkColor: string
  hoverLightColor: string
  hoverDarkColor: string
}

//

export const twConfig = preval`
const config = require('../../tailwind.config.js')
const resolveConfig = require('tailwindcss/resolveConfig')
module.exports = resolveConfig(config)
` as Config & {
  theme: {
    colors: Record<string, Record<string, string>>
  }
}

export function colorify<T extends ColorifyOptions>(
  props: T
): [ColorifyColors, Omit<T, 'color' | 'hoverColor'>] {
  const { color: colorIn, hoverColor: hoverColorIn, ...rest } = props

  let color: LightDarkColor
  let hoverColor: LightDarkColor

  if (!Array.isArray(colorIn)) {
    if (colorIn) {
      color = [colorIn, colorIn]
    }
  } else {
    color = colorIn
  }

  if (!Array.isArray(hoverColorIn)) {
    if (hoverColorIn) {
      hoverColor = [hoverColorIn, hoverColorIn]
    }
  } else {
    hoverColor = hoverColorIn
  }

  // @ts-expect-error  // Variable 'color' is used before being assigned.
  const [lightColorCombined, darkColorCombined] = color

  // @ts-expect-error  // Variable 'color' is used before being assigned.
  const defaultHoverColor = color.map((color, index) => {
    const [bg, shade] = color.split('-')
    if (typeof shade === 'undefined') {
      return bg
    }
    return [
      bg,
      Math.max(100, Math.min(900, Number(shade) + (index ? -100 : 100))),
    ].join('-')
  })

  const [hoverLightColorCombined, hoverDarkColorCombined] =
    // @ts-expect-error  // Variable 'hoverColor' is used before being assigne... Remove this comment to see the full error message
    hoverColor ?? defaultHoverColor

  const [lightColorBase, lightColorShade = 500] = lightColorCombined.split('-')
  const [darkColorBase, darkColorShade = 500] = darkColorCombined.split('-')
  const [hoverLightColorBase, hoverLightColorShade = 500] =
    hoverLightColorCombined.split('-')
  const [hoverDarkColorBase, hoverDarkColorShade = 500] =
    hoverDarkColorCombined.split('-')

  const lightColor: string =
    // @ts-expect-error  // Type 'undefined' cannot be used as an index type.
    twConfig.theme.colors?.[lightColorBase]?.[lightColorShade]
  const darkColor: string =
    // @ts-expect-error  // Type 'undefined' cannot be used as an index type.
    twConfig.theme.colors?.[darkColorBase]?.[darkColorShade]
  const hoverLightColor: string =
    // @ts-expect-error  // Type 'undefined' cannot be used as an index type.
    twConfig.theme.colors?.[hoverLightColorBase]?.[hoverLightColorShade]
  const hoverDarkColor: string =
    // @ts-expect-error  // Type 'undefined' cannot be used as an index type.
    twConfig.theme.colors?.[hoverDarkColorBase]?.[hoverDarkColorShade]

  return [
    {
      // @ts-expect-error  // Variable 'color' is used before being assigned.
      color,
      // @ts-expect-error  // Variable 'hoverColor' is used before being assigne... Remove this comment to see the full error message
      hoverColor,
      lightColor,
      darkColor,
      hoverLightColor,
      hoverDarkColor,
    },
    rest,
  ]
}
