import * as React from 'react'
import { useForm } from 'react-form'
import { BiPlus } from 'react-icons/bi'
import {
  FaCheck,
  FaEdit,
  FaInfoCircle,
  FaPlus,
  FaSearch,
  FaTimesCircle,
  FaTrashAlt,
} from 'react-icons/fa'
import { multiSortBy, parsePropertyUrlUsername } from '../../utils'
import { makeAnyCondition, makeColumnCondition } from '../../utils/Conditions'
import {
  BRAND_PROPERTY_TYPE_AMAZON_STOREFRONT,
  BRAND_PROPERTY_TYPE_ANGELLIST,
  BRAND_PROPERTY_TYPE_CRUNCHBASE,
  BRAND_PROPERTY_TYPE_CUSTOM,
  BRAND_PROPERTY_TYPE_DOMAIN,
  BRAND_PROPERTY_TYPE_EBAY_STORE,
  BRAND_PROPERTY_TYPE_FACEBOOK,
  BRAND_PROPERTY_TYPE_FOURSQUARE,
  BRAND_PROPERTY_TYPE_GOOGLE_MY_BUSINESS,
  BRAND_PROPERTY_TYPE_INSTAGRAM,
  BRAND_PROPERTY_TYPE_LINKEDIN,
  BRAND_PROPERTY_TYPE_OWLER,
  BRAND_PROPERTY_TYPE_PINTEREST,
  BRAND_PROPERTY_TYPE_SUBDOMAIN,
  BRAND_PROPERTY_TYPE_TWITTER,
  BRAND_PROPERTY_TYPE_URL,
  BRAND_PROPERTY_TYPE_WALMART_MARKETPLACE,
  BRAND_PROPERTY_TYPE_YOUTUBE_CHANNEL,
  BRAND_PROPERTY_TYPE_YOUTUBE_VIDEO,
  BRAND_TYPE_COMPETITOR_DIRECT,
  BRAND_TYPE_OWNED,
} from '../../utils/Constants'
import { renderLimitedText } from '../../utils/Format'
import Validate, { validateDomain, validateNumber } from '../../utils/Validate'
//
import { Brand, BrandTypeEnum } from '../../../openapi'
import BrandSearchModal from '../../components/BrandSearchModal'
import Button from '../../components/Button'
import Caption from '../../components/Caption'
import Clickable from '../../components/Clickable'
import Condition from '../../components/Condition'
import Input from '../../components/Input'
import LabelWrap from '../../components/LabelWrap'
import Select from '../../components/Select'
import SelectField from '../../components/SelectField'
import Separator from '../../components/Separator'
import Spinner from '../../components/Spinner'
import {
  HeaderRow,
  TableCell,
  TableEl,
  TableHeader,
  TableRow,
  TableWrapInner,
  TableWrapOuter,
} from '../../components/Table'
import TextField from '../../components/TextField'
import YouTubeModal from '../../components/YouTubeModal'
import useConfirm from '../../hooks/useConfirm'
import useGetLatest from '../../hooks/useGetLatest'
import useModal from '../../hooks/useModal'
import usePauseEvents from '../../hooks/usePauseEvents'
import { brandTypeOptions } from '../../options/brandTypeOptions'
import {
  validateBrandName,
  validateBrandType,
  validateCondition,
} from '../../utils/Validate'

//

type Props = {
  onSubmit?: (brand: Brand) => void
  projectId?: string
  onChange?: (brand: Brand) => void
  brand?: Brand
  onRemove?: () => void
  showId?: boolean
  canRemove?: boolean
  onboardingType?: typeof BRAND_TYPE_OWNED | typeof BRAND_TYPE_COMPETITOR_DIRECT
  submittingText?: React.ReactNode
  submitText?: React.ReactNode
  showBrandModal?: boolean
  isModal?: boolean
  closeModal?: any
}

type PropertyType = {
  name: string
  tableName?: string
  type: string
  validate: (value?: any) => any
  quickAdd?: boolean
  manual?: boolean
  value: any
  placeholder?: string
  createCondition?: (value?: any) => unknown
}

export const propertyTypes: PropertyType[] = [
  {
    name: 'Domain',
    type: BRAND_PROPERTY_TYPE_DOMAIN,
    quickAdd: true,
    placeholder: 'Enter a Domain...',
    validate: validateDomain(),
    value: null,
    createCondition: value =>
      makeColumnCondition('result__url__domain', '=', [value]),
  },
  {
    name: 'Subdomain',
    type: BRAND_PROPERTY_TYPE_SUBDOMAIN,
    quickAdd: true,
    placeholder: 'Enter a Subdomain...',
    validate: Validate.required('A subdomain is required.'),
    value: null,
    createCondition: value =>
      makeColumnCondition('result__url__host', '=', [value]),
  },
  {
    name: 'URL',
    type: BRAND_PROPERTY_TYPE_URL,
    placeholder: 'Enter a URL...',
    validate: Validate.url(),
    value: null,
    createCondition: value =>
      makeColumnCondition('result__url__url', '^', [value]),
  },
  {
    name: 'Google My Business',
    type: BRAND_PROPERTY_TYPE_GOOGLE_MY_BUSINESS,
    quickAdd: true,
    value: null,
    placeholder: 'Enter a Google My Business ID (CID)...',
    validate: validateNumber('A valid ID is required.'),
    createCondition: value =>
      makeColumnCondition('result__local__local_id', '=', [value]),
  },
  {
    name: 'Twitter',
    type: BRAND_PROPERTY_TYPE_TWITTER,
    quickAdd: true,
    value: null,
    placeholder: 'Enter a Twitter username or URL...',
    validate: Validate.required('A username or URL is required.'),
    createCondition: value =>
      makeColumnCondition('result__url__url', '^', [
        `https://twitter.com/${parsePropertyUrlUsername(value)}`,
      ]),
  },
  {
    name: 'Facebook',
    type: BRAND_PROPERTY_TYPE_FACEBOOK,
    quickAdd: true,
    value: null,
    placeholder: 'Enter a Facebook username or URL...',
    validate: Validate.required('A username or URL is required.'),
    createCondition: value =>
      makeColumnCondition('result__url__url', '^', [
        `https://www.facebook.com/${parsePropertyUrlUsername(value)}`,
      ]),
  },
  {
    name: 'Instagram',
    type: BRAND_PROPERTY_TYPE_INSTAGRAM,
    quickAdd: true,
    value: null,
    placeholder: 'Enter an Instagram username or URL...',
    validate: Validate.required('A username or URL is required.'),
    createCondition: value =>
      makeColumnCondition('result__url__url', '^', [
        `https://www.instagram.com/${parsePropertyUrlUsername(value)}`,
      ]),
  },
  {
    name: 'LinkedIn',
    type: BRAND_PROPERTY_TYPE_LINKEDIN,
    quickAdd: true,
    value: null,
    placeholder: 'Enter a LinkedIn username or URL...',
    validate: Validate.required('A username or URL is required.'),
    createCondition: value =>
      makeColumnCondition('result__url__url', '^', [
        `https://www.linkedin.com/in/${parsePropertyUrlUsername(value)}`,
      ]),
  },
  {
    name: 'Pinterest',
    type: BRAND_PROPERTY_TYPE_PINTEREST,
    quickAdd: true,
    value: null,
    placeholder: 'Enter a Pinterest URL...',
    validate: Validate.required('A URL is required.'),
    createCondition: value =>
      makeColumnCondition('result__url__url', '^', [
        `https://www.pinterest.com/${parsePropertyUrlUsername(value)}`,
      ]),
  },

  {
    name: 'YouTube Channel',
    tableName: 'Youtube Channel',
    type: BRAND_PROPERTY_TYPE_YOUTUBE_CHANNEL,
    quickAdd: true,
    value: null,
    placeholder: 'Enter a YouTube username, channel ID or URL...',
    validate: Validate.required('A username, channel ID, or URL is required.'),
    createCondition: value =>
      makeColumnCondition(
        'result__url__url',
        '^',
        [
          'https://www.youtube.com/',
          'https://www.youtube.com/user/',
          'https://www.youtube.com/channel/',
          'https://www.youtube.com/c/',
          'https://www.youtube.com/@',
        ].map(d => `${d}${parsePropertyUrlUsername(value)}`)
      ),
  },
  {
    name: 'Import YouTube Videos',
    tableName: 'YouTube Video',
    type: BRAND_PROPERTY_TYPE_YOUTUBE_VIDEO,
    quickAdd: true,
    value: null,
    placeholder: 'Enter a YouTube username, channel ID or URL...',
    validate: Validate.required('A username, channel ID, or URL is required.'),
    createCondition: value =>
      makeColumnCondition('result__url__url', '^', [
        `https://www.youtube.com/watch?v=${parsePropertyUrlUsername(value)}`,
      ]),
    manual: true,
  },
  {
    name: 'Amazon Storefront',
    type: BRAND_PROPERTY_TYPE_AMAZON_STOREFRONT,
    placeholder: 'Enter an Amazon Storefront URL...',
    validate: Validate.amazon(),
    value: null,
    createCondition: value =>
      makeColumnCondition('result__url__url', '^', [value]),
  },
  {
    name: 'eBay Store',
    type: BRAND_PROPERTY_TYPE_EBAY_STORE,
    placeholder: 'Enter an Ebay Store URL...',
    validate: Validate.ebay(),
    value: null,
    createCondition: value =>
      makeColumnCondition('result__url__url', '^', [value]),
  },
  {
    name: 'Walmart Marketplace',
    type: BRAND_PROPERTY_TYPE_WALMART_MARKETPLACE,
    placeholder: 'Enter a Walmart Marketplace URL...',
    validate: Validate.walmart(),
    value: null,
    createCondition: value =>
      makeColumnCondition('result__url__url', '^', [value]),
  },
  {
    name: 'Owler',
    type: BRAND_PROPERTY_TYPE_OWLER,
    placeholder: 'Enter an Owler URL...',
    value: null,
    validate: Validate.required('A Username or URL is required.'),
    createCondition: value =>
      makeColumnCondition('result__url__url', '^', [value]),
  },
  {
    name: 'AngelList',
    type: BRAND_PROPERTY_TYPE_ANGELLIST,
    placeholder: 'Enter an AngelList URL...',
    value: null,
    validate: Validate.required('A Username or URL is required.'),
    createCondition: value =>
      makeColumnCondition('result__url__url', '^', [value]),
  },
  {
    name: 'Crunchbase',
    type: BRAND_PROPERTY_TYPE_CRUNCHBASE,
    placeholder: 'Enter a Crunchase URL...',
    value: null,
    validate: Validate.required('A Username or URL is required.'),
    createCondition: value =>
      makeColumnCondition('result__url__url', '^', [value]),
  },
  {
    name: 'FourSquare',
    type: BRAND_PROPERTY_TYPE_FOURSQUARE,
    placeholder: 'Enter a FourSquare URL...',
    value: null,
    validate: Validate.required('A Username or URL is required.'),
    createCondition: value =>
      makeColumnCondition('result__url__url', '^', [value]),
  },
  {
    name: 'Custom SERP/Result Condition',
    type: BRAND_PROPERTY_TYPE_CUSTOM,
    value: null,
    validate: value =>
      Validate.required('A condition is required')(value) ||
      validateCondition()(value),
    manual: true,
  },
]

const propertyOptions = propertyTypes.map(d => ({
  label: d.name,
  value: d,
}))

const defaultBrandValues: () => Brand = () => ({
  name: '',
  type: BrandTypeEnum.Owned,
  mode: 'simple',
  reputationImpact: 0,
  properties: [],
})

export default function BrandForm(props: Props) {
  const confirm = useConfirm()

  const defaultValues = React.useMemo(() => {
    const values = {
      ...defaultBrandValues(),
      ...(props.brand ?? {}),
    }

    return {
      ...values,
      type: values.type ?? BRAND_TYPE_OWNED,
      reputationImpact: values.reputationImpact ?? 0,
    }
  }, [props.brand])

  const showModal = useModal()

  usePauseEvents('brand', defaultValues.id, true)

  const form = useForm({
    defaultValues,

    // validate: values => {
    //   if (values.properties?.length === 0) {
    //     return 'At least one property is required'
    //   }
    //   if (values.properties?.find(property => !property.name)) {
    //     return 'All properties must have a valid name'
    //   }
    //   if (values.properties?.find(property => !property.condition)) {
    //     return 'All properties must have condition'
    //   }

    //   if (
    //     values.properties?.find(property =>
    //       validateCondition()(property.condition)
    //     )
    //   ) {
    //     return 'All properties must have a valid condition'
    //   }
    // },
    onSubmit: async (values: Brand) => {
      // @ts-expect-error  // Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      await props.onSubmit({
        ...values,
        reputationImpact: Number(values.reputationImpact),
        properties: multiSortBy(values.properties, [
          d =>
            d.type === BRAND_PROPERTY_TYPE_YOUTUBE_VIDEO
              ? Infinity
              : propertyTypes.findIndex(dd => dd.type === d.type),
        ]).map(property => {
          const propertyType = propertyTypes.find(d => d.type === property.type)

          return {
            ...property,

            condition: propertyType.createCondition
              ? propertyType.createCondition(property.value)
              : property.condition,
          }
        }),
      })
    },
  })

  const getForm = useGetLatest(form)
  const [addName, setAddName] = React.useState(true)

  const openBrandSearchModal = React.useCallback(() => {
    return showModal(() => (
      <BrandSearchModal
        onChange={async brandInfo => {
          const form = getForm()

          if (form.values.name && form.values.name !== brandInfo.name) {
            const confirmed = await confirm({
              title: `Overwrite Brand Name to ${brandInfo.name}?`,
              message: (
                <>
                  This will override your existing brand name of{' '}
                  <strong>{form.values.name}</strong> to be{' '}
                  <strong>{brandInfo.name}</strong>
                </>
              ),
            })
            if (!confirmed) {
              setAddName(false)
            }
          }

          if (addName) {
            form.setFieldValue('name', brandInfo.name)
          }

          brandInfo.properties.forEach(property => {
            form.pushFieldValue('properties', property)
            form.setFieldMeta('properties', old => ({
              ...old,
              isTouched: true,
            }))
          })
        }}
        cancelText={props.brand?.id ? 'Cancel' : 'Skip'}
      />
    ))
  }, [confirm, getForm, props.brand?.id, showModal, setAddName, addName])

  const openYoutubeModal = React.useCallback(() => {
    return showModal(() => (
      <YouTubeModal
        properties={form.values.properties}
        onSubmit={videos => {
          form.setFieldValue('properties', (old: any) => {
            return [
              ...(old ?? []),
              ...videos.map(video => {
                return {
                  name: `YouTube - ${video.snippet.title}`,
                  type: BRAND_PROPERTY_TYPE_YOUTUBE_VIDEO,
                  value: video.id.videoId,
                }
              }),
            ]
          })
        }}
      />
    ))
  }, [form, showModal])

  React.useEffect(() => {
    if (!props.brand?.id && props.showBrandModal) {
      const modal = openBrandSearchModal()

      return () => {
        modal.close()
      }
    }
  }, [props.brand?.id, openBrandSearchModal, props.showBrandModal])

  const keyPressed = (e: any) => {
    if (e.which === 13) {
      e.preventDefault()
    }
  }

  const reputationImpactOptions = React.useMemo(() => {
    return [
      { label: 'Very Negative', value: -2 },
      { label: 'Negative', value: -1 },
      { label: 'Neutral', value: 0 },
      { label: 'Positive', value: 1 },
      { label: 'Very Positive', value: 2 },
    ]
  }, [])

  return (
    <form.Form onKeyPress={keyPressed} className="space-y-4">
      <TextField
        label="Brand Name"
        field="name"
        placeholder="My Brand"
        validate={validateBrandName()}
        className="w-80 flex-none"
      />
      {form.values.id && props.showId ? (
        <TextField
          field="id"
          label="Brand ID"
          placeholder="-"
          disabled
          className="w-80 flex-none"
        />
      ) : null}
      <SelectField
        label="Brand Type"
        field="type"
        placeholder="Select a brand type..."
        options={brandTypeOptions}
        validate={validateBrandType()}
        className="w-80 flex-none"
      />
      <Caption>
        <FaInfoCircle className="inline" /> Most Dashboards default to showing
        "Owned" brand performance, however, you can create an unlimited number
        of brands for direct/indirect competitors, friendlies, or neutral
        entities at no extra cost.
      </Caption>
      <SelectField
        label="Reputation Impact"
        field="reputationImpact"
        placeholder="Select a reputation impact..."
        options={reputationImpactOptions}
        className="w-80 flex-none"
      />
      <LabelWrap
        label="Properties"
        error={
          // @ts-expect-error  // Property 'getFieldMeta' does not exist on type '{ ... Remove this comment to see the full error message
          form.getFieldMeta('properties')?.isTouched &&
          // @ts-expect-error  // Property 'getFieldMeta' does not exist on type '{ ... Remove this comment to see the full error message
          form.getFieldMeta('properties')?.error
        }
      >
        {!form.values.properties?.length ? (
          <>
            <Caption>
              <FaInfoCircle className="inline" /> Please add at least one
              property to your brand.
            </Caption>
            <div className="h-1" />
          </>
        ) : null}
        {form.values.properties?.length ? (
          <TableWrapOuter className="rounded border border-gray-200 dark:border-gray-700">
            <TableWrapInner>
              <TableEl>
                <HeaderRow className="bg-gray-500/10">
                  <TableHeader className="p-2">Type</TableHeader>
                  <TableHeader className="p-2">Name</TableHeader>
                  <TableHeader className="p-2">Configuration</TableHeader>
                  <TableHeader />
                </HeaderRow>
                <tbody>
                  {form.values.properties?.map((property, index) => {
                    const propertyType = propertyTypes.find(
                      d => d.type === property?.type
                    )

                    return (
                      <TableRow key={index}>
                        <TableCell tight={false}>
                          {propertyType.tableName ?? propertyType.name}
                        </TableCell>
                        <TableCell tight={false}>
                          <PropertyName
                            value={property.name}
                            onChange={(value: any) =>
                              form.setFieldValue(
                                `properties[${index}].name`,
                                value
                              )
                            }
                          />
                        </TableCell>
                        <TableCell tight={false}>
                          {(() => {
                            if (property.type === BRAND_PROPERTY_TYPE_CUSTOM) {
                              return (
                                <Condition
                                  {...{
                                    condition: property.condition,
                                    stage: 'result',
                                    onChange: (newCondition: any) =>
                                      form.setFieldValue(
                                        `properties[${index}].condition`,
                                        newCondition
                                      ),
                                    projectId: props.projectId,
                                    canRemove: true,
                                    transactional: false,
                                    error: validateCondition()(
                                      property.condition
                                    ),
                                  }}
                                />
                              )
                            }

                            return (
                              <TextField
                                field={`properties[${index}].value`}
                                placeholder={propertyType?.placeholder}
                                validate={propertyType?.validate}
                                className="w-full"
                              />
                            )
                          })()}
                        </TableCell>
                        <TableCell tight>
                          <Clickable
                            onClick={() =>
                              form.removeFieldValue('properties', index)
                            }
                            className="flex items-center gap-1 hover:!text-red-500"
                          >
                            <FaTimesCircle />
                            Remove
                          </Clickable>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </tbody>
              </TableEl>
            </TableWrapInner>
          </TableWrapOuter>
        ) : null}
        <div className="h-2" />
        <LabelWrap label="Add Common Properties">
          <div className="flex flex-wrap gap-2 rounded-xl border border-gray-200 p-2 dark:border-gray-700">
            {propertyTypes
              .filter(propertyType => propertyType.quickAdd)
              .map(propertyType => {
                return (
                  <Button
                    key={propertyType.type}
                    size="sm"
                    color={['gray-500', 'gray-700']}
                    onClick={() => {
                      if (
                        propertyType.type === BRAND_PROPERTY_TYPE_YOUTUBE_VIDEO
                      ) {
                        openYoutubeModal()
                        return
                      }
                      form.pushFieldValue('properties', propertyType)
                    }}
                    className="flex items-center gap-1"
                  >
                    <BiPlus />
                    <span>{propertyType.name}</span>
                  </Button>
                )
              })}
          </div>
        </LabelWrap>
        <div className="h-4" />
        <div className="flex flex-wrap gap-2">
          <Select
            options={propertyOptions}
            onChange={value => {
              if (value.type === BRAND_PROPERTY_TYPE_YOUTUBE_VIDEO) {
                openYoutubeModal()
                return
              }
              form.pushFieldValue('properties', value)
              form.setFieldMeta('properties', old => ({
                ...old,
                isTouched: true,
              }))
            }}
          >
            {({ onClick }: any) => (
              <Button size="sm" color="blue-500" onClick={onClick}>
                <FaPlus className="inline" /> Add Property
              </Button>
            )}
          </Select>
          <Button
            size="sm"
            color="blue-600"
            onClick={() => openBrandSearchModal()}
          >
            <FaSearch className="inline" /> Find Properties by Domain
          </Button>
        </div>
      </LabelWrap>
      <Separator />
      <div className="flex gap-1">
        <Button
          type="submit"
          size="base"
          color="green-500"
          disabled={!form.meta.canSubmit || !form.values.properties.length}
        >
          {form.meta.isSubmitting ? (
            <>
              <Spinner color="white" /> Saving Brand
            </>
          ) : (
            <>
              <FaCheck className="inline" />
              Save Brand
            </>
          )}
        </Button>{' '}
        {props.isModal ? (
          <Button
            size="base"
            color="gray-500"
            hoverColor="red-500"
            onClick={props.closeModal}
          >
            <FaTimesCircle /> Close
          </Button>
        ) : null}
        {props.brand?.id && (props.canRemove ?? true) ? (
          <Button
            size="base"
            color="gray-500"
            hoverColor="red-500"
            onClick={props.onRemove}
          >
            <FaTrashAlt className="inline" /> Remove Brand
          </Button>
        ) : null}
      </div>
    </form.Form>
  )
}

// form.setFieldValue(`properties.${row.index}.name`, value)

function PropertyName({ value: initialValue, onChange }: any) {
  const [isEditing, setIsEditing] = React.useState(false)
  const [value, setEditingValue] = React.useState(initialValue)

  React.useEffect(() => {
    setEditingValue(initialValue)
  }, [initialValue])

  const onCancel = () => {
    setEditingValue(initialValue)
    setIsEditing(false)
  }

  // React.useEffect(() => {
  //   if (!value) {
  //     setIsEditing(true)
  //   }
  // }, [value])

  const handleSubmit = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (!isEditing) {
      return
    }
    onChange(value)
    setIsEditing(false)
  }

  return (
    <form className="flex items-center justify-between">
      <div className="flex-auto">
        {isEditing ? (
          <Input
            value={value}
            // @ts-expect-error  // Property 'value' does not exist on type 'EventTarg... Remove this comment to see the full error message
            onChange={e => setEditingValue(e.target.value)}
            className="w-full"
          />
        ) : (
          renderLimitedText(initialValue, 40, { fromPercentage: 0.5 })
        )}
      </div>
      <div className="ml-2">
        {isEditing ? (
          <>
            <Clickable
              onClick={onCancel}
              className="px-2 text-xl leading-none text-red-700 hover:text-red-500"
            >
              <FaTimesCircle className="inline" />
            </Clickable>
            <Clickable
              onClick={handleSubmit}
              className="px-2 text-xl leading-none text-red-700 hover:text-green-500"
            >
              <FaCheck className="inline" />
            </Clickable>
          </>
        ) : (
          <div>
            <Clickable
              onClick={() => setIsEditing(true)}
              className="px-1 text-gray-500 hover:text-blue-500"
            >
              <FaEdit className="inline" />
            </Clickable>
          </div>
        )}
      </div>
    </form>
  )
}
