import * as React from 'react'
import { TopicPb, TopicsClient } from '../../../utils/proto'
import { createColumnHelper } from '@tanstack/react-table'
import { useTable } from '../../../hooks/useTable'
import Table from '../../../components/Table'
import {
  useBatchRemoveTopicsByIdMutation,
  useBatchUpdateTopicsMutation,
  useDeleteFromRelatedTopicsMutation,
  useRelatedTopicsQuery,
} from '../../../utils/topics'
import { FaTrashAlt, FaRegTrashAlt } from 'react-icons/fa'
import { ButtonPlain } from '../../../components/ButtonPlain'
import Spinner from '../../../components/Spinner'
import { formatNumber } from '../../../utils/Format'
import { useActiveWorkspaceId } from '../../../hooks/workspaces'
import useConfirm from '../../../hooks/useConfirm'
import { createCheckboxColumn } from '../../../components/CheckboxColumn'
import Clickable from '../../../components/Clickable'

export function RelatedTopicsTable({
  topicId,
  keywordIds,
  projectId,
  excludeTopicIds,
  onSelectKeywordIds,
  ...rest
}: Parameters<typeof useRelatedTopicsQuery>[0] &
  Omit<React.ComponentProps<typeof Table>, 'table'> & {
    topicId: undefined | string
    onSelectKeywordIds: (keywordIds: string[]) => void
  }) {
  const confirm = useConfirm()
  const workspaceId = useActiveWorkspaceId()

  const relatedTopicsQuery = useRelatedTopicsQuery({
    keywordIds,
    projectId,
    excludeTopicIds,
  })

  const tableData = React.useMemo(() => {
    return relatedTopicsQuery.data ?? []
  }, [relatedTopicsQuery.data])

  const columns = React.useMemo(() => {
    const relatedTopicsColumnHelper =
      createColumnHelper<(typeof tableData)[number]>()

    return [
      // relatedTopicsColumnHelper.accessor('name', {
      //   id: 'Actions',
      //   header: '',
      //   cell: () => {
      //     return (
      //       <>
      //         {/* <ButtonPlain className="text-xs">Merge To Topic</ButtonPlain>
      //                       <ButtonPlain className="text-xs">Merge From Topic</ButtonPlain> */}
      //       </>
      //     )
      //   },
      // }),
      createCheckboxColumn(),
      relatedTopicsColumnHelper.accessor('name', {
        header: 'Topic',
      }),
      relatedTopicsColumnHelper.accessor(d => d.commonKeywords.length, {
        header: 'Keywords in Common',
        cell: props => (
          <>
            <Clickable
              className="font-black text-blue-500"
              onClick={() => {
                onSelectKeywordIds(
                  props.row.original.commonKeywords.map(v =>
                    String(v.keywordId)
                  )
                )
              }}
            >
              {props.getValue()}
            </Clickable>{' '}
            / {props.row.original.mappedKeywords.length}
          </>
        ),
      }),
    ]
  }, [onSelectKeywordIds])

  const table = useTable({
    columns,
    data: tableData,
    counter: {
      showingName: 'topics',
    },
  })

  const selectedRows = table.getSelectedRowModel().flatRows

  const batchRemoveTopicsByIdMutation = useBatchRemoveTopicsByIdMutation()
  const batchUpdateTopicsMutation = useBatchUpdateTopicsMutation()
  const deleteFromRelatedTopicsMutation = useDeleteFromRelatedTopicsMutation()

  return (
    <Table
      table={table}
      {...rest}
      beforeTable={
        selectedRows.length ? (
          <div className="flex items-center divide-x divide-gray-500/20">
            <div className="px-3 py-2">
              <strong>{formatNumber(selectedRows.length)}</strong> items
              selected
            </div>
            <div className="flex flex-wrap items-center gap-2 px-3 py-2">
              <ButtonPlain
                className="border border-red-500 bg-transparent text-xs text-red-500"
                onClick={async () => {
                  if (
                    await confirm({
                      message: 'Are you sure you want to delete these topics?',
                    })
                  ) {
                    batchRemoveTopicsByIdMutation.mutateAsync({
                      workspaceId: BigInt(workspaceId),
                      projectId: BigInt(projectId),
                      topicIds: selectedRows.map(v => v.original.id),
                    })
                    table.resetRowSelection()
                  }
                }}
              >
                <FaTrashAlt /> Delete
              </ButtonPlain>

              <ButtonPlain
                className="bg-gray-500/20 text-xs text-black hover:bg-gray-500/30 dark:text-white"
                onClick={async () => {
                  if (
                    await confirm({
                      message:
                        'Are you sure you want to remove the common keywords from the selected related Topics?',
                    })
                  ) {
                    await deleteFromRelatedTopicsMutation.mutateAsync({
                      projectId,
                      topicId,
                      sourceKeywordIds: keywordIds,
                      deleteKeywordIds: selectedRows.map(v =>
                        String(v.original.id)
                      ),
                    })

                    table.resetRowSelection()
                  }
                }}
              >
                {deleteFromRelatedTopicsMutation.isLoading ? (
                  <>
                    <Spinner /> Taking Common Keywords
                  </>
                ) : (
                  <>
                    <FaRegTrashAlt /> Take Common Keywords
                  </>
                )}
              </ButtonPlain>
            </div>
          </div>
        ) : null
      }
    />
  )
}
