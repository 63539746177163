import * as React from 'react'
import { FaAngleRight, FaCircle, FaInfoCircle } from 'react-icons/fa'
import { twMerge } from 'tailwind-merge'
import { Dashboard } from '.'
import Card from '../../components/Card'
import ControlledQueryTable from '../../components/ControlledQueryTable'
import { Counter } from '../../components/Counter'
import DashboardContainer from '../../components/DashboardContainer'
import DashboardContent from '../../components/DashboardContent'
import DashboardHeader from '../../components/DashboardHeader'
import DashboardRollupGate from '../../components/DashboardRollupGate'
import SegmentIdGate from '../../components/SegmentIdGate'
import SegmentPicker from '../../components/SegmentPicker'
import DashboardProjectGate from '../../components/DashboardTeamGate'
import DashboardTimeRange from '../../components/DashboardTimeRange'
import ErrorBoundary from '../../components/ErrorBoundary'
import { ExpandableWidget } from '../../components/ExpandableWidget'
import { GroupByFiltersWidget } from '../../components/GroupByFiltersWidget'
import Head from '../../components/Head'
import Loader from '../../components/Loader'
import MetricInfo from '../../components/MetricInfo'
import Pager from '../../components/Pager'
import { PerformanceMetricsWidget } from '../../components/PerformanceMetricsWidget'
import RollupPicker, { useRollup } from '../../components/RollupPicker'
import {
  DisplayMode,
  TimeSeriesWidget,
  TimeSeriesWidgetMode,
} from '../../components/TimeSeriesWidget'
import Tooltip from '../../components/Tooltip'
import WidgetControls from '../../components/WidgetControls'
import WidgetControlsToggle from '../../components/WidgetControlsToggle'
import { WidgetStateResetter } from '../../components/WidgetStateResetter'
import useGetLatest from '../../hooks/useGetLatest'
import useGroupByOverTimeQuery from '../../hooks/useGroupByOverTimeQuery'
import { useGroupByOverTimeQueryOptions } from '../../hooks/useGroupByOverTimeQueryOptions'
import useSyncMetricsState from '../../hooks/useSyncMetricsState'
import useTimeRangeState from '../../hooks/useTimeRanges'
import useWidgetPaginationReset from '../../hooks/useWidgetPaginationReset'
import useZustand from '../../hooks/useZustand'
import { getDataValue, toggleArray } from '../../utils'
import {
  DEFAULT_SAMPLES,
  performanceByKeywordGroupSlug,
} from '../../utils/Constants'
import {
  MetricId,
  MetricPermutation,
  MetricPostAggregation,
  getMetricChangeFormatter,
  getMetricRenderer,
  metricPermutation,
  metricsById,
  renderMetricDrillthrough,
} from '../../utils/Metrics'
import { allQueryFacets, queryFacetsById } from '../../utils/queryFacets'
import { ProjectPicker } from '../../components/ProjectPicker'
import { useKeywordGroupOptionsQuery } from '../../hooks/keywords'
import {
  useMetricIdState,
  useSegmentIdState,
  useSyncMetricIdState,
} from '../../utils/searchParams'
import { useKeywordGroupState } from '../../utils/searchParams'
import { useSearchState } from '../../components/useSearchState'
import { TableDisplayMode } from '../../utils/tableDisplayMode'
import { GroupByQuerySeries } from '../../utils/Api'

//

const title = 'Performance by Keyword Group'

export const metricIds: MetricId[] = [
  'unique_keywords',
  'top_rank',
  'click_through_rate',
  'estimated_traffic',
  'top_pixels_from_top',
  'percentage_of_viewport',
  'percentage_of_dom',
  'results',
  'unique_urls',
  'ppc_value',
  'adwords_search_volume',
  'top_stat_base_rank',
]

export const performanceByKeywordGroupDashboard: Dashboard = {
  name: 'By Keyword Group',
  id: performanceByKeywordGroupSlug,
  icon: <FaCircle className="inline-block scale-75 transform text-xs" />,
}

export default function PerformanceByKeywordGroup() {
  const [rollupBy, rollupValue] = useRollup()

  const rollupFacet = allQueryFacets.find(d => d.id === rollupBy)

  const dashboardReadyGate = !rollupBy
    ? 'Please select a rollup.'
    : !rollupValue
    ? `Please select at least one ${rollupFacet?.label}. You can also change the Rollup to Domain and analyze data for any domain right now.`
    : null

  const [, setStore] = useZustand(state => state.helpUrl)
  const openDashDocs = () => {
    setStore(old => {
      old.showHelp = true
      old.helpUrl =
        'https://help.nozzle.io/performance-by-keyword-group-dashboard'

      return old
    })
  }

  return (
    <DashboardContainer>
      <Head>
        <title>{title}</title>
      </Head>
      <Card className="divide-y divide-gray-500/20 p-0 ">
        <div className="p-2">
          <DashboardHeader right={<DashboardTimeRange />}>
            <div className="flex flex-wrap items-center gap-2">
              <ProjectPicker />
              <RollupPicker allowDomainsAndUrls />
              <FaAngleRight className="inline" />
              <span>{title}</span>
              <button onClick={openDashDocs} type="button">
                <Tooltip tooltip="Click here to learn more about this dashboard">
                  <FaInfoCircle className="text-sm text-blue-500" />
                </Tooltip>
              </button>
            </div>
          </DashboardHeader>
        </div>
        {dashboardReadyGate ? null : (
          <div className="flex flex-wrap gap-4 p-2">
            <div className="flex flex-auto items-center gap-2">
              <SegmentPicker />
            </div>
          </div>
        )}
      </Card>
      <DashboardProjectGate>
        <DashboardRollupGate>
          <SegmentIdGate>
            {dashboardReadyGate ? (
              <Card>{dashboardReadyGate}</Card>
            ) : (
              <DashboardContent>
                <div className="col-span-full">
                  <ErrorBoundary>
                    <Metrics />
                  </ErrorBoundary>
                </div>
                <div className="col-span-full">
                  <ErrorBoundary>
                    <Filters />
                  </ErrorBoundary>
                </div>
                <Top />
                <Winners />
                <Losers />
                <div className="col-span-full">
                  <ErrorBoundary>
                    <KeywordGroupsTable />
                  </ErrorBoundary>
                </div>
                {/* 
                {/* {keywordGroups?.length === 1 ? (
                  <div className="col-span-full">
                    <ErrorBoundary>
                      <KeywordsWidget />
                    </ErrorBoundary>
                  </div>
                ) : null} */}
              </DashboardContent>
            )}
          </SegmentIdGate>
        </DashboardRollupGate>
      </DashboardProjectGate>
    </DashboardContainer>
  )
}

const queryFacet = queryFacetsById.keywordGroup

export type PerformanceByKeywordGroupMetricsWidget = {
  metricId: (typeof metricIds)[number]
  postAggregation: MetricPostAggregation
  samples: number
  limit: number | undefined
}

const useMetricsWidgetState = () => {
  const metricIdState = useMetricIdState(metricIds)

  const searchState = useSearchState<PerformanceByKeywordGroupMetricsWidget>({
    path: 'performanceByKeywordGroupMetricsWidget',
    useDefaultValue: () => {
      return {
        metricId: metricIdState.state!,
        samples: DEFAULT_SAMPLES,
        limit: 1,
        postAggregation: 'value',
      }
    },
  })

  useSyncMetricIdState(metricIds, searchState.state.metricId)

  return searchState
}

function Metrics() {
  const [rollupBy, rollupValue] = useRollup()
  const segmentIdState = useSegmentIdState()
  const keywordGroupState = useKeywordGroupState()
  const widgetState = useMetricsWidgetState()

  return (
    <PerformanceMetricsWidget
      {...{
        ...widgetState.state,
        isDirty: widgetState.isDirty,
        metricIds,
        summary: false,
        groupBy: rollupBy,
        rollupBy,
        rollupValues: [rollupValue],
        filters: {
          segmentIds: [segmentIdState.state],
          keywordGroups: [keywordGroupState.state],
        },
        setMetricsState: widgetState.setState as any,
        onReset: widgetState.reset,
      }}
    />
  )
}

export type PerformanceByKeywordGroupKeywordGroups = string[]

const useKeywordGroupsState = () => {
  return useSearchState<PerformanceByKeywordGroupKeywordGroups>({
    path: 'keywordGroups',
    useDefaultValue: () => [],
  })
}

export function Filters() {
  const optionsQuery = useKeywordGroupOptionsQuery()
  const keywordGroupsState = useKeywordGroupsState()

  return (
    <Card>
      <GroupByFiltersWidget
        filterValues={keywordGroupsState.state}
        labelPlural={queryFacet.labelPlural}
        optionsQuery={optionsQuery}
        onFilterValuesChange={keywordGroupsState.setState}
      />
    </Card>
  )
}

export type PerformanceByKeywordGroupTopWidget = {
  metricId: (typeof metricIds)[number]
  samples: number
  limit: number
  displayPostAggregation: MetricPostAggregation
  offset: number
  tableDisplayMode: TableDisplayMode
  sortByMetricPermutation: MetricPermutation<MetricId>
  desc: boolean
  expanded: boolean
  displayMode: DisplayMode
  widgetMode: TimeSeriesWidgetMode
  showLegends: boolean
}

const useTopWidgetState = () => {
  return useSearchState<PerformanceByKeywordGroupTopWidget>({
    path: 'performanceByKeywordGroupTopWidget',
    useDefaultValue: () => {
      const parentState = useMetricsWidgetState()

      return {
        metricId: parentState.state.metricId,
        samples: DEFAULT_SAMPLES,
        desc: true,
        limit: 5,
        offset: 0,
        displayPostAggregation: 'value',
        sortByMetricPermutation: metricPermutation(parentState.state.metricId),
        widgetMode: 'top',
        displayMode: 'summary',
        tableDisplayMode: 'summary',
        expanded: false,
        showLegends: false,
      }
    },
  })
}

function Top() {
  const [rollupBy, rollupValue] = useRollup()
  const segmentIdState = useSegmentIdState()
  const keywordGroupsState = useKeywordGroupsState()

  const metricsState = useMetricsWidgetState()
  const widgetState = useTopWidgetState()

  useWidgetPaginationReset(widgetState.state, widgetState.setState)
  useSyncMetricsState({
    parentState: metricsState.state,
    setState: widgetState.setState,
    syncMetric: true,
    syncSortByMetric: true,
    syncPostAggregations: false,
    syncSamples: true,
  })

  return (
    <ExpandableWidget expanded={widgetState.state.expanded}>
      <TimeSeriesWidget
        {...{
          title: 'Top',
          description: `The best performing results for a given metric as of the last datetime for the selected time range.`,
          groupBy: 'keywordGroup',
          rollupBy,
          rollupValues: [rollupValue],
          filters: {
            segmentIds: [segmentIdState.state],
            keywordGroups: keywordGroupsState.state,
          },
          metricIds,
          showWidgetMode: false,
          canTitleDrillThrough: false,
          canExpand: true,
          state: widgetState.state,
          setState: widgetState.setState,
          isDirty: widgetState.isDirty,
          onReset: widgetState.reset,
          onClickSerie: serie => {
            keywordGroupsState.setState(prev =>
              toggleArray(prev, [queryFacet.getSerieValue(serie)])
            )
          },
          renderSeriesLabel: serie => {
            return (
              <keywordGroupsState.Link
                value={prev =>
                  toggleArray(prev, [queryFacet.getSerieValue(serie)])
                }
              />
            )
          },
        }}
      />
    </ExpandableWidget>
  )
}

export type PerformanceByKeywordGroupWinnersWidget = {
  metricId: (typeof metricIds)[number]
  samples: number
  limit: number
  displayPostAggregation: MetricPostAggregation
  offset: number
  tableDisplayMode: TableDisplayMode
  sortByMetricPermutation: MetricPermutation<MetricId>
  desc: boolean
  expanded: boolean
  displayMode: DisplayMode
  widgetMode: TimeSeriesWidgetMode
  showLegends: boolean
}

const useWinnersWidgetState = () => {
  return useSearchState<PerformanceByKeywordGroupWinnersWidget>({
    path: 'performanceByKeywordGroupWinnersWidget',
    useDefaultValue: () => {
      const parentState = useMetricsWidgetState()

      return {
        metricId: parentState.state.metricId,
        samples: DEFAULT_SAMPLES,
        desc: true,
        limit: 5,
        offset: 0,
        displayPostAggregation: 'change',
        sortByMetricPermutation: metricPermutation(parentState.state.metricId, {
          postAggregation: 'change',
        }),
        widgetMode: 'winners',
        displayMode: 'summary',
        tableDisplayMode: 'summary',
        expanded: false,
        showLegends: false,
      }
    },
  })
}

function Winners() {
  const [rollupBy, rollupValue] = useRollup()
  const segmentIdState = useSegmentIdState()
  const keywordGroupsState = useKeywordGroupsState()

  const metricsState = useMetricsWidgetState()
  const widgetState = useWinnersWidgetState()

  useWidgetPaginationReset(widgetState.state, widgetState.setState)
  useSyncMetricsState({
    parentState: metricsState.state,
    setState: widgetState.setState,
    syncMetric: true,
    syncSortByMetric: true,
    syncPostAggregations: false,
    syncSamples: true,
  })

  return (
    <ExpandableWidget expanded={widgetState.state.expanded}>
      <TimeSeriesWidget
        {...{
          title: 'Winners',
          description: `The results with the most positive change for a given metric over the selected time range.`,
          groupBy: 'keywordGroup',
          rollupBy,
          rollupValues: [rollupValue],
          filters: {
            segmentIds: [segmentIdState.state],
            keywordGroups: keywordGroupsState.state,
          },
          metricIds,
          showWidgetMode: false,
          canTitleDrillThrough: false,
          canExpand: true,
          state: widgetState.state,
          setState: widgetState.setState,
          isDirty: widgetState.isDirty,
          onReset: widgetState.reset,
          onClickSerie: serie => {
            keywordGroupsState.setState(prev =>
              toggleArray(prev, [queryFacet.getSerieValue(serie)])
            )
          },
          renderSeriesLabel: serie => {
            return (
              <keywordGroupsState.Link
                value={prev =>
                  toggleArray(prev, [queryFacet.getSerieValue(serie)])
                }
              />
            )
          },
        }}
      />
    </ExpandableWidget>
  )
}

export type PerformanceByKeywordGroupLosersWidget = {
  metricId: (typeof metricIds)[number]
  samples: number
  limit: number
  displayPostAggregation: MetricPostAggregation
  offset: number
  tableDisplayMode: TableDisplayMode
  sortByMetricPermutation: MetricPermutation<MetricId>
  desc: boolean
  expanded: boolean
  displayMode: DisplayMode
  widgetMode: TimeSeriesWidgetMode
  showLegends: boolean
}

const useLosersWidgetState = () => {
  return useSearchState<PerformanceByKeywordGroupLosersWidget>({
    path: 'performanceByKeywordGroupLosersWidget',
    useDefaultValue: () => {
      const parentState = useMetricsWidgetState()

      return {
        metricId: parentState.state.metricId,
        samples: DEFAULT_SAMPLES,
        desc: false,
        limit: 5,
        offset: 0,
        displayPostAggregation: 'change',
        sortByMetricPermutation: metricPermutation(parentState.state.metricId, {
          postAggregation: 'change',
        }),
        widgetMode: 'losers',
        displayMode: 'summary',
        tableDisplayMode: 'summary',
        expanded: false,
        showLegends: false,
      }
    },
  })
}

function Losers() {
  const [rollupBy, rollupValue] = useRollup()
  const segmentIdState = useSegmentIdState()
  const keywordGroupsState = useKeywordGroupsState()

  const metricsState = useMetricsWidgetState()
  const widgetState = useLosersWidgetState()

  useWidgetPaginationReset(widgetState.state, widgetState.setState)
  useSyncMetricsState({
    parentState: metricsState.state,
    setState: widgetState.setState,
    syncMetric: true,
    syncSortByMetric: true,
    syncPostAggregations: false,
    syncSamples: true,
  })

  return (
    <ExpandableWidget expanded={widgetState.state.expanded}>
      <TimeSeriesWidget
        {...{
          title: 'Losers',
          description: `The results with the most negative change for a given metric over the selected time range.`,
          groupBy: 'keywordGroup',
          rollupBy,
          rollupValues: [rollupValue],
          filters: {
            segmentIds: [segmentIdState.state],
            keywordGroups: keywordGroupsState.state,
          },
          metricIds,
          showWidgetMode: false,
          canTitleDrillThrough: false,
          canExpand: true,
          state: widgetState.state,
          setState: widgetState.setState,
          isDirty: widgetState.isDirty,
          onReset: widgetState.reset,
          onClickSerie: serie => {
            keywordGroupsState.setState(prev =>
              toggleArray(prev, [queryFacet.getSerieValue(serie)])
            )
          },
          renderSeriesLabel: serie => {
            return (
              <keywordGroupsState.Link
                value={prev =>
                  toggleArray(prev, [queryFacet.getSerieValue(serie)])
                }
              />
            )
          },
        }}
      />
    </ExpandableWidget>
  )
}

export type PerformanceByKeywordGroupKeywordGroupsWidget = {
  metricId: (typeof metricIds)[number]
  samples: number
  limit: number
  displayPostAggregation: MetricPostAggregation
  offset: number
  tableDisplayMode: TableDisplayMode
  sortByMetricPermutation: MetricPermutation<MetricId>
  desc: boolean
  expanded: boolean
  displayMode: DisplayMode
  widgetMode: TimeSeriesWidgetMode
  showLegends: boolean
}

const useKeywordGroupsWidgetState = () => {
  return useSearchState<PerformanceByKeywordGroupKeywordGroupsWidget>({
    path: 'performanceByKeywordGroupKeywordGroupsWidget',
    useDefaultValue: () => {
      const parentState = useMetricsWidgetState()

      return {
        metricId: parentState.state.metricId,
        samples: DEFAULT_SAMPLES,
        desc: true,
        limit: 50,
        offset: 0,
        displayPostAggregation: 'value',
        sortByMetricPermutation: metricPermutation(parentState.state.metricId),
        widgetMode: 'top',
        displayMode: 'summary',
        tableDisplayMode: 'summary',
        expanded: false,
        showLegends: false,
      }
    },
  })
}

export function KeywordGroupsTable() {
  const timeRangeState = useTimeRangeState()
  const [rollupBy, rollupValue] = useRollup()
  const segmentIdState = useSegmentIdState()
  const keywordGroupsState = useKeywordGroupsState()

  const metricsState = useMetricsWidgetState()
  const widgetState = useKeywordGroupsWidgetState()

  useWidgetPaginationReset(widgetState.state, widgetState.setState)
  useSyncMetricsState({
    parentState: metricsState.state,
    setState: widgetState.setState,
    syncMetric: true,
    syncSortByMetric: true,
    syncPostAggregations: true,
    syncSamples: false,
  })

  const dataQueryOptions = useGroupByOverTimeQueryOptions({
    start: timeRangeState.state[0].start,
    end: timeRangeState.state[0].end,
    rollupBy,
    rollupValues: [rollupValue],
    groupBy: 'keywordGroup',
    filters: {
      segmentIds: [segmentIdState.state],
      keywordGroups: keywordGroupsState.state,
    },
    ...widgetState.state,
    metricPermutations: [
      ...metricIds.map(id => metricPermutation(id)),
      ...metricIds.map(d =>
        metricPermutation(d, {
          postAggregation: 'change',
        })
      ),
    ],
  })

  const renderSeriesLabel = React.useCallback(
    (serie: GroupByQuerySeries) => {
      return (
        <keywordGroupsState.Link
          value={prev => toggleArray(prev, [queryFacet.getSerieValue(serie)])}
        />
      )
    },
    [keywordGroupsState]
  )

  const dataQuery = useGroupByOverTimeQuery(dataQueryOptions)

  const { sortByMetricPermutation } = widgetState.state

  const [showControls, toggleControls] = React.useReducer(
    (state, action = !state) => action,
    false
  )

  const columns = React.useMemo(() => {
    const getIsActive = (metricId: MetricId, isChange: boolean) => {
      return (
        sortByMetricPermutation.id === metricId &&
        (isChange
          ? sortByMetricPermutation.postAggregation === 'change'
          : sortByMetricPermutation.postAggregation === 'value')
      )
    }

    const metricColumn = (metricId: MetricId, isChange: any) => {
      return {
        getHeaderProps: ({ className }: any) => ({
          className: twMerge(
            getIsActive(metricId, isChange) && 'font-bold',
            className
          ),
        }),
        getCellProps: ({ className }: any) => ({
          className: twMerge(
            getIsActive(metricId, isChange) && 'font-bold',
            className
          ),
        }),
      }
    }

    const getActiveMetricStyles = (metricId: MetricId, isChange: boolean) => {
      return getIsActive(metricId, isChange)
        ? {
            fontWeight: 'bold',
          }
        : {}
    }

    return [
      {
        id: 'circle',
        enableSorting: false,
        cell: (props: any) => {
          const value = queryFacet.renderSeriesLabel(
            props.row.original,
            [props.row.original],
            { short: true }
          )

          return (
            <Tooltip
              tooltip={value}
              getTooltipProps={() => ({ className: 'max-w-full' })}
            >
              <FaCircle
                style={{
                  color: props.row.original.color,
                }}
              />
            </Tooltip>
          )
        },
        tight: true,
        getHeaderProps: () => ({
          className: `sticky left-0 z-10`,
        }),
        getCellProps: () => ({
          className: `sticky left-0 z-10`,
        }),
      },
      {
        id: 'label',
        header: queryFacet.label,
        enableSorting: false,
        cell: (props: any) => {
          const value = queryFacet.renderSeriesLabel(
            props.row.original,
            [props.row.original],
            {
              short: true,
            }
          )

          if (renderSeriesLabel) {
            return React.cloneElement(renderSeriesLabel(props.row.original), {
              children: value,
            })
          }

          return value
        },
      },
      ...metricIds
        .map(metricId => {
          const metric = metricsById[metricId]

          return [
            {
              header: () => (
                <MetricInfo metricId={metricId}>{metric.label}</MetricInfo>
              ),
              id: metricId,
              accessorFn: (row: any) =>
                getDataValue(
                  row.data[row.data.length - 1],
                  metricPermutation(metricId, {
                    postAggregation: 'value',
                  })
                ),
              cell: (props: any) => (
                <div
                  style={{
                    ...getActiveMetricStyles(metricId, false),
                  }}
                  className="text-right"
                >
                  {renderMetricDrillthrough(
                    metricId,
                    queryFacet,
                    props.row.original,
                    props.getValue()
                  )(
                    getMetricRenderer(metricId)(props.getValue(), {
                      row: props.row.original,
                    })
                  )}
                </div>
              ),
              ...metricColumn(metricId, false),
            },
            {
              id: `${metricId}Change`,
              header: '+/-',
              accessorFn: (row: any): any =>
                getDataValue(
                  row.data[row.data.length - 1],
                  metricPermutation(metricId, {
                    postAggregation: 'change',
                  })
                ),
              cell: (props: any) => (
                <div
                  style={{
                    ...getActiveMetricStyles(metricId, true),
                  }}
                  className="text-right"
                >
                  {getMetricChangeFormatter(metricId)(props.getValue())}
                </div>
              ),
              ...metricColumn(metricId, true),
            },
          ]
        })
        .flat(),
    ]
  }, [
    renderSeriesLabel,
    sortByMetricPermutation.id,
    sortByMetricPermutation.postAggregation,
  ])

  return (
    <Card className="divide-y divide-gray-500/20 p-0">
      <div className="m-0 flex items-center gap-2 p-2 font-bold">
        <span>{queryFacet.labelPlural}</span>
        <div className="mr-auto">
          {dataQuery.isFetching ? <Loader /> : null}
        </div>
        <WidgetStateResetter
          {...{
            isDirty: widgetState.isDirty,
            onReset: widgetState.reset,
          }}
        />
        <WidgetControlsToggle
          showControls={showControls}
          onToggle={toggleControls}
        />
      </div>
      {showControls ? (
        <div className="p-2">
          <WidgetControls
            {...{
              dataQueryOptions,
              ...widgetState.state,
              setState: widgetState.setState,
              allowSamples: true,
              allowSorting: true,
              allowMetrics: true,
              allowPostAggregation: true,
              allowExport: true,
              allowLegends: true,
            }}
          />
        </div>
      ) : null}

      <ControlledQueryTable
        {...{
          ...widgetState.state,
          compact: true,
          dataQuery,
          metricIds,
          setState: widgetState.setState,
          columns,
        }}
      />
      <div className="p-1">
        <Counter
          {...{
            compact: true,
            isLoading: dataQuery.isFetching,
            count: dataQuery.data?.series?.length,
            totalCount: dataQuery.data?.rowCount,
            totalName: queryFacet.labelPlural,
          }}
        />
      </div>
      <div className="p-1">
        <Pager
          compact
          {...widgetState.state}
          size={dataQuery.data?.rowCount}
          setOffset={offset =>
            widgetState.setState(old => ({
              ...old,
              offset,
            }))
          }
          setLimit={limit =>
            widgetState.setState(old => ({
              ...old,
              limit,
            }))
          }
        />
      </div>
    </Card>
  )
}
