export const numberIsOptions = [
  {
    label: 'null',
    value: 'null',
  },
]

export const stringIsOptions = [
  ...numberIsOptions,
  {
    label: 'empty',
    value: 'empty',
  },
]

export const booleanIsOptions = [
  {
    label: 'True',
    value: true,
  },
  {
    label: 'False',
    value: false,
  },
  ...numberIsOptions,
]
