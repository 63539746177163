import { createColumnHelper } from '@tanstack/react-table'
import * as React from 'react'
import { FaPlus, FaPlusCircle, FaTimes, FaTrashAlt } from 'react-icons/fa'
import { VscWand } from 'react-icons/vsc'
import useGetLatest from '../hooks/useGetLatest'
import { useMostFrequentScheduleOptions } from '../hooks/useMostFrequentScheduleOptions'
import { useTable } from '../hooks/useTable'
import { Updater, functionalUpdate, uniqBy } from '../utils'
import { twMerge } from 'tailwind-merge'
import { ScheduleConfig } from '../utils/schedules'
import Clickable, { ClickablePlain } from './Clickable'
import Select from './Select'
import Table, { TableCell, TableEl, TableRow } from './Table'
import { Expander } from './ExpanderColumn'
import { SelectOption } from '../hooks/useSelect'
import { PlainInput } from './Input'
import {
  DataModel,
  DataModelEntry,
  DataModelEntryValue,
} from '../utils/dataModels'

//

type DataModelEntryPbWithPath = DataModelEntry & { path: string[] }

//

const columnHelper = createColumnHelper<DataModelEntry>()

export function DataModelInput({
  dataModel,
  value,
  onChange: userOnChange,
  schedules,
}: {
  dataModel: DataModel
  value: DataModel
  onChange?: (value: Updater<DataModel>) => void
  onBlur?: (e: any) => void
  schedules?: ScheduleConfig[]
}) {
  const autoResetPageIndexRef = React.useRef(true)
  const mostFrequentScheduleOptions = useMostFrequentScheduleOptions({
    schedules,
  })

  const getMostFrequentScheduleOptions = useGetLatest(
    mostFrequentScheduleOptions
  )

  const onChange = (updater: Updater<DataModelEntry[]>) => {
    userOnChange?.(prev => {
      const entries = functionalUpdate(updater, prev.entries)

      return {
        ...prev,
        entries,
      }
    })
  }

  // const getUpdateRow = useGetLatest(
  //   (path: number[], updater: Updater<DataModelEntry>) => {
  //     onChange?.(prev => {})
  //   }
  // )

  const hierarchyInfos = React.useMemo(() => {
    const infos = dataModel?.hierarchy.map((hierarchy, index) => {
      return {
        hierarchy,
        entries: [] as DataModelEntry[],
        childHierarchies: dataModel.hierarchy.slice(index + 1),
        parentHierarchies: dataModel.hierarchy.slice(0, index),
      }
    })

    return infos
  }, [dataModel.hierarchy])

  const entriesByPath = React.useMemo(() => {
    const byPath = {} as Record<string, DataModelEntry>

    const recurse = (entry: DataModelEntry, pathArr: string[]) => {
      const depth = pathArr.length
      pathArr = [...pathArr, entry.name]
      hierarchyInfos[depth]!.entries.push(entry)
      byPath[pathArr.join('__')] = entry
      if (entry.children?.length) {
        entry.children.forEach(d => recurse(d, pathArr))
      }
    }

    dataModel.entries.forEach(entry => recurse(entry, []))

    return byPath
  }, [dataModel.entries, hierarchyInfos])

  const tableRows = React.useMemo(() => value.entries || [], [value.entries])

  const modelOptions = React.useMemo(() => {
    const infos = dataModel?.hierarchy.map((h, index) => {
      return {} as Record<string, SelectOption<DataModelEntryPbWithPath>>
    })

    const recurse = (rows: DataModelEntry[], pathArr: string[]) => {
      const depth = pathArr.length

      const rowsByName = {} as Record<string, DataModelEntry>

      rows.forEach(d => {
        rowsByName[d.name] = d
        if (d.children) {
          recurse(d.children, [...pathArr, d.name])
        }
      })

      const options = !pathArr.length
        ? dataModel.entries
        : entriesByPath[pathArr.join('__')]?.children

      if (!options) {
        return
      }

      options.forEach(d => {
        const path = [...pathArr, d.name]

        if (!rowsByName[d.name]) {
          infos[depth]![path.join('__')] = {
            label: path.join(' - '),
            value: {
              ...d,
              path,
            } as DataModelEntryPbWithPath,
          }
        }
      })
    }

    recurse(tableRows, [])

    return infos.map(d => Object.values(d))
  }, [dataModel.entries, dataModel?.hierarchy, entriesByPath, tableRows])

  const getTableRows = useGetLatest(tableRows)

  const addBranchEntries = useGetLatest(
    (writePathArr: string[], entries: DataModelEntry[]) => {
      const writePath = writePathArr.join('__')
      autoResetPageIndexRef.current = false
      onChange?.(() => {
        const recurse = (
          rows: DataModelEntry[],
          pathArr: string[]
        ): DataModelEntry[] => {
          // Right depth, right path? Add entries
          if (writePath === pathArr.join('__')) {
            return uniqBy(
              [
                ...rows,
                ...entries.map(d => ({
                  name: d.name,
                  values: d.values,
                  path: [...pathArr, d.name],
                  children: [],
                })),
              ],
              d => d.name
            )
          }

          // Not the right depth? Recurse
          if (writePathArr.length > pathArr.length) {
            return rows.map(row => {
              const childPathArr = [...pathArr, row.name]

              return {
                ...row,
                children: row.children
                  ? recurse(row.children, childPathArr)
                  : undefined,
              }
            })
          }

          return rows
        }

        const newTableRows = recurse(getTableRows(), [])

        return newTableRows
      })
    }
  )

  const removeEntry = useGetLatest((writePathArr: string[]) => {
    const writePath = writePathArr.join('__')

    autoResetPageIndexRef.current = false
    onChange?.(_prev => {
      const recurse = (
        rows: DataModelEntry[],
        pathArr: string[]
      ): DataModelEntry[] => {
        if (writePathArr.length - 1 === pathArr.length) {
          return rows.filter(row => {
            return writePath !== [...pathArr, row.name].join('__')
          })
        }

        return rows.map(row => {
          const childPathArr = [...pathArr, row.name]

          return {
            ...row,
            children: row.children
              ? recurse(row.children, childPathArr)
              : undefined,
          }
        })
      }

      const newTableRows = recurse(getTableRows(), [])

      return newTableRows
    })
  })

  const removeEntryValue = useGetLatest(
    (writePathArr: string[], index: number) => {
      const writePath = writePathArr.join('__')

      autoResetPageIndexRef.current = false
      onChange?.(() => {
        const recurse = (
          rows: DataModelEntry[],
          pathArr: string[]
        ): DataModelEntry[] => {
          return rows.map(row => {
            const childPathArr = [...pathArr, row.name]

            if (childPathArr.length === writePathArr.length) {
              if (childPathArr.join('__') === writePath) {
                return {
                  ...row,
                  values: row.values.filter((d, i) => i !== index),
                }
              }

              return row
            }

            return {
              ...row,
              children: row.children
                ? recurse(row.children, childPathArr)
                : undefined,
            }
          })
        }

        const newTableRows = recurse(getTableRows(), [])

        return newTableRows
      })
    }
  )

  const addEntryValue = useGetLatest(
    (writePathArr: string[], value: DataModelEntryValue) => {
      const writePath = writePathArr.join('__')

      autoResetPageIndexRef.current = false
      onChange?.(() => {
        const recurse = (
          rows: DataModelEntry[],
          pathArr: string[]
        ): DataModelEntry[] => {
          return rows.map(row => {
            const childPathArr = [...pathArr, row.name]

            if (childPathArr.length === writePathArr.length) {
              if (childPathArr.join('__') === writePath) {
                return {
                  ...row,
                  values: [...row.values, value],
                }
              }

              return row
            }

            return {
              ...row,
              children: row.children
                ? recurse(row.children, childPathArr)
                : undefined,
            }
          })
        }

        const newTableRows = recurse(getTableRows(), [])

        return newTableRows
      })
    }
  )

  const updateValue = useGetLatest(
    (
      writePathArr: string[],
      index: number,
      updater: Updater<DataModelEntryValue>
    ) => {
      const writePath = writePathArr.join('__')
      autoResetPageIndexRef.current = false

      onChange?.(() => {
        const recurse = (
          rows: DataModelEntry[],
          pathArr: string[]
        ): DataModelEntry[] => {
          return rows.map(row => {
            const childPathArr = [...pathArr, row.name]

            if (childPathArr.length === writePathArr.length) {
              if (childPathArr.join('__') === writePath) {
                return {
                  ...row,
                  values: row.values.map((d, i) => {
                    if (i === index) {
                      return functionalUpdate(updater, d)
                    }

                    return d
                  }),
                }
              }

              return row
            }

            return {
              ...row,
              children: row.children
                ? recurse(row.children, childPathArr)
                : undefined,
            }
          })
        }

        const newTableRows = recurse(getTableRows(), [])

        return newTableRows
      })
    }
  )

  const addAllEntries = (index: number) => {
    autoResetPageIndexRef.current = false
    onChange?.(() => {
      const recurse = (
        rows: DataModelEntry[],
        pathArr: string[]
      ): DataModelEntry[] => {
        if (pathArr.length > index) {
          return rows
        }

        const entryChildren = !pathArr.length
          ? dataModel.entries
          : entriesByPath[pathArr.join('__')]?.children

        if (!entryChildren) {
          return rows
        }

        return uniqBy(
          [
            ...rows,
            ...entryChildren.map(d => ({
              name: d.name,
              values: d.values,
              path: [...pathArr, d.name],
              children: [],
            })),
          ],
          d => d.name
        ).map(row => {
          return {
            ...row,
            children: row.children
              ? recurse(row.children, [...pathArr, row.name])
              : undefined,
          }
        })
      }

      return recurse(tableRows, [])
    })
  }

  const addEntry = (index: number, entry: DataModelEntryPbWithPath) => {
    autoResetPageIndexRef.current = false
    onChange?.(() => {
      const recurse = (
        rows: DataModelEntry[],
        pathArr: string[]
      ): DataModelEntry[] => {
        const rowsByPath = {} as any

        if (index > pathArr.length) {
          rows = rows.map(row => {
            const childPathArr = [...pathArr, row.name]

            rowsByPath[row.name] = row

            return {
              ...row,
              children: row.children
                ? recurse(row.children, childPathArr)
                : undefined,
            }
          })
        }

        if (index === pathArr.length) {
          if (!rowsByPath[entry.name]) {
            rows.push({
              name: entry.name,
              values: entry.values,
              path: [...pathArr, entry.name],
              children: [],
            })
          }
        }

        return rows
      }

      return recurse(tableRows, [])
    })
  }

  const columns = React.useMemo(
    () => [
      ...(hierarchyInfos?.map((hierarchyInfo, infoIndex) => {
        return columnHelper.accessor(d => d.path[infoIndex], {
          id: hierarchyInfo.hierarchy.name,
          header: hierarchyInfo.hierarchy.name,
          filterFn: 'fuzzy',
          meta: {
            tight: true,
            getCellProps: () => ({
              className: 'p-0 min-w-[150px]',
            }),
          },
          cell: cellProps => {
            const { row } = cellProps

            const isRepeatedTopRow =
              row.id === cellProps.table.getPaginationRowModel().rows[0]?.id &&
              infoIndex < row.depth

            const isValue = cellProps.row.depth === infoIndex

            const isBranch = (() => {
              if (infoIndex === modelOptions.length - 1) {
                return false
              }

              if (!cellProps.row.getCanExpand()) {
                return false
              }

              return isValue
            })()

            const isBranchChild = (() => {
              return cellProps.row.depth + 1 === infoIndex
            })()

            const getValue = () => {
              const value = cellProps.getValue()

              if (isRepeatedTopRow) {
                return value
              }

              if (row.depth < infoIndex) {
                return ''
              }

              if (row.depth === infoIndex) {
                return value
              }

              if (row.depth > infoIndex) {
                return ''
              }

              return ''
            }

            const value = getValue()

            // eslint-disable-next-line react-hooks/rules-of-hooks
            const options = React.useMemo(() => {
              return (
                entriesByPath[row.original.path.join('__')]?.children?.map(
                  d => ({
                    label: d.name,
                    value: d,
                  })
                ) || []
              ).filter(
                d =>
                  !row.original.children?.find(
                    child => child.name === d.value.name
                  )
              )
            }, [row.original.children, row.original.path])

            return (
              <div className="flex items-center justify-between">
                <div>
                  {isBranch ? (
                    <ClickablePlain
                      className="flex items-center gap-1 p-1"
                      onClick={() => {
                        cellProps.row.toggleExpanded()
                      }}
                    >
                      <Expander expanded={cellProps.row.getIsExpanded()} />
                      {value}
                    </ClickablePlain>
                  ) : isBranchChild ? (
                    options.length ? (
                      <div className="flex items-center">
                        <Select
                          options={options}
                          onChange={next => {
                            addBranchEntries()(row.original.path, [next])
                          }}
                          className="flex px-2 py-1"
                        >
                          {({ onClick }: any) => (
                            <Clickable
                              onClick={onClick}
                              className="inline-flex items-center gap-1 text-sm !font-bold text-blue-500"
                            >
                              <FaPlus className="text-xs" /> Add{' '}
                              {hierarchyInfo.hierarchy.name}
                            </Clickable>
                          )}
                        </Select>
                        <div className="text-xs font-bold opacity-30">
                          ({row.original.children?.length} / {options.length})
                        </div>
                      </div>
                    ) : (
                      <div className="p-2 text-xs font-bold opacity-30">
                        ({row.original.children?.length})
                      </div>
                    )
                  ) : (
                    <div
                      className={twMerge(
                        'p-2',
                        isRepeatedTopRow && 'opacity-50'
                      )}
                    >
                      {value}
                    </div>
                  )}
                </div>
                {/* <PlainInput
                  enableDraft
                  value={value}
                  // onChange={e => {
                  //   getUpdateSubRow()(
                  //     cellProps.row.parentRow!.index,
                  //     cellProps.row.index,
                  //     d => ({
                  //       ...d,
                  //       value: e.target.value,
                  //     })
                  //   )
                  // }}
                  // placeholder="Enter an alias..."
                  className="w-full min-w-36 !opacity-100"
                  disabled
                /> */}
                <div>
                  {isValue ? (
                    <ClickablePlain
                      className="p-1 opacity-20 hover:text-red hover:opacity-100"
                      onClick={() => {
                        removeEntry()(row.original.path)
                      }}
                    >
                      <FaTimes />
                    </ClickablePlain>
                  ) : null}
                </div>
              </div>
            )
          },
        })
      }) || []),
      columnHelper.accessor(row => row.values, {
        header: 'Values',
        getUniqueValues: row => row.values.map(d => d.value),
        meta: {
          tight: true,
          getCellProps: () => {
            return {
              className: 'p-0',
            }
          },
        },
        cell: function Cell(cellProps) {
          const values = cellProps.getValue()
          // if (cellProps.getValue() === cellProps.row.original.name) {
          //   return null
          // }

          const valueOptions = React.useMemo(
            () =>
              entriesByPath[cellProps.row.original.path.join('__')]?.values ??
              [],
            [cellProps.row.original.path]
          )

          const visibleValueOptions = React.useMemo(() => {
            return valueOptions
              .filter(d => !values.find(value => value.value === d.value))
              .map(d => ({
                label: d.value,
                value: d,
              }))
          }, [valueOptions, values])

          return (
            <div className="flex items-stretch justify-between divide-x divide-gray-500/20">
              <div
                className={twMerge(
                  'w-full divide-y divide-gray-50 dark:divide-gray-800',
                  valueOptions.length === 1 && 'opacity-20'
                )}
              >
                {values.map((value, i) => {
                  return (
                    <div className="flex items-center justify-between">
                      <div
                        className={'space-between flex items-center gap-1 px-2'}
                      >
                        <div className="py-2">{value.value}</div>
                        {visibleValueOptions.length &&
                        i === values.length - 1 ? (
                          <Select
                            options={visibleValueOptions}
                            onChange={next => {
                              addEntryValue()(cellProps.row.original.path, next)
                            }}
                            className="flex"
                          >
                            {({ onClick }: any) => (
                              <Clickable onClick={onClick} className="p-1">
                                <FaPlusCircle />
                              </Clickable>
                            )}
                          </Select>
                        ) : null}
                      </div>
                      {i ? (
                        <ClickablePlain
                          className="p-1 opacity-20 hover:text-red hover:opacity-100"
                          onClick={() => {
                            removeEntryValue()(cellProps.row.original.path, i)
                          }}
                        >
                          <FaTimes />
                        </ClickablePlain>
                      ) : null}
                    </div>
                  )
                })}
              </div>
            </div>
          )
        },
      }),
      columnHelper.accessor(d => d.values.map(d => d.keywordGroups), {
        header: 'Keyword Groups',
        filterFn: 'arrIncludesAll',
        getUniqueValues: row => row.values.map(d => d.keywordGroups),
        meta: {
          tight: true,
          getCellProps: () => ({
            className: 'p-0',
          }),
        },
        cell: function Cell(cellProps) {
          const value = cellProps.getValue()

          return (
            <div className="flex items-stretch justify-between divide-x divide-gray-500/20">
              <div className={'divide-y divide-gray-500/20'}>
                {value.map((value, index) => {
                  return (
                    <Select
                      value={value}
                      enableDraft
                      // options={groupOptions}
                      multi
                      create
                      placeholder="Add keyword groups..."
                      className="min-w-56"
                      onChange={value => {
                        updateValue()(
                          cellProps.row.original.path,
                          index,
                          d => ({
                            ...d,
                            keywordGroups: value,
                          })
                        )
                      }}
                      inputProps={{
                        Input: PlainInput,
                      }}
                    />
                  )
                })}
              </div>
            </div>
          )
        },
      }),
      columnHelper.accessor(
        row =>
          row.values.map(
            value =>
              mostFrequentScheduleOptions.find(
                d => d.value === value.mostFrequentScheduleId
              )?.label
          ),
        {
          header: 'Most Frequent Schedule',
          filterFn: 'arrIncludesSome',
          meta: {
            tight: true,
            getCellProps: () => ({
              className: 'p-0',
            }),
          },
          cell: cellProps => {
            const values = cellProps.row.original.values.map(
              value => value.mostFrequentScheduleId
            )

            return (
              <div className="flex items-stretch justify-between divide-x divide-gray-500/20">
                <div className={'divide-y divide-gray-500/20'}>
                  {values.map((value, index) => {
                    return (
                      <Select
                        options={getMostFrequentScheduleOptions()}
                        value={value}
                        className="min-w-52"
                        placeholder="Select a schedule..."
                        onChange={value => {
                          updateValue()(
                            cellProps.row.original.path,
                            index,
                            d => ({
                              ...d,
                              mostFrequentScheduleId: value,
                            })
                          )
                        }}
                        inputProps={{
                          Input: PlainInput,
                        }}
                      />
                    )
                  })}
                </div>
              </div>
            )
          },
        }
      ),
      // columnHelper.display({
      //   header: 'Options',
      //   meta: {
      //     // tight: true,
      //     getHeaderProps: () => ({
      //       className: 'w-full flex justify-end',
      //     }),
      //   },
      //   cell: cellProps => {
      //     return (
      //       <div>
      //         <div className="flex justify-end gap-4">
      //           <div>
      //             {cellProps.row.depth === 0 ? (
      //               <Clickable
      //                 className="flex items-center gap-1 text-xs font-bold"
      //                 onClick={() => {
      //                   getUpdateRow()(cellProps.row.index, d => ({
      //                     ...d,
      //                     values: [
      //                       {
      //                         value: '',
      //                       },
      //                       ...(d.values ?? []),
      //                     ],
      //                   }))
      //                 }}
      //               >
      //                 <FaPlus /> Add Alias
      //               </Clickable>
      //             ) : null}
      //           </div>
      //           <Clickable
      //             onClick={() => {
      //               if (cellProps.row.depth === 0) {
      //                 return onChange?.(prev =>
      //                   prev.filter((_, i) => i !== cellProps.row.index)
      //                 )
      //               }

      //               getUpdateRow()(cellProps.row.parentRow!.index, d => {
      //                 const aliases = d.values?.filter(
      //                   (_, i) => i !== cellProps.row.index
      //                 )

      //                 return {
      //                   ...d,
      //                   aliases,
      //                 }
      //               })
      //             }}
      //           >
      //             <FaTimes className="opacity-30 hover:text-red-500 hover:opacity-100" />
      //           </Clickable>
      //         </div>
      //       </div>
      //     )
      //   },
      // }),
    ],
    [
      addBranchEntries,
      addEntryValue,
      entriesByPath,
      getMostFrequentScheduleOptions,
      hierarchyInfos,
      modelOptions.length,
      mostFrequentScheduleOptions,
      removeEntry,
      removeEntryValue,
    ]
  )

  const table = useTable({
    columns,
    data: tableRows,
    showToolbar: true,
    autoResetPageIndex: autoResetPageIndexRef.current,

    initialState: React.useMemo(() => {
      return {
        expanded: true,
        sorting: hierarchyInfos.map(d => {
          return {
            id: d.hierarchy.name,
            desc: false,
          }
        }),
      }
    }, [hierarchyInfos]),
    getSubRows: row => row.children,
  })

  React.useEffect(() => {
    autoResetPageIndexRef.current = true
  })

  // React.useEffect(() => {
  //   if (
  //     value.find(row => {
  //       return !getMostFrequentScheduleOptions().find(
  //         d => d.value === row.mostFrequentScheduleId
  //       )
  //     })
  //   ) {
  //     onChange?.(
  //       value.map(row => {
  //         if (
  //           getMostFrequentScheduleOptions().find(
  //             d => d.value === row.mostFrequentScheduleId
  //           )
  //         ) {
  //           return { ...row, mostFrequentScheduleId: null! }
  //         }

  //         return row
  //       })
  //     )
  //   }
  // }, [getMostFrequentScheduleOptions, schedules])

  return (
    <div className="space-y-2">
      <div className="text-xl font-bold">Makes, Models & Years</div>
      <div className="divide-y divide-gray-500/20 rounded border border-gray-200 dark:border-gray-800">
        <Table table={table} />
        <TableEl>
          <tbody>
            {hierarchyInfos.map((info, index) => {
              const options = modelOptions[index]!
              return (
                <TableRow>
                  <TableCell tight compact className="font-bold">
                    {info.hierarchy.name}
                  </TableCell>
                  <TableCell>
                    <Select
                      options={options}
                      onChange={next => {
                        addEntry(index, next)
                      }}
                      className="flex"
                    >
                      {({ onClick }: any) => (
                        <Clickable
                          onClick={onClick}
                          className="inline-flex items-center gap-1 text-sm !font-bold text-blue-500"
                        >
                          <FaPlus /> Add
                        </Clickable>
                      )}
                    </Select>
                  </TableCell>
                  <TableCell tight compact>
                    <Clickable
                      onClick={() => {
                        addAllEntries(index)
                      }}
                      className="inline-flex items-center gap-1 text-sm !font-bold text-blue-500"
                    >
                      <VscWand /> Add All ({options.length})
                    </Clickable>
                  </TableCell>
                  <TableCell tight compact>
                    <Clickable
                      onClick={() => {
                        const recurse = (
                          rows: DataModelEntry[],
                          depth = 0
                        ): DataModelEntry[] => {
                          if (depth === index) {
                            return []
                          }

                          return rows.map(row => {
                            if (row.children) {
                              row.children = recurse(row.children, depth + 1)
                            }
                            return row
                          })
                        }

                        onChange(recurse(tableRows))
                      }}
                      className="inline-flex items-center gap-1 text-sm !font-bold text-blue-500"
                    >
                      <FaTrashAlt /> Clear All
                    </Clickable>
                  </TableCell>
                  <TableCell />
                </TableRow>
              )
            })}
          </tbody>
        </TableEl>
      </div>
    </div>
  )
}
