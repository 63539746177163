import moment from 'moment'
import { useQuery } from 'react-query'
import { TimeRange } from './useTimeRanges'

export function useGoogleOptionQuery({
  option,
  domain,
  timeRanges,
  country,
  device,
  appearance,
}: {
  option: string
  domain: string
  timeRanges: TimeRange
  country?: string
  device?: string
  appearance?: string
}) {
  const dimensionFilterGroups = [{ filters: [] }]
  if (device && option !== 'DEVICE') {
    dimensionFilterGroups[0].filters.push({
      dimension: 'DEVICE',
      operator: 'EQUALS',
      expression: device,
    })
  }

  if (country && option !== 'COUNTRY') {
    dimensionFilterGroups[0].filters.push({
      dimension: 'COUNTRY',
      operator: 'EQUALS',
      expression: country,
    })
  }

  return useQuery(
    [
      'googleOptions',
      {
        option,
        domain,
        timeRanges,
        country,
        device,
        appearance,
      },
    ],
    async () => {
      if (domain) {
        // @ts-expect-error  // Property 'gapi' does not exist on type 'Window & t... Remove this comment to see the full error message
        return window.gapi.client.webmasters.searchanalytics.query({
          siteUrl: domain,
          resource: {
            startDate: moment.unix(timeRanges.start).format('YYYY-MM-DD'),
            endDate: moment.unix(timeRanges.end).format('YYYY-MM-DD'),
            rowLimit: 25000,
            dimensions: [option],

            dimensionFilterGroups,
          },
        })
      }
    },
    { keepPreviousData: true, enabled: !!domain && !!option }
  )
}
