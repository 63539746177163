import * as React from 'react'
import { usePager } from '../hooks/usePager'
import Clickable from './Clickable'
import Pager from './Pager'
import { Updater, functionalUpdate } from '../utils'
import { Counter } from './Counter'
import { formatNumber } from '../utils/Format'

export function ListInput<T>(props: {
  items: undefined | T[]
  onChange: (items: T[]) => void
  renderItem: (opts: {
    item: T
    setItems: (updater: Updater<T[]>) => void
  }) => React.ReactNode
  renderNoneFound: () => React.ReactNode
  renderAfterList?: (opts: {
    pager: ReturnType<typeof usePager>
    setItems: (updater: Updater<T[]>) => void
  }) => React.ReactNode
  initialPageSize?: number
}) {
  const items = React.useMemo(() => {
    return props.items || []
  }, [props.items])

  const pager = usePager({
    pageSize: props.initialPageSize ?? 5,
    size: items.length,
  })

  const pageValues = React.useMemo(() => {
    return pager.slice(items)
  }, [pager, items])

  const setItems = (updater: Updater<T[]>) => {
    const items = functionalUpdate(updater, props.items!)
    pager.setOffset(Math.ceil(items.length / pager.pageSize) - 1, true)
    props.onChange(items)
  }

  return (
    <div
      className="flex flex-col divide-y divide-gray-100 rounded  border border-gray-100
        text-sm dark:divide-gray-800 dark:border-gray-800
        "
    >
      {items.length ? (
        <>
          {pager.offset > 0 ? (
            <div className="flex justify-between">
              <Clickable
                className="px-2 py-1 opacity-50 hover:text-blue-500 hover:opacity-100 dark:text-white"
                onClick={() => {
                  pager.setOffset(pager.offset - 1)
                }}
              >
                ... {pager.pageSize * pager.offset} more
              </Clickable>
            </div>
          ) : (
            <div className="px-2 py-1 opacity-50">
              Showing {formatNumber(Math.min(pager.pageSize, items.length))} of{' '}
              {formatNumber(items.length)} total items
            </div>
          )}
          {pageValues.map(item => props.renderItem({ item, setItems }))}
          {pager.offset + 1 < pager.totalPages ? (
            <div className="flex justify-between">
              <Clickable
                className="px-2 py-1 opacity-50 hover:text-blue-500 hover:opacity-100 dark:text-white"
                onClick={() => {
                  pager.setOffset(pager.offset + 1)
                }}
              >
                ...{' '}
                {(pager.size ?? 0) -
                  (pager.offset * pager.pageSize + pager.pageSize)}{' '}
                more
              </Clickable>
            </div>
          ) : null}
        </>
      ) : (
        <div className="opacity-50">{props.renderNoneFound()}</div>
      )}
      {props.renderAfterList?.({
        pager,
        setItems,
      }) ?? null}
      {items.length > 5 ? (
        <div className="flex flex-wrap items-center justify-between">
          <Pager compact {...pager} className="p-1" />
          <Counter
            count={pageValues.length}
            totalCount={items.length}
            className="px-2 py-1"
          />
        </div>
      ) : null}
    </div>
  )
}
