import { Outlet, useSearch, useMatchRoute } from '@tanstack/react-router'
import { twMerge } from 'tailwind-merge'
import * as React from 'react'
import { BiArrowToLeft, BiArrowToRight } from 'react-icons/bi'
import { FaInfoCircle, FaTimes } from 'react-icons/fa'
import Banners from '../components/Banners'
import Modals from '../components/Modals'
import Popups from '../components/Popups'
import Toasts from '../components/Toasts'
import { useProfileQuery } from '../hooks/profile'
import useShowBanner from '../hooks/useShowBanner'
import useZustand from '../hooks/useZustand'
import { setAnalyticsUser } from '../utils/Analytics'
import { setDebugUserId } from '../utils/Api'
import { useFirebaseAuth } from '../utils/Firebase'
import LocalStorage from '../utils/LocalStorage'
import useToast from '../hooks/useToast'
import { router } from '../Router'

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('react-query/devtools/development').then(d => ({
    default: d.ReactQueryDevtools,
  }))
)

const TanStackRouterDevtoolsProduction = React.lazy(() =>
  import('@tanstack/router-devtools').then(d => ({
    default: d.TanStackRouterDevtools,
  }))
)

let _toggleDevtools = () => {
  //
}

export function toggleDevtools() {
  _toggleDevtools()
}

const setAppHeight = () => {
  const doc = document.documentElement
  doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}

setAppHeight()

export default function Root() {
  const [showDevtools, setShowDevtools] = React.useState(import.meta.env.DEV)
  const [showHelp, setStore] = useZustand(state => state.showHelp)
  const [maximizeHelp] = useZustand(state => state.maximizeHelp)
  const [iframeOn, setIframeOn] = React.useState(showHelp)
  const [helpUrl] = useZustand(state => state.helpUrl)

  React.useLayoutEffect(() => {
    if (showHelp) {
      setIframeOn(true)
    }
  }, [showHelp])

  // Calculate true viewport height
  React.useLayoutEffect(() => {
    window.addEventListener('resize', setAppHeight)
    setAppHeight()
  }, [])

  React.useEffect(() => {
    _toggleDevtools = () => setShowDevtools(old => !old)
  }, [])

  React.useEffect(() => {
    // Let the document know when the mouse is being used
    document.body.addEventListener('mousedown', function () {
      document.body.classList.add('using-mouse')
    })

    // Re-enable focus styling when Tab is pressed
    document.body.addEventListener('keydown', function (event) {
      if (event.key === 'Tab') {
        document.body.classList.remove('using-mouse')
      }
    })
  }, [])

  useFirebaseAuth()
  usePopState()
  useEnvBanner()
  useAnalyticsIdentification()

  return (
    <>
      <div className={twMerge(`relative flex h-[var(--app-height,_100vh)]`)}>
        <div className="relative flex max-w-full flex-1 flex-col overflow-x-hidden">
          <Banners />
          <div className="relative z-0 flex flex-auto flex-col overflow-hidden">
            <Outlet />
          </div>
          <Modals />
          <Popups />
          <Toasts />
        </div>
        <div className={twMerge(`relative`)}>
          <div
            className={twMerge(
              `
            h-full
            w-0 flex-col overflow-x-hidden shadow-xl
            transition duration-200 [transition-property:width]`,
              showHelp && `w-[400px]`,
              showHelp && maximizeHelp && `w-[50vw]`
            )}
          >
            {!iframeOn ? null : (
              <iframe
                key={helpUrl}
                src={helpUrl}
                title="Nozzle Help & Knowledgebase"
                className="h-full w-full min-w-[400px] flex-auto"
              />
            )}
          </div>
          <div
            className={twMerge(
              `absolute right-full top-1/2 -translate-y-1/2 translate-x-full transform p-2
          transition duration-200`,
              showHelp && `translate-x-1/2 p-0`
            )}
          >
            <button
              onClick={() =>
                setStore(old => {
                  old.maximizeHelp = !old.maximizeHelp
                })
              }
              className="w-full cursor-pointer rounded-t-full bg-white p-2 text-2xl shadow-lg transition
            duration-200 hover:bg-blue-500
            hover:text-white dark:bg-gray-800"
            >
              {maximizeHelp ? <BiArrowToRight /> : <BiArrowToLeft />}
            </button>
            <button
              onClick={() =>
                setStore(old => {
                  old.showHelp = false
                })
              }
              className="w-full cursor-pointer rounded-b-full bg-white p-3 shadow-lg transition
            duration-200 hover:bg-red-500
            hover:text-white dark:bg-gray-800"
            >
              <FaTimes />
            </button>
          </div>
        </div>
      </div>
      {showDevtools ? (
        <React.Suspense fallback={null}>
          <ReactQueryDevtoolsProduction
            toggleButtonProps={{
              style: {
                marginLeft: '3.5rem',
              },
            }}
          />
          <TanStackRouterDevtoolsProduction
            toggleButtonProps={{
              style: {
                marginLeft: '7rem',
              },
            }}
          />
        </React.Suspense>
      ) : null}
    </>
  )
}

function useAnalyticsIdentification() {
  const profileQuery = useProfileQuery()

  React.useEffect(() => {
    if (profileQuery.data) {
      setDebugUserId(profileQuery.data?.id)
      setAnalyticsUser(profileQuery.data)
    }
  }, [profileQuery.data])
}

function usePopState() {
  const search = useSearch()

  React.useEffect(() => {
    const popKeys = ['appsumoLicenseId', 'appsumoActivationEmail']

    popKeys.forEach(key => {
      if (search[key]) {
        LocalStorage.set(`pop_${key}`, search[key])
      }
    })
  }, [search])
}

let toasted = false

function useEnvBanner() {
  const toast = useToast()

  React.useEffect(() => {
    if (!toasted) {
      toasted = true
      if (window.location.host === 'dev.nozzle.io') {
        toast({
          color: 'yellow-500',
          message:
            'You are using a development preview of Nozzle. Tread lightly!',
        })
      }

      if (window.location.host === 'beta.nozzle.io') {
        toast({
          color: 'yellow-500',
          message:
            'You are using a beta preview of Nozzle. Enjoy the latest and greatest!',
        })
      }
    }
  }, [toast])
}
