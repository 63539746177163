import Card from '../../components/Card'
import { LocationGenerics } from '../../LocationGenerics'
import { useNavigate, useSearch } from '@tanstack/react-router'

import {
  FacetFilters,
  UiFilter,
  filtersPbFromSearch,
} from '../../components/FacetFilters'
import { functionalUpdate } from '../../utils'
import { queryKeyMonitoredUrlsFacets } from '../../utils/Constants'
import { useQuery } from 'react-query'
import {
  ListMonitoredUrlsFiltersPb,
  MonitoredUrlsClient,
} from '../../utils/proto'

// const filterOptions =

export function MonitoredUrlsFilters({
  workspaceId,
  projectId,
}: {
  workspaceId: string
  projectId: string
}) {
  const search = useSearch()
  const navigate = useNavigate()

  const facetsQuery = useQuery({
    queryKey: [queryKeyMonitoredUrlsFacets, search.monitoredUrlsFilters],
    enabled: Boolean(workspaceId && projectId),
    queryFn: () => {
      return MonitoredUrlsClient.listMonitoredUrlsFacets({
        workspaceId: BigInt(workspaceId),
        projectId: BigInt(projectId),
        filters: {
          ...filtersPbFromSearch<
            Record<
              string,
              ListMonitoredUrlsFiltersPb[keyof ListMonitoredUrlsFiltersPb]
            >
          >(monitoredUrlFiltersList, search.monitoredUrlsFilters || {}),
          search: (search.monitoredUrlsFilters?.search ?? '') as string,
        },
      })
    },
    retry: false,
    staleTime: Infinity,
    cacheTime: Infinity,
    keepPreviousData: true,
  })

  return (
    <Card className="flex h-full w-[20vw] min-w-[280px] max-w-[300px] flex-col divide-y divide-gray-500/20 overflow-auto overflow-y-hidden p-0">
      <FacetFilters
        facets={facetsQuery.data}
        filterState={search.monitoredUrlsFilters}
        filters={monitoredUrlFiltersList}
        isLoading={false}
        labelPlural="Monitored URLs"
        searchKey="search"
        onFilterStateChange={updater => {
          navigate({
            search: prev => ({
              ...prev,
              monitoredUrlsFilters: functionalUpdate(
                updater,
                prev.monitoredUrlsFilters
              ),
            }),
            replace: true,
          })
        }}
      />
    </Card>
  )
}

export const monitoredUrlFilters = {
  primarySearchIntent: {
    id: 'primarySearchIntent',
    label: 'Primary Search Intent',
    ui: 'multi-select',
    type: 'string',
    keys: ['includePrimarySearchIntents', 'excludePrimarySearchIntents'],
    facetKey: 'primarySearchIntents',
    showBins: true,
  },
  secondarySearchIntent: {
    id: 'secondarySearchIntent',
    label: 'Secondary Search Intent',
    ui: 'multi-select',
    type: 'string',
    keys: ['includeSecondarySearchIntents', 'excludeSecondarySearchIntents'],
    facetKey: 'secondarySearchIntents',
    showBins: true,
  },
  customerJourneyStage: {
    id: 'customerJourneyStage',
    label: 'Customer Journey Stage',
    ui: 'multi-select',
    type: 'string',
    keys: ['includeCustomerJourneyStages', 'excludeCustomerJourneyStages'],
    facetKey: 'customerJourneyStages',
    showBins: true,
  },
  priority: {
    id: 'priority',
    label: 'Priority',
    ui: 'multi-select',
    type: 'string',
    keys: ['includePriorities', 'excludePriorities'],
    facetKey: 'priorities',
    showBins: true,
  },
  brandId: {
    id: 'brandId',
    label: 'Brands',
    ui: 'multi-select',
    type: 'number',
    keys: ['includeBrandIds', 'excludeBrandIds'],
    facetKey: 'brandIds',
    showBins: true,
  },
  domainId: {
    id: 'domainId',
    label: 'Domains',
    ui: 'multi-select',
    type: 'number',
    keys: ['includeDomainIds', 'excludeDomainIds'],
    facetKey: 'domainIds',
    showBins: true,
  },
  urlId: {
    id: 'urlId',
    label: 'URLs',
    ui: 'multi-select',
    type: 'number',
    keys: ['includeUrlIds', 'excludeUrlIds'],
    facetKey: 'urlIds',
    showBins: true,
  },
} satisfies Record<string, UiFilter>

export const monitoredUrlFiltersList = [
  monitoredUrlFilters.primarySearchIntent,
  monitoredUrlFilters.secondarySearchIntent,
  monitoredUrlFilters.customerJourneyStage,
  monitoredUrlFilters.priority,
  monitoredUrlFilters.brandId,
  monitoredUrlFilters.domainId,
  monitoredUrlFilters.urlId,
]
