export type TableDisplayMode = 'summary' | 'history'

export const tableDisplayModeOptions = [
  {
    value: 'summary',
    label: 'Summary',
  },
  {
    value: 'history',
    label: 'History',
  },
] as const
