import { css, cx } from '@emotion/css'
import * as React from 'react'
import {
  FaChartBar,
  FaChartLine,
  FaCheck,
  FaSort,
  FaTimes,
} from 'react-icons/fa'
import { ImLoop2 } from 'react-icons/im'
import { twMerge } from 'tailwind-merge'
import { Updater } from '../utils'
import {
  DEFAULT_SAMPLES,
  POST_AGGREGATION_CHANGE,
  POST_AGGREGATION_VALUE,
} from '../utils/Constants'
import { MetricId, MetricPostAggregation, metricsById } from '../utils/Metrics'
import { ButtonPlain } from './ButtonPlain'
import Input from './Input'
import Loader from './Loader'
import Select from './Select'
import Tooltip from './Tooltip'
import NumberInput from './NumberInput'
import { NumberInputWrap } from './NumberInputWrap'

//

export function MetricsControls<TMetricId extends MetricId>({
  setState,
  metricIds,
  metricId,
  postAggregation,
  isLoading,
  samples,
  className,
  isDirty,
  onReset,
  ...rest
}: {
  setState: (
    updater: Updater<{
      metricId: TMetricId
      postAggregation: MetricPostAggregation
      samples: number
    }>
  ) => void
  metricIds: TMetricId[]
  isLoading?: boolean
  metricId: TMetricId | undefined
  postAggregation: MetricPostAggregation
  samples: number
  isDirty?: boolean
  onReset: () => void
} & React.HTMLAttributes<HTMLDivElement>) {
  const resetSamples = () => {
    setTimeout(() => {
      setState(old => ({
        ...old,
        samples: DEFAULT_SAMPLES,
      }))
    }, 100)
  }

  const metricOptions = React.useMemo(() => {
    return metricIds.map(id => ({ value: id, label: metricsById[id].label }))
  }, [metricIds])

  return (
    <div
      className={twMerge(`flex flex-wrap items-center gap-2`, className)}
      {...rest}
    >
      {metricIds?.length ? (
        <>
          <div className="flex flex-wrap">
            <Select
              options={metricOptions}
              value={metricId}
              onChange={metricId => {
                setState(old => ({
                  ...old,
                  metricId,
                }))
              }}
              className="inline-flex"
            >
              {({ onClick }: any) => (
                <Tooltip
                  tooltip={
                    <div>
                      The metric to <strong>"focus"</strong> or{' '}
                      <strong>"highlight"</strong> in this dashboard.
                      <div className="h-1" />
                      <hr className="opacity-50" />
                      <div className="h-1" />
                      <small className="opacity-70">
                        NOTE: This option can also be customized per-widget via
                        each widget's advanced options.
                      </small>
                    </div>
                  }
                >
                  <ButtonPlain
                    onClick={onClick}
                    className="rounded-r-none bg-gray-500/10 text-xs hover:bg-gray-500/20"
                  >
                    <FaSort className="inline" /> Metric:{' '}
                    {metricsById[metricId!]?.label ?? 'Select a metric...'}
                  </ButtonPlain>
                </Tooltip>
              )}
            </Select>
            <Tooltip
              tooltip={
                <div>
                  The post aggregation used for{' '}
                  <strong>sorting and displaying</strong> this dashboard's data.{' '}
                  <strong>"Value"</strong> sorts by the standard numerical value
                  of the metric. <strong>"Change"</strong> sorts by the total
                  change across your selected time range.
                  <div className="h-1" />
                  <hr className="opacity-50" />
                  <div className="h-1" />
                  <small className="opacity-70">
                    NOTE: This option can also be customized per-widget via each
                    widget's advanced options.
                  </small>
                </div>
              }
            >
              <ButtonPlain
                onClick={() =>
                  setState(old => {
                    const newAggregation =
                      postAggregation === POST_AGGREGATION_CHANGE
                        ? POST_AGGREGATION_VALUE
                        : POST_AGGREGATION_CHANGE

                    return {
                      ...old,
                      postAggregation: newAggregation,
                    }
                  })
                }
                className="rounded-l-none bg-gray-500/10 text-xs hover:bg-gray-500/20"
              >
                {postAggregation === POST_AGGREGATION_CHANGE ? (
                  <>
                    <FaChartLine className="inline" /> Change
                  </>
                ) : (
                  <>
                    <FaChartBar className="inline" /> Value
                  </>
                )}
              </ButtonPlain>
            </Tooltip>
          </div>
        </>
      ) : null}
      <div className="flex items-center justify-center gap-2 rounded-md bg-gray-500/10 hover:bg-gray-500/20">
        <Tooltip
          className="flex items-center justify-center"
          tooltip={
            <div>
              The number of samples to request per query.
              <div className="h-1" />
              <hr className="opacity-50" />
              <div className="h-1" />
              <small className="opacity-70">
                More samples{' '}
                <strong>increases the amount of data points shown</strong>{' '}
                (maxing out at the frequency your data is requested).
                <div className="h-2" />
                Fewer samples <strong>increases dashboard performance</strong>.
              </small>
            </div>
          }
        >
          <NumberInputWrap label="Samples" className="text-xs">
            <NumberInput
              enableDraft
              value={samples}
              placeholder={`(30)`}
              min={1}
              max={10000}
              onChange={num =>
                setState(old => ({
                  ...old,
                  samples: num,
                }))
              }
              className={twMerge(
                'm-px w-12 rounded-none border-0 p-1.5 text-xs leading-none',
                css`
                  &::-webkit-inner-spin-button,
                  &::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                  }
                `
              )}
            />
          </NumberInputWrap>
        </Tooltip>
        {samples !== DEFAULT_SAMPLES ? (
          <Tooltip
            tooltip={`Reset Samples (${DEFAULT_SAMPLES})`}
            className="flex items-center justify-center"
          >
            <button
              type="button"
              className="pr-2 opacity-50 hover:opacity-100"
              onClick={() => resetSamples()}
            >
              <ImLoop2 />
            </button>
          </Tooltip>
        ) : null}
      </div>
      {isDirty ? (
        <ButtonPlain className="bg-yellow-500" onClick={() => onReset()}>
          Reset
        </ButtonPlain>
      ) : null}
      {isLoading ? <Loader /> : null}
    </div>
  )
}
