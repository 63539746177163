import * as React from 'react'
import {
  TooltipAnchor,
  Tooltip as AriaTooltip,
  useTooltipStore,
  TooltipStoreProps,
} from '@ariakit/react'
import { twMerge } from 'tailwind-merge'
import { withProps } from '../utils/withProps'
import Card from './Card'
import { AnimatePresence, motion } from 'framer-motion'

const TooltipStyles = motion(
  withProps(Card)(({ className, ...props }) => ({
    ...props,
    className: twMerge(
      `z-[50] max-w-[50vw] break-words p-0 px-2 py-1 text-xs`,
      className
    ),
  }))
)

type Props = {
  tooltip: React.ReactNode
  children: React.ReactNode
  className?: any
  getTooltipProps?: () => Partial<
    React.ComponentPropsWithoutRef<typeof TooltipStyles>
  >
  placement?: TooltipStoreProps['placement']
  showTimeout?: TooltipStoreProps['showTimeout']
  skipTimeout?: TooltipStoreProps['skipTimeout']
}

export default function Tooltip({
  tooltip,
  children,
  getTooltipProps,
  placement,
  showTimeout,
  skipTimeout,
  ...rest
}: Props & React.HTMLAttributes<HTMLSpanElement>) {
  const store = useTooltipStore({
    showTimeout: showTimeout ?? 250,
    skipTimeout,
    placement,
  })
  const mounted = store.useState('mounted')

  // We move the tooltip up or down depending on the current placement.
  const y = store.useState(state => {
    const dir = state.currentPlacement.split('-')[0]!
    return dir === 'top' ? -8 : 8
  })

  return (
    <>
      <TooltipAnchor {...rest} store={store} render={<span />}>
        {children}
      </TooltipAnchor>
      <AnimatePresence>
        {mounted && (
          <AriaTooltip
            {...getTooltipProps?.()}
            store={store}
            wrapperProps={{
              className: 'pointer-events-none',
            }}
            // disablePointerEventsOnApproach={false}
            render={
              <TooltipStyles
                initial={{ opacity: 0, y }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y }}
              >
                {tooltip}
              </TooltipStyles>
            }
            alwaysVisible
          />
        )}
      </AnimatePresence>
    </>
  )
}
