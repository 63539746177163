import * as React from 'react'
import Checkbox from '../components/Checkbox'
import LabelWrap from '../components/LabelWrap'

//

const LabeledCheckbox = React.forwardRef(
  (
    {
      label,
      className,
      style = {},
      ...rest
    }: Omit<React.ComponentProps<typeof LabelWrap>, 'children'>,
    ref
  ) => (
    <LabelWrap
      label={
        <span className="inline-flex items-center gap-2">
          <Checkbox {...rest} ref={ref as any} className="w-full" />
          {label}
        </span>
      }
      className={className}
      style={style}
      error={rest.error}
      isValidating={rest.isValidating}
      children={null}
    />
  )
)

export default LabeledCheckbox
