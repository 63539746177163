import MeiliSearch, { Index } from 'meilisearch'
import * as React from 'react'
import { getAccessToken } from '../utils/Api'
import { useActiveWorkspaceId } from './workspaces'

export type MeiliSearchKeyword = {
  country: string
  device_code: string
  keyword_id: string
  keyword_source_names: string[]
  language: string
  locale_id: number
  location: string
  location_id: number
  location_type: string
  phrase: string
  project_id: string
  workspace_id: string
  workspace_id__project_id__keyword_id: string
}

export function useKeywordsSearchIndex() {
  const activeWorkspaceId = useActiveWorkspaceId()

  const [searchIndex, setSearchIndex] = React.useState<
    undefined | Index<MeiliSearchKeyword>
  >()

  const nearest5Minute = Math.floor(Date.now() / 300000) * 300000

  React.useEffect(() => {
    getAccessToken().then(token => {
      const client = new MeiliSearch({
        host: 'https://api.nozzle.io/meilisearch/keywords',
        apiKey: token,
        requestConfig: {
          headers: {
            'nz-workspace-id': String(activeWorkspaceId),
          },
        },
      })
      setSearchIndex(client.index('keywords'))
    })
  }, [nearest5Minute, activeWorkspaceId])

  return searchIndex
}
