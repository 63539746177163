import { useMatch, useNavigate, useSearch } from '@tanstack/react-router'
import { twMerge } from 'tailwind-merge'
import * as React from 'react'
import { FaAngleRight, FaCheck, FaTimes, FaTrash } from 'react-icons/fa'
import Card from '../../components/Card'
import { Head } from '../../components/Head'
import Select from '../../components/Select'
import { ButtonPlain } from '../../components/ButtonPlain'
import { ProjectPicker } from '../../components/ProjectPicker'

import {
  fetchTopicById,
  useRemoveTopicByIdMutation,
  useTopicQuery,
  useUpdateTopicMutation,
} from '../../utils/topics'
import { useActiveWorkspaceId } from '../../hooks/workspaces'
import {
  MappedKeywordPb,
  MappedUrlPb,
  MappedUrlsByBrandPb,
  PlainMessage,
  TopicPb,
  UpdateTopicResponsePb,
} from '../../utils/proto'
import QueryGate from '../../components/QueryGate'
import { useForm } from '@tanstack/react-form'
import { LimitDepth } from '../../utils/types'
import LabeledInput from '../../components/LabeledInput'
import LabelWrap from '../../components/LabelWrap'
import Validate from '../../utils/Validate'
import useConfirm from '../../hooks/useConfirm'
import { backToTopics } from './Topics'
import { BrandPicker } from '../../components/BrandPicker'
import InlineLabeledInput from '../../components/InlineLabeledInput'
import { MonitoredUrlPicker } from '../../components/MonitoredUrlPicker'
import { priorityOptions } from '../../options/priority'
import { TopicPicker } from '../../components/TopicPicker'
import { Modal, ModalContent, ModalTitle } from '../../components/Modals'
import useModalContext from '../../hooks/useModalContext'
import { BarButton, ButtonBar } from '../../components/ButtonBar'
import { TopicKeywordTable } from './components/TopicKeywordTable'
import { RelatedTopicsTable } from './components/RelatedTopicsTable'
import { Table } from '@tanstack/react-table'
import { KeywordWithLocale } from '../../hooks/keywords'
import Link from '../../components/Link'
import { TopicKeywords } from './components/TopicKeywords'
import { useActiveProjectIdState } from '../../utils/searchParams'

export type TopicTab =
  | 'manage'
  | 'keywords'
  | 'mappedUrLs'
  | 'phrases'
  | 'devices'
  | 'locations'
  | 'languages'
  | 'paa'
  | 'shareOfVoice'
  | 'serpFeatures'

export function Topic({ isNew }: { isNew?: boolean }) {
  const search = useSearch()
  const { topicId } = useMatch().params
  const activeWorkspaceId = useActiveWorkspaceId()
  const navigate = useNavigate()
  const topicQuery = useTopicQuery({
    workspaceId: activeWorkspaceId,
    projectId: search.projectId,
    topicId,
  })

  const updateTopicMutation = useUpdateTopicMutation()

  return (
    <div className="space-y-2 py-4">
      <Head>
        <title>
          Topic - {isNew ? 'New Topic' : topicQuery.data?.topic?.name ?? ''}
        </title>
      </Head>
      <div className="px-4">
        <Card className="flex flex-wrap items-center gap-2">
          <ProjectPicker />
          <FaAngleRight />
          {backToTopics}
          <FaAngleRight />
          {isNew ? (
            <div className="text-xl font-bold">Create Topic</div>
          ) : topicId ? (
            <QueryGate query={topicQuery} small>
              {() => (
                <div className="text-xl font-bold">
                  {topicQuery.data?.topic?.name}
                </div>
              )}
            </QueryGate>
          ) : null}
        </Card>
      </div>
      {!isNew ? (
        <div className="px-4">
          <ButtonBar>
            {(
              [
                {
                  id: 'manage',
                  label: 'Manage',
                  search: prev => ({ ...prev, topicTab: 'manage' }),
                },
                { id: 'mappedUrLs', label: 'Mapped URLs', disabled: true },
                {
                  id: 'keywords',
                  label: 'Keywords',
                  search: prev => ({ ...prev, topicTab: 'keywords' }),
                },
                { id: 'phrases', label: 'Phrases', disabled: true },
                { id: 'devices', label: 'Devices', disabled: true },
                { id: 'locations', label: 'Locations', disabled: true },
                { id: 'languages', label: 'Languages', disabled: true },
                { id: 'paa', label: 'PAA', disabled: true },
                { id: 'shareOfVoice', label: 'Share of Voice', disabled: true },
                { id: 'serpFeatures', label: 'SERP Features', disabled: true },
              ] satisfies {
                id: TopicTab
                label: string
                disabled?: boolean
                search?: (
                  prev: Partial<LocationGenerics['Search']>
                ) => Partial<LocationGenerics['Search']>
              }[]
            ).map(tab => {
              return (
                <Link key={tab.id} search={tab.search as any}>
                  <BarButton
                    disabled={tab.disabled}
                    className={twMerge(search.topicTab === tab.id && 'active')}
                  >
                    {tab.label}
                  </BarButton>
                </Link>
              )
            })}
            {/* <Tab>Overview</Tab> */}
          </ButtonBar>
        </div>
      ) : null}
      <div className="px-4">
        {isNew ? (
          <Card className="">
            <TopicForm
              onSubmit={async values => {
                const newTopicResponse = await updateTopicMutation.mutateAsync({
                  allowMissing: true,
                  topic: values,
                })

                navigate({
                  to: `/topics/${newTopicResponse.topic?.id}`,
                })
              }}
            />
          </Card>
        ) : search.topicTab === 'keywords' ? (
          <TopicKeywords topicId={topicId} projectId={search.projectId} />
        ) : (
          <Card className="">
            <QueryGate query={topicQuery}>
              {() => (
                <TopicForm
                  defaultValues={topicQuery.data?.topic}
                  onSubmit={values => {
                    updateTopicMutation.mutate({
                      topic: values,
                    })
                  }}
                />
              )}
            </QueryGate>
          </Card>
        )}
      </div>
    </div>
  )
}

export function TopicForm(props: {
  defaultValues?: Partial<PlainMessage<TopicPb>>
  onSubmit: (values: Partial<PlainMessage<TopicPb>>) => void
  onCancel?: () => void
}) {
  const search = useSearch()
  const activeWorkspaceId = useActiveWorkspaceId()!
  const projectId = search.projectId!
  const removeTopicByIdMutation = useRemoveTopicByIdMutation()
  const confirm = useConfirm()
  const navigate = useNavigate()

  const form = useForm<LimitDepth<TopicPb>>({
    defaultValues: React.useMemo(
      () =>
        new TopicPb({
          name: '',
          mappedUrlsByBrand: [
            new MappedUrlsByBrandPb({
              primaryMappedUrl: new MappedUrlPb({}),
            }),
          ],
          mappedKeywords: [],
          ...props.defaultValues,
          workspaceId: BigInt(activeWorkspaceId),
          projectId: BigInt(projectId),
        }) as any,
      [activeWorkspaceId, projectId, props.defaultValues]
    ),
    onSubmit: values => {
      props.onSubmit({
        ...values,
        mappedUrlsByBrand: values.mappedUrlsByBrand.filter(d => {
          return d.primaryMappedUrl?.monitoredUrlId
        }),
      })
    },
  })

  const deleteTopic = async () => {
    if (
      props.defaultValues?.id &&
      (await confirm({
        message: `Are you sure you want to remove the topic "${props.defaultValues?.name}"?`,
      }))
    ) {
      await removeTopicByIdMutation.mutateAsync({
        workspaceId: BigInt(activeWorkspaceId),
        projectId: BigInt(projectId),
        topicId: props.defaultValues?.id,
      })

      navigate({
        to: '/topics',
      })
    }
  }

  const tableRef = React.useRef<Table<KeywordWithLocale>>(null!)

  return (
    <form.Form className="space-y-2">
      <div className="flex flex-wrap gap-2">
        <div className="min-w-0 flex-[2] space-y-2">
          <form.Field
            name="name"
            validate={Validate.required()}
            children={field => {
              return (
                <LabeledInput
                  label="Topic Name"
                  placeholder="e.g. My Topic"
                  error={field.state.meta.touchedError}
                  {...field.getInputProps({
                    // @ts-ignore
                    required: true,
                  })}
                />
              )
            }}
          />
          <form.Field
            name="parentTopicId"
            children={field => {
              const value = form.useField({
                name: 'id',
              })

              return (
                <TopicPicker
                  projectId={projectId}
                  label="Parent Topic"
                  error={field.state.meta.touchedError}
                  className="flex-1"
                  value={field.getValue() ? String(field.getValue()) : ''}
                  onChange={value => field.setValue(BigInt(value))}
                  excludeTopicIds={[String(value)]}
                  allowEmpty
                  // children={({ onClick }) => {
                  //   return (
                  //     <LabelWrap
                  //     >
                  //       <ButtonPlain className="bg-transparent text-left text-sm font-bold lowercase text-black">
                  //         {field.getValue() || 'Select a Parent Monitored URL...'}
                  //       </ButtonPlain>
                  //     </LabelWrap>
                  //   )
                  // }}
                />
              )
            }}
          />
          <form.Field
            name="priority"
            validate={Validate.required()}
            children={field => {
              return (
                <Select
                  {...field.getChangeProps()}
                  options={priorityOptions}
                  label="Priority"
                  // value={field.getValue()}
                  error={field.state.meta.touchedError}
                />
              )
            }}
          />
          <form.Field
            name="mappedUrlsByBrand"
            children={mappedUrlsByBrandField => {
              return (
                <LabelWrap
                  label={'Mapped URLs'}
                  error={mappedUrlsByBrandField.state.meta.touchedError}
                >
                  <div className="space-y-2 rounded border border-gray-500/20 p-2 text-sm">
                    <mappedUrlsByBrandField.Field
                      index={0}
                      children={mappedUrlByBrandField => {
                        return (
                          <>
                            <mappedUrlByBrandField.Field
                              name="brandId"
                              children={brandIdField => {
                                return (
                                  <BrandPicker
                                    label="Brand"
                                    error={brandIdField.state.meta.touchedError}
                                    CustomInput={InlineLabeledInput}
                                    className="flex flex-1"
                                    value={
                                      brandIdField.getValue()
                                        ? String(brandIdField.getValue())
                                        : ''
                                    }
                                    onChange={value =>
                                      brandIdField.setValue(BigInt(value))
                                    }
                                  />
                                )
                              }}
                            />
                            <mappedUrlByBrandField.Field
                              name="primaryMappedUrl.monitoredUrlId"
                              children={monitoredUrlIdField => {
                                return (
                                  <MonitoredUrlPicker
                                    projectId={projectId}
                                    filters={
                                      mappedUrlByBrandField.getValue()?.brandId
                                        ? {
                                            includeBrandIds: [
                                              BigInt(
                                                mappedUrlByBrandField.getValue()!
                                                  .brandId
                                              ),
                                            ],
                                          }
                                        : undefined
                                    }
                                    label="Monitored URL"
                                    error={
                                      monitoredUrlIdField.state.meta
                                        .touchedError
                                    }
                                    CustomInput={InlineLabeledInput}
                                    className="flex flex-1"
                                    value={
                                      monitoredUrlIdField.getValue()
                                        ? String(monitoredUrlIdField.getValue())
                                        : ''
                                    }
                                    onChange={value =>
                                      monitoredUrlIdField.setValue(
                                        BigInt(value)
                                      )
                                    }
                                    disabled={
                                      !mappedUrlByBrandField.getValue()?.brandId
                                    }
                                  />
                                )
                              }}
                            />
                          </>
                        )
                      }}
                    />
                  </div>

                  {/* <MonitoredUrlsInput
                projectId={projectId.toString()}
                monitoredUrlIds={mappedUrlsByBrand.map(
                  mappedUrlByBrand =>
                    mappedUrlByBrand.monitoredUrl?.id.toString() ?? ''
                )}
                onChange={next => {
                  field.setValue(
                    next.map(d => {
                      return new MappedUrlPb({
                        monitoredUrl: new MonitoredUrlPb({
                          id: BigInt(d),
                        }),
                      })
                    })
                  )
                }}
              /> */}
                </LabelWrap>
              )
            }}
          />
          <form.Field
            name="mappedKeywords"
            children={function Keywords(field) {
              const keywords = field.getValue()

              return (
                <LabelWrap
                  label={'Mapped Keywords'}
                  error={field.state.meta.touchedError}
                >
                  <div className="rounded border border-gray-500/20">
                    <TopicKeywordTable
                      tableRef={tableRef}
                      topicId={String(props.defaultValues?.id)}
                      projectId={projectId.toString()}
                      keywordIds={keywords.map(d => d.keywordId.toString())}
                      initialPageSize={20}
                      onChange={next => {
                        field.setValue(
                          next.map(d => {
                            return new MappedKeywordPb({
                              keywordId: BigInt(d),
                            }) as any
                          })
                        )
                      }}
                    />
                  </div>
                </LabelWrap>
              )
            }}
          />
        </div>
        <div className="w-full space-y-2 xl:w-auto xl:flex-none">
          <LabelWrap className="" label="Related Topics">
            <div className="rounded border border-gray-500/20">
              <form.Subscribe
                selector={d =>
                  d.values.mappedKeywords.map(d => String(d.keywordId))
                }
                children={keywordIds => {
                  return (
                    <RelatedTopicsTable
                      projectId={projectId}
                      topicId={String(props.defaultValues?.id)}
                      keywordIds={keywordIds}
                      excludeTopicIds={[String(props.defaultValues?.id ?? '')]}
                      onSelectKeywordIds={keywordIds => {
                        tableRef.current?.setRowSelection(
                          Object.fromEntries(
                            keywordIds
                              .map(keywordId => {
                                return [
                                  tableRef.current
                                    ?.getRowModel()
                                    .flatRows.find(row => {
                                      return (
                                        String(row.original.keywordId) ===
                                        keywordId
                                      )
                                    })?.id,
                                  true,
                                ]
                              })
                              .filter(d => d[0])
                          )
                        )
                      }}
                    />
                  )
                }}
              />
            </div>
          </LabelWrap>
        </div>
      </div>
      <div className="mt-2 flex gap-2">
        <ButtonPlain className="bg-green-500" type="submit">
          <FaCheck /> Save Topic
        </ButtonPlain>
        {props.onCancel ? (
          <ButtonPlain
            className="bg-gray-500 hover:bg-red-500"
            onClick={props.onCancel}
          >
            <FaTimes /> Cancel
          </ButtonPlain>
        ) : props.defaultValues?.id ? (
          <ButtonPlain
            className="bg-gray-500 hover:bg-red-500"
            onClick={deleteTopic}
          >
            <FaTrash /> Delete Topic
          </ButtonPlain>
        ) : null}
      </div>
    </form.Form>
  )
}

export function CreateTopicModal({
  defaultTopic,
  defaultTab,
  onSuccess,
}: {
  defaultTopic?: Partial<PlainMessage<TopicPb>>
  defaultTab?: 'new' | 'existing'
  onSuccess: (topic: UpdateTopicResponsePb) => void
}) {
  const activeWorkspaceId = useActiveWorkspaceId()
  const projectId = useActiveProjectIdState().state
  const confirm = useConfirm()
  const updateTopicMutation = useUpdateTopicMutation()
  const modalContext = useModalContext()

  const [tab, setTab] = React.useState<'new' | 'existing'>(defaultTab || 'new')

  const mergeWithTopic = async (topicId: string) => {
    const res = await fetchTopicById({
      workspaceId: activeWorkspaceId,
      projectId: projectId!,
      topicId,
    })

    const existingTopic = res.topic!

    existingTopic.mappedKeywords.push(...(defaultTopic?.mappedKeywords as any))

    await updateTopicMutation.mutateAsync({
      topic: existingTopic,
    })

    modalContext.close()
  }

  return (
    <Modal>
      <ModalTitle>Create Topic</ModalTitle>
      <ModalContent className="divide-y divide-gray-500/20">
        <ButtonBar className="p-2">
          <BarButton
            className={twMerge(tab === 'new' && 'active')}
            onClick={() => setTab('new')}
          >
            Create New Topic
          </BarButton>
          <BarButton
            className={twMerge(tab === 'existing' && 'active')}
            onClick={() => setTab('existing')}
          >
            Add to Existing Topic
          </BarButton>
        </ButtonBar>
        <div className="space-y-2 p-2">
          {tab === 'new' ? (
            <TopicForm
              defaultValues={defaultTopic}
              onSubmit={async values => {
                if (
                  !values.mappedUrlsByBrand?.length &&
                  !(await confirm({
                    message:
                      'Are you sure you want to create a topic without any URLs?',
                  }))
                ) {
                  return
                }

                const newTopicResponse = await updateTopicMutation.mutateAsync({
                  allowMissing: true,
                  topic: values,
                })

                onSuccess?.(newTopicResponse)
                modalContext.close()
              }}
              onCancel={() => modalContext.close()}
            />
          ) : (
            <>
              <TopicPicker
                projectId={projectId!}
                inlineLabel="Select a Topic"
                onChange={mergeWithTopic}
              />
              <div>
                <ButtonPlain
                  className="bg-gray-500 hover:bg-red-500"
                  onClick={() => modalContext.close()}
                >
                  <FaTimes /> Cancel
                </ButtonPlain>
              </div>
            </>
          )}
        </div>
      </ModalContent>
    </Modal>
  )
}
