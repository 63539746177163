import * as React from 'react'

export function useDelayedMemo<T>(state: T, delay: number) {
  const [delayed, setDelayed] = React.useState(state)

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setDelayed(state)
    }, delay)

    return () => {
      clearTimeout(timeout)
    }
  }, [state, delay])

  return delayed
}
