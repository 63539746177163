import * as React from 'react'
import { FaAngleRight, FaCircle, FaInfoCircle } from 'react-icons/fa'
import { Dashboard } from '.'
import Card from '../../components/Card'
import DashboardContainer from '../../components/DashboardContainer'
import DashboardContent from '../../components/DashboardContent'
import DashboardHeader from '../../components/DashboardHeader'
import KeywordGroupGate from '../../components/DashboardKeywordGroupGate'
import KeywordGroupPicker from '../../components/KeywordGroupPicker'
import DashboardRollupGate from '../../components/DashboardRollupGate'
import SegmentIdGate from '../../components/SegmentIdGate'
import SegmentPicker from '../../components/SegmentPicker'
import DashboardProjectGate from '../../components/DashboardTeamGate'
import DashboardTimeRange from '../../components/DashboardTimeRange'
import ErrorBoundary from '../../components/ErrorBoundary'
import Head from '../../components/Head'
import { PerformanceMetricsWidget } from '../../components/PerformanceMetricsWidget'
import RollupPicker, { useRollup } from '../../components/RollupPicker'
import Tooltip from '../../components/Tooltip'
import useZustand from '../../hooks/useZustand'
import { DEFAULT_SAMPLES, performanceOverviewSlug } from '../../utils/Constants'
import {
  MetricId,
  MetricPermutation,
  MetricPostAggregation,
  metricPermutation,
} from '../../utils/Metrics'
import { allQueryFacets } from '../../utils/queryFacets'
import { ProjectPicker } from '../../components/ProjectPicker'
import {
  useLocalStorageSearchPersisterState,
  useSearchState,
} from '../../components/useSearchState'
import {
  useMetricIdState,
  useSegmentIdState,
  useSyncMetricIdState,
} from '../../utils/searchParams'
import { useKeywordGroupState } from '../../utils/searchParams'
import {
  DisplayMode,
  TimeSeriesWidget,
  TimeSeriesWidgetMode,
} from '../../components/TimeSeriesWidget'
import { TableDisplayMode } from '../../utils/tableDisplayMode'
import useSyncMetricsState from '../../hooks/useSyncMetricsState'
import useWidgetPaginationReset from '../../hooks/useWidgetPaginationReset'
import { ExpandableWidget } from '../../components/ExpandableWidget'
import { KeywordsWidget } from '../../components/KeywordsWidget'

const title = 'Performance Overview'

export const metricIds = [
  'unique_keywords',
  'top_rank',
  'click_through_rate',
  'estimated_traffic',
  'top_pixels_from_top',
  'percentage_of_viewport',
  'percentage_of_dom',
  'results',
  'unique_urls',
  'ppc_value',
  'adwords_search_volume',
  'top_stat_base_rank',
] satisfies MetricId[]

export const performanceOverviewDashboard: Dashboard = {
  name: 'Overview',
  id: performanceOverviewSlug,
  icon: <FaCircle className="inline-block scale-75 transform text-xs" />,
}

export function PerformanceOverview() {
  const [rollupBy, rollupValue] = useRollup()

  const rollupFacet = allQueryFacets.find(d => d.id === rollupBy)

  const dashboardReadyGate = !rollupBy
    ? 'Please select a rollup.'
    : !rollupValue
    ? `Please select at least one ${rollupFacet?.label}. You can also change the Rollup to Domain and analyze data for any domain right now.`
    : null

  const canShowUrlsAndKeywords = true

  const [, setStore] = useZustand(state => state.helpUrl)
  const openDashDocs = () => {
    setStore(old => {
      old.showHelp = true
      old.helpUrl = 'https://help.nozzle.io/performance-overview-dashboard'
      return old
    })
  }

  return (
    <DashboardContainer>
      <Head>
        <title>{title}</title>
      </Head>
      <Card className="divide-y divide-gray-500/20 p-0 ">
        <div className="p-2">
          <DashboardHeader right={<DashboardTimeRange />}>
            <div className="flex flex-wrap items-center gap-2">
              <ProjectPicker />
              <RollupPicker allowDomainsAndUrls />
              <FaAngleRight className="inline" />
              <span>{title}</span>
              <button onClick={openDashDocs} type="button">
                <Tooltip tooltip="Click here to learn more about this dashboard">
                  <FaInfoCircle className="text-sm text-blue-500" />
                </Tooltip>
              </button>
            </div>
          </DashboardHeader>
        </div>
        {dashboardReadyGate ? null : (
          <div className="flex flex-wrap gap-4 p-2">
            <div className="flex flex-auto items-center gap-2">
              <SegmentPicker />
            </div>
            <div className="flex flex-auto items-center gap-2">
              <KeywordGroupPicker />
            </div>
          </div>
        )}
      </Card>
      <DashboardProjectGate>
        <DashboardRollupGate>
          <SegmentIdGate>
            <KeywordGroupGate>
              {dashboardReadyGate ? (
                <Card>{dashboardReadyGate}</Card>
              ) : (
                <DashboardContent>
                  <div className="col-span-full">
                    <ErrorBoundary>
                      <Metrics />
                    </ErrorBoundary>
                  </div>
                  <div className="col-span-full">
                    <ErrorBoundary>
                      <History />
                    </ErrorBoundary>
                  </div>

                  <div className="col-span-full">
                    {!canShowUrlsAndKeywords ? (
                      <Card className="divide-y divide-gray-500/20 p-0">
                        <div className="m-0 p-2 text-lg">Keywords</div>
                        <div className="p-2 text-sm italic opacity-80">
                          <FaInfoCircle className="inline-block" /> Metrics by
                          Keyword are only supported in{' '}
                          <strong>Brand, Brand Type</strong> and{' '}
                          <strong>Brand Property</strong> rollups.
                        </div>
                      </Card>
                    ) : (
                      <Keywords />
                    )}
                  </div>
                  <KeywordGroups />
                  <Segments />
                  {!canShowUrlsAndKeywords ? (
                    <Card className="divide-y divide-gray-500/20 p-0">
                      <div className="m-0 p-2 text-lg">URLs</div>
                      <div className="p-2 text-sm italic opacity-80">
                        <FaInfoCircle className="inline-block" /> Metrics by URL
                        are only supported in <strong>Brand, Brand Type</strong>{' '}
                        and <strong>Brand Property</strong> rollups.
                      </div>
                    </Card>
                  ) : (
                    <Urls />
                  )}
                </DashboardContent>
              )}
            </KeywordGroupGate>
          </SegmentIdGate>
        </DashboardRollupGate>
      </DashboardProjectGate>
    </DashboardContainer>
  )
}

export type PerformanceOverviewMetricsWidget = {
  metricId: (typeof metricIds)[number]
  postAggregation: MetricPostAggregation
  samples: number
  limit: number | undefined
}

const useMetricsWidgetState = () => {
  const metricIdState = useMetricIdState(metricIds)

  const searchState = useSearchState<PerformanceOverviewMetricsWidget>({
    path: 'performanceOverviewMetricsWidget',
    useDefaultValue: () => {
      return {
        metricId: metricIdState.state,
        postAggregation: 'value',
        samples: DEFAULT_SAMPLES,
        limit: 1,
      }
    },
  })

  useSyncMetricIdState(metricIds, searchState.state.metricId)

  return searchState
}

function Metrics() {
  const [rollupBy, rollupValue] = useRollup()
  const segmentIdState = useSegmentIdState()
  const keywordGroupState = useKeywordGroupState()
  const widgetState = useMetricsWidgetState()

  return (
    <PerformanceMetricsWidget
      {...{
        ...widgetState.state,
        isDirty: widgetState.isDirty,
        metricIds,
        summary: false,
        groupBy: rollupBy,
        rollupBy,
        rollupValues: [rollupValue],
        filters: {
          segmentIds: [segmentIdState.state],
          keywordGroups: [keywordGroupState.state],
        },
        setMetricsState: widgetState.setState as any,
        onReset: widgetState.reset,
      }}
    />
  )
}

export type PerformanceOverviewHistoryWidget = {
  metricId: (typeof metricIds)[number]
  samples: number
  limit: number
  displayPostAggregation: MetricPostAggregation
  offset: number
  tableDisplayMode: TableDisplayMode
  sortByMetricPermutation: MetricPermutation<MetricId>
  desc: boolean
  expanded: boolean
  displayMode: DisplayMode
  widgetMode: TimeSeriesWidgetMode
  showLegends: boolean
}

const useHistoryWidgetState = () => {
  const metricsState = useMetricsWidgetState()

  const searchState = useSearchState<PerformanceOverviewHistoryWidget>({
    path: 'performanceOverviewHistoryWidget',
    useDefaultValue: () => {
      const parentState = useMetricsWidgetState()

      return {
        metricId: parentState.state.metricId,
        samples: DEFAULT_SAMPLES,
        desc: true,
        limit: 1,
        offset: 0,
        displayPostAggregation: 'value',
        sortByMetricPermutation: metricPermutation(parentState.state.metricId),
        widgetMode: 'top',
        displayMode: 'summary',
        tableDisplayMode: 'summary',
        expanded: false,
        showLegends: false,
      }
    },
  })

  useWidgetPaginationReset(searchState.state, searchState.setState)
  useSyncMetricsState({
    parentState: metricsState.state,
    setState: searchState.setState,
    syncMetric: true,
    syncSortByMetric: true,
    syncPostAggregations: false,
    syncSamples: true,
  })

  return searchState
}

function History() {
  const [rollupBy, rollupValue] = useRollup()
  const segmentIdState = useSegmentIdState()
  const keywordGroupState = useKeywordGroupState()

  const widgetState = useHistoryWidgetState()

  return (
    <TimeSeriesWidget
      {...{
        title: 'History',
        description: `The latest rollup data for a given metric over the selected time range.`,
        groupBy: rollupBy,
        rollupBy,
        rollupValues: [rollupValue],
        filters: {
          segmentIds: [segmentIdState.state],
          keywordGroups: [keywordGroupState.state],
        },
        metricIds,
        showWidgetMode: false,
        canTitleDrillThrough: false,
        canExpand: false,
        hidePagination: false,
        state: widgetState.state,
        setState: widgetState.setState,
        isDirty: widgetState.isDirty,
        onReset: widgetState.reset,
      }}
    />
  )
}

export type PerformanceOverviewKeywordsWidget = {
  metricId: (typeof metricIds)[number]
  samples: number
  limit: number
  displayPostAggregation: MetricPostAggregation
  offset: number
  tableDisplayMode: TableDisplayMode
  sortByMetricPermutation: MetricPermutation<MetricId>
  desc: boolean
  expanded: boolean
  displayMode: DisplayMode
  widgetMode: TimeSeriesWidgetMode
  showLegends: boolean
}

const useKeywordsWidgetState = () => {
  return useSearchState<PerformanceOverviewKeywordsWidget>({
    path: 'performanceOverviewKeywordsWidget',
    useDefaultValue: () => {
      const parentState = useMetricsWidgetState()

      return {
        metricId:
          parentState.state.metricId === 'unique_keywords'
            ? 'top_rank'
            : parentState.state.metricId,
        samples: DEFAULT_SAMPLES,
        desc: true,
        limit: 10,
        offset: 0,
        displayPostAggregation: 'value',
        sortByMetricPermutation:
          parentState.state.metricId === 'unique_keywords'
            ? metricPermutation('top_rank')
            : metricPermutation(parentState.state.metricId),
        widgetMode: 'top',
        displayMode: 'summary',
        tableDisplayMode: 'summary',
        expanded: false,
        showLegends: false,
      }
    },
    useTransform: state => {
      const parentState = useMetricsWidgetState()

      if (parentState.state.metricId === 'unique_keywords') {
        state.metricId = 'top_rank'
        state.sortByMetricPermutation = metricPermutation(state.metricId)
      }

      return state
    },
  })
}

function Keywords() {
  const [rollupBy, rollupValue] = useRollup()
  const segmentIdState = useSegmentIdState()
  const keywordGroupState = useKeywordGroupState()

  const metricsState = useMetricsWidgetState()
  const widgetState = useKeywordsWidgetState()

  useWidgetPaginationReset(widgetState.state, widgetState.setState)
  useSyncMetricsState({
    parentState: metricsState.state,
    setState: widgetState.setState,
    syncMetric: true,
    syncSortByMetric: true,
    syncPostAggregations: true,
    syncSamples: false,
  })

  return (
    <KeywordsWidget
      {...{
        rollupBy,
        rollupValues: [rollupValue],
        filters: {
          segmentIds: [segmentIdState.state],
          keywordGroups: [keywordGroupState.state],
        },
        metricIds,
        state: widgetState.state,
        setState: widgetState.setState,
        isDirty: widgetState.isDirty,
        onReset: widgetState.reset,
      }}
    />
  )
}

export type PerformanceOverviewKeywordGroupsWidget = {
  metricId: (typeof metricIds)[number]
  samples: number
  limit: number
  displayPostAggregation: MetricPostAggregation
  offset: number
  tableDisplayMode: TableDisplayMode
  sortByMetricPermutation: MetricPermutation<MetricId>
  desc: boolean
  expanded: boolean
  displayMode: DisplayMode
  widgetMode: TimeSeriesWidgetMode
  showLegends: boolean
}

const useKeywordGroupsWidgetState = () => {
  return useSearchState<PerformanceOverviewKeywordGroupsWidget>({
    path: 'performanceOverviewKeywordGroupsWidget',
    useDefaultValue: () => {
      const parentState = useMetricsWidgetState()

      return {
        metricId: parentState.state.metricId,
        samples: DEFAULT_SAMPLES,
        desc: true,
        limit: 5,
        offset: 0,
        displayPostAggregation: 'value',
        sortByMetricPermutation: metricPermutation(parentState.state.metricId),
        widgetMode: 'top',
        displayMode: 'summary',
        tableDisplayMode: 'summary',
        expanded: false,
        showLegends: false,
      }
    },
  })
}

function KeywordGroups() {
  const [rollupBy, rollupValue] = useRollup()
  const segmentIdState = useSegmentIdState()
  const keywordGroupState = useKeywordGroupState()

  const metricsState = useMetricsWidgetState()
  const widgetState = useKeywordGroupsWidgetState()

  useWidgetPaginationReset(widgetState.state, widgetState.setState)
  useSyncMetricsState({
    parentState: metricsState.state,
    setState: widgetState.setState,
    syncMetric: true,
    syncSortByMetric: true,
    syncPostAggregations: false,
    syncSamples: true,
  })

  return (
    <ExpandableWidget expanded={widgetState.state.expanded}>
      <TimeSeriesWidget
        {...{
          title: 'Keyword Groups',
          description: `The latest data for a given metric by Keyword Group over the selected time range.`,
          groupBy: 'keywordGroup',
          rollupBy,
          rollupValues: [rollupValue],
          filters: {
            segmentIds: [segmentIdState.state],
            keywordGroups: [],
          },
          metricIds,
          showWidgetMode: true,
          canTitleDrillThrough: true,
          canExpand: false,
          state: widgetState.state,
          setState: widgetState.setState,
          isDirty: widgetState.isDirty,
          onReset: widgetState.reset,
        }}
      />
    </ExpandableWidget>
  )
}

export type PerformanceOverviewSegmentsWidget = {
  metricId: (typeof metricIds)[number]
  samples: number
  limit: number
  displayPostAggregation: MetricPostAggregation
  offset: number
  tableDisplayMode: TableDisplayMode
  sortByMetricPermutation: MetricPermutation<MetricId>
  desc: boolean
  expanded: boolean
  displayMode: DisplayMode
  widgetMode: TimeSeriesWidgetMode
  showLegends: boolean
}

const useSegmentsWidgetState = () => {
  return useSearchState<PerformanceOverviewSegmentsWidget>({
    path: 'performanceOverviewSegmentsWidget',
    useDefaultValue: () => {
      const parentState = useMetricsWidgetState()

      return {
        metricId: parentState.state.metricId,
        samples: DEFAULT_SAMPLES,
        desc: true,
        limit: 5,
        offset: 0,
        displayPostAggregation: 'value',
        sortByMetricPermutation: metricPermutation(parentState.state.metricId),
        widgetMode: 'top',
        displayMode: 'summary',
        tableDisplayMode: 'summary',
        expanded: false,
        showLegends: false,
      }
    },
  })
}

function Segments() {
  const [rollupBy, rollupValue] = useRollup()
  const keywordGroupState = useKeywordGroupState()

  const metricsState = useMetricsWidgetState()
  const widgetState = useSegmentsWidgetState()

  useWidgetPaginationReset(widgetState.state, widgetState.setState)
  useSyncMetricsState({
    parentState: metricsState.state,
    setState: widgetState.setState,
    syncMetric: true,
    syncSortByMetric: true,
    syncPostAggregations: false,
    syncSamples: true,
  })

  return (
    <ExpandableWidget expanded={widgetState.state.expanded}>
      <TimeSeriesWidget
        {...{
          title: 'Segments',
          description: `The latest data for a given metric by Segment over the selected time range.`,
          groupBy: 'segment',
          rollupBy,
          rollupValues: [rollupValue],
          filters: {
            segmentIds: [],
            keywordGroups: [keywordGroupState.state],
          },
          metricIds,
          showWidgetMode: true,
          canTitleDrillThrough: true,
          canExpand: false,
          state: widgetState.state,
          setState: widgetState.setState,
          isDirty: widgetState.isDirty,
          onReset: widgetState.reset,
        }}
      />
    </ExpandableWidget>
  )
}

export type PerformanceOverviewUrlsWidget = {
  metricId: (typeof metricIds)[number]
  samples: number
  limit: number
  displayPostAggregation: MetricPostAggregation
  offset: number
  tableDisplayMode: TableDisplayMode
  sortByMetricPermutation: MetricPermutation<MetricId>
  desc: boolean
  expanded: boolean
  displayMode: DisplayMode
  widgetMode: TimeSeriesWidgetMode
  showLegends: boolean
}

const useUrlsWidgetState = () => {
  return useSearchState<PerformanceOverviewUrlsWidget>({
    path: 'performanceOverviewUrlsWidget',
    useDefaultValue: () => {
      const parentState = useMetricsWidgetState()

      return {
        metricId: parentState.state.metricId,
        samples: DEFAULT_SAMPLES,
        desc: true,
        limit: 5,
        offset: 0,
        displayPostAggregation: 'value',
        sortByMetricPermutation: metricPermutation(parentState.state.metricId),
        widgetMode: 'top',
        displayMode: 'summary',
        tableDisplayMode: 'summary',
        expanded: false,
        showLegends: false,
      }
    },
  })
}

function Urls() {
  const [rollupBy, rollupValue] = useRollup()
  const segmentIdState = useSegmentIdState()
  const keywordGroupState = useKeywordGroupState()

  const metricsState = useMetricsWidgetState()
  const widgetState = useUrlsWidgetState()

  useWidgetPaginationReset(widgetState.state, widgetState.setState)
  useSyncMetricsState({
    parentState: metricsState.state,
    setState: widgetState.setState,
    syncMetric: true,
    syncSortByMetric: true,
    syncPostAggregations: false,
    syncSamples: true,
  })

  return (
    <ExpandableWidget expanded={widgetState.state.expanded}>
      <TimeSeriesWidget
        {...{
          title: 'URLs',
          description: `The latest data for a given metric by URL over the selected time range.`,
          groupBy: 'url',
          rollupBy,
          rollupValues: [rollupValue],
          filters: {
            segmentIds: [segmentIdState.state],
            keywordGroups: [keywordGroupState.state],
          },
          metricIds,
          showWidgetMode: true,
          canTitleDrillThrough: true,
          canExpand: false,
          state: widgetState.state,
          setState: widgetState.setState,
          isDirty: widgetState.isDirty,
          onReset: widgetState.reset,
        }}
      />
    </ExpandableWidget>
  )
}
