import React from 'react'
import { FaCheck } from 'react-icons/fa'
import { useAccount, useSetMaxOverageMutation } from '../hooks/account'
import useModalContext from '../hooks/useModalContext'
import { useActiveWorkspaceId } from '../hooks/workspaces'
import { floatToMoney, moneyToFloat } from '../utils/money'
import { AccountPb, AccountViewPb } from '../utils/proto'
import Button from './Button'
import Loader from './Loader'
import { MaxOverageFields } from './MaxOverageForm'
import { Modal, ModalClose, ModalContent, ModalTitle } from './Modals'
import Separator from './Separator'

export function MaxOverageModal() {
  const accountQuery = useAccount({ view: AccountViewPb.FULL })

  if (accountQuery.isLoading) {
    return <Loader />
  }

  return <Inner account={accountQuery.data!} />
}

function Inner({ account }: { account: AccountPb }) {
  const activeWorkspaceId = useActiveWorkspaceId()
  const setMaxOverageMutation = useSetMaxOverageMutation()
  const { close } = useModalContext()

  const subscription = account.subscriptions[0]

  if (!subscription) {
    throw new Error('No product subscript found!')
  }

  const meteredSubscription = subscription.meteredSubscriptions[0]

  if (!meteredSubscription) {
    throw new Error('No metered subscript found!')
  }

  const [maxOverageDraft, setMaxOverageDraft] = React.useState<number>(
    moneyToFloat(account.maxOverageAmount)
  )

  const handleSubmit = async () => {
    await setMaxOverageMutation.mutateAsync({
      maxOverage: {
        workspaceId: BigInt(activeWorkspaceId),
        amount: floatToMoney(maxOverageDraft),
      },
    })

    close()
  }

  return (
    <Modal>
      <ModalTitle>Set Max Overage</ModalTitle>
      <ModalContent className="p-4">
        <form
          onSubmit={e => {
            e.preventDefault()
            handleSubmit()
          }}
        >
          <MaxOverageFields
            pricePerSerp={moneyToFloat(meteredSubscription.unitPrice)}
            maxOverageDraft={maxOverageDraft}
            onMaxOverageDraftChange={setMaxOverageDraft}
          />
          <div className="h-4" />
          <Separator />
          <div className="h-4" />
          <div>
            <Button size="base" color="green-500" type="submit">
              <FaCheck /> Save
            </Button>
          </div>
        </form>
      </ModalContent>
      <ModalClose />
    </Modal>
  )
}
