import * as React from 'react'

export default function useAsyncDebounce<T>(
  defaultFn?: (...args: any[]) => T,
  defaultWait = 0
) {
  const debounceRef = React.useRef<any>({
    defaultFn,
    defaultWait,
    promise: null,
    resolve: null,
    reject: null,
    timeout: null,
  })

  debounceRef.current.defaultFn = defaultFn
  debounceRef.current.defaultWait = defaultWait

  const debounce = React.useCallback(
    async <TT = T>(
      fn: (...args: any[]) => TT = debounceRef.current.defaultFn as any,
      wait = debounceRef.current.defaultWait
    ): Promise<Awaited<TT>> => {
      if (!debounceRef.current.promise) {
        debounceRef.current.promise = new Promise((resolve, reject) => {
          debounceRef.current.resolve = resolve
          debounceRef.current.reject = reject
        })
      }

      if (debounceRef.current.timeout) {
        clearTimeout(debounceRef.current.timeout)
      }

      debounceRef.current.timeout = setTimeout(async () => {
        delete debounceRef.current.timeout
        try {
          debounceRef.current.resolve(await fn())
        } catch (err) {
          debounceRef.current.reject(err)
        } finally {
          delete debounceRef.current.promise
        }
      }, wait)

      return debounceRef.current.promise as any
    },
    []
  )

  return debounce
}
