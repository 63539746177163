import * as React from 'react'
import { FaInfoCircle, FaPlus, FaTimes } from 'react-icons/fa'
import { twMerge } from 'tailwind-merge'
import Caption from '../components/Caption'
import Select from '../components/Select'
import { deviceOptions } from '../options/deviceOptions'
import { formatDevice } from '../utils/Format'
import Clickable from './Clickable'

//

export default function DevicePicker(
  props: React.ComponentProps<typeof Select> & {
    children?: any
    onChange?: (value: string[]) => void
    onBlur?: (e: any) => void
    setTouched?: (touched: boolean) => void
    excludeDevices?: string[]
  }
) {
  const options = deviceOptions

  const filteredOptions = React.useMemo(() => {
    if (props.excludeDevices) {
      return options.filter(d => !props.excludeDevices?.includes(d.value))
    }

    return options
  }, [options, props.excludeDevices])

  return (
    <Select
      {...{
        closeOnSelect: false,
        placeholder: 'Search for a device...',
        className: 'inline-block',
        ...props,
        options: filteredOptions,
        onChange: (next: any) => {
          props.onChange?.(next)
        },
        onBlur: (e: Event) => {
          props.setTouched?.(true)
          props.onBlur?.(e)
        },
        renderOption: option => {
          return formatDevice(option.value, { short: false })
        },
      }}
    />
  )
}
