import React from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import { FiDownloadCloud } from 'react-icons/fi'
import { useKeywordGroupOptionsQuery } from '../hooks/keywords'
import { useSegmentOptionsQuery } from '../hooks/segments'
import { useDownloadGroupByOverTimeQuery } from '../hooks/useGroupByOverTimeQuery'
import useModalContext from '../hooks/useModalContext'
import { exportProfileOptions } from '../options/exportProfileOptions'
import { rollupFacets } from '../utils/queryFacets'
import Button from './Button'
import { Modal, ModalContent, ModalTitle } from './Modals'
import Select from './Select'
import UsageTimeRange from './UsageTimeRange'
import NumberInput from './NumberInput'
import { GroupByOverTimeQueryOptions } from '../utils/Api'

const selectOptions = [
  { label: 'Latest Data', value: 'latest' },
  { label: 'All Data Within Time Range', value: 'all' },
]

export function QueryExportModal({
  dataQueryOptions: defaultDataQueryOptions,
}: {
  dataQueryOptions: GroupByOverTimeQueryOptions
}) {
  const { close } = useModalContext()

  const [dataQueryOptions, setDataQueryOptions] = React.useState(
    defaultDataQueryOptions
  )

  const [sampleType, setSampleType] = React.useState('latest')
  const downloadQuery = useDownloadGroupByOverTimeQuery(dataQueryOptions)

  const handleSubmit = () => {
    downloadQuery()
    close()
  }

  const includedMetricsOptions = React.useMemo(
    () => [
      { label: 'Default', value: defaultDataQueryOptions.includedMetrics },
      ...exportProfileOptions.map(option =>
        defaultDataQueryOptions.groupBy !== 'keywordId'
          ? {
              ...option,
              value: option.value.filter(v => v.id !== 'top_ranking_results'),
            }
          : option
      ),
    ],
    [defaultDataQueryOptions.groupBy, defaultDataQueryOptions.includedMetrics]
  )

  React.useEffect(() => {
    if (sampleType === 'all') {
      setDataQueryOptions((old: any) => {
        return { ...old, samples: 10000 }
      })
    }
    if (sampleType === 'latest') {
      setDataQueryOptions((old: any) => {
        return { ...old, samples: 1 }
      })
    }
  }, [sampleType])

  const rollupOptions = rollupFacets.map(rollupFacet => ({
    label: rollupFacet.label,
    value: rollupFacet.rollupId,
  }))

  const keywordGroupsQuery = useKeywordGroupOptionsQuery()
  const segmentsQuery = useSegmentOptionsQuery()

  return (
    <Modal>
      <ModalTitle>Export Data</ModalTitle>
      <ModalContent className="p-2">
        <div>
          <div className="flex items-end gap-2">
            <Select
              label="Samples (History)"
              options={selectOptions}
              onChange={value => setSampleType(value)}
              value={sampleType}
            />
            {sampleType === 'custom' ? (
              <NumberInput
                min={1}
                value={dataQueryOptions.samples}
                className="max-w-[5rem]"
                onChange={num =>
                  setDataQueryOptions((old: any) => {
                    return { ...old, samples: num }
                  })
                }
              />
            ) : null}
            <div className="ml-auto self-center">
              <UsageTimeRange
                timeRanges={dataQueryOptions}
                setTimeRanges={setDataQueryOptions}
              />
            </div>
          </div>
        </div>
        <div className="h-4" />

        <Select
          label="Rollup"
          options={rollupOptions}
          onChange={value =>
            setDataQueryOptions((old: any) => {
              return { ...old, rollupBy: value }
            })
          }
          value={dataQueryOptions.rollupBy}
        />
        <div className="h-4" />
        <Select
          label="Keyword Groups"
          options={keywordGroupsQuery.data}
          value={dataQueryOptions.filters?.keywordGroups?.[0]}
          onChange={value =>
            setDataQueryOptions((old: any) => {
              return {
                ...old,
                filters: { ...old.filters, keywordGroups: [value] },
              }
            })
          }
        />
        <div className="h-4" />
        <Select
          label="Segments"
          options={segmentsQuery.data}
          value={dataQueryOptions.filters?.segmentIds?.[0]}
          onChange={value =>
            setDataQueryOptions((old: any) => {
              return {
                ...old,
                filters: { ...old.filters, segmentIds: [value] },
              }
            })
          }
        />

        <div className="h-4" />
        <Select
          placeholder="Select columns to export..."
          label="Columns to export"
          options={includedMetricsOptions}
          value={dataQueryOptions.includedMetrics}
          onChange={value =>
            setDataQueryOptions((old: any) => {
              return { ...old, includedMetrics: value }
            })
          }
        />

        <div className="h-4" />
        <div className="flex items-center gap-2">
          <Button
            size="base"
            color="gray-500"
            hoverColor="red-500"
            onClick={close}
          >
            <FaTimesCircle /> Cancel
          </Button>
          <Button size="base" color="green-500" onClick={handleSubmit}>
            <FiDownloadCloud /> Export Data
          </Button>
        </div>
      </ModalContent>
    </Modal>
  )
}
