import * as React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import useLoading from '../hooks/useLoading'
import useToast from '../hooks/useToast'
import useZustand from '../hooks/useZustand'
import { useActiveWorkspaceId } from '../hooks/workspaces'
import {
  deleteKeywordSource,
  fetchKeywordSource,
  fetchKeywordSources,
  patchKeywordSource,
  postGenerateKeywordSourceKeywords,
  postKeywordSource,
  postKeywordSourcesConvert,
} from '../utils/Api'
import { queryKeyKeywordSources } from '../utils/Constants'
import { ModelPhraseGroup, PhraseTemplate } from '../utils/Phrases'
import { DeviceModelNz as DeviceModel } from '../utils/devices'
import { LocaleModelNz as LocaleModel } from '../utils/locales'
import { ScheduleConfig, scheduleConfigToFullRRule } from '../utils/schedules'
import useConfirm from './useConfirm'
import useErrorPopup from './useErrorPopup'
import usePopup from './usePopup'
import {
  DeviceModelPb,
  KeywordSourcePb,
  KeywordSourcesClient,
  LocaleModelPb,
  PhraseTemplatePb,
  SchedulePb,
} from '../utils/proto'
import { DataModel } from '../utils/dataModels'

//

export function useKeywordSource(props: {
  id: undefined | string
  workspaceId?: string
  enabled?: boolean
  staleTime?: number
}) {
  const activeWorkspaceId = useActiveWorkspaceId()
  const workspaceId = props.workspaceId ?? activeWorkspaceId
  const enabled = props.enabled ?? true

  return useQuery(
    [queryKeyKeywordSources, { id: props.id, workspaceId }],
    () => fetchKeywordSource(props.id!, workspaceId),
    {
      enabled: enabled && !!(workspaceId && props.id),
      staleTime: props.staleTime,
    }
  )
}

export function useKeywordSources({
  workspaceId,
  projectId,
  staleTime,
}: {
  workspaceId?: string
  projectId: string | undefined
  staleTime?: number
}) {
  const activeWorkspaceId = useActiveWorkspaceId()
  workspaceId = workspaceId ?? activeWorkspaceId

  return useQuery(
    [queryKeyKeywordSources, { projectId, workspaceId }],
    () => fetchKeywordSources(projectId!, workspaceId!),
    {
      enabled: !!(workspaceId && projectId),
      staleTime,
    }
  )
}

//

export function useCreateKeywordSource(props: { silent?: boolean } = {}) {
  const toast = useToast()
  const errorPopup = useErrorPopup()
  const [, setLoading] = useLoading()
  const queryClient = useQueryClient()

  // @ts-expect-error  // 'data' implicitly has type 'any' because it does n... Remove this comment to see the full error message
  const { mutateAsync, data } = useMutation(postKeywordSource, {
    onMutate: () => {
      setLoading(true)
    },
    // @ts-expect-error  // 'onSuccess' implicitly has return type 'any' becau... Remove this comment to see the full error message
    onSuccess: async () => {
      if (!props.silent) {
        toast({
          message: 'Keyword Source created',
          color: 'green-500',
        })
      }
      await queryClient.invalidateQueries(queryKeyKeywordSources)
      return data
    },
    onError: err => {
      console.error(err)
      errorPopup('Failed to create keyword source.')
    },
    onSettled: () => {
      setLoading(false)
    },
  })

  return mutateAsync
}

//

export function useSaveKeywordSource(props: { silent?: boolean } = {}) {
  const workspaceId = useActiveWorkspaceId()

  const errorPopup = useErrorPopup()
  const popup = usePopup()
  const [popups] = useZustand(state => state.popups)
  const [, setLoading] = useLoading()
  const queryClient = useQueryClient()

  const message = (
    <>
      Your keyword source was saved! You can expect to see your data available
      in dashboards & reporting in:{' '}
      <div className="mt-4 text-xl">~30 Minutes</div>
    </>
  )

  const { mutateAsync } = useMutation(
    (newKeywordSource: any) =>
      patchKeywordSource({
        workspaceId,
        ...newKeywordSource,
      }),
    {
      onMutate: () => {
        setLoading(true)
      },
      onSuccess: async data => {
        if (
          !props.silent &&
          !popups.some((popup: any) => popup.message === message)
        ) {
          popup({
            title: 'Keyword Source Saved!',
            message: data.schedules.length
              ? message
              : ' Your keyword source was saved! Please add a schedule to your keywords to see your data available in dashboards & reporting.',
            color: 'green-500',
          })
        }

        await queryClient.invalidateQueries(queryKeyKeywordSources)
        queryClient.setQueryData(
          [queryKeyKeywordSources, String(data.id), data.workspaceId],
          data
        )
      },
      onError: err => {
        console.error(err)
        errorPopup('Failed to save keyword source.')
      },
      onSettled: () => {
        setLoading(false)
      },
    }
  )

  return mutateAsync
}

export function phraseTemplatesToPb(phraseTemplates: PhraseTemplate[]) {
  return phraseTemplates.map(d => {
    return {
      phraseTemplate: d.template,
      keywordGroupTemplates: d.groups?.map(d => ({
        groupTemplate: d,
      })),
      mostFrequentSchedule: d.mostFrequentScheduleId
        ? { rrule: d.mostFrequentScheduleId }
        : undefined,
    }
  })
}

export function phraseTemplateFromPb(d: PhraseTemplatePb): PhraseTemplate {
  return {
    template: d.phraseTemplate,
    groups: d.keywordGroupTemplates?.map(d => d.groupTemplate),
    mostFrequentScheduleId: d.mostFrequentSchedule?.rrule as any,
  }
}

export function deviceModelsToPb(devices: DeviceModel[]) {
  return devices.map(d => {
    return new DeviceModelPb({
      device: d.device,
      mostFrequentSchedule: d.mostFrequentScheduleId
        ? { rrule: d.mostFrequentScheduleId }
        : undefined,
    })
  })
}

export function deviceModelFromPb(d: DeviceModelPb): DeviceModel {
  return {
    device: d.device,
    mostFrequentScheduleId: d.mostFrequentSchedule?.rrule as any,
  }
}

export function localeModelsToPb(locales: LocaleModel[]) {
  return locales.map(d => {
    return new LocaleModelPb({
      localeId: BigInt(d.localeId),
      aliases: d.aliases?.map(dd => ({
        name: dd.alias,
        keywordGroups: dd.groups ?? [],
        mostFrequentSchedule: dd.mostFrequentScheduleId
          ? { rrule: dd.mostFrequentScheduleId }
          : undefined,
      })),
      mostFrequentSchedule: d.mostFrequentScheduleId
        ? { rrule: d.mostFrequentScheduleId }
        : undefined,
      keywordGroups: d.groups ?? [],
    })
  })
}

export function localePbToModel(d: LocaleModelPb): LocaleModel {
  return {
    localeId: Number(d.localeId),
    aliases: d.aliases?.map(d => {
      return {
        alias: d.name,
        groups: d.keywordGroups ?? [],
        mostFrequentScheduleId: d.mostFrequentSchedule?.rrule as any,
      }
    }),
    mostFrequentScheduleId: d.mostFrequentSchedule?.rrule as any,
    groups: d.keywordGroups ?? [],
  }
}

export function scheduleConfigsToPb(schedules: ScheduleConfig[]): SchedulePb[] {
  return schedules.filter(Boolean).map(
    d =>
      new SchedulePb({
        rrule: scheduleConfigToFullRRule(d),
      })
  )
}

export function scheduleConfigToPb(d: ScheduleConfig): SchedulePb {
  return new SchedulePb({
    rrule: scheduleConfigToFullRRule(d),
  })
}

export type KeywordSourceBase = {
  id?: number
  name: string
  devices: DeviceModel[]
  locales: LocaleModel[]
  schedules: ScheduleConfig[]
  phraseTemplates: PhraseTemplate[]
  dataModel: DataModel
}

export type KeywordSourceWithPhrases = Omit<KeywordSourceBase, 'dataModel'> & {
  phraseGroups: ModelPhraseGroup[]
}

export function useSaveKeywordSourceV2(opts?: {
  silent?: boolean
  oneTime?: boolean
}) {
  const confirm = useConfirm()
  const toast = useToast()
  const errorPopup = useErrorPopup()
  const queryClient = useQueryClient()

  return useMutation(
    async (keywordSource: KeywordSourcePb) => {
      // TODO: This will become a checkbox to add an "immediate" run to the list of the next 5 runs
      // Possibly use rrule dates or
      try {
        // let newKeywordSource
        // const isGreaterThanDaily = !scheduleOptions
        //   .filter(d => d.dailyOrFaster)
        //   .find(d => d.value === keywordSource.schedules[0]?.id)

        // if (
        //   isGreaterThanDaily &&
        //   (await confirm({
        //     title: 'When would you like to start tracking these keywords?',
        //     message: `Any overlap with keywords already pulled today will not be double charged.`,
        //     cancelText: 'Immediately',
        //     confirmText: 'Next Scheduled Time',
        //   }))
        // ) {
        //   // this daily ensures it will start a fetch immediately
        //   const valuesWithDaily = Object.assign({}, keywordSource, {
        //     schedules: [
        //       ...keywordSource.schedules,
        //       { rrule: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0' },
        //     ],
        //   })
        //   // Create or update the keyword source
        //   if (keywordSource.id) {
        //     newKeywordSource = await saveSilentKeywordSource(valuesWithDaily)
        //   } else {
        //     newKeywordSource = await createSilentKeywordSource({
        //       workspaceId,
        //       teamId,
        //       ...valuesWithDaily,
        //     })
        //   }
        //   // Remove the temporary schedule
        //   newKeywordSource.schedules?.pop()
        //   // Wait for 1 second to avoid version collisions
        //   await new Promise(resolve => setTimeout(resolve, 1000))
        //   // Resave the keyword source
        //   await saveKeywordSource(newKeywordSource)
        //   if (!props.keywordSource) {
        //     await new Promise(resolve => setTimeout(resolve, 500))
        //     navigate({ to: `../${newKeywordSource.id}` })
        //   }
        // } else {
        //   if (props.keywordSource?.id) {
        //     newKeywordSource = await saveKeywordSource(values)
        //   } else {
        //     newKeywordSource = await createKeywordSource({
        //       workspaceId,
        //       teamId,
        //       ...values,
        //     })
        //     await new Promise(resolve => setTimeout(resolve, 500))
        //     navigate({ to: `../${newKeywordSource.id}` })
        //   }
        // }
        // setFieldMeta('schedules', old => ({ ...old, isTouched: false }))
        let res
        if (!keywordSource?.id) {
          res = await KeywordSourcesClient.createKeywordSource({
            keywordSource,
          })
        } else {
          res = await KeywordSourcesClient.updateKeywordSource({
            keywordSource,
          })
        }

        if (opts?.oneTime) {
          await new Promise(r => setTimeout(r, 1001))
          res.keywordSource!.schedules = []
          res = await KeywordSourcesClient.updateKeywordSource(res)
        }

        if (!opts?.silent)
          toast({
            color: 'green-500',
            message: 'Keyword Source saved.',
          })
        return res
      } catch (err) {
        console.error(err)
        errorPopup('Failed to save Keyword Source.')
        throw err
      }
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(queryKeyKeywordSources)
      },
    }
  ).mutateAsync
}

export function useRemoveKeywordSourceById() {
  const toast = useToast()
  const errorPopup = useErrorPopup()
  const queryClient = useQueryClient()

  const { mutateAsync } = useMutation(deleteKeywordSource, {
    onSuccess: async () => {
      toast({
        message: 'Keyword Source deleted',
        color: 'green-500',
      })
      await queryClient.invalidateQueries(queryKeyKeywordSources)
    },
    onError: err => {
      console.error(err)
      errorPopup('Failed to remove keyword source.')
    },
  })

  return mutateAsync
}

//

export function useConvertKeywordSourceKeywords() {
  return React.useCallback(
    async (keywordSource, convertToConfigType) =>
      // @ts-expect-error  // Argument of type '{ keywordSource: any; convertToC... Remove this comment to see the full error message
      postKeywordSourcesConvert({ keywordSource, convertToConfigType }),
    []
  )
}

//

export function useGenerateKeywordSourceKeywords() {
  return React.useCallback(
    async keywordSource => postGenerateKeywordSourceKeywords(keywordSource),
    []
  )
}

export function useValidateKeywordSource() {
  const confirm = useConfirm()

  return async ({
    values,
    onHourlyWarningDismissed,
  }: {
    values: { schedules: ScheduleConfig[] }
    onHourlyWarningDismissed?: () => void
  }) => {
    // Ensure we have schedules set
    if (!values.schedules.length) {
      const confirmed = await confirm({
        message: `There are currently no schedules entered for this keyword source. Are you sure you want to save without scheduling?`,
      })
      if (!confirmed) {
        return true
      }
    }

    // Warn about hourly rankings
    // TODO: Get rid of this with a modal that has previews of pulls and usage with confirmation
    if (
      values.schedules.find(
        schedule => schedule?.id === 'FREQ=HOURLY;BYMINUTE=0;BYSECOND=0'
      )
    ) {
      const confirmed = await confirm({
        title: 'Are you sure you want to monitor your rankings hourly?',
        message: `This could potentially result in a large amount of daily usage, starting today. Are you sure you want to proceed?`,
        cancelText: 'Cancel',
        confirmText: "Yes, I'm Sure",
      })
      if (!confirmed) {
        onHourlyWarningDismissed?.()
        return true
      }
    }

    return false // No errors
  }
}
