import * as React from 'react'
import { AxisOptions } from 'react-charts'
import { range } from '../utils'
import Color from '../utils/Color'
import { twConfig } from '../utils/tailwind'
import Chart from './Chart'
import ErrorBoundary from './ErrorBoundary'

//

//

const yRange = 10

const makeData = (series: any, datums: any, sort: any, colors: any) =>
  range(Math.max(1, series))
    .map(d => ({
      label: `Series ${d + 1}`,
      color: colors[d % colors.length],
      data: range(Math.max(1, datums)).map(j => ({
        primary: j,
        secondary: Math.ceil((d + 1) * (Math.random() * yRange)),
      })),
    }))
    .sort((a, b) => {
      if (!sort) {
        return 0
      }

      const aVal = a.data[a.data.length - 1].secondary

      const bVal = b.data[b.data.length - 1].secondary
      if (aVal > bVal) return -1
      if (aVal < bVal) return 1
      return 0
    })

type Props = {
  series?: number
  datums?: number
  showGrid?: boolean
  type?: 'bar' | 'line' | 'area'
  stacked?: boolean
  sort?: boolean
  vertical?: boolean
}

export default function ChartPlaceholder({
  series: seriesCount = 5,
  datums = 15,
  showGrid = true,
  type = 'line',
  stacked = false,
  sort = false,
  vertical,
}: Props) {
  const colors = React.useMemo(() => {
    const colors = [
      Color(twConfig.theme.colors.gray['200']).setAlpha(0.5).toRgbString(),

      Color(twConfig.theme.colors.gray['400']).setAlpha(0.5).toRgbString(),

      Color(twConfig.theme.colors.gray['500']).setAlpha(0.5).toRgbString(),

      Color(twConfig.theme.colors.gray['600']).setAlpha(0.5).toRgbString(),

      Color(twConfig.theme.colors.gray['800']).setAlpha(0.5).toRgbString(),
    ]

    if (type === 'line') {
      colors.reverse()
    }
    return colors
  }, [type])

  const doMakeData = React.useCallback(
    () =>
      makeData(
        Math.min(seriesCount, colors.length),
        Math.min(datums, 15),
        sort,
        colors
      ),
    [colors, datums, seriesCount, sort]
  )

  const [data] = React.useState(() => doMakeData())

  const getSeriesStyle = React.useCallback(
    series => ({
      color: series.originalSeries.color,
      line: {
        color: series.originalSeries.color,
        strokeWidth: 2,
      },
    }),
    []
  )

  const primaryAxis: AxisOptions<any> = React.useMemo(
    () => ({
      scaleType: type === 'bar' ? 'band' : 'linear',
      position: vertical ? 'bottom' : type === 'bar' ? 'left' : 'bottom',
      showGrid,
      show: false,
      getValue: datum => datum.primary,
    }),
    //
    [showGrid, type, vertical]
  )

  const secondaryAxes = React.useMemo(
    (): AxisOptions<any>[] => [
      {
        position: vertical ? 'left' : type === 'bar' ? 'bottom' : 'left',
        scaleType: 'linear',
        getValue: datum => datum.secondary,
        stacked,
        show: false,
        elementType: type,
        showDatumElements: false,
      },
    ],
    //
    [stacked, type, vertical]
  )

  return (
    <ErrorBoundary small>
      <Chart
        options={{
          data,
          getSeriesStyle,
          primaryAxis,
          secondaryAxes,
          tooltip: false,
          primaryCursor: false,
          secondaryCursor: false,
        }}
        className="opacity-30"
      />
    </ErrorBoundary>
  )
}
