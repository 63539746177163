import * as React from 'react'
import { BiEnvelope } from 'react-icons/bi'
import { FaFacebook, FaGoogle, FaLock } from 'react-icons/fa'
import { useProfileQuery } from '../hooks/profile'
import useAuth from '../hooks/useAuth'
import useLogout from '../hooks/useLogout'
import useSearch from '../hooks/useSearch'
import { getEmailGravatar } from '../utils'
import { useSignIn } from '../utils/Firebase'
import Button from './Button'
import Clickable from './Clickable'
import Input from './Input'
import Loader from './Loader'

export function Auth({
  emailHint,
  hideProfile,
}: {
  emailHint?: string
  hideProfile?: boolean
}) {
  const profileQuery = useProfileQuery()
  const [{ stage }] = useAuth()
  const showLoading =
    stage === 'loading' || (stage === 'loggedIn' && profileQuery.isLoading)
  const gravatarImage = getEmailGravatar(profileQuery.data?.email)
  const logout = useLogout()
  const signIn = useSignIn()
  const { appsumoActivationEmail } = useSearch() as {
    appsumoActivationEmail: string
  }

  const [email, setEmail] = React.useState<string>(
    appsumoActivationEmail ?? emailHint ?? ''
  )
  const [showSignInWithEmail, setShowSignWithEmail] = React.useState(false)
  const [showSignInWithSaml, setShowSignWithSaml] = React.useState(false)

  const [resolvedShowLoading, setResolvedShowLoading] =
    React.useState(showLoading)

  React.useEffect(() => {
    if (showLoading !== resolvedShowLoading) {
      if (showLoading === true) {
        setResolvedShowLoading(true)
      }
    }

    if (showLoading === false) {
      if (stage === 'loggedIn' && profileQuery.data) {
        const timeout = setTimeout(() => {
          setResolvedShowLoading(false)
        }, 0)

        return () => {
          clearTimeout(timeout)
        }
      } else {
        setResolvedShowLoading(false)
      }
    }
  }, [profileQuery.data, resolvedShowLoading, showLoading, stage])

  return (
    <div className="flex items-center justify-center">
      {resolvedShowLoading ? (
        <Loader className="text-5xl" />
      ) : stage === 'loggedOut' ? (
        <div className="flex flex-col items-stretch gap-2">
          <Button
            size="base"
            color="red-500"
            className="justify-start"
            onClick={() => signIn.google(emailHint)}
          >
            <FaGoogle className="text-lg" /> Sign in with Google
          </Button>
          {/* <Button
            size="base"
            color="blue-500"
            className="justify-start"
            onClick={() => signIn.facebook()}
          >
            <FaFacebook className="text-lg" /> Sign in with Facebook
          </Button> */}
          {!showSignInWithEmail ? (
            <Button
              size="base"
              color="gray-100"
              hoverColor="white"
              className="justify-start"
              onClick={() => setShowSignWithEmail(true)}
            >
              <BiEnvelope className="text-lg" /> Sign in with Email
            </Button>
          ) : (
            <form
              onSubmit={e => {
                e.preventDefault()
                signIn.email(email)
              }}
              className="flex flex-col shadow-xl"
            >
              <Input
                type="email"
                autoComplete="email"
                value={email}
                // @ts-expect-error  // Property 'value' does not exist on type 'EventTarg... Remove this comment to see the full error message
                onChange={e => setEmail(e.target.value)}
                placeholder="Email Address..."
                className="bg-blue-850! rounded-b-none border-0 p-2 px-3"
                disabled={!!appsumoActivationEmail}
              />
              <Button
                size="base"
                color="gray-100"
                hoverColor="green-500"
                type="submit"
                className="justify-start rounded-t-none"
              >
                <BiEnvelope className="text-lg" /> Email Sign-In Link
              </Button>
            </form>
          )}
          {!showSignInWithSaml ? (
            <Button
              size="base"
              color="gray-700"
              hoverColor="white"
              className="justify-start"
              onClick={() => setShowSignWithSaml(true)}
            >
              <BiEnvelope className="text-lg" /> Sign in with SSO
            </Button>
          ) : (
            <form
              onSubmit={e => {
                e.preventDefault()
                signIn.sso(email)
              }}
              className="flex flex-col shadow-xl"
            >
              <Input
                type="email"
                autoComplete="email"
                value={email}
                // @ts-expect-error  // Property 'value' does not exist on type 'EventTarg... Remove this comment to see the full error message
                onChange={e => setEmail(e.target.value)}
                placeholder="Email Address..."
                className="bg-blue-850! rounded-b-none border-0 p-2 px-3"
              />
              <Button
                size="base"
                color="gray-100"
                hoverColor="green-500"
                type="submit"
                className="justify-start rounded-t-none"
              >
                <BiEnvelope className="text-lg" /> Sign In
              </Button>
            </form>
          )}
        </div>
      ) : profileQuery.data ? (
        !hideProfile ? (
          <div className="flex flex-col items-center">
            <div>Signed in as:</div>
            <div className="h-2" />
            <img
              src={
                profileQuery.data?.picture
                  ? profileQuery.data.picture
                  : gravatarImage
              }
              alt="Profile"
              className="inline-block h-20 w-20 rounded-full border-2 shadow-md"
            />
            <div className="h-2" />
            <div className="text-2xl font-bold">
              {profileQuery.data.displayName}
            </div>
            <div className="text-lg">{profileQuery.data.email}</div>
            <Clickable
              className="text-base text-blue-300"
              onClick={() => logout()}
            >
              Logout
            </Clickable>
          </div>
        ) : null
      ) : null}
    </div>
  )
}
