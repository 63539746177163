import * as React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import useLoading from '../hooks/useLoading'
import useToast from '../hooks/useToast'
import { useActiveWorkspaceId } from '../hooks/workspaces'
import {
  deleteSegment,
  fetchSegment,
  fetchSegments,
  onSegmentsChangeSubscription,
  patchSegment,
  postSegment,
} from '../utils/Api'
import { queryKeySegments } from '../utils/Constants'
import useErrorPopup from './useErrorPopup'
import { useQuerySubscription } from './useQuerySubscription'
import { useSearch } from '@tanstack/react-router'

import { useProjectOptionsQuery } from './projects'
import { useActiveProjectIdState } from '../utils/searchParams'

//

export function useSegments({
  projectId,
  enabled = true,
}: {
  projectId: undefined | string
  enabled?: boolean
}) {
  const workspaceId = useActiveWorkspaceId()

  const query = useQuery(
    [queryKeySegments, projectId, workspaceId],
    () => fetchSegments(projectId!, workspaceId!),
    {
      enabled: Boolean(workspaceId && enabled && projectId),
    }
  )

  useQuerySubscription({
    kind: queryKeySegments,
    label: 'Segment',
    subscriber: onSegmentsChangeSubscription,
  })

  return query
}

export function useSegment({ id }: { id: undefined | string }) {
  const workspaceId = useActiveWorkspaceId()

  const query = useQuery(
    [queryKeySegments, id, workspaceId],
    () => fetchSegment(id!, workspaceId),
    {
      enabled: !!(workspaceId && id),
    }
  )

  useQuerySubscription({
    kind: queryKeySegments,
    label: 'Segment',
    subscriber: onSegmentsChangeSubscription,
  })

  return query
}

export function useSegmentOptionsQuery({ enabled = true } = {}) {
  const projectId = useActiveProjectIdState().state
  const projectOptionsQuery = useProjectOptionsQuery()

  const resolvedProjectId = projectId || projectOptionsQuery.data?.[0]?.value

  const segmentsQuery = useSegments({
    projectId: resolvedProjectId,
    enabled,
  })

  const data = React.useMemo(
    () =>
      (segmentsQuery.data || []).map((d: any) => ({
        value: String(d.id),
        label: d.name,
      })),
    [segmentsQuery.data]
  )

  return {
    ...segmentsQuery,
    data,
  }
}

//

export function useCreateSegment() {
  const toast = useToast()
  const [, setLoading] = useLoading()
  const queryClient = useQueryClient()

  const { mutateAsync } = useMutation(postSegment, {
    onMutate: () => {
      setLoading(true)
    },
    onSuccess: async () => {
      toast({
        message: 'Segment Created',
        color: 'green-500',
      })
      await queryClient.invalidateQueries(queryKeySegments)
    },
    onError: err => {
      console.error(err)
      toast({
        message: 'Failed to create Segment',
        color: 'red-500',
      })
    },
    onSettled: () => {
      setLoading(false)
    },
  })

  return mutateAsync
}

export function useRemoveSegmentById() {
  const toast = useToast()
  const errorPopup = useErrorPopup()
  const queryClient = useQueryClient()

  return useMutation(deleteSegment, {
    onSuccess: async () => {
      toast({
        message: 'Segment Deleted',
        color: 'green-500',
      })
      await queryClient.invalidateQueries(queryKeySegments)
    },
    onError: err => {
      console.error(err)
      errorPopup('Failed to remove Segment.')
    },
  })
}

export function useSaveSegment() {
  const workspaceId = useActiveWorkspaceId()
  const toast = useToast()
  const errorPopup = useErrorPopup()
  const [, setLoading] = useLoading()
  const queryClient = useQueryClient()

  const { mutateAsync } = useMutation(
    newSegment =>
      patchSegment({
        workspaceId,
        ...newSegment,
      }),
    {
      onMutate: () => {
        setLoading(true)
      },
      onSuccess: async data => {
        toast({
          message: 'Segment Saved',
          color: 'green-500',
        })
        await queryClient.invalidateQueries(queryKeySegments)
        queryClient.setQueryData([queryKeySegments, data.id], data)
      },
      onError: err => {
        console.error(err)
        errorPopup('Failed to save segment.')
      },
      onSettled: () => {
        setLoading(false)
      },
    }
  )

  return mutateAsync
}
