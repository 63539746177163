import { Link as RLLink, LinkComponent } from '@tanstack/react-router'
import { twMerge } from 'tailwind-merge'

import { withProps } from '../utils/withProps'
import { anchorStyles } from './Anchor'

//

export const Link = withProps(RLLink)(({ className, ...props }) => ({
  search: {},
  ...props,
  className: twMerge(anchorStyles, className),
  onClick: e => {
    if (props.disabled) {
      e.preventDefault()
    } else {
      props.onClick?.(e)
    }
  },
})) as LinkComponent

export default Link
