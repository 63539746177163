import { createColumnHelper } from '@tanstack/react-table'
import * as React from 'react'
import { FaPlus, FaTimes } from 'react-icons/fa'
import Button from '../components/Button'
import { PlainInput } from '../components/Input'
import Select from '../components/Select'
import useGetLatest from '../hooks/useGetLatest'
import useModal from '../hooks/useModal'
import { useMostFrequentScheduleOptions } from '../hooks/useMostFrequentScheduleOptions'
import { useTable } from '../hooks/useTable'
import { Updater, functionalUpdate, log } from '../utils'
import { NO_GROUP_NAME } from '../utils/Constants'
import { ModelPhraseGroup, dedupePhraseGroups } from '../utils/Phrases'
import { ScheduleConfig } from '../utils/schedules'
import Clickable from './Clickable'
import { PhraseGroupsModal } from './PhraseGroupsModalV2'
import Table from './Table'
import { createCheckboxColumn } from './CheckboxColumn'

const columnHelper = createColumnHelper<ModelPhraseGroup>()

export function PhraseGroupsDataModelInput(props: {
  value: ModelPhraseGroup[]
  onChange: (value: Updater<ModelPhraseGroup[]>) => void
  schedules?: ScheduleConfig[]
}) {
  // const confirm = useConfirm()

  // const info = React.useMemo(
  //   () => dedupePhraseGroups(props.value),
  //   [props.value]
  // )

  const autoResetPageIndexRef = React.useRef(true)

  const mostFrequentScheduleOptions = useMostFrequentScheduleOptions({
    schedules: props.schedules,
  })

  const getMostFrequentScheduleOptions = useGetLatest(
    mostFrequentScheduleOptions
  )

  // Resets the most frequent schedules if not in the list of schedules
  React.useEffect(() => {
    if (
      props.value.find(row => {
        return !mostFrequentScheduleOptions.find(
          d => d.value === row.mostFrequentScheduleId
        )
      })
    ) {
      props.onChange?.(
        props.value.map(row => {
          if (
            mostFrequentScheduleOptions.find(
              d => d.value === row.mostFrequentScheduleId
            )
          ) {
            return { ...row, mostFrequentScheduleId: '' }
          }

          return row
        })
      )
    }
  }, [props.schedules])

  const getOnChange = useGetLatest((cb: Updater<typeof props.value>) => {
    props.onChange?.(functionalUpdate(cb, props.value))
  })

  const getUpdateRow = useGetLatest(
    (index: number, updater: Updater<ModelPhraseGroup>) => {
      props.onChange?.(
        props.value.map((d, i) => {
          if (i === index) {
            return functionalUpdate(updater, d)!
          }
          return d
        })
      )
    }
  )

  const columns = React.useMemo(
    () =>
      [
        createCheckboxColumn(),
        columnHelper.accessor('phrase', {
          header: 'Keyword Phrase',
          filterFn: 'includesString',
          meta: {
            getCellProps: () => ({
              className: 'p-0',
            }),
          },
          cell: function Cell(cellProps) {
            return (
              <PlainInput
                enableDraft
                value={cellProps.getValue()}
                onChange={e => {
                  getUpdateRow()(cellProps.row.index, d => ({
                    ...d,
                    phrase: e.currentTarget.value,
                  }))
                }}
                placeholder="Enter a phrase..."
                className="w-full min-w-36"
              />
            )
          },
        }),
        columnHelper.accessor(
          d => d.groups?.map(d => (!d ? NO_GROUP_NAME : d)),
          {
            id: 'groups',
            header: 'Keyword Groups',
            filterFn: 'arrIncludesAll',
            getUniqueValues: row => row.groups || [],
            meta: {
              getCellProps: () => ({
                className: 'p-0',
              }),
            },
            cell: function Cell(cellProps) {
              return (
                <Select
                  multi
                  create
                  enableDraft
                  placeholder="Add Keyword Groups..."
                  value={cellProps.getValue()}
                  onChange={values => {
                    getUpdateRow()(cellProps.row.index, d => ({
                      ...d,
                      groups: values,
                    }))
                  }}
                  inputProps={{
                    Input: PlainInput,
                  }}
                />
              )
            },
          }
        ),
        mostFrequentScheduleOptions.length > 1
          ? columnHelper.accessor(row => row.mostFrequentScheduleId, {
              header: 'Most Frequent Schedule',
              filterFn: 'arrIncludesSome',
              meta: {
                tight: true,
                getCellProps: () => ({
                  className: 'p-0',
                }),
              },
              cell: cellProps => {
                return (
                  <Select
                    options={mostFrequentScheduleOptions}
                    value={cellProps.row.original.mostFrequentScheduleId!}
                    className="min-w-52"
                    placeholder="Select a schedule..."
                    onChange={value => {
                      // getUpdateRow()(cellProps.row.index, d => ({
                      //   ...d,
                      //   mostFrequentScheduleId: value,
                      // }))
                    }}
                    inputProps={{
                      Input: PlainInput,
                    }}
                  />
                )
              },
            })
          : null,
        columnHelper.display({
          id: 'options',
          meta: {
            tight: true,
          },
          cell: cellProps => {
            return (
              <div className="flex justify-center gap-4">
                <Clickable
                  onClick={() => {
                    getOnChange()(prev =>
                      prev.filter((_, i) => i !== cellProps.row.index)
                    )
                  }}
                >
                  <FaTimes className="opacity-30 hover:text-red-500 hover:opacity-100" />
                </Clickable>
              </div>
            )
          },
        }),
      ].filter(Boolean),
    [getOnChange, getUpdateRow, mostFrequentScheduleOptions]
  )

  const table = useTable({
    data: props.value,
    columns,
    showToolbar: true,

    autoResetPageIndex: autoResetPageIndexRef.current,
    // showToolbar: true,
    initialState: React.useMemo(
      () => ({
        pagination: {
          pageSize: 10,
        },
        // sorting: [{ id: 'phrase', desc: false }],
      }),
      []
    ),
    enableSortingRemoval: true,
    counter: {
      totalName: 'keyword phrases',
    },
  })

  React.useEffect(() => {
    autoResetPageIndexRef.current = true
  })

  const groupArray: string[] = []

  const selectedRows = table.getSelectedRowModel().rows

  selectedRows.map(row =>
    row.original.groups.forEach((group: any) => {
      groupArray.push(group)
    })
  )

  // const removeOptions = info.groupOptions.filter(groupOption =>
  //   groupArray.includes(groupOption.label)
  // )

  // const { FieldScope } = useFormContext()

  const showModal = useModal()

  const onAddKeywordPhrase = () => {
    showModal(() => {
      return (
        <PhraseGroupsModal
          onSubmit={phraseGroups => {
            getOnChange()(prev => {
              return log(
                dedupePhraseGroups([...prev, ...phraseGroups]).phraseGroups
              ).map(d => {
                return {
                  mostFrequentScheduleId: mostFrequentScheduleOptions[0]!.value,
                  ...d,
                }
              })
            })
          }}
        />
      )
    })
  }

  return (
    <div className="divide-y divide-gray-500/20 rounded-lg border border-gray-500/20">
      <Table table={table} />
      <div className="flex flex-wrap items-center gap-1 p-2">
        <Button
          size="xs"
          color="blue-500"
          className="mr-1"
          onClick={onAddKeywordPhrase}
        >
          <FaPlus className="inline" />
          Keyword Phrase
        </Button>{' '}
        <span disabled={!selectedRows.length} className="pr-1">
          <strong>{selectedRows.length}</strong> keyword phrases selected
        </span>
      </div>
    </div>
  )
}
