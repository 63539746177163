import * as React from 'react'
import useGetLatest from './useGetLatest'

export function useDebouncedState<T>(opts: {
  initialValue: T
  time?: number
  onChange?: (value: T) => void
}): [T, T, (value: T) => void] {
  const [value, setValue] = React.useState(opts.initialValue)
  const [resolvedValue, setResolvedValue] = React.useState(opts.initialValue)
  const getOnChange = useGetLatest(opts.onChange)

  React.useEffect(() => {
    setValue(opts.initialValue)
  }, [opts.initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setResolvedValue(value)
      getOnChange()?.(value)
    }, opts.time ?? 500)

    return () => clearTimeout(timeout)
  }, [getOnChange, opts.time, value])

  return [value, resolvedValue, setValue]
}
