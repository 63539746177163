import { css } from '@emotion/css'
import { twMerge } from 'tailwind-merge'
import * as React from 'react'
import { useForm } from 'react-form'
import {
  FaCheck,
  FaCheckCircle,
  FaInfoCircle,
  FaRegCircle,
} from 'react-icons/fa'
import ProfileForm from '../containers/Users/ProfileForm'
import { useBrand, useCreateBrand, useSaveBrand } from '../hooks/brands'
import {
  useCreateKeywordSource,
  useSaveKeywordSource,
} from '../hooks/keywordSources'
import { useProfileQuery } from '../hooks/profile'
import { useCreateProject, useSaveProject, useProject } from '../hooks/projects'
import useAuth from '../hooks/useAuth'
import useLocalState from '../hooks/useLocalState'
import useNavigate from '../hooks/useNavigate'
import useSaveProfile from '../hooks/useSaveProfile'
import useSearch from '../hooks/useSearch'
import { useCreateWorkspace, useSaveWorkspace } from '../hooks/workspaces'
import { fetchBrandInfo } from '../utils/Api'
import {
  BRAND_PROPERTY_TYPE_DOMAIN,
  KEYWORD_SOURCE_TYPE_BASIC,
} from '../utils/Constants'
import { formatSlug } from '../utils/Format'
import { phraseTextToPhraseGroups } from '../utils/Phrases'
import Validate, { validateDomain } from '../utils/Validate'
import { twConfig } from '../utils/tailwind'
import { Auth } from './Auth'
import Button from './Button'
import Caption from './Caption'
import { LocalesField } from './LocalesField'
import Spinner from './Spinner'
import TextAreaField from './TextAreaField'
import TextField from './TextField'

//

type Step = {
  id: 'auth' | 'profile' | 'createWorkspace' | 'createKeywordSource'
  required: boolean
  label: React.ReactNode
  step: (stage: StepStage) => React.ReactNode
}

type StepStage = 'pending' | 'done' | 'current'

const TodoCircle = () => (
  <div className="relative">
    <FaRegCircle
      className="animation-duration-[3s]
      absolute inline-flex h-full w-full
origin-center animate-ping text-yellow-500"
    />
    <FaRegCircle className="relative" />
  </div>
)

export function PaaOnboarding() {
  const profileQuery = useProfileQuery()
  const [auth] = useAuth()

  const { workspaceSlug, teamId, brandId } = useSearch()
  const logoWhiteSrc = '/img/nozzle-white.svg'
  const logoSrc = logoWhiteSrc

  const isValidProfile = [
    profileQuery.data?.givenName,
    profileQuery.data?.familyName,
    profileQuery.data?.email,
  ].every(Boolean)

  const workspace = profileQuery.data?.userWorkspaces.find(
    d => d.workspace.slug === workspaceSlug
  )?.workspace

  const teamsQuery = useProject({
    workspaceId: workspace?.id,
    id: teamId,
  })
  const team = teamsQuery.data

  const brandQuery = useBrand({
    workspaceId: workspace?.id,
    id: brandId,
  })

  const brand = brandQuery.data

  const [showThank, setShowThank] = React.useState(false)

  const steps: Step[] = [
    {
      id: 'auth',
      required: auth.stage !== 'loggedIn',
      label: <>Sign in to create an account</>,
      step: stage => <StepAuth />,
    },
    {
      id: 'profile',
      required: !isValidProfile,
      label: <>Tell us about yourself!</>,
      step: stage => <StepUserInfo />,
    },
    {
      id: 'createWorkspace',
      required: !workspace,
      label: <>Company Info & Keywords</>,
      step: stage => (
        <StepWorkspace
          workspace={workspace}
          brand={brand}
          team={team}
          setShowThank={setShowThank}
        />
      ),
    },
  ]

  return (
    // @ts-expect-error  // Argument of type '[TemplateStringsArray]' is not a... Remove this comment to see the full error message
    <div className={twMerge(`dark flex flex-1 overflow-hidden`)}>
      <div
        className={twMerge(
          `relative z-10 w-full max-w-full overflow-auto bg-blue-900 text-white lg:w-[400px]  xl:w-[500px] 2xl:w-[600px]`,
          css({ boxShadow: '[0 0 80px rgba(0,0,0,.4)]' })
        )}
      >
        {showThank ? (
          <>
            <div className="p-8">
              <div className="text-center text-5xl font-bold">
                {' '}
                <FaCheckCircle className="inline text-green-500" />
              </div>
              <div className="pb-8" />
              <div className="text-center text-5xl font-bold">Thank you!</div>
            </div>
            <div className="h-4" />
            <div className="mx-auto w-[400px]">
              <p className="text-center text-lg">
                Someone from Nozzle will email you within 2-4 days with a link
                to your PAA Expansion Deliverable
              </p>
            </div>
          </>
        ) : (
          <>
            {steps.map((step, i) => {
              const previousSteps = steps.slice(0, i)

              const stage: StepStage = previousSteps.some(d => d.required)
                ? 'pending'
                : step.required
                ? 'current'
                : 'done'

              return (
                <div key={i}>
                  <div disabled={stage === 'pending'}>
                    <div
                      className={twMerge(
                        `sticky top-0 z-10 bg-blue-800 p-4 text-white`,
                        stage !== 'done' && `bg-blue-600`
                      )}
                    >
                      <div className="flex items-center gap-3">
                        <div className="text-5xl">
                          {stage === 'current' ? (
                            <TodoCircle />
                          ) : stage === 'pending' ? (
                            <FaRegCircle />
                          ) : (
                            <FaCheckCircle className="text-green-500" />
                          )}
                        </div>
                        <div>
                          <div className="text-2xl font-black">
                            Step {i + 1}
                          </div>
                          <div className="font-light">{step.label}</div>
                        </div>
                      </div>
                    </div>
                    {stage === 'pending' ? null : step.step(stage)}
                  </div>
                  <div className="h-1" />
                </div>
              )
            })}
            <div className="h-48`" />
          </>
        )}
      </div>
      <div
        className="hidden flex-1 items-center justify-center
        bg-blue-800 lg:flex"
        style={{
          background: `radial-gradient(
            ${twConfig.theme.colors.blue[700]},
              ${twConfig.theme.colors.blue[900]} 90%
            )`,
        }}
      >
        <div className="flex flex-col items-center p-8">
          <img src={logoSrc} alt="" className="w-[20vw] max-w-[300px]" />
          <div className="h-8" />
          <div className="text-center text-2xl font-light text-white">
            The <span className="font-black">most powerful rank tracker</span>{' '}
            on the planet.
          </div>
        </div>
      </div>
    </div>
  )
}

function StepAuth() {
  return (
    <>
      <div className="py-8">
        <Auth />
      </div>
    </>
  )
}

function StepUserInfo() {
  const saveProfile = useSaveProfile()

  return (
    <div className="p-8">
      <ProfileForm
        showUserId={false}
        paaOnboarding={true}
        onSubmit={async newProfile => {
          await saveProfile(newProfile)
        }}
      />
    </div>
  )
}

function StepWorkspace({ workspace, team, brand, setShowThank }: any) {
  const profileQuery = useProfileQuery()
  const createWorkspace = useCreateWorkspace()
  const saveWorkspace = useSaveWorkspace()
  const navigate = useNavigate()
  const createTeam = useCreateProject({ silent: true })
  const saveTeam = useSaveProject({ silent: true })
  const saveBrand = useSaveBrand({ silent: true })
  const createBrand = useCreateBrand({ silent: true })
  const [, setShowOnboarding] = useLocalState('showOnboarding', false)
  const createKeywordSource = useCreateKeywordSource({ silent: true })
  const saveKeywordSource = useSaveKeywordSource({ silent: true })

  const defaultValues = React.useMemo(() => {
    return {
      ...workspace,
      domain: brand?.properties?.find(
        (d: any) => d.type === BRAND_PROPERTY_TYPE_DOMAIN
      )?.value,
    }
  }, [brand?.properties, workspace])

  const onSubmit = async (newWorkspace: any) => {
    //Create Workspace

    const savedWorkspace = await (workspace?.id
      ? saveWorkspace(newWorkspace)
      : createWorkspace({
          ...newWorkspace,
          slug: formatSlug(newWorkspace.name),
          hubspotListIds: [252],
        }))

    const phraseGroups = phraseTextToPhraseGroups(newWorkspace.textPhrases)

    //Create Project
    const teamValues = {
      name: savedWorkspace.name,
      slug: savedWorkspace.slug,
      workspaceId: savedWorkspace.id,
      config: {},
    }

    const newTeam = await (team
      ? // @ts-expect-error  // Argument of type '{ id: any; name: any; slug: any;... Remove this comment to see the full error message
        saveTeam({ ...teamValues, id: team.id })
      : createTeam(teamValues))

    // create keyword source

    const payload = {
      ...savedWorkspace,
      type: KEYWORD_SOURCE_TYPE_BASIC,
      config: {
        phraseGroups,
        devices: ['d', 'i'],
        localeIds: newWorkspace.config?.localeIds,
      },

      workspaceId: savedWorkspace.id,
      teamId: newTeam.id,
    }

    const payloadWithDaily = {
      ...payload,
      schedules: [{ rrule: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0' }],
    }

    const newKeywordSource = await createKeywordSource(payloadWithDaily)

    await new Promise(r => setTimeout(r, 1200))

    await saveKeywordSource({
      ...newKeywordSource,
      ...payload,
      schedules: [],
      id: newKeywordSource.id,
    })

    //Create Brand

    const brandInfo = await fetchBrandInfo(newWorkspace.domain)

    const brandValues = {
      ...brandInfo,
      domain: newWorkspace.domain,

      type: 'Owned',
      reputationImpact: 0,
      workspaceId: savedWorkspace.id,
      teamId: newTeam.id,
    }

    const newBrand = await (brand
      ? saveBrand({ ...brandValues, id: brand.id })
      : createBrand(brandValues))

    navigate({
      search: old => ({
        ...old,
        workspaceSlug: savedWorkspace.slug,
        teamId: newTeam.id,
        brandId: newBrand.id,
      }),
    })

    setShowOnboarding(true)
    setShowThank(true)
  }

  const defaultLocaleIds = React.useMemo(() => [44249], [])
  const {
    Form,
    meta: { isSubmitting },
  } = useForm({
    onSubmit,
    defaultValues,
  })

  return (
    <div className="p-8">
      <Form>
        <Caption>
          <FaInfoCircle className="inline" /> This will typically be your
          company name. If you are an agency, have this be the name of your
          agency, and you will be able to create separate projects for each of
          your clients.
        </Caption>
        <div className="h-4" />
        <div>
          <TextField
            field="name"
            label="Company Name"
            placeholder="My Company"
            validate={Validate.required('A name is required.')}
            className="w-80 flex-none"
          />
        </div>
        <div className="h-3" />

        <div>
          <TextField
            field="domain"
            label={'Primary Domain'}
            placeholder="mysite.com"
            validate={d =>
              Validate.required('A domain is required.')(d) ||
              validateDomain()(d)
            }
            className="w-80 flex-none"
          />
        </div>
        <div className="h-3" />
        <TextAreaField
          label="Keyword Phrases"
          field="textPhrases"
          placeholder={[
            'keyword phrase 1; keyword group 1; keyword group 2',
            '',
            'eggs; Breakfast; Eggs',
            'hamburger; Lunch; Dinner',
            'sandwich; Lunch',
            'fried eggs; Breakfast; Eggs',
            'toast',
          ].join(`\n`)}
          rows={8}
          // @ts-expect-error  // Argument of type '{ required: boolean; }' is not a... Remove this comment to see the full error message
          validate={Validate.keywordSourcePhrases({ required: true })}
        />

        <Caption>
          <FaInfoCircle className="inline" /> Paste your keyword phrases into
          the Keyword Phrases box, one keyword phrase per line. You can
          optionally assign each keyword phrase one or more keyword groups by
          adding semi-colon separated groups after the keyword phrase on the
          same line, e.g.
          <br />{' '}
          <span className="rounded-lg border border-gray-500 bg-gray-500 bg-opacity-30 px-1">
            my keyword phrase; Group 1; Group 2; Group 3
          </span>
        </Caption>

        <div className="h-4" />

        <LocalesField
          field="config.localeIds"
          label="Locales"
          defaultValue={defaultLocaleIds}
          required
        />
        <div className="h-1" />
        <Caption className="font-normal">
          <FaInfoCircle className="inline" /> A locale is a combination of
          search engine, language, and location. To browse all locales, click
          Browse All. The locales you select will be used as search origins for
          all keywords in this keyword source.
        </Caption>

        <div className="h-4" />
        <Button
          type="submit"
          size="base"
          color={workspace?.id ? 'blue-500' : 'green-500'}
        >
          {isSubmitting ? (
            <>
              <Spinner color="white" /> Saving Keywords
            </>
          ) : (
            <>
              <FaCheck /> Save Keywords
            </>
          )}
        </Button>
      </Form>
    </div>
  )
}
