import * as React from 'react'
import BoxPlaceholder from '../components/BoxPlaceholder'
import { useTable } from '../hooks/useTable'
import { range } from '../utils'
import Table from './Table'

//

export default function TablePlaceholder({
  rows = 5,
  columns = 10,
  style = {},
}) {
  const data = React.useMemo(() => range(rows), [rows])

  const tableColumns = React.useMemo(
    () =>
      range(columns).map((d, i) => ({
        id: i + 1,
        accessorFn: (d: any) => d[i],
        header: () => (
          <>
            <div className="w-[100px]" />
            <BoxPlaceholder className="h-2" />
          </>
        ),
        cell: () => <BoxPlaceholder className="h-2" />,
      })),
    [columns]
  )

  const table = useTable({
    data,
    // @ts-expect-error  // Type '{ id: number; accessorFn: (d: any) => any; h... Remove this comment to see the full error message
    columns: tableColumns,
    pagination: {
      show: false,
    },
    counter: {
      show: false,
    },
  })

  return <Table table={table} style={style} />
}
