import * as React from 'react'
import { splitFormProps, useField } from 'react-form'
import DateInput, { DateInputProps } from './DateInput'
import LabelWrap from './LabelWrap'

//

export interface DateFieldProps extends Omit<DateInputProps, 'value'> {
  field?: string
  label?: React.ReactNode
  validate?: (e: any) => void
  filterValue?: (str?: string) => string
  inputProps?: any
}

export default React.forwardRef(function DateField(
  { onChange, ...props }: DateFieldProps,
  ref
) {
  const [field, formProps, { label, className, style, ...rest }] =
    splitFormProps(props)
  const {
    value,
    meta: { error, isTouched, isValidating },
    setValue,
    setMeta,
    // @ts-expect-error  // Argument of type '{ field?: string | undefined; la... Remove this comment to see the full error message
  } = useField(field, formProps)

  return (
    <LabelWrap
      label={label}
      className={className}
      style={style}
      error={error}
      isValidating={isValidating}
    >
      <DateInput
        ref={ref}
        onChange={(value, e) => {
          // @ts-expect-error  // Argument of type 'Date | undefined' is not assigna... Remove this comment to see the full error message
          setValue(value)
          if (onChange) {
            onChange(value, e)
          }
        }}
        onBlur={() => setMeta({ isTouched: true })}
        // @ts-expect-error  // Type 'string | number | readonly string[]' is not ... Remove this comment to see the full error message
        value={value || ''}
        error={isTouched && error}
        isValidating={isValidating}
        {...rest}
      />
    </LabelWrap>
  )
})
