import { twMerge } from 'tailwind-merge'
import * as React from 'react'

export function ExpandableWidget({
  expanded,
  children,
  ...rest
}: React.HTMLProps<HTMLDivElement> & {
  expanded: boolean
  children: any
}) {
  return (
    <div {...rest} className={twMerge(expanded && 'col-span-full')}>
      {children}
    </div>
  )
}
