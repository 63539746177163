import { initializeApp } from 'firebase/app'
import * as FirebaseAuth from 'firebase/auth'
import moment from 'moment'
import * as React from 'react'
import useAuth from '../hooks/useAuth'
import useConfirm from '../hooks/useConfirm'
import useErrorPopup from '../hooks/useErrorPopup'
import useNavigate from '../hooks/useNavigate'
import usePopup from '../hooks/usePopup'
import useToast from '../hooks/useToast'
import { openHubSpotChat } from './Analytics'
import { setClaims } from './Api'
import LocalStorage from './LocalStorage'

const firebaseConfig = {
  apiKey: 'AIzaSyDS-DWs_fPA05KC2DcUpdW-xtZwXZdquAI',
  authDomain: 'auth.nozzle.io',
  databaseURL: 'https://nozzle-app.firebaseio.com',
  projectId: 'nozzle-app',
  storageBucket: 'nozzle-app.appspot.com',
  messagingSenderId: '873065328857',
  appId: '1:873065328857:web:4646f398b12b7e2b',
}

initializeApp(firebaseConfig)

const FIREBASE_EMAIL_KEY = 'firebaseEmail'
// const FIREBASE_PENDING_CREDENTIAL_KEY = 'firebasePendingCredential'
let pendingCredential: FirebaseAuth.AuthCredential | null = null

let auth: FirebaseAuth.Auth

if (typeof document !== 'undefined') {
  auth = FirebaseAuth.getAuth()
  // window.firebaseAuth = auth
}

export function useFirebaseAuth() {
  const [{ claims }, setAuth] = useAuth()
  const errorPopup = useErrorPopup()
  const onLoginSuccessRef = React.useRef(null)
  const clearSearchParams = useClearSearchParams()

  useHandleEmailSignIn()

  //   tosUrl: 'https://nozzle.io/terms-of-service',
  //   privacyPolicyUrl: 'https://nozzle.io/privacy-policy',

  const handleClaims = React.useCallback(
    (claims: FirebaseAuth.IdTokenResult) => {
      setClaims(claims)

      setAuth(old => ({
        ...old,
        stage: 'loggedIn',
        claims,
      }))
    },
    []
  )

  React.useEffect(() => {
    if (import.meta.env.DEV) {
      let devClaims: any
      try {
        devClaims = JSON.parse(localStorage.getItem('dev_claims') || '')
      } catch (err) {
        devClaims = null
        localStorage.removeItem('dev_claims')
      }

      if (devClaims) handleClaims(devClaims)
    }
  }, [])

  const handleAuth = React.useCallback(
    async user => {
      try {
        if (user) {
          const claims = await user.getIdTokenResult()

          clearSearchParams()
          handleClaims(claims)
        } else {
          setAuth(old => ({
            ...old,
            stage: 'loggedOut',
            claims: null,
          }))
        }
      } catch (err) {
        console.error(err)

        setAuth(old => ({
          ...old,
          stage: 'loggedOut',
          claims: null,
        }))

        errorPopup(`Something went wrong trying to login!`)
      }
    },
    [clearSearchParams, errorPopup, setAuth]
  )

  // @ts-expect-error  // Type '({ user }: any) => boolean' is not assignabl... Remove this comment to see the full error message
  onLoginSuccessRef.current = ({ user }: any) => {
    handleAuth(user)
    return false
  }

  // Listens to the auth for changes in login state
  React.useEffect(() => {
    return auth.onAuthStateChanged(
      user => {
        handleAuth(user)
      },
      err => {
        console.error('An auth error occurred', err)
      }
    )
  }, [handleAuth])

  React.useEffect(() => {
    if (claims) {
      const expiresAt = new Date(claims?.expirationTime).valueOf()
      const time = expiresAt - Date.now() - 1000 * 60

      const timeout = setTimeout(() => {
        const currentUser = auth.currentUser
        handleAuth(currentUser)
      }, time)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [claims, handleAuth])
}

function useClearSearchParams() {
  const navigate = useNavigate()

  return React.useCallback(
    () =>
      navigate({
        fromCurrent: true,
        // @ts-expect-error  // Type '(old: Partial<Search<unknown>> | undefined) ... Remove this comment to see the full error message
        search: old => {
          delete old.apiKey

          delete old.oobCode

          delete old.mode

          delete old.lang
          return old
        },
        replace: true,
      }),
    [navigate]
  )
}

export function useSignIn() {
  const confirm = useConfirm()
  const popup = usePopup()
  const linkPendingCredential = useLinkPendingCredential()

  return React.useMemo(() => {
    const providers = {
      google: async (emailHint?: string) => {
        try {
          const authProviderGoogle = new FirebaseAuth.GoogleAuthProvider()
          authProviderGoogle.setCustomParameters({
            // @ts-expect-error  // Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
            login_hint: emailHint,
            prompt: 'select_account',
          })
          await FirebaseAuth.signInWithPopup(auth, authProviderGoogle)
          await linkPendingCredential()
        } catch (err: any) {
          if (err.code === 'auth/account-exists-with-different-credential') {
            return await handleAccountExists(
              // @ts-expect-error  // Argument of type 'OAuthCredential | null' is not a... Remove this comment to see the full error message
              FirebaseAuth.OAuthProvider.credentialFromError(err),
              err.customData.email
            )
          }

          console.error(err)

          const confirmed = await confirm({
            title: 'Something went wrong! 😢',
            message: `Something went wrong while logging in to Google. Please try again or contact support.`,
            confirmText: 'Start New Support Chat',
            cancelText: 'Dismiss',
          })

          if (confirmed) {
            openHubSpotChat()
          }
        }
      },
      facebook: async () => {
        try {
          const authProviderFacebook = new FirebaseAuth.FacebookAuthProvider()
          await FirebaseAuth.signInWithPopup(auth, authProviderFacebook)
          await linkPendingCredential()
        } catch (err: any) {
          if (err.code === 'auth/account-exists-with-different-credential') {
            return await handleAccountExists(
              // @ts-expect-error  // Argument of type 'OAuthCredential | null' is not a... Remove this comment to see the full error message
              FirebaseAuth.OAuthProvider.credentialFromError(err),
              err.customData.email
            )
          }

          console.error(err)

          const confirmed = await confirm({
            title: 'Something went wrong! 😢',
            message: `Something went wrong while logging in to Facebook. Please try again or contact support.`,
            confirmText: 'Start New Support Chat',
            cancelText: 'Dismiss',
          })

          if (confirmed) {
            openHubSpotChat()
          }
        }
      },
      email: async (email: string) => {
        const actionCodeSettings = {
          url: window.location.href,
          handleCodeInApp: true,
        }

        try {
          await FirebaseAuth.sendSignInLinkToEmail(
            auth,
            email,
            actionCodeSettings
          )

          LocalStorage.set(FIREBASE_EMAIL_KEY, email)

          await popup({
            title: 'Sign-in Link Sent!',
            message: `An email with a sign-in link has been sent to ${email}. Click OK to close this window.`,
          })

          window.close()
        } catch (err: any) {
          if (err.code === 'auth/account-exists-with-different-credential') {
            return handleAccountExists(err.credential, err.email)
          }

          console.error(err)

          const confirmed = await confirm({
            title: 'Something went wrong! 😢',
            message: `There was an error sending a new sign-in link to ${email}. Please contact support.`,
            confirmText: 'Start New Support Chat',
            cancelText: 'Dismiss',
          })

          if (confirmed) {
            openHubSpotChat()
          }
        }
      },
      sso: async (email: string) => {
        const domain = email.includes('@')
          ? email.substring(email.indexOf('@') + 1)
          : ''

        if (!domain) {
          return await confirm({
            title: `Invalid Email!`,
            message: `Your email didn't contain a valid domain. Try again!`,
            confirmText: 'Dismiss',
          })
        }

        const ssos = [
          { domains: ['angi.com'], provider: 'saml.angi' },
          {
            domains: [
              'costar.com',
              'costar.co.uk',
              'bureauxlocaux.com',
              'bizbuysell.com',
              'belbex.com',
              'loopnet.com',
            ],
            provider: 'saml.costar',
          },
        ]

        const sso = ssos.find(d => d.domains.includes(domain))

        if (!sso) {
          return await confirm({
            title: `Domain is not configured!`,
            message: `SSO is not configured for the domain: ${domain}. Please contact support to set up SSO for your domain.`,
            confirmText: 'Start New Support Chat',
            cancelText: 'Dismiss',
          })
        }

        try {
          const authProviderOkta = new FirebaseAuth.SAMLAuthProvider(
            sso.provider
          )
          // authProviderOkta.setCustomParameters({
          //   login_hint: emailHint,
          //   prompt: 'select_account',
          // })
          await FirebaseAuth.signInWithPopup(auth, authProviderOkta)
          await linkPendingCredential()
        } catch (err: any) {
          if (err.code === 'auth/account-exists-with-different-credential') {
            return await handleAccountExists(
              // @ts-expect-error  // Argument of type 'OAuthCredential | null' is not a... Remove this comment to see the full error message
              FirebaseAuth.OAuthProvider.credentialFromError(err),
              err.customData.email
            )
          }

          console.error(err)

          const confirmed = await confirm({
            title: 'Something went wrong! 😢',
            message: `Something went wrong while logging in. Please try again or contact support.`,
            confirmText: 'Start New Support Chat',
            cancelText: 'Dismiss',
          })

          if (confirmed) {
            openHubSpotChat()
          }
        }
      },
    }

    const handleAccountExists = async (
      credential: FirebaseAuth.AuthCredential,
      email: string
    ) => {
      const methods = await FirebaseAuth.fetchSignInMethodsForEmail(auth, email)

      const provider = methods[0]

      if (provider === 'password') {
        const confirmed = await confirm({
          title: 'We found an existing account!',
          message: `An account with the email ${email} already exists as a passwordless login!`,
          confirmText: 'Email Sign-In Link',
          cancelText: 'Cancel',
        })

        if (confirmed) {
          await providers.email(email)
        }
      } else if (provider === 'facebook.com') {
        pendingCredential = credential
        const confirmed = await confirm({
          title: 'We found an existing account!',
          message: `A ${provider} account with the email ${email} already exists!`,
          confirmText: `Log in with ${provider}`,
          cancelText: 'Cancel',
        })

        if (confirmed) {
          await providers.facebook()
        }
      } else if (provider === 'google.com') {
        pendingCredential = credential
        const confirmed = await confirm({
          title: 'We found an existing account!',
          message: `A ${provider} account with the email ${email} already exists!`,
          confirmText: `Log in with ${provider}`,
          cancelText: 'Cancel',
        })

        if (confirmed) {
          await providers.google(email)
        }
      } else {
        throw new Error(`Provider ${provider} not supported.`)
      }
    }

    return providers
  }, [confirm, linkPendingCredential, popup])
}

function useHandleEmailSignIn() {
  const [{ stage }] = useAuth()
  const toast = useToast()
  const linkPendingCredential = useLinkPendingCredential()
  const signIn = useSignIn()
  const confirm = useConfirm()
  const popup = usePopup()
  const clearSearchParams = useClearSearchParams()

  React.useEffect(() => {
    const handle = async () => {
      if (
        stage === 'loggedOut' &&
        FirebaseAuth.isSignInWithEmailLink(auth, window.location.href)
      ) {
        let email = LocalStorage.get(FIREBASE_EMAIL_KEY)

        if (!email) {
          email = window.prompt('Please reenter your email address to log in:')
        }

        try {
          await FirebaseAuth.signInWithEmailLink(
            auth,
            // @ts-expect-error  // Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
            email,
            window.location.href
          )
          await linkPendingCredential()
        } catch (err: any) {
          if (err.code === 'auth/invalid-action-code') {
            if (email) {
              const confirmed = await confirm({
                title: 'Expired Login Link!',
                message: `The sign-in link you used has expired. Would you like to send a new one to ${email}?`,
                confirmText: 'Email Sign-In Link',
                cancelText: 'Cancel',
              })

              if (confirmed) {
                // @ts-expect-error  // Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
                await signIn.email(email)
              }
            } else {
              await popup({
                title: 'Expired Login Link!',
                message: `The sign-in link you used has expired. Please enter your email to request another one.`,
              })
            }

            return
          }

          console.error(err)

          const confirmed = await confirm({
            title: 'Something went wrong 😢',
            message: `An unknown error occurred while trying to sign-in! Please contact support.`,
            confirmText: 'Start New Support Chat',
            cancelText: 'Dismiss',
          })

          if (confirmed) {
            openHubSpotChat()
          }
        } finally {
          clearSearchParams()
        }
      }
    }

    handle()
  }, [
    clearSearchParams,
    confirm,
    linkPendingCredential,
    popup,
    signIn,
    stage,
    toast,
  ])
}

export function signOutOfFirebase() {
  return FirebaseAuth.signOut(auth)
}

function useLinkPendingCredential() {
  const popup = usePopup()

  return React.useCallback(async () => {
    // const pendingCredential: FirebaseAuth.AuthCredential = LocalStorage.get(
    //   FIREBASE_PENDING_CREDENTIAL_KEY
    // )

    // LocalStorage.set(FIREBASE_PENDING_CREDENTIAL_KEY, undefined)
    const pendingCredentialCopy = pendingCredential
    pendingCredential = null

    if (pendingCredentialCopy) {
      await FirebaseAuth.linkWithCredential(
        // @ts-expect-error  // Argument of type 'User | null' is not assignable t... Remove this comment to see the full error message
        auth.currentUser,
        pendingCredentialCopy
      )
      popup({
        title: 'Accounts successfully linked!',
        message: `Your two accounts have been successfully linked. You can now use either provider to sign in to Nozzle.`,
      })
    }
  }, [popup])
}
