import { Stripe } from '@stripe/stripe-js'
import * as React from 'react'

let stripePromise: Promise<Stripe>

export function useStripePromise() {
  const rerender = React.useReducer(() => ({}), {})[1]

  if (!stripePromise) {
    stripePromise = (async () => {
      const { loadStripe } = await import('@stripe/stripe-js')
      const stripe = await loadStripe(
        'pk_live_CeHF3bYNSqr8TknDP9ax5Ccm00nxKzO0Zn'
      )
      return stripe as Stripe
    })()
  }

  React.useEffect(() => {
    stripePromise.then(rerender)
  }, [rerender])

  return stripePromise
}
