import * as React from 'react'
import Card from '../components/Card'
import ControlledQueryTable, {
  ControlledQueryTableState,
} from '../components/ControlledQueryTable'
import { Counter } from '../components/Counter'
import Loader from '../components/Loader'
import Pager from '../components/Pager'
import WidgetControls from '../components/WidgetControls'
import WidgetControlsToggle from '../components/WidgetControlsToggle'
import { WidgetStateResetter } from '../components/WidgetStateResetter'
import useGroupByOverTimeQuery from '../hooks/useGroupByOverTimeQuery'
import {
  UseGroupByOverTimeQueryOptions,
  useGroupByOverTimeQueryOptions,
} from '../hooks/useGroupByOverTimeQueryOptions'
import useTimeRangeState from '../hooks/useTimeRanges'
import {
  MetricId,
  MetricPostAggregation,
  metricPermutation,
} from '../utils/Metrics'
import { makeKeywordTableColumns } from '../utils/makeKeywordTableColumns'
import {
  QueryFacetId,
  allQueryFacets,
  queryFacetsById,
} from '../utils/queryFacets'
import { Updater } from '../utils'
import Link from './Link'

type KeywordsWidgetState = ControlledQueryTableState & {
  offset: number
  limit: number
  showLegends: boolean
  displayPostAggregation: MetricPostAggregation
}

export function KeywordsWidget<TState extends KeywordsWidgetState>({
  metricIds,
  filters,
  state,
  setState,
  isDirty,
  onReset,
  rollupBy,
  rollupValues,
}: {
  metricIds: MetricId[]
  rollupBy: QueryFacetId
  rollupValues?: string[]
  filters: UseGroupByOverTimeQueryOptions['filters']
  state: KeywordsWidgetState
  setState: (updater: Updater<TState>) => void
  isDirty: boolean
  onReset: () => void
}) {
  const timeRangeState = useTimeRangeState()
  const groupByFacet = allQueryFacets.find(d => d.id === 'keyword')!

  const keywordMetricIdsWithTopResult: MetricId[] = React.useMemo(
    () => [...metricIds, 'top_ranking_results'],
    [metricIds]
  )

  const dataQueryOptions = useGroupByOverTimeQueryOptions({
    start: timeRangeState.state[0]?.start,
    end: timeRangeState.state[0]?.end,
    groupBy: 'keyword',
    rollupBy,
    rollupValues,
    filters,
    samples: 1,
    metricPermutations: [
      ...keywordMetricIdsWithTopResult.map(id => metricPermutation(id)),
      ...metricIds.map(d =>
        metricPermutation(d, {
          postAggregation: 'change',
        })
      ),
    ],
    desc: state.desc,
    limit: state.limit,
    offset: state.offset,
    sortByMetricPermutation: state.sortByMetricPermutation,
  })

  const dataQuery = useGroupByOverTimeQuery(dataQueryOptions)

  const [showControls, toggleControls] = React.useReducer(
    (state, action = !state) => action,
    false
  )

  const columns = React.useMemo(
    () =>
      makeKeywordTableColumns({
        metricIds: keywordMetricIdsWithTopResult,
        sortByMetricPermutation: state.sortByMetricPermutation,
        isKeywordTable: true,
        renderSeriesLabel: serie => {
          return (
            <Link
              to={`/dashboards/performance-single-keyword`}
              search={{
                keywordId: queryFacetsById.keyword.getSerieValue(serie),
              }}
            />
          )
        },
      }),
    [keywordMetricIdsWithTopResult, state.sortByMetricPermutation]
  )

  return (
    <Card className="divide-y divide-gray-500/20 p-0">
      <div className="m-0 flex items-center gap-2 p-2 font-bold">
        <span>Keywords</span>
        <div className="mr-auto">
          {dataQuery.isFetching ? <Loader /> : null}
        </div>
        <WidgetStateResetter
          {...{
            isDirty,
            onReset,
          }}
        />
        <WidgetControlsToggle
          showControls={showControls}
          onToggle={toggleControls}
        />
      </div>
      {showControls ? (
        <div className="p-2">
          <WidgetControls
            {...{
              ...state,
              dataQueryOptions,
              metricIds,
              setState,
              allowSamples: false,
              allowSorting: true,
              allowMetrics: true,
              allowPostAggregation: true,
              allowExport: true,
              allowLegends: true,
            }}
          />
        </div>
      ) : null}
      <ControlledQueryTable
        {...{
          compact: true,
          dataQuery,
          metricIds,
          sortByMetricPermutation: state.sortByMetricPermutation,
          desc: state.desc,
          setState,
          columns: columns as any,
          limit: state.limit,
        }}
      />
      <div className="p-1">
        <Counter
          {...{
            compact: true,
            isLoading: dataQuery.isFetching,
            count: dataQuery.data?.series?.length,
            totalCount: dataQuery.data?.rowCount,
            totalName: groupByFacet.labelPlural,
          }}
        />
      </div>
      <div className="p-1">
        <Pager
          compact
          offset={state.offset}
          limit={state.limit}
          size={dataQuery.data?.rowCount}
          setOffset={offset =>
            setState(old => ({
              ...old,
              offset,
            }))
          }
          setLimit={limit =>
            setState(old => ({
              ...old,
              limit,
            }))
          }
        />
      </div>
    </Card>
  )
}
