import React from 'react'
import { twMerge } from 'tailwind-merge'

export function ProgressBar({
  innerProps,
  value,
  ...props
}: {
  value: number
  innerProps?: React.HTMLAttributes<HTMLDivElement>
} & React.HTMLAttributes<HTMLDivElement>) {
  value = Math.min(value * 100, 100)

  return (
    <div
      {...props}
      className={twMerge(
        `bg-opacity relative h-3 w-32 overflow-hidden rounded-l-full rounded-r-full bg-gray-500 bg-opacity-20`,
        props.className
      )}
    >
      <div
        {...innerProps}
        className={twMerge(
          'absolute left-0 top-0 h-full bg-gray-500',
          innerProps?.className
        )}
        style={{
          ...innerProps?.style,
          width: `${value}%`,
        }}
      />
    </div>
  )
}
