import { useNavigate, useSearch } from '@tanstack/react-router'
import { createColumnHelper } from '@tanstack/react-table'
import * as React from 'react'
import { FaAngleRight, FaCaretDown } from 'react-icons/fa'
import Anchor from '../../../components/Anchor'
import Card from '../../../components/Card'
import ErrorBoundary from '../../../components/ErrorBoundary'
import { Head } from '../../../components/Head'
import Link from '../../../components/Link'
import Table from '../../../components/Table'
import { ClusterURLComp, IframeProxy } from '../../../components/ClusterUrl'
import { useTable } from '../../../hooks/useTable'
import { urlMetricsList } from '../metrics'
import { ClusterPb, ClusterUrlPb } from '../../../utils/proto'
import Clickable from '../../../components/Clickable'
import Select from '../../../components/Select'
import Loader from '../../../components/Loader'
import Tooltip from '../../../components/Tooltip'

const urlsColumnHelper = createColumnHelper<ClusterUrlPb>()
const urlsColumns = [
  urlsColumnHelper.accessor(
    d => d.url?.url?.replace('https://', '').replace('http://', '') ?? '',
    {
      header: 'URL',
      id: 'url',
      cell: cellProps => {
        return (
          <Link
            search={prev => ({
              ...prev,
              urlId: cellProps.row.original.url?.urlId,
            })}
          >
            {cellProps.getValue()}
          </Link>
        )
      },
    }
  ),
  urlsColumnHelper.accessor('page.title', { header: 'Title' }),
  ...urlMetricsList.map(metric => {
    return urlsColumnHelper.accessor(
      d => metric.getValue(d.urlMetrics!.metrics![metric.id]!),
      {
        header: metric.label,
        id: metric.id,
        cell: cellProps => metric.renderValue(cellProps.getValue()),
        meta: {
          getCellProps: () => {
            return {
              className: 'text-right',
            }
          },
        },
      }
    )
  }),
]
export function URLs(props: { cluster: ClusterPb }) {
  const search = useSearch()
  const navigate = useNavigate()
  const urlId = search.urlId ? Number(search.urlId) : undefined

  const table = useTable({
    data: props.cluster.clusterUrls,
    columns: urlsColumns,
  })

  const urlOptions = React.useMemo(() => {
    return props.cluster.clusterUrls.map(d => ({
      label: d.url?.url?.replace('https://', '').replace('http://', '') ?? '',
      value: d.url?.urlId,
    }))
  }, [props.cluster.clusterUrls])

  if (urlId) {
    const clusterUrl = props.cluster.clusterUrls.find(
      d => Number(d.url?.urlId) === urlId
    )

    return (
      <div className="space-y-2">
        <Head>
          <title>
            {`Keyword Clustering - ${
              props.cluster.name
            } - URL: ${clusterUrl?.url?.url
              .replace('https://', '')
              .replace('http://', '')}`}
          </title>
        </Head>
        <Card className="flex flex-wrap items-center gap-1 text-lg font-bold">
          <Link search={d => ({ ...d, urlId: undefined })}>URLs</Link>
          <FaAngleRight />
          <Select
            options={urlOptions}
            onChange={value => {
              navigate({
                search: d => ({
                  ...d,
                  urlId: value,
                }),
              })
            }}
            children={({ onClick }) => {
              return (
                <Clickable
                  onClick={onClick}
                  className="flex items-center gap-1"
                >
                  {clusterUrl?.url?.url ?? <Loader />} <FaCaretDown />
                </Clickable>
              )
            }}
          />
        </Card>
        <ErrorBoundary>
          {!clusterUrl ? (
            <div className="text-center">URL not found!</div>
          ) : (
            <ClusterURLComp clusterUrl={clusterUrl!} />
          )}
        </ErrorBoundary>
      </div>
    )
  }

  return (
    <div className="space-y-2">
      <Card className="divide-y divide-gray-500/20 p-0">
        <div className="p-2 text-lg font-bold">URLs</div>
        <div className="flex divide-x divide-gray-100 dark:divide-gray-850">
          <div className="min-w-0 flex-1">
            <Table table={table} />
          </div>
        </div>
      </Card>
      <Card className="flex divide-x divide-gray-500/20 overflow-x-auto p-0">
        {props.cluster.clusterUrls.map(clusterUrl => {
          return (
            <div
              key={clusterUrl.url?.url}
              className="w-[320px] flex-none divide-y divide-gray-100 dark:divide-gray-850"
            >
              <Tooltip
                tooltip={clusterUrl.url?.url}
                className="block w-full overflow-hidden text-ellipsis whitespace-nowrap px-2 py-1 text-xs font-bold"
              >
                <Anchor href={clusterUrl.url?.url} target="_blank">
                  {clusterUrl.page?.title ?? clusterUrl.url?.url}
                </Anchor>
              </Tooltip>
              <IframeProxy
                src={clusterUrl.url?.url}
                className="h-[70vh] w-full"
              />
            </div>
          )
        })}
      </Card>
    </div>
  )
}
