//
import { twMerge } from 'tailwind-merge'
import { withProps } from '../utils/withProps'

const Card = withProps('div')(({ className, ...props }) => {
  return {
    ...props,
    className: twMerge(
      `
        relative rounded-md border-0 bg-white p-2 shadow-md
        dark:border dark:border-gray-850 dark:bg-gray-900 dark:shadow-lg dark:shadow-black/80
    `,
      className
    ),
  }
})

export default Card
