import { twMerge } from 'tailwind-merge'
import { withProps } from '../utils/withProps'

export const ButtonBar = withProps('div')(({ className, ...props }) => ({
  ...props,
  className: twMerge(
    `divide inline-flex max-w-full flex-wrap gap-1 rounded-lg bg-transparent`,
    className
  ),
}))

export const tabStyles = twMerge(
  `inline-flex flex-wrap items-center gap-1 rounded-md border-gray-800 bg-white px-2 py-1
  text-sm font-bold text-blue-700 shadow-md dark:border`,
  `dark:bg-gray-500/20 dark:text-white`,
  `hover:bg-gray-500/10 dark:hover:bg-gray-500/30`,
  `[&.active]:border-blue-500 [&.active]:bg-blue-500 [&.active]:text-white`,
  'transition'
)

export const BarButton = withProps('button')(({ className, ...props }) => ({
  ...props,
  className: twMerge(tabStyles, className),
}))
