import * as Sentry from '@sentry/react'
import { HTMLProps } from 'react'
import { getSleekToken } from './Api'

//

const GA_ID = 'G-9SEV3MWY6D'
const hubspot: any = []

export function hubspotDispatch(...args: any[]) {
  // @ts-expect-error  // Property '_hsq' does not exist on type 'Window & t... Remove this comment to see the full error message
  const h = window._hsq || hubspot
  h.push(args)
}

export function setAnalyticsUser(profile: any) {
  // @ts-expect-error  // Property 'gtag' does not exist on type 'Window & t... Remove this comment to see the full error message
  if (window.gtag) {
    // @ts-expect-error  // Property 'gtag' does not exist on type 'Window & t... Remove this comment to see the full error message
    window.gtag('config', GA_ID, {
      user_id: profile.id,
    })
  }

  // // @ts-expect-error  // Property 'usetifulTags' does not exist on type 'Wi... Remove this comment to see the full error message
  // window.usetifulTags = { userId: profile.id, firstName: profile.givenName }

  // loadScript(
  //   {
  //     src: 'https://www.usetiful.com/dist/usetiful.js',
  //     id: 'usetifulScript',
  //   },
  //   script => {
  //     script.dataset.token = '18a16cc1c2fa0e90ee09c520708670ba'
  //   }
  // )

  if (
    // @ts-expect-error  // Property '$sleek' does not exist on type 'Window &... Remove this comment to see the full error message
    !window.$sleek &&
    // import.meta.env.PROD &&
    window.location.pathname !== '/paa'
  ) {
    // @ts-expect-error  // Property '$sleek' does not exist on type 'Window &... Remove this comment to see the full error message
    window.$sleek = []

    // @ts-expect-error  // Property '$sleek' does not exist on type 'Window &... Remove this comment to see the full error message
    window.$sleek.sso = function (callback: any) {
      getSleekToken().then(data => {
        const userToken = data
        callback({ token: userToken })
      })
    }

    // @ts-expect-error  // Property 'SLEEK_PRODUCT_ID' does not exist on type... Remove this comment to see the full error message
    window.SLEEK_PRODUCT_ID = 111273426

    loadScript({
      src: 'https://client.sleekplan.com/sdk/e.js',
    })
  }

  hubspotDispatch('identify', {
    email: profile.email,
    id: profile.id,
    name: profile.name,
    createdAt: profile.createdAt,
    picture: profile.picture,
  })

  trackPageVisited()

  Sentry.configureScope(function (scope) {
    scope.setUser({
      id: profile.id,
      email: profile.email,
      username: profile.name,
    })
  })
}

export function trackPageVisited() {
  hubspotDispatch('setPath', window.location.pathname + window.location.search)
  hubspotDispatch('trackPageView')
}

export function openHubSpotChat() {
  try {
    // @ts-expect-error  // Property 'HubSpotConversations' does not exist on ... Remove this comment to see the full error message
    window.HubSpotConversations.widget.open({ openToNewThread: true })
  } catch (err) {
    console.error(err)
  }
}

// ########################################################################
// Production Globals
// ########################################################################

/* eslint-disable */
if (typeof document !== 'undefined') {
  // Hubspot
  const search = new URLSearchParams(window.location.search)

  if (import.meta.env.PROD) {
    if (window.location.pathname !== '/paa' && !search.has('disableHubspot')) {
      loadScript({
        id: 'hs-script-loader',
        src: '//js.hs-scripts.com/2030303.js',
      })
      // @ts-expect-error  // Property '_hsq' does not exist on type 'Window & t... Remove this comment to see the full error message
      window._hsq = hubspot
      hubspotDispatch(
        'setPath',
        window.location.pathname + window.location.search
      )
    }
    // Google Analytics
    loadScript({
      src: `//www.googletagmanager.com/gtag/js?id=${GA_ID}`,
    })
    // @ts-expect-error  // Property 'dataLayer' does not exist on type 'Windo... Remove this comment to see the full error message
    window.dataLayer = window.dataLayer || []
    function gtag() {
      // @ts-expect-error  // Cannot find name 'dataLayer'.
      dataLayer.push(arguments)
    }
    // @ts-expect-error  // Expected 0 arguments, but got 2.
    gtag('js', new Date())
    // @ts-expect-error  // Expected 0 arguments, but got 2.
    gtag('config', GA_ID)

    // TruConversion
    // @ts-expect-error  // '_tip' implicitly has type 'any' because it does n... Remove this comment to see the full error message
    var _tip = _tip || []
    ;(function (d, s, id) {
      var js,
        tjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      // @ts-expect-error  // Property 'async' does not exist on type 'HTMLEleme... Remove this comment to see the full error message
      js.async = true
      // @ts-expect-error  // Property 'src' does not exist on type 'HTMLElement... Remove this comment to see the full error message
      js.src =
        d.location.protocol + '//app.truconversion.com/ti-js/19188/73016.js'

      tjs.parentNode.insertBefore(js, tjs)
    })(document, 'script', 'ti-js')
  }
}

function loadScript(
  attributes: HTMLProps<HTMLScriptElement>,
  beforeMount?: (el: HTMLScriptElement) => void
) {
  const script = document.createElement('script')

  Object.assign(script, {
    type: 'text/javascript',
    async: true,
    defer: true,
    ...attributes,
  })

  if (beforeMount) {
    beforeMount(script)
  }

  document.body.appendChild(script)
}
