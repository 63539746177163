import { createColumnHelper } from '@tanstack/react-table'
import moment from 'moment'
import * as React from 'react'
import { FaPlus } from 'react-icons/fa'
import Card from '../../components/Card'
import Container from '../../components/Container'
import Link from '../../components/Link'
import QueryGate from '../../components/QueryGate'
import Table from '../../components/Table'
import Tooltip from '../../components/Tooltip'
import { useTable } from '../../hooks/useTable'
import { useUsers } from '../../hooks/users'
import { User } from '../../utils/Api'
import { ButtonPlain } from '../../components/ButtonPlain'

//

export const usersLabel = <div className="pl-2 text-xl font-bold">Users</div>
export const backToUsers = <Link to="/users">{usersLabel}</Link>

const columnHelper = createColumnHelper<User>()

export function Users() {
  const usersQuery = useUsers()

  const tableData = React.useMemo(
    () => usersQuery.data?.filter(d => !d.deletedAt) ?? [],
    [usersQuery.data]
  )

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('displayName', {
        header: 'User Name',
        filterFn: 'fuzzy',
        cell: ({ getValue, row: { original } }) => (
          <Link to={`./${original.id}`}>{getValue()}</Link>
        ),
      }),
      columnHelper.accessor('email', {
        header: 'Email',
        filterFn: 'fuzzy',
      }),
      columnHelper.accessor('id', {
        header: 'ID',
      }),
      columnHelper.accessor('createdAt', {
        header: 'Date Added',
        cell: props => (
          <Tooltip tooltip={moment(props.getValue()).format('lll')}>
            {moment(props.getValue()).fromNow()}
          </Tooltip>
        ),
      }),
    ],
    []
  )

  const table = useTable({
    data: tableData,
    columns,
    initialState: {
      sorting: [{ id: 'displayName', desc: false }],
      pagination: {
        pageSize: 20,
      },
    },
  })

  return (
    <Container className="space-y-2">
      <Card className="flex flex-wrap items-center gap-2">
        {usersLabel}
        <Link to="../../invite">
          <ButtonPlain className="bg-green-500">
            <FaPlus className="inline" /> Invite Users
          </ButtonPlain>
        </Link>
      </Card>
      <Card className="divide-y divide-gray-500/20 p-0">
        <QueryGate query={usersQuery} className="p-2">
          {() => <Table table={table} />}
        </QueryGate>
      </Card>
    </Container>
  )
}
