import { useMatch, useNavigate, useSearch } from '@tanstack/react-router'
import { twMerge } from 'tailwind-merge'
import * as React from 'react'
import { FaAngleRight, FaCheck, FaTimes, FaTrash } from 'react-icons/fa'
import Card from '../../components/Card'
import { Head } from '../../components/Head'
import Select from '../../components/Select'
import { ButtonPlain } from '../../components/ButtonPlain'
import { LocationGenerics } from '../../LocationGenerics'
import {
  useMonitoredUrlQuery,
  useRemoveMonitoredUrlByIdMutation,
  useUpdateMonitoredUrlMutation,
} from '../../utils/monitoredUrls'
import { useActiveWorkspaceId } from '../../hooks/workspaces'
import QueryGate from '../../components/QueryGate'
import { useForm } from '@tanstack/react-form'
import { LimitDepth } from '../../utils/types'
import LabeledInput from '../../components/LabeledInput'
import { MonitoredUrlPicker } from '../../components/MonitoredUrlPicker'
import useConfirm from '../../hooks/useConfirm'
import { customerJourneyStageOptions } from '../../options/customerJourneyStage'
import { priorityOptions } from '../../options/priority'
import Validate from '../../utils/Validate'
import {
  PlainMessage,
  MonitoredUrlPb,
  UrlPb,
  SearchIntentTypePb,
  UpdateMonitoredUrlResponsePb,
} from '../../utils/proto'
import { ProjectPicker } from '../../components/ProjectPicker'
import { BackToMonitoredUrls } from './MonitoredUrls'
import LabeledCheckbox from '../../components/LabeledCheckbox'
import { Modal, ModalContent, ModalTitle } from '../../components/Modals'
import { BrandPicker } from '../../components/BrandPicker'
import { BRAND_TYPE_OWNED } from '../../utils/Constants'
import { BarButton, ButtonBar } from '../../components/ButtonBar'
import Link from '../../components/Link'

export function MonitoredUrl({ isNew }: { isNew?: boolean }) {
  const search = useSearch()
  const projectId = search.projectId
  const { monitoredUrlId } = useMatch().params
  const activeWorkspaceId = useActiveWorkspaceId()
  const navigate = useNavigate()
  const updateMonitoredUrlMutation = useUpdateMonitoredUrlMutation()

  const monitoredUrlQuery = useMonitoredUrlQuery({
    workspaceId: activeWorkspaceId,
    projectId,
    monitoredUrlId,
  })

  return (
    <div className="space-y-2 py-4">
      <Head>
        <title>
          Monitored URL - {monitoredUrlQuery.data?.monitoredUrl?.url?.url ?? ''}
        </title>
      </Head>
      <div className="px-4">
        <Card className="flex flex-wrap items-center gap-2">
          <ProjectPicker />
          <FaAngleRight />
          <BackToMonitoredUrls />
          <FaAngleRight />
          {isNew ? (
            <div className="text-xl font-bold">Add URL</div>
          ) : monitoredUrlId ? (
            <QueryGate query={monitoredUrlQuery} small>
              {() => (
                <span className="break-all text-sm font-bold">
                  {monitoredUrlQuery.data?.monitoredUrl?.url?.url}
                </span>
              )}
            </QueryGate>
          ) : null}
        </Card>
      </div>
      <div className="px-4">
        <ButtonBar>
          {(
            [
              {
                id: 'manage',
                label: 'Manage',
                search: prev => ({ ...prev, topicTab: 'manage' }),
              },
              { id: 'overview', label: 'Overview' },
              { id: 'topics', label: 'Topics', disabled: true },
              {
                id: 'keywords',
                label: 'Keywords',
                search: prev => ({ ...prev, topicTab: 'keywords' }),
              },
              { id: 'phrases', label: 'Phrases', disabled: true },
              { id: 'devices', label: 'Devices', disabled: true },
              { id: 'locations', label: 'Locations', disabled: true },
              { id: 'languages', label: 'Languages', disabled: true },
              { id: 'paa', label: 'PAA', disabled: true },
              { id: 'shareOfVoice', label: 'Share of Voice', disabled: true },
              { id: 'serpFeatures', label: 'SERP Features', disabled: true },
            ] satisfies {
              // id: TopicTab
              label: string
              disabled?: boolean
              search?: (
                prev: Partial<LocationGenerics['Search']>
              ) => Partial<LocationGenerics['Search']>
            }[]
          ).map(tab => {
            return (
              <Link key={tab.id} search={tab.search as any}>
                <BarButton
                  disabled={tab.disabled}
                  className={twMerge(search.topicTab === tab.id && 'active')}
                >
                  {tab.label}
                </BarButton>
              </Link>
            )
          })}
          {/* <Tab>Overview</Tab> */}
        </ButtonBar>
      </div>
      <div className="px-4">
        <Card className="">
          {isNew ? (
            <AddMonitoredUrlForm
              onSuccess={newMonitoredUrlResponse => {
                navigate({
                  to: `/monitored-urls/${newMonitoredUrlResponse.monitoredUrl?.id}`,
                })
              }}
            />
          ) : (
            <QueryGate query={monitoredUrlQuery}>
              {() => (
                <MonitoredUrlForm
                  defaultValues={monitoredUrlQuery.data?.monitoredUrl}
                  onSubmit={values => {
                    updateMonitoredUrlMutation.mutate({
                      monitoredUrl: values,
                    })
                  }}
                />
              )}
            </QueryGate>
          )}
        </Card>
      </div>
    </div>
  )
}

export function AddMonitoredUrlForm({
  onSuccess,
  onError,
  onCancel,
  defaultValues,
}: {
  onSuccess?: (updateMonitoredUrlResponse: UpdateMonitoredUrlResponsePb) => void
  onError?: (err: any) => void
  onCancel?: () => void
  defaultValues?: Partial<PlainMessage<MonitoredUrlPb>>
}) {
  const updateMonitoredUrlMutation = useUpdateMonitoredUrlMutation()
  return (
    <MonitoredUrlForm
      onSubmit={values => {
        updateMonitoredUrlMutation.mutate(
          {
            allowMissing: true,
            monitoredUrl: values,
          },
          {
            onSuccess,
            onError,
          }
        )
      }}
      defaultValues={defaultValues}
      onCancel={onCancel}
    />
  )
}

export function AddMonitoredUrlModal({
  className,
  ...rest
}: React.ComponentProps<typeof AddMonitoredUrlForm> &
  React.HTMLProps<HTMLDivElement>) {
  return (
    <Modal>
      <ModalTitle>Add Monitored URL</ModalTitle>
      <ModalContent className="p-2">
        <AddMonitoredUrlForm {...rest} />
      </ModalContent>
    </Modal>
  )
}

export function MonitoredUrlForm(props: {
  defaultValues?: Partial<PlainMessage<MonitoredUrlPb>>
  onSubmit: (values: Partial<PlainMessage<MonitoredUrlPb>>) => void
  onCancel?: () => void
}) {
  const search = useSearch()
  const activeWorkspaceId = useActiveWorkspaceId()!
  const projectId = search.projectId!
  const removeMonitoredUrlByIdMutation = useRemoveMonitoredUrlByIdMutation()
  const confirm = useConfirm()
  const navigate = useNavigate()

  const form = useForm<LimitDepth<PlainMessage<MonitoredUrlPb>>>({
    defaultValues: React.useMemo(
      (): PlainMessage<MonitoredUrlPb> =>
        new MonitoredUrlPb({
          url: new UrlPb({}),
          ...props.defaultValues,
          workspaceId: BigInt(activeWorkspaceId),
          projectId: BigInt(projectId),
        }),
      [activeWorkspaceId, projectId, props.defaultValues]
    ),
    onSubmit: props.onSubmit,
  })

  const deleteMonitoredUrl = async () => {
    if (
      props.defaultValues?.id &&
      (await confirm({
        message: `Are you sure you want to remove the monitoredUrl "${props.defaultValues?.name}"?`,
      }))
    ) {
      await removeMonitoredUrlByIdMutation.mutateAsync({
        workspaceId: BigInt(activeWorkspaceId),
        projectId: BigInt(projectId),
        monitoredUrlId: props.defaultValues?.id,
      })

      navigate({
        to: '/monitored-urls',
      })
    }
  }

  const searchIntentOptions = React.useMemo(
    () => [
      { value: SearchIntentTypePb.UNSPECIFIED, label: 'Select One...' },
      { value: SearchIntentTypePb.INFORMATIONAL, label: 'Informational' },
      { value: SearchIntentTypePb.NAVIGATIONAL, label: 'Navigational' },
      { value: SearchIntentTypePb.TRANSACTIONAL, label: 'Transactional' },
      { value: SearchIntentTypePb.COMMERCIAL, label: 'Commercial' },
      { value: SearchIntentTypePb.LOCAL, label: 'Local' },
    ],
    []
  )

  return (
    <form.Form className="space-y-2">
      <form.Field
        name="brandId"
        validate={Validate.required()}
        children={field => {
          return (
            <BrandPicker
              label="Brand*"
              placeholder="Select a Brand..."
              error={field.state.meta.touchedError}
              value={String(field.getValue() || '')}
              onChange={next => field.setValue(BigInt(next))}
              disabled={props.defaultValues?.id}
              filterOptions={options => {
                return options.filter(
                  option => option.brand.type === BRAND_TYPE_OWNED
                )
              }}
              closeOnSelect
              className="flex-1"
            />
          )
        }}
      />
      <form.Field
        name="url.url"
        validate={Validate.required()}
        children={field => {
          return (
            <LabeledInput
              label="URL*"
              placeholder="e.g. https://mysite.com/my-page"
              error={field.state.meta.touchedError}
              {...field.getInputProps({
                // @ts-ignore
                required: true,
              })}
              disabled={props.defaultValues?.id}
            />
          )
        }}
      />
      <form.Field
        name="parentMonitoredUrlId"
        children={field => {
          const idField = form.useField({
            name: 'id',
          })

          return (
            <MonitoredUrlPicker
              projectId={projectId}
              label="Parent Monitored URL"
              error={field.state.meta.touchedError}
              className="flex-1"
              value={field.getValue() ? String(field.getValue()) : ''}
              onChange={value => field.setValue(BigInt(value))}
              excludeMonitoredUrlIds={[String(idField.state.value)]}
              allowEmpty
              // children={({ onClick }) => {
              //   return (
              //     <LabelWrap
              //     >
              //       <ButtonPlain className="bg-transparent text-left text-sm font-bold lowercase text-black">
              //         {field.getValue() || 'Select a Parent Monitored URL...'}
              //       </ButtonPlain>
              //     </LabelWrap>
              //   )
              // }}
            />
          )
        }}
      />
      <form.Field
        name="priority"
        children={field => {
          return (
            <Select
              label="Priority"
              error={field.state.meta.touchedError}
              options={priorityOptions}
              {...field.getChangeProps()}
            />
          )
        }}
      />
      <form.Field
        name="customerJourneyStage"
        children={field => {
          return (
            <Select
              label="Customer Journey Stage"
              error={field.state.meta.touchedError}
              options={customerJourneyStageOptions}
              {...field.getChangeProps()}
            />
          )
        }}
      />
      <form.Field
        name="primarySearchIntent"
        children={field => {
          return (
            <Select
              label="Primary Search Intent"
              error={field.state.meta.touchedError}
              options={searchIntentOptions}
              {...field.getChangeProps()}
            />
          )
        }}
      />
      <form.Field
        name="secondarySearchIntent"
        children={field => {
          return (
            <Select
              label="Secondary Search Intent"
              error={field.state.meta.touchedError}
              options={searchIntentOptions}
              {...field.getChangeProps()}
            />
          )
        }}
      />
      <form.Field
        name="isVirtual"
        children={field => {
          return (
            <LabeledCheckbox
              label="Virtual URL"
              error={field.state.meta.touchedError}
              {...field.getChangeProps()}
            />
          )
        }}
      />
      <div className="mt-2 flex gap-2">
        <ButtonPlain className="bg-green-500" type="submit">
          <FaCheck /> Save URL
        </ButtonPlain>
        {props.onCancel ? (
          <ButtonPlain
            className="bg-gray-500 hover:bg-red-500"
            onClick={props.onCancel}
          >
            <FaTimes /> Cancel
          </ButtonPlain>
        ) : props.defaultValues?.id ? (
          <ButtonPlain
            className="bg-gray-500 hover:bg-red-500"
            onClick={deleteMonitoredUrl}
          >
            <FaTrash /> Delete URL
          </ButtonPlain>
        ) : null}
      </div>
    </form.Form>
  )
}
