import * as React from 'react'
import { useRollupOptions, rollupDelimiter } from '../components/RollupPicker'
import { useLocalStorageSearchPersisterState } from '../components/useSearchState'
import {
  useKeywordGroupOptionsQuery,
  useKeywordOptionsQuery,
} from '../hooks/keywords'
import { useProjectOptionsQuery } from '../hooks/projects'
import { useSegmentOptionsQuery } from '../hooks/segments'
import { useActiveWorkspaceId } from '../hooks/workspaces'
import { KeywordGroupAllKeywords } from './Constants'
import { MetricId } from './Metrics'
import { featuredSnippetSegmentOptions } from './featuredSnippets'
import { QueryFacetId } from './queryFacets'

export type ActiveProjectId = string

export function useActiveProjectIdState() {
  const state = useLocalStorageSearchPersisterState<ActiveProjectId>({
    path: 'projectId',
    useDeps: () => {
      return [useActiveWorkspaceId()]
    },
    useDefaultValue: () => {
      const projectOptionsQuery = useProjectOptionsQuery()
      return projectOptionsQuery.data?.[0]?.value as any
    },
    useIsInvalid: value => {
      const workspaceId = useActiveWorkspaceId()
      const projectOptionsQuery = useProjectOptionsQuery()

      return (
        projectOptionsQuery.data.length &&
        projectOptionsQuery.data[0]?.project.workspaceId === workspaceId &&
        !projectOptionsQuery.data.find(d => d.value === value)
      )
    },
  })

  return state
}

export type SearchRollup = string | undefined

export function useRollupState() {
  return useLocalStorageSearchPersisterState<SearchRollup>({
    path: 'rollup',
    useDeps: () => {
      return [useActiveWorkspaceId(), useActiveProjectIdState().state]
    },
    useDefaultValue: ({ search }) => {
      const searchRollup = search.rollup
      const rollupOptions = useRollupOptions()
      return searchRollup
        ? rollupOptions?.find(d => d.value.startsWith(searchRollup))?.value ||
            rollupOptions?.[0]?.value
        : rollupOptions?.[0]?.value
    },
    useIsInvalid: value => {
      const rollupOptions = useRollupOptions()

      return (
        rollupOptions?.length &&
        (!value ||
          !value.includes(rollupDelimiter) ||
          !rollupOptions?.find(d => d.value === value))
      )
    },
  })
}
export type SearchGroupBy = QueryFacetId

export function useGroupByState() {
  return useLocalStorageSearchPersisterState<SearchGroupBy>({
    path: 'groupBy',
    useDeps: () => {
      return [useActiveWorkspaceId(), useActiveProjectIdState().state]
    },
    useDefaultValue: () => {
      return 'brandType'
    },
  })
}

export type SearchSegmentId = string

export function useSegmentIdState() {
  return useLocalStorageSearchPersisterState<SearchSegmentId>({
    path: 'segmentId',
    useDeps: () => {
      return [useActiveWorkspaceId(), useActiveProjectIdState().state]
    },
    useDefaultValue: () => {
      return '1'
    },
    useIsInvalid: value => {
      const optionsQuery = useSegmentOptionsQuery()

      return (
        value !== '1' &&
        optionsQuery.data.length &&
        !optionsQuery.data.find((d: any) => d.value === value)
      )
    },
  })
}

export type SearchKeywordId = string | undefined

export function useKeywordIdState() {
  return useLocalStorageSearchPersisterState<SearchKeywordId>({
    path: 'keywordId',
    useDeps: () => {
      return [useActiveWorkspaceId(), useActiveProjectIdState().state]
    },
    useDefaultValue: () => {
      const optionsQuery = useKeywordOptionsQuery()
      return optionsQuery.data?.[0]?.value
    },
    useIsInvalid: value => {
      const optionsQuery = useKeywordOptionsQuery()

      return (
        optionsQuery.data.length &&
        !optionsQuery.data.find((d: any) => d.value === value)
      )
    },
  })
}
export type SearchKeywordGroup = string

export function useKeywordGroupState() {
  return useLocalStorageSearchPersisterState<SearchKeywordGroup>({
    path: 'keywordGroup',
    useDeps: () => {
      return [useActiveWorkspaceId(), useActiveProjectIdState().state]
    },
    useDefaultValue: () => {
      return KeywordGroupAllKeywords
    },
    useIsInvalid: value => {
      const optionsQuery = useKeywordGroupOptionsQuery()

      return (
        optionsQuery.data?.length &&
        value !== KeywordGroupAllKeywords &&
        !optionsQuery.data?.find((d: any) => d.value === value)
      )
    },
  })
}

export type SearchFeaturedSnippetSegmentId = string | undefined

export function useFeaturedSnippetSegmentIdState() {
  return useLocalStorageSearchPersisterState<SearchFeaturedSnippetSegmentId>({
    path: 'featuredSnippetSegmentId',
    useDeps: () => {
      return [useActiveWorkspaceId(), useActiveProjectIdState().state]
    },
    useDefaultValue: () => {
      const options = featuredSnippetSegmentOptions
      return options[0]!.value
    },
    useIsInvalid: value => {
      const options = featuredSnippetSegmentOptions
      return !options.find(d => d.value === value)
    },
  })
}

export type SearchMonitoredUrlsTab =
  | undefined
  | 'manage'
  | 'overview'
  | 'priority'
  | 'stage'
  | 'intent'

export function useMonitoredUrlsTab() {
  return useLocalStorageSearchPersisterState<SearchMonitoredUrlsTab>({
    path: 'monitoredUrlsTab',
    useDeps: () => {
      return [useActiveWorkspaceId(), useActiveProjectIdState().state]
    },
    useDefaultValue: () => 'manage',
  })
}

export const monitoredUrlsTabOptions = [
  { id: 'manage', label: 'Manage' },
  { id: 'overview', label: 'Overview' },
  { id: 'priority', label: 'Priority' },
  { id: 'stage', label: 'Stage' },
  { id: 'intent', label: 'Intent' },
] satisfies {
  id: SearchMonitoredUrlsTab
  label: string
  disabled?: boolean
}[]

export const useMetricIdState = <TMetricId extends MetricId>(
  metricIds: TMetricId[]
) => {
  return useLocalStorageSearchPersisterState<TMetricId>({
    path: 'metricId',
    useDefaultValue: () => metricIds[0]!,
    useIsInvalid: value => {
      return !metricIds.includes(value)
    },
  })
}

export const useSyncMetricIdState = <TMetricId extends MetricId>(
  metricIds: TMetricId[],
  metricId: TMetricId
) => {
  const metricIdState = useMetricIdState(metricIds)

  React.useLayoutEffect(() => {
    metricIdState.setState(metricId)
  }, [metricIdState, metricId])
}
