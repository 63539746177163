export default {
  importFromFile,
  saveToFile,
}

function getXlsx() {
  return import('xlsx')
}

async function importFromFile(file: any, { header = 1, ...rest } = {}) {
  const xlsx = await getXlsx()
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    const rABS = !!reader.readAsBinaryString

    reader.onload = function (e) {
      // @ts-expect-error  // Object is possibly 'null'.
      const bstr = e.target.result
      const wb = xlsx.read(bstr, { type: rABS ? 'binary' : 'array' })
      const wsname = wb.SheetNames[0]
      // @ts-expect-error  // Type 'undefined' cannot be used as an index type.
      const ws = wb.Sheets[wsname]
      let data = xlsx.utils.sheet_to_json(ws, { header, ...rest })
      const [headers, ...rows] = data
      const obj = {}
      data = []
      rows.forEach(row => {
        // @ts-expect-error  // Object is of type 'unknown'.
        if (!row || !row.length) {
          return
        }
        // @ts-expect-error  // Object is of type 'unknown'.
        headers.forEach((header: any, i: any) => {
          // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          obj[header] = row[i]
        })
        data.push({ ...obj })
      })
      resolve(data)
    }

    reader.onerror = function (err) {
      reject(err)
    }

    reader.readAsBinaryString(file)
  })
}

async function saveToFile(
  name: any,
  data: any,
  toSheetOptions = {},
  writeOptions = {}
) {
  const xlsx = await getXlsx()
  const wb = xlsx.utils.book_new()
  const ws = xlsx.utils.json_to_sheet(data, toSheetOptions)
  xlsx.utils.book_append_sheet(wb, ws, name)
  xlsx.writeFile(wb, `${name}.xlsx`, writeOptions)
}
