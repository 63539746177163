import { DevicePb } from './proto'

export type DeviceModelNz = {
  device: DevicePb
  mostFrequentScheduleId: string
}

export function deviceCodeToDevicePb(code: string): DevicePb {
  return (
    {
      d: DevicePb.DESKTOP_WINDOWS,
      t: DevicePb.TABLET_ANDROID,
      p: DevicePb.TABLET_IOS,
      m: DevicePb.MOBILE_ANDROID,
      i: DevicePb.MOBILE_IOS,
    }[code] || DevicePb.UNSPECIFIED
  )
}

export function devicePbToDeviceCode(device: DevicePb): string {
  return (
    (
      {
        [DevicePb.DESKTOP_WINDOWS]: 'd',
        [DevicePb.TABLET_ANDROID]: 't',
        [DevicePb.TABLET_IOS]: 'p',
        [DevicePb.MOBILE_ANDROID]: 'm',
        [DevicePb.MOBILE_IOS]: 'i',
      } as const
    )[device as number] || 'unknown device'
  )
}
