export const ImportSourceOptions: any = [
  // {
  //   label: 'Accuranker',
  //   value: 'accuranker',
  // },
  {
    label: 'Google Search Console',
    value: 'googleSearchConsole',
  },
  // {
  //   label: 'Keyword.com',
  //   value: 'keyword',
  // },
  // {
  //   label: 'RankWatch',
  //   value: 'rankWatch',
  // },
  // {
  //   label: 'Serpstat',
  //   value: 'serpstat',
  // },
  // {
  //   label: 'SERPWoo',
  //   value: 'serpwoo',
  // },
  // {
  //   label: 'SEOmonitor',
  //   value: 'seoMonitor',
  // },
]
