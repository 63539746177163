import { PlainMessage } from '@bufbuild/protobuf'
import { createColumnHelper } from '@tanstack/react-table'
import { twMerge } from 'tailwind-merge'
import moment from 'moment'
import React from 'react'
import { FaCircle } from 'react-icons/fa'
import { useMutation } from 'react-query'
import Card from '../../../components/Card'
import Clickable from '../../../components/Clickable'
import QueryGate from '../../../components/QueryGate'
import Separator from '../../../components/Separator'
import Spinner from '../../../components/Spinner'
import Table from '../../../components/Table'
import Tooltip from '../../../components/Tooltip'
import { formatInvoiceState, useInvoices } from '../../../hooks/invoices'
import { useTable } from '../../../hooks/useTable'
import { useActiveWorkspaceId } from '../../../hooks/workspaces'
import { moneyToFloat } from '../../../utils/money'
import { datePbToDate } from '../../../utils/protoDate'
import { InvoicePb, InvoiceStatePb, InvoicesClient } from '../../../utils/proto'

const columnHelper = createColumnHelper<PlainMessage<InvoicePb>>()

export default function Invoices() {
  const invoicesQuery = useInvoices()
  const activeWorkspaceId = useActiveWorkspaceId()

  const columns = React.useMemo(
    () => [
      columnHelper.display({
        id: 'circle',
        meta: {
          tight: true,
        },
        cell: props => {
          const original = props.row.original
          const invoiceState = original.state

          return (
            <Tooltip tooltip={formatInvoiceState(invoiceState)}>
              <FaCircle
                className={twMerge({
                  'text-green-500': invoiceState === InvoiceStatePb.PAID,
                  'text-orange-500': invoiceState === InvoiceStatePb.POSTED,
                  'text-yellow-500': invoiceState === InvoiceStatePb.PENDING,
                  'text-red-500':
                    invoiceState === InvoiceStatePb.NOT_PAID ||
                    invoiceState === InvoiceStatePb.PAYMENT_DUE,
                  'text-grey-500': invoiceState === InvoiceStatePb.VOIDED,
                })}
              />
            </Tooltip>
          )
        },
      }),
      columnHelper.accessor('invoiceId', {
        header: 'Invoice #',
        meta: {
          tight: true,
        },
        filterFn: 'fuzzy',
        cell: function Cell(props) {
          const invoiceId = props.getValue()

          const mutation = useMutation(
            () =>
              InvoicesClient.getInvoicePdf({
                // @ts-expect-error  // Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
                workspaceId: BigInt(activeWorkspaceId),
                invoiceId,
              }),
            {
              onSuccess: data => {
                window.open(data.url, '_blank')
              },
            }
          )

          return (
            <Clickable
              onClick={() => mutation.mutate()}
              disabled={mutation.isLoading}
              className="flex items-center gap-2 "
            >
              Invoice # {invoiceId} {mutation.isLoading ? <Spinner /> : null}
            </Clickable>
          )
        },
      }),
      columnHelper.accessor(d => formatInvoiceState(d.state), {
        header: 'Status',
        meta: {
          tight: true,
        },
        filterFn: 'equals',
        cell: props => {
          const value = props.getValue()
          return <div>{value}</div>
        },
      }),
      columnHelper.accessor(d => d.date, {
        header: 'Date',
        meta: {
          tight: true,
        },
        filterFn: 'equals',
        cell: props => {
          // @ts-expect-error  // Argument of type 'PlainMessage<Date> | undefined' ... Remove this comment to see the full error message
          const date = moment(datePbToDate(props.getValue())).format(
            'MM/DD/YYYY'
          )

          return <div>{date}</div>
        },
      }),
      columnHelper.accessor(d => d.dueDate, {
        header: 'Due Date',
        meta: {
          tight: true,
        },
        filterFn: 'equals',
        cell: props => {
          // @ts-expect-error  // Argument of type 'PlainMessage<Date> | undefined' ... Remove this comment to see the full error message
          const date = moment(datePbToDate(props.getValue())).format(
            'MM/DD/YYYY'
          )

          return <div>{date}</div>
        },
      }),
      // columnHelper.accessor('', {
      //   header: 'SERPs',
      //   meta: {
      //     tight: true,
      //   },
      //   filterFn: 'inNumberRange',
      //   cell: props => (
      //     <div>{props.getValue() ? formatNumber(+props.getValue()) : 0}</div>
      //   ),
      // }),
      // @ts-expect-error  // Argument of type 'PlainMessage<Money> | undefined'... Remove this comment to see the full error message
      columnHelper.accessor(row => moneyToFloat(row.total), {
        header: 'Total',
        meta: {
          tight: true,
          getCellProps: () => {
            return {
              className: 'text-right',
            }
          },
        },
        filterFn: 'inNumberRange',
        cell: props =>
          props.getValue() ? (
            <div>
              {props.getValue().toLocaleString(undefined, {
                style: 'currency',
                currency: 'USD',
              })}
            </div>
          ) : (
            '$0.00'
          ),
      }),
    ],
    [activeWorkspaceId]
  )

  const tableData = React.useMemo(
    () => invoicesQuery.data?.invoices ?? [],
    [invoicesQuery.data?.invoices]
  )

  const table = useTable({
    data: tableData,
    columns,
    initialState: {
      sorting: [{ id: 'Date', desc: true }],
    },
  })

  return (
    <Card className="p-0">
      <div className="p-4">
        <div className="text-xl font-bold">Invoices</div>
      </div>
      <Separator />
      <QueryGate query={invoicesQuery} className="m-2">
        {!tableData.length ? (
          <div className="p-2 italic opacity-50">
            No invoices have been created yet.
          </div>
        ) : (
          <Table table={table} />
        )}
      </QueryGate>
    </Card>
  )
}
