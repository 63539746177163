// @generated by protoc-gen-connect-web v0.11.0 with parameter "target=ts"
// @generated from file nozzle/billing/v1/payment.proto (package nozzle.billing.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AddCardByTokenRequest, AddCardByTokenResponse } from "./payment_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * PaymentsService provides APIs for interacting with billing cards.
 *
 * @generated from service nozzle.billing.v1.PaymentsService
 */
export const PaymentsService = {
  typeName: "nozzle.billing.v1.PaymentsService",
  methods: {
    /**
     * AddCardByToken updates the specified card.
     *
     * @generated from rpc nozzle.billing.v1.PaymentsService.AddCardByToken
     */
    addCardByToken: {
      name: "AddCardByToken",
      I: AddCardByTokenRequest,
      O: AddCardByTokenResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

