import * as React from 'react'
import { useForm } from 'react-form'
import { FaCheck } from 'react-icons/fa'
import { countryOptions } from '../options/countryOptions'
import { useTaxIdTypesQuery } from '../utils/taxIds'
// import { taxIdCountryOptions } from '../options/taxIdCountryOptions'
import { useAccount, useUpdateBillingDetailsMutation } from '../hooks/account'
import { useActiveWorkspaceId } from '../hooks/workspaces'
import { AccountViewPb } from '../utils/proto'
import Button from './Button'
import Checkbox from './Checkbox'
import Loader from './Loader'
import Select from './Select'
import SelectField from './SelectField'
import Spinner from './Spinner'
import TextField from './TextField'

//

export function BillingDetailsForm({ onSubmit }: { onSubmit?: () => void }) {
  const activeWorkspaceId = useActiveWorkspaceId()
  const accountQuery = useAccount({ view: AccountViewPb.FULL })
  const saveBillingDetailsMutation = useUpdateBillingDetailsMutation()
  const taxIdTypesQuery = useTaxIdTypesQuery()

  const {
    Form,
    meta: { isSubmitting },
    setFieldValue,
    // pushFieldValue,
    // removeFieldValue,
    values,
  } = useForm({
    defaultValues: React.useMemo(() => {
      return {
        company: accountQuery.data?.billingDetails?.company,
        email: accountQuery.data?.billingDetails?.email,
        givenName: accountQuery.data?.billingDetails?.givenName,
        familyName: accountQuery.data?.billingDetails?.familyName,
        phone: accountQuery.data?.billingDetails?.phone?.kind.value,
        address1: accountQuery.data?.billingDetails?.address?.addressLines[0],
        address2: accountQuery.data?.billingDetails?.address?.addressLines[1],
        locality: accountQuery.data?.billingDetails?.address?.locality,
        administrativeArea:
          accountQuery.data?.billingDetails?.address?.administrativeArea,
        regionCode:
          accountQuery.data?.billingDetails?.address?.regionCode.toLowerCase(),
        postalCode: accountQuery.data?.billingDetails?.address?.postalCode,
        taxIdTypeId: accountQuery.data?.billingDetails?.taxIdTypeId,
        taxId: accountQuery.data?.billingDetails?.taxId,
        noTaxId: accountQuery.data?.billingDetails?.noTaxId ?? false,
      }
    }, [accountQuery.data]),
    onSubmit: async (values: any) => {
      await saveBillingDetailsMutation.mutateAsync({
        // @ts-expect-error  // Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
        workspaceId: BigInt(activeWorkspaceId),
        billingDetails: {
          email: values.email,
          givenName: values.givenName,
          familyName: values.familyName,
          company: values.company,
          phone: {
            kind: {
              case: 'e164Number',
              value: values.phone,
            },
          },
          address: {
            addressLines: [values.address1, values.address2],
            locality: values.locality,
            administrativeArea: values.administrativeArea,
            regionCode: values.regionCode.toUpperCase(),
            postalCode: values.postalCode,
          },
          taxIdTypeId: values.taxIdTypeId,
          taxId: values.taxId,
          noTaxId: values.noTaxId ?? false,
        },
      })

      onSubmit?.()
    },
  })

  const taxIdTypes = React.useMemo(
    () =>
      taxIdTypesQuery.data?.taxIdTypes?.filter(
        d => d.countryCode === values.regionCode
      ),
    [taxIdTypesQuery.data?.taxIdTypes, values.regionCode]
  )

  const taxIdTypeOptions = React.useMemo(
    () =>
      taxIdTypes?.map(taxIdType => ({
        label: taxIdType.name,
        value: taxIdType.id,
      })),
    [taxIdTypes]
  )

  React.useEffect(() => {
    if (
      !values.taxIdTypeId ||
      !taxIdTypes?.find(d => d.id === values.taxIdTypeId)
    ) {
      setFieldValue('taxIdTypeId', taxIdTypes?.[0]?.id)
    }
  }, [setFieldValue, taxIdTypes, values.taxIdTypeId])

  const taxIdType = taxIdTypes?.find(d => d.id === values.taxIdTypeId)

  if (taxIdTypesQuery.isLoading) {
    return <Loader />
  }

  return (
    <Form>
      <div className="text-xl font-bold">Billing Details</div>
      <div className="h-2" />
      <TextField
        field="company"
        label="Company Name"
        placeholder="Company Name..."
        required
      />
      <div className="h-2" />
      <TextField
        field="givenName"
        label="First Name"
        placeholder="Contact Name..."
        required
      />
      <div className="h-2" />
      <TextField
        field="familyName"
        label="Last Name"
        placeholder="Contact Name..."
        required
      />
      <div className="h-2" />
      {/* <TextField
          field="customerRef"
          label="Customer Reference"
          placeholder="Reference..."
          required
          maxLength={50}
        />
      <Caption>
        <FaInfoCircle className="inline" /> Use a customer reference to add a
        personal reference to your invoices.
      </Caption>
      <div className="h-2" />
      */}
      <TextField
        field="email"
        label="Email"
        placeholder="Email..."
        type="email"
        required
      />
      <div className="h-2" />
      <TextField
        field="phone"
        label="Phone"
        placeholder="Phone Number..."
        required
      />
      <div className="h-2" />
      <TextField
        field="address1"
        label="Address 1"
        placeholder="Address..."
        required
      />
      <div className="h-2" />
      <TextField field="address2" label="Address 2" placeholder="Address..." />
      <div className="h-2" />
      <TextField field="locality" label="City" placeholder="City..." required />
      <div className="h-2" />
      <TextField
        field="administrativeArea"
        label="State / Province"
        placeholder="State or Province..."
        required
      />
      <div className="h-2" />
      <SelectField
        label="Country"
        field="regionCode"
        options={countryOptions}
        required
      />
      <div className="h-2" />
      <TextField
        field="postalCode"
        label="Postal Code"
        placeholder="Enter a Postal Code..."
        required
      />
      <div className="h-2" />
      {(taxIdTypes?.length ?? 0) > 1 ? (
        <>
          <Select
            className="pr-1"
            label="Tax ID Type"
            options={taxIdTypeOptions}
            value={values.taxIdTypeId}
            onChange={val => setFieldValue('taxIdTypeId', val)}
          />
          <div className="h-2" />
        </>
      ) : null}
      {!taxIdType ? (
        <TextField
          field={`taxId`}
          label={'Tax ID #'}
          placeholder={'12-3456789'}
          disabled={values.noTaxId}
        />
      ) : (
        <TextField
          field={`taxId`}
          label={taxIdType?.name ?? 'Tax Id'}
          placeholder={taxIdType?.example}
          disabled={values.noTaxId}
        />
      )}
      <div className="h-2" />
      <label className="ml-2 flex gap-2">
        <Checkbox
          checked={values.noTaxId ?? false}
          onChange={val => setFieldValue('noTaxId', val)}
        />{' '}
        I do not have a {taxIdType?.name ?? 'Tax ID #'}, or do not wish to
        provide one.
      </label>
      <div className="h-4" />
      <Button
        type="submit"
        color="green-500"
        size="base"
        disabled={isSubmitting}
        className="flex items-center gap-2"
      >
        {isSubmitting ? (
          <>
            <Spinner color="white" /> Updating Billing Information
          </>
        ) : (
          <>
            <FaCheck className="inline" /> Update Billing Information
          </>
        )}
      </Button>{' '}
    </Form>
  )
}
