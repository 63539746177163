import * as React from 'react'
import { useField, useForm } from 'react-form'
import { FaCheck, FaCog, FaTrashAlt } from 'react-icons/fa'
import Button from '../components/Button'
import Spinner from '../components/Spinner'
import TextField from '../components/TextField'
import useSlugGeneration from '../hooks/useSlugGeneration'
import Validate from '../utils/Validate'
import { useProfileQuery } from '../hooks/profile'

//

type Props = {
  workspace: any
  onSubmit: any
  onRemove?: any
}

export default function WorkspaceForm({
  workspace,
  onSubmit,
  onRemove,
}: Props) {
  const isAdmin = useProfileQuery().data?.isAdmin

  const {
    Form,
    values,
    meta: { isSubmitting },
    formContext,
  } = useForm({
    defaultValues: workspace,
    onSubmit,
  })

  const nameField = useField('name', { formContext })
  const slugField = useField('slug', { formContext })

  useSlugGeneration(!workspace, nameField, slugField)

  return (
    <Form>
      <TextField
        field="name"
        validate={Validate.workspaceName()}
        label="Workspace Name"
        placeholder="My Workspace"
        autoFocus
        className="w-80 flex-none"
      />
      <div className="h-2" />
      {values.id ? (
        <>
          <TextField
            field="id"
            label="Workspace ID"
            placeholder="-"
            disabled
            className="w-80 flex-none"
          />
          <div className="h-2" />
        </>
      ) : null}
      <TextField
        field="slug"
        validate={Validate.workspaceSlug()}
        label="Workspace Slug"
        placeholder="myworkspace"
        disabled={!!workspace}
        className="w-80 flex-none"
      />
      <div className="h-4" />
      <div className="flex gap-2">
        <Button type="submit" size="base" color="green-500">
          {isSubmitting ? (
            <span>
              <Spinner color="white" />{' '}
              {workspace ? 'Saving Workspace' : 'Creating Workspace'}
            </span>
          ) : (
            <span>
              <FaCheck className="inline" />{' '}
              {workspace ? 'Save Workspace' : 'Create Workspace'}
            </span>
          )}
        </Button>
        {isAdmin && onRemove ? (
          <Button
            size="base"
            color="gray-500"
            hoverColor="red-500"
            onClick={onRemove}
          >
            <FaTrashAlt className="inline" /> Remove Workspace
          </Button>
        ) : null}
      </div>
    </Form>
  )
}
