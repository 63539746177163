export const numberOperatorOptions = [
  {
    label: '= Equals',
    value: '=',
  },
  {
    label: '!= Does not equal',
    value: '!=',
  },
  {
    label: '< Less Than',
    value: '<',
  },
  {
    label: '> Greater Than',
    value: '>',
  },
  {
    label: '<= Less Than/Equals',
    value: '<=',
  },
  {
    label: '>= Greater Than/Equals',
    value: '>=',
  },
  { label: 'is', value: 'is' },
  {
    label: '!is',
    value: '!is',
  },
]
