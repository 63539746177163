import {
  NO_DEVICE_NAME,
  NO_GROUP_NAME,
  NO_LOCALE_NAME,
} from '../utils/Constants'
import makeMemoCache from '../utils/makeMemoCache'
import { PhraseGroup, PhraseGroupExt } from './Api'

export type PhraseTemplate = {
  template: string
  groups?: string[]
  mostFrequentScheduleId: string
}

export type ModelPhraseGroup = PhraseGroup & {
  mostFrequentScheduleId: string
}

export const dedupePhraseGroups = makeMemoCache(
  (phraseGroups: Partial<PhraseGroup>[]) => {
    const uniquePhrases: Record<string, string> = {}
    const phraseGroupsByPhrase: Record<string, PhraseGroupExt> = {}
    const phraseGroupsByGroup: Record<string, PhraseGroupExt> = {}

    phraseGroups.forEach(phraseGroup => {
      if (!phraseGroup.phrase) {
        return
      }

      let phrase = phraseGroup.phrase.trim()
      const loweredPhrase = phrase.toLowerCase()

      if (!uniquePhrases[loweredPhrase]) {
        uniquePhrases[loweredPhrase] = phrase
        phraseGroupsByPhrase[phrase] = {
          groups: new Set(),
          displayGroups: new Set(),
        }
      }

      phrase = uniquePhrases[loweredPhrase]!

      //
      ;(phraseGroup.groups?.length
        ? phraseGroup.groups
        : [NO_GROUP_NAME]
      ).forEach(group => {
        if (group && group !== NO_GROUP_NAME) {
          group = group.trim()
          phraseGroupsByPhrase[phrase]!.groups.add(group)
        }
        phraseGroupsByPhrase[phrase]!.displayGroups.add(group)

        // @ts-expect-error  // Type 'PhraseGroupExt | never[]' is not assignable ... Remove this comment to see the full error message
        phraseGroupsByGroup[group] = phraseGroupsByGroup[group] || []

        if (!phraseGroupsByGroup[group].includes(phrase)) {
          phraseGroupsByGroup[group].push(phrase)
        }
      })
    })

    return {
      phraseGroups: Object.entries(phraseGroupsByPhrase).map(
        ([phrase, { groups, displayGroups }]) => {
          return {
            phrase,
            groups: [...groups].sort(),
            displayGroups: [...displayGroups].sort(),
          }
        }
      ) as PhraseGroup[],
      phrases: phraseGroupsByPhrase,
      groups: phraseGroupsByGroup,
      groupOptions: Object.keys(phraseGroupsByGroup).map(d => ({
        value: d,
        label: d,
      })),
    }
  }
)

export const dedupeKeywords = makeMemoCache(keywords => {
  const keywordsByPhrase = Object.create(null) as Record<string, any>
  const keywordsByGroup = Object.create(null) as Record<string, any>
  const keywordsByDevice = Object.create(null) as Record<string, any>
  const keywordsByLocaleId = Object.create(null) as Record<string, any>
  const uniqueKeywords = Object.create(null)

  //
  ;(keywords || []).forEach((keyword: any) => {
    if (!keyword.phrase || typeof keyword.phrase !== 'string') {
      return
    }

    let phrase = keyword.phrase.trim()
    const loweredPhrase = phrase.toLowerCase()

    if (!uniqueKeywords[loweredPhrase]) {
      uniqueKeywords[loweredPhrase] = phrase
      keywordsByPhrase[phrase] = keywordsByPhrase[phrase] || {
        groups: new Set(),
        displayGroups: new Set(),
        devices: new Set(),
        displayDevices: new Set(),
        localeIds: new Set(),
        displayLocaleIds: new Set(),
      }
    }

    phrase = uniqueKeywords[loweredPhrase]

    //
    ;(keyword.groups?.length ? keyword.groups : [NO_GROUP_NAME]).forEach(
      (group: any) => {
        if (group && group !== NO_GROUP_NAME) {
          group = group.trim()
          keywordsByPhrase[phrase].groups.add(group)
        }
        keywordsByPhrase[phrase].displayGroups.add(group)

        keywordsByGroup[group] = keywordsByGroup[group] || []
        keywordsByGroup[group].push(keyword)
      }
    )

    //
    ;(keyword.devices?.length ? keyword.devices : [NO_DEVICE_NAME]).forEach(
      (device: any) => {
        if (device && device !== NO_DEVICE_NAME) {
          keywordsByPhrase[phrase].devices.add(device)
        }
        keywordsByPhrase[phrase].displayDevices.add(device)

        keywordsByDevice[device] = keywordsByDevice[device] || []
        keywordsByDevice[device].push(keyword)
      }
    )

    //
    ;(keyword.localeIds?.length ? keyword.localeIds : [NO_LOCALE_NAME]).forEach(
      (localeId: any) => {
        if (localeId && localeId !== NO_LOCALE_NAME) {
          keywordsByPhrase[phrase].localeIds.add(localeId)
        }
        keywordsByPhrase[phrase].displayLocaleIds.add(localeId)

        keywordsByLocaleId[localeId] = keywordsByLocaleId[localeId] || []
        keywordsByLocaleId[localeId].push(keyword)
      }
    )
  })

  return {
    keywords: Object.entries(keywordsByPhrase).map(
      ([
        phrase,
        {
          groups,
          devices,
          localeIds,
          displayGroups,
          displayDevices,
          displayLocaleIds,
        },
      ]) => {
        return {
          phrase,
          groups: [...groups].sort(),
          devices: [...devices].sort(),
          localeIds: [...localeIds].sort(),
          displayGroups: [...displayGroups].sort(),
          displayDevices: [...displayDevices].sort(),
          displayLocaleIds: [...displayLocaleIds].sort(),
        }
      }
    ),
    phrases: keywordsByPhrase,
    groups: keywordsByGroup,
    devices: keywordsByDevice,
    localeIds: keywordsByLocaleId,
    // phraseOptions: Object.keys(keywordsByPhrase).map(d => ({
    //   value: d,
    //   label: d,
    // })),
    // groupOptions: Object.keys(keywordsByGroup).map(d => ({
    //   value: d,
    //   label: d,
    // })),
    // deviceOptions: Object.keys(keywordsByDevice).map(d => ({
    //   value: d,
    //   label: d,
    // })),
    // localeIdOptions: Object.keys(keywordsByDevice).map(d => ({
    //   value: d,
    //   label: d,
    // })),
  }
})

export function phraseTextToPhraseGroups(text: any) {
  const phraseGroups = text
    .split('\n')
    .map((d: any) => d.trim())
    .filter(Boolean)
    .map((text: any) => {
      const [phrase, ...groups] = text.split(';').map((d: any) => d.trim())
      return {
        phrase,
        groups,
      }
    })

  return dedupePhraseGroups(phraseGroups).phraseGroups
}

export function phraseGroupsToPhraseText(phraseGroups: any) {
  return phraseGroups
    .map(({ phrase, groups = [] }: any) =>
      groups ? [phrase, ...groups].join('; ') : [phrase, groups].join('; ')
    )
    .join('\n')
}

export function keywordsToExcelShorthand(keywords: any) {
  const phraseGroupHashes = {}

  keywords.forEach((keyword: any) => {
    const phraseGroupHash = [
      keyword.phrase,
      ...[...(keyword.groups || []).sort()],
    ].join(';')
    // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    phraseGroupHashes[phraseGroupHash] =
      // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      phraseGroupHashes[phraseGroupHash] || []
    // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    phraseGroupHashes[phraseGroupHash].push(keyword)
  })

  const newKeyords: any = []

  Object.values(phraseGroupHashes).forEach(keywords => {
    // @ts-expect-error  // Object is of type 'unknown'.
    const { phrase, groups } = keywords[0]
    const keyword = {
      phrase,
      device: {},
      localeId: {},
      groups: (groups || []).join('; '),
    }
    // @ts-expect-error  // Object is of type 'unknown'.
    keywords.forEach((subKeyword: any) => {
      // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      keyword.device[subKeyword.device] = true
      // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      keyword.localeId[subKeyword.localeId] = true
    })
    keyword.device = Object.keys(keyword.device).sort().join('; ')
    keyword.localeId = Object.keys(keyword.localeId).sort().join('; ')
    newKeyords.push(keyword)
  })

  return newKeyords
}
