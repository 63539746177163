import { css, cx } from '@emotion/css'
import * as React from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import { twMerge } from 'tailwind-merge'
import { twConfig } from '../utils/tailwind'
import { withProps } from '../utils/withProps'

const Styles = withProps('div')(({ className, inset, ...props }) => ({
  ...props,
  className: twMerge(
    css`
      position: relative;

      &:hover {
        & > .-close {
          color: ${twConfig.theme.colors.red['500']};
        }
      }

      & > .-close {
        z-index: 1000;
        font-size: 1.2rem;
        position: absolute;
        top: 0;
        right: 0;
        width: 1.2rem;
        transform: translate(40%, -40%);
        cursor: pointer;
        text-align: center;
        /* color: ${twConfig.theme.colors.gray['500']}; */
        transition: 0.1s ease-out; i

        { transition:
          0.1s ease-out; } &
        :hover

        { i {
          background: white;
            transform: scale(1.2);
            } }
          transform
        transform

        ${
          inset &&
          `
        font-size: 1rem;
        transform: translate(0, 0);
        // color:  ${twConfig.theme.colors.gray['500']};
        border-bottom-left-radius: 0.5rem;

        &:hover {
          background: transparent;
          color: ${twConfig.theme.colors.red['500']},;
          i {
            transform: scale(1.3);
          }
        }
      `
        };
      }
    `,
    className
  ),
}))

export default function Removable({
  onRemove,
  inset = false,
  children,
  canRemove = true,
  ...rest
}: any) {
  return (
    <Styles inset={inset} {...rest}>
      {!!onRemove &&
        (canRemove ? (
          <div className="-close">
            <FaTimesCircle onClick={onRemove} className="inline" />
          </div>
        ) : null)}
      {children}
    </Styles>
  )
}
