import * as React from 'react'
import useIsomorphicLayoutEffect from '../hooks/useIsomorphicLayoutEffect'
import { Updater } from '../utils'
import useGetLatest from './useGetLatest'

export type UsePagerOpts = {
  offset?: number
  pageSize?: number
  size?: number
  setPageSize?: (pageSize: number) => void
  setOffset?: (offset: number) => void
  canPreviousPage?: boolean
  canNextPage?: boolean
}

export type Pager = ReturnType<typeof usePager>

export function usePager(opts: UsePagerOpts = {}) {
  // Page Size
  const [pageSize, setPageSize_] = React.useState(opts.pageSize ?? 10)
  const setPageSize = React.useCallback(async (newSize: number) => {
    const topRowIndex = pageSize * offset
    const pageIndex = Math.floor(topRowIndex / newSize)
    opts.setPageSize?.(newSize)
    setPageSize_(newSize)
    setOffset(pageIndex)
  }, [])
  useIsomorphicLayoutEffect(() => {
    if (opts.pageSize !== undefined) setPageSize(opts.pageSize)
  }, [opts.pageSize])

  // Pages
  const totalPages =
    opts.size === undefined ? 0 : Math.ceil(opts.size / pageSize)

  // Safe Range
  const getClampOffset = useGetLatest((newOffset: number) =>
    Math.max(0, totalPages ? Math.min(totalPages, newOffset) : newOffset)
  )

  // Offset
  const [offset, setOffset_] = React.useState(
    getClampOffset()(opts.offset ?? 0)
  )

  const setOffset = React.useCallback((offset: number, force?: boolean) => {
    const safeOffset = force ? offset : getClampOffset()(offset)
    setOffset_(safeOffset)
    opts.setOffset?.(safeOffset)
  }, [])

  useIsomorphicLayoutEffect(() => {
    if (opts.offset !== undefined) setOffset(opts.offset)
  }, [opts.offset])

  useIsomorphicLayoutEffect(() => {
    if (offset > totalPages - 1) {
      setOffset(totalPages - 1)
    }
  }, [offset, totalPages])

  return {
    offset: opts.offset !== undefined ? opts.offset : offset,
    setOffset,
    setPageSize,
    slice: React.useCallback(
      <T,>(arr: T[]): T[] => {
        return arr.slice(offset * pageSize, (offset + 1) * pageSize)
      },
      [offset, pageSize]
    ),
    pageSize,
    size: opts.size,
    totalPages,
  }
}
