import { FaCircle } from 'react-icons/fa'
import { IoIosWarning } from 'react-icons/io'
import Card from '../../../components/Card'
import { ProgressBar } from '../../../components/ProgressBar'
import Separator from '../../../components/Separator'
import {
  HeaderRow,
  TableCell,
  TableEl,
  TableHead,
  TableRow,
} from '../../../components/Table'
import Tooltip from '../../../components/Tooltip'
import Usage from '../../../components/Usage'
import { useAccount } from '../../../hooks/account'
import { formatNumber } from '../../../utils/Format'
import { moneyToFloat } from '../../../utils/money'
import { AccountViewPb } from '../../../utils/proto'

export default function BillingOverview() {
  const accountQuery = useAccount({ view: AccountViewPb.FULL })
  const account = accountQuery.data
  const meteredSubscription = account?.subscriptions[0]?.meteredSubscriptions[0]
  const totalConsumed = Number(meteredSubscription?.consumedQuantity ?? 0)
  const baseAvailable = Number(meteredSubscription?.includedQuantity ?? 0)
  const couponAvailable = Number(meteredSubscription?.couponQuantity ?? 0)
  const maxOverageAvailable = moneyToFloat(account?.maxOverageAmount) ?? 0
  const totalAvailable = baseAvailable + couponAvailable

  const includedConsumed = Math.min(baseAvailable, totalConsumed)
  const couponConsumed = Math.max(
    0,
    Math.min(couponAvailable, totalConsumed - baseAvailable)
  )
  const maxOverageConsumed = Math.max(
    0,
    totalConsumed - (includedConsumed + couponConsumed)
  )

  const currentOverageAmount = moneyToFloat(account?.currentOverageAmount)
  const maxOverageAmount = moneyToFloat(account?.maxOverageAmount)

  return (
    <>
      <Card className="p-0">
        <div className="flex items-center gap-2 p-2 text-xl font-bold">
          Current Period Usage
        </div>
        <Separator />
        <TableEl>
          <TableHead>
            <HeaderRow className="font-black">
              <TableCell tight>
                <div className="flex items-center gap-2">
                  <FaCircle className={`text-gray-500 opacity-50`} />
                  Usage Type
                </div>
              </TableCell>
              <TableCell tight className="text-right">
                Used / Total
              </TableCell>
              <TableCell tight></TableCell>
              <TableCell></TableCell>
            </HeaderRow>
          </TableHead>
          <tbody>
            <TableRow>
              <TableCell tight>
                <div className="flex items-center gap-2">
                  <FaCircle className={`text-green-500`} />
                  Included SERPs
                </div>
              </TableCell>
              <TableCell tight className="text-right">
                {formatNumber(includedConsumed)} / {formatNumber(baseAvailable)}
              </TableCell>
              <TableCell tight className="text-right">
                <ProgressBar
                  value={includedConsumed / baseAvailable}
                  innerProps={{
                    className: `bg-green-500`,
                  }}
                />
              </TableCell>
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell tight>
                <div className="flex items-center gap-2">
                  <FaCircle className={`text-yellow-500`} />
                  Coupon SERPs
                </div>
              </TableCell>
              <TableCell tight className="text-right">
                {couponAvailable > 0 ? (
                  <>
                    {formatNumber(couponConsumed)} /{' '}
                    {formatNumber(couponAvailable)}
                  </>
                ) : (
                  '-'
                )}
              </TableCell>
              <TableCell tight className="text-right">
                {couponAvailable > 0 ? (
                  <>
                    <ProgressBar
                      value={couponConsumed / couponAvailable}
                      innerProps={{
                        className: `bg-yellow-500`,
                      }}
                    />
                  </>
                ) : (
                  '-'
                )}
              </TableCell>
              <TableCell />
            </TableRow>
            <TableRow className="font-black">
              <TableCell tight>
                <div className="flex items-center gap-2">
                  <FaCircle className={`text-blue-500`} />
                  Total SERPs
                </div>
              </TableCell>
              <TableCell tight className="text-right">
                {' '}
                {formatNumber(totalConsumed)} / {formatNumber(totalAvailable)}
              </TableCell>
              <TableCell tight>
                <div className="flex items-center gap-2">
                  <ProgressBar
                    value={totalConsumed / totalAvailable}
                    innerProps={{
                      className: `bg-blue-500`,
                    }}
                  />
                  {totalConsumed >= totalAvailable ? (
                    <span>
                      <Tooltip tooltip="Your maximum SERP amount has been reached! Please upgrade your plan or contact support.">
                        <IoIosWarning className="animate-pulse text-yellow-300 [animation-duration:750ms]" />
                      </Tooltip>
                    </span>
                  ) : null}
                </div>
              </TableCell>
              <TableCell tight className=""></TableCell>
            </TableRow>
            <TableRow>
              <TableCell tight>
                <div className="flex items-center gap-2">
                  <FaCircle className={`text-red-500`} />
                  Overages
                </div>
              </TableCell>
              <TableCell tight className="text-right">
                <div className="flex items-center gap-2">
                  {maxOverageConsumed ? (
                    <>
                      <div className="font-bold text-red-500">
                        {formatNumber(maxOverageConsumed)}
                      </div>
                      <div className="opacity-30">|</div>
                    </>
                  ) : null}
                  {maxOverageAmount ? (
                    <div>
                      $
                      {formatNumber(currentOverageAmount, {
                        precision: 2,
                        forcePrecision: true,
                      })}{' '}
                      / $
                      {formatNumber(maxOverageAmount, {
                        precision: 2,
                        forcePrecision: true,
                      })}
                    </div>
                  ) : (
                    <div className="text-sm italic opacity-50">
                      Overages are disabled.
                    </div>
                  )}
                </div>
              </TableCell>
              <TableCell tight className="text-right">
                {currentOverageAmount > 0 ? (
                  <ProgressBar
                    value={currentOverageAmount / maxOverageAvailable}
                    innerProps={{
                      className: `bg-red-500`,
                    }}
                  />
                ) : (
                  '-'
                )}
              </TableCell>
              <TableCell />
            </TableRow>
          </tbody>
        </TableEl>
      </Card>
      <div className="h-4" />
      <Usage />
    </>
  )
}
