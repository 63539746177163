import { twMerge } from 'tailwind-merge'
import moment from 'moment'
import * as React from 'react'
import { BiCloudDownload, BiTask, BiTrash } from 'react-icons/bi'
import {
  FaCheck,
  FaCheckCircle,
  FaExclamationTriangle,
  FaTimes,
} from 'react-icons/fa'
import { ImSpinner2 } from 'react-icons/im'
import { Job, useJobs } from '../hooks/jobs'
import useClickOutside from '../hooks/useClickOutside'
import useToast from '../hooks/useToast'
import { useWindowIsVisible } from '../hooks/useWindowIsVisible'
import useZustand from '../hooks/useZustand'
import { multiSortBy } from '../utils'
import { fetchReportsGroupByOverTimeJob } from '../utils/Api'
import { ButtonPlain } from './ButtonPlain'

//

//

const MIN_INTERVAL_DURATION = 5000

export default function Jobs(props: any) {
  // return null

  // const { isBroadcastLeader } = useBroadcastLeader()
  const isFocusedWindow = useWindowIsVisible()
  const [showJobs, setStore] = useZustand(state => state.showJobs)
  const [jobs, setJobs] = useJobs()
  const toast = useToast()

  const sortedJobs = multiSortBy(jobs, [d => -new Date(d.createdAt)])

  const pendingJobs = React.useMemo(() => {
    return jobs.filter(job => {
      return job.status === 'pending'
    })
  }, [jobs])

  const pendingJobsCount = pendingJobs.length

  const downloadableJobsCount = React.useMemo(() => {
    return jobs.filter(job => {
      return job.status === 'completed' && !job.checked
    }).length
  }, [jobs])

  const failedJobsCount = React.useMemo(() => {
    return jobs.filter(job => {
      return job.status === 'failed' && !job.checked
    }).length
  }, [jobs])

  const closeJobsPanel = () =>
    setStore(old => {
      old.showJobs = false
    })

  const elRef = React.useRef()
  useClickOutside(elRef, closeJobsPanel)

  const openJobsPanel = React.useCallback(() => {
    setStore(old => {
      old.showJobs = true
    })
  }, [setStore])

  React.useEffect(() => {
    let running = pendingJobs.length > 0

    const run = async () => {
      if (!running) {
        return
      }

      const sleepPromise = new Promise(r =>
        setTimeout(r, MIN_INTERVAL_DURATION)
      )

      try {
        const allChecks = pendingJobs.map(async job => {
          const jobResponse = await fetchReportsGroupByOverTimeJob({
            exportToken: job.exportToken,
          })

          if (!running) {
            return
          }

          if (['completed', 'failed'].includes(jobResponse.status)) {
            if (jobResponse.status === 'completed') {
              toast({
                color: 'green-500',
                message: `Job Completed: ${job.name}`,
                timeout: 10000,
                onClick: () => {
                  Promise.resolve().then(() => {
                    openJobsPanel()
                  })
                },
              })
            } else if (jobResponse.status === 'failed') {
              toast({
                color: 'red-600',
                message: `Job Failed: ${job.name}`,
                timeout: 10000,
                onClick: () => {
                  Promise.resolve().then(() => {
                    openJobsPanel()
                  })
                },
              })
            }

            setJobs(prev =>
              prev.map(existingJob => {
                if (existingJob === job) {
                  return {
                    ...existingJob,
                    checked: false,
                    ...(jobResponse.status === 'completed'
                      ? {
                          status: jobResponse.status,
                          completedAt: Date.now(),
                        }
                      : jobResponse.status === 'failed'
                      ? {
                          status: jobResponse.status,
                          failedAt: Date.now(),
                        }
                      : {}),
                    urls:
                      jobResponse.urls?.map(url => {
                        return {
                          ...url,
                          downloaded: false,
                        }
                      }) ?? [],
                  }
                }

                return existingJob
              })
            )
          }
        })

        await Promise.all(allChecks)
      } catch (err) {
        console.info('Encountered an error checking for job status', err)
      }

      await sleepPromise

      await run()
    }

    run()

    return () => {
      running = false
    }
  }, [toast, pendingJobs, setJobs, isFocusedWindow, openJobsPanel])

  return (
    <div
      ref={elRef}
      {...props}
      className={twMerge(
        `absolute -right-2
          bottom-2 top-2 z-20 w-full max-w-[400px]
          translate-x-full transform
          transition duration-200 ease-out`,
        showJobs && `right-2 translate-x-0 opacity-100`
      )}
    >
      <button
        className={twMerge(
          `absolute left-0 top-4 transform
            transition duration-200`,
          showJobs && `-translate-x-8 delay-300`
        )}
        onClick={closeJobsPanel}
      >
        <div
          className=" flex h-8
          w-8 items-center
          justify-center
          rounded-l-lg bg-gray-200 text-center text-white shadow-xl
          hover:bg-red-500 dark:border dark:border-r-0 dark:border-gray-700
          dark:bg-gray-900 
            "
        >
          <FaTimes className=" inline text-white" />
        </div>
      </button>

      <div
        className={twMerge(
          `relative h-full
            list-none divide-y divide-gray-100
            overflow-hidden rounded-lg bg-white
            opacity-50 shadow-xl
            transition duration-200
            ease-out
            dark:divide-gray-750 dark:border dark:border-gray-700 dark:bg-gray-850`,
          showJobs && `opacity-100`
        )}
      >
        <div className="flex w-full items-center gap-2 p-2 font-bold">
          <BiTask /> <span>Jobs</span>
          <div className="ml-auto flex gap-2">
            {pendingJobsCount ? (
              <div className="rounded-full bg-yellow-500 px-2 py-1 text-sm text-white">
                <ImSpinner2 className="inline animate-spin" />{' '}
                {pendingJobsCount} Pending
              </div>
            ) : null}
            {downloadableJobsCount ? (
              <div className="rounded-full bg-green-500 px-2 py-1 text-sm text-white">
                <FaCheck className="inline" /> {downloadableJobsCount} Completed
              </div>
            ) : null}
            {failedJobsCount ? (
              <div className="rounded-full bg-red-500 px-2 py-1 text-sm text-white">
                <FaExclamationTriangle className="inline" /> {failedJobsCount}{' '}
                Failed
              </div>
            ) : null}
          </div>
        </div>
        {sortedJobs.length ? (
          sortedJobs.map(job => <JobComp job={job} key={job.exportToken} />)
        ) : (
          <div className="flex items-center p-4 opacity-50">
            No jobs yet. Try exporting some data!
          </div>
        )}
      </div>
    </div>
  )
}

function JobComp({ job }: { job: Job }) {
  const [, setJobs] = useJobs()
  const removeJob = () =>
    setJobs(prev => prev.filter(d => d.exportToken !== job.exportToken))

  const checkJob = () => {
    setJobs(prev =>
      prev.map(d =>
        d.exportToken === job.exportToken
          ? {
              ...d,
              checked: true,
            }
          : d
      )
    )
  }

  const checkUrl = (url: any) => {
    setJobs(prev =>
      prev.map(d => {
        if (d.exportToken !== job.exportToken) {
          return d
        }

        const newUrls = d.urls?.map(dd =>
          dd.url === url
            ? {
                ...dd,
                downloaded: true,
              }
            : dd
        )

        return {
          ...d,
          urls: newUrls,
          checked: newUrls?.every(d => d.downloaded) ? true : d.checked,
        }
      })
    )
  }

  return (
    <div
      className={twMerge(
        `space-y-2 p-2 text-sm`,
        job.status === 'completed' &&
          !job.checked &&
          `bg-green-100 dark:bg-green-900`,

        job.status === 'failed' && !job.checked && `bg-red-100 dark:bg-red-900`
      )}
    >
      <div className="flex flex-1 items-center space-y-1 overflow-hidden">
        <button
          className="block flex-1 space-y-1 text-left"
          onClick={() => {
            checkJob()
            job.urls?.forEach(jobUrl => {
              checkUrl(jobUrl.url)
              window.open(jobUrl.url)
            })
          }}
        >
          <div className="">{job.name}</div>
          <div className="flex items-center gap-1 text-xs">
            {job.status === 'pending' ? (
              <>
                <ImSpinner2 className="animate-spin text-yellow-500" />
                <span>Created {moment(new Date(job.createdAt)).fromNow()}</span>
              </>
            ) : job.status === 'completed' ? (
              <>
                <FaCheck className="inline text-green-500" />
                <span>
                  Completed {moment(new Date(job.completedAt!)).fromNow()}
                </span>
              </>
            ) : (
              <>
                <FaExclamationTriangle className="inline text-red-500" />
                <span>Failed {moment(new Date(job.failedAt!)).fromNow()}</span>
              </>
            )}
          </div>
        </button>
        <button
          className="p-2 text-sm text-gray-500 hover:text-red-500"
          onClick={e => {
            e.stopPropagation()
            removeJob()
          }}
        >
          <BiTrash />
        </button>
      </div>
      {job.urls?.length ? (
        <div className="overflow-hidden text-ellipsis rounded border border-gray-500/20 bg-gray-500/10 px-2 py-1">
          {job.urls.map(url => {
            return (
              <div key={url.url} className="flex items-center gap-2">
                <div>
                  {url.downloaded ? (
                    <FaCheckCircle className="text-xs text-green-500" />
                  ) : (
                    <BiCloudDownload className="text-base text-blue-500 dark:text-blue-400" />
                  )}
                </div>
                <a
                  href={url.url}
                  target="_blank"
                  rel="noreferrer"
                  className="whitespace-nowrap text-xs text-blue-500 hover:underline dark:text-blue-400"
                  onClick={() => checkUrl(url.url)}
                >
                  {url.url}
                </a>
              </div>
            )
          })}
        </div>
      ) : null}
    </div>
  )
}
