import {
  KEYWORD_SOURCE_TYPE_AUTOMOTIVE,
  KEYWORD_SOURCE_TYPE_BASIC,
  KEYWORD_SOURCE_TYPE_CITY,
  KEYWORD_SOURCE_TYPE_JSON,
  KEYWORD_SOURCE_TYPE_TEMPLATE,
} from '../utils/Constants'

export const keywordSourceTypeOptions: any = [
  {
    label: 'Basic',
    value: KEYWORD_SOURCE_TYPE_BASIC,
  },
  {
    label: 'Advanced (Excel/CSV)',
    value: KEYWORD_SOURCE_TYPE_JSON,
  },
  {
    label: 'Template Generator v1 (deprecated)',
    value: KEYWORD_SOURCE_TYPE_CITY,
  },
  {
    label: 'Sync from BigQuery (coming soon)',
    disabled: true,
  },
]

export const keywordSourceTypeOptionsAlpha: any = [
  ...keywordSourceTypeOptions,
  {
    label: 'Template Generator v2 (alpha)',
    value: KEYWORD_SOURCE_TYPE_TEMPLATE,
  },
  {
    label: 'Automotive Generator v2 (alpha)',
    value: KEYWORD_SOURCE_TYPE_AUTOMOTIVE,
  },
]
