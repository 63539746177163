import * as React from 'react'
import { useQueryClient } from 'react-query'
import useToast from '../hooks/useToast'
import { setClaims } from '../utils/Api'
import { signOutOfFirebase } from '../utils/Firebase'
import useAuth from './useAuth'
import useErrorPopup from './useErrorPopup'

//

export default function useLogout() {
  const [, setAuth] = useAuth()
  const toast = useToast()
  const errorPopup = useErrorPopup()
  const queryClient = useQueryClient()

  return React.useCallback(async () => {
    // @ts-expect-error  // Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    setClaims(null)

    setAuth(() => ({
      claims: null,
      stage: 'loggedOut',
    }))

    queryClient.setQueriesData('profile', undefined)
    queryClient.clear()

    try {
      await signOutOfFirebase()
      toast({
        color: 'blue-500',
        message: `You have been logged out of Nozzle`,
      })
    } catch (err) {
      console.error(err)
      errorPopup(`Something went wrong trying to log out!`)
    }
  }, [queryClient, setAuth, toast, errorPopup])
}
