import { createColumnHelper } from '@tanstack/react-table'
import * as Plot from '@observablehq/plot'
import * as d3 from 'd3'
import moment, { Moment } from 'moment'
import * as React from 'react'
import { AxisOptions } from 'react-charts'
import { FaCircle } from 'react-icons/fa'
import { UseQueryResult } from 'react-query'
import { useProjects } from '../hooks/projects'
import { useTable } from '../hooks/useTable'
import { useUsage } from '../hooks/useUsage'
import { getDataColor } from '../utils/DataColors'
import { formatNumber } from '../utils/Format'
import { datePbToDate } from '../utils/protoDate'
import Card from './Card'
import Chart from './Chart'
import ChartPlaceholder from './ChartPlaceholder'
import ErrorBoundary from './ErrorBoundary'
import Link from './Link'
import Separator from './Separator'
import Table from './Table'
import UsageTimeRange from './UsageTimeRange'
import UsageWidgetControls from './UsageWidgetControls'
import { UsageViewPb } from '../utils/proto'
import { ObservablePlot } from './ObservablePlot'
import { sortBy } from '../utils'

//

export default function Usage({ hideCost }: { hideCost?: boolean }) {
  const minDate = moment().subtract(29, 'day').startOf('day')
  const today = moment().startOf('day')

  const [timeRanges, setTimeRanges] = React.useState({
    start: minDate.clone().utc().unix(),
    end: today.clone().utc().unix(),
  })

  const usageQuery = useUsage({ timeRange: timeRanges, view: UsageViewPb.FULL })
  const teamsQuery = useProjects()

  const dates = React.useMemo(() => {
    const dates: Moment[] = []

    const cursor = moment.unix(timeRanges.start).startOf('day')
    const end = moment.unix(timeRanges.end).startOf('day')

    dates.push(cursor.clone())

    while (cursor.add(1, 'days').diff(end, 'days') <= 0) {
      dates.push(cursor.clone())
    }

    return dates
  }, [timeRanges.end, timeRanges.start])

  const overallData = React.useMemo(() => {
    const datesWithData = dates.map(dateMoment => {
      const dateUnix = dateMoment.startOf('day').unix()
      const usageDate = usageQuery.data?.usageByWorkspace?.usageByDate.find(
        d => datePbToDate(d.date).getTime() === dateUnix * 1000
      )

      return {
        dateMoment,
        quantity: Number(usageDate?.quantity ?? 0),
      }
    })

    return datesWithData.map((dateWithData, index) => {
      const cumulativeQuantity = datesWithData
        .slice(0, index + 1)
        .reduce((acc, d) => acc + (d.quantity || 0), 0)

      return {
        ...dateWithData,
        cumulativeQuantity,
      }
    })
  }, [dates, usageQuery.data?.usageByWorkspace?.usageByDate])

  const teamData = React.useMemo(
    () =>
      usageQuery.data?.usageByWorkspace?.usageByProject.map(
        (byProject, index) => {
          const datesWithData = dates.map(dateMoment => {
            const dateUnix = dateMoment.startOf('day').unix()
            const usageDate = byProject.usageByDate.find(
              d => datePbToDate(d.date).getTime() === dateUnix * 1000
            )

            return {
              dateMoment,
              quantity: Number(usageDate?.quantity ?? 0),
            }
          })

          const datesWithCumulativeData = datesWithData.map(
            (dateWithData, index) => {
              const cumulativeQuantity = datesWithData
                .slice(0, index + 1)
                .reduce((acc, d) => acc + (Number(d.quantity) || 0), 0)

              return {
                ...dateWithData,
                cumulativeQuantity,
              }
            }
          )

          const team = teamsQuery.data?.find(
            teams => teams.id === Number(byProject.projectId)
          )

          return {
            id: byProject.projectId,
            label: team?.name,
            totals: byProject.usageTotals[0],
            data: datesWithCumulativeData,
          }
        }
      ) ?? [],
    [usageQuery.data?.usageByWorkspace?.usageByProject, teamsQuery.data, dates]
  )

  const columnHelper = createColumnHelper<(typeof teamData)[number]>()

  const columns = React.useMemo(
    () => [
      columnHelper.display({
        id: 'circle',
        cell: props => (
          <div className="text-center">
            <FaCircle
              style={{
                color: getDataColor(props.row.index),
              }}
            />
          </div>
        ),
        meta: {
          tight: true,
        },
      }),
      columnHelper.accessor(d => d.label, {
        id: 'label',
        header: 'Project',
        cell: props => (
          <Link to={`/projects/${props.row.original.id}`}>
            {props.getValue()}
          </Link>
        ),
        meta: {
          tight: true,
        },
      }),
      columnHelper.accessor(d => d.totals?.quantity, {
        id: 'serps',
        header: 'SERPs',
        cell: props => (
          <div className="text-right">
            {formatNumber(Number(props.getValue()))}
          </div>
        ),
        meta: {
          tight: true,
        },
      }),

      // columnHelper.accessor('totals.pullMicrocentsTotal', {
      //   header: 'Cost of SERPs',
      //   cell: props => (
      //     <div className="text-right">{formatCurrency(
      //       props.getValue() / 1e8,
      //       {
      //         forcePrecision: true,
      //         precision: 2,
      //       }
      //     )}</div>
      //   ),
      // }),

      // columnHelper.accessor(d => d., {
      //   header: 'Max SERPs',
      //   cell: props => (
      //     <div className="text-right">{`${formatNumber(
      //       props.getValue()
      //     )}`}</div>
      //   ),
      // }),
    ],
    [columnHelper]
  )

  const table = useTable({
    data: teamData,
    columns,
    initialState: {
      sorting: [{ id: 'serps', desc: true }],
    },
  })

  const plotted = React.useMemo(
    () => sortBy(teamData, d => -d.totals?.quantity).slice(0, 100),
    [teamData]
  )

  return (
    <div className="flex flex-col gap-4">
      <Card className="divide-y divide-gray-500/20 p-0">
        <div className="flex flex-wrap items-center gap-4 p-2 font-bold">
          <div className="text-lg">Overall Usage</div>
          <div className="">
            <div className="flex items-center gap-1">
              <FaCircle className="text-xs text-blue-500" /> Total SERPs:{' '}
              {formatNumber(
                Number(
                  usageQuery.data?.usageByWorkspace?.usageTotals[0]?.quantity
                )
              )}
            </div>
          </div>
          <UsageTimeRange
            timeRanges={timeRanges}
            setTimeRanges={setTimeRanges}
          />
        </div>
        <div className="p-2">
          <ObservablePlot
            className="h-60"
            options={{
              x: {
                label: 'Date',
              },
              y: {
                label: 'SERPs',
              },
              grid: true,
              marks: [
                Plot.areaY(overallData, {
                  x: d => d.dateMoment.toDate(),
                  y: d => d.quantity,
                  fill: getDataColor(0),
                  fillOpacity: 0.3,
                  curve: 'monotone-x',
                }),
                Plot.lineY(overallData, {
                  x: d => d.dateMoment.toDate(),
                  y: d => d.quantity,
                  stroke: getDataColor(0),
                  tip: true,
                  curve: 'monotone-x',
                }),
                Plot.crosshairX(overallData, {
                  x: d => d.dateMoment.toDate(),
                  y: d => d.quantity,
                }),
              ],
            }}
          />
        </div>
        <div className="p-2">
          <div className="text-lg font-bold">Usage By Project</div>
        </div>
        <div className="p-2">
          <ObservablePlot
            className="h-60"
            options={React.useMemo(
              () => ({
                x: {
                  label: 'Date',
                },
                y: {
                  label: 'SERPs',
                },
                color: {
                  label: 'Project',
                },
                grid: true,
                marks: [
                  Plot.crosshairX(
                    plotted.flatMap(d =>
                      d.data.map(dd => ({
                        ...dd,
                        label: d.label,
                      }))
                    ),
                    {
                      x: d => d.dateMoment.toDate(),
                      y: d => d.quantity,
                      stroke: d => d.label,
                    }
                  ),
                  ...plotted.map(row => {
                    return Plot.lineY(row.data, {
                      x: d => d.dateMoment.toDate(),
                      y: d => d.quantity,
                      stroke: () => row.label,
                      curve: 'monotone-x',
                    })
                  }),
                  Plot.tip(
                    plotted.flatMap(d =>
                      d.data.map(dd => ({
                        ...dd,
                        label: d.label,
                      }))
                    ),
                    Plot.pointerX({
                      x: d => d.dateMoment.toDate(),
                      y: d => d.quantity,
                      stroke: d => d.label,
                    })
                  ),
                ],
              }),
              [plotted]
            )}
          />
        </div>
        <Table table={table} />
      </Card>
    </div>
  )
}
