import { createColumnHelper } from '@tanstack/react-table'
import { twMerge } from 'tailwind-merge'
import * as React from 'react'
import { useQuery } from 'react-query'
import Card from '../../../components/Card'
import Table from '../../../components/Table'
import { useTable } from '../../../hooks/useTable'
import {
  ClusterPb,
  ClusterUrlPb,
  CategoryMetricsPb,
  PageClient,
  ClassificationCategoryPb,
} from '../../../utils/proto'
import { formatNumber, formatPercentage } from '../../../utils/Format'
import { queryKeyPages } from '../../../utils/Constants'
import { sortBy, uniqBy } from '../../../utils'
import useLocalState from '../../../hooks/useLocalState'
import { useForm } from '@tanstack/react-form'
import { useNavigate, useSearch } from '@tanstack/react-router'

import { FaCaretDown, FaTimes } from 'react-icons/fa'
import { ButtonPlain } from '../../../components/ButtonPlain'
import Select from '../../../components/Select'
import { MdCompare } from 'react-icons/md'
import QueryGate from '../../../components/QueryGate'
import Loader from '../../../components/Loader'
import Tooltip from '../../../components/Tooltip'
import Input from '../../../components/Input'
import Spinner from '../../../components/Spinner'
// import * as venn from '@upsetjs/venn.js'

const categoryColumnHelper = createColumnHelper<CategoryMetricsPb>()
const categoryColumns = [
  categoryColumnHelper.accessor('category', {
    header: 'Category',
    filterFn: 'fuzzy',
  }),
  categoryColumnHelper.accessor(d => d.confidence?.avg?.value, {
    header: 'Avg Confidence',
    filterFn: 'inNumberRange',
    cell: cellProps => {
      return formatNumber(cellProps.getValue()!, {
        precision: 3,
        forcePrecision: true,
      })
    },
    meta: {
      getCellProps: () => {
        return {
          className: 'text-right',
        }
      },
    },
  }),
  categoryColumnHelper.accessor(d => d.confidence?.avg?.value, {
    id: 'confidenceChart',
    header: '',
    cell: cellProps => {
      const value = cellProps.getValue() ?? 0

      return (
        <div className="w-[150px]">
          <div
            className="rounded bg-gray-500 px-1 text-xs font-bold uppercase text-white"
            style={{
              backgroundColor: `hsl(${60 + value * 100}, 100%, 40%)`,
              width: `${value * 100}%`,
              height: '1em',
            }}
          />
        </div>
      )
    },
  }),
]

const comparisonColumnHelper = createColumnHelper<{
  category: string
  maxConfidence?: number
  cluster?: CategoryMetricsPb
  clusterUrls?: {
    clusterUrl: ClusterUrlPb
    category?: ClassificationCategoryPb
  }[]
  url?: ClassificationCategoryPb
}>()

export function Categories(props: {
  cluster: ClusterPb
  focusDomain?: string
}) {
  const { url } = useSearch()
  const categories = props.cluster.metricsByCategory
  const navigate = useNavigate()

  const table = useTable({
    data: categories,
    columns: categoryColumns,
    initialState: React.useMemo(() => {
      return {
        pagination: {
          pageSize: 50,
        },
        sorting: [
          {
            id: 'confidence',
            desc: true,
          },
        ],
      }
    }, []),
  })

  const pageQuery = useQuery(
    [queryKeyPages, url],
    () =>
      PageClient.requestPage({
        url,
        includeCategories: true,
        waitForCompletion: true,
      }),
    {
      enabled: !!url,
      keepPreviousData: true,
    }
  )

  const joinedCategories = React.useMemo(() => {
    return sortBy(
      uniqBy([
        ...categories.map(d => d.category),
        ...(pageQuery.data?.page?.categories.map(d => d.name) ?? []),
      ]).map(category => {
        const cluster = categories.find(d => d.category === category)
        const clusterUrls = props.cluster.clusterUrls.map(d => ({
          clusterUrl: d,
          category: d.page?.categories.find(dd => dd.name === category),
        }))
        const url = pageQuery.data?.page?.categories.find(
          d => d.name === category
        )

        return {
          category,
          maxConfidence:
            cluster?.confidence?.avg?.value && url?.confidence
              ? Math.max(cluster?.confidence?.avg?.value, url?.confidence)
              : cluster?.confidence?.avg?.value ?? url?.confidence ?? 0,
          cluster,
          clusterUrls,
          url,
        }
      }),
      d => -d.maxConfidence
    )
  }, [categories, pageQuery.data?.page?.categories, props.cluster.clusterUrls])

  const [history, setHistory] = useLocalState<string[]>(
    'urlExplorerHistory',
    []
  )

  const urlOptions = React.useMemo(() => {
    return props.cluster.clusterUrls?.map(d => ({
      label: d.url?.url ?? '',
      value: d.url?.url ?? '',
    }))
  }, [props.cluster.clusterUrls])

  const historyOptions = React.useMemo(() => {
    return history.map(d => ({
      label: d,
      value: d,
    }))
  }, [history])

  const defaultValues = React.useMemo(
    () => ({
      url,
    }),
    [url]
  )

  const setUrl = React.useCallback(
    (url?: string) => {
      navigate({
        search: prev => ({
          ...prev,
          url,
        }),
      })

      if (url) {
        setHistory(prev => {
          return [url, ...prev.filter(d => d !== url)].slice(0, 500)
        })
      }

      // navigate({
      //   search: prev => ({
      //     ...prev,
      //     url,
      //   }),
      // })
    },
    [navigate, setHistory]
  )

  const form = useForm({
    defaultValues,
    onSubmit: values => {
      setUrl(values.url)
    },
  })

  const split = false

  const comparisonColumns = React.useMemo(
    () => [
      comparisonColumnHelper.accessor('category', {
        header: 'Name',
        filterFn: 'fuzzy',
      }),
      comparisonColumnHelper.group({
        header: 'Cluster Average',
        columns: [
          comparisonColumnHelper.accessor(
            d => d.cluster?.confidence?.avg?.value ?? 0,
            {
              id: 'clusterConfidence',
              header: 'Confidence',
              cell: cellProps =>
                formatPercentage(cellProps.getValue(), {
                  precision: 1,
                  forcePrecision: true,
                }),
              meta: {
                tight: true,
                getCellProps: () => ({
                  className: 'text-right',
                }),
              },
            }
          ),
          comparisonColumnHelper.accessor(
            d => d.cluster?.confidence?.avg?.value ?? 0,
            {
              id: 'clusterConfidenceChart',
              header: 'Confidence',
              filterFn: 'inNumberRange',
              sortUndefined: 1,
              cell: cellProps => {
                const {
                  row: { original },
                } = cellProps

                if (split) {
                  return (
                    <div className="flex w-36 flex-col items-end space-y-1 px-1 py-4">
                      {original.clusterUrls?.map((row, i) => {
                        const confidence = row.category?.confidence ?? 0

                        return (
                          <Tooltip
                            key={i}
                            tooltip={
                              <div className="space-y-1">
                                <div className="text-xs">
                                  {row.clusterUrl.url?.url}
                                </div>
                                <table className="w-auto">
                                  <tbody>
                                    <tr>
                                      <td>Confidence</td>
                                      <td className="px-2 font-bold">
                                        {formatPercentage(confidence, {
                                          precision: 1,
                                          forcePrecision: true,
                                        })}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            }
                            className="group flex w-full justify-end"
                          >
                            <div
                              className={twMerge(
                                'h-2 min-w-[2px] group-hover:border-2 group-hover:border-gray-500'
                              )}
                              style={{
                                width: `${confidence * 100}%`,
                                backgroundColor: `hsl(${
                                  60 + confidence * 100
                                }, 100%, 40%)`,
                              }}
                            />
                          </Tooltip>
                        )
                      })}
                    </div>
                  )
                }

                const confidence = cellProps.getValue()

                return (
                  <div className="flex w-36 justify-end p-1">
                    <div
                      className={twMerge('h-3')}
                      style={{
                        width: `${confidence * 100}%`,
                        backgroundColor: `hsl(${
                          60 + confidence * 100
                        }, 100%, 40%)`,
                      }}
                    />
                  </div>
                )
              },
              meta: {
                tight: true,
                getCellProps: () => ({
                  className: 'p-0',
                }),
              },
            }
          ),
        ],
      }),
      comparisonColumnHelper.group({
        header: 'URL',
        columns: [
          comparisonColumnHelper.accessor(d => d.url?.confidence ?? 0, {
            id: 'urlConfidenceChart',
            header: 'Confidence',
            filterFn: 'inNumberRange',
            cell: cellProps => {
              const confidence = cellProps.getValue() ?? 0

              return (
                <div className="w-36 p-1">
                  <div
                    className={twMerge('h-3')}
                    style={{
                      width: `${confidence * 100}%`,
                      backgroundColor: `hsl(${
                        60 + confidence * 100
                      }, 100%, 40%)`,
                    }}
                  />
                </div>
              )
            },
            meta: {
              tight: true,
              getCellProps: () => ({
                className: 'p-0',
              }),
            },
          }),
          comparisonColumnHelper.accessor(d => d.url?.confidence ?? 0, {
            id: 'urlConfidence',
            header: 'Confidence',
            cell: cellProps =>
              formatPercentage(cellProps.getValue(), {
                precision: 1,
                forcePrecision: true,
              }),
            meta: {
              tight: true,
            },
          }),
        ],
      }),
    ],
    [split]
  )

  const comparisonTable = useTable({
    data: joinedCategories,
    columns: comparisonColumns,
    enableSortingRemoval: true,
    initialState: React.useMemo(() => {
      return {
        pagination: {
          pageSize: 50,
        },
      }
    }, []),
  })

  const preferredUrl = props.cluster.clusterUrls.find(
    d => d.url?.domain === props.focusDomain
  )?.url?.url

  React.useEffect(() => {
    if (!props.focusDomain) {
      return
    }

    if (preferredUrl && !url) {
      navigate({
        search: prev => ({
          ...prev,
          url: preferredUrl,
        }),
        replace: true,
      })
    }
  }, [navigate, preferredUrl, props.focusDomain, url])

  return (
    <div className="space-y-4">
      <Card className="divide-y divide-gray-500/20 p-0">
        <div className="p-2 text-lg font-bold">Categories</div>
        <div className="p-2">
          <form.Form className="space-y-2">
            <form.Field
              name="url"
              children={field => {
                return (
                  <div className="flex items-center gap-2">
                    {url && !preferredUrl ? (
                      <ButtonPlain
                        className="bg-gray-500/30 py-1.5 text-black hover:bg-red-500 hover:text-white dark:text-white"
                        onClick={() => {
                          setUrl('')
                        }}
                      >
                        <FaTimes className="text-base" />
                      </ButtonPlain>
                    ) : null}
                    <Select
                      options={urlOptions}
                      onChange={value => {
                        form.setFieldValue('url', value)
                        setUrl(value)
                      }}
                      children={({ onClick }) => {
                        return (
                          <ButtonPlain
                            className="bg-blue-500"
                            onClick={onClick}
                          >
                            Select a URL <FaCaretDown />
                          </ButtonPlain>
                        )
                      }}
                    />
                    {pageQuery.isFetching ? <Spinner className="" /> : null}
                    <Input
                      {...field.getInputProps()}
                      placeholder="https://example.com"
                      className="flex-1 py-1"
                      type="url"
                    />
                    <ButtonPlain type="submit" className="bg-green-500">
                      <MdCompare /> Compare
                    </ButtonPlain>
                    <Select
                      options={historyOptions}
                      onChange={value => {
                        form.setFieldValue('url', value)
                        setUrl(value)
                      }}
                      children={({ onClick }) => {
                        return (
                          <ButtonPlain
                            onClick={onClick}
                            className="bg-gray-100 text-gray-700 dark:bg-gray-800 dark:text-white"
                          >
                            History <FaCaretDown />
                          </ButtonPlain>
                        )
                      }}
                    />
                  </div>
                )
              }}
            />
          </form.Form>
        </div>
        <div className="divide-y divide-gray-100 dark:divide-gray-850">
          {!url ? (
            !categories?.length ? (
              <div className="p-2">No categories found.</div>
            ) : (
              <Table table={table} />
            )
          ) : (
            <QueryGate
              query={pageQuery}
              loader={
                <>
                  <div className="space-y-2 p-4 text-center">
                    <div className="text-sm font-bold">
                      Loading Comparison URL
                    </div>
                    <Loader className="text-2xl" />
                    <div className="text-xs italic opacity-50">
                      (This can take up to 2 minutes)
                    </div>
                  </div>
                  <Table table={table} />
                </>
              }
            >
              <Table table={comparisonTable} />
            </QueryGate>
          )}
        </div>
      </Card>
    </div>
  )
}
