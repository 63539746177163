// @generated by protoc-gen-es v1.3.0 with parameter "target=ts"
// @generated from file nozzle/reports/v1/cluster.proto (package nozzle.reports.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { Operation } from "../../../google/longrunning/operations_pb.js";
import { CategoryMetrics, EntityMetrics, Facet, KeywordMetrics, RollupMetrics, SerpMetrics } from "./metrics_pb.js";
import { ExportOptionCsv, ExportOptionExcel, ExportOptionGoogleSheets } from "./export_pb.js";
import { Page, Url } from "../../urls/v1/page_pb.js";

/**
 * ClusterView determines which fields of the Cluster are returned in the response.
 *
 * @generated from enum nozzle.reports.v1.ClusterView
 */
export enum ClusterView {
  /**
   * Will default to CLUSTER_VIEW_PARTIAL.
   *
   * @generated from enum value: CLUSTER_VIEW_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * This only returns cluster metrics and focused rollup metrics.
   *
   * @generated from enum value: CLUSTER_VIEW_BASIC = 1;
   */
  BASIC = 1,

  /**
   * This returns a subset of the data based on the max_sub_rows field.
   *
   * @generated from enum value: CLUSTER_VIEW_PARTIAL = 3;
   */
  PARTIAL = 3,

  /**
   * This returns all the data, and is the default for exported data.
   *
   * @generated from enum value: CLUSTER_VIEW_FULL = 2;
   */
  FULL = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(ClusterView)
proto3.util.setEnumType(ClusterView, "nozzle.reports.v1.ClusterView", [
  { no: 0, name: "CLUSTER_VIEW_UNSPECIFIED" },
  { no: 1, name: "CLUSTER_VIEW_BASIC" },
  { no: 3, name: "CLUSTER_VIEW_PARTIAL" },
  { no: 2, name: "CLUSTER_VIEW_FULL" },
]);

/**
 * UpdateClustersRequest is the request for UpdateClusters.
 *
 * @generated from message nozzle.reports.v1.UpdateClustersRequest
 */
export class UpdateClustersRequest extends Message<UpdateClustersRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: int64 project_id = 2;
   */
  projectId = protoInt64.zero;

  /**
   * @generated from field: string job_slug = 3;
   */
  jobSlug = "";

  /**
   * if true, the request will wait for the clustering operation to complete before returning,
   * and the job will be executed with higher priority. If false, the request will return immediately
   * with the longrunning operation id, and the job will be executed with lower priority. If there is
   * a timeout that does not stop the job from running, the job will continue to run in the background,
   * and the operation will not be marked as done.
   *
   * @generated from field: bool wait_for_completion = 4;
   */
  waitForCompletion = false;

  /**
   * these domains will be eligible to be focus domains for the cluster. There is a maximum
   * of 10 domains allowed. If fewer than 10 are provided, the remaining domains will be
   * populated from brands defined on the project, prioritizing owned brands first.
   *
   * @generated from field: repeated string included_domains = 5;
   */
  includedDomains: string[] = [];

  /**
   * define the minimum number of urls that must appear in the top 10 results for all keywords
   * in order for a cluster to be created
   *
   * @generated from field: int32 min_url_count = 6;
   */
  minUrlCount = 0;

  /**
   * start and end dates for the clustering period. Multiple SERPs for the same keyword will be
   * averaged together, so the best performing urls over the whole period will be used. It is fine
   * for keywords with different schedules to be included in the same job, as long as there is at
   * least a single SERP for each keyword in the period.
   *
   * @generated from field: google.protobuf.Timestamp start = 7;
   */
  start?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp end = 8;
   */
  end?: Timestamp;

  /**
   * allow_missing must be true, as only upserts are currently supported
   *
   * @generated from field: bool allow_missing = 9;
   */
  allowMissing = false;

  constructor(data?: PartialMessage<UpdateClustersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.reports.v1.UpdateClustersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "project_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "job_slug", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "wait_for_completion", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "included_domains", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "min_url_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "start", kind: "message", T: Timestamp },
    { no: 8, name: "end", kind: "message", T: Timestamp },
    { no: 9, name: "allow_missing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateClustersRequest {
    return new UpdateClustersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateClustersRequest {
    return new UpdateClustersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateClustersRequest {
    return new UpdateClustersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateClustersRequest | PlainMessage<UpdateClustersRequest> | undefined, b: UpdateClustersRequest | PlainMessage<UpdateClustersRequest> | undefined): boolean {
    return proto3.util.equals(UpdateClustersRequest, a, b);
  }
}

/**
 * UpdateClustersResponse is the response for UpdateClusters.
 *
 * @generated from message nozzle.reports.v1.UpdateClustersResponse
 */
export class UpdateClustersResponse extends Message<UpdateClustersResponse> {
  /**
   * @generated from field: google.longrunning.Operation operation = 1;
   */
  operation?: Operation;

  constructor(data?: PartialMessage<UpdateClustersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.reports.v1.UpdateClustersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "operation", kind: "message", T: Operation },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateClustersResponse {
    return new UpdateClustersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateClustersResponse {
    return new UpdateClustersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateClustersResponse {
    return new UpdateClustersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateClustersResponse | PlainMessage<UpdateClustersResponse> | undefined, b: UpdateClustersResponse | PlainMessage<UpdateClustersResponse> | undefined): boolean {
    return proto3.util.equals(UpdateClustersResponse, a, b);
  }
}

/**
 * ListClustersFacetsRequest is the request for ListClustersFacets.
 *
 * @generated from message nozzle.reports.v1.ListClustersFacetsRequest
 */
export class ListClustersFacetsRequest extends Message<ListClustersFacetsRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: int64 project_id = 2;
   */
  projectId = protoInt64.zero;

  /**
   * @generated from field: int64 job_id = 3;
   */
  jobId = protoInt64.zero;

  /**
   * if specified, each facet will be limited to the top N values
   *
   * @generated from field: int32 max_values_per_facet = 4;
   */
  maxValuesPerFacet = 0;

  /**
   * @generated from field: int64 domain_id = 5;
   */
  domainId = protoInt64.zero;

  /**
   * @generated from field: nozzle.reports.v1.ListClustersFilters filters = 6;
   */
  filters?: ListClustersFilters;

  constructor(data?: PartialMessage<ListClustersFacetsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.reports.v1.ListClustersFacetsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "project_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "job_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "max_values_per_facet", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "domain_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "filters", kind: "message", T: ListClustersFilters },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListClustersFacetsRequest {
    return new ListClustersFacetsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListClustersFacetsRequest {
    return new ListClustersFacetsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListClustersFacetsRequest {
    return new ListClustersFacetsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListClustersFacetsRequest | PlainMessage<ListClustersFacetsRequest> | undefined, b: ListClustersFacetsRequest | PlainMessage<ListClustersFacetsRequest> | undefined): boolean {
    return proto3.util.equals(ListClustersFacetsRequest, a, b);
  }
}

/**
 * ListClustersFacetsResponse is the response for ListClustersFacets.
 *
 * @generated from message nozzle.reports.v1.ListClustersFacetsResponse
 */
export class ListClustersFacetsResponse extends Message<ListClustersFacetsResponse> {
  /**
   * @generated from field: nozzle.reports.v1.Facet url_counts = 1;
   */
  urlCounts?: Facet;

  /**
   * @generated from field: nozzle.reports.v1.Facet keyword_counts = 2;
   */
  keywordCounts?: Facet;

  /**
   * @generated from field: nozzle.reports.v1.Facet search_volume = 3;
   */
  searchVolume?: Facet;

  /**
   * @generated from field: nozzle.reports.v1.Facet traffic_opportunity = 4;
   */
  trafficOpportunity?: Facet;

  /**
   * @generated from field: nozzle.reports.v1.Facet cpc = 5;
   */
  cpc?: Facet;

  /**
   * @generated from field: nozzle.reports.v1.Facet top_rank = 6;
   */
  topRank?: Facet;

  /**
   * @generated from field: nozzle.reports.v1.Facet serp_features = 7;
   */
  serpFeatures?: Facet;

  /**
   * @generated from field: nozzle.reports.v1.Facet domains = 8;
   */
  domains?: Facet;

  /**
   * @generated from field: nozzle.reports.v1.Facet urls = 9;
   */
  urls?: Facet;

  /**
   * @generated from field: nozzle.reports.v1.Facet keyword_groups = 10;
   */
  keywordGroups?: Facet;

  /**
   * @generated from field: nozzle.reports.v1.Facet keywords = 11;
   */
  keywords?: Facet;

  /**
   * @generated from field: nozzle.reports.v1.Facet people_also_ask = 12;
   */
  peopleAlsoAsk?: Facet;

  /**
   * @generated from field: nozzle.reports.v1.Facet faq = 13;
   */
  faq?: Facet;

  /**
   * @generated from field: nozzle.reports.v1.Facet focused_rollups = 14;
   */
  focusedRollups?: Facet;

  constructor(data?: PartialMessage<ListClustersFacetsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.reports.v1.ListClustersFacetsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url_counts", kind: "message", T: Facet },
    { no: 2, name: "keyword_counts", kind: "message", T: Facet },
    { no: 3, name: "search_volume", kind: "message", T: Facet },
    { no: 4, name: "traffic_opportunity", kind: "message", T: Facet },
    { no: 5, name: "cpc", kind: "message", T: Facet },
    { no: 6, name: "top_rank", kind: "message", T: Facet },
    { no: 7, name: "serp_features", kind: "message", T: Facet },
    { no: 8, name: "domains", kind: "message", T: Facet },
    { no: 9, name: "urls", kind: "message", T: Facet },
    { no: 10, name: "keyword_groups", kind: "message", T: Facet },
    { no: 11, name: "keywords", kind: "message", T: Facet },
    { no: 12, name: "people_also_ask", kind: "message", T: Facet },
    { no: 13, name: "faq", kind: "message", T: Facet },
    { no: 14, name: "focused_rollups", kind: "message", T: Facet },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListClustersFacetsResponse {
    return new ListClustersFacetsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListClustersFacetsResponse {
    return new ListClustersFacetsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListClustersFacetsResponse {
    return new ListClustersFacetsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListClustersFacetsResponse | PlainMessage<ListClustersFacetsResponse> | undefined, b: ListClustersFacetsResponse | PlainMessage<ListClustersFacetsResponse> | undefined): boolean {
    return proto3.util.equals(ListClustersFacetsResponse, a, b);
  }
}

/**
 * ListClustersRequest is the request for ListClusters.
 *
 * @generated from message nozzle.reports.v1.ListClustersRequest
 */
export class ListClustersRequest extends Message<ListClustersRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: int64 project_id = 2;
   */
  projectId = protoInt64.zero;

  /**
   * @generated from field: int64 job_id = 3;
   */
  jobId = protoInt64.zero;

  /**
   * @generated from field: int64 domain_id = 4;
   */
  domainId = protoInt64.zero;

  /**
   * @generated from field: nozzle.reports.v1.ListClustersFilters filters = 5;
   */
  filters?: ListClustersFilters;

  /**
   * this is the max number of keywords, paa, serp features, and share of voice to return per cluster.
   * all cluster urls will be returned, regardless of this value.
   *
   * @generated from field: int32 max_sub_rows = 9;
   */
  maxSubRows = 0;

  /**
   * @generated from field: nozzle.reports.v1.ClusterView view = 6;
   */
  view = ClusterView.UNSPECIFIED;

  /**
   * optional pagination
   *
   * @generated from field: string page_token = 7;
   */
  pageToken = "";

  /**
   * page_size defaults:
   * - CLUSTER_VIEW_BASIC: 10,000
   * - CLUSTER_VIEW_FULL:     100
   *
   * @generated from field: int32 page_size = 8;
   */
  pageSize = 0;

  constructor(data?: PartialMessage<ListClustersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.reports.v1.ListClustersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "project_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "job_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "domain_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "filters", kind: "message", T: ListClustersFilters },
    { no: 9, name: "max_sub_rows", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "view", kind: "enum", T: proto3.getEnumType(ClusterView) },
    { no: 7, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListClustersRequest {
    return new ListClustersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListClustersRequest {
    return new ListClustersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListClustersRequest {
    return new ListClustersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListClustersRequest | PlainMessage<ListClustersRequest> | undefined, b: ListClustersRequest | PlainMessage<ListClustersRequest> | undefined): boolean {
    return proto3.util.equals(ListClustersRequest, a, b);
  }
}

/**
 * ListClustersFilters describes all the available filters
 *
 * @generated from message nozzle.reports.v1.ListClustersFilters
 */
export class ListClustersFilters extends Message<ListClustersFilters> {
  /**
   * only return clusters where the number of clustered urls is greater than or equal to min_url_count
   *
   * @generated from field: int32 min_url_count = 1;
   */
  minUrlCount = 0;

  /**
   * only return clusters where the number of clustered urls is less than or equal to max_url_count
   *
   * @generated from field: int32 max_url_count = 2;
   */
  maxUrlCount = 0;

  /**
   * only return clusters where the number of clustered keywords is greater than or equal to min_keyword_count
   *
   * @generated from field: int32 min_keyword_count = 3;
   */
  minKeywordCount = 0;

  /**
   * only return clusters where the number of clustered keywords is less than or equal to max_keyword_count
   *
   * @generated from field: int32 max_keyword_count = 4;
   */
  maxKeywordCount = 0;

  /**
   * only return clusters where the cluster search volume is greater than or equal to min_search_volume
   *
   * @generated from field: int32 min_search_volume = 5;
   */
  minSearchVolume = 0;

  /**
   * only return clusters where the cluster search volume is less than or equal to max_search_volume
   *
   * @generated from field: int32 max_search_volume = 6;
   */
  maxSearchVolume = 0;

  /**
   * only return clusters where the cluster traffic opportunity is greater than or equal to min_traffic_opportunity
   *
   * @generated from field: int32 min_traffic_opportunity = 7;
   */
  minTrafficOpportunity = 0;

  /**
   * only return clusters where the cluster traffic opportunity is less than or equal to max_traffic_opportunity
   *
   * @generated from field: int32 max_traffic_opportunity = 8;
   */
  maxTrafficOpportunity = 0;

  /**
   * only return clusters where the average cluster cpc is greater than or equal to min_cpc
   *
   * @generated from field: float min_cpc = 9;
   */
  minCpc = 0;

  /**
   * only return clusters where the average cluster cpc is less than or equal to max_cpc
   *
   * @generated from field: float max_cpc = 10;
   */
  maxCpc = 0;

  /**
   * only return clusters where the focus domain rank is greater than or equal to min_top_rank
   *
   * @generated from field: float min_top_rank = 11;
   */
  minTopRank = 0;

  /**
   * only return clusters where the focus domain rank is less than or equal to max_top_rank
   *
   * @generated from field: float max_top_rank = 12;
   */
  maxTopRank = 0;

  /**
   * only returns clusters where any of the provided serp features appear in a keyword that belongs to the cluster
   *
   * @generated from field: repeated string include_serp_features = 26;
   */
  includeSerpFeatures: string[] = [];

  /**
   * only returns clusters where none of the provided serp features appear in a keyword that belongs to the cluster
   *
   * @generated from field: repeated string exclude_serp_features = 27;
   */
  excludeSerpFeatures: string[] = [];

  /**
   * only returns clusters where the provided domain id matches one of the cluster urls
   *
   * @generated from field: repeated int64 include_domain_ids = 14;
   */
  includeDomainIds: bigint[] = [];

  /**
   * only returns clusters where the provided domain id does NOT match one of the cluster urls
   *
   * @generated from field: repeated int64 exclude_domain_ids = 15;
   */
  excludeDomainIds: bigint[] = [];

  /**
   * only returns clusters where any of the provided url ids are one of the cluster urls
   *
   * @generated from field: repeated int64 include_url_ids = 16;
   */
  includeUrlIds: bigint[] = [];

  /**
   * only returns clusters where none of the provided url ids are one of the cluster urls
   *
   * @generated from field: repeated int64 exclude_url_ids = 17;
   */
  excludeUrlIds: bigint[] = [];

  /**
   * only returns clusters where at least one of the provided keyword groups are set on any keyword id
   * that belongs to the cluster
   *
   * @generated from field: repeated string include_keyword_groups = 18;
   */
  includeKeywordGroups: string[] = [];

  /**
   * only returns clusters where none of the provided keyword groups are set on any keyword id
   * that belongs to the cluster
   *
   * @generated from field: repeated string exclude_keyword_groups = 19;
   */
  excludeKeywordGroups: string[] = [];

  /**
   * only returns clusters where any of the provided keyword id belongs to the cluster
   *
   * @generated from field: repeated int64 include_keyword_ids = 20;
   */
  includeKeywordIds: bigint[] = [];

  /**
   * only returns clusters where none of the provided keyword ids belong to the cluster
   *
   * @generated from field: repeated int64 exclude_keyword_ids = 21;
   */
  excludeKeywordIds: bigint[] = [];

  /**
   * only returns clusters where any of the provided phrases are in at least one keyword that belongs to the cluster
   *
   * @generated from field: repeated string phrase_contains = 22;
   */
  phraseContains: string[] = [];

  /**
   * only returns clusters where none of the provided phrases are in at least one keyword that belongs to the cluster
   *
   * @generated from field: repeated string phrase_does_not_contain = 23;
   */
  phraseDoesNotContain: string[] = [];

  /**
   * only returns clusters where the provided regex matches at least one keyword that belongs to the cluster
   *
   * @generated from field: string phrase_regex_contains = 24;
   */
  phraseRegexContains = "";

  /**
   * only returns clusters where the provided regex matches at zero keywords that belongs to the cluster
   *
   * @generated from field: string phrase_regex_does_not_contain = 25;
   */
  phraseRegexDoesNotContain = "";

  /**
   * only returns clusters where at least one of the provided people also ask questions appears in the cluster
   *
   * @generated from field: repeated string include_people_also_ask = 28;
   */
  includePeopleAlsoAsk: string[] = [];

  /**
   * only returns clusters where zero of the provided people also ask questions appears in the cluster
   *
   * @generated from field: repeated string exclude_people_also_ask = 29;
   */
  excludePeopleAlsoAsk: string[] = [];

  /**
   * only returns clusters where at least one of the provided faqs appears in the cluster
   *
   * @generated from field: repeated string include_faq = 30;
   */
  includeFaq: string[] = [];

  /**
   * only returns clusters where zero of the provided faqs appears in the cluster
   *
   * @generated from field: repeated string exclude_faq = 31;
   */
  excludeFaq: string[] = [];

  /**
   * only returns specified clusters
   *
   * @generated from field: repeated int64 include_cluster_ids = 32;
   */
  includeClusterIds: bigint[] = [];

  /**
   * returns all matching clusters except the specified clusters
   *
   * @generated from field: repeated int64 exclude_cluster_ids = 33;
   */
  excludeClusterIds: bigint[] = [];

  constructor(data?: PartialMessage<ListClustersFilters>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.reports.v1.ListClustersFilters";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "min_url_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "max_url_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "min_keyword_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "max_keyword_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "min_search_volume", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "max_search_volume", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "min_traffic_opportunity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "max_traffic_opportunity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "min_cpc", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 10, name: "max_cpc", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 11, name: "min_top_rank", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 12, name: "max_top_rank", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 26, name: "include_serp_features", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 27, name: "exclude_serp_features", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 14, name: "include_domain_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 15, name: "exclude_domain_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 16, name: "include_url_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 17, name: "exclude_url_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 18, name: "include_keyword_groups", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 19, name: "exclude_keyword_groups", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 20, name: "include_keyword_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 21, name: "exclude_keyword_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 22, name: "phrase_contains", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 23, name: "phrase_does_not_contain", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 24, name: "phrase_regex_contains", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 25, name: "phrase_regex_does_not_contain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 28, name: "include_people_also_ask", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 29, name: "exclude_people_also_ask", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 30, name: "include_faq", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 31, name: "exclude_faq", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 32, name: "include_cluster_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 33, name: "exclude_cluster_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListClustersFilters {
    return new ListClustersFilters().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListClustersFilters {
    return new ListClustersFilters().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListClustersFilters {
    return new ListClustersFilters().fromJsonString(jsonString, options);
  }

  static equals(a: ListClustersFilters | PlainMessage<ListClustersFilters> | undefined, b: ListClustersFilters | PlainMessage<ListClustersFilters> | undefined): boolean {
    return proto3.util.equals(ListClustersFilters, a, b);
  }
}

/**
 * ListClustersResponse is the response for ListClusters.
 *
 * @generated from message nozzle.reports.v1.ListClustersResponse
 */
export class ListClustersResponse extends Message<ListClustersResponse> {
  /**
   * @generated from field: repeated nozzle.reports.v1.Cluster clusters = 1;
   */
  clusters: Cluster[] = [];

  /**
   * the original domains, min_url_count, start, and end from the UpdateClustersRequest
   *
   * @generated from field: repeated nozzle.reports.v1.RollupMetrics metrics_by_focused_rollup = 5;
   */
  metricsByFocusedRollup: RollupMetrics[] = [];

  /**
   * @generated from field: int32 min_url_count = 6;
   */
  minUrlCount = 0;

  /**
   * @generated from field: google.protobuf.Timestamp start = 7;
   */
  start?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp end = 8;
   */
  end?: Timestamp;

  /**
   * @generated from field: string next_page_token = 9;
   */
  nextPageToken = "";

  /**
   * @generated from field: int32 total_size = 10;
   */
  totalSize = 0;

  constructor(data?: PartialMessage<ListClustersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.reports.v1.ListClustersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "clusters", kind: "message", T: Cluster, repeated: true },
    { no: 5, name: "metrics_by_focused_rollup", kind: "message", T: RollupMetrics, repeated: true },
    { no: 6, name: "min_url_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "start", kind: "message", T: Timestamp },
    { no: 8, name: "end", kind: "message", T: Timestamp },
    { no: 9, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "total_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListClustersResponse {
    return new ListClustersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListClustersResponse {
    return new ListClustersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListClustersResponse {
    return new ListClustersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListClustersResponse | PlainMessage<ListClustersResponse> | undefined, b: ListClustersResponse | PlainMessage<ListClustersResponse> | undefined): boolean {
    return proto3.util.equals(ListClustersResponse, a, b);
  }
}

/**
 * GetClusterRequest is the request for GetCluster.
 *
 * @generated from message nozzle.reports.v1.GetClusterRequest
 */
export class GetClusterRequest extends Message<GetClusterRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: int64 project_id = 2;
   */
  projectId = protoInt64.zero;

  /**
   * @generated from field: int64 job_id = 4;
   */
  jobId = protoInt64.zero;

  /**
   * @generated from field: int64 cluster_id = 3;
   */
  clusterId = protoInt64.zero;

  constructor(data?: PartialMessage<GetClusterRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.reports.v1.GetClusterRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "project_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "job_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "cluster_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetClusterRequest {
    return new GetClusterRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetClusterRequest {
    return new GetClusterRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetClusterRequest {
    return new GetClusterRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetClusterRequest | PlainMessage<GetClusterRequest> | undefined, b: GetClusterRequest | PlainMessage<GetClusterRequest> | undefined): boolean {
    return proto3.util.equals(GetClusterRequest, a, b);
  }
}

/**
 * GetClusterResponse is the response for GetCluster.
 *
 * @generated from message nozzle.reports.v1.GetClusterResponse
 */
export class GetClusterResponse extends Message<GetClusterResponse> {
  /**
   * @generated from field: nozzle.reports.v1.Cluster cluster = 1;
   */
  cluster?: Cluster;

  constructor(data?: PartialMessage<GetClusterResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.reports.v1.GetClusterResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "cluster", kind: "message", T: Cluster },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetClusterResponse {
    return new GetClusterResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetClusterResponse {
    return new GetClusterResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetClusterResponse {
    return new GetClusterResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetClusterResponse | PlainMessage<GetClusterResponse> | undefined, b: GetClusterResponse | PlainMessage<GetClusterResponse> | undefined): boolean {
    return proto3.util.equals(GetClusterResponse, a, b);
  }
}

/**
 * ExportClustersRequest is the request for ExportClusters.
 *
 * @generated from message nozzle.reports.v1.ExportClustersRequest
 */
export class ExportClustersRequest extends Message<ExportClustersRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: int64 project_id = 2;
   */
  projectId = protoInt64.zero;

  /**
   * @generated from field: int64 job_id = 3;
   */
  jobId = protoInt64.zero;

  /**
   * @generated from field: int64 domain_id = 4;
   */
  domainId = protoInt64.zero;

  /**
   * @generated from field: nozzle.reports.v1.ListClustersFilters filters = 5;
   */
  filters?: ListClustersFilters;

  /**
   * @generated from field: bool wait_for_completion = 6;
   */
  waitForCompletion = false;

  /**
   * name of the spreadsheet, can be empty
   *
   * @generated from field: string name = 7;
   */
  name = "";

  /**
   * @generated from oneof nozzle.reports.v1.ExportClustersRequest.options
   */
  options: {
    /**
     * @generated from field: nozzle.reports.v1.ExportOptionCsv csv = 8;
     */
    value: ExportOptionCsv;
    case: "csv";
  } | {
    /**
     * @generated from field: nozzle.reports.v1.ExportOptionExcel excel = 9;
     */
    value: ExportOptionExcel;
    case: "excel";
  } | {
    /**
     * @generated from field: nozzle.reports.v1.ExportOptionGoogleSheets google_sheets = 10;
     */
    value: ExportOptionGoogleSheets;
    case: "googleSheets";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ExportClustersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.reports.v1.ExportClustersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "project_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "job_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "domain_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "filters", kind: "message", T: ListClustersFilters },
    { no: 6, name: "wait_for_completion", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "csv", kind: "message", T: ExportOptionCsv, oneof: "options" },
    { no: 9, name: "excel", kind: "message", T: ExportOptionExcel, oneof: "options" },
    { no: 10, name: "google_sheets", kind: "message", T: ExportOptionGoogleSheets, oneof: "options" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExportClustersRequest {
    return new ExportClustersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExportClustersRequest {
    return new ExportClustersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExportClustersRequest {
    return new ExportClustersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ExportClustersRequest | PlainMessage<ExportClustersRequest> | undefined, b: ExportClustersRequest | PlainMessage<ExportClustersRequest> | undefined): boolean {
    return proto3.util.equals(ExportClustersRequest, a, b);
  }
}

/**
 * ExportClustersResponse is the response for ExportClusters.
 *
 * @generated from message nozzle.reports.v1.ExportClustersResponse
 */
export class ExportClustersResponse extends Message<ExportClustersResponse> {
  constructor(data?: PartialMessage<ExportClustersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.reports.v1.ExportClustersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExportClustersResponse {
    return new ExportClustersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExportClustersResponse {
    return new ExportClustersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExportClustersResponse {
    return new ExportClustersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ExportClustersResponse | PlainMessage<ExportClustersResponse> | undefined, b: ExportClustersResponse | PlainMessage<ExportClustersResponse> | undefined): boolean {
    return proto3.util.equals(ExportClustersResponse, a, b);
  }
}

/**
 * Cluster is a group of keywords that share common urls, with accompanying metrics
 *
 * @generated from message nozzle.reports.v1.Cluster
 */
export class Cluster extends Message<Cluster> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: int64 project_id = 2;
   */
  projectId = protoInt64.zero;

  /**
   * @generated from field: int64 cluster_id = 3;
   */
  clusterId = protoInt64.zero;

  /**
   * @generated from field: sint64 cluster_hash = 4;
   */
  clusterHash = protoInt64.zero;

  /**
   * @generated from field: string name = 5;
   */
  name = "";

  /**
   * @generated from field: int32 keyword_count = 6;
   */
  keywordCount = 0;

  /**
   * @generated from field: int32 url_count = 7;
   */
  urlCount = 0;

  /**
   * @generated from field: nozzle.reports.v1.SerpMetrics cluster_metrics = 8;
   */
  clusterMetrics?: SerpMetrics;

  /**
   * @generated from field: repeated nozzle.reports.v1.RollupMetrics metrics_by_keyword_group = 14;
   */
  metricsByKeywordGroup: RollupMetrics[] = [];

  /**
   * @generated from field: repeated nozzle.reports.v1.ClusterUrl cluster_urls = 9;
   */
  clusterUrls: ClusterUrl[] = [];

  /**
   * @generated from field: repeated nozzle.reports.v1.RollupMetrics metrics_by_rollup = 10;
   */
  metricsByRollup: RollupMetrics[] = [];

  /**
   * @generated from field: nozzle.reports.v1.RollupMetrics focused_rollup_metrics = 17;
   */
  focusedRollupMetrics?: RollupMetrics;

  /**
   * @generated from field: repeated nozzle.reports.v1.KeywordMetrics metrics_by_keyword = 11;
   */
  metricsByKeyword: KeywordMetrics[] = [];

  /**
   * @generated from field: repeated nozzle.reports.v1.RollupMetrics metrics_by_serp_feature = 12;
   */
  metricsBySerpFeature: RollupMetrics[] = [];

  /**
   * @generated from field: nozzle.reports.v1.MetricsByRelatedPhraseType metrics_by_related_phrase_type = 13;
   */
  metricsByRelatedPhraseType?: MetricsByRelatedPhraseType;

  /**
   * @generated from field: repeated nozzle.reports.v1.EntityMetrics metrics_by_entity = 15;
   */
  metricsByEntity: EntityMetrics[] = [];

  /**
   * @generated from field: repeated nozzle.reports.v1.CategoryMetrics metrics_by_category = 16;
   */
  metricsByCategory: CategoryMetrics[] = [];

  constructor(data?: PartialMessage<Cluster>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.reports.v1.Cluster";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "project_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "cluster_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "cluster_hash", kind: "scalar", T: 18 /* ScalarType.SINT64 */ },
    { no: 5, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "keyword_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "url_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "cluster_metrics", kind: "message", T: SerpMetrics },
    { no: 14, name: "metrics_by_keyword_group", kind: "message", T: RollupMetrics, repeated: true },
    { no: 9, name: "cluster_urls", kind: "message", T: ClusterUrl, repeated: true },
    { no: 10, name: "metrics_by_rollup", kind: "message", T: RollupMetrics, repeated: true },
    { no: 17, name: "focused_rollup_metrics", kind: "message", T: RollupMetrics },
    { no: 11, name: "metrics_by_keyword", kind: "message", T: KeywordMetrics, repeated: true },
    { no: 12, name: "metrics_by_serp_feature", kind: "message", T: RollupMetrics, repeated: true },
    { no: 13, name: "metrics_by_related_phrase_type", kind: "message", T: MetricsByRelatedPhraseType },
    { no: 15, name: "metrics_by_entity", kind: "message", T: EntityMetrics, repeated: true },
    { no: 16, name: "metrics_by_category", kind: "message", T: CategoryMetrics, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Cluster {
    return new Cluster().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Cluster {
    return new Cluster().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Cluster {
    return new Cluster().fromJsonString(jsonString, options);
  }

  static equals(a: Cluster | PlainMessage<Cluster> | undefined, b: Cluster | PlainMessage<Cluster> | undefined): boolean {
    return proto3.util.equals(Cluster, a, b);
  }
}

/**
 * ClusterUrl is one of the urls shared across all keywords that defines the cluster
 *
 * @generated from message nozzle.reports.v1.ClusterUrl
 */
export class ClusterUrl extends Message<ClusterUrl> {
  /**
   * @generated from field: nozzle.urls.v1.Url url = 1;
   */
  url?: Url;

  /**
   * @generated from field: nozzle.urls.v1.Page page = 2;
   */
  page?: Page;

  /**
   * @generated from field: nozzle.reports.v1.RollupMetrics url_metrics = 3;
   */
  urlMetrics?: RollupMetrics;

  /**
   * @generated from field: repeated nozzle.reports.v1.RollupMetrics metrics_by_title = 4;
   */
  metricsByTitle: RollupMetrics[] = [];

  /**
   * @generated from field: repeated nozzle.reports.v1.RollupMetrics metrics_by_description = 5;
   */
  metricsByDescription: RollupMetrics[] = [];

  constructor(data?: PartialMessage<ClusterUrl>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.reports.v1.ClusterUrl";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "message", T: Url },
    { no: 2, name: "page", kind: "message", T: Page },
    { no: 3, name: "url_metrics", kind: "message", T: RollupMetrics },
    { no: 4, name: "metrics_by_title", kind: "message", T: RollupMetrics, repeated: true },
    { no: 5, name: "metrics_by_description", kind: "message", T: RollupMetrics, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ClusterUrl {
    return new ClusterUrl().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ClusterUrl {
    return new ClusterUrl().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ClusterUrl {
    return new ClusterUrl().fromJsonString(jsonString, options);
  }

  static equals(a: ClusterUrl | PlainMessage<ClusterUrl> | undefined, b: ClusterUrl | PlainMessage<ClusterUrl> | undefined): boolean {
    return proto3.util.equals(ClusterUrl, a, b);
  }
}

/**
 * MetricsByRelatedPhraseType is a collection of metrics for each related phrase type. This could have been
 * represented with a repeated struct but this is more explicit where the cardinality rarely changes.
 *
 * @generated from message nozzle.reports.v1.MetricsByRelatedPhraseType
 */
export class MetricsByRelatedPhraseType extends Message<MetricsByRelatedPhraseType> {
  /**
   * @generated from field: repeated nozzle.reports.v1.RollupMetrics people_also_ask = 1;
   */
  peopleAlsoAsk: RollupMetrics[] = [];

  /**
   * @generated from field: repeated nozzle.reports.v1.RollupMetrics faq = 2;
   */
  faq: RollupMetrics[] = [];

  /**
   * @generated from field: repeated nozzle.reports.v1.RollupMetrics consideration = 3;
   */
  consideration: RollupMetrics[] = [];

  /**
   * @generated from field: repeated nozzle.reports.v1.RollupMetrics things_to_know = 4;
   */
  thingsToKnow: RollupMetrics[] = [];

  /**
   * @generated from field: repeated nozzle.reports.v1.RollupMetrics people_also_search_for = 5;
   */
  peopleAlsoSearchFor: RollupMetrics[] = [];

  /**
   * @generated from field: repeated nozzle.reports.v1.RollupMetrics refine_by = 6;
   */
  refineBy: RollupMetrics[] = [];

  /**
   * @generated from field: repeated nozzle.reports.v1.RollupMetrics related_search = 7;
   */
  relatedSearch: RollupMetrics[] = [];

  /**
   * @generated from field: repeated nozzle.reports.v1.RollupMetrics other = 8;
   */
  other: RollupMetrics[] = [];

  constructor(data?: PartialMessage<MetricsByRelatedPhraseType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.reports.v1.MetricsByRelatedPhraseType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "people_also_ask", kind: "message", T: RollupMetrics, repeated: true },
    { no: 2, name: "faq", kind: "message", T: RollupMetrics, repeated: true },
    { no: 3, name: "consideration", kind: "message", T: RollupMetrics, repeated: true },
    { no: 4, name: "things_to_know", kind: "message", T: RollupMetrics, repeated: true },
    { no: 5, name: "people_also_search_for", kind: "message", T: RollupMetrics, repeated: true },
    { no: 6, name: "refine_by", kind: "message", T: RollupMetrics, repeated: true },
    { no: 7, name: "related_search", kind: "message", T: RollupMetrics, repeated: true },
    { no: 8, name: "other", kind: "message", T: RollupMetrics, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MetricsByRelatedPhraseType {
    return new MetricsByRelatedPhraseType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MetricsByRelatedPhraseType {
    return new MetricsByRelatedPhraseType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MetricsByRelatedPhraseType {
    return new MetricsByRelatedPhraseType().fromJsonString(jsonString, options);
  }

  static equals(a: MetricsByRelatedPhraseType | PlainMessage<MetricsByRelatedPhraseType> | undefined, b: MetricsByRelatedPhraseType | PlainMessage<MetricsByRelatedPhraseType> | undefined): boolean {
    return proto3.util.equals(MetricsByRelatedPhraseType, a, b);
  }
}

