import * as React from 'react'
import { twMerge } from 'tailwind-merge'
import Link from '../../components/Link'
import { createCheckboxColumn } from '../../components/CheckboxColumn'
import { createExpanderColumn } from '../../components/ExpanderColumn'
import { formatNumber } from '../../utils/Format'
import { clusterFocusMetricsList, clusterMetricsList, metrics } from './metrics'
import Tooltip from '../../components/Tooltip'
import { IoMdInformationCircleOutline } from 'react-icons/io'
import { createColumnHelper } from '@tanstack/react-table'
import { ClusterPb } from '../../utils/proto'

const clusterColumnHelper = createColumnHelper<ClusterPb>()

export const clusterColumns = [
  createExpanderColumn({ canExpandAll: false }),
  createCheckboxColumn(),
  clusterColumnHelper.group({
    header: 'Cluster',
    columns: [
      clusterColumnHelper.accessor('name', {
        header: 'Name',
        filterFn: 'fuzzy',
        cell: cellProps => {
          return (
            <Link
              search={d => ({
                ...d,
                clusterId: String(cellProps.row.original.clusterId),
                urlId: undefined,
                keywordId: undefined,
              })}
            >
              {cellProps.getValue()}
            </Link>
          )
        },
        meta: {
          getHeaderProps: () => ({
            className: `sticky left-0 z-10 bg-white dark:bg-gray-900`,
          }),
          getCellProps: () => ({
            className: `sticky left-0 z-10 bg-white dark:bg-gray-900`,
          }),
        },
      }),
      clusterColumnHelper.accessor('keywordCount', {
        id: 'Keyword Count',
        header: () => (
          <Tooltip tooltip={metrics.keywordCount.tooltip}>Keywords</Tooltip>
        ),
        sortDescFirst: true,
        cell: cellProps => (
          <Link
            search={prev => ({
              ...prev,
              clusterId: String(cellProps.row.original.clusterId),
              urlId: undefined,
              keywordId: undefined,
              clusterPanel: 'keywords',
            })}
          >
            {formatNumber(cellProps.getValue()!)}
          </Link>
        ),
        meta: {
          getCellProps: () => {
            return {
              className: 'text-right',
            }
          },
        },
      }),
      clusterColumnHelper.accessor('urlCount', {
        id: 'URL Count',
        header: () => (
          <Tooltip tooltip={metrics.urlCount.tooltip}>URLs</Tooltip>
        ),
        sortDescFirst: true,
        cell: cellProps => (
          <Link
            search={prev => ({
              ...prev,
              clusterId: String(cellProps.row.original.clusterId),
              urlId: undefined,
              keywordId: undefined,
              clusterPanel: 'urls',
            })}
          >
            {formatNumber(cellProps.getValue()!)}
          </Link>
        ),
        meta: {
          getCellProps: () => {
            return {
              className: 'text-right',
            }
          },
        },
      }),
      ...clusterMetricsList.map((metric, i) => {
        return clusterColumnHelper.accessor(
          d => metric.getValue(d.clusterMetrics![metric.id]!),
          {
            header: () => (
              <Tooltip tooltip={metric.tooltip}>{metric.label}</Tooltip>
            ),
            id: metric.label,
            sortDescFirst: true,
            invertSorting: metric.inverted,
            cell: cellProps => metric.renderValue(cellProps.getValue()!),
            meta: {
              getCellProps: () => {
                return {
                  className: twMerge(
                    'text-right',
                    i === clusterMetricsList.length - 1 &&
                      `border-r-4 border-solid border-gray-500/50`
                  ),
                }
              },
            },
          }
        )
      }),
    ],
  }),
  clusterColumnHelper.group({
    id: 'Focused Domain',
    header: () => (
      <Tooltip
        tooltip={`These metrics are specific to the selected focus domain. If a domain doesn't rank, these metrics will be blank or assumed non-ranking.`}
        className="flex items-center gap-1"
      >
        Focused Domain <IoMdInformationCircleOutline />
      </Tooltip>
    ),
    columns: clusterFocusMetricsList.map(metric => {
      return clusterColumnHelper.accessor(
        d => metric.getValue(d.focusedRollupMetrics?.metrics?.[metric.id]),
        {
          header: () => (
            <Tooltip tooltip={metric.tooltip}>{metric.label}</Tooltip>
          ),
          id: metric.label,
          cell: cellProps => metric.renderValue(cellProps.getValue()),
          invertSorting: metric.inverted,
          meta: {
            getCellProps: () => {
              return {
                className: 'text-right',
              }
            },
          },
        }
      )
    }),
  }),
]
