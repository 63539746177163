// @generated by protoc-gen-es v1.3.0 with parameter "target=ts"
// @generated from file nozzle/reports/v1/export.proto (package nozzle.reports.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * ExportOptionCsv contains generic CSV export options
 *
 * @generated from message nozzle.reports.v1.ExportOptionCsv
 */
export class ExportOptionCsv extends Message<ExportOptionCsv> {
  constructor(data?: PartialMessage<ExportOptionCsv>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.reports.v1.ExportOptionCsv";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExportOptionCsv {
    return new ExportOptionCsv().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExportOptionCsv {
    return new ExportOptionCsv().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExportOptionCsv {
    return new ExportOptionCsv().fromJsonString(jsonString, options);
  }

  static equals(a: ExportOptionCsv | PlainMessage<ExportOptionCsv> | undefined, b: ExportOptionCsv | PlainMessage<ExportOptionCsv> | undefined): boolean {
    return proto3.util.equals(ExportOptionCsv, a, b);
  }
}

/**
 * ExportOptionExcel contains generic Excel export options
 *
 * @generated from message nozzle.reports.v1.ExportOptionExcel
 */
export class ExportOptionExcel extends Message<ExportOptionExcel> {
  constructor(data?: PartialMessage<ExportOptionExcel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.reports.v1.ExportOptionExcel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExportOptionExcel {
    return new ExportOptionExcel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExportOptionExcel {
    return new ExportOptionExcel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExportOptionExcel {
    return new ExportOptionExcel().fromJsonString(jsonString, options);
  }

  static equals(a: ExportOptionExcel | PlainMessage<ExportOptionExcel> | undefined, b: ExportOptionExcel | PlainMessage<ExportOptionExcel> | undefined): boolean {
    return proto3.util.equals(ExportOptionExcel, a, b);
  }
}

/**
 * ExportOptionGoogleSheets contains generic Google Sheets export options
 *
 * @generated from message nozzle.reports.v1.ExportOptionGoogleSheets
 */
export class ExportOptionGoogleSheets extends Message<ExportOptionGoogleSheets> {
  /**
   * @generated from field: string spreadsheet_id = 1;
   */
  spreadsheetId = "";

  /**
   * @generated from field: string access_token = 2;
   */
  accessToken = "";

  constructor(data?: PartialMessage<ExportOptionGoogleSheets>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.reports.v1.ExportOptionGoogleSheets";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "spreadsheet_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "access_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExportOptionGoogleSheets {
    return new ExportOptionGoogleSheets().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExportOptionGoogleSheets {
    return new ExportOptionGoogleSheets().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExportOptionGoogleSheets {
    return new ExportOptionGoogleSheets().fromJsonString(jsonString, options);
  }

  static equals(a: ExportOptionGoogleSheets | PlainMessage<ExportOptionGoogleSheets> | undefined, b: ExportOptionGoogleSheets | PlainMessage<ExportOptionGoogleSheets> | undefined): boolean {
    return proto3.util.equals(ExportOptionGoogleSheets, a, b);
  }
}

