// @generated by protoc-gen-connect-web v0.11.0 with parameter "target=ts"
// @generated from file nozzle/reports/v1/cluster.proto (package nozzle.reports.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { ExportClustersRequest, ExportClustersResponse, GetClusterRequest, GetClusterResponse, ListClustersFacetsRequest, ListClustersFacetsResponse, ListClustersRequest, ListClustersResponse, UpdateClustersRequest, UpdateClustersResponse } from "./cluster_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * ClustersService provides APIs for clustering keywords
 *
 * @generated from service nozzle.reports.v1.ClustersService
 */
export const ClustersService = {
  typeName: "nozzle.reports.v1.ClustersService",
  methods: {
    /**
     * UpdateClusters returns all the clusters for a given job
     *
     * @generated from rpc nozzle.reports.v1.ClustersService.UpdateClusters
     */
    updateClusters: {
      name: "UpdateClusters",
      I: UpdateClustersRequest,
      O: UpdateClustersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ListClusters returns all the clusters for a given job
     *
     * @generated from rpc nozzle.reports.v1.ClustersService.ListClusters
     */
    listClusters: {
      name: "ListClusters",
      I: ListClustersRequest,
      O: ListClustersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ListClustersFacets returns facets to be used with ListClusters for a given job
     *
     * @generated from rpc nozzle.reports.v1.ClustersService.ListClustersFacets
     */
    listClustersFacets: {
      name: "ListClustersFacets",
      I: ListClustersFacetsRequest,
      O: ListClustersFacetsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetCluster returns a single cluster from a given job
     *
     * @generated from rpc nozzle.reports.v1.ClustersService.GetCluster
     */
    getCluster: {
      name: "GetCluster",
      I: GetClusterRequest,
      O: GetClusterResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ExportClusters generates a CSV, Excel, or Google Sheets spreadsheet of all the clusters for a given job
     *
     * @generated from rpc nozzle.reports.v1.ClustersService.ExportClusters
     */
    exportClusters: {
      name: "ExportClusters",
      I: ExportClustersRequest,
      O: ExportClustersResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

