import { useMutation, useQuery, useQueryClient } from 'react-query'
import { onBillingAccountChangeSubscription } from '../utils/Api'
import { queryKeyAccount } from '../utils/Constants'
import useErrorPopup from './useErrorPopup'
import useLoading from './useLoading'
import { useQuerySubscription } from './useQuerySubscription'
import useToast from './useToast'
import { useActiveWorkspaceId } from './workspaces'
import {
  AccountsClient,
  OveragesClient,
  AccountViewPb,
  CardTypePb,
} from '../utils/proto'

export type MaxOverageDraft = {
  limitBy: 'amount' | 'usage'
  amount: number
  usage: number
}

export function useAccount(opts: {
  workspaceId?: string
  view: AccountViewPb
}) {
  const activeWorkspaceId = useActiveWorkspaceId()
  const workspaceId = opts.workspaceId ?? activeWorkspaceId

  useQuerySubscription({
    kind: queryKeyAccount,
    label: 'Account Subscription',
    subscriber: onBillingAccountChangeSubscription,
  })

  return useQuery(
    [queryKeyAccount, workspaceId, opts?.view],
    () =>
      AccountsClient.getAccount({
        workspaceId: BigInt(workspaceId),
        view: opts?.view ?? AccountViewPb.FULL,
      }).then(res => res.account),
    {
      enabled: !!workspaceId,
      retry: false,
    }
  )
}

export function useUpdateBillingDetailsMutation() {
  const queryClient = useQueryClient()
  const toast = useToast()
  const errorPopup = useErrorPopup()
  const [, setLoading] = useLoading()

  return useMutation(AccountsClient.updateBillingDetails, {
    onMutate: () => {
      setLoading(true)
    },
    onSuccess: async () => {
      queryClient.invalidateQueries([queryKeyAccount])
      toast({
        message: 'Billing details updated.',
        color: 'green-500',
      })
    },
    onError: () => {
      errorPopup('Failed to update billing details.')
    },
    onSettled: () => {
      setLoading(false)
    },
  })
}

export function useSetMaxOverageMutation() {
  const queryClient = useQueryClient()
  const toast = useToast()
  const errorPopup = useErrorPopup()
  const [, setLoading] = useLoading()

  return useMutation(OveragesClient.updateMaxOverage, {
    onMutate: () => {
      setLoading(true)
    },
    onSuccess: () => {
      toast({
        message: 'Max overage updated.',
        color: 'green-500',
      })
      queryClient.invalidateQueries([queryKeyAccount])
    },
    onError: () => {
      errorPopup('Failed to update max overage.')
    },
    onSettled: () => {
      setLoading(false)
    },
  })
}

export function formatCardType(type: CardTypePb) {
  return (
    {
      [CardTypePb.UNSPECIFIED]: 'Unknown',
      [CardTypePb.VISA]: 'Visa',
      [CardTypePb.MASTERCARD]: 'Mastercard',
      [CardTypePb.AMERICAN_EXPRESS]: 'American Express',
      [CardTypePb.DISCOVER]: 'Discover',
      [CardTypePb.JCB]: 'Jcb',
      [CardTypePb.DINERS_CLUB]: 'Diners Club',
      [CardTypePb.BANCONTACT]: 'Bancontact',
      [CardTypePb.OTHER]: 'Other',
    }[type] ?? 'Unknown'
  )
}
