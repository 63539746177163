import { PlainMessage } from '@bufbuild/protobuf'
import { Link, useSearch } from '@tanstack/react-router'
import { createColumnHelper } from '@tanstack/react-table'
import React from 'react'
import { twMerge } from 'tailwind-merge'
import {
  metrics,
  relatedPhrasesMetricsList,
  urlMetricsList,
} from '../containers/topic-clusters/metrics'
import { useTable } from '../hooks/useTable'
import { getDataColor } from '../utils/DataColors'
import { formatNumber } from '../utils/Format'
import Anchor from './Anchor'
import Card from './Card'
import Explorer from './Explorer'
import Table, {
  TableCell,
  TableEl,
  TableRow,
  TableWrapInner,
  TableWrapOuter,
} from './Table'
import { BarButton, ButtonBar } from './ButtonBar'
import {
  ClassificationCategoryPb,
  ClusterUrlPb,
  EntityPb,
  Entity_TypePb,
  HeadingPb,
  RollupMetricsPb,
  TechnologyPb,
  TextStatsPb,
  UrlPb,
} from '../utils/proto'
import { useQuery } from 'react-query'
import { fetchProxyUrl } from '../utils/Api'
import { FaExternalLinkAlt } from 'react-icons/fa'
import { Tab, Tabs } from './Tabs'

type PanelId =
  | undefined
  | 'categories'
  | 'textStats'
  | 'entities'
  | 'headings'
  | 'technologies'
  | 'schema'
  | 'titles-descriptions'

const panels: { label: string; id: PanelId }[] = [
  { label: 'Info', id: undefined },
  { label: 'Entities', id: 'entities' },
  { label: 'Categories', id: 'categories' },
  { label: 'Titles & Descriptions', id: 'titles-descriptions' },
  { label: 'Headings', id: 'headings' },
  { label: 'Schema', id: 'schema' },
  { label: 'Text Stats', id: 'textStats' },
  { label: 'Technologies', id: 'technologies' },
]

export function ClusterURLComp(props: { clusterUrl: ClusterUrlPb }) {
  const { clusterUrl } = props
  const search = useSearch()
  const clusterUrlPanelId = search.clusterUrlPanelId as PanelId

  const url = clusterUrl.url!
  const page = clusterUrl.page

  return (
    <div className="space-y-4 pb-[400px]">
      <div className="grid items-stretch gap-2 font-bold sm:grid-cols-2 md:grid-cols-3">
        {urlMetricsList.map((metric, i) => {
          return (
            <Card
              key={metric.label}
              className={twMerge(
                'flex flex-1 items-center justify-between gap-2 p-1 px-2',
                'md:p-2',
                // 'lg:text-center lg:flex-col',
                '!border-y-0 !border-l-4 !border-r-0 dark:shadow dark:shadow-gray-800'
              )}
              style={{
                borderColor: getDataColor(i),
              }}
            >
              <div className="text-sm uppercase opacity-80">{metric.label}</div>
              <div className="text-lg md:text-xl lg:text-2xl">
                {metric.getRenderedValue(
                  clusterUrl.urlMetrics!.metrics![metric.id]!
                )}
              </div>
            </Card>
          )
        })}
      </div>
      <Tabs>
        {panels.map(panel => {
          return (
            <Link
              key={panel.id || 0}
              search={d => ({
                ...d,
                clusterUrlPanelId: panel.id,
              })}
            >
              <Tab
                className={twMerge(panel.id === clusterUrlPanelId && 'active')}
              >
                {panel.label}
              </Tab>
            </Link>
          )
        })}
      </Tabs>
      <div>
        {(() => {
          if (!clusterUrlPanelId) {
            return (
              <div className="space-y-4">
                <Card className="divide-y divide-gray-500/20 p-0">
                  <div className="p-2 text-lg font-bold">URL</div>
                  <InfoTable url={url} />
                  <IframeProxy
                    src={url.url}
                    title={url.url}
                    className="m-0 h-[60vh] w-full"
                  />
                </Card>
              </div>
            )
          } else if (clusterUrlPanelId === 'categories') {
            return (
              <Card className="divide-y divide-gray-500/20 p-0">
                <div className="p-2 text-lg font-bold">Categories</div>
                <CategoriesTable categories={page?.categories} />
              </Card>
            )
          } else if (clusterUrlPanelId === 'titles-descriptions') {
            return (
              <div className="space-y-4">
                <Card className="divide-y divide-gray-500/20 p-0">
                  <div className="p-2 text-lg font-bold">Titles</div>
                  <TitlesTable clusterUrl={clusterUrl} />
                </Card>
                <Card className="divide-y divide-gray-500/20 p-0">
                  <div className="p-2 text-lg font-bold">Descriptions</div>
                  <DescriptionsTable clusterUrl={clusterUrl} />
                </Card>
              </div>
            )
          } else if (clusterUrlPanelId === 'textStats') {
            return (
              <Card className="divide-y divide-gray-500/20 p-0">
                <div className="p-2 text-lg font-bold">Text Stats</div>
                <TextStatsTable textStats={page?.textStats} />
              </Card>
            )
          } else if (clusterUrlPanelId === 'entities') {
            return (
              <Card className="divide-y divide-gray-500/20 p-0">
                <div className="p-2 text-lg font-bold">Entities</div>
                <EntitiesTable entities={page?.entities} />
              </Card>
            )
          } else if (clusterUrlPanelId === 'headings') {
            return (
              <Card className="flex flex-col divide-y divide-gray-500/20 p-0">
                <div className="p-2 text-lg font-bold">Headings</div>
                <Headings headings={page?.headings} />
              </Card>
            )
          } else if (clusterUrlPanelId === 'technologies') {
            return (
              <Card className="divide-y divide-gray-500/20 p-0">
                <div className="p-2 text-lg font-bold">Technologies</div>
                <TechnologiesTable technologies={page?.technologies} />
              </Card>
            )
          } else if (clusterUrlPanelId === 'schema') {
            return (
              <Card className="divide-y divide-gray-500/20 p-0">
                <div className="p-2 text-lg font-bold">Schema</div>
                <Schema schema={page?.schema} />
              </Card>
            )
          } else {
            return (
              <Card>
                Invalid Panel! Please select a panel from the tabs above.
              </Card>
            )
          }
        })()}
      </div>
    </div>
  )
}

function InfoTable(props: { url: UrlPb }) {
  const { url } = props

  return (
    <TableWrapOuter>
      <TableWrapInner>
        <TableEl className="text-sm">
          <tbody>
            <TableRow>
              <TableCell className="p-2">URL</TableCell>
              <TableCell className="p-2">{url.url}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="p-2">Domain</TableCell>
              <TableCell className="p-2">{url.domain}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="p-2">Host</TableCell>
              <TableCell className="p-2">{url.host}</TableCell>
            </TableRow>
          </tbody>
        </TableEl>
      </TableWrapInner>
    </TableWrapOuter>
  )
}

export function CategoriesTable(props: {
  categories?: ClassificationCategoryPb[]
}) {
  return !props.categories?.length ? (
    <div className="p-2">No categories found.</div>
  ) : (
    <TableEl className="text-sm">
      <tbody>
        {props.categories?.map((category, i) => {
          return (
            <TableRow key={i}>
              <TableCell className="whitespace-normal p-2">
                {category.name}
              </TableCell>
              <TableCell className="p-2">
                {(() => {
                  const value = category.confidence

                  return (
                    <div className="flex items-center">
                      <div className="w-[50px]">
                        {metrics.categoryConfidence.renderValue(value)}
                      </div>
                      <div className="flex-1">
                        <div
                          className="rounded bg-gray-500 px-1 text-xs font-bold uppercase text-white"
                          style={{
                            backgroundColor: `hsl(${
                              60 + value * 100
                            }, 100%, 40%)`,
                            width: `${value * 100}%`,
                            height: '1em',
                          }}
                        />
                      </div>
                    </div>
                  )
                })()}
              </TableCell>
            </TableRow>
          )
        })}
      </tbody>
    </TableEl>
  )
}

const textStatTypes = [
  { id: 'wordCount', label: 'Word Count' },
  { id: 'letterCount', label: 'Letter Count' },
  { id: 'sentenceCount', label: 'Sentence Count' },
  { id: 'syllableCount', label: 'Syllable Count' },
  { id: 'difficultWordCount', label: 'Difficult Word Count' },
  { id: 'spaceCount', label: 'Space Count' },
  { id: 'punctuationCount', label: 'Punctuation Count' },
  { id: 'paragraphCount', label: 'Paragraph Count' },
  { id: 'headingCount', label: 'Heading Count' },
  { id: 'boldWordCount', label: 'Bold Word Count' },
  { id: 'italicizedWordCount', label: 'Italicized Word Count' },
  { id: 'imageCount', label: 'Image Count' },
  { id: 'internalLinkCount', label: 'Internal Link Count' },
  { id: 'externalLinkCount', label: 'External Link Count' },
  { id: 'totalLinkCount', label: 'Total Link Count' },
  { id: 'readingEase', label: 'Reading Ease' },
  { id: 'fleschKincaidReadingEase', label: 'Flesch Kincaid Reading Ease' },
  { id: 'fleschKincaidGradeLevel', label: 'Flesch Kincaid Grade Level' },
  { id: 'colemanLiauIndex', label: 'Coleman Liau Index' },
  { id: 'automatedReadabilityIndex', label: 'Automated Readability Index' },
  { id: 'smogIndex', label: 'Smog Index' },
  { id: 'gunningFogScore', label: 'Gunning Fog Score' },
  { id: 'daleChallReadabilityScore', label: 'Dale Chall Readability Score' },
]

export function TextStatsTable(props: { textStats?: TextStatsPb }) {
  return (
    <TableEl className="text-sm">
      <tbody>
        {textStatTypes.map((type, i) => {
          const value =
            props.textStats?.[type.id as keyof PlainMessage<TextStatsPb>]

          return (
            <TableRow key={i}>
              <TableCell className="whitespace-normal p-2">
                {type.label}
              </TableCell>
              <TableCell className="p-2">
                {value
                  ? formatNumber(value, {
                      precision: 2,
                    })
                  : null}
              </TableCell>
            </TableRow>
          )
        })}
      </tbody>
    </TableEl>
  )
}

const titlesHelper = createColumnHelper<RollupMetricsPb>()
export const titleColumns = [
  titlesHelper.accessor('label', {
    header: 'Title',
    meta: {
      tight: true,
    },
  }),
  ...relatedPhrasesMetricsList
    .filter(d => d.id !== 'keywordCount')
    .map(metric => {
      return titlesHelper.accessor(
        d => metric.getValue(d.metrics![metric.id]!),
        {
          header: metric.label,
          id: metric.id,
          cell: cellProps => metric.renderValue(cellProps.getValue()),
          meta: {
            getCellProps: () => {
              return {
                className: 'text-right',
              }
            },
            tight: true,
          },
        }
      )
    }),
]

function TitlesTable(props: { clusterUrl?: ClusterUrlPb }) {
  const data = React.useMemo(
    (): RollupMetricsPb[] => props.clusterUrl?.metricsByTitle || [],
    [props.clusterUrl?.metricsByTitle]
  )

  const table = useTable({
    data,
    columns: titleColumns,
    initialState: React.useMemo(
      () => ({
        pagination: {
          pageSize: 50,
        },
      }),
      []
    ),
  })

  return <Table table={table} />
}

const descriptionsHelper = createColumnHelper<RollupMetricsPb>()
export const descriptionColumns = [
  descriptionsHelper.accessor('label', {
    header: 'Description',
    cell: cellProps => (
      <div className="w-[400px] whitespace-normal text-xs">
        {cellProps.getValue()}
      </div>
    ),
    meta: {
      tight: true,
    },
  }),
  ...relatedPhrasesMetricsList
    .filter(d => d.id !== 'keywordCount')
    .map(metric => {
      return descriptionsHelper.accessor(
        d => metric.getValue(d.metrics![metric.id]!),
        {
          header: metric.label,
          id: metric.id,
          cell: cellProps => metric.renderValue(cellProps.getValue()),
          meta: {
            getCellProps: () => {
              return {
                className: 'text-right',
              }
            },
            tight: true,
          },
        }
      )
    }),
]

function DescriptionsTable(props: { clusterUrl?: ClusterUrlPb }) {
  const data = React.useMemo(
    (): RollupMetricsPb[] => props.clusterUrl?.metricsByDescription || [],
    [props.clusterUrl?.metricsByDescription]
  )

  const table = useTable({
    data,
    columns: descriptionColumns,
    initialState: React.useMemo(
      () => ({
        pagination: {
          pageSize: 50,
        },
      }),
      []
    ),
  })

  return <Table table={table} />
}

const entityHelper = createColumnHelper<EntityPb>()

export function EntitiesTable(props: { entities?: EntityPb[] }) {
  const columns = React.useMemo(() => {
    return [
      entityHelper.accessor('name', {
        header: 'Name',
        filterFn: 'fuzzy',
      }),
      entityHelper.accessor(d => Entity_TypePb[d.type], {
        header: 'Type',
        filterFn: 'arrIncludesSome',
      }),
      entityHelper.accessor('salience', {
        header: 'Salience',
        filterFn: 'inNumberRange',
        cell: cellProps => {
          return formatNumber(cellProps.getValue(), {
            precision: 3,
            forcePrecision: true,
          })
        },
        meta: {
          getCellProps: () => {
            return {
              className: 'text-right',
            }
          },
        },
      }),
      entityHelper.group({
        header: 'Sentiment',
        columns: [
          entityHelper.accessor('sentiment.score', {
            header: 'Score',
            filterFn: 'inNumberRange',
            cell: cellProps => {
              return formatNumber(cellProps.getValue(), {
                precision: 1,
                forcePrecision: true,
              })
            },
            meta: {
              getCellProps: () => {
                return {
                  className: 'text-right',
                }
              },
            },
          }),
          entityHelper.accessor('sentiment.magnitude', {
            header: 'Magnitude',
            filterFn: 'inNumberRange',
            cell: cellProps => {
              return formatNumber(cellProps.getValue(), {
                precision: 1,
                forcePrecision: true,
              })
            },
            meta: {
              getCellProps: () => {
                return {
                  className: 'text-right',
                }
              },
            },
          }),
        ],
      }),
    ]
  }, [])

  const entities = React.useMemo(() => {
    return props.entities || []
  }, [props.entities])

  const table = useTable({
    data: entities,
    columns,
    initialState: React.useMemo(() => {
      return {
        pagination: {
          pageSize: 20,
        },
        sorting: [
          {
            id: 'salience',
            desc: true,
          },
        ],
      }
    }, []),
  })

  return !entities?.length ? (
    <div className="p-2">No entities found.</div>
  ) : (
    <Table table={table} />
  )
}

export function Headings(props: { headings?: HeadingPb[] }) {
  const { headings } = props

  const headingScale = React.useMemo(() => {
    // A scale to convert heading levels to a red => orange => yellow => green => blue => purple color scale.
    return (level: number) => {
      return `hsl(${((level - 1) / 6) * 200}, 70%, 50%)`
    }
  }, [])

  return (
    <>
      {headings?.length ? (
        <div className="height-[300px] min-h-0 flex-[1_0_300px] divide-y divide-gray-50 overflow-y-auto overflow-x-hidden dark:divide-gray-800">
          {headings?.map((heading, i) => {
            return (
              <div
                className="flex items-center gap-2 p-1"
                style={{
                  paddingLeft: `${heading.level * 0.5}rem`,
                }}
              >
                <div
                  className="rounded px-1 text-xs font-black uppercase text-black"
                  style={{
                    backgroundColor: headingScale(heading.level),
                  }}
                >
                  H{heading.level}
                </div>
                <div
                  className={
                    {
                      '1': 'font-black',
                      '2': 'font-bold',
                      '3': 'font-semi-bold',
                      '4': 'text-sm font-medium',
                      '5': 'text-sm font-normal',
                      '6': 'text-sm font-normal',
                    }[heading.level]
                  }
                >
                  {heading.text}
                </div>
              </div>
            )
          })}
        </div>
      ) : (
        <div className="p-2">No headings found.</div>
      )}
    </>
  )
}

const technologyHelper = createColumnHelper<TechnologyPb>()

export function TechnologiesTable(props: { technologies?: TechnologyPb[] }) {
  const columns = React.useMemo(() => {
    return [
      technologyHelper.accessor('name', {
        header: 'Name',
        filterFn: 'fuzzy',
        cell: cellProps => {
          return (
            <Anchor href={cellProps.row.original.url} target="_blank">
              {cellProps.getValue()}
            </Anchor>
          )
        },
      }),
      technologyHelper.accessor(d => d.categories.map((d: any) => d.name), {
        header: 'Categories',
        filterFn: 'arrIncludesSome',
        getUniqueValues: d => d.categories.map((d: any) => d.name),
        cell: cellProps => {
          return cellProps.getValue().join(', ')
        },
      }),
      technologyHelper.accessor(d => d.categories[0]?.priority, {
        id: 'categoryPriority',
        header: 'Category Priority',
        filterFn: 'inNumberRange',
        invertSorting: true,
      }),
      technologyHelper.accessor('description', {
        header: 'Description',
        filterFn: 'fuzzy',
      }),
    ]
  }, [])

  const technologies = React.useMemo(
    () => props.technologies || [],
    [props.technologies]
  )

  const table = useTable({
    data: technologies,
    columns,
    initialState: React.useMemo(() => {
      return {
        pagination: {
          pageSize: 20,
        },
        sorting: [
          {
            id: 'categoryPriority',
            desc: true,
          },
        ],
      }
    }, []),
  })

  return !technologies.length ? (
    <div className="p-2">No technologies found.</div>
  ) : (
    <Table table={table} />
  )
}

export function Schema(props: { schema?: Uint8Array }) {
  const schema = React.useMemo(() => {
    try {
      return JSON.parse(new TextDecoder().decode(props.schema))
    } catch (err) {
      return undefined
    }
  }, [props.schema])

  const defaultExpanded = React.useMemo(() => ({}), [])

  return (
    <div className="p-2">
      {!schema ? (
        'No schema found.'
      ) : (
        <Explorer defaultExpanded={defaultExpanded} value={schema} />
      )}
    </div>
  )
}

export function IframeProxy({
  showOpenFallback,
  src,
  ...rest
}: React.HTMLProps<HTMLIFrameElement> & { showOpenFallback?: boolean }) {
  const query = useQuery({
    queryKey: ['nozzle-proxy', src],
    enabled: !!src,
    queryFn: () => fetchProxyUrl(src!),
    retry: false,
  })

  // Inject the base href into the html so it knows where it's being loaded from.
  const srcDoc = React.useMemo(() => {
    return query.data?.replace('<head>', `<head><base href="${src}"/>`)
  }, [query.data, src])

  const ref = React.useRef<HTMLIFrameElement>(null)

  return query.isError && query.error?.toString().includes('403') ? (
    <div className="w-full space-y-2 p-4 text-sm">
      <div className="max-w-full">
        This URL cannot be embedded due to their content security policy.
      </div>
      {showOpenFallback ?? true ? (
        <div>
          <Anchor
            href={src}
            target="_blank"
            className="inline-flex items-center gap-2 rounded-md bg-blue-500 px-2 py-1 text-xs uppercase !text-white"
          >
            <div>Open URL in new tab</div>
            <FaExternalLinkAlt className="inline" />
          </Anchor>
        </div>
      ) : null}
    </div>
  ) : (
    <div>
      {/* <button
        onClick={() => {
          ref.current?.contentWindow?.postMessage(
            {
              type: 'nozzle-iframe-exec',
              code: `window.history.back()`,
            },
            '*'
          )
        }}
      >
        Back
      </button> */}
      <iframe
        title="nozzle-iframe"
        {...rest}
        ref={ref}
        src={undefined}
        srcDoc={srcDoc}
        sandbox="allow-scripts"
      />
    </div>
  )
}
