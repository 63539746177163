import { useNavigate, useSearch } from '@tanstack/react-router'
import { createColumnHelper } from '@tanstack/react-table'
import * as React from 'react'
import { FaAngleRight, FaCaretDown } from 'react-icons/fa'
import Card from '../../../components/Card'
import ErrorBoundary from '../../../components/ErrorBoundary'
import { Head } from '../../../components/Head'
import { KeywordMetricsComp } from '../../../components/KeywordMetrics'
import Link from '../../../components/Link'
import Table from '../../../components/Table'
import { useLocalesByIdQuery } from '../../../utils/locales'
import { useTable } from '../../../hooks/useTable'
import { keywordColumns, renderKeyword, serpMetricsList } from '../metrics'
import { formatKeyword } from '../../../hooks/keywords'
import { ClusterPb, KeywordMetricsPb } from '../../../utils/proto'
import Clickable from '../../../components/Clickable'
import Select from '../../../components/Select'
import Loader from '../../../components/Loader'

const keywordsColumnHelper = createColumnHelper<KeywordMetricsPb>()
export function Keywords(props: { cluster: ClusterPb }) {
  const navigate = useNavigate()

  const localesByIdQuery = useLocalesByIdQuery({
    localeIds: props.cluster.metricsByKeyword.map(d =>
      Number(d.keyword?.localeId)
    ),
  })

  const search = useSearch()
  const keywordId = search.keywordId ? Number(search.keywordId) : undefined

  const keywordsColumns = React.useMemo(
    () => [
      keywordsColumnHelper.accessor('keyword.phrase', {
        header: 'Phrase',
        cell: cellProps => {
          return (
            <Link
              search={prev => {
                return {
                  ...prev,
                  keywordId: cellProps.row.original.keyword?.keywordId,
                }
              }}
            >
              {cellProps.getValue()}
            </Link>
          )
        },
        meta: {
          getHeaderProps: () => ({
            className: `sticky left-0 z-10 bg-white dark:bg-gray-900`,
          }),
          getCellProps: () => ({
            className: `sticky left-0 z-10 bg-white dark:bg-gray-900`,
          }),
        },
      }),
      keywordsColumnHelper.accessor(
        d => {
          return keywordColumns.device.getFormattedValue(d.keyword?.device)
        },
        {
          header: 'Device',
          cell: cellProps => {
            return keywordColumns.device.getRenderedValue(
              cellProps.row.original.keyword?.device
            )
          },
          meta: {
            getCellProps: () => ({
              className: 'text-center',
            }),
          },
        }
      ),
      keywordsColumnHelper.accessor(
        d => {
          return keywordColumns.locale.getFormattedValue(
            Number(d.keyword?.localeId),
            localesByIdQuery.data
          )
        },
        {
          header: 'Locale',
          cell: cellProps => {
            return keywordColumns.locale.getRenderedValue(
              Number(cellProps.row.original.keyword?.localeId),
              localesByIdQuery.data
            )
          },
        }
      ),
      ...serpMetricsList.map(metric => {
        return keywordsColumnHelper.accessor(
          d => metric.getValue(d.serpMetrics![metric.id]!),
          {
            header: metric.label,
            id: metric.id,
            cell: cellProps => metric.renderValue(cellProps.getValue()),
            meta: {
              getCellProps: () => {
                return {
                  className: 'text-right',
                }
              },
            },
          }
        )
      }),
    ],
    [localesByIdQuery.data]
  )

  const table = useTable({
    data: props.cluster.metricsByKeyword,
    columns: keywordsColumns,
    initialState: React.useMemo(
      () => ({
        pagination: {
          pageSize: 50,
        },
      }),
      []
    ),
  })

  const keywordOptions = React.useMemo(() => {
    return props.cluster.metricsByKeyword.map(d => {
      return {
        label: formatKeyword(d.keyword, localesByIdQuery.data),
        value: d.keyword?.keywordId,
      }
    })
  }, [localesByIdQuery.data, props.cluster.metricsByKeyword])

  if (keywordId) {
    const keywordMetrics = props.cluster.metricsByKeyword.find(
      d => Number(d.keyword?.keywordId) === keywordId
    )

    return (
      <div className="space-y-4">
        <Head>
          <title>
            {`Keyword Clustering - ${
              props.cluster.name
            } - Keyword: ${formatKeyword(
              keywordMetrics?.keyword,
              localesByIdQuery.data
            )} - `}
          </title>
        </Head>
        <Card className="flex flex-wrap items-center gap-1 text-lg font-bold">
          <Link search={d => ({ ...d, keywordId: undefined })}>Keywords</Link>
          <FaAngleRight />
          <Select
            options={keywordOptions}
            onChange={value => {
              navigate({
                search: d => ({
                  ...d,
                  keywordId: value,
                }),
              })
            }}
            children={({ onClick }) => {
              return (
                <Clickable
                  onClick={onClick}
                  className="flex items-center gap-1"
                >
                  {
                    (keywordMetrics?.keyword,
                    localesByIdQuery.data ? (
                      <span className="text-base">
                        {renderKeyword(
                          keywordMetrics?.keyword,
                          localesByIdQuery.data
                        )}
                      </span>
                    ) : (
                      <Loader />
                    ))
                  }
                  <FaCaretDown />
                </Clickable>
              )
            }}
          />
        </Card>
        <ErrorBoundary>
          {!keywordMetrics ? (
            <div className="text-center">Keyword not found!</div>
          ) : (
            <KeywordMetricsComp keywordMetrics={keywordMetrics!} />
          )}
        </ErrorBoundary>
      </div>
    )
  }

  return (
    <Card className="divide-y divide-gray-500/20 p-0">
      <div className="p-2 text-lg font-bold">Keywords</div>
      <div className="divide-x divide-gray-100 dark:divide-gray-850">
        <div className="col-span-2 min-w-0 flex-1">
          <Table table={table} />
        </div>
      </div>
    </Card>
  )
}
