import React from 'react'
import {
  appearanceLabels,
  deviceLabels,
} from '../containers/Dashboards/GoogleSearchConsole'
import { useGooglePhrasesQuery } from '../hooks/useGooglePhrases'
import { useTable } from '../hooks/useTable'
import { TimeRange } from '../hooks/useTimeRanges'
import { formatNumber, formatPercentage } from '../utils/Format'
import QueryGate from './QueryGate'
import Table from './Table'
import TablePlaceholder from './TablePlaceholder'
import Tooltip from './Tooltip'

export function GoogleSearchConsoleTable({
  property,
  timeRanges,
  device,
  country,
  appearance,
  groupBy,
  groupByOptions,
  url,
}: {
  property: string
  timeRanges: TimeRange
  device: string
  country: string
  appearance: string
  groupBy: string
  groupByOptions: { label: string; value: string }[]
  url: string
}) {
  const phrasesQuery = useGooglePhrasesQuery({
    property,
    timeRanges,
    device,
    country,
    appearance,
    url,
    groupBy,
  })

  const data = React.useMemo(
    () =>
      phrasesQuery.data?.map((row: any) => {
        return {
          key: row.keys[0],
          clicks: Number(row.clicks),
          ctr: Number(row.ctr),
          impressions: Number(row.impressions),
          position: Number(row.position),
        }
      }) || [],
    [phrasesQuery.data]
  )

  const columns = React.useMemo(
    () => [
      {
        header: groupByOptions.find(o => o.value === groupBy).label,
        accessorKey: 'key',
        filterFn: 'fuzzy',
        cell: (props: any) => {
          const value =
            groupBy === 'DEVICE'
              ? deviceLabels.find(option => option.value === props.getValue())
                  ?.label
              : groupBy === 'SEARCH_APPEARANCE'
              ? appearanceLabels.find(
                  option => option.value === props.getValue()
                )?.label
              : props.getValue()
          return (
            <div className="w-80 truncate">
              <Tooltip tooltip={props.getValue()}>{value}</Tooltip>
            </div>
          )
        },
      },
      {
        header: 'Clicks',
        accessorKey: 'clicks',
        filterFn: 'inNumberRange',
        cell: (props: any) => (
          <div className="text-right">{formatNumber(props.getValue())}</div>
        ),
        sortDescFirst: true,
        meta: { tight: true },
      },
      {
        header: 'Impressions',
        accessorKey: 'impressions',
        filterFn: 'inNumberRange',
        cell: (props: any) => (
          <div className="text-right">{formatNumber(props.getValue())}</div>
        ),
        sortDescFirst: true,
        meta: { tight: true },
      },
      {
        header: 'CTR',
        filterFn: 'inNumberRange',
        accessorFn: (row: any) => row.ctr * 100,
        cell: (props: any) => (
          <div className="text-right">
            {formatPercentage(props.getValue() / 100)}
          </div>
        ),
        sortDescFirst: true,
        meta: { tight: true },
      },

      {
        header: 'Rank (Position)',
        accessorKey: 'position',
        filterFn: 'inNumberRange',
        cell: (props: any) => (
          <div className="text-right">
            {formatNumber(props.getValue(), { precision: 1 })}
          </div>
        ),
        meta: { tight: true },
        sortDescFirst: true,
        invertSorting: true,
      },
    ],
    [groupBy, groupByOptions]
  )

  const table = useTable({
    data,
    // @ts-expect-error  // Type '({ header: string; accessorKey: string; filt... Remove this comment to see the full error message
    columns,
    initialState: {
      sorting: [{ id: 'clicks', desc: true }],
    },
  })

  return !phrasesQuery.isLoading ? (
    <QueryGate query={phrasesQuery}>
      {() => (
        <Table
          table={table}
          // @ts-expect-error  // Type 'false | "opacity-30"' is not assignable to t... Remove this comment to see the full error message
          className={phrasesQuery.isFetching && 'opacity-30'}
        />
      )}
    </QueryGate>
  ) : (
    <TablePlaceholder
      style={{
        borderWidth: 0,
        borderTopWidth: 1,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        rows: 10,
      }}
    />
  )
}
