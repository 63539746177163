// @generated by protoc-gen-connect-web v0.11.0 with parameter "target=ts"
// @generated from file nozzle/workspaces/v1/monitored_url.proto (package nozzle.workspaces.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AutoParentMonitoredUrlsRequest, AutoParentMonitoredUrlsResponse, BatchDeleteMonitoredUrlsRequest, BatchDeleteMonitoredUrlsResponse, BatchGetMonitoredUrlsRequest, BatchGetMonitoredUrlsResponse, BatchUpdateMonitoredUrlsRequest, BatchUpdateMonitoredUrlsResponse, DeleteMonitoredUrlRequest, DeleteMonitoredUrlResponse, GetMonitoredUrlRequest, GetMonitoredUrlResponse, ListMonitoredUrlsFacetsRequest, ListMonitoredUrlsFacetsResponse, ListMonitoredUrlsRequest, ListMonitoredUrlsResponse, UpdateMonitoredUrlRequest, UpdateMonitoredUrlResponse, UpdateMonitoredUrlsFromSitemapRequest, UpdateMonitoredUrlsFromSitemapResponse } from "./monitored_url_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * MonitoredUrlsService is a service for managing url mappings and crawling frequencies.
 *
 * @generated from service nozzle.workspaces.v1.MonitoredUrlsService
 */
export const MonitoredUrlsService = {
  typeName: "nozzle.workspaces.v1.MonitoredUrlsService",
  methods: {
    /**
     * UpdateMonitoredUrl will create/update a url.
     *
     * @generated from rpc nozzle.workspaces.v1.MonitoredUrlsService.UpdateMonitoredUrl
     */
    updateMonitoredUrl: {
      name: "UpdateMonitoredUrl",
      I: UpdateMonitoredUrlRequest,
      O: UpdateMonitoredUrlResponse,
      kind: MethodKind.Unary,
    },
    /**
     * BatchUpdateMonitoredUrls will create/update a url.
     *
     * @generated from rpc nozzle.workspaces.v1.MonitoredUrlsService.BatchUpdateMonitoredUrls
     */
    batchUpdateMonitoredUrls: {
      name: "BatchUpdateMonitoredUrls",
      I: BatchUpdateMonitoredUrlsRequest,
      O: BatchUpdateMonitoredUrlsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * UpdateMonitoredUrlsFromSitemap will traverse a sitemap, through any nested sitemaps, and create/update urls.
     *
     * @generated from rpc nozzle.workspaces.v1.MonitoredUrlsService.UpdateMonitoredUrlsFromSitemap
     */
    updateMonitoredUrlsFromSitemap: {
      name: "UpdateMonitoredUrlsFromSitemap",
      I: UpdateMonitoredUrlsFromSitemapRequest,
      O: UpdateMonitoredUrlsFromSitemapResponse,
      kind: MethodKind.Unary,
    },
    /**
     * AutoParentMonitoredUrls will attempt to auto-parent monitored urls based on the url path.
     *
     * @generated from rpc nozzle.workspaces.v1.MonitoredUrlsService.AutoParentMonitoredUrls
     */
    autoParentMonitoredUrls: {
      name: "AutoParentMonitoredUrls",
      I: AutoParentMonitoredUrlsRequest,
      O: AutoParentMonitoredUrlsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetMonitoredUrl will get a url.
     *
     * @generated from rpc nozzle.workspaces.v1.MonitoredUrlsService.GetMonitoredUrl
     */
    getMonitoredUrl: {
      name: "GetMonitoredUrl",
      I: GetMonitoredUrlRequest,
      O: GetMonitoredUrlResponse,
      kind: MethodKind.Unary,
    },
    /**
     * BatchGetMonitoredUrls will get a url.
     *
     * @generated from rpc nozzle.workspaces.v1.MonitoredUrlsService.BatchGetMonitoredUrls
     */
    batchGetMonitoredUrls: {
      name: "BatchGetMonitoredUrls",
      I: BatchGetMonitoredUrlsRequest,
      O: BatchGetMonitoredUrlsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ListMonitoredUrls will list urls.
     *
     * @generated from rpc nozzle.workspaces.v1.MonitoredUrlsService.ListMonitoredUrls
     */
    listMonitoredUrls: {
      name: "ListMonitoredUrls",
      I: ListMonitoredUrlsRequest,
      O: ListMonitoredUrlsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ListMonitoredUrlsFacets will return facets for the list of urls.
     *
     * @generated from rpc nozzle.workspaces.v1.MonitoredUrlsService.ListMonitoredUrlsFacets
     */
    listMonitoredUrlsFacets: {
      name: "ListMonitoredUrlsFacets",
      I: ListMonitoredUrlsFacetsRequest,
      O: ListMonitoredUrlsFacetsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * DeleteMonitoredUrl will delete a url.
     *
     * @generated from rpc nozzle.workspaces.v1.MonitoredUrlsService.DeleteMonitoredUrl
     */
    deleteMonitoredUrl: {
      name: "DeleteMonitoredUrl",
      I: DeleteMonitoredUrlRequest,
      O: DeleteMonitoredUrlResponse,
      kind: MethodKind.Unary,
    },
    /**
     * BatchDeleteMonitoredUrls will batch delete urls.
     *
     * @generated from rpc nozzle.workspaces.v1.MonitoredUrlsService.BatchDeleteMonitoredUrls
     */
    batchDeleteMonitoredUrls: {
      name: "BatchDeleteMonitoredUrls",
      I: BatchDeleteMonitoredUrlsRequest,
      O: BatchDeleteMonitoredUrlsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

