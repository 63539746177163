// @generated by protoc-gen-es v1.3.0 with parameter "target=ts"
// @generated from file nozzle/billing/v1/payment.proto (package nozzle.billing.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { PostalAddress } from "../../../google/type/postal_address_pb.js";

/**
 * PaymentMethodState represents the state of a payment method.
 *
 * @generated from enum nozzle.billing.v1.PaymentMethodState
 */
export enum PaymentMethodState {
  /**
   * @generated from enum value: PAYMENT_METHOD_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * A payment source that is valid and active.
   *
   * @generated from enum value: PAYMENT_METHOD_STATE_VALID = 1;
   */
  VALID = 1,

  /**
   * A payment source that is expiring (like card's status based on its expiry date).
   *
   * @generated from enum value: PAYMENT_METHOD_STATE_EXPIRING = 2;
   */
  EXPIRING = 2,

  /**
   * A payment source that has expired.
   *
   * @generated from enum value: PAYMENT_METHOD_STATE_EXPIRED = 3;
   */
  EXPIRED = 3,

  /**
   * The billing agreement cannot be used. It might become valid again either automatically
   * or due to customer action.
   *
   * @generated from enum value: PAYMENT_METHOD_STATE_INVALID = 4;
   */
  INVALID = 4,

  /**
   * The payment source needs to be verified.
   *
   * @generated from enum value: PAYMENT_METHOD_STATE_PENDING_VERIFICATION = 5;
   */
  PENDING_VERIFICATION = 5,

  /**
   * @generated from enum value: PAYMENT_METHOD_STATE_NO_CARD = 6;
   */
  NO_CARD = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(PaymentMethodState)
proto3.util.setEnumType(PaymentMethodState, "nozzle.billing.v1.PaymentMethodState", [
  { no: 0, name: "PAYMENT_METHOD_STATE_UNSPECIFIED" },
  { no: 1, name: "PAYMENT_METHOD_STATE_VALID" },
  { no: 2, name: "PAYMENT_METHOD_STATE_EXPIRING" },
  { no: 3, name: "PAYMENT_METHOD_STATE_EXPIRED" },
  { no: 4, name: "PAYMENT_METHOD_STATE_INVALID" },
  { no: 5, name: "PAYMENT_METHOD_STATE_PENDING_VERIFICATION" },
  { no: 6, name: "PAYMENT_METHOD_STATE_NO_CARD" },
]);

/**
 * CardType represents the brand type of a credit card.
 *
 * @generated from enum nozzle.billing.v1.CardType
 */
export enum CardType {
  /**
   * @generated from enum value: CARD_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: CARD_TYPE_VISA = 1;
   */
  VISA = 1,

  /**
   * @generated from enum value: CARD_TYPE_MASTERCARD = 2;
   */
  MASTERCARD = 2,

  /**
   * @generated from enum value: CARD_TYPE_AMERICAN_EXPRESS = 3;
   */
  AMERICAN_EXPRESS = 3,

  /**
   * @generated from enum value: CARD_TYPE_DISCOVER = 4;
   */
  DISCOVER = 4,

  /**
   * @generated from enum value: CARD_TYPE_JCB = 5;
   */
  JCB = 5,

  /**
   * @generated from enum value: CARD_TYPE_DINERS_CLUB = 6;
   */
  DINERS_CLUB = 6,

  /**
   * @generated from enum value: CARD_TYPE_BANCONTACT = 7;
   */
  BANCONTACT = 7,

  /**
   * @generated from enum value: CARD_TYPE_OTHER = 15;
   */
  OTHER = 15,
}
// Retrieve enum metadata with: proto3.getEnumType(CardType)
proto3.util.setEnumType(CardType, "nozzle.billing.v1.CardType", [
  { no: 0, name: "CARD_TYPE_UNSPECIFIED" },
  { no: 1, name: "CARD_TYPE_VISA" },
  { no: 2, name: "CARD_TYPE_MASTERCARD" },
  { no: 3, name: "CARD_TYPE_AMERICAN_EXPRESS" },
  { no: 4, name: "CARD_TYPE_DISCOVER" },
  { no: 5, name: "CARD_TYPE_JCB" },
  { no: 6, name: "CARD_TYPE_DINERS_CLUB" },
  { no: 7, name: "CARD_TYPE_BANCONTACT" },
  { no: 15, name: "CARD_TYPE_OTHER" },
]);

/**
 * AddCardByTokenRequest is the request for AddCardByToken.
 *
 * @generated from message nozzle.billing.v1.AddCardByTokenRequest
 */
export class AddCardByTokenRequest extends Message<AddCardByTokenRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: string token = 2;
   */
  token = "";

  constructor(data?: PartialMessage<AddCardByTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.AddCardByTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddCardByTokenRequest {
    return new AddCardByTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddCardByTokenRequest {
    return new AddCardByTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddCardByTokenRequest {
    return new AddCardByTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddCardByTokenRequest | PlainMessage<AddCardByTokenRequest> | undefined, b: AddCardByTokenRequest | PlainMessage<AddCardByTokenRequest> | undefined): boolean {
    return proto3.util.equals(AddCardByTokenRequest, a, b);
  }
}

/**
 * AddCardByTokenResponse is the response for AddCardByToken.
 *
 * @generated from message nozzle.billing.v1.AddCardByTokenResponse
 */
export class AddCardByTokenResponse extends Message<AddCardByTokenResponse> {
  /**
   * @generated from field: nozzle.billing.v1.PaymentMethod payment_method = 1;
   */
  paymentMethod?: PaymentMethod;

  constructor(data?: PartialMessage<AddCardByTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.AddCardByTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "payment_method", kind: "message", T: PaymentMethod },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddCardByTokenResponse {
    return new AddCardByTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddCardByTokenResponse {
    return new AddCardByTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddCardByTokenResponse {
    return new AddCardByTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AddCardByTokenResponse | PlainMessage<AddCardByTokenResponse> | undefined, b: AddCardByTokenResponse | PlainMessage<AddCardByTokenResponse> | undefined): boolean {
    return proto3.util.equals(AddCardByTokenResponse, a, b);
  }
}

/**
 * PaymentMethod represents the payment method for a subscription.
 *
 * @generated from message nozzle.billing.v1.PaymentMethod
 */
export class PaymentMethod extends Message<PaymentMethod> {
  /**
   * @generated from field: string payment_method_id = 1;
   */
  paymentMethodId = "";

  /**
   * @generated from oneof nozzle.billing.v1.PaymentMethod.details
   */
  details: {
    /**
     * @generated from field: nozzle.billing.v1.Card card = 2;
     */
    value: Card;
    case: "card";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: nozzle.billing.v1.PaymentMethodState state = 3;
   */
  state = PaymentMethodState.UNSPECIFIED;

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 21;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 22;
   */
  updatedAt?: Timestamp;

  constructor(data?: PartialMessage<PaymentMethod>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.PaymentMethod";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "payment_method_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "card", kind: "message", T: Card, oneof: "details" },
    { no: 3, name: "state", kind: "enum", T: proto3.getEnumType(PaymentMethodState) },
    { no: 21, name: "created_at", kind: "message", T: Timestamp },
    { no: 22, name: "updated_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaymentMethod {
    return new PaymentMethod().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaymentMethod {
    return new PaymentMethod().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaymentMethod {
    return new PaymentMethod().fromJsonString(jsonString, options);
  }

  static equals(a: PaymentMethod | PlainMessage<PaymentMethod> | undefined, b: PaymentMethod | PlainMessage<PaymentMethod> | undefined): boolean {
    return proto3.util.equals(PaymentMethod, a, b);
  }
}

/**
 * Card represents a credit card.
 *
 * @generated from message nozzle.billing.v1.Card
 */
export class Card extends Message<Card> {
  /**
   * @generated from field: string given_name = 1;
   */
  givenName = "";

  /**
   * @generated from field: string family_name = 2;
   */
  familyName = "";

  /**
   * @generated from field: nozzle.billing.v1.CardType type = 3;
   */
  type = CardType.UNSPECIFIED;

  /**
   * has to be a string because it can start with 0
   *
   * @generated from field: string last4 = 4;
   */
  last4 = "";

  /**
   * @generated from field: int32 exp_month = 5;
   */
  expMonth = 0;

  /**
   * @generated from field: int32 exp_year = 6;
   */
  expYear = 0;

  /**
   * @generated from field: google.type.PostalAddress address = 7;
   */
  address?: PostalAddress;

  constructor(data?: PartialMessage<Card>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.Card";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "given_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "family_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "type", kind: "enum", T: proto3.getEnumType(CardType) },
    { no: 4, name: "last4", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "exp_month", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "exp_year", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "address", kind: "message", T: PostalAddress },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Card {
    return new Card().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Card {
    return new Card().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Card {
    return new Card().fromJsonString(jsonString, options);
  }

  static equals(a: Card | PlainMessage<Card> | undefined, b: Card | PlainMessage<Card> | undefined): boolean {
    return proto3.util.equals(Card, a, b);
  }
}

