import React from 'react'
import { PricingCards } from '../components/PricingCards'
import { useThemeMode } from '../utils/Theme'

export function PricingCardsEmbed() {
  const { setThemeMode } = useThemeMode()

  React.useLayoutEffect(() => {
    setThemeMode('light')
  }, [setThemeMode])

  const ref = React.useRef<HTMLDivElement>(null!)

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (window.parent) {
        window.parent.postMessage(
          JSON.stringify({
            type: 'nz-size',
            size: ref.current?.scrollHeight + 40,
          }),
          '*'
        )
      }
    }, 250)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
        html, body {
          background: transparent;
        }
      `,
        }}
      />
      <div className="overflow-auto p-4">
        <PricingCards ref={ref} />
      </div>
    </>
  )
}
