import { css } from '@emotion/css'
import { matchSorter, rankings } from 'match-sorter'
import * as React from 'react'
import {
  FaArrowUp,
  FaCaretDown,
  FaGlasses,
  FaPlus,
  FaSearch,
  FaTimes,
} from 'react-icons/fa'
import { useQuery } from 'react-query'
import { twMerge } from 'tailwind-merge'
import useGetPrevious from '../hooks/useGetPrevious'
import useRect from '../hooks/useRect'
import { useActiveWorkspaceId } from '../hooks/workspaces'
import { Updater, getRankGroupFromRank, last, uniqBy } from '../utils'
import { fetchSerps, fetchSerpsHtml } from '../utils/Api'
import Color from '../utils/Color'
import {
  queryKeyReportsKeywordSerp,
  queryKeyReportsKeywordSerpHtml,
} from '../utils/Constants'
import { formatLimitedText, formatNumber } from '../utils/Format'
import { useResultColumnsQuery } from '../utils/Results'
import { useThemeMode } from '../utils/Theme'
import { twConfig } from '../utils/tailwind'
import Button from './Button'
import ErrorComp from './Error'
import InlineLabeledInput from './InlineLabeledInput'
import Input from './Input'
import Loader from './Loader'
import Select from './Select'
import {
  filterResultByTerm,
  resultColumnGroupOptions,
} from './SerpResultsTables'
import { TableCell, TableEl, TableRow } from './Table'
import { SelectOption } from '../hooks/useSelect'

const minimapScale = 0.04

const zoomOptions = [
  { label: '100%', value: 100 },
  { label: '75%', value: 75 },
  { label: '50%', value: 50 },
  { label: '25%', value: 25 },
]

export function SerpHtml({
  rankingOptions,
  rankingIds,
  zoom,
  isNozzleVisionEnabled,
  resultColumnIds,
  resultSearchTerm,
  setIsNozzleVisionEnabled,
  setResultSearchTerm,
  setRankingIds,
  setZoom,
  setResultColumnGroup,
  setResultColumnIds,
  resultColumnGroup,
}: {
  isNozzleVisionEnabled?: boolean
  rankingIds?: (string | null)[]
  rankingOptions: undefined | SelectOption[]
  resultColumnGroup?: string
  resultColumnIds?: string[]
  resultSearchTerm?: string
  setIsNozzleVisionEnabled: (isNozzleVisionEnabled: Updater<boolean>) => void
  setRankingIds: (rankingIds: Updater<(string | null)[]>) => void
  setResultColumnGroup: (resultColumnGroup: Updater<string>) => void
  setResultColumnIds: (resultColumnGroup: Updater<string[]>) => void
  setResultSearchTerm: (resultSearchTerm: Updater<string>) => void
  setZoom: (zoom: Updater<number>) => void
  zoom?: number
}) {
  isNozzleVisionEnabled = isNozzleVisionEnabled ?? true

  resultColumnIds = resultColumnIds ?? resultColumnGroupOptions[0]!.columns

  const serpHtmlScrollsRef = React.useRef<HTMLDivElement[]>([])
  const serpActiveElRef = React.useRef<null | HTMLDivElement>(null)
  const [el, setEl] = React.useState<HTMLDivElement>(null!)
  const parentRect = useRect(el)
  const serpWidthRef = React.useRef(0)
  const resultColumnsQuery = useResultColumnsQuery()

  zoom = zoom ?? 100

  const [resultSearchTermDraft, setResultSearchTermDraft] =
    React.useState(resultSearchTerm)

  React.useEffect(() => {
    setResultSearchTermDraft(resultSearchTerm)
  }, [resultSearchTerm])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setResultSearchTerm(resultSearchTermDraft || '')
    }, 500)

    return () => clearTimeout(timeout)
  }, [resultSearchTermDraft])

  const addComparison = () => {
    if (!rankingOptions) return

    setRankingIds(prev => [
      rankingOptions.find(d => !prev?.includes(d.value))?.value ?? null,
      ...(prev ?? []),
    ])
  }

  React.useEffect(() => {
    serpHtmlScrollsRef.current.forEach(pane => (pane.scrollLeft = 0))
  }, [serpHtmlScrollsRef.current.length])

  const visibleRankingOptions = React.useMemo(
    () => rankingOptions?.filter(d => !rankingIds?.includes(d.value)) ?? [],
    [rankingOptions, rankingIds]
  )

  React.useEffect(() => {
    if (
      !rankingIds?.length &&
      rankingOptions?.length &&
      rankingOptions?.[0]?.value
    ) {
      setRankingIds([rankingOptions[0].value])
    }
  }, [rankingOptions, rankingIds?.length, setRankingIds])

  React.useEffect(() => {
    if (
      rankingIds?.length &&
      rankingOptions?.[0] &&
      rankingIds.some((id: any) => !rankingOptions.find(d => d.value === id))
    ) {
      setRankingIds([rankingOptions[0].value])
    }
  })

  const rankingIdsLength = rankingIds?.length

  const compareHistoryButton = (
    <Button
      size="sm"
      color="green-500"
      onClick={addComparison}
      disabled={!visibleRankingOptions?.length}
    >
      <FaPlus className="inline" /> Compare History
    </Button>
  )

  const fitToScreen = React.useCallback(async () => {
    setZoom(
      Math.min(
        100,
        Math.round(
          ((parentRect?.width ?? 1) /
            (serpWidthRef.current * (rankingIds?.length ?? 1))) *
            100
        )
      )
    )
  }, [parentRect?.width, rankingIds?.length])

  React.useEffect(() => {
    if (rankingIdsLength) {
    }
    fitToScreen()
  }, [rankingIdsLength, fitToScreen])

  const viewButton = (
    <Select
      options={resultColumnGroupOptions}
      value={resultColumnGroup}
      onChange={setResultColumnGroup}
    >
      {({ onClick, selectedOption }: any) => (
        <Button size="sm" color={['gray-100', 'gray-800']} onClick={onClick}>
          <span>
            View: <strong>{selectedOption.label}</strong>
          </span>
          <FaCaretDown className="inline" />
        </Button>
      )}
    </Select>
  )
  const addColumnsButton = (
    <Select
      multi
      options={resultColumnsQuery.data}
      value={resultColumnIds}
      onChange={(vals: any) => {
        setResultColumnGroup('custom')
        setResultColumnIds(vals)
      }}
    >
      {({ onClick, selectedOption }: any) => (
        <Button size="sm" color={['gray-100', 'gray-800']} onClick={onClick}>
          {resultColumnGroup === 'custom' ? (
            !selectedOption?.length ? (
              <>
                Select Columns... <FaPlus className="inline" />
              </>
            ) : (
              <>
                {formatLimitedText(
                  selectedOption.map((d: any) => d.label).join(', '),
                  40
                )}
                <FaCaretDown className="inline" />
              </>
            )
          ) : (
            <span className="flex items-center gap-2">
              <FaPlus className="inline" />
              <div>Add Columns</div>
            </span>
          )}
        </Button>
      )}
    </Select>
  )

  const columnsButtons = (
    <div className="flex gap-1">
      {viewButton}
      {addColumnsButton}
    </div>
  )

  const searchInput = (
    <InlineLabeledInput
      label={<FaSearch className="inline" />}
      placeholder="e.g. PAA, My brand, etc"
      value={resultSearchTermDraft}
      onChange={e =>
        setResultSearchTermDraft((e.target as HTMLInputElement).value)
      }
      className="w-56"
    />
  )

  const rankingSelectorElement = rankingOptions?.length ? (
    <div className="flex divide-x-2 divide-gray-500/20">
      {rankingIds?.map((rankingId, index) => {
        return (
          <div
            key={rankingId || index}
            className="flex flex-1 items-center justify-between gap-2 p-2"
          >
            <Select
              options={rankingOptions}
              create
              // @ts-expect-error  // Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
              value={rankingId}
              onChange={(val: any) =>
                setRankingIds(prev =>
                  prev.map((d, i) => (i === index ? val : d))
                )
              }
            >
              {({ onClick, selectedOption }: any) => (
                <Button size="sm" color="gray-600" onClick={onClick}>
                  {selectedOption?.label
                    ? selectedOption.label
                    : 'Select a requested date/time...'}{' '}
                  <FaCaretDown className="inline" />
                </Button>
              )}
            </Select>

            {rankingIds?.length > 1 ? (
              <Button
                size="sm"
                color={['gray-100', 'gray-800']}
                hoverColor="red-600"
                onClick={() =>
                  setRankingIds(prev => prev.filter((d, i) => i !== index))
                }
              >
                <FaTimes className="inline" />
              </Button>
            ) : null}
          </div>
        )
      }) ?? null}
    </div>
  ) : null

  return (
    <div className="divide-y divide-gray-500/20">
      <div
        className="
          flex flex-wrap
          items-center gap-2
          rounded-b-sm 
          rounded-t-lg bg-white p-2 dark:bg-gray-900
        "
      >
        {compareHistoryButton}
        <div className="flex flex-auto flex-wrap items-center justify-between gap-2">
          <div className="flex flex-auto flex-wrap items-center gap-2">
            <Button
              size="sm"
              color={['gray-100', 'gray-800']}
              hoverColor={isNozzleVisionEnabled ? 'red-600' : 'green-500'}
              onClick={() => setIsNozzleVisionEnabled(prev => !prev)}
            >
              <FaGlasses className="inline" />{' '}
              {isNozzleVisionEnabled ? 'Disable' : 'Enable'} Nozzle Vision
            </Button>
            {isNozzleVisionEnabled ? (
              <>
                {columnsButtons}
                {searchInput}
              </>
            ) : null}
          </div>
          <div className="flex flex-wrap items-center gap-2">
            <span className="text-sm font-bold">Zoom</span>
            <Input
              type="range"
              min="5"
              max="100"
              step="1"
              value={zoom}
              // @ts-expect-error  // Property 'value' does not exist on type 'EventTarg... Remove this comment to see the full error message
              onChange={e => setZoom(Number(e.target.value))}
              className="w-36 flex-none"
            />
            <Select
              options={zoomOptions}
              // @ts-expect-error  // Type 'string | number' is not assignable to type '... Remove this comment to see the full error message
              value={
                zoomOptions.find(d => d.value === zoom) ? zoom : `${zoom}%`
              }
              onChange={setZoom}
              className="w-20"
            />
            <Button size="sm" color="blue-500" onClick={fitToScreen}>
              Fit to Screen
            </Button>
          </div>
        </div>
      </div>
      {rankingSelectorElement}
      <div
        // @ts-expect-error  // Type 'Dispatch<SetStateAction<HTMLDivElement>>' is... Remove this comment to see the full error message
        ref={setEl}
        className="flex divide-x-2 divide-gray-500/20"
      >
        {rankingIds?.filter(Boolean)?.map((rankingId, index) => {
          return (
            <SerpHtmlSingle
              key={rankingId}
              rankingIds={rankingIds}
              rankingId={rankingId}
              rankingOptions={rankingOptions}
              zoom={zoom}
              serpHtmlScrollsRef={serpHtmlScrollsRef}
              serpActiveElRef={serpActiveElRef}
              serpWidthRef={serpWidthRef}
              isNozzleVisionEnabled={isNozzleVisionEnabled}
              setIsNozzleVisionEnabled={setIsNozzleVisionEnabled}
              resultColumnIds={resultColumnIds}
              resultSearchTerm={resultSearchTerm}
            />
          )
        }) ?? null}
      </div>
    </div>
  )
}

export function SerpHtmlSingle({
  rankingId,
  serpHtmlScrollsRef,
  serpActiveElRef,
  serpWidthRef,
  zoom,
  isNozzleVisionEnabled,
  resultColumnIds,
  resultSearchTerm,
  setIsNozzleVisionEnabled,
}: {
  isNozzleVisionEnabled?: boolean
  rankingId: null | string
  resultColumnIds?: string[]
  resultSearchTerm?: string
  setIsNozzleVisionEnabled: (isNozzleVisionEnabled: Updater<boolean>) => void
  zoom?: number
  serpWidthRef: React.MutableRefObject<number>
  serpHtmlScrollsRef: React.MutableRefObject<HTMLElement[]>
  serpActiveElRef: React.MutableRefObject<HTMLElement | null>
}) {
  zoom = zoom ?? 100
  const activeWorkspaceId = useActiveWorkspaceId()

  const rankingParams = rankingId && {
    workspaceId: activeWorkspaceId,
    rankingId,
  }

  const keywordSerpQuery = useQuery(
    [queryKeyReportsKeywordSerp, rankingParams],
    () => fetchSerps(rankingParams),
    { staleTime: Infinity, enabled: !!rankingParams }
  )

  const domWidth =
    keywordSerpQuery.data?.ranking.search.measurements.dom_width ?? 0
  const domHeight =
    keywordSerpQuery.data?.ranking.search.measurements.dom_height ?? 0

  const zoomedHeight = domHeight * (zoom / 100)

  // const isMobile = domWidth < 800

  const keywordSerpHtmlQuery = useQuery(
    [queryKeyReportsKeywordSerpHtml, rankingParams],
    () => fetchSerpsHtml(rankingParams),
    { staleTime: Infinity, enabled: !!rankingParams }
  )

  const [el, setEl] = React.useState<HTMLDivElement>(null!)
  const serpOverlayRef = React.useRef()
  const [loaded, setLoaded] = React.useState(false)
  const iframeRef = React.useRef<HTMLIFrameElement>(null!)
  const [hoveredResult, setHoveredResult] = React.useState(null)

  const onLoad = () => {
    setLoaded(true)
  }

  React.useEffect(() => {
    if (loaded && el) {
      serpHtmlScrollsRef.current.push(el)

      if (isNozzleVisionEnabled === null) {
        setIsNozzleVisionEnabled(true)
      }

      if (iframeRef.current?.contentDocument) {
        const htmlElements =
          iframeRef.current.contentDocument.getElementsByTagName('html')

        if (htmlElements?.[0]?.style) {
          htmlElements[0].style.overflowX = 'hidden'
        }

        if (iframeRef.current.contentDocument.body?.style) {
          Object.assign(iframeRef.current.contentDocument.body.style, {
            width: domWidth + 'px',
            height: domHeight + 'px',
            overflow: 'hidden',
          })
        }
      }

      return () => {
        serpHtmlScrollsRef.current = serpHtmlScrollsRef.current.filter(
          d => d !== el
        )
      }
    }
  }, [
    domHeight,
    domWidth,
    isNozzleVisionEnabled,
    loaded,
    el,
    serpHtmlScrollsRef,
    setIsNozzleVisionEnabled,
  ])

  React.useEffect(() => {
    serpWidthRef.current = domWidth ?? 0
  }, [domWidth, serpWidthRef])

  const nozzle_metrics = keywordSerpQuery.data?.ranking.search.nozzle_metrics

  const serpHtml = React.useMemo(
    () =>
      keywordSerpHtmlQuery.data?.replace(
        /<\/head>/,
        '<style>html { overflow: hidden; }</style></head>'
      ),
    [keywordSerpHtmlQuery.data]
  )

  return !rankingId ? (
    <div className="p-2">Select a ranking...</div>
  ) : (
    <div className="relative flex-1 divide-y divide-gray-500/20">
      {isNozzleVisionEnabled && nozzle_metrics ? (
        <div className="inline-flex divide-x divide-gray-500/20">
          <TableEl className="inline w-auto text-xs">
            <tbody>
              <TableRow>
                <TableCell className="font-bold">No Click %</TableCell>
                <TableCell className="text-right">
                  {formatNumber((nozzle_metrics.no_click_ctr || 0) * 100, {
                    precision: 2,
                    forcePrecision: true,
                  })}{' '}
                  %
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-bold">Ad Click %</TableCell>
                <TableCell className="text-right">
                  {formatNumber((nozzle_metrics.paid_ctr || 0) * 100, {
                    precision: 2,
                    forcePrecision: true,
                  })}{' '}
                  %
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-bold">Column 1 Click %</TableCell>
                <TableCell className="text-right">
                  {formatNumber((nozzle_metrics.column1_ctr || 0) * 100, {
                    precision: 2,
                    forcePrecision: true,
                  })}{' '}
                  %
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-bold">Column 2 Click %</TableCell>
                <TableCell className="text-right">
                  {formatNumber((nozzle_metrics.column2_ctr || 0) * 100, {
                    precision: 2,
                    forcePrecision: true,
                  })}{' '}
                  %
                </TableCell>
              </TableRow>
            </tbody>
          </TableEl>
          {keywordSerpQuery.data?.ranking.search_intent ? (
            <TableEl className="inline w-auto text-xs">
              <tbody>
                <TableRow>
                  <TableCell className="font-bold">
                    Informational Intent
                  </TableCell>
                  <TableCell className="text-right">
                    {formatNumber(
                      (keywordSerpQuery.data?.ranking.search_intent.traditional
                        .informational?.score || 0) * 100,
                      {
                        precision: 1,
                        forcePrecision: true,
                      }
                    )}{' '}
                    %
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-bold">
                    Navigational Intent
                  </TableCell>
                  <TableCell className="text-right">
                    {formatNumber(
                      (keywordSerpQuery.data?.ranking.search_intent.traditional
                        .navigational?.score || 0) * 100,
                      {
                        precision: 1,
                        forcePrecision: true,
                      }
                    )}{' '}
                    %
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-bold">Commercial Intent</TableCell>
                  <TableCell className="text-right">
                    {formatNumber(
                      (keywordSerpQuery.data?.ranking.search_intent.traditional
                        .commercial?.score || 0) * 100,
                      {
                        precision: 1,
                        forcePrecision: true,
                      }
                    )}{' '}
                    %
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-bold">
                    Transactional Intent
                  </TableCell>
                  <TableCell className="text-right">
                    {formatNumber(
                      (keywordSerpQuery.data?.ranking.search_intent.traditional
                        .transactional?.score || 0) * 100,
                      {
                        precision: 1,
                        forcePrecision: true,
                      }
                    )}{' '}
                    %
                  </TableCell>
                </TableRow>
              </tbody>
            </TableEl>
          ) : null}
        </div>
      ) : null}
      <div
        className="flex flex-1"
        style={{
          height: zoomedHeight,
        }}
      >
        <SerpMinimap
          {...{
            serp: keywordSerpQuery.data,
            isNozzleVisionEnabled,
            resultSearchTerm,
            serpOverlayRef,
            hoveredResult,
            setHoveredResult,
            domWidth,
            domHeight,
            resultColumnIds,
          }}
        />
        {keywordSerpQuery.isLoading ? (
          <div className="flex items-center justify-center p-4">
            <Loader className="text-2xl" />
          </div>
        ) : keywordSerpQuery.isError ? (
          <ErrorComp error={keywordSerpQuery.error} />
        ) : (
          <div
            className={twMerge(
              'relative flex-1',
              isNozzleVisionEnabled &&
                'border-l-2 border-gray-200 dark:border-l-0'
            )}
          >
            <div
              // @ts-expect-error  // Type 'Dispatch<SetStateAction<HTMLDivElement>>' is... Remove this comment to see the full error message
              ref={setEl}
              onMouseEnter={() => {
                serpActiveElRef.current = el as any
              }}
              onMouseLeave={() => {
                serpActiveElRef.current = null
              }}
              onScroll={e => {
                if (serpActiveElRef.current !== e.currentTarget) {
                  return
                }

                serpHtmlScrollsRef.current.forEach((otherEl: any) => {
                  if (otherEl !== serpActiveElRef.current) {
                    otherEl.scrollLeft = serpActiveElRef.current?.scrollLeft
                  }
                })
              }}
              className="absolute inset-0 overflow-x-auto overflow-y-hidden"
            >
              <div
                className="origin-top-left"
                style={{
                  transform: `scale(${zoom / 100})`,
                }}
              >
                <iframe
                  ref={iframeRef}
                  src={keywordSerpQuery.data?.serps[0].url}
                  srcDoc={serpHtml}
                  onLoad={onLoad}
                  title="serp"
                  style={{
                    height: domHeight + 100,
                    width: domWidth,
                  }}
                />
                <SerpOverlay
                  {...{
                    serp: keywordSerpQuery.data,
                    isNozzleVisionEnabled,
                    resultColumnIds,
                    zoom,
                    resultSearchTerm,
                    serpOverlayRef,
                    hoveredResult,
                    setHoveredResult,
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

function useValueIsAnimating(value: any, time: any) {
  const [isAnimating, setIsAnimating] = React.useState(false)

  const getPreviousValue = useGetPrevious(value)

  React.useLayoutEffect(() => {
    setIsAnimating(true)
    const timeout = setTimeout(() => {
      setIsAnimating(false)
    }, time)

    return () => {
      clearTimeout(timeout)
    }
  }, [value, time])

  return isAnimating || getPreviousValue() !== value
}

function SerpOverlay({
  // @ts-expect-error  // Property 'serp' does not exist on type '{ isNozzle... Remove this comment to see the full error message
  serp,
  isNozzleVisionEnabled,
  resultColumnIds,
  resultSearchTerm,
  // @ts-expect-error  // Property 'serpOverlayRef' does not exist on type '... Remove this comment to see the full error message
  serpOverlayRef,
  // @ts-expect-error  // Property 'hoveredResult' does not exist on type '{... Remove this comment to see the full error message
  hoveredResult,
  // @ts-expect-error  // Property 'setHoveredResult' does not exist on type... Remove this comment to see the full error message
  setHoveredResult,
}: {
  isNozzleVisionEnabled?: boolean
  resultColumnIds?: string[]
  resultSearchTerm?: string
}) {
  const resultColumnsQuery = useResultColumnsQuery()

  const resultColumns = React.useMemo(
    () =>
      resultColumnIds
        ?.map(id => resultColumnsQuery.data?.find((d: any) => d.id === id))
        .filter(Boolean),
    [resultColumnsQuery.data, resultColumnIds]
  )

  const hoverMode = !!hoveredResult

  const filterMode = !!resultSearchTerm
  const allResults = serp.ranking.results
  const filteredResults = filterResultByTerm(
    allResults,
    // @ts-expect-error  // Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    resultSearchTerm,
    resultColumns
  )

  const isNozzleVisionEnabledAnimating = useValueIsAnimating(
    isNozzleVisionEnabled,
    400
  )

  const requiredLabelCount = React.useMemo(() => {
    let requiredLabelCount = 0

    const findUniqueLabels = () => {
      const labels = resultColumns?.map(focusResultColumn => {
        const column = resultColumnsQuery.data?.find(
          (d: any) => d.id === focusResultColumn.id
        )

        return [...column.labels].reverse()[requiredLabelCount]
      })

      const uniqueLabels = uniqBy(labels as any)

      if (uniqueLabels.length !== labels?.length) {
        requiredLabelCount++
        findUniqueLabels()
      }
    }

    findUniqueLabels()

    return requiredLabelCount
  }, [resultColumns, resultColumnsQuery.data])

  return (
    <>
      <div
        className="pointer-events-none absolute inset-0 z-10 origin-top-left"
        ref={serpOverlayRef}
      >
        {allResults.map((result: any, index: any) => {
          const {
            pixels_from_top,
            pixels_from_left,
            pixel_height,
            pixel_width,
          } = result.measurements

          const isHovered = hoveredResult === result
          const isNotHovered = hoverMode && hoveredResult !== result
          const isFiltered = filterMode && filteredResults.includes(result)

          // Find color mode
          const isColored = isHovered || (filterMode ? isFiltered : true)

          // Find opacities
          let boxOpacity = 0

          if (isNozzleVisionEnabled) {
            if (isHovered) {
              boxOpacity = 1
            } else if (filterMode) {
              if (isFiltered) {
                if (isNotHovered) {
                  boxOpacity = 0.5
                } else {
                  boxOpacity = 1
                }
              } else {
                boxOpacity = 0.1
              }
            } else {
              if (isNotHovered) {
                boxOpacity = 0.2
              } else {
                boxOpacity = 1
              }
            }
          }

          const metricsOpacity = boxOpacity === 1 ? 1 : 0

          const tColor = Color(
            !isColored
              ? 'gray'
              : twConfig.theme.colors.rankGroup[
                  getRankGroupFromRank(result.rank)
                ]
          )

          return (
            <div
              key={index}
              className={twMerge(
                'ease absolute origin-top-left scale-75 transform rounded-lg border-2 border-green-500 opacity-0 shadow-md transition',
                isNozzleVisionEnabled &&
                  'pointer-events-auto scale-100 opacity-20'
              )}
              style={{
                top: pixels_from_top,
                left: pixels_from_left,
                height: pixel_height,
                width: pixel_width,
                transitionDuration: isNozzleVisionEnabledAnimating
                  ? `200ms`
                  : `0ms`,
                transitionDelay: isNozzleVisionEnabledAnimating
                  ? `${
                      result.column_paid_adjusted_rank + result.item_rank * 20
                    }ms`
                  : `0ms`,
                backgroundColor: tColor.clone().setAlpha(0.05).toRgbString(),
                borderColor: tColor.clone().toRgbString(),
                opacity: boxOpacity,
              }}
              onMouseEnter={() => setHoveredResult(result)}
              onMouseLeave={() => setHoveredResult()}
            >
              <div
                className={twMerge(
                  'pointer-events-none absolute -right-1 -top-1 transform opacity-0',
                  hoveredResult && '-translate-y-full whitespace-nowrap'
                )}
                style={{
                  opacity: metricsOpacity,
                }}
              >
                {resultColumns?.length ? (
                  <div
                    className="
                origin-top-right rounded-lg
                border-2 bg-white px-1 text-xs
                text-black
                shadow-lg
              "
                    style={{
                      // transform: `scale(${Math.min(100 / (zoom / 2), 1)})`,
                      borderColor: tColor.clone().toRgbString(),
                    }}
                  >
                    <table className="flex-col gap-2">
                      <tbody>
                        {resultColumns.map(focusResultColumn => {
                          const column = resultColumnsQuery.data?.find(
                            (d: any) => d.id === focusResultColumn.id
                          )

                          return (
                            <tr
                              key={focusResultColumn.id}
                              className="flex justify-between gap-2"
                            >
                              {resultColumns.length > 1 ? (
                                <td>
                                  {[...column.labels]
                                    .reverse()
                                    .slice(0, requiredLabelCount + 1)
                                    .reverse()
                                    .join(' › ')}
                                </td>
                              ) : null}
                              <td className="font-bold">
                                {focusResultColumn.render(
                                  focusResultColumn.accessor({ result })
                                )}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : null}
              </div>
            </div>
          )
        })}
        <div
          className={twMerge(
            `absolute left-0 h-[2px] w-full bg-gray-500 opacity-0`,
            isNozzleVisionEnabled &&
              `pointer-events-auto opacity-100 hover:opacity-0`
          )}
          style={{
            top: serp?.ranking.search.measurements.viewport_height,
          }}
        >
          <div
            className={twMerge(
              `absolute left-2 top-1/2 flex -translate-y-1/2 items-center gap-2
            rounded-lg bg-gray-700 px-2 py-1 text-xs font-bold text-white transition-opacity duration-200`
            )}
          >
            <FaArrowUp /> Above The Fold (
            {serp?.ranking.search.measurements.viewport_height}px)
          </div>
        </div>
      </div>
    </>
  )
}

function SerpMinimap({
  serp,
  resultSearchTerm,
  resultColumnIds,
  serpOverlayRef,
  hoveredResult,
  setHoveredResult,
  domWidth,
  domHeight,
  isNozzleVisionEnabled,
}: any) {
  const { themeMode } = useThemeMode()
  const allResults = serp?.ranking.results ?? []
  const resultColumnsQuery = useResultColumnsQuery()

  const resultColumns = React.useMemo(
    () =>
      resultColumnIds
        ?.map((id: any) =>
          resultColumnsQuery.data?.find((d: any) => d.id === id)
        )
        .filter(Boolean),
    [resultColumnsQuery.data, resultColumnIds]
  )
  const filteredResults = filterResultByTerm(
    allResults,
    resultSearchTerm,
    resultColumns
  )

  const isMobile = domWidth < 800

  const isNozzleVisionEnabledAnimating = useValueIsAnimating(
    isNozzleVisionEnabled,
    400
  )

  const hideOverflow = !isNozzleVisionEnabled || isNozzleVisionEnabledAnimating

  const scrollToResultIndex = (index: any) => {
    serpOverlayRef.current.children[index].scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
  }

  return (
    <div
      className={twMerge(
        `
          transitionDuration[400ms] ease sticky top-0
          z-20 overflow-visible transition-all
        `,
        isNozzleVisionEnabled && isMobile && `p-2`,
        hideOverflow && `overflow-hidden`
      )}
      style={{
        width: !isNozzleVisionEnabled ? 0 : domWidth * minimapScale + 16,
        height: domHeight * minimapScale,
      }}
    >
      <div className="relative">
        {allResults.map((result: any, index: any) => {
          const {
            pixels_from_top,
            pixels_from_left,
            pixel_height,
            pixel_width,
          } = result.measurements

          const filteredOut = !filteredResults.includes(result)

          const tColor = Color(
            filteredOut
              ? 'gray'
              : twConfig.theme.colors.rankGroup[
                  getRankGroupFromRank(result.rank)
                ]
          )

          return (
            <button
              key={index}
              onMouseEnter={() => setHoveredResult(result)}
              onMouseLeave={() => setHoveredResult()}
              onClick={() => scrollToResultIndex(index)}
              className={twMerge(
                `
                pointer-events-auto absolute
                origin-top-left transform
              `,
                css`
                  box-shadow: 0 0 0 1px
                    ${themeMode === 'dark' ? 'black' : 'white'};
                `,
                hoveredResult === result &&
                  css`
                    z-index: 10;
                    box-shadow: 0 0 0 3px
                      ${themeMode === 'dark' ? 'white' : 'black'};
                  `
              )}
              style={{
                top: pixels_from_top * minimapScale,
                left: pixels_from_left * minimapScale,
                height: pixel_height * minimapScale,
                width: pixel_width * minimapScale,
                backgroundColor: tColor
                  .clone()
                  .setAlpha(filteredOut ? 0.1 : 1)
                  .toRgbString(),
              }}
            />
          )
        })}
      </div>
    </div>
  )
}
