import moment from 'moment'

export const TimeRangeQuickPicks = [
  [
    {
      label: 'Last 7 Days',
      startOf: 'day',
      baseOffset: 1,
      baseOffsetUnit: 'day',
      offset: -7,
      offsetUnit: 'day',
      length: 7,
      lengthUnit: 'day',
    },
    {
      label: 'Last 14 Days',
      startOf: 'day',
      baseOffset: 1,
      baseOffsetUnit: 'day',
      offset: -14,
      offsetUnit: 'day',
      length: 14,
      lengthUnit: 'day',
    },
    {
      label: 'Last 30 Days',
      startOf: 'day',
      baseOffset: 1,
      baseOffsetUnit: 'day',
      offset: -30,
      offsetUnit: 'day',
      length: 30,
      lengthUnit: 'day',
    },
    {
      label: 'Last 90 Days',
      startOf: 'day',
      baseOffset: 1,
      baseOffsetUnit: 'day',
      offset: -90,
      offsetUnit: 'day',
      length: 90,
      lengthUnit: 'day',
    },
    {
      label: 'Last 365 Days',
      startOf: 'day',
      baseOffset: 1,
      baseOffsetUnit: 'day',
      offset: -365,
      offsetUnit: 'day',
      length: 365,
      lengthUnit: 'day',
    },
  ],
  [
    {
      label: 'This Week',
      startOf: 'week',
      offset: 0,
      offsetUnit: 'week',
      length: 1,
      lengthUnit: 'week',
    },
    {
      label: 'This Month',
      startOf: 'month',
      offset: 0,
      offsetUnit: 'month',
      length: 1,
      lengthUnit: 'month',
    },
    {
      label: 'This Quarter',
      startOf: 'quarter',
      offset: 0,
      offsetUnit: 'quarter',
      length: 1,
      lengthUnit: 'quarter',
    },
    {
      label: 'This Year',
      startOf: 'year',
      offset: 0,
      offsetUnit: 'year',
      length: 1,
      lengthUnit: 'year',
    },
  ],
  [
    {
      label: 'Last Week',
      startOf: 'week',
      offset: -1,
      offsetUnit: 'week',
      length: 1,
      lengthUnit: 'week',
    },
    {
      label: 'Last Month',
      startOf: 'month',
      offset: -1,
      offsetUnit: 'month',
      length: 1,
      lengthUnit: 'month',
    },
    {
      label: 'Last Quarter',
      startOf: 'quarter',
      offset: -1,
      offsetUnit: 'quarter',
      length: 1,
      lengthUnit: 'quarter',
    },
    {
      label: 'Last Year',
      startOf: 'year',
      offset: -1,
      offsetUnit: 'year',
      length: 1,
      lengthUnit: 'year',
    },
  ],
] as const

// export const FutureDateQuickPicks = [
//   [
//     {
//       label: 'Tomorrow',
//       startOf: 'day',
//       offset: 1,
//       offsetUnit: 'day',
//     },
//     {
//       label: '1 week from today',
//       startOf: 'day',
//       offset: 7,
//       offsetUnit: 'day',
//     },
//     {
//       label: '2 weeks from today',
//       startOf: 'day',
//       offset: 14,
//       offsetUnit: 'day',
//     },
//     {
//       label: '1 month from today',
//       startOf: 'day',
//       offset: 1,
//       offsetUnit: 'month',
//     },
//   ],
//   [
//     {
//       label: 'Next Week',
//       startOf: 'week',
//       offset: 1,
//       offsetUnit: 'week',
//     },
//     {
//       label: 'Next Month',
//       startOf: 'month',
//       offset: 1,
//       offsetUnit: 'month',
//     },
//     {
//       label: 'Next Quarter',
//       startOf: 'quarter',
//       offset: 1,
//       offsetUnit: 'quarter',
//     },
//     {
//       label: 'Next Year',
//       startOf: 'year',
//       offset: 1,
//       offsetUnit: 'year',
//     },
//   ],
// ]

export function convertQuickPickToRange(quickPick: any) {
  const {
    baseOffset = 0,
    baseOffsetUnit = 'day',
    startOf = 'day',
    offset = 0,
    offsetUnit = 'day',
    length = 1,
    lengthUnit = 'day',
  } = quickPick

  const base = moment()
    .utc(true)
    .startOf('day')
    .add(baseOffset, baseOffsetUnit)
    .startOf(startOf)

  const newStart = base.clone().add(offset, offsetUnit)
  const newEnd = newStart.clone().add(length, lengthUnit).add(-1, 'day')

  return [newStart.unix(), newEnd.unix()] as const
}
