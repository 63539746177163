import { ErrorBoundary } from '@sentry/react'
import { Outlet } from '@tanstack/react-router'
import { Sidebar } from '../components/Sidebar'
import { useTrialBanner } from '../hooks/useTrialBanner'
import { useUsageBanner } from '../hooks/useUsageBanner'

export function NavRoot() {
  // const showBanner = useShowBanner()

  // React.useEffect(() => {
  //   const banner = showBanner({
  //     color: 'green-500',
  //     className: 'bg-gradient-to-r from-amber-500 via-red-500 to-purple-500',
  //     style: {
  //       background: '',
  //       color: '',
  //     },
  //     href: 'https://www.producthunt.com/posts/nozzle-keyword-clustering-tool',
  //     target: '_blank',
  //     message: (
  //       // An error message to users that we are experiencing problems and are trying to fix them
  //       <Anchor className="block w-full p-2 !text-white">
  //         <LuPartyPopper className="inline text-2xl" /> We just launched our new{' '}
  //         <strong>Keyword Clustering Tool</strong>! Click here for more info and
  //         to try it out!
  //       </Anchor>
  //     ),
  //   })
  //   return banner.close
  // }, [showBanner])

  // useTrialBanner()
  // useUsageBanner()

  return (
    <>
      <Sidebar>
        <ErrorBoundary>
          <main className="flex min-h-0 w-full flex-1 flex-col items-stretch overflow-y-auto overflow-x-hidden">
            <Outlet />
          </main>
        </ErrorBoundary>
      </Sidebar>
    </>
  )
}
