import { twMerge } from 'tailwind-merge'
import { FaAngleRight } from 'react-icons/fa'
import Card from '../../components/Card'
import { Head } from '../../components/Head'
import Link from '../../components/Link'
import { useActiveWorkspaceId } from '../../hooks/workspaces'
import { ProjectPicker } from '../../components/ProjectPicker'
import RollupPicker from '../../components/RollupPicker'
import { Tabs, Tab } from '../../components/Tabs'
import {
  monitoredUrlsTabOptions,
  useActiveProjectIdState,
  useMonitoredUrlsTab,
} from '../../utils/searchParams'
import { MonitoredUrlsFilters } from './MonitoredUrlsFilters'
import { MonitoredUrlsTable } from './MonitoredUrlsTable'
import { MonitoredUrlsOverview } from './MonitoredUrlsOverview'

const monitoredUrlsLabel = (
  <span className="text-xl font-bold">Monitored URLs</span>
)

export function BackToMonitoredUrls() {
  return <Link to="/monitored-urls">{monitoredUrlsLabel}</Link>
}

export function MonitoredUrls() {
  const workspaceId = useActiveWorkspaceId()!
  const projectId = useActiveProjectIdState().state
  const monitoredUrlsTab = useMonitoredUrlsTab()

  return (
    <div className="flex min-h-0 flex-1 flex-col space-y-2 p-2">
      <Head>
        <title>Monitored URLs</title>
      </Head>
      <Card className="flex flex-wrap items-center gap-2">
        <ProjectPicker />
        <RollupPicker allowDomainsAndUrls={false} />
        <FaAngleRight />
        {monitoredUrlsLabel}
      </Card>
      <div className="flex min-h-0 flex-1 gap-2 overflow-hidden">
        <MonitoredUrlsFilters workspaceId={workspaceId} projectId={projectId} />
        <div className="flex min-h-0 w-full flex-1 flex-col gap-2 overflow-hidden">
          <Tabs>
            {monitoredUrlsTabOptions.map(tab => {
              return (
                <monitoredUrlsTab.Link key={tab.id} value={tab.id}>
                  <Tab
                    className={twMerge(
                      monitoredUrlsTab.state === tab.id && 'active'
                    )}
                  >
                    {tab.label}
                  </Tab>
                </monitoredUrlsTab.Link>
              )
            })}
            {/* <Tab>Overview</Tab> */}
          </Tabs>
          {monitoredUrlsTab.state === 'manage' ? (
            <MonitoredUrlsTable
              workspaceId={workspaceId}
              projectId={projectId}
            />
          ) : monitoredUrlsTab.state === 'overview' ? (
            <MonitoredUrlsOverview
              workspaceId={workspaceId}
              projectId={projectId}
            />
          ) : null}
          {/* <ForceTree
            parentMonitoredUrl={monitoredUrlQuery.data?.monitoredUrl}
            tableRows={tableRows}
          /> */}
        </div>
      </div>
    </div>
  )
}
