import { Outlet, Route, useMatch } from '@tanstack/react-router'
import {
  FaAngleRight,
  FaKey,
  FaUser,
  FaUserPlus,
  FaUsers,
} from 'react-icons/fa'
import { twMerge } from 'tailwind-merge'
import Card from '../../components/Card'
import Container from '../../components/Container'
import Link from '../../components/Link'
import QueryGate from '../../components/QueryGate'
import Separator from '../../components/Separator'
import { ButtonBar, tabStyles } from '../../components/ButtonBar'
import useConfirm from '../../hooks/useConfirm'
import useNavigate from '../../hooks/useNavigate'
import { useRemoveUserById, useSaveUser, useUser } from '../../hooks/users'
import { withProps } from '../../utils/withProps'
import UserForm from './UserForm'
import UserInviteForm from './UserInviteForm'
import { backToUsers } from './Users'

const TabLink = withProps(Link)(({ className, ...props }) => ({
  ...props,
  className: twMerge(tabStyles, className),
}))

export function UserRoot({ isNew }: { isNew?: boolean }) {
  const {
    params: { userId },
  } = useMatch()

  const userQuery = useUser({
    id: userId,
  })

  return (
    <Container className="space-y-2">
      <Card className="flex flex-wrap items-center gap-2">
        {backToUsers}
        <FaAngleRight />
        <QueryGate query={userQuery}>
          <div className="text-xl font-bold">{userQuery.data?.displayName}</div>
        </QueryGate>
      </Card>
      <ButtonBar>
        <TabLink activeOptions={{ exact: true }} to=".">
          <FaUser className="icon inline" /> User Info
        </TabLink>
        <TabLink to="api-tokens">
          <FaKey className="icon inline" /> API Tokens
        </TabLink>
      </ButtonBar>
      <QueryGate query={userQuery}>{() => <Outlet />}</QueryGate>
    </Container>
  )
}

export function InviteUsers() {
  return (
    <Container className="space-y-2">
      <Card className="flex items-center gap-2">
        {backToUsers}
        <FaAngleRight />
        <div className="text-xl font-bold">Invite Users</div>
      </Card>
      <Card>
        <UserInviteForm />
      </Card>
    </Container>
  )
}

export function User() {
  const {
    params: { userId },
  } = useMatch()
  const userQuery = useUser({
    id: userId,
  })
  const saveUser = useSaveUser()
  const removeUserById = useRemoveUserById()
  const confirm = useConfirm()
  const navigate = useNavigate()

  const onRemove = async () => {
    const confirmed = await confirm({
      color: 'red-500',
      message: 'This User will be deleted!',
    })

    if (confirmed) {
      // @ts-expect-error  // This expression is not callable.
      await removeUserById(userQuery.data.id)
      navigate({ to: '..' })
    }
  }

  return (
    <Card className="p-2">
      <UserForm user={userQuery.data} onSubmit={saveUser} onRemove={onRemove} />
    </Card>
  )
}
