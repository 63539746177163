import * as React from 'react'
import {
  FaAngleRight,
  FaCheck,
  FaCircle,
  FaCog,
  FaEdit,
  FaInfoCircle,
  FaPlus,
} from 'react-icons/fa'
import { UseQueryResult, useQuery } from 'react-query'
import { twMerge } from 'tailwind-merge'
import { Dashboard } from '.'
import Card from '../../components/Card'
import ControlledQueryTable from '../../components/ControlledQueryTable'
import { Counter } from '../../components/Counter'
import DashboardContainer from '../../components/DashboardContainer'
import DashboardContent from '../../components/DashboardContent'
import DashboardHeader from '../../components/DashboardHeader'
import KeywordGroupGate from '../../components/DashboardKeywordGroupGate'
import KeywordGroupPicker from '../../components/KeywordGroupPicker'
import SegmentIdGate from '../../components/SegmentIdGate'
import SegmentPicker from '../../components/SegmentPicker'
import DashboardProjectGate from '../../components/DashboardTeamGate'
import DashboardTimeRange from '../../components/DashboardTimeRange'
import { Menu } from '../../components/Menu'
import ErrorBoundary from '../../components/ErrorBoundary'
import { ExpandableWidget } from '../../components/ExpandableWidget'
import { GroupByFiltersWidget } from '../../components/GroupByFiltersWidget'
import GroupByPicker from '../../components/GroupByPicker'
import Head from '../../components/Head'
import Link from '../../components/Link'
import Loader from '../../components/Loader'
import MetricInfo from '../../components/MetricInfo'
import { Modal, ModalContent, ModalTitle } from '../../components/Modals'
import Pager from '../../components/Pager'
import { PerformanceMetricsWidget } from '../../components/PerformanceMetricsWidget'
import {
  DisplayMode,
  TimeSeriesWidget,
  TimeSeriesWidgetMode,
} from '../../components/TimeSeriesWidget'
import Tooltip from '../../components/Tooltip'
import WidgetControls from '../../components/WidgetControls'
import WidgetControlsToggle from '../../components/WidgetControlsToggle'
import { WidgetStateResetter } from '../../components/WidgetStateResetter'
import {
  useBrandOptionsQuery,
  useBrandPropertyOptionsQuery,
  useBrandTypeOptionsQuery,
  useCreateBrand,
} from '../../hooks/brands'
import useGroupByOverTimeQuery from '../../hooks/useGroupByOverTimeQuery'
import { useGroupByOverTimeQueryOptions } from '../../hooks/useGroupByOverTimeQueryOptions'
import useModal from '../../hooks/useModal'
import useModalContext from '../../hooks/useModalContext'
import useSyncMetricsState from '../../hooks/useSyncMetricsState'
import useTimeRangeState from '../../hooks/useTimeRanges'
import useWidgetPaginationReset from '../../hooks/useWidgetPaginationReset'
import useZustand from '../../hooks/useZustand'
import { useActiveWorkspaceId } from '../../hooks/workspaces'
import { getDataValue } from '../../utils'
import { fetchBrandInfo } from '../../utils/Api'
import { makeColumnCondition } from '../../utils/Conditions'
import {
  BRAND_PROPERTY_TYPE_DOMAIN,
  BRAND_PROPERTY_TYPE_SUBDOMAIN,
  BRAND_PROPERTY_TYPE_URL,
  BRAND_TYPE_COMPETITOR_DIRECT,
  DEFAULT_SAMPLES,
  competitiveShareOfVoiceSlug,
} from '../../utils/Constants'
import {
  MetricId,
  MetricPermutation,
  MetricPostAggregation,
  getMetricChangeFormatter,
  getMetricRenderer,
  metricPermutation,
  metricsById,
  renderMetricDrillthrough,
} from '../../utils/Metrics'
import { QueryFacetId, queryFacetsById } from '../../utils/queryFacets'
import BrandForm from '../brands/BrandForm'
import { ProjectPicker } from '../../components/ProjectPicker'
import { rollupDelimiter, useRollup } from '../../components/RollupPicker'
import { MenuItem } from '@ariakit/react'
import { SelectOption } from '../../hooks/useSelect'
import { useDomainOptionsQuery } from '../../hooks/useDomainOptionsQuery'
import { useHostOptionsQuery } from '../../hooks/useHostOptionsQuery'
import { useUrlOptionsQuery } from '../../hooks/useUrlOptionsQuery'
import {
  useActiveProjectIdState,
  useGroupByState,
  useKeywordGroupState,
  useMetricIdState,
  useSegmentIdState,
  useSyncMetricIdState,
} from '../../utils/searchParams'
import { useSearchState } from '../../components/useSearchState'
import { TableDisplayMode } from '../../utils/tableDisplayMode'
import { useNavigate } from '@tanstack/react-router'

//

const title = 'Competitive Share of Voice'

export const metricIds: MetricId[] = [
  'unique_keywords',
  'top_rank',
  'click_through_rate',
  'estimated_traffic',
  'top_pixels_from_top',
  'percentage_of_viewport',
  'percentage_of_dom',
  'results',
  'unique_urls',
  'ppc_value',
  'adwords_search_volume',
  'top_stat_base_rank',
]

export const competitiveShareOfVoiceDashboard: Dashboard = {
  name: 'Share of Voice',
  id: competitiveShareOfVoiceSlug,
  icon: <FaCircle className="inline-block scale-75 transform text-xs" />,
}

export default function ShareOfVoice() {
  const groupByState = useGroupByState()
  const dashboardReadyGate = !groupByState.state
    ? 'Please select a rollup level.'
    : null
  const [, setStore] = useZustand(state => state.helpUrl)
  const openDashDocs = () => {
    setStore(old => {
      old.showHelp = true
      old.helpUrl =
        'https://help.nozzle.io/competitive-share-of-voice-dashboard'

      return old
    })
  }

  return (
    <DashboardContainer>
      <Head>
        <title>{title}</title>
      </Head>
      <Card className="divide-y divide-gray-500/20 p-0">
        <div className="p-2">
          <DashboardHeader right={<DashboardTimeRange />}>
            <div className="flex flex-wrap items-center gap-2">
              <ProjectPicker />
              <FaAngleRight className="inline" />
              <span>{title}</span>
              <button onClick={openDashDocs} type="button">
                <Tooltip tooltip="Click here to learn more about this dashboard">
                  <FaInfoCircle className="text-sm text-blue-500" />
                </Tooltip>
              </button>
            </div>
          </DashboardHeader>
        </div>
        {dashboardReadyGate ? null : (
          <>
            <div className="flex flex-wrap gap-4 p-2">
              <div className="flex flex-auto items-center gap-2">
                <SegmentPicker />
              </div>
              <div className="flex flex-auto items-center gap-2">
                <KeywordGroupPicker />
              </div>
            </div>
            <div className="flex flex-wrap gap-2 p-2">
              <GroupByPicker />
              <Filters />
            </div>
          </>
        )}
      </Card>
      <DashboardProjectGate>
        <SegmentIdGate>
          <KeywordGroupGate>
            {dashboardReadyGate ? (
              <Card>{dashboardReadyGate}</Card>
            ) : (
              <DashboardContent>
                <div className="col-span-full">
                  <ErrorBoundary>
                    <Metrics />
                  </ErrorBoundary>
                </div>
                <Top />
                <Winners />
                <Losers />
                <div className="col-span-full">
                  <ErrorBoundary>
                    <RollupTable />
                  </ErrorBoundary>
                </div>
              </DashboardContent>
            )}
          </KeywordGroupGate>
        </SegmentIdGate>
      </DashboardProjectGate>
    </DashboardContainer>
  )
}

const useShareOfVoiceFilterState = () => {
  return useSearchState<string[]>({
    path: 'shareOfVoiceFilters',
    useDeps: () => {
      const groupByState = useGroupByState()
      return [groupByState.state]
    },
    useDefaultValue: () => [],
  })
}

export function createFilterComp({
  groupBy,
  useOptionsQuery,
}: {
  groupBy: QueryFacetId
  useOptionsQuery: () => UseQueryResult<SelectOption[]>
}) {
  return () => {
    const queryFacet = queryFacetsById[groupBy]
    const filterState = useShareOfVoiceFilterState()
    const optionsQuery = useOptionsQuery()

    return (
      <GroupByFiltersWidget
        filterValues={filterState.state}
        labelPlural={queryFacet.labelPlural}
        optionsQuery={optionsQuery}
        onFilterValuesChange={filterState.setState}
        className="flex-1"
      />
    )
  }
}

const BrandTypeFilters = createFilterComp({
  groupBy: 'brandType',
  useOptionsQuery: useBrandTypeOptionsQuery as any,
})

const BrandFilters = createFilterComp({
  groupBy: 'brand',
  useOptionsQuery: useBrandOptionsQuery as any,
})

const BrandPropertyFilters = createFilterComp({
  groupBy: 'brandProperty',
  useOptionsQuery: useBrandPropertyOptionsQuery as any,
})

const DomainFilters = createFilterComp({
  groupBy: 'domain',
  useOptionsQuery: useDomainOptionsQuery as any,
})

const HostFilters = createFilterComp({
  groupBy: 'host',
  useOptionsQuery: useHostOptionsQuery as any,
})

const UrlFilters = createFilterComp({
  groupBy: 'url',
  useOptionsQuery: useUrlOptionsQuery as any,
})

function Filters() {
  const groupByState = useGroupByState()

  if (groupByState.state === 'brandType') {
    return <BrandTypeFilters />
  }

  if (groupByState.state === 'brand') {
    return <BrandFilters />
  }

  if (groupByState.state === 'brandProperty') {
    return <BrandPropertyFilters />
  }

  if (groupByState.state === 'domain') {
    return <DomainFilters />
  }

  if (groupByState.state === 'host') {
    return <HostFilters />
  }

  if (groupByState.state === 'url') {
    return <UrlFilters />
  }

  return null
}

export type ShareOfVoiceMetricsWidget = {
  metricId: (typeof metricIds)[number]
  postAggregation: MetricPostAggregation
  samples: number
  limit: number | undefined
}

const useMetricsWidgetState = () => {
  const metricIdState = useMetricIdState(metricIds)

  const searchState = useSearchState<ShareOfVoiceMetricsWidget>({
    path: 'shareOfVoiceMetricsWidget',
    useDefaultValue: () => {
      return {
        metricId: metricIdState.state!,
        samples: DEFAULT_SAMPLES,
        limit: 1,
        postAggregation: 'value',
      }
    },
  })

  useSyncMetricIdState(metricIds, searchState.state.metricId)

  return searchState
}

function Metrics() {
  const groupByState = useGroupByState()
  const segmentIdState = useSegmentIdState()
  const keywordGroupState = useKeywordGroupState()
  const widgetState = useMetricsWidgetState()
  const filtersState = useShareOfVoiceFilterState()

  return (
    <PerformanceMetricsWidget
      {...{
        ...widgetState.state,
        isDirty: widgetState.isDirty,
        metricIds,
        summary: true,
        groupBy: groupByState.state,
        rollupBy: groupByState.state,
        rollupValues: filtersState.state,
        filters: {
          segmentIds: [segmentIdState.state],
          keywordGroups: [keywordGroupState.state],
        },
        setMetricsState: widgetState.setState as any,
        onReset: widgetState.reset,
      }}
    />
  )
}

export type ShareOfVoiceTopWidget = {
  metricId: (typeof metricIds)[number]
  samples: number
  limit: number
  displayPostAggregation: MetricPostAggregation
  offset: number
  tableDisplayMode: TableDisplayMode
  sortByMetricPermutation: MetricPermutation<MetricId>
  desc: boolean
  expanded: boolean
  displayMode: DisplayMode
  widgetMode: TimeSeriesWidgetMode
  showLegends: boolean
}

const useTopWidgetState = () => {
  return useSearchState<ShareOfVoiceTopWidget>({
    path: 'shareOfVoiceTopWidget',
    useDefaultValue: () => {
      const parentState = useMetricsWidgetState()

      return {
        metricId: parentState.state.metricId,
        samples: DEFAULT_SAMPLES,
        desc: true,
        limit: 5,
        offset: 0,
        displayPostAggregation: 'value',
        sortByMetricPermutation: metricPermutation(parentState.state.metricId),
        widgetMode: 'top',
        displayMode: 'summary',
        tableDisplayMode: 'summary',
        expanded: false,
        showLegends: false,
      }
    },
  })
}

function Top() {
  const groupByState = useGroupByState()
  const filtersState = useShareOfVoiceFilterState()
  const keywordGroupState = useKeywordGroupState()
  const segmentIdState = useSegmentIdState()
  const metricsState = useMetricsWidgetState()
  const widgetState = useTopWidgetState()
  const navigate = useNavigate()

  useWidgetPaginationReset(widgetState.state, widgetState.setState)
  useSyncMetricsState({
    parentState: metricsState.state,
    setState: widgetState.setState,
    syncMetric: true,
    syncSortByMetric: true,
    syncPostAggregations: false,
    syncSamples: true,
  })

  return (
    <ExpandableWidget expanded={widgetState.state.expanded}>
      <TimeSeriesWidget
        {...{
          title: 'Top',
          description: `The best performing results for a given metric as of the last datetime for the selected time range.`,
          groupBy: groupByState.state,
          rollupBy: groupByState.state,
          rollupValues: filtersState.state,
          filters: {
            segmentIds: [segmentIdState.state],
            keywordGroups: [keywordGroupState.state],
          },
          metricIds,
          showWidgetMode: false,
          canTitleDrillThrough: false,
          canExpand: true,
          state: widgetState.state,
          setState: widgetState.setState,
          isDirty: widgetState.isDirty,
          onReset: widgetState.reset,
          onClickSerie: serie => {
            navigate({
              to: `/dashboards/performance-overview`,
              search: {
                rollup:
                  queryFacetsById[groupByState.state!].getSerieRollup(serie),
              },
            })
          },
          renderSeriesLabel: serie => {
            return (
              <Link
                to="/dashboards/performance-overview"
                search={{
                  rollup:
                    queryFacetsById[groupByState.state!].getSerieRollup(serie),
                }}
              />
            )
          },
        }}
      />
    </ExpandableWidget>
  )
}

export type ShareOfVoiceWinnersWidget = {
  metricId: (typeof metricIds)[number]
  samples: number
  limit: number
  displayPostAggregation: MetricPostAggregation
  offset: number
  tableDisplayMode: TableDisplayMode
  sortByMetricPermutation: MetricPermutation<MetricId>
  desc: boolean
  expanded: boolean
  displayMode: DisplayMode
  widgetMode: TimeSeriesWidgetMode
  showLegends: boolean
}

const useWinnersWidgetState = () => {
  return useSearchState<ShareOfVoiceWinnersWidget>({
    path: 'shareOfVoiceWinnersWidget',
    useDefaultValue: () => {
      const parentState = useMetricsWidgetState()

      return {
        metricId: parentState.state.metricId,
        samples: DEFAULT_SAMPLES,
        desc: true,
        limit: 5,
        offset: 0,
        displayPostAggregation: 'change',
        sortByMetricPermutation: metricPermutation(parentState.state.metricId, {
          postAggregation: 'change',
        }),
        widgetMode: 'winners',
        displayMode: 'summary',
        tableDisplayMode: 'summary',
        expanded: false,
        showLegends: false,
      }
    },
  })
}

function Winners() {
  const groupByState = useGroupByState()
  const filtersState = useShareOfVoiceFilterState()
  const keywordGroupState = useKeywordGroupState()
  const segmentIdState = useSegmentIdState()
  const metricsState = useMetricsWidgetState()
  const widgetState = useWinnersWidgetState()
  const navigate = useNavigate()

  useWidgetPaginationReset(widgetState.state, widgetState.setState)
  useSyncMetricsState({
    parentState: metricsState.state,
    setState: widgetState.setState,
    syncMetric: true,
    syncSortByMetric: true,
    syncPostAggregations: false,
    syncSamples: true,
  })

  return (
    <ExpandableWidget expanded={widgetState.state.expanded}>
      <TimeSeriesWidget
        {...{
          title: 'Winners',
          description: `The results with the most positive change for a given metric over the selected time range.`,
          groupBy: groupByState.state,
          rollupBy: groupByState.state,
          rollupValues: filtersState.state,
          filters: {
            segmentIds: [segmentIdState.state],
            keywordGroups: [keywordGroupState.state],
          },
          metricIds,
          showWidgetMode: false,
          canTitleDrillThrough: false,
          canExpand: true,
          state: widgetState.state,
          setState: widgetState.setState,
          isDirty: widgetState.isDirty,
          onReset: widgetState.reset,
          onClickSerie: serie => {
            navigate({
              to: `/dashboards/performance-overview`,
              search: {
                rollup:
                  queryFacetsById[groupByState.state!].getSerieRollup(serie),
              },
            })
          },
          renderSeriesLabel: serie => {
            return (
              <Link
                to="/dashboards/performance-overview"
                search={{
                  rollup:
                    queryFacetsById[groupByState.state!].getSerieRollup(serie),
                }}
              />
            )
          },
        }}
      />
    </ExpandableWidget>
  )
}

export type ShareOfVoiceLosersWidget = {
  metricId: (typeof metricIds)[number]
  samples: number
  limit: number
  displayPostAggregation: MetricPostAggregation
  offset: number
  tableDisplayMode: TableDisplayMode
  sortByMetricPermutation: MetricPermutation<MetricId>
  desc: boolean
  expanded: boolean
  displayMode: DisplayMode
  widgetMode: TimeSeriesWidgetMode
  showLegends: boolean
}

const useLosersWidgetState = () => {
  return useSearchState<ShareOfVoiceLosersWidget>({
    path: 'shareOfVoiceLosersWidget',
    useDefaultValue: () => {
      const parentState = useMetricsWidgetState()

      return {
        metricId: parentState.state.metricId,
        samples: DEFAULT_SAMPLES,
        desc: false,
        limit: 5,
        offset: 0,
        displayPostAggregation: 'change',
        sortByMetricPermutation: metricPermutation(parentState.state.metricId, {
          postAggregation: 'change',
        }),
        widgetMode: 'losers',
        displayMode: 'summary',
        tableDisplayMode: 'summary',
        expanded: false,
        showLegends: false,
      }
    },
  })
}

function Losers() {
  const groupByState = useGroupByState()
  const filtersState = useShareOfVoiceFilterState()
  const keywordGroupState = useKeywordGroupState()
  const segmentIdState = useSegmentIdState()
  const metricsState = useMetricsWidgetState()
  const widgetState = useLosersWidgetState()
  const navigate = useNavigate()

  useWidgetPaginationReset(widgetState.state, widgetState.setState)
  useSyncMetricsState({
    parentState: metricsState.state,
    setState: widgetState.setState,
    syncMetric: true,
    syncSortByMetric: true,
    syncPostAggregations: false,
    syncSamples: true,
  })

  return (
    <ExpandableWidget expanded={widgetState.state.expanded}>
      <TimeSeriesWidget
        {...{
          title: 'Losers',
          description: `The results with the most negative change for a given metric over the selected time range.`,
          groupBy: groupByState.state,
          rollupBy: groupByState.state,
          rollupValues: filtersState.state,
          filters: {
            segmentIds: [segmentIdState.state],
            keywordGroups: [keywordGroupState.state],
          },
          metricIds,
          showWidgetMode: false,
          canTitleDrillThrough: false,
          canExpand: true,
          state: widgetState.state,
          setState: widgetState.setState,
          isDirty: widgetState.isDirty,
          onReset: widgetState.reset,
          onClickSerie: serie => {
            navigate({
              to: `/dashboards/performance-overview`,
              search: {
                rollup:
                  queryFacetsById[groupByState.state!].getSerieRollup(serie),
              },
            })
          },
          renderSeriesLabel: serie => {
            return (
              <Link
                to="/dashboards/performance-overview"
                search={{
                  rollup:
                    queryFacetsById[groupByState.state!].getSerieRollup(serie),
                }}
              />
            )
          },
        }}
      />
    </ExpandableWidget>
  )
}

export type ShareOfVoiceRollupsWidget = {
  metricId: (typeof metricIds)[number]
  samples: number
  limit: number
  displayPostAggregation: MetricPostAggregation
  offset: number
  tableDisplayMode: TableDisplayMode
  sortByMetricPermutation: MetricPermutation<MetricId>
  desc: boolean
  expanded: boolean
  displayMode: DisplayMode
  widgetMode: TimeSeriesWidgetMode
  showLegends: boolean
}

const useUrlsWidgetState = () => {
  return useSearchState<ShareOfVoiceRollupsWidget>({
    path: 'shareOfVoiceUrlsWidget',
    useDefaultValue: () => {
      const parentState = useMetricsWidgetState()

      return {
        metricId: parentState.state.metricId,
        samples: DEFAULT_SAMPLES,
        desc: true,
        limit: 50,
        offset: 0,
        displayPostAggregation: 'value',
        sortByMetricPermutation: metricPermutation(parentState.state.metricId),
        widgetMode: 'top',
        displayMode: 'summary',
        tableDisplayMode: 'summary',
        expanded: false,
        showLegends: false,
      }
    },
  })
}

export function RollupTable() {
  const timeRangeState = useTimeRangeState()
  const segmentIdState = useSegmentIdState()
  const keywordGroupState = useKeywordGroupState()
  const groupByState = useGroupByState()
  const [rollupBy] = useRollup()
  const metricsState = useMetricsWidgetState()
  const widgetState = useUrlsWidgetState()
  const filtersState = useShareOfVoiceFilterState()

  const showModal = useModal()

  useWidgetPaginationReset(widgetState.state, widgetState.setState)
  useSyncMetricsState({
    parentState: metricsState.state,
    setState: widgetState.setState,
    syncMetric: true,
    syncSortByMetric: true,
    syncPostAggregations: true,
    syncSamples: true,
  })

  const queryFacet = queryFacetsById[groupByState.state!]

  const metricIdsWithTopResult: MetricId[] = React.useMemo(
    () => [
      ...metricIds,
      // 'top_ranking_results'
    ],
    []
  )

  const dataQueryOptions = useGroupByOverTimeQueryOptions({
    ...widgetState.state,
    start: timeRangeState.state[0]?.start,
    end: timeRangeState.state[0]?.end,
    groupBy: groupByState.state,
    rollupBy: groupByState.state,
    rollupValues: filtersState.state,
    samples: 1,
    filters: {
      segmentIds: [segmentIdState.state],
      keywordGroups: [keywordGroupState.state],
    },
    metricPermutations: [
      ...metricIdsWithTopResult.map(id => metricPermutation(id)),
      ...metricIds.map(d =>
        metricPermutation(d, {
          postAggregation: 'change',
        })
      ),
    ],
  })

  const dataQuery = useGroupByOverTimeQuery(dataQueryOptions)
  const { sortByMetricPermutation } = widgetState.state

  const [showControls, toggleControls] = React.useReducer(
    (state, action = !state) => action,
    false
  )

  const brandsQuery = useBrandOptionsQuery()

  const columns = React.useMemo(() => {
    const getIsActive = (metricId: MetricId, isChange: boolean) => {
      return (
        sortByMetricPermutation.id === metricId &&
        (isChange
          ? sortByMetricPermutation.postAggregation === 'change'
          : sortByMetricPermutation.postAggregation === 'value')
      )
    }

    const metricColumn = (metricId: MetricId, isChange: any) => {
      return {
        getHeaderProps: ({ className }: any) => ({
          className: twMerge(
            getIsActive(metricId, isChange) && 'font-bold',
            className
          ),
        }),
        getCellProps: ({ className }: any) => ({
          className: twMerge(
            getIsActive(metricId, isChange) && 'font-bold',
            className
          ),
        }),
      }
    }

    const getActiveMetricStyles = (metricId: MetricId, isChange: boolean) => {
      return getIsActive(metricId, isChange)
        ? {
            fontWeight: 'bold',
          }
        : {}
    }

    const addBrand = ({
      value,
      rollup,
      brand,
    }: {
      value: string
      rollup: string
      brand?: any
    }) => {
      showModal(() => <AddBrandModal {...{ value, rollup, brand }} />)
    }

    const newBrandRollups = ['domainIds', 'urlIds', 'hostIds']

    const brandPropertyValues = brandsQuery.data?.flatMap(brand => {
      return {
        brandId: brand.brand.id,
        properties: brand.brand.properties.flatMap(
          property =>
            property.condition.values ??
            property.condition.conditions.flatMap((v: any) => v.values)
        ),
      }
    })

    return [
      {
        id: 'actions',
        tight: true,
        cell: function Cell({ row: { original } }: any) {
          const value =
            rollupBy === 'domain'
              ? original.domain_data?.domain
              : rollupBy === 'host'
              ? original.host_data?.host
              : original.url?.result__url__url

          const editUrl =
            rollupBy === 'brandType'
              ? `/brands`
              : rollupBy === 'brand'
              ? `/brands/${original.brand?.brand_id}`
              : `/brands/${original.brand_property?.brand_id}`

          const hasBrands = brandPropertyValues.filter(value =>
            value.properties.find(v => v === original.label)
          )

          return (
            <Menu
              button={
                <button type="button">
                  <FaCog className="inline text-lg opacity-70 hover:opacity-100" />
                </button>
              }
            >
              {newBrandRollups.includes(rollupBy) ? (
                <>
                  <MenuItem
                    onClick={() =>
                      addBrand({ value, rollup: groupByState.state })
                    }
                  >
                    <div className="text-left ">
                      <FaPlus className="inline" /> Create Brand
                      {hasBrands.length > 0 ? (
                        <div className="block text-xs font-light italic opacity-70 ">
                          {`Belongs to ${hasBrands.length} existing brand${
                            hasBrands.length > 1 ? 's' : ''
                          }`}
                        </div>
                      ) : null}
                    </div>
                  </MenuItem>
                  <Menu button={<MenuItem>Add to Existing Brand</MenuItem>}>
                    {brandsQuery.data?.map(brand => (
                      <MenuItem
                        key={brand.brand.id}
                        onClick={() =>
                          addBrand({
                            value,
                            brand: brand.brand,
                            rollup: rollupBy,
                          })
                        }
                      >
                        {brand.label}{' '}
                        {hasBrands?.find(
                          value => value.brandId === brand.brand.id
                        ) ? (
                          <FaCheck />
                        ) : (
                          ''
                        )}
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              ) : (
                <Link
                  to={editUrl}
                  params={undefined as any}
                  search={undefined as any}
                >
                  <FaEdit className="inline" /> Edit
                </Link>
              )}
            </Menu>
          )
        },
      },

      {
        id: 'label',
        header: queryFacet.label,
        enableSorting: false,
        cell: (props: any) => {
          const value = queryFacet.renderSeriesLabel(
            props.row.original,
            [props.row.original],
            {
              short: true,
            }
          )

          return (
            <Link
              to="/dashboards/performance-overview"
              search={{
                rollup: queryFacet.getSerieRollup(props.row.original),
              }}
              className="font-bold"
            >
              {value}
            </Link>
          )
        },
      },
      ...((
        {
          brand: [
            {
              header: 'Brand Type',
              cell: (props: any) => (
                <Link
                  to="/dashboards/performance-overview"
                  search={{
                    rollup: [
                      'brandType',
                      props.row.original.brand?.brand_type,
                    ].join(rollupDelimiter),
                  }}
                  className="font-bold"
                >
                  {props.row.original.brand?.brand_type ?? '-'}
                </Link>
              ),
            },
            // {
            //   header: 'Reputation Impact',
            //   cell: props =>
            //     formatOption(
            //       reputationImpactOptions,
            //       props.row.original.brand?.reputation_impact
            //     ) ?? '-',
            // },
          ],
          brandProperty: [
            {
              header: 'Brand Name',
              cell: (props: any) => (
                <Link
                  to="/dashboards/performance-overview"
                  search={{
                    rollup: [
                      'brand',
                      props.row.original.brand_property?.brand_id,
                    ].join(rollupDelimiter),
                  }}
                  className="font-bold"
                >
                  {props.row.original.brand_property?.brand_name ?? '-'}
                </Link>
              ),
            },
            {
              header: 'Brand Type',
              cell: (props: any) => (
                <Link
                  to="/dashboards/performance-overview"
                  search={{
                    rollup: [
                      'brandType',
                      props.row.original.brand_property?.brand_type,
                    ].join(rollupDelimiter),
                  }}
                  className="font-bold"
                >
                  {props.row.original.brand_property?.brand_type ?? '-'}
                </Link>
              ),
            },
            // {
            //   header: 'Reputation Impact',
            //   cell: props =>
            //     formatOption(
            //       reputationImpactOptions,
            //       props.row.original.brand_property?.reputation_impact
            //     ) ?? '-',
            // },
          ],
        } as Record<QueryFacetId, any[]>
      )[queryFacet.id] ?? []),
      ...metricIds
        .map(metricId => {
          const metric = metricsById[metricId]

          return [
            {
              header: () => (
                <MetricInfo metricId={metricId}>{metric.label}</MetricInfo>
              ),
              id: metricId,
              accessorFn: (row: any): any =>
                getDataValue(
                  row.data[row.data.length - 1],
                  metricPermutation(metricId, {
                    postAggregation: 'value',
                  })
                ),
              cell: (props: any) => (
                <div
                  style={{
                    ...getActiveMetricStyles(metricId, false),
                  }}
                  className="text-right"
                >
                  {renderMetricDrillthrough(
                    metricId,
                    queryFacet,
                    props.row.original,
                    props.getValue()
                  )(
                    getMetricRenderer(metricId)(props.getValue(), {
                      row: props.row.original,
                    })
                  )}
                </div>
              ),
              ...metricColumn(metricId, false),
            },
            {
              id: `${metricId}Change`,
              header: '+/-',
              accessorFn: (row: any): any =>
                getDataValue(
                  row.data[row.data.length - 1],
                  metricPermutation(metricId, {
                    postAggregation: 'change',
                  })
                ),
              cell: (props: any) => (
                <div
                  style={{
                    ...getActiveMetricStyles(metricId, true),
                  }}
                  className="text-right"
                >
                  {getMetricChangeFormatter(metricId)(props.getValue())}
                </div>
              ),
              ...metricColumn(metricId, true),
            },
          ]
        })
        .flat(),
    ]
  }, [
    brandsQuery.data,
    groupByState.state,
    rollupBy,
    queryFacet,
    showModal,
    sortByMetricPermutation.id,
    sortByMetricPermutation.postAggregation,
  ])

  return (
    <Card className="divide-y divide-gray-500/20 p-0">
      <div className="m-0 flex items-center gap-2 p-2 font-bold">
        <span>{queryFacet.labelPlural}</span>
        <div className="mr-auto">
          {dataQuery.isFetching ? <Loader /> : null}
        </div>
        <WidgetStateResetter
          {...{
            isDirty: widgetState.isDirty,
            onReset: widgetState.reset,
          }}
        />
        <WidgetControlsToggle
          showControls={showControls}
          onToggle={toggleControls}
        />
      </div>
      {showControls ? (
        <div className="p-2">
          <WidgetControls
            {...{
              dataQueryOptions,
              ...widgetState.state,
              setState: widgetState.setState,
              allowSamples: true,
              allowSorting: true,
              allowMetrics: true,
              allowPostAggregation: true,
              allowExport: true,
              allowLegends: true,
            }}
          />
        </div>
      ) : null}

      <ControlledQueryTable
        {...{
          ...widgetState.state,
          compact: true,
          dataQuery,
          metricIds,
          setState: widgetState.setState,
          columns,
        }}
      />
      <div className="p-1">
        <Counter
          {...{
            compact: true,
            isLoading: dataQuery.isFetching,
            count: dataQuery.data?.series?.length,
            totalCount: dataQuery.data?.rowCount,
            totalName: queryFacet.labelPlural,
          }}
        />
      </div>
      <div className="p-1">
        <Pager
          compact
          {...widgetState.state}
          size={dataQuery.data?.rowCount}
          setOffset={offset =>
            widgetState.setState(old => ({
              ...old,
              offset,
            }))
          }
          setLimit={limit =>
            widgetState.setState(old => ({
              ...old,
              limit,
            }))
          }
        />
      </div>
    </Card>
  )
}

function AddBrandModal({ value, rollup, brand }: any) {
  const { close } = useModalContext()
  const createBrand = useCreateBrand()
  const activeWorkspaceId = useActiveWorkspaceId()
  const projectId = useActiveProjectIdState().state

  const brandInfoQuery = useQuery(['brandInfo', value], () =>
    fetchBrandInfo(value)
  )

  return (
    <Modal>
      <ModalTitle>Add Brand</ModalTitle>
      <ModalContent className="p-4">
        {brandInfoQuery.data ? (
          <BrandForm
            showBrandModal={false}
            isModal={true}
            closeModal={close}
            canRemove={false}
            brand={
              brand
                ? {
                    ...brand,
                    properties: [
                      ...brand.properties,
                      {
                        name: value,
                        type:
                          rollup === 'domainIds'
                            ? BRAND_PROPERTY_TYPE_DOMAIN
                            : rollup === 'urlIds'
                            ? BRAND_PROPERTY_TYPE_URL
                            : BRAND_PROPERTY_TYPE_SUBDOMAIN,

                        value,
                        condition:
                          rollup === 'domainIds'
                            ? makeColumnCondition('result__url__domain', '=', [
                                value,
                              ])
                            : rollup === 'hostIds'
                            ? makeColumnCondition('result__url__host', '=', [
                                value,
                              ])
                            : makeColumnCondition('result__url__url', '=', [
                                value,
                              ]),
                      },
                    ],
                  }
                : {
                    name: value,
                    type: BRAND_TYPE_COMPETITOR_DIRECT,
                    properties:
                      rollup === 'domainIds'
                        ? brandInfoQuery.data?.properties
                        : [
                            {
                              name: value,
                              type:
                                rollup === 'domainIds'
                                  ? BRAND_PROPERTY_TYPE_DOMAIN
                                  : rollup === 'urlIds'
                                  ? BRAND_PROPERTY_TYPE_URL
                                  : BRAND_PROPERTY_TYPE_SUBDOMAIN,
                              value,
                              condition:
                                rollup === 'domainIds'
                                  ? makeColumnCondition(
                                      'result__url__domain',
                                      '=',
                                      [value]
                                    )
                                  : rollup === 'hostIds'
                                  ? makeColumnCondition(
                                      'result__url__host',
                                      '=',
                                      [value]
                                    )
                                  : makeColumnCondition(
                                      'result__url__url',
                                      '=',
                                      [value]
                                    ),
                            },
                          ],
                  }
            }
            onSubmit={values => {
              createBrand({
                ...values,
                // @ts-expect-error  // Argument of type '{ workspaceId: number | undefine... Remove this comment to see the full error message
                workspaceId: activeWorkspaceId,
                teamId: projectId,
              })
              close()
            }}
          />
        ) : (
          <Loader />
        )}
      </ModalContent>
    </Modal>
  )
}
