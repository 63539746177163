// @generated by protoc-gen-es v1.3.0 with parameter "target=ts"
// @generated from file nozzle/workspaces/v1/keyword_sources.proto (package nozzle.workspaces.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { DataModel, DataModel2, DeviceModel, KeywordSourceDataModel, LocaleModel, PhraseTemplate, Schedule } from "./data_model_pb.js";
import { Keyword } from "../../keywords/v1/keywords_pb.js";

/**
 * KeywordSourceState is the state of a keyword source.
 *
 * @generated from enum nozzle.workspaces.v1.KeywordSourceState
 */
export enum KeywordSourceState {
  /**
   * @generated from enum value: KEYWORD_SOURCE_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: KEYWORD_SOURCE_STATE_ACTIVE = 1;
   */
  ACTIVE = 1,

  /**
   * @generated from enum value: KEYWORD_SOURCE_STATE_PAUSED = 2;
   */
  PAUSED = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(KeywordSourceState)
proto3.util.setEnumType(KeywordSourceState, "nozzle.workspaces.v1.KeywordSourceState", [
  { no: 0, name: "KEYWORD_SOURCE_STATE_UNSPECIFIED" },
  { no: 1, name: "KEYWORD_SOURCE_STATE_ACTIVE" },
  { no: 2, name: "KEYWORD_SOURCE_STATE_PAUSED" },
]);

/**
 * Type is what kind of Keyword Source this is.
 *
 * @generated from enum nozzle.workspaces.v1.KeywordSourceConfigType
 */
export enum KeywordSourceConfigType {
  /**
   * @generated from enum value: KEYWORD_SOURCE_CONFIG_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: KEYWORD_SOURCE_CONFIG_TYPE_JSON = 1;
   */
  JSON = 1,

  /**
   * @generated from enum value: KEYWORD_SOURCE_CONFIG_TYPE_CITY = 2;
   */
  CITY = 2,

  /**
   * @generated from enum value: KEYWORD_SOURCE_CONFIG_TYPE_BASIC = 3;
   */
  BASIC = 3,

  /**
   * @generated from enum value: KEYWORD_SOURCE_CONFIG_TYPE_KEYWORD_ID_GROUPS = 4;
   */
  KEYWORD_ID_GROUPS = 4,

  /**
   * @generated from enum value: KEYWORD_SOURCE_CONFIG_TYPE_DATA_MODEL = 5;
   */
  DATA_MODEL = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(KeywordSourceConfigType)
proto3.util.setEnumType(KeywordSourceConfigType, "nozzle.workspaces.v1.KeywordSourceConfigType", [
  { no: 0, name: "KEYWORD_SOURCE_CONFIG_TYPE_UNSPECIFIED" },
  { no: 1, name: "KEYWORD_SOURCE_CONFIG_TYPE_JSON" },
  { no: 2, name: "KEYWORD_SOURCE_CONFIG_TYPE_CITY" },
  { no: 3, name: "KEYWORD_SOURCE_CONFIG_TYPE_BASIC" },
  { no: 4, name: "KEYWORD_SOURCE_CONFIG_TYPE_KEYWORD_ID_GROUPS" },
  { no: 5, name: "KEYWORD_SOURCE_CONFIG_TYPE_DATA_MODEL" },
]);

/**
 * RequestTiming is when to request a keyword source refresh.
 *
 * @generated from enum nozzle.workspaces.v1.RequestTiming
 */
export enum RequestTiming {
  /**
   * @generated from enum value: REQUEST_TIMING_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: REQUEST_TIMING_IMMEDIATE = 1;
   */
  IMMEDIATE = 1,

  /**
   * @generated from enum value: REQUEST_TIMING_TODAY = 2;
   */
  TODAY = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(RequestTiming)
proto3.util.setEnumType(RequestTiming, "nozzle.workspaces.v1.RequestTiming", [
  { no: 0, name: "REQUEST_TIMING_UNSPECIFIED" },
  { no: 1, name: "REQUEST_TIMING_IMMEDIATE" },
  { no: 2, name: "REQUEST_TIMING_TODAY" },
]);

/**
 * GenerateKeywordsRequest is the request for the GenerateKeywords method.
 *
 * @generated from message nozzle.workspaces.v1.GenerateKeywordsRequest
 */
export class GenerateKeywordsRequest extends Message<GenerateKeywordsRequest> {
  /**
   * @generated from oneof nozzle.workspaces.v1.GenerateKeywordsRequest.config
   */
  config: {
    /**
     * @generated from field: nozzle.workspaces.v1.KeywordSourceDataModelConfig data_model_config = 1;
     */
    value: KeywordSourceDataModelConfig;
    case: "dataModelConfig";
  } | {
    /**
     * @generated from field: nozzle.workspaces.v1.KeywordSourceDataModelConfig2 keyword_source_config = 4;
     */
    value: KeywordSourceDataModelConfig2;
    case: "keywordSourceConfig";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: int64 workspace_id = 2;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: repeated nozzle.workspaces.v1.Schedule schedules = 3;
   */
  schedules: Schedule[] = [];

  constructor(data?: PartialMessage<GenerateKeywordsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.GenerateKeywordsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "data_model_config", kind: "message", T: KeywordSourceDataModelConfig, oneof: "config" },
    { no: 4, name: "keyword_source_config", kind: "message", T: KeywordSourceDataModelConfig2, oneof: "config" },
    { no: 2, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "schedules", kind: "message", T: Schedule, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateKeywordsRequest {
    return new GenerateKeywordsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateKeywordsRequest {
    return new GenerateKeywordsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerateKeywordsRequest {
    return new GenerateKeywordsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GenerateKeywordsRequest | PlainMessage<GenerateKeywordsRequest> | undefined, b: GenerateKeywordsRequest | PlainMessage<GenerateKeywordsRequest> | undefined): boolean {
    return proto3.util.equals(GenerateKeywordsRequest, a, b);
  }
}

/**
 * GenerateKeywordsResponse is the response for the GenerateKeywords method.
 *
 * @generated from message nozzle.workspaces.v1.GenerateKeywordsResponse
 */
export class GenerateKeywordsResponse extends Message<GenerateKeywordsResponse> {
  /**
   * @generated from field: repeated nozzle.workspaces.v1.ScheduledKeyword keywords = 1;
   */
  keywords: ScheduledKeyword[] = [];

  /**
   * @generated from field: nozzle.workspaces.v1.KeywordSourceDataModel originating_data_model = 2;
   */
  originatingDataModel?: KeywordSourceDataModel;

  /**
   * @generated from field: repeated nozzle.workspaces.v1.ScheduledKeyword invalid_keywords = 3;
   */
  invalidKeywords: ScheduledKeyword[] = [];

  constructor(data?: PartialMessage<GenerateKeywordsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.GenerateKeywordsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "keywords", kind: "message", T: ScheduledKeyword, repeated: true },
    { no: 2, name: "originating_data_model", kind: "message", T: KeywordSourceDataModel },
    { no: 3, name: "invalid_keywords", kind: "message", T: ScheduledKeyword, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateKeywordsResponse {
    return new GenerateKeywordsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateKeywordsResponse {
    return new GenerateKeywordsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerateKeywordsResponse {
    return new GenerateKeywordsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GenerateKeywordsResponse | PlainMessage<GenerateKeywordsResponse> | undefined, b: GenerateKeywordsResponse | PlainMessage<GenerateKeywordsResponse> | undefined): boolean {
    return proto3.util.equals(GenerateKeywordsResponse, a, b);
  }
}

/**
 * GetKeywordSourceRequest is the request for the GetKeywordSource method.
 *
 * @generated from message nozzle.workspaces.v1.GetKeywordSourceRequest
 */
export class GetKeywordSourceRequest extends Message<GetKeywordSourceRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: int64 keyword_source_id = 2;
   */
  keywordSourceId = protoInt64.zero;

  /**
   * @generated from field: int64 keyword_source_version_id = 3;
   */
  keywordSourceVersionId = protoInt64.zero;

  constructor(data?: PartialMessage<GetKeywordSourceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.GetKeywordSourceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "keyword_source_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "keyword_source_version_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetKeywordSourceRequest {
    return new GetKeywordSourceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetKeywordSourceRequest {
    return new GetKeywordSourceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetKeywordSourceRequest {
    return new GetKeywordSourceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetKeywordSourceRequest | PlainMessage<GetKeywordSourceRequest> | undefined, b: GetKeywordSourceRequest | PlainMessage<GetKeywordSourceRequest> | undefined): boolean {
    return proto3.util.equals(GetKeywordSourceRequest, a, b);
  }
}

/**
 * GetKeywordSourceResponse is the response for the GetKeywordSource method.
 *
 * @generated from message nozzle.workspaces.v1.GetKeywordSourceResponse
 */
export class GetKeywordSourceResponse extends Message<GetKeywordSourceResponse> {
  /**
   * @generated from field: nozzle.workspaces.v1.KeywordSource keyword_source = 1;
   */
  keywordSource?: KeywordSource;

  constructor(data?: PartialMessage<GetKeywordSourceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.GetKeywordSourceResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "keyword_source", kind: "message", T: KeywordSource },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetKeywordSourceResponse {
    return new GetKeywordSourceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetKeywordSourceResponse {
    return new GetKeywordSourceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetKeywordSourceResponse {
    return new GetKeywordSourceResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetKeywordSourceResponse | PlainMessage<GetKeywordSourceResponse> | undefined, b: GetKeywordSourceResponse | PlainMessage<GetKeywordSourceResponse> | undefined): boolean {
    return proto3.util.equals(GetKeywordSourceResponse, a, b);
  }
}

/**
 * CreateKeywordSourceRequest is the request for the CreateKeywordSource method.
 *
 * @generated from message nozzle.workspaces.v1.CreateKeywordSourceRequest
 */
export class CreateKeywordSourceRequest extends Message<CreateKeywordSourceRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: nozzle.workspaces.v1.KeywordSource keyword_source = 2;
   */
  keywordSource?: KeywordSource;

  /**
   * @generated from field: nozzle.workspaces.v1.RequestTiming request_timing = 3;
   */
  requestTiming = RequestTiming.UNSPECIFIED;

  constructor(data?: PartialMessage<CreateKeywordSourceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.CreateKeywordSourceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "keyword_source", kind: "message", T: KeywordSource },
    { no: 3, name: "request_timing", kind: "enum", T: proto3.getEnumType(RequestTiming) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateKeywordSourceRequest {
    return new CreateKeywordSourceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateKeywordSourceRequest {
    return new CreateKeywordSourceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateKeywordSourceRequest {
    return new CreateKeywordSourceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateKeywordSourceRequest | PlainMessage<CreateKeywordSourceRequest> | undefined, b: CreateKeywordSourceRequest | PlainMessage<CreateKeywordSourceRequest> | undefined): boolean {
    return proto3.util.equals(CreateKeywordSourceRequest, a, b);
  }
}

/**
 * CreateKeywordSourceResponse is the response for the CreateKeywordSource method.
 *
 * @generated from message nozzle.workspaces.v1.CreateKeywordSourceResponse
 */
export class CreateKeywordSourceResponse extends Message<CreateKeywordSourceResponse> {
  /**
   * @generated from field: nozzle.workspaces.v1.KeywordSource keyword_source = 1;
   */
  keywordSource?: KeywordSource;

  constructor(data?: PartialMessage<CreateKeywordSourceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.CreateKeywordSourceResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "keyword_source", kind: "message", T: KeywordSource },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateKeywordSourceResponse {
    return new CreateKeywordSourceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateKeywordSourceResponse {
    return new CreateKeywordSourceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateKeywordSourceResponse {
    return new CreateKeywordSourceResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateKeywordSourceResponse | PlainMessage<CreateKeywordSourceResponse> | undefined, b: CreateKeywordSourceResponse | PlainMessage<CreateKeywordSourceResponse> | undefined): boolean {
    return proto3.util.equals(CreateKeywordSourceResponse, a, b);
  }
}

/**
 * ListKeywordSourcesRequest is the request for the ListKeywordSources method.
 *
 * @generated from message nozzle.workspaces.v1.ListKeywordSourcesRequest
 */
export class ListKeywordSourcesRequest extends Message<ListKeywordSourcesRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: int64 project_id = 2;
   */
  projectId = protoInt64.zero;

  constructor(data?: PartialMessage<ListKeywordSourcesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.ListKeywordSourcesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "project_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListKeywordSourcesRequest {
    return new ListKeywordSourcesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListKeywordSourcesRequest {
    return new ListKeywordSourcesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListKeywordSourcesRequest {
    return new ListKeywordSourcesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListKeywordSourcesRequest | PlainMessage<ListKeywordSourcesRequest> | undefined, b: ListKeywordSourcesRequest | PlainMessage<ListKeywordSourcesRequest> | undefined): boolean {
    return proto3.util.equals(ListKeywordSourcesRequest, a, b);
  }
}

/**
 * ListKeywordSourcesResponse is the response for the ListKeywordSources method.
 *
 * @generated from message nozzle.workspaces.v1.ListKeywordSourcesResponse
 */
export class ListKeywordSourcesResponse extends Message<ListKeywordSourcesResponse> {
  /**
   * @generated from field: repeated nozzle.workspaces.v1.KeywordSource keyword_sources = 1;
   */
  keywordSources: KeywordSource[] = [];

  constructor(data?: PartialMessage<ListKeywordSourcesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.ListKeywordSourcesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "keyword_sources", kind: "message", T: KeywordSource, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListKeywordSourcesResponse {
    return new ListKeywordSourcesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListKeywordSourcesResponse {
    return new ListKeywordSourcesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListKeywordSourcesResponse {
    return new ListKeywordSourcesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListKeywordSourcesResponse | PlainMessage<ListKeywordSourcesResponse> | undefined, b: ListKeywordSourcesResponse | PlainMessage<ListKeywordSourcesResponse> | undefined): boolean {
    return proto3.util.equals(ListKeywordSourcesResponse, a, b);
  }
}

/**
 * UpdateKeywordSourceRequest is the request for the UpdateKeywordSource method.
 *
 * @generated from message nozzle.workspaces.v1.UpdateKeywordSourceRequest
 */
export class UpdateKeywordSourceRequest extends Message<UpdateKeywordSourceRequest> {
  /**
   * @generated from field: nozzle.workspaces.v1.KeywordSource keyword_source = 1;
   */
  keywordSource?: KeywordSource;

  /**
   * @generated from field: nozzle.workspaces.v1.RequestTiming request_timing = 2;
   */
  requestTiming = RequestTiming.UNSPECIFIED;

  constructor(data?: PartialMessage<UpdateKeywordSourceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.UpdateKeywordSourceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "keyword_source", kind: "message", T: KeywordSource },
    { no: 2, name: "request_timing", kind: "enum", T: proto3.getEnumType(RequestTiming) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateKeywordSourceRequest {
    return new UpdateKeywordSourceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateKeywordSourceRequest {
    return new UpdateKeywordSourceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateKeywordSourceRequest {
    return new UpdateKeywordSourceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateKeywordSourceRequest | PlainMessage<UpdateKeywordSourceRequest> | undefined, b: UpdateKeywordSourceRequest | PlainMessage<UpdateKeywordSourceRequest> | undefined): boolean {
    return proto3.util.equals(UpdateKeywordSourceRequest, a, b);
  }
}

/**
 * UpdateKeywordSourceResponse is the response for the UpdateKeywordSource method.
 *
 * @generated from message nozzle.workspaces.v1.UpdateKeywordSourceResponse
 */
export class UpdateKeywordSourceResponse extends Message<UpdateKeywordSourceResponse> {
  /**
   * @generated from field: nozzle.workspaces.v1.KeywordSource keyword_source = 1;
   */
  keywordSource?: KeywordSource;

  constructor(data?: PartialMessage<UpdateKeywordSourceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.UpdateKeywordSourceResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "keyword_source", kind: "message", T: KeywordSource },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateKeywordSourceResponse {
    return new UpdateKeywordSourceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateKeywordSourceResponse {
    return new UpdateKeywordSourceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateKeywordSourceResponse {
    return new UpdateKeywordSourceResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateKeywordSourceResponse | PlainMessage<UpdateKeywordSourceResponse> | undefined, b: UpdateKeywordSourceResponse | PlainMessage<UpdateKeywordSourceResponse> | undefined): boolean {
    return proto3.util.equals(UpdateKeywordSourceResponse, a, b);
  }
}

/**
 * RefreshKeywordSourceRequest is the request for the RefreshKeywordSource method.
 *
 * @generated from message nozzle.workspaces.v1.RefreshKeywordSourceRequest
 */
export class RefreshKeywordSourceRequest extends Message<RefreshKeywordSourceRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: int64 keyword_source_id = 2;
   */
  keywordSourceId = protoInt64.zero;

  /**
   * @generated from field: int64 keyword_source_version_id = 3;
   */
  keywordSourceVersionId = protoInt64.zero;

  /**
   * @generated from field: nozzle.workspaces.v1.RequestTiming request_timing = 4;
   */
  requestTiming = RequestTiming.UNSPECIFIED;

  constructor(data?: PartialMessage<RefreshKeywordSourceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.RefreshKeywordSourceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "keyword_source_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "keyword_source_version_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "request_timing", kind: "enum", T: proto3.getEnumType(RequestTiming) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RefreshKeywordSourceRequest {
    return new RefreshKeywordSourceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RefreshKeywordSourceRequest {
    return new RefreshKeywordSourceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RefreshKeywordSourceRequest {
    return new RefreshKeywordSourceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RefreshKeywordSourceRequest | PlainMessage<RefreshKeywordSourceRequest> | undefined, b: RefreshKeywordSourceRequest | PlainMessage<RefreshKeywordSourceRequest> | undefined): boolean {
    return proto3.util.equals(RefreshKeywordSourceRequest, a, b);
  }
}

/**
 * RefreshKeywordSourceResponse is the response for the RefreshKeywordSource method.
 *
 * @generated from message nozzle.workspaces.v1.RefreshKeywordSourceResponse
 */
export class RefreshKeywordSourceResponse extends Message<RefreshKeywordSourceResponse> {
  constructor(data?: PartialMessage<RefreshKeywordSourceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.RefreshKeywordSourceResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RefreshKeywordSourceResponse {
    return new RefreshKeywordSourceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RefreshKeywordSourceResponse {
    return new RefreshKeywordSourceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RefreshKeywordSourceResponse {
    return new RefreshKeywordSourceResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RefreshKeywordSourceResponse | PlainMessage<RefreshKeywordSourceResponse> | undefined, b: RefreshKeywordSourceResponse | PlainMessage<RefreshKeywordSourceResponse> | undefined): boolean {
    return proto3.util.equals(RefreshKeywordSourceResponse, a, b);
  }
}

/**
 * SuggestKeywordsRequest is the request for SuggestKeywords.
 *
 * @generated from message nozzle.workspaces.v1.SuggestKeywordsRequest
 */
export class SuggestKeywordsRequest extends Message<SuggestKeywordsRequest> {
  /**
   * @generated from field: string query = 1;
   */
  query = "";

  /**
   * @generated from field: bool include_alphabetical_prefixes = 2;
   */
  includeAlphabeticalPrefixes = false;

  /**
   * @generated from field: bool include_alphabetical_suffixes = 3;
   */
  includeAlphabeticalSuffixes = false;

  constructor(data?: PartialMessage<SuggestKeywordsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.SuggestKeywordsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "query", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "include_alphabetical_prefixes", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "include_alphabetical_suffixes", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SuggestKeywordsRequest {
    return new SuggestKeywordsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SuggestKeywordsRequest {
    return new SuggestKeywordsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SuggestKeywordsRequest {
    return new SuggestKeywordsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SuggestKeywordsRequest | PlainMessage<SuggestKeywordsRequest> | undefined, b: SuggestKeywordsRequest | PlainMessage<SuggestKeywordsRequest> | undefined): boolean {
    return proto3.util.equals(SuggestKeywordsRequest, a, b);
  }
}

/**
 * SuggestKeywordResponse is the request for SuggestKeywords.
 *
 * @generated from message nozzle.workspaces.v1.SuggestKeywordsResponse
 */
export class SuggestKeywordsResponse extends Message<SuggestKeywordsResponse> {
  /**
   * @generated from field: repeated nozzle.workspaces.v1.SuggestedKeywordResult results = 1;
   */
  results: SuggestedKeywordResult[] = [];

  constructor(data?: PartialMessage<SuggestKeywordsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.SuggestKeywordsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "results", kind: "message", T: SuggestedKeywordResult, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SuggestKeywordsResponse {
    return new SuggestKeywordsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SuggestKeywordsResponse {
    return new SuggestKeywordsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SuggestKeywordsResponse {
    return new SuggestKeywordsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SuggestKeywordsResponse | PlainMessage<SuggestKeywordsResponse> | undefined, b: SuggestKeywordsResponse | PlainMessage<SuggestKeywordsResponse> | undefined): boolean {
    return proto3.util.equals(SuggestKeywordsResponse, a, b);
  }
}

/**
 * SuggestedKeywordResult contains suggestions for a keyword and the prefix used.
 *
 * @generated from message nozzle.workspaces.v1.SuggestedKeywordResult
 */
export class SuggestedKeywordResult extends Message<SuggestedKeywordResult> {
  /**
   * @generated from field: string pattern = 1;
   */
  pattern = "";

  /**
   * @generated from field: repeated string results = 3;
   */
  results: string[] = [];

  constructor(data?: PartialMessage<SuggestedKeywordResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.SuggestedKeywordResult";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pattern", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "results", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SuggestedKeywordResult {
    return new SuggestedKeywordResult().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SuggestedKeywordResult {
    return new SuggestedKeywordResult().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SuggestedKeywordResult {
    return new SuggestedKeywordResult().fromJsonString(jsonString, options);
  }

  static equals(a: SuggestedKeywordResult | PlainMessage<SuggestedKeywordResult> | undefined, b: SuggestedKeywordResult | PlainMessage<SuggestedKeywordResult> | undefined): boolean {
    return proto3.util.equals(SuggestedKeywordResult, a, b);
  }
}

/**
 * ScheduledKeyword is a keyword that's been generated.
 *
 * @generated from message nozzle.workspaces.v1.ScheduledKeyword
 */
export class ScheduledKeyword extends Message<ScheduledKeyword> {
  /**
   * The keyword to be scheduled.
   *
   * @generated from field: nozzle.keywords.v1.Keyword keyword = 1;
   */
  keyword?: Keyword;

  /**
   * @generated from field: repeated nozzle.workspaces.v1.KeywordGroup keyword_groups = 2;
   */
  keywordGroups: KeywordGroup[] = [];

  /**
   * @generated from field: nozzle.workspaces.v1.Schedule most_frequent_schedule = 3;
   */
  mostFrequentSchedule?: Schedule;

  /**
   * @generated from field: repeated google.protobuf.Timestamp times = 4;
   */
  times: Timestamp[] = [];

  /**
   * @generated from field: int32 serp_count_30_days = 5;
   */
  serpCount30Days = 0;

  /**
   * @generated from field: int32 serp_count_365_days = 6;
   */
  serpCount365Days = 0;

  /**
   * phrase_template is the template used to generate the keyword.
   *
   * @generated from field: string phrase_template = 7;
   */
  phraseTemplate = "";

  /**
   * model_sources is the list of models used to generate the keyword.
   *
   * @generated from field: repeated nozzle.workspaces.v1.ModelSource model_sources = 8;
   */
  modelSources: ModelSource[] = [];

  /**
   * locale_alias is the alias of the locale used to generate the keyword.
   *
   * @generated from field: string locale_alias = 9;
   */
  localeAlias = "";

  constructor(data?: PartialMessage<ScheduledKeyword>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.ScheduledKeyword";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "keyword", kind: "message", T: Keyword },
    { no: 2, name: "keyword_groups", kind: "message", T: KeywordGroup, repeated: true },
    { no: 3, name: "most_frequent_schedule", kind: "message", T: Schedule },
    { no: 4, name: "times", kind: "message", T: Timestamp, repeated: true },
    { no: 5, name: "serp_count_30_days", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "serp_count_365_days", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "phrase_template", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "model_sources", kind: "message", T: ModelSource, repeated: true },
    { no: 9, name: "locale_alias", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ScheduledKeyword {
    return new ScheduledKeyword().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ScheduledKeyword {
    return new ScheduledKeyword().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ScheduledKeyword {
    return new ScheduledKeyword().fromJsonString(jsonString, options);
  }

  static equals(a: ScheduledKeyword | PlainMessage<ScheduledKeyword> | undefined, b: ScheduledKeyword | PlainMessage<ScheduledKeyword> | undefined): boolean {
    return proto3.util.equals(ScheduledKeyword, a, b);
  }
}

/**
 * ModelSource is the originating model for a keyword.
 *
 * @generated from message nozzle.workspaces.v1.ModelSource
 */
export class ModelSource extends Message<ModelSource> {
  /**
   * @generated from field: string model = 1;
   */
  model = "";

  /**
   * @generated from field: string value = 2;
   */
  value = "";

  /**
   * @generated from field: string alias = 3;
   */
  alias = "";

  constructor(data?: PartialMessage<ModelSource>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.ModelSource";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "model", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "alias", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModelSource {
    return new ModelSource().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModelSource {
    return new ModelSource().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModelSource {
    return new ModelSource().fromJsonString(jsonString, options);
  }

  static equals(a: ModelSource | PlainMessage<ModelSource> | undefined, b: ModelSource | PlainMessage<ModelSource> | undefined): boolean {
    return proto3.util.equals(ModelSource, a, b);
  }
}

/**
 * KeywordGroup is group for a keyword.
 *
 * @generated from message nozzle.workspaces.v1.KeywordGroup
 */
export class KeywordGroup extends Message<KeywordGroup> {
  /**
   * @generated from field: string group = 1;
   */
  group = "";

  constructor(data?: PartialMessage<KeywordGroup>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.KeywordGroup";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "group", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): KeywordGroup {
    return new KeywordGroup().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): KeywordGroup {
    return new KeywordGroup().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): KeywordGroup {
    return new KeywordGroup().fromJsonString(jsonString, options);
  }

  static equals(a: KeywordGroup | PlainMessage<KeywordGroup> | undefined, b: KeywordGroup | PlainMessage<KeywordGroup> | undefined): boolean {
    return proto3.util.equals(KeywordGroup, a, b);
  }
}

/**
 * KeywordSource is a config for generation keywords.
 *
 * @generated from message nozzle.workspaces.v1.KeywordSource
 */
export class KeywordSource extends Message<KeywordSource> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: int64 version_id = 16;
   */
  versionId = protoInt64.zero;

  /**
   * @generated from field: int64 workspace_id = 2;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: int64 project_id = 3;
   */
  projectId = protoInt64.zero;

  /**
   * @generated from field: string name = 4;
   */
  name = "";

  /**
   * @generated from field: string description = 5;
   */
  description = "";

  /**
   * @generated from oneof nozzle.workspaces.v1.KeywordSource.config
   */
  config: {
    /**
     * @generated from field: nozzle.workspaces.v1.KeywordSourceDataModelConfig data_model_config = 6;
     */
    value: KeywordSourceDataModelConfig;
    case: "dataModelConfig";
  } | {
    /**
     * @generated from field: nozzle.workspaces.v1.KeywordSourceDataModelConfig2 keyword_source_config = 20;
     */
    value: KeywordSourceDataModelConfig2;
    case: "keywordSourceConfig";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: nozzle.workspaces.v1.KeywordSourceConfigType type = 15;
   */
  type = KeywordSourceConfigType.UNSPECIFIED;

  /**
   * @generated from field: repeated nozzle.workspaces.v1.Schedule schedules = 7;
   */
  schedules: Schedule[] = [];

  /**
   * @generated from field: int64 keyword_count = 8;
   */
  keywordCount = protoInt64.zero;

  /**
   * @generated from field: int64 unique_phrase_count = 12;
   */
  uniquePhraseCount = protoInt64.zero;

  /**
   * @generated from field: int64 unique_locale_count = 13;
   */
  uniqueLocaleCount = protoInt64.zero;

  /**
   * @generated from field: int64 unique_device_count = 14;
   */
  uniqueDeviceCount = protoInt64.zero;

  /**
   * serp counts
   *
   * @generated from field: int64 serp_count_30_days = 18;
   */
  serpCount30Days = protoInt64.zero;

  /**
   * @generated from field: int64 serp_count_365_days = 19;
   */
  serpCount365Days = protoInt64.zero;

  /**
   * @generated from field: nozzle.workspaces.v1.KeywordSourceState state = 17;
   */
  state = KeywordSourceState.UNSPECIFIED;

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 9;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 10;
   */
  updatedAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp deleted_at = 11;
   */
  deletedAt?: Timestamp;

  constructor(data?: PartialMessage<KeywordSource>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.KeywordSource";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 16, name: "version_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "project_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "data_model_config", kind: "message", T: KeywordSourceDataModelConfig, oneof: "config" },
    { no: 20, name: "keyword_source_config", kind: "message", T: KeywordSourceDataModelConfig2, oneof: "config" },
    { no: 15, name: "type", kind: "enum", T: proto3.getEnumType(KeywordSourceConfigType) },
    { no: 7, name: "schedules", kind: "message", T: Schedule, repeated: true },
    { no: 8, name: "keyword_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 12, name: "unique_phrase_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 13, name: "unique_locale_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 14, name: "unique_device_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 18, name: "serp_count_30_days", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 19, name: "serp_count_365_days", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 17, name: "state", kind: "enum", T: proto3.getEnumType(KeywordSourceState) },
    { no: 9, name: "created_at", kind: "message", T: Timestamp },
    { no: 10, name: "updated_at", kind: "message", T: Timestamp },
    { no: 11, name: "deleted_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): KeywordSource {
    return new KeywordSource().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): KeywordSource {
    return new KeywordSource().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): KeywordSource {
    return new KeywordSource().fromJsonString(jsonString, options);
  }

  static equals(a: KeywordSource | PlainMessage<KeywordSource> | undefined, b: KeywordSource | PlainMessage<KeywordSource> | undefined): boolean {
    return proto3.util.equals(KeywordSource, a, b);
  }
}

/**
 * KeywordSourceDataModelConfig is a config for generating keywords from a data model.
 *
 * @generated from message nozzle.workspaces.v1.KeywordSourceDataModelConfig
 */
export class KeywordSourceDataModelConfig extends Message<KeywordSourceDataModelConfig> {
  /**
   * @generated from field: nozzle.workspaces.v1.DataModel ad_hoc_data_model = 1;
   */
  adHocDataModel?: DataModel;

  /**
   * The data model to use for generating phrases.
   *
   * @generated from field: nozzle.workspaces.v1.KeywordSourceDataModel keyword_source_data_model = 2;
   */
  keywordSourceDataModel?: KeywordSourceDataModel;

  /**
   * The phrase template to use for generating phrases.
   *
   * @generated from field: repeated nozzle.workspaces.v1.PhraseTemplate phrase_templates = 3;
   */
  phraseTemplates: PhraseTemplate[] = [];

  /**
   * @generated from field: repeated nozzle.workspaces.v1.LocaleModel locale_models = 4;
   */
  localeModels: LocaleModel[] = [];

  /**
   * @generated from field: repeated nozzle.workspaces.v1.DeviceModel device_models = 5;
   */
  deviceModels: DeviceModel[] = [];

  constructor(data?: PartialMessage<KeywordSourceDataModelConfig>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.KeywordSourceDataModelConfig";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ad_hoc_data_model", kind: "message", T: DataModel },
    { no: 2, name: "keyword_source_data_model", kind: "message", T: KeywordSourceDataModel },
    { no: 3, name: "phrase_templates", kind: "message", T: PhraseTemplate, repeated: true },
    { no: 4, name: "locale_models", kind: "message", T: LocaleModel, repeated: true },
    { no: 5, name: "device_models", kind: "message", T: DeviceModel, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): KeywordSourceDataModelConfig {
    return new KeywordSourceDataModelConfig().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): KeywordSourceDataModelConfig {
    return new KeywordSourceDataModelConfig().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): KeywordSourceDataModelConfig {
    return new KeywordSourceDataModelConfig().fromJsonString(jsonString, options);
  }

  static equals(a: KeywordSourceDataModelConfig | PlainMessage<KeywordSourceDataModelConfig> | undefined, b: KeywordSourceDataModelConfig | PlainMessage<KeywordSourceDataModelConfig> | undefined): boolean {
    return proto3.util.equals(KeywordSourceDataModelConfig, a, b);
  }
}

/**
 * KeywordSourceDataModelConfig2 is a config for generating keywords from a data model.
 *
 * @generated from message nozzle.workspaces.v1.KeywordSourceDataModelConfig2
 */
export class KeywordSourceDataModelConfig2 extends Message<KeywordSourceDataModelConfig2> {
  /**
   * The data model to use for generating phrases.
   *
   * @generated from field: nozzle.workspaces.v1.DataModel2 keyword_source_data_model = 1;
   */
  keywordSourceDataModel?: DataModel2;

  /**
   * The phrase template to use for generating phrases.
   *
   * @generated from field: repeated nozzle.workspaces.v1.PhraseTemplate phrase_templates = 2;
   */
  phraseTemplates: PhraseTemplate[] = [];

  /**
   * @generated from field: repeated nozzle.workspaces.v1.LocaleModel locale_models = 3;
   */
  localeModels: LocaleModel[] = [];

  /**
   * @generated from field: repeated nozzle.workspaces.v1.DeviceModel device_models = 4;
   */
  deviceModels: DeviceModel[] = [];

  constructor(data?: PartialMessage<KeywordSourceDataModelConfig2>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.KeywordSourceDataModelConfig2";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "keyword_source_data_model", kind: "message", T: DataModel2 },
    { no: 2, name: "phrase_templates", kind: "message", T: PhraseTemplate, repeated: true },
    { no: 3, name: "locale_models", kind: "message", T: LocaleModel, repeated: true },
    { no: 4, name: "device_models", kind: "message", T: DeviceModel, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): KeywordSourceDataModelConfig2 {
    return new KeywordSourceDataModelConfig2().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): KeywordSourceDataModelConfig2 {
    return new KeywordSourceDataModelConfig2().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): KeywordSourceDataModelConfig2 {
    return new KeywordSourceDataModelConfig2().fromJsonString(jsonString, options);
  }

  static equals(a: KeywordSourceDataModelConfig2 | PlainMessage<KeywordSourceDataModelConfig2> | undefined, b: KeywordSourceDataModelConfig2 | PlainMessage<KeywordSourceDataModelConfig2> | undefined): boolean {
    return proto3.util.equals(KeywordSourceDataModelConfig2, a, b);
  }
}

