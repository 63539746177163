import { useSearch } from '@tanstack/react-router'
import { createColumnHelper } from '@tanstack/react-table'
import moment from 'moment'
import * as React from 'react'
import { FaAngleRight, FaInfoCircle, FaPlus } from 'react-icons/fa'
import Caption from '../../components/Caption'
import Card from '../../components/Card'
import Clickable from '../../components/Clickable'
import Link from '../../components/Link'
import QueryGate from '../../components/QueryGate'
import Table from '../../components/Table'
import Tooltip from '../../components/Tooltip'
import { useBrands, useRemoveBrandById } from '../../hooks/brands'
import useConfirm from '../../hooks/useConfirm'
import { useTable } from '../../hooks/useTable'
import useZustand from '../../hooks/useZustand'
import { formatBrandType } from '../../utils/Format'
import { ButtonPlain } from '../../components/ButtonPlain'
import { ProjectPicker } from '../../components/ProjectPicker'
import { Brand } from '../../../openapi'
import { Head } from '../../components/Head'
import { LocationGenerics } from '../../LocationGenerics'
import { useActiveProjectIdState } from '../../utils/searchParams'

//

export const brandsLabel = <div className="text-xl font-bold">Brands</div>
export const backToBrands = <Link to="/brands">{brandsLabel}</Link>

const columnHelper = createColumnHelper<Brand>()

export function Brands() {
  const projectId = useActiveProjectIdState().state
  const brandsQuery = useBrands({ projectId })
  const confirm = useConfirm()
  const removeBrandById_ = useRemoveBrandById()

  const removeBrandById = React.useCallback(
    async (id: string) => {
      const confirmed = await confirm({
        color: 'red-500',
        message: 'This Brand will be deleted!',
        subMessage: 'Brands can be restored after they have been deleted.',
      })
      if (confirmed) {
        await removeBrandById_.mutateAsync(id)
      }
    },
    [confirm, removeBrandById_]
  )

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Brand Name',
        filterFn: 'fuzzy',
        cell: props => (
          <Link to={`/brands/${props.row.original.id}`}>
            {props.getValue()}
          </Link>
        ),
      }),
      columnHelper.accessor(d => formatBrandType(d.type), {
        header: 'Type',
        filterFn: 'arrIncludesSome',
        meta: {
          showQuickFilter: true,
        },
      }),
      columnHelper.accessor(
        row => (row.properties ? row.properties.length : 0),
        {
          header: 'Properties',
        }
      ),
      columnHelper.accessor('id', {
        header: 'ID',
      }),
      columnHelper.accessor('updatedAt', {
        header: 'Last Updated',
        meta: { tight: true },
        cell: props => {
          const value = props.getValue()
          return (
            <Tooltip tooltip={moment(value).format('lll')}>
              {moment(value).fromNow()}
            </Tooltip>
          )
        },
      }),
      columnHelper.accessor('createdAt', {
        header: 'Date Created',
        meta: { tight: true },
        cell: props => {
          const value = props.getValue()
          return (
            <Tooltip tooltip={moment(value).format('lll')}>
              {moment(value).fromNow()}
            </Tooltip>
          )
        },
      }),
    ],
    [removeBrandById]
  )

  const tableData = React.useMemo(
    () => brandsQuery.data ?? [],
    [brandsQuery.data]
  )

  const table = useTable({
    data: tableData,
    columns,
    initialState: {
      pagination: {
        pageSize: 20,
      },
      sorting: [{ id: 'name', desc: false }],
    },
  })

  return (
    <div className="space-y-2 p-2">
      <Head>
        <title>Brands</title>
      </Head>
      <Card className="flex flex-wrap items-center gap-2">
        <ProjectPicker />
        <FaAngleRight />
        {brandsLabel}
      </Card>
      <Card className="divide-y divide-gray-500/20 p-0">
        <div className="flex items-center justify-between gap-4 p-2">
          <Link to="/brands/new">
            <ButtonPlain className="bg-green-500">
              <FaPlus className="inline" /> Add Brand
            </ButtonPlain>
          </Link>
          <BrandHelp />
        </div>
        <QueryGate query={brandsQuery} className="p-2">
          {() => (
            <>
              {!tableData?.length ? (
                <div className="p-4 italic opacity-50">
                  No brands have been created yet.
                </div>
              ) : (
                <Table table={table} />
              )}
            </>
          )}
        </QueryGate>
      </Card>
    </div>
  )
}

export function BrandHelp() {
  const [, setStore] = useZustand(state => state.helpUrl)

  const openBrandDocs = () => {
    setStore(old => {
      old.showHelp = true
      old.helpUrl = 'https://help.nozzle.io/how-do-i-add-brands'
    })
  }

  return (
    <Tooltip
      tooltip={
        <>
          Brands are used to track companies, products or any other entities on
          the web against your keywords. While most SEO tools allow you to track
          only one domain, Nozzle supports tracking an unlimited number of
          brands each comprised of an unlimited number of domains, urls, social
          profiles, etc. For example, we could use a single brand to track
          Taylor Swift's website, Twitter and Instagram profiles, YouTube
          videos, album reviews and even fan merch links, all at the same time.{' '}
          <span className="underline">Click to learn more</span>
        </>
      }
    >
      <Clickable onClick={openBrandDocs}>
        <Caption className="flex items-center gap-1">
          <FaInfoCircle className="inline" /> What is a brand?
        </Caption>
      </Clickable>
    </Tooltip>
  )
}
