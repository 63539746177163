import * as React from 'react'

declare module 'react' {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null
}

export function withProps<
  TComp extends keyof JSX.IntrinsicElements | React.ComponentType<any>
>(component: TComp) {
  return function <TGetterPropsIn extends Record<string, any>>(
    getProps: (
      props: React.ComponentPropsWithoutRef<TComp> & TGetterPropsIn
    ) => React.ComponentPropsWithRef<TComp>
  ) {
    const key = (
      typeof component === 'function' ? component.name : component
    ) as string

    const func = {
      [key]: (
        {
          children,
          ...rest
        }: React.ComponentPropsWithoutRef<TComp> & TGetterPropsIn,
        ref: React.ForwardedRef<
          React.ComponentPropsWithRef<TComp>['ref']['current']
        >
      ) => {
        const finalProps = getProps(rest as any)
        return React.createElement(
          component,
          {
            ...finalProps,
            ref,
          },
          children
        )
      },
    } as const

    return React.forwardRef(func[key] as (typeof func)[string])
  }
}

// // You can use withProps to compose default (or overridden) props to a component

// // Add a default className + any className the user provides
// const Paragraph = withProps('p')(props => ({
//   // This prop composition function must either override or compose together the props during usage
//   className: twMerge(`p-2`, props.className),
// }))

// function Example1() {
//   return (
//     <Paragraph
//       // Our className prop will get composed into the default className
//       className="p-4"
//       // We also have access to all of the normal 'p' attributes
//       onClick={void 0}
//     />
//   )
// }

// // Optionally, you can pass props and/or a ref to the prop composition function
// const Link = withProps('a')<
//   // These are additional props available in your function below. They also show up on the component when used. (See Usage)
//   { isActive?: boolean },
//   // This is the ref type that you can pass to the component
//   HTMLAnchorElement
// >(props => ({
//   // Notice that we are using the isActive prop!
//   className: twMerge(props.isActive ? 'text-blue-500' : '', props.className),
// }))

// function Example2() {
//   const ref = React.useRef<HTMLAnchorElement>(null)
//   return <Link ref={ref} isActive />
// }
