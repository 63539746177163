import {
  DataModel2Pb,
  DataModelEntryPb,
  DataModelEntryValuePb,
  PlainMessage,
  SchedulePb,
} from './proto'

export type DataModel = Omit<PlainMessage<DataModel2Pb>, 'entries'> & {
  entries: DataModelEntry[]
}

export type DataModelEntry = {
  name: string
  values: DataModelEntryValue[]
  children?: DataModelEntry[]
  path: string[]
}

export type DataModelEntryValue = {
  value: string
  mostFrequentScheduleId: string | undefined
  keywordGroups: string[]
}

export function dataModelFromPb(dataModel: DataModel2Pb): DataModel {
  return {
    ...dataModel,
    entries: dataModel.entries.map(d => dataModelEntryFromPb(d)),
  }
}

export function dataModelEntryFromPb(
  entry: DataModelEntryPb,
  pathArr: string[] = []
) {
  const path = [...pathArr, entry.name]

  const row: DataModelEntry = {
    name: entry.name,
    values: entry.values.map(d => dataModelEntryValueFromPb(d)),
    path,
    children: [],
  }

  row.children = entry.children
    ? entry.children.map(d => dataModelEntryFromPb(d, path))
    : []

  return row
}

export function dataModelEntryValueFromPb(
  value: DataModelEntryValuePb
): DataModelEntryValue {
  return {
    value: value.value,
    mostFrequentScheduleId: value.mostFrequentSchedule?.rrule,
    keywordGroups: value.keywordGroups,
  }
}

export function dataModelEntryToPb(d: DataModelEntry): DataModelEntryPb {
  return new DataModelEntryPb({
    name: d.name,
    values: d.values.map(d => dataModelEntryValueToPb(d)),
    children: d.children?.map(d => dataModelEntryToPb(d)),
  })
}

export function dataModelEntryValueToPb(
  d: DataModelEntryValue
): DataModelEntryValuePb {
  return new DataModelEntryValuePb({
    value: d.value,
    keywordGroups: d.keywordGroups,
    mostFrequentSchedule: new SchedulePb({
      rrule: d.mostFrequentScheduleId,
    }),
  })
}
