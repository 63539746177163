import moment from 'moment'
import { twMerge } from 'tailwind-merge'
import * as React from 'react'
import * as d3 from 'd3'
import * as Plot from '@observablehq/plot'
import {
  FaAngleRight,
  FaCircle,
  FaExclamationTriangle,
  FaExternalLinkAlt,
  FaInfoCircle,
  FaRegCircle,
} from 'react-icons/fa'
import { useQuery } from 'react-query'
import { Dashboard } from '.'
import Card from '../../components/Card'
import DashboardContainer from '../../components/DashboardContainer'
import DashboardContent from '../../components/DashboardContent'
import DashboardHeader from '../../components/DashboardHeader'
import DashboardKeywordGroupGate from '../../components/DashboardKeywordGroupGate'
import DashboardKeywordPicker from '../../components/DashboardKeywordPicker'
import SegmentPicker from '../../components/SegmentPicker'
import DashboardTeamGate from '../../components/DashboardTeamGate'
import DashboardTimeRange from '../../components/DashboardTimeRange'
import ErrorBoundary from '../../components/ErrorBoundary'
import Head from '../../components/Head'
import Loader from '../../components/Loader'
import NoData from '../../components/NoData'
import { SerpHtml } from '../../components/SerpHtml'
import {
  SerpResultsTables,
  SerpWidgetState,
  resultColumnGroupOptions,
} from '../../components/SerpResultsTables'
import Tooltip from '../../components/Tooltip'
import useTimeRangeState from '../../hooks/useTimeRanges'
import useZustand from '../../hooks/useZustand'
import { useActiveWorkspaceId } from '../../hooks/workspaces'
import { Updater, functionalUpdate, last, sortBy } from '../../utils'
import { fetchPulls, fetchSerps } from '../../utils/Api'
import {
  UTC_FORMAT,
  reputationManagementKeywordSlug,
  queryKeyKeywordPulls,
  queryKeyReportsKeywordSerp,
  BRAND_PROPERTY_TYPE_DOMAIN,
  BRAND_PROPERTY_TYPE_SUBDOMAIN,
  BRAND_PROPERTY_TYPE_URL,
  BRAND_PROPERTY_TYPE_INSTAGRAM,
  BRAND_PROPERTY_TYPE_PINTEREST,
  BRAND_PROPERTY_TYPE_TWITTER,
  BRAND_PROPERTY_TYPE_FACEBOOK,
  BRAND_PROPERTY_TYPE_LINKEDIN,
  BRAND_PROPERTY_TYPE_YOUTUBE_VIDEO,
} from '../../utils/Constants'
import { MetricId } from '../../utils/Metrics'
import { ProjectPicker } from '../../components/ProjectPicker'
import { ObservablePlot } from '../../components/ObservablePlot'
import { WidgetStateResetter } from '../../components/WidgetStateResetter'
import WidgetControlsToggle from '../../components/WidgetControlsToggle'
import { NumberInputWrap } from '../../components/NumberInputWrap'
import NumberInput from '../../components/NumberInput'
import {
  dataColors,
  dataColorsList,
  getRankColor,
} from '../../utils/DataColors'
import Anchor from '../../components/Anchor'
import { useBrands } from '../../hooks/brands'
import { Brand, BrandProperty } from '../../../openapi'
import { Tab, Tabs } from '../../components/Tabs'
import { propertyTypes } from '../brands/BrandForm'
import {
  useActiveProjectIdState,
  useKeywordIdState,
} from '../../utils/searchParams'
import { useSearchState } from '../../components/useSearchState'

//

const title = 'Reputation Management - Keyword'

export const metricIds: MetricId[] = [
  // 'unique_keywords',
  'top_rank',
  'click_through_rate',
  'estimated_traffic',
  'top_pixels_from_top',
  'percentage_of_viewport',
  'percentage_of_dom',
  'results',
  'unique_urls',
  'ppc_value',
  'top_stat_base_rank',
]

export const reputationKeywordDashboard: Dashboard = {
  name: 'Keyword',
  id: reputationManagementKeywordSlug,
  icon: <FaCircle className="inline-block scale-75 transform text-xs" />,
}

export default function ReputationSingleKeyword() {
  const keywordIdState = useKeywordIdState()

  const dashboardReadyGate = !keywordIdState.state
    ? 'Please select a keyword.'
    : null

  const [, setStore] = useZustand(state => state.helpUrl)
  const openDashDocs = () => {
    setStore(old => {
      old.showHelp = true
      old.helpUrl =
        'https://help.nozzle.io/performance-single-keyword-dashboard'

      return old
    })
  }

  return (
    <DashboardContainer>
      <Head>
        <title>{title} | Dashboards | Nozzle</title>
      </Head>
      <Card className="divide-y divide-gray-500/20 p-0">
        <div className="p-2">
          <DashboardHeader right={<DashboardTimeRange />}>
            <div className="flex flex-wrap items-center gap-2">
              <ProjectPicker />
              {/* <RollupPicker allowDomainsAndUrls /> */}
              <FaAngleRight className="inline" />
              <span>{title}</span>
              <button onClick={openDashDocs} type="button">
                <Tooltip tooltip="Click here to learn more about this dashboard">
                  <FaInfoCircle className="text-sm text-blue-500" />
                </Tooltip>
              </button>
            </div>
          </DashboardHeader>
        </div>
        <div className="flex flex-wrap gap-4 p-2">
          <div className="flex flex-1 items-center gap-2">
            <SegmentPicker />
          </div>
        </div>
        <div className="flex flex-wrap gap-4 p-2">
          <div className="flex flex-1 items-center gap-2">
            <DashboardKeywordPicker />
          </div>
        </div>
      </Card>
      <DashboardTeamGate>
        <DashboardKeywordGroupGate>
          <DashboardContent>
            {dashboardReadyGate ? (
              <div className="col-span-full">
                <Card className="flex items-center gap-2 text-sm">
                  <FaInfoCircle className="text-blue-500" />{' '}
                  {dashboardReadyGate}
                </Card>
              </div>
            ) : (
              <>
                <div className="col-span-full">
                  <ErrorBoundary>
                    <History />
                  </ErrorBoundary>
                </div>
                <div className="col-span-full">
                  <ErrorBoundary>
                    <SerpsWidget />
                  </ErrorBoundary>
                </div>
              </>
            )}
          </DashboardContent>
        </DashboardKeywordGroupGate>
      </DashboardTeamGate>
    </DashboardContainer>
  )
}

type ReputationSingleKeywordHistoryWidget = {
  limit: number
  samples: number
  mode: 'reputation' | 'rank'
}

const useHistoryWidgetState = () => {
  return useSearchState<ReputationSingleKeywordHistoryWidget>({
    path: 'reputationSingleKeywordHistoryWidget',
    useDefaultValue: () => {
      return {
        limit: 30,
        samples: 100,
        mode: 'reputation',
      }
    },
  })
}

function History() {
  const workspaceId = useActiveWorkspaceId()
  const projectIdState = useActiveProjectIdState()
  const timeRangeState = useTimeRangeState()
  const keywordIdState = useKeywordIdState()

  const brandsQuery = useBrands({
    workspaceId,
    projectId: projectIdState.state,
  })

  const widgetState = useHistoryWidgetState()

  // A function to get the property for a given url
  const getUrlProperty = React.useCallback(
    (url: string) => {
      const lowerUrl = url.toLowerCase()
      let property: undefined | (BrandProperty & { brand: Brand })

      const brand = brandsQuery.data?.find(brand => {
        const p = brand.properties.find(property => {
          const lowerPropertyValue = property.value?.toLowerCase()

          if (property.type === BRAND_PROPERTY_TYPE_DOMAIN) {
            return lowerUrl.toLowerCase().includes(lowerPropertyValue)
          } else if (property.type === BRAND_PROPERTY_TYPE_SUBDOMAIN) {
            return lowerUrl.toLowerCase().includes(lowerPropertyValue)
          } else if (property.type === BRAND_PROPERTY_TYPE_URL) {
            return lowerUrl.startsWith(lowerPropertyValue)
          } else if (
            [
              BRAND_PROPERTY_TYPE_INSTAGRAM,
              BRAND_PROPERTY_TYPE_PINTEREST,
              BRAND_PROPERTY_TYPE_TWITTER,
              BRAND_PROPERTY_TYPE_FACEBOOK,
              BRAND_PROPERTY_TYPE_LINKEDIN,
              BRAND_PROPERTY_TYPE_YOUTUBE_VIDEO,
            ].includes(property.type)
          ) {
            const foundPropertyType = propertyTypes.find(
              d => d.type === property.type
            )!

            const condition =
              foundPropertyType.createCondition?.(lowerPropertyValue)

            if (condition) {
              return lowerUrl.startsWith(condition.values?.[0])
            }
          }
        })

        if (p) {
          property = p as any
          return true
        }
      })

      if (property) {
        property.brand = brand!
      }

      return property
    },
    [brandsQuery.data]
  )

  const limit = widgetState.state.limit

  const params = {
    workspaceId,
    keywordId: keywordIdState.state,
    start: moment.unix(timeRangeState.state[0]!.start).utc().format(UTC_FORMAT),
    end: moment
      .unix(timeRangeState.state[0]!.end)
      .utc()
      .endOf('day')
      .format(UTC_FORMAT),
  }

  const rankingsQuery = useQuery(
    [queryKeyKeywordPulls, params],
    () => fetchPulls(params),
    {
      staleTime: Infinity,
    }
  )

  const keywordSerpsQuery = useQuery({
    queryKey: [
      queryKeyReportsKeywordSerp,
      rankingsQuery.data?.map(pull => pull.rankingId),
    ],
    queryFn: () =>
      Promise.all(
        rankingsQuery.data?.map(async pull => {
          const rankingParams = pull.rankingId && {
            workspaceId,
            rankingId: pull.rankingId,
          }

          return fetchSerps(rankingParams)
        }) ?? []
      ),
    staleTime: Infinity,
  })

  const isFetching = rankingsQuery.isFetching || keywordSerpsQuery.isFetching

  const [hoveredUrl, setHoveredUrl] = React.useState<string | null>(null)
  const [clickedRequested, setClickedRequested] = React.useState<string | null>(
    null
  )

  const getTitleFromResult = (result: any) => {
    return (
      result?.url?.url ||
      result?.title?.text ||
      (result?.product?.is_product ? 'Product' : `Unknown Rank`)
    )
  }

  const serps = React.useMemo(
    () => (keywordSerpsQuery.data ?? []).map(d => d.ranking),
    [keywordSerpsQuery.data]
  )

  // Get all of the unique requested dates
  const requestedValues = React.useMemo(
    () => d3.sort(new Set(serps?.map(d => d?.requested))),
    [serps]
  )

  // Get the number of samples to show
  const samples = Math.min(
    Math.max(2, widgetState.state.samples ?? 100),
    requestedValues.length
  )

  const sampledRequestedIndexes = React.useMemo(() => {
    if (!requestedValues.length) {
      return []
    }
    const step = Math.floor((requestedValues.length - 2) / (samples - 2))
    const sampledRequestedIndexes = [0]
    if (requestedValues.length > 2) {
      for (let i = step; i < requestedValues.length - 1; i += step) {
        sampledRequestedIndexes.push(i)
      }
    }
    if (requestedValues.length > 1) {
      sampledRequestedIndexes.push(requestedValues.length - 1)
    }
    return sampledRequestedIndexes
  }, [requestedValues, samples])

  const sampledRequestedValues = React.useMemo(() => {
    return sampledRequestedIndexes.map(i => requestedValues[i])
  }, [requestedValues, sampledRequestedIndexes])

  // Only include the days we sampled
  const sampledSerps = React.useMemo(() => {
    return sampledRequestedValues.map(requested =>
      serps.find(d => d.requested === requested)
    )
  }, [sampledRequestedValues, serps])

  // Get the resolved clicked requested value
  let resolvedClickedRequested = React.useMemo(
    () => clickedRequested || last(requestedValues),
    [clickedRequested, requestedValues]
  )

  const sampledDatums = React.useMemo(() => {
    return sampledSerps.flatMap(serp => {
      // Filter out results outside of our limit
      const noItemRankResults = serp.results.filter(result =>
        result.item_rank >= 1 ? false : true
      )

      return d3
        .sort(noItemRankResults, result => result.rank)
        .slice(0, limit)
        .map(result => ({
          requested: serp.requested,
          result,
        }))
    })
  }, [limit, sampledSerps])

  const [urlSeries, foundReputation] = React.useMemo(() => {
    let foundReputation = false

    return [
      [
        ...d3.group(sampledDatums, d => getTitleFromResult(d.result)).entries(),
      ].map(([url, data]) => {
        data = sampledRequestedValues.map(requested => {
          const found = data.find(d => d.requested === requested)
          return (
            found || {
              requested,
              result: null,
            }
          )
        })

        const focusedResult = data.find(
          d => d.requested === resolvedClickedRequested
        )

        const color =
          getRankColor(focusedResult?.result?.rank, limit) || dataColors.red

        const property = getUrlProperty(url)

        if (
          typeof property?.brand.reputationImpact === 'number' &&
          property?.brand.reputationImpact !== 0
        ) {
          foundReputation = true
        }

        return {
          url,
          property,
          color,
          data,
        }
      }),
      foundReputation,
    ] as const
  }, [
    sampledDatums,
    sampledRequestedValues,
    limit,
    getUrlProperty,
    resolvedClickedRequested,
  ])

  // const seriesByUrl = React.useMemo(() => {
  //   return Object.fromEntries(urlSeries.map(d => [d.url, d])) as Record<
  //     string,
  //     (typeof urlSeries)[0]
  //   >
  // }, [urlSeries])

  const mode = foundReputation ? widgetState.state.mode : 'rank'

  resolvedClickedRequested = React.useMemo(
    () => clickedRequested || last(requestedValues),
    [clickedRequested, requestedValues]
  )

  const [showControls, toggleControls] = React.useReducer(
    (state, action = !state) => action,
    false
  )

  const activeResultsByUrl = React.useMemo(() => {
    return sortBy(
      urlSeries,
      d =>
        d.data?.find(d => d?.requested === resolvedClickedRequested)?.result
          ?.rank
    ).slice(0, limit)
  }, [limit, resolvedClickedRequested, urlSeries])

  const plotOptions = React.useMemo(() => {
    if (!sampledDatums.length) {
      return {}
    }

    return {
      color: {
        label: 'URL',
        range:
          mode === 'reputation'
            ? [
                dataColors.red,
                dataColors.orange,
                dataColors.gray,
                dataColors.greenAlt,
                dataColors.blue,
              ]
            : dataColorsList,
        domain: mode === 'reputation' ? [-2, -1, 0, 1, 2] : undefined,
      },
      x: {
        label: 'Date',
        type: 'time',
      },
      y: {
        label: 'Rank',
        reverse: true,
      },
      marks: [
        Plot.gridX({
          strokeOpacity: 0.02,
        }),
        Plot.gridY({
          strokeOpacity: 0.05,
        }),
        Plot.crosshair(sampledDatums, {
          x: d => new Date(d?.requested),
          y: d => d?.result?.rank,
          // channels: {
          //   'Item Rank': d => d?.result.item_rank,
          // },
          ruleStrokeWidth: 1,
        }),
        Plot.ruleX([new Date(resolvedClickedRequested || 0)], {
          stroke: '#88888870',
          strokeWidth: 6,
        }),
        // Plot.ruleY(Plot.pointer),
        ...urlSeries.flatMap(({ url, color, data, property }) => {
          const isActive = url === hoveredUrl
          const isInactive = hoveredUrl && url !== hoveredUrl
          return [
            Plot.lineY(data, {
              x: d => new Date(d?.requested),
              y: d => d?.result?.rank ?? null,
              stroke:
                mode === 'reputation'
                  ? property?.brand.reputationImpact ?? 0
                  : color || '#88888850',
              strokeOpacity:
                mode === 'reputation' && !property?.brand.reputationImpact
                  ? isInactive
                    ? 0.1
                    : isActive
                    ? 1
                    : 0.2
                  : isInactive
                  ? 0.8
                  : 1,
              strokeWidth: isActive ? 4 : isInactive ? 1.5 : 2,
              curve: d3.curveLinear,
              sort: isActive ? true : false,
            }),
            Plot.circle(data, {
              x: d => new Date(d?.requested),
              y: d => d?.result?.rank ?? null,
              fill:
                mode === 'reputation'
                  ? property?.brand.reputationImpact ?? 0
                  : color || '#88888850',
              fillOpacity:
                mode === 'reputation' && !property?.brand.reputationImpact
                  ? isInactive
                    ? 0.1
                    : isActive
                    ? 1
                    : 0.2
                  : isActive
                  ? 3
                  : isInactive
                  ? 0.8
                  : 1,
              r: isActive ? 3 : 2,
            }),
          ]
        }),
        Plot.tip(
          sampledDatums,
          Plot.pointer({
            x: d => new Date(d?.requested),
            y: d => d?.result?.rank,
            // stroke: d =>
            //   resultsByUrl.find(
            //     byUrl => byUrl[0] === getResultLabel(d.result)
            //   )[2] || '#88888850',
            channels: {
              URL: d => getTitleFromResult(d.result),
            },
          })
        ),
      ],
    }
  }, [hoveredUrl, mode, resolvedClickedRequested, sampledDatums, urlSeries])

  return (
    <Card className="divide-y divide-gray-500/20 p-0">
      <div className="flex items-center gap-2 p-2">
        <div className="font-bold">History by URL</div>
        <Tabs className="text-sm">
          <Tab
            disabled={!foundReputation}
            className={twMerge(mode === 'reputation' && 'active')}
            onClick={() => {
              widgetState.setState(prev => ({
                ...prev,
                mode: 'reputation',
              }))
            }}
          >
            Reputation
          </Tab>
          <Tab
            className={twMerge(mode === 'rank' && 'active')}
            onClick={() => {
              widgetState.setState(prev => ({
                ...prev,
                mode: 'rank',
              }))
            }}
          >
            Rank
          </Tab>
        </Tabs>
        <div className="mr-auto">{isFetching ? <Loader /> : null}</div>
        {!isFetching && !foundReputation ? (
          <div className="flex items-center gap-2 rounded-md bg-yellow-400/50 px-2 py-1 text-xs font-bold">
            <FaExclamationTriangle className="animate-pulse" /> No URLs with
            reputation impact were found in these results. Rank history and
            colors are being shown instead.
          </div>
        ) : null}
        <WidgetStateResetter
          {...{
            isDirty: widgetState.isDirty,
            onReset: widgetState.reset,
          }}
        />
        <WidgetControlsToggle
          showControls={showControls}
          onToggle={toggleControls}
        />
      </div>
      {showControls ? (
        <div className="flex flex-wrap gap-2 p-2">
          <NumberInputWrap label="Limit">
            <NumberInput
              enableDraft
              value={limit}
              onChange={num =>
                widgetState.setState(prev => ({
                  ...prev,
                  limit: num!,
                }))
              }
              min={1}
              max={100}
            />
          </NumberInputWrap>
          <NumberInputWrap label="Samples">
            <NumberInput
              enableDraft
              value={samples}
              onChange={num =>
                widgetState.setState(prev => ({
                  ...prev,
                  samples: num!,
                }))
              }
              min={2}
              max={1000}
            />
          </NumberInputWrap>
        </div>
      ) : null}
      {keywordSerpsQuery.isLoading ? (
        <div className="p-2">
          <Loader />
        </div>
      ) : (
        <div className="flex divide-x divide-gray-500/20">
          <div
            className="hidden divide-y divide-gray-500/20 overflow-x-hidden text-sm md:block"
            style={{
              width:
                sampledRequestedValues.length > 20
                  ? '25%'
                  : sampledRequestedValues.length > 10
                  ? '30%'
                  : '50%',
            }}
          >
            <div className="p-2 font-bold">
              {moment(resolvedClickedRequested).format('lll')}
            </div>
            <div className="grid w-full overflow-x-hidden p-2 [grid-template-columns:auto_1fr_auto]">
              {activeResultsByUrl.map(({ url, property }, i) => {
                const rank = i + 1
                const isActive = hoveredUrl === url
                const isInactive = hoveredUrl && url !== hoveredUrl
                const href = (() => {
                  try {
                    return new URL(url).href
                  } catch {}
                })()

                const color =
                  mode === 'reputation'
                    ? {
                        [-2]: dataColors.red,
                        [-1]: dataColors.orange,
                        0: dataColors.gray,
                        1: dataColors.greenAlt,
                        2: dataColors.blue,
                      }[property?.brand.reputationImpact ?? 0]
                    : getRankColor(rank, limit)

                const itemProps = {
                  onMouseEnter: () => setHoveredUrl(url),
                  onMouseLeave: () => setHoveredUrl(null),
                  className: twMerge(isActive && 'bg-gray-500/10'),
                }

                return (
                  <React.Fragment key={url}>
                    <div
                      {...itemProps}
                      className={twMerge(
                        `space-between flex items-center gap-2 px-1 text-right font-bold`,
                        itemProps.className
                      )}
                    >
                      {isActive ||
                      (mode === 'reputation' &&
                        property?.brand.reputationImpact) ? (
                        <FaCircle
                          style={{
                            color,
                          }}
                        />
                      ) : (
                        <FaRegCircle
                          style={{
                            color,
                          }}
                        />
                      )}
                      {rank}
                    </div>
                    <Tooltip
                      tooltip={<>{url}</>}
                      showTimeout={1000}
                      skipTimeout={1}
                      {...itemProps}
                      className={twMerge(
                        'truncate px-1 py-px',
                        itemProps.className,
                        isActive && 'font-bold',
                        isInactive && 'opacity-70'
                      )}
                    >
                      {url}
                    </Tooltip>
                    {href ? (
                      <Anchor
                        href={href}
                        target="_blank"
                        rel="noreferrer"
                        className={twMerge(
                          'flex items-center justify-center text-xs opacity-50 hover:opacity-100'
                        )}
                      >
                        <FaExternalLinkAlt />
                      </Anchor>
                    ) : (
                      <div />
                    )}
                  </React.Fragment>
                )
              })}
            </div>
          </div>
          <div className="flex-1 divide-y divide-gray-500/20">
            <div className="p-2">
              <ObservablePlot
                className="min-h-[300px] cursor-pointer"
                style={{
                  height: 20 + limit * 20,
                }}
                legendAfter
                onTipHover={(item: any) => {
                  setHoveredUrl(
                    item?.result?.url?.url ?? item?.result?.title?.text
                  )
                  // setHoveredRequested(item?.requested)
                }}
                onTipClick={(item: any) => {
                  setClickedRequested(item?.requested)
                  // if (item.result.url?.url) {
                  //   window.open(item.result.url?.url)
                  // } else {
                  //   toast({
                  //     message: 'No URL is available for this result.',
                  //   })
                  // }
                }}
                options={plotOptions}
              />
            </div>
            {foundReputation ? (
              <div className="flex flex-wrap items-center gap-2 p-2 text-sm">
                {[
                  [dataColors.red, 'Very Negative'],
                  [dataColors.orange, 'Negative'],
                  [dataColors.gray, 'Neutral'],
                  [dataColors.greenAlt, 'Positive'],
                  [dataColors.blue, 'Very Positive'],
                ].map(([color, label]) => (
                  <div className="flex items-center gap-2">
                    <FaCircle
                      style={{
                        color,
                      }}
                    />
                    {label}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      )}
    </Card>
  )
}

export type ReputationSingleKeywordSerpWidget = SerpWidgetState

const useSerpWidgetState = () => {
  return useSearchState<ReputationSingleKeywordSerpWidget>({
    path: 'performanceSingleKeywordSerpWidget',
    useDefaultValue: () => {
      return {
        isNozzleVisionEnabled: true,
        resultSearchTerm: '',
        rankingIds: [],
        zoom: 100,
        sortById: 'result__paid_adjusted_rank',
        desc: true,
        offset: 0,
        limit: 20,
        resultColumnGroup: resultColumnGroupOptions[0]!.value,
        resultColumnIds: resultColumnGroupOptions[0]!.columns,
        serpResultColumnGroup: 'custom',
        serpResultColumnIds: [
          'result__rank',
          'result__measurements__pixels_from_top',
          'result__nozzle_metrics__estimated_traffic',
        ],
        expandItemResults: false,
      }
    },
  })
}

function SerpsWidget() {
  const activeWorkspaceId = useActiveWorkspaceId()
  const timeRangeState = useTimeRangeState()
  const keywordIdState = useKeywordIdState()
  const widgetState = useSerpWidgetState()

  const params = {
    workspaceId: activeWorkspaceId,
    keywordId: keywordIdState.state,
    start: moment.unix(timeRangeState.state[0]!.start).utc().format(UTC_FORMAT),
    end: moment
      .unix(timeRangeState.state[0]!.end)
      .utc()
      .endOf('day')
      .format(UTC_FORMAT),
  }

  const rankingsQuery = useQuery(
    [queryKeyKeywordPulls, params],
    () => fetchPulls(params),
    {
      staleTime: Infinity,
    }
  )

  const rankingOptions = React.useMemo(
    () =>
      rankingsQuery.data?.map(pull => ({
        label: moment.utc(pull.requested).format('lll'),
        value: String(pull.rankingId),
      })),
    [rankingsQuery.data]
  )

  const rankingIds = widgetState.state.rankingIds

  React.useEffect(() => {
    if (rankingIds === null && rankingOptions?.length) {
      widgetState.setState((old: any) => ({
        ...old,
        rankingIds: [rankingOptions?.[0]?.value].filter(Boolean),
      }))
    }
  }, [rankingIds, rankingOptions, widgetState])

  const setZoom = (zoom: Updater<number>) =>
    widgetState.setState((prev: any) => ({
      ...prev,
      zoom: functionalUpdate(zoom, prev.zoom),
    }))

  const setResultColumnGroup = (group: Updater<any>) =>
    widgetState.setState((old: any) => ({
      ...old,
      resultColumnGroup: group,

      resultColumnIds:
        resultColumnGroupOptions.find(d => d.value === group)?.columns ?? [],
    }))

  const setRankingIds = React.useCallback(
    (rankingIds: Updater<(string | null)[]>) =>
      widgetState.setState((prev: any) => ({
        ...prev,
        rankingIds: functionalUpdate(rankingIds, prev.rankingIds),
      })),
    [widgetState]
  )

  const setResultColumnIds = (resultColumnIds: Updater<string[]>) =>
    widgetState.setState((prev: any) => ({
      ...prev,
      resultColumnIds: functionalUpdate(resultColumnIds, prev.resultColumnIds),
    }))

  const setResultSearchTerm = (resultSearchTerm: Updater<string>) =>
    widgetState.setState((state: any) => ({
      ...state,

      resultSearchTerm: functionalUpdate(
        resultSearchTerm,
        state.resultSearchTerm
      ),
    }))

  const setExpandItemResults = (expandItemResults: Updater<boolean>) =>
    widgetState.setState((prev: any) => ({
      ...prev,

      expandItemResults: functionalUpdate(
        expandItemResults,
        prev.expandItemResults
      ),
    }))

  const setOffset = (offset: any) =>
    widgetState.setState((old: any) => ({
      ...old,
      offset: functionalUpdate(offset, old.offset),
    }))

  const setLimit = (limit: any) =>
    widgetState.setState((old: any) => ({
      ...old,
      limit: functionalUpdate(limit, old.limit),
    }))

  const setDesc = (desc: any) =>
    widgetState.setState((old: any) => ({
      ...old,
      desc: functionalUpdate(desc, old.desc),
    }))

  const setSortById = (sortById: any) =>
    widgetState.setState((old: any) => ({
      ...old,
      sortById: functionalUpdate(sortById, old.sortById),
    }))

  const setIsNozzleVisionEnabled = (isNozzleVisionEnabled: any) =>
    widgetState.setState((old: any) => ({
      ...old,

      isNozzleVisionEnabled: functionalUpdate(
        isNozzleVisionEnabled,
        old.isNozzleVisionEnabled
      ),
    }))

  const firstRankingOptionValue = rankingOptions?.[0]?.value

  React.useEffect(() => {
    if (
      !rankingIds?.length &&
      rankingOptions?.length &&
      firstRankingOptionValue
    ) {
      setRankingIds([firstRankingOptionValue])
    }
  }, [
    firstRankingOptionValue,
    rankingIds?.length,
    rankingOptions?.length,
    setRankingIds,
  ])

  React.useEffect(() => {
    if (
      rankingIds?.length &&
      firstRankingOptionValue &&
      rankingIds.some((id: any) => !rankingOptions.find(d => d.value === id))
    ) {
      setRankingIds([firstRankingOptionValue])
    }
  })

  return (
    <div className="space-y-2">
      <Card className="p-0">
        <SerpResultsTables
          {...widgetState.state}
          isLoading={rankingsQuery.isLoading}
          rankingOptions={rankingOptions}
          setDesc={setDesc}
          setExpandItemResults={setExpandItemResults}
          setLimit={setLimit}
          setOffset={setOffset}
          setRankingIds={setRankingIds}
          setResultColumnGroup={setResultColumnGroup}
          setResultColumnIds={setResultColumnIds}
          setResultSearchTerm={setResultSearchTerm}
          setSortById={setSortById}
        />
      </Card>
      <Card className="p-0">
        {rankingsQuery.isLoading ? (
          <div className="flex items-center justify-center p-4">
            <Loader className="text-2xl" />
          </div>
        ) : !rankingIds?.[0] ? (
          <div className="p-2">
            <NoData />
          </div>
        ) : (
          <SerpHtml
            {...widgetState.state}
            rankingOptions={rankingOptions}
            setIsNozzleVisionEnabled={setIsNozzleVisionEnabled}
            setRankingIds={setRankingIds}
            setResultColumnGroup={setResultColumnGroup}
            setResultColumnIds={setResultColumnIds}
            setResultSearchTerm={setResultSearchTerm}
            setZoom={setZoom}
          />
        )}
      </Card>
    </div>
  )
}
