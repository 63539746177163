import * as Plot from '@observablehq/plot'
import { PlotOptions } from '@observablehq/plot'
import * as React from 'react'
import { ObservablePlot } from './ObservablePlot'
import Select from './Select'
import { Updater } from '../utils'
import { formatNumber } from '../utils/Format'
import { ClusterPb } from '../utils/proto'
import { ButtonPlain } from './ButtonPlain'
import { IoWarning } from 'react-icons/io5'
import Button from './Button'
import Clickable from './Clickable'
import Loader from './Loader'
import { FaCog, FaEyeSlash } from 'react-icons/fa'

export type BubbleChartAccessor<TDatum> = {
  label: string
  id: string
  accessor: (d: TDatum) => any
  scaleOptions?: Plot.ScaleOptions
}
export type BubbleChartState = {
  x: string
  y: string
  r: string
  fx: string
  fy: string
  color: string
}
export function BubbleChart<TDatum>({
  data,
  state,
  setState,
  accessors,
  defaultState,
  title,
  isLoading,
  totalSize,
  onTipHover,
  onTipClick,
  labelPlural,
}: {
  data: TDatum[]
  state: BubbleChartState
  setState: (updater: Updater<BubbleChartState>) => void
  accessors: BubbleChartAccessor<TDatum>[]
  defaultState: BubbleChartState
  title: any
  isLoading: boolean
  totalSize: number | undefined
  onTipHover?: (d: TDatum) => void
  onTipClick?: (d: TDatum) => void
  labelPlural: string
}) {
  const [showAll, setShowAll] = React.useState(false)
  const [showCustomControls, setShowCustomControls] = React.useState(false)
  const visibleItems = React.useMemo(() => {
    return showAll ? data : data.slice(0, 5000)
  }, [data, showAll])

  const clampable = data?.length > 5000
  const isAllVisible = data?.length === visibleItems?.length

  const labelPluralLower = labelPlural.toLowerCase()

  const chartMetricOptions = React.useMemo(() => {
    return [
      {
        label: 'None',
        value: '',
      },
      ...accessors.map(metric => ({
        label: metric.label,
        value: metric.id,
      })),
    ]
  }, [accessors])

  const isDirty = React.useMemo(
    () =>
      state.x !== defaultState.x ||
      state.y !== defaultState.y ||
      state.r !== defaultState.r ||
      state.fx !== defaultState.fx ||
      state.fy !== defaultState.fy ||
      state.color !== defaultState.color,
    [state]
  )

  const xMetric = accessors.find(d => d.id === state.x)
  const yMetric = accessors.find(d => d.id === state.y)
  const rMetric = accessors.find(d => d.id === state.r)
  const fxMetric = accessors.find(d => d.id === state.fx)
  const fyMetric = accessors.find(d => d.id === state.fy)
  const colorMetric = accessors.find(d => d.id === state.color)

  const options = React.useMemo((): PlotOptions => {
    return {
      grid: true,
      x: {
        label: xMetric?.label,
        inset: 15,
        ...xMetric?.scaleOptions,
      },
      y: {
        label: yMetric?.label,
        ...yMetric?.scaleOptions,
      },
      fy: {
        label: fyMetric?.label,
        domain: fyMetric?.scaleOptions?.domain,
      },
      fx: {
        label: fxMetric?.label,
        domain: fxMetric?.scaleOptions?.domain,
      },
      r: {
        label: rMetric?.label,
        range: rMetric ? [1, 15] : undefined,
        domain: rMetric?.scaleOptions?.domain,
      },
      color: {
        label: colorMetric?.label,
        type: 'linear',
        ...colorMetric?.scaleOptions,
      },
      marks: [
        Plot.frame(),
        Plot.dot(visibleItems, {
          x: d => xMetric?.accessor(d),
          y: d => yMetric?.accessor(d),
          r: d => rMetric?.accessor(d) ?? 1,
          fx: fxMetric ? d => fxMetric?.accessor(d) : undefined,
          fy: fyMetric ? d => fyMetric?.accessor(d) : undefined,
          stroke: d => colorMetric?.accessor(d),
          fill: d => colorMetric?.accessor(d),
          fillOpacity: 0.1,
          tip: true,
          channels: { 'Cluster Name': d => d.name },
          sort: {
            channel: '-fill',
          },
        }),
        // Plot.crosshair(visibleClusters, {
        //   x: d => xMetric?.accessor(d),
        //   y: d => yMetric?.accessor(d),
        // }),
      ],
    }
  }, [colorMetric, fxMetric, fyMetric, rMetric, visibleItems, xMetric, yMetric])

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-2 text-sm font-bold opacity-80">
        <span>{title}</span>
        {isLoading ? <Loader /> : null}
        <ButtonPlain
          className="bg-gray-100 text-xs dark:bg-gray-800"
          onClick={() => {
            setShowCustomControls(prev => !prev)
          }}
        >
          {showCustomControls ? (
            <>
              <FaEyeSlash /> Hide Settings
            </>
          ) : (
            <FaCog className="text-sm" />
          )}
        </ButtonPlain>
        {isDirty ? (
          <Button
            size="xs"
            color="yellow-500"
            onClick={() => setState(defaultState)}
          >
            Reset
          </Button>
        ) : null}
      </div>
      {showCustomControls || clampable ? (
        <div className="flex flex-wrap items-center gap-1">
          {showCustomControls
            ? (
                [
                  {
                    label: 'X Axis',
                    value: state.x,
                    key: 'x',
                  },
                  {
                    label: 'Y Axis',
                    value: state.y,
                    key: 'y',
                  },
                  {
                    label: 'Radius',
                    value: state.r,
                    key: 'r',
                  },
                  {
                    label: 'Facet X',
                    value: state.fx,
                    key: 'fx',
                  },
                  {
                    label: 'Facet Y',
                    value: state.fy,
                    key: 'fy',
                  },
                  {
                    label: 'Color',
                    value: state.color,
                    key: 'color',
                  },
                ] as const
              ).map(({ label, value, key }) => {
                return (
                  <Select
                    options={chartMetricOptions}
                    inlineLabel={<span className="font-bold">{label}</span>}
                    value={value}
                    onChange={next => {
                      setState(prev => ({
                        ...prev,
                        [key]: next,
                      }))
                    }}
                    className="text-xs"
                  />
                )
              })
            : null}
          {clampable ? (
            isAllVisible ? (
              <div className="inline-block rounded-lg bg-gray-500/10 px-2 py-1 text-sm shadow-sm">
                <IoWarning className="inline animate-pulse text-yellow-500" />{' '}
                You are viewing {formatNumber(visibleItems.length)} of{' '}
                {formatNumber(totalSize ?? 0)} {labelPluralLower} (the maximum
                allowed visualized {labelPluralLower}), which may be slower to
                visualize.{' '}
                <Clickable
                  onClick={() => setShowAll(false)}
                  className="font-bold underline"
                >
                  Show fewer {labelPluralLower}
                </Clickable>
              </div>
            ) : (
              <div className="inline-block rounded-lg bg-gray-500/10 px-2 py-1 text-sm shadow-sm">
                <IoWarning className="inline animate-pulse text-yellow-500" />{' '}
                You are viewing {formatNumber(visibleItems.length)} of{' '}
                {formatNumber(totalSize ?? 0)} {labelPluralLower} to maintain
                good performance.{' '}
                <Clickable
                  onClick={() => setShowAll(true)}
                  className="font-bold underline"
                >
                  Show all {labelPluralLower}
                </Clickable>
              </div>
            )
          ) : null}
        </div>
      ) : null}
      <ObservablePlot<ClusterPb>
        options={options}
        legend
        legendAfter
        className="h-[500px] max-w-full"
        onTipHover={onTipHover as any}
        onTipClick={onTipClick as any}
      />
    </div>
  )
}
