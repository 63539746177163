import { createColumnHelper } from '@tanstack/react-table'
import * as React from 'react'
import { useForm } from 'react-form'
import { FaCheck, FaEdit, FaPlus, FaTimes, FaTrashAlt } from 'react-icons/fa'
import Button from '../components/Button'
import Clickable from '../components/Clickable'
import DevicesInput from '../components/DevicesInput'
import Input from '../components/Input'
import JsonPhraseGroupsModal from '../components/JsonPhraseGroupsModal'
import Select from '../components/Select'
import useConfirm from '../hooks/useConfirm'
import { renderLocale, useLocalesQuery } from '../utils/locales'
import useModal from '../hooks/useModal'
import { useTable } from '../hooks/useTable'
import { deviceOptions } from '../options/deviceOptions'
import { AdvancedKeyword, Keyword } from '../utils/Api'
import { NO_DEVICE_NAME, NO_GROUP_NAME } from '../utils/Constants'
import { formatDevice, formatNumber } from '../utils/Format'
import { formatLocale } from '../utils/locales'
import { dedupeKeywords } from '../utils/Phrases'
import Validate from '../utils/Validate'
import { LocalePicker } from './LocalePicker'
import { LocalesInput } from './LocalesInput'
import QueryGate from './QueryGate'
import Separator from './Separator'
import Table from './Table'
import { createCheckboxColumn } from './CheckboxColumn'
import TextField from './TextField'
import DevicePicker from './DevicePicker'

//

const columnHelper = createColumnHelper<AdvancedKeyword>()

export default function KeywordsEditorTable({
  keywords,
  onKeywordsChange,
}: any) {
  const confirm = useConfirm()

  const info = React.useMemo(() => dedupeKeywords(keywords), [keywords])

  const groupOptions = React.useMemo(
    () =>
      Object.keys(info.groups).map(d => ({
        value: d,
        label: d,
      })),
    [info.groups]
  )

  const infoDeviceOptions = React.useMemo(
    () =>
      Object.keys(info.devices).map(d => ({
        value: d,
        label: formatDevice(d, { string: true }),
      })),
    [info.devices]
  )

  const localeIds = React.useMemo(
    () => Object.keys(info.localeIds),
    [info.localeIds]
  )

  const localesByIdQuery = useLocalesQuery({
    localeIds,
  })

  const columns = React.useMemo(
    () => [
      createCheckboxColumn(),
      columnHelper.accessor('phrase', {
        header: 'Keyword Phrase',
        filterFn: 'fuzzy',
        cell: function Cell(props) {
          const initialValue = props.getValue()
          const [isEditing, setIsEditing] = React.useState(false)
          const [value, setEditingValue] = React.useState(initialValue)

          React.useEffect(() => {
            setEditingValue(initialValue)
          }, [initialValue])

          const onCancel = () => {
            setEditingValue(initialValue)
            setIsEditing(false)
          }

          const handleSubmit = (e: any) => {
            e.preventDefault()
            e.stopPropagation()
            if (!isEditing) {
              return
            }
            onKeywordsChange(
              props.table.options.data.map(keyword => {
                if (keyword.phrase === initialValue) {
                  return {
                    ...keyword,
                    phrase: value,
                  }
                }
                return keyword
              })
            )
            setIsEditing(false)
          }

          const {
            Form,
            // @ts-expect-error  // Property 'isValid' does not exist on type '{ isSub... Remove this comment to see the full error message
            meta: { isValid },
            // @ts-expect-error  // Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
          } = useForm({})

          return (
            <Form className="flex items-center justify-between">
              <div className="flex-auto">
                {isEditing ? (
                  <TextField
                    field="phrase"
                    // @ts-expect-error  // Type '{ field: string; value: string; onChange: (e... Remove this comment to see the full error message
                    value={value}
                    onChange={e => setEditingValue(e.target.value)}
                    className="w-full"
                    validate={Validate.keywordSourcePhrases()}
                  />
                ) : (
                  initialValue
                )}
              </div>
              <div className="ml-2">
                {isEditing ? (
                  <>
                    <Clickable
                      onClick={onCancel}
                      className={` px-2 text-[1.2rem] text-red-600 hover:text-red-500`}
                    >
                      <FaTimes className="inline" />
                    </Clickable>
                    <Clickable
                      onClick={handleSubmit}
                      disabled={!isValid}
                      className={` px-2 text-[1.2rem] text-green-600 hover:text-green-500`}
                    >
                      <FaCheck className="inline" />
                    </Clickable>
                  </>
                ) : (
                  <div>
                    <Clickable
                      onClick={() => setIsEditing(true)}
                      className={` px-2 text-gray-200 hover:text-blue-500 dark:text-gray-700`}
                    >
                      <FaEdit className="inline" />
                    </Clickable>
                    <Clickable
                      onClick={() =>
                        onKeywordsChange(
                          props.table.options.data.filter(
                            phraseGroup => phraseGroup.phrase !== initialValue
                          )
                        )
                      }
                      className={` px-2 text-gray-200 hover:text-red-500 dark:text-gray-700`}
                    >
                      <FaTrashAlt className="inline" />
                    </Clickable>
                  </div>
                )}
              </div>
            </Form>
          )
        },
      }),
      columnHelper.accessor('groups', {
        header: 'Keyword Groups',
        filterFn: 'arrIncludesAll',
        getUniqueValues: row => row.groups,
        meta: {
          showQuickFilter: true,
        },
        cell: function Cell(props) {
          const original = props.row.original
          const [isEditing, setIsEditing] = React.useState(false)
          const [value, setEditingValue] = React.useState(original.groups)

          const displayValue = (props.getValue() || [])
            .map(d => (d === NO_GROUP_NAME ? '-' : d))
            .join(', ')

          React.useEffect(() => {
            setEditingValue(original.groups)
          }, [original.groups])

          const onCancel = () => {
            setEditingValue(original.groups)
            setIsEditing(false)
          }

          const handleSubmit = (e: any) => {
            e.preventDefault()
            e.stopPropagation()
            if (!isEditing) {
              return
            }
            onKeywordsChange(
              props.table.options.data.map(keyword => {
                if (keyword.phrase === original.phrase) {
                  return {
                    ...keyword,
                    groups: value,
                  }
                }
                return keyword
              })
            )
            setIsEditing(false)
          }

          return (
            <form className="flex items-center justify-between">
              <div className="flex-auto">
                {isEditing ? (
                  <Select
                    value={value}
                    options={groupOptions}
                    multi
                    create
                    onChange={setEditingValue}
                  />
                ) : (
                  displayValue
                )}
              </div>
              <div className="ml-2">
                {isEditing ? (
                  <>
                    <Clickable
                      onClick={onCancel}
                      className={` px-2 text-[1.2rem] text-red-600 hover:text-red-500`}
                    >
                      <FaTimes className="inline" />
                    </Clickable>
                    <Clickable
                      onClick={handleSubmit}
                      className={` px-2 text-[1.2rem] text-green-600 hover:text-green-500`}
                    >
                      <FaCheck className="inline" />
                    </Clickable>
                  </>
                ) : (
                  <div>
                    <Clickable
                      onClick={() => setIsEditing(true)}
                      className={` px-1 text-gray-200 hover:text-blue-500 dark:text-gray-700`}
                    >
                      <FaEdit className="inline" />
                    </Clickable>
                  </div>
                )}
              </div>
            </form>
          )
        },
      }),
      columnHelper.accessor('devices', {
        header: 'Devices',
        filterFn: 'arrIncludesAll',
        getUniqueValues: row => row.devices,
        meta: {
          getFilterLabel: value =>
            deviceOptions.find(d => d.value === value)?.label ?? '',
          showQuickFilter: true,
        },
        cell: function Cell(props) {
          const original = props.row.original
          const [isEditing, setIsEditing] = React.useState(false)
          const [value, setEditingValue] = React.useState(original.devices)

          const displayValue = (props.getValue() || [])
            .map(d =>
              // @ts-expect-error  // This condition will always return 'false' since th... Remove this comment to see the full error message
              d === NO_DEVICE_NAME ? '-' : formatDevice(d, { string: true })
            )
            .join(', ')

          React.useEffect(() => {
            setEditingValue(original.devices)
          }, [original.devices])

          const onCancel = () => {
            setEditingValue(original.devices)
            setIsEditing(false)
          }

          const handleSubmit = (e: any) => {
            e.preventDefault()
            e.stopPropagation()
            if (!isEditing) {
              return
            }
            onKeywordsChange(
              props.table.options.data.map(keyword => {
                if (keyword.phrase === original.phrase) {
                  return {
                    ...keyword,
                    devices: value,
                  }
                }
                return keyword
              })
            )
            setIsEditing(false)
          }

          return (
            <form className="flex items-center justify-between">
              <div className="flex-auto">
                {isEditing ? (
                  <Select
                    value={value}
                    options={deviceOptions}
                    multi
                    create
                    // @ts-expect-error  // Type 'Dispatch<SetStateAction<DeviceCode[]>>' is n... Remove this comment to see the full error message
                    onChange={setEditingValue}
                  />
                ) : (
                  displayValue
                )}
              </div>
              <div className="ml-2">
                {isEditing ? (
                  <>
                    <Clickable
                      onClick={onCancel}
                      className={` px-2 text-[1.2rem] text-red-600 hover:text-red-500`}
                    >
                      <FaTimes className="inline" />
                    </Clickable>
                    <Clickable
                      onClick={handleSubmit}
                      className={` px-2 text-[1.2rem] text-green-600 hover:text-green-500`}
                    >
                      <FaCheck className="inline" />
                    </Clickable>
                  </>
                ) : (
                  <div>
                    <Clickable
                      onClick={() => setIsEditing(true)}
                      className={` px-1 text-gray-200 hover:text-blue-500 dark:text-gray-700`}
                    >
                      <FaEdit className="inline" />
                    </Clickable>
                  </div>
                )}
              </div>
            </form>
          )
        },
      }),
      columnHelper.accessor('localeIds', {
        header: 'Locales',
        filterFn: 'arrIncludesAll',
        getUniqueValues: row => row.localeIds,
        meta: {
          getFilterLabel: value =>
            !localesByIdQuery.data
              ? 'Loading...'
              : formatLocale(
                  localesByIdQuery.data.find(d => d?.locale_id === value)
                ),
          showQuickFilter: true,
        },
        cell: function Cell(props) {
          const displayValue = props.getValue()
          const original = props.row.original
          const [isEditing, setIsEditing] = React.useState(false)
          const [value, setEditingValue] = React.useState(original.localeIds)

          React.useEffect(() => {
            setEditingValue(original.localeIds)
          }, [original.localeIds])

          const onCancel = () => {
            setEditingValue(original.localeIds)
            setIsEditing(false)
          }

          const handleSubmit = (e: any) => {
            e.preventDefault()
            e.stopPropagation()
            if (!isEditing) {
              return
            }
            onKeywordsChange(
              props.table.options.data.map(keyword => {
                if (keyword.phrase === original.phrase) {
                  return {
                    ...keyword,
                    localeIds: value,
                  }
                }
                return keyword
              })
            )
            setIsEditing(false)
          }

          return (
            <form className="flex items-center justify-between">
              <div className="mr-2 rounded bg-gray-800 px-1 text-xs font-bold text-white opacity-50">
                {formatNumber(displayValue.length)}
              </div>
              <div className="flex-auto">
                {isEditing ? (
                  <LocalesInput value={value} onChange={setEditingValue} />
                ) : (
                  <QueryGate query={localesByIdQuery}>
                    {() => {
                      const locale = localesByIdQuery.data?.find(
                        d => d?.locale_id === displayValue[0]
                      )

                      if (!locale) {
                        return '-'
                      }

                      const renderedLocale = renderLocale(locale)

                      return displayValue?.length > 1 ? (
                        <>{renderedLocale}, ...</>
                      ) : displayValue.length ? (
                        renderedLocale
                      ) : (
                        '-'
                      )
                    }}
                  </QueryGate>
                )}
              </div>
              <div className="ml-2">
                {isEditing ? (
                  <>
                    <Clickable
                      onClick={onCancel}
                      className={` px-2 text-[1.2rem] text-red-600 hover:text-red-500`}
                    >
                      <FaTimes className="inline" />
                    </Clickable>
                    <Clickable
                      onClick={handleSubmit}
                      className={` px-2 text-[1.2rem] text-green-600 hover:text-green-500`}
                    >
                      <FaCheck className="inline" />
                    </Clickable>
                  </>
                ) : (
                  <div>
                    <Clickable
                      onClick={() => setIsEditing(true)}
                      className={` px-1 text-gray-200 hover:text-blue-500 dark:text-gray-700`}
                    >
                      <FaEdit className="inline" />
                    </Clickable>
                  </div>
                )}
              </div>
            </form>
          )
        },
      }),
    ],
    [onKeywordsChange, groupOptions, localesByIdQuery]
  )

  const table = useTable({
    data: keywords,
    columns,
    tight: true,
    initialState: {
      sorting: [
        { id: 'phrase', desc: false },
        { id: 'groups', desc: false },
        { id: 'devices', desc: false },
        { id: 'localeIds', desc: false },
      ],
    },
    getRowProps: props => ({
      style: {
        background:
          !props.row.original.phrase ||
          !props.row.original.devices?.length ||
          !props.row.original.localeIds?.length
            ? 'lightred'
            : null,
      },
    }),
    showToolbar: true,
  })

  const selectedRows = table.getSelectedRowModel().rows

  const groupArray: string[] = []

  selectedRows.forEach(row =>
    row.original.groups?.forEach((group: any) => {
      groupArray.push(group)
    })
  )

  const groupRemoveOptions = groupOptions.filter(groupOption =>
    groupArray.includes(groupOption.label)
  )

  const showModal = useModal()

  const selectedLocaleIds = selectedRows
    .map(row => {
      return row.original.localeIds
    })
    .flat()

  const selectedLocales = localeIds.filter(id =>
    selectedLocaleIds.find(id2 => id === id2)
  )

  const selectedLocaleOptions = selectedLocales.map(localeId => {
    return {
      value: localeId,
      label: formatLocale(
        // @ts-expect-error  // Argument of type 'Locale | undefined' is not assig... Remove this comment to see the full error message
        localesByIdQuery.data?.find(d => d.id === localeId),
        { string: true }
      ),
    }
  })

  return (
    <div className="divide-y divide-gray-500/20 rounded-lg border border-gray-500/20">
      <Table table={table} />
      <div className="p-2">
        <Button
          size="xs"
          color="blue-500"
          className="mb-1 mr-1"
          onClick={() =>
            showModal(() => (
              <JsonPhraseGroupsModal
                keywords={keywords}
                onKeywordsChange={onKeywordsChange}
              />
            ))
          }
        >
          <FaPlus className="inline" />
          Add Keywords
        </Button>{' '}
        <span disabled={!selectedRows.length}>
          <strong>{selectedRows.length}</strong> keywords selected &nbsp;
        </span>
        {selectedRows.length ? (
          <>
            <span disabled={!selectedRows.length}>
              <Select
                options={groupOptions}
                onChange={value => {
                  const newKeywords = keywords.map((keyword: any) => {
                    if (
                      selectedRows.find(
                        d => d.original.phrase === keyword.phrase
                      )
                    ) {
                      const newGroups = keyword.groups || []
                      if (!newGroups.includes(value)) {
                        newGroups.push(value)
                      }

                      return {
                        ...keyword,
                        groups: newGroups,
                      }
                    }
                    return keyword
                  })
                  onKeywordsChange(newKeywords)
                }}
                create
                value={''}
                className="inline-flex"
              >
                {({ onClick }: any) => (
                  <Button size="xs" color="blue-400" onClick={onClick}>
                    <FaPlus className="inline" /> Keyword Group
                  </Button>
                )}
              </Select>{' '}
              <DevicePicker
                onChange={(value: any) => {
                  selectedRows.forEach(({ original }) => {
                    original.devices = original.devices || []

                    if (!original.devices.includes(value)) {
                      original.devices.push(value)
                    }
                  })
                  onKeywordsChange([...keywords])
                }}
                className="inline-flex"
              >
                {({ onClick }: any) => (
                  <Button size="xs" color="blue-400" onClick={onClick}>
                    <FaPlus className="inline" /> Device
                  </Button>
                )}
              </DevicePicker>{' '}
              <LocalePicker
                onChange={value => {
                  selectedRows.forEach(({ original }) => {
                    original.localeIds = original.localeIds || []

                    if (!original.localeIds.includes(value)) {
                      original.localeIds.push(value)
                    }
                  })
                  onKeywordsChange([...keywords])
                }}
                className="inline-flex"
              >
                {({ onClick }: any) => (
                  <Button size="xs" color="blue-400" onClick={onClick}>
                    <FaPlus className="inline" /> Locale
                  </Button>
                )}
              </LocalePicker>{' '}
              <Select
                options={groupRemoveOptions}
                onChange={value => {
                  selectedRows.forEach(({ original }) => {
                    original.groups = original.groups
                      ? original.groups.filter((group: any) => group !== value)
                      : []
                  })
                  onKeywordsChange([...keywords])
                }}
                value={''}
                className="inline-flex"
              >
                {({ onClick }: any) => (
                  <Button
                    size="xs"
                    color={['gray-100', 'gray-700']}
                    hoverColor="red-500"
                    onClick={onClick}
                  >
                    <FaTimes className="inline" /> Remove Keyword Group
                  </Button>
                )}
              </Select>{' '}
              <Select
                options={infoDeviceOptions}
                onChange={value => {
                  selectedRows.forEach(({ original }) => {
                    original.devices = original.devices
                      ? original.devices.filter(
                          (device: any) => device !== value
                        )
                      : []
                  })
                  onKeywordsChange([...keywords])
                }}
                value={''}
                className="inline-flex"
              >
                {({ onClick }: any) => (
                  <Button
                    size="xs"
                    color={['gray-100', 'gray-700']}
                    hoverColor="red-500"
                    onClick={onClick}
                  >
                    <FaTimes className="inline" /> Remove Device
                  </Button>
                )}
              </Select>{' '}
              <Select
                onChange={value => {
                  selectedRows.forEach(({ original }) => {
                    original.localeIds = original.localeIds
                      ? original.localeIds.filter(
                          (localeId: any) => localeId !== value
                        )
                      : []
                  })
                  onKeywordsChange([...keywords])
                }}
                options={selectedLocaleOptions}
                value={''}
                className="inline-flex"
              >
                {({ onClick }: any) => (
                  <Button
                    size="xs"
                    color={['gray-100', 'gray-700']}
                    hoverColor="red-500"
                    onClick={onClick}
                  >
                    <FaTimes className="inline" /> Remove Locale
                  </Button>
                )}
              </Select>{' '}
              <Button
                size="xs"
                color="red-500"
                onClick={async () => {
                  const confirmed = await confirm({
                    message: `Are you sure you want to delete all selected keywords?`,
                  })

                  if (confirmed) {
                    onKeywordsChange(
                      keywords.filter(
                        (phraseGroup: any) =>
                          !selectedRows.find(
                            selectedRow => selectedRow.original === phraseGroup
                          )
                      )
                    )
                  }
                }}
              >
                <FaTrashAlt className="inline" /> Delete
              </Button>
            </span>
          </>
        ) : null}
      </div>
    </div>
  )
}
