export const locationTypeOptions = [
  { label: 'Airport', value: 'Airport' },
  { label: 'Autonomous Community', value: 'Autonomous Community' },
  { label: 'Borough', value: 'Borough' },
  { label: 'Canton', value: 'Canton' },
  { label: 'City', value: 'City' },
  { label: 'City Region', value: 'City Region' },
  { label: 'Congressional District', value: 'Congressional District' },
  { label: 'Country', value: 'Country' },
  { label: 'County', value: 'County' },
  { label: 'Department', value: 'Department' },
  { label: 'District', value: 'District' },
  { label: 'Governorate', value: 'Governorate' },
  { label: 'Municipality', value: 'Municipality' },
  { label: 'National Park', value: 'National Park' },
  { label: 'Neighborhood', value: 'Neighborhood' },
  { label: 'Okrug', value: 'Okrug' },
  { label: 'Other', value: 'Other' },
  { label: 'Postal Code', value: 'Postal Code' },
  { label: 'Prefecture', value: 'Prefecture' },
  { label: 'Province', value: 'Province' },
  { label: 'Region', value: 'Region' },
  { label: 'State', value: 'State' },
  { label: 'Territory', value: 'Territory' },
  { label: 'TV Region', value: 'TV Region' },
  { label: 'Union Territory', value: 'Union Territory' },
  { label: 'University', value: 'University' },
]
