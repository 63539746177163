import React from 'react'
import ReactDOM from 'react-dom/client'
import './utils/sentry'

import App from './App'
import './index.css'

// Tailwind and emotion need to be reordered for css cascading to work propertly
// First tailwind, then emotion
if (typeof document !== 'undefined') {
  const twImport = Array.from(document.getElementsByTagName('style')).find(d =>
    // @ts-expect-error  // Object is possibly 'null'.
    d.textContent.includes('tailwindcss')
  )

  const emotionImport = document.querySelector('[data-emotion]')

  if (twImport && emotionImport) {
    twImport.remove()
    emotionImport.prepend(twImport)
  }
}

ReactDOM.createRoot(document.getElementById('root')!).render(<App />)
