import React from 'react'
import { Badge } from '../components/Badge'
import Link from '../components/Link'
import Tooltip from '../components/Tooltip'

import { GroupByQuerySeries } from './Api'
import {
  performanceByKeywordSlug,
  performanceByUrlSlug,
  performanceOverviewSlug,
} from './Constants'
import {
  formatChange,
  formatCurrency,
  formatNumber,
  formatPercentage,
  formatRank,
} from './Format'
import { QueryFacet, queryFacetsById } from './queryFacets'

const rankFormatters = (precision: number, hideItemRank?: boolean) => {
  const format = (d: number, options?: Parameters<typeof formatRank>[1]) => {
    return formatRank(d, {
      precision,
      string: true,
      hideItemRank,
      ...options,
    })
  }

  return {
    format,
    render: (d: number, options?: Parameters<typeof format>[1]) => {
      return format(d, {
        ...options,
        string: false,
      })
    },
  } as const
}

const decimalFormatters = (precision: number) => {
  const format = (
    d: number,
    options?: Parameters<typeof formatPercentage>[1]
  ) => {
    return formatPercentage(d, {
      forcePrecision: true,
      precision,
      ...options,
    })
  }

  return {
    format,
    render: format,
  } as const
}

const numberFormatters = (precision: number) => {
  const format = (d: number, options?: Parameters<typeof formatNumber>[1]) => {
    return formatNumber(d, {
      forcePrecision: true,
      precision,
      ...options,
    })
  }

  return {
    format,
    render: format,
  } as const
}

const currencyFormatters = (precision: number) => {
  const format = (
    d: number,
    options?: Parameters<typeof formatCurrency>[1]
  ) => {
    return formatCurrency(d, {
      forcePrecision: true,
      precision,
      ...options,
    })
  }

  return {
    format,
    render: format,
  }
}

export const metricsById = {
  top_rank: {
    label: 'Rank',
    id: 'top_rank',
    type: 'int',
    precision: 2,
    scaleMin: null,
    minDomainLength: 0.5,
    aggregations: ['avg', 'min', 'max', 'p25', 'p50', 'p75'],
    subAggregations: ['total'],
    postAggregations: ['value', 'change', 'best', 'worst'],
    inverted: true,
    dashboardSlug: null,
    badgeLabel: null,
    getDrillThroughLabel: null,
    ...rankFormatters(2),
  },
  top_stat_base_rank: {
    label: 'Base Rank',
    id: 'top_stat_base_rank',
    type: 'int',
    precision: 2,
    scaleMin: null,
    minDomainLength: 0.5,
    aggregations: ['avg', 'min', 'max', 'p25', 'p50', 'p75'],
    subAggregations: ['total'],
    postAggregations: ['value', 'change', 'best', 'worst'],
    inverted: true,
    dashboardSlug: null,
    badgeLabel: null,
    getDrillThroughLabel: null,
    ...rankFormatters(2, true),
  },
  top_pixels_from_top: {
    label: 'Pixels From Top',
    id: 'top_pixels_from_top',
    type: 'int',
    precision: 0,
    scaleMin: null,
    minDomainLength: 500,
    aggregations: ['avg', 'min', 'max', 'p25', 'p50', 'p75'],
    subAggregations: ['total'],
    postAggregations: ['value', 'change', 'best', 'worst'],
    inverted: true,
    dashboardSlug: null,
    badgeLabel: null,
    getDrillThroughLabel: null,
    ...numberFormatters(1),
  },
  percentage_of_viewport: {
    label: 'Above the Fold %',
    id: 'percentage_of_viewport',
    type: 'decimal',
    precision: 2,
    scaleMin: 0,
    minDomainLength: 0.01,
    aggregations: ['avg', 'min', 'max', 'p25', 'p50', 'p75'],
    subAggregations: ['total'],
    postAggregations: ['value', 'change', 'best', 'worst'],
    inverted: false,
    dashboardSlug: null,
    badgeLabel: null,
    getDrillThroughLabel: null,
    ...decimalFormatters(2),
  },
  percentage_of_dom: {
    label: 'SERP %',
    id: 'percentage_of_dom',
    type: 'decimal',
    precision: 2,
    scaleMin: 0,
    minDomainLength: 0.01,
    aggregations: ['avg', 'min', 'max', 'p25', 'p50', 'p75'],
    subAggregations: ['total'],
    postAggregations: ['value', 'change', 'best', 'worst'],
    inverted: false,
    dashboardSlug: null,
    badgeLabel: null,
    getDrillThroughLabel: null,
    ...decimalFormatters(2),
  },
  click_through_rate: {
    label: 'CTR',
    id: 'click_through_rate',
    type: 'decimal',
    precision: 1,
    scaleMin: 0,
    minDomainLength: 0.01,
    aggregations: ['avg', 'min', 'max', 'p25', 'p50', 'p75'],
    subAggregations: ['total'],
    postAggregations: ['value', 'change', 'best', 'worst'],
    inverted: false,
    dashboardSlug: null,
    badgeLabel: null,
    getDrillThroughLabel: null,
    ...decimalFormatters(1),
  },
  estimated_traffic: {
    label: 'Estimated Traffic',
    id: 'estimated_traffic',
    type: 'int',
    precision: 0,
    scaleMin: 0,
    minDomainLength: 100,
    aggregations: ['sum'],
    subAggregations: [
      'total',
      'rank_group_1',
      'rank_group_2',
      'rank_group_3',
      'rank_group_4_6',
      'rank_group_7_10',
      'rank_group_11_15',
      'rank_group_16_20',
      'rank_group_21_50',
      'rank_group_51',
    ],
    postAggregations: ['value', 'change', 'best', 'worst'],
    inverted: false,
    dashboardSlug: null,
    badgeLabel: null,
    getDrillThroughLabel: null,
    ...numberFormatters(0),
  },
  ppc_value: {
    label: 'PPC Value',
    id: 'ppc_value',
    type: 'int',
    precision: 0,
    scaleMin: 0,
    minDomainLength: 10,
    aggregations: ['sum'],
    subAggregations: [
      'total',
      'rank_group_1',
      'rank_group_2',
      'rank_group_3',
      'rank_group_4_6',
      'rank_group_7_10',
      'rank_group_11_15',
      'rank_group_16_20',
      'rank_group_21_50',
      'rank_group_51',
    ],
    postAggregations: ['value', 'change', 'best', 'worst'],
    inverted: false,
    dashboardSlug: null,
    badgeLabel: null,
    getDrillThroughLabel: null,
    ...currencyFormatters(0),
  },

  unique_keywords: {
    label: 'Keywords',
    id: 'unique_keywords',
    type: 'int',
    precision: 0,
    scaleMin: 0,
    minDomainLength: 10,
    aggregations: ['count'],
    subAggregations: [
      'total',
      'rank_group_1',
      'rank_group_2',
      'rank_group_3',
      'rank_group_4_6',
      'rank_group_7_10',
      'rank_group_11_15',
      'rank_group_16_20',
      'rank_group_21_50',
      'rank_group_51',
    ],
    postAggregations: ['value', 'change', 'best', 'worst'],
    inverted: false,
    dashboardSlug: (queryFacet: QueryFacet) => performanceByKeywordSlug,
    badgeLabel: 'kw',
    getDrillThroughLabel: (value: number) => `View ${value} Keywords`,
    ...numberFormatters(0),
  },
  unique_urls: {
    label: 'Unique URLs',
    id: 'unique_urls',
    type: 'int',
    precision: 0,
    scaleMin: 0,
    minDomainLength: 10,
    aggregations: ['count'],
    subAggregations: [
      'total',
      'rank_group_1',
      'rank_group_2',
      'rank_group_3',
      'rank_group_4_6',
      'rank_group_7_10',
      'rank_group_11_15',
      'rank_group_16_20',
      'rank_group_21_50',
      'rank_group_51',
    ],
    postAggregations: ['value', 'change', 'best', 'worst'],
    inverted: false,
    dashboardSlug: (queryFacet: QueryFacet) =>
      queryFacet.id === 'url' ? performanceOverviewSlug : performanceByUrlSlug,
    badgeLabel: 'url',
    getDrillThroughLabel: (value: number) => `View ${value} URLs`,
    ...numberFormatters(0),
  },
  results: {
    label: 'Results',
    id: 'results',
    type: 'int',
    precision: 0,
    scaleMin: 0,
    minDomainLength: 10,
    aggregations: ['count'],
    subAggregations: [
      'total',
      'rank_group_1',
      'rank_group_2',
      'rank_group_3',
      'rank_group_4_6',
      'rank_group_7_10',
      'rank_group_11_15',
      'rank_group_16_20',
      'rank_group_21_50',
      'rank_group_51',
    ],
    postAggregations: ['value', 'change', 'best', 'worst'],
    inverted: false,
    dashboardSlug: null,
    badgeLabel: null,
    getDrillThroughLabel: null,
    ...numberFormatters(1),
  },
  phrase: {
    label: 'Phrase',
    id: 'phrase',
    type: 'string',
    precision: 0,
    scaleMin: null,
    minDomainLength: null,
    aggregations: ['value'],
    subAggregations: ['value'],
    postAggregations: ['value', 'change', 'best', 'worst'],
    inverted: false,
    dashboardSlug: null,
    badgeLabel: null,
    getDrillThroughLabel: null,
    ...numberFormatters(0),
  },
  engine: {
    label: 'Engine',
    id: 'engine',
    type: 'string',
    precision: 0,
    scaleMin: null,
    minDomainLength: null,
    aggregations: ['value'],
    subAggregations: ['value'],
    postAggregations: ['value', 'change', 'best', 'worst'],
    inverted: false,
    dashboardSlug: null,
    badgeLabel: null,
    getDrillThroughLabel: null,
    ...numberFormatters(0),
  },
  device: {
    label: 'Device',
    id: 'device',
    type: 'string',
    precision: 0,
    scaleMin: null,
    minDomainLength: null,
    aggregations: ['value'],
    subAggregations: ['value'],
    postAggregations: ['value', 'change', 'best', 'worst'],
    inverted: false,
    dashboardSlug: null,
    badgeLabel: null,
    getDrillThroughLabel: null,
    ...numberFormatters(0),
  },
  country: {
    label: 'Country',
    id: 'country',
    type: 'string',
    precision: 0,
    scaleMin: null,
    minDomainLength: null,
    aggregations: ['value'],
    subAggregations: ['value'],
    postAggregations: ['value', 'change', 'best', 'worst'],
    inverted: false,
    dashboardSlug: null,
    badgeLabel: null,
    getDrillThroughLabel: null,
    ...numberFormatters(0),
  },
  language: {
    label: 'Language',
    id: 'language',
    type: 'string',
    precision: 0,
    scaleMin: null,
    minDomainLength: null,
    aggregations: ['value'],
    subAggregations: ['value'],
    postAggregations: ['value', 'change', 'best', 'worst'],
    inverted: false,
    dashboardSlug: null,
    badgeLabel: null,
    getDrillThroughLabel: null,
    ...numberFormatters(0),
  },
  location_type: {
    label: 'Location Type',
    id: 'location_type',
    type: 'string',
    precision: 0,
    scaleMin: null,
    minDomainLength: null,
    aggregations: ['value'],
    subAggregations: ['value'],
    postAggregations: ['value', 'change', 'best', 'worst'],
    inverted: false,
    dashboardSlug: null,
    badgeLabel: null,
    getDrillThroughLabel: null,
    ...numberFormatters(0),
  },
  location: {
    label: 'Location',
    id: 'location',
    type: 'string',
    precision: 0,
    scaleMin: null,
    minDomainLength: null,
    aggregations: ['value'],
    subAggregations: ['value'],
    postAggregations: ['value', 'change', 'best', 'worst'],
    inverted: false,
    dashboardSlug: null,
    badgeLabel: null,
    getDrillThroughLabel: null,
    ...numberFormatters(0),
  },
  adwords_search_volume: {
    label: 'Search Volume',
    id: 'adwords_search_volume',
    type: 'int',
    precision: 0,
    scaleMin: 0,
    minDomainLength: null,
    aggregations: ['sum'],
    subAggregations: ['total'],
    postAggregations: ['value', 'change', 'best', 'worst'],
    inverted: false,
    dashboardSlug: null,
    badgeLabel: null,
    getDrillThroughLabel: null,
    ...numberFormatters(1),
  },
  top_ranking_results: {
    label: 'Top Ranking Results',
    id: 'top_ranking_results',
    type: 'result',
    precision: 0,
    scaleMin: null,
    minDomainLength: null,
    aggregations: ['any'],
    subAggregations: ['any'],
    postAggregations: ['value'],
    inverted: false,
    dashboardSlug: null,
    badgeLabel: null,
    getDrillThroughLabel: null,
    ...numberFormatters(0),
  },
} as const

export type MetricType = 'string' | 'int' | 'decimal' | 'result'

export type MetricAggregation =
  | 'any'
  | 'value'
  | 'count'
  | 'sum'
  | 'avg'
  | 'min'
  | 'p25'
  | 'p50'
  | 'p75'
  | 'max'

export type MetricSubAggregation =
  | 'total'
  | 'value'
  | 'any'
  | 'rank_group_1'
  | 'rank_group_2'
  | 'rank_group_3'
  | 'rank_group_4_6'
  | 'rank_group_7_10'
  | 'rank_group_11_15'
  | 'rank_group_16_20'
  | 'rank_group_21_50'
  | 'rank_group_51'

export type MetricPostAggregation = 'value' | 'change' | 'best' | 'worst'

export type AnyMetric = {
  // Required
  label: Metric['label']
  id: Metric['id']
  type: MetricType
  precision: Metric['precision']
  aggregations: readonly MetricAggregation[]
  subAggregations: readonly MetricSubAggregation[]
  postAggregations: readonly MetricPostAggregation[]
  inverted: Metric['inverted']
  // Optional
  scaleMin: number | null
  minDomainLength: number | null
  dashboardSlug: ((queryFacet: QueryFacet) => string) | null
  badgeLabel: string | null
  getDrillThroughLabel: (value: number) => React.ReactNode | null
}

// Check that every metric is at least AnyMetric
// @ts-expect-error  // Conversion of type '{ readonly top_rank: { readonl... Remove this comment to see the full error message
void (metricsById as typeof metricsById as Record<string, AnyMetric>)

export const allMetrics = Object.values(metricsById)

export type Metric = (typeof allMetrics)[number]
export type MetricsById = typeof metricsById
export type MetricId = keyof MetricsById
export type MetricById<TMetricId extends MetricId> = MetricsById[TMetricId]

export type MetricPermutation<TMetricId extends MetricId> = {
  id: TMetricId
  aggregation: MetricById<TMetricId>['aggregations'][number]
  subAggregation: MetricById<TMetricId>['subAggregations'][number]
  postAggregation: MetricById<TMetricId>['postAggregations'][number]
}

export function metricPermutation<TMetricId extends MetricId>(
  id: TMetricId,
  defaults?: {
    aggregation?: MetricById<TMetricId>['aggregations'][number]
    subAggregation?: MetricById<TMetricId>['subAggregations'][number]
    postAggregation?: MetricById<TMetricId>['postAggregations'][number]
  },
  opts?: {
    rewrite?: boolean
  }
): MetricPermutation<TMetricId> {
  const metric = metricsById[id]
  const { aggregations, subAggregations, postAggregations } = metric
  const permutation = {
    id,
    aggregation: defaults?.aggregation ?? aggregations[0],
    subAggregation: defaults?.subAggregation ?? subAggregations[0],
    postAggregation: defaults?.postAggregation ?? postAggregations[0],
  }

  return safeMetricPermutation(permutation, opts)
}

export function safeMetricPermutation<TMetricId extends MetricId>(
  permutation: MetricPermutation<TMetricId>,
  opts?: { rewrite?: boolean }
) {
  const errors = getMetricPermutationErrors(permutation)

  if (errors) {
    const metric = metricsById[permutation.id]
    const { aggregations, subAggregations, postAggregations } = metric
    const replacement = {
      id: permutation.id,
      aggregation: errors.aggregation
        ? aggregations[0]
        : permutation?.aggregation,
      subAggregation: errors.subAggregation
        ? subAggregations[0]
        : permutation?.subAggregation,
      postAggregation: errors.postAggregation
        ? postAggregations[0]
        : permutation?.postAggregation,
    }

    if (!opts?.rewrite) {
      console.info('Invalid metric permutation:', permutation)
      console.info(
        'Invalid options:',
        // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        Object.keys(errors).map(key => `${key}: ${permutation[key]}`)
      )
      console.info('Valid metric options:', {
        aggregations,
        subAggregations,
        postAggregations,
      })
      throw new Error('Invalid metric permutation!')
    }

    return replacement
  }

  return permutation
}

export function getMetricPermutationErrors<TMetricId extends MetricId>(
  permutation: MetricPermutation<TMetricId>
) {
  const errors: {
    id?: true
    aggregation?: true
    subAggregation?: true
    postAggregation?: true
  } = {}

  const metric = metricsById[permutation.id]

  if (!metric) {
    errors.id = true
  }

  if (![...metric.aggregations].includes(permutation.aggregation)) {
    errors.aggregation = true
  }

  if (![...metric.subAggregations].includes(permutation.subAggregation)) {
    errors.subAggregation = true
  }

  if (![...metric.postAggregations].includes(permutation.postAggregation)) {
    errors.postAggregation = true
  }

  return Object.values(errors).some(Boolean) ? errors : false
}

export function getMetricChangeFormatter(metricId: MetricId) {
  const metric = metricsById[metricId]

  return (
    d: Parameters<typeof formatChange>[0],
    opts?: Partial<Parameters<typeof metric.format>[1]>
  ) => {
    return formatChange(metric.inverted ? -d : d, {
      // @ts-expect-error  // Argument of type '{ string: boolean; precision?: n... Remove this comment to see the full error message
      format: dd => metric.format(dd, { string: true, ...(opts ?? {}) }),
    })
  }
}

export function getMetricFormatter(metricId: MetricId) {
  return metricsById[metricId].format
}

export function getMetricRenderer(metricId: MetricId) {
  return metricsById[metricId].render
}

export function renderMetricDrillthrough(
  metricId: MetricId,
  queryFacet: QueryFacet,
  row: GroupByQuerySeries,
  rawValue: number
) {
  const metric = metricsById[metricId]

  return (value: any) => {
    if (metric.dashboardSlug && queryFacet.getMetricSearchFn) {
      return (
        <Tooltip tooltip={metric.getDrillThroughLabel(rawValue)}>
          <Link
            to={`/dashboards/${metric.dashboardSlug(queryFacet)}`}
            search={queryFacet.getMetricSearchFn(row)}
            className="inline-flex items-center gap-2"
          >
            {metric.badgeLabel ? <Badge>{metric.badgeLabel}</Badge> : null}
            {value}
          </Link>
        </Tooltip>
      )
    }

    return value
  }
}

export const safeMetricId = (
  metricId: MetricId,
  metricIds: MetricId[],
  defaultMetricId: MetricId
) => {
  return metricId && metricIds.includes(metricId) ? metricId : defaultMetricId
}

export function metricHasRankGroups(metricId: MetricId) {
  return (metricsById[metricId] as AnyMetric).subAggregations.includes(
    'rank_group_1'
  )
}
