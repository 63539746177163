import * as React from 'react'
import { splitFormProps, useField } from 'react-form'
import { deviceOptions } from '../options/deviceOptions'
import Validate from '../utils/Validate'
import DevicesInput from './DevicesInput'
import LabelWrap from './LabelWrap'

//

export default function DevicesField({ required = true, ...props }) {
  const [field, formProps, rest] = splitFormProps({
    validate: required
      ? Validate.required('At least one device is required.')
      : undefined,
    field: 'devices',
    ...props,
  })

  const {
    setValue,
    value,
    setMeta,
    meta: { error, isTouched },
    // @ts-expect-error  // Argument of type '{ validate: ((val: any) => strin... Remove this comment to see the full error message
  } = useField(field, formProps)

  const { label, ...renderProps }: any = {
    ...rest,
    value: value || [],
    error: isTouched && error,
    onChange: (value: any) => {
      setValue(value)
      props.onChange?.(value)
    },
    onBlur: () => {
      setMeta({ isTouched: true })
    },
  }

  return (
    <LabelWrap label={label} error={isTouched && error}>
      <DevicesInput options={deviceOptions} {...renderProps} />
    </LabelWrap>
  )
}
