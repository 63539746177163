import React from 'react'
import { Updater, functionalUpdate } from '../utils'

export type SelectOption<T = string> = {
  label: string
  value: T
}

export interface InputProps {
  refKey?: string
  ref?: any
  onChange?: any
  onFocus?: any
  onClick?: any
  onBlur?: any
}

const defaultFilterFn = (options: any, searchValue: any) => {
  return options
    .filter((option: any) =>
      option.value.toLowerCase().includes(searchValue.toLowerCase())
    )
    .sort((a: any, b: any) => {
      return a.value.toLowerCase().indexOf(searchValue.toLowerCase())
    })
}

function useDebounce(fn: any, time = 0) {
  const ref = React.useRef(null)
  const fnRef = React.useRef()

  fnRef.current = fn

  React.useEffect(() => {
    return () => {
      // @ts-expect-error  // No overload matches this call.
      clearTimeout(ref.current)
    }
  }, [time])

  return React.useCallback(
    async (...args) => {
      if (ref.current) {
        clearTimeout(ref.current)
      }
      return new Promise((resolve, reject) => {
        // @ts-expect-error  // Type 'Timeout' is not assignable to type 'null'.
        ref.current = setTimeout(() => {
          ref.current = null
          try {
            // @ts-expect-error  // Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
            resolve(fnRef.current(...args))
          } catch (err) {
            reject(err)
          }
        }, time)
      })
    },
    [time]
  )
}

const initialState = {
  searchValue: '',
  resolvedSearchValue: '',
  isOpen: false,
  highlightedIndex: 0,
}

const actions = {
  setOpen: 'setOpen',
  setSearch: 'setSearch',
  highlightIndex: 'highlightIndex',
} as const

function useHoistedState<T>(initialState: T, reducer: any) {
  const reducerRef = React.useRef()
  reducerRef.current = reducer
  const [state, _setState] = React.useState(initialState)
  const setState = React.useCallback(
    (updater: Updater<T>, action: keyof typeof actions) => {
      if (!action) {
        throw new Error('An action type is required to update the state')
      }
      // @ts-expect-error  // Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      return _setState(old => reducerRef.current(old, updater(old), action))
    },
    [_setState]
  )
  return [state, setState] as const
}

export function useSelect({
  multi,
  create,
  getCreateLabel = d => `Use "${d}"`,
  stateReducer = (old, newState, action) => newState,
  options,
  value,
  onChange,
  scrollToIndex = () => {
    //
  },
  shiftAmount = 5,
  filterFn = defaultFilterFn,
  optionsRef,
  getDebounce = options =>
    options.length > 10000 ? 1000 : options.length > 1000 ? 200 : 0,
  closeOnSelect,
}: {
  multi?: boolean
  create?: boolean
  getCreateLabel?: (searchValue: string) => string
  stateReducer?: (old: any, newState: any, action: any) => any
  options: any[]
  value?: any
  onChange?: (value: any) => void
  scrollToIndex?: (index: number) => void
  shiftAmount?: number
  filterFn?: (options: any[], searchValue: string) => any[]
  optionsRef?: React.MutableRefObject<any>
  getDebounce?: (options: any[]) => number
  closeOnSelect?: boolean
}) {
  const [
    { searchValue, resolvedSearchValue, isOpen, highlightedIndex },
    setState,
  ] = useHoistedState(initialState, stateReducer)

  // Refs

  const inputRef = React.useRef()
  const onBlurRef = React.useRef({})
  const onChangeRef = React.useRef()
  const getCreateLabelRef = React.useRef()
  const scrollToIndexRef = React.useRef()

  // @ts-expect-error  // Type '(index: number) => void' is not assignable t... Remove this comment to see the full error message
  scrollToIndexRef.current = scrollToIndex
  // @ts-expect-error  // Type '(searchValue: string) => string' is not assi... Remove this comment to see the full error message
  getCreateLabelRef.current = getCreateLabel

  // @ts-expect-error  // Type '((value: any) => void) | undefined' is not a... Remove this comment to see the full error message
  onChangeRef.current = onChange

  // We need to memoize these default values to keep things
  // from rendereing without cause
  const defaultMultiValue = React.useMemo(() => [], [])
  const defaultOptions = React.useMemo(() => [], [])

  // Multi should always at least have an empty array as the value
  if (multi && typeof value === 'undefined') {
    value = defaultMultiValue
  }

  // If no options are provided, then use an empty array
  if (!options) {
    options = defaultOptions
  }

  const originalOptions = options

  // Compute the currently selected option(s)
  let selectedOption = React.useMemo(() => {
    if (!multi) {
      return (
        originalOptions.find(d => d.value === value) || {
          label: value,
          value,
        }
      )
    }
    return value.map(
      (val: any) =>
        originalOptions.find(d => d.value === val) || {
          label: val,
          value: val,
        }
    )
  }, [multi, value, originalOptions])

  selectedOption = React.useMemo(() => {
    if (!multi && selectedOption?.value === undefined) {
      return { ...selectedOption, label: '' }
    }
    return selectedOption
  }, [selectedOption, multi])

  // If there is a search value, filter the options for that value
  // TODO: This is likely where we will perform async option fetching
  // in the future.
  options = React.useMemo(
    () => filterFn(options, resolvedSearchValue),
    [filterFn, options, resolvedSearchValue]
  )

  // If in create mode and we have a search value, fabricate
  // an option for that searchValue and prepend it to options
  options = React.useMemo(() => {
    if (create && searchValue && !options.find(d => d.value === searchValue)) {
      return [
        // @ts-expect-error  // Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        { label: getCreateLabelRef.current(searchValue), value: searchValue },
        ...options,
      ]
    }
    return options
  }, [create, searchValue, options])

  // Actions

  const setOpen = React.useCallback(
    newIsOpen => {
      setState(
        old => ({
          ...old,
          isOpen: newIsOpen,
        }),
        actions.setOpen
      )
    },
    [setState]
  )

  const setResolvedSearch = useDebounce((value: any) => {
    setState(
      old => ({
        ...old,
        resolvedSearchValue: value,
      }),
      actions.setSearch
    )
  }, getDebounce(options))

  const setSearch = React.useCallback(
    value => {
      setState(
        old => ({
          ...old,
          searchValue: value,
        }),
        actions.setSearch
      )
      setResolvedSearch(value)
    },
    [setState, setResolvedSearch]
  )

  const usingKeyboardRef = React.useRef(false)

  const highlightIndex = React.useCallback(
    (value: Updater<number>, scrollIntoView: boolean) => {
      setState(old => {
        const next = Math.min(
          Math.max(0, functionalUpdate(value, old.highlightedIndex)),
          options.length - 1
        )

        if (scrollIntoView && usingKeyboardRef.current) {
          // @ts-expect-error  // Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
          scrollToIndexRef.current(next)
        }

        return {
          ...old,
          highlightedIndex: next,
        }
      }, actions.highlightIndex)
    },
    [options, setState]
  )

  const selectIndex = React.useCallback(
    index => {
      const option = options[index]
      if (option) {
        if (multi) {
          if (value.includes(option.value)) {
            // @ts-expect-error  // Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
            onChangeRef.current(value.filter((d: any) => d !== option.value))
          } else {
            // @ts-expect-error  // Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
            onChangeRef.current([...value, option.value])
            if (create) {
              setSearch('')
            }
          }
        } else {
          // @ts-expect-error  // Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
          onChangeRef.current(option.value)
        }
      }

      if ((closeOnSelect ?? !multi) && !multi) {
        setOpen(false)
      }
    },
    [multi, options, value, setOpen]
  )

  const removeValue = React.useCallback(
    index => {
      // @ts-expect-error  // Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      onChangeRef.current(value.filter((d: any, i: any) => i !== index))
    },
    [value]
  )

  // Handlers

  const handleSearchValueChange = (e: any) => {
    setSearch(e.target.value)
    setOpen(true)
  }

  const handleSearchClick = () => {
    if (!create || multi) {
      setSearch('')
    }
    setOpen(true)
  }

  const handleSearchFocus = () => handleSearchClick()

  // Prop Getters

  const ArrowUp =
    (defaultShift?: boolean, defaultMeta?: boolean) =>
    ({ shift, meta }: any, e: any) => {
      usingKeyboardRef.current = true
      e.preventDefault()
      const amount =
        defaultMeta || meta
          ? 1000000000000
          : defaultShift || shift
          ? shiftAmount - 1
          : 1
      setOpen(true)
      highlightIndex(old => old - amount, true)
    }

  const ArrowDown =
    (defaultShift?: boolean, defaultMeta?: boolean) =>
    ({ shift, meta }: any, e: any) => {
      usingKeyboardRef.current = true
      e.preventDefault()
      const amount =
        defaultMeta || meta
          ? 1000000000000
          : defaultShift || shift
          ? shiftAmount - 1
          : 1
      setOpen(true)
      highlightIndex(old => old + amount, true)
    }

  const Enter = (_: any, e: any) => {
    if (isOpen) {
      if (searchValue || options[highlightedIndex]) {
        e.preventDefault()
      }
      if (options[highlightedIndex]) {
        selectIndex(highlightedIndex)
      }
    }
  }

  const Escape = () => {
    setOpen(false)
  }

  const Tab = () => {
    setOpen(false)
  }

  // const Backspace = () => {
  //   if (!multi || searchValue) {
  //     return
  //   }
  //   removeValue(value.length - 1)
  // }

  const getKeyProps_ = useKeys({
    ArrowUp: ArrowUp(),
    ArrowDown: ArrowDown(),
    PageUp: ArrowUp(true),
    PageDown: ArrowDown(true),
    Home: ArrowUp(false, true),
    End: ArrowDown(false, true),
    Enter,
    Escape,
    Tab,
    // Backspace,
  })

  const getKeyProps = (props = {}) => {
    return getKeyProps_({
      onKeyDown: (e: any) => {
        if (e.code === 'Space' && !searchValue) {
          Enter(undefined, e)
        }
      },
      ...props,
    })
  }

  const getInputProps = ({
    refKey = 'ref',
    ref,
    onChange,
    onFocus,
    onClick,
    onBlur,
    ...rest
  }: InputProps = {}) => {
    return getKeyProps({
      ...rest,
      [refKey]: (el: any) => {
        inputRef.current = el
        if (ref) {
          ref.current = el
        }
      },
      value:
        (isOpen ? searchValue : selectedOption ? selectedOption.label : '') ||
        '',
      onChange: (e: any) => {
        handleSearchValueChange(e)
        onChange?.(e)
      },
      onFocus: (e: any) => {
        // @ts-expect-error  // Expected 0 arguments, but got 1.
        handleSearchFocus(e)
        onFocus?.(e)
      },
      onClick: (e: any) => {
        // @ts-expect-error  // Expected 0 arguments, but got 1.
        handleSearchClick(e)
        onClick?.(e)
      },
      onBlur: (e: any) => {
        if (onBlur) {
          e.persist()
          // @ts-expect-error  // Property 'cb' does not exist on type '{}'.
          onBlurRef.current.cb = onBlur
          // @ts-expect-error  // Property 'event' does not exist on type '{}'.
          onBlurRef.current.event = e
        }
      },
    })
  }

  const getOptionProps = ({
    index,
    key = index,
    onClick,
    onMouseEnter,
    ...rest
  }: any = {}) => {
    if (typeof index !== 'number' || index < 0) {
      throw new Error(
        `useSelect: The getOptionProps prop getter requires an index property, eg. 'getOptionProps({index: 1})'`
      )
    }

    return getKeyProps({
      key,
      ...rest,
      onClick: (e: any) => {
        selectIndex(index)
        if (onClick) {
          onClick(e)
        }
      },
      onMouseEnter: (e: any) => {
        if (usingKeyboardRef.current) {
          return
        }
        usingKeyboardRef.current = false
        highlightIndex(index, false)
        if (onMouseEnter) {
          onMouseEnter(e)
        }
      },
      onMouseMove: () => {
        usingKeyboardRef.current = false
      },
    })
  }

  // Effects

  // When the user clicks outside of the options box
  // while open, we need to close the dropdown
  useClickOutsideRef(
    isOpen,
    () => {
      setOpen(false)
    },
    optionsRef
  )

  // When searching, activate the first option
  React.useEffect(() => {
    highlightIndex(0, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])

  // When we open and close the options, set the highlightedIndex to 0
  React.useEffect(() => {
    highlightIndex(0, true)

    // @ts-expect-error  // Property 'event' does not exist on type '{}'.
    if (!isOpen && onBlurRef.current.event) {
      // @ts-expect-error  // Property 'cb' does not exist on type '{}'.
      onBlurRef.current.cb(onBlurRef.current.event)
      // @ts-expect-error  // Property 'event' does not exist on type '{}'.
      onBlurRef.current.event = null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  React.useEffect(() => {
    if (isOpen && inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, inputRef.current])

  return {
    // State
    searchValue,
    isOpen,
    highlightedIndex,
    selectedOption,
    visibleOptions: options,
    // Actions
    selectIndex,
    removeValue,
    setOpen,
    setSearch,
    highlightIndex,
    // Prop Getters
    getKeyProps,
    getInputProps,
    getOptionProps,
  }
}

function useClickOutsideRef(enable: any, fn: any, userRef: any) {
  const localRef = React.useRef()
  const fnRef = React.useRef()

  fnRef.current = fn
  const elRef = userRef || localRef

  const handle = React.useCallback(
    e => {
      const isTouch = e.type === 'touchstart'
      if (e.type === 'click' && isTouch) {
        return
      }
      const el = elRef.current
      // @ts-expect-error  // Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      if (el && !el.contains(e.target)) fnRef.current(e)
    },
    [elRef]
  )

  React.useEffect(() => {
    if (enable) {
      document.addEventListener('touchstart', handle, true)
      document.addEventListener('mousedown', handle, true)
    }

    return () => {
      document.removeEventListener('touchstart', handle, true)
      document.removeEventListener('mousedown', handle, true)
    }
  }, [enable, handle])
}

const useKeys = (userKeys: any) => {
  return ({ onKeyDown, ...rest }: any = {}) => {
    return {
      ...rest,
      onKeyDown: (e: any) => {
        if (onKeyDown) {
          onKeyDown(e)
        }

        if (e.defaultPrevented) {
          return
        }

        const { keyCode, key, shiftKey: shift, metaKey: meta } = e
        const handler = userKeys[key] || userKeys[keyCode]
        if (handler) {
          handler(
            {
              keyCode,
              key,
              shift,
              meta,
            },
            e
          )
        }
      },
    }
  }
}
