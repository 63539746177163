import * as React from 'react'
import { useTable, UseTableOptions } from './useTable'

//

export default function useControlledTable<TData>({
  data,
  columns,
  ...rest
}: UseTableOptions<TData>) {
  return useTable({
    data,
    columns,
    manualSorting: true,
    manualFiltering: true,
    manualPagination: true,
    enableSortingRemoval: false,
    // UI
    pagination: {
      show: false,
    },
    counter: {
      show: false,
    },
    ...rest,
  })
}
