import * as React from 'react'
import { useQuery } from 'react-query'
import { fetchDashboardKeywords } from '../utils/Api'
import { queryKeyAllKeywords } from '../utils/Constants'
import { facetKeywords } from '../utils/Keywords'

//

import { useActiveWorkspaceId } from './workspaces'
import {
  MeiliSearchKeyword,
  useKeywordsSearchIndex,
} from './useKeywordsSearchIndex'
import { nozzleCacheClient } from '../utils/nozzleCacheClient'
import {
  EnginePb,
  KeywordPb,
  KeywordsClient,
  LocationTypePb,
} from '../utils/proto'
import { formatDevice, formatKeywordV1, renderDevice } from '../utils/Format'
import { formatLocale, renderLocale } from '../utils/locales'
import { Locale } from '../utils/locales'
import { useLocalesByIdQuery } from '../utils/locales'
import { deviceCodeToDevicePb } from '../utils/devices'
import { useSearch } from '@tanstack/react-router'
import { useActiveProjectIdState } from '../utils/searchParams'

export function useKeywords(options: {
  projectId: undefined | string
  enabled?: boolean
}) {
  const activeWorkspaceId = useActiveWorkspaceId()

  return useQuery(
    [
      queryKeyAllKeywords,
      {
        workspaceId: activeWorkspaceId,
        teamId: options.projectId,
      },
    ],
    () =>
      fetchDashboardKeywords({
        workspaceId: activeWorkspaceId,
        teamId: options.projectId,
      }),
    {
      enabled:
        (options.enabled ?? true) && !!(activeWorkspaceId && options.projectId),
      staleTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
      retry: 0,
    }
  )
}

//

export function useKeywordFacetsQuery({ teamId, enabled = true }: any) {
  const dashboardKeywordsQuery = useKeywords({
    projectId: teamId,
    enabled,
  })

  const data = React.useMemo(
    () => facetKeywords(dashboardKeywordsQuery.data || []),
    [dashboardKeywordsQuery.data]
  )

  return {
    ...dashboardKeywordsQuery,
    data,
  }
}

type Props = {
  enabled?: boolean
}

export function useKeywordOptionsQuery({ enabled }: Props = {}) {
  const projectId = useActiveProjectIdState().state

  const query = useKeywords({
    projectId,
    enabled,
  })

  const localesByIdQuery = useLocalesByIdQuery({
    localeIds: query.data?.map((d: any) => d.locale_id),
  })

  // Build the focus options
  const data = React.useMemo(
    () =>
      query.data && localesByIdQuery.data
        ? query.data
            .map((d: any) => ({
              value: d.keyword_id,
              label: formatKeywordV1(d, { string: true }),
              keyword: d,
            }))
            .sort((a: any, b: any) =>
              a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
            )
        : [],
    [query.data, localesByIdQuery.data]
  )

  return { ...query, data }
}

export function useKeywordGroupOptionsQuery({ enabled = true } = {}) {
  const projectId = useActiveProjectIdState().state

  const facetsQuery = useKeywordFacetsQuery({
    teamId: projectId,
    enabled,
  })

  // @ts-expect-error  // Property 'keyword_group' does not exist on type '{... Remove this comment to see the full error message
  const data = facetsQuery.data?.keyword_group

  return {
    ...facetsQuery,
    data,
  }
}

export type KeywordWithLocale = KeywordPb & { locale: Locale }

export type KeywordSearchResults = KeywordWithLocale[] & {
  localesById: Record<string, Locale>
}

function meilisearchKeywordToKeywordPb(
  hit?: MeiliSearchKeyword
): KeywordWithLocale | undefined {
  if (!hit) {
    return undefined
  }

  const keyword = new KeywordPb({
    keywordId: BigInt(hit.keyword_id),
    phrase: hit.phrase,
    device: deviceCodeToDevicePb(hit.device_code),
    localeId: BigInt(hit.locale_id),
    engine: 1,
    languageCode: hit.language,
    location: {
      countryCode: hit.country,
      location: hit.location,
      locationId: BigInt(hit.location_id),
      type: hit.location_type as unknown as LocationTypePb,
    },
  })

  ;(keyword as any).locale = hit

  return keyword as any
}

function keywordsResultWithLocales(result: KeywordPb[]): KeywordSearchResults {
  const keywordsResultWithLocales = result as KeywordSearchResults

  keywordsResultWithLocales.localesById = result.reduce((acc, d) => {
    if (d?.localeId) {
      acc[String(d.localeId)] = d.locale
    }
    return acc
  }, {} as Record<string, Locale>)

  return keywordsResultWithLocales
}

export function useKeywordSearchQuery(options: {
  search: string
  projectId: string
}) {
  const workspaceId = useActiveWorkspaceId()

  return useQuery(
    ['locales-search', options.search],
    async () => {
      const keywords = KeywordsClient.listKeywords({
        workspaceId: BigInt(workspaceId),
        projectId: BigInt(options.projectId),
        filters: {
          search: options.search,
        },
      }).then(d => d.keywords.map(d => d.keyword!))

      return keywords

      // return keywordsResultWithLocales(keywords)
    },
    {
      enabled: Boolean(workspaceId && options.projectId),
      keepPreviousData: true,
    }
  )
}

export function useKeywordsQuery(opts: {
  projectId: string
  keywordIds: string[]
}) {
  const activeWorkspaceId = useActiveWorkspaceId()
  const searchIndex = useKeywordsSearchIndex()
  const keywordIds = opts.keywordIds?.filter(Boolean)

  return useQuery({
    queryKey: ['keywords', keywordIds],
    queryFn: async () => {
      const existing = await Promise.all(
        keywordIds.map(async keywordId => {
          return [
            keywordId,
            (await nozzleCacheClient.get([
              'keyword',
              [activeWorkspaceId, opts.projectId, keywordId].join('-'),
            ])) as KeywordPb & { locale: Locale },
          ] as const
        })
      )

      const shouldRequestList = existing
        .filter(([d, existing]) => !existing)
        .map(([d]) => d)

      const requested = shouldRequestList.length
        ? (
            await searchIndex!.search('', {
              limit: 999999999,
              filter: `workspace_id=${activeWorkspaceId} AND project_id=${
                opts.projectId
              } AND keyword_id IN [${shouldRequestList.join(',')}]`,
            })
          ).hits
        : []

      const result = await Promise.all(
        existing.map(async ([d, existing], i) => {
          if (existing) {
            return existing
          }

          const found = meilisearchKeywordToKeywordPb(
            requested.find(dd => dd.keyword_id === String(d))!
          )

          if (found) {
            nozzleCacheClient.set(
              ['keyword', [activeWorkspaceId, opts.projectId, d].join('-')],
              found,
              {
                ttl: Date.now() + 1000 * 60 * 5, // 5 minutes
              }
            )
          }

          return found
        })
      )

      return keywordsResultWithLocales(result)
    },
    enabled: Boolean(searchIndex?.getDocument),
    keepPreviousData: true,
    retry: false,
  })
}

export function useKeywordsByIdQuery(props: {
  projectId: string
  keywordIds: string[]
}) {
  const keywordsQuery = useKeywordsQuery(props)

  return useQuery({
    queryKey: [
      'keywordsById',
      props.keywordIds,
      {
        keywordsQuery: keywordsQuery.dataUpdatedAt,
      },
    ],
    queryFn: () => {
      const byId: Record<string, KeywordWithLocale> & {
        localesById: Record<string, Locale>
      } = {} as any

      keywordsQuery.data?.forEach(keyword => {
        if (keyword) {
          byId[String(keyword.keywordId)] = keyword
        }
      })

      byId.localesById = keywordsQuery.data!.localesById!

      return byId
    },
    staleTime: Infinity,
    cacheTime: 0,
    enabled: keywordsQuery.isSuccess,
  })
}

export function formatKeyword(
  keyword: KeywordPb | undefined,
  localesById: Record<string, Locale> | undefined
) {
  if (!keyword) {
    return 'Unknown'
  }

  const locale = localesById?.[Number(keyword.localeId)]

  return [
    keyword.engine === EnginePb.GOOGLE ? 'Google' : 'Other',
    keyword.phrase,
    formatDevice(keyword.device, { v2: true, string: true }),
    locale ? formatLocale(locale) : null,
  ]
    .filter(Boolean)
    .join(' - ')
}

export function renderKeyword(
  keyword: KeywordPb | undefined,
  localesById: Record<string, Locale> | undefined
) {
  if (!keyword) {
    return 'Unknown'
  }

  const locale =
    (keyword as any).locale || localesById?.[Number(keyword.localeId)]

  return (
    <span className="flex flex-wrap items-center gap-2">
      {[
        keyword.phrase,
        renderDevice(keyword.device),
        locale ? renderLocale(locale) : null,
      ].reduce((acc, d) => {
        return (
          <>
            {acc}
            {d}
          </>
        )
      })}
    </span>
  )
}
