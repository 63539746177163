export const booleanOperatorOptions = [
  {
    value: 'is',
    label: 'is',
  },
  {
    value: '!is',
    label: '!is',
  },
]
