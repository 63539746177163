import * as React from 'react'
import { FaCheck, FaTimes } from 'react-icons/fa'
import useModalContext from '../hooks/useModalContext'
import { PhraseGroup } from '../utils/Api'
import { dedupePhraseGroups } from '../utils/Phrases'
import Button from './Button'
import LabelWrap from './LabelWrap'
import { Modal, ModalContent, ModalTitle } from './Modals'
import Select from './Select'
import TextArea from './TextAreaInput'

export function PhraseGroupsModal(props: {
  onSubmit: (phraseGroups: PhraseGroup[]) => void
}) {
  const [keywordsInput, setKeywordsInput] = React.useState('')
  const [keywordGroups, setKeywordGroups] = React.useState([])

  const { close } = useModalContext()

  const onSubmit = () => {
    props.onSubmit(
      dedupePhraseGroups(
        keywordsInput
          .split('\n')
          .map(d => d.trim())
          .filter(Boolean)
          .map(line => {
            const [phrase, ...groups] = line.split(';').map(d => d.trim())
            return {
              phrase: phrase!,
              groups: [...groups, ...keywordGroups],
              displayGroups: [],
            }
          })
      ).phraseGroups
    )
    close()
  }

  return (
    <Modal>
      <ModalTitle>Add Phrases</ModalTitle>
      <ModalContent className="divide-y divide-gray-500/20">
        <div className="space-y-4 p-4">
          <LabelWrap label="Phrases">
            <TextArea
              value={keywordsInput}
              onChange={e => setKeywordsInput(e.currentTarget.value)}
              autoFocus
              placeholder={[
                'phrase; group 1; group 2',
                '',
                'e.g.',
                'eggs; Breakfast; Eggs',
                'hamburger; Lunch; Dinner',
                'sandwich; Lunch',
                'fried eggs; Breakfast; Eggs',
                'toast',
              ].join(`\n`)}
              rows={6}
            />
          </LabelWrap>
          <LabelWrap label="Keyword Groups">
            <Select
              value={keywordGroups}
              onChange={setKeywordGroups}
              multi
              create
              placeholder="Assign your keyword phrases to a keyword group..."
            />
          </LabelWrap>
        </div>
        <div className="flex gap-2 p-4">
          <Button size="sm" color="red-500" onClick={close}>
            <FaTimes /> Cancel
          </Button>
          <Button size="sm" color="green-500" onClick={onSubmit}>
            <FaCheck /> Add Keywords
          </Button>
        </div>
      </ModalContent>
    </Modal>
  )
}
