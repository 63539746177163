import * as React from 'react'
import useZustand from './useZustand'

//

export default function useQuickOptions(options: any) {
  const [, setStore] = useZustand()

  React.useEffect(() => {
    setStore(old => {
      options.forEach((option: any) => {
        // if (old.quickOptions.find(d => d.label === option.label)) {
        //   return
        // }
        if (!option.priority) {
          console.warn(option)
          throw new Error(
            `Quick Options require a priority level! Offending Quick Option:

${JSON.stringify(option, null, 2)}
            `
          )
        }
        old.quickOptions[option.label] = option
      })
    })

    return () => {
      setStore(old => {
        const quickOptions = { ...old.quickOptions }

        options.forEach((option: any) => {
          delete quickOptions[option.label]
        })

        return {
          ...old,
          quickOptions,
        }
      })
    };
  }, [options, setStore])
}
