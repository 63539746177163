import { Navigate, Outlet, Route, RootRoute } from '@tanstack/react-router'
import * as React from 'react'
import { Signup } from './components/Onboarding'
import { PaaOnboarding } from './components/PaaOnboarding'
import { SleekPlanReroute } from './components/SleekPlanReroute'
import Usage from './components/Usage'
import { WorkspaceSerpUsageCalculator } from './components/WorkspaceSerpUsageCalculator'
import { Admin, AdminIndex } from './containers/Admin/Admin'
import { AdminPlans } from './containers/Admin/Plans'
import { AdminSubscriptions } from './containers/Admin/Subscriptions'
import { AdminEditPlan } from './containers/Admin/plans/EditPlan'
import { AdminNewPlan } from './containers/Admin/plans/NewPlan'
import { AuthRoot } from './containers/AuthRoot'
import { NoDashboardFound } from './containers/Dashboards'
import { Keyword_ } from './containers/Dashboards/SerpAiAlpha'
import { NavRoot } from './containers/NavRoot'
import NotFound from './containers/NotFound'
import { PricingCardsEmbed } from './containers/PricingCardsEmbed'
import { Rewards } from './containers/Rewards'
import { Project } from './containers/projects/Project'
import { UsageCalculatorEmbed } from './containers/UsageCalculatorEmbed'
import { ApiToken } from './containers/Users/ApiToken'
import { ApiTokens } from './containers/Users/ApiTokens'
import { InviteUsers, User, UserRoot } from './containers/Users/User'
import { Users } from './containers/Users/Users'
import { BillingRoot } from './containers/Workspaces/Billing'
import { Details } from './containers/Workspaces/Billing/Details'
import Invoice from './containers/Workspaces/Billing/Invoice'
import Invoices from './containers/Workspaces/Billing/Invoices'
import Overview from './containers/Workspaces/Billing/Overview'
import { ManageSubscription } from './containers/Workspaces/ManageSubscription'
import { Profile } from './containers/Workspaces/Profile'
import { Settings } from './containers/Workspaces/Settings'
import { KeywordSource } from './containers/keyword-manager/KeywordSource'
import {
  TopicClusterTab,
  TopicClusters,
} from './containers/topic-clusters/TopicClusters'
import { TimeRange } from './hooks/useTimeRanges'
import { TableState } from '@tanstack/react-table'
import { MonitoredUrl } from './containers/monitored-urls/MonitoredUrl'
import { MonitoredUrls } from './containers/monitored-urls/MonitoredUrls'
import { MonitoredUrlsImport } from './containers/monitored-urls/MonitoredUrlsImport'
import { Topic, TopicTab } from './containers/topics/Topic'
import { Topics } from './containers/topics/Topics'
import { UrlExplorer } from './containers/url-explorer/UrlExplorer'
import { multiSortBy } from './utils'
import { TopicPb } from './utils/proto'
import { QueryFacetId, allQueryFacets } from './utils/queryFacets'
import { KeywordManager } from './containers/keyword-manager/KeywordManager'
import { KeywordSource as KeywordSourceType } from './utils/Api'
import { Brands } from './containers/brands/Brands'
import Brand from './containers/brands/Brand'
import { Segments } from './containers/segments/Segments'
import { Segment } from './containers/segments/Segment'
import {
  Projects,
  ProjectsOverviewWidget,
} from './containers/projects/Projects'
import { Workspaces } from './containers/Workspaces/Workspaces'
import { BubbleChartState } from './components/BubbleChart'
import { SearchMonitoredUrlsTab } from './utils/searchParams'
import {
  PerformanceOverview,
  PerformanceOverviewHistoryWidget,
  PerformanceOverviewKeywordGroupsWidget,
  PerformanceOverviewKeywordsWidget,
  PerformanceOverviewMetricsWidget,
  PerformanceOverviewSegmentsWidget,
  PerformanceOverviewUrlsWidget,
} from './containers/Dashboards/PerformanceOverview'
import { SerpWidgetState } from './components/SerpResultsTables'
import Root from './containers/Root'
import { Home } from './containers/Home'
import PerformanceByKeywordGroup, {
  PerformanceByKeywordGroupKeywordGroupsWidget,
  PerformanceByKeywordGroupLosersWidget,
  PerformanceByKeywordGroupMetricsWidget,
  PerformanceByKeywordGroupTopWidget,
  PerformanceByKeywordGroupWinnersWidget,
} from './containers/Dashboards/PerformanceByKeywordGroup'
import {
  PerformanceByKeyword,
  PerformanceByKeywordKeywordsWidget,
  PerformanceByKeywordLosersWidget,
  PerformanceByKeywordMetricsWidget,
  PerformanceByKeywordTopWidget,
  PerformanceByKeywordWinnersWidget,
} from './containers/Dashboards/PerformanceByKeyword'
import PerformanceBySegment, {
  PerformanceBySegmentLosersWidget,
  PerformanceBySegmentMetricsWidget,
  PerformanceBySegmentSegmentsWidget,
  PerformanceBySegmentTopWidget,
  PerformanceBySegmentWinnersWidget,
} from './containers/Dashboards/PerformanceBySegment'
import PerformanceByUrl, {
  PerformanceByUrlLosersWidget,
  PerformanceByUrlMetricsWidget,
  PerformanceByUrlTopWidget,
  PerformanceByUrlUrlIds,
  PerformanceByUrlUrlsWidget,
  PerformanceByUrlWinnersWidget,
} from './containers/Dashboards/PerformanceByUrl'
import PerformanceSingleKeyword, {
  PerformanceSingleKeywordHistoryWidget,
  PerformanceSingleKeywordMetricsWidget,
  PerformanceSingleKeywordSerpWidget,
} from './containers/Dashboards/PerformanceSingleKeyword'
import { GoogleSearchConsole } from './containers/Dashboards/GoogleSearchConsole'
import ShareOfVoice, {
  ShareOfVoiceLosersWidget,
  ShareOfVoiceMetricsWidget,
  ShareOfVoiceRollupsWidget,
  ShareOfVoiceTopWidget,
  ShareOfVoiceWinnersWidget,
} from './containers/Dashboards/ShareOfVoice'
import ReputationSingleKeyword, {
  reputationKeywordDashboard,
} from './containers/Dashboards/ReputationSingleKeyword'
import { MetricId } from './utils/Metrics'

export const rootRoute = new RootRoute({
  validateSearch: search => {
    return search as {
      brandId?: string
      clusterId?: string
      clusterListFilters?: Record<string, unknown>
      clustersBubbleChart?: BubbleChartState
      clustersFocusDomainId?: string
      clustersSelectedMetricIds?: string[]
      clustersTable?: Partial<TableState>
      clustersView?: 'table' | 'cards'
      continueAs?: string
      expandedMonitoredUrlIds?: Record<string, boolean>
      expandedTopicIds?: Record<string, boolean>
      featuredSnippetSegmentId?: string
      googleSearchConsoleSiteUrl?: string
      groupBy?: QueryFacetId
      hubspotListId?: string
      import?: true
      importer?: 'google-search-console'
      keyword?: Keyword_ | undefined
      keywordGroup?: string
      keywordGroups?: string[]
      keywordId?: string
      keywordIds?: string[]
      keywordSource?: Partial<KeywordSourceType>
      keywordSourceId?: string
      loginFlow?: 'topic-clusters'
      metricId?: MetricId
      monitoredUrlId?: string
      monitoredUrlsBubbleChart?: BubbleChartState
      monitoredUrlsFilters?: Record<string, unknown>
      monitoredUrlsImportTabId?: 'domain' | 'sitemap' | 'text'
      monitoredUrlsSearch?: string
      monitoredUrlsTab?: SearchMonitoredUrlsTab
      newTopic?: Partial<TopicPb>
      projectId?: string
      reputationSingleKeywordMode?: 'reputation' | 'rank'
      rollup?: string
      segmentId?: string
      segmentIds?: string[]
      serpwooUploaded?: boolean
      sourceType?: string // This should be more strict
      stepIndex?: number
      timeRanges?: TimeRange[]
      topicClusterTab?: TopicClusterTab
      topicId?: string
      topicTab?: TopicTab
      topicsFilters?: Record<string, unknown>
      topicsTab?: 'flat' | 'tree'
      url?: string
      urlIds?: string[]
      workspaceId?: string
      projectsOverviewWidget?: ProjectsOverviewWidget
      serpWidget?: SerpWidgetState
      // widgets?: {
      //   metrics?: WidgetState
      //   top?: WidgetState
      //   winners?: WidgetState
      //   losers?: WidgetState
      //   //
      //   overview?: WidgetState
      //   keywordGroups?: WidgetState
      //   segments?: WidgetState
      //   urls?: WidgetState
      //   keywords?: WidgetState
      //   rollups?: WidgetState
      //   //
      //   serps?: WidgetState
      // }
    }
  },
  component: Root,
})

const routeSignUp = new Route({
  getParentRoute: () => rootRoute,
  path: 'sign-up',
  component: Signup,
})

const routeSignup = new Route({
  getParentRoute: () => rootRoute,
  path: 'signup',
  component: () => <Navigate to="/sign-up" />,
})

const routePaa = new Route({
  getParentRoute: () => rootRoute,
  path: 'paa',
  component: PaaOnboarding,
})

const routeUsageCalculator = new Route({
  getParentRoute: () => rootRoute,
  path: 'usage-calculator',
  component: UsageCalculatorEmbed,
})

const routePricingCards = new Route({
  getParentRoute: () => rootRoute,
  path: 'pricing-cards',
  component: PricingCardsEmbed,
})

const routeAuth = new Route({
  getParentRoute: () => rootRoute,
  id: 'auth',
  component: AuthRoot,
  postSearchFilters: [
    search => {
      // The types for the old v1 search params
      // We'll migrate these to the new search params in the next step
      const v1Search = search as {
        dashboard?: {
          teamId?: string
          rollupBy?: string
          rollupValue?: string
          groupBy?: string
        }
      }

      // Migrate dashboard.teamId to search.projectId
      if (v1Search?.dashboard?.teamId) {
        search.projectId = v1Search.dashboard.teamId
        delete v1Search.dashboard.teamId
      }

      // Migrate dashboard.rollupBy to search.rollup
      if (v1Search.dashboard?.rollupBy) {
        if (!search.rollup) {
          const queryFacet =
            allQueryFacets.find(
              d => d.filterKey === v1Search.dashboard!.rollupBy!
            ) ?? allQueryFacets[0]!

          search.rollup = `${queryFacet.id}${
            v1Search.dashboard.rollupValue
              ? `__${v1Search.dashboard.rollupValue}`
              : ''
          }`
        }

        delete v1Search.dashboard.rollupBy
        delete v1Search.dashboard.rollupValue
      }

      // Migrate dashboard.groupBy out of dashboard
      if (v1Search.dashboard?.groupBy) {
        if (!search.groupBy) {
          const queryFacet = allQueryFacets.find(
            d => d.filterKey === v1Search.dashboard!.groupBy!
          )

          if (queryFacet) {
            search.groupBy = queryFacet.id
          }
        }

        delete v1Search.dashboard.groupBy
      }

      // Migrate the rest of the dashboard out of dashboard
      if (v1Search.dashboard) {
        Object.assign(search, v1Search.dashboard)
        v1Search.dashboard = undefined!
      }

      // Sort search param keys to our liking
      return Object.fromEntries(
        multiSortBy(Object.keys(search), [
          d => (d === 'workspace' ? -1 : 0),
          d => (d === 'workspaceId' ? -1 : 0),
          d => (d === 'projectId' ? -1 : 0),
          d => (d === 'userId' ? -1 : 0),
          d => (d.includes('brand') ? -1 : 0),
          d => (d === 'rollup' ? -1 : 0),
          d => (d === 'metricId' ? -1 : 0),
          d => (d === 'keywordId' ? -1 : 0),
          d => (d === 'segmentId' ? -1 : 0),
        ]).map(key => [key, search[key as keyof typeof search]])
      )
    },
  ],
})

const routeIndex = new Route({
  getParentRoute: () => routeAuth,
  path: '/',
  component: () => <Home />,
})

const routeFeedbackLogin = new Route({
  getParentRoute: () => routeAuth,
  path: 'feedback-login',
  component: SleekPlanReroute,
})

const routeNav = new Route({
  getParentRoute: () => routeAuth,
  id: 'nav',
  component: NavRoot,
})

const routeProfile = new Route({
  getParentRoute: () => routeNav,
  path: 'profile',
  component: Profile,
})

const routeProjectsNew = new Route({
  getParentRoute: () => routeNav,
  path: 'projects/new',
  component: () => <Project isNew />,
})

const routeProject = new Route({
  getParentRoute: () => routeNav,
  path: 'projects/$projectId',
  component: Project,
})

const routeProjects = new Route({
  getParentRoute: () => routeNav,
  path: 'projects',
  component: Projects,
  preSearchFilters: [
    search => {
      return {
        ...search,
        projectsOverviewWidget: search?.projectsOverviewWidget,
      }
    },
  ],
})

const routeMonitoredUrlsImport = new Route({
  getParentRoute: () => routeNav,
  path: 'monitored-urls/import',
  component: MonitoredUrlsImport,
})

const routeMonitoredUrlsNew = new Route({
  getParentRoute: () => routeNav,
  path: 'monitored-urls/new',
  component: () => <MonitoredUrl isNew />,
})

const routeMonitoredUrl = new Route({
  getParentRoute: () => routeNav,
  path: 'monitored-urls/$monitoredUrlId',
  component: MonitoredUrl,
})

const routeMonitoredUrls = new Route({
  getParentRoute: () => routeNav,
  path: 'monitored-urls',
  component: MonitoredUrls,
})

const routeTopicsNew = new Route({
  getParentRoute: () => routeNav,
  path: 'topics/new',
  component: () => <Topic isNew />,
})

const routeTopic = new Route({
  getParentRoute: () => routeNav,
  path: 'topics/$topicId',
  component: Topic,
})

const routeTopics = new Route({
  getParentRoute: () => routeNav,
  path: 'topics',
  component: Topics,
})

const routeKeywordSourceNew = new Route({
  getParentRoute: () => routeNav,
  path: 'keyword-sources/new',
  component: () => <KeywordSource isNew />,
})

const routeKeywordSource = new Route({
  getParentRoute: () => routeNav,
  path: 'keyword-sources/$keywordSourceId',
  component: KeywordSource,
})

const routeKeywordManager = new Route({
  getParentRoute: () => routeNav,
  path: 'keyword-manager',
  component: KeywordManager,
})

const routeBrandsNew = new Route({
  getParentRoute: () => routeNav,
  path: 'brands/new',
  component: () => <Brand isNew />,
})

const routeBrand = new Route({
  getParentRoute: () => routeNav,
  path: 'brands/$brandId',
  component: Brand,
})

const routeBrands = new Route({
  getParentRoute: () => routeNav,
  path: 'brands',
  component: Brands,
})

const routeSegmentsNew = new Route({
  getParentRoute: () => routeNav,
  path: 'segments/new',
  component: () => <Segment isNew />,
})

const routeSegment = new Route({
  getParentRoute: () => routeNav,
  path: 'segments/$segmentId',
  component: Segment,
})

const routeSegments = new Route({
  getParentRoute: () => routeNav,
  path: 'segments',
  component: Segments,
})

const routeTopicClusters = new Route({
  getParentRoute: () => routeNav,
  path: 'keyword-research/topic-clusters',
  component: TopicClusters,
})

const routeUrlExplorer = new Route({
  getParentRoute: () => routeNav,
  path: 'keyword-research/url-explorer',
  component: UrlExplorer,
})

const routeInvite = new Route({
  getParentRoute: () => routeNav,
  path: 'invite',
  component: InviteUsers,
})

const routeRewards = new Route({
  getParentRoute: () => routeNav,
  path: 'rewards',
  component: Rewards,
})

const routeUser = new Route({
  getParentRoute: () => routeNav,
  path: 'users/$userId',
  component: UserRoot,
})

const routeUserIndex = new Route({
  getParentRoute: () => routeUser,
  path: '/',
  component: User,
})

const routeApiTokenNew = new Route({
  getParentRoute: () => routeUser,
  path: 'api-tokens/new',
  component: () => <ApiToken isNew />,
})

const routeApiToken = new Route({
  getParentRoute: () => routeUser,
  path: 'api-tokens/$apiTokenId',
  component: ApiToken,
})

const routeApiTokens = new Route({
  getParentRoute: () => routeUser,
  path: 'api-tokens',
  component: ApiTokens,
})

const routeUsers = new Route({
  getParentRoute: () => routeNav,
  path: 'users',
  component: Users,
})

const routeSubscription = new Route({
  getParentRoute: () => routeNav,
  path: 'subscription',
  component: ManageSubscription,
})

const routeBilling = new Route({
  getParentRoute: () => routeNav,
  path: 'billing',
  component: BillingRoot,
})

const routeBillingOverview = new Route({
  getParentRoute: () => routeBilling,
  path: '/',
  component: Overview,
})

const routeBillingUsage = new Route({
  getParentRoute: () => routeBilling,
  path: 'usage',
  component: Usage,
})

const routeBillingInvoices = new Route({
  getParentRoute: () => routeBilling,
  path: 'invoices',
  component: Invoices,
})

const routeBillingInvoice = new Route({
  getParentRoute: () => routeBilling,
  path: 'invoices/$invoiceId',
  component: Invoice,
})

const routeBillingDetails = new Route({
  getParentRoute: () => routeBilling,
  path: 'details',
  component: Details,
})

const routeBillingAccount = new Route({
  getParentRoute: () => routeBilling,
  path: 'account',
  component: () => <Navigate to="../overview" replace />,
})

const routeBillingUsageCalculator = new Route({
  getParentRoute: () => routeBilling,
  path: 'usage-calculator',
  component: WorkspaceSerpUsageCalculator,
})

const routeSettings = new Route({
  getParentRoute: () => routeNav,
  path: 'settings',
  component: Settings,
})

const routeDashboards = new Route({
  getParentRoute: () => routeNav,
  path: 'dashboards',
  component: Outlet,
})

const routeDashboardPerformanceOverview = new Route({
  getParentRoute: () => routeDashboards,
  path: 'performance-overview',
  component: PerformanceOverview,
  validateSearch: search =>
    search as {
      performanceOverviewMetricsWidget?: PerformanceOverviewMetricsWidget
      performanceOverviewHistoryWidget?: PerformanceOverviewHistoryWidget
      performanceOverviewSegmentsWidget?: PerformanceOverviewSegmentsWidget
      performanceOverviewKeywordGroupsWidget?: PerformanceOverviewKeywordGroupsWidget
      performanceOverviewUrlsWidget?: PerformanceOverviewUrlsWidget
      performanceOverviewKeywordsWidget?: PerformanceOverviewKeywordsWidget
    },
  postSearchFilters: [
    search => {
      return {
        ...search,
        performanceOverviewMetricsWidget:
          search.performanceOverviewMetricsWidget,
        performanceOverviewHistoryWidget:
          search.performanceOverviewHistoryWidget,
        performanceOverviewSegmentsWidget:
          search.performanceOverviewSegmentsWidget,
        performanceOverviewKeywordGroupsWidget:
          search.performanceOverviewKeywordGroupsWidget,
        performanceOverviewUrlsWidget: search.performanceOverviewUrlsWidget,
        performanceOverviewKeywordsWidget:
          search.performanceOverviewKeywordsWidget,
      }
    },
  ],
})

const routeDashboardPerformanceByKeywordGroup = new Route({
  getParentRoute: () => routeDashboards,
  path: 'performance-by-keyword-group',
  component: PerformanceByKeywordGroup,
  validateSearch: search =>
    search as {
      performanceByKeywordGroupMetricsWidget?: PerformanceByKeywordGroupMetricsWidget
      performanceByKeywordGroupTopWidget?: PerformanceByKeywordGroupTopWidget
      performanceByKeywordGroupWinnersWidget?: PerformanceByKeywordGroupWinnersWidget
      performanceByKeywordGroupLosersWidget?: PerformanceByKeywordGroupLosersWidget
      performanceByKeywordGroupKeywordGroupsWidget?: PerformanceByKeywordGroupKeywordGroupsWidget
    },
  postSearchFilters: [
    search => {
      return {
        ...search,
        performanceByKeywordGroupMetricsWidget:
          search.performanceByKeywordGroupMetricsWidget,
        performanceByKeywordGroupTopWidget:
          search.performanceByKeywordGroupTopWidget,
        performanceByKeywordGroupWinnersWidget:
          search.performanceByKeywordGroupWinnersWidget,
        performanceByKeywordGroupLosersWidget:
          search.performanceByKeywordGroupLosersWidget,
        performanceByKeywordGroupKeywordGroupsWidget:
          search.performanceByKeywordGroupKeywordGroupsWidget,
      }
    },
  ],
})

const routeDashboardPerformanceBySegment = new Route({
  getParentRoute: () => routeDashboards,
  path: 'performance-by-segment',
  component: PerformanceBySegment,
  validateSearch: search =>
    search as {
      performanceBySegmentMetricsWidget?: PerformanceBySegmentMetricsWidget
      performanceBySegmentTopWidget?: PerformanceBySegmentTopWidget
      performanceBySegmentWinnersWidget?: PerformanceBySegmentWinnersWidget
      performanceBySegmentLosersWidget?: PerformanceBySegmentLosersWidget
      performanceBySegmentSegmentsWidget?: PerformanceBySegmentSegmentsWidget
    },
  postSearchFilters: [
    search => {
      return {
        ...search,
        performanceBySegmentMetricsWidget:
          search.performanceBySegmentMetricsWidget,
        performanceBySegmentTopWidget: search.performanceBySegmentTopWidget,
        performanceBySegmentWinnersWidget:
          search.performanceBySegmentWinnersWidget,
        performanceBySegmentLosersWidget:
          search.performanceBySegmentLosersWidget,
        performanceBySegmentSegmentsWidget:
          search.performanceBySegmentSegmentsWidget,
      }
    },
  ],
})

const routeDashboardPerformanceByUrl = new Route({
  getParentRoute: () => routeDashboards,
  path: 'performance-by-url',
  component: PerformanceByUrl,
  validateSearch: search =>
    search as {
      performanceByUrlMetricsWidget?: PerformanceByUrlMetricsWidget
      performanceByUrlTopWidget?: PerformanceByUrlTopWidget
      performanceByUrlWinnersWidget?: PerformanceByUrlWinnersWidget
      performanceByUrlLosersWidget?: PerformanceByUrlLosersWidget
      performanceByUrlUrlsWidget?: PerformanceByUrlUrlsWidget
      performanceByUrlUrlIds?: PerformanceByUrlUrlIds
    },
  postSearchFilters: [
    search => {
      return {
        ...search,
        performanceByUrlMetricsWidget: search.performanceByUrlMetricsWidget,
        performanceByUrlTopWidget: search.performanceByUrlTopWidget,
        performanceByUrlWinnersWidget: search.performanceByUrlWinnersWidget,
        performanceByUrlLosersWidget: search.performanceByUrlLosersWidget,
        performanceByUrlUrlsWidget: search.performanceByUrlUrlsWidget,
        performanceByUrlUrlIds: search.performanceByUrlUrlIds,
      }
    },
  ],
})

const routeDashboardPerformanceByKeyword = new Route({
  getParentRoute: () => routeDashboards,
  path: 'performance-by-keyword',
  component: PerformanceByKeyword,
  validateSearch: search =>
    search as {
      performanceByKeywordMetricsWidget?: PerformanceByKeywordMetricsWidget
      performanceByKeywordTopWidget?: PerformanceByKeywordTopWidget
      performanceByKeywordWinnersWidget?: PerformanceByKeywordWinnersWidget
      performanceByKeywordLosersWidget?: PerformanceByKeywordLosersWidget
      performanceByKeywordKeywordsWidget?: PerformanceByKeywordKeywordsWidget
      keywordIds: string[]
    },
  postSearchFilters: [
    search => {
      return {
        ...search,
        performanceByKeywordMetricsWidget:
          search.performanceByKeywordMetricsWidget,
        performanceByKeywordTopWidget: search.performanceByKeywordTopWidget,
        performanceByKeywordWinnersWidget:
          search.performanceByKeywordWinnersWidget,
        performanceByKeywordLosersWidget:
          search.performanceByKeywordLosersWidget,
        performanceByKeywordKeywordsWidget:
          search.performanceByKeywordKeywordsWidget,
      }
    },
  ],
})

const routeDashboardPerformanceSingleKeyword = new Route({
  getParentRoute: () => routeDashboards,
  path: 'performance-single-keyword',
  component: PerformanceSingleKeyword,
  validateSearch: search =>
    search as {
      performanceSingleKeywordMetricsWidget?: PerformanceSingleKeywordMetricsWidget
      performanceSingleKeywordHistoryWidget?: PerformanceSingleKeywordHistoryWidget
      performanceSingleKeywordSerpWidget?: PerformanceSingleKeywordSerpWidget
      keywordId: string
    },
  postSearchFilters: [
    search => {
      return {
        ...search,
        performanceSingleKeywordMetricsWidget:
          search.performanceSingleKeywordMetricsWidget,
        performanceSingleKeywordHistoryWidget:
          search.performanceSingleKeywordHistoryWidget,
        performanceSingleKeywordSerpWidget:
          search.performanceSingleKeywordSerpWidget,
        keywordId: search.keywordId,
      }
    },
  ],
})

const routeDashboardGoogleSearchConsole = new Route({
  getParentRoute: () => routeDashboards,
  path: 'google-search-console',
  component: GoogleSearchConsole,
})

const routeDashboardShareOfVoice = new Route({
  getParentRoute: () => routeDashboards,
  path: 'competitive-share-of-voice',
  component: ShareOfVoice,
  validateSearch: search =>
    search as {
      shareOfVoicedMetricsWidget?: ShareOfVoiceMetricsWidget
      shareOfVoiceTopWidget?: ShareOfVoiceTopWidget
      shareOfVoiceWinnersWidget?: ShareOfVoiceWinnersWidget
      shareOfVoiceLosersWidget?: ShareOfVoiceLosersWidget
      shareOfVoiceRollupsWidget?: ShareOfVoiceRollupsWidget
    },
  postSearchFilters: [
    search => {
      return {
        ...search,
        shareOfVoicedMetricsWidget: search.shareOfVoicedMetricsWidget,
        shareOfVoiceTopWidget: search.shareOfVoiceTopWidget,
        shareOfVoiceWinnersWidget: search.shareOfVoiceWinnersWidget,
        shareOfVoiceLosersWidget: search.shareOfVoiceLosersWidget,
        shareOfVoiceRollupsWidget: search.shareOfVoiceRollupsWidget,
      }
    },
  ],
})

const routeDashboardReputationSingleKeyword = new Route({
  getParentRoute: () => routeDashboards,
  path: 'reputation-management-keyword',
  component: ReputationSingleKeyword,
})

const routeDashboardNotFound = new Route({
  getParentRoute: () => routeDashboards,
  path: '*',
  component: NoDashboardFound,
})

const routeWorkspaces = new Route({
  getParentRoute: () => routeNav,
  path: 'workspaces',
  component: Outlet,
})

const routeWorkspacesIndex = new Route({
  getParentRoute: () => routeWorkspaces,
  path: '/',
  component: Workspaces,
})

const routeAdmin = new Route({
  getParentRoute: () => routeNav,
  path: 'admin',
  component: Admin,
})

const routeAdminIndex = new Route({
  getParentRoute: () => routeAdmin,
  path: '/',
  component: AdminIndex,
})

const routeAdminPlansNew = new Route({
  getParentRoute: () => routeAdmin,
  path: 'plans/new',
  component: AdminNewPlan,
})

const routeAdminPlansEdit = new Route({
  getParentRoute: () => routeAdmin,
  path: 'plans/$planId',
  component: AdminEditPlan,
})

const routeAdminPlans = new Route({
  getParentRoute: () => routeAdmin,
  path: 'plans',
  component: AdminPlans,
})

const routeAdminSubscriptions = new Route({
  getParentRoute: () => routeAdmin,
  path: 'subscriptions',
  component: AdminSubscriptions,
})

const routeAdminNotFound = new Route({
  getParentRoute: () => routeAdmin,
  path: '*',
  component: NotFound,
})

export const routeTree = rootRoute.addChildren([
  routeSignUp,
  routeSignup,
  routePaa,
  routeUsageCalculator,
  routePricingCards,
  routeAuth.addChildren([
    routeIndex,
    routeFeedbackLogin,
    routeNav.addChildren([
      routeProfile,
      routeProjectsNew,
      routeProject,
      routeProjects,
      routeMonitoredUrlsImport,
      routeMonitoredUrlsNew,
      routeMonitoredUrl,
      routeMonitoredUrls,
      routeTopicsNew,
      routeTopic,
      routeTopics,
      routeKeywordSourceNew,
      routeKeywordSource,
      routeKeywordManager,
      routeBrandsNew,
      routeBrand,
      routeBrands,
      routeSegmentsNew,
      routeSegment,
      routeSegments,
      routeTopicClusters,
      routeUrlExplorer,
      routeInvite,
      routeRewards,
      routeUser.addChildren([
        routeUserIndex,
        routeApiTokenNew,
        routeApiToken,
        routeApiTokens,
      ]),
      routeUsers,
      routeSubscription,
      routeBilling.addChildren([
        routeBillingOverview,
        routeBillingUsage,
        routeBillingInvoice,
        routeBillingInvoices,
        routeBillingDetails,
        routeBillingAccount,
        routeBillingUsageCalculator,
      ]),
      routeSettings,
      routeDashboards.addChildren([
        routeDashboardPerformanceOverview,
        routeDashboardPerformanceByKeywordGroup,
        routeDashboardPerformanceBySegment,
        routeDashboardPerformanceByUrl,
        routeDashboardPerformanceByKeyword,
        routeDashboardPerformanceSingleKeyword,
        routeDashboardGoogleSearchConsole,
        routeDashboardShareOfVoice,
        routeDashboardReputationSingleKeyword,
        routeDashboardNotFound,
      ]),
      routeWorkspaces.addChildren([routeWorkspacesIndex]),
      routeAdmin.addChildren([
        routeAdminIndex,
        routeAdminPlansNew,
        routeAdminPlansEdit,
        routeAdminPlans,
        routeAdminSubscriptions,
        routeAdminNotFound,
      ]),
    ]),
  ]),
])
