import * as React from 'react'
import LabelWrap from '../components/LabelWrap'
import Toggle from '../components/Toggle'

//

const LabeledToggle = React.forwardRef(
  // @ts-expect-error  // Property 'label' does not exist on type '{}'.
  ({ label, className, style = {}, children, ...rest }, ref) => (
    <LabelWrap
      label={label}
      className={className}
      style={style}
      // @ts-expect-error  // Property 'error' does not exist on type '{}'.
      error={rest.error}
      // @ts-expect-error  // Property 'isValidating' does not exist on type '{}... Remove this comment to see the full error message
      isValidating={rest.isValidating}
    >
      <Toggle {...rest} ref={ref} />
    </LabelWrap>
  )
)

export default LabeledToggle
