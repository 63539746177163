import { useQuery } from 'react-query'
import { parseLastHrefPath } from '../utils'
import { fetchYoutubeDomains } from '../utils/Api'
import { queryKeyYouTubeDomains } from '../utils/Constants'

export default function useYoutubeVideos(href: string) {
  const username = parseLastHrefPath(href)

  return useQuery(
    [queryKeyYouTubeDomains, username],
    () => fetchYoutubeDomains(username!),
    {
      enabled: !!username && !!href,
    }
  )
}
