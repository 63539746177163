import * as React from 'react'
import useOnChange from './useOnChange'
import { MetricId, MetricPermutation } from '../utils/Metrics'

export default function useWidgetPaginationReset(
  state: {
    desc: boolean
    sortByMetricPermutation: MetricPermutation<MetricId>
  },
  setState: (updater: any) => void
) {
  const resetKey = React.useMemo(
    () => [
      state.sortByMetricPermutation?.id,
      state.sortByMetricPermutation?.aggregation,
      state.sortByMetricPermutation?.subAggregation,
      state.sortByMetricPermutation?.postAggregation,
      state.desc,
    ],
    [
      state.desc,
      state.sortByMetricPermutation?.id,
      state.sortByMetricPermutation?.aggregation,
      state.sortByMetricPermutation?.subAggregation,
      state.sortByMetricPermutation?.postAggregation,
    ]
  )

  useOnChange(
    resetKey,
    () => {
      setState((old: any) => ({
        ...old,
        offset: 0,
      }))
    },
    {
      onMount: false,
    }
  )
}
