import moment from 'moment'
import * as React from 'react'
import { AxisOptions, ChartOptions } from 'react-charts'
import Chart from '../components/Chart'
import ChartPlaceholder from '../components/ChartPlaceholder'
import ErrorComp from '../components/Error'
import useGetLatest from '../hooks/useGetLatest'
import { getDataValue } from '../utils'
import { GroupByQueryDatum } from '../utils/Api'
import { getDataColor } from '../utils/DataColors'
import { formatChange } from '../utils/Format'
import {
  MetricId,
  MetricPostAggregation,
  MetricSubAggregation,
  getMetricFormatter,
  getMetricRenderer,
  metricsById,
} from '../utils/Metrics'
import ErrorBoundary from './ErrorBoundary'
import NoData from './NoData'

//

export type TimeSeriesChartProps<TDatum> = {
  chartType?: 'line' | 'bar' | 'area'
  isFetching?: boolean
  limit?: number
  isLoading: boolean
  isError: boolean
  error: any
  subAggregation:
    | MetricSubAggregation
    | ((datum: TDatum) => MetricSubAggregation)
  displayPostAggregation: MetricPostAggregation
  sparkline?: boolean
  metricId: MetricId
} & Partial<
  Pick<
    ChartOptions<TDatum>,
    | 'data'
    | 'getSeriesStyle'
    | 'getDatumStyle'
    | 'onClickDatum'
    | 'primaryCursor'
    | 'secondaryCursor'
    | 'tooltip'
  >
>

export default function TimeSeriesChart<TDatum extends GroupByQueryDatum>(
  props: TimeSeriesChartProps<TDatum>
) {
  const datumsLength = props.data?.length
    ? Math.max(...props.data.map(d => d.data?.length))
    : 0

  const isSingleDay = datumsLength === 1
  const isChange = props.displayPostAggregation === 'change'
  const isAreaChange = props.chartType === 'area' && isChange

  return (
    <ErrorBoundary small={props.sparkline}>
      {(() => {
        if (isSingleDay) {
          return (
            <SingleDayTimeSeriesChart
              {...{ ...props, isChange, isAreaChange }}
            />
          )
        }

        return (
          <MultiDayTimeSeriesChart {...{ ...props, isChange, isAreaChange }} />
        )
      })()}
    </ErrorBoundary>
  )
}

function SingleDayTimeSeriesChart<TDatum extends GroupByQueryDatum>(
  props: TimeSeriesChartProps<TDatum> & {
    isChange: boolean
    isAreaChange: boolean
  }
) {
  const data = React.useMemo(
    () => [
      {
        label: moment.utc(props.data?.[0]?.data[0].requested).format('ll'),
        data: props.data,
      },
    ],
    [props.data]
  )

  // @ts-expect-error  // Type 'UserSerie<TDatum>[] | undefined' has no matc... Remove this comment to see the full error message
  type TResolvedDatum = (typeof data)[number]['data'][number]

  const primaryAxis = React.useMemo(
    (): AxisOptions<TResolvedDatum> => ({
      scaleType: 'band',
      position: 'left',
      getValue: datum => datum.label,
      show: !props.sparkline,
    }),
    [props.sparkline]
  )

  const { subAggregation } = props

  const getSubAggregation = useGetLatest(subAggregation)

  const metric = metricsById[props.metricId]
  const metricFormatter = getMetricFormatter(metric.id)
  const metricRenderer = getMetricRenderer(metric.id)

  const secondaryAxes = React.useMemo((): AxisOptions<TResolvedDatum>[] => {
    const subAggregation = getSubAggregation()
    return [
      {
        elementType: metric.inverted && !props.isChange ? 'line' : 'bar',
        scaleType: 'linear',
        getValue: datum =>
          getDataValue(datum.data[0], {
            id: metric.id,
            aggregation: metric.aggregations[0],
            subAggregation:
              typeof subAggregation === 'function'
                ? subAggregation(datum.data[0])
                : subAggregation,
            postAggregation: props.displayPostAggregation,
          }),
        position: 'bottom',
        invert: metric.inverted,
        show: !props.sparkline,
        showDatumElements: true,
        min:
          // props.displayPostAggregation === POST_AGGREGATION_VALUE ||
          metric.type === 'decimal' ? 0 : undefined,
        // max: metric.type === 'decimal' ? 1 : undefined,
        // hardMin: metric.type === 'decimal' ? 0 : undefined,
        // @ts-expect-error  // Type '0.5 | 500 | 0.01 | 100 | 10 | null' is not a... Remove this comment to see the full error message
        minDomainLength: metric.minDomainLength,
        formatters: {
          scale:
            props.displayPostAggregation === 'change'
              ? d =>
                  formatChange(metric.inverted ? -d : d, {
                    format: dd => metricFormatter(dd, { string: true }),
                    string: true,
                  }) as string
              : d => metricFormatter(d, { string: true }) as string,
          tooltip:
            props.displayPostAggregation === 'change'
              ? d =>
                  formatChange(metric.inverted ? -d : d, {
                    format: dd => metricFormatter(dd, { string: true }),
                  })
              : d => metricRenderer(d),
        },
      },
    ]
  }, [
    getSubAggregation,
    metric.inverted,
    metric.type,
    metric.minDomainLength,
    metric.id,
    metric.aggregations,
    props.isChange,
    props.sparkline,
    props.displayPostAggregation,
    metricFormatter,
    metricRenderer,
  ])

  if (props.isAreaChange) {
    secondaryAxes[0].stacked = false
  }

  const options: ChartOptions<TResolvedDatum> = {
    ...(props as unknown as ChartOptions<TResolvedDatum>),
    // @ts-expect-error  // Type '{ label: string; data: UserSerie<TDatum>[] |... Remove this comment to see the full error message
    data,
    primaryAxis,
    secondaryAxes,
    primaryCursor: props.primaryCursor ?? true,
    secondaryCursor: props.secondaryCursor ?? true,
    getSeriesStyle: (datum, ...args) => {
      return {
        opacity: 0,
        ...props.getSeriesStyle?.(datum as any, ...args),
      }
    },
    getDatumStyle: (datum, ...args) => {
      return {
        opacity: 1,
        color: datum.originalDatum.color ?? getDataColor(datum.seriesIndex),
        r: 7,
        ...props.getDatumStyle?.(datum as any, ...args),
      }
    },
    tooltip: !(props.tooltip ?? true)
      ? false
      : ({
          ...(typeof props.tooltip === 'object' ? props.tooltip : {}),
          formatters: {
            scale:
              props.displayPostAggregation === 'change'
                ? (d: any) =>
                    formatChange(metric.inverted ? -d : d, {
                      format: dd => metricFormatter(dd, { string: true }),
                      string: true,
                    }) as string
                : (d: any) => metricFormatter(d, { string: true }),
            tooltip:
              props.displayPostAggregation === 'change'
                ? (d: any) =>
                    formatChange(metric.inverted ? -d : d, {
                      format: dd => metricFormatter(dd, { string: true }),
                    })
                : (d: any) => metricRenderer(d),
          },
          invert: metric.inverted,
          anchor: 'right center',
        } as any),
  }

  return props.isLoading ? (
    <ChartPlaceholder type="line" series={props.limit} />
  ) : props.isError ? (
    <ErrorComp error={props.error} small={props.sparkline} />
  ) : !data?.length || !data[0].data?.length ? (
    <NoData />
  ) : (
    <Chart
      {...{
        options,
        style: {
          opacity: props.isFetching ? 0.5 : 1,
        },
      }}
    />
  )
}

function MultiDayTimeSeriesChart<TDatum extends GroupByQueryDatum>(
  props: TimeSeriesChartProps<TDatum> & {
    isChange: boolean
    isAreaChange: boolean
  }
) {
  const primaryAxis = React.useMemo(
    (): AxisOptions<TDatum> => ({
      scaleType: 'time',
      position: 'bottom',
      // @ts-expect-error  // No overload matches this call.
      getValue: datum => new Date(datum.requested),
      show: !props.sparkline,
    }),
    [props.sparkline]
  )

  const elementType =
    props.chartType === 'area' && props.isAreaChange
      ? 'line'
      : props.chartType ?? 'line'

  const { subAggregation } = props

  const getSubAggregation = useGetLatest(subAggregation)

  const metric = metricsById[props.metricId]
  const metricFormatter = getMetricFormatter(metric.id)
  const metricRenderer = getMetricRenderer(metric.id)

  const secondaryAxes = React.useMemo((): AxisOptions<TDatum>[] => {
    const subAggregation = getSubAggregation()
    return [
      {
        elementType,
        scaleType: 'linear',
        stacked: elementType !== 'line',
        getValue: datum =>
          getDataValue(datum, {
            id: metric.id,
            aggregation: metric.aggregations[0],
            subAggregation:
              typeof subAggregation === 'function'
                ? subAggregation(datum)
                : subAggregation,
            postAggregation: props.displayPostAggregation,
          }),
        position: 'left',
        invert: metric.inverted,
        show: !props.sparkline,
        showDatumElements: false,
        min:
          // props.displayPostAggregation === POST_AGGREGATION_VALUE ||
          metric.type === 'decimal' ? 0 : undefined,
        // max: metric.type === 'decimal' ? 1 : undefined,
        // hardMin: metric.type === 'decimal' ? 0 : undefined,
        // @ts-expect-error  // Type '0.5 | 500 | 0.01 | 100 | 10 | null' is not a... Remove this comment to see the full error message
        minDomainLength: metric.minDomainLength,
        formatters: {
          scale:
            props.displayPostAggregation === 'change'
              ? d =>
                  formatChange(metric.inverted ? -d : d, {
                    format: dd => metricFormatter(dd, { string: true }),
                    string: true,
                  }) as string
              : d => metricFormatter(d, { string: true }) as string,
          tooltip:
            props.displayPostAggregation === 'change'
              ? d =>
                  formatChange(metric.inverted ? -d : d, {
                    format: dd => metricFormatter(dd, { string: true }),
                  })
              : d => metricRenderer(d), // TODO add item-rank formatter
        },
      },
    ]
  }, [
    getSubAggregation,
    elementType,
    metric.inverted,
    metric.type,
    metric.minDomainLength,
    metric.id,
    metric.aggregations,
    props.sparkline,
    props.displayPostAggregation,
    metricFormatter,
    metricRenderer,
  ])

  if (props.isAreaChange) {
    secondaryAxes[0].stacked = false
  }

  const options: ChartOptions<TDatum> = {
    ...props,
    data: props.data ?? [],
    primaryAxis,
    secondaryAxes,
    primaryCursor: props.primaryCursor ?? true,
    secondaryCursor: props.secondaryCursor ?? true,
    getSeriesStyle: (series, ...args) => {
      return {
        color: series.originalSeries.color,
        ...props.getSeriesStyle?.(series as any, ...args),
      }
    },
    tooltip: !(props.tooltip ?? true)
      ? false
      : {
          ...(typeof props.tooltip === 'object' ? props.tooltip : {}),
          invert: metric.inverted,
        },
  }

  return props.isLoading ? (
    <ChartPlaceholder type="line" series={props.limit} />
  ) : props.isError ? (
    <ErrorComp error={props.error} small={props.sparkline} />
  ) : !props.data?.length || !props.data[0].data?.length ? (
    <NoData />
  ) : (
    <Chart
      {...{
        options,
        style: {
          opacity: props.isFetching ? 0.5 : 1,
        },
      }}
    />
  )
}
