import { css } from '@emotion/css'
import { Route } from '@tanstack/react-router'
import axios from 'axios'
import * as React from 'react'
import { useField, useForm } from 'react-form'
import { BiGhost, BiImport } from 'react-icons/bi'
import {
  FaArrowLeft,
  FaArrowRight,
  FaCheck,
  FaCheckCircle,
  FaCheckSquare,
  FaInfoCircle,
  FaQuestionCircle,
  FaRegCircle,
  FaRegSquare,
} from 'react-icons/fa'
import { FiPlus } from 'react-icons/fi'
import { IoIosWarning } from 'react-icons/io'
import { twMerge } from 'tailwind-merge'
import { Brand, Workspace } from '../../openapi'

import Button from '../components/Button'
import Caption from '../components/Caption'
import Clickable from '../components/Clickable'
import Spinner from '../components/Spinner'
import TextField from '../components/TextField'
import BrandFormOnboarding from '../containers/brands/BrandFormOnboarding'
import { ProjectForm } from '../containers/projects/ProjectForm'
import ProfileForm from '../containers/Users/ProfileForm'
import {
  useBrand,
  useBrands,
  useCreateBrand,
  useSaveBrand,
} from '../hooks/brands'
import {
  useCreateKeywordSource,
  useKeywordSource,
  useSaveKeywordSource,
} from '../hooks/keywordSources'
import { useProfileQuery } from '../hooks/profile'
import { useCreateProject, useSaveProject, useProject } from '../hooks/projects'
import useAuth from '../hooks/useAuth'
import useLocalState from '../hooks/useLocalState'
import useLogout from '../hooks/useLogout'
import useModal from '../hooks/useModal'
import useNavigate from '../hooks/useNavigate'
import useSaveProfile from '../hooks/useSaveProfile'
import useSearch from '../hooks/useSearch'
import useSlugGeneration from '../hooks/useSlugGeneration'
import {
  useCreateWorkspace,
  useSaveWorkspace,
  useWorkspaceOptions,
} from '../hooks/workspaces'
import { getHubSpotIDFromCookie, sortBy } from '../utils'
import { Project } from '../utils/Api'
import {
  BRAND_PROPERTY_TYPE_DOMAIN,
  BRAND_TYPE_COMPETITOR_DIRECT,
  KEYWORD_SOURCE_TYPE_BASIC,
} from '../utils/Constants'
import { getDataColor } from '../utils/DataColors'
import {
  phraseGroupsToPhraseText,
  phraseTextToPhraseGroups,
} from '../utils/Phrases'
import Validate from '../utils/Validate'
import { twConfig } from '../utils/tailwind'
import Anchor from './Anchor'
import { Auth } from './Auth'
import DevicesField from './DevicesField'
import { GoogleSearchConsoleModal } from './GoogleSearchConsoleModal'
import Link from './Link'
import { LocalesField } from './LocalesField'
import SchedulesField from './SchedulesField'
import Select from './Select'
import Separator from './Separator'
import TextAreaField from './TextAreaField'
import Tooltip from './Tooltip'
import { TopicClustersSignup } from '../containers/topic-clusters/TopicClustersSignup'
import { HiLightBulb } from 'react-icons/hi'
import { SerpwooSignup } from '../containers/serpwoo/SerpWooSignup'

//

type Step = {
  id:
    | 'auth'
    | 'profile'
    | 'createWorkspace'
    | 'createProject'
    | 'createBrand'
    | 'createKeywordSource'
    | 'addCompetitors'
    | 'learn'
  required: boolean
  label: React.ReactNode
  step: (stage: StepStage) => React.ReactNode
}

type StepStage = 'pending' | 'done' | 'current'

type InviteToken = {
  workspaceId: number
  workspaceSlug: string
  workspaceName: string
  userId: number
  userEmail: string
  userDisplayName: string
  userGivenName: string
  userFamilyName: string
  userPicture: string
  invitedByUserId: number
  invitedByUserEmail: string
  invitedByUserDisplayName: string
  invitedByUserGivenName: string
  invitedByUserFamilyName: string
  invitedByUserPicture: string
}

export function Signup() {
  const search = useSearch()

  if (search.loginFlow === 'topic-clusters') {
    return <TopicClustersSignup />
  }

  if (search.loginFlow === 'serpwoo') {
    return <SerpwooSignup />
  }

  return <Onboarding />
}

const TodoCircle = () => (
  <div className="relative">
    <FaRegCircle
      className="animation-duration-[3s]
      absolute inline-flex h-full w-full
origin-center animate-ping text-yellow-500"
    />
    <FaRegCircle className="relative" />
  </div>
)

const appSumoPlansById = {
  appsumo_tier1: {
    prefix: 'Basic',
    name: 'Basic License Tier 1',
    pulls: '5,000',
  },
  appsumo_tier2: {
    prefix: 'Basic',
    name: 'Basic License Tier 2',
    pulls: '10,000',
  },
  appsumo_tier3: {
    prefix: 'Advanced',
    name: 'Advanced License Tier 3',
    pulls: '21,000',
  },
}

export const defaultHubspotListIds = [259]

export function Onboarding() {
  const profileQuery = useProfileQuery()
  const [auth] = useAuth()

  const {
    isNewWorkspace,
    appsumoLicenseId,
    appsumoPlanId,
    workspaceSlug,
    teamId,
    brandId,
    keywordSourceId,
    inviteToken: inviteTokenBase,
    competitorsDone,
    hubspotListId,
  } = useSearch()
  const logoWhiteSrc = '/img/nozzle-white.svg'
  const logoSrc = logoWhiteSrc

  // @ts-expect-error  // Type 'unknown' is not assignable to type 'InviteTo... Remove this comment to see the full error message
  const inviteToken: InviteToken | undefined = (() => {
    try {
      return JSON.parse(window.atob(inviteTokenBase as string))
    } catch (err) {
      return undefined
    }
  })()

  const appsumoPlan =
    appSumoPlansById[appsumoPlanId as keyof typeof appSumoPlansById]

  const isValidProfile = [
    profileQuery.data?.givenName,
    profileQuery.data?.familyName,
    profileQuery.data?.displayName,
    profileQuery.data?.email,
  ].every(Boolean)

  const workspace = profileQuery.data?.userWorkspaces.find(
    d => d.workspace.slug === workspaceSlug
  )?.workspace

  const projectQuery = useProject({
    workspaceId: workspace?.id,
    id: teamId,
  })
  const project = projectQuery.data

  const brandQuery = useBrand({
    workspaceId: workspace?.id,
    id: brandId,
  })
  const brand = brandQuery.data

  const keywordSourceQuery = useKeywordSource({
    workspaceId: workspace?.id,
    id: keywordSourceId as number,
  })
  const keywordSource = keywordSourceQuery.data

  const brandsQuery = useBrands({
    workspaceId: workspace?.id,
    // @ts-expect-error  // Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
    projectId: project?.id,
  })

  const isValidKeywordSource = [
    // @ts-expect-error  // Property 'phraseGroups' does not exist on type '{ ... Remove this comment to see the full error message
    keywordSource?.config?.phraseGroups?.length,
    // @ts-expect-error  // Property 'devices' does not exist on type '{ value... Remove this comment to see the full error message
    keywordSource?.config?.devices?.length,
    // @ts-expect-error  // Property 'localeIds' does not exist on type '{ val... Remove this comment to see the full error message
    keywordSource?.config?.localeIds?.length,
    keywordSource?.schedules?.length,
  ].every(Boolean)

  const competitors = brandsQuery.data?.filter(
    d => d.type === BRAND_TYPE_COMPETITOR_DIRECT
  )

  const [doneLearning, setDoneLearning] = React.useState(false)

  const steps: Step[] = [
    {
      id: 'auth',
      required: auth.stage !== 'loggedIn',
      label: <>Sign in to create an account</>,
      // @ts-expect-error  // Type 'InviteToken | undefined' is not assignable t... Remove this comment to see the full error message
      step: stage => <StepAuth inviteToken={inviteToken} />,
    },
    {
      id: 'profile',
      required: !isValidProfile,
      label: <>Tell us about yourself!</>,
      // @ts-expect-error  // Type 'InviteToken | undefined' is not assignable t... Remove this comment to see the full error message
      step: stage => <StepUserInfo inviteToken={inviteToken} />,
    },
    {
      id: 'createWorkspace',
      required: !workspace,
      label: <>Create a Workspace</>,
      step: stage => (
        <StepWorkspace
          workspace={workspace}
          hubspotListIds={
            hubspotListId
              ? [hubspotListId]
              : appsumoLicenseId
              ? [260]
              : defaultHubspotListIds
          }
        />
      ),
    },
    {
      id: 'createProject',
      required: !project,
      label: (
        <>
          Create a Project
          {workspace ? (
            <>
              {' '}
              in <strong>{workspace.name}</strong>
            </>
          ) : null}
        </>
      ),
      step: stage => <StepProject workspace={workspace} project={project} />,
    },
    {
      id: 'createBrand',
      required: !brand,
      label: (
        <>
          Setup Primary Domain
          {project ? (
            <>
              {' '}
              for <strong>{project.name}</strong>
            </>
          ) : null}
        </>
      ),
      step: stage => (
        <StepBrand workspace={workspace} project={project} brand={brand} />
      ),
    },
    {
      id: 'createKeywordSource',
      required: !isValidKeywordSource,
      label: (
        <>
          Add Keywords
          {project ? (
            <>
              {' '}
              to <strong>{project.name}</strong>
            </>
          ) : null}
        </>
      ),
      step: stage => (
        <StepKeywords
          workspace={workspace}
          project={project}
          keywordSource={keywordSource}
        />
      ),
    },
    {
      id: 'addCompetitors',
      required: !competitorsDone,
      label: (
        <>
          Add Competitors
          {project ? (
            <>
              {' '}
              to <strong>{project.name}</strong>
            </>
          ) : null}
        </>
      ),
      step: stage => (
        <StepCompetitors
          {...{
            workspace,
            project,
            brand,
            competitors,
            competitorsDone,
          }}
        />
      ),
    },
    {
      id: 'learn',
      required: !doneLearning,
      label: <>Learn up!</>,
      step: stage => (
        // @ts-expect-error  // Type '{ workspace: Workspace | undefined; project: Te... Remove this comment to see the full error message
        <StepLearn
          {...{
            workspace,
            project,
            brand,
            competitors,
            doneLearning,
          }}
        />
      ),
    },
  ]

  return (
    <div className={twMerge('dark flex flex-1 overflow-hidden')}>
      <div
        className={twMerge(
          `relative z-10 w-full max-w-full overflow-auto bg-blue-900 text-white lg:w-[400px]  xl:w-[500px] 2xl:w-[600px]`,
          css({ boxShadow: '[0 0 80px rgba(0,0,0,.4)]' })
        )}
      >
        <div className="h-20" />
        <div className="p-8">
          {isNewWorkspace ? (
            <>
              <div className="text-center">
                <Clickable
                  className="text-lg font-bold text-blue-400"
                  onClick={() => window.history.go(-1)}
                >
                  <FaArrowLeft className="inline" /> Go Back
                </Clickable>
              </div>
              <div className="h-6" />
              <div className="text-center text-4xl font-bold">
                One new Workspace, comin' up!
              </div>
            </>
          ) : appsumoLicenseId ? (
            <div>
              <div className="text-center text-4xl font-bold">
                <span role="img" aria-label="Party Emoji">
                  🎉
                </span>{' '}
                Thanks for purchasing Nozzle on AppSumo!
              </div>
              <div className="h-8" />
              <div className="flex flex-col items-center">
                <div className="text-center text-2xl font-light">
                  Your{' '}
                  <span className="font-bold text-yellow-400">
                    {appsumoPlan.name}
                  </span>{' '}
                  plan includes:
                </div>
                <div className="h-4" />
                <div className="flex flex-col items-start gap-2 font-bold">
                  <div>
                    <FaCheck className="mr-2 inline text-xl text-green-500" />{' '}
                    {appsumoPlan.prefix}
                    Plan features
                  </div>
                  <div>
                    <FaCheck className="mr-2 inline text-xl text-green-500" />{' '}
                    All future {appsumoPlan.prefix} Plan updates
                  </div>
                  <div>
                    <FaCheck className="mr-2 inline text-xl text-green-500" />{' '}
                    {appsumoPlan.pulls} pulls per month
                  </div>
                </div>
              </div>
              <div className="h-4" />
            </div>
          ) : (
            <div className="text-center text-5xl font-bold">
              Welcome to Nozzle!
            </div>
          )}
        </div>
        <div className="h-4" />
        {inviteToken ? (
          <div className="mx-auto w-[300px] text-center">
            <div className="h-6" />
            <div className="rounded-lg bg-blue-800 p-2 px-3 text-lg">
              <span role="img" aria-label="tada">
                🎉
              </span>{' '}
              You've been invited by{' '}
              <strong className="text-yellow-400">
                {inviteToken.invitedByUserDisplayName}
              </strong>{' '}
              to join the{' '}
              <strong className="text-yellow-400">
                {inviteToken.workspaceName}
              </strong>{' '}
              workspace!
            </div>
            <div className="h-4" />
          </div>
        ) : null}
        <div className="mx-auto w-[300px]">
          <p className="text-center text-lg">
            You're just a few simple steps away from tracking all the rankings
            you can dream of!
          </p>
        </div>
        <div className="h-16" />

        {steps.map((step, i) => {
          const previousSteps = steps.slice(0, i)
          const previousStep = steps[i - 1]

          const stage: StepStage = previousSteps.some(d => d.required)
            ? 'pending'
            : step.required
            ? 'current'
            : 'done'

          return (
            <div key={i}>
              <div disabled={stage === 'pending'}>
                <div
                  className={twMerge(
                    `sticky top-0 z-10 bg-blue-800 p-4 text-white`,
                    stage !== 'done' && `bg-blue-600`
                  )}
                >
                  <div className="flex items-center gap-3">
                    <div className="text-5xl">
                      {stage === 'current' ? (
                        <TodoCircle />
                      ) : stage === 'pending' ? (
                        <FaRegCircle />
                      ) : (
                        <FaCheckCircle className="text-green-500" />
                      )}
                    </div>
                    <div>
                      <div className="text-2xl font-black">Step {i + 1}</div>
                      <div className="font-light">{step.label}</div>
                    </div>
                  </div>
                </div>
                {stage === 'pending' ? null : step.step(stage)}
              </div>
              <div className="h-1" />
            </div>
          )
        })}

        <div className="h-48`" />
      </div>
      <div
        className="hidden flex-1 items-center justify-center
        bg-blue-800 lg:flex"
        style={{
          background: `radial-gradient(
            ${twConfig.theme.colors.blue[700]}, ${twConfig.theme.colors.blue[900]} 90%
            )`,
        }}
      >
        <div className="flex flex-col items-center space-y-8 p-8">
          <img src={logoSrc} alt="" className="w-[20vw] max-w-[300px]" />
          <div className="text-center text-2xl font-light text-white">
            The <span className="font-black">most powerful rank tracker</span>{' '}
            on the planet.
          </div>
          <Anchor
            href="https://meetings.hubspot.com/b-norwood/nozzle-demo"
            target="_blank"
            rel="noreferrer"
            className={twMerge(
              'flex animate-bounce items-center gap-2 rounded-lg bg-gradient-to-r from-green-600 via-green-500 to-green-600  p-3 px-5 text-xl font-medium !text-white [animation-duration:2s]',
              'font-black uppercase'
            )}
          >
            <HiLightBulb className="text-3xl text-yellow-200" />
            <div className={twMerge('')}>Schedule a Demo</div>
          </Anchor>
        </div>
      </div>
    </div>
  )
}

function StepAuth({ inviteToken }: { inviteToken: InviteToken }) {
  const profileQuery = useProfileQuery()
  const logout = useLogout()
  const navigate = useNavigate()
  const [showWorkspaces, setShowWorkspaces] = React.useState(false)
  const { isNewWorkspace, workspaceSlug } = useSearch()
  const workspacesOptions = useWorkspaceOptions()

  return (
    <>
      {inviteToken ? (
        <div>
          <div className="h-8" />
          <div className="mx-auto w-[300px] rounded-lg bg-blue-800 p-2 px-3">
            <FaInfoCircle className="inline" /> For your invitation to work
            properly, you should sign in with a provider that uses{' '}
            <strong className="text-yellow-400">{inviteToken.userEmail}</strong>
          </div>
        </div>
      ) : null}
      <div className="py-8">
        <Auth emailHint={inviteToken?.userEmail} />
      </div>
      {isNewWorkspace ? null : workspacesOptions.length ? (
        workspaceSlug && !showWorkspaces ? (
          <>
            <div className="text-center">
              <FaQuestionCircle className="inline-block text-blue-500" /> Switch
              to one of your existing workspaces instead?
            </div>
            <div className="h-1" />
            <div className="text-center">
              <Clickable
                className="text-blue-300"
                onClick={() => setShowWorkspaces(true)}
              >
                Show {workspacesOptions.length} Workspaces
              </Clickable>
            </div>
            <div className="h-8" />
          </>
        ) : (
          <div>
            <div className="text-center">
              <FaQuestionCircle className="inline-block text-blue-500" /> Switch
              to one of your existing workspaces instead?
            </div>
            <div className="h-2" />
            <div className="mx-auto w-[300px] rounded-lg border border-gray-700">
              <Select
                options={workspacesOptions}
                inline={workspacesOptions.length < 20}
                onChange={value => {
                  navigate({ to: `/`, search: { workspaceId: value.id } })
                }}
              />
            </div>
            <div className="h-2" />
            <div className="text-center text-sm">
              Can't find your workspace?{' '}
              <Clickable className="text-blue-300" onClick={() => logout()}>
                Switch accounts
              </Clickable>
            </div>
            <div className="h-8" />
          </div>
        )
      ) : profileQuery.data ? (
        <div className="text-center">
          <FaQuestionCircle className="inline-block text-blue-500" /> Expecting
          to see an existing workspace? Try{' '}
          <Clickable className="text-blue-300" onClick={() => logout()}>
            switching accounts
          </Clickable>
          <div className="h-8" />
        </div>
      ) : null}
    </>
  )
}

function StepUserInfo({ inviteToken }: { inviteToken: InviteToken }) {
  const saveProfile = useSaveProfile()

  return (
    <div className="p-8">
      <ProfileForm showUserId={false} onSubmit={saveProfile} />
    </div>
  )
}

function StepWorkspace({ workspace, hubspotListIds }: any) {
  const profileQuery = useProfileQuery()
  const createWorkspace = useCreateWorkspace()
  const saveWorkspace = useSaveWorkspace()
  const navigate = useNavigate()
  const [, setShowOnboarding] = useLocalState('showOnboarding', false)

  const onSubmit = async (newWorkspace: any) => {
    await (workspace?.id
      ? saveWorkspace(newWorkspace)
      : createWorkspace({
          ...newWorkspace,
          hubspotListIds,
        }))

    navigate({
      search: old => ({ ...old, workspaceSlug: newWorkspace.slug }),
      replace: true,
    })

    setShowOnboarding(true)
  }

  const {
    Form,
    meta: { isSubmitting },
    formContext,
  } = useForm({
    onSubmit,
    defaultValues: workspace,
  })
  const [isAutoSlug, setIsAutoSlug] = React.useState(true)

  const nameField = useField('name', { formContext })
  const slugField = useField('slug', { formContext })

  useSlugGeneration(workspace ? false : isAutoSlug, nameField, slugField)

  return (
    <div className="p-8">
      <Form>
        <Caption>
          <FaInfoCircle className="inline" /> This will typically be your
          company name. If you are an agency, have this be the name of your
          agency, and you will be able to create separate projects for each of
          your clients.
        </Caption>
        <div className="h-4" />
        <div>
          <TextField
            field="name"
            label="Company Name"
            placeholder="My Company"
            validate={Validate.required('A name is required.')}
            className="w-80 flex-none"
          />
        </div>
        <div className="h-3" />
        <div>
          <TextField
            field="slug"
            label={`Slug${
              !workspace?.id && isAutoSlug ? ' (Auto Generated)' : ''
            }`}
            placeholder="mycompany"
            validate={Validate.workspaceSlug()}
            disabled={workspace || isAutoSlug}
            className="w-80 flex-none"
          />
        </div>

        <div className="h-1" />
        {!workspace ? (
          <>
            <Button
              size="xs"
              color="blue-800"
              onClick={() =>
                setIsAutoSlug(old => {
                  if (!old) {
                    slugField.setValue('')
                  }
                  return !old
                })
              }
            >
              {isAutoSlug ? 'Edit Slug' : 'Auto Generate Slug'}
            </Button>
            <div className="h-2" />
          </>
        ) : null}
        <Caption>
          <IoIosWarning className="inline text-yellow-400" /> Workspace slugs
          cannot be changed after creation.
        </Caption>
        <div className="h-4" />
        <Button
          type="submit"
          size="base"
          color={workspace?.id ? 'blue-500' : 'green-500'}
        >
          {isSubmitting ? (
            <>
              <Spinner color="white" />{' '}
              {workspace ? 'Saving Workspace' : 'Creating Workspace'}
            </>
          ) : (
            <>
              <FaCheck /> {workspace ? 'Save Workspace' : 'Create Workspace'}
            </>
          )}
        </Button>
      </Form>
    </div>
  )
}

function StepProject({ workspace, project }: any) {
  const createProject = useCreateProject()
  const saveProject = useSaveProject()
  const navigate = useNavigate()

  const onSubmit = async (values: any) => {
    values = {
      ...values,
      workspaceId: workspace.id,
    }

    try {
      const newProject = await (project
        ? saveProject(values)
        : createProject(values))

      navigate({
        search: old => ({ ...old, teamId: newProject.id }),
      })
    } catch (err) {
      console.error(err)
    }
  }

  const projectValues = React.useMemo(
    () => project ?? { name: workspace.name },
    [project, workspace.name]
  )

  return (
    <div className="p-8">
      <ProjectForm project={projectValues} onSubmit={onSubmit} showId={false} />
    </div>
  )
}

function StepBrand({ workspace, project, brand }: any) {
  const createBrand = useCreateBrand()
  const saveBrand = useSaveBrand()
  const navigate = useNavigate()

  const onSubmit = async (values: any) => {
    values = {
      ...values,
      workspaceId: workspace.id,
      teamId: project.id,
    }

    try {
      const newBrand = await (brand ? saveBrand(values) : createBrand(values))

      navigate({
        search: old => ({ ...old, brandId: newBrand.id }),
      })
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div className="p-8">
      <Caption>
        <FaInfoCircle className="inline" /> This should be the primary domain
        you want to track for the project that was just set up. You’ll be able
        to add competitors later. You can also add your Google My Business ID
        (should be a long number with no letters) for local tracking. These can
        be added or changed later.
      </Caption>

      <div className="h-4" />

      <BrandFormOnboarding
        brand={brand}
        onSubmit={onSubmit}
        onboardingType="Owned"
        submittingText="Saving Domain"
        submitText="Save Domain"
      />
    </div>
  )
}

function StepKeywords({ workspace, project, keywordSource }: any) {
  const createKeywordSource = useCreateKeywordSource()
  const saveKeywordSource = useSaveKeywordSource({ silent: true })
  const navigate = useNavigate()

  const onSubmit = async ({ textPhrases, ...values }: any) => {
    const phraseGroups = phraseTextToPhraseGroups(textPhrases)

    const payload = {
      ...values,
      type: KEYWORD_SOURCE_TYPE_BASIC,
      config: {
        ...values.config,
        phraseGroups,
      },
      schedules: values.schedules,
      workspaceId: workspace.id,
      teamId: project.id,
    }

    const payloadWithDaily = {
      ...payload,
      schedules: [
        ...payload.schedules,
        { id: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0' },
      ],
    }

    try {
      let newKeywordSource = await (keywordSource?.id
        ? saveKeywordSource(payloadWithDaily)
        : createKeywordSource(payloadWithDaily))

      await new Promise(r => setTimeout(r, 1200))

      newKeywordSource = await saveKeywordSource({
        ...newKeywordSource,
        ...payload,
        id: newKeywordSource.id,
      })

      navigate({
        search: old => ({ ...old, keywordSourceId: newKeywordSource.id }),
      })
    } catch (err) {
      console.error(err)
    }
  }

  const keywordSourceValues = React.useMemo(
    () => ({
      ...(keywordSource ?? {
        name: project?.name ? `${project?.name} Keywords` : '',
      }),
      textPhrases: keywordSource?.config?.phraseGroups
        ? phraseGroupsToPhraseText(keywordSource.config.phraseGroups)
        : '',
    }),
    [keywordSource, project?.name]
  )

  const form = useForm({
    defaultValues: keywordSourceValues,
    onSubmit,
  })

  const defaultDevices = React.useMemo(() => ['d'], [])
  const defaultLocaleIds = React.useMemo(() => [44249], [])
  const showModal = useModal()

  const onGoogleSubmit = async (selectedRows: any, globalGroups: any) => {
    form.setFieldValue(
      'textPhrases',
      selectedRows
        .map(
          (row: any) =>
            `${row.values.key}; ${globalGroups
              .map((group: any, index: any) => (index ? '; ' : '') + group)
              .join(' ')} \n`
        )
        .join('')
    )
  }

  return (
    <form.Form>
      <div className="p-8">
        <Caption>
          <FaInfoCircle className="inline" /> Enter your keyword phrases,
          devices, locales and a schedule. Total SERPs are calculated by
          multiplying the number of phrases, devices, locales, and the schedule
          frequency together to find the total SERPs per month.
          <br />
          <br />
          Example:{' '}
          <span className="rounded-lg border border-gray-500 bg-gray-500 bg-opacity-30 px-1">
            30 phrases * 2 devices (Desktop, iPhone) * 2 locales (US/English,
            UK/English) * Weekly (4 / month) = 480 total SERPs / month
          </span>
        </Caption>
        <div className="h-4" />
        <Button
          size="sm"
          color="gray-500"
          className="mb-1 mr-1"
          onClick={() =>
            showModal(() => (
              <GoogleSearchConsoleModal
                onSubmit={onGoogleSubmit}
                allowMoreAdding={false}
              />
            ))
          }
        >
          <BiImport className="inline" />
          Import from Search Console
        </Button>

        <div className="h-4" />

        <TextAreaField
          label="Keyword Phrases"
          field="textPhrases"
          placeholder={[
            'keyword phrase 1; keyword group 1; keyword group 2',
            '',
            'eggs; Breakfast; Eggs',
            'hamburger; Lunch; Dinner',
            'sandwich; Lunch',
            'fried eggs; Breakfast; Eggs',
            'toast',
          ].join(`\n`)}
          rows={8}
          // @ts-expect-error  // Argument of type '{ required: boolean; }' is not a... Remove this comment to see the full error message
          validate={Validate.keywordSourcePhrases({ required: true })}
        />

        <Caption>
          <FaInfoCircle className="inline" /> Paste your keyword phrases into
          the Keyword Phrases box, one keyword phrase per line. You can
          optionally assign each keyword phrase one or more keyword groups by
          adding semi-colon separated groups after the keyword phrase on the
          same line, e.g.
          <br />{' '}
          <span className="rounded-lg border border-gray-500 bg-gray-500 bg-opacity-30 px-1">
            my keyword phrase; Group 1; Group 2; Group 3
          </span>
        </Caption>

        <div className="h-4" />

        <DevicesField
          field="config.devices"
          label="Devices"
          defaultValue={defaultDevices}
          required
        />
        <div className="h-1" />
        <Caption className="font-normal">
          <FaInfoCircle className="inline" /> Most people pick "desktop" and
          either "mobile iOS" or "mobile Android". Unless you’re tracking
          app-related keyword phrases, you typically only need one of these.
        </Caption>

        <div className="h-4" />

        <LocalesField
          field="config.localeIds"
          label="Locales"
          defaultValue={defaultLocaleIds}
          required
        />
        <div className="h-1" />
        <Caption className="font-normal">
          <FaInfoCircle className="inline" /> A locale is a combination of
          search engine, language, and location. To browse all locales, click
          Browse All. The locales you select will be used as search origins for
          all keywords in this keyword source.
        </Caption>

        <div className="h-4" />

        <SchedulesField required onboarding />
        <div className="h-1" />
        <Caption className="font-normal">
          <FaInfoCircle className="inline" /> Pick how frequently you want new
          data pulled for your keywords.
        </Caption>

        <div className="h-4" />

        <Button type="submit" size="base" color="blue-500">
          {form.meta.isSubmitting ? (
            <>
              <Spinner color="white" /> Saving Keywords
            </>
          ) : (
            <>
              <FaCheck /> Save Keywords
            </>
          )}
        </Button>
      </div>
    </form.Form>
  )
}

function StepCompetitors({
  workspace,
  project,
  brand,
  competitors,
  competitorsDone,
}: any) {
  const createBrand = useCreateBrand()
  const navigate = useNavigate()
  const rerender = React.useReducer<any>(() => ({}), {})[1]

  const onSubmit = async (values: any) => {
    await createBrand({
      ...values,
      type: BRAND_TYPE_COMPETITOR_DIRECT,
      workspaceId: workspace.id,
      teamId: project.id,
    })
  }

  const [blankBrand, blankBrandHash] = React.useMemo(() => {
    if (competitors?.length || competitorsDone) {
      //
    }

    return [{}, Math.random()]
  }, [competitors?.length, competitorsDone])

  React.useEffect(() => {
    const interval = setInterval(() => {
      rerender()
    }, 2000)

    return () => {
      clearInterval(interval)
    }
  }, [rerender])

  return (
    <div className="p-8">
      <Caption>
        <FaInfoCircle className="inline" /> Add competitors by entering their
        primary domain and an optional Google My Business ID (should be a long
        number with no letters) for local tracking. You can track as many
        competitors as you want at no extra cost!
      </Caption>
      <div className="h-4" />
      {competitors?.length ? (
        <>
          <div className="text-xl font-bold underline">Competitors</div>
          <div className="h-2" />
          {competitors?.map((competitor: any, i: any) => {
            const domainProperty = competitor.properties?.find(
              (d: any) => d.type === BRAND_PROPERTY_TYPE_DOMAIN
            )

            return (
              <div key={competitor.id} className="text-xl">
                <Tooltip
                  tooltip={
                    competitor.properties[0].value ??
                    competitor.properties[0].condition?.values?.[0]
                  }
                >
                  <div
                    className={twMerge(
                      `inline-block animate-bounce`,
                      css({ animation: `bounce ${800 + i * 100}ms infinite` })
                    )}
                  >
                    <BiGhost
                      className={twMerge(
                        `inline scale-[scale(1.2)] transition duration-[2s]`,
                        css({
                          color: getDataColor(Math.round(Math.random() * 100)),
                        })
                      )}
                    />
                  </div>{' '}
                  {domainProperty?.value ??
                    domainProperty?.name ??
                    competitor.name}
                </Tooltip>
              </div>
            )
          })}
          <div className="h-4" />
          <Separator />
          <div className="h-4" />
        </>
      ) : null}
      <BrandFormOnboarding
        key={blankBrandHash}
        onSubmit={onSubmit}
        brand={blankBrand as Brand}
        onboardingType="Competitor (Direct)"
        submittingText="Adding Competitor"
        submitText={
          <>
            <FiPlus className="inline" /> Add Competitor
          </>
        }
      />
      {competitorsDone ? null : (
        <>
          <div className="h-4" />
          <Separator />
          <div className="h-4" />
          {competitors?.length ? (
            <Button
              color="green-500"
              size="base"
              onClick={() =>
                navigate({
                  search: old => {
                    return {
                      ...old,
                      competitorsDone: true,
                    }
                  },
                })
              }
            >
              <FaCheck /> Done
            </Button>
          ) : (
            <Button
              color="gray-500"
              size="base"
              onClick={() =>
                navigate({
                  search: old => {
                    return {
                      ...old,
                      competitorsDone: true,
                    }
                  },
                })
              }
            >
              <FaCheck /> Skip
            </Button>
          )}
        </>
      )}
    </div>
  )
}

function StepLearn(props: {
  workspace: Workspace
  project: Project
  brand: Brand
  competitors: Brand[]
}) {
  const { learn, redirect } = useSearch() as { learn: any; redirect: any }
  const navigate = useNavigate()

  return (
    <div className="p-8">
      <div className="flex items-start gap-4">
        <div className="relative text-center text-7xl">
          <span
            role="img"
            aria-label="tada-emoji"
            className="origin-[35%
                    50%] absolute inline-flex h-full
              w-full animate-ping text-yellow-500 duration-[3s]"
          >
            🎉
          </span>
          <span role="img" aria-label="tada-emoji" className="relative">
            🎉
          </span>
        </div>
        <div className="text-lg">
          Hooray! At this very moment, your keywords are being pulled and
          analyzed by our data-hungry servers! Depending on how many keywords
          you are tracking, it can take up to 30 minutes for data to be visible
          in your dashboards, so now is a great time to learn how to use Nozzle!
        </div>
      </div>
      <div className="h-4" />
      <Separator />
      <div className="h-4" />
      <label
        className="flex items-center gap-2"
        onClick={() =>
          navigate({
            search: (old: any) => ({
              ...old,
              learn: { ...(old.learn ?? {}), 1: !old.learn?.['1'] },
            }),
          })
        }
      >
        {learn?.['1'] ? (
          <FaCheckSquare className="inline text-2xl text-green-500" />
        ) : (
          <FaRegSquare className="inline text-2xl" />
        )}
        <div>
          Watch a{' '}
          <Anchor
            href="https://www.youtube.com/watch?v=Cuf-R5LOIH0"
            target="_blank"
          >
            walkthrough video of how dashboards work
          </Anchor>
        </div>
      </label>
      <div className="h-4" />
      <div className="ml-8 max-w-lg">
        <div className="aspect-w-16 aspect-h-9">
          <iframe
            src="https://www.youtube.com/embed/Cuf-R5LOIH0"
            title="Nozzle Dashboard Walkthrough"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
      <div className="h-4" />
      <label
        className="flex items-center gap-2"
        onClick={() =>
          navigate({
            search: (old: any) => ({
              ...old,
              learn: { ...(old.learn ?? {}), 2: !old.learn?.['2'] },
            }),
          })
        }
      >
        {learn?.['2'] ? (
          <FaCheckSquare className="inline text-2xl text-green-500" />
        ) : (
          <FaRegSquare className="inline text-2xl" />
        )}{' '}
        <div>
          Join the{' '}
          <Anchor href="http://slack.nozzle.io/" target="_blank">
            Nozzlers Slack Organization
          </Anchor>
        </div>
      </label>
      <div className="h-4" />
      <label
        className="flex items-center gap-2"
        onClick={() =>
          navigate({
            search: (old: any) => ({
              ...old,
              learn: { ...(old.learn ?? {}), 3: !old.learn?.['3'] },
            }),
          })
        }
      >
        {learn?.['3'] ? (
          <FaCheckSquare className="inline text-2xl text-green-500" />
        ) : (
          <FaRegSquare className="inline text-2xl" />
        )}{' '}
        <div>
          Get acquainted with{' '}
          <Anchor
            href="https://help.nozzle.io/getting-started-with-your-workspace"
            target="_blank"
          >
            Nozzle Support (help.nozzle.io)
          </Anchor>
        </div>
      </label>
      <div className="h-2" />
      <label
        className="flex items-center gap-2"
        onClick={() =>
          navigate({
            search: (old: any) => ({
              ...old,
              learn: { ...(old.learn ?? {}), 4: !old.learn?.['4'] },
            }),
          })
        }
      >
        {learn?.['4'] ? (
          <FaCheckSquare className="inline text-2xl text-green-500" />
        ) : (
          <FaRegSquare className="inline text-2xl" />
        )}{' '}
        <div>
          Take a peek at our{' '}
          <Anchor href="https://feedback.nozzle.io/" target="_blank">
            upcoming features and roadmap
          </Anchor>
          !
        </div>
      </label>
      <div className="h-4" />
      <Separator />
      <div className="h-4" />
      <Link
        to={`/`}
        search={{
          workspaceId: props.workspace.id,
        }}
        onClick={e => {
          if (redirect) {
            e.preventDefault()
            // @ts-expect-error  // Object is possibly 'null'.
            window.top.location.href = redirect
          }
        }}
      >
        <Button size="xl" color="green-500">
          <FaArrowRight /> Go to my Workspace!
        </Button>
      </Link>
    </div>
  )
}
