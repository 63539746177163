import * as React from 'react'
import LabelWrap from '../components/LabelWrap'
import TextAreaInput from '../components/TextAreaInput'

//

// @ts-expect-error  // Property 'label' does not exist on type '{}'.
const LabeledInput = React.forwardRef(({ label, children, ...rest }, ref) => (
  <LabelWrap
    label={label}
    // @ts-expect-error  // Property 'error' does not exist on type '{}'.
    error={rest.error}
    // @ts-expect-error  // Property 'isValidating' does not exist on type '{}... Remove this comment to see the full error message
    isValidating={rest.isValidating}
  >
    <TextAreaInput {...rest} ref={ref} className="w-full" />
  </LabelWrap>
))

export default LabeledInput
