import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { browserHistory } from '../browserHistory'

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  release: import.meta.env.COMMIT_REF,
  tracesSampleRate: 1.0,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(
        browserHistory as any
      ),
    }),
  ],
})

// REGISTER ERROR OVERLAY
const showErrorOverlay = (err: any) => {
  // must be within function call because that's when the element is defined for sure.
  const ErrorOverlay = customElements.get('vite-error-overlay')
  // don't open outside vite environment
  if (!ErrorOverlay) {
    return
  }
  console.error(err)
  const overlay = new ErrorOverlay(err)
  document.body.appendChild(overlay)
}

window.addEventListener('error', showErrorOverlay)
window.addEventListener('unhandledrejection', ({ reason }) =>
  showErrorOverlay(reason)
)
