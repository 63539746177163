import { twMerge } from 'tailwind-merge'
import { FaCheck, FaTimes, FaUndo } from 'react-icons/fa'
import { Updater, deepEqual } from '../utils'
import Button from './Button'
import Loader from './Loader'
import Select from './Select'
import { UseQueryResult } from 'react-query'
import { SelectOption } from '../hooks/useSelect'
import { useDraftState } from '../hooks/useDraftState'

type Props = {
  filterValues: string[]
  className?: string
  onFilterValuesChange: (updater: Updater<string[]>) => void
  optionsQuery: UseQueryResult<SelectOption[]>
  labelPlural: string
}

export function GroupByFiltersWidget(props: Props) {
  return <Inner key={props.labelPlural} {...props} />
}

export function Inner({
  className,
  filterValues,
  onFilterValuesChange,
  optionsQuery,
  labelPlural,
}: Props) {
  // Use a draft state for filterValues to avoid re-rendering
  const filterValuesDraftState = useDraftState({
    value: filterValues,
    onSubmit: onFilterValuesChange,
  })

  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        onFilterValuesChange(filterValuesDraftState.draft)
      }}
      className={twMerge('flex flex-wrap items-center gap-2', className)}
    >
      {optionsQuery.isLoading ? (
        <div className="flex items-center gap-2">
          <Loader color="gray-500" />
          <span className="text-sm">Loading {labelPlural}...</span>
        </div>
      ) : null}
      {!optionsQuery.isLoading ? (
        <>
          <Select
            multi
            options={optionsQuery.data}
            value={filterValuesDraftState.draft}
            onChange={value => filterValuesDraftState.setDraft(value)}
            placeholder={`Select ${labelPlural} to filter...`}
            className="min-w-[160px] flex-1 text-sm"
            inlineLabel={`Filter ${labelPlural}`}
          />
          {!deepEqual(filterValues, filterValuesDraftState.draft) ? (
            <>
              <Button size="sm" color="green-500" type="submit">
                <FaCheck className="inline" />
              </Button>
              <Button
                size="sm"
                color="red-500"
                onClick={() => {
                  filterValuesDraftState.setDraft(filterValuesDraftState.draft)
                }}
              >
                <FaTimes className="inline" />
              </Button>
            </>
          ) : null}
          {(filterValues?.length ?? 0) > 0 ? (
            <Button
              size="sm"
              color="yellow-500"
              onClick={() => {
                onFilterValuesChange([])
              }}
            >
              <FaUndo /> Reset
            </Button>
          ) : null}
        </>
      ) : null}
    </form>
  )
}
