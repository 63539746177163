import * as React from 'react'
import { twMerge } from 'tailwind-merge'
import Loader from '../components/Loader'

export type LabelWrapProps = {
  label?: any
  children: any
  error?: any
  isValidating?: boolean
  htmlFor?: any
  className?: any
  style?: any
}

function LabelWrap({
  label,
  error,
  isValidating,
  children,
  htmlFor,
  className,
  ...rest
}: LabelWrapProps) {
  const errors = (Array.isArray(error) ? error : [error]).filter(Boolean)

  return (
    <div {...rest} className={twMerge(`flex flex-1 flex-col gap-1`, className)}>
      {label ? (
        <label
          className={twMerge(
            `inline-block text-[.9rem] font-bold leading-tight`,
            isValidating && 'text-yellow-500',
            error && 'text-red-500'
          )}
          htmlFor={htmlFor}
        >
          {label === true ? <>&nbsp;</> : label}
        </label>
      ) : null}
      {children}
      {isValidating ? (
        <div>
          <Loader color="gray-500" />
        </div>
      ) : errors?.length ? (
        errors.map(error => (
          <div key={error} className="p-2 text-sm leading-tight text-red-500">
            {error}
          </div>
        ))
      ) : null}
    </div>
  )
}

export default LabelWrap
