export const autoNew = {
  keywordSourceConfig: {
    keywordSourceDataModel: {
      hierarchy: [
        {
          name: 'Make',
          templateSlug: 'make',
        },
        {
          name: 'Model',
          templateSlug: 'model',
        },
        {
          name: 'Year',
          templateSlug: 'year',
        },
      ],
      entries: [
        {
          name: 'Abarth',
          values: [
            {
              value: 'Abarth',
            },
          ],
          children: [
            {
              name: '500',
              values: [
                {
                  value: '500',
                },
              ],
            },
            {
              name: '595',
              values: [
                {
                  value: '595',
                },
              ],
            },
            {
              name: '695',
              values: [
                {
                  value: '695',
                },
              ],
            },
            {
              name: '124 Spider',
              values: [
                {
                  value: '124 Spider',
                },
              ],
            },
            {
              name: '500C',
              values: [
                {
                  value: '500C',
                },
              ],
            },
            {
              name: 'Grande Punto',
              values: [
                {
                  value: 'Grande Punto',
                },
              ],
            },
            {
              name: 'Punto Evo',
              values: [
                {
                  value: 'Punto Evo',
                },
              ],
            },
          ],
        },
        {
          name: 'AC',
          values: [
            {
              value: 'AC',
            },
          ],
          children: [
            {
              name: '378 GT Zagato',
              values: [
                {
                  value: '378 GT Zagato',
                },
              ],
            },
            {
              name: 'Ace',
              values: [
                {
                  value: 'Ace',
                },
              ],
            },
            {
              name: 'Aceca',
              values: [
                {
                  value: 'Aceca',
                },
              ],
            },
            {
              name: 'Cobra',
              values: [
                {
                  value: 'Cobra',
                },
              ],
            },
          ],
        },
        {
          name: 'Acura',
          values: [
            {
              value: 'Acura',
            },
          ],
          children: [
            {
              name: 'CL',
              values: [
                {
                  value: 'CL',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'CSX',
              values: [
                {
                  value: 'CSX',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'EL',
              values: [
                {
                  value: 'EL',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'ILX',
              values: [
                {
                  value: 'ILX',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Integra',
              values: [
                {
                  value: 'Integra',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'MDX',
              values: [
                {
                  value: 'MDX',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'NSX',
              values: [
                {
                  value: 'NSX',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'RDX',
              values: [
                {
                  value: 'RDX',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'RL',
              values: [
                {
                  value: 'RL',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'RLX',
              values: [
                {
                  value: 'RLX',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'RSX',
              values: [
                {
                  value: 'RSX',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'TL',
              values: [
                {
                  value: 'TL',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'TLX',
              values: [
                {
                  value: 'TLX',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'TSX',
              values: [
                {
                  value: 'TSX',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'ZDX',
              values: [
                {
                  value: 'ZDX',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'Aito',
          values: [
            {
              value: 'Aito',
            },
          ],
          children: [
            {
              name: 'M5',
              values: [
                {
                  value: 'M5',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'M7',
              values: [
                {
                  value: 'M7',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Alfa Romeo',
          values: [
            {
              value: 'Alfa Romeo',
            },
          ],
          children: [
            {
              name: '145',
              values: [
                {
                  value: '145',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '146',
              values: [
                {
                  value: '146',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '147',
              values: [
                {
                  value: '147',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '156',
              values: [
                {
                  value: '156',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '159',
              values: [
                {
                  value: '159',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '166',
              values: [
                {
                  value: '166',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '4C',
              values: [
                {
                  value: '4C',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '8C Competizione',
              values: [
                {
                  value: '8C Competizione',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Brera',
              values: [
                {
                  value: 'Brera',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Disco Volante',
              values: [
                {
                  value: 'Disco Volante',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Giulia',
              values: [
                {
                  value: 'Giulia',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Giulietta',
              values: [
                {
                  value: 'Giulietta',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'GT',
              values: [
                {
                  value: 'GT',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'GTV',
              values: [
                {
                  value: 'GTV',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'MiTo',
              values: [
                {
                  value: 'MiTo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Spider',
              values: [
                {
                  value: 'Spider',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Stelvio',
              values: [
                {
                  value: 'Stelvio',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Tonale',
              values: [
                {
                  value: 'Tonale',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Alpina',
          values: [
            {
              value: 'Alpina',
            },
          ],
          children: [
            {
              name: 'B10',
              values: [
                {
                  value: 'B10',
                },
              ],
            },
            {
              name: 'B12',
              values: [
                {
                  value: 'B12',
                },
              ],
            },
            {
              name: 'B3',
              values: [
                {
                  value: 'B3',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'B4',
              values: [
                {
                  value: 'B4',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'B5',
              values: [
                {
                  value: 'B5',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'B6',
              values: [
                {
                  value: 'B6',
                },
              ],
            },
            {
              name: 'B7',
              values: [
                {
                  value: 'B7',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'B8',
              values: [
                {
                  value: 'B8',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'D10',
              values: [
                {
                  value: 'D10',
                },
              ],
            },
            {
              name: 'D3',
              values: [
                {
                  value: 'D3',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'D4',
              values: [
                {
                  value: 'D4',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'D5',
              values: [
                {
                  value: 'D5',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Roadster',
              values: [
                {
                  value: 'Roadster',
                },
              ],
            },
            {
              name: 'XB7',
              values: [
                {
                  value: 'XB7',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'XD3',
              values: [
                {
                  value: 'XD3',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'XD4',
              values: [
                {
                  value: 'XD4',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Alpine',
          values: [
            {
              value: 'Alpine',
            },
          ],
          children: [
            {
              name: 'A110',
              values: [
                {
                  value: 'A110',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'AM General',
          values: [
            {
              value: 'AM General',
            },
          ],
          children: [
            {
              name: 'HMMWV (Humvee)',
              values: [
                {
                  value: 'HMMWV (Humvee)',
                },
              ],
            },
          ],
        },
        {
          name: 'Apal',
          values: [
            {
              value: 'Apal',
            },
          ],
          children: [
            {
              name: '21541 Stalker',
              values: [
                {
                  value: '21541 Stalker',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Arcfox',
          values: [
            {
              value: 'Arcfox',
            },
          ],
          children: [
            {
              name: 'Alpha S',
              values: [
                {
                  value: 'Alpha S',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Alpha T',
              values: [
                {
                  value: 'Alpha T',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Ariel',
          values: [
            {
              value: 'Ariel',
            },
          ],
          children: [
            {
              name: 'Atom',
              values: [
                {
                  value: 'Atom',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Nomad',
              values: [
                {
                  value: 'Nomad',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Aro',
          values: [
            {
              value: 'Aro',
            },
          ],
          children: [
            {
              name: '10',
              values: [
                {
                  value: '10',
                },
              ],
            },
            {
              name: '24',
              values: [
                {
                  value: '24',
                },
              ],
            },
          ],
        },
        {
          name: 'Asia',
          values: [
            {
              value: 'Asia',
            },
          ],
          children: [
            {
              name: 'Retona',
              values: [
                {
                  value: 'Retona',
                },
              ],
            },
          ],
        },
        {
          name: 'Aston Martin',
          values: [
            {
              value: 'Aston Martin',
            },
          ],
          children: [
            {
              name: 'Cygnet',
              values: [
                {
                  value: 'Cygnet',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'DB AR1',
              values: [
                {
                  value: 'DB AR1',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'DB11',
              values: [
                {
                  value: 'DB11',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'DB7',
              values: [
                {
                  value: 'DB7',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'DB9',
              values: [
                {
                  value: 'DB9',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'DBS',
              values: [
                {
                  value: 'DBS',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'DBX',
              values: [
                {
                  value: 'DBX',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'One-77',
              values: [
                {
                  value: 'One-77',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Rapide',
              values: [
                {
                  value: 'Rapide',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'V12 Vanquish',
              values: [
                {
                  value: 'V12 Vanquish',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'V12 Vantage',
              values: [
                {
                  value: 'V12 Vantage',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'V12 Zagato',
              values: [
                {
                  value: 'V12 Zagato',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'V8 Vantage',
              values: [
                {
                  value: 'V8 Vantage',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Virage',
              values: [
                {
                  value: 'Virage',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Vulcan',
              values: [
                {
                  value: 'Vulcan',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Audi',
          values: [
            {
              value: 'Audi',
            },
          ],
          children: [
            {
              name: 'A1',
              values: [
                {
                  value: 'A1',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'A2',
              values: [
                {
                  value: 'A2',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'A3',
              values: [
                {
                  value: 'A3',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'A4',
              values: [
                {
                  value: 'A4',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'A4 allroad',
              values: [
                {
                  value: 'A4 allroad',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'A5',
              values: [
                {
                  value: 'A5',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'A6',
              values: [
                {
                  value: 'A6',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'A6 allroad',
              values: [
                {
                  value: 'A6 allroad',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'A7',
              values: [
                {
                  value: 'A7',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'A8',
              values: [
                {
                  value: 'A8',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Cabriolet',
              values: [
                {
                  value: 'Cabriolet',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'e-tron',
              values: [
                {
                  value: 'e-tron',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'e-tron GT',
              values: [
                {
                  value: 'e-tron GT',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'e-tron S',
              values: [
                {
                  value: 'e-tron S',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'e-tron S Sportback',
              values: [
                {
                  value: 'e-tron S Sportback',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'e-tron Sportback',
              values: [
                {
                  value: 'e-tron Sportback',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Q2',
              values: [
                {
                  value: 'Q2',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Q2L',
              values: [
                {
                  value: 'Q2L',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Q3',
              values: [
                {
                  value: 'Q3',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Q3 Sportback',
              values: [
                {
                  value: 'Q3 Sportback',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Q4 e-tron',
              values: [
                {
                  value: 'Q4 e-tron',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Q4 Sportback e-tron',
              values: [
                {
                  value: 'Q4 Sportback e-tron',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Q5',
              values: [
                {
                  value: 'Q5',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Q5 e-tron',
              values: [
                {
                  value: 'Q5 e-tron',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Q5 Sportback',
              values: [
                {
                  value: 'Q5 Sportback',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Q5L',
              values: [
                {
                  value: 'Q5L',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Q6',
              values: [
                {
                  value: 'Q6',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Q7',
              values: [
                {
                  value: 'Q7',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Q8',
              values: [
                {
                  value: 'Q8',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Q8 e-tron',
              values: [
                {
                  value: 'Q8 e-tron',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Q8 Sportback e-tron',
              values: [
                {
                  value: 'Q8 Sportback e-tron',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'R8',
              values: [
                {
                  value: 'R8',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'R8 LMP',
              values: [
                {
                  value: 'R8 LMP',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'RS e-tron GT',
              values: [
                {
                  value: 'RS e-tron GT',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'RS Q3',
              values: [
                {
                  value: 'RS Q3',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'RS Q3 Sportback',
              values: [
                {
                  value: 'RS Q3 Sportback',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'RS Q8',
              values: [
                {
                  value: 'RS Q8',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'RS3',
              values: [
                {
                  value: 'RS3',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'RS4',
              values: [
                {
                  value: 'RS4',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'RS5',
              values: [
                {
                  value: 'RS5',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'RS6',
              values: [
                {
                  value: 'RS6',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'RS7',
              values: [
                {
                  value: 'RS7',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'S1',
              values: [
                {
                  value: 'S1',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'S3',
              values: [
                {
                  value: 'S3',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'S4',
              values: [
                {
                  value: 'S4',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'S5',
              values: [
                {
                  value: 'S5',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'S6',
              values: [
                {
                  value: 'S6',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'S7',
              values: [
                {
                  value: 'S7',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'S8',
              values: [
                {
                  value: 'S8',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'SQ2',
              values: [
                {
                  value: 'SQ2',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'SQ5',
              values: [
                {
                  value: 'SQ5',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'SQ5 Sportback',
              values: [
                {
                  value: 'SQ5 Sportback',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'SQ7',
              values: [
                {
                  value: 'SQ7',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'SQ8',
              values: [
                {
                  value: 'SQ8',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'SQ8 e-tron',
              values: [
                {
                  value: 'SQ8 e-tron',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'SQ8 Sportback e-tron',
              values: [
                {
                  value: 'SQ8 Sportback e-tron',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'TT',
              values: [
                {
                  value: 'TT',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'TT RS',
              values: [
                {
                  value: 'TT RS',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'TTS',
              values: [
                {
                  value: 'TTS',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Aurus',
          values: [
            {
              value: 'Aurus',
            },
          ],
          children: [
            {
              name: 'Komendant',
              values: [
                {
                  value: 'Komendant',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Senat',
              values: [
                {
                  value: 'Senat',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Austin Healey',
          values: [
            {
              value: 'Austin Healey',
            },
          ],
          children: [
            {
              name: '3000',
              values: [
                {
                  value: '3000',
                },
              ],
            },
          ],
        },
        {
          name: 'BAIC',
          values: [
            {
              value: 'BAIC',
            },
          ],
          children: [
            {
              name: 'A1',
              values: [
                {
                  value: 'A1',
                },
              ],
            },
            {
              name: 'BJ2020',
              values: [
                {
                  value: 'BJ2020',
                },
              ],
            },
            {
              name: 'BJ2021',
              values: [
                {
                  value: 'BJ2021',
                },
              ],
            },
            {
              name: 'BJ2026',
              values: [
                {
                  value: 'BJ2026',
                },
              ],
            },
            {
              name: 'BJ40',
              values: [
                {
                  value: 'BJ40',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'BJ80',
              values: [
                {
                  value: 'BJ80',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'EU260',
              values: [
                {
                  value: 'EU260',
                },
              ],
            },
            {
              name: 'EU5',
              values: [
                {
                  value: 'EU5',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'EX5',
              values: [
                {
                  value: 'EX5',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Jeep Cherokee 2500',
              values: [
                {
                  value: 'Jeep Cherokee 2500',
                },
              ],
            },
            {
              name: 'Luba (XB624)',
              values: [
                {
                  value: 'Luba (XB624)',
                },
              ],
            },
            {
              name: 'U7',
              values: [
                {
                  value: 'U7',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'X3',
              values: [
                {
                  value: 'X3',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'X7',
              values: [
                {
                  value: 'X7',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Bajaj',
          values: [
            {
              value: 'Bajaj',
            },
          ],
          children: [
            {
              name: 'Qute',
              values: [
                {
                  value: 'Qute',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Baltijas Dzips',
          values: [
            {
              value: 'Baltijas Dzips',
            },
          ],
          children: [
            {
              name: 'BD-1322',
              values: [
                {
                  value: 'BD-1322',
                },
              ],
            },
          ],
        },
        {
          name: 'Baojun',
          values: [
            {
              value: 'Baojun',
            },
          ],
          children: [
            {
              name: 'E300',
              values: [
                {
                  value: 'E300',
                },
              ],
            },
            {
              name: 'Kiwi EV',
              values: [
                {
                  value: 'Kiwi EV',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Batmobile',
          values: [
            {
              value: 'Batmobile',
            },
          ],
          children: [
            {
              name: '2018',
              values: [
                {
                  value: '2018',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'Bentley',
          values: [
            {
              value: 'Bentley',
            },
          ],
          children: [
            {
              name: 'Arnage',
              values: [
                {
                  value: 'Arnage',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Azure',
              values: [
                {
                  value: 'Azure',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Bentayga',
              values: [
                {
                  value: 'Bentayga',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Brooklands',
              values: [
                {
                  value: 'Brooklands',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Continental',
              values: [
                {
                  value: 'Continental',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Continental Flying Spur',
              values: [
                {
                  value: 'Continental Flying Spur',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Continental GT',
              values: [
                {
                  value: 'Continental GT',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Flying Spur',
              values: [
                {
                  value: 'Flying Spur',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Mulsanne',
              values: [
                {
                  value: 'Mulsanne',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'Bilenkin',
          values: [
            {
              value: 'Bilenkin',
            },
          ],
          children: [
            {
              name: 'Vintage',
              values: [
                {
                  value: 'Vintage',
                },
              ],
            },
          ],
        },
        {
          name: 'Bio auto',
          values: [
            {
              value: 'Bio auto',
            },
          ],
          children: [
            {
              name: 'evA-4',
              values: [
                {
                  value: 'evA-4',
                },
              ],
            },
          ],
        },
        {
          name: 'Blaval',
          values: [
            {
              value: 'Blaval',
            },
          ],
          children: [
            {
              name: 'FH-EQ',
              values: [
                {
                  value: 'FH-EQ',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'BMW',
          values: [
            {
              value: 'BMW',
            },
          ],
          children: [
            {
              name: '1 Series',
              values: [
                {
                  value: '1 Series',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '1M',
              values: [
                {
                  value: '1M',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '2 Series',
              values: [
                {
                  value: '2 Series',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '2 Series Active Tourer',
              values: [
                {
                  value: '2 Series Active Tourer',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '2 Series Grand Tourer',
              values: [
                {
                  value: '2 Series Grand Tourer',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '3 Series',
              values: [
                {
                  value: '3 Series',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '4 Series',
              values: [
                {
                  value: '4 Series',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '5 Series',
              values: [
                {
                  value: '5 Series',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '6 Series',
              values: [
                {
                  value: '6 Series',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '7 Series',
              values: [
                {
                  value: '7 Series',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '8 Series',
              values: [
                {
                  value: '8 Series',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'i3',
              values: [
                {
                  value: 'i3',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'i4',
              values: [
                {
                  value: 'i4',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'i7',
              values: [
                {
                  value: 'i7',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'i8',
              values: [
                {
                  value: 'i8',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'iX',
              values: [
                {
                  value: 'iX',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'iX1',
              values: [
                {
                  value: 'iX1',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'iX3',
              values: [
                {
                  value: 'iX3',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'M2',
              values: [
                {
                  value: 'M2',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'M3',
              values: [
                {
                  value: 'M3',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'M4',
              values: [
                {
                  value: 'M4',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'M5',
              values: [
                {
                  value: 'M5',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'M6',
              values: [
                {
                  value: 'M6',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'M8',
              values: [
                {
                  value: 'M8',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'X1',
              values: [
                {
                  value: 'X1',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'X2 Concept',
              values: [
                {
                  value: 'X2 Concept',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'X3',
              values: [
                {
                  value: 'X3',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'X3 M',
              values: [
                {
                  value: 'X3 M',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'X4',
              values: [
                {
                  value: 'X4',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'X4 M',
              values: [
                {
                  value: 'X4 M',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'X5',
              values: [
                {
                  value: 'X5',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'X5 M',
              values: [
                {
                  value: 'X5 M',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'X6',
              values: [
                {
                  value: 'X6',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'X6 M',
              values: [
                {
                  value: 'X6 M',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'X7',
              values: [
                {
                  value: 'X7',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'XM',
              values: [
                {
                  value: 'XM',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Z3',
              values: [
                {
                  value: 'Z3',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Z3 M',
              values: [
                {
                  value: 'Z3 M',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Z4',
              values: [
                {
                  value: 'Z4',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Z4 M',
              values: [
                {
                  value: 'Z4 M',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Z8',
              values: [
                {
                  value: 'Z8',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'Borgward',
          values: [
            {
              value: 'Borgward',
            },
          ],
          children: [
            {
              name: '2000',
              values: [
                {
                  value: '2000',
                },
              ],
            },
          ],
        },
        {
          name: 'Brabus',
          values: [
            {
              value: 'Brabus',
            },
          ],
          children: [
            {
              name: 'E V12',
              values: [
                {
                  value: 'E V12',
                },
              ],
            },
            {
              name: 'M V12',
              values: [
                {
                  value: 'M V12',
                },
              ],
            },
            {
              name: 'ML 63 Biturbo',
              values: [
                {
                  value: 'ML 63 Biturbo',
                },
              ],
            },
            {
              name: 'SV12',
              values: [
                {
                  value: 'SV12',
                },
              ],
            },
          ],
        },
        {
          name: 'Brilliance',
          values: [
            {
              value: 'Brilliance',
            },
          ],
          children: [
            {
              name: 'FRV (BS2)',
              values: [
                {
                  value: 'FRV (BS2)',
                },
              ],
            },
            {
              name: 'H230',
              values: [
                {
                  value: 'H230',
                },
              ],
            },
            {
              name: 'H530',
              values: [
                {
                  value: 'H530',
                },
              ],
            },
            {
              name: 'M1 (BS6)',
              values: [
                {
                  value: 'M1 (BS6)',
                },
              ],
            },
            {
              name: 'M2 (BS4)',
              values: [
                {
                  value: 'M2 (BS4)',
                },
              ],
            },
            {
              name: 'M3 (BC3)',
              values: [
                {
                  value: 'M3 (BC3)',
                },
              ],
            },
            {
              name: 'V3',
              values: [
                {
                  value: 'V3',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'V5',
              values: [
                {
                  value: 'V5',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Bristol',
          values: [
            {
              value: 'Bristol',
            },
          ],
          children: [
            {
              name: 'Blenheim',
              values: [
                {
                  value: 'Blenheim',
                },
              ],
            },
            {
              name: 'Blenheim Speedster',
              values: [
                {
                  value: 'Blenheim Speedster',
                },
              ],
            },
            {
              name: 'Fighter',
              values: [
                {
                  value: 'Fighter',
                },
              ],
            },
          ],
        },
        {
          name: 'Bronto',
          values: [
            {
              value: 'Bronto',
            },
          ],
          children: [
            {
              name: 'Fora',
              values: [
                {
                  value: 'Fora',
                },
              ],
            },
            {
              name: 'Rysy',
              values: [
                {
                  value: 'Rysy',
                },
              ],
            },
          ],
        },
        {
          name: 'Bufori',
          values: [
            {
              value: 'Bufori',
            },
          ],
          children: [
            {
              name: 'Geneva',
              values: [
                {
                  value: 'Geneva',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'La Joya',
              values: [
                {
                  value: 'La Joya',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Bugatti',
          values: [
            {
              value: 'Bugatti',
            },
          ],
          children: [
            {
              name: 'Chiron',
              values: [
                {
                  value: 'Chiron',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'EB Veyron 16.4',
              values: [
                {
                  value: 'EB Veyron 16.4',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'Buick',
          values: [
            {
              value: 'Buick',
            },
          ],
          children: [
            {
              name: 'Cascada',
              values: [
                {
                  value: 'Cascada',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Century',
              values: [
                {
                  value: 'Century',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Enclave',
              values: [
                {
                  value: 'Enclave',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Encore',
              values: [
                {
                  value: 'Encore',
                },
              ],
            },
            {
              name: 'Encore GX',
              values: [
                {
                  value: 'Encore GX',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Envision',
              values: [
                {
                  value: 'Envision',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Excelle',
              values: [
                {
                  value: 'Excelle',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'GL8',
              values: [
                {
                  value: 'GL8',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'LaCrosse',
              values: [
                {
                  value: 'LaCrosse',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'LeSabre',
              values: [
                {
                  value: 'LeSabre',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Lucerne',
              values: [
                {
                  value: 'Lucerne',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Park Avenue',
              values: [
                {
                  value: 'Park Avenue',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Rainer',
              values: [
                {
                  value: 'Rainer',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Regal',
              values: [
                {
                  value: 'Regal',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Rendezvous',
              values: [
                {
                  value: 'Rendezvous',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Riviera',
              values: [
                {
                  value: 'Riviera',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Terraza',
              values: [
                {
                  value: 'Terraza',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Velite 6',
              values: [
                {
                  value: 'Velite 6',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Verano',
              values: [
                {
                  value: 'Verano',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'BYD',
          values: [
            {
              value: 'BYD',
            },
          ],
          children: [
            {
              name: 'Dolphin',
              values: [
                {
                  value: 'Dolphin',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'E1',
              values: [
                {
                  value: 'E1',
                },
              ],
            },
            {
              name: 'e2',
              values: [
                {
                  value: 'e2',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'E5',
              values: [
                {
                  value: 'E5',
                },
              ],
            },
            {
              name: 'E6',
              values: [
                {
                  value: 'E6',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'F0',
              values: [
                {
                  value: 'F0',
                },
              ],
            },
            {
              name: 'F3',
              values: [
                {
                  value: 'F3',
                },
              ],
            },
            {
              name: 'F5',
              values: [
                {
                  value: 'F5',
                },
              ],
            },
            {
              name: 'F6',
              values: [
                {
                  value: 'F6',
                },
              ],
            },
            {
              name: 'F8',
              values: [
                {
                  value: 'F8',
                },
              ],
            },
            {
              name: 'Flyer',
              values: [
                {
                  value: 'Flyer',
                },
              ],
            },
            {
              name: 'G3',
              values: [
                {
                  value: 'G3',
                },
              ],
            },
            {
              name: 'G6',
              values: [
                {
                  value: 'G6',
                },
              ],
            },
            {
              name: 'Han',
              values: [
                {
                  value: 'Han',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'L3',
              values: [
                {
                  value: 'L3',
                },
              ],
            },
            {
              name: 'M6',
              values: [
                {
                  value: 'M6',
                },
              ],
            },
            {
              name: 'Qin',
              values: [
                {
                  value: 'Qin',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'S6',
              values: [
                {
                  value: 'S6',
                },
              ],
            },
            {
              name: 'Song EV',
              values: [
                {
                  value: 'Song EV',
                },
              ],
            },
            {
              name: 'Song Max',
              values: [
                {
                  value: 'Song Max',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Song Plus',
              values: [
                {
                  value: 'Song Plus',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Song Pro',
              values: [
                {
                  value: 'Song Pro',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Tang',
              values: [
                {
                  value: 'Tang',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Yuan',
              values: [
                {
                  value: 'Yuan',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Byvin',
          values: [
            {
              value: 'Byvin',
            },
          ],
          children: [
            {
              name: 'BD132J (CoCo)',
              values: [
                {
                  value: 'BD132J (CoCo)',
                },
              ],
            },
            {
              name: 'BD326J (Moca)',
              values: [
                {
                  value: 'BD326J (Moca)',
                },
              ],
            },
          ],
        },
        {
          name: 'Cadillac',
          values: [
            {
              value: 'Cadillac',
            },
          ],
          children: [
            {
              name: 'ATS',
              values: [
                {
                  value: 'ATS',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'ATS-V',
              values: [
                {
                  value: 'ATS-V',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'BLS',
              values: [
                {
                  value: 'BLS',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Catera',
              values: [
                {
                  value: 'Catera',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'CT4',
              values: [
                {
                  value: 'CT4',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'CT4-V',
              values: [
                {
                  value: 'CT4-V',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'CT5',
              values: [
                {
                  value: 'CT5',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'CT5-V',
              values: [
                {
                  value: 'CT5-V',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'CT6',
              values: [
                {
                  value: 'CT6',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'CTS',
              values: [
                {
                  value: 'CTS',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'CTS-V',
              values: [
                {
                  value: 'CTS-V',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'De Ville',
              values: [
                {
                  value: 'De Ville',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'DTS',
              values: [
                {
                  value: 'DTS',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Eldorado',
              values: [
                {
                  value: 'Eldorado',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'ELR',
              values: [
                {
                  value: 'ELR',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Escalade',
              values: [
                {
                  value: 'Escalade',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Escalade-V',
              values: [
                {
                  value: 'Escalade-V',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Lyriq',
              values: [
                {
                  value: 'Lyriq',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Seville',
              values: [
                {
                  value: 'Seville',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'SRX',
              values: [
                {
                  value: 'SRX',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'STS',
              values: [
                {
                  value: 'STS',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'XLR',
              values: [
                {
                  value: 'XLR',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'XT4',
              values: [
                {
                  value: 'XT4',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'XT5',
              values: [
                {
                  value: 'XT5',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'XT6',
              values: [
                {
                  value: 'XT6',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'XTS',
              values: [
                {
                  value: 'XTS',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'Callaway',
          values: [
            {
              value: 'Callaway',
            },
          ],
          children: [
            {
              name: 'C12',
              values: [
                {
                  value: 'C12',
                },
              ],
            },
          ],
        },
        {
          name: 'Caterham',
          values: [
            {
              value: 'Caterham',
            },
          ],
          children: [
            {
              name: '21',
              values: [
                {
                  value: '21',
                },
              ],
            },
            {
              name: 'CSR',
              values: [
                {
                  value: 'CSR',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Seven',
              values: [
                {
                  value: 'Seven',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Chana',
          values: [
            {
              value: 'Chana',
            },
          ],
          children: [
            {
              name: 'Benni',
              values: [
                {
                  value: 'Benni',
                },
              ],
            },
            {
              name: 'SC6390',
              values: [
                {
                  value: 'SC6390',
                },
              ],
            },
          ],
        },
        {
          name: 'Changan',
          values: [
            {
              value: 'Changan',
            },
          ],
          children: [
            {
              name: 'Alsvin V7',
              values: [
                {
                  value: 'Alsvin V7',
                },
              ],
            },
            {
              name: 'Auchan A600 EV',
              values: [
                {
                  value: 'Auchan A600 EV',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Benni',
              values: [
                {
                  value: 'Benni',
                },
              ],
            },
            {
              name: 'Benni EC/EV',
              values: [
                {
                  value: 'Benni EC/EV',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
              ],
            },
            {
              name: 'CM-8',
              values: [
                {
                  value: 'CM-8',
                },
              ],
            },
            {
              name: 'CS15',
              values: [
                {
                  value: 'CS15',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'CS35',
              values: [
                {
                  value: 'CS35',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'CS35PLUS',
              values: [
                {
                  value: 'CS35PLUS',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'CS55',
              values: [
                {
                  value: 'CS55',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'CS55PLUS',
              values: [
                {
                  value: 'CS55PLUS',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'CS75',
              values: [
                {
                  value: 'CS75',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'CS75PLUS',
              values: [
                {
                  value: 'CS75PLUS',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'CS85',
              values: [
                {
                  value: 'CS85',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'CS95',
              values: [
                {
                  value: 'CS95',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'CX20',
              values: [
                {
                  value: 'CX20',
                },
              ],
            },
            {
              name: 'Eado',
              values: [
                {
                  value: 'Eado',
                },
              ],
            },
            {
              name: 'Linmax',
              values: [
                {
                  value: 'Linmax',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Raeton',
              values: [
                {
                  value: 'Raeton',
                },
              ],
            },
            {
              name: 'Shenlan SL03',
              values: [
                {
                  value: 'Shenlan SL03',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'UNI-K',
              values: [
                {
                  value: 'UNI-K',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'UNI-T',
              values: [
                {
                  value: 'UNI-T',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'UNI-V',
              values: [
                {
                  value: 'UNI-V',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Z-Shine',
              values: [
                {
                  value: 'Z-Shine',
                },
              ],
            },
          ],
        },
        {
          name: 'ChangFeng',
          values: [
            {
              value: 'ChangFeng',
            },
          ],
          children: [
            {
              name: 'Flying',
              values: [
                {
                  value: 'Flying',
                },
              ],
            },
            {
              name: 'SUV (CS6)',
              values: [
                {
                  value: 'SUV (CS6)',
                },
              ],
            },
          ],
        },
        {
          name: 'Changhe',
          values: [
            {
              value: 'Changhe',
            },
          ],
          children: [
            {
              name: 'Ideal',
              values: [
                {
                  value: 'Ideal',
                },
              ],
            },
          ],
        },
        {
          name: 'Chery',
          values: [
            {
              value: 'Chery',
            },
          ],
          children: [
            {
              name: 'Amulet (A15)',
              values: [
                {
                  value: 'Amulet (A15)',
                },
              ],
            },
            {
              name: 'Arrizo 3',
              values: [
                {
                  value: 'Arrizo 3',
                },
              ],
            },
            {
              name: 'Arrizo 6',
              values: [
                {
                  value: 'Arrizo 6',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Arrizo 7',
              values: [
                {
                  value: 'Arrizo 7',
                },
              ],
            },
            {
              name: 'B13',
              values: [
                {
                  value: 'B13',
                },
              ],
            },
            {
              name: 'Bonus (A13)',
              values: [
                {
                  value: 'Bonus (A13)',
                },
              ],
            },
            {
              name: 'Bonus 3 (E3/A19)',
              values: [
                {
                  value: 'Bonus 3 (E3/A19)',
                },
              ],
            },
            {
              name: 'CrossEastar (B14)',
              values: [
                {
                  value: 'CrossEastar (B14)',
                },
              ],
            },
            {
              name: 'E5',
              values: [
                {
                  value: 'E5',
                },
              ],
            },
            {
              name: 'eQ5',
              values: [
                {
                  value: 'eQ5',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Fora (A21)',
              values: [
                {
                  value: 'Fora (A21)',
                },
              ],
            },
            {
              name: 'IndiS (S18D)',
              values: [
                {
                  value: 'IndiS (S18D)',
                },
              ],
            },
            {
              name: 'Karry',
              values: [
                {
                  value: 'Karry',
                },
              ],
            },
            {
              name: 'Kimo (A1)',
              values: [
                {
                  value: 'Kimo (A1)',
                },
              ],
            },
            {
              name: 'M11 (A3)',
              values: [
                {
                  value: 'M11 (A3)',
                },
              ],
            },
            {
              name: 'Oriental Son (B11)',
              values: [
                {
                  value: 'Oriental Son (B11)',
                },
              ],
            },
            {
              name: 'QQ6 (S21)',
              values: [
                {
                  value: 'QQ6 (S21)',
                },
              ],
            },
            {
              name: 'QQme',
              values: [
                {
                  value: 'QQme',
                },
              ],
            },
            {
              name: 'Sweet (QQ)',
              values: [
                {
                  value: 'Sweet (QQ)',
                },
              ],
            },
            {
              name: 'Tiggo (T11)',
              values: [
                {
                  value: 'Tiggo (T11)',
                },
              ],
            },
            {
              name: 'Tiggo 2',
              values: [
                {
                  value: 'Tiggo 2',
                },
              ],
            },
            {
              name: 'Tiggo 2 Pro',
              values: [
                {
                  value: 'Tiggo 2 Pro',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Tiggo 3',
              values: [
                {
                  value: 'Tiggo 3',
                },
              ],
            },
            {
              name: 'Tiggo 3xe',
              values: [
                {
                  value: 'Tiggo 3xe',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Tiggo 4',
              values: [
                {
                  value: 'Tiggo 4',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Tiggo 4 Pro',
              values: [
                {
                  value: 'Tiggo 4 Pro',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Tiggo 5',
              values: [
                {
                  value: 'Tiggo 5',
                },
              ],
            },
            {
              name: 'Tiggo 7',
              values: [
                {
                  value: 'Tiggo 7',
                },
              ],
            },
            {
              name: 'Tiggo 7 Pro',
              values: [
                {
                  value: 'Tiggo 7 Pro',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Tiggo 7 Pro Max',
              values: [
                {
                  value: 'Tiggo 7 Pro Max',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Tiggo 8',
              values: [
                {
                  value: 'Tiggo 8',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Tiggo 8 Pro',
              values: [
                {
                  value: 'Tiggo 8 Pro',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Tiggo 8 Pro Max',
              values: [
                {
                  value: 'Tiggo 8 Pro Max',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Very (A13)',
              values: [
                {
                  value: 'Very (A13)',
                },
              ],
            },
            {
              name: 'Windcloud (A11)',
              values: [
                {
                  value: 'Windcloud (A11)',
                },
              ],
            },
          ],
        },
        {
          name: 'CHERYEXEED',
          values: [
            {
              value: 'CHERYEXEED',
            },
          ],
          children: [
            {
              name: 'LX',
              values: [
                {
                  value: 'LX',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'TXL',
              values: [
                {
                  value: 'TXL',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'VX',
              values: [
                {
                  value: 'VX',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Chevrolet',
          values: [
            {
              value: 'Chevrolet',
            },
          ],
          children: [
            {
              name: 'Alero',
              values: [
                {
                  value: 'Alero',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Astra',
              values: [
                {
                  value: 'Astra',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Astro',
              values: [
                {
                  value: 'Astro',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Avalanche',
              values: [
                {
                  value: 'Avalanche',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Aveo',
              values: [
                {
                  value: 'Aveo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Blazer',
              values: [
                {
                  value: 'Blazer',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Blazer EV',
              values: [
                {
                  value: 'Blazer EV',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Blazer K5',
              values: [
                {
                  value: 'Blazer K5',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Bolt',
              values: [
                {
                  value: 'Bolt',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Bolt EUV',
              values: [
                {
                  value: 'Bolt EUV',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'C/K',
              values: [
                {
                  value: 'C/K',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Camaro',
              values: [
                {
                  value: 'Camaro',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Caprice',
              values: [
                {
                  value: 'Caprice',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Captiva',
              values: [
                {
                  value: 'Captiva',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Captiva Sport',
              values: [
                {
                  value: 'Captiva Sport',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Cavalier',
              values: [
                {
                  value: 'Cavalier',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Celta',
              values: [
                {
                  value: 'Celta',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Classic',
              values: [
                {
                  value: 'Classic',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Cobalt',
              values: [
                {
                  value: 'Cobalt',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Colorado',
              values: [
                {
                  value: 'Colorado',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Corsa',
              values: [
                {
                  value: 'Corsa',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Corvette',
              values: [
                {
                  value: 'Corvette',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Cruze',
              values: [
                {
                  value: 'Cruze',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Cruze (HR)',
              values: [
                {
                  value: 'Cruze (HR)',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'CSV CR8',
              values: [
                {
                  value: 'CSV CR8',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Damas',
              values: [
                {
                  value: 'Damas',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Epica',
              values: [
                {
                  value: 'Epica',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Equinox',
              values: [
                {
                  value: 'Equinox',
                  mostFrequentSchedule: {
                    name: 'Daily',
                    rrule: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Evanda',
              values: [
                {
                  value: 'Evanda',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Express',
              values: [
                {
                  value: 'Express',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Groove',
              values: [
                {
                  value: 'Groove',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'HHR',
              values: [
                {
                  value: 'HHR',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Impala',
              values: [
                {
                  value: 'Impala',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Kalos',
              values: [
                {
                  value: 'Kalos',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Lacetti',
              values: [
                {
                  value: 'Lacetti',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Lanos',
              values: [
                {
                  value: 'Lanos',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Lumina',
              values: [
                {
                  value: 'Lumina',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'LUV D-MAX',
              values: [
                {
                  value: 'LUV D-MAX',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Malibu',
              values: [
                {
                  value: 'Malibu',
                  mostFrequentSchedule: {
                    name: 'MWF',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=MO,WE,FR;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Matiz',
              values: [
                {
                  value: 'Matiz',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Menlo',
              values: [
                {
                  value: 'Menlo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Metro',
              values: [
                {
                  value: 'Metro',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Monte Carlo',
              values: [
                {
                  value: 'Monte Carlo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Monza',
              values: [
                {
                  value: 'Monza',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'MW',
              values: [
                {
                  value: 'MW',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Nexia',
              values: [
                {
                  value: 'Nexia',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Niva',
              values: [
                {
                  value: 'Niva',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Nubira',
              values: [
                {
                  value: 'Nubira',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Omega',
              values: [
                {
                  value: 'Omega',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Onix',
              values: [
                {
                  value: 'Onix',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Orlando',
              values: [
                {
                  value: 'Orlando',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Prizm',
              values: [
                {
                  value: 'Prizm',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Rezzo',
              values: [
                {
                  value: 'Rezzo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'S-10 Pickup',
              values: [
                {
                  value: 'S-10 Pickup',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Sail',
              values: [
                {
                  value: 'Sail',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Seeker',
              values: [
                {
                  value: 'Seeker',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Silverado',
              values: [
                {
                  value: 'Silverado',
                  mostFrequentSchedule: {
                    name: 'Daily',
                    rrule: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Silverado High Country',
              values: [
                {
                  value: 'Silverado High Country',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Silverado EV',
              values: [
                {
                  value: 'Silverado EV',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Sonic',
              values: [
                {
                  value: 'Sonic',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Spark',
              values: [
                {
                  value: 'Spark',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Spin',
              values: [
                {
                  value: 'Spin',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'SS',
              values: [
                {
                  value: 'SS',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'SSR',
              values: [
                {
                  value: 'SSR',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Suburban',
              values: [
                {
                  value: 'Suburban',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Tacuma',
              values: [
                {
                  value: 'Tacuma',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Tahoe',
              values: [
                {
                  value: 'Tahoe',
                  mostFrequentSchedule: {
                    name: 'MWF',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=MO,WE,FR;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Tavera',
              values: [
                {
                  value: 'Tavera',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Tracker',
              values: [
                {
                  value: 'Tracker',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'TrailBlazer',
              values: [
                {
                  value: 'TrailBlazer',
                  mostFrequentSchedule: {
                    name: 'MWF',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=MO,WE,FR;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Trans Sport',
              values: [
                {
                  value: 'Trans Sport',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Traverse',
              values: [
                {
                  value: 'Traverse',
                  mostFrequentSchedule: {
                    name: 'MWF',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=MO,WE,FR;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Trax',
              values: [
                {
                  value: 'Trax',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Uplander',
              values: [
                {
                  value: 'Uplander',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Vectra',
              values: [
                {
                  value: 'Vectra',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Venture',
              values: [
                {
                  value: 'Venture',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Viva',
              values: [
                {
                  value: 'Viva',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Volt',
              values: [
                {
                  value: 'Volt',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Zafira',
              values: [
                {
                  value: 'Zafira',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'Chrysler',
          values: [
            {
              value: 'Chrysler',
            },
          ],
          children: [
            {
              name: '200',
              values: [
                {
                  value: '200',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '300C',
              values: [
                {
                  value: '300C',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: '300C SRT8',
              values: [
                {
                  value: '300C SRT8',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '300M',
              values: [
                {
                  value: '300M',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Aspen',
              values: [
                {
                  value: 'Aspen',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Cirrus',
              values: [
                {
                  value: 'Cirrus',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Concorde',
              values: [
                {
                  value: 'Concorde',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Crossfire',
              values: [
                {
                  value: 'Crossfire',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Intrepid',
              values: [
                {
                  value: 'Intrepid',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'LHS',
              values: [
                {
                  value: 'LHS',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Nassau',
              values: [
                {
                  value: 'Nassau',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Neon',
              values: [
                {
                  value: 'Neon',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Pacifica',
              values: [
                {
                  value: 'Pacifica',
                  mostFrequentSchedule: {
                    name: 'MWF',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=MO,WE,FR;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Prowler',
              values: [
                {
                  value: 'Prowler',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'PT Cruiser',
              values: [
                {
                  value: 'PT Cruiser',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Sebring',
              values: [
                {
                  value: 'Sebring',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Stratus',
              values: [
                {
                  value: 'Stratus',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Town \u0026 Country',
              values: [
                {
                  value: 'Town \u0026 Country',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Viper',
              values: [
                {
                  value: 'Viper',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Voyager',
              values: [
                {
                  value: 'Voyager',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Ciimo',
          values: [
            {
              value: 'Ciimo',
            },
          ],
          children: [
            {
              name: 'M-NV',
              values: [
                {
                  value: 'M-NV',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'X-NV',
              values: [
                {
                  value: 'X-NV',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Citroen',
          values: [
            {
              value: 'Citroen',
            },
          ],
          children: [
            {
              name: 'Berlingo',
              values: [
                {
                  value: 'Berlingo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'C-Crosser',
              values: [
                {
                  value: 'C-Crosser',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'C-Elysee',
              values: [
                {
                  value: 'C-Elysee',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'C-Quatre',
              values: [
                {
                  value: 'C-Quatre',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'C-Triomphe',
              values: [
                {
                  value: 'C-Triomphe',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'C-ZERO',
              values: [
                {
                  value: 'C-ZERO',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'C1',
              values: [
                {
                  value: 'C1',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'C2',
              values: [
                {
                  value: 'C2',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'C3',
              values: [
                {
                  value: 'C3',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'C3 Aircross',
              values: [
                {
                  value: 'C3 Aircross',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'C3 Picasso',
              values: [
                {
                  value: 'C3 Picasso',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'C4',
              values: [
                {
                  value: 'C4',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'C4 Aircross',
              values: [
                {
                  value: 'C4 Aircross',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'C4 Cactus',
              values: [
                {
                  value: 'C4 Cactus',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'C4 Picasso',
              values: [
                {
                  value: 'C4 Picasso',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'C4 SpaceTourer',
              values: [
                {
                  value: 'C4 SpaceTourer',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'C5',
              values: [
                {
                  value: 'C5',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'C5 Aircross',
              values: [
                {
                  value: 'C5 Aircross',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'C5 X',
              values: [
                {
                  value: 'C5 X',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'C6',
              values: [
                {
                  value: 'C6',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'C8',
              values: [
                {
                  value: 'C8',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'DS3',
              values: [
                {
                  value: 'DS3',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'DS4',
              values: [
                {
                  value: 'DS4',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'DS5',
              values: [
                {
                  value: 'DS5',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'E-Mehari',
              values: [
                {
                  value: 'E-Mehari',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Evasion',
              values: [
                {
                  value: 'Evasion',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Jumpy',
              values: [
                {
                  value: 'Jumpy',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Nemo',
              values: [
                {
                  value: 'Nemo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Saxo',
              values: [
                {
                  value: 'Saxo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Spacetourer',
              values: [
                {
                  value: 'Spacetourer',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Xantia',
              values: [
                {
                  value: 'Xantia',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'XM',
              values: [
                {
                  value: 'XM',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Xsara',
              values: [
                {
                  value: 'Xsara',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Xsara Picasso',
              values: [
                {
                  value: 'Xsara Picasso',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'Coggiola',
          values: [
            {
              value: 'Coggiola',
            },
          ],
          children: [
            {
              name: 'T Rex',
              values: [
                {
                  value: 'T Rex',
                },
              ],
            },
          ],
        },
        {
          name: 'Cupra',
          values: [
            {
              value: 'Cupra',
            },
          ],
          children: [
            {
              name: 'Ateca',
              values: [
                {
                  value: 'Ateca',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Born',
              values: [
                {
                  value: 'Born',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Formentor',
              values: [
                {
                  value: 'Formentor',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Leon',
              values: [
                {
                  value: 'Leon',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Dacia',
          values: [
            {
              value: 'Dacia',
            },
          ],
          children: [
            {
              name: '1310',
              values: [
                {
                  value: '1310',
                },
              ],
            },
            {
              name: '1410',
              values: [
                {
                  value: '1410',
                },
              ],
            },
            {
              name: 'Dokker',
              values: [
                {
                  value: 'Dokker',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Duster',
              values: [
                {
                  value: 'Duster',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Jogger',
              values: [
                {
                  value: 'Jogger',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Lodgy',
              values: [
                {
                  value: 'Lodgy',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Logan',
              values: [
                {
                  value: 'Logan',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Nova',
              values: [
                {
                  value: 'Nova',
                },
              ],
            },
            {
              name: 'Pick-Up',
              values: [
                {
                  value: 'Pick-Up',
                },
              ],
            },
            {
              name: 'Sandero',
              values: [
                {
                  value: 'Sandero',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Solenza',
              values: [
                {
                  value: 'Solenza',
                },
              ],
            },
            {
              name: 'Spring',
              values: [
                {
                  value: 'Spring',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'SuperNova',
              values: [
                {
                  value: 'SuperNova',
                },
              ],
            },
          ],
        },
        {
          name: 'Dadi',
          values: [
            {
              value: 'Dadi',
            },
          ],
          children: [
            {
              name: 'City Leading',
              values: [
                {
                  value: 'City Leading',
                },
              ],
            },
            {
              name: 'Shuttle',
              values: [
                {
                  value: 'Shuttle',
                },
              ],
            },
            {
              name: 'Smoothing',
              values: [
                {
                  value: 'Smoothing',
                },
              ],
            },
          ],
        },
        {
          name: 'Daewoo',
          values: [
            {
              value: 'Daewoo',
            },
          ],
          children: [
            {
              name: 'Alpheon',
              values: [
                {
                  value: 'Alpheon',
                },
              ],
            },
            {
              name: 'Chairman',
              values: [
                {
                  value: 'Chairman',
                },
              ],
            },
            {
              name: 'Damas',
              values: [
                {
                  value: 'Damas',
                },
              ],
            },
            {
              name: 'Evanda',
              values: [
                {
                  value: 'Evanda',
                },
              ],
            },
            {
              name: 'G2X',
              values: [
                {
                  value: 'G2X',
                },
              ],
            },
            {
              name: 'Gentra',
              values: [
                {
                  value: 'Gentra',
                },
              ],
            },
            {
              name: 'Kalos',
              values: [
                {
                  value: 'Kalos',
                },
              ],
            },
            {
              name: 'Korando',
              values: [
                {
                  value: 'Korando',
                },
              ],
            },
            {
              name: 'Lacetti',
              values: [
                {
                  value: 'Lacetti',
                },
              ],
            },
            {
              name: 'Lacetti Premiere',
              values: [
                {
                  value: 'Lacetti Premiere',
                },
              ],
            },
            {
              name: 'Lanos',
              values: [
                {
                  value: 'Lanos',
                },
              ],
            },
            {
              name: 'Leganza',
              values: [
                {
                  value: 'Leganza',
                },
              ],
            },
            {
              name: 'Magnus',
              values: [
                {
                  value: 'Magnus',
                },
              ],
            },
            {
              name: 'Matiz',
              values: [
                {
                  value: 'Matiz',
                },
              ],
            },
            {
              name: 'Matiz Creative',
              values: [
                {
                  value: 'Matiz Creative',
                },
              ],
            },
            {
              name: 'Musso',
              values: [
                {
                  value: 'Musso',
                },
              ],
            },
            {
              name: 'Nexia',
              values: [
                {
                  value: 'Nexia',
                },
              ],
            },
            {
              name: 'Nubira',
              values: [
                {
                  value: 'Nubira',
                },
              ],
            },
            {
              name: 'Rezzo',
              values: [
                {
                  value: 'Rezzo',
                },
              ],
            },
            {
              name: 'Sens',
              values: [
                {
                  value: 'Sens',
                },
              ],
            },
            {
              name: 'Tacuma',
              values: [
                {
                  value: 'Tacuma',
                },
              ],
            },
            {
              name: 'Tico',
              values: [
                {
                  value: 'Tico',
                },
              ],
            },
            {
              name: 'Tosca',
              values: [
                {
                  value: 'Tosca',
                },
              ],
            },
            {
              name: 'Winstorm',
              values: [
                {
                  value: 'Winstorm',
                },
              ],
            },
          ],
        },
        {
          name: 'Daihatsu',
          values: [
            {
              value: 'Daihatsu',
            },
          ],
          children: [
            {
              name: 'Altis',
              values: [
                {
                  value: 'Altis',
                },
              ],
            },
            {
              name: 'Applause',
              values: [
                {
                  value: 'Applause',
                },
              ],
            },
            {
              name: 'Atrai',
              values: [
                {
                  value: 'Atrai',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Be-go',
              values: [
                {
                  value: 'Be-go',
                },
              ],
            },
            {
              name: 'Boon',
              values: [
                {
                  value: 'Boon',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Boon Luminas',
              values: [
                {
                  value: 'Boon Luminas',
                },
              ],
            },
            {
              name: 'Cast',
              values: [
                {
                  value: 'Cast',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Ceria',
              values: [
                {
                  value: 'Ceria',
                },
              ],
            },
            {
              name: 'Charade',
              values: [
                {
                  value: 'Charade',
                },
              ],
            },
            {
              name: 'Coo',
              values: [
                {
                  value: 'Coo',
                },
              ],
            },
            {
              name: 'Copen',
              values: [
                {
                  value: 'Copen',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Cuore',
              values: [
                {
                  value: 'Cuore',
                },
              ],
            },
            {
              name: 'Delta Wagon',
              values: [
                {
                  value: 'Delta Wagon',
                },
              ],
            },
            {
              name: 'Esse',
              values: [
                {
                  value: 'Esse',
                },
              ],
            },
            {
              name: 'Extol',
              values: [
                {
                  value: 'Extol',
                },
              ],
            },
            {
              name: 'Gran Move',
              values: [
                {
                  value: 'Gran Move',
                },
              ],
            },
            {
              name: 'Hijet',
              values: [
                {
                  value: 'Hijet',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Hijet Caddie',
              values: [
                {
                  value: 'Hijet Caddie',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Materia',
              values: [
                {
                  value: 'Materia',
                },
              ],
            },
            {
              name: 'MAX',
              values: [
                {
                  value: 'MAX',
                },
              ],
            },
            {
              name: 'Mebius',
              values: [
                {
                  value: 'Mebius',
                },
              ],
            },
            {
              name: 'Midget',
              values: [
                {
                  value: 'Midget',
                },
              ],
            },
            {
              name: 'Mira',
              values: [
                {
                  value: 'Mira',
                },
              ],
            },
            {
              name: 'Mira Cocoa',
              values: [
                {
                  value: 'Mira Cocoa',
                },
              ],
            },
            {
              name: 'Mira e:S',
              values: [
                {
                  value: 'Mira e:S',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Mira Gino',
              values: [
                {
                  value: 'Mira Gino',
                },
              ],
            },
            {
              name: 'Move',
              values: [
                {
                  value: 'Move',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Move Canbus',
              values: [
                {
                  value: 'Move Canbus',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Move Conte',
              values: [
                {
                  value: 'Move Conte',
                },
              ],
            },
            {
              name: 'Move Latte',
              values: [
                {
                  value: 'Move Latte',
                },
              ],
            },
            {
              name: 'Naked',
              values: [
                {
                  value: 'Naked',
                },
              ],
            },
            {
              name: 'Opti',
              values: [
                {
                  value: 'Opti',
                },
              ],
            },
            {
              name: 'Pyzar',
              values: [
                {
                  value: 'Pyzar',
                },
              ],
            },
            {
              name: 'Rugger',
              values: [
                {
                  value: 'Rugger',
                },
              ],
            },
            {
              name: 'Sirion',
              values: [
                {
                  value: 'Sirion',
                },
              ],
            },
            {
              name: 'Sonica',
              values: [
                {
                  value: 'Sonica',
                },
              ],
            },
            {
              name: 'Storia',
              values: [
                {
                  value: 'Storia',
                },
              ],
            },
            {
              name: 'Taft',
              values: [
                {
                  value: 'Taft',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Tanto',
              values: [
                {
                  value: 'Tanto',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Tanto Exe',
              values: [
                {
                  value: 'Tanto Exe',
                },
              ],
            },
            {
              name: 'Terios',
              values: [
                {
                  value: 'Terios',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Thor',
              values: [
                {
                  value: 'Thor',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Trevis',
              values: [
                {
                  value: 'Trevis',
                },
              ],
            },
            {
              name: 'Wake',
              values: [
                {
                  value: 'Wake',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Xenia',
              values: [
                {
                  value: 'Xenia',
                },
              ],
            },
            {
              name: 'YRV',
              values: [
                {
                  value: 'YRV',
                },
              ],
            },
          ],
        },
        {
          name: 'Daimler',
          values: [
            {
              value: 'Daimler',
            },
          ],
          children: [
            {
              name: 'X308',
              values: [
                {
                  value: 'X308',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'X350',
              values: [
                {
                  value: 'X350',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'Dallara',
          values: [
            {
              value: 'Dallara',
            },
          ],
          children: [
            {
              name: 'Stradale',
              values: [
                {
                  value: 'Stradale',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Datsun',
          values: [
            {
              value: 'Datsun',
            },
          ],
          children: [
            {
              name: 'GO',
              values: [
                {
                  value: 'GO',
                },
              ],
            },
            {
              name: 'GO+',
              values: [
                {
                  value: 'GO+',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'mi-DO',
              values: [
                {
                  value: 'mi-DO',
                },
              ],
            },
            {
              name: 'on-DO',
              values: [
                {
                  value: 'on-DO',
                },
              ],
            },
          ],
        },
        {
          name: 'De Tomaso',
          values: [
            {
              value: 'De Tomaso',
            },
          ],
          children: [
            {
              name: 'Bigua',
              values: [
                {
                  value: 'Bigua',
                },
              ],
            },
            {
              name: 'Guara',
              values: [
                {
                  value: 'Guara',
                },
              ],
            },
            {
              name: 'Mangusta',
              values: [
                {
                  value: 'Mangusta',
                },
              ],
            },
          ],
        },
        {
          name: 'Deco Rides',
          values: [
            {
              value: 'Deco Rides',
            },
          ],
          children: [
            {
              name: 'Zephyr',
              values: [
                {
                  value: 'Zephyr',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Delage',
          values: [
            {
              value: 'Delage',
            },
          ],
          children: [
            {
              name: 'D12',
              values: [
                {
                  value: 'D12',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Denza',
          values: [
            {
              value: 'Denza',
            },
          ],
          children: [
            {
              name: 'X',
              values: [
                {
                  value: 'X',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Derways',
          values: [
            {
              value: 'Derways',
            },
          ],
          children: [
            {
              name: 'Antelope',
              values: [
                {
                  value: 'Antelope',
                },
              ],
            },
            {
              name: 'Aurora',
              values: [
                {
                  value: 'Aurora',
                },
              ],
            },
            {
              name: 'Cowboy',
              values: [
                {
                  value: 'Cowboy',
                },
              ],
            },
            {
              name: 'DW Hower H3',
              values: [
                {
                  value: 'DW Hower H3',
                },
              ],
            },
            {
              name: 'Land Crown',
              values: [
                {
                  value: 'Land Crown',
                },
              ],
            },
            {
              name: 'Plutus',
              values: [
                {
                  value: 'Plutus',
                },
              ],
            },
            {
              name: 'Saladin',
              values: [
                {
                  value: 'Saladin',
                },
              ],
            },
            {
              name: 'Shuttle',
              values: [
                {
                  value: 'Shuttle',
                },
              ],
            },
          ],
        },
        {
          name: 'Dodge',
          values: [
            {
              value: 'Dodge',
            },
          ],
          children: [
            {
              name: 'Avenger',
              values: [
                {
                  value: 'Avenger',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Caliber',
              values: [
                {
                  value: 'Caliber',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Caravan',
              values: [
                {
                  value: 'Caravan',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Challenger',
              values: [
                {
                  value: 'Challenger',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Challenger Hellcat',
              values: [
                {
                  value: 'Challenger Hellcat',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Challenger Scatpack',
              values: [
                {
                  value: 'Challenger Scatpack',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Charger',
              values: [
                {
                  value: 'Charger',
                  mostFrequentSchedule: {
                    name: 'MWF',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=MO,WE,FR;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Dakota',
              values: [
                {
                  value: 'Dakota',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Dart',
              values: [
                {
                  value: 'Dart',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Durango',
              values: [
                {
                  value: 'Durango',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Hornet',
              values: [
                {
                  value: 'Hornet',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Intrepid',
              values: [
                {
                  value: 'Intrepid',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Journey',
              values: [
                {
                  value: 'Journey',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Magnum',
              values: [
                {
                  value: 'Magnum',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Neon',
              values: [
                {
                  value: 'Neon',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Nitro',
              values: [
                {
                  value: 'Nitro',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'RAM',
              values: [
                {
                  value: 'RAM',
                  mostFrequentSchedule: {
                    name: 'Daily',
                    rrule: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'RAM 1500',
              values: [
                {
                  value: 'RAM 1500',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'RAM 1500 Lunar',
              values: [
                {
                  value: 'RAM 1500 Lunar',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'RAM 2500',
              values: [
                {
                  value: 'RAM 2500',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'RAM Van',
              values: [
                {
                  value: 'RAM Van',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Ramcharger',
              values: [
                {
                  value: 'Ramcharger',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Stratus',
              values: [
                {
                  value: 'Stratus',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Viper',
              values: [
                {
                  value: 'Viper',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'DongFeng',
          values: [
            {
              value: 'DongFeng',
            },
          ],
          children: [
            {
              name: '370',
              values: [
                {
                  value: '370',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: '580',
              values: [
                {
                  value: '580',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'A30',
              values: [
                {
                  value: 'A30',
                },
              ],
            },
            {
              name: 'A9',
              values: [
                {
                  value: 'A9',
                },
              ],
            },
            {
              name: 'AX4',
              values: [
                {
                  value: 'AX4',
                },
              ],
            },
            {
              name: 'AX7',
              values: [
                {
                  value: 'AX7',
                },
              ],
            },
            {
              name: 'DF6',
              values: [
                {
                  value: 'DF6',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'E11K',
              values: [
                {
                  value: 'E11K',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'H30 Cross',
              values: [
                {
                  value: 'H30 Cross',
                },
              ],
            },
            {
              name: 'iX5',
              values: [
                {
                  value: 'iX5',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'MPV',
              values: [
                {
                  value: 'MPV',
                },
              ],
            },
            {
              name: 'Oting',
              values: [
                {
                  value: 'Oting',
                },
              ],
            },
            {
              name: 'Rich',
              values: [
                {
                  value: 'Rich',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'S30',
              values: [
                {
                  value: 'S30',
                },
              ],
            },
            {
              name: 'T5L',
              values: [
                {
                  value: 'T5L',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Doninvest',
          values: [
            {
              value: 'Doninvest',
            },
          ],
          children: [
            {
              name: 'Assol',
              values: [
                {
                  value: 'Assol',
                },
              ],
            },
            {
              name: 'Kondor',
              values: [
                {
                  value: 'Kondor',
                },
              ],
            },
            {
              name: 'Orion',
              values: [
                {
                  value: 'Orion',
                },
              ],
            },
          ],
        },
        {
          name: 'Donkervoort',
          values: [
            {
              value: 'Donkervoort',
            },
          ],
          children: [
            {
              name: 'D8',
              values: [
                {
                  value: 'D8',
                },
              ],
            },
            {
              name: 'D8 GT',
              values: [
                {
                  value: 'D8 GT',
                },
              ],
            },
            {
              name: 'D8 GTO',
              values: [
                {
                  value: 'D8 GTO',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'DS',
          values: [
            {
              value: 'DS',
            },
          ],
          children: [
            {
              name: '3',
              values: [
                {
                  value: '3',
                },
              ],
            },
            {
              name: '4',
              values: [
                {
                  value: '4',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: '5',
              values: [
                {
                  value: '5',
                },
              ],
            },
            {
              name: '9',
              values: [
                {
                  value: '9',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: '3 Crossback',
              values: [
                {
                  value: '3 Crossback',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: '7 Crossback',
              values: [
                {
                  value: '7 Crossback',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'DW Hower',
          values: [
            {
              value: 'DW Hower',
            },
          ],
          children: [
            {
              name: 'H3',
              values: [
                {
                  value: 'H3',
                },
              ],
            },
            {
              name: 'H5',
              values: [
                {
                  value: 'H5',
                },
              ],
            },
          ],
        },
        {
          name: 'E-Car',
          values: [
            {
              value: 'E-Car',
            },
          ],
          children: [
            {
              name: 'GD04B',
              values: [
                {
                  value: 'GD04B',
                },
              ],
            },
          ],
        },
        {
          name: 'Ecomotors',
          values: [
            {
              value: 'Ecomotors',
            },
          ],
          children: [
            {
              name: 'Estrima Biro',
              values: [
                {
                  value: 'Estrima Biro',
                },
              ],
            },
          ],
        },
        {
          name: 'Everus',
          values: [
            {
              value: 'Everus',
            },
          ],
          children: [
            {
              name: 'VE-1',
              values: [
                {
                  value: 'VE-1',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Evolute',
          values: [
            {
              value: 'Evolute',
            },
          ],
          children: [
            {
              name: 'i-JET',
              values: [
                {
                  value: 'i-JET',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'i-JOY',
              values: [
                {
                  value: 'i-JOY',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'i-PRO',
              values: [
                {
                  value: 'i-PRO',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'i-VAN',
              values: [
                {
                  value: 'i-VAN',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'FAW',
          values: [
            {
              value: 'FAW',
            },
          ],
          children: [
            {
              name: 'Bestune NAT',
              values: [
                {
                  value: 'Bestune NAT',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Bestune T55',
              values: [
                {
                  value: 'Bestune T55',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Bestune T77',
              values: [
                {
                  value: 'Bestune T77',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Bestune T99',
              values: [
                {
                  value: 'Bestune T99',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Besturn B30',
              values: [
                {
                  value: 'Besturn B30',
                },
              ],
            },
            {
              name: 'Besturn B50',
              values: [
                {
                  value: 'Besturn B50',
                },
              ],
            },
            {
              name: 'Besturn B70',
              values: [
                {
                  value: 'Besturn B70',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Besturn X40',
              values: [
                {
                  value: 'Besturn X40',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Besturn X80',
              values: [
                {
                  value: 'Besturn X80',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'City Golf',
              values: [
                {
                  value: 'City Golf',
                },
              ],
            },
            {
              name: 'D60',
              values: [
                {
                  value: 'D60',
                },
              ],
            },
            {
              name: 'Jinn',
              values: [
                {
                  value: 'Jinn',
                },
              ],
            },
            {
              name: 'Oley',
              values: [
                {
                  value: 'Oley',
                },
              ],
            },
            {
              name: 'V2',
              values: [
                {
                  value: 'V2',
                },
              ],
            },
            {
              name: 'V5',
              values: [
                {
                  value: 'V5',
                },
              ],
            },
            {
              name: 'Vita',
              values: [
                {
                  value: 'Vita',
                },
              ],
            },
          ],
        },
        {
          name: 'Ferrari',
          values: [
            {
              value: 'Ferrari',
            },
          ],
          children: [
            {
              name: '360',
              values: [
                {
                  value: '360',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '456',
              values: [
                {
                  value: '456',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '458',
              values: [
                {
                  value: '458',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '488',
              values: [
                {
                  value: '488',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '550',
              values: [
                {
                  value: '550',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '599',
              values: [
                {
                  value: '599',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '612',
              values: [
                {
                  value: '612',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '296 GTB',
              values: [
                {
                  value: '296 GTB',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: '575M',
              values: [
                {
                  value: '575M',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '812 Superfast',
              values: [
                {
                  value: '812 Superfast',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'California',
              values: [
                {
                  value: 'California',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Daytona SP3',
              values: [
                {
                  value: 'Daytona SP3',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Enzo',
              values: [
                {
                  value: 'Enzo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'F12berlinetta',
              values: [
                {
                  value: 'F12berlinetta',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'F430',
              values: [
                {
                  value: 'F430',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'F8 Tributo',
              values: [
                {
                  value: 'F8 Tributo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'FF',
              values: [
                {
                  value: 'FF',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'FXX K',
              values: [
                {
                  value: 'FXX K',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'GTC4Lusso',
              values: [
                {
                  value: 'GTC4Lusso',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'LaFerrari',
              values: [
                {
                  value: 'LaFerrari',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Monza SP',
              values: [
                {
                  value: 'Monza SP',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Portofino',
              values: [
                {
                  value: 'Portofino',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Purosangue',
              values: [
                {
                  value: 'Purosangue',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Roma',
              values: [
                {
                  value: 'Roma',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'SF90 Stradale',
              values: [
                {
                  value: 'SF90 Stradale',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Fiat',
          values: [
            {
              value: 'Fiat',
            },
          ],
          children: [
            {
              name: '500',
              values: [
                {
                  value: '500',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: '600',
              values: [
                {
                  value: '600',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '124 Spider',
              values: [
                {
                  value: '124 Spider',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '500L',
              values: [
                {
                  value: '500L',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: '500X',
              values: [
                {
                  value: '500X',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Albea',
              values: [
                {
                  value: 'Albea',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Barchetta',
              values: [
                {
                  value: 'Barchetta',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Brava',
              values: [
                {
                  value: 'Brava',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Bravo',
              values: [
                {
                  value: 'Bravo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Coupe',
              values: [
                {
                  value: 'Coupe',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Croma',
              values: [
                {
                  value: 'Croma',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Doblo',
              values: [
                {
                  value: 'Doblo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Egea',
              values: [
                {
                  value: 'Egea',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Fiorino',
              values: [
                {
                  value: 'Fiorino',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Freemont',
              values: [
                {
                  value: 'Freemont',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Fullback',
              values: [
                {
                  value: 'Fullback',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Idea',
              values: [
                {
                  value: 'Idea',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Linea',
              values: [
                {
                  value: 'Linea',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Marea',
              values: [
                {
                  value: 'Marea',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Multipla',
              values: [
                {
                  value: 'Multipla',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Palio',
              values: [
                {
                  value: 'Palio',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Panda',
              values: [
                {
                  value: 'Panda',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Punto',
              values: [
                {
                  value: 'Punto',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Qubo',
              values: [
                {
                  value: 'Qubo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Scudo',
              values: [
                {
                  value: 'Scudo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Sedici',
              values: [
                {
                  value: 'Sedici',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Seicento',
              values: [
                {
                  value: 'Seicento',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Siena',
              values: [
                {
                  value: 'Siena',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Stilo',
              values: [
                {
                  value: 'Stilo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Strada',
              values: [
                {
                  value: 'Strada',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Tipo',
              values: [
                {
                  value: 'Tipo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Ulysse',
              values: [
                {
                  value: 'Ulysse',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Uno',
              values: [
                {
                  value: 'Uno',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'Fisker',
          values: [
            {
              value: 'Fisker',
            },
          ],
          children: [
            {
              name: 'Karma',
              values: [
                {
                  value: 'Karma',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'Flanker',
          values: [
            {
              value: 'Flanker',
            },
          ],
          children: [
            {
              name: 'F',
              values: [
                {
                  value: 'F',
                },
              ],
            },
          ],
        },
        {
          name: 'Ford',
          values: [
            {
              value: 'Ford',
            },
          ],
          children: [
            {
              name: 'B-MAX',
              values: [
                {
                  value: 'B-MAX',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Bronco',
              values: [
                {
                  value: 'Bronco',
                  mostFrequentSchedule: {
                    name: 'MWF',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=MO,WE,FR;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Bronco Sport',
              values: [
                {
                  value: 'Bronco Sport',
                  mostFrequentSchedule: {
                    name: 'MWF',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=MO,WE,FR;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'C-MAX',
              values: [
                {
                  value: 'C-MAX',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Contour',
              values: [
                {
                  value: 'Contour',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Cougar',
              values: [
                {
                  value: 'Cougar',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Crown Victoria',
              values: [
                {
                  value: 'Crown Victoria',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Econoline',
              values: [
                {
                  value: 'Econoline',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'EcoSport',
              values: [
                {
                  value: 'EcoSport',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Edge',
              values: [
                {
                  value: 'Edge',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Equator',
              values: [
                {
                  value: 'Equator',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Escape',
              values: [
                {
                  value: 'Escape',
                  mostFrequentSchedule: {
                    name: 'MWF',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=MO,WE,FR;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Escort',
              values: [
                {
                  value: 'Escort',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Escort (North America)',
              values: [
                {
                  value: 'Escort (North America)',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Everest',
              values: [
                {
                  value: 'Everest',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Evos',
              values: [
                {
                  value: 'Evos',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Excursion',
              values: [
                {
                  value: 'Excursion',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Expedition',
              values: [
                {
                  value: 'Expedition',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Explorer',
              values: [
                {
                  value: 'Explorer',
                  mostFrequentSchedule: {
                    name: 'Daily',
                    rrule: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Explorer Sport Trac',
              values: [
                {
                  value: 'Explorer Sport Trac',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'F-150',
              values: [
                {
                  value: 'F-150',
                  mostFrequentSchedule: {
                    name: 'Daily',
                    rrule: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'F-150 Lariat',
              values: [
                {
                  value: 'F-150 Lariat',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'F-150 Platinum',
              values: [
                {
                  value: 'F-150 Platinum',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'F-250',
              values: [
                {
                  value: 'F-250',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'F-350',
              values: [
                {
                  value: 'F-350',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'F-150 Lightning',
              values: [
                {
                  value: 'F-150 Lightning',
                  mostFrequentSchedule: {
                    name: 'Daily',
                    rrule: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Festiva',
              values: [
                {
                  value: 'Festiva',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Fiesta',
              values: [
                {
                  value: 'Fiesta',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Fiesta ST',
              values: [
                {
                  value: 'Fiesta ST',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Five Hundred',
              values: [
                {
                  value: 'Five Hundred',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Flex',
              values: [
                {
                  value: 'Flex',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Focus',
              values: [
                {
                  value: 'Focus',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Focus (North America)',
              values: [
                {
                  value: 'Focus (North America)',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Focus RS',
              values: [
                {
                  value: 'Focus RS',
                },
              ],
            },
            {
              name: 'Focus ST',
              values: [
                {
                  value: 'Focus ST',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Freestar',
              values: [
                {
                  value: 'Freestar',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Freestyle',
              values: [
                {
                  value: 'Freestyle',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Fusion',
              values: [
                {
                  value: 'Fusion',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Fusion (North America)',
              values: [
                {
                  value: 'Fusion (North America)',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Galaxy',
              values: [
                {
                  value: 'Galaxy',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'GT',
              values: [
                {
                  value: 'GT',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Ikon',
              values: [
                {
                  value: 'Ikon',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Ixion',
              values: [
                {
                  value: 'Ixion',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'KA',
              values: [
                {
                  value: 'KA',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Kuga',
              values: [
                {
                  value: 'Kuga',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Laser',
              values: [
                {
                  value: 'Laser',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Maverick',
              values: [
                {
                  value: 'Maverick',
                  mostFrequentSchedule: {
                    name: 'MWF',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=MO,WE,FR;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Mondeo',
              values: [
                {
                  value: 'Mondeo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Mondeo ST',
              values: [
                {
                  value: 'Mondeo ST',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Mustang',
              values: [
                {
                  value: 'Mustang',
                  mostFrequentSchedule: {
                    name: 'MWF',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=MO,WE,FR;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Mustang Mach-E',
              values: [
                {
                  value: 'Mustang Mach-E',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Puma',
              values: [
                {
                  value: 'Puma',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Puma ST',
              values: [
                {
                  value: 'Puma ST',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Ranger',
              values: [
                {
                  value: 'Ranger',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Ranger (North America)',
              values: [
                {
                  value: 'Ranger (North America)',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'S-MAX',
              values: [
                {
                  value: 'S-MAX',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Taurus',
              values: [
                {
                  value: 'Taurus',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Taurus X',
              values: [
                {
                  value: 'Taurus X',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Territory',
              values: [
                {
                  value: 'Territory',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Thunderbird',
              values: [
                {
                  value: 'Thunderbird',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Tourneo Connect',
              values: [
                {
                  value: 'Tourneo Connect',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Tourneo Courier',
              values: [
                {
                  value: 'Tourneo Courier',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Tourneo Custom',
              values: [
                {
                  value: 'Tourneo Custom',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Transit Connect',
              values: [
                {
                  value: 'Transit Connect',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Transit Custom',
              values: [
                {
                  value: 'Transit Custom',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Windstar',
              values: [
                {
                  value: 'Windstar',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'Foton',
          values: [
            {
              value: 'Foton',
            },
          ],
          children: [
            {
              name: 'Midi',
              values: [
                {
                  value: 'Midi',
                },
              ],
            },
            {
              name: 'Sauvana',
              values: [
                {
                  value: 'Sauvana',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Tunland',
              values: [
                {
                  value: 'Tunland',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'FSO',
          values: [
            {
              value: 'FSO',
            },
          ],
          children: [
            {
              name: 'Lanos',
              values: [
                {
                  value: 'Lanos',
                },
              ],
            },
            {
              name: 'Polonez',
              values: [
                {
                  value: 'Polonez',
                },
              ],
            },
          ],
        },
        {
          name: 'Fuqi',
          values: [
            {
              value: 'Fuqi',
            },
          ],
          children: [
            {
              name: '6500 (Land King)',
              values: [
                {
                  value: '6500 (Land King)',
                },
              ],
            },
          ],
        },
        {
          name: 'GAC',
          values: [
            {
              value: 'GAC',
            },
          ],
          children: [
            {
              name: 'GE3',
              values: [
                {
                  value: 'GE3',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'GN8',
              values: [
                {
                  value: 'GN8',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'GS5',
              values: [
                {
                  value: 'GS5',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Trumpchi GS8',
              values: [
                {
                  value: 'Trumpchi GS8',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'GAC Aion',
          values: [
            {
              value: 'GAC Aion',
            },
          ],
          children: [
            {
              name: 'S',
              values: [
                {
                  value: 'S',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'S Plus',
              values: [
                {
                  value: 'S Plus',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'V',
              values: [
                {
                  value: 'V',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Y',
              values: [
                {
                  value: 'Y',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'GAZ',
          values: [
            {
              value: 'GAZ',
            },
          ],
          children: [
            {
              name: '2308 ¬´Ataman¬ª',
              values: [
                {
                  value: '2308 ¬´Ataman¬ª',
                },
              ],
            },
            {
              name: '2330 ¬´Tigr¬ª',
              values: [
                {
                  value: '2330 ¬´Tigr¬ª',
                },
              ],
            },
            {
              name: '3102 ¬´Volga¬ª',
              values: [
                {
                  value: '3102 ¬´Volga¬ª',
                },
              ],
            },
            {
              name: '310221 ¬´Volga¬ª',
              values: [
                {
                  value: '310221 ¬´Volga¬ª',
                },
              ],
            },
            {
              name: '3110 ¬´Volga¬ª',
              values: [
                {
                  value: '3110 ¬´Volga¬ª',
                },
              ],
            },
            {
              name: '31105 ¬´Volga¬ª',
              values: [
                {
                  value: '31105 ¬´Volga¬ª',
                },
              ],
            },
            {
              name: '3111 ¬´Volga¬ª',
              values: [
                {
                  value: '3111 ¬´Volga¬ª',
                },
              ],
            },
            {
              name: 'Volga Siber',
              values: [
                {
                  value: 'Volga Siber',
                },
              ],
            },
          ],
        },
        {
          name: 'Geely',
          values: [
            {
              value: 'Geely',
            },
          ],
          children: [
            {
              name: 'Atlas',
              values: [
                {
                  value: 'Atlas',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Atlas Pro',
              values: [
                {
                  value: 'Atlas Pro',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Beauty Leopard',
              values: [
                {
                  value: 'Beauty Leopard',
                },
              ],
            },
            {
              name: 'Binyue Cool',
              values: [
                {
                  value: 'Binyue Cool',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'CK (Otaka)',
              values: [
                {
                  value: 'CK (Otaka)',
                },
              ],
            },
            {
              name: 'Coolray',
              values: [
                {
                  value: 'Coolray',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Emgrand 7',
              values: [
                {
                  value: 'Emgrand 7',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Emgrand EC7',
              values: [
                {
                  value: 'Emgrand EC7',
                },
              ],
            },
            {
              name: 'Emgrand EC8',
              values: [
                {
                  value: 'Emgrand EC8',
                },
              ],
            },
            {
              name: 'Emgrand GT',
              values: [
                {
                  value: 'Emgrand GT',
                },
              ],
            },
            {
              name: 'Emgrand X7',
              values: [
                {
                  value: 'Emgrand X7',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'FC (Vision)',
              values: [
                {
                  value: 'FC (Vision)',
                },
              ],
            },
            {
              name: 'GC6',
              values: [
                {
                  value: 'GC6',
                },
              ],
            },
            {
              name: 'GC9',
              values: [
                {
                  value: 'GC9',
                },
              ],
            },
            {
              name: 'Geometry A',
              values: [
                {
                  value: 'Geometry A',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Geometry C',
              values: [
                {
                  value: 'Geometry C',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'GS',
              values: [
                {
                  value: 'GS',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Haoqing',
              values: [
                {
                  value: 'Haoqing',
                },
              ],
            },
            {
              name: 'Kandi EX3',
              values: [
                {
                  value: 'Kandi EX3',
                },
              ],
            },
            {
              name: 'LC (Panda)',
              values: [
                {
                  value: 'LC (Panda)',
                },
              ],
            },
            {
              name: 'LC (Panda) Cross',
              values: [
                {
                  value: 'LC (Panda) Cross',
                },
              ],
            },
            {
              name: 'MK',
              values: [
                {
                  value: 'MK',
                },
              ],
            },
            {
              name: 'MK Cross',
              values: [
                {
                  value: 'MK Cross',
                },
              ],
            },
            {
              name: 'Monjaro',
              values: [
                {
                  value: 'Monjaro',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'MR',
              values: [
                {
                  value: 'MR',
                },
              ],
            },
            {
              name: 'Okavango',
              values: [
                {
                  value: 'Okavango',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Preface',
              values: [
                {
                  value: 'Preface',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'SC7',
              values: [
                {
                  value: 'SC7',
                },
              ],
            },
            {
              name: 'Tugella',
              values: [
                {
                  value: 'Tugella',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'TX4',
              values: [
                {
                  value: 'TX4',
                },
              ],
            },
          ],
        },
        {
          name: 'Genesis',
          values: [
            {
              value: 'Genesis',
            },
          ],
          children: [
            {
              name: 'G70',
              values: [
                {
                  value: 'G70',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'G80',
              values: [
                {
                  value: 'G80',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'G90',
              values: [
                {
                  value: 'G90',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'GV60',
              values: [
                {
                  value: 'GV60',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'GV70',
              values: [
                {
                  value: 'GV70',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'GV80',
              values: [
                {
                  value: 'GV80',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Geo',
          values: [
            {
              value: 'Geo',
            },
          ],
          children: [
            {
              name: 'Prizm',
              values: [
                {
                  value: 'Prizm',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'GMC',
          values: [
            {
              value: 'GMC',
            },
          ],
          children: [
            {
              name: 'Acadia',
              values: [
                {
                  value: 'Acadia',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Canyon',
              values: [
                {
                  value: 'Canyon',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Envoy',
              values: [
                {
                  value: 'Envoy',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Hummer EV',
              values: [
                {
                  value: 'Hummer EV',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Hummer EV SUV',
              values: [
                {
                  value: 'Hummer EV SUV',
                },
              ],
            },
            {
              name: 'Jimmy',
              values: [
                {
                  value: 'Jimmy',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Safari',
              values: [
                {
                  value: 'Safari',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Savana',
              values: [
                {
                  value: 'Savana',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Sierra',
              values: [
                {
                  value: 'Sierra',
                  mostFrequentSchedule: {
                    name: 'Daily',
                    rrule: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Sonoma',
              values: [
                {
                  value: 'Sonoma',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Terrain',
              values: [
                {
                  value: 'Terrain',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Yukon',
              values: [
                {
                  value: 'Yukon',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Yukon Denali',
              values: [
                {
                  value: 'Yukon Denali',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'Gonow',
          values: [
            {
              value: 'Gonow',
            },
          ],
          children: [
            {
              name: 'Troy',
              values: [
                {
                  value: 'Troy',
                },
              ],
            },
          ],
        },
        {
          name: 'Gordon',
          values: [
            {
              value: 'Gordon',
            },
          ],
          children: [
            {
              name: 'Roadster',
              values: [
                {
                  value: 'Roadster',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Great Wall',
          values: [
            {
              value: 'Great Wall',
            },
          ],
          children: [
            {
              name: 'Coolbear',
              values: [
                {
                  value: 'Coolbear',
                },
              ],
            },
            {
              name: 'Cowry (V80)',
              values: [
                {
                  value: 'Cowry (V80)',
                },
              ],
            },
            {
              name: 'Deer',
              values: [
                {
                  value: 'Deer',
                },
              ],
            },
            {
              name: 'Florid',
              values: [
                {
                  value: 'Florid',
                },
              ],
            },
            {
              name: 'Hover',
              values: [
                {
                  value: 'Hover',
                },
              ],
            },
            {
              name: 'Hover H3',
              values: [
                {
                  value: 'Hover H3',
                },
              ],
            },
            {
              name: 'Hover H5',
              values: [
                {
                  value: 'Hover H5',
                },
              ],
            },
            {
              name: 'Hover H6',
              values: [
                {
                  value: 'Hover H6',
                },
              ],
            },
            {
              name: 'Hover M1 (Peri 4x4)',
              values: [
                {
                  value: 'Hover M1 (Peri 4x4)',
                },
              ],
            },
            {
              name: 'Hover M2',
              values: [
                {
                  value: 'Hover M2',
                },
              ],
            },
            {
              name: 'Hover M4',
              values: [
                {
                  value: 'Hover M4',
                },
              ],
            },
            {
              name: 'Hover Pi',
              values: [
                {
                  value: 'Hover Pi',
                },
              ],
            },
            {
              name: 'Pegasus',
              values: [
                {
                  value: 'Pegasus',
                },
              ],
            },
            {
              name: 'Peri',
              values: [
                {
                  value: 'Peri',
                },
              ],
            },
            {
              name: 'Poer',
              values: [
                {
                  value: 'Poer',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Safe',
              values: [
                {
                  value: 'Safe',
                },
              ],
            },
            {
              name: 'Sailor',
              values: [
                {
                  value: 'Sailor',
                },
              ],
            },
            {
              name: 'Sing RUV',
              values: [
                {
                  value: 'Sing RUV',
                },
              ],
            },
            {
              name: 'Socool',
              values: [
                {
                  value: 'Socool',
                },
              ],
            },
            {
              name: 'Voleex C10 (Phenom)',
              values: [
                {
                  value: 'Voleex C10 (Phenom)',
                },
              ],
            },
            {
              name: 'Voleex C30',
              values: [
                {
                  value: 'Voleex C30',
                },
              ],
            },
            {
              name: 'Wingle',
              values: [
                {
                  value: 'Wingle',
                },
              ],
            },
            {
              name: 'Wingle 7',
              values: [
                {
                  value: 'Wingle 7',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Hafei',
          values: [
            {
              value: 'Hafei',
            },
          ],
          children: [
            {
              name: 'Brio',
              values: [
                {
                  value: 'Brio',
                },
              ],
            },
            {
              name: 'Minyi',
              values: [
                {
                  value: 'Minyi',
                },
              ],
            },
            {
              name: 'Princip',
              values: [
                {
                  value: 'Princip',
                },
              ],
            },
            {
              name: 'Saibao',
              values: [
                {
                  value: 'Saibao',
                },
              ],
            },
            {
              name: 'Sigma',
              values: [
                {
                  value: 'Sigma',
                },
              ],
            },
            {
              name: 'Simbo',
              values: [
                {
                  value: 'Simbo',
                },
              ],
            },
          ],
        },
        {
          name: 'Haima',
          values: [
            {
              value: 'Haima',
            },
          ],
          children: [
            {
              name: '2',
              values: [
                {
                  value: '2',
                },
              ],
            },
            {
              name: '3',
              values: [
                {
                  value: '3',
                },
              ],
            },
            {
              name: '7',
              values: [
                {
                  value: '7',
                },
              ],
            },
            {
              name: '8S',
              values: [
                {
                  value: '8S',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Family',
              values: [
                {
                  value: 'Family',
                },
              ],
            },
            {
              name: 'Family F7',
              values: [
                {
                  value: 'Family F7',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Freema',
              values: [
                {
                  value: 'Freema',
                },
              ],
            },
            {
              name: 'M3',
              values: [
                {
                  value: 'M3',
                },
              ],
            },
            {
              name: 'S5',
              values: [
                {
                  value: 'S5',
                },
              ],
            },
          ],
        },
        {
          name: 'Haval',
          values: [
            {
              value: 'Haval',
            },
          ],
          children: [
            {
              name: 'Chitu',
              values: [
                {
                  value: 'Chitu',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Dargo',
              values: [
                {
                  value: 'Dargo',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'F7',
              values: [
                {
                  value: 'F7',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'F7x',
              values: [
                {
                  value: 'F7x',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'H2',
              values: [
                {
                  value: 'H2',
                },
              ],
            },
            {
              name: 'H5',
              values: [
                {
                  value: 'H5',
                },
              ],
            },
            {
              name: 'H6',
              values: [
                {
                  value: 'H6',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'H6 Coupe',
              values: [
                {
                  value: 'H6 Coupe',
                },
              ],
            },
            {
              name: 'H6S',
              values: [
                {
                  value: 'H6S',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'H8',
              values: [
                {
                  value: 'H8',
                },
              ],
            },
            {
              name: 'H9',
              values: [
                {
                  value: 'H9',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Jolion',
              values: [
                {
                  value: 'Jolion',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'KuGou',
              values: [
                {
                  value: 'KuGou',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Hawtai',
          values: [
            {
              value: 'Hawtai',
            },
          ],
          children: [
            {
              name: 'B21',
              values: [
                {
                  value: 'B21',
                },
              ],
            },
            {
              name: 'Boliger',
              values: [
                {
                  value: 'Boliger',
                },
              ],
            },
            {
              name: 'Laville',
              values: [
                {
                  value: 'Laville',
                },
              ],
            },
          ],
        },
        {
          name: 'Hennessey',
          values: [
            {
              value: 'Hennessey',
            },
          ],
          children: [
            {
              name: 'Venom F5',
              values: [
                {
                  value: 'Venom F5',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Venom GT',
              values: [
                {
                  value: 'Venom GT',
                },
              ],
            },
          ],
        },
        {
          name: 'Hindustan',
          values: [
            {
              value: 'Hindustan',
            },
          ],
          children: [
            {
              name: 'Ambassador',
              values: [
                {
                  value: 'Ambassador',
                },
              ],
            },
            {
              name: 'Contessa',
              values: [
                {
                  value: 'Contessa',
                },
              ],
            },
          ],
        },
        {
          name: 'HiPhi',
          values: [
            {
              value: 'HiPhi',
            },
          ],
          children: [
            {
              name: 'X',
              values: [
                {
                  value: 'X',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Z',
              values: [
                {
                  value: 'Z',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Holden',
          values: [
            {
              value: 'Holden',
            },
          ],
          children: [
            {
              name: 'Astra',
              values: [
                {
                  value: 'Astra',
                },
              ],
            },
            {
              name: 'Barina',
              values: [
                {
                  value: 'Barina',
                },
              ],
            },
            {
              name: 'Calais',
              values: [
                {
                  value: 'Calais',
                },
              ],
            },
            {
              name: 'Caprice',
              values: [
                {
                  value: 'Caprice',
                },
              ],
            },
            {
              name: 'Commodore',
              values: [
                {
                  value: 'Commodore',
                },
              ],
            },
            {
              name: 'Cruze',
              values: [
                {
                  value: 'Cruze',
                },
              ],
            },
            {
              name: 'Frontera',
              values: [
                {
                  value: 'Frontera',
                },
              ],
            },
            {
              name: 'Jackaroo',
              values: [
                {
                  value: 'Jackaroo',
                },
              ],
            },
            {
              name: 'Monaro',
              values: [
                {
                  value: 'Monaro',
                },
              ],
            },
            {
              name: 'Rodeo',
              values: [
                {
                  value: 'Rodeo',
                },
              ],
            },
            {
              name: 'Statesman',
              values: [
                {
                  value: 'Statesman',
                },
              ],
            },
            {
              name: 'UTE',
              values: [
                {
                  value: 'UTE',
                },
              ],
            },
            {
              name: 'Vectra',
              values: [
                {
                  value: 'Vectra',
                },
              ],
            },
            {
              name: 'Zafira',
              values: [
                {
                  value: 'Zafira',
                },
              ],
            },
          ],
        },
        {
          name: 'Honda',
          values: [
            {
              value: 'Honda',
            },
          ],
          children: [
            {
              name: 'Accord',
              values: [
                {
                  value: 'Accord',
                  mostFrequentSchedule: {
                    name: 'Daily',
                    rrule: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Acty',
              values: [
                {
                  value: 'Acty',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Airwave',
              values: [
                {
                  value: 'Airwave',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Avancier',
              values: [
                {
                  value: 'Avancier',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Brio',
              values: [
                {
                  value: 'Brio',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Capa',
              values: [
                {
                  value: 'Capa',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'City',
              values: [
                {
                  value: 'City',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Civic',
              values: [
                {
                  value: 'Civic',
                  mostFrequentSchedule: {
                    name: 'Daily',
                    rrule: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Civic Ferio',
              values: [
                {
                  value: 'Civic Ferio',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Civic Type R',
              values: [
                {
                  value: 'Civic Type R',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'CR-V',
              values: [
                {
                  value: 'CR-V',
                  mostFrequentSchedule: {
                    name: 'Daily',
                    rrule: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'CR-Z',
              values: [
                {
                  value: 'CR-Z',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Crider',
              values: [
                {
                  value: 'Crider',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Crossroad',
              values: [
                {
                  value: 'Crossroad',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Crosstour',
              values: [
                {
                  value: 'Crosstour',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Domani',
              values: [
                {
                  value: 'Domani',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'e',
              values: [
                {
                  value: 'e',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'e:NP1',
              values: [
                {
                  value: 'e:NP1',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'e:NS1',
              values: [
                {
                  value: 'e:NS1',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Edix',
              values: [
                {
                  value: 'Edix',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Element',
              values: [
                {
                  value: 'Element',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Elysion',
              values: [
                {
                  value: 'Elysion',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Envix',
              values: [
                {
                  value: 'Envix',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'FCX Clarity',
              values: [
                {
                  value: 'FCX Clarity',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Fit',
              values: [
                {
                  value: 'Fit',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Fit Aria',
              values: [
                {
                  value: 'Fit Aria',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Fit Shuttle',
              values: [
                {
                  value: 'Fit Shuttle',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'FR-V',
              values: [
                {
                  value: 'FR-V',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Freed',
              values: [
                {
                  value: 'Freed',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Grace',
              values: [
                {
                  value: 'Grace',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'HR-V',
              values: [
                {
                  value: 'HR-V',
                  mostFrequentSchedule: {
                    name: 'MWF',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=MO,WE,FR;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Insight',
              values: [
                {
                  value: 'Insight',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Inspire',
              values: [
                {
                  value: 'Inspire',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Integra',
              values: [
                {
                  value: 'Integra',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Integra SJ',
              values: [
                {
                  value: 'Integra SJ',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Jade',
              values: [
                {
                  value: 'Jade',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Jazz',
              values: [
                {
                  value: 'Jazz',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Lagreat',
              values: [
                {
                  value: 'Lagreat',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Legend',
              values: [
                {
                  value: 'Legend',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Life',
              values: [
                {
                  value: 'Life',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Logo',
              values: [
                {
                  value: 'Logo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'MDX',
              values: [
                {
                  value: 'MDX',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Mobilio',
              values: [
                {
                  value: 'Mobilio',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Mobilio Spike',
              values: [
                {
                  value: 'Mobilio Spike',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'N-BOX',
              values: [
                {
                  value: 'N-BOX',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'N-BOX Slash',
              values: [
                {
                  value: 'N-BOX Slash',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'N-One',
              values: [
                {
                  value: 'N-One',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'N-VAN',
              values: [
                {
                  value: 'N-VAN',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'N-WGN',
              values: [
                {
                  value: 'N-WGN',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
              ],
            },
            {
              name: 'NSX',
              values: [
                {
                  value: 'NSX',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Odyssey',
              values: [
                {
                  value: 'Odyssey',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Odyssey (North America)',
              values: [
                {
                  value: 'Odyssey (North America)',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Orthia',
              values: [
                {
                  value: 'Orthia',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Partner',
              values: [
                {
                  value: 'Partner',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Passport',
              values: [
                {
                  value: 'Passport',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Pilot',
              values: [
                {
                  value: 'Pilot',
                  mostFrequentSchedule: {
                    name: 'MWF',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=MO,WE,FR;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Prelude',
              values: [
                {
                  value: 'Prelude',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Ridgeline',
              values: [
                {
                  value: 'Ridgeline',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'S-MX',
              values: [
                {
                  value: 'S-MX',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'S2000',
              values: [
                {
                  value: 'S2000',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'S660',
              values: [
                {
                  value: 'S660',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Saber',
              values: [
                {
                  value: 'Saber',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Shuttle',
              values: [
                {
                  value: 'Shuttle',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Stepwgn',
              values: [
                {
                  value: 'Stepwgn',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Stream',
              values: [
                {
                  value: 'Stream',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: "That'S",
              values: [
                {
                  value: "That'S",
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Torneo',
              values: [
                {
                  value: 'Torneo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'UR-V',
              values: [
                {
                  value: 'UR-V',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Vamos',
              values: [
                {
                  value: 'Vamos',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Vezel',
              values: [
                {
                  value: 'Vezel',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'XR-V',
              values: [
                {
                  value: 'XR-V',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Z',
              values: [
                {
                  value: 'Z',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Zest',
              values: [
                {
                  value: 'Zest',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'ZR-V',
              values: [
                {
                  value: 'ZR-V',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Hongqi',
          values: [
            {
              value: 'Hongqi',
            },
          ],
          children: [
            {
              name: 'E-HS9',
              values: [
                {
                  value: 'E-HS9',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'H5',
              values: [
                {
                  value: 'H5',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'H9',
              values: [
                {
                  value: 'H9',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'HS5',
              values: [
                {
                  value: 'HS5',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'HS7',
              values: [
                {
                  value: 'HS7',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'L5',
              values: [
                {
                  value: 'L5',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Hozon',
          values: [
            {
              value: 'Hozon',
            },
          ],
          children: [
            {
              name: 'Neta U',
              values: [
                {
                  value: 'Neta U',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'HSV',
          values: [
            {
              value: 'HSV',
            },
          ],
          children: [
            {
              name: 'Maloo',
              values: [
                {
                  value: 'Maloo',
                },
              ],
            },
          ],
        },
        {
          name: 'HuangHai',
          values: [
            {
              value: 'HuangHai',
            },
          ],
          children: [
            {
              name: 'Antelope',
              values: [
                {
                  value: 'Antelope',
                },
              ],
            },
            {
              name: 'Landscape',
              values: [
                {
                  value: 'Landscape',
                },
              ],
            },
            {
              name: 'Plutus',
              values: [
                {
                  value: 'Plutus',
                },
              ],
            },
          ],
        },
        {
          name: 'Hummer',
          values: [
            {
              value: 'Hummer',
            },
          ],
          children: [
            {
              name: 'H1',
              values: [
                {
                  value: 'H1',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'H2',
              values: [
                {
                  value: 'H2',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'H3',
              values: [
                {
                  value: 'H3',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'Hyundai',
          values: [
            {
              value: 'Hyundai',
            },
          ],
          children: [
            {
              name: 'Accent',
              values: [
                {
                  value: 'Accent',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Aslan',
              values: [
                {
                  value: 'Aslan',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Atos',
              values: [
                {
                  value: 'Atos',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Avante',
              values: [
                {
                  value: 'Avante',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Avante N',
              values: [
                {
                  value: 'Avante N',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Azera',
              values: [
                {
                  value: 'Azera',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Bayon',
              values: [
                {
                  value: 'Bayon',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Casper',
              values: [
                {
                  value: 'Casper',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Celesta',
              values: [
                {
                  value: 'Celesta',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Centennial',
              values: [
                {
                  value: 'Centennial',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Click',
              values: [
                {
                  value: 'Click',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Coupe',
              values: [
                {
                  value: 'Coupe',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Creta',
              values: [
                {
                  value: 'Creta',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Custo',
              values: [
                {
                  value: 'Custo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Dynasty',
              values: [
                {
                  value: 'Dynasty',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Elantra',
              values: [
                {
                  value: 'Elantra',
                  mostFrequentSchedule: {
                    name: 'MWF',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=MO,WE,FR;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Elantra N',
              values: [
                {
                  value: 'Elantra N',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Encino',
              values: [
                {
                  value: 'Encino',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Entourage',
              values: [
                {
                  value: 'Entourage',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'EON',
              values: [
                {
                  value: 'EON',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Equus',
              values: [
                {
                  value: 'Equus',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Galloper',
              values: [
                {
                  value: 'Galloper',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Genesis',
              values: [
                {
                  value: 'Genesis',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Genesis Coupe',
              values: [
                {
                  value: 'Genesis Coupe',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Getz',
              values: [
                {
                  value: 'Getz',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Grace',
              values: [
                {
                  value: 'Grace',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Grand Starex Urban',
              values: [
                {
                  value: 'Grand Starex Urban',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Grandeur',
              values: [
                {
                  value: 'Grandeur',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'H200',
              values: [
                {
                  value: 'H200',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'HB20',
              values: [
                {
                  value: 'HB20',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'i10',
              values: [
                {
                  value: 'i10',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'i20',
              values: [
                {
                  value: 'i20',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'i20 N',
              values: [
                {
                  value: 'i20 N',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'i30',
              values: [
                {
                  value: 'i30',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'i30 N',
              values: [
                {
                  value: 'i30 N',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'i40',
              values: [
                {
                  value: 'i40',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'IONIQ',
              values: [
                {
                  value: 'IONIQ',
                  mostFrequentSchedule: {
                    name: 'MWF',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=MO,WE,FR;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'IONIQ 5',
              values: [
                {
                  value: 'IONIQ 5',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'IONIQ 6',
              values: [
                {
                  value: 'IONIQ 6',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'ix20',
              values: [
                {
                  value: 'ix20',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'ix35',
              values: [
                {
                  value: 'ix35',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'ix55',
              values: [
                {
                  value: 'ix55',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Kona',
              values: [
                {
                  value: 'Kona',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Kona N',
              values: [
                {
                  value: 'Kona N',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Lantra',
              values: [
                {
                  value: 'Lantra',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Lavita',
              values: [
                {
                  value: 'Lavita',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Matrix',
              values: [
                {
                  value: 'Matrix',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Maxcruz',
              values: [
                {
                  value: 'Maxcruz',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Mistra',
              values: [
                {
                  value: 'Mistra',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Palisade',
              values: [
                {
                  value: 'Palisade',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Santa Cruz',
              values: [
                {
                  value: 'Santa Cruz',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Santa Fe',
              values: [
                {
                  value: 'Santa Fe',
                  mostFrequentSchedule: {
                    name: 'MWF',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=MO,WE,FR;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Santamo',
              values: [
                {
                  value: 'Santamo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Solaris',
              values: [
                {
                  value: 'Solaris',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Sonata',
              values: [
                {
                  value: 'Sonata',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Starex',
              values: [
                {
                  value: 'Starex',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Starex (H-1)',
              values: [
                {
                  value: 'Starex (H-1)',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Staria',
              values: [
                {
                  value: 'Staria',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Terracan',
              values: [
                {
                  value: 'Terracan',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Tiburon',
              values: [
                {
                  value: 'Tiburon',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Trajet',
              values: [
                {
                  value: 'Trajet',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Tucson',
              values: [
                {
                  value: 'Tucson',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Tuscani',
              values: [
                {
                  value: 'Tuscani',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Veloster',
              values: [
                {
                  value: 'Veloster',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Venue',
              values: [
                {
                  value: 'Venue',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Veracruz',
              values: [
                {
                  value: 'Veracruz',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Verna',
              values: [
                {
                  value: 'Verna',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'XG',
              values: [
                {
                  value: 'XG',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'Infiniti',
          values: [
            {
              value: 'Infiniti',
            },
          ],
          children: [
            {
              name: 'EX',
              values: [
                {
                  value: 'EX',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'FX',
              values: [
                {
                  value: 'FX',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'G',
              values: [
                {
                  value: 'G',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'I',
              values: [
                {
                  value: 'I',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'JX',
              values: [
                {
                  value: 'JX',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'M',
              values: [
                {
                  value: 'M',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Q',
              values: [
                {
                  value: 'Q',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Q30',
              values: [
                {
                  value: 'Q30',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Q40',
              values: [
                {
                  value: 'Q40',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Q50',
              values: [
                {
                  value: 'Q50',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Q60',
              values: [
                {
                  value: 'Q60',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Q70',
              values: [
                {
                  value: 'Q70',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'QX30',
              values: [
                {
                  value: 'QX30',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'QX4',
              values: [
                {
                  value: 'QX4',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'QX50',
              values: [
                {
                  value: 'QX50',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'QX55',
              values: [
                {
                  value: 'QX55',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'QX56',
              values: [
                {
                  value: 'QX56',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'QX60',
              values: [
                {
                  value: 'QX60',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'QX70',
              values: [
                {
                  value: 'QX70',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'QX80',
              values: [
                {
                  value: 'QX80',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Invicta',
          values: [
            {
              value: 'Invicta',
            },
          ],
          children: [
            {
              name: 'S1',
              values: [
                {
                  value: 'S1',
                },
              ],
            },
          ],
        },
        {
          name: 'Iran Khodro',
          values: [
            {
              value: 'Iran Khodro',
            },
          ],
          children: [
            {
              name: 'Arisun',
              values: [
                {
                  value: 'Arisun',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Dena',
              values: [
                {
                  value: 'Dena',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Paykan',
              values: [
                {
                  value: 'Paykan',
                },
              ],
            },
            {
              name: 'Runna',
              values: [
                {
                  value: 'Runna',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Sahra',
              values: [
                {
                  value: 'Sahra',
                },
              ],
            },
            {
              name: 'Samand',
              values: [
                {
                  value: 'Samand',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Sarir',
              values: [
                {
                  value: 'Sarir',
                },
              ],
            },
            {
              name: 'Soren',
              values: [
                {
                  value: 'Soren',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Tara',
              values: [
                {
                  value: 'Tara',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Isuzu',
          values: [
            {
              value: 'Isuzu',
            },
          ],
          children: [
            {
              name: 'Amigo',
              values: [
                {
                  value: 'Amigo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Ascender',
              values: [
                {
                  value: 'Ascender',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Aska',
              values: [
                {
                  value: 'Aska',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Axiom',
              values: [
                {
                  value: 'Axiom',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Bighorn',
              values: [
                {
                  value: 'Bighorn',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'D-Max',
              values: [
                {
                  value: 'D-Max',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Fargo',
              values: [
                {
                  value: 'Fargo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Fargo Filly',
              values: [
                {
                  value: 'Fargo Filly',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Gemini',
              values: [
                {
                  value: 'Gemini',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Hombre',
              values: [
                {
                  value: 'Hombre',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'KB',
              values: [
                {
                  value: 'KB',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'MU',
              values: [
                {
                  value: 'MU',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'MU-7',
              values: [
                {
                  value: 'MU-7',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'MU-X',
              values: [
                {
                  value: 'MU-X',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Rodeo',
              values: [
                {
                  value: 'Rodeo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'TF (Pickup)',
              values: [
                {
                  value: 'TF (Pickup)',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Trooper',
              values: [
                {
                  value: 'Trooper',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'VehiCross',
              values: [
                {
                  value: 'VehiCross',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Wizard',
              values: [
                {
                  value: 'Wizard',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'IVECO',
          values: [
            {
              value: 'IVECO',
            },
          ],
          children: [
            {
              name: 'Massif',
              values: [
                {
                  value: 'Massif',
                },
              ],
            },
          ],
        },
        {
          name: 'Izh',
          values: [
            {
              value: 'Izh',
            },
          ],
          children: [
            {
              name: '2715',
              values: [
                {
                  value: '2715',
                },
              ],
            },
            {
              name: '2717',
              values: [
                {
                  value: '2717',
                },
              ],
            },
            {
              name: '27175',
              values: [
                {
                  value: '27175',
                },
              ],
            },
            {
              name: '2126 ¬´Oda¬ª',
              values: [
                {
                  value: '2126 ¬´Oda¬ª',
                },
              ],
            },
            {
              name: '21261 ¬´Fabula¬ª',
              values: [
                {
                  value: '21261 ¬´Fabula¬ª',
                },
              ],
            },
            {
              name: 'Moskvich-412',
              values: [
                {
                  value: 'Moskvich-412',
                },
              ],
            },
          ],
        },
        {
          name: 'JAC',
          values: [
            {
              value: 'JAC',
            },
          ],
          children: [
            {
              name: 'iEV7L',
              values: [
                {
                  value: 'iEV7L',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'iEV7S',
              values: [
                {
                  value: 'iEV7S',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'iEVA50',
              values: [
                {
                  value: 'iEVA50',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'iEVS4',
              values: [
                {
                  value: 'iEVS4',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'J2 (Yueyue)',
              values: [
                {
                  value: 'J2 (Yueyue)',
                },
              ],
            },
            {
              name: 'J3 (Tongyue,Tojoy)',
              values: [
                {
                  value: 'J3 (Tongyue,Tojoy)',
                },
              ],
            },
            {
              name: 'J4 (Heyue A30)',
              values: [
                {
                  value: 'J4 (Heyue A30)',
                },
              ],
            },
            {
              name: 'J5 (Heyue)',
              values: [
                {
                  value: 'J5 (Heyue)',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'J6 (Heyue RS)',
              values: [
                {
                  value: 'J6 (Heyue RS)',
                },
              ],
            },
            {
              name: 'J7',
              values: [
                {
                  value: 'J7',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'J7 (Binyue)',
              values: [
                {
                  value: 'J7 (Binyue)',
                },
              ],
            },
            {
              name: 'JS4',
              values: [
                {
                  value: 'JS4',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'JS6',
              values: [
                {
                  value: 'JS6',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'M1 (Refine)',
              values: [
                {
                  value: 'M1 (Refine)',
                },
              ],
            },
            {
              name: 'M5',
              values: [
                {
                  value: 'M5',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'S1 (Rein)',
              values: [
                {
                  value: 'S1 (Rein)',
                },
              ],
            },
            {
              name: 'S3',
              values: [
                {
                  value: 'S3',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'S5 (Eagle)',
              values: [
                {
                  value: 'S5 (Eagle)',
                },
              ],
            },
            {
              name: 'S7',
              values: [
                {
                  value: 'S7',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Sehol A5 Plus',
              values: [
                {
                  value: 'Sehol A5 Plus',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Sehol Aipao',
              values: [
                {
                  value: 'Sehol Aipao',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Sehol E20X',
              values: [
                {
                  value: 'Sehol E20X',
                },
              ],
            },
            {
              name: 'Sehol X8',
              values: [
                {
                  value: 'Sehol X8',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Sehol X8 Plus',
              values: [
                {
                  value: 'Sehol X8 Plus',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'T6',
              values: [
                {
                  value: 'T6',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'T8 Pro',
              values: [
                {
                  value: 'T8 Pro',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Jaguar',
          values: [
            {
              value: 'Jaguar',
            },
          ],
          children: [
            {
              name: 'E-Pace',
              values: [
                {
                  value: 'E-Pace',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'F-Pace',
              values: [
                {
                  value: 'F-Pace',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'F-Type',
              values: [
                {
                  value: 'F-Type',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'F-Type SVR',
              values: [
                {
                  value: 'F-Type SVR',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'I-Pace',
              values: [
                {
                  value: 'I-Pace',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'S-Type',
              values: [
                {
                  value: 'S-Type',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'X-Type',
              values: [
                {
                  value: 'X-Type',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'XE',
              values: [
                {
                  value: 'XE',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'XF',
              values: [
                {
                  value: 'XF',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'XFR',
              values: [
                {
                  value: 'XFR',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'XJ',
              values: [
                {
                  value: 'XJ',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'XJR',
              values: [
                {
                  value: 'XJR',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'XK',
              values: [
                {
                  value: 'XK',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'XKR',
              values: [
                {
                  value: 'XKR',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'Jeep',
          values: [
            {
              value: 'Jeep',
            },
          ],
          children: [
            {
              name: 'Cherokee',
              values: [
                {
                  value: 'Cherokee',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Commander',
              values: [
                {
                  value: 'Commander',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Compass',
              values: [
                {
                  value: 'Compass',
                  mostFrequentSchedule: {
                    name: 'MWF',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=MO,WE,FR;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Gladiator',
              values: [
                {
                  value: 'Gladiator',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Grand Cherokee',
              values: [
                {
                  value: 'Grand Cherokee',
                  mostFrequentSchedule: {
                    name: 'Daily',
                    rrule: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Grand Cherokee SRT8',
              values: [
                {
                  value: 'Grand Cherokee SRT8',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Grand Wagoneer',
              values: [
                {
                  value: 'Grand Wagoneer',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Liberty (North America)',
              values: [
                {
                  value: 'Liberty (North America)',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Liberty (Patriot)',
              values: [
                {
                  value: 'Liberty (Patriot)',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Renegade',
              values: [
                {
                  value: 'Renegade',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Wrangler',
              values: [
                {
                  value: 'Wrangler',
                  mostFrequentSchedule: {
                    name: 'MWF',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=MO,WE,FR;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Jensen',
          values: [
            {
              value: 'Jensen',
            },
          ],
          children: [
            {
              name: 'S-V8',
              values: [
                {
                  value: 'S-V8',
                },
              ],
            },
          ],
        },
        {
          name: 'Jetour',
          values: [
            {
              value: 'Jetour',
            },
          ],
          children: [
            {
              name: 'X70 PLUS',
              values: [
                {
                  value: 'X70 PLUS',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Jetta',
          values: [
            {
              value: 'Jetta',
            },
          ],
          children: [
            {
              name: 'VA3',
              values: [
                {
                  value: 'VA3',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'VS5',
              values: [
                {
                  value: 'VS5',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'VS7',
              values: [
                {
                  value: 'VS7',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Jinbei',
          values: [
            {
              value: 'Jinbei',
            },
          ],
          children: [
            {
              name: 'Haise',
              values: [
                {
                  value: 'Haise',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'JMC',
          values: [
            {
              value: 'JMC',
            },
          ],
          children: [
            {
              name: 'Baodian',
              values: [
                {
                  value: 'Baodian',
                },
              ],
            },
          ],
        },
        {
          name: 'Kaiyi',
          values: [
            {
              value: 'Kaiyi',
            },
          ],
          children: [
            {
              name: 'E5',
              values: [
                {
                  value: 'E5',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'X3 Pro',
              values: [
                {
                  value: 'X3 Pro',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Kanonir',
          values: [
            {
              value: 'Kanonir',
            },
          ],
          children: [
            {
              name: '2317',
              values: [
                {
                  value: '2317',
                },
              ],
            },
          ],
        },
        {
          name: 'Kia',
          values: [
            {
              value: 'Kia',
            },
          ],
          children: [
            {
              name: 'Avella',
              values: [
                {
                  value: 'Avella',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Cadenza',
              values: [
                {
                  value: 'Cadenza',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Carens',
              values: [
                {
                  value: 'Carens',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Carnival',
              values: [
                {
                  value: 'Carnival',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Carstar',
              values: [
                {
                  value: 'Carstar',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: "Cee'd",
              values: [
                {
                  value: "Cee'd",
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: "Cee'd GT",
              values: [
                {
                  value: "Cee'd GT",
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Cerato',
              values: [
                {
                  value: 'Cerato',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Clarus',
              values: [
                {
                  value: 'Clarus',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Enterprise',
              values: [
                {
                  value: 'Enterprise',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'EV6',
              values: [
                {
                  value: 'EV6',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Forte',
              values: [
                {
                  value: 'Forte',
                  mostFrequentSchedule: {
                    name: 'MWF',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=MO,WE,FR;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Joice',
              values: [
                {
                  value: 'Joice',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'K3',
              values: [
                {
                  value: 'K3',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'K5',
              values: [
                {
                  value: 'K5',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'K7',
              values: [
                {
                  value: 'K7',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'K8',
              values: [
                {
                  value: 'K8',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'K9',
              values: [
                {
                  value: 'K9',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'K900',
              values: [
                {
                  value: 'K900',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
              ],
            },
            {
              name: 'KX3',
              values: [
                {
                  value: 'KX3',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Lotze',
              values: [
                {
                  value: 'Lotze',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Magentis',
              values: [
                {
                  value: 'Magentis',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Mentor',
              values: [
                {
                  value: 'Mentor',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Mohave (Borrego)',
              values: [
                {
                  value: 'Mohave (Borrego)',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Morning',
              values: [
                {
                  value: 'Morning',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Niro',
              values: [
                {
                  value: 'Niro',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Opirus',
              values: [
                {
                  value: 'Opirus',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Optima',
              values: [
                {
                  value: 'Optima',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Picanto',
              values: [
                {
                  value: 'Picanto',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Potentia',
              values: [
                {
                  value: 'Potentia',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Pregio',
              values: [
                {
                  value: 'Pregio',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Pride',
              values: [
                {
                  value: 'Pride',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Proceed',
              values: [
                {
                  value: 'Proceed',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Quanlima',
              values: [
                {
                  value: 'Quanlima',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Quoris',
              values: [
                {
                  value: 'Quoris',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Ray',
              values: [
                {
                  value: 'Ray',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Retona',
              values: [
                {
                  value: 'Retona',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Rio',
              values: [
                {
                  value: 'Rio',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Sedona',
              values: [
                {
                  value: 'Sedona',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Seltos',
              values: [
                {
                  value: 'Seltos',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Sephia',
              values: [
                {
                  value: 'Sephia',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Shuma',
              values: [
                {
                  value: 'Shuma',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Sorento',
              values: [
                {
                  value: 'Sorento',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Soul',
              values: [
                {
                  value: 'Soul',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Soul EV',
              values: [
                {
                  value: 'Soul EV',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Spectra',
              values: [
                {
                  value: 'Spectra',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Sportage',
              values: [
                {
                  value: 'Sportage',
                  mostFrequentSchedule: {
                    name: 'MWF',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=MO,WE,FR;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Sportage (China)',
              values: [
                {
                  value: 'Sportage (China)',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Stinger',
              values: [
                {
                  value: 'Stinger',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Stonic',
              values: [
                {
                  value: 'Stonic',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Telluride',
              values: [
                {
                  value: 'Telluride',
                  mostFrequentSchedule: {
                    name: 'MWF',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=MO,WE,FR;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Towner',
              values: [
                {
                  value: 'Towner',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Venga',
              values: [
                {
                  value: 'Venga',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Visto',
              values: [
                {
                  value: 'Visto',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'X-Trek',
              values: [
                {
                  value: 'X-Trek',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'XCeed',
              values: [
                {
                  value: 'XCeed',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Koenigsegg',
          values: [
            {
              value: 'Koenigsegg',
            },
          ],
          children: [
            {
              name: 'Agera',
              values: [
                {
                  value: 'Agera',
                },
              ],
            },
            {
              name: 'CC8S',
              values: [
                {
                  value: 'CC8S',
                },
              ],
            },
            {
              name: 'CCR',
              values: [
                {
                  value: 'CCR',
                },
              ],
            },
            {
              name: 'CCX',
              values: [
                {
                  value: 'CCX',
                },
              ],
            },
            {
              name: 'Jesco',
              values: [
                {
                  value: 'Jesco',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'One:1',
              values: [
                {
                  value: 'One:1',
                },
              ],
            },
            {
              name: 'Regera',
              values: [
                {
                  value: 'Regera',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Kombat',
          values: [
            {
              value: 'Kombat',
            },
          ],
          children: [
            {
              name: 'T98',
              values: [
                {
                  value: 'T98',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'KTM',
          values: [
            {
              value: 'KTM',
            },
          ],
          children: [
            {
              name: 'X-Bow',
              values: [
                {
                  value: 'X-Bow',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Lamborghini',
          values: [
            {
              value: 'Lamborghini',
            },
          ],
          children: [
            {
              name: 'Aventador',
              values: [
                {
                  value: 'Aventador',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Centanario',
              values: [
                {
                  value: 'Centanario',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Countach LPI 800-4',
              values: [
                {
                  value: 'Countach LPI 800-4',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Diablo',
              values: [
                {
                  value: 'Diablo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Egoista',
              values: [
                {
                  value: 'Egoista',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Gallardo',
              values: [
                {
                  value: 'Gallardo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Hurac√°n',
              values: [
                {
                  value: 'Hurac√°n',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Murcielago',
              values: [
                {
                  value: 'Murcielago',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Reventon',
              values: [
                {
                  value: 'Reventon',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Sesto Elemento',
              values: [
                {
                  value: 'Sesto Elemento',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Si√°n',
              values: [
                {
                  value: 'Si√°n',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Urus Concept',
              values: [
                {
                  value: 'Urus Concept',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Veneno',
              values: [
                {
                  value: 'Veneno',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'Lancia',
          values: [
            {
              value: 'Lancia',
            },
          ],
          children: [
            {
              name: 'Delta',
              values: [
                {
                  value: 'Delta',
                },
              ],
            },
            {
              name: 'Flavia',
              values: [
                {
                  value: 'Flavia',
                },
              ],
            },
            {
              name: 'Kappa',
              values: [
                {
                  value: 'Kappa',
                },
              ],
            },
            {
              name: 'Lybra',
              values: [
                {
                  value: 'Lybra',
                },
              ],
            },
            {
              name: 'Musa',
              values: [
                {
                  value: 'Musa',
                },
              ],
            },
            {
              name: 'Phedra',
              values: [
                {
                  value: 'Phedra',
                },
              ],
            },
            {
              name: 'Thema',
              values: [
                {
                  value: 'Thema',
                },
              ],
            },
            {
              name: 'Thesis',
              values: [
                {
                  value: 'Thesis',
                },
              ],
            },
            {
              name: 'Voyager',
              values: [
                {
                  value: 'Voyager',
                },
              ],
            },
            {
              name: 'Ypsilon',
              values: [
                {
                  value: 'Ypsilon',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Zeta',
              values: [
                {
                  value: 'Zeta',
                },
              ],
            },
          ],
        },
        {
          name: 'Land Rover',
          values: [
            {
              value: 'Land Rover',
            },
          ],
          children: [
            {
              name: 'Defender',
              values: [
                {
                  value: 'Defender',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Discovery',
              values: [
                {
                  value: 'Discovery',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Discovery Sport',
              values: [
                {
                  value: 'Discovery Sport',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Freelander',
              values: [
                {
                  value: 'Freelander',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Range Rover',
              values: [
                {
                  value: 'Range Rover',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Range Rover Evoque',
              values: [
                {
                  value: 'Range Rover Evoque',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Range Rover Sport',
              values: [
                {
                  value: 'Range Rover Sport',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Range Rover Velar',
              values: [
                {
                  value: 'Range Rover Velar',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Landwind',
          values: [
            {
              value: 'Landwind',
            },
          ],
          children: [
            {
              name: 'Fashion (CV9)',
              values: [
                {
                  value: 'Fashion (CV9)',
                },
              ],
            },
            {
              name: 'Forward',
              values: [
                {
                  value: 'Forward',
                },
              ],
            },
            {
              name: 'H9',
              values: [
                {
                  value: 'H9',
                },
              ],
            },
            {
              name: 'X5',
              values: [
                {
                  value: 'X5',
                },
              ],
            },
            {
              name: 'X6',
              values: [
                {
                  value: 'X6',
                },
              ],
            },
            {
              name: 'X7',
              values: [
                {
                  value: 'X7',
                },
              ],
            },
          ],
        },
        {
          name: 'Leapmotor',
          values: [
            {
              value: 'Leapmotor',
            },
          ],
          children: [
            {
              name: 'C11',
              values: [
                {
                  value: 'C11',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'T03',
              values: [
                {
                  value: 'T03',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Lexus',
          values: [
            {
              value: 'Lexus',
            },
          ],
          children: [
            {
              name: 'CT',
              values: [
                {
                  value: 'CT',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'ES',
              values: [
                {
                  value: 'ES',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'GS',
              values: [
                {
                  value: 'GS',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'GS F',
              values: [
                {
                  value: 'GS F',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'GX',
              values: [
                {
                  value: 'GX',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'HS',
              values: [
                {
                  value: 'HS',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'IS',
              values: [
                {
                  value: 'IS',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'IS F',
              values: [
                {
                  value: 'IS F',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'LC',
              values: [
                {
                  value: 'LC',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'LFA',
              values: [
                {
                  value: 'LFA',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'LM',
              values: [
                {
                  value: 'LM',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'LS',
              values: [
                {
                  value: 'LS',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'LX',
              values: [
                {
                  value: 'LX',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'NX',
              values: [
                {
                  value: 'NX',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'RC',
              values: [
                {
                  value: 'RC',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'RC F',
              values: [
                {
                  value: 'RC F',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'RX',
              values: [
                {
                  value: 'RX',
                  mostFrequentSchedule: {
                    name: 'MWF',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=MO,WE,FR;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'RZ',
              values: [
                {
                  value: 'RZ',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'SC',
              values: [
                {
                  value: 'SC',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'UX',
              values: [
                {
                  value: 'UX',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Liebao Motor',
          values: [
            {
              value: 'Liebao Motor',
            },
          ],
          children: [
            {
              name: 'Leopard',
              values: [
                {
                  value: 'Leopard',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Lifan',
          values: [
            {
              value: 'Lifan',
            },
          ],
          children: [
            {
              name: '650 EV',
              values: [
                {
                  value: '650 EV',
                },
              ],
            },
            {
              name: 'Breez (520)',
              values: [
                {
                  value: 'Breez (520)',
                },
              ],
            },
            {
              name: 'Cebrium (720)',
              values: [
                {
                  value: 'Cebrium (720)',
                },
              ],
            },
            {
              name: 'Celliya (530)',
              values: [
                {
                  value: 'Celliya (530)',
                },
              ],
            },
            {
              name: 'Murman',
              values: [
                {
                  value: 'Murman',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Myway',
              values: [
                {
                  value: 'Myway',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Smily',
              values: [
                {
                  value: 'Smily',
                },
              ],
            },
            {
              name: 'Solano',
              values: [
                {
                  value: 'Solano',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'X50',
              values: [
                {
                  value: 'X50',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'X60',
              values: [
                {
                  value: 'X60',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'X70',
              values: [
                {
                  value: 'X70',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Ligier',
          values: [
            {
              value: 'Ligier',
            },
          ],
          children: [
            {
              name: 'JS 51',
              values: [
                {
                  value: 'JS 51',
                },
              ],
            },
          ],
        },
        {
          name: 'Lincoln',
          values: [
            {
              value: 'Lincoln',
            },
          ],
          children: [
            {
              name: 'Aviator',
              values: [
                {
                  value: 'Aviator',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Blackwood',
              values: [
                {
                  value: 'Blackwood',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Continental',
              values: [
                {
                  value: 'Continental',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Corsair',
              values: [
                {
                  value: 'Corsair',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'LS',
              values: [
                {
                  value: 'LS',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Mark LT',
              values: [
                {
                  value: 'Mark LT',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'MKC',
              values: [
                {
                  value: 'MKC',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'MKS',
              values: [
                {
                  value: 'MKS',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'MKT',
              values: [
                {
                  value: 'MKT',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'MKX',
              values: [
                {
                  value: 'MKX',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'MKZ',
              values: [
                {
                  value: 'MKZ',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Nautilus',
              values: [
                {
                  value: 'Nautilus',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Navigator',
              values: [
                {
                  value: 'Navigator',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Town Car',
              values: [
                {
                  value: 'Town Car',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'LiXiang',
          values: [
            {
              value: 'LiXiang',
            },
          ],
          children: [
            {
              name: 'L9',
              values: [
                {
                  value: 'L9',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'One',
              values: [
                {
                  value: 'One',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Logem',
          values: [
            {
              value: 'Logem',
            },
          ],
          children: [
            {
              name: 'EC30',
              values: [
                {
                  value: 'EC30',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Lotus',
          values: [
            {
              value: 'Lotus',
            },
          ],
          children: [
            {
              name: '3-Eleven',
              values: [
                {
                  value: '3-Eleven',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: '340R',
              values: [
                {
                  value: '340R',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Eletre',
              values: [
                {
                  value: 'Eletre',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Elise',
              values: [
                {
                  value: 'Elise',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Emira',
              values: [
                {
                  value: 'Emira',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Esprit',
              values: [
                {
                  value: 'Esprit',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Europa S',
              values: [
                {
                  value: 'Europa S',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Evora',
              values: [
                {
                  value: 'Evora',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Exige',
              values: [
                {
                  value: 'Exige',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'LTI',
          values: [
            {
              value: 'LTI',
            },
          ],
          children: [
            {
              name: 'TX',
              values: [
                {
                  value: 'TX',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'LUAZ',
          values: [
            {
              value: 'LUAZ',
            },
          ],
          children: [
            {
              name: '1302 Volyny',
              values: [
                {
                  value: '1302 Volyny',
                },
              ],
            },
          ],
        },
        {
          name: 'Lucid',
          values: [
            {
              value: 'Lucid',
            },
          ],
          children: [
            {
              name: 'Air Concept',
              values: [
                {
                  value: 'Air Concept',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Luxgen',
          values: [
            {
              value: 'Luxgen',
            },
          ],
          children: [
            {
              name: 'Luxgen5',
              values: [
                {
                  value: 'Luxgen5',
                },
              ],
            },
            {
              name: 'Luxgen7 MPV',
              values: [
                {
                  value: 'Luxgen7 MPV',
                },
              ],
            },
            {
              name: 'Luxgen7 SUV',
              values: [
                {
                  value: 'Luxgen7 SUV',
                },
              ],
            },
            {
              name: 'U6 Turbo',
              values: [
                {
                  value: 'U6 Turbo',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'U7 Turbo',
              values: [
                {
                  value: 'U7 Turbo',
                },
              ],
            },
          ],
        },
        {
          name: 'Lynk \u0026 Co',
          values: [
            {
              value: 'Lynk \u0026 Co',
            },
          ],
          children: [
            {
              name: '1',
              values: [
                {
                  value: '1',
                },
              ],
            },
            {
              name: '2',
              values: [
                {
                  value: '2',
                },
              ],
            },
            {
              name: '3',
              values: [
                {
                  value: '3',
                },
              ],
            },
            {
              name: '5',
              values: [
                {
                  value: '5',
                },
              ],
            },
            {
              name: '6',
              values: [
                {
                  value: '6',
                },
              ],
            },
            {
              name: '9',
              values: [
                {
                  value: '9',
                },
              ],
            },
          ],
        },
        {
          name: 'Mahindra',
          values: [
            {
              value: 'Mahindra',
            },
          ],
          children: [
            {
              name: 'Armada',
              values: [
                {
                  value: 'Armada',
                },
              ],
            },
            {
              name: 'Bolero',
              values: [
                {
                  value: 'Bolero',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'CL',
              values: [
                {
                  value: 'CL',
                },
              ],
            },
            {
              name: 'Commander',
              values: [
                {
                  value: 'Commander',
                },
              ],
            },
            {
              name: 'Marshal',
              values: [
                {
                  value: 'Marshal',
                },
              ],
            },
            {
              name: 'MM',
              values: [
                {
                  value: 'MM',
                },
              ],
            },
            {
              name: 'NC 640 DP',
              values: [
                {
                  value: 'NC 640 DP',
                },
              ],
            },
            {
              name: 'Scorpio',
              values: [
                {
                  value: 'Scorpio',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Verito',
              values: [
                {
                  value: 'Verito',
                },
              ],
            },
            {
              name: 'Voyager',
              values: [
                {
                  value: 'Voyager',
                },
              ],
            },
            {
              name: 'Xylo',
              values: [
                {
                  value: 'Xylo',
                },
              ],
            },
          ],
        },
        {
          name: 'Marcos',
          values: [
            {
              value: 'Marcos',
            },
          ],
          children: [
            {
              name: 'Marcasite',
              values: [
                {
                  value: 'Marcasite',
                },
              ],
            },
          ],
        },
        {
          name: 'Marlin',
          values: [
            {
              value: 'Marlin',
            },
          ],
          children: [
            {
              name: '5EXi',
              values: [
                {
                  value: '5EXi',
                },
              ],
            },
            {
              name: 'Sportster',
              values: [
                {
                  value: 'Sportster',
                },
              ],
            },
          ],
        },
        {
          name: 'Marussia',
          values: [
            {
              value: 'Marussia',
            },
          ],
          children: [
            {
              name: 'B1',
              values: [
                {
                  value: 'B1',
                },
              ],
            },
            {
              name: 'B2',
              values: [
                {
                  value: 'B2',
                },
              ],
            },
          ],
        },
        {
          name: 'Maruti',
          values: [
            {
              value: 'Maruti',
            },
          ],
          children: [
            {
              name: '800',
              values: [
                {
                  value: '800',
                },
              ],
            },
            {
              name: '1000',
              values: [
                {
                  value: '1000',
                },
              ],
            },
            {
              name: 'Alto',
              values: [
                {
                  value: 'Alto',
                },
              ],
            },
            {
              name: 'Baleno',
              values: [
                {
                  value: 'Baleno',
                },
              ],
            },
            {
              name: 'Esteem',
              values: [
                {
                  value: 'Esteem',
                },
              ],
            },
            {
              name: 'Gypsy',
              values: [
                {
                  value: 'Gypsy',
                },
              ],
            },
            {
              name: 'Omni',
              values: [
                {
                  value: 'Omni',
                },
              ],
            },
            {
              name: 'Versa',
              values: [
                {
                  value: 'Versa',
                },
              ],
            },
            {
              name: 'Wagon R',
              values: [
                {
                  value: 'Wagon R',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Zen',
              values: [
                {
                  value: 'Zen',
                },
              ],
            },
          ],
        },
        {
          name: 'Maserati',
          values: [
            {
              value: 'Maserati',
            },
          ],
          children: [
            {
              name: '3200 GT',
              values: [
                {
                  value: '3200 GT',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '4200 GT',
              values: [
                {
                  value: '4200 GT',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Ghibli',
              values: [
                {
                  value: 'Ghibli',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'GranTurismo',
              values: [
                {
                  value: 'GranTurismo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Grecale',
              values: [
                {
                  value: 'Grecale',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Karif',
              values: [
                {
                  value: 'Karif',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Levante',
              values: [
                {
                  value: 'Levante',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'MC12',
              values: [
                {
                  value: 'MC12',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'MC20',
              values: [
                {
                  value: 'MC20',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Quattroporte',
              values: [
                {
                  value: 'Quattroporte',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Maybach',
          values: [
            {
              value: 'Maybach',
            },
          ],
          children: [
            {
              name: '57',
              values: [
                {
                  value: '57',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '62',
              values: [
                {
                  value: '62',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Exelero',
              values: [
                {
                  value: 'Exelero',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'Mazda',
          values: [
            {
              value: 'Mazda',
            },
          ],
          children: [
            {
              name: '2',
              values: [
                {
                  value: '2',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: '3',
              values: [
                {
                  value: '3',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: '5',
              values: [
                {
                  value: '5',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '6',
              values: [
                {
                  value: '6',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: '121',
              values: [
                {
                  value: '121',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '323',
              values: [
                {
                  value: '323',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '626',
              values: [
                {
                  value: '626',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '3 MPS',
              values: [
                {
                  value: '3 MPS',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '6 MPS',
              values: [
                {
                  value: '6 MPS',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Atenza',
              values: [
                {
                  value: 'Atenza',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Axela',
              values: [
                {
                  value: 'Axela',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'AZ-Offroad',
              values: [
                {
                  value: 'AZ-Offroad',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'AZ-Wagon',
              values: [
                {
                  value: 'AZ-Wagon',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'B-series',
              values: [
                {
                  value: 'B-series',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Biante',
              values: [
                {
                  value: 'Biante',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Bongo',
              values: [
                {
                  value: 'Bongo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Bongo Friendee',
              values: [
                {
                  value: 'Bongo Friendee',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'BT-50',
              values: [
                {
                  value: 'BT-50',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Capella',
              values: [
                {
                  value: 'Capella',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Carol',
              values: [
                {
                  value: 'Carol',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'CX-3',
              values: [
                {
                  value: 'CX-3',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'CX-30',
              values: [
                {
                  value: 'CX-30',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'CX-4',
              values: [
                {
                  value: 'CX-4',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'CX-5',
              values: [
                {
                  value: 'CX-5',
                  mostFrequentSchedule: {
                    name: 'MWF',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=MO,WE,FR;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'CX-60',
              values: [
                {
                  value: 'CX-60',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'CX-7',
              values: [
                {
                  value: 'CX-7',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'CX-8',
              values: [
                {
                  value: 'CX-8',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'CX-9',
              values: [
                {
                  value: 'CX-9',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Demio',
              values: [
                {
                  value: 'Demio',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Familia',
              values: [
                {
                  value: 'Familia',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Flair',
              values: [
                {
                  value: 'Flair',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Flair Crossover',
              values: [
                {
                  value: 'Flair Crossover',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Flair Wagon',
              values: [
                {
                  value: 'Flair Wagon',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Laputa',
              values: [
                {
                  value: 'Laputa',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Millenia',
              values: [
                {
                  value: 'Millenia',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'MPV',
              values: [
                {
                  value: 'MPV',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'MX-3',
              values: [
                {
                  value: 'MX-3',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'MX-30',
              values: [
                {
                  value: 'MX-30',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'MX-5',
              values: [
                {
                  value: 'MX-5',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'MX-6',
              values: [
                {
                  value: 'MX-6',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Premacy',
              values: [
                {
                  value: 'Premacy',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Proceed',
              values: [
                {
                  value: 'Proceed',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Proceed Levante',
              values: [
                {
                  value: 'Proceed Levante',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Protege',
              values: [
                {
                  value: 'Protege',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Roadster',
              values: [
                {
                  value: 'Roadster',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'RX-7',
              values: [
                {
                  value: 'RX-7',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'RX-8',
              values: [
                {
                  value: 'RX-8',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Scrum',
              values: [
                {
                  value: 'Scrum',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Sentia',
              values: [
                {
                  value: 'Sentia',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Spiano',
              values: [
                {
                  value: 'Spiano',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Tribute',
              values: [
                {
                  value: 'Tribute',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Verisa',
              values: [
                {
                  value: 'Verisa',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Xedos 6',
              values: [
                {
                  value: 'Xedos 6',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Xedos 9',
              values: [
                {
                  value: 'Xedos 9',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'McLaren',
          values: [
            {
              value: 'McLaren',
            },
          ],
          children: [
            {
              name: '540C',
              values: [
                {
                  value: '540C',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: '570GT',
              values: [
                {
                  value: '570GT',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: '570S',
              values: [
                {
                  value: '570S',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: '600LT',
              values: [
                {
                  value: '600LT',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: '650S',
              values: [
                {
                  value: '650S',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '675LT',
              values: [
                {
                  value: '675LT',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '720S',
              values: [
                {
                  value: '720S',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: '765LT',
              values: [
                {
                  value: '765LT',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Artura',
              values: [
                {
                  value: 'Artura',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'F1',
              values: [
                {
                  value: 'F1',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'GT',
              values: [
                {
                  value: 'GT',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'MP4-12C',
              values: [
                {
                  value: 'MP4-12C',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'P1',
              values: [
                {
                  value: 'P1',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Senna',
              values: [
                {
                  value: 'Senna',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Mega',
          values: [
            {
              value: 'Mega',
            },
          ],
          children: [
            {
              name: 'Track',
              values: [
                {
                  value: 'Track',
                },
              ],
            },
          ],
        },
        {
          name: 'Mercedes-Benz',
          values: [
            {
              value: 'Mercedes-Benz',
            },
          ],
          children: [
            {
              name: 'A Class',
              values: [
                {
                  value: 'A Class',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'A Class AMG',
              values: [
                {
                  value: 'A Class AMG',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'AMG GLC',
              values: [
                {
                  value: 'AMG GLC',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'AMG GLC Coupe',
              values: [
                {
                  value: 'AMG GLC Coupe',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'AMG GLE',
              values: [
                {
                  value: 'AMG GLE',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'AMG GLE Coupe',
              values: [
                {
                  value: 'AMG GLE Coupe',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'AMG GT',
              values: [
                {
                  value: 'AMG GT',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'AMG ONE',
              values: [
                {
                  value: 'AMG ONE',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'B Class',
              values: [
                {
                  value: 'B Class',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'C Class',
              values: [
                {
                  value: 'C Class',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'C Class AMG',
              values: [
                {
                  value: 'C Class AMG',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Citan',
              values: [
                {
                  value: 'Citan',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'CL Class',
              values: [
                {
                  value: 'CL Class',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'CL Class AMG',
              values: [
                {
                  value: 'CL Class AMG',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'CLA Class',
              values: [
                {
                  value: 'CLA Class',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'CLA Class AMG',
              values: [
                {
                  value: 'CLA Class AMG',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'CLC Class',
              values: [
                {
                  value: 'CLC Class',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'CLK Class',
              values: [
                {
                  value: 'CLK Class',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'CLK Class AMG',
              values: [
                {
                  value: 'CLK Class AMG',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'CLS Class',
              values: [
                {
                  value: 'CLS Class',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'CLS Class AMG',
              values: [
                {
                  value: 'CLS Class AMG',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'E Class',
              values: [
                {
                  value: 'E Class',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'E Class AMG',
              values: [
                {
                  value: 'E Class AMG',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'EQA',
              values: [
                {
                  value: 'EQA',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'EQB',
              values: [
                {
                  value: 'EQB',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'EQC',
              values: [
                {
                  value: 'EQC',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'EQE',
              values: [
                {
                  value: 'EQE',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'EQE AMG',
              values: [
                {
                  value: 'EQE AMG',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'EQE SUV',
              values: [
                {
                  value: 'EQE SUV',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'EQE SUV AMG',
              values: [
                {
                  value: 'EQE SUV AMG',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'EQS',
              values: [
                {
                  value: 'EQS',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'EQS AMG',
              values: [
                {
                  value: 'EQS AMG',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'EQS SUV',
              values: [
                {
                  value: 'EQS SUV',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'EQV',
              values: [
                {
                  value: 'EQV',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'G Class',
              values: [
                {
                  value: 'G Class',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'G Class AMG',
              values: [
                {
                  value: 'G Class AMG',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'G Class AMG 6x6',
              values: [
                {
                  value: 'G Class AMG 6x6',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'GL Class',
              values: [
                {
                  value: 'GL Class',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'GL Class AMG',
              values: [
                {
                  value: 'GL Class AMG',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'GLA Class',
              values: [
                {
                  value: 'GLA Class',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'GLA Class AMG',
              values: [
                {
                  value: 'GLA Class AMG',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'GLB',
              values: [
                {
                  value: 'GLB',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'GLB AMG',
              values: [
                {
                  value: 'GLB AMG',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'GLC',
              values: [
                {
                  value: 'GLC',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'GLC Coupe',
              values: [
                {
                  value: 'GLC Coupe',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'GLE',
              values: [
                {
                  value: 'GLE',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'GLE Coupe',
              values: [
                {
                  value: 'GLE Coupe',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'GLK Class',
              values: [
                {
                  value: 'GLK Class',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'GLS Class',
              values: [
                {
                  value: 'GLS Class',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'GLS Class AMG',
              values: [
                {
                  value: 'GLS Class AMG',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'M Class',
              values: [
                {
                  value: 'M Class',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'M Class AMG',
              values: [
                {
                  value: 'M Class AMG',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Marco Polo',
              values: [
                {
                  value: 'Marco Polo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Maybach G 650 Landaulet',
              values: [
                {
                  value: 'Maybach G 650 Landaulet',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Maybach GLS',
              values: [
                {
                  value: 'Maybach GLS',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Maybach S Class',
              values: [
                {
                  value: 'Maybach S Class',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Metris',
              values: [
                {
                  value: 'Metris',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'R Class',
              values: [
                {
                  value: 'R Class',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'R Class AMG',
              values: [
                {
                  value: 'R Class AMG',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'S Class',
              values: [
                {
                  value: 'S Class',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'S Class AMG',
              values: [
                {
                  value: 'S Class AMG',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'SL Class',
              values: [
                {
                  value: 'SL Class',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'SL Class AMG',
              values: [
                {
                  value: 'SL Class AMG',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'SLC Class',
              values: [
                {
                  value: 'SLC Class',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'SLC Class AMG',
              values: [
                {
                  value: 'SLC Class AMG',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'SLK Class',
              values: [
                {
                  value: 'SLK Class',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'SLK Class AMG',
              values: [
                {
                  value: 'SLK Class AMG',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'SLR McLaren',
              values: [
                {
                  value: 'SLR McLaren',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'SLS AMG',
              values: [
                {
                  value: 'SLS AMG',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'T-Klass',
              values: [
                {
                  value: 'T-Klass',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'V Class',
              values: [
                {
                  value: 'V Class',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Vaneo',
              values: [
                {
                  value: 'Vaneo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Viano',
              values: [
                {
                  value: 'Viano',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Vito',
              values: [
                {
                  value: 'Vito',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'X Class Concept',
              values: [
                {
                  value: 'X Class Concept',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'Mercury',
          values: [
            {
              value: 'Mercury',
            },
          ],
          children: [
            {
              name: 'Cougar',
              values: [
                {
                  value: 'Cougar',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Grand Marquis',
              values: [
                {
                  value: 'Grand Marquis',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Marauder',
              values: [
                {
                  value: 'Marauder',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Mariner',
              values: [
                {
                  value: 'Mariner',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Milan',
              values: [
                {
                  value: 'Milan',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Montego',
              values: [
                {
                  value: 'Montego',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Monterey',
              values: [
                {
                  value: 'Monterey',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Mountaineer',
              values: [
                {
                  value: 'Mountaineer',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Mystique',
              values: [
                {
                  value: 'Mystique',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Sable',
              values: [
                {
                  value: 'Sable',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Villager',
              values: [
                {
                  value: 'Villager',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'Metrocab',
          values: [
            {
              value: 'Metrocab',
            },
          ],
          children: [
            {
              name: 'Metrocab I',
              values: [
                {
                  value: 'Metrocab I',
                },
              ],
            },
            {
              name: 'Metrocab II (TTT)',
              values: [
                {
                  value: 'Metrocab II (TTT)',
                },
              ],
            },
          ],
        },
        {
          name: 'MG',
          values: [
            {
              value: 'MG',
            },
          ],
          children: [
            {
              name: '3',
              values: [
                {
                  value: '3',
                },
              ],
            },
            {
              name: '5',
              values: [
                {
                  value: '5',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: '6',
              values: [
                {
                  value: '6',
                },
              ],
            },
            {
              name: '350',
              values: [
                {
                  value: '350',
                },
              ],
            },
            {
              name: '550',
              values: [
                {
                  value: '550',
                },
              ],
            },
            {
              name: '750',
              values: [
                {
                  value: '750',
                },
              ],
            },
            {
              name: '4 EV',
              values: [
                {
                  value: '4 EV',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: '5 EV',
              values: [
                {
                  value: '5 EV',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'F',
              values: [
                {
                  value: 'F',
                },
              ],
            },
            {
              name: 'GS',
              values: [
                {
                  value: 'GS',
                },
              ],
            },
            {
              name: 'TF',
              values: [
                {
                  value: 'TF',
                },
              ],
            },
            {
              name: 'Xpower SV',
              values: [
                {
                  value: 'Xpower SV',
                },
              ],
            },
            {
              name: 'ZR',
              values: [
                {
                  value: 'ZR',
                },
              ],
            },
            {
              name: 'ZS',
              values: [
                {
                  value: 'ZS',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'ZT',
              values: [
                {
                  value: 'ZT',
                },
              ],
            },
          ],
        },
        {
          name: 'Microcar',
          values: [
            {
              value: 'Microcar',
            },
          ],
          children: [
            {
              name: 'F8C',
              values: [
                {
                  value: 'F8C',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'M.Go',
              values: [
                {
                  value: 'M.Go',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'M8',
              values: [
                {
                  value: 'M8',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'MC',
              values: [
                {
                  value: 'MC',
                },
              ],
            },
            {
              name: 'Virgo',
              values: [
                {
                  value: 'Virgo',
                },
              ],
            },
          ],
        },
        {
          name: 'Minelli',
          values: [
            {
              value: 'Minelli',
            },
          ],
          children: [
            {
              name: 'TF 1800',
              values: [
                {
                  value: 'TF 1800',
                },
              ],
            },
          ],
        },
        {
          name: 'MINI',
          values: [
            {
              value: 'MINI',
            },
          ],
          children: [
            {
              name: 'Cabrio',
              values: [
                {
                  value: 'Cabrio',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Clubman',
              values: [
                {
                  value: 'Clubman',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Countryman',
              values: [
                {
                  value: 'Countryman',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Coupe',
              values: [
                {
                  value: 'Coupe',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Hatch',
              values: [
                {
                  value: 'Hatch',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Paceman',
              values: [
                {
                  value: 'Paceman',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Roadster',
              values: [
                {
                  value: 'Roadster',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'Mitsubishi',
          values: [
            {
              value: 'Mitsubishi',
            },
          ],
          children: [
            {
              name: '3000 GT',
              values: [
                {
                  value: '3000 GT',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Airtrek',
              values: [
                {
                  value: 'Airtrek',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Aspire',
              values: [
                {
                  value: 'Aspire',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'ASX',
              values: [
                {
                  value: 'ASX',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Attrage',
              values: [
                {
                  value: 'Attrage',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Carisma',
              values: [
                {
                  value: 'Carisma',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Challenger',
              values: [
                {
                  value: 'Challenger',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Chariot',
              values: [
                {
                  value: 'Chariot',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Colt',
              values: [
                {
                  value: 'Colt',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Delica',
              values: [
                {
                  value: 'Delica',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Delica D:2',
              values: [
                {
                  value: 'Delica D:2',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Delica D:3',
              values: [
                {
                  value: 'Delica D:3',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Delica D:5',
              values: [
                {
                  value: 'Delica D:5',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Diamante',
              values: [
                {
                  value: 'Diamante',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Dignity',
              values: [
                {
                  value: 'Dignity',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Dingo',
              values: [
                {
                  value: 'Dingo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Dion',
              values: [
                {
                  value: 'Dion',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Eclipse',
              values: [
                {
                  value: 'Eclipse',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Eclipse Cross',
              values: [
                {
                  value: 'Eclipse Cross',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'eK',
              values: [
                {
                  value: 'eK',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'eK Active',
              values: [
                {
                  value: 'eK Active',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'eK Classic',
              values: [
                {
                  value: 'eK Classic',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'eK Custom',
              values: [
                {
                  value: 'eK Custom',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'eK Space',
              values: [
                {
                  value: 'eK Space',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'eK Sport',
              values: [
                {
                  value: 'eK Sport',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Endeavor',
              values: [
                {
                  value: 'Endeavor',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Freeca',
              values: [
                {
                  value: 'Freeca',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'FTO',
              values: [
                {
                  value: 'FTO',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Galant',
              values: [
                {
                  value: 'Galant',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Galant Fortis',
              values: [
                {
                  value: 'Galant Fortis',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Grandis',
              values: [
                {
                  value: 'Grandis',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'GTO',
              values: [
                {
                  value: 'GTO',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'i',
              values: [
                {
                  value: 'i',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'i-MiEV',
              values: [
                {
                  value: 'i-MiEV',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'L200',
              values: [
                {
                  value: 'L200',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'L400',
              values: [
                {
                  value: 'L400',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Lancer',
              values: [
                {
                  value: 'Lancer',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Lancer Cargo',
              values: [
                {
                  value: 'Lancer Cargo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Lancer Evolution',
              values: [
                {
                  value: 'Lancer Evolution',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Lancer Ralliart',
              values: [
                {
                  value: 'Lancer Ralliart',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Legnum',
              values: [
                {
                  value: 'Legnum',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Libero',
              values: [
                {
                  value: 'Libero',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Minica',
              values: [
                {
                  value: 'Minica',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Minicab',
              values: [
                {
                  value: 'Minicab',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Mirage',
              values: [
                {
                  value: 'Mirage',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Montero',
              values: [
                {
                  value: 'Montero',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Montero Sport',
              values: [
                {
                  value: 'Montero Sport',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Outlander',
              values: [
                {
                  value: 'Outlander',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Outlander Sport',
              values: [
                {
                  value: 'Outlander Sport',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Pajero',
              values: [
                {
                  value: 'Pajero',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Pajero iO',
              values: [
                {
                  value: 'Pajero iO',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Pajero Mini',
              values: [
                {
                  value: 'Pajero Mini',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Pajero Pinin',
              values: [
                {
                  value: 'Pajero Pinin',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Pajero Sport',
              values: [
                {
                  value: 'Pajero Sport',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Pistachio',
              values: [
                {
                  value: 'Pistachio',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Proudia',
              values: [
                {
                  value: 'Proudia',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Raider',
              values: [
                {
                  value: 'Raider',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'RVR',
              values: [
                {
                  value: 'RVR',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Savrin',
              values: [
                {
                  value: 'Savrin',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Space Gear',
              values: [
                {
                  value: 'Space Gear',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Space Runner',
              values: [
                {
                  value: 'Space Runner',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Space Star',
              values: [
                {
                  value: 'Space Star',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Space Wagon',
              values: [
                {
                  value: 'Space Wagon',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Toppo',
              values: [
                {
                  value: 'Toppo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Town Box',
              values: [
                {
                  value: 'Town Box',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Triton',
              values: [
                {
                  value: 'Triton',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Xpander',
              values: [
                {
                  value: 'Xpander',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Mitsuoka',
          values: [
            {
              value: 'Mitsuoka',
            },
          ],
          children: [
            {
              name: 'Galue',
              values: [
                {
                  value: 'Galue',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Galue 204',
              values: [
                {
                  value: 'Galue 204',
                },
              ],
            },
            {
              name: 'Himiko',
              values: [
                {
                  value: 'Himiko',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Le-Seyde',
              values: [
                {
                  value: 'Le-Seyde',
                },
              ],
            },
            {
              name: 'Like',
              values: [
                {
                  value: 'Like',
                },
              ],
            },
            {
              name: 'MC-1',
              values: [
                {
                  value: 'MC-1',
                },
              ],
            },
            {
              name: 'Nouera',
              values: [
                {
                  value: 'Nouera',
                },
              ],
            },
            {
              name: 'Orochi',
              values: [
                {
                  value: 'Orochi',
                },
              ],
            },
            {
              name: 'Ray',
              values: [
                {
                  value: 'Ray',
                },
              ],
            },
            {
              name: 'Rock Star',
              values: [
                {
                  value: 'Rock Star',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Ryoga',
              values: [
                {
                  value: 'Ryoga',
                },
              ],
            },
            {
              name: 'Ryugi',
              values: [
                {
                  value: 'Ryugi',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Viewt',
              values: [
                {
                  value: 'Viewt',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Yuga',
              values: [
                {
                  value: 'Yuga',
                },
              ],
            },
            {
              name: 'Zero 1',
              values: [
                {
                  value: 'Zero 1',
                },
              ],
            },
          ],
        },
        {
          name: 'Mobilize',
          values: [
            {
              value: 'Mobilize',
            },
          ],
          children: [
            {
              name: 'Limo',
              values: [
                {
                  value: 'Limo',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Morgan',
          values: [
            {
              value: 'Morgan',
            },
          ],
          children: [
            {
              name: '4-Apr',
              values: [
                {
                  value: '4-Apr',
                },
              ],
            },
            {
              name: '3 Wheeler',
              values: [
                {
                  value: '3 Wheeler',
                },
              ],
            },
            {
              name: '4 Seater',
              values: [
                {
                  value: '4 Seater',
                },
              ],
            },
            {
              name: 'Aero 8',
              values: [
                {
                  value: 'Aero 8',
                },
              ],
            },
            {
              name: 'Aero Coupe',
              values: [
                {
                  value: 'Aero Coupe',
                },
              ],
            },
            {
              name: 'Aero SuperSports',
              values: [
                {
                  value: 'Aero SuperSports',
                },
              ],
            },
            {
              name: 'AeroMax',
              values: [
                {
                  value: 'AeroMax',
                },
              ],
            },
            {
              name: 'Plus 4',
              values: [
                {
                  value: 'Plus 4',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Plus 8',
              values: [
                {
                  value: 'Plus 8',
                },
              ],
            },
            {
              name: 'Roadster',
              values: [
                {
                  value: 'Roadster',
                },
              ],
            },
          ],
        },
        {
          name: 'Moskvich',
          values: [
            {
              value: 'Moskvich',
            },
          ],
          children: [
            {
              name: '3',
              values: [
                {
                  value: '3',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: '2142',
              values: [
                {
                  value: '2142',
                },
              ],
            },
            {
              name: '3e',
              values: [
                {
                  value: '3e',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Duet',
              values: [
                {
                  value: 'Duet',
                },
              ],
            },
            {
              name: 'Ivan Kalita',
              values: [
                {
                  value: 'Ivan Kalita',
                },
              ],
            },
            {
              name: 'Knyazy Vladimir',
              values: [
                {
                  value: 'Knyazy Vladimir',
                },
              ],
            },
            {
              name: 'Svyatogor',
              values: [
                {
                  value: 'Svyatogor',
                },
              ],
            },
            {
              name: 'Yuriy Dolgorukiy',
              values: [
                {
                  value: 'Yuriy Dolgorukiy',
                },
              ],
            },
          ],
        },
        {
          name: 'Nio',
          values: [
            {
              value: 'Nio',
            },
          ],
          children: [
            {
              name: 'EC6',
              values: [
                {
                  value: 'EC6',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'ES6',
              values: [
                {
                  value: 'ES6',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'ES8',
              values: [
                {
                  value: 'ES8',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'ET5',
              values: [
                {
                  value: 'ET5',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'ET7',
              values: [
                {
                  value: 'ET7',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Nissan',
          values: [
            {
              value: 'Nissan',
            },
          ],
          children: [
            {
              name: '200SX',
              values: [
                {
                  value: '200SX',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '300ZX',
              values: [
                {
                  value: '300ZX',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '350Z',
              values: [
                {
                  value: '350Z',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '370Z',
              values: [
                {
                  value: '370Z',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'AD',
              values: [
                {
                  value: 'AD',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Almera',
              values: [
                {
                  value: 'Almera',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Almera Classic',
              values: [
                {
                  value: 'Almera Classic',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Almera Tino',
              values: [
                {
                  value: 'Almera Tino',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Altima',
              values: [
                {
                  value: 'Altima',
                  mostFrequentSchedule: {
                    name: 'MWF',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=MO,WE,FR;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Ariya',
              values: [
                {
                  value: 'Ariya',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Armada',
              values: [
                {
                  value: 'Armada',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Avenir',
              values: [
                {
                  value: 'Avenir',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Bassara',
              values: [
                {
                  value: 'Bassara',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Bluebird',
              values: [
                {
                  value: 'Bluebird',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Bluebird Sylphy',
              values: [
                {
                  value: 'Bluebird Sylphy',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Caravan',
              values: [
                {
                  value: 'Caravan',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Cedric',
              values: [
                {
                  value: 'Cedric',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Cefiro',
              values: [
                {
                  value: 'Cefiro',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Cima',
              values: [
                {
                  value: 'Cima',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Clipper',
              values: [
                {
                  value: 'Clipper',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Clipper Rio',
              values: [
                {
                  value: 'Clipper Rio',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Crew',
              values: [
                {
                  value: 'Crew',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Cube',
              values: [
                {
                  value: 'Cube',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Datsun',
              values: [
                {
                  value: 'Datsun',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Dayz',
              values: [
                {
                  value: 'Dayz',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Dayz Roox',
              values: [
                {
                  value: 'Dayz Roox',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Dualis',
              values: [
                {
                  value: 'Dualis',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Elgrand',
              values: [
                {
                  value: 'Elgrand',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Expert',
              values: [
                {
                  value: 'Expert',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Fairlady Z',
              values: [
                {
                  value: 'Fairlady Z',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Fuga',
              values: [
                {
                  value: 'Fuga',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Gloria',
              values: [
                {
                  value: 'Gloria',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'GT-R',
              values: [
                {
                  value: 'GT-R',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Hypermini',
              values: [
                {
                  value: 'Hypermini',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Juke',
              values: [
                {
                  value: 'Juke',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Juke Nismo',
              values: [
                {
                  value: 'Juke Nismo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Kicks',
              values: [
                {
                  value: 'Kicks',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Kix',
              values: [
                {
                  value: 'Kix',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Lafesta',
              values: [
                {
                  value: 'Lafesta',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Latio',
              values: [
                {
                  value: 'Latio',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Laurel',
              values: [
                {
                  value: 'Laurel',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Leaf',
              values: [
                {
                  value: 'Leaf',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Leopard',
              values: [
                {
                  value: 'Leopard',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Liberty',
              values: [
                {
                  value: 'Liberty',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Livina',
              values: [
                {
                  value: 'Livina',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Lucino',
              values: [
                {
                  value: 'Lucino',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'March',
              values: [
                {
                  value: 'March',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Maxima',
              values: [
                {
                  value: 'Maxima',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Micra',
              values: [
                {
                  value: 'Micra',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Moco',
              values: [
                {
                  value: 'Moco',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Murano',
              values: [
                {
                  value: 'Murano',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Navara (Frontier)',
              values: [
                {
                  value: 'Navara (Frontier)',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Note',
              values: [
                {
                  value: 'Note',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'NP 300',
              values: [
                {
                  value: 'NP 300',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'NV200',
              values: [
                {
                  value: 'NV200',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'NV300',
              values: [
                {
                  value: 'NV300',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'NV350 Caravan',
              values: [
                {
                  value: 'NV350 Caravan',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Otti (Dayz)',
              values: [
                {
                  value: 'Otti (Dayz)',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Pathfinder',
              values: [
                {
                  value: 'Pathfinder',
                  mostFrequentSchedule: {
                    name: 'MWF',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=MO,WE,FR;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Patrol',
              values: [
                {
                  value: 'Patrol',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Pino',
              values: [
                {
                  value: 'Pino',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Pixo',
              values: [
                {
                  value: 'Pixo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Prairie',
              values: [
                {
                  value: 'Prairie',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Presage',
              values: [
                {
                  value: 'Presage',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Presea',
              values: [
                {
                  value: 'Presea',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'President',
              values: [
                {
                  value: 'President',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Primastar',
              values: [
                {
                  value: 'Primastar',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Primera',
              values: [
                {
                  value: 'Primera',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Pulsar',
              values: [
                {
                  value: 'Pulsar',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Qashqai',
              values: [
                {
                  value: 'Qashqai',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Qashqai+2',
              values: [
                {
                  value: 'Qashqai+2',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Quest',
              values: [
                {
                  value: 'Quest',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: "R'nessa",
              values: [
                {
                  value: "R'nessa",
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Rasheen',
              values: [
                {
                  value: 'Rasheen',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Rogue',
              values: [
                {
                  value: 'Rogue',
                  mostFrequentSchedule: {
                    name: 'Daily',
                    rrule: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Rogue Sport',
              values: [
                {
                  value: 'Rogue Sport',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Roox',
              values: [
                {
                  value: 'Roox',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Safari',
              values: [
                {
                  value: 'Safari',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Sentra',
              values: [
                {
                  value: 'Sentra',
                  mostFrequentSchedule: {
                    name: 'MWF',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=MO,WE,FR;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Serena',
              values: [
                {
                  value: 'Serena',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Silvia',
              values: [
                {
                  value: 'Silvia',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Skyline',
              values: [
                {
                  value: 'Skyline',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Skyline Crossover',
              values: [
                {
                  value: 'Skyline Crossover',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Stagea',
              values: [
                {
                  value: 'Stagea',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Sunny',
              values: [
                {
                  value: 'Sunny',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Sylphy Zero Emission',
              values: [
                {
                  value: 'Sylphy Zero Emission',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Teana',
              values: [
                {
                  value: 'Teana',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Terra',
              values: [
                {
                  value: 'Terra',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Terrano',
              values: [
                {
                  value: 'Terrano',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Terrano Regulus',
              values: [
                {
                  value: 'Terrano Regulus',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Tiida',
              values: [
                {
                  value: 'Tiida',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Tino',
              values: [
                {
                  value: 'Tino',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Titan',
              values: [
                {
                  value: 'Titan',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Urvan',
              values: [
                {
                  value: 'Urvan',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Vanette',
              values: [
                {
                  value: 'Vanette',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Versa',
              values: [
                {
                  value: 'Versa',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Wingroad',
              values: [
                {
                  value: 'Wingroad',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'X-Terra',
              values: [
                {
                  value: 'X-Terra',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'X-Trail',
              values: [
                {
                  value: 'X-Trail',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Z',
              values: [
                {
                  value: 'Z',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Noble',
          values: [
            {
              value: 'Noble',
            },
          ],
          children: [
            {
              name: 'M12 GTO',
              values: [
                {
                  value: 'M12 GTO',
                },
              ],
            },
            {
              name: 'M15',
              values: [
                {
                  value: 'M15',
                },
              ],
            },
            {
              name: 'M600',
              values: [
                {
                  value: 'M600',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Oldsmobile',
          values: [
            {
              value: 'Oldsmobile',
            },
          ],
          children: [
            {
              name: 'Alero',
              values: [
                {
                  value: 'Alero',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Aurora',
              values: [
                {
                  value: 'Aurora',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Bravada',
              values: [
                {
                  value: 'Bravada',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Intrigue',
              values: [
                {
                  value: 'Intrigue',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Silhouette',
              values: [
                {
                  value: 'Silhouette',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'Omoda',
          values: [
            {
              value: 'Omoda',
            },
          ],
          children: [
            {
              name: 'C5',
              values: [
                {
                  value: 'C5',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Opel',
          values: [
            {
              value: 'Opel',
            },
          ],
          children: [
            {
              name: 'Adam',
              values: [
                {
                  value: 'Adam',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Agila',
              values: [
                {
                  value: 'Agila',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Ampera',
              values: [
                {
                  value: 'Ampera',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Antara',
              values: [
                {
                  value: 'Antara',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Astra',
              values: [
                {
                  value: 'Astra',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Astra OPC',
              values: [
                {
                  value: 'Astra OPC',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Campo',
              values: [
                {
                  value: 'Campo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Cascada',
              values: [
                {
                  value: 'Cascada',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Combo',
              values: [
                {
                  value: 'Combo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Corsa',
              values: [
                {
                  value: 'Corsa',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Corsa OPC',
              values: [
                {
                  value: 'Corsa OPC',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Crossland X',
              values: [
                {
                  value: 'Crossland X',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Frontera',
              values: [
                {
                  value: 'Frontera',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Grandland X',
              values: [
                {
                  value: 'Grandland X',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'GT',
              values: [
                {
                  value: 'GT',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Insignia',
              values: [
                {
                  value: 'Insignia',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Insignia OPC',
              values: [
                {
                  value: 'Insignia OPC',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Karl',
              values: [
                {
                  value: 'Karl',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Meriva',
              values: [
                {
                  value: 'Meriva',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Meriva OPC',
              values: [
                {
                  value: 'Meriva OPC',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Mokka',
              values: [
                {
                  value: 'Mokka',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Omega',
              values: [
                {
                  value: 'Omega',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Signum',
              values: [
                {
                  value: 'Signum',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Speedster',
              values: [
                {
                  value: 'Speedster',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Tigra',
              values: [
                {
                  value: 'Tigra',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Vectra',
              values: [
                {
                  value: 'Vectra',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Vectra OPC',
              values: [
                {
                  value: 'Vectra OPC',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Vita',
              values: [
                {
                  value: 'Vita',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Vivaro',
              values: [
                {
                  value: 'Vivaro',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Zafira',
              values: [
                {
                  value: 'Zafira',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Zafira Life',
              values: [
                {
                  value: 'Zafira Life',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Zafira OPC',
              values: [
                {
                  value: 'Zafira OPC',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'Osca',
          values: [
            {
              value: 'Osca',
            },
          ],
          children: [
            {
              name: '2500 GT',
              values: [
                {
                  value: '2500 GT',
                },
              ],
            },
          ],
        },
        {
          name: 'Oshan',
          values: [
            {
              value: 'Oshan',
            },
          ],
          children: [
            {
              name: 'X5',
              values: [
                {
                  value: 'X5',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'X7',
              values: [
                {
                  value: 'X7',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'X7 Plus',
              values: [
                {
                  value: 'X7 Plus',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Z6',
              values: [
                {
                  value: 'Z6',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Pagani',
          values: [
            {
              value: 'Pagani',
            },
          ],
          children: [
            {
              name: 'Huayra',
              values: [
                {
                  value: 'Huayra',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Utopia',
              values: [
                {
                  value: 'Utopia',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Zonda',
              values: [
                {
                  value: 'Zonda',
                },
              ],
            },
          ],
        },
        {
          name: 'Panoz',
          values: [
            {
              value: 'Panoz',
            },
          ],
          children: [
            {
              name: 'Esperante',
              values: [
                {
                  value: 'Esperante',
                },
              ],
            },
          ],
        },
        {
          name: 'Perodua',
          values: [
            {
              value: 'Perodua',
            },
          ],
          children: [
            {
              name: 'Alza',
              values: [
                {
                  value: 'Alza',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Kancil',
              values: [
                {
                  value: 'Kancil',
                },
              ],
            },
            {
              name: 'Kelisa',
              values: [
                {
                  value: 'Kelisa',
                },
              ],
            },
            {
              name: 'Kembara',
              values: [
                {
                  value: 'Kembara',
                },
              ],
            },
            {
              name: 'Kenari',
              values: [
                {
                  value: 'Kenari',
                },
              ],
            },
            {
              name: 'MyVi',
              values: [
                {
                  value: 'MyVi',
                },
              ],
            },
            {
              name: 'Nautica',
              values: [
                {
                  value: 'Nautica',
                },
              ],
            },
            {
              name: 'Viva',
              values: [
                {
                  value: 'Viva',
                },
              ],
            },
          ],
        },
        {
          name: 'Peugeot',
          values: [
            {
              value: 'Peugeot',
            },
          ],
          children: [
            {
              name: '106',
              values: [
                {
                  value: '106',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '107',
              values: [
                {
                  value: '107',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '108',
              values: [
                {
                  value: '108',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '206',
              values: [
                {
                  value: '206',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '207',
              values: [
                {
                  value: '207',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '208',
              values: [
                {
                  value: '208',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: '301',
              values: [
                {
                  value: '301',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: '306',
              values: [
                {
                  value: '306',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '307',
              values: [
                {
                  value: '307',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '308',
              values: [
                {
                  value: '308',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: '405',
              values: [
                {
                  value: '405',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '406',
              values: [
                {
                  value: '406',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '407',
              values: [
                {
                  value: '407',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '408',
              values: [
                {
                  value: '408',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: '508',
              values: [
                {
                  value: '508',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: '605',
              values: [
                {
                  value: '605',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '607',
              values: [
                {
                  value: '607',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '806',
              values: [
                {
                  value: '806',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '807',
              values: [
                {
                  value: '807',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '1007',
              values: [
                {
                  value: '1007',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '2008',
              values: [
                {
                  value: '2008',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: '3008',
              values: [
                {
                  value: '3008',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: '4007',
              values: [
                {
                  value: '4007',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '4008',
              values: [
                {
                  value: '4008',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '5008',
              values: [
                {
                  value: '5008',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: '208 GTi',
              values: [
                {
                  value: '208 GTi',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '308 GTi',
              values: [
                {
                  value: '308 GTi',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Bipper',
              values: [
                {
                  value: 'Bipper',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Expert',
              values: [
                {
                  value: 'Expert',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'iOn',
              values: [
                {
                  value: 'iOn',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Partner',
              values: [
                {
                  value: 'Partner',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'RCZ',
              values: [
                {
                  value: 'RCZ',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Rifter',
              values: [
                {
                  value: 'Rifter',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Traveller',
              values: [
                {
                  value: 'Traveller',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'PGO',
          values: [
            {
              value: 'PGO',
            },
          ],
          children: [
            {
              name: 'Cevennes',
              values: [
                {
                  value: 'Cevennes',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Hemera',
              values: [
                {
                  value: 'Hemera',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Speedster II',
              values: [
                {
                  value: 'Speedster II',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Piaggio',
          values: [
            {
              value: 'Piaggio',
            },
          ],
          children: [
            {
              name: 'Porter',
              values: [
                {
                  value: 'Porter',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Plymouth',
          values: [
            {
              value: 'Plymouth',
            },
          ],
          children: [
            {
              name: 'Breeze',
              values: [
                {
                  value: 'Breeze',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Neon',
              values: [
                {
                  value: 'Neon',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Prowler',
              values: [
                {
                  value: 'Prowler',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Voyager',
              values: [
                {
                  value: 'Voyager',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'Polestar',
          values: [
            {
              value: 'Polestar',
            },
          ],
          children: [
            {
              name: '1',
              values: [
                {
                  value: '1',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: '2',
              values: [
                {
                  value: '2',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Pontiac',
          values: [
            {
              value: 'Pontiac',
            },
          ],
          children: [
            {
              name: 'Aztek',
              values: [
                {
                  value: 'Aztek',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Bonneville',
              values: [
                {
                  value: 'Bonneville',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Firebird',
              values: [
                {
                  value: 'Firebird',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'G4',
              values: [
                {
                  value: 'G4',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'G5',
              values: [
                {
                  value: 'G5',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'G6',
              values: [
                {
                  value: 'G6',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'G8',
              values: [
                {
                  value: 'G8',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Grand AM',
              values: [
                {
                  value: 'Grand AM',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Grand Prix',
              values: [
                {
                  value: 'Grand Prix',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'GTO',
              values: [
                {
                  value: 'GTO',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Montana',
              values: [
                {
                  value: 'Montana',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Solstice',
              values: [
                {
                  value: 'Solstice',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Sunfire',
              values: [
                {
                  value: 'Sunfire',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Torrent',
              values: [
                {
                  value: 'Torrent',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Vibe',
              values: [
                {
                  value: 'Vibe',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Wave',
              values: [
                {
                  value: 'Wave',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'Porsche',
          values: [
            {
              value: 'Porsche',
            },
          ],
          children: [
            {
              name: '911',
              values: [
                {
                  value: '911',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: '718 Boxster',
              values: [
                {
                  value: '718 Boxster',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '718 Cayman',
              values: [
                {
                  value: '718 Cayman',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '718 Spyder',
              values: [
                {
                  value: '718 Spyder',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: '911 GT2',
              values: [
                {
                  value: '911 GT2',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '911 GT3',
              values: [
                {
                  value: '911 GT3',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: '911 R',
              values: [
                {
                  value: '911 R',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '918 Spyder',
              values: [
                {
                  value: '918 Spyder',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Boxster',
              values: [
                {
                  value: 'Boxster',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Carrera GT',
              values: [
                {
                  value: 'Carrera GT',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Cayenne',
              values: [
                {
                  value: 'Cayenne',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Cayenne Coupe',
              values: [
                {
                  value: 'Cayenne Coupe',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Cayman',
              values: [
                {
                  value: 'Cayman',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Cayman GT4',
              values: [
                {
                  value: 'Cayman GT4',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Macan',
              values: [
                {
                  value: 'Macan',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Panamera',
              values: [
                {
                  value: 'Panamera',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Taycan',
              values: [
                {
                  value: 'Taycan',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Premier',
          values: [
            {
              value: 'Premier',
            },
          ],
          children: [
            {
              name: '118NE',
              values: [
                {
                  value: '118NE',
                },
              ],
            },
            {
              name: 'Padmini',
              values: [
                {
                  value: 'Padmini',
                },
              ],
            },
          ],
        },
        {
          name: 'Proton',
          values: [
            {
              value: 'Proton',
            },
          ],
          children: [
            {
              name: 'Arena',
              values: [
                {
                  value: 'Arena',
                },
              ],
            },
            {
              name: 'Exora',
              values: [
                {
                  value: 'Exora',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Gen-2',
              values: [
                {
                  value: 'Gen-2',
                },
              ],
            },
            {
              name: 'Inspira',
              values: [
                {
                  value: 'Inspira',
                },
              ],
            },
            {
              name: 'Iriz',
              values: [
                {
                  value: 'Iriz',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Juara',
              values: [
                {
                  value: 'Juara',
                },
              ],
            },
            {
              name: 'Perdana',
              values: [
                {
                  value: 'Perdana',
                },
              ],
            },
            {
              name: 'Persona',
              values: [
                {
                  value: 'Persona',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Preve',
              values: [
                {
                  value: 'Preve',
                },
              ],
            },
            {
              name: 'Putra',
              values: [
                {
                  value: 'Putra',
                },
              ],
            },
            {
              name: 'Saga',
              values: [
                {
                  value: 'Saga',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Satria',
              values: [
                {
                  value: 'Satria',
                },
              ],
            },
            {
              name: 'Savvy',
              values: [
                {
                  value: 'Savvy',
                },
              ],
            },
            {
              name: 'Tiara',
              values: [
                {
                  value: 'Tiara',
                },
              ],
            },
            {
              name: 'Waja',
              values: [
                {
                  value: 'Waja',
                },
              ],
            },
            {
              name: 'Wira (400 Series)',
              values: [
                {
                  value: 'Wira (400 Series)',
                },
              ],
            },
            {
              name: 'X50',
              values: [
                {
                  value: 'X50',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'X70',
              values: [
                {
                  value: 'X70',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'PUCH',
          values: [
            {
              value: 'PUCH',
            },
          ],
          children: [
            {
              name: 'G-modell',
              values: [
                {
                  value: 'G-modell',
                  mostFrequentSchedule: {
                    name: 'Never',
                  },
                },
              ],
            },
            {
              name: 'Pinzgauer',
              values: [
                {
                  value: 'Pinzgauer',
                  mostFrequentSchedule: {
                    name: 'Never',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'Qoros',
          values: [
            {
              value: 'Qoros',
            },
          ],
          children: [
            {
              name: '3',
              values: [
                {
                  value: '3',
                  mostFrequentSchedule: {
                    name: 'Daily',
                    rrule: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: '5',
              values: [
                {
                  value: '5',
                  mostFrequentSchedule: {
                    name: 'Never',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Qvale',
          values: [
            {
              value: 'Qvale',
            },
          ],
          children: [
            {
              name: 'Mangusta',
              values: [
                {
                  value: 'Mangusta',
                },
              ],
            },
          ],
        },
        {
          name: 'RAM',
          values: [
            {
              value: 'RAM',
            },
          ],
          children: [
            {
              name: '1500',
              values: [
                {
                  value: '1500',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'ProMaster City',
              values: [
                {
                  value: 'ProMaster City',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Ravon',
          values: [
            {
              value: 'Ravon',
            },
          ],
          children: [
            {
              name: 'Gentra',
              values: [
                {
                  value: 'Gentra',
                },
              ],
            },
            {
              name: 'Matiz',
              values: [
                {
                  value: 'Matiz',
                },
              ],
            },
            {
              name: 'Nexia R3',
              values: [
                {
                  value: 'Nexia R3',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
              ],
            },
            {
              name: 'R2',
              values: [
                {
                  value: 'R2',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
              ],
            },
            {
              name: 'R4',
              values: [
                {
                  value: 'R4',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Renault',
          values: [
            {
              value: 'Renault',
            },
          ],
          children: [
            {
              name: '19',
              values: [
                {
                  value: '19',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Alaskan',
              values: [
                {
                  value: 'Alaskan',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Arkana',
              values: [
                {
                  value: 'Arkana',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Austral',
              values: [
                {
                  value: 'Austral',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Avantime',
              values: [
                {
                  value: 'Avantime',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Captur',
              values: [
                {
                  value: 'Captur',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'City K-ZE',
              values: [
                {
                  value: 'City K-ZE',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Clio',
              values: [
                {
                  value: 'Clio',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Clio RS',
              values: [
                {
                  value: 'Clio RS',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Clio V6',
              values: [
                {
                  value: 'Clio V6',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Dokker',
              values: [
                {
                  value: 'Dokker',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Duster',
              values: [
                {
                  value: 'Duster',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Espace',
              values: [
                {
                  value: 'Espace',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Fluence',
              values: [
                {
                  value: 'Fluence',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Kadjar',
              values: [
                {
                  value: 'Kadjar',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Kangoo',
              values: [
                {
                  value: 'Kangoo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Kaptur',
              values: [
                {
                  value: 'Kaptur',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Koleos',
              values: [
                {
                  value: 'Koleos',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'KWID',
              values: [
                {
                  value: 'KWID',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Laguna',
              values: [
                {
                  value: 'Laguna',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Latitude',
              values: [
                {
                  value: 'Latitude',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Lodgy',
              values: [
                {
                  value: 'Lodgy',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Logan',
              values: [
                {
                  value: 'Logan',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Megane',
              values: [
                {
                  value: 'Megane',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Megane E-Tech',
              values: [
                {
                  value: 'Megane E-Tech',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Megane RS',
              values: [
                {
                  value: 'Megane RS',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Modus',
              values: [
                {
                  value: 'Modus',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Safrane',
              values: [
                {
                  value: 'Safrane',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Sandero',
              values: [
                {
                  value: 'Sandero',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Sandero RS',
              values: [
                {
                  value: 'Sandero RS',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Scenic',
              values: [
                {
                  value: 'Scenic',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Sport Spider',
              values: [
                {
                  value: 'Sport Spider',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Symbol',
              values: [
                {
                  value: 'Symbol',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Talisman',
              values: [
                {
                  value: 'Talisman',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Trafic',
              values: [
                {
                  value: 'Trafic',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Twingo',
              values: [
                {
                  value: 'Twingo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Twizy',
              values: [
                {
                  value: 'Twizy',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Vel Satis',
              values: [
                {
                  value: 'Vel Satis',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Wind',
              values: [
                {
                  value: 'Wind',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'ZOE',
              values: [
                {
                  value: 'ZOE',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Renault Samsung',
          values: [
            {
              value: 'Renault Samsung',
            },
          ],
          children: [
            {
              name: 'QM3',
              values: [
                {
                  value: 'QM3',
                },
              ],
            },
            {
              name: 'QM5',
              values: [
                {
                  value: 'QM5',
                },
              ],
            },
            {
              name: 'QM6',
              values: [
                {
                  value: 'QM6',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'SM3',
              values: [
                {
                  value: 'SM3',
                },
              ],
            },
            {
              name: 'SM5',
              values: [
                {
                  value: 'SM5',
                },
              ],
            },
            {
              name: 'SM6',
              values: [
                {
                  value: 'SM6',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'SM7',
              values: [
                {
                  value: 'SM7',
                },
              ],
            },
            {
              name: 'XM3',
              values: [
                {
                  value: 'XM3',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Rezvani',
          values: [
            {
              value: 'Rezvani',
            },
          ],
          children: [
            {
              name: 'Beast',
              values: [
                {
                  value: 'Beast',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Tank',
              values: [
                {
                  value: 'Tank',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Rimac',
          values: [
            {
              value: 'Rimac',
            },
          ],
          children: [
            {
              name: 'C Two',
              values: [
                {
                  value: 'C Two',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Concept_One',
              values: [
                {
                  value: 'Concept_One',
                },
              ],
            },
            {
              name: 'Nevera',
              values: [
                {
                  value: 'Nevera',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Rinspeed',
          values: [
            {
              value: 'Rinspeed',
            },
          ],
          children: [
            {
              name: 'Chopster',
              values: [
                {
                  value: 'Chopster',
                },
              ],
            },
          ],
        },
        {
          name: 'Rivian',
          values: [
            {
              value: 'Rivian',
            },
          ],
          children: [
            {
              name: 'R1S',
              values: [
                {
                  value: 'R1S',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'R1T',
              values: [
                {
                  value: 'R1T',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Roewe',
          values: [
            {
              value: 'Roewe',
            },
          ],
          children: [
            {
              name: 'E50',
              values: [
                {
                  value: 'E50',
                },
              ],
            },
            {
              name: 'Ei5',
              values: [
                {
                  value: 'Ei5',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'i5',
              values: [
                {
                  value: 'i5',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'i6',
              values: [
                {
                  value: 'i6',
                },
              ],
            },
            {
              name: 'i6 Max',
              values: [
                {
                  value: 'i6 Max',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'iMAX8',
              values: [
                {
                  value: 'iMAX8',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'RX3',
              values: [
                {
                  value: 'RX3',
                },
              ],
            },
            {
              name: 'RX3 Pro',
              values: [
                {
                  value: 'RX3 Pro',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'RX5',
              values: [
                {
                  value: 'RX5',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'RX5 Max',
              values: [
                {
                  value: 'RX5 Max',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'RX8',
              values: [
                {
                  value: 'RX8',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Rolls-Royce',
          values: [
            {
              value: 'Rolls-Royce',
            },
          ],
          children: [
            {
              name: 'Corniche',
              values: [
                {
                  value: 'Corniche',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Cullinan',
              values: [
                {
                  value: 'Cullinan',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Dawn',
              values: [
                {
                  value: 'Dawn',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Ghost',
              values: [
                {
                  value: 'Ghost',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Park Ward',
              values: [
                {
                  value: 'Park Ward',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Phantom',
              values: [
                {
                  value: 'Phantom',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Silver Seraph',
              values: [
                {
                  value: 'Silver Seraph',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Spectre',
              values: [
                {
                  value: 'Spectre',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Wraith',
              values: [
                {
                  value: 'Wraith',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Ronart',
          values: [
            {
              value: 'Ronart',
            },
          ],
          children: [
            {
              name: 'Lightning',
              values: [
                {
                  value: 'Lightning',
                },
              ],
            },
          ],
        },
        {
          name: 'Rover',
          values: [
            {
              value: 'Rover',
            },
          ],
          children: [
            {
              name: '25',
              values: [
                {
                  value: '25',
                },
              ],
            },
            {
              name: '45',
              values: [
                {
                  value: '45',
                },
              ],
            },
            {
              name: '75',
              values: [
                {
                  value: '75',
                },
              ],
            },
            {
              name: '100',
              values: [
                {
                  value: '100',
                },
              ],
            },
            {
              name: '200',
              values: [
                {
                  value: '200',
                },
              ],
            },
            {
              name: '400',
              values: [
                {
                  value: '400',
                },
              ],
            },
            {
              name: 'Mini',
              values: [
                {
                  value: 'Mini',
                },
              ],
            },
            {
              name: 'Streetwise',
              values: [
                {
                  value: 'Streetwise',
                },
              ],
            },
          ],
        },
        {
          name: 'Saab',
          values: [
            {
              value: 'Saab',
            },
          ],
          children: [
            {
              name: '3-Sep',
              values: [
                {
                  value: '3-Sep',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '5-Sep',
              values: [
                {
                  value: '5-Sep',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '9-2X',
              values: [
                {
                  value: '9-2X',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '9-4X',
              values: [
                {
                  value: '9-4X',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: '9-7X',
              values: [
                {
                  value: '9-7X',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'Saipa',
          values: [
            {
              value: 'Saipa',
            },
          ],
          children: [
            {
              name: 'Saina',
              values: [
                {
                  value: 'Saina',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Tiba',
              values: [
                {
                  value: 'Tiba',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Saleen',
          values: [
            {
              value: 'Saleen',
            },
          ],
          children: [
            {
              name: 'S7',
              values: [
                {
                  value: 'S7',
                },
              ],
            },
          ],
        },
        {
          name: 'Santana',
          values: [
            {
              value: 'Santana',
            },
          ],
          children: [
            {
              name: 'PS-10',
              values: [
                {
                  value: 'PS-10',
                },
              ],
            },
          ],
        },
        {
          name: 'Saturn',
          values: [
            {
              value: 'Saturn',
            },
          ],
          children: [
            {
              name: 'Astra',
              values: [
                {
                  value: 'Astra',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Aura',
              values: [
                {
                  value: 'Aura',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'ION',
              values: [
                {
                  value: 'ION',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'LS',
              values: [
                {
                  value: 'LS',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'LW',
              values: [
                {
                  value: 'LW',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Outlook',
              values: [
                {
                  value: 'Outlook',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Relay',
              values: [
                {
                  value: 'Relay',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'SC',
              values: [
                {
                  value: 'SC',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Sky',
              values: [
                {
                  value: 'Sky',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'SL',
              values: [
                {
                  value: 'SL',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'SW',
              values: [
                {
                  value: 'SW',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'VUE',
              values: [
                {
                  value: 'VUE',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'Scion',
          values: [
            {
              value: 'Scion',
            },
          ],
          children: [
            {
              name: 'FR-S',
              values: [
                {
                  value: 'FR-S',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'iA',
              values: [
                {
                  value: 'iA',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'iM',
              values: [
                {
                  value: 'iM',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'iQ',
              values: [
                {
                  value: 'iQ',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'tC',
              values: [
                {
                  value: 'tC',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'xA',
              values: [
                {
                  value: 'xA',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'xB',
              values: [
                {
                  value: 'xB',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'xD',
              values: [
                {
                  value: 'xD',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'SEAT',
          values: [
            {
              value: 'SEAT',
            },
          ],
          children: [
            {
              name: 'Alhambra',
              values: [
                {
                  value: 'Alhambra',
                },
              ],
            },
            {
              name: 'Altea',
              values: [
                {
                  value: 'Altea',
                },
              ],
            },
            {
              name: 'Arona',
              values: [
                {
                  value: 'Arona',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Arosa',
              values: [
                {
                  value: 'Arosa',
                },
              ],
            },
            {
              name: 'Ateca',
              values: [
                {
                  value: 'Ateca',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Cordoba',
              values: [
                {
                  value: 'Cordoba',
                },
              ],
            },
            {
              name: 'Exeo',
              values: [
                {
                  value: 'Exeo',
                },
              ],
            },
            {
              name: 'Ibiza',
              values: [
                {
                  value: 'Ibiza',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Ibiza Cupra',
              values: [
                {
                  value: 'Ibiza Cupra',
                },
              ],
            },
            {
              name: 'Inca',
              values: [
                {
                  value: 'Inca',
                },
              ],
            },
            {
              name: 'Leon',
              values: [
                {
                  value: 'Leon',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Leon Cupra',
              values: [
                {
                  value: 'Leon Cupra',
                },
              ],
            },
            {
              name: 'Mii',
              values: [
                {
                  value: 'Mii',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Tarraco',
              values: [
                {
                  value: 'Tarraco',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Toledo',
              values: [
                {
                  value: 'Toledo',
                },
              ],
            },
          ],
        },
        {
          name: 'Seres',
          values: [
            {
              value: 'Seres',
            },
          ],
          children: [
            {
              name: 'SF5',
              values: [
                {
                  value: 'SF5',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Shanghai Maple',
          values: [
            {
              value: 'Shanghai Maple',
            },
          ],
          children: [
            {
              name: 'C31',
              values: [
                {
                  value: 'C31',
                },
              ],
            },
            {
              name: 'C32',
              values: [
                {
                  value: 'C32',
                },
              ],
            },
            {
              name: 'C61',
              values: [
                {
                  value: 'C61',
                },
              ],
            },
            {
              name: 'S51',
              values: [
                {
                  value: 'S51',
                },
              ],
            },
            {
              name: 'S52',
              values: [
                {
                  value: 'S52',
                },
              ],
            },
            {
              name: 'S81',
              values: [
                {
                  value: 'S81',
                },
              ],
            },
          ],
        },
        {
          name: 'ShuangHuan',
          values: [
            {
              value: 'ShuangHuan',
            },
          ],
          children: [
            {
              name: 'Noble',
              values: [
                {
                  value: 'Noble',
                },
              ],
            },
            {
              name: 'Sceo',
              values: [
                {
                  value: 'Sceo',
                },
              ],
            },
          ],
        },
        {
          name: 'Skoda',
          values: [
            {
              value: 'Skoda',
            },
          ],
          children: [
            {
              name: 'Citigo',
              values: [
                {
                  value: 'Citigo',
                },
              ],
            },
            {
              name: 'Enyaq',
              values: [
                {
                  value: 'Enyaq',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Enyaq Coupe',
              values: [
                {
                  value: 'Enyaq Coupe',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Enyaq Coupe RS',
              values: [
                {
                  value: 'Enyaq Coupe RS',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Enyaq RS',
              values: [
                {
                  value: 'Enyaq RS',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Fabia',
              values: [
                {
                  value: 'Fabia',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Fabia RS',
              values: [
                {
                  value: 'Fabia RS',
                },
              ],
            },
            {
              name: 'Favorit',
              values: [
                {
                  value: 'Favorit',
                },
              ],
            },
            {
              name: 'Felicia',
              values: [
                {
                  value: 'Felicia',
                },
              ],
            },
            {
              name: 'Kamiq',
              values: [
                {
                  value: 'Kamiq',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Karoq',
              values: [
                {
                  value: 'Karoq',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Kodiaq',
              values: [
                {
                  value: 'Kodiaq',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Kodiaq RS',
              values: [
                {
                  value: 'Kodiaq RS',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Octavia',
              values: [
                {
                  value: 'Octavia',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Octavia RS',
              values: [
                {
                  value: 'Octavia RS',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Rapid',
              values: [
                {
                  value: 'Rapid',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Roomster',
              values: [
                {
                  value: 'Roomster',
                },
              ],
            },
            {
              name: 'Scala',
              values: [
                {
                  value: 'Scala',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Superb',
              values: [
                {
                  value: 'Superb',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Yeti',
              values: [
                {
                  value: 'Yeti',
                },
              ],
            },
          ],
        },
        {
          name: 'Skywell',
          values: [
            {
              value: 'Skywell',
            },
          ],
          children: [
            {
              name: 'ET5',
              values: [
                {
                  value: 'ET5',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Smart',
          values: [
            {
              value: 'Smart',
            },
          ],
          children: [
            {
              name: 'Forfour',
              values: [
                {
                  value: 'Forfour',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Fortwo',
              values: [
                {
                  value: 'Fortwo',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Roadster',
              values: [
                {
                  value: 'Roadster',
                },
              ],
            },
          ],
        },
        {
          name: 'Soueast',
          values: [
            {
              value: 'Soueast',
            },
          ],
          children: [
            {
              name: 'Lioncel',
              values: [
                {
                  value: 'Lioncel',
                },
              ],
            },
          ],
        },
        {
          name: 'Spyker',
          values: [
            {
              value: 'Spyker',
            },
          ],
          children: [
            {
              name: 'C12',
              values: [
                {
                  value: 'C12',
                },
              ],
            },
            {
              name: 'C8',
              values: [
                {
                  value: 'C8',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'SsangYong',
          values: [
            {
              value: 'SsangYong',
            },
          ],
          children: [
            {
              name: 'Actyon',
              values: [
                {
                  value: 'Actyon',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Actyon Sports',
              values: [
                {
                  value: 'Actyon Sports',
                },
              ],
            },
            {
              name: 'Chairman',
              values: [
                {
                  value: 'Chairman',
                },
              ],
            },
            {
              name: 'Istana',
              values: [
                {
                  value: 'Istana',
                },
              ],
            },
            {
              name: 'Korando',
              values: [
                {
                  value: 'Korando',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Korando Sports',
              values: [
                {
                  value: 'Korando Sports',
                },
              ],
            },
            {
              name: 'Korando Turismo',
              values: [
                {
                  value: 'Korando Turismo',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Kyron',
              values: [
                {
                  value: 'Kyron',
                },
              ],
            },
            {
              name: 'Musso',
              values: [
                {
                  value: 'Musso',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Nomad',
              values: [
                {
                  value: 'Nomad',
                },
              ],
            },
            {
              name: 'Rexton',
              values: [
                {
                  value: 'Rexton',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Rexton Sports',
              values: [
                {
                  value: 'Rexton Sports',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Rodius',
              values: [
                {
                  value: 'Rodius',
                },
              ],
            },
            {
              name: 'Stavic',
              values: [
                {
                  value: 'Stavic',
                },
              ],
            },
            {
              name: 'Tivoli',
              values: [
                {
                  value: 'Tivoli',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Torres',
              values: [
                {
                  value: 'Torres',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'XLV',
              values: [
                {
                  value: 'XLV',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Subaru',
          values: [
            {
              value: 'Subaru',
            },
          ],
          children: [
            {
              name: 'Ascent',
              values: [
                {
                  value: 'Ascent',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Baja',
              values: [
                {
                  value: 'Baja',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'BRZ',
              values: [
                {
                  value: 'BRZ',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Crosstrek',
              values: [
                {
                  value: 'Crosstrek',
                  mostFrequentSchedule: {
                    name: 'MWF',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=MO,WE,FR;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Dex',
              values: [
                {
                  value: 'Dex',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Dias Wagon',
              values: [
                {
                  value: 'Dias Wagon',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Exiga',
              values: [
                {
                  value: 'Exiga',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Forester',
              values: [
                {
                  value: 'Forester',
                  mostFrequentSchedule: {
                    name: 'MWF',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=MO,WE,FR;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Impreza',
              values: [
                {
                  value: 'Impreza',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Impreza WRX',
              values: [
                {
                  value: 'Impreza WRX',
                },
              ],
            },
            {
              name: 'Impreza WRX STi',
              values: [
                {
                  value: 'Impreza WRX STi',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Justy',
              values: [
                {
                  value: 'Justy',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Legacy',
              values: [
                {
                  value: 'Legacy',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Legacy Lancaster',
              values: [
                {
                  value: 'Legacy Lancaster',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Levorg',
              values: [
                {
                  value: 'Levorg',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Lucra',
              values: [
                {
                  value: 'Lucra',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Outback',
              values: [
                {
                  value: 'Outback',
                  mostFrequentSchedule: {
                    name: 'MWF',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=MO,WE,FR;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Pleo',
              values: [
                {
                  value: 'Pleo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Pleo Plus',
              values: [
                {
                  value: 'Pleo Plus',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'R1',
              values: [
                {
                  value: 'R1',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'R2',
              values: [
                {
                  value: 'R2',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Sambar',
              values: [
                {
                  value: 'Sambar',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Solterra',
              values: [
                {
                  value: 'Solterra',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Stella',
              values: [
                {
                  value: 'Stella',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Traviq',
              values: [
                {
                  value: 'Traviq',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Trezia',
              values: [
                {
                  value: 'Trezia',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Tribeca',
              values: [
                {
                  value: 'Tribeca',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Vivio',
              values: [
                {
                  value: 'Vivio',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'WRX',
              values: [
                {
                  value: 'WRX',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'WRX STi',
              values: [
                {
                  value: 'WRX STi',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
              ],
            },
            {
              name: 'XV',
              values: [
                {
                  value: 'XV',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Suzuki',
          values: [
            {
              value: 'Suzuki',
            },
          ],
          children: [
            {
              name: 'Across',
              values: [
                {
                  value: 'Across',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Aerio',
              values: [
                {
                  value: 'Aerio',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Alto',
              values: [
                {
                  value: 'Alto',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Alto Lapin',
              values: [
                {
                  value: 'Alto Lapin',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'APV',
              values: [
                {
                  value: 'APV',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Baleno',
              values: [
                {
                  value: 'Baleno',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Celerio',
              values: [
                {
                  value: 'Celerio',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Cervo',
              values: [
                {
                  value: 'Cervo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Ciaz',
              values: [
                {
                  value: 'Ciaz',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Cultus',
              values: [
                {
                  value: 'Cultus',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'DZire',
              values: [
                {
                  value: 'DZire',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Equator',
              values: [
                {
                  value: 'Equator',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Ertiga',
              values: [
                {
                  value: 'Ertiga',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Escudo',
              values: [
                {
                  value: 'Escudo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Esteem',
              values: [
                {
                  value: 'Esteem',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Every',
              values: [
                {
                  value: 'Every',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Forenza',
              values: [
                {
                  value: 'Forenza',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Grand Vitara',
              values: [
                {
                  value: 'Grand Vitara',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Hustler',
              values: [
                {
                  value: 'Hustler',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Ignis',
              values: [
                {
                  value: 'Ignis',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Jimny',
              values: [
                {
                  value: 'Jimny',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Kei',
              values: [
                {
                  value: 'Kei',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Kizashi',
              values: [
                {
                  value: 'Kizashi',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Landy',
              values: [
                {
                  value: 'Landy',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Liana',
              values: [
                {
                  value: 'Liana',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'MR Wagon',
              values: [
                {
                  value: 'MR Wagon',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Palette',
              values: [
                {
                  value: 'Palette',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Reno',
              values: [
                {
                  value: 'Reno',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'S-Presso',
              values: [
                {
                  value: 'S-Presso',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Samurai',
              values: [
                {
                  value: 'Samurai',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Solio',
              values: [
                {
                  value: 'Solio',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Spacia',
              values: [
                {
                  value: 'Spacia',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Splash',
              values: [
                {
                  value: 'Splash',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Swace',
              values: [
                {
                  value: 'Swace',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Swift',
              values: [
                {
                  value: 'Swift',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'SX4',
              values: [
                {
                  value: 'SX4',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Twin',
              values: [
                {
                  value: 'Twin',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Verona',
              values: [
                {
                  value: 'Verona',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Vitara',
              values: [
                {
                  value: 'Vitara',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Wagon R',
              values: [
                {
                  value: 'Wagon R',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Wagon R+',
              values: [
                {
                  value: 'Wagon R+',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'X-90',
              values: [
                {
                  value: 'X-90',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Xbee',
              values: [
                {
                  value: 'Xbee',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'XL7',
              values: [
                {
                  value: 'XL7',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'TagAZ',
          values: [
            {
              value: 'TagAZ',
            },
          ],
          children: [
            {
              name: 'Aquila',
              values: [
                {
                  value: 'Aquila',
                },
              ],
            },
            {
              name: 'C-30',
              values: [
                {
                  value: 'C-30',
                },
              ],
            },
            {
              name: 'C10',
              values: [
                {
                  value: 'C10',
                },
              ],
            },
            {
              name: 'C190',
              values: [
                {
                  value: 'C190',
                },
              ],
            },
            {
              name: 'Road Partner',
              values: [
                {
                  value: 'Road Partner',
                },
              ],
            },
            {
              name: 'Tager',
              values: [
                {
                  value: 'Tager',
                },
              ],
            },
            {
              name: 'Vega',
              values: [
                {
                  value: 'Vega',
                },
              ],
            },
          ],
        },
        {
          name: 'Tank',
          values: [
            {
              value: 'Tank',
            },
          ],
          children: [
            {
              name: '300',
              values: [
                {
                  value: '300',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: '500',
              values: [
                {
                  value: '500',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'TATA',
          values: [
            {
              value: 'TATA',
            },
          ],
          children: [
            {
              name: 'Aria',
              values: [
                {
                  value: 'Aria',
                },
              ],
            },
            {
              name: 'Estate',
              values: [
                {
                  value: 'Estate',
                },
              ],
            },
            {
              name: 'Indica',
              values: [
                {
                  value: 'Indica',
                },
              ],
            },
            {
              name: 'Indigo',
              values: [
                {
                  value: 'Indigo',
                },
              ],
            },
            {
              name: 'Nano',
              values: [
                {
                  value: 'Nano',
                },
              ],
            },
            {
              name: 'Safari',
              values: [
                {
                  value: 'Safari',
                },
              ],
            },
            {
              name: 'Sierra',
              values: [
                {
                  value: 'Sierra',
                },
              ],
            },
            {
              name: 'Sumo',
              values: [
                {
                  value: 'Sumo',
                },
              ],
            },
            {
              name: 'Sumo Grande',
              values: [
                {
                  value: 'Sumo Grande',
                },
              ],
            },
            {
              name: 'Telcoline',
              values: [
                {
                  value: 'Telcoline',
                },
              ],
            },
            {
              name: 'Xenon',
              values: [
                {
                  value: 'Xenon',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Tazzari',
          values: [
            {
              value: 'Tazzari',
            },
          ],
          children: [
            {
              name: 'Zero',
              values: [
                {
                  value: 'Zero',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Tesla',
          values: [
            {
              value: 'Tesla',
            },
          ],
          children: [
            {
              name: 'Cybertruck',
              values: [
                {
                  value: 'Cybertruck',
                },
              ],
            },
            {
              name: 'Model 3',
              values: [
                {
                  value: 'Model 3',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Model S',
              values: [
                {
                  value: 'Model S',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Model X',
              values: [
                {
                  value: 'Model X',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Model Y',
              values: [
                {
                  value: 'Model Y',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Roadster',
              values: [
                {
                  value: 'Roadster',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Think',
          values: [
            {
              value: 'Think',
            },
          ],
          children: [
            {
              name: 'City',
              values: [
                {
                  value: 'City',
                },
              ],
            },
          ],
        },
        {
          name: 'Tianma',
          values: [
            {
              value: 'Tianma',
            },
          ],
          children: [
            {
              name: 'Century',
              values: [
                {
                  value: 'Century',
                },
              ],
            },
            {
              name: 'Dragon',
              values: [
                {
                  value: 'Dragon',
                },
              ],
            },
          ],
        },
        {
          name: 'Tianye',
          values: [
            {
              value: 'Tianye',
            },
          ],
          children: [
            {
              name: 'Admiral',
              values: [
                {
                  value: 'Admiral',
                },
              ],
            },
          ],
        },
        {
          name: 'Tofas',
          values: [
            {
              value: 'Tofas',
            },
          ],
          children: [
            {
              name: 'Kartal',
              values: [
                {
                  value: 'Kartal',
                },
              ],
            },
            {
              name: 'Sahin',
              values: [
                {
                  value: 'Sahin',
                },
              ],
            },
          ],
        },
        {
          name: 'Toyota',
          values: [
            {
              value: 'Toyota',
            },
          ],
          children: [
            {
              name: '4Runner',
              values: [
                {
                  value: '4Runner',
                  mostFrequentSchedule: {
                    name: 'MWF',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=MO,WE,FR;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Allex',
              values: [
                {
                  value: 'Allex',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Allion',
              values: [
                {
                  value: 'Allion',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Alphard',
              values: [
                {
                  value: 'Alphard',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Altezza',
              values: [
                {
                  value: 'Altezza',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Aqua',
              values: [
                {
                  value: 'Aqua',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Aristo',
              values: [
                {
                  value: 'Aristo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Aurion',
              values: [
                {
                  value: 'Aurion',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Auris',
              values: [
                {
                  value: 'Auris',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Avalon',
              values: [
                {
                  value: 'Avalon',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Avanza',
              values: [
                {
                  value: 'Avanza',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Avensis',
              values: [
                {
                  value: 'Avensis',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Avensis Verso',
              values: [
                {
                  value: 'Avensis Verso',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Aygo',
              values: [
                {
                  value: 'Aygo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Aygo X',
              values: [
                {
                  value: 'Aygo X',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'bB',
              values: [
                {
                  value: 'bB',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Belta',
              values: [
                {
                  value: 'Belta',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Blade',
              values: [
                {
                  value: 'Blade',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Brevis',
              values: [
                {
                  value: 'Brevis',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'bZ4X',
              values: [
                {
                  value: 'bZ4X',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'C-HR',
              values: [
                {
                  value: 'C-HR',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Caldina',
              values: [
                {
                  value: 'Caldina',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Cami',
              values: [
                {
                  value: 'Cami',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Camry',
              values: [
                {
                  value: 'Camry',
                  mostFrequentSchedule: {
                    name: 'Daily',
                    rrule: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Camry (Japan)',
              values: [
                {
                  value: 'Camry (Japan)',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Camry Solara',
              values: [
                {
                  value: 'Camry Solara',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Carina',
              values: [
                {
                  value: 'Carina',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Cavalier',
              values: [
                {
                  value: 'Cavalier',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Celica',
              values: [
                {
                  value: 'Celica',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Celsior',
              values: [
                {
                  value: 'Celsior',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Century',
              values: [
                {
                  value: 'Century',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Chaser',
              values: [
                {
                  value: 'Chaser',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Comfort',
              values: [
                {
                  value: 'Comfort',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'COMS',
              values: [
                {
                  value: 'COMS',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Corolla',
              values: [
                {
                  value: 'Corolla',
                  mostFrequentSchedule: {
                    name: 'Daily',
                    rrule: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Corolla Cross',
              values: [
                {
                  value: 'Corolla Cross',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Corolla Levin',
              values: [
                {
                  value: 'Corolla Levin',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Corolla Rumion',
              values: [
                {
                  value: 'Corolla Rumion',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Corolla Spacio',
              values: [
                {
                  value: 'Corolla Spacio',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Corolla Verso',
              values: [
                {
                  value: 'Corolla Verso',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Corona',
              values: [
                {
                  value: 'Corona',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Cresta',
              values: [
                {
                  value: 'Cresta',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Crown',
              values: [
                {
                  value: 'Crown',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Crown Majesta',
              values: [
                {
                  value: 'Crown Majesta',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Duet',
              values: [
                {
                  value: 'Duet',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Echo',
              values: [
                {
                  value: 'Echo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Esquire',
              values: [
                {
                  value: 'Esquire',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Estima',
              values: [
                {
                  value: 'Estima',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Etios',
              values: [
                {
                  value: 'Etios',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'FJ Cruiser',
              values: [
                {
                  value: 'FJ Cruiser',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Fortuner',
              values: [
                {
                  value: 'Fortuner',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Frontlander',
              values: [
                {
                  value: 'Frontlander',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'FunCargo',
              values: [
                {
                  value: 'FunCargo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Gaia',
              values: [
                {
                  value: 'Gaia',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'GR86',
              values: [
                {
                  value: 'GR86',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Grand HiAce',
              values: [
                {
                  value: 'Grand HiAce',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Granvia',
              values: [
                {
                  value: 'Granvia',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'GT86',
              values: [
                {
                  value: 'GT86',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Harrier',
              values: [
                {
                  value: 'Harrier',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'HiAce',
              values: [
                {
                  value: 'HiAce',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Highlander',
              values: [
                {
                  value: 'Highlander',
                  mostFrequentSchedule: {
                    name: 'Daily',
                    rrule: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Hilux',
              values: [
                {
                  value: 'Hilux',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Hilux Surf',
              values: [
                {
                  value: 'Hilux Surf',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Innova',
              values: [
                {
                  value: 'Innova',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Ipsum',
              values: [
                {
                  value: 'Ipsum',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'iQ',
              values: [
                {
                  value: 'iQ',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'ISis',
              values: [
                {
                  value: 'ISis',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Ist',
              values: [
                {
                  value: 'Ist',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Izoa',
              values: [
                {
                  value: 'Izoa',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Kluger',
              values: [
                {
                  value: 'Kluger',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Land Cruiser',
              values: [
                {
                  value: 'Land Cruiser',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Land Cruiser Prado',
              values: [
                {
                  value: 'Land Cruiser Prado',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Levin',
              values: [
                {
                  value: 'Levin',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'LiteAce',
              values: [
                {
                  value: 'LiteAce',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Mark II',
              values: [
                {
                  value: 'Mark II',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Mark X',
              values: [
                {
                  value: 'Mark X',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Mark X ZiO',
              values: [
                {
                  value: 'Mark X ZiO',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Matrix',
              values: [
                {
                  value: 'Matrix',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Mega Cruiser',
              values: [
                {
                  value: 'Mega Cruiser',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Mirai',
              values: [
                {
                  value: 'Mirai',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'MR-S',
              values: [
                {
                  value: 'MR-S',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'MR2',
              values: [
                {
                  value: 'MR2',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Nadia',
              values: [
                {
                  value: 'Nadia',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Noah',
              values: [
                {
                  value: 'Noah',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Opa',
              values: [
                {
                  value: 'Opa',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Origin',
              values: [
                {
                  value: 'Origin',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Paseo',
              values: [
                {
                  value: 'Paseo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Passo',
              values: [
                {
                  value: 'Passo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Passo Sette',
              values: [
                {
                  value: 'Passo Sette',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Picnic',
              values: [
                {
                  value: 'Picnic',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Pixis Epoch',
              values: [
                {
                  value: 'Pixis Epoch',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Pixis Joy',
              values: [
                {
                  value: 'Pixis Joy',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Pixis Mega',
              values: [
                {
                  value: 'Pixis Mega',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Pixis Space',
              values: [
                {
                  value: 'Pixis Space',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Pixis Van',
              values: [
                {
                  value: 'Pixis Van',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Platz',
              values: [
                {
                  value: 'Platz',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Porte',
              values: [
                {
                  value: 'Porte',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Premio',
              values: [
                {
                  value: 'Premio',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Previa',
              values: [
                {
                  value: 'Previa',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Prius',
              values: [
                {
                  value: 'Prius',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Prius Alpha',
              values: [
                {
                  value: 'Prius Alpha',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Prius c',
              values: [
                {
                  value: 'Prius c',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Prius v (+)',
              values: [
                {
                  value: 'Prius v (+)',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'ProAce',
              values: [
                {
                  value: 'ProAce',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'ProAce City',
              values: [
                {
                  value: 'ProAce City',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Probox',
              values: [
                {
                  value: 'Probox',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Progres',
              values: [
                {
                  value: 'Progres',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Pronard',
              values: [
                {
                  value: 'Pronard',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Ractis',
              values: [
                {
                  value: 'Ractis',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Raize',
              values: [
                {
                  value: 'Raize',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Raum',
              values: [
                {
                  value: 'Raum',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'RAV 4',
              values: [
                {
                  value: 'RAV 4',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Regius',
              values: [
                {
                  value: 'Regius',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'RegiusAce',
              values: [
                {
                  value: 'RegiusAce',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Roomy',
              values: [
                {
                  value: 'Roomy',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Rush',
              values: [
                {
                  value: 'Rush',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Sai',
              values: [
                {
                  value: 'Sai',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Sequoia',
              values: [
                {
                  value: 'Sequoia',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Sienna',
              values: [
                {
                  value: 'Sienna',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Sienta',
              values: [
                {
                  value: 'Sienta',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Soarer',
              values: [
                {
                  value: 'Soarer',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Soluna',
              values: [
                {
                  value: 'Soluna',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Spade',
              values: [
                {
                  value: 'Spade',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Sparky',
              values: [
                {
                  value: 'Sparky',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Sprinter',
              values: [
                {
                  value: 'Sprinter',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Sprinter Carib',
              values: [
                {
                  value: 'Sprinter Carib',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Sprinter Trueno',
              values: [
                {
                  value: 'Sprinter Trueno',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Succeed',
              values: [
                {
                  value: 'Succeed',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Supra',
              values: [
                {
                  value: 'Supra',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Tacoma',
              values: [
                {
                  value: 'Tacoma',
                  mostFrequentSchedule: {
                    name: 'Daily',
                    rrule: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Tank',
              values: [
                {
                  value: 'Tank',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Tercel',
              values: [
                {
                  value: 'Tercel',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Touring HiAce',
              values: [
                {
                  value: 'Touring HiAce',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'TownAce',
              values: [
                {
                  value: 'TownAce',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Tundra',
              values: [
                {
                  value: 'Tundra',
                  mostFrequentSchedule: {
                    name: 'MWF',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=MO,WE,FR;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Urban Cruiser',
              values: [
                {
                  value: 'Urban Cruiser',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Vanguard',
              values: [
                {
                  value: 'Vanguard',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Vellfire',
              values: [
                {
                  value: 'Vellfire',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Venza',
              values: [
                {
                  value: 'Venza',
                },
              ],
            },
            {
              name: 'Verossa',
              values: [
                {
                  value: 'Verossa',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Verso',
              values: [
                {
                  value: 'Verso',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Verso-S',
              values: [
                {
                  value: 'Verso-S',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Vios',
              values: [
                {
                  value: 'Vios',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Vista',
              values: [
                {
                  value: 'Vista',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Vitz',
              values: [
                {
                  value: 'Vitz',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Voltz',
              values: [
                {
                  value: 'Voltz',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Voxy',
              values: [
                {
                  value: 'Voxy',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Wigo',
              values: [
                {
                  value: 'Wigo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Wildlander',
              values: [
                {
                  value: 'Wildlander',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'WiLL',
              values: [
                {
                  value: 'WiLL',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'WiLL Cypha',
              values: [
                {
                  value: 'WiLL Cypha',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Windom',
              values: [
                {
                  value: 'Windom',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Wish',
              values: [
                {
                  value: 'Wish',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Yaris',
              values: [
                {
                  value: 'Yaris',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Yaris Cross',
              values: [
                {
                  value: 'Yaris Cross',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Yaris Verso',
              values: [
                {
                  value: 'Yaris Verso',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Zelas',
              values: [
                {
                  value: 'Zelas',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'Tramontana',
          values: [
            {
              value: 'Tramontana',
            },
          ],
          children: [
            {
              name: 'Tramontana',
              values: [
                {
                  value: 'Tramontana',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Trumpchi',
          values: [
            {
              value: 'Trumpchi',
            },
          ],
          children: [
            {
              name: 'Emkoo',
              values: [
                {
                  value: 'Emkoo',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Empow',
              values: [
                {
                  value: 'Empow',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'GA4 Plus',
              values: [
                {
                  value: 'GA4 Plus',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'GA6',
              values: [
                {
                  value: 'GA6',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'GA8',
              values: [
                {
                  value: 'GA8',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'GS3 Power',
              values: [
                {
                  value: 'GS3 Power',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'GS4',
              values: [
                {
                  value: 'GS4',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'GS4 Plus',
              values: [
                {
                  value: 'GS4 Plus',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'GS8',
              values: [
                {
                  value: 'GS8',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'M6',
              values: [
                {
                  value: 'M6',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'M6 Pro',
              values: [
                {
                  value: 'M6 Pro',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'M8',
              values: [
                {
                  value: 'M8',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'TVR',
          values: [
            {
              value: 'TVR',
            },
          ],
          children: [
            {
              name: 'Cerbera',
              values: [
                {
                  value: 'Cerbera',
                },
              ],
            },
            {
              name: 'Chimaera',
              values: [
                {
                  value: 'Chimaera',
                },
              ],
            },
            {
              name: 'Griffith',
              values: [
                {
                  value: 'Griffith',
                },
              ],
            },
            {
              name: 'Sagaris',
              values: [
                {
                  value: 'Sagaris',
                },
              ],
            },
            {
              name: 'Tamora',
              values: [
                {
                  value: 'Tamora',
                },
              ],
            },
            {
              name: 'Tuscan',
              values: [
                {
                  value: 'Tuscan',
                },
              ],
            },
          ],
        },
        {
          name: 'UAZ',
          values: [
            {
              value: 'UAZ',
            },
          ],
          children: [
            {
              name: '469',
              values: [
                {
                  value: '469',
                },
              ],
            },
            {
              name: '3151',
              values: [
                {
                  value: '3151',
                },
              ],
            },
            {
              name: '3153',
              values: [
                {
                  value: '3153',
                },
              ],
            },
            {
              name: '3159',
              values: [
                {
                  value: '3159',
                },
              ],
            },
            {
              name: '3160',
              values: [
                {
                  value: '3160',
                },
              ],
            },
            {
              name: '3162 Simbir',
              values: [
                {
                  value: '3162 Simbir',
                },
              ],
            },
            {
              name: 'Hunter',
              values: [
                {
                  value: 'Hunter',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Patriot',
              values: [
                {
                  value: 'Patriot',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Pickup',
              values: [
                {
                  value: 'Pickup',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Ultima',
          values: [
            {
              value: 'Ultima',
            },
          ],
          children: [
            {
              name: 'Can-Am',
              values: [
                {
                  value: 'Can-Am',
                },
              ],
            },
            {
              name: 'GTR',
              values: [
                {
                  value: 'GTR',
                },
              ],
            },
            {
              name: 'RS',
              values: [
                {
                  value: 'RS',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Vauxhall',
          values: [
            {
              value: 'Vauxhall',
            },
          ],
          children: [
            {
              name: 'Adam',
              values: [
                {
                  value: 'Adam',
                },
              ],
            },
            {
              name: 'Ampera',
              values: [
                {
                  value: 'Ampera',
                },
              ],
            },
            {
              name: 'Astra',
              values: [
                {
                  value: 'Astra',
                },
              ],
            },
            {
              name: 'Corsa',
              values: [
                {
                  value: 'Corsa',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Frontera',
              values: [
                {
                  value: 'Frontera',
                },
              ],
            },
            {
              name: 'Insignia',
              values: [
                {
                  value: 'Insignia',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Meriva',
              values: [
                {
                  value: 'Meriva',
                },
              ],
            },
            {
              name: 'Mokka',
              values: [
                {
                  value: 'Mokka',
                },
              ],
            },
            {
              name: 'Monaro',
              values: [
                {
                  value: 'Monaro',
                },
              ],
            },
            {
              name: 'Omega',
              values: [
                {
                  value: 'Omega',
                },
              ],
            },
            {
              name: 'Tigra',
              values: [
                {
                  value: 'Tigra',
                },
              ],
            },
            {
              name: 'Vectra',
              values: [
                {
                  value: 'Vectra',
                },
              ],
            },
            {
              name: 'VXR8',
              values: [
                {
                  value: 'VXR8',
                },
              ],
            },
            {
              name: 'Zafira',
              values: [
                {
                  value: 'Zafira',
                },
              ],
            },
          ],
        },
        {
          name: 'VAZ (Lada)',
          values: [
            {
              value: 'VAZ (Lada)',
            },
          ],
          children: [
            {
              name: '2104',
              values: [
                {
                  value: '2104',
                },
              ],
            },
            {
              name: '2105',
              values: [
                {
                  value: '2105',
                },
              ],
            },
            {
              name: '2106',
              values: [
                {
                  value: '2106',
                },
              ],
            },
            {
              name: '2107',
              values: [
                {
                  value: '2107',
                },
              ],
            },
            {
              name: '2108',
              values: [
                {
                  value: '2108',
                },
              ],
            },
            {
              name: '2109',
              values: [
                {
                  value: '2109',
                },
              ],
            },
            {
              name: '2110',
              values: [
                {
                  value: '2110',
                },
              ],
            },
            {
              name: '2111',
              values: [
                {
                  value: '2111',
                },
              ],
            },
            {
              name: '2112',
              values: [
                {
                  value: '2112',
                },
              ],
            },
            {
              name: '2113',
              values: [
                {
                  value: '2113',
                },
              ],
            },
            {
              name: '2114',
              values: [
                {
                  value: '2114',
                },
              ],
            },
            {
              name: '2115',
              values: [
                {
                  value: '2115',
                },
              ],
            },
            {
              name: '2123',
              values: [
                {
                  value: '2123',
                },
              ],
            },
            {
              name: '2329',
              values: [
                {
                  value: '2329',
                },
              ],
            },
            {
              name: '21099',
              values: [
                {
                  value: '21099',
                },
              ],
            },
            {
              name: '1111 Oka',
              values: [
                {
                  value: '1111 Oka',
                },
              ],
            },
            {
              name: '2120 Nadezhda',
              values: [
                {
                  value: '2120 Nadezhda',
                },
              ],
            },
            {
              name: '2121 (4x4)',
              values: [
                {
                  value: '2121 (4x4)',
                },
              ],
            },
            {
              name: '2131 (4x4)',
              values: [
                {
                  value: '2131 (4x4)',
                },
              ],
            },
            {
              name: 'EL Lada',
              values: [
                {
                  value: 'EL Lada',
                },
              ],
            },
            {
              name: 'Granta',
              values: [
                {
                  value: 'Granta',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Kalina',
              values: [
                {
                  value: 'Kalina',
                },
              ],
            },
            {
              name: 'Largus',
              values: [
                {
                  value: 'Largus',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Niva',
              values: [
                {
                  value: 'Niva',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Niva Legend',
              values: [
                {
                  value: 'Niva Legend',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Priora',
              values: [
                {
                  value: 'Priora',
                },
              ],
            },
            {
              name: 'Revolution',
              values: [
                {
                  value: 'Revolution',
                },
              ],
            },
            {
              name: 'Vesta',
              values: [
                {
                  value: 'Vesta',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'XRAY',
              values: [
                {
                  value: 'XRAY',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Vector',
          values: [
            {
              value: 'Vector',
            },
          ],
          children: [
            {
              name: 'M12',
              values: [
                {
                  value: 'M12',
                },
              ],
            },
          ],
        },
        {
          name: 'Venturi',
          values: [
            {
              value: 'Venturi',
            },
          ],
          children: [
            {
              name: '300 Atlantique',
              values: [
                {
                  value: '300 Atlantique',
                },
              ],
            },
          ],
        },
        {
          name: 'Volkswagen',
          values: [
            {
              value: 'Volkswagen',
            },
          ],
          children: [
            {
              name: 'Amarok',
              values: [
                {
                  value: 'Amarok',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Arteon',
              values: [
                {
                  value: 'Arteon',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Arteon R',
              values: [
                {
                  value: 'Arteon R',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Atlas',
              values: [
                {
                  value: 'Atlas',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Atlas Cross Sport',
              values: [
                {
                  value: 'Atlas Cross Sport',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Beetle',
              values: [
                {
                  value: 'Beetle',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Bora',
              values: [
                {
                  value: 'Bora',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Caddy',
              values: [
                {
                  value: 'Caddy',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'California',
              values: [
                {
                  value: 'California',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Caravelle',
              values: [
                {
                  value: 'Caravelle',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Eos',
              values: [
                {
                  value: 'Eos',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'EuroVan',
              values: [
                {
                  value: 'EuroVan',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Fox',
              values: [
                {
                  value: 'Fox',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Gol',
              values: [
                {
                  value: 'Gol',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Golf',
              values: [
                {
                  value: 'Golf',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Golf GTI',
              values: [
                {
                  value: 'Golf GTI',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Golf Plus',
              values: [
                {
                  value: 'Golf Plus',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Golf R',
              values: [
                {
                  value: 'Golf R',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Golf R32',
              values: [
                {
                  value: 'Golf R32',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Golf Sportsvan',
              values: [
                {
                  value: 'Golf Sportsvan',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'ID.3',
              values: [
                {
                  value: 'ID.3',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'ID.4',
              values: [
                {
                  value: 'ID.4',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'ID.5',
              values: [
                {
                  value: 'ID.5',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'ID.6',
              values: [
                {
                  value: 'ID.6',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'ID.Buzz',
              values: [
                {
                  value: 'ID.Buzz',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Jetta',
              values: [
                {
                  value: 'Jetta',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Lamando',
              values: [
                {
                  value: 'Lamando',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Lavida',
              values: [
                {
                  value: 'Lavida',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Lupo',
              values: [
                {
                  value: 'Lupo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Lupo GTI',
              values: [
                {
                  value: 'Lupo GTI',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Magotan',
              values: [
                {
                  value: 'Magotan',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Multivan',
              values: [
                {
                  value: 'Multivan',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Parati',
              values: [
                {
                  value: 'Parati',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Passat',
              values: [
                {
                  value: 'Passat',
                  mostFrequentSchedule: {
                    name: 'Quarterly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Passat (North America)',
              values: [
                {
                  value: 'Passat (North America)',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Passat CC',
              values: [
                {
                  value: 'Passat CC',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Phaeton',
              values: [
                {
                  value: 'Phaeton',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Phideon',
              values: [
                {
                  value: 'Phideon',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Pointer',
              values: [
                {
                  value: 'Pointer',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Polo',
              values: [
                {
                  value: 'Polo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Polo GTI',
              values: [
                {
                  value: 'Polo GTI',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Polo R WRC',
              values: [
                {
                  value: 'Polo R WRC',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Quantum',
              values: [
                {
                  value: 'Quantum',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Routan',
              values: [
                {
                  value: 'Routan',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Sagitar',
              values: [
                {
                  value: 'Sagitar',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Scirocco',
              values: [
                {
                  value: 'Scirocco',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Scirocco R',
              values: [
                {
                  value: 'Scirocco R',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'Sharan',
              values: [
                {
                  value: 'Sharan',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'SpaceFox',
              values: [
                {
                  value: 'SpaceFox',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'T-Cross',
              values: [
                {
                  value: 'T-Cross',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'T-Roc',
              values: [
                {
                  value: 'T-Roc',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'T-Roc R',
              values: [
                {
                  value: 'T-Roc R',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Tacqua',
              values: [
                {
                  value: 'Tacqua',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Taigo',
              values: [
                {
                  value: 'Taigo',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Talagon',
              values: [
                {
                  value: 'Talagon',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Taos',
              values: [
                {
                  value: 'Taos',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Tavendor',
              values: [
                {
                  value: 'Tavendor',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Tayron',
              values: [
                {
                  value: 'Tayron',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Teramont',
              values: [
                {
                  value: 'Teramont',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Tharu',
              values: [
                {
                  value: 'Tharu',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Tiguan',
              values: [
                {
                  value: 'Tiguan',
                  mostFrequentSchedule: {
                    name: 'Weekly',
                    rrule:
                      'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Tiguan R',
              values: [
                {
                  value: 'Tiguan R',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Touareg',
              values: [
                {
                  value: 'Touareg',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Touran',
              values: [
                {
                  value: 'Touran',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Transporter',
              values: [
                {
                  value: 'Transporter',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Type 1',
              values: [
                {
                  value: 'Type 1',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'up!',
              values: [
                {
                  value: 'up!',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Viloran',
              values: [
                {
                  value: 'Viloran',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'XL1',
              values: [
                {
                  value: 'XL1',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'Volvo',
          values: [
            {
              value: 'Volvo',
            },
          ],
          children: [
            {
              name: 'C30',
              values: [
                {
                  value: 'C30',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'C40',
              values: [
                {
                  value: 'C40',
                  mostFrequentSchedule: {
                    name: 'Monthly',
                    rrule:
                      'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'C70',
              values: [
                {
                  value: 'C70',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'EX90',
              values: [
                {
                  value: 'EX90',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'S40',
              values: [
                {
                  value: 'S40',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'S60',
              values: [
                {
                  value: 'S60',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'S60 Cross Country',
              values: [
                {
                  value: 'S60 Cross Country',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'S70',
              values: [
                {
                  value: 'S70',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'S80',
              values: [
                {
                  value: 'S80',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'S90',
              values: [
                {
                  value: 'S90',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'V40',
              values: [
                {
                  value: 'V40',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'V40 Cross Country',
              values: [
                {
                  value: 'V40 Cross Country',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'V50',
              values: [
                {
                  value: 'V50',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'V60',
              values: [
                {
                  value: 'V60',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'V60 Cross Country',
              values: [
                {
                  value: 'V60 Cross Country',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'V70',
              values: [
                {
                  value: 'V70',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'V90',
              values: [
                {
                  value: 'V90',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'V90 Cross Country',
              values: [
                {
                  value: 'V90 Cross Country',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'XC40',
              values: [
                {
                  value: 'XC40',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'XC60',
              values: [
                {
                  value: 'XC60',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'XC70',
              values: [
                {
                  value: 'XC70',
                  mostFrequentSchedule: {
                    name: 'Yearly',
                    rrule:
                      'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
                  },
                },
              ],
            },
            {
              name: 'XC90',
              values: [
                {
                  value: 'XC90',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Vortex',
          values: [
            {
              value: 'Vortex',
            },
          ],
          children: [
            {
              name: 'Corda',
              values: [
                {
                  value: 'Corda',
                },
              ],
            },
            {
              name: 'Estina',
              values: [
                {
                  value: 'Estina',
                },
              ],
            },
            {
              name: 'Tingo',
              values: [
                {
                  value: 'Tingo',
                },
              ],
            },
          ],
        },
        {
          name: 'Voyah',
          values: [
            {
              value: 'Voyah',
            },
          ],
          children: [
            {
              name: 'Dreamer',
              values: [
                {
                  value: 'Dreamer',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Free',
              values: [
                {
                  value: 'Free',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'VUHL',
          values: [
            {
              value: 'VUHL',
            },
          ],
          children: [
            {
              name: '5',
              values: [
                {
                  value: '5',
                },
              ],
            },
          ],
        },
        {
          name: 'W Motors',
          values: [
            {
              value: 'W Motors',
            },
          ],
          children: [
            {
              name: 'Fenyr Supersport',
              values: [
                {
                  value: 'Fenyr Supersport',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Lykan Hypersport',
              values: [
                {
                  value: 'Lykan Hypersport',
                },
              ],
            },
          ],
        },
        {
          name: 'Weltmeister',
          values: [
            {
              value: 'Weltmeister',
            },
          ],
          children: [
            {
              name: 'E5',
              values: [
                {
                  value: 'E5',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'EX5',
              values: [
                {
                  value: 'EX5',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'EX6 Plus',
              values: [
                {
                  value: 'EX6 Plus',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'W6',
              values: [
                {
                  value: 'W6',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Westfield',
          values: [
            {
              value: 'Westfield',
            },
          ],
          children: [
            {
              name: 'SEi \u0026 Sport',
              values: [
                {
                  value: 'SEi \u0026 Sport',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'SEiGHT',
              values: [
                {
                  value: 'SEiGHT',
                },
              ],
            },
          ],
        },
        {
          name: 'Wiesmann',
          values: [
            {
              value: 'Wiesmann',
            },
          ],
          children: [
            {
              name: 'GT',
              values: [
                {
                  value: 'GT',
                },
              ],
            },
            {
              name: 'Roadster',
              values: [
                {
                  value: 'Roadster',
                },
              ],
            },
          ],
        },
        {
          name: 'Xin Kai',
          values: [
            {
              value: 'Xin Kai',
            },
          ],
          children: [
            {
              name: 'Pickup X3',
              values: [
                {
                  value: 'Pickup X3',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'SR-V X3',
              values: [
                {
                  value: 'SR-V X3',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'SUV X3',
              values: [
                {
                  value: 'SUV X3',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Xpeng',
          values: [
            {
              value: 'Xpeng',
            },
          ],
          children: [
            {
              name: 'G3',
              values: [
                {
                  value: 'G3',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'G9',
              values: [
                {
                  value: 'G9',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'P5',
              values: [
                {
                  value: 'P5',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'P7',
              values: [
                {
                  value: 'P7',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Yo-mobile',
          values: [
            {
              value: 'Yo-mobile',
            },
          ],
          children: [
            {
              name: 'E-Krossover',
              values: [
                {
                  value: 'E-Krossover',
                },
              ],
            },
          ],
        },
        {
          name: 'Zastava',
          values: [
            {
              value: 'Zastava',
            },
          ],
          children: [
            {
              name: '10',
              values: [
                {
                  value: '10',
                },
              ],
            },
            {
              name: 'Florida',
              values: [
                {
                  value: 'Florida',
                },
              ],
            },
            {
              name: 'Skala',
              values: [
                {
                  value: 'Skala',
                },
              ],
            },
            {
              name: 'Yugo',
              values: [
                {
                  value: 'Yugo',
                },
              ],
            },
          ],
        },
        {
          name: 'ZAZ',
          values: [
            {
              value: 'ZAZ',
            },
          ],
          children: [
            {
              name: '1102 ¬´Tavriya¬ª',
              values: [
                {
                  value: '1102 ¬´Tavriya¬ª',
                },
              ],
            },
            {
              name: '1103 ¬´Slavuta¬ª',
              values: [
                {
                  value: '1103 ¬´Slavuta¬ª',
                },
              ],
            },
            {
              name: '1105 ¬´Dana¬ª',
              values: [
                {
                  value: '1105 ¬´Dana¬ª',
                },
              ],
            },
            {
              name: 'Chance',
              values: [
                {
                  value: 'Chance',
                },
              ],
            },
            {
              name: 'Forza',
              values: [
                {
                  value: 'Forza',
                },
              ],
            },
            {
              name: 'Lanos',
              values: [
                {
                  value: 'Lanos',
                },
              ],
            },
            {
              name: 'Sens',
              values: [
                {
                  value: 'Sens',
                },
              ],
            },
            {
              name: 'Vida',
              values: [
                {
                  value: 'Vida',
                },
              ],
            },
          ],
        },
        {
          name: 'Zeekr',
          values: [
            {
              value: 'Zeekr',
            },
          ],
          children: [
            {
              name: '1',
              values: [
                {
                  value: '1',
                },
              ],
            },
            {
              name: '9',
              values: [
                {
                  value: '9',
                },
              ],
            },
          ],
        },
        {
          name: 'Zenos',
          values: [
            {
              value: 'Zenos',
            },
          ],
          children: [
            {
              name: 'E10',
              values: [
                {
                  value: 'E10',
                },
              ],
            },
          ],
        },
        {
          name: 'Zenvo',
          values: [
            {
              value: 'Zenvo',
            },
          ],
          children: [
            {
              name: 'ST1',
              values: [
                {
                  value: 'ST1',
                },
              ],
            },
            {
              name: 'TSR-S',
              values: [
                {
                  value: 'TSR-S',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Zibar',
          values: [
            {
              value: 'Zibar',
            },
          ],
          children: [
            {
              name: 'MK2',
              values: [
                {
                  value: 'MK2',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'ZIL',
          values: [
            {
              value: 'ZIL',
            },
          ],
          children: [
            {
              name: '4104',
              values: [
                {
                  value: '4104',
                },
              ],
            },
          ],
        },
        {
          name: 'Zotye',
          values: [
            {
              value: 'Zotye',
            },
          ],
          children: [
            {
              name: 'Coupa',
              values: [
                {
                  value: 'Coupa',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'E200',
              values: [
                {
                  value: 'E200',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Nomad (RX6400)',
              values: [
                {
                  value: 'Nomad (RX6400)',
                },
              ],
            },
            {
              name: 'SR9',
              values: [
                {
                  value: 'SR9',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'T600',
              values: [
                {
                  value: 'T600',
                },
              ],
              children: [
                {
                  name: '2022',
                  values: [
                    {
                      value: '2022',
                    },
                  ],
                },
                {
                  name: '2023',
                  values: [
                    {
                      value: '2023',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Z100',
              values: [
                {
                  value: 'Z100',
                },
              ],
            },
            {
              name: 'Z300',
              values: [
                {
                  value: 'Z300',
                },
              ],
            },
          ],
        },
        {
          name: 'ZX',
          values: [
            {
              value: 'ZX',
            },
          ],
          children: [
            {
              name: 'Admiral',
              values: [
                {
                  value: 'Admiral',
                },
              ],
            },
            {
              name: 'Grand Tiger',
              values: [
                {
                  value: 'Grand Tiger',
                },
              ],
            },
            {
              name: 'Landmark',
              values: [
                {
                  value: 'Landmark',
                },
              ],
            },
          ],
        },
      ],
    },
    phraseTemplates: [
      {
        phraseTemplate: 'new {{make}} {{model}} for sale',
        keywordGroupTemplates: [
          {
            groupTemplate: 'For Sale',
          },
          {
            groupTemplate: 'New',
          },
          {
            groupTemplate: 'Transactional',
          },
        ],
        mostFrequentSchedule: {
          name: 'Daily',
          rrule: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
        },
      },
      {
        phraseTemplate: 'new {{make}} {{model}} for sale near me',
        keywordGroupTemplates: [
          {
            groupTemplate: 'For Sale',
          },
          {
            groupTemplate: 'New',
          },
          {
            groupTemplate: 'Near Me',
          },
          {
            groupTemplate: 'Transactional',
          },
        ],
        mostFrequentSchedule: {
          name: 'Daily',
          rrule: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
        },
      },
      {
        phraseTemplate: '{{year}} {{make}} {{model}} for sale',
        keywordGroupTemplates: [
          {
            groupTemplate: 'For Sale',
          },
          {
            groupTemplate: 'Transactional',
          },
        ],
        mostFrequentSchedule: {
          name: 'Daily',
          rrule: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
        },
      },
      {
        phraseTemplate: 'new {{year}} {{make}} {{model}} for lease',
        keywordGroupTemplates: [
          {
            groupTemplate: 'For Lease',
          },
          {
            groupTemplate: 'New',
          },
          {
            groupTemplate: 'Transactional',
          },
        ],
        mostFrequentSchedule: {
          name: 'Daily',
          rrule: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
        },
      },
      {
        phraseTemplate: 'new {{year}} {{make}} {{model}} for lease near me',
        keywordGroupTemplates: [
          {
            groupTemplate: 'For Lease',
          },
          {
            groupTemplate: 'New',
          },
          {
            groupTemplate: 'Near Me',
          },
          {
            groupTemplate: 'Transactional',
          },
        ],
        mostFrequentSchedule: {
          name: 'Daily',
          rrule: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
        },
      },
      {
        phraseTemplate: '{{year}} {{make}} {{model}} for lease',
        keywordGroupTemplates: [
          {
            groupTemplate: 'For Lease',
          },
          {
            groupTemplate: 'Transactional',
          },
        ],
        mostFrequentSchedule: {
          name: 'Daily',
          rrule: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
        },
      },
      {
        phraseTemplate: '{{make}} lease',
        keywordGroupTemplates: [
          {
            groupTemplate: 'For Lease',
          },
          {
            groupTemplate: 'New',
          },
          {
            groupTemplate: 'Transactional',
          },
        ],
        mostFrequentSchedule: {
          name: 'Monthly',
          rrule: 'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
        },
      },
      {
        phraseTemplate: '{{make}} lease deals',
        keywordGroupTemplates: [
          {
            groupTemplate: 'Transactional',
          },
          {
            groupTemplate: 'New Model',
          },
        ],
        mostFrequentSchedule: {
          name: 'Twice Monthly',
          rrule: 'FREQ=MONTHLY;BYMONTHDAY=1,15;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
        },
      },
      {
        phraseTemplate: '{{make}} {{model}} lease',
        keywordGroupTemplates: [
          {
            groupTemplate: 'Transactional',
          },
          {
            groupTemplate: 'New Model',
          },
        ],
        mostFrequentSchedule: {
          name: 'Weekly',
          rrule: 'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
        },
      },
      {
        phraseTemplate: '{{make}} {{model}} lease deals',
        keywordGroupTemplates: [
          {
            groupTemplate: 'Transactional',
          },
          {
            groupTemplate: 'New Model',
          },
        ],
        mostFrequentSchedule: {
          name: 'Weekly',
          rrule: 'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
        },
      },
    ],
  },
  schedules: [
    {
      name: 'Daily',
      rrule: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
    },
    {
      name: 'MWF',
      rrule: 'FREQ=WEEKLY;BYDAY=MO,WE,FR;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
    },
    {
      name: 'Weekly',
      rrule: 'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
    },
    {
      name: 'Twice Monthly',
      rrule: 'FREQ=MONTHLY;BYMONTHDAY=1,15;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
    },
    {
      name: 'Monthly',
      rrule: 'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
    },
    {
      name: 'Quarterly',
      rrule:
        'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
    },
    {
      name: 'Twice Yearly',
      rrule:
        'FREQ=YEARLY;BYMONTH=1,7;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
    },
    {
      name: 'Yearly',
      rrule:
        'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
    },
    {
      name: 'Never',
    },
  ],
}
