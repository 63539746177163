import { createColumnHelper } from '@tanstack/react-table'
import * as React from 'react'
import { FaEdit, FaTimes, FaTimesCircle } from 'react-icons/fa'
import Button from '../components/Button'
import Select from '../components/Select'
import useConfirm from '../hooks/useConfirm'
import { useTable } from '../hooks/useTable'
import useToast from '../hooks/useToast'
import { PhraseGroup } from '../utils/Api'
import { NO_GROUP_NAME } from '../utils/Constants'
import { dedupePhraseGroups } from '../utils/Phrases'
import Clickable from './Clickable'
import Table from './Table'
import { createCheckboxColumn } from './CheckboxColumn'

type Props = {
  phraseGroups: any
  onPhraseGroupsChange: any
}

//

const columnHelper = createColumnHelper<PhraseGroup>()

export default function PhraseGroupsFieldGroups({
  phraseGroups,
  onPhraseGroupsChange,
}: Props) {
  const toast = useToast()
  const confirm = useConfirm()

  const info = React.useMemo(
    () => dedupePhraseGroups(phraseGroups),
    [phraseGroups]
  )

  const groupOptions = React.useMemo(
    () =>
      Object.keys(info.groups).map(d => ({
        value: d,
        label: d,
      })),
    [info.groups]
  )

  const tableData = React.useMemo(
    () =>
      Object.entries(info.groups).map(([group, phrases]: any) => {
        return {
          group,
          phraseCount: phrases.length,
        }
      }),
    [info.groups]
  )

  const columns = React.useMemo(
    () => [
      createCheckboxColumn(),
      // @ts-expect-error  // Argument of type '"group"' is not assignable to pa... Remove this comment to see the full error message
      columnHelper.accessor('group', {
        header: 'Keyword Group',
        filterFn: 'fuzzy',
      }),
      // @ts-expect-error  // Argument of type '"phraseCount"' is not assignable... Remove this comment to see the full error message
      columnHelper.accessor('phraseCount', {
        header: 'Keyword Phrases',
        filterFn: 'inNumberRange',
      }),
      columnHelper.display({
        id: 'actions',
        header: 'Actions',
        cell: (props: any) => (
          <div>
            {props.row.original.group !== NO_GROUP_NAME ? (
              <div className="flex items-center gap-4">
                <Select
                  options={groupOptions}
                  onChange={async value => {
                    const confirmed = await confirm({
                      title: `Are you sure you want to move/rename this group?`,
                    })

                    if (confirmed) {
                      phraseGroups.forEach((phraseGroup: any) => {
                        phraseGroup.groups = phraseGroup.groups
                          ? phraseGroup.groups.map((group: any) =>
                              group === props.row.original.group ? value : group
                            )
                          : []
                      })
                      onPhraseGroupsChange([...phraseGroups])
                      toast({
                        color: 'green-500',
                        message: 'Group has been moved/renamed',
                      })
                    }
                  }}
                  create
                  placeholder={'Select a Keyword Group or Type to Rename...'}
                  value={''}
                  className="inline-flex"
                >
                  {({ onClick }: any) => (
                    <Clickable onClick={onClick}>
                      <FaEdit className="inline" /> Move / Rename
                    </Clickable>
                  )}
                </Select>{' '}
                <Clickable
                  onClick={async () => {
                    const confirmed = await confirm({
                      title: `Are you sure you want to remove this group?`,
                      message: `The group will only be removed from these keyword phrases. The keyword phrases themselves will not be deleted.`,
                    })

                    if (confirmed) {
                      phraseGroups.forEach((phraseGroup: any) => {
                        phraseGroup.groups = phraseGroup.groups
                          ? phraseGroup.groups.filter(
                              (group: any) => group !== props.row.original.group
                            )
                          : []
                      })
                      onPhraseGroupsChange([...phraseGroups])
                    }
                  }}
                >
                  <FaTimes className="inline" /> Remove Keyword Group
                </Clickable>{' '}
              </div>
            ) : null}
          </div>
        ),
      }),
    ],
    [confirm, groupOptions, onPhraseGroupsChange, phraseGroups, toast]
  )

  const table = useTable({
    data: tableData,
    columns,
    showToolbar: true,

    initialState: {
      pagination: {
        pageSize: 10,
      },
      sorting: [{ id: 'group', desc: false }],
    },
    tight: true,
  })

  const selectedRows = table.getSelectedRowModel().rows as any[]

  return (
    <div className="divide-y divide-gray-500/20 rounded-lg border border-gray-500/20">
      <Table table={table} />
      {selectedRows.length ? (
        <div className="flex flex-wrap items-center gap-1 p-2">
          <strong>{selectedRows.length}</strong> keyword groups selected &nbsp;
          <Select
            options={groupOptions}
            onChange={async value => {
              const confirmed = await confirm({
                title: `Are you sure you want to move these groups?`,
              })

              if (confirmed) {
                selectedRows.forEach(selectedRow =>
                  phraseGroups.forEach((phraseGroup: any) => {
                    phraseGroup.groups = phraseGroup.groups
                      ? phraseGroup.groups.map((group: any) =>
                          group === selectedRow.original.group ? value : group
                        )
                      : []
                  })
                )

                onPhraseGroupsChange([...phraseGroups])
                toast({
                  color: 'green-500',
                  message: 'Keyword Group has been moved/renamed',
                })
              }
            }}
            create
            placeholder={'Select a Keyword Group or Type to Rename...'}
            value={''}
            className="inline-flex"
          >
            {({ onClick }: any) => (
              <Button
                size="xs"
                color={['gray-100', 'gray-800']}
                hoverColor="blue-500"
                onClick={onClick}
              >
                <FaEdit className="inline" /> Move Keyword Groups
              </Button>
            )}
          </Select>{' '}
          <Button
            size="xs"
            color={['gray-100', 'gray-800']}
            hoverColor="red-500"
            onClick={async () => {
              const confirmed = await confirm({
                message: 'Are you sure you want to delete all selected groups?',
              })

              if (confirmed) {
                phraseGroups.forEach((phraseGroup: any) => {
                  phraseGroup.groups = phraseGroup.groups
                    ? phraseGroup.groups.filter(
                        (group: any) =>
                          !selectedRows.find(
                            selectedRow => selectedRow.original.group === group
                          )
                      )
                    : []
                })
                onPhraseGroupsChange([...phraseGroups])
              }
            }}
          >
            <FaTimesCircle className="inline" /> Remove Keyword Groups
          </Button>
        </div>
      ) : null}
    </div>
  )
}
