import * as React from 'react'
import { FaPlus } from 'react-icons/fa'
import { MdOpenInNew } from 'react-icons/md'
import Button from '../components/Button'
import Link from '../components/Link'
import {
  useBrandOptionsQuery,
  useBrandPropertyOptionsQuery,
  useBrandTypeOptionsQuery,
} from '../hooks/brands'
import {
  useKeywordGroupOptionsQuery,
  useKeywordOptionsQuery,
} from '../hooks/keywords'
import { useSegmentOptionsQuery } from '../hooks/segments'
import { useDomainOptionsQuery } from '../hooks/useDomainOptionsQuery'
import { useHostOptionsQuery } from '../hooks/useHostOptionsQuery'
import { useUrlOptionsQuery } from '../hooks/useUrlOptionsQuery'
import {
  performanceByKeywordGroupSlug,
  performanceBySegmentSlug,
  performanceByUrlSlug,
  performanceOverviewSlug,
  performanceSingleKeywordSlug,
} from '../utils/Constants'
import { GroupByQuerySeries } from './Api'
import { BRAND_TYPE_OWNED } from './Constants'
import { formatKeywordV1, formatLimitedText, renderLimitedText } from './Format'
import { rollupDelimiter } from '../components/RollupPicker'
import Clickable from '../components/Clickable'
import { FullSearchSchema, RegisteredRouter } from '@tanstack/react-router'

export type QueryFacetId =
  | 'brand'
  | 'brandType'
  | 'brandProperty'
  | 'domain'
  | 'host'
  | 'url'
  | 'keywordGroup'
  | 'keyword'
  | 'segment'

export type QueryFacet = {
  id: QueryFacetId
  filterKey: string
  rollupId: string
  label: string
  labelPlural: string
  useOptionsQuery:
    | typeof useBrandOptionsQuery
    | typeof useBrandTypeOptionsQuery
    | typeof useBrandPropertyOptionsQuery
    | typeof useDomainOptionsQuery
    | typeof useHostOptionsQuery
    | typeof useUrlOptionsQuery
    | typeof useKeywordGroupOptionsQuery
    | typeof useKeywordOptionsQuery
  formatSeriesLabel: (
    serie: GroupByQuerySeries,
    allSeries: GroupByQuerySeries[],
    opts?: { short?: boolean }
  ) => string
  renderSeriesLabel: (
    serie: GroupByQuerySeries,
    allSeries: GroupByQuerySeries[],
    opts?: { short?: boolean }
  ) => React.ReactNode
  getDefaultValue: (options: any[]) => string | number
  dashboardSlug: string
  getSerieValue: (serie: GroupByQuerySeries) => any
  getSerieRollup: (serie: GroupByQuerySeries) => any
  getMetricSearchFn?: (
    row: GroupByQuerySeries
  ) => (
    search: FullSearchSchema<RegisteredRouter['routeTree']>
  ) => FullSearchSchema<RegisteredRouter['routeTree']>
  seriesDrillthroughLabel: string
  AddColumnItem?: React.FC
  getSeriesStatusFn?: (activeValues: unknown[]) => SeriesStatusFn
}

export type SeriesStatusFn = (value: unknown) => 'inactive' | 'idle' | 'active'

function AddBrandColumnItem() {
  return (
    <Link to={`/brands/new`}>
      <Button size="sm" color="blue-500">
        <FaPlus className="inline" /> Add a Brand
      </Button>
    </Link>
  )
}

const brandRollup: QueryFacet = {
  id: 'brand',
  filterKey: 'brandIds',
  rollupId: 'brand_id',
  label: 'Brand',
  labelPlural: 'Brands',
  useOptionsQuery: useBrandOptionsQuery,
  formatSeriesLabel: series => series.brand?.name ?? series.label ?? 'N/A',
  renderSeriesLabel: series => series.brand?.name ?? series.label ?? 'N/A',
  getDefaultValue: (options = []) => {
    return options.find(d => d.brand.type === BRAND_TYPE_OWNED)?.value
  },
  AddColumnItem: AddBrandColumnItem,
  dashboardSlug: performanceOverviewSlug,
  getSerieValue: serie => serie?.brand?.brand_id,
  getSerieRollup: serie =>
    ['brand', serie?.brand?.brand_id].join(rollupDelimiter),
  seriesDrillthroughLabel: 'View Brand Overview',
  getMetricSearchFn: row => search => {
    return {
      rollup: ['brand', row.brand?.brand_id].join(rollupDelimiter),
      brandIds: undefined,
    }
  },
}

const brandTypeRollup: QueryFacet = {
  id: 'brandType',
  filterKey: 'brandTypes',
  rollupId: 'brand_type',
  label: 'Brand Type',
  labelPlural: 'Brand Types',
  useOptionsQuery: useBrandTypeOptionsQuery,
  formatSeriesLabel: series =>
    formatLimitedText(series.label ?? 'N/A', 75, { fromPercentage: 0.8 }),
  renderSeriesLabel: series =>
    renderLimitedText(series.label ?? 'N/A', 75, { fromPercentage: 0.8 }),
  getDefaultValue: (options = []) => {
    return BRAND_TYPE_OWNED
  },
  AddColumnItem: AddBrandColumnItem,
  dashboardSlug: performanceOverviewSlug,
  getSerieValue: serie => serie?.brand_type_data?.brand_type,
  getSerieRollup: serie =>
    ['brandType', serie?.brand_type_data?.brand_type].join(rollupDelimiter),
  seriesDrillthroughLabel: 'View Brand Type Overview',
  getMetricSearchFn: row => search => {
    return {
      rollup: ['brandType', row.brand_type_data?.brand_type].join(
        rollupDelimiter
      ),
    }
  },
}

const brandPropertyRollup: QueryFacet = {
  id: 'brandProperty',
  filterKey: 'brandProperties',
  rollupId: 'brand_property',
  label: 'Brand Property',
  labelPlural: 'Brand Properties',
  useOptionsQuery: useBrandPropertyOptionsQuery,
  formatSeriesLabel: series =>
    formatLimitedText(series.label ?? 'N/A', 75, { fromPercentage: 0.8 }),
  renderSeriesLabel: series =>
    renderLimitedText(series.label ?? 'N/A', 75, { fromPercentage: 0.8 }),
  AddColumnItem: AddBrandColumnItem,
  getDefaultValue: (options = []) => {
    return options.find(d => d.brand?.type === BRAND_TYPE_OWNED)?.value ?? null
  },
  getSerieValue: serie =>
    `${serie.brand_property?.brand_id}__${serie.brand_property?.property_name}`,
  getSerieRollup: serie =>
    [
      'brandProperty',
      `${serie.brand_property?.brand_id}__${serie.brand_property?.property_name}`,
    ].join(rollupDelimiter),
  dashboardSlug: performanceOverviewSlug,
  seriesDrillthroughLabel: 'View Brand Property Overview',
  getMetricSearchFn: row => search => {
    return {
      rollup: [
        'brandProperty',
        `${row.brand_property?.brand_id}__${row.brand_property?.property_name}`,
      ].join(rollupDelimiter),
    }
  },
}

const domainRollup: QueryFacet = {
  id: 'domain',
  filterKey: 'domainIds',
  rollupId: 'result__url__domain_id',
  label: 'Domain',
  labelPlural: 'Domains',
  useOptionsQuery: useDomainOptionsQuery,
  formatSeriesLabel: series =>
    formatLimitedText(series.label ?? 'N/A', 75, { fromPercentage: 0.8 }),
  renderSeriesLabel: series =>
    renderLimitedText(series.label ?? 'N/A', 75, { fromPercentage: 0.8 }),
  getDefaultValue: (options = []) => {
    return options.find(d => !d.label.startsWith('google.')).value
  },
  dashboardSlug: performanceOverviewSlug,
  getSerieValue: serie => serie?.domain_data?.domain_id,
  getSerieRollup: serie =>
    ['domain', serie?.domain_data?.domain_id].join(rollupDelimiter),
  seriesDrillthroughLabel: 'View Domain Overview',
  getMetricSearchFn: row => search => {
    return {
      rollup: ['domain', row.domain_data?.domain_id].join(rollupDelimiter),
    }
  },
}

const hostRollup: QueryFacet = {
  id: 'host',
  filterKey: 'hostIds',
  rollupId: 'result__url__host_id',
  label: 'Subdomain',
  labelPlural: 'Subdomains',
  useOptionsQuery: useHostOptionsQuery,
  formatSeriesLabel: series =>
    formatLimitedText(series.label ?? 'N/A', 75, { fromPercentage: 0.8 }),
  renderSeriesLabel: series =>
    renderLimitedText(series.label ?? 'N/A', 75, { fromPercentage: 0.8 }),
  getDefaultValue: (options = []) => {
    return options.find(d => !d.label.startsWith('google.')).value
  },
  dashboardSlug: performanceOverviewSlug,
  getSerieValue: serie => serie?.host_data?.host_id,
  getSerieRollup: serie =>
    ['host', serie?.host_data?.host_id].join(rollupDelimiter),
  seriesDrillthroughLabel: 'View Host Overview',
  getMetricSearchFn: row => search => {
    return {
      rollup: ['host', row.host_data?.host_id].join(rollupDelimiter),
    }
  },
}

const urlIdRollup: QueryFacet = {
  id: 'url',
  filterKey: 'urlIds',
  rollupId: 'result__url__url_id',
  label: 'URL',
  labelPlural: 'URLs',
  useOptionsQuery: useUrlOptionsQuery,
  formatSeriesLabel: (series, allSeries, opts) =>
    opts?.short
      ? formatLimitedText(series.label ?? 'N/A', 75, {
          fromPercentage: 0.8,
        })
      : series.label ?? 'N/A',
  renderSeriesLabel: (series, allSeries, opts) => (
    <>
      {opts?.short
        ? renderLimitedText(series.label ?? 'N/A', 75, { fromPercentage: 0.8 })
        : series.label ?? 'N/A'}{' '}
      <Clickable
        onClick={(e: any) => {
          e.stopPropagation()
          window.open(series.label, '_blank')
        }}
      >
        <MdOpenInNew className="inline" />
      </Clickable>
    </>
  ),
  getDefaultValue: (options = []) => {
    return options[0].value
  },
  dashboardSlug: performanceByUrlSlug,
  getSerieValue: serie => serie?.url?.result__url__url_id,
  getSerieRollup: serie =>
    ['url', serie?.url?.result__url__url_id].join(rollupDelimiter),
  seriesDrillthroughLabel: 'View URL Overview',
  getMetricSearchFn: row => search => {
    return {
      rollup: ['url', row.url?.result__url__url_id].join(rollupDelimiter),
    }
  },
}

const segmentIdRollup: QueryFacet = {
  id: 'segment',
  filterKey: 'segmentIds',
  rollupId: 'segment_id',
  label: 'Segment',
  labelPlural: 'Segments',
  useOptionsQuery: useSegmentOptionsQuery,
  formatSeriesLabel: (series, all) =>
    formatLimitedText(series.label, 75, { fromPercentage: 0.8 }),
  renderSeriesLabel: (series, all) =>
    renderLimitedText(series.label, 75, { fromPercentage: 0.8 }),
  getDefaultValue: (options = []) => {
    return 1
  },
  getSeriesStatusFn: activeValues => (row: any) => {
    activeValues = activeValues?.filter(Boolean) ?? []

    return activeValues.length
      ? activeValues.includes(row.segment.segment_id)
        ? 'active'
        : 'inactive'
      : 'idle'
  },
  dashboardSlug: performanceBySegmentSlug,
  getSerieValue: serie => String(serie?.segment?.segment_id),
  getSerieRollup: serie => ['segment', serie?.segment?.segment_id].join('_'),
  seriesDrillthroughLabel: 'View Keywords',
  getMetricSearchFn: row => search => {
    return {
      segmentId: row?.segment?.segment_id,
    }
  },
}

const keywordGroupRollup: QueryFacet = {
  id: 'keywordGroup',
  filterKey: 'keywordGroups',
  rollupId: 'keyword_group',
  label: 'Keyword Group',
  labelPlural: 'Keyword Groups',
  useOptionsQuery: useKeywordGroupOptionsQuery,
  formatSeriesLabel: (series, all) =>
    formatLimitedText(series.label, 75, { fromPercentage: 0.8 }),
  renderSeriesLabel: (series, all) =>
    renderLimitedText(series.label, 75, { fromPercentage: 0.8 }),
  getDefaultValue: (options = []) => {
    return options[0].value
  },
  dashboardSlug: performanceByKeywordGroupSlug,
  getSerieValue: serie => serie?.keyword_group?.keyword_group,
  getSerieRollup: serie =>
    ['keywordGroup', serie?.keyword_group?.keyword_group].join(rollupDelimiter),
  getMetricSearchFn: row => search => {
    return {
      keywordGroup: row?.keyword_group?.keyword_group,
    }
  },
  seriesDrillthroughLabel: 'View Keywords',
}

const keywordIdRollup: QueryFacet = {
  id: 'keyword',
  filterKey: 'keywordIds',
  rollupId: 'keyword_id',
  label: 'Keyword',
  labelPlural: 'Keywords',
  useOptionsQuery: useKeywordOptionsQuery,
  formatSeriesLabel: (series, all) =>
    formatKeywordV1(series.keyword, {
      // @ts-expect-error  // Type '({ keyword_id: number; } | undefined)[]' is ... Remove this comment to see the full error message
      keywords: all?.map(d => d.keyword) ?? [],
      string: true,
    }) as string,
  renderSeriesLabel: (series, all) =>
    formatKeywordV1(series.keyword, {
      short: true,
      // @ts-expect-error  // Type '({ keyword_id: number; } | undefined)[]' is ... Remove this comment to see the full error message
      keywords: all.map(d => d.keyword),
    }),
  getDefaultValue: (options = []) => {
    return options[0].value
  },
  dashboardSlug: performanceSingleKeywordSlug,
  getSerieValue: serie => serie?.keyword?.keyword_id,
  getSerieRollup: serie =>
    ['keyword', serie?.keyword?.keyword_id].join(rollupDelimiter),
  seriesDrillthroughLabel: 'View Keyword',
}

export const allQueryFacets = [
  brandTypeRollup,
  brandRollup,
  brandPropertyRollup,
  domainRollup,
  hostRollup,
  urlIdRollup,
  segmentIdRollup,
  keywordGroupRollup,
  keywordIdRollup,
]

export const queryFacetsById: Record<QueryFacet['id'], QueryFacet> =
  allQueryFacets.reduce(
    (acc, facet) => ({
      ...acc,
      [facet.id]: facet,
    }),
    {} as Record<QueryFacet['id'], QueryFacet>
  )

export const groupByFacets = [
  brandTypeRollup,
  brandRollup,
  brandPropertyRollup,
  domainRollup,
  hostRollup,
  urlIdRollup,
]

export const rollupFacets = [
  brandTypeRollup,
  brandRollup,
  brandPropertyRollup,
  domainRollup,
  hostRollup,
  urlIdRollup,
]
