import * as React from 'react'
import { useQueryClient } from 'react-query'
import { useProfileQuery } from './profile'
import useEventFilter from './useEventFilter'

const subscriptionsByQueryKey: Record<string, () => void> = {}

export function useQuerySubscription({
  kind,
  label,
  subscriber,
}: {
  kind: string
  label: string
  subscriber: (userId: number, callback: any) => () => void
}) {
  const profileQuery = useProfileQuery()
  const queryClient = useQueryClient()
  const meId = profileQuery.data?.id

  //

  const handleEvent = useEventFilter(kind, label, event => {
    if (event.type === 'TYPE_CREATE') {
      queryClient.invalidateQueries(kind)
    }

    if (event.type === 'TYPE_UPDATE') {
      queryClient.invalidateQueries(kind)
      queryClient.invalidateQueries(kind)
    }

    if (event.type === 'TYPE_DELETE') {
      queryClient.invalidateQueries(kind)
    }
  })

  React.useEffect(() => {
    if (!meId || subscriptionsByQueryKey[kind]) {
      return
    }

    subscriptionsByQueryKey[kind] = subscriber(meId, handleEvent)

    return () => {
      subscriptionsByQueryKey[kind]?.()
      delete subscriptionsByQueryKey[kind]
    }
  }, [handleEvent, kind, meId, subscriber])
}
