import { PlainMessage } from '@bufbuild/protobuf'
import { useSearch } from '@tanstack/react-router'
import * as React from 'react'
import {
  FaExclamationTriangle,
  FaPlus,
  FaTimes,
  FaTimesCircle,
} from 'react-icons/fa'
import { twMerge } from 'tailwind-merge'
import { usePublicPlansQuery } from '../hooks/plans'
import { scheduleOptions } from '../options/scheduleOptions'
import { last, max, multiSortBy, sum } from '../utils'
import { openHubSpotChat } from '../utils/Analytics'
import { formatCurrency, formatNumber } from '../utils/Format'
import { moneyToFloat } from '../utils/money'
import { PeriodPb, PlanPb, ProductFamilyPb } from '../utils/proto'
import Button from './Button'
import Card from './Card'
import Clickable from './Clickable'
import Input, { PlainInput } from './Input'
import NumberInput from './NumberInput'
import Select from './Select'
import {
  HeaderRow,
  TableCell,
  TableEl,
  TableHead,
  TableHeader,
  TableRow,
} from './Table'
import Tooltip from './Tooltip'
import { ButtonPlain } from './ButtonPlain'

export interface SerpUsageCalculatorRow {
  projectName: string
  type: 'phrase' | 'keyword'
  scheduleCounts: number[]
  deviceCount?: number
  localeCount?: number
}

const enterprisePlan = {
  name: 'Enterprise',
  periodPlans: [],
  planId: '',
  productFamily: ProductFamilyPb.STANDARD,
}

const presets: { label: string; rows: SerpUsageCalculatorRow[] }[] = [
  {
    label: 'Hobby',
    rows: [
      {
        projectName: 'Focus Keywords',
        type: 'phrase',
        scheduleCounts: [100, 0, 0],
        deviceCount: 2,
        localeCount: 1,
      },
      {
        projectName: 'Research Keywords',
        type: 'phrase',
        scheduleCounts: [0, 1000, 0],
        deviceCount: 2,
        localeCount: 1,
      },
    ],
  },
  {
    label: 'Small Site',
    rows: [
      {
        projectName: 'Industry Keywords',
        type: 'phrase',
        scheduleCounts: [500, 0, 0],
        deviceCount: 2,
        localeCount: 1,
      },
      {
        projectName: 'Branded Keywords',
        type: 'phrase',
        scheduleCounts: [0, 1000, 0],
        deviceCount: 2,
        localeCount: 1,
      },
      {
        projectName: 'Content Idea Keywords',
        type: 'phrase',
        scheduleCounts: [0, 0, 10000],
        deviceCount: 2,
        localeCount: 1,
      },
    ],
  },
  {
    label: 'Small SMB Agency',
    rows: [
      {
        projectName: 'Client A',
        type: 'phrase',
        scheduleCounts: [450, 1000, 5000],
        deviceCount: 2,
        localeCount: 1,
      },
      {
        projectName: 'Client B',
        type: 'phrase',
        scheduleCounts: [200, 4000, 0],
        deviceCount: 2,
        localeCount: 1,
      },
      {
        projectName: 'Client B - 2nd KW Group',
        type: 'phrase',
        scheduleCounts: [0, 0, 3000],
        deviceCount: 1,
        localeCount: 1,
      },
      {
        projectName: 'Client C',
        type: 'phrase',
        scheduleCounts: [40, 700, 0],
        deviceCount: 1,
        localeCount: 1,
      },
      {
        projectName: 'Client C - 2nd KW Group',
        type: 'phrase',
        scheduleCounts: [0, 0, 1000],
        deviceCount: 2,
        localeCount: 1,
      },
      {
        projectName: 'Client D',
        type: 'phrase',
        scheduleCounts: [75, 0, 500],
        deviceCount: 2,
        localeCount: 1,
      },
      {
        projectName: 'Client D - Multi Location',
        type: 'phrase',
        scheduleCounts: [0, 0, 250],
        deviceCount: 2,
        localeCount: 25,
      },
      {
        projectName: 'Client E',
        type: 'phrase',
        scheduleCounts: [100, 500, 0],
        deviceCount: 2,
        localeCount: 1,
      },
      {
        projectName: 'Client F',
        type: 'phrase',
        scheduleCounts: [25, 200, 5000],
        deviceCount: 1,
        localeCount: 1,
      },
    ],
  },
  {
    label: 'Large Agency',
    rows: [
      {
        projectName: 'Client A',
        type: 'phrase',
        scheduleCounts: [500, 0, 4000],
        deviceCount: 2,
        localeCount: 1,
      },
      {
        projectName: 'Client B',
        type: 'phrase',
        scheduleCounts: [350, 2500, 4000],
        deviceCount: 2,
        localeCount: 1,
      },
      {
        projectName: 'Client C',
        type: 'phrase',
        scheduleCounts: [100, 0, 0],
        deviceCount: 2,
        localeCount: 1,
      },
      {
        projectName: 'Client C - 2nd KW Group',
        type: 'phrase',
        scheduleCounts: [0, 0, 20000],
        deviceCount: 1,
        localeCount: 1,
      },
      {
        projectName: 'Client D',
        type: 'phrase',
        scheduleCounts: [25, 0, 100],
        deviceCount: 2,
        localeCount: 16,
      },
      {
        projectName: '20 Clients - 1 Device',
        type: 'phrase',
        scheduleCounts: [2000, 0, 20000],
        deviceCount: 1,
        localeCount: 1,
      },
      {
        projectName: '35 Clients - 2 Devices',
        type: 'phrase',
        scheduleCounts: [2500, 0, 25000],
        deviceCount: 2,
        localeCount: 1,
      },
    ],
  },
  {
    label: 'Large Site',
    rows: [
      {
        projectName: 'Overall SERP Landscape',
        type: 'phrase',
        scheduleCounts: [0, 0, 1000000],
        deviceCount: 2,
        localeCount: 1,
      },
      {
        projectName: 'Most Important KWs',
        type: 'phrase',
        scheduleCounts: [20000, 0, 0],
        deviceCount: 2,
        localeCount: 1,
      },
    ],
  },
  {
    label: 'Large Site - Multiple Locations',
    rows: [
      {
        projectName: 'Country Targeted KWs',
        type: 'phrase',
        scheduleCounts: [5000, 0, 30000],
        deviceCount: 2,
        localeCount: 1,
      },
      {
        projectName: 'All Geo Locations',
        type: 'phrase',
        scheduleCounts: [75, 0, 0],
        deviceCount: 2,
        localeCount: 350,
      },
    ],
  },
]

const ScheduleInput = (props: any) => (
  <PlainInput
    {...props}
    className={twMerge(
      'text-base font-bold underline decoration-gray-500',
      props.className
    )}
  />
)

export const SerpUsageCalculator = React.forwardRef(
  (
    props: {
      initialSchedules?: string[]
      initialRows?: SerpUsageCalculatorRow[]
    },
    ref: React.Ref<HTMLDivElement>
  ) => {
    const search = useSearch() as {
      disableHubspot?: boolean
      showIntro?: boolean
    }
    const [schedules, setSchedules] = React.useState<string[]>(
      props.initialSchedules?.length
        ? props.initialSchedules
        : ['Daily', 'Weekly on Sunday', 'Monthly on the 1st'].map(
            d => scheduleOptions.find(option => option.label === d)!.value
          )
    )

    const customScheduleIndexes = React.useMemo(
      () =>
        schedules
          .map((d, index) =>
            scheduleOptions.find(option => option.value === d)
              ? undefined!
              : index
          )
          .filter(i => i !== undefined),
      [schedules]
    )

    const [rows, setRows] = React.useState<SerpUsageCalculatorRow[]>(
      props.initialRows?.length ? props.initialRows : presets[1]?.rows ?? []
    )

    const plansQuery = usePublicPlansQuery()

    const addSchedule = (schedule: string) => {
      setSchedules(prev => [...prev, schedule])

      const newRows = rows.map(row => {
        return { ...row, scheduleCounts: [...row.scheduleCounts, 0] }
      })
      setRows(newRows)
    }

    const removeSchedule = (scheduleIndex: number) => {
      setSchedules(prev => prev.filter((_, index) => index !== scheduleIndex))

      setRows(prev =>
        prev.map(row => {
          return {
            ...row,
            scheduleCounts: row.scheduleCounts.filter(
              (_, index) => index !== scheduleIndex
            ),
          }
        })
      )
    }

    const addProject = () => {
      setRows(prev => [
        ...prev,
        {
          projectName: `Project ${prev.length + 1}`,
          type: 'phrase',
          deviceCount: 1,
          localeCount: 1,
          scheduleCounts: schedules.map(d => 0),
        },
      ])
    }

    const deleteRow = (rowIndex: number) => () => {
      setRows(prev => prev.filter((row, i) => i !== rowIndex))
    }

    const totalRequiredSerps = sum(
      rows,
      row =>
        sum(
          row.scheduleCounts.map((count, i) => {
            const schedule = scheduleOptions.find(
              option => option.value === schedules[i]
            )

            if (!schedule) {
              return 0
            }

            return count * schedule.multiplier
          })
        ) *
        (row.type === 'phrase'
          ? (row.deviceCount ?? 1) * (row.localeCount ?? 1)
          : 1)
    )

    const sortedPlans = React.useMemo(
      () =>
        multiSortBy(
          [...(plansQuery.data?.plans ?? [])],
          [
            d =>
              moneyToFloat(
                d.periodPlans?.find(d => d.basePeriod === PeriodPb.YEARLY)
                  ?.basePrice
              ),
          ]
        ),
      [plansQuery.data?.plans]
    )

    const planInfo = (():
      | undefined
      | { plan: PlainMessage<PlanPb> | undefined; overageCost: number } => {
      const plans = sortedPlans

      if (plans) {
        for (let i = 0; i < plans.length; i++) {
          const plan = plans[i]!
          const nextPlan = plans[i + 1]

          const upsellCost = nextPlan
            ? moneyToFloat(
                nextPlan?.periodPlans.find(
                  d => d.basePeriod === PeriodPb.YEARLY
                )?.basePrice
              ) -
              moneyToFloat(
                plan.periodPlans.find(d => d.basePeriod === PeriodPb.YEARLY)
                  ?.basePrice
              )
            : 9999999999999

          const included = Number(
            plan?.periodPlans.find(d => d.basePeriod === PeriodPb.YEARLY)
              ?.meteredPlans[0]?.includedQuantity ?? 0
          )

          const requiredOverage = Math.max(totalRequiredSerps - included, 0)

          const yearlyOverageCost =
            requiredOverage *
            12 *
            moneyToFloat(
              plan.periodPlans.find(d => d.basePeriod === PeriodPb.YEARLY)
                ?.meteredPlans[0]?.unitPrice
            )

          const overageOkay = nextPlan && yearlyOverageCost <= upsellCost

          // If there's enough serps or the overage amount is less than the cost of the next plan
          if (included > totalRequiredSerps || overageOkay) {
            return { plan, overageCost: yearlyOverageCost / 12 }
          }
        }

        return {
          plan: enterprisePlan,
          overageCost: 0,
        }
      }
    })()

    const rowsWithTotals = rows.map(row => ({
      ...row,
      total: sum(row.scheduleCounts, (count, index) => {
        const schedule = scheduleOptions.find(d => d.value === schedules[index])

        if (!schedule) {
          return count
        }

        return (
          count *
          (row.type === 'phrase'
            ? (row.deviceCount ?? 1) * (row.localeCount ?? 1)
            : 1) *
          schedule.multiplier
        )
      }),
    }))

    const maxTotal = max(rowsWithTotals, d => d.total)?.total ?? 0
    const sumTotal = sum(rowsWithTotals, d => d.total) ?? 0

    const yearlyCost = moneyToFloat(
      // @ts-expect-error  // Argument of type 'PlainMessage<Money> | undefined'... Remove this comment to see the full error message
      planInfo?.plan?.periodPlans.find(d => d.basePeriod === PeriodPb.YEARLY)
        ?.basePrice
    )

    const monthlyCost = yearlyCost / 12

    return (
      <div className="space-y-4" ref={ref}>
        {search.showIntro ? (
          <div className="space-y-2 p-6 text-center">
            <div className="text-3xl">How many SERPs do I need per month?</div>
            <div className="text-xl italic">
              Not sure where to start? Try using one of our preset options to
              find the right plan for you.
            </div>
            <div className="flex justify-center gap-2">
              {presets.map(preset => {
                return (
                  <Button
                    size="base"
                    color="blue-500"
                    key={preset.label}
                    onClick={() => setRows(preset.rows)}
                  >
                    {preset.label}
                  </Button>
                )
              })}
            </div>
          </div>
        ) : null}
        <Card className="mx-auto overflow-x-auto p-0">
          <TableEl className="dark:bg-gray-900">
            <TableHead className="bg-white dark:bg-gray-900">
              <HeaderRow>
                <TableHeader tight rowSpan={2}></TableHeader>
                <TableHeader tight rowSpan={2} className="text-left">
                  <div className="flex gap-2 p-2">
                    <span className="text-xl">Projects</span>
                  </div>
                </TableHeader>
                <TableHeader tight rowSpan={2} className="p-4">
                  Devices
                </TableHeader>
                <TableHeader tight rowSpan={2} className="p-4">
                  Locations
                </TableHeader>
                <TableHeader rowSpan={1} colSpan={schedules.length + 1}>
                  <div className="flex items-center gap-2 py-2">
                    Keyword Phrases
                    <Select
                      options={scheduleOptions}
                      className="inline-flex"
                      onChange={(value: string) => addSchedule(value)}
                    >
                      {({ onClick }: any) => (
                        <ButtonPlain
                          className="bg-green-500 text-sm"
                          onClick={onClick}
                        >
                          <FaPlus className="inline" />
                          Add Schedule
                        </ButtonPlain>
                      )}
                    </Select>
                  </div>
                </TableHeader>
              </HeaderRow>
              <HeaderRow>
                {schedules.map((schedule, scheduleIndex) => (
                  <TableHeader
                    tight
                    key={scheduleIndex}
                    className="p-0 text-left font-normal"
                  >
                    <div className="flex gap-2">
                      <Select
                        // @ts-expect-error  // The type 'readonly [{ readonly label: "Hourly"; re... Remove this comment to see the full error message
                        options={scheduleOptions}
                        onChange={(value: string) =>
                          setSchedules(prev =>
                            prev.map((d, i) =>
                              i === scheduleIndex ? value : d
                            )
                          )
                        }
                        value={schedules[scheduleIndex]}
                        className="min-w-[120px] flex-1 text-sm"
                        inputProps={{
                          Input: PlainInput,
                        }}
                      />
                      <Clickable
                        onClick={() => removeSchedule(scheduleIndex)}
                        className="mr-2 self-center text-red-500 opacity-50 hover:opacity-100"
                      >
                        <FaTimes />
                      </Clickable>
                    </div>
                  </TableHeader>
                ))}
                <TableHeader tight>Monthly Total</TableHeader>
                <TableHeader tight>% of Total</TableHeader>
              </HeaderRow>
            </TableHead>
            <tbody>
              {rowsWithTotals.map((row, rowIndex) => {
                const setRow = (
                  updater: (
                    row: SerpUsageCalculatorRow
                  ) => SerpUsageCalculatorRow
                ) => {
                  setRows(prev =>
                    prev.map((p, i) => (i === rowIndex ? updater(p) : p))
                  )
                }

                return (
                  <TableRow key={rowIndex} className="[&>td]:p-0">
                    <TableCell tight>
                      {rows.length > 1 ? (
                        <button
                          className="flex w-full items-center justify-center p-2 text-gray-500 hover:text-red-500"
                          onClick={deleteRow(rowIndex)}
                        >
                          <FaTimesCircle />
                        </button>
                      ) : (
                        ''
                      )}
                    </TableCell>
                    <TableCell tight>
                      <Input
                        type="text"
                        value={row.projectName}
                        onChange={e =>
                          setRow(prev => ({
                            ...prev,
                            // @ts-expect-error  // Property 'value' does not exist on type 'EventTarg... Remove this comment to see the full error message
                            projectName: e.target.value,
                          }))
                        }
                        className={`w-[300px] min-w-[200px] overflow-ellipsis rounded-none border-0 !bg-transparent
                          px-2 py-3 text-sm leading-none [letter-spacing:-.5px]`}
                      />
                    </TableCell>
                    <TableCell tight>
                      <NumberInput
                        value={row.deviceCount}
                        min="1"
                        max="5"
                        allowUndefined
                        onChange={value =>
                          setRow(prev => ({
                            ...prev,
                            deviceCount: value ?? 0,
                          }))
                        }
                        className={twMerge(
                          `w-full rounded-none border-0 !bg-transparent text-sm leading-none`,
                          row.type === 'phrase' && 'font-bold'
                        )}
                        disabled={row.type === 'keyword'}
                      />
                    </TableCell>
                    <TableCell tight>
                      <NumberInput
                        value={row.localeCount}
                        min="1"
                        allowUndefined
                        onChange={value =>
                          setRow(prev => ({
                            ...prev,
                            localeCount: value ?? 0,
                          }))
                        }
                        className={twMerge(
                          `w-full rounded-none border-0 !bg-transparent text-sm leading-none`,
                          row.type === 'phrase' && 'font-bold'
                        )}
                        disabled={row.type === 'keyword'}
                      />
                    </TableCell>
                    {schedules.map((schedule, scheduleIndex) => {
                      const count = row.scheduleCounts[scheduleIndex] ?? 0
                      return (
                        <TableCell tight className="" key={scheduleIndex}>
                          <div className="flex items-center">
                            <NumberInput
                              value={count || 0}
                              min="0"
                              allowUndefined
                              onChange={value =>
                                setRow(prev => ({
                                  ...prev,
                                  scheduleCounts: prev.scheduleCounts.map(
                                    (d, i) =>
                                      i === scheduleIndex ? value ?? 0 : d
                                  ),
                                }))
                              }
                              className={twMerge(
                                `w-full rounded-none border-0 !bg-transparent text-right text-sm leading-none text-opacity-30`,
                                // `focus:text-left`,
                                count! !== 0 && 'font-bold text-opacity-100'
                              )}
                            />
                            {customScheduleIndexes.includes(scheduleIndex) &&
                            count > 0 ? (
                              <Tooltip
                                tooltip="Due to custom scheduling, this keyword count is inaccurate."
                                className="px-2"
                              >
                                <FaExclamationTriangle className="animate-pulse text-yellow-500" />
                              </Tooltip>
                            ) : null}
                          </div>
                        </TableCell>
                      )
                    })}
                    <TableCell tight className="!p-2 text-right">
                      <div className="inline-flex items-center gap-2 text-sm">
                        {formatNumber(row.total)}{' '}
                        {row.scheduleCounts.some(
                          (d, i) => customScheduleIndexes.includes(i) && d > 0
                        ) ? (
                          <Tooltip tooltip="Due to custom scheduling, this count is inaccurate.">
                            <FaExclamationTriangle className="animate-pulse text-yellow-500" />
                          </Tooltip>
                        ) : null}
                      </div>
                    </TableCell>
                    <TableCell tight className="!p-2">
                      {row.scheduleCounts.some(
                        (d, i) => customScheduleIndexes.includes(i) && d > 0
                      )
                        ? null
                        : (() => {
                            const pct = sumTotal
                              ? 100 * (row.total / sumTotal)
                              : 0

                            return (
                              <Tooltip
                                tooltip={
                                  <>
                                    These keywords make up{' '}
                                    <strong>
                                      {formatNumber(pct, { precision: 2 })}%
                                    </strong>{' '}
                                    of the total keywords
                                  </>
                                }
                              >
                                <div className="w-[120px] rounded-full bg-gray-500 bg-opacity-40">
                                  <div
                                    style={{
                                      minWidth: '5%',
                                      width: `${pct}%`,
                                    }}
                                    className="h-[8px] rounded-full bg-blue-500"
                                  />
                                </div>
                              </Tooltip>
                            )
                          })()}
                    </TableCell>
                    <TableCell />
                  </TableRow>
                )
              })}
              <TableRow>
                <TableCell tight></TableCell>
                <TableCell tight>
                  <ButtonPlain className="bg-green-500" onClick={addProject}>
                    <FaPlus className="text-white" />
                    Add Project
                  </ButtonPlain>
                </TableCell>
                <TableCell tight>
                  <Input
                    value=""
                    disabled
                    className="w-full rounded-none border-0 !bg-transparent text-base leading-none"
                  />
                </TableCell>
                <TableCell tight>
                  <Input
                    value=""
                    disabled
                    className="w-full rounded-none border-0 !bg-transparent text-base leading-none"
                  />
                </TableCell>
                {schedules.map((schedule, index) => {
                  return (
                    <TableCell tight className="" key={index}>
                      <Input
                        value=""
                        min="0"
                        disabled
                        className="w-full rounded-none border-0 !bg-transparent text-base leading-none"
                      />
                    </TableCell>
                  )
                })}
                <TableCell tight />
                <TableCell />
              </TableRow>
            </tbody>
            <tfoot className="border-t-2 border-gray-200 text-left dark:border-gray-800">
              <TableRow className="text-right">
                <TableHeader tight colSpan={4} className="p-3">
                  Schedule
                </TableHeader>
                {schedules.map((schedule, i) => {
                  const scheduleOption = scheduleOptions.find(
                    d => d.value === schedule
                  )!

                  return (
                    <TableHeader
                      key={i}
                      tight
                      className="p-2 text-right text-sm font-normal"
                    >
                      {scheduleOption?.label}
                    </TableHeader>
                  )
                })}
                <TableHeader tight />
                <TableHeader />
              </TableRow>
              <TableRow className="text-right">
                <TableHeader tight colSpan={4} className="p-3">
                  Schedule Total
                </TableHeader>
                {schedules.map((schedule, scheduleIndex) => {
                  const totalKeywords = sum(
                    rows,
                    row =>
                      row.scheduleCounts[scheduleIndex]! *
                      (row.type === 'phrase'
                        ? (row.deviceCount ?? 1) * (row.localeCount ?? 1)
                        : 1)
                  )

                  return (
                    <TableCell
                      tight
                      className="p-2 text-right "
                      key={scheduleIndex}
                    >
                      <div className="inline-flex items-center gap-2">
                        {formatNumber(totalKeywords)}{' '}
                        {customScheduleIndexes.includes(scheduleIndex) &&
                        totalKeywords > 0 ? (
                          <Tooltip tooltip="Due to custom scheduling, this count is inaccurate.">
                            <FaExclamationTriangle className="animate-pulse text-yellow-500" />
                          </Tooltip>
                        ) : null}
                      </div>
                    </TableCell>
                  )
                })}

                <TableHeader tight />
                <TableHeader />
              </TableRow>
              <TableRow className="text-right">
                <TableHeader tight colSpan={4} className="p-3">
                  Monthly Total
                </TableHeader>
                {schedules.map((schedule, scheduleIndex) => {
                  const scheduleOption = scheduleOptions.find(
                    d => d.value === schedule
                  )

                  const totalKeywords = sum(
                    rows,
                    row =>
                      row.scheduleCounts[scheduleIndex]! *
                      (row.type === 'phrase'
                        ? (row.deviceCount ?? 1) * (row.localeCount ?? 1)
                        : 1) *
                      (scheduleOption?.multiplier ?? 1)!
                  )

                  return (
                    <TableCell
                      tight
                      className="p-2 text-right font-bold"
                      key={scheduleIndex}
                    >
                      <div className="inline-flex items-center gap-2">
                        {formatNumber(totalKeywords)}{' '}
                        {customScheduleIndexes.includes(scheduleIndex) &&
                        totalKeywords > 0 ? (
                          <Tooltip tooltip="Due to custom scheduling, this count is inaccurate.">
                            <FaExclamationTriangle className="animate-pulse text-yellow-500" />
                          </Tooltip>
                        ) : null}
                      </div>
                    </TableCell>
                  )
                })}
                <TableCell tight className="font-bold">
                  {formatNumber(totalRequiredSerps)}
                </TableCell>
                <TableCell />
              </TableRow>
            </tfoot>
          </TableEl>
        </Card>
        <div className="flex flex-wrap gap-4 bg-gray-50 dark:bg-black">
          <Card className={`flex flex-1 flex-col px-4 py-8 text-center`}>
            {planInfo ? (
              <div className="flex flex-1 flex-col justify-around gap-8">
                <div>
                  <div className="text-6xl font-black leading-none">
                    {formatNumber(totalRequiredSerps)}
                  </div>
                  <div className="p-2 text-2xl">SERPs / month</div>
                </div>
                <div>
                  <div className={`px-10 text-lg leading-none opacity-50`}>
                    Suggested Plan:
                  </div>
                  <div className="p-2.5 text-center text-4xl font-bold leading-none">
                    {planInfo.plan?.name}
                  </div>
                  <div>
                    {planInfo.plan?.name === 'Enterprise' ? (
                      search.disableHubspot ? (
                        'Contact Us'
                      ) : (
                        <>
                          <Button
                            size="xl"
                            color="green-500"
                            onClick={() => openHubSpotChat()}
                          >
                            Chat with us!
                          </Button>
                        </>
                      )
                    ) : (
                      <div className="space-y-2">
                        {!planInfo.overageCost ? (
                          <>
                            <div className="text-2xl font-bold">
                              {formatCurrency(monthlyCost)} / mo
                            </div>
                            <div className="text-sm">(billed yearly)</div>
                          </>
                        ) : (
                          <>
                            <div className="text-2xl font-bold">
                              {formatCurrency(
                                monthlyCost + planInfo.overageCost
                              )}{' '}
                              / mo
                            </div>
                            <div className="text-sm">
                              {formatCurrency(monthlyCost)} /mo (billed yearly){' '}
                              {planInfo?.overageCost > 0
                                ? `+ ${formatCurrency(
                                    planInfo?.overageCost
                                  )} /mo additional overages`
                                : ''}
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <span>Enter your requirements to see a suggested plan</span>
            )}
          </Card>
          <Card className="flex-1 divide-y divide-gray-500 p-0">
            <TableEl>
              <TableHead className="bg-white dark:bg-gray-900">
                <HeaderRow>
                  <TableHeader className="text-lg">Plan Name</TableHeader>
                  <TableHeader className="text-lg">Monthly SERPs</TableHeader>
                  <TableHeader className="text-lg">
                    <div className="flex items-center gap-2">
                      Yearly Price{' '}
                      <div className="rounded-lg bg-green-500 p-1 px-2 text-xs font-black uppercase text-white">
                        SAVE!
                      </div>
                    </div>
                    <div className="text-xs opacity-70">Billed yearly</div>
                  </TableHeader>
                  <TableHeader className="text-lg">
                    <div>Monthly Price</div>
                    <div className="text-xs opacity-70">Billed monthly</div>
                  </TableHeader>
                </HeaderRow>
              </TableHead>
              <tbody>
                {[...sortedPlans, enterprisePlan].map(plan => {
                  return (
                    <TableRow
                      key={plan.name}
                      className={twMerge(
                        'opacity-50',
                        planInfo?.plan?.name === plan.name &&
                          'bg-blue-500 font-bold text-black opacity-100 dark:text-white'
                      )}
                    >
                      <TableCell className="text-lg">
                        <div className="flex w-full justify-between gap-2 py-1">
                          {plan.name}
                          {planInfo?.plan?.planId === plan.planId ? (
                            <div className="rounded-lg bg-green-500 px-2 py-1 text-sm font-black uppercase text-white">
                              Suggested
                            </div>
                          ) : null}
                        </div>
                      </TableCell>
                      <TableCell className="text-lg">
                        {plan.name === 'Enterprise'
                          ? `> ${formatNumber(
                              Number(
                                last(sortedPlans)?.periodPlans.find(
                                  d => d.basePeriod === PeriodPb.MONTHLY
                                )?.meteredPlans[0]?.includedQuantity ?? 0
                              )
                            )}`
                          : formatNumber(
                              Number(
                                plan.periodPlans.find(
                                  d => d.basePeriod === PeriodPb.MONTHLY
                                )?.meteredPlans[0]?.includedQuantity
                              )
                            )}
                      </TableCell>
                      {plan.name === 'Enterprise' ? (
                        <TableCell className="text-sm opacity-80">
                          {search.disableHubspot ? (
                            'Contact Us'
                          ) : (
                            <Button
                              size="base"
                              color="green-500"
                              onClick={() => openHubSpotChat()}
                            >
                              Chat with us!
                            </Button>
                          )}
                        </TableCell>
                      ) : (
                        <TableCell className="text-sm opacity-80">
                          {formatCurrency(
                            moneyToFloat(
                              plan.periodPlans.find(
                                d => d.basePeriod === PeriodPb.YEARLY
                              )?.basePrice
                            ) / 12
                          )}{' '}
                          /mo
                        </TableCell>
                      )}
                      {plan.name === 'Enterprise' ? (
                        <TableCell className="text-sm opacity-80" />
                      ) : (
                        <TableCell className="text-sm opacity-80">
                          {formatCurrency(
                            moneyToFloat(
                              plan.periodPlans.find(
                                d => d.basePeriod === PeriodPb.MONTHLY
                              )?.basePrice
                            )
                          )}{' '}
                          /mo
                        </TableCell>
                      )}
                    </TableRow>
                  )
                })}
              </tbody>
            </TableEl>
          </Card>
        </div>
      </div>
    )
  }
)
