// @generated by protoc-gen-connect-web v0.11.0 with parameter "target=ts"
// @generated from file nozzle/billing/v1/usage.proto (package nozzle.billing.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetUsageRequest, GetUsageResponse, UpdateUsageStateRequest, UpdateUsageStateResponse } from "./usage_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * UsageService provides APIs for interacting with billing usage.
 *
 * @generated from service nozzle.billing.v1.UsageService
 */
export const UsageService = {
  typeName: "nozzle.billing.v1.UsageService",
  methods: {
    /**
     * GetUsage will return a usage breakdown by workspace and project per day
     *
     * @generated from rpc nozzle.billing.v1.UsageService.GetUsage
     */
    getUsage: {
      name: "GetUsage",
      I: GetUsageRequest,
      O: GetUsageResponse,
      kind: MethodKind.Unary,
    },
    /**
     * UpdateUsageState adds a usage value to a given plan.
     *
     * @generated from rpc nozzle.billing.v1.UsageService.UpdateUsageState
     */
    updateUsageState: {
      name: "UpdateUsageState",
      I: UpdateUsageStateRequest,
      O: UpdateUsageStateResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

