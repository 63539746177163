import { useNavigate } from '@tanstack/react-router'
import * as React from 'react'
import { AiOutlineGift } from 'react-icons/ai'
import { BiCreditCard, BiSearch, BiTask } from 'react-icons/bi'
import { BsHouseDoor, BsPieChart } from 'react-icons/bs'
import { CgSmartphoneChip } from 'react-icons/cg'
import {
  FaAdjust,
  FaBriefcase,
  FaBug,
  FaCaretDown,
  FaChartLine,
  FaCircle,
  FaCog,
  FaLaptop,
  FaLink,
  FaMoon,
  FaPaperPlane,
  FaPizzaSlice,
  FaQuestionCircle,
  FaSun,
  FaTimes,
  FaUser,
  FaUsers,
} from 'react-icons/fa'
import { FiFlag, FiLink2 } from 'react-icons/fi'
import { GiSkeletonKey } from 'react-icons/gi'
import { GoProject } from 'react-icons/go'
import {
  HiLightBulb,
  HiOutlineDocument,
  HiOutlineDocumentDuplicate,
} from 'react-icons/hi'
import { ImBullhorn, ImMakeGroup } from 'react-icons/im'
import { IoIosColorFilter } from 'react-icons/io'
import { IoLogoGoogle } from 'react-icons/io5'
import { MdOutlineGroupWork, MdOutlineTravelExplore } from 'react-icons/md'
import {
  TbChartBubbleFilled,
  TbLayoutSidebarLeftCollapse,
  TbLayoutSidebarLeftExpand,
} from 'react-icons/tb'
import { twMerge } from 'tailwind-merge'
import { RewardStatusEnum } from '../../openapi'

import { performanceByKeywordDashboard } from '../containers/Dashboards/PerformanceByKeyword'
import { performanceByKeywordGroupDashboard } from '../containers/Dashboards/PerformanceByKeywordGroup'
import { performanceBySegmentDashboard } from '../containers/Dashboards/PerformanceBySegment'
import { performanceByUrlDashboard } from '../containers/Dashboards/PerformanceByUrl'
import { performanceOverviewDashboard } from '../containers/Dashboards/PerformanceOverview'
import { performanceSingleKeywordDashboard } from '../containers/Dashboards/PerformanceSingleKeyword'
import { competitiveShareOfVoiceDashboard } from '../containers/Dashboards/ShareOfVoice'
import { useProfileQuery } from '../hooks/profile'
import { useRewardsQuery } from '../hooks/rewards'
import useLocalState from '../hooks/useLocalState'
import useRect from '../hooks/useRect'
import useZustand from '../hooks/useZustand'
import { useActiveWorkspace } from '../hooks/workspaces'
import { getEmailGravatar } from '../utils'
import { useThemeMode } from '../utils/Theme'
import Clickable, { ClickablePlain } from './Clickable'
import Link from './Link'
import Loader from './Loader'
import { openQuickPanel } from './QuickPanel'
import Select from './Select'
import Tooltip from './Tooltip'
import useConfirm from '../hooks/useConfirm'
import { useDelayedMemo } from '../hooks/useDelayedMemo'
import useClickOutside from '../hooks/useClickOutside'
import { Tag } from './Tags'
import { reputationKeywordDashboard } from '../containers/Dashboards/ReputationSingleKeyword'
import { toggleDevtools } from '../containers/Root'

type SidebarItem = {
  label: string
  icon?: any
  badge?: 'comingSoon' | 'alpha' | 'beta' | 'new'
  to?: string
  search?:
    | LocationGenerics['Search']
    | ((search: LocationGenerics['Search']) => LocationGenerics['Search'])
  onClick?: () => void
  adminOnly?: boolean
  exact?: boolean
}

type SidebarGroup = {
  id?: string
  label: any
  items: SidebarItem[]
  adminOnly?: boolean
}

const activeClassNames = `
  [&.active]:opacity-100
  [&.active]:bg-gray-100
  dark:[&.active]:bg-gray-800
  hover:opacity-100
  hover:bg-gray-100
  dark:hover:bg-gray-800
  
`

export function useSidebarItems(): SidebarGroup[] {
  // const workspace = useActiveWorkspace()
  const profileQuery = useProfileQuery()
  const isAdmin = profileQuery.data?.isAdmin
  const [, setStore] = useZustand()

  const groups = React.useMemo(
    (): SidebarGroup[] => [
      {
        label: 'Workspace',
        items: [
          {
            label: 'Projects',
            to: `/projects`,
            icon: <GoProject />,
          },
          {
            label: 'Monitored URLs',
            to: `/monitored-urls`,
            icon: <FaLink />,
            adminOnly: true,
            badge: 'alpha',
          },
          {
            label: 'Topics',
            to: `/topics`,
            icon: <TbChartBubbleFilled />,
            adminOnly: true,
            badge: 'alpha',
          },
          {
            label: 'Keyword Manager',
            to: `/keyword-manager`,
            icon: <HiOutlineDocumentDuplicate />,
          },
          {
            label: 'Brands',
            to: `/brands`,
            icon: <FaBriefcase />,
          },
          {
            label: 'Segments',
            to: `/segments`,
            icon: <FaPizzaSlice />,
          },
          {
            label: 'Users',
            to: `/users`,
            icon: <FaUsers />,
          },
          {
            label: `Jobs & Tasks`,
            icon: <BiTask />,
            onClick: () =>
              setStore(old => {
                old.showJobs = true
              }),
            // <button
            //   type="button"
            //   onClick={
            //   }
            //   className={twMerge(
            //     `w-8 h-8 mr-2 rounded-full shadow-md bg-blue-800 flex items-center justify-center
            //       hover:bg-blue-500`,
            //     !pendingJobsCount && downloadableJobsCount && `bg-green-500`
            //   )}
            // >
            //   {pendingJobsCount ? (
            //     pendingJobsCount
            //   ) : downloadableJobsCount ? (
            //     downloadableJobsCount
            //   ) : (
            //     <BiTask />
            //   )}
            //   <ImSpinner2
            //     className={twMerge(
            //       `absolute text-[2.3rem] text-yellow-400 transition duration-500`,
            //       pendingJobsCount ? `animate-spin opacity-100` : `opacity-0`
            //     )}
            //   />
            // </button>
          },
        ],
      },
      {
        label: 'Performance',
        items: [
          ...(
            [
              {
                dashboard: performanceOverviewDashboard,
                icon: <BsHouseDoor />,
              },
              {
                dashboard: performanceByKeywordGroupDashboard,
                icon: <MdOutlineGroupWork />,
              },
              {
                dashboard: performanceBySegmentDashboard,
                icon: <IoIosColorFilter />,
              },
              {
                dashboard: performanceByUrlDashboard,
                icon: <FiLink2 />,
              },
              {
                dashboard: performanceByKeywordDashboard,
                icon: <HiOutlineDocumentDuplicate />,
              },
              {
                dashboard: performanceSingleKeywordDashboard,
                icon: <HiOutlineDocument />,
              },
            ] as const
          ).map(({ dashboard, icon }) => {
            return {
              label: dashboard.name,
              badge: dashboard.status,
              icon,
              to: `/dashboards/${dashboard.id}`,
              search: dashboard.getSearch,
            }
          }),
          {
            label: 'Google Search Console',
            to: `/dashboards/google-search-console`,
            icon: <IoLogoGoogle />,
          },
        ],
      },
      {
        label: 'Competitive Analysis',
        items: (
          [
            {
              dashboard: competitiveShareOfVoiceDashboard,
              icon: <BsPieChart />,
            },
          ] as const
        ).map(({ dashboard, icon }) => {
          return {
            label: dashboard.name,
            badge: dashboard.status,
            to: `/dashboards/${dashboard.id}`,
            search: dashboard.getSearch,
            icon,
          }
        }),
      },
      {
        label: 'Reputation Management',
        items: (
          [
            {
              dashboard: reputationKeywordDashboard,
              icon: <FaChartLine />,
            },
          ] as const
        ).map(({ dashboard, icon }) => {
          return {
            label: dashboard.name,
            badge: 'new',
            to: `/dashboards/${dashboard.id}`,
            search: dashboard.getSearch,
            icon,
          }
        }),
      },
      // {
      //   label: 'SERP Features',
      //   items: (
      //     [
      //       // {
      //       //   label: 'Overview',
      //       //   comingSoon: 'https://feedback.nozzle.io/boards/feature-requests/',
      //       // },
      //       { dashboard: serpFeaturedSnippetsDashboard, icon: <FiFlag /> },
      //     ] as const
      //   ).map(({ dashboard, icon }) => {
      //     return {
      //       label: dashboard.name,
      //       badge: dashboard.status,
      //       to: `/dashboards/${dashboard.id}`,
      //       icon,
      //     }
      //   }),
      // },
      {
        label: 'Keyword Research',
        items: [
          {
            label: 'Topic Clusters',
            to: `/keyword-research/topic-clusters`,
            icon: <ImMakeGroup />,
            badge: 'new',
          },
          // {
          //   label: 'Keyword Explorer',
          //   badge: 'alpha',
          //   to: `/dashboards/${serpAiDashboardAlpha.id}`,
          //   icon: <CgSmartphoneChip />,
          //   adminOnly: true,
          // },
          // {
          //   label: 'URL Explorer',
          //   to: `/keyword-research/url-explorer`,
          //   icon: <MdOutlineTravelExplore />,
          //   badge: 'alpha',
          // },
        ],
      },
    ],
    [setStore]
  )

  return React.useMemo(
    () =>
      groups
        .filter(group => {
          if (group.adminOnly && !isAdmin) {
            return false
          }
          return true
        })
        .map(group => {
          return {
            ...group,
            items: group.items.filter(item => {
              if (item.adminOnly && !isAdmin) {
                return false
              }
              return true
            }),
          }
        }),
    [groups, isAdmin]
  )
}

const useAdminItems = (): SidebarItem[] => {
  const profileQuery = useProfileQuery()

  const rewardsQuery = useRewardsQuery()
  const hasRewards = rewardsQuery.data?.filter(
    reward => reward.status === RewardStatusEnum.Approved
  )

  // @ts-expect-error  // Type '({ label: string; icon: Element; to: string;... Remove this comment to see the full error message
  return [
    {
      label: 'Invite Users',
      icon: <FaPaperPlane />,
      to: `/invite`,
    },
    {
      label: 'Billing',
      icon: <BiCreditCard />,
      to: `/billing`,
    },
    {
      label: 'Workspace Settings',
      to: `/settings`,
      icon: <FaCog />,
    },
    {
      label: 'Workspaces',
      icon: <FaLaptop />,
      to: `/workspaces`,
      exact: true,
    },
    profileQuery.data?.isAdmin
      ? {
          label: 'Admin',
          icon: <GiSkeletonKey />,
          to: '/admin',
        }
      : null,
    profileQuery.data?.isAdmin
      ? {
          label: 'Toggle Devtools',
          icon: <FaBug />,
          onClick: () => {
            toggleDevtools()
          },
        }
      : null,
    hasRewards?.length
      ? {
          label: 'Rewards',
          icon: <AiOutlineGift />,
          to: `/rewards`,
        }
      : null,

    {
      label: (
        <button data-sleek-changelog data-badge-changelog>
          Feedback
        </button>
      ),
      icon: <ImBullhorn />,
    },
  ].filter(Boolean)
}

const linkClasses =
  'opacity-80 flex gap-2 items-center py-1 px-2 rounded-lg !text-black dark:!text-white'

export function Sidebar(props: { children: any }) {
  const { children } = props

  const [, setStore] = useZustand(state => state.lastWorkspaceId)
  const workspace = useActiveWorkspace()
  const profileQuery = useProfileQuery()
  const gravatarImage = getEmailGravatar(profileQuery.data?.email)
  const adminItems = useAdminItems()
  const sidebarItems = useSidebarItems()
  const { preThemeMode, setThemeMode } = useThemeMode()
  const navigate = useNavigate()
  const confirm = useConfirm()
  const [showDemoLink, setShowDemoLink] = useLocalState('showDemoLink', true)

  const [isExpanded, setIsExpanded] = useLocalState('sidebarIsExpanded', true)
  const [isHovered, setIsHovered] = React.useState(false)
  const isOpen = isExpanded || isHovered
  const delayedIsOpen = useDelayedMemo(isOpen, 500)

  const [innerRef, setInnerRef] = React.useState<HTMLDivElement>(null!)
  const innerRect = useRect(innerRef)

  const [adminMenuExpanded, setAdminMenuExpanded] = React.useState(false)

  const smallWidth = 42
  const transitionClasses = twMerge(
    `transition-all`,
    isHovered
      ? 'duration-[250ms] ease-out-expo'
      : 'duration-[350ms] ease-in-out-expo'
  )

  const timeoutRef = React.useRef<any>(null)

  const workspaceOptions = React.useMemo(
    () =>
      [
        ...(profileQuery.data?.userWorkspaces || []).map(userWorkspace => ({
          label: `${userWorkspace.workspace.name} (${userWorkspace.workspace.slug})`,
          value: userWorkspace.workspace.id,
        })),
      ].filter(Boolean),
    [profileQuery.data?.userWorkspaces]
  )

  const elRef = React.useRef<HTMLDivElement>(null!)

  useClickOutside(elRef, () => {
    setIsHovered(false)
  })

  return (
    <div className="flex min-h-0 flex-1">
      <div
        ref={elRef}
        className={twMerge('relative z-[9999] ', transitionClasses)}
        style={{
          width: isExpanded ? innerRect.width : smallWidth,
        }}
        onPointerEnter={() => {
          clearTimeout(timeoutRef.current)
          setIsHovered(true)
        }}
        onPointerLeave={e => {
          if (e.pointerType === 'mouse') {
            timeoutRef.current = setTimeout(() => {
              setIsHovered(false)
              setAdminMenuExpanded(false)
            }, 200)
          }
        }}
      >
        <div
          className={twMerge(
            'absolute inset-0 overflow-hidden',
            transitionClasses,
            'bg-white text-black shadow-xl dark:border-r dark:border-gray-800 dark:bg-gray-900 dark:text-white',
            delayedIsOpen ? 'pointer-events-auto' : 'pointer-events-none'
          )}
          style={{
            width: isOpen ? innerRect.width : smallWidth,
          }}
        >
          <div
            ref={setInnerRef as any}
            className="absolute inset-0 flex h-full min-w-fit flex-col"
          >
            <div className="flex flex-wrap items-center bg-gradient-to-r from-blue-550 to-blue-650 text-white">
              <div className="flex w-full items-center justify-between">
                <div className="flex items-center gap-2">
                  <Link
                    to="/"
                    className={twMerge(
                      'flex items-center justify-center',
                      transitionClasses
                    )}
                    style={{
                      padding: isOpen ? '10px' : '8px',
                    }}
                  >
                    <img
                      src="/img/nozzle-square-white.png"
                      alt="Nozzle Logo"
                      className={twMerge(transitionClasses, 'w-[25px]')}
                    />
                  </Link>
                  <Link
                    to="/"
                    className={twMerge(
                      '-ml-1 mr-1 mt-[2px] whitespace-nowrap text-lg font-normal leading-none text-white dark:text-white',
                      'font-light',
                      transitionClasses,
                      isOpen ? 'opacity-100' : 'opacity-0'
                    )}
                  >
                    Nozzle
                  </Link>
                </div>
                <div className="flex items-center pr-2">
                  <Tooltip
                    tooltip={
                      preThemeMode === 'light'
                        ? 'Theme: Light'
                        : preThemeMode === 'dark'
                        ? 'Theme: Dark'
                        : 'Theme: System/Auto'
                    }
                  >
                    <ClickablePlain
                      onClick={() =>
                        setThemeMode((old: any) =>
                          old === 'dark'
                            ? 'auto'
                            : old === 'auto'
                            ? 'light'
                            : 'dark'
                        )
                      }
                      className="p-2 opacity-50 transition duration-100 hover:opacity-100"
                    >
                      {preThemeMode === 'light' ? (
                        <FaSun />
                      ) : preThemeMode === 'dark' ? (
                        <FaMoon />
                      ) : (
                        <FaAdjust />
                      )}
                    </ClickablePlain>
                  </Tooltip>
                  <Tooltip tooltip="News & Feedback">
                    <ClickablePlain
                      data-sleek-changelog
                      data-badge-changelog
                      className="p-2 opacity-50 transition duration-100 hover:opacity-100"
                    >
                      <ImBullhorn />
                    </ClickablePlain>
                  </Tooltip>
                  <Tooltip tooltip="Show Help">
                    <ClickablePlain
                      onClick={() => {
                        setStore(old => {
                          old.showHelp = !old.showHelp
                        })
                      }}
                      className="p-2 opacity-50 transition duration-100 hover:opacity-100"
                    >
                      <FaQuestionCircle />
                    </ClickablePlain>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div
              className={twMerge(
                'overflow-y-auto overflow-x-hidden whitespace-nowrap',
                isOpen ? 'space-y-4 py-4' : 'space-y-0 py-2',
                transitionClasses
              )}
            >
              <div
                className={twMerge(
                  'flex items-center gap-2',
                  transitionClasses,
                  isOpen ? 'px-4' : 'px-2 pr-6'
                )}
              >
                <Tooltip
                  className="flex w-full"
                  tooltip={
                    <div className="flex items-center gap-2  text-sm">
                      <Tag compact>
                        <code>⌘K</code>
                      </Tag>
                      <span className="opacity-50">or</span>
                      <Tag compact>
                        <code>Ctrl+Shift+K</code>
                      </Tag>
                    </div>
                  }
                >
                  <button
                    type="button"
                    className={twMerge(
                      `flex w-full
                    flex-1 cursor-text
                    items-center gap-2
                    rounded-md px-2 py-1
                    text-gray-700 dark:text-gray-300`,
                      isOpen &&
                        'bg-white shadow-md shadow-gray-200 dark:bg-gray-800 dark:shadow-none',
                      transitionClasses
                    )}
                    onClick={() => openQuickPanel()}
                  >
                    <BiSearch className="inline" />
                    <span
                      className={twMerge(
                        'text-sm',
                        isOpen ? 'opacity-100' : 'opacity-0',
                        transitionClasses
                      )}
                    >
                      Search...
                    </span>
                  </button>
                </Tooltip>
                <Tooltip
                  tooltip={isExpanded ? 'Collapse Sidebar' : 'Expand Sidebar'}
                  className="flex items-center justify-center"
                >
                  <button
                    className={twMerge(
                      'bg-white p-1 text-xl text-gray-600 hover:opacity-100 dark:bg-gray-800',
                      'hover:bg-blue-500 hover:text-white',
                      'rounded-md shadow-md shadow-gray-200 dark:shadow-none'
                    )}
                    onClick={() => setIsExpanded(prev => !prev)}
                  >
                    {isExpanded ? (
                      <TbLayoutSidebarLeftCollapse />
                    ) : (
                      <TbLayoutSidebarLeftExpand />
                    )}
                  </button>
                </Tooltip>
              </div>
              <div
                className={twMerge(
                  'flex items-center justify-between pt-1',
                  transitionClasses,
                  isOpen ? 'px-4' : 'px-2 pr-6'
                )}
              >
                <Select
                  options={workspaceOptions}
                  onChange={workspaceId => {
                    navigate({
                      search: prev => ({
                        ...prev,
                        workspace: undefined,
                        workspaceSlug: undefined,
                        workspaceId,
                      }),
                    })
                  }}
                >
                  {({ onClick }: any) => {
                    return (
                      <Clickable
                        onClick={onClick}
                        className={twMerge(
                          'flex items-center gap-1 text-xl font-bold !text-black dark:!text-white'
                        )}
                      >
                        <div>
                          {workspace?.name ? (
                            <div className={twMerge('relative flex')}>
                              <div
                                className={twMerge(
                                  'absolute left-0 top-1/2 -translate-y-1/2 text-sm',
                                  isOpen
                                    ? 'opacity-0'
                                    : 'mx-2 flex w-[14px] items-center justify-center text-center uppercase opacity-100',
                                  transitionClasses
                                )}
                              >
                                {workspace?.name.slice(0, 1)}
                              </div>
                              <div
                                className={twMerge(
                                  'max-w-[175px] overflow-hidden overflow-ellipsis',
                                  isOpen
                                    ? 'opacity-100'
                                    : 'ml-2 text-sm opacity-0',
                                  transitionClasses
                                )}
                              >
                                {workspace?.name}
                              </div>
                            </div>
                          ) : (
                            <Loader />
                          )}
                        </div>
                        <FaCaretDown className="opacity-30" />
                      </Clickable>
                    )
                  }}
                </Select>
              </div>
              {sidebarItems.map(group => {
                return (
                  <div
                    key={
                      typeof group.label == 'string' ? group.label : group.id
                    }
                    className={twMerge(
                      'space-y-2',
                      transitionClasses,
                      isOpen ? 'px-4' : 'px-2 pr-6'
                    )}
                  >
                    <div
                      className={twMerge(
                        'text-xs font-bold uppercase opacity-50',
                        isOpen ? 'h-[14px] opacity-50' : 'h-0 opacity-0',
                        transitionClasses
                      )}
                    >
                      {group.label}
                    </div>
                    <div className={twMerge('space-y-1')}>
                      {group.items.map(item => {
                        const linkContent = (
                          <div className="flex w-full items-center justify-between gap-4">
                            <div className="flex items-center gap-2">
                              <div className="opacity-70 [.active_&]:text-blue-400 [.active_&]:opacity-100 dark:[.active_&]:text-[#00afeb]">
                                {item.icon || (
                                  <FaCircle className="scale-50 transform" />
                                )}
                              </div>
                              <div
                                className={twMerge(
                                  'text-sm',
                                  isOpen ? 'opacity-100' : 'opacity-0',
                                  transitionClasses
                                )}
                              >
                                {item.label}
                              </div>
                            </div>
                            {item.badge === 'alpha' ? (
                              <div className="rounded-md border border-purple-500 bg-white px-1.5 text-[.7rem] font-bold uppercase text-purple-500 dark:bg-gray-900">
                                Alpha
                              </div>
                            ) : item.badge === 'beta' ? (
                              <div className="rounded-md border border-cyan-500 bg-white px-1.5 text-[.7rem] font-bold uppercase text-cyan-500 dark:bg-gray-900">
                                Beta
                              </div>
                            ) : item.badge === 'new' ? (
                              <div className="rounded-md border border-green-500 bg-white px-1.5 text-[.7rem] font-bold uppercase text-green-500 dark:bg-gray-900">
                                New
                              </div>
                            ) : item.badge === 'comingSoon' ? (
                              <div className="rounded-md border border-white bg-white px-1.5 text-[.7rem] uppercase text-white dark:bg-gray-900">
                                Soon
                              </div>
                            ) : null}
                          </div>
                        )

                        return (
                          <div
                            key={item.label}
                            className={twMerge('w-full', transitionClasses)}
                          >
                            {!item.onClick ? (
                              <Link
                                className={twMerge(
                                  'flex items-center gap-2 rounded-lg px-2 py-1 !text-black opacity-80 dark:!text-white',
                                  activeClassNames
                                )}
                                to={item.to}
                                params={undefined as any}
                                search={(item.search as any) || {}}
                                activeOptions={{
                                  exact: false,
                                  includeSearch: false,
                                }}
                              >
                                {linkContent}
                              </Link>
                            ) : (
                              <Clickable
                                className={twMerge(
                                  'flex w-full items-center gap-2 rounded-lg px-2 py-1 font-bold !text-black opacity-80 dark:!text-white',
                                  activeClassNames
                                )}
                                onClick={() => item.onClick?.()}
                              >
                                {linkContent}
                              </Clickable>
                            )}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
            </div>
            <div className={twMerge('mt-auto', 'transform')}>
              {showDemoLink ? (
                <div
                  className={twMerge(
                    'flex w-full items-center gap-1 bg-gradient-to-r from-green-600 to-green-400 px-3 font-medium text-white',
                    transitionClasses
                    // isOpen ? 'px-4' : 'pr-6'
                  )}
                >
                  <HiLightBulb className="h-12 w-6 animate-pulse py-3 text-lg text-yellow-100 " />
                  <a
                    href="https://meetings.hubspot.com/b-norwood/nozzle-demo"
                    target="_blank"
                    rel="noreferrer"
                    className={twMerge(
                      'flex-1 animate-pulse py-3',
                      transitionClasses,
                      isOpen ? 'opacity-100' : '!opacity-0'
                    )}
                  >
                    Schedule a Demo
                  </a>
                  <Clickable
                    onClick={async (e: Event) => {
                      e.stopPropagation()
                      e.preventDefault()
                      if (
                        await confirm({
                          title: 'Are you sure?',
                          message: `You can always schedule a demo any time you'd like via our help desk chat.`,
                        })
                      ) {
                        setShowDemoLink(false)
                      }
                    }}
                    className="ml-auto p-3 text-white"
                  >
                    <FaTimes />
                  </Clickable>
                </div>
              ) : null}
              <Clickable
                onClick={() => setAdminMenuExpanded(prev => !prev)}
                className={twMerge(
                  'flex w-full items-center gap-2 px-4 py-2 text-sm font-bold text-black opacity-70 hover:bg-gray-500/10 hover:opacity-100 dark:text-white',
                  'border-y border-gray-100 dark:border-gray-800',
                  transitionClasses
                  // isOpen ? 'px-4' : 'pr-6'
                )}
              >
                <FaCog />
                <div
                  className={twMerge(
                    transitionClasses,
                    isOpen ? 'opacity-100' : 'opacity-0'
                  )}
                >
                  Settings
                </div>
              </Clickable>
              <div
                className={twMerge(
                  'overflow-hidden',
                  transitionClasses,
                  adminMenuExpanded ? 'h-[210px] py-4' : 'm-0 h-0 py-0'
                )}
              >
                <div className={twMerge('space-y-1 px-4')}>
                  {adminItems.map(item => {
                    const content = (
                      <div className="flex w-full items-center justify-between gap-4">
                        <div className="flex items-center gap-2">
                          <div className="opacity-70 [.active_&]:text-blue-400 [.active_&]:opacity-100 dark:[.active_&]:text-[#00afeb]">
                            {item.icon || (
                              <FaCircle className="scale-50 transform" />
                            )}
                          </div>
                          <div
                            className={twMerge(
                              'text-sm',
                              isOpen ? 'opacity-100' : 'opacity-0',
                              transitionClasses
                            )}
                          >
                            {item.label}
                          </div>
                        </div>
                      </div>
                    )

                    return (
                      <div
                        key={item.label}
                        className={twMerge('w-full', transitionClasses)}
                      >
                        {item.onClick ? (
                          <button
                            className={twMerge(
                              'font-bold',
                              linkClasses,
                              activeClassNames
                            )}
                            onClick={item.onClick}
                          >
                            {content}
                          </button>
                        ) : (
                          <Link
                            className={twMerge(linkClasses, activeClassNames)}
                            to={item.to}
                            search={(item.search as any) || {}}
                            activeOptions={{
                              exact: item.exact,
                            }}
                          >
                            {content}
                          </Link>
                        )}
                      </div>
                    )
                  })}
                </div>
              </div>
              <Link
                to={`/profile`}
                className={twMerge(
                  'flex gap-2',
                  transitionClasses,
                  isOpen ? 'p-4' : 'p-2 pr-6'
                )}
              >
                <div className="relative inline-flex h-8 w-8 items-center justify-center overflow-hidden rounded-full bg-blue-800 shadow-md">
                  <FaUser className="" />
                  <div
                    style={{
                      backgroundImage: `url('${
                        profileQuery.data?.picture !== ''
                          ? profileQuery.data?.picture
                          : gravatarImage
                      }')`,
                    }}
                    className="absolute inset-0 bg-cover bg-center bg-no-repeat"
                  />
                </div>
                <div>
                  <div className="text-sm font-bold">
                    {profileQuery.data?.displayName}
                  </div>
                  <div className="text-xs italic">
                    {profileQuery.data?.email}
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div
        className={twMerge(
          `flex min-w-0 flex-1 flex-col overflow-y-auto overflow-x-hidden`
        )}
      >
        {children}
      </div>
    </div>
  )
}
