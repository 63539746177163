import * as React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import useLoading from './useLoading'
import useToast from './useToast'
import { useActiveWorkspaceId } from './workspaces'
import {
  Project,
  deleteTeam,
  fetchTeam,
  fetchTeams,
  onTeamsChangeSubscription,
  patchTeam,
  postTeam,
} from '../utils/Api'
import { queryKeyTeams } from '../utils/Constants'
import useErrorPopup from './useErrorPopup'
import usePopup from './usePopup'
import { useQuerySubscription } from './useQuerySubscription'
import { useProfileQuery } from './profile'

type Options = {
  id?: string | null
  workspaceId?: string
  enabled?: boolean
}

export function useProject({ id, workspaceId, enabled = true }: Options) {
  const activeWorkspaceId = useActiveWorkspaceId()

  workspaceId = workspaceId ?? activeWorkspaceId

  useQuerySubscription({
    kind: queryKeyTeams,
    label: 'Team',
    subscriber: onTeamsChangeSubscription,
  })

  return useQuery(
    [queryKeyTeams, id, workspaceId],
    // @ts-expect-error  // Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
    () => fetchTeam(id, workspaceId),
    {
      enabled: enabled && !!(workspaceId && id),
    }
  )
}

export function useGetProject() {
  const activeWorkspaceId = useActiveWorkspaceId()
  const queryClient = useQueryClient()

  return React.useCallback(
    ({ id, workspaceId }: Options) => {
      workspaceId = workspaceId ?? activeWorkspaceId

      return queryClient.fetchQuery([queryKeyTeams, id, workspaceId], () =>
        // @ts-expect-error  // Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
        fetchTeam(id, workspaceId)
      )
    },
    [activeWorkspaceId, queryClient]
  )
}

//

export function useProjects(workspaceId?: string) {
  const activeWorkspaceId = useActiveWorkspaceId()
  const profileQuery = useProfileQuery()

  workspaceId = workspaceId ?? activeWorkspaceId

  useQuerySubscription({
    kind: queryKeyTeams,
    label: 'Team',
    subscriber: onTeamsChangeSubscription,
  })

  return useQuery(
    [queryKeyTeams, workspaceId],
    () => fetchTeams(workspaceId!),
    {
      enabled: Boolean(workspaceId && profileQuery.data),
    }
  )
}

export function useProjectOptionsQuery() {
  const query = useProjects()

  const data = React.useMemo(
    () =>
      (query.data || []).map(d => ({ value: d.id, label: d.name, project: d })),
    [query.data]
  )

  return {
    ...query,
    data,
  }
}

//

export function useSaveProject(
  options: {
    silent?: boolean
    workspaceId?: string
  } = {}
) {
  const activeWorkspaceId = useActiveWorkspaceId()
  const toast = useToast()
  const errorPopup = useErrorPopup()
  const [, setLoading] = useLoading()
  const queryClient = useQueryClient()

  const { mutateAsync } = useMutation(
    (newTeam: Project) =>
      patchTeam({
        ...newTeam,
        workspaceId:
          newTeam.workspaceId ?? options?.workspaceId ?? activeWorkspaceId,
      }),
    {
      onMutate: () => {
        setLoading(true)
      },
      onSuccess: async data => {
        if (!options.silent) {
          toast({
            message: 'Project Saved',
            color: 'green-500',
          })
        }
        await queryClient.invalidateQueries(queryKeyTeams)
        queryClient.setQueryData([queryKeyTeams, data.id], data)
      },
      onError: err => {
        console.error(err)
        if (!options.silent) {
          errorPopup('Failed to save project.')
        }
      },
      onSettled: () => {
        setLoading(false)
      },
    }
  )

  return mutateAsync
}

//

export function useCreateProject(options: { silent?: boolean } = {}) {
  const toast = useToast()
  const errorPopup = useErrorPopup()
  const [, setLoading] = useLoading()
  const queryClient = useQueryClient()
  const popup = usePopup()

  const { mutateAsync } = useMutation(postTeam, {
    onMutate: () => {
      setLoading(true)
    },
    onSuccess: async () => {
      if (!options.silent) {
        toast({
          message: 'Project Created',
          color: 'green-500',
        })
      }
      await queryClient.invalidateQueries(queryKeyTeams)
    },
    onError: async (err: any) => {
      console.error(err)
      if (!options.silent) {
        if (err.response?.status === 409) {
          await popup({
            color: 'red-500',
            title: 'Project Already Exists!',
            message:
              'Failed to Create Project. Please try a different Project Name/Project Slug.',
          })
        } else {
          errorPopup('Failed to create project.')
        }
      }
    },
    onSettled: () => {
      setLoading(false)
    },
  })

  return mutateAsync
}

export function useRemoveProjectById() {
  const toast = useToast()
  const errorPopup = useErrorPopup()
  const queryClient = useQueryClient()

  const { mutateAsync } = useMutation(deleteTeam, {
    onSuccess: async () => {
      toast({
        message: 'Project Removed',
        color: 'green-500',
      })
      await queryClient.invalidateQueries(queryKeyTeams)
    },
    onError: err => {
      console.error(err)
      errorPopup('Failed to remove project.')
    },
  })

  return mutateAsync
}
