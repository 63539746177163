import { CustomerJourneyStagePb } from '../utils/proto'

export const customerJourneyStageLabels = {
  [CustomerJourneyStagePb.UNSPECIFIED]: 'Unspecified',
  [CustomerJourneyStagePb.AWARENESS]: 'Awareness',
  [CustomerJourneyStagePb.CONSIDERATION]: 'Consideration',
  [CustomerJourneyStagePb.CONVERSION]: 'Conversion',
  [CustomerJourneyStagePb.RETENTION]: 'Retention',
  [CustomerJourneyStagePb.ADVOCACY]: 'Advocacy',
}

export const customerJourneyStageOptions = [
  {
    value: CustomerJourneyStagePb.UNSPECIFIED,
    label: customerJourneyStageLabels[CustomerJourneyStagePb.UNSPECIFIED],
  },
  {
    value: CustomerJourneyStagePb.AWARENESS,
    label: customerJourneyStageLabels[CustomerJourneyStagePb.AWARENESS],
  },
  {
    value: CustomerJourneyStagePb.CONSIDERATION,
    label: customerJourneyStageLabels[CustomerJourneyStagePb.CONSIDERATION],
  },
  {
    value: CustomerJourneyStagePb.CONVERSION,
    label: customerJourneyStageLabels[CustomerJourneyStagePb.CONVERSION],
  },
  {
    value: CustomerJourneyStagePb.RETENTION,
    label: customerJourneyStageLabels[CustomerJourneyStagePb.RETENTION],
  },
  {
    value: CustomerJourneyStagePb.ADVOCACY,
    label: customerJourneyStageLabels[CustomerJourneyStagePb.ADVOCACY],
  },
]

export function formatCustomerJourneyStage(value: CustomerJourneyStagePb) {
  return customerJourneyStageLabels[value]
}
