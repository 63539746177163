// @generated by protoc-gen-es v1.3.0 with parameter "target=ts"
// @generated from file nozzle/billing/v1/plan.proto (package nozzle.billing.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { ProductFamily, UsageType } from "./usage_pb.js";
import { Money } from "../../../google/type/money_pb.js";

/**
 * Period is the billing period for the subscription.
 *
 * @generated from enum nozzle.billing.v1.Period
 */
export enum Period {
  /**
   * @generated from enum value: PERIOD_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: PERIOD_MONTHLY = 1;
   */
  MONTHLY = 1,

  /**
   * @generated from enum value: PERIOD_YEARLY = 2;
   */
  YEARLY = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(Period)
proto3.util.setEnumType(Period, "nozzle.billing.v1.Period", [
  { no: 0, name: "PERIOD_UNSPECIFIED" },
  { no: 1, name: "PERIOD_MONTHLY" },
  { no: 2, name: "PERIOD_YEARLY" },
]);

/**
 * ListPlansRequest is the request for ListPlans.
 *
 * @generated from message nozzle.billing.v1.ListPlansRequest
 */
export class ListPlansRequest extends Message<ListPlansRequest> {
  /**
   * @generated from field: bool include_custom = 1;
   */
  includeCustom = false;

  constructor(data?: PartialMessage<ListPlansRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.ListPlansRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "include_custom", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListPlansRequest {
    return new ListPlansRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListPlansRequest {
    return new ListPlansRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListPlansRequest {
    return new ListPlansRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListPlansRequest | PlainMessage<ListPlansRequest> | undefined, b: ListPlansRequest | PlainMessage<ListPlansRequest> | undefined): boolean {
    return proto3.util.equals(ListPlansRequest, a, b);
  }
}

/**
 * ListPlansResponse is the response for ListPlans.
 *
 * @generated from message nozzle.billing.v1.ListPlansResponse
 */
export class ListPlansResponse extends Message<ListPlansResponse> {
  /**
   * @generated from field: repeated nozzle.billing.v1.Plan plans = 1;
   */
  plans: Plan[] = [];

  constructor(data?: PartialMessage<ListPlansResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.ListPlansResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "plans", kind: "message", T: Plan, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListPlansResponse {
    return new ListPlansResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListPlansResponse {
    return new ListPlansResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListPlansResponse {
    return new ListPlansResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListPlansResponse | PlainMessage<ListPlansResponse> | undefined, b: ListPlansResponse | PlainMessage<ListPlansResponse> | undefined): boolean {
    return proto3.util.equals(ListPlansResponse, a, b);
  }
}

/**
 * ListPublicPlansRequest is the request for ListPublicPlans.
 *
 * @generated from message nozzle.billing.v1.ListPublicPlansRequest
 */
export class ListPublicPlansRequest extends Message<ListPublicPlansRequest> {
  constructor(data?: PartialMessage<ListPublicPlansRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.ListPublicPlansRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListPublicPlansRequest {
    return new ListPublicPlansRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListPublicPlansRequest {
    return new ListPublicPlansRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListPublicPlansRequest {
    return new ListPublicPlansRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListPublicPlansRequest | PlainMessage<ListPublicPlansRequest> | undefined, b: ListPublicPlansRequest | PlainMessage<ListPublicPlansRequest> | undefined): boolean {
    return proto3.util.equals(ListPublicPlansRequest, a, b);
  }
}

/**
 * ListPublicPlansResponse is the response for ListPublicPlans.
 *
 * @generated from message nozzle.billing.v1.ListPublicPlansResponse
 */
export class ListPublicPlansResponse extends Message<ListPublicPlansResponse> {
  /**
   * @generated from field: repeated nozzle.billing.v1.Plan plans = 1;
   */
  plans: Plan[] = [];

  constructor(data?: PartialMessage<ListPublicPlansResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.ListPublicPlansResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "plans", kind: "message", T: Plan, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListPublicPlansResponse {
    return new ListPublicPlansResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListPublicPlansResponse {
    return new ListPublicPlansResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListPublicPlansResponse {
    return new ListPublicPlansResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListPublicPlansResponse | PlainMessage<ListPublicPlansResponse> | undefined, b: ListPublicPlansResponse | PlainMessage<ListPublicPlansResponse> | undefined): boolean {
    return proto3.util.equals(ListPublicPlansResponse, a, b);
  }
}

/**
 * UpdatePlanRequest is the request for UpdatePlan.
 *
 * @generated from message nozzle.billing.v1.UpdatePlanRequest
 */
export class UpdatePlanRequest extends Message<UpdatePlanRequest> {
  /**
   * @generated from field: nozzle.billing.v1.Plan plan = 1;
   */
  plan?: Plan;

  /**
   * allow_missing must be true, as only upserts are currently supported
   *
   * @generated from field: bool allow_missing = 2;
   */
  allowMissing = false;

  constructor(data?: PartialMessage<UpdatePlanRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.UpdatePlanRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "plan", kind: "message", T: Plan },
    { no: 2, name: "allow_missing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdatePlanRequest {
    return new UpdatePlanRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdatePlanRequest {
    return new UpdatePlanRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdatePlanRequest {
    return new UpdatePlanRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdatePlanRequest | PlainMessage<UpdatePlanRequest> | undefined, b: UpdatePlanRequest | PlainMessage<UpdatePlanRequest> | undefined): boolean {
    return proto3.util.equals(UpdatePlanRequest, a, b);
  }
}

/**
 * UpdatePlanResponse is the response for UpdatePlan.
 *
 * @generated from message nozzle.billing.v1.UpdatePlanResponse
 */
export class UpdatePlanResponse extends Message<UpdatePlanResponse> {
  constructor(data?: PartialMessage<UpdatePlanResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.UpdatePlanResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdatePlanResponse {
    return new UpdatePlanResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdatePlanResponse {
    return new UpdatePlanResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdatePlanResponse {
    return new UpdatePlanResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdatePlanResponse | PlainMessage<UpdatePlanResponse> | undefined, b: UpdatePlanResponse | PlainMessage<UpdatePlanResponse> | undefined): boolean {
    return proto3.util.equals(UpdatePlanResponse, a, b);
  }
}

/**
 * Plan represents a billing plan.
 *
 * @generated from message nozzle.billing.v1.Plan
 */
export class Plan extends Message<Plan> {
  /**
   * Business-Pro (Item ID)
   *
   * @generated from field: string plan_id = 1;
   */
  planId = "";

  /**
   * Business Pro (Display Name)
   *
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: nozzle.billing.v1.ProductFamily product_family = 3;
   */
  productFamily = ProductFamily.UNSPECIFIED;

  /**
   * one per unique period/overage_period combination
   *
   * @generated from field: repeated nozzle.billing.v1.PeriodPlan period_plans = 4;
   */
  periodPlans: PeriodPlan[] = [];

  constructor(data?: PartialMessage<Plan>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.Plan";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "plan_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "product_family", kind: "enum", T: proto3.getEnumType(ProductFamily) },
    { no: 4, name: "period_plans", kind: "message", T: PeriodPlan, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Plan {
    return new Plan().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Plan {
    return new Plan().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Plan {
    return new Plan().fromJsonString(jsonString, options);
  }

  static equals(a: Plan | PlainMessage<Plan> | undefined, b: Plan | PlainMessage<Plan> | undefined): boolean {
    return proto3.util.equals(Plan, a, b);
  }
}

/**
 * PeriodPlan descriptions the pricing and included quantities for a given billing period.
 *
 * @generated from message nozzle.billing.v1.PeriodPlan
 */
export class PeriodPlan extends Message<PeriodPlan> {
  /**
   * @generated from field: nozzle.billing.v1.Period base_period = 1;
   */
  basePeriod = Period.UNSPECIFIED;

  /**
   * @generated from field: google.type.Money base_price = 2;
   */
  basePrice?: Money;

  /**
   * @generated from field: nozzle.billing.v1.Period overage_period = 3;
   */
  overagePeriod = Period.UNSPECIFIED;

  /**
   * @generated from field: repeated nozzle.billing.v1.MeteredPlan metered_plans = 4;
   */
  meteredPlans: MeteredPlan[] = [];

  constructor(data?: PartialMessage<PeriodPlan>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.PeriodPlan";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "base_period", kind: "enum", T: proto3.getEnumType(Period) },
    { no: 2, name: "base_price", kind: "message", T: Money },
    { no: 3, name: "overage_period", kind: "enum", T: proto3.getEnumType(Period) },
    { no: 4, name: "metered_plans", kind: "message", T: MeteredPlan, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PeriodPlan {
    return new PeriodPlan().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PeriodPlan {
    return new PeriodPlan().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PeriodPlan {
    return new PeriodPlan().fromJsonString(jsonString, options);
  }

  static equals(a: PeriodPlan | PlainMessage<PeriodPlan> | undefined, b: PeriodPlan | PlainMessage<PeriodPlan> | undefined): boolean {
    return proto3.util.equals(PeriodPlan, a, b);
  }
}

/**
 * MeteredPlan describes the pricing and included quantities for a given metered usage.
 *
 * @generated from message nozzle.billing.v1.MeteredPlan
 */
export class MeteredPlan extends Message<MeteredPlan> {
  /**
   * @generated from field: nozzle.billing.v1.UsageType usage_type = 1;
   */
  usageType = UsageType.UNSPECIFIED;

  /**
   * @generated from field: int64 included_quantity = 2;
   */
  includedQuantity = protoInt64.zero;

  /**
   * @generated from field: google.type.Money unit_price = 3;
   */
  unitPrice?: Money;

  constructor(data?: PartialMessage<MeteredPlan>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.MeteredPlan";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "usage_type", kind: "enum", T: proto3.getEnumType(UsageType) },
    { no: 2, name: "included_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "unit_price", kind: "message", T: Money },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MeteredPlan {
    return new MeteredPlan().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MeteredPlan {
    return new MeteredPlan().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MeteredPlan {
    return new MeteredPlan().fromJsonString(jsonString, options);
  }

  static equals(a: MeteredPlan | PlainMessage<MeteredPlan> | undefined, b: MeteredPlan | PlainMessage<MeteredPlan> | undefined): boolean {
    return proto3.util.equals(MeteredPlan, a, b);
  }
}

