// @generated by protoc-gen-connect-web v0.11.0 with parameter "target=ts"
// @generated from file nozzle/billing/v1/plan.proto (package nozzle.billing.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { ListPlansRequest, ListPlansResponse, ListPublicPlansRequest, ListPublicPlansResponse, UpdatePlanRequest, UpdatePlanResponse } from "./plan_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * PlansService provides APIs for interacting with billing plans.
 *
 * @generated from service nozzle.billing.v1.PlansService
 */
export const PlansService = {
  typeName: "nozzle.billing.v1.PlansService",
  methods: {
    /**
     * ListPlans retrieves plans.
     *
     * @generated from rpc nozzle.billing.v1.PlansService.ListPlans
     */
    listPlans: {
      name: "ListPlans",
      I: ListPlansRequest,
      O: ListPlansResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ListPublicPlans retrieves all the default plans.
     *
     * @generated from rpc nozzle.billing.v1.PlansService.ListPublicPlans
     */
    listPublicPlans: {
      name: "ListPublicPlans",
      I: ListPublicPlansRequest,
      O: ListPublicPlansResponse,
      kind: MethodKind.Unary,
    },
    /**
     * UpdatePlan upserts a billing plan.
     *
     * @generated from rpc nozzle.billing.v1.PlansService.UpdatePlan
     */
    updatePlan: {
      name: "UpdatePlan",
      I: UpdatePlanRequest,
      O: UpdatePlanResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

