import * as React from 'react'
import {
  ScheduleConfig,
  formatSchedule,
  scheduleConfigToFullRRule,
} from '../utils/schedules'

export function useMostFrequentScheduleOptions(opts: {
  schedules?: ScheduleConfig[]
}) {
  return React.useMemo(() => {
    return [
      ...(opts.schedules?.map(schedule => ({
        value: scheduleConfigToFullRRule(schedule),
        label: formatSchedule(schedule.id),
      })) || []),
      {
        label: 'Never',
        value: undefined,
      },
    ]
  }, [opts.schedules])
}
