import * as React from 'react'
import { useField } from 'react-form'
import { FaEdit, FaInfoCircle, FaPlus } from 'react-icons/fa'
import CityPhraseTemplatesField from '../components/CityPhraseTemplatesField'
import DevicesField from '../components/DevicesField'
import useModal from '../hooks/useModal'
import { Keyword, KeywordSource } from '../utils/Api'
import { formatNumber } from '../utils/Format'
import Button from './Button'
import Caption from './Caption'
import { DevicesHelp, LocalesHelp } from './KeywordSourceKeywordsBasic'
import Loader from './Loader'
import { LocalesField } from './LocalesField'
import { PhraseGroupsAsTextPhrasesForm } from './PhraseGroupsByPhraseText'
import PhraseGroupsFieldGroups from './PhraseGroupsFieldGroups'
import PhraseGroupsFieldPhrases from './PhraseGroupsFieldPhrases'
import PhraseGroupsModal from './PhraseGroupsModal'
import SchedulesField from './SchedulesField'
import Separator from './Separator'
import { Modal } from './Modals'
import Card from './Card'

//

const defaultConfigValue = {}
const defaultDeviceIds = ['d', 'm']
const defaultLocaleIds = [36430, 38283]
const defaultGlobalLocaleIds = [44249]

export function KeywordSourceCity(props: {
  keywordSource: KeywordSource
  generatedKeywords: Keyword[]
  isGeneratingKeywords: boolean
}) {
  // @ts-expect-error  // Property 'FieldScope' does not exist on type 'Fiel... Remove this comment to see the full error message
  const { FieldScope } = useField('config', {
    defaultValue: defaultConfigValue,
  })

  const [showTextEditor, setShowTextEditor] = React.useState(false)

  const { value: phraseGroups, setValue: setPhraseGroups } = useField(
    'config.phraseGroups',
    {
      defaultValue: [],
    }
  )
  const showModal = useModal()

  return (
    <>
      <div className="grid grid-cols-1 gap-2 xl:grid-cols-2">
        <FieldScope>
          <Card>
            <div className="text-xl font-bold">
              <div>
                Keyword Phrases{' '}
                <Button
                  size="sm"
                  color="blue-500"
                  className="mb-1 mr-1"
                  onClick={() =>
                    showModal(() => (
                      <Modal>
                        <FieldScope>
                          <PhraseGroupsModal />
                        </FieldScope>
                      </Modal>
                    ))
                  }
                >
                  <FaPlus className="inline" />
                  Add Keyword Phrases
                </Button>
              </div>{' '}
              <Caption className="font-normal">
                <FaInfoCircle className="inline" /> This is where you can add
                keyword phrases, edit them, and assign them to keyword groups.
              </Caption>
            </div>

            {showTextEditor ? (
              <PhraseGroupsAsTextPhrasesForm
                phraseGroups={phraseGroups}
                onChange={(newPhraseGroups: any) => {
                  setPhraseGroups(newPhraseGroups)
                  setShowTextEditor(false)
                }}
                onCancel={() => setShowTextEditor(false)}
              />
            ) : (
              <>
                <PhraseGroupsFieldPhrases
                  phraseGroups={phraseGroups}
                  onPhraseGroupsChange={setPhraseGroups}
                />
                <div className="h-2" />
                <Button
                  size="xs"
                  color={['gray-100', 'gray-800']}
                  onClick={() => setShowTextEditor(true)}
                >
                  <FaEdit className="inline" /> Edit as Text
                </Button>
              </>
            )}

            <div className="h-4" />
          </Card>
        </FieldScope>
        <div className="flex flex-1 flex-wrap items-start gap-2">
          <FieldScope>
            <Card className="w-[400] flex-1">
              <div className="text-xl font-bold">
                <div> Devices</div> <DevicesHelp />
              </div>
              <DevicesField
                label=""
                defaultValue={defaultDeviceIds}
                field="devices"
                required
              />
              <div className="h-8" />
            </Card>
            <Card className="w-[400] flex-1">
              <div className="text-xl font-bold">
                <div>Locales</div> <LocalesHelp />
              </div>
              <LocalesField
                label=""
                field="globalLocaleIds"
                defaultValue={defaultGlobalLocaleIds}
                placeholder="Select Locales..."
                required
              />
              <div className="h-8" />
            </Card>
          </FieldScope>
          <Card className="w-[400] flex-1">
            <div className="text-xl font-bold">
              <div>Schedule</div>{' '}
              <Caption className="font-normal">
                <FaInfoCircle className="inline" /> Pick how frequently you want
                new data pulled for your keywords.
              </Caption>
            </div>
            <SchedulesField />
          </Card>
        </div>
      </div>
      <FieldScope>
        <Card>
          <div className="text-xl font-bold">
            <div>Keyword Groups</div>{' '}
            <Caption className="font-normal">
              <FaInfoCircle className="inline" /> The table below can be used to
              manage the keyword phrase groups for this keyword source.
            </Caption>
          </div>
          <PhraseGroupsFieldGroups
            phraseGroups={phraseGroups}
            onPhraseGroupsChange={setPhraseGroups}
          />
        </Card>
      </FieldScope>
      <Card className="text-xl font-bold">
        <div>City Templates</div>{' '}
        <Caption className="font-normal">
          <FaInfoCircle className="inline" /> The phrase templates below will be
          injected with each base city locale selected below to generated a new
          phrase.
        </Caption>
      </Card>
      <FieldScope>
        <Card>
          <CityPhraseTemplatesField
            field="phraseTemplates"
            defaultLocaleIds={defaultLocaleIds}
          />
        </Card>
      </FieldScope>
    </>
  )
}
