import * as React from 'react'
import useLocalState from './useLocalState'

let windowId: number | 'visible'

export function useWindowIsVisible() {
  if (!windowId) {
    windowId = Date.now()
  }

  const [focusedWindowId, setFocusedWindowId] = useLocalState(
    'useWindowIsVisible',
    windowId,
    {
      storage: window.sessionStorage,
    }
  )

  React.useEffect(() => {
    if (windowId === 'visible') {
      if (!isPageHidden()) {
        setFocusedWindowId(windowId)
      }
    }
  })

  React.useEffect(() => {
    const onFocus = () => {
      setFocusedWindowId(windowId)
    }

    const onBlur = () => {
      if (focusedWindowId === windowId) {
        setFocusedWindowId('visible')
      }
    }

    window.addEventListener('focus', onFocus, false)
    window.addEventListener('blur', onBlur, false)

    return () => {
      // Be sure to unsubscribe if a new handler is set
      window.removeEventListener('focus', onFocus)
      window.removeEventListener('blur', onBlur)
    }
  }, [focusedWindowId, setFocusedWindowId])

  return windowId === focusedWindowId
}

function isPageHidden(): boolean {
  return (
    document.hidden ||
    // @ts-expect-error  // Property 'msHidden' does not exist on type 'Docume... Remove this comment to see the full error message
    document.msHidden ||
    // @ts-expect-error  // Property 'webkitHidden' does not exist on type 'Do... Remove this comment to see the full error message
    document.webkitHidden ||
    // @ts-expect-error  // Property 'mozHidden' does not exist on type 'Docum... Remove this comment to see the full error message
    document.mozHidden
  )
}
