import * as React from 'react'
import { splitFormProps, useField } from 'react-form'
import LabeledTextArea from '../components/LabeledTextArea'

//

type Props = {
  onChange?: (e: any) => void
  label?: string
  field?: string
  placeholder?: string
  disabled?: boolean
  rows?: number
  validate?: any
}

export default React.forwardRef(function TextField(
  { onChange, ...props }: Props,
  ref
) {
  const [field, formProps, rest] = splitFormProps(props)

  const {
    setValue,
    value,
    setMeta,
    meta: { error, isTouched, isValidating },
  } = useField(field, formProps)

  return (
    <LabeledTextArea
      ref={ref}
      // @ts-expect-error  // Type '{ label?: string | undefined; field?: string... Remove this comment to see the full error message
      onChange={(e: any) => {
        setValue(e.target.value)
        if (onChange) {
          onChange(e)
        }
      }}
      onBlur={() => setMeta({ isTouched: true })}
      value={value || ''}
      error={isTouched && error}
      isValidating={isValidating}
      {...rest}
    />
  )
})
