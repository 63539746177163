import * as React from 'react'
import { FaClock } from 'react-icons/fa'
import useDateRangePickerUI from '../hooks/useDateRangePickerUI'
import { TimeRangeQuickPicks } from '../utils/TimeRangeQuickPicks'

export default function UsageTimeRange({ timeRanges, setTimeRanges }: any) {
  const value = React.useMemo(() => {
    return [timeRanges.start, timeRanges.end]
  }, [timeRanges])

  const { textValue, getInputProps, Render } = useDateRangePickerUI({
    label: 'Time Ranges',
    value,
    range: true,
    align: 'right',
    onChange: (newValue: any) => {
      setTimeRanges((old: any) => {
        return {
          ...old,
          start: newValue[0],
          end: newValue[1],
        }
      })
    },
    quickPicks: TimeRangeQuickPicks,
  })

  const { onClick } = getInputProps()

  return (
    <>
      <button
        id="timeSelector"
        type="button"
        onClick={onClick}
        className="ml-auto flex cursor-pointer items-center gap-2 rounded-md bg-blue-600 px-3
        py-1 text-sm text-white"
      >
        <FaClock className="inline" />
        <span>{textValue}</span>
      </button>
      <Render />
    </>
  )
}
