import * as React from 'react'
import {
  FaCaretDown,
  FaChartBar,
  FaChartLine,
  FaCheckCircle,
  FaRegCircle,
  FaSort,
  FaSortAmountDown,
  FaSortAmountUpAlt,
} from 'react-icons/fa'
import { FiDownloadCloud } from 'react-icons/fi'
import { ImLoop2 } from 'react-icons/im'
import { twMerge } from 'tailwind-merge'
import Loader from '../components/Loader'
import Select from '../components/Select'
import useModal from '../hooks/useModal'

import { GroupByOverTimeQueryOptions } from '../utils/Api'
import {
  DEFAULT_SAMPLES,
  POST_AGGREGATION_CHANGE,
  POST_AGGREGATION_VALUE,
} from '../utils/Constants'
import {
  MetricId,
  MetricPermutation,
  MetricPostAggregation,
  metricPermutation,
  metricsById,
} from '../utils/Metrics'
import { QueryExportModal } from './QueryExportModal'
import Tooltip from './Tooltip'
import { ButtonPlain } from './ButtonPlain'
import NumberInput from './NumberInput'
import { NumberInputWrap } from './NumberInputWrap'

type Props = {
  setState: any
  metricIds?: MetricId[]
  isLoading?: boolean
  dataQueryOptions?: GroupByOverTimeQueryOptions
  allowExport: boolean
  allowLegends: boolean
  allowMetrics: boolean
  allowSorting: boolean
  allowPostAggregation: boolean
  allowSamples: boolean
} & (
  | { allowLegends: true; showLegends: boolean }
  | { allowLegends: false; showLegends?: never }
) &
  (
    | { allowMetrics: true; metricId: string }
    | { allowMetrics: false; metricId?: never }
  ) &
  (
    | {
        allowSorting: false
        sortByMetricPermutation?: never
        desc?: never
      }
    | {
        allowSorting: true
        sortByMetricPermutation: MetricPermutation<MetricId>
        desc: boolean
      }
  ) &
  (
    | {
        allowPostAggregation: true
        displayPostAggregation: MetricPostAggregation
      }
    | { allowPostAggregation: false; displayPostAggregation?: never }
  ) &
  (
    | {
        allowSamples: false
        samples?: never
      }
    | {
        allowSamples: true
        samples: number
      }
  )

export default function WidgetControls({
  dataQueryOptions,
  sortByMetricPermutation,
  desc,
  setState,
  metricIds,
  metricId,
  displayPostAggregation,
  isLoading,
  samples,
  allowSorting,
  allowMetrics,
  allowPostAggregation,
  allowSamples,
  className,
  showLegends,
  allowLegends,
  allowExport: showExport = true,
  ...rest
}: Props & React.HTMLAttributes<HTMLDivElement>) {
  const resetSamples = () => {
    setTimeout(() => {
      setState((old: any) => ({
        ...old,
        samples: DEFAULT_SAMPLES,
      }))
    }, 100)
  }

  const metricOptions = React.useMemo(() => {
    return (
      metricIds?.map(id => ({ value: id, label: metricsById[id].label })) ?? []
    )
  }, [metricIds])

  const showModal = useModal()

  return (
    <div
      className={twMerge(`flex flex-wrap items-center gap-1`, className)}
      {...rest}
    >
      {metricIds?.length && allowSorting ? (
        <>
          <div className="flex flex-wrap">
            <Select
              options={metricOptions}
              value={sortByMetricPermutation.id}
              onChange={metricId => {
                setState((old: any) => ({
                  ...old,

                  sortByMetricPermutation: metricPermutation(
                    metricId,
                    old.sortByMetricPermutation
                  ),

                  metricId,
                  desc: true,
                }))
              }}
              className="inline-flex"
            >
              {({ onClick }: any) => (
                <Tooltip
                  tooltip={
                    <div>
                      The metric used to <strong>sort</strong> this widget's
                      data.
                    </div>
                  }
                >
                  <ButtonPlain
                    onClick={onClick}
                    className="rounded-r-none bg-gray-100 text-xs dark:bg-gray-700"
                  >
                    <FaSort className="inline" /> Sort by{' '}
                    {metricsById[sortByMetricPermutation.id].label}
                  </ButtonPlain>
                </Tooltip>
              )}
            </Select>
            <Tooltip
              tooltip={
                <div>
                  The post aggregation used to <strong>sort</strong> this
                  widget's data.
                  <div className="h-1" />
                  <hr className="opacity-50" />
                  <div className="h-1" />
                  <small className="opacity-70">
                    <strong>"Value"</strong> sorts by the standard numerical
                    value of the metric. <strong>"Change"</strong> sorts by the
                    total change across your selected time range.
                  </small>
                </div>
              }
            >
              <ButtonPlain
                onClick={() =>
                  setState((old: any) => {
                    const newAggregation =
                      sortByMetricPermutation.postAggregation ===
                      POST_AGGREGATION_CHANGE
                        ? POST_AGGREGATION_VALUE
                        : POST_AGGREGATION_CHANGE

                    return {
                      ...old,
                      sortByMetricPermutation: {
                        ...old.sortByMetricPermutation,
                        postAggregation: newAggregation,
                      },
                      displayPostAggregation: newAggregation,
                    }
                  })
                }
                className="rounded-l-none bg-gray-100 text-xs dark:bg-gray-700"
              >
                {sortByMetricPermutation.postAggregation ===
                POST_AGGREGATION_CHANGE ? (
                  <>
                    <FaChartLine className="inline" /> Change
                  </>
                ) : (
                  <>
                    <FaChartBar className="inline" /> Value
                  </>
                )}
              </ButtonPlain>
            </Tooltip>
          </div>
          <Tooltip
            tooltip={
              <div>
                The <strong>direction</strong> to sort this widget's data.
                <div className="h-1" />
                <hr className="opacity-50" />
                <div className="h-1" />
                <small className="opacity-70">
                  <strong>"Desc"</strong> sorts highest/best values to the top.{' '}
                  <strong>"Asc"</strong> sorts lowest/worst values to the top.
                </small>
              </div>
            }
          >
            <ButtonPlain
              className="bg-gray-100 text-xs dark:bg-gray-700"
              onClick={() =>
                setState((old: any) => {
                  return {
                    ...old,
                    desc: !old.desc,
                    offset: 0,
                  }
                })
              }
            >
              {desc ? (
                <>
                  <FaSortAmountDown className="inline" /> Desc
                </>
              ) : (
                <>
                  <FaSortAmountUpAlt className="inline" /> Asc
                </>
              )}
            </ButtonPlain>
          </Tooltip>
        </>
      ) : null}
      {allowSamples ? (
        <div className="flex items-center justify-center gap-2 rounded-md bg-gray-100 text-xs dark:bg-gray-700">
          <Tooltip
            className="flex items-center justify-center"
            tooltip={
              <div>
                The number of samples to request per query.
                <div className="h-1" />
                <hr className="opacity-50" />
                <div className="h-1" />
                <small className="opacity-70">
                  More samples{' '}
                  <strong>increases the amount of data points shown</strong>{' '}
                  (maxing out at the frequency your data is requested).
                  <div className="h-2" />
                  Fewer samples <strong>increases dashboard performance</strong>
                  .
                </small>
              </div>
            }
          >
            <label
              htmlFor="samples"
              className="flex items-center divide-x divide-gray-500/20"
            >
              <div className="px-2">Samples</div>
              <NumberInputWrap>
                <NumberInput
                  value={samples}
                  enableDraft
                  placeholder={`(30)`}
                  onChange={num => {
                    setState((old: any) => ({
                      ...old,
                      samples: num,
                    }))
                  }}
                  className={twMerge(
                    'm-px w-12 rounded-none border-0 px-1.5 text-xs leading-none'
                  )}
                />
              </NumberInputWrap>
            </label>
          </Tooltip>
          {samples !== DEFAULT_SAMPLES ? (
            <Tooltip
              tooltip={`Reset Samples (${DEFAULT_SAMPLES})`}
              className="flex items-center justify-center"
            >
              <button
                type="button"
                className="pr-2 opacity-50 hover:opacity-100"
                onClick={() => resetSamples()}
              >
                <ImLoop2 />
              </button>
            </Tooltip>
          ) : null}
        </div>
      ) : null}
      {allowMetrics ? (
        <Select
          options={metricOptions}
          // @ts-expect-error  // Type 'string' is not assignable to type '"results"... Remove this comment to see the full error message
          value={metricId}
          onChange={metricId => {
            setState((old: any) => ({
              ...old,
              metricId,
            }))
          }}
          className="inline-flex"
        >
          {({ selectedOption, onClick }: any) => (
            <Tooltip
              tooltip={
                <div>
                  The metric to <strong>"focus"</strong> or{' '}
                  <strong>"highlight"</strong> in this widget.
                </div>
              }
            >
              <ButtonPlain
                className="bg-gray-100 text-xs dark:bg-gray-700"
                onClick={onClick}
              >
                {metricId ? (
                  // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  <>Metric: {metricsById[metricId]?.label ?? 'N/A'}</>
                ) : (
                  'Select a metric...'
                )}{' '}
                <FaCaretDown className="inline" />
              </ButtonPlain>
            </Tooltip>
          )}
        </Select>
      ) : null}
      {allowPostAggregation ? (
        <Tooltip
          tooltip={
            <div>
              The post aggregation <strong>displayed</strong> by this widget.
              <div className="h-1" />
              <hr className="opacity-50" />
              <div className="h-1" />
              <small className="opacity-70">
                While usually the same as the{' '}
                <strong>post aggregation used for sorting</strong>, this can be
                different, e.g.{' '}
                <em>Sorting by "Change" and displaying by "Value"</em>
              </small>
            </div>
          }
        >
          <ButtonPlain
            className="bg-gray-100 text-xs dark:bg-gray-700"
            onClick={() =>
              setState((old: any) => ({
                ...old,

                displayPostAggregation:
                  old.displayPostAggregation === POST_AGGREGATION_VALUE
                    ? POST_AGGREGATION_CHANGE
                    : POST_AGGREGATION_VALUE,

                offset: 0,
              }))
            }
          >
            {displayPostAggregation === POST_AGGREGATION_VALUE ? (
              <>
                <FaRegCircle className="inline" /> Show Change
              </>
            ) : (
              <>
                <FaCheckCircle className="inline-block rounded-full bg-white text-green-500 dark:text-green-500" />{' '}
                Show Change
              </>
            )}
          </ButtonPlain>
        </Tooltip>
      ) : null}
      {allowLegends ? (
        <Tooltip
          tooltip={showLegends ? 'Hide Chart Legends' : 'Show Chart Legends'}
        >
          <ButtonPlain
            className="bg-gray-100 text-xs dark:bg-gray-700"
            onClick={() =>
              setState((old: any) => ({
                ...old,
                showLegends: !old.showLegends,
              }))
            }
          >
            {!showLegends ? (
              <>
                Legends <FaRegCircle className="inline" />
              </>
            ) : (
              <>
                Legends{' '}
                <FaCheckCircle className="inline-block rounded-full bg-white text-green-500 dark:text-green-500" />
              </>
            )}
          </ButtonPlain>
        </Tooltip>
      ) : null}
      {isLoading ? <Loader /> : null}
      {dataQueryOptions && showExport ? (
        <ButtonPlain
          className="bg-gray-100 text-xs hover:bg-green-500 hover:text-white dark:bg-gray-700"
          onClick={() =>
            showModal(() => <QueryExportModal {...{ dataQueryOptions }} />)
          }
        >
          <FiDownloadCloud /> Export Query
        </ButtonPlain>
      ) : null}
    </div>
  )
}
