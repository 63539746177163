import { FaCaretDown, FaCog } from 'react-icons/fa'
import Select from './Select'
import { useProjectOptionsQuery } from '../hooks/projects'
import { ButtonPlain } from './ButtonPlain'
import { useManageProjectOptions } from '../containers/projects/Projects'
import { useActiveProjectIdState } from '../utils/searchParams'

export function ProjectPicker({
  onChange,
}: {
  onChange?: (projectId: string) => void
}) {
  const projectOptionsQuery = useProjectOptionsQuery()
  const activeProjectIdState = useActiveProjectIdState()
  const manageOptions = useManageProjectOptions()

  return (
    <Select
      options={projectOptionsQuery.data}
      value={activeProjectIdState.state}
      onChange={next => {
        activeProjectIdState.setState(next)
        onChange?.(next)
      }}
      children={({ onClick }: any) => {
        return (
          <ButtonPlain
            onClick={onClick}
            className="flex items-center gap-0 divide-x divide-gray-500/20 bg-gray-100 p-0 dark:bg-gray-800"
          >
            <div className="px-2 py-1">Project</div>
            <div className="flex items-center gap-2 px-2 py-1">
              <Select
                options={manageOptions}
                onChange={value => {
                  value(activeProjectIdState.state!)
                }}
                children={({ onClick }) => {
                  return (
                    <FaCog
                      className="text-blue-500"
                      onClick={e => {
                        e.stopPropagation()
                        onClick()
                      }}
                    />
                  )
                }}
              />
              {!activeProjectIdState.state
                ? 'Select...'
                : projectOptionsQuery.data?.find(
                    d => d.value === activeProjectIdState.state
                  )?.label}
            </div>
            <div>
              <FaCaretDown className="mx-1" />
            </div>
          </ButtonPlain>
        )
      }}
    />
  )
}
