import * as React from 'react'
import Link from '../../components/Link'
import { formatNumber, formatRank } from '../../utils/Format'
import { metrics, renderKeyword } from './metrics'
import { ClusterPb } from '../../utils/proto'
import Tooltip from '../../components/Tooltip'
import { useLocalesByIdQuery } from '../../utils/locales'

export function ClusterDetails({ cluster }: { cluster: ClusterPb }) {
  const localesByIdQuery = useLocalesByIdQuery({
    localeIds: cluster.metricsByKeyword.map(d => Number(d.keyword?.localeId)),
  })

  const remainingKeywords = Math.max(
    cluster.keywordCount - cluster.metricsByKeyword.length,
    0
  )

  return (
    <>
      <div className="w-[250px] divide-y divide-gray-500/20">
        <div className="flex items-center justify-between">
          <Tooltip tooltip={metrics.keywordCount.tooltip}>
            <Link
              className="block w-full p-2 text-sm font-bold"
              search={prev => ({
                ...prev,
                clusterId: Number(cluster.clusterId),
                clusterPanel: 'keywords',
              })}
            >
              Keywords ({formatNumber(cluster.keywordCount)})
            </Link>
          </Tooltip>
          <Tooltip
            tooltip={metrics.topRank.tooltip}
            className="whitespace-nowrap p-2 text-right text-xs opacity-30"
          >
            Rank
          </Tooltip>
        </div>
        <div className="relative text-sm">
          <div className="divide-y divide-gray-500/20">
            {cluster.metricsByKeyword.map((byKeyword, i) => {
              return (
                <div
                  key={Number(byKeyword.keyword?.keywordId) || i}
                  className="flex justify-between gap-2 overflow-hidden whitespace-nowrap px-2 py-1"
                >
                  <Tooltip
                    tooltip={renderKeyword(
                      byKeyword.keyword,
                      localesByIdQuery.data,
                      {
                        wrap: false,
                      }
                    )}
                    className="relative flex-1 overflow-hidden"
                  >
                    <Link
                      search={prev => ({
                        ...prev,
                        clusterId: Number(cluster.clusterId),
                        clusterPanel: 'keywords',
                        keywordId: Number(byKeyword.keyword?.keywordId),
                      })}
                    >
                      {renderKeyword(byKeyword.keyword, localesByIdQuery.data, {
                        wrap: false,
                      })}
                    </Link>
                    <div className="absolute bottom-0 right-0 top-0 w-8 bg-gradient-to-r from-transparent to-white dark:to-gray-900" />
                  </Tooltip>
                  <div>
                    {formatRank(
                      byKeyword.metricsByRollup?.[0]?.metrics?.topRank?.avg
                        ?.value ?? 101,
                      {
                        precision: metrics.topRank.getPrecision({}),
                        forcePrecision: true,
                      }
                    )}
                  </div>
                </div>
              )
            })}
            {remainingKeywords ? (
              <Link
                search={prev => ({
                  ...prev,
                  clusterId: Number(cluster.clusterId),
                  clusterPanel: 'keywords',
                })}
                className="block w-full px-2 py-1 text-left font-bold"
              >
                ...{formatNumber(remainingKeywords)} more
              </Link>
            ) : null}
          </div>
        </div>
      </div>
      <div className="w-[400px] divide-y divide-gray-500/20">
        <div className="flex items-center justify-between">
          <Tooltip tooltip={metrics.urlCount.tooltip}>
            <Link
              className="p-2 text-sm font-bold"
              search={prev => ({
                ...prev,
                clusterId: Number(cluster.clusterId),
                clusterPanel: 'urls',
              })}
            >
              URLs ({formatNumber(cluster.urlCount)})
            </Link>
          </Tooltip>
          <Tooltip
            tooltip={metrics.topRank.tooltip}
            className="whitespace-nowrap p-2 text-right text-xs opacity-30"
          >
            Rank
          </Tooltip>
        </div>
        <div className="relative text-sm">
          <div className="divide-y divide-gray-500/20">
            {cluster.clusterUrls.map((clusterUrl, i) => {
              return (
                <div
                  key={Number(clusterUrl.url?.urlId) || i}
                  className="flex justify-between gap-2 overflow-hidden whitespace-nowrap px-2 py-1"
                >
                  <Tooltip
                    key={i}
                    tooltip={clusterUrl.url?.url}
                    className="relative flex-1 overflow-hidden"
                  >
                    <Link
                      search={prev => ({
                        ...prev,
                        clusterId: Number(cluster.clusterId),
                        clusterPanel: 'urls',
                        urlId: Number(clusterUrl.url?.urlId),
                      })}
                    >
                      {clusterUrl.url?.url}
                    </Link>
                    <div className="absolute bottom-0 right-0 top-0 w-8 bg-gradient-to-r from-transparent to-white dark:to-gray-900" />
                  </Tooltip>
                  <div>
                    {formatRank(
                      clusterUrl.urlMetrics?.metrics?.topRank?.avg?.value ??
                        101,
                      {
                        precision: metrics.topRank.getPrecision({}),
                        forcePrecision: true,
                      }
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="w-[250px] divide-y divide-gray-500/20">
        <div className="flex items-center justify-between">
          <Link
            className="block w-full p-2 text-sm font-bold"
            search={prev => ({
              ...prev,
              clusterId: Number(cluster.clusterId),
              clusterPanel: 'paa',
            })}
          >
            PAA
          </Link>
          <Tooltip
            tooltip="Total number of keywords on which the PAA appeared in the cluster."
            className="whitespace-nowrap p-2 text-right text-xs opacity-30"
          >
            Keywords
          </Tooltip>
        </div>
        <div className="relative divide-y divide-gray-500/20 text-xs">
          <div className="relative">
            <div className="divide-y divide-gray-500/20">
              {cluster.metricsByRelatedPhraseType?.peopleAlsoAsk.map(
                (paa, i) => {
                  return (
                    <div className="flex items-center gap-2 px-2 py-1">
                      <div className="relative flex-1">{paa.label}</div>
                      <div className="text-right">
                        {formatNumber(
                          paa.metrics?.keywordCount?.sum?.value ?? 0
                        )}
                      </div>
                    </div>
                  )
                }
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="w-[250px] divide-y divide-gray-500/20">
        <div className="flex items-center justify-between">
          <Link
            className="block w-full p-2 text-sm font-bold"
            search={prev => ({
              ...prev,
              clusterId: Number(cluster.clusterId),
              clusterPanel: 'share-of-voice',
            })}
          >
            Share of Voice
          </Link>
          <Tooltip
            tooltip="The average top rank per domain across the cluster"
            className="whitespace-nowrap p-2 text-right text-xs opacity-30"
          >
            Rank
          </Tooltip>
        </div>
        <div className="divide-y divide-gray-500/20 text-xs">
          {cluster.metricsByRollup.map((rollup, i) => {
            return (
              <div className="flex items-center gap-2 px-2 py-1">
                <div className="relative flex-1">{rollup.label}</div>
                <div className="text-right">
                  {formatRank(rollup.metrics?.topRank?.avg?.value ?? 101, {
                    precision: metrics.topRank.getPrecision({}),
                    forcePrecision: true,
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="w-[250px] divide-y divide-gray-500/20">
        <div className="flex items-center justify-between">
          <Link
            className="block w-full p-2 text-sm font-bold"
            search={prev => ({
              ...prev,
              clusterId: Number(cluster.clusterId),
              clusterPanel: 'serp-features',
            })}
          >
            SERP Features
          </Link>
          <Tooltip
            tooltip="The average top rank per SERP feature across the cluster"
            className="whitespace-nowrap p-2 text-right text-xs opacity-30"
          >
            Rank
          </Tooltip>
        </div>
        <div className="divide-y divide-gray-500/20 text-xs">
          {cluster.metricsBySerpFeature.map((feature, i) => {
            return (
              <div className="flex items-center gap-2 px-2 py-1">
                <div className="relative flex-1">{feature.label}</div>
                <div className="text-right">
                  {formatRank(feature.metrics?.topRank?.avg?.value ?? 101, {
                    precision: metrics.topRank.getPrecision({}),
                    forcePrecision: true,
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
