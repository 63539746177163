import { Outlet } from '@tanstack/react-router'
import Card from '../../components/Card'
import Container from '../../components/Container'
import Link from '../../components/Link'

export function Admin() {
  return (
    <Container className="flex gap-4">
      <Card className="w-[250px] max-w-full divide-y divide-gray-200 p-0 dark:divide-gray-700">
        <Link to="." className="block px-4 py-2 text-2xl">
          Nozzle Admin
        </Link>
        <div className="divide-y divide-gray-500/20 text-lg">
          {[
            ['plans', 'Plans'],
            ['subscriptions', 'Subscriptions'],
          ].map(d => {
            return (
              <Link
                to={d[0]}
                key={d[0]}
                className="block px-4 py-2 hover:bg-gray-500 hover:bg-opacity-20"
              >
                {d[1]}
              </Link>
            )
          })}
        </div>
      </Card>
      <div className="min-w-0 flex-1">
        <Outlet />
      </div>
    </Container>
  )
}

export function AdminIndex() {
  return (
    <Card className="flex flex-col gap-4 py-4">
      <div className="px-4">Ultimate Cosmic Power!!!</div>
      <div className="px-4 text-sm italic">Itty-bitty living space.</div>
    </Card>
  )
}
