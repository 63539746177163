import { useMatch, useSearch } from '@tanstack/react-router'
import * as React from 'react'
import { useForm } from 'react-form'
import { FaAngleRight, FaCheck, FaTrashAlt } from 'react-icons/fa'
import { recursiveValidateConditions } from '../../utils/Conditions'
//
import Button from '../../components/Button'
import Card from '../../components/Card'
import Conditions from '../../components/Conditions'
import FieldError from '../../components/FieldError'
import QueryGate from '../../components/QueryGate'
import Spinner from '../../components/Spinner'
import TextAreaField from '../../components/TextAreaField'
import TextField from '../../components/TextField'
import {
  useCreateSegment,
  useRemoveSegmentById,
  useSaveSegment,
  useSegment,
} from '../../hooks/segments'
import useConfirm from '../../hooks/useConfirm'
import useNavigate from '../../hooks/useNavigate'
import usePauseEvents from '../../hooks/usePauseEvents'
import { useActiveWorkspaceId } from '../../hooks/workspaces'
import Validate, { validateConditionErrors } from '../../utils/Validate'
import { backToSegments } from './Segments'
import Head from '../../components/Head'
import { ProjectPicker } from '../../components/ProjectPicker'
import { useActiveProjectIdState } from '../../utils/searchParams'

type Props = {
  isNew?: boolean
  isDuplicate?: boolean
}

export function Segment({ isNew, isDuplicate }: Props) {
  const projectId = useActiveProjectIdState().state
  const { segmentId } = useMatch().params
  const workspaceId = useActiveWorkspaceId()
  const navigate = useNavigate()
  const saveSegment = useSaveSegment()
  const createSegment = useCreateSegment()
  const removeSegmentById = useRemoveSegmentById()
  const confirm = useConfirm()

  const activeWorkspaceId = useActiveWorkspaceId()

  const segmentQuery = useSegment({
    id: segmentId,
  })

  const segment = segmentQuery.data

  const defaultValues = React.useMemo(
    () =>
      isDuplicate && segmentQuery.isSuccess
        ? {
            ...segmentQuery?.data,
            id: '',
            name: `${segmentQuery?.data?.name} - Copy`,
            workspaceId: activeWorkspaceId,
            teamId: projectId,
          }
        : segmentQuery.data || {
            name: '',
            teamId: projectId,
            conditions: {},
          },
    [
      activeWorkspaceId,
      isDuplicate,
      segmentQuery.data,
      segmentQuery.isSuccess,
      projectId,
    ]
  )

  usePauseEvents('segment', defaultValues.id, true)

  const {
    Form,
    values,
    setFieldValue,
    setFieldMeta,
    meta: { isSubmitting, canSubmit },
  } = useForm({
    defaultValues,
    onSubmit: async values => {
      try {
        if (values.id) {
          return saveSegment(values)
        }

        const newSegment = await createSegment({
          workspaceId,
          teamId: projectId,
          ...values,
        })

        // If this is a new segment, navigate to the segment page
        if (isDuplicate) {
          navigate({ to: `/segments` })
        } else {
          navigate({
            to: `/segments/${newSegment.id}`,
          })
        }
      } catch {}
    },
  })

  const onRemove = async () => {
    const confirmed = await confirm({
      color: 'red-500',
      message: 'This Segment will be deleted!',
      subMessage: 'Segments can be restored after they have been deleted.',
    })
    if (confirmed) {
      await removeSegmentById.mutateAsync(segment.id)
      navigate({ to: `/segments` })
    }
  }

  const canEdit = Boolean(segment?.teamId || isNew || isDuplicate)

  const conditionErrors = React.useMemo(
    () => recursiveValidateConditions(values.conditions),
    [values.conditions]
  )

  const conditionError = React.useMemo(
    () => validateConditionErrors()(conditionErrors),
    [conditionErrors]
  )

  React.useEffect(() => {
    setFieldMeta('conditions', old => ({
      ...old,
      error: conditionError,
    }))
  }, [conditionError, setFieldMeta])

  const renderForm = () => (
    <Form className="divide-y divide-gray-500/20">
      <div className="space-y-2 p-2">
        <TextField
          label="Segment Name"
          field="name"
          placeholder="My Segment"
          validate={Validate.segmentName()}
          disabled={!canEdit}
        />
        {values.id ? (
          <TextField field="id" label="Segment ID" placeholder="-" disabled />
        ) : null}
        <TextAreaField
          label="Segment Description"
          field="description"
          placeholder="This segment contains..."
          // validate={Validate.segmentDescription()}
          disabled={!canEdit}
        />
        <div className="font-bold">Conditions</div>
        <Conditions
          conditions={values.conditions}
          errors={conditionErrors}
          onChange={(conditions: any) => {
            setFieldValue('conditions', conditions)
            // setFieldMeta('conditions', {
            //   isTouched: true,
            // })
          }}
          transactional={false}
          workspaceId={activeWorkspaceId}
          projectId={projectId}
          canEdit={canEdit}
        />
        <FieldError field="conditions" />
      </div>
      {
        canEdit ? (
          <div className="flex items-center gap-2 p-2">
            <Button
              type="submit"
              size="base"
              color="green-500"
              disabled={!canSubmit}
            >
              {isSubmitting ? (
                <span>
                  <Spinner color="white" /> Saving Segment
                </span>
              ) : (
                <span>
                  <FaCheck className="inline" /> Save Segment
                </span>
              )}
            </Button>
            {segment && !isDuplicate ? (
              <>
                {/* <Button
                size="base"
                color="gray-500"
                hoverColor="blue-500"
                onClick={() => navigate({ to: './new' })}
              >
                <FaCopy className="inline" />
                Duplicate Segment
              </Button>{' '} */}
                <Button
                  size="base"
                  color="gray-500"
                  hoverColor="red-500"
                  onClick={onRemove}
                >
                  <FaTrashAlt className="inline" /> Remove Segment
                </Button>
              </>
            ) : null}
          </div>
        ) : null
        // <Button
        //   size="base"
        //   color="gray-500"
        //   hoverColor="blue-500"
        //   onClick={() => navigate({ to: './new' })}
        // >
        //   <FaCopy className="inline" />
        //   Duplicate Segment
        // </Button>
      }
    </Form>
  )

  return (
    <div className="space-y-2 p-2">
      <Head>
        <title>Segment - {segmentQuery.data?.name ?? ''}</title>
      </Head>
      <Card className="flex flex-wrap items-center gap-2">
        <ProjectPicker />
        <FaAngleRight />
        {backToSegments}
        <FaAngleRight />
        <div className="text-xl font-bold">{segmentQuery.data?.name ?? ''}</div>
      </Card>
      <Card className="p-0">
        {isNew ? (
          renderForm()
        ) : (
          <QueryGate query={segmentQuery}>{() => renderForm()}</QueryGate>
        )}
      </Card>
    </div>
  )
}
