import { useNavigate, useSearch } from '@tanstack/react-router'
import { createColumnHelper } from '@tanstack/react-table'
import moment from 'moment'
import React from 'react'
import { BiCreditCard } from 'react-icons/bi'
import { BsStars } from 'react-icons/bs'
import {
  FaAngleRight,
  FaArrowRight,
  FaCalculator,
  FaCheck,
  FaInfoCircle,
} from 'react-icons/fa'
import { UseQueryResult } from 'react-query'
import { twMerge } from 'tailwind-merge'
import { BillingDetailsForm } from '../../components/BillingDetailsForm'
import Button from '../../components/Button'
import Card from '../../components/Card'
import Clickable from '../../components/Clickable'
import Container from '../../components/Container'
import Input from '../../components/Input'
import Link from '../../components/Link'
import Loader from '../../components/Loader'
import {
  Modal,
  ModalClose,
  ModalContent,
  ModalTitle,
} from '../../components/Modals'
import Separator from '../../components/Separator'
import Spinner from '../../components/Spinner'
import StripeCard from '../../components/StripeCard'
import Table from '../../components/Table'
import { Tag } from '../../components/Tags'
import Tooltip from '../../components/Tooltip'
import { WorkspaceSerpUsageCalculator } from '../../components/WorkspaceSerpUsageCalculator'
import { formatCardType, useAccount } from '../../hooks/account'
import {
  useChangeSubscriptionPlan,
  useEstimateChangeBillingPlan,
  usePlansQuery,
} from '../../hooks/plans'
import useModal from '../../hooks/useModal'
import usePopup from '../../hooks/usePopup'
import { useTable } from '../../hooks/useTable'
import { useActiveWorkspaceId } from '../../hooks/workspaces'
import { openHubSpotChat } from '../../utils/Analytics'
import { formatCurrency, formatNumber } from '../../utils/Format'
import { moneyToFloat } from '../../utils/money'
import {
  AccountPb,
  AccountViewPb,
  ChangeTimingPb,
  CouponCodeStatePb,
  EstimateChangeSubscriptionResponsePb,
  PeriodPb,
  PeriodPlanPb,
  PlanPb,
  ProductFamilyPb,
  SubscriptionStatePb,
} from '../../utils/proto'
import { datePbToDate } from '../../utils/protoDate'

export function ManageSubscription() {
  const plansQuery = usePlansQuery()
  const accountQuery = useAccount({
    view: AccountViewPb.FULL,
  })

  // React.useEffect(() => {
  //   if (selectedPlan) {
  //     maxOverage(old => {
  //       if (!old) {
  //         return old
  //       }

  //       if (old.chargebeeOverageLimitBy === 'serps') {
  //         return {
  //           ...old,
  //           chargebeeMeteredItemPriceMaxOverageMicrocents:
  //             round(
  //               (old!.quantity *
  //                 selectedPlan?.overageMicrocentsPerPull) /
  //                 1e6
  //             ) * 1e6,
  //         }
  //       }

  //       return {
  //         ...old,
  //         quantity: round(
  //           old!.chargebeeMeteredItemPriceMaxOverageMicrocents /
  //             selectedPlan?.overageMicrocentsPerPull
  //         ),
  //       }
  //     })
  //   }
  // }, [selectedPlan])

  // const teamsQuery = useTeams()

  // const [pendingTeams, setPendingTeams] = React.useState<Team[]>([])
  // React.useEffect(() => {
  //   if (teamsQuery.data) {
  //     setPendingTeams(teamsQuery.data)
  //   }
  // }, [teamsQuery.data])

  if (!accountQuery.data || !plansQuery.data) {
    return <Loader />
  }

  const selectedPlan = plansQuery.data.plans.find(
    d => d.planId === accountQuery.data?.subscriptions[0]?.planId
  )
  const selectedPeriodPlan = selectedPlan?.periodPlans.find(
    d => d.basePeriod === accountQuery.data?.subscriptions[0]?.basePeriod
  )

  return (
    <Container>
      <Card className="flex items-center gap-2 p-4 text-2xl font-bold">
        <Link to="../billing" className="flex items-center gap-2">
          <BiCreditCard /> Billing
        </Link>{' '}
        <FaAngleRight /> Manage Subscription
      </Card>
      <div className="h-4" />
      {selectedPeriodPlan ? (
        <Inner
          {...{
            account: accountQuery.data,
            plans: plansQuery.data?.plans,
          }}
        />
      ) : (
        <Card>
          You are currently on a <strong>custom plan.</strong> Please{' '}
          <Clickable onClick={() => openHubSpotChat()} className="!font-bold">
            contact support
          </Clickable>{' '}
          to make changes to your plan
        </Card>
      )}
    </Container>
  )
}

function Inner({ account, plans }: { account: AccountPb; plans: PlanPb[] }) {
  const activeWorkspaceId = useActiveWorkspaceId()!
  const saveBillingPlan = useChangeSubscriptionPlan()
  const search = useSearch()
  const showModal = useModal()
  const navigate = useNavigate()

  const [activeStep, setActiveStep] = React.useState<number>(
    (search['activeStep'] as number) || 0
  )

  const subscription = account.subscriptions[0]

  if (!subscription) {
    throw new Error('No product subscript found!')
  }

  const meteredSubscription = subscription.meteredSubscriptions[0]

  if (!meteredSubscription) {
    throw new Error('No metered subscript found!')
  }

  const [selectedPlanId, setSelectedPlanId] = React.useState<string>(
    subscription.planId.toLocaleLowerCase()
  )
  const [selectedPeriod, setSelectedPeriod] = React.useState<PeriodPb>(
    subscription.basePeriod
  )

  const selectedPlan = plans.find(d => d.planId === selectedPlanId)
  const selectedPeriodPlan = selectedPlan?.periodPlans.find(
    d => d.basePeriod === selectedPeriod
  )

  if (!selectedPlan) {
    throw new Error('No plan selected!')
  }

  if (!selectedPeriodPlan) {
    throw new Error('No period plan selected!')
  }

  React.useEffect(() => {
    if (selectedPlanId) {
      setActiveStep(0)
    }
  }, [selectedPlanId])

  const [couponCode, setCouponCode] = React.useState('')
  const [poNumber, setPoNumber] = React.useState('')

  const noChanges =
    selectedPlanId === subscription.planId &&
    selectedPeriod === selectedPeriodPlan.basePeriod

  const estimateQuery = useEstimateChangeBillingPlan({
    productFamily: ProductFamilyPb.STANDARD,
    basePeriod: selectedPeriod,
    overagePeriod: PeriodPb.MONTHLY,
    planId: selectedPlanId,
    changeTiming: ChangeTimingPb.UNSPECIFIED,
    couponCodes: couponCode ? [couponCode] : [],
  })

  const columnHelper = createColumnHelper<PlanPb>()

  const columns = React.useMemo(
    () => [
      columnHelper.display({
        id: 'name',
        header: 'Plan Name',
        cell: props => props.row.original.name,
      }),
      columnHelper.group({
        id: 'base-plan',
        header: () => <div className="flex-1 text-center">Base Plan</div>,
        columns: [
          columnHelper.display({
            id: 'price',
            header: () => <div className="ml-auto">Monthly</div>,
            meta: {
              getHeaderProps: () => ({
                style: {
                  fontWeight: selectedPeriod === PeriodPb.MONTHLY ? '900' : '',
                },
              }),
              getCellProps: props => ({
                style: {
                  fontWeight:
                    props.row.original.planId === selectedPlanId &&
                    PeriodPb.YEARLY === selectedPeriod
                      ? 'normal'
                      : '',
                },
              }),
            },
            cell: props => {
              const original = props.row.original
              const periodPlan = original.periodPlans.find(
                d => d.basePeriod === PeriodPb.MONTHLY
              )

              if (!periodPlan) {
                throw new Error('No monthly plan found!')
              }

              return (
                <label
                  className={twMerge(
                    'flex w-full cursor-pointer justify-between gap-4 py-1',
                    props.row.original.planId === selectedPlanId &&
                      periodPlan?.basePeriod === selectedPeriod &&
                      'dark:text-blue-300'
                  )}
                  onClick={e => {
                    setSelectedPlanId(original.planId)
                    setSelectedPeriod(periodPlan?.basePeriod)
                  }}
                >
                  <input
                    type="radio"
                    checked={
                      props.row.original.planId === selectedPlanId &&
                      periodPlan?.basePeriod === selectedPeriod
                    }
                    onChange={() => {
                      //
                    }}
                  />
                  <span>
                    {formatCurrency(moneyToFloat(periodPlan?.basePrice))} / mo
                  </span>
                </label>
              )
            },
          }),
          columnHelper.display({
            id: 'annual',
            header: () => <div className="ml-auto">Yearly</div>,
            meta: {
              getHeaderProps: () => ({
                style: {
                  fontWeight: selectedPeriod === PeriodPb.YEARLY ? '900' : '',
                },
              }),
              getCellProps: props => ({
                style: {
                  fontWeight:
                    props.row.original.planId === selectedPlanId &&
                    PeriodPb.MONTHLY === selectedPeriod
                      ? 'normal'
                      : '',
                },
              }),
            },
            cell: props => {
              const original = props.row.original
              const monthlyPeriodPlan = original.periodPlans.find(
                d => d.basePeriod === PeriodPb.MONTHLY
              )
              const periodPlan = original.periodPlans.find(
                d => d.basePeriod === PeriodPb.YEARLY
              )

              if (!periodPlan || !monthlyPeriodPlan) {
                throw new Error('No plans found!')
              }

              const savings = formatCurrency(
                moneyToFloat(monthlyPeriodPlan?.basePrice) * 12 -
                  moneyToFloat(periodPlan?.basePrice)
              )

              return (
                <label
                  className={twMerge(
                    'flex w-full cursor-pointer justify-between gap-4 py-1',
                    original.planId === selectedPlanId &&
                      periodPlan?.basePeriod === selectedPeriod &&
                      'dark:text-blue-300'
                  )}
                  onClick={e => {
                    setSelectedPlanId(original.planId)
                    setSelectedPeriod(periodPlan?.basePeriod)
                  }}
                >
                  <input
                    type="radio"
                    checked={
                      original.planId === selectedPlanId &&
                      periodPlan?.basePeriod === selectedPeriod
                    }
                    onChange={() => {
                      //
                    }}
                    className="cursor-pointer"
                  />
                  <div className="flex items-center gap-2">
                    <div>
                      {formatCurrency(moneyToFloat(periodPlan?.basePrice))} / yr
                    </div>
                    <Tooltip
                      tooltip={
                        <>
                          Instantly save ${savings} when compared to a monthly
                          subscription!
                        </>
                      }
                    >
                      <div className="rounded-md border border-green-500 px-1 py-px text-xs text-green-500">
                        - $<span className="font-black">{savings}</span>
                      </div>
                    </Tooltip>
                  </div>
                </label>
              )
            },
          }),
        ],
      }),

      columnHelper.group({
        id: 'serps',
        header: () => <div className="flex-1 text-center">SERPs</div>,
        columns: [
          columnHelper.display({
            id: 'included',
            header: () => <div className="ml-auto">Included / mo</div>,
            cell: props => {
              const value = Number(
                props.row.original.periodPlans.find(
                  d => d.basePeriod === PeriodPb.MONTHLY
                )?.meteredPlans[0]?.includedQuantity
              )

              return !value ? (
                '-'
              ) : (
                <div className="text-right">{formatNumber(value)}</div>
              )
            },
          }),
          columnHelper.display({
            id: 'overage',
            header: () => <div className="ml-auto">Overage Per 1k</div>,
            cell: props => {
              const value =
                moneyToFloat(
                  props.row.original.periodPlans.find(
                    d => d.basePeriod === PeriodPb.MONTHLY
                  )?.meteredPlans[0]?.unitPrice
                ) * 1000

              return !value ? (
                '-'
              ) : (
                <div className="text-right">
                  {formatCurrency(value, {
                    precision: 2,
                    forcePrecision: true,
                  })}
                </div>
              )
            },
          }),
        ],
      }),
    ],
    [columnHelper, selectedPeriod, selectedPlanId]
  )

  const table = useTable({
    data: plans,
    columns,
    showToolbar: false,
    pagination: {
      show: false,
    },
    counter: {
      show: false,
    },
    getRowProps: props => {
      return {
        style: {
          fontWeight: selectedPlanId === props.row.original.planId ? '900' : '',
        },
      }
    },
  })

  const handleSubmit = async () => {
    await saveBillingPlan.mutateAsync({
      workspaceId: BigInt(activeWorkspaceId),
      productFamily: ProductFamilyPb.STANDARD,
      planId: selectedPlanId,
      basePeriod: selectedPeriod,
      overagePeriod: PeriodPb.MONTHLY,
      couponCodes: couponCode ? [couponCode] : [],
      poNumber,
    })

    navigate({ to: '../billing' })
  }

  const steps = [
    {
      title: 'Choose Your Plan',
      canProceed: () => true,
      render: () => {
        return (
          <div>
            <div className="p-4">
              {subscription.state === SubscriptionStatePb.TRIAL ? (
                account.paymentMethod?.paymentMethodId ? (
                  <>
                    <div
                      className="flex items-center gap-2 rounded-lg border-2 border-blue-500 px-3
                      py-2 font-bold text-blue-500"
                    >
                      <FaInfoCircle />{' '}
                      <span>
                        Your free trial will automatically convert{' '}
                        {subscription?.name
                          ? `to the ${subscription?.name} plan`
                          : ''}{' '}
                        on{' '}
                        {moment
                          .utc(datePbToDate(subscription.trialEnd))
                          .startOf('day')
                          .add('day', 1)
                          .format('ll')}
                      </span>
                    </div>
                    <div className="h-4" />
                  </>
                ) : (
                  <>
                    <div
                      className="flex items-center gap-2 rounded-lg bg-green-500 px-3
                      py-2 font-bold text-white"
                    >
                      <BsStars className="text-3xl" />
                      <span>
                        We hope you're enjoying your free trial! To keep your
                        data flowing, pick one of the plans below.
                      </span>
                    </div>
                    <div className="h-4" />
                  </>
                )
              ) : null}
              <div className="flex items-center justify-stretch gap-2">
                <Button
                  size="lg"
                  color={
                    selectedPeriod === PeriodPb.MONTHLY
                      ? 'blue-500'
                      : ['gray-200', 'gray-800']
                  }
                  onClick={() => setSelectedPeriod(PeriodPb.MONTHLY)}
                  className="flex-1 font-bold shadow-none"
                >
                  Monthly
                </Button>
                <Button
                  size="lg"
                  color={
                    selectedPeriod === PeriodPb.YEARLY
                      ? 'blue-500'
                      : ['gray-200', 'gray-800']
                  }
                  onClick={() => setSelectedPeriod(PeriodPb.YEARLY)}
                  className="flex-1 font-bold shadow-none"
                >
                  <span>Yearly</span>
                  <div className="-m-1 ml-0 rounded-md bg-green-500 px-1 py-px text-sm text-white">
                    Save!
                  </div>
                </Button>
              </div>
            </div>
            <Separator />
            <Table table={table} />
            <Separator />
            <div className="p-4">
              <div className="flex items-center">
                <div>
                  Need a Custom Plan?{' '}
                  <Clickable onClick={() => openHubSpotChat()}>
                    Contact Us.
                  </Clickable>
                </div>
                <div className="ml-auto flex gap-2">
                  <Button
                    size="base"
                    color="gray-500"
                    onClick={() =>
                      showModal(
                        () => (
                          <Modal>
                            <ModalTitle>Usage Calculator</ModalTitle>
                            <ModalContent className="w-full">
                              <div className="">
                                <WorkspaceSerpUsageCalculator />
                              </div>
                            </ModalContent>
                            <ModalClose />
                          </Modal>
                        ),
                        {
                          style: {
                            alignSelf: 'flex-start',
                            marginTop: '4rem',
                            width: 'auto',
                          },
                        }
                      )
                    }
                  >
                    <FaCalculator /> View Calculator
                  </Button>
                  <Button
                    size="base"
                    color="green-500"
                    onClick={() => setActiveStep(old => old + 1)}
                  >
                    Continue <FaArrowRight />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )
      },
    },
    {
      title: 'Payment Method',
      canProceed: () => account.paymentMethod?.details.value?.last4,
      render: () => (
        <>
          <StepPayment {...{ account }} />
          <Separator />
          <div className="p-4">
            <div className="flex items-center">
              <div>
                Have a Question?{' '}
                <Clickable onClick={() => openHubSpotChat()}>
                  Contact Us.
                </Clickable>
              </div>
              <div className="ml-auto flex gap-2">
                <Button
                  size="base"
                  color="green-500"
                  onClick={() => setActiveStep(old => old + 1)}
                  disabled={
                    !account.paymentMethod?.details.value?.last4 ? true : false
                  }
                >
                  Continue <FaArrowRight />
                </Button>
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      title: 'Billing Information',
      canProceed: () => false,
      render: () => (
        <>
          <div className="p-4">
            <BillingDetailsForm
              onSubmit={() => {
                setActiveStep(old => old + 1)
              }}
            />
          </div>
          <Separator />
          <div className="p-4">
            <div className="flex items-center">
              <div>
                Have a Question?{' '}
                <Clickable onClick={() => openHubSpotChat()}>
                  Contact Us.
                </Clickable>
              </div>
              {/* <div className="ml-auto flex gap-2">
                <Button
                  size="base"
                  color="green-500"
                  onClick={() => setActiveStep(old => old + 1)}
                  // disabled={!account.billingDetails?.address }
                >
                  Continue <FaArrowRight />
                </Button>
              </div> */}
            </div>
          </div>
        </>
      ),
    },
    {
      title: 'Review & Confirm',
      canProceed: () => true,
      render: () => {
        return (
          <>
            <StepConfirm
              {...{
                selectedPlan,
                selectedPeriodPlan,
                estimateQuery,
                couponCode,
                setCouponCode,
                noChanges,
                poNumber,
                setPoNumber,
              }}
            />
            <Separator />
            <div className="p-4">
              <div className="flex items-center">
                <div>
                  Need Help?{' '}
                  <Clickable onClick={() => openHubSpotChat()}>
                    Contact Us.
                  </Clickable>
                </div>
                <div className="ml-auto flex gap-2">
                  <Button
                    size="base"
                    color="green-500"
                    onClick={handleSubmit}
                    disabled={
                      !estimateQuery.isSuccess || saveBillingPlan.isLoading
                    }
                  >
                    {saveBillingPlan.isLoading ? <Spinner /> : <FaCheck />}{' '}
                    Confirm Changes
                  </Button>
                </div>
              </div>
            </div>
          </>
        )
      },
    },
  ]

  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-2">
      <div className="space-y-4">
        {steps.map((step, i) => (
          <Card
            key={step.title}
            className="divide-y divide-gray-500/20 p-0"
            disabled={i > activeStep}
          >
            <button
              onClick={() => {
                if (steps[i - 1]?.canProceed() ?? true) {
                  setActiveStep(i)
                }
              }}
              className={twMerge(
                'flex w-full items-center bg-transparent px-4 py-2 hover:cursor-pointer',
                (!!steps[i - 1]?.canProceed() ?? true) &&
                  i === steps.length - 1 &&
                  'hover:cursor-default'
              )}
            >
              <div className="text-lg">
                {i + 1}. {step.title}
              </div>
            </button>
            {activeStep === i ? <div>{step.render()}</div> : null}
          </Card>
        ))}
      </div>
      <div>
        <Card className="hidden divide-y divide-gray-500/20 p-0 md:block">
          <div className="flex items-center justify-between gap-2 p-4">
            <div className="text-2xl font-bold">{selectedPlan.name} Plan</div>
          </div>
          <div className="flex items-center p-4 font-bold">
            <div>
              Plan Type <div className="h-2" />
              <div className="font-normal">
                {selectedPeriod === PeriodPb.MONTHLY ? 'Monthly' : 'Yearly'}
              </div>
            </div>
            <div className="ml-auto">
              {formatCurrency(moneyToFloat(selectedPeriodPlan.basePrice))} /{' '}
              {selectedPeriod === PeriodPb.MONTHLY ? 'month' : 'year'}
            </div>
          </div>
          <div className="flex items-center p-4 font-bold">
            <div>
              Overage <div className="h-2" />
              <div className="font-normal">Per 1,000 SERPs</div>
            </div>
            <div className="ml-auto">
              {formatCurrency(
                moneyToFloat(selectedPeriodPlan.meteredPlans[0]?.unitPrice) *
                  1000,
                {
                  precision: 2,
                  forcePrecision: true,
                }
              )}
            </div>
          </div>
          <div className="flex items-center p-4 font-bold">
            <div>
              SERPs
              <div className="h-2" />
              <div className="font-normal">
                {formatNumber(
                  Number(selectedPeriodPlan.meteredPlans[0]?.includedQuantity)
                )}{' '}
                / month
              </div>
            </div>
            <div className="ml-auto">
              <Tag className="flex items-center gap-2 text-green-500">
                <FaCheck /> Included
              </Tag>
            </div>
          </div>
        </Card>
        <div className="h-0 md:h-4" />
        <Card className="block">
          <div className="flex p-2 text-base">
            Have a question?{' '}
            <Clickable className="ml-auto" onClick={() => openHubSpotChat()}>
              Ask Support
            </Clickable>
          </div>
        </Card>
      </div>
    </div>
  )
}

function StepPayment({ account }: { account: AccountPb }) {
  return (
    <div className="flex items-center justify-between gap-2">
      <div className="p-4">
        {account.paymentMethod?.details.value?.last4 ? (
          <div>
            <div className="flex items-center gap-2">
              <BiCreditCard className="text-2xl text-blue-500" />
              <span className="">
                {formatCardType(account.paymentMethod?.details.value?.type)}
              </span>
              <span className="text-sm">**** **** ****</span>
              <span className="font-bold">
                {account.paymentMethod.details.value?.last4}
              </span>
            </div>
          </div>
        ) : (
          <div className="italic opacity-50">No card added yet.</div>
        )}
      </div>
      <div className="p-4">
        <StripeCard />
      </div>
    </div>
  )
}

function StepConfirm({
  estimateQuery,
  selectedPlan,
  selectedPeriodPlan,
  couponCode,
  setCouponCode,
  poNumber,
  setPoNumber,
}: {
  estimateQuery: UseQueryResult<EstimateChangeSubscriptionResponsePb>
  selectedPlan: PlanPb
  selectedPeriodPlan: PeriodPlanPb
  couponCode: string
  setCouponCode: React.Dispatch<React.SetStateAction<string>>
  poNumber: string
  setPoNumber: React.Dispatch<React.SetStateAction<string>>
}) {
  const showPopup = usePopup()
  const [couponCodeDraft, setCouponCodeDraft] = React.useState(couponCode)

  React.useEffect(() => {
    setCouponCodeDraft(couponCode)
  }, [couponCode])

  React.useEffect(() => {
    if (
      estimateQuery.data?.couponCodes.find(
        d => d.state === CouponCodeStatePb.NOT_FOUND
      )
    ) {
      showPopup({
        title: 'Coupon Code Not Found',
        message: 'Coupon code was not found.',
      })
      setCouponCode('')
    }

    if (
      estimateQuery.data?.couponCodes.find(
        d => d.state === CouponCodeStatePb.ARCHIVED
      )
    ) {
      showPopup({
        title: 'Coupon Code Archived',
        message: 'Coupon code has been archived.',
      })
      setCouponCode('')
    }

    if (
      estimateQuery.data?.couponCodes.find(
        d => d.state === CouponCodeStatePb.REDEEMED
      )
    ) {
      showPopup({
        title: 'Coupon Code Redeemed',
        message: 'Coupon code has already been redeemed.',
      })
      setCouponCode('')
    }
  }, [estimateQuery.data?.couponCodes, setCouponCode, showPopup])

  return (
    <div className="">
      {estimateQuery.isLoading ? (
        <div className="p-8 text-center">
          <Loader />
        </div>
      ) : (
        <div className="divide-y divide-gray-500/20">
          <div className="p-4 text-xl">
            {moment(datePbToDate(estimateQuery.data?.nextBillingAt))
              .utc()
              .isSame(moment.utc(), 'day') ? (
              <>
                Effective <strong>Today</strong>
              </>
            ) : (
              <>
                Effective on{' '}
                <strong>
                  {moment(datePbToDate(estimateQuery.data?.nextBillingAt))
                    .utc()
                    .format('ll')}
                </strong>
              </>
            )}
          </div>
          <div className="flex justify-between p-4">
            <div>
              <div>Subscription to {selectedPlan.name} Plan</div>
              <div className="italic opacity-50">
                {moment(datePbToDate(estimateQuery.data?.termStart))
                  .utc()
                  .format('ll')}{' '}
                -{' '}
                {moment(datePbToDate(estimateQuery.data?.termEnd))
                  .utc()
                  .format('ll')}
              </div>
            </div>
            <div>
              {formatCurrency(moneyToFloat(selectedPeriodPlan.basePrice), {
                precision: 2,
              })}{' '}
              /{' '}
              {selectedPeriodPlan?.basePeriod === PeriodPb.MONTHLY
                ? 'month'
                : 'year'}
            </div>
          </div>
          {moneyToFloat(estimateQuery.data?.proratedCredits) ? (
            <div className="flex items-center justify-between gap-2 p-4">
              <div>Pro-rated credits from existing plan</div>
              <div>
                -{' '}
                {formatCurrency(
                  moneyToFloat(estimateQuery.data?.proratedCredits),
                  { precision: 2 }
                )}
              </div>
            </div>
          ) : null}
          <div className="flex items-center justify-between gap-2 p-4">
            <div>Coupons & Discounts</div>
            <form
              className="flex items-center gap-2"
              onSubmit={e => {
                e.preventDefault()
                e.stopPropagation()
                setCouponCode(couponCodeDraft)
              }}
            >
              <Input
                placeholder="Enter coupon code..."
                value={couponCodeDraft}
                onChange={e => setCouponCodeDraft(e.currentTarget.value)}
              />
              <Button
                color="gray-500"
                size="sm"
                type="submit"
                disabled={couponCodeDraft === couponCode}
              >
                Apply
              </Button>
              {couponCodeDraft === couponCode ? (
                <Button
                  color="gray-800"
                  size="sm"
                  onClick={() => setCouponCode('')}
                >
                  Remove
                </Button>
              ) : null}
            </form>
          </div>
          <div className="flex items-center justify-between gap-2 p-4">
            <div>PO Number (optional)</div>
            <Input
              placeholder=""
              value={poNumber}
              onChange={e => setPoNumber(e.currentTarget.value)}
            />
          </div>
          {estimateQuery.isFetching ? (
            <div className="p-8 text-center">
              <Loader />
            </div>
          ) : (
            estimateQuery.data?.couponCodes?.map(couponCode => {
              return (
                <div
                  key={couponCode.couponCode}
                  className="flex items-center justify-between gap-2 p-4"
                >
                  <div>
                    {
                      //prettier-ignore
                      {
                        [CouponCodeStatePb.UNSPECIFIED]: <div className='font-bold text-red-500'>Coupon code "{couponCode.couponCode}"" not found!</div>,
                        [CouponCodeStatePb.NOT_REDEEMED]: <div className='font-bold text-green-500'>Coupon code "{couponCode.couponCode}"" applied!</div>,
                        [CouponCodeStatePb.REDEEMED]: <div className='font-bold text-red-500'>Coupon code "{couponCode.couponCode}"" has already been redeeemed!</div>,
                        [CouponCodeStatePb.ARCHIVED]: <div className='font-bold text-red-500'>Coupon code "{couponCode.couponCode}"" is no longer valid.</div>,
                        [CouponCodeStatePb.NOT_FOUND]: <div className='font-bold text-red-500'>Coupon code "{couponCode.couponCode}"" not found!</div>,
                      }[couponCode.state]
                    }
                  </div>
                </div>
              )
            })
          )}
          <div className="flex items-center gap-2 p-4 text-xl">
            <div>
              {moment(datePbToDate(estimateQuery.data?.nextBillingAt))
                .utc()
                .isSame(moment.utc(), 'day') ? (
                <>
                  Amount due <strong>Today</strong>
                </>
              ) : (
                <>
                  Amount due on{' '}
                  <strong>
                    {moment(datePbToDate(estimateQuery.data?.nextBillingAt))
                      .utc()
                      .format('ll')}
                  </strong>
                </>
              )}
            </div>
            <div className="ml-auto">
              {formatCurrency(moneyToFloat(estimateQuery.data?.amountDue), {
                precision: 2,
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
