import * as React from 'react'
import { twMerge } from 'tailwind-merge'
import Input from '../components/Input'
import { Modal, ModalContent } from '../components/Modals'
import Select from '../components/Select'
import useModal from '../hooks/useModal'
import useModalContext from '../hooks/useModalContext'
import useZustand from '../hooks/useZustand'
import { multiSortBy } from '../utils'

//

export const openQuickPanel = () => _openQuickPanel()
let _openQuickPanel = () => {
  //
}

export default function QuickPanel() {
  const showModal = useModal()
  const [currentModal, setModal] = React.useState(false)

  const showQuickPanel = React.useCallback(() => {
    if (currentModal) return
    const modal = showModal(QuickPanelModal, {
      maskClose: true,
      onClose: () => setModal(false),
    })
    setModal(modal)
  }, [currentModal, showModal])

  _openQuickPanel = showQuickPanel

  React.useEffect(() => {
    const onKeyDown = (e: any) => {
      if (
        e.key?.toLowerCase() === 'k' &&
        (e.metaKey || (e.shiftKey && e.ctrlKey))
      ) {
        showQuickPanel()
      }

      if (currentModal && e.key === 'Escape') {
        // @ts-expect-error  // Property 'close' does not exist on type 'true'.
        currentModal.close()
      }
    }

    document.addEventListener('keydown', onKeyDown)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [currentModal, showModal, showQuickPanel])

  return null
}

function QuickPanelModal() {
  const { close } = useModalContext()

  const [quickOptionsObj] = useZustand(state => state.quickOptions)

  const quickOptions = React.useMemo(
    () =>
      multiSortBy(Object.values(quickOptionsObj), [
        // @ts-expect-error  // Object is of type 'unknown'.
        d => d.priority,
        // @ts-expect-error  // Object is of type 'unknown'.
        d => d.label,
      ]),
    [quickOptionsObj]
  )

  const onChange = (value: any) => {
    value()
    close()
  }

  return (
    <Modal className="mt-12 w-auto self-start">
      <ModalContent className="bg-transparent">
        <Select
          // @ts-expect-error  // Type 'unknown[]' is not assignable to type '{ labe... Remove this comment to see the full error message
          options={quickOptions}
          onChange={onChange}
          placeholder="Search..."
          className="h-[700px] w-[700px]"
          inline
          // filterFn={(options, value) =>
          //   matchSorter(options, value, {
          //     keys: ['label'],
          //     baseSort: (a, b) => (a.index < b.index ? -1 : 1),
          //     threshold: matchSorter.rankings.CONTAINS,
          //   })
          // }
          optionProps={{
            className: 'p-2 text-base',
          }}
          dropdownInputProps={{
            className: 'px-4 py-3 text-2xl font-bold',
          }}
        />
      </ModalContent>
    </Modal>
  )
}
