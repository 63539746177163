// @generated by protoc-gen-es v1.3.0 with parameter "target=ts"
// @generated from file nozzle/workspaces/v1/topic.proto (package nozzle.workspaces.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { ListMonitoredUrlsFilters, MonitoredUrl } from "./monitored_url_pb.js";
import { ListKeywordsFilters } from "./keyword_pb.js";
import { Label } from "../../protobuf/label_pb.js";
import { Keyword } from "../../keywords/v1/keywords_pb.js";

/**
 * TopicView determines which fields of the Topic are returned in the response.
 *
 * @generated from enum nozzle.workspaces.v1.TopicView
 */
export enum TopicView {
  /**
   * Will default to TOPIC_VIEW_BASIC.
   *
   * @generated from enum value: TOPIC_VIEW_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * This only returns the manually configured fields of the topic, not any reporting data
   *
   * @generated from enum value: TOPIC_VIEW_BASIC = 1;
   */
  BASIC = 1,

  /**
   * This will populate the topic with all of the reporting data
   *
   * @generated from enum value: TOPIC_VIEW_FULL = 2;
   */
  FULL = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(TopicView)
proto3.util.setEnumType(TopicView, "nozzle.workspaces.v1.TopicView", [
  { no: 0, name: "TOPIC_VIEW_UNSPECIFIED" },
  { no: 1, name: "TOPIC_VIEW_BASIC" },
  { no: 2, name: "TOPIC_VIEW_FULL" },
]);

/**
 * UpdateTopicRequest is the request for the UpdateTopic method.
 *
 * @generated from message nozzle.workspaces.v1.UpdateTopicRequest
 */
export class UpdateTopicRequest extends Message<UpdateTopicRequest> {
  /**
   * @generated from field: nozzle.workspaces.v1.Topic topic = 1;
   */
  topic?: Topic;

  /**
   * If true, the request will succeed even if the topic does not exist. If false, the request will fail
   * if topic_id is not set or if the referenced topic_id does not exist.
   *
   * @generated from field: bool allow_missing = 2;
   */
  allowMissing = false;

  constructor(data?: PartialMessage<UpdateTopicRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.UpdateTopicRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "topic", kind: "message", T: Topic },
    { no: 2, name: "allow_missing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateTopicRequest {
    return new UpdateTopicRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateTopicRequest {
    return new UpdateTopicRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateTopicRequest {
    return new UpdateTopicRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateTopicRequest | PlainMessage<UpdateTopicRequest> | undefined, b: UpdateTopicRequest | PlainMessage<UpdateTopicRequest> | undefined): boolean {
    return proto3.util.equals(UpdateTopicRequest, a, b);
  }
}

/**
 * UpdateTopicResponse is the response for the UpdateTopic method.
 *
 * @generated from message nozzle.workspaces.v1.UpdateTopicResponse
 */
export class UpdateTopicResponse extends Message<UpdateTopicResponse> {
  /**
   * @generated from field: nozzle.workspaces.v1.Topic topic = 1;
   */
  topic?: Topic;

  constructor(data?: PartialMessage<UpdateTopicResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.UpdateTopicResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "topic", kind: "message", T: Topic },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateTopicResponse {
    return new UpdateTopicResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateTopicResponse {
    return new UpdateTopicResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateTopicResponse {
    return new UpdateTopicResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateTopicResponse | PlainMessage<UpdateTopicResponse> | undefined, b: UpdateTopicResponse | PlainMessage<UpdateTopicResponse> | undefined): boolean {
    return proto3.util.equals(UpdateTopicResponse, a, b);
  }
}

/**
 * BatchUpdateTopicsRequest is the request for the BatchUpdateTopic method.
 *
 * @generated from message nozzle.workspaces.v1.BatchUpdateTopicsRequest
 */
export class BatchUpdateTopicsRequest extends Message<BatchUpdateTopicsRequest> {
  /**
   * @generated from field: repeated nozzle.workspaces.v1.Topic topics = 1;
   */
  topics: Topic[] = [];

  /**
   * If true, the request will succeed even if the topic does not exist. If false, the request will fail
   * if topic_id is not set or if the referenced topic_id does not exist.
   *
   * @generated from field: bool allow_missing = 2;
   */
  allowMissing = false;

  constructor(data?: PartialMessage<BatchUpdateTopicsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.BatchUpdateTopicsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "topics", kind: "message", T: Topic, repeated: true },
    { no: 2, name: "allow_missing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BatchUpdateTopicsRequest {
    return new BatchUpdateTopicsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BatchUpdateTopicsRequest {
    return new BatchUpdateTopicsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BatchUpdateTopicsRequest {
    return new BatchUpdateTopicsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: BatchUpdateTopicsRequest | PlainMessage<BatchUpdateTopicsRequest> | undefined, b: BatchUpdateTopicsRequest | PlainMessage<BatchUpdateTopicsRequest> | undefined): boolean {
    return proto3.util.equals(BatchUpdateTopicsRequest, a, b);
  }
}

/**
 * BatchUpdateTopicsResponse is the response for the BatchUpdateTopic method.
 *
 * @generated from message nozzle.workspaces.v1.BatchUpdateTopicsResponse
 */
export class BatchUpdateTopicsResponse extends Message<BatchUpdateTopicsResponse> {
  /**
   * @generated from field: repeated nozzle.workspaces.v1.Topic topics = 1;
   */
  topics: Topic[] = [];

  constructor(data?: PartialMessage<BatchUpdateTopicsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.BatchUpdateTopicsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "topics", kind: "message", T: Topic, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BatchUpdateTopicsResponse {
    return new BatchUpdateTopicsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BatchUpdateTopicsResponse {
    return new BatchUpdateTopicsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BatchUpdateTopicsResponse {
    return new BatchUpdateTopicsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: BatchUpdateTopicsResponse | PlainMessage<BatchUpdateTopicsResponse> | undefined, b: BatchUpdateTopicsResponse | PlainMessage<BatchUpdateTopicsResponse> | undefined): boolean {
    return proto3.util.equals(BatchUpdateTopicsResponse, a, b);
  }
}

/**
 * GetTopicRequest is the request for the GetTopic method.
 *
 * @generated from message nozzle.workspaces.v1.GetTopicRequest
 */
export class GetTopicRequest extends Message<GetTopicRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: int64 project_id = 2;
   */
  projectId = protoInt64.zero;

  /**
   * @generated from field: int64 topic_id = 3;
   */
  topicId = protoInt64.zero;

  /**
   * @generated from field: nozzle.workspaces.v1.TopicView view = 4;
   */
  view = TopicView.UNSPECIFIED;

  /**
   * @generated from field: nozzle.workspaces.v1.TopicViewOptions view_options = 5;
   */
  viewOptions?: TopicViewOptions;

  constructor(data?: PartialMessage<GetTopicRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.GetTopicRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "project_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "topic_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "view", kind: "enum", T: proto3.getEnumType(TopicView) },
    { no: 5, name: "view_options", kind: "message", T: TopicViewOptions },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTopicRequest {
    return new GetTopicRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTopicRequest {
    return new GetTopicRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTopicRequest {
    return new GetTopicRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetTopicRequest | PlainMessage<GetTopicRequest> | undefined, b: GetTopicRequest | PlainMessage<GetTopicRequest> | undefined): boolean {
    return proto3.util.equals(GetTopicRequest, a, b);
  }
}

/**
 * GetTopicResponse is the response for the GetTopic method.
 *
 * @generated from message nozzle.workspaces.v1.GetTopicResponse
 */
export class GetTopicResponse extends Message<GetTopicResponse> {
  /**
   * @generated from field: nozzle.workspaces.v1.Topic topic = 1;
   */
  topic?: Topic;

  constructor(data?: PartialMessage<GetTopicResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.GetTopicResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "topic", kind: "message", T: Topic },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTopicResponse {
    return new GetTopicResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTopicResponse {
    return new GetTopicResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTopicResponse {
    return new GetTopicResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetTopicResponse | PlainMessage<GetTopicResponse> | undefined, b: GetTopicResponse | PlainMessage<GetTopicResponse> | undefined): boolean {
    return proto3.util.equals(GetTopicResponse, a, b);
  }
}

/**
 * BatchGetTopicsRequest is the request for the BatchGetTopics method.
 *
 * @generated from message nozzle.workspaces.v1.BatchGetTopicsRequest
 */
export class BatchGetTopicsRequest extends Message<BatchGetTopicsRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: int64 project_id = 2;
   */
  projectId = protoInt64.zero;

  /**
   * @generated from field: repeated int64 topic_ids = 3;
   */
  topicIds: bigint[] = [];

  /**
   * @generated from field: nozzle.workspaces.v1.TopicView view = 4;
   */
  view = TopicView.UNSPECIFIED;

  /**
   * @generated from field: nozzle.workspaces.v1.TopicViewOptions view_options = 5;
   */
  viewOptions?: TopicViewOptions;

  constructor(data?: PartialMessage<BatchGetTopicsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.BatchGetTopicsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "project_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "topic_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 4, name: "view", kind: "enum", T: proto3.getEnumType(TopicView) },
    { no: 5, name: "view_options", kind: "message", T: TopicViewOptions },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BatchGetTopicsRequest {
    return new BatchGetTopicsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BatchGetTopicsRequest {
    return new BatchGetTopicsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BatchGetTopicsRequest {
    return new BatchGetTopicsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: BatchGetTopicsRequest | PlainMessage<BatchGetTopicsRequest> | undefined, b: BatchGetTopicsRequest | PlainMessage<BatchGetTopicsRequest> | undefined): boolean {
    return proto3.util.equals(BatchGetTopicsRequest, a, b);
  }
}

/**
 * BatchGetTopicsResponse is the response for the BatchGetTopics method.
 *
 * @generated from message nozzle.workspaces.v1.BatchGetTopicsResponse
 */
export class BatchGetTopicsResponse extends Message<BatchGetTopicsResponse> {
  /**
   * @generated from field: repeated nozzle.workspaces.v1.Topic topics = 1;
   */
  topics: Topic[] = [];

  constructor(data?: PartialMessage<BatchGetTopicsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.BatchGetTopicsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "topics", kind: "message", T: Topic, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BatchGetTopicsResponse {
    return new BatchGetTopicsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BatchGetTopicsResponse {
    return new BatchGetTopicsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BatchGetTopicsResponse {
    return new BatchGetTopicsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: BatchGetTopicsResponse | PlainMessage<BatchGetTopicsResponse> | undefined, b: BatchGetTopicsResponse | PlainMessage<BatchGetTopicsResponse> | undefined): boolean {
    return proto3.util.equals(BatchGetTopicsResponse, a, b);
  }
}

/**
 * ListTopicsRequest is the request for the ListTopics method.
 *
 * @generated from message nozzle.workspaces.v1.ListTopicsRequest
 */
export class ListTopicsRequest extends Message<ListTopicsRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: int64 project_id = 2;
   */
  projectId = protoInt64.zero;

  /**
   * @generated from field: nozzle.workspaces.v1.TopicView view = 3;
   */
  view = TopicView.UNSPECIFIED;

  /**
   * @generated from field: nozzle.workspaces.v1.TopicViewOptions view_options = 4;
   */
  viewOptions?: TopicViewOptions;

  /**
   * if set, will only return topics without a parent_topic_id
   *
   * @generated from field: bool root_only = 5;
   */
  rootOnly = false;

  /**
   * @generated from field: nozzle.workspaces.v1.ListTopicsFilters filters = 6;
   */
  filters?: ListTopicsFilters;

  constructor(data?: PartialMessage<ListTopicsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.ListTopicsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "project_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "view", kind: "enum", T: proto3.getEnumType(TopicView) },
    { no: 4, name: "view_options", kind: "message", T: TopicViewOptions },
    { no: 5, name: "root_only", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "filters", kind: "message", T: ListTopicsFilters },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListTopicsRequest {
    return new ListTopicsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListTopicsRequest {
    return new ListTopicsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListTopicsRequest {
    return new ListTopicsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListTopicsRequest | PlainMessage<ListTopicsRequest> | undefined, b: ListTopicsRequest | PlainMessage<ListTopicsRequest> | undefined): boolean {
    return proto3.util.equals(ListTopicsRequest, a, b);
  }
}

/**
 * ListTopicsFilters is a set of available filters to apply to the list request.
 *
 * @generated from message nozzle.workspaces.v1.ListTopicsFilters
 */
export class ListTopicsFilters extends Message<ListTopicsFilters> {
  /**
   * @generated from field: string search = 1;
   */
  search = "";

  /**
   * @generated from field: repeated int32 include_priorities = 2;
   */
  includePriorities: number[] = [];

  /**
   * @generated from field: repeated int32 exclude_priorities = 3;
   */
  excludePriorities: number[] = [];

  /**
   * @generated from field: nozzle.workspaces.v1.ListMonitoredUrlsFilters primary_mapped_url_filters = 4;
   */
  primaryMappedUrlFilters?: ListMonitoredUrlsFilters;

  /**
   * @generated from field: nozzle.workspaces.v1.ListMonitoredUrlsFilters secondary_mapped_url_filters = 5;
   */
  secondaryMappedUrlFilters?: ListMonitoredUrlsFilters;

  /**
   * only return topics where the number of topic keywords is greater than or equal to min_keyword_count
   *
   * @generated from field: int32 min_keyword_count = 6;
   */
  minKeywordCount = 0;

  /**
   * only return topics where the number of topic keywords is less than or equal to max_keyword_count
   *
   * @generated from field: int32 max_keyword_count = 7;
   */
  maxKeywordCount = 0;

  /**
   * @generated from field: nozzle.workspaces.v1.ListKeywordsFilters mapped_keywords_filters = 8;
   */
  mappedKeywordsFilters?: ListKeywordsFilters;

  constructor(data?: PartialMessage<ListTopicsFilters>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.ListTopicsFilters";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "search", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "include_priorities", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 3, name: "exclude_priorities", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 4, name: "primary_mapped_url_filters", kind: "message", T: ListMonitoredUrlsFilters },
    { no: 5, name: "secondary_mapped_url_filters", kind: "message", T: ListMonitoredUrlsFilters },
    { no: 6, name: "min_keyword_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "max_keyword_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "mapped_keywords_filters", kind: "message", T: ListKeywordsFilters },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListTopicsFilters {
    return new ListTopicsFilters().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListTopicsFilters {
    return new ListTopicsFilters().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListTopicsFilters {
    return new ListTopicsFilters().fromJsonString(jsonString, options);
  }

  static equals(a: ListTopicsFilters | PlainMessage<ListTopicsFilters> | undefined, b: ListTopicsFilters | PlainMessage<ListTopicsFilters> | undefined): boolean {
    return proto3.util.equals(ListTopicsFilters, a, b);
  }
}

/**
 * ListTopicsResponse is the response for the ListTopics method.
 *
 * @generated from message nozzle.workspaces.v1.ListTopicsResponse
 */
export class ListTopicsResponse extends Message<ListTopicsResponse> {
  /**
   * @generated from field: repeated nozzle.workspaces.v1.Topic topics = 1;
   */
  topics: Topic[] = [];

  constructor(data?: PartialMessage<ListTopicsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.ListTopicsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "topics", kind: "message", T: Topic, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListTopicsResponse {
    return new ListTopicsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListTopicsResponse {
    return new ListTopicsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListTopicsResponse {
    return new ListTopicsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListTopicsResponse | PlainMessage<ListTopicsResponse> | undefined, b: ListTopicsResponse | PlainMessage<ListTopicsResponse> | undefined): boolean {
    return proto3.util.equals(ListTopicsResponse, a, b);
  }
}

/**
 * DeleteTopicRequest is the request for the DeleteTopic method.
 *
 * @generated from message nozzle.workspaces.v1.DeleteTopicRequest
 */
export class DeleteTopicRequest extends Message<DeleteTopicRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: int64 project_id = 2;
   */
  projectId = protoInt64.zero;

  /**
   * @generated from field: int64 topic_id = 3;
   */
  topicId = protoInt64.zero;

  constructor(data?: PartialMessage<DeleteTopicRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.DeleteTopicRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "project_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "topic_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteTopicRequest {
    return new DeleteTopicRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteTopicRequest {
    return new DeleteTopicRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteTopicRequest {
    return new DeleteTopicRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteTopicRequest | PlainMessage<DeleteTopicRequest> | undefined, b: DeleteTopicRequest | PlainMessage<DeleteTopicRequest> | undefined): boolean {
    return proto3.util.equals(DeleteTopicRequest, a, b);
  }
}

/**
 * DeleteTopicResponse is the response for the DeleteTopic method.
 *
 * @generated from message nozzle.workspaces.v1.DeleteTopicResponse
 */
export class DeleteTopicResponse extends Message<DeleteTopicResponse> {
  constructor(data?: PartialMessage<DeleteTopicResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.DeleteTopicResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteTopicResponse {
    return new DeleteTopicResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteTopicResponse {
    return new DeleteTopicResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteTopicResponse {
    return new DeleteTopicResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteTopicResponse | PlainMessage<DeleteTopicResponse> | undefined, b: DeleteTopicResponse | PlainMessage<DeleteTopicResponse> | undefined): boolean {
    return proto3.util.equals(DeleteTopicResponse, a, b);
  }
}

/**
 * BatchUpdateTopicsRequest is the request for the BatchUpdateTopic method.
 *
 * @generated from message nozzle.workspaces.v1.BatchDeleteTopicsRequest
 */
export class BatchDeleteTopicsRequest extends Message<BatchDeleteTopicsRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: int64 project_id = 2;
   */
  projectId = protoInt64.zero;

  /**
   * @generated from field: repeated int64 topic_ids = 3;
   */
  topicIds: bigint[] = [];

  constructor(data?: PartialMessage<BatchDeleteTopicsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.BatchDeleteTopicsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "project_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "topic_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BatchDeleteTopicsRequest {
    return new BatchDeleteTopicsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BatchDeleteTopicsRequest {
    return new BatchDeleteTopicsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BatchDeleteTopicsRequest {
    return new BatchDeleteTopicsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: BatchDeleteTopicsRequest | PlainMessage<BatchDeleteTopicsRequest> | undefined, b: BatchDeleteTopicsRequest | PlainMessage<BatchDeleteTopicsRequest> | undefined): boolean {
    return proto3.util.equals(BatchDeleteTopicsRequest, a, b);
  }
}

/**
 * BatchDeleteTopicsResponse is the response for the BatchDeleteTopic method.
 *
 * @generated from message nozzle.workspaces.v1.BatchDeleteTopicsResponse
 */
export class BatchDeleteTopicsResponse extends Message<BatchDeleteTopicsResponse> {
  constructor(data?: PartialMessage<BatchDeleteTopicsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.BatchDeleteTopicsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BatchDeleteTopicsResponse {
    return new BatchDeleteTopicsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BatchDeleteTopicsResponse {
    return new BatchDeleteTopicsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BatchDeleteTopicsResponse {
    return new BatchDeleteTopicsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: BatchDeleteTopicsResponse | PlainMessage<BatchDeleteTopicsResponse> | undefined, b: BatchDeleteTopicsResponse | PlainMessage<BatchDeleteTopicsResponse> | undefined): boolean {
    return proto3.util.equals(BatchDeleteTopicsResponse, a, b);
  }
}

/**
 * A Topic is a collection of keywords that are related to each other and mapped to a page on your site.
 * Topics are used to organize keywords into groups that can be used for reporting and analysis. We
 * aren't storing reporting data here on purpose, as we will display up to date data from the reporting
 * service when you view a topic.
 *
 * Topics may often be created from clustering keyword research data, but they can also be created manually.
 * Once a cluster is promoted to a topic, it can be edited and keywords can be added or removed, and any
 * links back to the original cluster will be removed.
 *
 * @generated from message nozzle.workspaces.v1.Topic
 */
export class Topic extends Message<Topic> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: int64 project_id = 2;
   */
  projectId = protoInt64.zero;

  /**
   * @generated from field: int64 id = 3;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: int64 version_id = 4;
   */
  versionId = protoInt64.zero;

  /**
   * optional parent topic id for subtopics
   *
   * @generated from field: int64 parent_topic_id = 5;
   */
  parentTopicId = protoInt64.zero;

  /**
   * returns all ancestors of this topic, not including the current topic, ordered from furthest to closest
   *
   * @generated from field: repeated nozzle.workspaces.v1.Topic ancestors = 15;
   */
  ancestors: Topic[] = [];

  /**
   * may optionally contain all subtopics of this topic, recursively to a max depth
   *
   * @generated from field: repeated nozzle.workspaces.v1.Topic children = 16;
   */
  children: Topic[] = [];

  /**
   * @generated from field: string name = 6;
   */
  name = "";

  /**
   * @generated from field: string description = 7;
   */
  description = "";

  /**
   * arbitrary priority score between 1 and 5, with 1 being the highest priority and 5 being the lowest.
   * 0 is treated as unspecified.
   *
   * @generated from field: int32 priority = 14;
   */
  priority = 0;

  /**
   * like keyword groups / tags, this enables us to group topics arbitrarily, e.g.
   * Product: product1, product2, product3
   * Business Unit: business_unit1, business_unit2, business_unit3
   * Relevance: High, Medium, Low
   *
   * For reports that only support keyword groups, labels will be available as a keyword group,
   * formatted as "name: value"
   *
   * @generated from field: repeated nozzle.protobuf.Label labels = 8;
   */
  labels: Label[] = [];

  /**
   * generally a topic will have a single preferred url, but sometimes it will have multiple without
   * keyword cannibalization, e.g. ranking both a blog post and a product page for a keyword
   *
   * in the event that you have multiple brands, you can specify a primary/secondary url for each brand,
   * e.g. wayfair.com and allmodern.com
   *
   * there are no duplicate brand ids allowed in this list
   *
   * @generated from field: repeated nozzle.workspaces.v1.MappedUrlsByBrand mapped_urls_by_brand = 17;
   */
  mappedUrlsByBrand: MappedUrlsByBrand[] = [];

  /**
   * After keyword research, choose which keywords to map to this topic. Generally a keyword
   * should only be mapped to a single topic.
   *
   * there are no duplicate keyword ids allowed in this list
   *
   * @generated from field: repeated nozzle.workspaces.v1.MappedKeyword mapped_keywords = 10;
   */
  mappedKeywords: MappedKeyword[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 11;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 12;
   */
  updatedAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp deleted_at = 13;
   */
  deletedAt?: Timestamp;

  constructor(data?: PartialMessage<Topic>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.Topic";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "project_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "version_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "parent_topic_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 15, name: "ancestors", kind: "message", T: Topic, repeated: true },
    { no: 16, name: "children", kind: "message", T: Topic, repeated: true },
    { no: 6, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "priority", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "labels", kind: "message", T: Label, repeated: true },
    { no: 17, name: "mapped_urls_by_brand", kind: "message", T: MappedUrlsByBrand, repeated: true },
    { no: 10, name: "mapped_keywords", kind: "message", T: MappedKeyword, repeated: true },
    { no: 11, name: "created_at", kind: "message", T: Timestamp },
    { no: 12, name: "updated_at", kind: "message", T: Timestamp },
    { no: 13, name: "deleted_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Topic {
    return new Topic().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Topic {
    return new Topic().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Topic {
    return new Topic().fromJsonString(jsonString, options);
  }

  static equals(a: Topic | PlainMessage<Topic> | undefined, b: Topic | PlainMessage<Topic> | undefined): boolean {
    return proto3.util.equals(Topic, a, b);
  }
}

/**
 * TopicViewOptions sets recursive depth and limits for ancestors and descendents.
 *
 * @generated from message nozzle.workspaces.v1.TopicViewOptions
 */
export class TopicViewOptions extends Message<TopicViewOptions> {
  /**
   * @generated from field: nozzle.workspaces.v1.TopicView view = 1;
   */
  view = TopicView.UNSPECIFIED;

  /**
   * if set, will include all ancestors of the topics, recursively to the specified depth.
   * The default is 0, which will not include any ancestors, and 1 only includes the immediate parent.
   * If set to -1, will include all ancestors.
   *
   * @generated from field: int32 ancestor_depth = 2;
   */
  ancestorDepth = 0;

  /**
   * this is the maximum number of ancestors to return, regardless of depth.
   *
   * @generated from field: int32 ancestor_limit = 3;
   */
  ancestorLimit = 0;

  /**
   * if set, will include all children of the topics, recursively to the specified depth.
   * The default is 0, which will not include any children, and 1 only includes the immediate children.
   * If set to -1, will include all children.
   *
   * @generated from field: int32 descendent_depth = 4;
   */
  descendentDepth = 0;

  /**
   * this is the maximum number of descendents to return, regardless of depth.
   *
   * @generated from field: int32 descendent_limit = 5;
   */
  descendentLimit = 0;

  constructor(data?: PartialMessage<TopicViewOptions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.TopicViewOptions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "view", kind: "enum", T: proto3.getEnumType(TopicView) },
    { no: 2, name: "ancestor_depth", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "ancestor_limit", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "descendent_depth", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "descendent_limit", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopicViewOptions {
    return new TopicViewOptions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopicViewOptions {
    return new TopicViewOptions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopicViewOptions {
    return new TopicViewOptions().fromJsonString(jsonString, options);
  }

  static equals(a: TopicViewOptions | PlainMessage<TopicViewOptions> | undefined, b: TopicViewOptions | PlainMessage<TopicViewOptions> | undefined): boolean {
    return proto3.util.equals(TopicViewOptions, a, b);
  }
}

/**
 * MappedKeyword is a keyword that has been mapped to a topic.
 *
 * @generated from message nozzle.workspaces.v1.MappedKeyword
 */
export class MappedKeyword extends Message<MappedKeyword> {
  /**
   * @generated from field: int64 keyword_id = 1;
   */
  keywordId = protoInt64.zero;

  /**
   * @generated from field: nozzle.keywords.v1.Keyword keyword = 2;
   */
  keyword?: Keyword;

  constructor(data?: PartialMessage<MappedKeyword>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.MappedKeyword";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "keyword_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "keyword", kind: "message", T: Keyword },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MappedKeyword {
    return new MappedKeyword().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MappedKeyword {
    return new MappedKeyword().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MappedKeyword {
    return new MappedKeyword().fromJsonString(jsonString, options);
  }

  static equals(a: MappedKeyword | PlainMessage<MappedKeyword> | undefined, b: MappedKeyword | PlainMessage<MappedKeyword> | undefined): boolean {
    return proto3.util.equals(MappedKeyword, a, b);
  }
}

/**
 * MappedUrlsByBrand is a preferred url for a single brand to rank for a topic. A primary monitored url is required,
 * and a secondary monitored url is optional. Both urls must belong to the same brand, matching the brand_id.
 *
 * @generated from message nozzle.workspaces.v1.MappedUrlsByBrand
 */
export class MappedUrlsByBrand extends Message<MappedUrlsByBrand> {
  /**
   * @generated from field: int64 brand_id = 1;
   */
  brandId = protoInt64.zero;

  /**
   * @generated from field: nozzle.workspaces.v1.MappedUrl primary_mapped_url = 2;
   */
  primaryMappedUrl?: MappedUrl;

  /**
   * @generated from field: nozzle.workspaces.v1.MappedUrl secondary_mapped_url = 3;
   */
  secondaryMappedUrl?: MappedUrl;

  constructor(data?: PartialMessage<MappedUrlsByBrand>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.MappedUrlsByBrand";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "brand_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "primary_mapped_url", kind: "message", T: MappedUrl },
    { no: 3, name: "secondary_mapped_url", kind: "message", T: MappedUrl },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MappedUrlsByBrand {
    return new MappedUrlsByBrand().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MappedUrlsByBrand {
    return new MappedUrlsByBrand().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MappedUrlsByBrand {
    return new MappedUrlsByBrand().fromJsonString(jsonString, options);
  }

  static equals(a: MappedUrlsByBrand | PlainMessage<MappedUrlsByBrand> | undefined, b: MappedUrlsByBrand | PlainMessage<MappedUrlsByBrand> | undefined): boolean {
    return proto3.util.equals(MappedUrlsByBrand, a, b);
  }
}

/**
 * MappedUrl is a preferred url to rank for a topic.
 *
 * @generated from message nozzle.workspaces.v1.MappedUrl
 */
export class MappedUrl extends Message<MappedUrl> {
  /**
   * @generated from field: int64 monitored_url_id = 1;
   */
  monitoredUrlId = protoInt64.zero;

  /**
   * @generated from field: nozzle.workspaces.v1.MonitoredUrl monitored_url = 2;
   */
  monitoredUrl?: MonitoredUrl;

  constructor(data?: PartialMessage<MappedUrl>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.MappedUrl";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "monitored_url_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "monitored_url", kind: "message", T: MonitoredUrl },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MappedUrl {
    return new MappedUrl().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MappedUrl {
    return new MappedUrl().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MappedUrl {
    return new MappedUrl().fromJsonString(jsonString, options);
  }

  static equals(a: MappedUrl | PlainMessage<MappedUrl> | undefined, b: MappedUrl | PlainMessage<MappedUrl> | undefined): boolean {
    return proto3.util.equals(MappedUrl, a, b);
  }
}

