// @generated by protoc-gen-connect-web v0.11.0 with parameter "target=ts"
// @generated from file nozzle/workspaces/v1/ranking.proto (package nozzle.workspaces.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { RequestRankingRequest, RequestRankingResponse } from "./ranking_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * RankingsService provides APIs for interacting with billing subscriptions.
 *
 * @generated from service nozzle.workspaces.v1.RankingsService
 */
export const RankingsService = {
  typeName: "nozzle.workspaces.v1.RankingsService",
  methods: {
    /**
     * RequestRanking creates a new billing account
     *
     * @generated from rpc nozzle.workspaces.v1.RankingsService.RequestRanking
     */
    requestRanking: {
      name: "RequestRanking",
      I: RequestRankingRequest,
      O: RequestRankingResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

