//
import { twMerge } from 'tailwind-merge'
import { withProps } from '../utils/withProps'
import ErrorBoundary from './ErrorBoundary'

export const DashboardContainerStyles = `
  p-2 overflow-x-hidden overflow-y-auto space-y-2
`

const DashboardContainer = withProps(props => (
  <ErrorBoundary>
    <div {...props} />
  </ErrorBoundary>
))(({ className, ...props }) => ({
  ...props,
  className: twMerge(DashboardContainerStyles, className),
}))

export default DashboardContainer
