import { ColumnDef } from '@tanstack/react-table'
import * as React from 'react'
import { FaPlus, FaSearch, FaTimesCircle } from 'react-icons/fa'
import useModalContext from '../hooks/useModalContext'
import { useTable } from '../hooks/useTable'
import useYouTubeVideos from '../hooks/useYoutubeVideos'
import { YoutubeVideo } from '../utils/Api'
import Button from './Button'
import Input from './Input'
import { Modal, ModalContent, ModalTitle } from './Modals'
import QueryGate from './QueryGate'
import Separator from './Separator'
import Table from './Table'
import { createCheckboxColumn } from './CheckboxColumn'
import { GiPartyPopper } from 'react-icons/gi'

export default function YouTubeModal(props: {
  properties: any[]
  onSubmit: (videos: YoutubeVideo[]) => void
}) {
  const { close } = useModalContext()
  const [resolvedUsername, setResolvedUsername] = React.useState('')
  const [username, setUsername] = React.useState('')
  const videoQuery = useYouTubeVideos(resolvedUsername)

  const columns = React.useMemo<ColumnDef<any, unknown>[]>(
    () => [
      createCheckboxColumn(),
      {
        header: 'Thumbnail',
        accessorKey: 'snippet.thumbnails.default',
        cell: props => {
          const value = props.getValue<{ url: string }>()
          return <img src={value.url} alt={value.url} />
        },
      },
      {
        header: 'Title',
        accessorKey: 'snippet.title',
        filterFn: 'fuzzy',
        cell: props => {
          const original = props.row.original
          return (
            <>
              <div className="mb-1">{props.getValue()}</div>
              <a
                href={`https://www.youtube.com/watch?v=${original.id.videoId}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                {`https://www.youtube.com/watch?v=${original.id.videoId}`}
              </a>
            </>
          )
        },
      },
      {
        header: 'Channel',
        accessorKey: 'snippet.channelTitle',
        filterFn: 'fuzzy',
      },
    ],
    []
  )

  const tableData = React.useMemo(() => {
    return (videoQuery.data?.videos ?? []).filter(video => {
      return !props.properties.find(d => d.value === video.id.videoId)
    })
  }, [props.properties, videoQuery.data?.videos])

  const table = useTable({
    data: tableData,
    columns,
    showToolbar: false,
  })

  const handleSubmit = async () => {
    props.onSubmit(
      table
        .getSelectedRowModel()
        .rows.map(row => row.original) as YoutubeVideo[]
    )

    close()
  }

  return (
    <Modal>
      <ModalTitle>Import YouTube Video URLs</ModalTitle>
      <ModalContent>
        <div>
          <form
            onSubmit={e => {
              e.preventDefault()
              setResolvedUsername(username)
            }}
          >
            <div className="flex flex-wrap items-center gap-2 p-4">
              <Input
                value={username}
                // @ts-expect-error  // Property 'value' does not exist on type 'EventTarg... Remove this comment to see the full error message
                onChange={e => setUsername(e.target.value)}
                placeholder="Youtube UserName or Channel ID"
                className="w-80 flex-none"
              />
              <Button size="sm" color="blue-500" type="submit">
                <FaSearch className="inline" /> Search
              </Button>
            </div>
          </form>
          <Separator />
          {videoQuery.isIdle ? null : (
            <QueryGate query={videoQuery} className="m-4">
              {() =>
                tableData.length ? (
                  <Table table={table} />
                ) : videoQuery.data?.videos?.length ? (
                  <div className="p-4 italic opacity-50">
                    All videos have already been imported!{' '}
                    <GiPartyPopper className="inline" />
                  </div>
                ) : (
                  <div className="p-4 italic opacity-50">No videos Found.</div>
                )
              }
            </QueryGate>
          )}
          <Separator />
          <div className="flex flex-wrap items-center gap-2 p-4">
            <Button
              size="base"
              color="green-500"
              onClick={handleSubmit}
              disabled={table.getSelectedRowModel().rows.length < 1}
            >
              <FaPlus className="inline" /> Add{' '}
              {table.getSelectedRowModel().rows.length} Videos
            </Button>
            <Button
              size="base"
              color="gray-500"
              hoverColor="red-500"
              onClick={close}
            >
              <FaTimesCircle className="inline" /> Cancel
            </Button>
          </div>
        </div>
      </ModalContent>
    </Modal>
  )
}
