import Card from '../../components/Card'
import { useNavigate, useSearch } from '@tanstack/react-router'

import {
  FacetFilters,
  UiFilter,
  filtersPbFromSearch,
} from '../../components/FacetFilters'
import { functionalUpdate } from '../../utils'
import { queryKeyTopicsFacets } from '../../utils/Constants'
import { useQuery } from 'react-query'
import { ListTopicsFiltersPb, TopicsClient } from '../../utils/proto'

// const filterOptions =

export function TopicsFilters({
  workspaceId,
  projectId,
}: {
  workspaceId: string
  projectId: string
}) {
  const search = useSearch()
  const navigate = useNavigate()

  const facetsQuery = useQuery({
    queryKey: [queryKeyTopicsFacets, search.topicsFilters],
    enabled: Boolean(workspaceId && projectId),
    queryFn: () => {
      // return TopicsClient.listTopicsFacets({
      //   workspaceId: BigInt(workspaceId),
      //   projectId: BigInt(projectId),
      //   filters: {
      //     ...filtersPbFromSearch<
      //       Record<string, ListTopicsFiltersPb[keyof ListTopicsFiltersPb]>
      //     >(topicFiltersList, search.topicsFilters || {}),
      //     search: (search.topicsFilters?.search ?? '') as string,
      //   },
      // })
    },
    retry: false,
    staleTime: Infinity,
    cacheTime: Infinity,
    keepPreviousData: true,
  })

  return (
    <Card className="flex h-full w-[20vw] min-w-[200px] max-w-[300px] flex-col divide-y divide-gray-500/20 overflow-auto overflow-y-hidden p-0">
      <FacetFilters
        facets={facetsQuery.data}
        filterState={search.topicsFilters}
        filters={topicFiltersList}
        isLoading={false}
        labelPlural="Topics"
        searchKey="search"
        onFilterStateChange={updater => {
          navigate({
            search: prev => ({
              ...prev,
              topicsFilters: functionalUpdate(updater, prev.topicsFilters),
            }),
            replace: true,
          })
        }}
      />
    </Card>
  )
}

export const topicFilters = {
  priority: {
    id: 'priority',
    label: 'Priority',
    ui: 'multi-select',
    type: 'string',
    keys: ['includePriorities', 'excludePriorities'],
    facetKey: 'priorities',
    showBins: true,
  },
  brandId: {
    id: 'brandId',
    label: 'Brands',
    ui: 'multi-select',
    type: 'number',
    keys: ['includeBrandIds', 'excludeBrandIds'],
    facetKey: 'brandIds',
    showBins: true,
  },
  domainId: {
    id: 'domainId',
    label: 'Domains',
    ui: 'multi-select',
    type: 'number',
    keys: ['includeDomainIds', 'excludeDomainIds'],
    facetKey: 'domainIds',
    showBins: true,
  },
  urlId: {
    id: 'urlId',
    label: 'URLs',
    ui: 'multi-select',
    type: 'number',
    keys: ['includeUrlIds', 'excludeUrlIds'],
    facetKey: 'urlIds',
    showBins: true,
  },
  keywordId: {
    id: 'keywordId',
    label: 'Keywords',
    ui: 'multi-select',
    type: 'number',
    keys: ['includeKeywordIds', 'excludeKeywordIds'],
    facetKey: 'keywordIds',
    showBins: true,
  },
} satisfies Record<string, UiFilter>

export const topicFiltersList = [
  topicFilters.priority,
  topicFilters.brandId,
  topicFilters.domainId,
  topicFilters.urlId,
  topicFilters.keywordId,
]
