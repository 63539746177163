import * as Plot from '@observablehq/plot'
import { useNavigate, useSearch } from '@tanstack/react-router'
import * as React from 'react'
import { UseQueryResult, useQueryClient } from 'react-query'
import {
  formatRankGroup,
  functionalUpdate,
  getRankGroupFromRank,
} from '../../utils'
import { clusterFocusMetricsList, clusterMetricsList, metrics } from './metrics'
import { ClusterPb, ListClustersResponsePb } from '../../utils/proto'

import { rankGroupColors } from '../../utils/DataColors'
import { queryKeyClusters } from '../../utils/Constants'
import { rankGroupOptions } from '../../options/rankGroupOptions'
import {
  BubbleChartState,
  BubbleChartAccessor,
  BubbleChart,
} from '../../components/BubbleChart'

export const defaultState: BubbleChartState = {
  x: 'keywordCount',
  y: 'trafficOpportunity',
  r: 'cpc',
  fx: '',
  fy: '',
  color: 'topRank',
}

const rankScaleOptions = {
  type: 'linear',
  domain: [101, 100, 50, 20, 10, 5, 3, 2, 1],
  range: rankGroupColors,
  reverse: true,
  interpolate: 'hcl',
  ticks: [101, 100, 50, 20, 10, 5, 3, 2, 1],
} as const

const rankGroupScaleOptions: Plot.ScaleOptions = {
  type: 'ordinal',
  domain: rankGroupOptions.map(d => d.label),
  range: rankGroupColors,
  // interpolate: 'hcl',
} as const

const linearScaleOptions: Plot.ScaleOptions = {
  tickFormat: '~s',
}

const accessors: BubbleChartAccessor<ClusterPb>[] = [
  {
    label: 'Cluster Keyword Count',
    id: 'keywordCount',
    accessor: d => d.keywordCount,
    scaleOptions: linearScaleOptions,
  },
  {
    label: 'Cluster URL Count',
    id: 'urlCount',
    accessor: d => d.urlCount,
    scaleOptions: linearScaleOptions,
  },
  ...clusterMetricsList.map((metric): BubbleChartAccessor<ClusterPb> => {
    return {
      label: `Cluster ${metric.label}`,
      id: metric.id,
      accessor: d => metric.getValue(d.clusterMetrics?.[metric.id]),
      scaleOptions: ['topPaidAdjustedRank', 'topRank'].includes(metric.id)
        ? rankScaleOptions
        : linearScaleOptions,
    }
  }),
  ...clusterFocusMetricsList.map((metric): BubbleChartAccessor<ClusterPb> => {
    return {
      label: `Focused ${metric.label}`,
      id: metric.id,
      accessor: d =>
        metric.getValue(d.focusedRollupMetrics?.metrics?.[metric.id]),
      scaleOptions: ['topPaidAdjustedRank', 'topRank'].includes(metric.id)
        ? rankScaleOptions
        : linearScaleOptions,
    }
  }),
  {
    label: 'Focused Rank Group',
    id: 'rankGroup',
    accessor: d => {
      const group = getRankGroupFromRank(
        metrics.topRank.getValue(d.focusedRollupMetrics?.metrics?.topRank)
      )

      return formatRankGroup(group)
    },
    scaleOptions: rankGroupScaleOptions,
  },
]

export function ClustersViz({
  clusterVizQuery,
  workspaceId,
  projectId,
}: {
  clusterVizQuery: UseQueryResult<ListClustersResponsePb>
  workspaceId: string
  projectId: string
}) {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { clustersBubbleChart } = useSearch()
  const state = React.useMemo(() => {
    return {
      ...defaultState,
      ...clustersBubbleChart,
    }
  }, [clustersBubbleChart])

  const clusters = React.useMemo(
    () => clusterVizQuery.data?.clusters ?? [],
    [clusterVizQuery.data?.clusters]
  )

  const setState = (updater: any) => {
    navigate({
      search: prev => {
        return {
          ...prev,
          clustersBubbleChart: functionalUpdate(
            updater,
            prev?.clustersBubbleChart
          ),
        }
      },
    })
  }

  const onTipHover = (cluster: ClusterPb) => {
    if (!cluster) return

    queryClient.setQueryData(
      [
        queryKeyClusters,
        String(cluster.clusterId),
        {
          workspaceId,
          projectId,
        },
      ],
      cluster,
      { updatedAt: 1 }
    )
  }

  const onTipClick = (cluster: ClusterPb) => {
    navigate({
      search: d => ({
        ...d,
        clusterId: cluster.clusterId,
        urlId: undefined,
        keywordId: undefined,
      }),
    })
  }

  return (
    <div className="p-4">
      <BubbleChart
        data={clusters}
        state={state}
        accessors={accessors}
        setState={setState}
        defaultState={defaultState}
        title="Cluster Traffic Opportunity by Keyword Count"
        isLoading={clusterVizQuery.isFetching}
        totalSize={clusterVizQuery.data?.totalSize ?? 0}
        onTipHover={onTipHover}
        onTipClick={onTipClick}
        labelPlural="Clusters"
      />
    </div>
  )
}
