import Card from '../components/Card'
import { useActiveProjectIdState } from '../utils/searchParams'

export default function DashboardProjectGate({ children }: any) {
  const activeProjectIdState = useActiveProjectIdState()

  return !activeProjectIdState.state ? (
    <>
      <Card className="px-4 py-8 text-center">
        <div className="mx-auto max-w-[400px] text-lg">
          Please select a project.
        </div>
      </Card>
    </>
  ) : (
    children
  )
}
