import * as React from 'react'
import { twMerge } from 'tailwind-merge'

export default function Container({
  children,
  ...props
}: React.HTMLProps<'div'>) {
  return (
    <div
      className={twMerge(
        `mx-auto w-full max-w-screen-2xl p-2`,
        props.className
      )}
      {...Object.keys(props).filter(key => key !== 'className')}
    >
      {children}
    </div>
  )
}
