import * as React from 'react'

export default React.forwardRef<
  HTMLInputElement,
  Omit<React.HTMLAttributes<HTMLInputElement>, 'onChange'> & {
    onChange?: (checked: boolean) => void
    checked?: boolean
    error?: string | boolean
  }
>(function Checkbox(
  { checked, onClick, onChange, className, error, ...rest },
  ref
) {
  return (
    <input
      type="checkbox"
      checked={checked}
      ref={ref}
      onChange={e => {
        // @ts-expect-error  // Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        onChange(e.target.checked)
      }}
      {...rest}
    />
  )
})
