import { useMatch } from '@tanstack/react-router'
import moment from 'moment'
import React from 'react'
import { FaAngleRight, FaArrowLeft } from 'react-icons/fa'
import Card from '../../../components/Card'
import Link from '../../../components/Link'
import Separator from '../../../components/Separator'
import Table from '../../../components/Table'
import { useInvoice } from '../../../hooks/invoices'
import { useTable } from '../../../hooks/useTable'
import { formatNumber } from '../../../utils/Format'

export default function Invoice() {
  const { params } = useMatch()
  const invoiceQuery = useInvoice({
    // @ts-expect-error  // Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    id: params.invoiceId,
  })
  const currencyCode = React.useMemo(
    // @ts-expect-error  // Property 'currencyCode' does not exist on type 'Ge... Remove this comment to see the full error message
    () => invoiceQuery.data?.currencyCode || 'USD',
    [invoiceQuery.data]
  )

  const columns = React.useMemo(
    () => [
      {
        header: 'Description',
        accessorKey: 'description',
      },

      {
        header: 'Quantity',
        accessorKey: 'quantity',
        cell: (props: any) => formatNumber(props.getValue()),
      },

      {
        header: 'Price Per Thousand',
        accessorKey: 'unitMicrocents',
        cell: (props: any) =>
          (props.getValue() / 1e8).toLocaleString(undefined, {
            style: 'currency',
            currency: currencyCode,
          }),
      },
      {
        header: 'Discount',
        accessorKey: 'discountMicropercentage',
        cell: (props: any) =>
          props.getValue() ? <div>{props.getValue() / 1e6}%</div> : '-',
        footer: () => <div className="text-right">Total:</div>,
      },
      {
        header: 'Total',
        accessorKey: 'totalMicrocents',
        cell: (props: any) =>
          props.getValue()
            ? (props.getValue() / 1e8).toLocaleString(undefined, {
                style: 'currency',
                currency: currencyCode,
              })
            : '-',
        footer: () => (
          <div>
            {
              // @ts-expect-error  // Property 'totalMicrocents' does not exist on type ... Remove this comment to see the full error message
              (invoiceQuery.data?.totalMicrocents / 1e8).toLocaleString(
                undefined,
                {
                  style: 'currency',
                  currency: currencyCode,
                }
              )
            }
          </div>
        ),
      },
    ],
    [currencyCode, invoiceQuery.data]
  )

  const tableData = React.useMemo(
    // @ts-expect-error  // Property 'lineItems' does not exist on type 'GetIn... Remove this comment to see the full error message
    () => invoiceQuery.data?.lineItems ?? [],
    // @ts-expect-error  // Property 'lineItems' does not exist on type 'GetIn... Remove this comment to see the full error message
    [invoiceQuery.data?.lineItems]
  )

  const table = useTable({
    data: tableData,
    columns,
    showToolbar: false,
    counter: {
      show: false,
    },
    pagination: {
      show: false,
    },
  })

  // @ts-expect-error  // Property 'date' does not exist on type 'GetInvoice... Remove this comment to see the full error message
  const invoiceDate = moment(invoiceQuery.data?.date * 1000).format(
    'MM/DD/YYYY'
  )

  return (
    <Card className="p-0">
      <div className="flex flex-wrap items-center gap-2 p-4 text-xl font-bold">
        <Link to="../">Invoices</Link>
        <FaAngleRight /> Invoice #{params.invoiceId}
      </div>
      <Separator />
      <div className="mx-2 mb-4 flex items-end justify-between p-4">
        <div>
          <div className="my-0 text-2xl">Nozzle</div>
          <div>10421 S Jordan Gateway # 210, South Jordan, UT 84095</div>
          <div>(855) 669-9531</div>
          <a
            href="https://nozzle.io/contact"
            target="_blank"
            rel="noreferrer"
            className="text-blue-500 hover:underline"
          >
            https://nozzle.io/contact
          </a>
        </div>
        <div className="text-center">
          <img
            src="/img/nozzle-wide.png"
            alt="Nozzle Logo"
            className="mb-2 w-64 rounded bg-blue-500 p-2"
          />

          <div>Invoice Date: {invoiceDate}</div>
        </div>
      </div>
      <Table table={table} />
    </Card>
  )
}
