import { useMutation, useQuery, useQueryClient } from 'react-query'
import robotsParser from 'robots-parser'
import {
  ListMonitoredUrlsFiltersPb,
  MonitoredUrlPb,
  MonitoredUrlViewOptionsPb,
  MonitoredUrlViewPb,
  MonitoredUrlsClient,
  UrlPb,
  PartialMessage,
} from './proto'
import useToast from '../hooks/useToast'
import useErrorPopup from '../hooks/useErrorPopup'
import { fetchViaProxy } from './Api'
import usePopup from '../hooks/usePopup'
import { useActiveWorkspaceId } from '../hooks/workspaces'

export const MONITORED_URL_KEY = 'monitoredUrls'

export type FetchMonitoredUrlsOptions = {
  workspaceId: string
  projectId: string
  rootOnly: boolean
  view: MonitoredUrlViewPb
  viewOptions?: MonitoredUrlViewOptionsPb
  enabled?: boolean
  filters?: Partial<PartialMessage<ListMonitoredUrlsFiltersPb>>
}

export function fetchMonitoredUrls(opts: FetchMonitoredUrlsOptions) {
  return MonitoredUrlsClient.listMonitoredUrls({
    workspaceId: BigInt(opts.workspaceId),
    projectId: BigInt(opts.projectId),
    view: opts.view,
    rootOnly: opts.rootOnly,
    filters: opts.filters,
    viewOptions: new MonitoredUrlViewOptionsPb({
      descendentDepth: 0,
      ancestorDepth: 0,
      ...opts.viewOptions,
    }),
  })
}

export function useMonitoredUrlsQuery(opts: FetchMonitoredUrlsOptions) {
  return useQuery({
    queryKey: [MONITORED_URL_KEY, opts],
    queryFn: () => fetchMonitoredUrls(opts as FetchMonitoredUrlsOptions),
    enabled: !!opts.workspaceId && !!opts.projectId && opts.enabled !== false,
    keepPreviousData: true,
  })
}

export type FetchMonitoredUrlOptions = {
  workspaceId: string
  projectId: string
  monitoredUrlId: string
  view?: MonitoredUrlViewPb
  viewOptions?: MonitoredUrlViewOptionsPb
  enabled?: boolean
}

export function fetchMonitoredUrlById(opts: FetchMonitoredUrlOptions) {
  return MonitoredUrlsClient.getMonitoredUrl({
    workspaceId: BigInt(opts.workspaceId),
    projectId: BigInt(opts.projectId),
    monitoredUrlId: BigInt(opts.monitoredUrlId),
    view: opts.view ?? MonitoredUrlViewPb.BASIC,
    viewOptions:
      opts.viewOptions ??
      new MonitoredUrlViewOptionsPb({
        ancestorDepth: -1,
      }),
  })
}

export function useMonitoredUrlQuery(opts: Partial<FetchMonitoredUrlOptions>) {
  return useQuery({
    queryKey: [MONITORED_URL_KEY, opts.monitoredUrlId, opts],
    queryFn: () => fetchMonitoredUrlById(opts as FetchMonitoredUrlOptions),
    enabled:
      !!opts.workspaceId &&
      !!opts.projectId &&
      !!opts.monitoredUrlId &&
      opts.enabled !== false,
  })
}

export const updateMonitoredUrl = MonitoredUrlsClient.updateMonitoredUrl

export function useUpdateMonitoredUrlMutation() {
  const queryClient = useQueryClient()
  const toast = useToast()
  const errorPopup = useErrorPopup()

  return useMutation(updateMonitoredUrl, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(MONITORED_URL_KEY)
      toast({
        color: 'green-500',
        message: 'Url updated',
      })
    },
    onError: err => {
      errorPopup(err)
    },
  })
}

export function useBatchUpdateMonitoredUrlsMutation() {
  const queryClient = useQueryClient()
  const toast = useToast()
  const errorPopup = useErrorPopup()

  return useMutation(MonitoredUrlsClient.batchUpdateMonitoredUrls, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(MONITORED_URL_KEY)
      toast({
        color: 'green-500',
        message: 'Urls updated',
      })
    },
    onError: err => {
      errorPopup(err)
    },
  })
}

export function useRemoveMonitoredUrlByIdMutation() {
  const queryClient = useQueryClient()
  const toast = useToast()
  const errorPopup = useErrorPopup()

  return useMutation(MonitoredUrlsClient.deleteMonitoredUrl, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(MONITORED_URL_KEY)
      toast({
        color: 'green-500',
        message: 'Url removed',
      })
    },
    onError: err => {
      errorPopup(err)
    },
  })
}

export function useBatchRemoveMonitoredUrlsByIdMutation() {
  const queryClient = useQueryClient()
  const toast = useToast()
  const errorPopup = useErrorPopup()

  return useMutation(MonitoredUrlsClient.batchDeleteMonitoredUrls, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(MONITORED_URL_KEY)
      toast({
        color: 'green-500',
        message: 'Urls removed',
      })
    },
    onError: err => {
      errorPopup(err)
    },
  })
}

export function useUpdateMonitoredUrlsFromSitemapMutation() {
  const queryClient = useQueryClient()
  const toast = useToast()
  const errorPopup = useErrorPopup()

  return useMutation(MonitoredUrlsClient.updateMonitoredUrlsFromSitemap, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(MONITORED_URL_KEY)
      toast({
        color: 'green-500',
        message: 'URLs updated from sitemap',
      })
    },
    onError: err => {
      errorPopup(err)
    },
  })
}

export function useUpdateMonitoredUrlsFromDomainMutation() {
  const queryClient = useQueryClient()
  const toast = useToast()
  const errorPopup = useErrorPopup()

  return useMutation(
    async (variables: {
      workspaceId: string
      projectId: string
      brandId: string
      domain: string
    }) => {
      let sitemaps: string[] = []

      try {
        const robotsHref = new URL('/robots.txt', variables.domain).href
        const { data } = await fetchViaProxy(robotsHref)

        const parsed = robotsParser(robotsHref, data)

        sitemaps = parsed.getSitemaps()
      } catch (err) {
        if (`${err}`.includes('404')) {
          // Ignore 404s. This just means there is no robots.txt
        }

        throw err
      }

      await Promise.all(
        [
          '/sitemap.xml',
          '/sitemap_index.xml',
          '/sitemap.php',
          '/sitemap.txt',
          '/sitemap.xml.gz',
          '/sitemap/',
          '/sitemap/sitemap.xml',
          '/sitemapindex.xml',
          '/sitemap/index.xml',
          '/sitemap1.xml',
        ].map(async loc => {
          try {
            const sitemapXmlHref = new URL(loc, variables.domain).href
            const sitemapCheck = await fetchViaProxy(sitemapXmlHref)

            if (sitemapCheck.data) {
              sitemaps.push(sitemapXmlHref)
            }
          } catch (err) {
            if (`${err}`.includes('404')) {
              // Ignore 404s. This just means there is no sitemap at this location
            }

            // Log any other errors
            console.log(err)
          }
        })
      )

      if (!sitemaps.length) {
        throw new Error('No sitemaps found for this domain.')
      }

      sitemaps = [...new Set(sitemaps).values()]

      await Promise.all(
        sitemaps.map(sitemap => {
          return MonitoredUrlsClient.updateMonitoredUrlsFromSitemap({
            allowMissing: true,
            sitemapUrl: sitemap,
            monitoredUrlTemplate: new MonitoredUrlPb({
              workspaceId: BigInt(variables.workspaceId),
              projectId: BigInt(variables.projectId),
              brandId: BigInt(variables.brandId),
            }),
          })
        })
      )
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(MONITORED_URL_KEY)
        toast({
          color: 'green-500',
          message: 'URLs imported from domain',
        })
      },
      onError: err => {
        errorPopup(err)
      },
    }
  )
}

export type UpdateMonitoredUrlsFromTextMutationOptions = {
  workspaceId: string
  projectId: string
  brandId: string
  urlsText: string
}

export function useUpdateMonitoredUrlsFromTextMutation() {
  const queryClient = useQueryClient()
  const toast = useToast()
  const errorPopup = useErrorPopup()
  const popup = usePopup()

  return useMutation(
    async (variables: UpdateMonitoredUrlsFromTextMutationOptions) => {
      const monitoredUrls: MonitoredUrlPb[] = []
      const badUrls: string[] = []

      variables.urlsText.split('\n').forEach(url => {
        const trimmed = url.trim()

        if (!trimmed) {
          return
        }

        try {
          new URL(trimmed)

          monitoredUrls.push(
            new MonitoredUrlPb({
              workspaceId: BigInt(variables.workspaceId),
              projectId: BigInt(variables.projectId),
              brandId: BigInt(variables.brandId),
              url: new UrlPb({
                url: trimmed,
              }),
            })
          )
        } catch (err) {
          badUrls.push(trimmed)
        }
      })

      if (badUrls.length) {
        popup({
          title: 'Invalid URLs',
          message: (
            <div className="space-y-2">
              <div className="text-sm">The following URLs were invalid:</div>
              <div className="max-h-[400px] overflow-auto rounded border border-gray-500/20 bg-gray-500/5 p-2 text-sm">
                {badUrls.map((url, i) => (
                  <div key={i}>{url}</div>
                ))}
              </div>
              <div className="text-sm">Please fix the URLs and try again.</div>
            </div>
          ),
        })
        return
      }

      if (!monitoredUrls.length) {
        toast({
          message: 'No valid URLs were found in the text input.',
          className: 'bg-yellow-500',
        })
        return
      }

      // split the monitoredUrls into chunks of 250
      const chunks = []
      let chunk = []
      for (const monitoredUrl of monitoredUrls) {
        if (chunk.length === 250) {
          chunks.push(chunk)
          chunk = []
        }
        chunk.push(monitoredUrl)
      }

      for (const chunk of chunks) {
        await MonitoredUrlsClient.batchUpdateMonitoredUrls({
          allowMissing: true,
          monitoredUrls: chunk,
        })
      }
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(MONITORED_URL_KEY)
        toast({
          color: 'green-500',
          message: 'URLs imported!',
        })
      },
      onError: err => {
        errorPopup(err)
      },
    }
  )
}

export function useAutoParentUrlsMutation() {
  const queryClient = useQueryClient()
  const toast = useToast()
  const errorPopup = useErrorPopup()

  return useMutation(
    async (variables: {
      workspaceId: string
      projectId: string
      brandId: string
      createVirtualUrls: boolean
      overwriteExistingParents: boolean
    }) => {
      const response = await MonitoredUrlsClient.autoParentMonitoredUrls({
        workspaceId: BigInt(variables.workspaceId),
        projectId: BigInt(variables.projectId),
        brandId: BigInt(variables.brandId),
        createVirtualUrls: variables.createVirtualUrls,
        overwriteExistingParents: variables.overwriteExistingParents,
      })

      // const originals = sortBy(
      //   monitoredUrlsResponse.monitoredUrls,
      //   d => d.url?.url
      // ).reverse()

      // originals.forEach((monitoredUrl, i) => {
      //   const parent = originals.find(
      //     d =>
      //       monitoredUrl.url?.url.startsWith(d.url!.url) && d !== monitoredUrl
      //   )

      //   if (parent) {
      //     monitoredUrl.parentMonitoredUrlId = parent.id
      //   }
      // })

      // await MonitoredUrlsClient.batchUpdateMonitoredUrls({
      //   monitoredUrls: originals,
      // })
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(MONITORED_URL_KEY)
        toast({
          color: 'green-500',
          message: 'URLs Auto-Parented',
        })
      },
      onError: err => {
        errorPopup(err)
      },
    }
  )
}

// export function useMonitoredUrlSearchQuery(options: {
//   search: string
//   projectId: string
//   brandId?: string
// }) {
//   const searchIndex = useMonitoredUrlsSearchIndex()

//   return useQuery(
//     ['monitored-url-search', options.search],
//     async () => {
//       return (
//         await searchIndex!.search!(options.search || '', {
//           filter: [
//             `project_id=${options.projectId}`,
//             // options.brandId && `brand_id=${options.brandId}`,
//           ]
//             .filter(Boolean)
//             .join(' AND '),
//           limit: 100,
//         }).then(d => d.hits)
//       ).map(hit => meilisearchMonitoredUrlToMonitoredUrlPb(hit))
//     },
//     {
//       enabled: Boolean(searchIndex?.search),
//       keepPreviousData: true,
//     }
//   )
// }

// export function useMonitoredUrlsIndexQuery(opts: {
//   projectId: string
//   monitoredUrlIds: string[]
// }) {
//   const activeWorkspaceId = useActiveWorkspaceId()
//   const searchIndex = useMonitoredUrlsSearchIndex()
//   const monitoredUrlIds = opts.monitoredUrlIds?.filter(Boolean)

//   return useQuery({
//     queryKey: ['monitoredUrls', monitoredUrlIds],
//     queryFn: async () => {
//       const existing = await Promise.all(
//         monitoredUrlIds.map(async monitoredUrlId => {
//           return [
//             monitoredUrlId,
//             (await nozzleCacheClient.get([
//               'monitoredUrl',
//               [activeWorkspaceId, opts.projectId, monitoredUrlId].join('-'),
//             ])) as MonitoredUrlPb,
//           ] as const
//         })
//       )

//       const shouldRequestList = existing
//         .filter(([d, existing]) => !existing)
//         .map(([d]) => d)

//       const requested = shouldRequestList.length
//         ? (
//             await searchIndex!.search('', {
//               limit: 999999999,
//               filter: `workspace_id=${activeWorkspaceId} AND project_id=${
//                 opts.projectId
//               } AND monitored_url_id IN [${shouldRequestList.join(',')}]`,
//             })
//           ).hits
//         : []

//       const result = await Promise.all(
//         existing.map(async ([d, existing], i) => {
//           if (existing) {
//             return existing
//           }

//           const found = meilisearchMonitoredUrlToMonitoredUrlPb(
//             requested.find(dd => dd.monitored_url_id === d)!
//           )

//           if (found) {
//             nozzleCacheClient.set(
//               [
//                 'monitoredUrl',
//                 [activeWorkspaceId, opts.projectId, d].join('-'),
//               ],
//               found,
//               {
//                 ttl: Date.now() + 1000 * 60 * 5, // 5 minutes
//               }
//             )
//           }

//           return found
//         })
//       )

//       return result
//     },
//     enabled: Boolean(searchIndex?.getDocument),
//     keepPreviousData: true,
//     retry: false,
//   })
// }

export function useMonitoredUrlsByIdQuery(opts: {
  projectId: string
  monitoredUrlIds: string[]
}) {
  const workspaceId = useActiveWorkspaceId()
  const monitoredUrlsQuery = useMonitoredUrlsQuery({
    workspaceId,
    projectId: opts.projectId,
    rootOnly: false,
    view: MonitoredUrlViewPb.SEARCH,
    viewOptions: new MonitoredUrlViewOptionsPb({
      ancestorDepth: 0,
      descendentDepth: 0,
    }),
    filters: {
      includeUrlIds: opts.monitoredUrlIds.map(d => BigInt(d)),
    },
  })

  return useQuery({
    queryKey: [
      'monitoredUrlsById',
      opts.monitoredUrlIds,
      {
        monitoredUrlsQuery: monitoredUrlsQuery.dataUpdatedAt,
      },
    ],
    queryFn: () => {
      const byId: Record<string, MonitoredUrlPb> = {}

      monitoredUrlsQuery.data?.monitoredUrls.forEach(monitoredUrl => {
        if (monitoredUrl) {
          byId[monitoredUrl.id.toString()] = monitoredUrl
        }
      })

      return byId
    },
    staleTime: Infinity,
    cacheTime: 0,
    enabled: monitoredUrlsQuery.isSuccess,
  })
}
