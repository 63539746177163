import { Route } from '@tanstack/react-router'
import moment from 'moment'
import * as React from 'react'
import { useForm } from 'react-form'
import { AiOutlineGift } from 'react-icons/ai'
import {
  FaCheck,
  FaExternalLinkAlt,
  FaEye,
  FaEyeSlash,
  FaPaperPlane,
  FaQuestionCircle,
  FaTimes,
} from 'react-icons/fa'
import { Reward, RewardStatusEnum } from '../../openapi'

import Anchor from '../components/Anchor'
import Button from '../components/Button'
import Card from '../components/Card'
import Clickable from '../components/Clickable'
import Container from '../components/Container'
import ErrorComp from '../components/Error'
import Loader from '../components/Loader'
import Separator from '../components/Separator'
import { Tag } from '../components/Tags'
import TextField from '../components/TextField'
import Tooltip from '../components/Tooltip'
import {
  getRewardAvailability,
  useCancelRewardMutation,
  useRewardsQuery,
  useSubmitRewardMutation,
} from '../hooks/rewards'
import { multiSortBy } from '../utils'
import { formatNumber } from '../utils/Format'
import Validate from '../utils/Validate'

export function Rewards() {
  const rewardsQuery = useRewardsQuery()

  const sortedRewards = React.useMemo(() => {
    let rewards = rewardsQuery.data ?? []

    rewards = rewards.filter(
      reward => reward.status === RewardStatusEnum.Approved
    )

    rewards = rewards.map(d => {
      return d

      // const chance = () => Math.random() > 0.5
      // return {
      //   ...d,
      //   ...(chance()
      //     ? {
      //         url: 'https://twitter.com/tannerlinsley',
      //         urlLabel: 'View Tweet',
      //       }
      //     : {}),
      // status: chance()
      //   ? 'approved'
      //   : chance()
      //   ? 'submitted'
      //   : chance()
      //   ? 'denied'
      //   : 'unsubmitted',
      // } as Reward
    })

    return multiSortBy(rewards, [
      d => (d.status === RewardStatusEnum.Unsubmitted ? -1 : 0),
      d => (d.status === RewardStatusEnum.Submitted ? -1 : 0),
      d => (d.status === RewardStatusEnum.Approved ? -1 : 0),
      d => (d.status === RewardStatusEnum.Denied ? -1 : 0),
      d => (getRewardAvailability(d) === 'available' ? -1 : 1),
      d => d.priority,
      d => moment(d.availableAt).unix(),
    ])
  }, [rewardsQuery.data])

  return (
    <Container className="max-w-[1000px]">
      <div className="h-4" />
      <Card>
        <div className="flex items-center gap-6 p-4">
          <div className="relative rounded-lg p-1 shadow-xl dark:bg-gray-800">
            <AiOutlineGift className="inline text-[4rem] text-green-400" />
          </div>
          <div>
            <div className="text-3xl">Get Rewards</div>
            <div className="h-2" />
            <div>
              Interested in some more free SERPs? You can win rewards by doing
              the following tasks that change from time to time! Please, only
              one use per reward, per account.
            </div>
          </div>
        </div>
        <Separator />
        <div className="divide-y divide-gray-500/20">
          {rewardsQuery.isLoading ? (
            <div className="flex items-center justify-center p-6">
              <Loader className="text-xl" />
            </div>
          ) : rewardsQuery.error ? (
            <div className="p-4">
              <ErrorComp
                error={rewardsQuery.error}
                onRetry={rewardsQuery.refetch}
              />
            </div>
          ) : sortedRewards.length ? (
            sortedRewards.map(reward => {
              return <RewardComp reward={reward} key={reward.id} />
            })
          ) : (
            <div className="flex flex-col items-center gap-2 p-2">
              <span aria-label="pirate-flag" role="img" className="text-[5rem]">
                🏴‍☠️
              </span>
              <div className="text-sm font-bold">
                You've collected all of the loot! Check back later for more
                goodies.
              </div>
            </div>
          )}
        </div>
      </Card>
      <div className="h-4" />
    </Container>
  )
}

function RewardComp({ reward }: { reward: Reward }) {
  const [showForm, setShowForm] = React.useState(false)
  const submitRewardMutation = useSubmitRewardMutation()
  const cancelRewardMutation = useCancelRewardMutation()

  const defaultValues = React.useMemo(
    () => ({
      value: reward.inputValue,
    }),
    [reward.inputValue]
  )

  const isComingSoon = getRewardAvailability(reward) === 'coming-soon'

  const isFormDisabled = isComingSoon || reward.status === 'approved'

  const form = useForm({
    defaultValues,
    onSubmit: async values => {
      if (isFormDisabled) {
        return
      }

      await submitRewardMutation.mutateAsync({
        rewardId: reward.id,
        // @ts-expect-error  // Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        value: values.value,
      })
      setShowForm(false)
    },
  })

  const badgeElement =
    reward.status === 'submitted' ? (
      <Tooltip
        tooltip={
          <>
            Submitted{' '}
            <strong>
              {moment(reward.submittedAt).fromNow()} (
              {moment(reward.submittedAt).format('ll')})
            </strong>
          </>
        }
        className="pointer-events-auto"
      >
        <div className="rounded-full bg-blue-500 px-2 py-1 text-xs text-white">
          <FaCheck className="inline" /> Submitted
        </div>
      </Tooltip>
    ) : reward.status === 'approved' ? (
      <Tooltip
        tooltip={
          <>
            Approved{' '}
            <strong>
              {moment(reward.approvedAt).fromNow()} (
              {moment(reward.approvedAt).format('ll')})
            </strong>
          </>
        }
        className="pointer-events-auto"
      >
        <div className="rounded-full bg-green-500 px-2 py-1 text-xs text-white">
          <FaCheck className="inline" /> Approved
        </div>
      </Tooltip>
    ) : reward.status === 'denied' ? (
      <Tooltip
        tooltip={
          <>
            Denied{' '}
            <strong>
              {moment(reward.deniedAt).fromNow()} (
              {moment(reward.deniedAt).format('ll')})
            </strong>
          </>
        }
        className="pointer-events-auto"
      >
        <div className="rounded-full bg-red-500 px-2 py-1 text-xs text-white">
          <FaTimes className="inline" /> Denied
        </div>
      </Tooltip>
    ) : isComingSoon ? (
      <Tooltip
        tooltip={
          <div>
            This reward will become available{' '}
            <strong>
              {moment(reward.availableAt).fromNow()} (
              {moment(reward.availableAt).format('ll')})
            </strong>
          </div>
        }
        className="pointer-events-auto"
      >
        <div className="rounded-full bg-gray-500 px-2 py-1 text-xs text-white">
          Coming Soon
        </div>
      </Tooltip>
    ) : null

  const actionElement = isComingSoon ? null : reward.status === 'submitted' ? (
    <Button
      size="sm"
      color={'gray-400'}
      hoverColor={'red-500'}
      onClick={() => cancelRewardMutation.mutate({ rewardId: reward.id })}
    >
      <FaTimes className="inline" /> Cancel
    </Button>
  ) : reward.status === 'approved' ? (
    <div className="flex items-center gap-2">
      <FaCheck className="inline text-green-400" />
    </div>
  ) : reward.status === 'denied' ? (
    <Button
      size="sm"
      color={showForm ? 'gray-400' : 'blue-500'}
      onClick={() => setShowForm(old => !old)}
    >
      {showForm ? (
        <>
          <FaEyeSlash className="inline" /> Hide
        </>
      ) : (
        <>
          <FaPaperPlane className="inline" /> Re-Apply
        </>
      )}
    </Button>
  ) : (
    <Button
      size="sm"
      color={showForm ? 'gray-400' : 'green-500'}
      onClick={() => setShowForm(old => !old)}
    >
      {showForm ? (
        <>
          <FaEyeSlash className="inline" /> Hide
        </>
      ) : (
        <>
          <FaPaperPlane className="inline" /> Apply
        </>
      )}
    </Button>
  )

  return (
    <div className="p-4">
      <div
        className="flex flex-wrap items-center justify-between gap-4"
        disabled={isComingSoon}
      >
        <div className="flex-1">
          <div className="flex items-center gap-2 text-xl">
            {reward.label}
            {badgeElement}
          </div>
          <div className="h-1" />
          <div className="whitespace-pre text-sm opacity-70">
            {reward.description}
          </div>
          {reward.url ?? reward.urlLabel ? (
            <div>
              <div className="h-2" />
              <Anchor
                href={reward.url?.replace(/^\uFEFF/gm, '')}
                target="_blank"
                rel="noreferrer"
              >
                <Button size="xs" color="blue-500">
                  {reward.urlLabel ?? reward.url}{' '}
                  <FaExternalLinkAlt className="inline" />
                </Button>
              </Anchor>
            </div>
          ) : null}
        </div>
        {reward.perks.length
          ? reward.perks.map(perk => {
              return (
                <Tag
                  className="flex overflow-hidden p-0 text-sm"
                  key={perk.type}
                >
                  <div className="bg-gray-100 px-2 py-1 dark:bg-gray-700">
                    {perk.type === 'pulls' ? 'Monthly Pulls' : perk.type}
                  </div>
                  {perk?.value != null ? (
                    <div className="px-2 py-1 text-green-600 dark:text-green-400">
                      +{formatNumber(perk.value, { short: false })}
                    </div>
                  ) : null}
                </Tag>
              )
            })
          : null}
        {actionElement}
      </div>
      {reward.status !== 'unsubmitted' ? <div className="h-2" /> : null}
      <div className="flex gap-2">
        {reward.status !== 'unsubmitted' ? (
          <Clickable
            onClick={() => setShowForm(old => !old)}
            className="text-sm text-blue-500 dark:text-blue-400"
          >
            {showForm ? (
              <>
                <FaEyeSlash className="inline" /> Hide
              </>
            ) : (
              <>
                <FaEye className="inline" /> View
              </>
            )}{' '}
            Submission Details
          </Clickable>
        ) : null}
        {reward.status === 'denied' && reward.deniedReason ? (
          <Tooltip tooltip={reward.deniedReason}>
            <span className="text-sm text-red-500 dark:text-red-400">
              <FaQuestionCircle className="inline" /> Why was my submission
              denied?
            </span>
          </Tooltip>
        ) : null}
      </div>
      {showForm ? (
        <>
          <div className="h-4" />
          <div className="font-bold">Terms & Requirements</div>
          <div className="whitespace-pre p-1 text-sm">{reward.terms}</div>
          <div className="h-4" />
          <form.Form disabled={isFormDisabled}>
            <TextField
              label={reward.inputLabel}
              field="value"
              placeholder={reward.inputPlaceholder}
              validate={Validate.required()}
              className="w-[500px] max-w-full"
            />
            <div className="h-2" />
            {reward.status === 'approved' ? null : (
              <Button size="base" color="blue-500" type="submit">
                {reward.status === 'unsubmitted' ? 'Submit' : 'Resubmit'}
              </Button>
            )}
          </form.Form>
        </>
      ) : null}
    </div>
  )
}
