import { css, cx } from '@emotion/css'
import * as React from 'react'
import { twMerge } from 'tailwind-merge'
import { withProps } from '../utils/withProps'

//

// const StyledCheckbox = withProps('label')(
//   ({ className, disabled, error, ...props }) => ({
//     ...props,
//     className: twMerge(
//       css`
//         display: inline-block;
//         position: relative;

//         ${error &&
//         `
//       box-shadow: inset 0 0 0 2px ${twConfig.theme.colors.red['500']};
//     `};

//         ${disabled &&
//         `
//       opacity: 0.3;
//     `};

//         /* Hide the browser's default checkbox */
//         & > input {
//           position: absolute;
//           opacity: 0;
//           cursor: pointer;
//           height: 0;
//           width: 0;

//           &:checked {
//             ~ .toggle {
//               @apply bg-green-600 dark:bg-gray-50 border border-gray-200 dark:border-gray-800;

//               .switch {
//                 @apply l-full dark:bg-green-500;
//                 transform: translateY(-50%) translateX(calc(-100% - 5px));
//               }
//             }
//           }

//           &:focus {
//             ~ .toggle {
//               & > .on {
//                 @apply bg-primary-500;
//               }
//             }
//             :checked {
//               ~ .toggle {
//                 & > .off {
//                   background: white;
//                 }
//               }
//             }
//           }
//         }

//         & > .toggle {
//           @apply bg-gray-100 dark:bg-gray-900;
//           cursor: pointer;
//           user-select: none;
//           display: inline-block;
//           position: relative;
//           height: 2.5rem;
//           width: 4rem;
//           max-width: 100%;
//           border-radius: 0.3rem;
//           box-shadow: inset 0 0 0 2px ${twConfig.theme.colors.gray['200']};

//           & > .switch {
//             @apply bg-gray-900 dark:bg-gray-50;
//             position: absolute;
//             left: 5px;
//             top: 50%;
//             width: 40%;
//             max-width: 30px;
//             height: 70%;
//             border-radius: 0.15rem;
//             transition: all 0.2s ease;
//             transform: translateY(-50%);
//             box-shadow: ${`inset 0 0 0 2px ${twConfig.theme.colors.gray[200]}`};
//           }

//           & > .off {
//             position: absolute;
//             left: 15%;
//             top: 50%;
//             width: 20%;
//             height: 35%;
//             border-radius: 10rem;
//             transform: translateY(-50%);
//             transition: all 0.2s ease;
//             background: ${props => twConfig.theme.colors.fgA1};
//           }

//           & > .on {
//             position: absolute;
//             left: 65%;
//             top: 50%;
//             width: 20%;
//             height: 35%;
//             border-radius: 10rem;
//             transform: translateY(-50%);
//             transition: all 0.2s ease;
//             background: ${twConfig.theme.colors.gray['500']};
//           }
//         }
//       `,
//       className
//     ),
//   })
// )

export default withProps('input')(({ className, ...props }) => ({
  type: 'checkbox',
  ...props,
  className: twMerge(`inline-block`, className),
}))

// export default React.forwardRef(function Checkbox(
//   { value, onChange, ...rest },
//   ref
// ) {
//   return (
//     <StyledCheckbox {...rest}>
//       <input
//         type="checkbox"
//         checked={Boolean(value)}
//         ref={ref}
//         onChange={e => {
//           onChange(e.target.checked)
//         }}
//       />
//       <div className="toggle">
//         <div className="off" />
//         <div className="on" />
//         <div className="switch" />
//       </div>
//     </StyledCheckbox>
//   )
// })
