import * as React from 'react'
import useZustand from '../hooks/useZustand'
import { ColorifyOptions } from '../utils/tailwind'

export type ToastOptions = Partial<ColorifyOptions> & {
  timeout?: number
  message?: any
  onClick?: () => void
  className?: string
}

export type Toast = ToastOptions & {
  id: number
  timeout: number
  resolve?: (value?: unknown) => void
  timeoutInstance?: NodeJS.Timeout
}

export default function useToast() {
  const [, setStore] = useZustand()
  return React.useCallback(
    async (options: ToastOptions) => {
      const toast: Toast = {
        id: Date.now(),
        timeout: 3000,
        color: ['blue-500', 'blue-500'],
        ...options,
      }

      const callback = toast.onClick

      await new Promise(resolve => {
        toast.resolve = resolve
        toast.timeoutInstance = setTimeout(
          toast.resolve,
          toast.timeout ?? 10000000
        )
        toast.onClick = async () => {
          if (callback) {
            callback()
          }
          toast.resolve?.()
        }
        setStore(draft => {
          draft.toasts = [...draft.toasts, toast]
        })
      })

      clearTimeout(toast.timeoutInstance)

      setStore(draft => {
        draft.toasts = draft.toasts.filter(d => d.id !== toast.id)
      })
    },
    [setStore]
  )
}
