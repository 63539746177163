import React from 'react'
import { AxisOptions, UserSerie } from 'react-charts'
import { useGooglePhrasesQuery } from '../hooks/useGooglePhrases'
import { TimeRange } from '../hooks/useTimeRanges'
import { getDataColor } from '../utils/DataColors'
import { formatNumber, formatPercentage } from '../utils/Format'
import Chart from './Chart'
import ChartPlaceholder from './ChartPlaceholder'

export function GoogleSearchConsoleChart({
  property,
  timeRanges,
  device,
  country,
  appearance,
  url,
  metrics,
  filter,
}: {
  property: string
  timeRanges: TimeRange
  device: string
  country: string
  appearance: string
  url: string
  metrics: { label: string; value: string }[]
  filter: {
    clicks: boolean
    impressions: boolean
    ctr: boolean
    position: boolean
  }
}) {
  const phrasesQuery = useGooglePhrasesQuery({
    property,
    timeRanges,
    device,
    country,
    appearance,
    url,
    groupBy: 'DATE',
  })

  const data = metrics
    .map((metric, i): UserSerie<any> => {
      return {
        label: metric.label,
        color: getDataColor(i),
        data: phrasesQuery.data?.map((d: any) => {
          return { ...d, value: d[metric.value] }
        }),
        secondaryAxisId: metric.value,
      }
    })
    // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    .filter(m => filter[metrics.find(metric => metric.label === m.label).value])

  const primaryAxis = React.useMemo(
    (): AxisOptions<any> => ({
      getValue: datum => new Date(`${datum.keys[0]}T00:00:00Z`),
      scaleType: 'time',
      position: 'bottom',
    }),
    []
  )

  const secondaryAxes = React.useMemo(
    (): AxisOptions<any>[] =>
      metrics.map(metric => {
        return {
          id: metric.value,
          elementType: 'line',
          position: 'left',
          invert: metric.value === 'position' ? true : false,
          getValue: datum => datum.value,
          show: false,
          formatters: {
            tooltip: (value: any) =>
              metric.value === 'ctr'
                ? formatPercentage(value, { precision: 2 })
                : metric.value === 'position'
                ? formatNumber(value, { precision: 1 })
                : formatNumber(value),
            cursor: (value: any) =>
              metric.value === 'ctr'
                ? formatPercentage(value, { precision: 2 })
                : metric.value === 'position'
                ? formatNumber(value, { precision: 1 })
                : formatNumber(value),
          },
        }
      }),
    [metrics]
  )

  return (
    <>
      {!phrasesQuery.data || phrasesQuery.isFetching ? (
        <ChartPlaceholder type="line" stacked vertical />
      ) : (
        <Chart
          {...{
            options: {
              data,
              primaryAxis,
              secondaryAxes,
              getSeriesStyle: series => {
                return {
                  color: series.originalSeries.color,
                }
              },
            },
          }}
        />
      )}
    </>
  )
}
