import { useField } from 'react-form'
import { ScheduleConfig } from '../utils/schedules'
import { SchedulesInput } from './SchedulesInput'

const defaultValue: ScheduleConfig[] = [
  { id: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0' },
]

export default function SchedulesField(props: {
  required?: boolean
  onboarding?: boolean
}) {
  const { value, setValue } = useField('schedules', {
    defaultValue,
  })

  return (
    <SchedulesInput
      value={value}
      onChange={setValue}
      onboarding={props.onboarding}
      required={props.required}
    />
  )
}
