import * as React from 'react'
import Table from '../../../components/Table'
import { KeywordWithLocale, useKeywordsQuery } from '../../../hooks/keywords'
import { useTable } from '../../../hooks/useTable'
import { Table as ReactTable, createColumnHelper } from '@tanstack/react-table'
import { createCheckboxColumn } from '../../../components/CheckboxColumn'
import { formatEngine } from '../../topic-clusters/metrics'
import { renderEngine } from '../../../utils/engines'
import { formatDevice, formatNumber, renderDevice } from '../../../utils/Format'
import {
  formatLocaleCountry,
  formatLocaleLanguage,
  formatLocaleLocationType,
  renderLocaleCountry,
  renderLocaleLanguage,
} from '../../../utils/locales'
import { KeywordPicker } from '../../../components/KeywordPicker'
import Clickable from '../../../components/Clickable'
import { FaPlus, FaRegTrashAlt, FaTrashAlt } from 'react-icons/fa'
import { ButtonPlain } from '../../../components/ButtonPlain'
import { useDeleteFromRelatedTopicsMutation } from '../../../utils/topics'
import Spinner from '../../../components/Spinner'
import useConfirm from '../../../hooks/useConfirm'

export function TopicKeywordTable({
  projectId,
  keywordIds,
  onChange,
  topicId,
  initialPageSize,
  tableRef,
}: {
  projectId: string
  topicId: string | undefined
  keywordIds: string[]
  onChange?: (keywordIds: string[]) => void
  initialPageSize?: number
  tableRef?: React.MutableRefObject<ReactTable<KeywordWithLocale>>
}) {
  const confirm = useConfirm()

  const keywordsQuery = useKeywordsQuery({
    projectId,
    keywordIds,
  })

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<KeywordWithLocale>()

    return [
      createCheckboxColumn(),
      columnHelper.accessor('phrase', {
        header: 'Phrase',
      }),
      columnHelper.accessor(d => formatDevice(d.device), {
        header: 'Device',
        cell: props => renderDevice(props.row.original.device),
      }),
      columnHelper.accessor(d => formatEngine(d.engine), {
        header: 'Engine',
        cell: props => renderEngine(props.row.original.engine),
      }),
      columnHelper.accessor(d => formatLocaleCountry(d.locale), {
        header: 'Country',
        cell: props => renderLocaleCountry(props.row.original.locale),
      }),
      columnHelper.accessor(d => formatLocaleLanguage(d.locale), {
        header: 'Language',
        cell: props => renderLocaleLanguage(props.row.original.locale),
      }),
      columnHelper.accessor(d => formatLocaleLocationType(d.locale), {
        header: 'Location Type',
      }),
      columnHelper.accessor(d => d.locale.location_type, {
        header: 'Location',
      }),
    ]
  }, [])

  const tableData = React.useMemo(() => {
    return keywordsQuery.data ?? []
  }, [keywordsQuery.data])

  const table = useTable({
    columns,
    data: tableData,
    initialState: React.useMemo(
      () => ({
        pagination: {
          pageSize: initialPageSize ?? 10,
        },
      }),
      [initialPageSize]
    ),
  })

  if (tableRef) {
    tableRef.current = table
  }

  const selectedRows = table.getSelectedRowModel().flatRows

  const batchDelete = () => {
    onChange?.(
      keywordIds.filter(
        keywordId =>
          !selectedRows.find(v => String(v.original.keywordId) === keywordId)
      )
    )
    table.resetRowSelection()
  }

  const deleteFromRelatedTopicsMutation = useDeleteFromRelatedTopicsMutation()

  return (
    <Table
      table={table}
      beforeTable={
        selectedRows.length ? (
          <div className="flex items-center divide-x divide-gray-500/20">
            <div className="px-3 py-2">
              <strong>{formatNumber(selectedRows.length)}</strong> items
              selected
            </div>
            <div className="flex flex-wrap items-center gap-2 px-3 py-2">
              <ButtonPlain
                className="border border-red-500 bg-transparent text-xs text-red-500"
                onClick={batchDelete}
              >
                <FaTrashAlt /> Delete
              </ButtonPlain>

              <ButtonPlain
                className="bg-gray-500/20 text-xs text-black hover:bg-gray-500/30 dark:text-white"
                onClick={async () => {
                  if (
                    await confirm({
                      message:
                        'Are you sure you want to remove these keywords from all related Topics?',
                    })
                  ) {
                    await deleteFromRelatedTopicsMutation.mutateAsync({
                      projectId,
                      topicId,
                      sourceKeywordIds: keywordIds,
                      deleteKeywordIds: selectedRows.map(v =>
                        String(v.original.keywordId)
                      ),
                    })

                    table.resetRowSelection()
                  }
                }}
              >
                {deleteFromRelatedTopicsMutation.isLoading ? (
                  <>
                    <Spinner /> Deleting From Related Topics
                  </>
                ) : (
                  <>
                    <FaRegTrashAlt /> Delete From Related Topics
                  </>
                )}
              </ButtonPlain>
            </div>
          </div>
        ) : null
      }
      afterTable={
        <>
          <KeywordPicker
            {...{
              projectId,
              excludeKeywordIds: keywordIds.map(v => v),
              onChange: (next: any) => {
                // Dedupe keywords
                onChange?.([...new Set([...keywordIds, next]).values()])
              },
            }}
          >
            {({ onClick }: any) => (
              <Clickable
                onClick={onClick}
                className="flex items-center gap-1 p-2 text-sm !font-bold text-blue-500"
              >
                <FaPlus /> Add Keyword
              </Clickable>
            )}
          </KeywordPicker>
        </>
      }
    />
  )
}
