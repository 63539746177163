import { useMatch, useSearch } from '@tanstack/react-router'
import { FaAngleRight } from 'react-icons/fa'
import Card from '../../components/Card'
import QueryGate from '../../components/QueryGate'
import {
  useBrand,
  useCreateBrand,
  useRemoveBrandById,
  useSaveBrand,
} from '../../hooks/brands'
import useConfirm from '../../hooks/useConfirm'
import useNavigate from '../../hooks/useNavigate'
import { useActiveWorkspaceId } from '../../hooks/workspaces'
import BrandForm from './BrandForm'
import { backToBrands } from './Brands'
import Head from '../../components/Head'
import { ProjectPicker } from '../../components/ProjectPicker'
import { LocationGenerics } from '../../LocationGenerics'

//

type Props = {
  isNew?: boolean
}

export default function Brand({ isNew }: Props) {
  const workspaceId = useActiveWorkspaceId()
  const navigate = useNavigate()
  const saveBrand = useSaveBrand()
  const createBrand = useCreateBrand()
  const search = useSearch()
  const { brandId } = useMatch().params
  const { projectId } = search
  const removeBrandById = useRemoveBrandById()
  const confirm = useConfirm()

  const brandQuery = useBrand({
    id: isNew ? undefined : brandId,
  })

  const onSubmit = async (values: any) => {
    if (brandQuery.data) {
      return saveBrand(values)
    }

    const newBrand = await createBrand({
      ...values,
      workspaceId,
      teamId: projectId,
    })

    // If this is a new brand, navigate to the brand page
    navigate({
      to: `/brands/${newBrand.id}`,
    })
  }

  const onRemove = async () => {
    const confirmed = await confirm({
      color: 'red-500',
      message: 'This Brand will be deleted!',
      subMessage: 'Brands can be restored after they have been deleted.',
    })
    if (confirmed) {
      // @ts-expect-error  // Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
      await removeBrandById.mutateAsync(brandQuery.data?.id)
      navigate({
        to: '/brands',
      })
    }
  }

  return (
    <div className="space-y-2 p-2">
      <Head>
        <title>Brand - {brandQuery.data?.name ?? ''}</title>
      </Head>
      <Card className="flex flex-wrap items-center gap-2">
        <ProjectPicker />
        <FaAngleRight />
        {backToBrands}
        <FaAngleRight />
        <div className="text-xl font-bold">{brandQuery.data?.name ?? ''}</div>
      </Card>
      <Card className="divide-y divide-gray-500/20">
        {isNew ? (
          <BrandForm
            {...{
              onSubmit,
              projectId,
              showBrandModal: true,
            }}
          />
        ) : (
          <QueryGate query={brandQuery}>
            {() => (
              <BrandForm
                {...{
                  onSubmit,
                  projectId,
                  brand: brandQuery.data,
                  onRemove,
                }}
              />
            )}
          </QueryGate>
        )}
      </Card>
    </div>
  )
}
