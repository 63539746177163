import { twMerge } from 'tailwind-merge'
import * as React from 'react'
import Color from '../utils/Color'
import { withProps } from '../utils/withProps'

//

export const DatePickerStyles = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    // count: number
    hasQuickPicks: boolean
    single: boolean
    isOpen: boolean
  }
>(({ className, hasQuickPicks, isOpen, single, ...rest }, ref) => (
  <div
    {...rest}
    ref={ref}
    tabIndex={0}
    className={twMerge(
      `fixed left-1/2 top-1/2 z-[99999] flex max-h-[calc(100%_-_5rem)] w-[max-content]
      max-w-[calc(100%_-_2rem)]
      -translate-x-1/2 -translate-y-1/2 scale-90 flex-wrap overflow-y-auto overflow-x-hidden rounded-md bg-white
      opacity-0 shadow-xl
      transition-all duration-200 ease-out-expo dark:border
      dark:border-gray-800
      dark:bg-gray-900
      ${isOpen && `scale-100 opacity-100`}`,
      className
    )}
  />
  // tabIndex is used here so we can detect accurately
  // if it was the blur target or not for some of the inputs
))

export const QuickPickerStyles = withProps('div')(
  ({ className, ...props }) => ({
    ...props,
    className: twMerge(
      `flex-auto, md:order-0 order-last flex flex-col
      self-stretch rounded-md`,
      className
    ),
  })
)

export const QuickPickStyles = withProps('div')<{ index: number }>(
  ({ className, ...props }) => ({
    ...props,
    className: twMerge(
      `flex flex-auto flex-col border-b
        border-r border-gray-100 last:border-b-0 dark:border-gray-700
        `,
      className
    ),
  })
)

export const QuickPickOptionStyles = withProps('button')<{ active: boolean }>(
  ({ className, active, ...props }) => ({
    ...props,
    className: twMerge(
      `flex items-center justify-start
      whitespace-nowrap border-b
      border-gray-50 p-2 px-4 text-black hover:bg-gray-100
      dark:border-gray-850

      dark:text-gray-200 hover:dark:bg-gray-800
      `,
      active && `bg-gray-50 font-bold dark:bg-gray-800 dark:text-white`,
      className
    ),
  })
)

export const RangeInputs = withProps('div')(({ className, ...props }) => ({
  ...props,
  className: twMerge(
    `order-first flex min-w-[200px] flex-1 
    flex-col bg-white
    dark:bg-gray-850 md:order-none 
    `,
    className
  ),
}))

export const CalendarStyles = withProps('div')(({ className, ...props }) => ({
  ...props,
  className: twMerge(`flex min-h-72 min-w-64 flex-col`, className),
}))

export const CalendarHeaderStyles = withProps('div')(
  ({ className, ...props }) => ({
    ...props,
    className: twMerge(`flex items-center justify-center`, className),
  })
)

export const MonthNameStyles = withProps('div')(({ className, ...props }) => ({
  ...props,
  className: twMerge(`text-center font-bold`, className),
}))

export const WeekStyles = withProps('div')<{ isFirst: boolean; week: unknown }>(
  ({ className, isFirst, ...props }) => ({
    ...props,
    className: twMerge(
      `flex items-center justify-start`,
      isFirst && `justify-end`,
      className
    ),
  })
)

export const WeekdaysStyles = withProps('div')(({ className, ...props }) => ({
  ...props,
  className: twMerge(`space-between flex items-center`, className),
}))

export const WeekdayStyles = withProps('div')(({ className, ...props }) => ({
  ...props,
  className: twMerge(
    `flex h-9 flex-1 items-center justify-center text-xs opacity-70`,
    className
  ),
}))

export const DayStyles = ({
  children,
  selectable,
  today,
  isNewSelection,
  isOldSelection,
  rangeIndex,
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & {
  rangeIndex: number
  isOldSelection: boolean
  isNewSelection: boolean
  today: unknown
  selectable: boolean
}) => (
  <div
    {...rest}
    className={twMerge(
      `flex w-[1.5rem] flex-auto items-center justify-center p-[0.1rem]`,
      selectable && `cursor-pointer`,
      className
    )}
  >
    <div
      className={twMerge(
        'inner',
        twMerge(
          `flex h-8 flex-auto items-center justify-center rounded-sm`,
          selectable && `bg-gray-50 dark:bg-gray-800`,
          isOldSelection && `bg-gray-200 dark:bg-gray-700`,
          // @ts-expect-error  // Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
          today && `!border-2 !border-black dark:!border-white`,
          isNewSelection && `border-0 !bg-blue-500 text-white`
        )
      )}
    >
      {children}
    </div>
  </div>
)

export const DatePickerMask = withProps('div')<{ isOpen: boolean }>(
  ({ className, isOpen, ...props }) => ({
    ...props,
    className: twMerge(
      `pointer-events-none fixed inset-0 z-[999998] bg-black bg-opacity-0 transition duration-100`,
      isOpen && `pointer-events-auto bg-opacity-60`,
      className
    ),
  })
)
