import * as React from 'react'
import { twMerge } from 'tailwind-merge'
import { withProps } from '../utils/withProps'

export const Tag = withProps('div')<{ compact?: boolean }>(
  ({ className, compact, ...props }) => ({
    ...props,
    className: twMerge(
      'inline-block rounded-md border border-gray-500/20 bg-gray-500/5 font-medium text-black dark:bg-gray-700 dark:text-white',
      compact ? 'px-1 text-xs' : 'px-2  py-1 text-sm',
      className
    ),
  })
)

const Tags = ({
  tags,
  compact,
  ...rest
}: {
  tags: string[]
  compact?: boolean
}) => (
  <div
    {...rest}
    className="
      inline-flex flex-wrap gap-2
  "
  >
    {tags.map(tag => (
      <Tag key={tag} compact={compact} {...rest}>
        {tag}
      </Tag>
    ))}
  </div>
)

export default Tags
