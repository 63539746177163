import * as React from 'react'
import useZustand from '../hooks/useZustand'

//

export default function usePauseEvents(kind: any, id: any, enabled: any) {
  const [, setState] = useZustand()

  const idempotencyKeyRef = React.useRef(Date.now())

  React.useEffect(() => {
    const idempotencyKey = idempotencyKeyRef.current

    if (kind && enabled) {
      setState(draft => {
        const oldKind = draft.entityLeases[kind] || {}
        const oldId = oldKind[id] || []
        const newId = Array.from(new Set(oldId).add(idempotencyKey))

        draft.entityLeases = {
          ...draft.entityLeases,
          [kind]: {
            ...oldKind,
            [id]: newId,
          },
        }
      })

      return () => {
        setState(draft => {
          let oldKind = draft.entityLeases[kind] || {}
          const oldId = oldKind[id] || []
          const newId = oldId.filter(d => d !== idempotencyKey)

          if (!newId.length) {
            if (!Object.keys(oldKind).length) {
              oldKind = { ...oldKind }
              delete oldKind[kind]

              draft.entityLeases = {
                ...draft.entityLeases,
                [kind]: oldKind,
              }
              return
            }

            draft.entityLeases = { ...draft.entityLeases }
            delete draft.entityLeases[kind]
            return
          }

          draft.entityLeases = {
            ...draft.entityLeases,
            [kind]: {
              ...oldKind,
              [id]: newId,
            },
          }
        })
      }
    }
  }, [enabled, id, kind, setState])
}
