import { twMerge } from 'tailwind-merge'
import { withProps } from '../utils/withProps'
import { ImSpinner8 } from 'react-icons/im'

//

const Spinner = withProps(ImSpinner8)(({ className, ...props }) => ({
  ...props,
  className: twMerge(
    'inline-block animate-pulse animate-spin text-[1.2em]',
    // css({ color: ({ color, theme }) => theme.colors[color] || 'inherit' }),
    className
  ),
}))

export default Spinner
