import * as React from 'react'
import { FaCaretDown } from 'react-icons/fa'
import { QueryFacetId } from '../utils/queryFacets'
import Select from './Select'
import { ButtonPlain } from './ButtonPlain'
import { useBrands } from '../hooks/brands'
import { brandTypeOptions } from '../options/brandTypeOptions'
import { useDomainOptionsQuery } from '../hooks/useDomainOptionsQuery'
import { useHostOptionsQuery } from '../hooks/useHostOptionsQuery'
import { useUrlOptionsQuery } from '../hooks/useUrlOptionsQuery'
import { SelectOption } from '../hooks/useSelect'
import Loader from './Loader'
import { useActiveProjectIdState, useRollupState } from '../utils/searchParams'

export const rollupDelimiter = '::'
// const endsWithRollupDelimiterRegex = new RegExp(`${rollupDelimiter}$`, 'g')

export function useRollupOptions(opts?: { allowDomainsAndUrls: boolean }) {
  const projectIdState = useActiveProjectIdState()

  const brandsQuery = useBrands({
    projectId: projectIdState.state,
  })

  const domainOptionsQuery = useDomainOptionsQuery({
    enabled: opts?.allowDomainsAndUrls,
  })

  const hostOptionsQuery = useHostOptionsQuery({
    enabled: opts?.allowDomainsAndUrls,
  })

  const urlOptionsQuery = useUrlOptionsQuery({
    enabled: opts?.allowDomainsAndUrls,
  })

  const isLoading =
    brandsQuery.isLoading ||
    (opts?.allowDomainsAndUrls
      ? domainOptionsQuery.isLoading ||
        hostOptionsQuery.isLoading ||
        urlOptionsQuery.isLoading
      : false)

  const options = React.useMemo(() => {
    const baseOptions = opts?.allowDomainsAndUrls
      ? ([
          'brandType',
          'brand',
          'brandProperty',
          'domain',
          'host',
          'url',
        ] as const)
      : (['brandType', 'brand', 'brandProperty'] as const)

    const options: SelectOption[] = []

    baseOptions.forEach(base => {
      if (base === 'brandType') {
        // Only add brand types that are found in the projects brands
        brandTypeOptions
          .filter(brandTypeOption =>
            brandsQuery.data?.find(
              brand => brand.type === brandTypeOption.value
            )
          )
          .forEach(brandTypeOption => {
            options.push({
              label: `Brand Type: ${brandTypeOption.label}`,
              value: `brandType${rollupDelimiter}${brandTypeOption.value}`,
            })
          })
      } else if (base === 'brand') {
        brandsQuery.data?.forEach(brand => {
          options.push({
            label: `Brand: ${brand.name}`,
            value: `brand${rollupDelimiter}${brand.id}`,
          })
        })
      } else if (base === 'brandProperty') {
        brandsQuery.data?.forEach(brand => {
          brand.properties.forEach(property => {
            options.push({
              label: `Brand Property: ${brand.name} - ${property.name}`,
              value: `brandProperty${rollupDelimiter}${brand.id}__${property.name}`,
            })
          })
        })
      } else if (base === 'domain') {
        domainOptionsQuery.data?.forEach(option => {
          options.push({
            label: `Domain: ${option.label}`,
            value: `domain${rollupDelimiter}${option.value}`,
          })
        })
      } else if (base === 'host') {
        hostOptionsQuery.data?.forEach(option => {
          options.push({
            label: `Host: ${option.label}`,
            value: `host${rollupDelimiter}${option.value}`,
          })
        })
      } else if (base === 'url') {
        urlOptionsQuery.data?.forEach(option => {
          options.push({
            label: `URL: ${option.label}`,
            value: `url${rollupDelimiter}${option.value}`,
          })
        })
      }
    })

    return options
  }, [
    brandsQuery.data,
    domainOptionsQuery.data,
    hostOptionsQuery.data,
    opts?.allowDomainsAndUrls,
    urlOptionsQuery.data,
  ])

  return isLoading ? undefined : options
}

export default function RollupPicker({
  allowDomainsAndUrls,
  onChange,
}: {
  allowDomainsAndUrls: boolean
  onChange?: (next: string) => void
}) {
  const rollupState = useRollupState()
  const rollupOptions = useRollupOptions({
    allowDomainsAndUrls,
  })

  return (
    <Select
      options={rollupOptions}
      value={rollupState.state}
      sort={false}
      onChange={next => {
        rollupState.setState(next)
        onChange?.(next)
      }}
      children={({ onClick, selectedOption }: any) => {
        return (
          <ButtonPlain
            onClick={onClick}
            className="flex items-center gap-0 divide-x divide-gray-500/20 bg-gray-100 p-0 dark:bg-gray-800"
          >
            {!rollupOptions ? (
              <div className="px-2 py-1">
                <Loader />
              </div>
            ) : !rollupOptions.length ? (
              <div className="px-2 py-1">No Brands/Domains Found</div>
            ) : (
              <div className="px-2 py-1">{selectedOption?.label}</div>
            )}
            <div>
              <FaCaretDown className="mx-1" />
            </div>
          </ButtonPlain>
        )
      }}
    />
  )
}

export function useRollup() {
  const rollupState = useRollupState()

  return React.useMemo(() => {
    return (rollupState.state ?? rollupDelimiter).split(rollupDelimiter) as [
      QueryFacetId,
      string
    ]
  }, [rollupState.state])
}
