import { twMerge } from 'tailwind-merge'
import { FaChartLine } from 'react-icons/fa'
import { IoMdPodium } from 'react-icons/io'
import { DisplayMode } from './TimeSeriesWidget'
import { Updater } from '../utils'
import Tooltip from './Tooltip'
import { ButtonPlain } from './ButtonPlain'

const options: {
  label: string
  displayMode: DisplayMode
  icon: React.ReactNode
}[] = [
  {
    label: 'Summary',
    displayMode: 'summary',
    icon: <FaChartLine />,
  },
  {
    label: 'Rank Groups',
    displayMode: 'rankGroups',
    icon: <IoMdPodium />,
  },
]

export type ChartOptionsState = {
  displayMode: DisplayMode
}

export function ChartOptions<TWidgetState extends ChartOptionsState>({
  state,
  setState,
}: {
  state: ChartOptionsState
  setState: (updater: Updater<TWidgetState>) => void
}) {
  return (
    <div className="flex items-center">
      {options.map((option, i) => {
        return (
          <Tooltip
            key={option.displayMode}
            tooltip={option.label}
            className="flex items-center"
          >
            <ButtonPlain
              className={twMerge(
                'bg-gray-500/10',
                'hover:bg-blue-500 hover:text-white',
                state.displayMode === option.displayMode && 'bg-gray-500/30',
                i > 0 && 'rounded-l-none',
                i < options.length - 1 && 'rounded-r-none'
              )}
              onClick={() =>
                setState(old => ({
                  ...old,
                  displayMode: option.displayMode,
                  tableDisplayMode: 'summary',
                }))
              }
            >
              {option.icon}
            </ButtonPlain>
          </Tooltip>
        )
      })}
    </div>
  )
}
