import * as React from 'react'
import { BillingDetailsForm } from '../../../components/BillingDetailsForm'
import Card from '../../../components/Card'

//

export function Details() {
  return (
    <Card>
      <BillingDetailsForm />
    </Card>
  )
}
