import { useSearch } from '@tanstack/react-router'
import { createColumnHelper } from '@tanstack/react-table'
import moment from 'moment'
import * as React from 'react'
import { FaAngleRight, FaInfoCircle, FaPlus } from 'react-icons/fa'
import Caption from '../../components/Caption'
import Card from '../../components/Card'
import Clickable from '../../components/Clickable'
import Link from '../../components/Link'
import QueryGate from '../../components/QueryGate'
import Table from '../../components/Table'
import Tooltip from '../../components/Tooltip'
import { useSegments } from '../../hooks/segments'
import { useTable } from '../../hooks/useTable'
import useZustand from '../../hooks/useZustand'
import { Segment } from '../../utils/Api'
import { ButtonPlain } from '../../components/ButtonPlain'
import Head from '../../components/Head'
import { ProjectPicker } from '../../components/ProjectPicker'
import { useActiveProjectIdState } from '../../utils/searchParams'

//

export const segmentsLabel = <div className="text-xl font-bold">Segments</div>
export const backToSegments = <Link to="/segments">{segmentsLabel}</Link>

const columnHelper = createColumnHelper<Segment>()

export function Segments() {
  const projectId = useActiveProjectIdState().state
  const segmentsQuery = useSegments({ projectId })

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Segment Name',
        filterFn: 'fuzzy',
        cell: props => (
          <Link to={`/segments/${props.row.original.id}`}>
            {props.getValue()}
          </Link>
        ),
      }),
      columnHelper.accessor(row => (row.teamId ? 'Custom' : 'Built-In'), {
        header: 'Type',
        id: 'type',
        filterFn: 'equals',
        meta: {
          showQuickFilter: true,
        },
      }),
      columnHelper.accessor('description', {
        header: 'Description',
      }),
      columnHelper.accessor('id', {
        header: 'ID',
      }),
      columnHelper.accessor('updatedAt', {
        header: 'Last Updated',
        cell: props =>
          !props.row.original.teamId
            ? null
            : moment(props.getValue()).fromNow(),
      }),
      columnHelper.accessor('createdAt', {
        header: 'Date Created',
        cell: props =>
          !props.row.original.teamId ? null : (
            <Tooltip tooltip={moment(props.getValue()).format('lll')}>
              {moment(props.getValue()).fromNow()}
            </Tooltip>
          ),
      }),
    ],
    []
  )

  const tableData = React.useMemo(
    () => segmentsQuery.data ?? [],
    [segmentsQuery.data]
  )

  const table = useTable({
    data: tableData,
    columns,
    initialState: {
      pagination: {
        pageSize: 20,
      },
      sorting: [
        { id: 'type', desc: true },
        { id: 'name', desc: false },
      ],
    },
    virtualize: true,
  })

  return (
    <div className="flex flex-1 flex-col space-y-2 overflow-hidden p-2">
      <Head>
        <title>Segments</title>
      </Head>
      <Card className="flex flex-wrap items-center gap-2">
        <ProjectPicker />
        <FaAngleRight />
        {segmentsLabel}
      </Card>
      <Card className="flex min-h-0 flex-1 flex-col divide-y divide-gray-500/20 p-0">
        <div className="flex items-center justify-between gap-4 p-2">
          <Link to="/segments/new">
            <ButtonPlain className="bg-green-500">
              <FaPlus className="inline" /> Add Segment
            </ButtonPlain>
          </Link>
          <SegmentHelp />
        </div>
        <QueryGate query={segmentsQuery} className="p-2">
          {() => (
            <>
              {!tableData?.length ? (
                <div className="p-4 italic opacity-50">
                  No segments have been created yet.
                </div>
              ) : (
                <Table table={table} />
              )}
            </>
          )}
        </QueryGate>
      </Card>
    </div>
  )
}

export function SegmentHelp() {
  const [, setStore] = useZustand(state => state.helpUrl)

  const openSegmentsDocs = () => {
    setStore(old => {
      old.showHelp = true
      old.helpUrl = 'https://help.nozzle.io/how-do-i-add-segments'
    })
  }

  return (
    <Tooltip
      tooltip={
        <>
          Segments are filters that allow you to switch between different types
          or "segments" of SERP data with ease. Nozzle has 84 built-in segments.{' '}
          <span className="underline">Learn more</span>
        </>
      }
    >
      <Clickable onClick={openSegmentsDocs}>
        <Caption className="flex items-center gap-1">
          <FaInfoCircle className="inline" /> What is a segment?
        </Caption>
      </Clickable>
    </Tooltip>
  )
}
