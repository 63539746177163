import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import * as React from 'react'
import { useForm } from 'react-form'
import {
  FaCheck,
  FaCheckCircle,
  FaPlusCircle,
  FaTimesCircle,
} from 'react-icons/fa'
import { MdLoop } from 'react-icons/md'
import { useMutation, useQueryClient } from 'react-query'
import { useAccount } from '../hooks/account'
import useErrorPopup from '../hooks/useErrorPopup'
import useModal from '../hooks/useModal'
import useModalContext from '../hooks/useModalContext'
import useToast from '../hooks/useToast'
import { useActiveWorkspaceId } from '../hooks/workspaces'
import { countryOptions } from '../options/countryOptions'
import { queryKeyAccount, queryKeyBillingCards } from '../utils/Constants'
import { useThemeMode } from '../utils/Theme'
import Validate from '../utils/Validate'
import { PaymentsClient, AccountViewPb } from '../utils/proto'
import Button from './Button'
import Caption from './Caption'
import LabelWrap from './LabelWrap'
import { Modal, ModalContent, ModalTitle } from './Modals'
import SelectField from './SelectField'
import Spinner from './Spinner'
import { StripeProvider } from './StripeProvider'
import TextField from './TextField'

export default function StripeCard({
  button,
  workspaceId,
}: {
  button?: any
  workspaceId?: string
}) {
  const accountQuery = useAccount({ workspaceId, view: AccountViewPb.FULL })
  const showModal = useModal()

  const showAddStripeCardModal = () => {
    showModal(() => (
      <Modal>
        <StripeProvider>
          <InlineStripeCard workspaceId={workspaceId} />
        </StripeProvider>
      </Modal>
    ))
  }

  const latestCard = accountQuery.data?.paymentMethod

  return (
    <div onClick={showAddStripeCardModal}>
      {button ?? (
        <Button
          size="base"
          color="blue-500"
          className="flex items-center gap-2"
        >
          {latestCard ? (
            <>
              <MdLoop /> Update Card
            </>
          ) : (
            <>
              <FaPlusCircle /> Add Card
            </>
          )}
        </Button>
      )}
    </div>
  )
}

function InlineStripeCard({ workspaceId }: { workspaceId?: string }) {
  const queryClient = useQueryClient()
  const accountQuery = useAccount({ view: AccountViewPb.FULL })
  const { themeMode } = useThemeMode()
  const activeWorkspaceId = useActiveWorkspaceId()
  const { close } = useModalContext()
  const stripe = useStripe()
  const elements = useElements()
  const toast = useToast()
  const errorPopup = useErrorPopup()

  const createTokenMutation = useMutation(
    async values => {
      if (!stripe || !elements) {
        return
      }

      const cardElement = elements.getElement(CardElement)

      // @ts-expect-error  // No overload matches this call.
      const result = await stripe.createToken(cardElement, {
        // @ts-expect-error  // Property 'name' does not exist on type 'void'.
        name: values.name,
        // @ts-expect-error  // Property 'company' does not exist on type 'void'.
        company: values.company,
        // @ts-expect-error  // Property 'phone' does not exist on type 'void'.
        phone: values.phone,
        // @ts-expect-error  // Property 'email' does not exist on type 'void'.
        email: values.email,
        // @ts-expect-error  // Property 'address1' does not exist on type 'void'.
        address_line1: values.address1,
        // @ts-expect-error  // Property 'address2' does not exist on type 'void'.
        address_line2: values.address2,
        // @ts-expect-error  // Property 'locality' does not exist on type 'void'.
        address_city: values.locality,
        // @ts-expect-error  // Property 'administrativeArea' does not exist on ty... Remove this comment to see the full error message
        address_state: values.administrativeArea,
        // @ts-expect-error  // Property 'postalCode' does not exist on type 'void... Remove this comment to see the full error message
        address_zip: values.postalCode,
        // @ts-expect-error  // Property 'regionCode' does not exist on type 'void... Remove this comment to see the full error message
        address_country: values.regionCode.toUpperCase(),
      })

      if (result.error) {
        throw result.error
      }

      await PaymentsClient.addCardByToken({
        // @ts-expect-error  // Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
        workspaceId: BigInt(workspaceId || activeWorkspaceId),
        token: result.token.id,
      })
    },
    {
      onSuccess: async () => {
        await new Promise(r => setTimeout(r, 2000))
        toast({
          message: 'Card Saved',
          color: 'green-500',
        })
        close()
      },
      onError: async () => {
        await new Promise(r => setTimeout(r, 2000))
        errorPopup(
          `Failed to save card. Please check your card information and try again.`
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKeyBillingCards)
        queryClient.invalidateQueries(queryKeyAccount)
      },
    }
  )

  const { Form } = useForm({
    defaultValues: React.useMemo(
      () => ({
        name: [
          accountQuery.data?.billingDetails?.givenName,
          accountQuery.data?.billingDetails?.familyName,
        ]
          .filter(Boolean)
          .join(' '),
        company: accountQuery.data?.billingDetails?.company,
        email: accountQuery.data?.billingDetails?.email,
        phone: accountQuery.data?.billingDetails?.phone?.kind.value,
        address1: accountQuery.data?.billingDetails?.address?.addressLines[0],
        address2: accountQuery.data?.billingDetails?.address?.addressLines[1],
        locality: accountQuery.data?.billingDetails?.address?.locality,
        administrativeArea:
          accountQuery.data?.billingDetails?.address?.administrativeArea,
        postalCode: accountQuery.data?.billingDetails?.address?.postalCode,
        regionCode:
          accountQuery.data?.billingDetails?.address?.regionCode.toLowerCase(),
      }),
      [accountQuery.data]
    ),
    // @ts-expect-error  // Type 'void' is not assignable to type 'Promise<any... Remove this comment to see the full error message
    onSubmit: values => createTokenMutation.mutate(values),
  })

  return (
    <>
      <ModalTitle>Add/Edit Card</ModalTitle>
      <ModalContent>
        <div className="p-8">
          <Form>
            <TextField
              label="Name on Card"
              field="name"
              validate={Validate.required('A name is required')}
            />
            <div className="h-2" />
            <LabelWrap label="Card Info">
              <div className="rounded border border-solid border-gray-200 bg-gray-50 px-2 py-2 dark:border-gray-800 dark:bg-gray-800">
                <CardElement
                  options={{
                    hidePostalCode: true,
                    style: {
                      base: {
                        color: themeMode === 'dark' ? 'white' : 'black',
                        '::placeholder': {
                          color: '#678b97',
                        },
                      },
                    },
                  }}
                />
              </div>
            </LabelWrap>
            <div className="h-2" />
            <TextField
              field="company"
              label="Company Name"
              placeholder="Company Name..."
            />
            <div className="h-2" />
            <TextField field="email" label="Email" placeholder="Email..." />
            <div className="h-2" />
            <TextField
              field="phone"
              label="Phone"
              placeholder="Phone Number..."
            />
            <div className="h-2" />
            <TextField
              field="address1"
              label="Billing Address 1"
              placeholder="Address..."
            />
            <div className="h-2" />
            <TextField
              field="address2"
              label="Billing Address 2"
              placeholder="Address..."
            />
            <div className="h-2" />
            <TextField field="locality" label="City" placeholder="City..." />
            <div className="h-2" />
            <TextField
              field="administrativeArea"
              label="State"
              placeholder="State..."
            />
            <div className="h-2" />
            <TextField
              field="postalCode"
              label="Postal Code"
              placeholder="Enter a Postal Code..."
            />
            <div className="h-2" />
            <SelectField
              label="Country"
              field="regionCode"
              options={countryOptions}
            />
            <div className="h-4" />
            <Caption className="flex max-w-full items-center gap-4">
              <FaCheckCircle className="text-lg" /> By submitting this
              information, I authorize Nozzle to send instructions to the
              financial institution that issued my card to take payments from my
              card account in accordance with the terms of our agreement.
            </Caption>
            <div className="h-4" />
            <div className="flex gap-2">
              {createTokenMutation.isLoading ? (
                <Button size="base" color="green-500" className="mr-1" disabled>
                  <Spinner color="white" /> Saving Card
                </Button>
              ) : (
                <Button
                  size="base"
                  color="green-500"
                  className="mr-1"
                  type="submit"
                >
                  <FaCheck className="inline" /> Save Card
                </Button>
              )}

              <Button
                size="base"
                color="gray-500"
                hoverColor="red-500"
                onClick={close}
              >
                <FaTimesCircle className="inline" /> Cancel
              </Button>
            </div>
          </Form>
        </div>
      </ModalContent>
    </>
  )
}
