import { twMerge } from 'tailwind-merge'
import * as React from 'react'
import { FaInfoCircle } from 'react-icons/fa'
import useGroupByOverTimeQuery from '../hooks/useGroupByOverTimeQuery'
import {
  UseGroupByOverTimeQueryOptions,
  useGroupByOverTimeQueryOptions,
} from '../hooks/useGroupByOverTimeQueryOptions'
import useOnChange from '../hooks/useOnChange'
import useTimeRangeState from '../hooks/useTimeRanges'
import { GroupByQuerySeries } from '../utils/Api'
import {
  MetricId,
  MetricPermutation,
  metricHasRankGroups,
  metricPermutation,
  metricsById,
} from '../utils/Metrics'
import { allQueryFacets } from '../utils/queryFacets'
import Card from './Card'
import { ChartOptions, ChartOptionsState } from './ChartOptions'
import { ExpandOptions, ExpandOptionsState } from './ExpandOptions'
import Link from './Link'
import Loader from './Loader'
import { RankGroupsWidget, RankGroupsWidgetState } from './RankGroupsWidget'
import { SummaryWidget, SummaryWidgetState } from './SummaryWidget'
import Tooltip from './Tooltip'
import WidgetControls from './WidgetControls'
import WidgetControlsToggle from './WidgetControlsToggle'
import { WidgetStateResetter } from './WidgetStateResetter'
import { ButtonPlain } from './ButtonPlain'
import { Updater } from '../utils'
import { rankGroupOptions } from '../options/rankGroupOptions'

export type DisplayMode = 'summary' | 'rankGroups'
export type TimeSeriesWidgetMode = 'top' | 'winners' | 'losers'

export type TimeSeriesWidgetState = RankGroupsWidgetState &
  SummaryWidgetState &
  ExpandOptionsState &
  ChartOptionsState & {
    samples: number
    widgetMode: TimeSeriesWidgetMode
    displayMode: DisplayMode
    showLegends: boolean
  }

export function TimeSeriesWidget<TState extends TimeSeriesWidgetState>({
  title,
  description,
  rollupBy,
  rollupValues,
  groupBy,
  metricIds,
  showWidgetMode,
  canTitleDrillThrough,
  hidePagination,
  onClickSerie,
  canExpand,
  filters,
  state,
  setState,
  isDirty,
  onReset,
  renderSeriesLabel,
}: Pick<
  UseGroupByOverTimeQueryOptions,
  'rollupBy' | 'rollupValues' | 'filters' | 'groupBy'
> & {
  title: string
  description: string
  metricIds: MetricId[]
  showWidgetMode: boolean
  canTitleDrillThrough: boolean
  hidePagination?: boolean
  onClickSerie?: (series: GroupByQuerySeries) => void
  canExpand: boolean
  isDirty: boolean
  onReset: () => void
  state: TimeSeriesWidgetState
  setState: (state: Updater<TState>) => void
  renderSeriesLabel?: (serie: GroupByQuerySeries) => any
}) {
  const timeRangeState = useTimeRangeState()

  const dataQueryOptions = useGroupByOverTimeQueryOptions({
    start: timeRangeState.state[0].start,
    end: timeRangeState.state[0].end,
    rollupBy,
    rollupValues,
    groupBy,
    filters,
    desc: state.desc,
    limit: state.limit,
    offset: state.offset,
    sortByMetricPermutation: state.sortByMetricPermutation,
    samples: state.samples,
    metricPermutations: getMetricPermutations(state),
  })

  const dataQuery = useGroupByOverTimeQuery(dataQueryOptions)

  const [showControls, toggleControls] = React.useReducer(
    (state, action = !state) => action,
    false
  )

  const queryFacet = allQueryFacets.find(d => d.id === groupBy)!

  const groupByFilterValues = (filters as any)[queryFacet?.filterKey ?? '']

  useOnChange(
    groupByFilterValues?.length === 1,
    () => {
      if (groupByFilterValues?.length === 1) {
        setState(old => ({ ...old, displayMode: 'rankGroups' }))
      } else {
        setState(old => ({ ...old, displayMode: 'summary' }))
      }
    },
    {
      onMount: false,
    }
  )

  return (
    <Card className="divide-y divide-gray-500/20 overflow-hidden p-0">
      <div className="text m-0 flex flex-wrap items-center gap-2 p-2 font-bold">
        {canTitleDrillThrough ? (
          <Link
            to={`../${queryFacet.dashboardSlug}`}
            params={undefined as any}
            search={undefined as any}
          >
            {title}
          </Link>
        ) : (
          <span>{title}</span>
        )}
        <Tooltip tooltip={description}>
          <FaInfoCircle className="text-base text-gray-500 opacity-50" />
        </Tooltip>

        <div className="mr-auto">
          {dataQuery.isFetching ? <Loader /> : null}
        </div>
        <WidgetStateResetter
          {...{
            isDirty,
            onReset,
          }}
        />
        {showWidgetMode ? (
          <div>
            {[
              {
                label: 'Top',
                widgetMode: 'top',
                setState: (old: any) => ({
                  ...old,
                  widgetMode: 'top',
                  desc: true,
                  displayPostAggregation: 'value',

                  sortByMetricPermutation: {
                    ...old.sortByMetricPermutation,
                    postAggregation: 'value',
                  },
                }),
              },
              {
                label: 'Winners',
                widgetMode: 'winners',
                setState: (old: any) => ({
                  ...old,
                  widgetMode: 'winners',
                  desc: true,
                  displayPostAggregation: 'change',

                  sortByMetricPermutation: {
                    ...old.sortByMetricPermutation,
                    postAggregation: 'change',
                  },
                }),
              },
              {
                label: 'Losers',
                widgetMode: 'losers',
                setState: (old: any) => ({
                  ...old,
                  widgetMode: 'losers',
                  desc: false,
                  displayPostAggregation: 'change',

                  sortByMetricPermutation: {
                    ...old.sortByMetricPermutation,
                    postAggregation: 'change',
                  },
                }),
              },
            ].map((option, i, all) => {
              return (
                <Tooltip key={option.label} tooltip={option.label}>
                  <ButtonPlain
                    className={twMerge(
                      'bg-gray-500/10 text-xs',
                      'hover:bg-blue-500 hover:text-white',
                      state.widgetMode === option.widgetMode &&
                        'bg-gray-500/30',
                      i > 0 && 'rounded-l-none',
                      i < all.length - 1 && 'rounded-r-none'
                    )}
                    onClick={() => {
                      setState(option.setState)
                    }}
                  >
                    {option.label}
                  </ButtonPlain>
                </Tooltip>
              )
            })}
          </div>
        ) : null}
        {canExpand ? (
          <ExpandOptions
            {...{
              setState,
              state,
            }}
          />
        ) : null}
        <ChartOptions
          {...{
            setState,
            state,
          }}
        />
        <WidgetControlsToggle
          showControls={showControls}
          onToggle={toggleControls}
        />
      </div>
      {showControls ? (
        <>
          <div className="p-2">
            <WidgetControls
              {...{
                ...state,
                dataQueryOptions,
                metricIds,
                samples: state.samples,
                setState,
                allowSamples: true,
                allowSorting: true,
                allowMetrics: true,
                allowPostAggregation: true,
                allowExport: true,
                allowLegends: true,
              }}
            />
          </div>
        </>
      ) : null}
      {state.displayMode === 'summary' ? (
        <SummaryWidget
          {...{
            ...state,
            renderSeriesLabel,
            dataQuery,
            groupBy,
            metricIds,
            setState,
            hidePagination,
            onClickSerie,
          }}
        />
      ) : state.displayMode === 'rankGroups' ? (
        <RankGroupsWidget
          {...{
            ...state,
            metricIds,
            setState,
            dataQuery,
            groupBy,
            hidePagination,
            renderSeriesLabel,
            onClickSerie,
          }}
        />
      ) : null}
    </Card>
  )
}

function getMetricPermutations(opts: {
  displayMode: DisplayMode
  metricId: MetricId
  sortByMetricPermutation: MetricPermutation<MetricId>
}) {
  // Build the right metric permutations for the different display modes

  return opts.displayMode === 'rankGroups' && metricHasRankGroups(opts.metricId)
    ? [
        opts.sortByMetricPermutation,
        ...(metricsById[opts.metricId]?.postAggregations.flatMap(
          postAggregation => [
            metricPermutation(opts.metricId, {
              postAggregation,
            }),
            ...(metricHasRankGroups(opts.metricId)
              ? rankGroupOptions.map(option =>
                  metricPermutation(opts.metricId, {
                    subAggregation: option.value,
                    postAggregation,
                  })
                )
              : []),
          ]
        ) ?? []),
      ]
    : [
        opts.sortByMetricPermutation,
        ...(metricsById[opts.metricId]?.postAggregations.map(postAggregation =>
          metricPermutation(opts.metricId, {
            postAggregation,
          })
        ) ?? []),
      ]
}
