import * as React from 'react'
import Select from './Select'
import { Brand } from '../../openapi'
import { useBrandOptionsQuery } from '../hooks/brands'
import useGetLatest from '../hooks/useGetLatest'

type BrandOption = {
  value: string
  label: string
  brand: Brand
}

export function BrandPicker({
  excludeBrandIds,
  filterOptions,
  closeOnSelect,
  value,
  onChange,
  setTouched,
  onBlur,
  ...rest
}: {
  children?: any
  value?: string
  onChange?: (value: string, brand?: Brand) => void
  onBlur?: (e: any) => void
  setTouched?: (touched: boolean) => void
  filterOptions?: (options: BrandOption[]) => BrandOption[]
  excludeBrandIds?: string[]
} & Omit<
  React.ComponentProps<typeof Select>,
  'options' | 'value' | 'onChange'
>) {
  const brandOptionsQuery = useBrandOptionsQuery()
  const getFilterOptions = useGetLatest(filterOptions)

  const filteredOptions = React.useMemo(() => {
    let options = brandOptionsQuery.data ?? []

    if (excludeBrandIds) {
      options = options.filter(d => !excludeBrandIds?.includes(d.value))
    }

    if (getFilterOptions()) {
      options = getFilterOptions()!(options)
    }

    return options
  }, [brandOptionsQuery.data, excludeBrandIds, getFilterOptions])

  return (
    <Select<BrandOption>
      {...{
        closeOnSelect: closeOnSelect ?? (value ? true : false),
        placeholder: 'Search for a Monitored URL...',
        className: 'inline-block',
        isLoading: brandOptionsQuery.isLoading,
        ...rest,
        options: filteredOptions,
        value,
        onChange: (next: any) => {
          const brand = brandOptionsQuery.data?.find(
            d => d.value === next
          )?.brand
          onChange?.(next, brand as any)
        },
        onBlur: (e: Event) => {
          setTouched?.(true)
          onBlur?.(e)
        },
        renderOption: option => {
          return option.label
        },
      }}
    />
  )
}
