import { createColumnHelper } from '@tanstack/react-table'
import * as React from 'react'
import { FaEdit, FaTrashAlt } from 'react-icons/fa'
import Button from '../components/Button'
import Select from '../components/Select'
import useConfirm from '../hooks/useConfirm'
import { useTable } from '../hooks/useTable'
import { deviceOptions } from '../options/deviceOptions'
import { NO_GROUP_NAME } from '../utils/Constants'
import { formatDevice } from '../utils/Format'
import { dedupeKeywords } from '../utils/Phrases'
import Clickable from './Clickable'
import Table from './Table'

//

type DeviceCount = {
  device: string
  phraseCount: number
}

const columnHelper = createColumnHelper<DeviceCount>()

export default function JsonKeywordsDevices({
  keywords,
  onKeywordsChange,
}: any) {
  const confirm = useConfirm()

  const info = React.useMemo(() => dedupeKeywords(keywords), [keywords])

  const tableData = React.useMemo<DeviceCount[]>(
    () =>
      Object.entries(info.devices).map(([device, phrases]) => {
        return {
          device,
          phraseCount: phrases.length,
        }
      }),
    [info.devices]
  )

  const columns = React.useMemo(
    () => [
      columnHelper.accessor(d => formatDevice(d.device, { string: true }), {
        id: 'device',
        header: 'Device',
        filterFn: 'arrIncludesSome',
        cell: props => (
          <>
            <span className="inline-block w-6 text-center">
              {formatDevice(props.row.original.device)}
            </span>{' '}
            {props.getValue()}
          </>
        ),
      }),
      columnHelper.accessor('phraseCount', {
        header: 'Keyword Phrases',
      }),
      columnHelper.display({
        id: 'actions',
        header: 'Actions',
        cell: props => (
          <div className="flex items-center gap-2">
            {props.row.original.device !== NO_GROUP_NAME ? (
              <>
                <Select
                  label="Devices"
                  placeholder="Select a Device..."
                  options={deviceOptions}
                  onChange={async value => {
                    const confirmed = await confirm({
                      message: `This will change the device for all keywords in this row.`,
                    })

                    if (confirmed) {
                      keywords.forEach((keyword: any) => {
                        keyword.devices = keyword.devices
                          ? keyword.devices.map((device: any) =>
                              device === props.row.original.device
                                ? value
                                : device
                            )
                          : []
                      })
                      onKeywordsChange([...keywords])
                    }
                  }}
                  className="inline-flex"
                >
                  {({ onClick }: any) => (
                    <Clickable className="text-sm" onClick={onClick}>
                      <FaEdit className="inline" /> Change Device
                    </Clickable>
                  )}
                </Select>{' '}
                <Clickable
                  onClick={async () => {
                    const confirmed = await confirm({
                      message: `This will delete any keyword phrases from this source that have this device, regardless if they are using other devices.`,
                    })

                    if (confirmed) {
                      onKeywordsChange(
                        keywords.filter((keyword: any) => {
                          return !(keyword.devices || []).find(
                            (device: any) =>
                              device === props.row.original.device
                          )
                        })
                      )
                    }
                  }}
                >
                  <FaTrashAlt className="inline" /> Remove Keyword Phrases
                </Clickable>
              </>
            ) : null}
          </div>
        ),
      }),
    ],
    [confirm, deviceOptions, onKeywordsChange, keywords]
  )

  const table = useTable({
    data: tableData,
    columns,
    initialState: {
      pagination: {
        pageSize: 10,
      },
      sorting: [{ id: 'device', desc: false }],
    },
    showToolbar: true,
  })

  return (
    <div className="rounded-lg border border-gray-200 dark:border-gray-800">
      <Table table={table} />
    </div>
  )
}
