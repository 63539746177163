import * as React from 'react'
import { useField, useForm } from 'react-form'
import { FaCheck, FaTrashAlt } from 'react-icons/fa'
import { IoIosWarning } from 'react-icons/io'
import { Project } from '../../utils/Api'
import Validate from '../../utils/Validate'

//
import Button from '../../components/Button'
import Caption from '../../components/Caption'
import Card from '../../components/Card'
import Spinner from '../../components/Spinner'
import TextField from '../../components/TextField'
import usePauseEvents from '../../hooks/usePauseEvents'
import useSlugGeneration from '../../hooks/useSlugGeneration'
import { ButtonPlain } from '../../components/ButtonPlain'
import { useActiveWorkspaceId } from '../../hooks/workspaces'
import TextAreaField from '../../components/TextAreaField'
import LabelWrap from '../../components/LabelWrap'
import TextArea from '../../components/TextAreaInput'

export function ProjectForm({
  project,
  onSubmit,
  onRemove,
  showId,
  beforeSubmit,
}: {
  project?: Project
  onSubmit: (project: Project) => void
  onRemove?: () => void
  showId?: boolean
  beforeSubmit?: React.ReactNode
}) {
  const activeWorkspaceId = useActiveWorkspaceId()

  const defaultValues = React.useMemo(
    () =>
      project || {
        name: '',
        id: null,
        workspaceId: activeWorkspaceId,
      },
    [activeWorkspaceId, project]
  )

  usePauseEvents('team', defaultValues.id, true)

  const {
    Form,
    values,
    meta: { isSubmitting },
    formContext,
  } = useForm({
    // @ts-expect-error  // Type 'Team | { name: string; id: null; }' is not a... Remove this comment to see the full error message
    defaultValues,
    // @ts-expect-error  // Type '(team: Team) => void' is not assignable to t... Remove this comment to see the full error message
    onSubmit,
  })

  const [isAutoSlug, setIsAutoSlug] = React.useState(true)

  const nameField = useField('name', { formContext })
  const slugField = useField('slug', { formContext })

  useSlugGeneration(project?.id ? false : isAutoSlug, nameField, slugField)

  return (
    <Form>
      <TextField
        field="name"
        validate={Validate.projectName()}
        label="Project Name"
        placeholder="My Project"
        className="w-80 flex-none"
      />
      <div className="h-2" />
      {values.id && (showId ?? true) ? (
        <>
          <TextField
            field="id"
            label="Project ID"
            placeholder="-"
            disabled
            className="w-80 flex-none"
          />
          <div className="h-2" />
        </>
      ) : null}
      <TextField
        field="slug"
        validate={Validate.projectSlug()}
        placeholder="myprojectorteam"
        label={`Project Slug${
          !project?.id && isAutoSlug ? ' (Auto Generated)' : ''
        }`}
        // @ts-expect-error  // Type 'number | boolean' is not assignable to type ... Remove this comment to see the full error message
        disabled={project?.id || isAutoSlug}
        className="w-80 flex-none"
      />
      {!project?.id ? (
        <>
          <div className="h-2" />
          <Button
            size="xs"
            color="blue-800"
            onClick={() =>
              setIsAutoSlug(old => {
                if (!old) {
                  slugField.setValue('')
                }
                return !old
              })
            }
          >
            {isAutoSlug ? 'Edit Slug' : 'Auto Generate Slug'}
          </Button>
        </>
      ) : null}
      <div className="h-2" />
      {project?.description ? (
        <>
          <LabelWrap label="Description">
            <TextArea
              value={project?.description}
              label="Description"
              placeholder="A useful description of this project..."
              rows={Math.min(project?.description.split('\n').length + 1, 20)}
            />
          </LabelWrap>
          <div className="h-2" />
        </>
      ) : null}
      <Caption>
        <IoIosWarning className="inline text-yellow-400" /> Project slugs cannot
        be changed after creation.
      </Caption>
      {beforeSubmit ?? null}
      <div className="h-4" />
      <div className="flex gap-1">
        <ButtonPlain type="submit" className="bg-green-500">
          {isSubmitting ? (
            <>
              <Spinner color="white" />{' '}
              {project?.id ? 'Saving Project' : 'Creating Project'}
            </>
          ) : (
            <>
              <FaCheck className="inline" />{' '}
              {project?.id ? 'Save Project' : 'Create Project'}
            </>
          )}
        </ButtonPlain>
        {project?.id && onRemove ? (
          <ButtonPlain
            type="button"
            className="bg-gray-500 hover:bg-red-500"
            onClick={onRemove}
          >
            <FaTrashAlt className="inline" /> Remove Project
          </ButtonPlain>
        ) : null}
      </div>
    </Form>
  )
}
