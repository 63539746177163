// partial copy from https://github.com/googleapis/googleapis/blob/d02e58244db5d01607ec2ad52a47e7edce8612f0/google/cloud/language/v1/language_service.proto
//
// Copyright 2022 Google LLC
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

// @generated by protoc-gen-es v1.3.0 with parameter "target=ts"
// @generated from file nozzle/urls/v1/language.proto (package nozzle.urls.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * Represents a phrase in the text that is a known entity, such as
 * a person, an organization, or location. The API associates information, such
 * as salience and mentions, with entities.
 *
 * @generated from message nozzle.urls.v1.Entity
 */
export class Entity extends Message<Entity> {
  /**
   * The representative name for the entity.
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * The entity type.
   *
   * @generated from field: nozzle.urls.v1.Entity.Type type = 2;
   */
  type = Entity_Type.UNKNOWN;

  /**
   * Metadata associated with the entity.
   *
   * For most entity types, the metadata is a Wikipedia URL (`wikipedia_url`)
   * and Knowledge Graph MID (`mid`), if they are available. For the metadata
   * associated with other entity types, see the Type table below.
   *
   * @generated from field: map<string, string> metadata = 3;
   */
  metadata: { [key: string]: string } = {};

  /**
   * The salience score associated with the entity in the [0, 1.0] range.
   *
   * The salience score for an entity provides information about the
   * importance or centrality of that entity to the entire document text.
   * Scores closer to 0 are less salient, while scores closer to 1.0 are highly
   * salient.
   *
   * @generated from field: float salience = 4;
   */
  salience = 0;

  /**
   * The mentions of this entity in the input document. The API currently
   * supports proper noun mentions.
   *
   * @generated from field: repeated nozzle.urls.v1.EntityMention mentions = 5;
   */
  mentions: EntityMention[] = [];

  /**
   * For calls to [AnalyzeEntitySentiment][] or if
   * [AnnotateTextRequest.Features.extract_entity_sentiment][google.cloud.language.v1.AnnotateTextRequest.Features.extract_entity_sentiment] is set to
   * true, this field will contain the aggregate sentiment expressed for this
   * entity in the provided document.
   *
   * @generated from field: nozzle.urls.v1.Sentiment sentiment = 6;
   */
  sentiment?: Sentiment;

  constructor(data?: PartialMessage<Entity>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.Entity";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(Entity_Type) },
    { no: 3, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 4, name: "salience", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "mentions", kind: "message", T: EntityMention, repeated: true },
    { no: 6, name: "sentiment", kind: "message", T: Sentiment },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Entity {
    return new Entity().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Entity {
    return new Entity().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Entity {
    return new Entity().fromJsonString(jsonString, options);
  }

  static equals(a: Entity | PlainMessage<Entity> | undefined, b: Entity | PlainMessage<Entity> | undefined): boolean {
    return proto3.util.equals(Entity, a, b);
  }
}

/**
 * The type of the entity. For most entity types, the associated metadata is a
 * Wikipedia URL (`wikipedia_url`) and Knowledge Graph MID (`mid`). The table
 * below lists the associated fields for entities that have different
 * metadata.
 *
 * @generated from enum nozzle.urls.v1.Entity.Type
 */
export enum Entity_Type {
  /**
   * Unknown
   *
   * @generated from enum value: UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * Person
   *
   * @generated from enum value: PERSON = 1;
   */
  PERSON = 1,

  /**
   * Location
   *
   * @generated from enum value: LOCATION = 2;
   */
  LOCATION = 2,

  /**
   * Organization
   *
   * @generated from enum value: ORGANIZATION = 3;
   */
  ORGANIZATION = 3,

  /**
   * Event
   *
   * @generated from enum value: EVENT = 4;
   */
  EVENT = 4,

  /**
   * Artwork
   *
   * @generated from enum value: WORK_OF_ART = 5;
   */
  WORK_OF_ART = 5,

  /**
   * Consumer product
   *
   * @generated from enum value: CONSUMER_GOOD = 6;
   */
  CONSUMER_GOOD = 6,

  /**
   * Other types of entities
   *
   * @generated from enum value: OTHER = 7;
   */
  OTHER = 7,

  /**
   * Phone number
   *
   * The metadata lists the phone number, formatted according to local
   * convention, plus whichever additional elements appear in the text:
   *
   * * `number` - the actual number, broken down into sections as per local
   * convention
   * * `national_prefix` - country code, if detected
   * * `area_code` - region or area code, if detected
   * * `extension` - phone extension (to be dialed after connection), if
   * detected
   *
   * @generated from enum value: PHONE_NUMBER = 9;
   */
  PHONE_NUMBER = 9,

  /**
   * Address
   *
   * The metadata identifies the street number and locality plus whichever
   * additional elements appear in the text:
   *
   * * `street_number` - street number
   * * `locality` - city or town
   * * `street_name` - street/route name, if detected
   * * `postal_code` - postal code, if detected
   * * `country` - country, if detected<
   * * `broad_region` - administrative area, such as the state, if detected
   * * `narrow_region` - smaller administrative area, such as county, if
   * detected
   * * `sublocality` - used in Asian addresses to demark a district within a
   * city, if detected
   *
   * @generated from enum value: ADDRESS = 10;
   */
  ADDRESS = 10,

  /**
   * Date
   *
   * The metadata identifies the components of the date:
   *
   * * `year` - four digit year, if detected
   * * `month` - two digit month number, if detected
   * * `day` - two digit day number, if detected
   *
   * @generated from enum value: DATE = 11;
   */
  DATE = 11,

  /**
   * Number
   *
   * The metadata is the number itself.
   *
   * @generated from enum value: NUMBER = 12;
   */
  NUMBER = 12,

  /**
   * Price
   *
   * The metadata identifies the `value` and `currency`.
   *
   * @generated from enum value: PRICE = 13;
   */
  PRICE = 13,
}
// Retrieve enum metadata with: proto3.getEnumType(Entity_Type)
proto3.util.setEnumType(Entity_Type, "nozzle.urls.v1.Entity.Type", [
  { no: 0, name: "UNKNOWN" },
  { no: 1, name: "PERSON" },
  { no: 2, name: "LOCATION" },
  { no: 3, name: "ORGANIZATION" },
  { no: 4, name: "EVENT" },
  { no: 5, name: "WORK_OF_ART" },
  { no: 6, name: "CONSUMER_GOOD" },
  { no: 7, name: "OTHER" },
  { no: 9, name: "PHONE_NUMBER" },
  { no: 10, name: "ADDRESS" },
  { no: 11, name: "DATE" },
  { no: 12, name: "NUMBER" },
  { no: 13, name: "PRICE" },
]);

/**
 * Represents the feeling associated with the entire text or entities in
 * the text.
 *
 * @generated from message nozzle.urls.v1.Sentiment
 */
export class Sentiment extends Message<Sentiment> {
  /**
   * A non-negative number in the [0, +inf) range, which represents
   * the absolute magnitude of sentiment regardless of score (positive or
   * negative).
   *
   * @generated from field: float magnitude = 2;
   */
  magnitude = 0;

  /**
   * Sentiment score between -1.0 (negative sentiment) and 1.0
   * (positive sentiment).
   *
   * @generated from field: float score = 3;
   */
  score = 0;

  constructor(data?: PartialMessage<Sentiment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.Sentiment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "magnitude", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Sentiment {
    return new Sentiment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Sentiment {
    return new Sentiment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Sentiment {
    return new Sentiment().fromJsonString(jsonString, options);
  }

  static equals(a: Sentiment | PlainMessage<Sentiment> | undefined, b: Sentiment | PlainMessage<Sentiment> | undefined): boolean {
    return proto3.util.equals(Sentiment, a, b);
  }
}

/**
 * Represents a mention for an entity in the text. Currently, proper noun
 * mentions are supported.
 *
 * @generated from message nozzle.urls.v1.EntityMention
 */
export class EntityMention extends Message<EntityMention> {
  /**
   * The mention text.
   *
   * @generated from field: nozzle.urls.v1.TextSpan text = 1;
   */
  text?: TextSpan;

  /**
   * The type of the entity mention.
   *
   * @generated from field: nozzle.urls.v1.EntityMention.Type type = 2;
   */
  type = EntityMention_Type.TYPE_UNKNOWN;

  /**
   * For calls to [AnalyzeEntitySentiment][] or if
   * [AnnotateTextRequest.Features.extract_entity_sentiment][google.cloud.language.v1.AnnotateTextRequest.Features.extract_entity_sentiment] is set to
   * true, this field will contain the sentiment expressed for this mention of
   * the entity in the provided document.
   *
   * @generated from field: nozzle.urls.v1.Sentiment sentiment = 3;
   */
  sentiment?: Sentiment;

  constructor(data?: PartialMessage<EntityMention>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.EntityMention";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "text", kind: "message", T: TextSpan },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(EntityMention_Type) },
    { no: 3, name: "sentiment", kind: "message", T: Sentiment },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EntityMention {
    return new EntityMention().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EntityMention {
    return new EntityMention().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EntityMention {
    return new EntityMention().fromJsonString(jsonString, options);
  }

  static equals(a: EntityMention | PlainMessage<EntityMention> | undefined, b: EntityMention | PlainMessage<EntityMention> | undefined): boolean {
    return proto3.util.equals(EntityMention, a, b);
  }
}

/**
 * The supported types of mentions.
 *
 * @generated from enum nozzle.urls.v1.EntityMention.Type
 */
export enum EntityMention_Type {
  /**
   * Unknown
   *
   * @generated from enum value: TYPE_UNKNOWN = 0;
   */
  TYPE_UNKNOWN = 0,

  /**
   * Proper name
   *
   * @generated from enum value: PROPER = 1;
   */
  PROPER = 1,

  /**
   * Common noun (or noun compound)
   *
   * @generated from enum value: COMMON = 2;
   */
  COMMON = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(EntityMention_Type)
proto3.util.setEnumType(EntityMention_Type, "nozzle.urls.v1.EntityMention.Type", [
  { no: 0, name: "TYPE_UNKNOWN" },
  { no: 1, name: "PROPER" },
  { no: 2, name: "COMMON" },
]);

/**
 * Represents an output piece of text.
 *
 * @generated from message nozzle.urls.v1.TextSpan
 */
export class TextSpan extends Message<TextSpan> {
  /**
   * The content of the output text.
   *
   * @generated from field: string content = 1;
   */
  content = "";

  /**
   * The API calculates the beginning offset of the content in the original
   * document according to the [EncodingType][google.cloud.language.v1.EncodingType] specified in the API request.
   *
   * @generated from field: int32 begin_offset = 2;
   */
  beginOffset = 0;

  constructor(data?: PartialMessage<TextSpan>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.TextSpan";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "begin_offset", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TextSpan {
    return new TextSpan().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TextSpan {
    return new TextSpan().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TextSpan {
    return new TextSpan().fromJsonString(jsonString, options);
  }

  static equals(a: TextSpan | PlainMessage<TextSpan> | undefined, b: TextSpan | PlainMessage<TextSpan> | undefined): boolean {
    return proto3.util.equals(TextSpan, a, b);
  }
}

/**
 * Represents a category returned from the text classifier.
 *
 * @generated from message nozzle.urls.v1.ClassificationCategory
 */
export class ClassificationCategory extends Message<ClassificationCategory> {
  /**
   * The name of the category representing the document, from the [predefined
   * taxonomy](https://cloud.google.com/natural-language/docs/categories).
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * The classifier's confidence of the category. Number represents how certain
   * the classifier is that this category represents the given text.
   *
   * @generated from field: float confidence = 2;
   */
  confidence = 0;

  constructor(data?: PartialMessage<ClassificationCategory>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.ClassificationCategory";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "confidence", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ClassificationCategory {
    return new ClassificationCategory().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ClassificationCategory {
    return new ClassificationCategory().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ClassificationCategory {
    return new ClassificationCategory().fromJsonString(jsonString, options);
  }

  static equals(a: ClassificationCategory | PlainMessage<ClassificationCategory> | undefined, b: ClassificationCategory | PlainMessage<ClassificationCategory> | undefined): boolean {
    return proto3.util.equals(ClassificationCategory, a, b);
  }
}

