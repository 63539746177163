import moment from 'moment'
// @ts-expect-error  // Could not find a declaration file for module 'mome... Remove this comment to see the full error message
import parseFormat from 'moment-parseformat'
import * as React from 'react'
import useCalendar from '../hooks/useCalendar'
import { convertQuickPickToRange } from '../utils/TimeRangeQuickPicks'

//

export default function DateRangePicker({
  offset: userOffset = -1,
  displayFormat = 'll',
  value: userValue,
  minDate,
  maxDate,
  onChange,
}: any) {
  // Ensuyre that the userValue is parsed into a date
  userValue = React.useMemo(() => {
    return userValue.map((d: any) => moment.unix(d).utc())
  }, [userValue])

  // Ensure min and max dates are also parts into dates
  minDate = React.useMemo(
    () =>
      // @ts-expect-error  // Property 'utc' does not exist on type 'number'.
      minDate ? moment(minDate).unix().utc(true).startOf('day') : undefined,
    [minDate]
  )

  maxDate = React.useMemo(
    () =>
      maxDate
        ? // @ts-expect-error  // Property 'utc' does not exist on type 'number'.
          moment(maxDate).unix().utc(true).startOf('day')
        : moment().utc(true),
    [maxDate]
  )

  const isInMinMax = React.useCallback(
    date => {
      return (
        (minDate ? minDate.isSameOrBefore(date) : true) &&
        (maxDate ? maxDate.isSameOrAfter(date) : true)
      )
    },
    [maxDate, minDate]
  )

  // State!
  const [isOpen, setIsOpen] = React.useState(false)
  const [focusedInputIndex, setFocusedInputIndex] = React.useState(null)
  const [focusedValues, setFocusedValue] = React.useState([])
  const [focusedError, setFocusedError] = React.useState(['', ''])
  const [offset, setOffset] = React.useState(userOffset)
  const [hoveredDate, setHoveredDate] = React.useState(null)
  const [dirty, setDirty] = React.useState(false)
  const [value, setValue] = React.useState(userValue)
  const [selectedQuickPick, setSelectedQuickPick] = React.useState(null)

  React.useEffect(() => {
    setValue(userValue)
  }, [userValue])

  React.useEffect(() => {
    if (value !== userValue && !dirty) {
      setDirty(true)
    } else if (value === userValue && dirty) {
      setDirty(false)
    }
  }, [value, userValue, dirty])

  // When the mouse enters a day box, set it as the hovered date
  const onDayMouseEnter = React.useCallback(date => {
    setHoveredDate(date)
  }, [])

  // When the focused text value changes
  const onInputChange = React.useCallback(
    (index, value) => {
      // Parse the date from the string
      const date = moment.utc(value, parseFormat(value), true)

      // Get an error is necessary
      const focusedError =
        (!date.isValid() || !isInMinMax(date)) && 'Invalid Date'

      // @ts-expect-error  // Argument of type '(old: never[]) => any[]' is not ... Remove this comment to see the full error message
      setFocusedValue(old => (!index ? [value, old[1]] : [old[0], value]))
      // @ts-expect-error  // Argument of type '(old: string[]) => (string | fal... Remove this comment to see the full error message
      setFocusedError(old =>
        !index ? [focusedError, old[1]] : [old[0], focusedError]
      )
    },
    [isInMinMax]
  )

  const onDateChange = React.useCallback(
    ({ selectable, date }) => {
      // If this date isn't selectable, then don't do anything
      if (!selectable) {
        return
      }

      date = moment(date).utc(true).startOf('day')

      // If this date is outside of the acceptable range, don't do anything
      if (!isInMinMax(date)) {
        return
      }

      setSelectedQuickPick(null)

      // If the focused input doesn't have an end date
      if (focusedInputIndex === 0 || focusedInputIndex === null) {
        // @ts-expect-error  // Argument of type '(old: never[]) => (string | unde... Remove this comment to see the full error message
        setFocusedValue(old => [old[0], ''])
        setValue((old: any) => [date, old[1]])
        // @ts-expect-error  // Argument of type '1' is not assignable to paramete... Remove this comment to see the full error message
        setFocusedInputIndex(1)
      } else if (focusedInputIndex) {
        if (date.isBefore(value[0])) {
          setValue((old: any) => [date, old[0]])
        } else {
          setValue((old: any) => [old[0], date])
        }
        // @ts-expect-error  // Argument of type '(old: never[]) => undefined[]' i... Remove this comment to see the full error message
        setFocusedValue(old => [old[0]])
        setFocusedInputIndex(null)
      }
    },
    [focusedInputIndex, isInMinMax, value]
  )

  const setQuickPick = (quickPick: any) => {
    const [start, end] = convertQuickPickToRange(quickPick)
    setSelectedQuickPick(quickPick)
    // @ts-expect-error  // Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
    setValue([moment.unix(start).utc(), moment.unix(end).utc()])
    setFocusedInputIndex(null)
  }

  const apply = () => {
    setIsOpen(false)
    if (value[1].isBefore(value[0])) {
      const holder = value[0]
      value[0] = value[1]
      value[1] = holder
    }
    onChange(value.map((d: any) => d.utc(true).startOf('day').unix()))
  }

  const revert = () => {
    setSelectedQuickPick(null)
    setFocusedInputIndex(null)

    setFocusedValue('')
    setValue(userValue)
  }

  const isHighlighted = React.useCallback(
    (mdate, isNewSelection) => {
      const isInside = (d: any, start: any, end: any) =>
        d &&
        d.isBetween(
          ...(start && start.isAfter(end) ? [end, start] : [start, end]),
          undefined,
          '[]'
        ) &&
        isInMinMax(d)

      if (isNewSelection) {
        if (focusedInputIndex === 0) {
          // Selecting first
          if (isInside(mdate, hoveredDate, hoveredDate)) {
            return true
          }
        } else if (focusedInputIndex === 1) {
          // Selecting Second
          if (
            isInside(mdate, value[0], hoveredDate || value[1] || userValue[1])
          ) {
            return true
          }
        } else {
          if (isInside(mdate, value[0], value[1])) {
            return true
          }
        }
      } else {
        // Previous Range for current highlight
        if (
          // Other New Range
          isInside(mdate, userValue[0], userValue[1])
        ) {
          return true
        }
      }
      return false
    },
    [focusedInputIndex, hoveredDate, isInMinMax, userValue, value]
  )

  const submitInput = React.useCallback(() => {
    const isChanged =
      // @ts-expect-error  // Type 'null' cannot be used as an index type.
      focusedValues[focusedInputIndex] !== value[focusedInputIndex]

    const date = moment(
      // @ts-expect-error  // Type 'null' cannot be used as an index type.
      focusedValues[focusedInputIndex],
      // @ts-expect-error  // Type 'null' cannot be used as an index type.
      parseFormat(focusedValues[focusedInputIndex] ?? '')
    )

    const isValid = date.isValid()

    if (isChanged && isValid) {
      return onDateChange({
        selectable: true,
        date: date.toDate(),
      })
    }
  }, [focusedInputIndex, focusedValues, onDateChange, value])

  const textValue = value
    .map((d: any) => d.local(true).format(displayFormat))
    .join(' - ')

  const getInputProps = ({ onClick, ...rest }: any = {}) => ({
    value: textValue,
    onClick: (e: any) => {
      setIsOpen(true)
      if (onClick) {
        onClick(e)
      }
    },
    ...rest,
  })

  const getCalendarsProps = (props = {}) => ({
    tabIndex: '0',
    ...props,
  })

  const submitFocusedInput = React.useCallback(() => {
    submitInput()
  }, [submitInput])

  let { calendars, getBackProps, getForwardProps, getDateProps } = useCalendar({
    offset,
    onOffsetChanged: setOffset,
    onDateChange,
    selected: value.map((d: any) => d.local(true).toDate()),
    minDate,
    maxDate,
    monthsToDisplay: 2,
  })

  const getPreviousMonthProps = ({ onClick, ...rest }: any = {}) =>
    getBackProps({
      calendars,
      onClick: (e: any) => {
        if (onClick) {
          onClick(e)
        }
      },
      ...rest,
    })

  const getNextMonthProps = ({ onClick, ...rest }: any = {}) =>
    getForwardProps({
      calendars,
      onClick: (e: any) => {
        if (onClick) {
          onClick(e)
        }
      },
      ...rest,
    })

  // @ts-expect-error  // Type '{ getCalendarProps: (props?: {}) => { key: a... Remove this comment to see the full error message
  calendars = React.useMemo(
    () =>
      calendars.map(calendar => ({
        ...calendar,
        getCalendarProps: (props = {}) => ({
          key: calendar.year,
          ...props,
        }),
        weeks: calendar.weeks.map(week => ({
          days: week.map((dateObj, index) => {
            let { date, selected, selectable, today } = dateObj || {}

            // @ts-expect-error  // Type 'Moment | null' is not assignable to type 'ne... Remove this comment to see the full error message
            date = date ? moment(date).startOf('day') : null

            return {
              date,
              focusedInputIndex,
              selected,
              selectable,
              today,
              // @ts-expect-error  // Expected 2 arguments, but got 1.
              isOldSelection: isHighlighted(date),
              isNewSelection: isHighlighted(date, true),
              getDayProps: ({
                onMouseEnter,
                onMouseLeave,
                className = '',
                ...rest
              }: any = {}) =>
                dateObj
                  ? getDateProps(
                      {
                        ...dateObj,
                        focusedInputIndex,
                      },
                      {
                        key: `${calendar.year}${calendar.month}${index}`,
                        className: `${className} __calendarDay`,
                        ...(dateObj
                          ? {
                              onMouseEnter: (e: any) => {
                                onDayMouseEnter(moment(date).startOf('day'))
                                if (onMouseEnter) {
                                  onMouseEnter(e)
                                }
                              },
                              onMouseLeave: (e: any) => {
                                setHoveredDate(null)
                                if (onMouseLeave) {
                                  onMouseLeave(e)
                                }
                              },
                            }
                          : {}),
                        ...rest,
                      }
                    )
                  : {
                      key: `${calendar.year}${calendar.month}${index}`,
                    },
            }
          }),
        })),
      })),
    [calendars, focusedInputIndex, getDateProps, isHighlighted, onDayMouseEnter]
  )

  const inputs = React.useMemo(
    () =>
      [value[0], value[1]].map((part, index) => ({
        error: focusedInputIndex === index && focusedError,
        getInputProps: ({
          refKey = 'ref',
          onFocus,
          onBlur,
          onChange,
          ...rest
        }: any = {}) => ({
          placeholder: 'Select a date...',
          onFocus: (e: any) => {
            // @ts-expect-error  // Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
            setFocusedInputIndex(index)
            if (onFocus) {
              onFocus(e)
            }
          },
          onBlur: (e: any) => {
            e.persist()
            if (
              e.relatedTarget &&
              e.relatedTarget.classList.contains('__calendarDay')
            ) {
              return
            }
            submitFocusedInput()
            if (onBlur) {
              onBlur(e)
            }
          },
          focused: focusedInputIndex === index,
          value:
            focusedInputIndex === index
              ? focusedValues[index]
              : moment(part).format(displayFormat),
          onChange: (e: any) => {
            onInputChange(index, e.target.value)
            if (onChange) {
              onChange(e)
            }
          },
          ...rest,
        }),
      })),
    [
      displayFormat,
      focusedError,
      focusedInputIndex,
      focusedValues,
      onInputChange,
      submitFocusedInput,
      value,
    ]
  )

  return {
    isOpen,
    inputs,
    focusedInputIndex,
    value,
    offset,
    selectedQuickPick,
    focusedValues,
    focusedError,
    minDate,
    maxDate,
    dirty,
    textValue,
    calendars,
    submitInput,
    submitFocusedInput,
    revert,
    apply,
    setQuickPick,
    setIsOpen,
    // prop-getters
    getInputProps,
    getCalendarsProps,
    getPreviousMonthProps,
    getNextMonthProps,
  }
}
