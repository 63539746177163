import * as React from 'react'
import { FaInfoCircle, FaPlus, FaTimes } from 'react-icons/fa'
import { twMerge } from 'tailwind-merge'
import Caption from '../components/Caption'
import Select from '../components/Select'
import { deviceOptions } from '../options/deviceOptions'
import { formatDevice } from '../utils/Format'
import Clickable from './Clickable'
import DevicePicker from './DevicePicker'

//

const defaultValue: string[] = []

type Props = {
  value?: any
  onBlur?: any
  required?: boolean
  placeholder?: string
  options?: any
  multi?: boolean
  showCaption?: boolean
  caption?: any
  children?: any
  onChange?: any
  className?: string
}

export default function DevicesInput({
  value = defaultValue,
  required,
  placeholder = 'Select Devices...',
  options = deviceOptions,
  multi = true,
  showCaption = true,
  caption = (
    <Caption>
      <FaInfoCircle className="inline" /> Above is a list of our supported
      device types. Every unique serp will be pulled once for each of these
      devices.
    </Caption>
  ),
  onChange,
  onBlur,
  ...rest
}: Props) {
  return (
    <div
      {...rest}
      className={twMerge(
        `flex flex-col divide-y divide-gray-100 rounded
        border border-gray-100 text-sm dark:divide-gray-800 dark:border-gray-800`,
        rest.className
      )}
    >
      {value?.length ? (
        value.map((device: any, i: any) => (
          <div key={device} className="flex justify-between">
            <div className="flex-1 p-1.5">
              {formatDevice(device, { short: false })}
            </div>
            <Clickable
              className="p-2 opacity-30 hover:text-red-500 hover:opacity-100 dark:text-white"
              onClick={() => {
                onChange(value.filter((_: any, j: any) => j !== i))
              }}
            >
              <FaTimes />
            </Clickable>
          </div>
        ))
      ) : (
        <div className="p-1.5">No devices selected</div>
      )}
      <DevicePicker
        onChange={(next: any) => {
          onChange([...new Set([...value, next]).values()])
        }}
        excludeDevices={value}
      >
        {({ onClick }: any) => (
          <Clickable
            onClick={onClick}
            className="flex items-center gap-1 p-2 !font-bold text-blue-500"
          >
            <FaPlus /> Add Device
          </Clickable>
        )}
      </DevicePicker>
    </div>
  )
}

// <Select
//   {...{
//     multi,
//     placeholder,
//     options,
//     value,
//     error: isTouched && error,
//     onBlur: e => {
//       setTouched(true)
//       onBlur && onBlur(e)
//     },
//     ...rest,
//   }}
// />
// {showCaption ? caption : null}
