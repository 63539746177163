import * as React from 'react'
import usePopup from './usePopup'

export default function useConfirm() {
  const popup = usePopup()

  return React.useCallback(
    (options: Parameters<typeof popup>[0]) =>
      popup({
        ...options,
        isConfirm: true,
      }),
    [popup]
  )
}
