import { useMatch, useSearch } from '@tanstack/react-router'
import { FaAngleRight } from 'react-icons/fa'
import Card from '../../components/Card'
import QueryGate from '../../components/QueryGate'
import { useKeywordSource } from '../../hooks/keywordSources'
import {
  KEYWORD_SOURCE_TYPE_AUTOMOTIVE,
  KEYWORD_SOURCE_TYPE_TEMPLATE,
} from '../../utils/Constants'
import { KeywordSourceAutomotiveEditor } from './KeywordSourceAutomotive'
import { KeywordSourceTemplate } from './KeywordSourceTemplate'
import { KeywordSourceV1 } from './KeywordSourceV1'
import {
  KeywordSourceConfigTypePb,
  KeywordSourceDataModelConfigPb,
} from '../../utils/proto'
import { backToKeywordManager } from './KeywordManager'
import { ProjectPicker } from '../../components/ProjectPicker'
import { Head } from '../../components/Head'
import { LocationGenerics } from '../../LocationGenerics'

export function KeywordSource(props: { isNew?: boolean }) {
  const search = useSearch()
  const { keywordSourceId } = useMatch().params

  const keywordSourceQuery = useKeywordSource({
    id: keywordSourceId,
  })

  const renderEditor = (isNew: boolean) => {
    if (
      (isNew && search.sourceType === KEYWORD_SOURCE_TYPE_AUTOMOTIVE) ||
      (keywordSourceQuery.data?.config.value as KeywordSourceDataModelConfigPb)
        ?.adHocDataModel?.children[0]
    ) {
      return (
        <KeywordSourceAutomotiveEditor
          keywordSource={keywordSourceQuery.data}
        />
      )
    }
    if (
      (isNew && search.sourceType === KEYWORD_SOURCE_TYPE_TEMPLATE) ||
      keywordSourceQuery.data?.type === KeywordSourceConfigTypePb.DATA_MODEL
    ) {
      return <KeywordSourceTemplate keywordSource={keywordSourceQuery.data} />
    }

    if (isNew) {
      return <KeywordSourceV1 keywordSource={search.keywordSource} />
    }

    return <KeywordSourceV1 keywordSource={keywordSourceQuery.data!} />
  }

  return (
    <div className="space-y-2 p-2">
      <Head>
        <title>Keyword Source - {keywordSourceQuery.data?.name ?? ''}</title>
      </Head>
      <Card className="flex flex-wrap items-center gap-2">
        <ProjectPicker />
        <FaAngleRight />
        {backToKeywordManager}
        <FaAngleRight />
        {props.isNew ? (
          <div className="text-xl font-bold">Create Keyword Source</div>
        ) : (
          <QueryGate query={keywordSourceQuery} small>
            {() =>
              keywordSourceQuery.data ? (
                <div className="text-xl font-bold">
                  {keywordSourceQuery.data.name}
                </div>
              ) : null
            }
          </QueryGate>
        )}
      </Card>
      {(() => {
        if (props.isNew) {
          return renderEditor(true)
        }

        return (
          <QueryGate query={keywordSourceQuery} className="m-4">
            {() => renderEditor(false)}
          </QueryGate>
        )
      })()}
    </div>
  )
}
