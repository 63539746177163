import useGroupByOverTimeQuery from '../hooks/useGroupByOverTimeQuery'
import {
  UseGroupByOverTimeQueryOptions,
  useGroupByOverTimeQueryOptions,
} from '../hooks/useGroupByOverTimeQueryOptions'
import useTimeRangeState from '../hooks/useTimeRanges'
import { Updater } from '../utils'
import {
  MetricId,
  MetricPostAggregation,
  metricPermutation,
} from '../utils/Metrics'
import { QueryFacetId, queryFacetsById } from '../utils/queryFacets'
import Card from './Card'
import MetricsBar from './MetricsBar'
import { MetricsControls } from './MetricsControls'

export function PerformanceMetricsWidget<TMetricId extends MetricId>({
  metricIds,
  summary,
  groupBy,
  rollupBy,
  rollupValues,
  filters,
  samples,
  limit = 1,
  metricId,
  postAggregation,
  setMetricsState,
  isDirty,
  onReset,
}: {
  metricIds: TMetricId[]
  summary: boolean
  groupBy: QueryFacetId
  rollupBy: QueryFacetId
  rollupValues?: string[]
  filters: UseGroupByOverTimeQueryOptions['filters']
  samples: number
  limit: number | undefined
  metricId: TMetricId
  postAggregation: MetricPostAggregation
  setMetricsState: (
    state: Updater<{
      metricId: TMetricId
      postAggregation: MetricPostAggregation
      samples: number
    }>
  ) => void
  isDirty: boolean
  onReset: () => void
}) {
  const timeRangeState = useTimeRangeState()
  const groupByFacet = queryFacetsById[groupBy!]

  const dataQueryOptions = useGroupByOverTimeQueryOptions({
    filters,
    samples,
    start: timeRangeState.state[0]?.start,
    end: timeRangeState.state[0]?.end,
    rollupBy,
    rollupValues,
    groupBy,
    sortByMetricPermutation:
      limit > 1
        ? metricPermutation(metricId!, {
            postAggregation: postAggregation as any,
          })
        : metricPermutation('unique_keywords'),
    desc: true,
    offset: 0,
    limit,
    metricPermutations: summary
      ? [
          ...metricIds.map(id =>
            metricPermutation(id, {
              postAggregation: postAggregation as any,
            })
          ),
        ]
      : [
          ...metricIds.map(id =>
            metricPermutation(id, {
              postAggregation: 'value',
            })
          ),
          ...metricIds.map(id =>
            metricPermutation(id, {
              postAggregation: 'change',
            })
          ),
        ],
  })

  const dataQuery = useGroupByOverTimeQuery(dataQueryOptions)

  return (
    <Card className="divide-y divide-gray-500/20 overflow-hidden p-0">
      <div className="m-0 flex items-center gap-3 p-2">
        <span className="font-bold">Metrics</span>
        <MetricsControls
          {...{
            metricIds,
            isDirty,
            onReset,
            setState: setMetricsState,
            isLoading: dataQuery.isFetching,
            metricId,
            postAggregation,
            samples,
          }}
        />
      </div>
      <MetricsBar
        {...{
          metricId,
          postAggregation,
          dataQuery,
          metricIds,
          formatSeriesLabel: groupByFacet.formatSeriesLabel,
          setState: setMetricsState,
          summary,
        }}
      />
    </Card>
  )
}
