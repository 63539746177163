import * as React from 'react'
import { twMerge } from 'tailwind-merge'
import { useSegmentOptionsQuery } from '../hooks/segments'
import Button from './Button'
import Loader from './Loader'
import Select from './Select'
import Tooltip from './Tooltip'
import { useSegmentIdState } from '../utils/searchParams'

export default function SegmentPicker({
  multi,
  className,
  ...rest
}: React.HTMLAttributes<HTMLElement> & {
  multi?: boolean
}) {
  const segmentIdState = useSegmentIdState()
  const segmentOptionsQuery = useSegmentOptionsQuery()

  return segmentOptionsQuery.isLoading ? (
    <Loader color="gray-500" />
  ) : (
    <div className="inline-flex flex-auto items-center gap-2">
      {String(segmentIdState.state) !== '1' ? (
        <Tooltip tooltip={<>Reset the segment back to the default (Unpaid).</>}>
          <Button
            size="xs"
            color="yellow-500"
            onClick={() => segmentIdState.setState('1')}
          >
            Reset
          </Button>
        </Tooltip>
      ) : null}
      <Select
        {...rest}
        multi={multi as any}
        options={segmentOptionsQuery.data}
        value={segmentIdState.state}
        onChange={(value: any) => segmentIdState.setState(value)}
        placeholder="Select a segment..."
        className={twMerge(`flex-auto text-sm`, className)}
        inlineLabel="Segment"
      />
    </div>
  )
}
