import { PlainMessage } from '@bufbuild/protobuf'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  queryKeyAccount,
  queryKeyBillingPlans,
  queryKeyBillingPublicPlans,
} from '../utils/Constants'
import useErrorPopup from './useErrorPopup'
import useLoading from './useLoading'
import useToast from './useToast'
import { useActiveWorkspaceId } from './workspaces'
import {
  PlansClient,
  SubscriptionsClient,
  CouponCodeStatePb,
  EstimateChangeSubscriptionRequestPb,
  ListPlansRequestPb,
  ListPublicPlansRequestPb,
} from '../utils/proto'

export type BillingPlansQuery = ReturnType<typeof usePlansQuery>

export function usePlansQuery(options?: PlainMessage<ListPlansRequestPb>) {
  return useQuery([queryKeyBillingPlans, options], () =>
    PlansClient.listPlans(options ?? {})
  )
}

export function usePublicPlansQuery(
  options?: PlainMessage<ListPublicPlansRequestPb>
) {
  return useQuery([queryKeyBillingPublicPlans, options], () =>
    PlansClient.listPublicPlans(options ?? {})
  )
}

export function useChangeSubscriptionPlan() {
  const toast = useToast()
  const errorPopup = useErrorPopup()
  const [, setLoading] = useLoading()
  const queryClient = useQueryClient()

  return useMutation(SubscriptionsClient.changeSubscription, {
    onMutate: () => {
      setLoading(true)
    },
    onError: () => {
      errorPopup('Failed to save billing plan.')
    },
    onSuccess: async () => {
      toast({
        message: 'Billing Plan saved',
        color: 'green-500',
      })
      await queryClient.invalidateQueries(queryKeyAccount)
      // queryClient.setQueryData([queryKeyBillingAccounts, data.workspaceId], data)
    },
    onSettled: () => {
      setLoading(false)
    },
  })
}

export function useEstimateChangeBillingPlan(
  opts: Omit<PlainMessage<EstimateChangeSubscriptionRequestPb>, 'workspaceId'>
) {
  const activeWorkspaceId = useActiveWorkspaceId()

  return useQuery(
    [
      'estimateChangeBillingPlan',
      activeWorkspaceId,
      opts.productFamily,
      opts.basePeriod,
      opts.planId,
      opts.overagePeriod,
      opts.changeTiming,
      opts.couponCodes,
    ],
    () =>
      SubscriptionsClient.estimateChangeSubscription({
        ...opts,
        // @ts-expect-error  // Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
        workspaceId: BigInt(activeWorkspaceId),
      }),
    {
      enabled: [
        activeWorkspaceId,
        opts.productFamily,
        opts.basePeriod,
        opts.planId,
        opts.overagePeriod,
      ].every(Boolean),
      keepPreviousData: true,
      retry: false,
    }
  )
}

export function formatCouponCodeState(state: CouponCodeStatePb) {
  return {
    [CouponCodeStatePb.UNSPECIFIED]: 'Unspecified',
    [CouponCodeStatePb.NOT_REDEEMED]: 'Not Redeemed',
    [CouponCodeStatePb.REDEEMED]: 'Redeemed',
    [CouponCodeStatePb.ARCHIVED]: 'Archived',
    [CouponCodeStatePb.NOT_FOUND]: 'Not Found',
  }[state]
}
