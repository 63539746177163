import * as React from 'react'
import { useForm } from 'react-form'
import { FaCheck } from 'react-icons/fa'
import { billingPeriodOptions } from '../utils/BillingPeriods'
import { productFamilyOptions } from '../utils/ProductFamilies'
import { usageTypeOptions } from '../utils/Usage'
import Validate from '../utils/Validate'
import { floatToMoney, moneyToFloat } from '../utils/money'
import { PeriodPb, PlanPb, ProductFamilyPb, UsageTypePb } from '../utils/proto'
import Button from './Button'
import Caption from './Caption'
import LabelWrap from './LabelWrap'
import NumberInput from './NumberInput'
import SelectField from './SelectField'
import Spinner from './Spinner'
import TextField from './TextField'

export function CreatePlanForm(props: {
  defaultValue?: PlanPb
  onSubmit: (values: PlanPb) => void
}) {
  const {
    Form,
    meta: { isSubmitting },
    setFieldValue,
    pushFieldValue,
    removeFieldValue,
    values,
  } = useForm({
    defaultValues: React.useMemo(() => {
      return props.defaultValue
        ? {
            ...props.defaultValue,
            periodPlans: props.defaultValue.periodPlans.map(periodPlan => ({
              ...periodPlan,
              basePrice: moneyToFloat(periodPlan.basePrice),
              meteredPlans: periodPlan.meteredPlans.map(meteredPlan => ({
                ...meteredPlan,
                includedQuantity: Number(meteredPlan.includedQuantity),
                unitPrice: moneyToFloat(meteredPlan.unitPrice),
              })),
            })),
          }
        : {
            productFamily: ProductFamilyPb.STANDARD,
            periodPlans: [
              {
                basePeriod: PeriodPb.YEARLY,
                overagePeriod: PeriodPb.MONTHLY,
                meteredPlans: [
                  {
                    usageType: UsageTypePb.SERP,
                  },
                ],
              },
            ],
          }
    }, [props.defaultValue]),
    onSubmit: async (values: any) => {
      const transformed = {
        ...values,
        periodPlans: values.periodPlans.map((periodPlan: any) => {
          return {
            ...periodPlan,
            basePrice: floatToMoney(periodPlan.basePrice ?? 0),
            meteredPlans: periodPlan.meteredPlans.map((meteredPlan: any) => {
              return {
                ...meteredPlan,
                includedQuantity: BigInt(meteredPlan.includedQuantity || 0),
                unitPrice: floatToMoney(meteredPlan.unitPrice),
              }
            }),
          }
        }),
      }

      props.onSubmit(transformed)
    },
  })

  return (
    <Form>
      <div className="flex flex-wrap gap-2">
        <div className="min-w-[250px] flex-1">
          <TextField
            field="planId"
            label="Plan ID"
            placeholder="plan-id"
            required
            validate={Validate.maxLength(10)}
          />
          <Caption>
            This will typically be the customer's name, but all lowercase and in
            kebab-case if necessary. This plan ID will be visible to the client.
            e.g. "home-depot" <strong>Must be 10 characters or less.</strong>{' '}
            <strong>This cannot be changed later.</strong>
          </Caption>
        </div>
        <div className="min-w-[250px] flex-1">
          <TextField
            field="name"
            label="Plan Name"
            placeholder="Plan Name"
            required
          />
          <Caption>
            This will typically be the customer's name. This plan name will be
            visible to the client. e.g. "Home Depot"
          </Caption>
        </div>
        <div className="min-w-[250px] flex-1">
          <SelectField
            field="productFamily"
            label="Product Family"
            options={productFamilyOptions}
          />
          <Caption>
            Right now there is only one product family: "Standard"
          </Caption>
        </div>
      </div>
      <div className="h-2" />
      <div className="font-bold">Period Plans</div>
      <div className="h-2" />
      {!values.periodPlans?.length ? (
        <>
          <div className="italic">No period plans added yet.</div>
        </>
      ) : (
        <div className="space-y-4">
          {values.periodPlans?.map((periodPlan: any, planIndex: number) => {
            return (
              <div
                key={planIndex}
                className="ml-4 rounded-md border-2 border-gray-500 p-2"
              >
                <div className="flex flex-wrap gap-2">
                  <div className="min-w-[100px] flex-1">
                    <SelectField
                      field={`periodPlans.${planIndex}.basePeriod`}
                      label="Base Period"
                      options={billingPeriodOptions}
                    />
                  </div>
                  <div className="min-w-[250px] flex-1">
                    <LabelWrap label="Base Price">
                      <NumberInput
                        value={periodPlan.basePrice}
                        onChange={num =>
                          setFieldValue(
                            `periodPlans.${planIndex}.basePrice`,
                            num
                          )
                        }
                        placeholder="50.00"
                      />
                    </LabelWrap>
                    <Caption>
                      This is the price that will be charged per the period
                      specified above. e.g. If "50.00" is specified and the
                      billing period is "Yearly", then it will cost $50 per
                      year.
                    </Caption>
                  </div>
                  <div className="min-w-[250px] flex-1">
                    <SelectField
                      field={`periodPlans.${planIndex}.overagePeriod`}
                      label="Overage Period"
                      options={billingPeriodOptions}
                    />
                    <Caption>
                      This is the period at which overage charges will be
                      calculated. Normally this should be "Monthly".
                    </Caption>
                  </div>
                </div>
                <div className="h-2" />
                <div className="font-bold">Metered Plans</div>
                <div className="h-2" />
                {!periodPlan.meteredPlans?.length ? (
                  <>
                    <div className="italic">No metered plans added yet.</div>
                  </>
                ) : (
                  <div className="space-y-4">
                    {periodPlan.meteredPlans?.map(
                      (meteredPlan: any, meteredPlanIndex: number) => {
                        return (
                          <div
                            key={meteredPlanIndex}
                            className="ml-4 rounded-md border-2 border-gray-500 p-2"
                          >
                            <div className="flex flex-wrap gap-2">
                              <div className="min-w-[100px] flex-1">
                                <SelectField
                                  field={`periodPlans.${planIndex}.meteredPlans.${meteredPlanIndex}.usageType`}
                                  label="Usage Type"
                                  options={usageTypeOptions}
                                />
                                <Caption>
                                  Currently only "SERP" is supported.
                                </Caption>
                              </div>
                              <div className="min-w-[250px] flex-1">
                                <LabelWrap label="Included Quantity">
                                  <NumberInput
                                    value={meteredPlan.includedQuantity}
                                    onChange={num =>
                                      setFieldValue(
                                        `periodPlans.${planIndex}.meteredPlans.${meteredPlanIndex}.includedQuantity`,
                                        num
                                      )
                                    }
                                    placeholder="100,000"
                                  />
                                </LabelWrap>
                                <Caption>
                                  The number of units that are included in the
                                  base price. e.g. If usage type is set to
                                  "SERP" and the included quantity is set to
                                  "100,000", then the base price will include
                                  100,000 SERPs.
                                </Caption>
                              </div>
                              <div className="min-w-[250px] flex-1">
                                <LabelWrap label="Overage Unit Price">
                                  <NumberInput
                                    value={meteredPlan.unitPrice}
                                    onChange={num =>
                                      setFieldValue(
                                        `periodPlans.${planIndex}.meteredPlans.${meteredPlanIndex}.unitPrice`,
                                        num
                                      )
                                    }
                                    placeholder="0.00025"
                                    step={0.00001}
                                    precision={5}
                                  />
                                </LabelWrap>
                                <Caption>
                                  This is the price per unit that is applied to
                                  usage beyond the included quantity e.g. If
                                  usage type is set to "SERP" and the overage
                                  unit price is set to "0.00025", then any SERP
                                  usage beyond the included quantity will be
                                  charged at 0.00025 per SERP.
                                </Caption>
                              </div>
                            </div>
                            <div className="h-2" />
                            <Button
                              color="gray-200"
                              size="sm"
                              className="flex items-center gap-2"
                              onClick={() =>
                                removeFieldValue(
                                  `periodPlans.${planIndex}.meteredPlans`,
                                  meteredPlanIndex
                                )
                              }
                            >
                              Remove Metered Plan
                            </Button>
                          </div>
                        )
                      }
                    )}
                  </div>
                )}
                <div className="h-2" />
                <Button
                  color="gray-500"
                  size="sm"
                  className="ml-4 flex items-center gap-2"
                  onClick={() =>
                    pushFieldValue(`periodPlans.${planIndex}.meteredPlans`, {})
                  }
                >
                  Add Metered Plan
                </Button>
                <div className="h-2" />

                <Button
                  color="gray-200"
                  size="sm"
                  className="flex items-center gap-2"
                  onClick={() => removeFieldValue(`periodPlans`, planIndex)}
                >
                  Remove Period Plan
                </Button>
              </div>
            )
          })}
        </div>
      )}
      <div className="h-2" />
      <Button
        color="gray-500"
        size="sm"
        className="ml-4 flex items-center gap-2"
        onClick={() => pushFieldValue('periodPlans', {})}
      >
        Add Period Plan
      </Button>

      <div className="h-6" />
      <Button
        type="submit"
        color="green-500"
        size="base"
        disabled={isSubmitting}
        className="flex items-center gap-2"
      >
        {isSubmitting ? (
          <>
            <Spinner color="white" /> Saving Plan...
          </>
        ) : (
          <>
            <FaCheck className="inline" /> Save Plan
          </>
        )}
      </Button>
    </Form>
  )
}
