import * as React from 'react'
import { useForm } from 'react-form'
import { parseLastHrefPath } from '../../utils'
import { fetchBrandInfo, fetchYoutubeDomains } from '../../utils/Api'
import {
  BRAND_PROPERTY_TYPE_DOMAIN,
  BRAND_PROPERTY_TYPE_GOOGLE_MY_BUSINESS,
  BRAND_PROPERTY_TYPE_YOUTUBE_CHANNEL,
  BRAND_PROPERTY_TYPE_YOUTUBE_VIDEO,
  BRAND_TYPE_COMPETITOR_DIRECT,
  BRAND_TYPE_OWNED,
} from '../../utils/Constants'
//
import { Brand } from '../../../openapi'
import Button from '../../components/Button'
import Spinner from '../../components/Spinner'
import TextField from '../../components/TextField'
import Validate, { validateDomain, validateNumber } from '../../utils/Validate'
import { propertyTypes } from './BrandForm'

//

type FormValues = {
  domain: string
  gmbId?: string
  youtube?: string
}

//

export default function BrandFormOnboarding({
  onSubmit,
  brand,
  onboardingType,
  submittingText,
  submitText,
}: {
  onSubmit?: (brand: Brand) => void
  brand?: Brand
  onboardingType?: typeof BRAND_TYPE_OWNED | typeof BRAND_TYPE_COMPETITOR_DIRECT
  submittingText?: React.ReactNode
  submitText?: React.ReactNode
}) {
  const brandWithoutProperties = React.useMemo(() => {
    const { properties, ...rest } = brand ?? {}
    return rest
  }, [brand])

  const defaultValues = React.useMemo(() => {
    return {
      domain: brand?.properties?.find(
        d => d.type === BRAND_PROPERTY_TYPE_DOMAIN
      )?.value,
      gmbId: brand?.properties?.find(
        d => d.type === BRAND_PROPERTY_TYPE_GOOGLE_MY_BUSINESS
      )?.value,
      youtube: brand?.properties?.find(d => d.name === `YouTube Channel`)
        ?.value,
    }
  }, [brand])

  const form = useForm({
    // @ts-expect-error  // Type '{ domain: string | undefined; gmbId: string ... Remove this comment to see the full error message
    defaultValues,
    onSubmit: async (values: FormValues) => {
      const brandInfo = await fetchBrandInfo(values.domain)

      if (values.gmbId) {
        brandInfo.properties.push({
          name: `Google My Business`,
          type: BRAND_PROPERTY_TYPE_GOOGLE_MY_BUSINESS,
          value: values.gmbId,

          condition: propertyTypes
            .find(d => d.type === BRAND_PROPERTY_TYPE_GOOGLE_MY_BUSINESS)
            .createCondition(values.gmbId),
        })
      }

      if (values.youtube) {
        const youtubeUsername = parseLastHrefPath(values.youtube)
        // @ts-expect-error  // Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        const youtubeInfo = await fetchYoutubeDomains(youtubeUsername)

        brandInfo.properties.push(
          {
            name: `YouTube Channel`,
            type: BRAND_PROPERTY_TYPE_YOUTUBE_CHANNEL,
            value: youtubeUsername,

            condition: propertyTypes
              .find(d => d.type === BRAND_PROPERTY_TYPE_YOUTUBE_CHANNEL)
              .createCondition(youtubeUsername),
          },
          ...(youtubeInfo.videos?.map(video => ({
            name: `YouTube - ${video.snippet.title}`,
            type: BRAND_PROPERTY_TYPE_YOUTUBE_VIDEO,
            value: video.id.videoId,

            condition: propertyTypes
              .find(d => d.type === BRAND_PROPERTY_TYPE_YOUTUBE_VIDEO)
              .createCondition(video.id.videoId),
          })) ?? [])
        )
      }

      const newBrand = {
        ...brandInfo,
        ...brandWithoutProperties,
        type: onboardingType,
        reputationImpact: onboardingType === 'Owned' ? 0 : -2,
      }

      // @ts-expect-error  // Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      await onSubmit(newBrand)
    },
  })

  return (
    <form.Form>
      <TextField
        field="domain"
        type="text"
        label="Domain"
        placeholder="mysite.com"
        validate={d =>
          Validate.required('A domain is required.')(d) || validateDomain()(d)
        }
        className="w-80 flex-none"
      />
      <div className="h-2" />
      <TextField
        field="gmbId"
        type="text"
        label={
          <>
            Google My Business ID <span className="opacity-50">(optional)</span>
          </>
        }
        placeholder="123456789123456789"
        className="w-80 flex-none"
        validate={validateNumber('A valid ID is required.')}
      />
      <div className="h-2" />
      <TextField
        field="youtube"
        type="text"
        label={
          <>
            YouTube Username / Channel ID{' '}
            <span className="opacity-50">(optional)</span>
          </>
        }
        placeholder="youtube.com/user/johnsmith"
        className="w-80 flex-none"
      />
      <div className="h-2" />
      <div className="h-2" />
      <div className="flex gap-1">
        <Button
          type="submit"
          size="base"
          color="blue-500"
          disabled={!form.meta.canSubmit}
        >
          {form.meta.isSubmitting ? (
            <>
              <Spinner color="white" /> {submittingText}
            </>
          ) : (
            submitText
          )}
        </Button>
      </div>
    </form.Form>
  )
}
