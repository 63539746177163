import { round } from '../utils'
import { formatNumber } from '../utils/Format'
import { FunctionalUpdater } from '../utils/types'
import NumberInput from './NumberInput'

export function MaxOverageFields(props: {
  pricePerSerp: number
  maxOverageDraft: number
  onMaxOverageDraftChange: FunctionalUpdater<number>
}) {
  return (
    <div className="space-y-2">
      <div className="inline-flex items-center gap-2 whitespace-nowrap">
        <div>Amount in USD $</div>
        <NumberInput
          allowUndefined
          placeholder="0.00"
          min={0}
          value={props.maxOverageDraft}
          // @ts-expect-error  // Type 'FunctionalUpdater<number>' is not assignable... Remove this comment to see the full error message
          onChange={props.onMaxOverageDraftChange}
        />
        {props.maxOverageDraft ? (
          <>
            ~{' '}
            {formatNumber(
              round((props.maxOverageDraft || 0) / props.pricePerSerp)
            )}{' '}
            SERPs
          </>
        ) : null}
      </div>
    </div>
  )
}
