import * as React from 'react'
import usePopup from '../hooks/usePopup'
import { Dropzone } from './Dropzone'
import { FaCheck, FaCheckCircle, FaInfoCircle } from 'react-icons/fa'
import Input from './Input'
import { useDropzone } from 'react-dropzone'
import { ButtonPlain } from './ButtonPlain'

export function ImportSerpWooProjectsForm({
  workspaceId,
}: {
  workspaceId: string
  onImport: () => void
}) {
  const popup = usePopup()

  const [dropped, setDropped] = React.useState(false) as any

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    multiple: false,
    accept: '.zip',
    onDropAccepted: async (acceptedFiles: any) => {
      const [acceptedFile] = acceptedFiles
      setDropped(acceptedFile)
    },
    onDropRejected: async () => {
      popup({
        title: 'File not supported!',
        message: `File must be a SERPWoo master export ending in ".zip"`,
      })
    },
    noDrag: dropped,
    noClick: dropped,
  })

  const importProjects = () => {
    const reader = new FileReader()
    reader.onload = function (e) {
      const arrayBuffer = (e.target as FileReader).result as ArrayBuffer
      console.log(arrayBuffer)
    }
    reader.readAsArrayBuffer(dropped)
  }

  return (
    <div className="space-y-2">
      <Dropzone
        {...getRootProps({
          isDragActive,
          isDragAccept,
          isDragReject,
          dropped,
        })}
        className="flex flex-col divide-y divide-gray-500/20"
      >
        <div className="p-2">
          <FaInfoCircle className="inline text-blue-500" /> Drop or click to
          upload a SERPWoo master export file ending in ".zip"
        </div>
        <Input {...getInputProps()} />
      </Dropzone>
      {dropped ? (
        <>
          <div>
            <FaCheckCircle className="inline text-green-500" /> Ready to import{' '}
            <strong>{dropped.path}</strong>
          </div>
          <div>
            <ButtonPlain className="bg-green-500" onClick={importProjects}>
              <FaCheck /> Import Projects
            </ButtonPlain>
          </div>
        </>
      ) : null}
    </div>
  )
}
