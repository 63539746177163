import { useNavigate, useSearch } from '@tanstack/react-router'
import * as React from 'react'
import { FaTable } from 'react-icons/fa'
import { MdHeartBroken } from 'react-icons/md'
import { TbListDetails } from 'react-icons/tb'
import { UseQueryResult, useQuery } from 'react-query'
import { twMerge } from 'tailwind-merge'

import Card from '../../components/Card'
import ErrorComp from '../../components/Error'
import ErrorBoundary from '../../components/ErrorBoundary'
import { FacetFilters, UiFilter } from '../../components/FacetFilters'
import { Head } from '../../components/Head'
import Link from '../../components/Link'
import Loader from '../../components/Loader'
import QueryGate from '../../components/QueryGate'
import { useTable } from '../../hooks/useTable'
import { useActiveWorkspaceId } from '../../hooks/workspaces'
import { functionalUpdate, pick } from '../../utils'
import {
  queryKeyClusterFacets,
  queryKeyClusters,
  queryKeyClustersViz,
} from '../../utils/Constants'
import {
  FetchClustersOpts,
  fetchClusterFacets,
  fetchClusters,
} from '../../utils/clusters'
import {
  ClusterViewPb,
  ListClustersFacetsResponsePb,
  ListClustersResponsePb,
  ListClustersFiltersPb,
} from '../../utils/proto'
import { ClusterCards } from './ClusterCards'
import {
  ClusteringFocusComp,
  useFocusDomain,
  useFocusDomainOptions,
  useUpdateClustersMutation,
} from './ClustersControl'
import { ClusterTableDetails, ClustersTable } from './ClustersTable'
import { ClustersViz } from './ClustersViz'
import { ClusterComp } from './cluster/Cluster'
import { clusterColumns } from './clusterColumns'
import { filtersPbFromSearch } from '../../components/FacetFilters'
import { useActiveProjectIdState } from '../../utils/searchParams'

const clusterJobId = '220862728941201'

export function ClustersComp() {
  const workspaceId = useActiveWorkspaceId()!
  const search = useSearch()
  const projectId = useActiveProjectIdState().state
  const { clusterId, clusterListFilters } = search

  const [clustersResponseCache, setClustersResponseCache] = React.useState<
    undefined | ListClustersFacetsResponsePb
  >()

  const [focusDomainId] = useFocusDomain(clustersResponseCache)
  const focusDomainOptions = useFocusDomainOptions(clustersResponseCache)
  const focusDomain = React.useMemo(
    () => focusDomainOptions?.find(d => d.value === focusDomainId)?.label,
    [focusDomainId, focusDomainOptions]
  )

  const [fetchClustersKey, fetchClustersPayload] = React.useMemo(() => {
    const filters = filtersPbFromSearch<
      Record<string, ListClustersFiltersPb[keyof ListClustersFiltersPb]>
    >(clusterListFilterList, clusterListFilters || {})

    const payload: FetchClustersOpts = {
      workspaceId,
      projectId: projectId!,
      clusterJobId,
      filters,
      domainId: focusDomainId,
      view: ClusterViewPb.BASIC,
      pageSize: 10000,
    }

    return [{ ...payload, filters: clusterListFilters }, payload] as const
  }, [clusterListFilters, projectId, workspaceId, focusDomainId])

  const clusterVizQuery = useQuery({
    queryKey: [queryKeyClustersViz, fetchClustersKey],
    enabled: Boolean(workspaceId && projectId && clusterJobId),
    queryFn: () => fetchClusters(fetchClustersPayload),
    retry: false,
    staleTime: Infinity,
    cacheTime: Infinity,
    keepPreviousData: true,
  })

  const clustersQuery = useQuery({
    queryKey: [queryKeyClusters, fetchClustersKey],
    enabled: Boolean(workspaceId && projectId && clusterJobId),
    queryFn: () =>
      fetchClusters({
        ...fetchClustersPayload,
        view: ClusterViewPb.PARTIAL,
        maxSubRows: 10,
        pageSize: 10000,
      }),
    retry: false,
    staleTime: Infinity,
    cacheTime: Infinity,
    keepPreviousData: true,
  })

  const facetsQuery = useQuery({
    queryKey: [queryKeyClusterFacets, fetchClustersKey],
    enabled: Boolean(workspaceId && projectId && clusterJobId),
    queryFn: () => fetchClusterFacets(fetchClustersPayload),
    retry: false,
    staleTime: Infinity,
    cacheTime: Infinity,
    keepPreviousData: true,
  })

  React.useEffect(() => {
    setClustersResponseCache(facetsQuery.data)
  }, [facetsQuery.data])

  const autoGenerateTopics = React.useCallback(async () => {
    const clustersRes = await fetchClusters({
      ...fetchClustersPayload,
      view: ClusterViewPb.FULL,
      maxSubRows: 10000,
      pageSize: 10000,
    })

    console.log(clustersRes)
  }, [fetchClustersPayload])

  return (
    <>
      <Head>
        <title>Clusters</title>
      </Head>
      <ErrorBoundary>
        {(() => {
          if (!projectId) {
            return <Card>Select a project.</Card>
          }

          if (clusterId) {
            return (
              <ClusterComp
                workspaceId={workspaceId}
                projectId={projectId}
                clusterJobId={clusterJobId}
                clusterId={clusterId}
                focusDomain={focusDomain}
              />
            )
          }

          return (
            <ClustersList
              key={`${workspaceId} ${projectId}`}
              clusterVizQuery={clusterVizQuery}
              clustersQuery={clustersQuery}
              facetsQuery={facetsQuery}
              workspaceId={workspaceId}
              projectId={projectId}
              onAutoGenerateTopics={autoGenerateTopics}
            />
          )
        })()}
      </ErrorBoundary>
    </>
  )
}

const viewTabs = [
  {
    id: 'cards',
    label: 'Cards',
    icon: <TbListDetails />,
  },
  {
    id: 'table',
    label: 'Table',
    icon: <FaTable />,
  },
] as const

export function ClustersList({
  clusterVizQuery,
  clustersQuery,
  facetsQuery,
  workspaceId,
  projectId,
  onAutoGenerateTopics,
}: {
  clusterVizQuery: UseQueryResult<ListClustersResponsePb>
  clustersQuery: UseQueryResult<ListClustersResponsePb>
  facetsQuery: UseQueryResult<ListClustersFacetsResponsePb>
  workspaceId: string
  projectId: string
  onAutoGenerateTopics: () => void
}) {
  const search = useSearch()
  const { clustersTable, clusterListFilters } = search
  let { clustersView } = search
  const navigate = useNavigate()

  clustersView = clustersView ?? 'cards'

  const needsFirstCluster = React.useMemo(
    () =>
      clustersQuery.error
        ? `${clustersQuery.error}`.includes('Error 404: Not found')
        : false,
    [clustersQuery.error]
  )

  const clusters = React.useMemo(
    () => clustersQuery.data?.clusters ?? [],
    [clustersQuery.data?.clusters]
  )

  const initialState = React.useMemo(
    () => ({
      sorting: [
        {
          id: 'Keyword Count',
          desc: true,
        },
        {
          id: 'URL Count',
          desc: true,
        },
      ],
      pagination: {
        pageSize: 50,
      },
    }),
    []
  )

  const table = useTable({
    data: clusters,
    columns: clusterColumns,
    initialState,
    state: clustersTable,
    onStateChange: updater => {
      navigate({
        search: (prev: any) => ({
          ...prev,
          clustersTable: pick(
            functionalUpdate(
              updater,
              (prev.clustersTable ?? table.getState()) as any
            ),
            ['pagination', 'sorting', 'globalFilter']
          )[0],
        }),
        replace: true,
      })
    },
    enableSortingRemoval: true,
    subComponent: ClusterTableDetails as any,
  })

  const updateClustersMutation = useUpdateClustersMutation({
    workspaceId,
    projectId,
    clusterJobId,
  })

  React.useEffect(() => {
    if (needsFirstCluster && updateClustersMutation.isIdle) {
      updateClustersMutation.mutate([])
    }
  })

  return (
    <div className="space-y-2">
      {needsFirstCluster ? (
        updateClustersMutation.isError ? (
          <ErrorComp error={updateClustersMutation.error} />
        ) : (
          <Card className="flex items-center justify-center">
            <div className="space-y-2 p-4 text-center">
              <div className="text-sm font-bold">Clustering Keywords...</div>
              <Loader className="text-2xl" />
              <div className="text-xs italic opacity-50">
                (Clustering generally completes within a few seconds, but can
                take up to 10 minutes for larger projects)
              </div>
            </div>
          </Card>
        )
      ) : (
        <div className="flex min-h-0 flex-1 items-start gap-2">
          <Card className="w-[20vw] min-w-[240px] max-w-[300px] p-0">
            <FacetFilters
              facets={facetsQuery.data}
              filters={clusterListFilterList}
              filterState={clusterListFilters}
              onFilterStateChange={filterState =>
                navigate({
                  search: prev => ({
                    ...prev,
                    clusterListFilters: functionalUpdate(
                      filterState,
                      prev.clusterListFilters
                    ),
                  }),
                  replace: true,
                })
              }
              isLoading={facetsQuery.isLoading}
              labelPlural="Clusters"
            />
          </Card>
          <Card className="min-w-0 flex-1 divide-y divide-gray-500/20 p-0">
            <div className="flex items-center gap-2 p-2 text-lg font-bold">
              <div>Clusters</div>
            </div>
            <ClusteringFocusComp
              facetsQuery={facetsQuery}
              clusterJobId={clusterJobId}
              workspaceId={workspaceId}
              projectId={projectId}
              onAutoGenerateTopics={onAutoGenerateTopics}
            />
            {clusterVizQuery.isLoading ||
            (clusterVizQuery.isFetching &&
              clusterVizQuery.data?.clusters?.length === 0) ? (
              <Card className="flex items-center justify-center">
                <div className="space-y-2 p-4 text-center">
                  <div className="text-sm font-bold">Loading Clusters</div>
                  <Loader className="text-2xl" />
                </div>
              </Card>
            ) : clusterVizQuery.isSuccess &&
              !clusterVizQuery.data.clusters.length ? (
              <div className="flex flex-col items-center justify-center gap-2 py-8">
                <div className="text-sm font-bold">No Clusters Found</div>
                <MdHeartBroken className="text-3xl opacity-30" />
              </div>
            ) : (
              <>
                <QueryGate
                  query={clusterVizQuery}
                  className="p-2"
                  children={() => (
                    <ClustersViz
                      clusterVizQuery={clusterVizQuery}
                      workspaceId={workspaceId}
                      projectId={projectId}
                    />
                  )}
                />
                <QueryGate
                  query={clustersQuery}
                  className="p-2"
                  loader={
                    <Card className="flex items-center justify-center">
                      <div className="space-y-2 p-4 text-center">
                        <div className="text-sm font-bold">
                          Loading Clusters
                        </div>
                        <Loader className="text-2xl" />
                        <div className="text-xs italic opacity-50">
                          (This can take up 60 seconds for larger projects)
                        </div>
                      </div>
                    </Card>
                  }
                  children={() => (
                    <>
                      <div className="flex items-center gap-2">
                        <div className="flex divide-x divide-gray-100 dark:divide-gray-850">
                          {viewTabs.map(tab => {
                            return (
                              <Link
                                key={tab.id || 0}
                                search={prev => {
                                  return {
                                    ...prev,
                                    clustersView: tab.id,
                                  }
                                }}
                                replace
                                className={twMerge(
                                  'flex items-center gap-2 px-2 py-1',
                                  tab.id === clustersView &&
                                    'bg-gray-100 text-black dark:bg-gray-800 dark:text-white'
                                )}
                              >
                                <div className="text-lg">{tab.icon}</div>
                                {tab.label}
                              </Link>
                            )
                          })}
                        </div>
                        {clustersQuery.isFetching ? <Loader /> : null}
                      </div>
                      {clustersView === 'table' ? (
                        <ClustersTable
                          workspaceId={workspaceId}
                          projectId={projectId}
                          clusters={clusters}
                          table={table}
                        />
                      ) : (
                        <ClusterCards
                          workspaceId={workspaceId}
                          projectId={projectId}
                          clusters={clusters}
                          table={table}
                          totalSize={clustersQuery.data?.totalSize ?? 0}
                        />
                      )}
                    </>
                  )}
                />
              </>
            )}
          </Card>
        </div>
      )}
    </div>
  )
}

export const clusteringFilters = {
  urlCount: {
    id: 'urlCount',
    label: 'URL Count',
    type: 'number',
    ui: 'range',
    keys: ['minUrlCount', 'maxUrlCount'],
    facetKey: 'urlCounts',
    showBins: true,
  },
  keywordCount: {
    id: 'keywordCount',
    label: 'Keyword Count',
    type: 'number',
    ui: 'range',
    keys: ['minKeywordCount', 'maxKeywordCount'],
    facetKey: 'keywordCounts',
    showBins: true,
  },
  searchVolume: {
    id: 'searchVolume',
    label: 'Search Volume',
    type: 'number',
    ui: 'range',
    keys: ['minSearchVolume', 'maxSearchVolume'],
    facetKey: 'searchVolume',
    showBins: true,
  },
  trafficOpportunity: {
    id: 'trafficOpportunity',
    label: 'Traffic Opportunity',
    type: 'number',
    ui: 'range',
    keys: ['minTrafficOpportunity', 'maxTrafficOpportunity'],
    facetKey: 'trafficOpportunity',
    showBins: true,
  },
  cpc: {
    id: 'cpc',
    label: 'CPC',
    type: 'number',
    ui: 'range',
    keys: ['minCpc', 'maxCpc'],
    facetKey: 'cpc',
    showBins: true,
  },
  rank: {
    id: 'rank',
    label: 'Rank',
    type: 'number',
    ui: 'range',
    keys: ['minTopRank', 'maxTopRank'],
    facetKey: 'topRank',
    showBins: true,
  },
  serpFeatures: {
    id: 'serpFeatures',
    label: 'SERP Features',
    ui: 'multi-select',
    type: 'string',
    keys: ['includeSerpFeatures', 'excludeSerpFeatures'],
    facetKey: 'serpFeatures',
    showBins: true,
  },
  // notRanked: {
  //   id: 'notRanked',
  //   label: 'Include Non-Ranked',
  //   type: 'boolean',
  //   key: 'notRanked',
  // },
  domainIds: {
    id: 'domainIds',
    label: 'Domains',
    ui: 'multi-select',
    type: 'number',
    keys: ['includeDomainIds', 'excludeDomainIds'],
    facetKey: 'domains',
    showBins: true,
  },
  urlIds: {
    id: 'urlIds',
    label: 'URLs',
    ui: 'multi-select',
    type: 'number',
    keys: ['includeUrlIds', 'excludeUrlIds'],
    facetKey: 'urls',
    showBins: true,
  },
  keywordGroups: {
    id: 'keywordGroups',
    label: 'Keyword Groups',
    ui: 'multi-select',
    type: 'string',
    keys: ['includeKeywordGroups', 'excludeKeywordGroups'],
    facetKey: 'keywordGroups',
    showBins: true,
  },
  keywordIds: {
    id: 'keywordIds',
    label: 'Keywords',
    ui: 'multi-select',
    type: 'number',
    keys: ['includeKeywordIds', 'excludeKeywordIds'],
    facetKey: 'keywords',
    showBins: true,
  },
  phrase: {
    id: 'phrase',
    label: 'Phrase',
    ui: 'multi-select',
    type: 'string',
    keys: ['phraseContains', 'phraseDoesNotContain'],
    showBins: false,
    facetKey: '',
  },
  phraseRegex: {
    id: 'phraseRegex',
    label: 'Phrase RegEx',
    ui: 'text',
    type: 'string',
    keys: ['phraseRegexContains', 'phraseRegexDoesNotContain'],
    showBins: false,
    facetKey: '',
  },
  peopleAlsoAsk: {
    id: 'peopleAlsoAsk',
    label: 'People Also Ask',
    ui: 'multi-select',
    type: 'string',
    keys: ['includePeopleAlsoAsk', 'excludePeopleAlsoAsk'],
    facetKey: 'peopleAlsoAsk',
    showBins: true,
  },
  faq: {
    id: 'faq',
    label: 'FAQ',
    ui: 'multi-select',
    type: 'string',
    keys: ['includeFaq', 'excludeFaq'],
    facetKey: 'faq',
    showBins: true,
  },
} satisfies Record<string, UiFilter>

export const clusterListFilterList = [
  clusteringFilters.urlCount,
  clusteringFilters.keywordCount,
  clusteringFilters.searchVolume,
  clusteringFilters.trafficOpportunity,
  clusteringFilters.cpc,
  clusteringFilters.rank,
  clusteringFilters.serpFeatures,
  // clusteringFilters.notRanked,
  clusteringFilters.domainIds,
  clusteringFilters.urlIds,
  clusteringFilters.keywordGroups,
  clusteringFilters.keywordIds,
  clusteringFilters.phrase,
  clusteringFilters.phraseRegex,
  clusteringFilters.peopleAlsoAsk,
  clusteringFilters.faq,
]
