import * as React from 'react'
import { useForm } from 'react-form'
import { FaInfoCircle, FaPlus, FaTimesCircle } from 'react-icons/fa'
import Button from '../components/Button'
import Caption from '../components/Caption'
import TextAreaField from '../components/TextAreaField'
import useModalContext from '../hooks/useModalContext'
import Validate from '../utils/Validate'
import { Modal, ModalContent, ModalTitle } from './Modals'

//

export default function UserInviteModal({ onUsersAdd }: any) {
  const { close } = useModalContext()
  const {
    Form,
    // @ts-expect-error  // Property 'handleSubmit' does not exist on type '{ ... Remove this comment to see the full error message
    handleSubmit,

    meta: { canSubmit },
  } = useForm({
    // @ts-expect-error  // Type '({ users }: { users: any; }) => void' is not... Remove this comment to see the full error message
    onSubmit: ({ users }) => {
      const userArray = users.split('\n')
      const test = userArray.map((user: any) => {
        const email = user.match(
          /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/
        )
        const givenName = user.match(/^([\w\-]+)/)
        const familyName = user.match(/(\w+\s){2}/)
        const extraName = user.match(/(\w+\s){3}/)

        return {
          givenName: givenName ? givenName[1] : null,
          familyName:
            familyName && extraName
              ? familyName[1] + extraName[1]
              : familyName
              ? familyName[1]
              : null,
          email: email ? email[1] : null,
        }
      })

      onUsersAdd(test)

      close()
    },
  })

  return (
    <Modal>
      <ModalTitle>Import List of Users</ModalTitle>
      <ModalContent>
        <div className="p-8">
          <Form>
            <TextAreaField
              label="Import Users"
              field="users"
              placeholder={[
                'First Name Last Name Email',
                'First Name Last Name Email',
                'First Name Last Name Email',
              ].join(`\n`)}
              rows={6}
              validate={Validate.required('At least one User is required.')}
            />
            <div className="h-2" />

            <Button
              size="base"
              color="green-500"
              onClick={() => handleSubmit()}
              disabled={!canSubmit}
              className="mr-1"
            >
              <FaPlus className="inline" /> Import Users
            </Button>

            <Button
              size="base"
              color="gray-500"
              hoverColor="red-500"
              onClick={close}
            >
              <FaTimesCircle className="inline" /> Cancel
            </Button>
          </Form>

          <div className="h-2" />
          <Caption>
            <FaInfoCircle className="inline" /> The form above can be used to
            add a list of users to invite form.
          </Caption>
          <div className="h-4" />
        </div>
      </ModalContent>
    </Modal>
  )
}
