import { useNavigate, useSearch } from '@tanstack/react-router'
import { Table as TTable } from '@tanstack/react-table'
import * as React from 'react'
import {
  FaTimesCircle,
  FaSortAmountDown,
  FaSortAmountUpAlt,
} from 'react-icons/fa'
import { useQueryClient } from 'react-query'
import { twMerge } from 'tailwind-merge'
import Card from '../../components/Card'
import Link from '../../components/Link'
import Select from '../../components/Select'
import { TableCell, TableEl, TableRow } from '../../components/Table'
import { getRankGroupFromRank } from '../../utils'
import { clusterFocusMetricsList, clusterMetricsList } from './metrics'
import { ClusterPb } from '../../utils/proto'

import { ButtonPlain } from '../../components/ButtonPlain'
import { rankGroupColors } from '../../utils/DataColors'
import {
  VirtualItem,
  Virtualizer,
  useVirtualizer,
} from '@tanstack/react-virtual'
import { queryKeyClusters } from '../../utils/Constants'
import { ClusterDetails } from './ClusterDetails'
import { Counter } from '../../components/Counter'
import Tooltip from '../../components/Tooltip'

export function ClusterCards(props: {
  clusters: undefined | ClusterPb[]
  workspaceId: string
  projectId: string
  table: TTable<ClusterPb>
  totalSize: number
}) {
  const { workspaceId, projectId, table } = props
  const search = useSearch()
  const { clustersTable } = search
  const navigate = useNavigate()

  const clustersSelectedMetricIds = React.useMemo(
    () => (search.clustersSelectedMetricIds ?? []) as string[],
    [search.clustersSelectedMetricIds]
  )

  const queryClient = useQueryClient()

  const scrollRef = React.useRef<HTMLDivElement>(null)

  const tableRows = table.getSortedRowModel().rows

  const clusters = React.useMemo(
    () => tableRows.map(d => d.original),
    [tableRows]
  )

  const virtualizer = useVirtualizer({
    count: clusters?.length ?? 0,
    estimateSize: React.useCallback(() => 325, []),
    getScrollElement: () => scrollRef.current,
  })

  const virtualItems = virtualizer.getVirtualItems()

  React.useEffect(() => {
    virtualItems.forEach(item => {
      const cluster = clusters![item.index]!

      queryClient.setQueryData(
        [
          queryKeyClusters,
          Number(cluster.clusterId),
          {
            workspaceId,
            projectId,
          },
        ],
        cluster,
        { updatedAt: 1 }
      )
    })
  }, [projectId, clusters, queryClient, virtualItems, workspaceId])

  const metricOptions = React.useMemo(() => {
    return [...clusterMetricsList, ...clusterFocusMetricsList].map(d => ({
      label: d.label,
      value: d.id,
    }))
  }, [])

  const sortingOptions = React.useMemo(
    () =>
      table
        .getVisibleFlatColumns()
        .map(column => {
          return (
            column.getCanSort() && {
              label: column.id,
              value: column.id,
            }
          )
        })
        .filter(Boolean),
    [table]
  )

  const sortingValue = React.useMemo(() => {
    return clustersTable?.sorting?.[0]?.id ?? 'keywordCount'
  }, [clustersTable?.sorting])

  const sortDesc = React.useMemo(() => {
    return clustersTable?.sorting?.[0]?.desc ?? false
  }, [clustersTable?.sorting])

  return (
    <>
      <div className="p-2 shadow-xl">
        <div className="flex items-center gap-2">
          <div className="flex items-center gap-1">
            <Select
              options={sortingOptions}
              value={sortingValue}
              onChange={value => {
                navigate({
                  search: prev => ({
                    ...prev,
                    clustersTable: {
                      ...(prev?.clustersTable ?? {}),
                      sorting: [
                        {
                          id: value,
                          desc: true,
                        },
                      ],
                    },
                  }),
                })
              }}
              inlineLabel={<div className="font-bold">Sort by</div>}
              className="w-fit text-sm"
              placeholder="Select a column..."
            />
            <ButtonPlain
              className="items-center border bg-gray-50 normal-case text-black shadow-none dark:border-gray-800 dark:bg-gray-800 dark:text-white"
              onClick={() => {
                navigate({
                  search: prev => ({
                    ...prev,
                    clustersTable: {
                      ...(prev?.clustersTable ?? {}),
                      sorting: [
                        {
                          id: sortingValue as string,
                          desc: !sortDesc,
                        },
                      ],
                    },
                  }),
                })
              }}
            >
              {sortDesc ? (
                <>
                  <FaSortAmountDown /> Desc
                </>
              ) : (
                <>
                  <FaSortAmountUpAlt /> Asc
                </>
              )}
            </ButtonPlain>
          </div>
          <Select
            multi
            options={metricOptions}
            value={clustersSelectedMetricIds as any[]}
            onChange={values => {
              navigate({
                search: prev => ({
                  ...prev,
                  clustersSelectedMetricIds: values,
                }),
              })
            }}
            inlineLabel={<div className="font-bold">Metrics</div>}
            className="text-sm"
            placeholder="Select metrics..."
          />
          <Counter
            // count={clusters.length}
            totalCount={props.totalSize}
            // showingName={''}
            totalName={'clusters'}
            zeroIsAll
            // compact={}
          />
        </div>
      </div>
      <div
        ref={scrollRef}
        className="max-h-[80vh] overflow-x-auto overflow-y-auto bg-gray-50/50 py-2 dark:bg-gray-950/50"
      >
        <div
          className="relative"
          style={{
            height: virtualizer.getTotalSize(),
          }}
        >
          {virtualItems.map(item => {
            return (
              <ClusterCard
                item={item}
                clusters={clusters}
                clustersSelectedMetricIds={clustersSelectedMetricIds}
                virtualizer={virtualizer}
              />
            )
          })}
        </div>
      </div>
    </>
  )
}
function ClusterCard({
  item,
  clusters,
  clustersSelectedMetricIds,
  virtualizer,
}: {
  item: VirtualItem
  clusters?: ClusterPb[]
  clustersSelectedMetricIds: string[]
  virtualizer?: Virtualizer<any, any>
}) {
  const cluster = clusters![item.index]!

  const visibleClusterMetricsList = clusterMetricsList.filter(d =>
    clustersSelectedMetricIds?.length
      ? clustersSelectedMetricIds.includes(d.id)
      : true
  )

  const visibleClusterFocusMetricsList = clusterFocusMetricsList.filter(d =>
    clustersSelectedMetricIds?.length
      ? clustersSelectedMetricIds.includes(d.id)
      : true
  )

  const rank = cluster.focusedRollupMetrics?.metrics?.topRank?.avg?.value ?? 101

  const isRanking = rank < 101

  const rankGroupColor = isRanking
    ? rankGroupColors[getRankGroupFromRank(rank)]
    : undefined

  return (
    <div
      ref={virtualizer?.measureElement}
      data-index={item.index}
      key={String(cluster.clusterId)}
      className={twMerge('absolute w-fit px-4 py-2')}
      style={{
        transform: `translateY(${item.start}px)`,
      }}
    >
      <Card className="flex h-full min-w-full divide-x divide-gray-500/20 overflow-hidden p-0">
        <div className="sticky left-0 z-10 flex w-[250px] flex-col divide-y divide-gray-500/20 bg-white shadow-xl dark:bg-gray-900 dark:shadow-black">
          <Link
            search={prev => {
              return {
                ...prev,
                clusterId: String(cluster.clusterId),
              }
            }}
            className="px-2 py-1 text-lg"
          >
            {cluster.name}
          </Link>
          <TableEl className="text-xs">
            <tbody>
              {visibleClusterMetricsList
                .map((metric, i) => {
                  return {
                    metric,
                    value: metric.renderValue(
                      metric.getValue(cluster.clusterMetrics![metric.id]!)
                    ),
                  }
                })
                .map(column => {
                  return (
                    <TableRow key={column.metric.label}>
                      <TableCell>
                        <Tooltip tooltip={column.metric.tooltip}>
                          {column.metric.label}
                        </Tooltip>
                      </TableCell>
                      <TableCell className="text-right font-medium">
                        {column.value}
                      </TableCell>
                    </TableRow>
                  )
                })}
            </tbody>
          </TableEl>
          {visibleClusterFocusMetricsList?.length ? (
            <div className="flex flex-1 divide-x divide-gray-500/20">
              <Tooltip
                tooltip={`These metrics are specific to the selected focus domain. If a domain doesn't rank, these metrics will be blank or assumed non-ranking.`}
                className={twMerge(
                  'min-h-14 w-[1.5rem] overflow-hidden rounded-bl-md px-2 py-1 text-xs font-bold uppercase',
                  isRanking && 'border-l-2'
                )}
                style={{
                  borderColor: rankGroupColor,
                }}
              >
                <div
                  className={twMerge(
                    'rotate-90 whitespace-nowrap opacity-20',
                    isRanking && 'opacity-100'
                  )}
                  style={{
                    color: rankGroupColor,
                  }}
                >
                  Focus
                </div>
              </Tooltip>
              <div className="flex-1">
                {isRanking ? (
                  <TableEl className="text-xs">
                    <tbody>
                      {visibleClusterFocusMetricsList
                        .map(metric => {
                          return {
                            header: metric.label,
                            value: metric.renderValue(
                              metric.getValue(
                                cluster.metricsByRollup[0]?.metrics?.[metric.id]
                              )
                            ),
                          } as const
                        })
                        .map(column => {
                          return (
                            <TableRow key={column.header}>
                              <TableCell>{column.header}</TableCell>
                              <TableCell className="items-end text-right font-medium">
                                <div className="ml-auto w-fit">
                                  {column.value}
                                </div>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                    </tbody>
                  </TableEl>
                ) : (
                  <div className="p-2 text-xs italic opacity-30">
                    <FaTimesCircle className="inline text-red-500" /> The
                    focused rollup does not rank for this cluster.
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </div>
        <ClusterDetails cluster={cluster} />
      </Card>
    </div>
  )
}
