import * as React from 'react'
import { twMerge } from 'tailwind-merge'
import { formatNumber } from '../utils/Format'
import Loader from './Loader'

export type CounterProps = {
  isLoading?: boolean
  count?: number
  isLoadingTotalCount?: boolean
  totalCount?: number
  showingName?: string
  totalName?: string
  zeroIsAll?: boolean
  compact?: boolean
} & React.HTMLAttributes<HTMLDivElement>

export function Counter({
  isLoading = false,
  count = 0,
  totalCount = 0,
  isLoadingTotalCount,
  showingName = 'Showing',
  totalName = 'items',
  zeroIsAll,
  compact,
  ...rest
}: CounterProps) {
  const showing = !compact && showingName ? showingName + ' ' : ''
  const allText = showing ? 'all' : 'All'
  const showOf = count !== totalCount
  const isAll =
    (totalCount > 1 && count === totalCount) || (zeroIsAll && !count)

  const totalCountFormatted = formatNumber(totalCount)

  return (
    <div
      className={twMerge(`italic`, compact ? 'text-xs' : 'text-sm')}
      {...rest}
    >
      {isLoading ? (
        <Loader color="gray-500" />
      ) : (
        <span>
          {!totalCount || isLoadingTotalCount ? (
            <>
              {showing}
              {isAll ? allText : count} {totalName}
            </>
          ) : (
            <>
              {showing}
              {isAll ? allText : showOf ? <>{count} of</> : ''}{' '}
              {totalCountFormatted} {totalName}
            </>
          )}
        </span>
      )}
    </div>
  )
}
