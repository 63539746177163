import { css, cx } from '@emotion/css'
import * as React from 'react'
import { twMerge } from 'tailwind-merge'
import Color from '../utils/Color'
import {
  ColorifyColors,
  ColorifyOptions,
  colorify,
  twConfig,
} from '../utils/tailwind'

export default function Loader(
  props: React.HTMLAttributes<HTMLSpanElement> & Partial<ColorifyOptions>
) {
  let colors: ColorifyColors = {} as ColorifyColors

  if (props.color || props.hoverColor) {
    const colorified = colorify(props as ColorifyOptions)
    colors = colorified[0]
    props = colorified[1]
  }

  const { className, ...rest } = props

  return (
    <span
      {...{
        ...rest,
        className: twMerge(
          css`
            display: inline-block;
            position: relative;
            width: 3.5em;
            height: 1em;
            line-height: 1em;
            transition: none;

            & > span {
              display: inline;
              width: 0px;
              opacity: 0;
            }

            & > .isLoading-bar-dot {
              display: block;
              position: absolute;
              left: 0;
              top: 50%;
              height: 0.7em;
              width: 0.7em;
              transform: translateX(-50%) translateY(-50%) scale(0);
              animation: 1s LoadingDotBounce infinite ease-in-out;
              animation-delay: 0ms;
              /* animation-iteration-count: infinite; */
              border-radius: 0.1em;
              -webkit-transform-style: preserve-3d;
              -webkit-backface-visibility: hidden;

              &:nth-child(2) {
                left: 10%;
                animation-delay: 1ms;
                background: ${colors.lightColor ??
                twConfig.theme.colors.blue['500']};

                .dark & {
                  background: ${colors.darkColor ??
                  twConfig.theme.colors.blue['500']};
                }
              }

              &:nth-child(3) {
                left: 36%;
                animation-delay: 150ms;
                background: ${colors.lightColor
                  ? Color(colors.lightColor).darken(5).toRgbString()
                  : twConfig.theme.colors.green[500]};

                .dark & {
                  background: ${colors.darkColor
                    ? Color(colors.darkColor).darken(5).toRgbString()
                    : twConfig.theme.colors.green[500]};
                }
              }

              &:nth-child(4) {
                left: 63%;
                animation-delay: 300ms;
                background: ${colors.lightColor
                  ? Color(colors.lightColor).darken(10).toRgbString()
                  : twConfig.theme.colors.yellow[500]};

                .dark & {
                  background: ${colors.darkColor
                    ? Color(colors.darkColor).darken(10).toRgbString()
                    : twConfig.theme.colors.yellow[500]};
                }
              }

              &:nth-child(5) {
                left: 90%;
                animation-delay: 450ms;
                background: ${colors.lightColor
                  ? Color(colors.lightColor).darken(15).toRgbString()
                  : twConfig.theme.colors.red[500]};

                .dark & {
                  background: ${colors.darkColor
                    ? Color(colors.darkColor).darken(15).toRgbString()
                    : twConfig.theme.colors.red[500]};
                }
              }
            }
          `,
          className
        ),
      }}
    >
      <span>&nbsp;</span>
      {[1, 2, 3, 4].map(d => (
        <div key={d} className="isLoading-bar-dot" />
      ))}
    </span>
  )
}
