import * as React from 'react'
import { useQuery } from 'react-query'
import { fetchReportsTopHosts } from '../utils/Api'
import { queryKeyReportsTop } from '../utils/Constants'
import { useActiveWorkspaceId } from './workspaces'
import { useSearch } from '@tanstack/react-router'

import { SelectOption } from './useSelect'
import { useActiveProjectIdState } from '../utils/searchParams'

export function useHostOptionsQuery({ enabled = true } = {}) {
  const activeWorkspaceId = useActiveWorkspaceId()
  const projectId = useActiveProjectIdState().state

  const optionsQuery = useQuery(
    [
      queryKeyReportsTop,
      'hosts',
      {
        workspaceId: activeWorkspaceId,
        teamId: projectId,
      },
    ],
    () =>
      fetchReportsTopHosts({
        workspaceId: activeWorkspaceId,
        teamId: projectId,
      }),
    {
      enabled: enabled && !!(activeWorkspaceId && projectId),
      staleTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
      retry: 0,
    }
  )

  // Build the focus options
  const data = React.useMemo(
    () =>
      (optionsQuery.data || []).map((d: any) => ({
        value: d.host_id,
        label: `${d.host}`,
      })) as SelectOption[],
    [optionsQuery.data]
  )

  return { ...optionsQuery, data }
}
