import { useNavigate, useSearch } from '@tanstack/react-router'
import * as React from 'react'
import { FaCaretDown, FaCheck, FaInfo } from 'react-icons/fa'
import Card from '../../components/Card'
import { Head } from '../../components/Head'
import { useProjectOptionsQuery } from '../../hooks/projects'
// import { ClustersComp } from './Clusters'
import Clickable from '../../components/Clickable'
import { ButtonPlain } from '../../components/ButtonPlain'
import useLocalState from '../../hooks/useLocalState'

import { openHubSpotChat } from '../../utils/Analytics'
import { useForm } from '@tanstack/react-form'
import LabeledInput from '../../components/LabeledInput'
import Select from '../../components/Select'
import { useQuery } from 'react-query'
import {
  CategoriesTable,
  EntitiesTable,
  Headings,
  IframeProxy,
  Schema,
  TechnologiesTable,
  TextStatsTable,
} from '../../components/ClusterUrl'
import { queryKeyPages } from '../../utils/Constants'

import { Link } from '@tanstack/react-router'
import { twMerge } from 'tailwind-merge'
import {
  TableCell,
  TableEl,
  TableRow,
  TableWrapInner,
  TableWrapOuter,
} from '../../components/Table'
import { BarButton, ButtonBar } from '../../components/ButtonBar'
import { PageClient, PagePb, UrlPb } from '../../utils/proto'
import QueryGate from '../../components/QueryGate'
import Input from '../../components/Input'
import Loader from '../../components/Loader'
import Spinner from '../../components/Spinner'

export function UrlExplorer() {
  const [urlExplorerDismissVersion, setUrlExplorerDismissVersion] =
    useLocalState('urlExplorerDismissVersion', 0)

  const currentDismissVersion = 1

  return (
    <div className="space-y-4 py-4">
      <Head>
        <title>URL Explorer</title>
      </Head>
      <div className="px-4">
        <Card className="space-between flex items-center gap-1">
          <h1 className="text-2xl font-bold">URL Explorer</h1>
        </Card>
      </div>
      <div className="space-y-4 px-4">
        {currentDismissVersion === urlExplorerDismissVersion ? (
          <ButtonPlain
            className="flex items-center bg-white text-xs text-gray-700 dark:bg-gray-800 dark:text-white"
            onClick={() => setUrlExplorerDismissVersion(0)}
          >
            <FaInfo /> Show Welcome Message
          </ButtonPlain>
        ) : null}
      </div>
      {urlExplorerDismissVersion < currentDismissVersion ? (
        <div className="space-y-4 px-4">
          <div className="grid gap-4">
            <div className="flex  flex-col gap-4">
              <Card className="flex-1 space-y-4 p-4">
                <div className="text-2xl font-bold">
                  Welcome to the URL Explorer!
                </div>
                <div>
                  <strong>The URL Explorer</strong> is currently in{' '}
                  <div className="inline-block rounded-md border border-purple-500 bg-white px-1.5 text-[.7rem] font-bold uppercase text-purple-500 dark:bg-gray-900">
                    alpha
                  </div>
                  . If you experience errors, have suggestions, or would like to
                  provide general feedback,{' '}
                  <Clickable onClick={openHubSpotChat}>chat with us!</Clickable>
                </div>
              </Card>
            </div>
          </div>
          <div className="text-center">
            <ButtonPlain
              className="bg-green-500"
              onClick={() =>
                setUrlExplorerDismissVersion(currentDismissVersion)
              }
            >
              <FaCheck /> Hide
            </ButtonPlain>
          </div>
        </div>
      ) : null}
      <UrlExplorerComp />
    </div>
  )
}

function UrlExplorerComp() {
  const { url } = useSearch()
  const navigate = useNavigate()
  const [history, setHistory] = useLocalState<string[]>(
    'urlExplorerHistory',
    []
  )

  const historyOptions = React.useMemo(() => {
    return history.map(d => ({
      label: d,
      value: d,
    }))
  }, [history])

  const defaultValues = React.useMemo(
    () => ({
      url: url || '',
    }),
    [url]
  )

  const setUrl = (url: string) => {
    if (!url) {
      return
    }

    setHistory(prev => {
      return [url, ...prev.filter(d => d !== url)].slice(0, 500)
    })

    navigate({
      search: prev => ({
        ...prev,
        url,
      }),
    })
  }

  const form = useForm({
    defaultValues,
    onSubmit: values => {
      setUrl(values.url)
    },
  })

  const pageQuery = useQuery({
    queryKey: [queryKeyPages, url],
    queryFn: () =>
      PageClient.requestPage({
        url,
        waitForCompletion: true,
        includeCategories: true,
        includeEntities: true,
        // includeLighthouse: true,
      }),
    enabled: !!url,
  })

  return (
    <div className="space-y-4 px-4">
      <Head>
        <title>URL Explorer</title>
      </Head>
      <Card>
        <form.Form className="space-y-2">
          <form.Field
            name="url"
            children={field => {
              return (
                <div className="flex items-center gap-2">
                  {pageQuery.isFetching ? (
                    <Spinner className="mx-2" />
                  ) : (
                    <div className="px-2 font-bold">URL</div>
                  )}
                  <Input
                    {...field.getInputProps()}
                    placeholder="https://example.com"
                    className="flex-1"
                    type="url"
                  />
                  <ButtonPlain type="submit" className="bg-blue-500">
                    <FaCheck /> Explore
                  </ButtonPlain>
                  <Select
                    options={historyOptions}
                    onChange={value => {
                      form.setFieldValue('url', value)
                      setUrl(value)
                    }}
                    children={({ onClick }) => {
                      return (
                        <ButtonPlain
                          onClick={onClick}
                          className="bg-gray-100 text-gray-700 dark:bg-gray-800 dark:text-white"
                        >
                          History <FaCaretDown />
                        </ButtonPlain>
                      )
                    }}
                  />
                </div>
              )
            }}
          />
        </form.Form>
      </Card>
      {url ? (
        <QueryGate
          query={pageQuery}
          loader={
            <Card className="flex items-center justify-center">
              <div className="space-y-2 p-4 text-center">
                <div className="text-sm font-bold">Loading URL</div>
                <Loader className="text-2xl" />
                <div className="text-xs italic opacity-50">
                  (This can take up to 2 minutes...)
                </div>
              </div>
            </Card>
          }
        >
          <PageComp page={pageQuery.data?.page} />
        </QueryGate>
      ) : null}
    </div>
  )
}

type PanelId =
  | undefined
  | 'categories'
  | 'textStats'
  | 'entities'
  | 'headings'
  | 'technologies'
  | 'schema'

const panels: { label: string; id: PanelId }[] = [
  { label: 'Info', id: undefined },
  { label: 'Entities', id: 'entities' },
  { label: 'Categories', id: 'categories' },
  { label: 'Headings', id: 'headings' },
  { label: 'Schema', id: 'schema' },
  { label: 'Text Stats', id: 'textStats' },
  { label: 'Technologies', id: 'technologies' },
]

export function PageComp({ page }: { page?: PagePb }) {
  const search = useSearch()
  const clusterUrlPanelId = search.clusterUrlPanelId as PanelId

  const url = page?.url

  return (
    <div className="space-y-4 pb-[400px]">
      <ButtonBar>
        {panels.map(panel => {
          return (
            <Link
              key={panel.id || 0}
              search={d => ({
                ...d,
                clusterUrlPanelId: panel.id,
              })}
            >
              <BarButton
                className={twMerge(panel.id === clusterUrlPanelId && 'active')}
              >
                {panel.label}
              </BarButton>
            </Link>
          )
        })}
      </ButtonBar>
      <div>
        {(() => {
          if (!clusterUrlPanelId) {
            return (
              <div className="space-y-4">
                <Card className="divide-y divide-gray-500/20 p-0">
                  <div className="p-2 text-lg font-bold">URL</div>
                  <InfoTable url={url} />
                  <IframeProxy
                    src={url?.url}
                    title={url?.url}
                    className="m-0 h-[60vh] w-full"
                  />
                </Card>
              </div>
            )
          } else if (clusterUrlPanelId === 'categories') {
            return (
              <Card className="divide-y divide-gray-500/20 p-0">
                <div className="p-2 text-lg font-bold">Categories</div>
                <CategoriesTable categories={page?.categories} />
              </Card>
            )
          } else if (clusterUrlPanelId === 'textStats') {
            return (
              <Card className="divide-y divide-gray-500/20 p-0">
                <div className="p-2 text-lg font-bold">Text Stats</div>
                <TextStatsTable textStats={page?.textStats} />
              </Card>
            )
          } else if (clusterUrlPanelId === 'entities') {
            return (
              <Card className="divide-y divide-gray-500/20 p-0">
                <div className="p-2 text-lg font-bold">Entities</div>
                <EntitiesTable entities={page?.entities} />
              </Card>
            )
          } else if (clusterUrlPanelId === 'headings') {
            return (
              <Card className="flex flex-col divide-y divide-gray-500/20 p-0">
                <div className="p-2 text-lg font-bold">Headings</div>
                <Headings headings={page?.headings} />
              </Card>
            )
          } else if (clusterUrlPanelId === 'technologies') {
            return (
              <Card className="divide-y divide-gray-500/20 p-0">
                <div className="p-2 text-lg font-bold">Technologies</div>
                <TechnologiesTable technologies={page?.technologies} />
              </Card>
            )
          } else if (clusterUrlPanelId === 'schema') {
            return (
              <Card className="divide-y divide-gray-500/20 p-0">
                <div className="p-2 text-lg font-bold">Schema</div>
                <Schema schema={page?.schema} />
              </Card>
            )
          } else {
            return (
              <Card>
                Invalid Panel! Please select a panel from the tabs above.
              </Card>
            )
          }
        })()}
      </div>
    </div>
  )
}

function InfoTable(props: { url?: UrlPb }) {
  const { url } = props

  return (
    <TableWrapOuter>
      <TableWrapInner>
        <TableEl className="text-sm">
          <tbody>
            <TableRow>
              <TableCell>URL</TableCell>
              <TableCell>{url?.url ?? 'N/A'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Domain</TableCell>
              <TableCell>{url?.domain ?? 'N/A'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Host</TableCell>
              <TableCell>{url?.host ?? 'N/A'}</TableCell>
            </TableRow>
          </tbody>
        </TableEl>
      </TableWrapInner>
    </TableWrapOuter>
  )
}
