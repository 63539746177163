export const scheduleOptions = [
  {
    label: 'Hourly',
    value: 'FREQ=HOURLY;BYMINUTE=0;BYSECOND=0',
    dailyOrFaster: true,
    multiplier: 24 * 30,
    requiresStartDate: false,
  },
  {
    label: 'Every 6 Hours',
    value: 'FREQ=HOURLY;INTERVAL=6',
    dailyOrFaster: true,
    multiplier: 4 * 30,
    requiresStartDate: false,
  },
  {
    label: 'Every 8 Hours',
    value: 'FREQ=HOURLY;INTERVAL=8',
    dailyOrFaster: true,
    multiplier: 3 * 30,
    requiresStartDate: false,
  },
  {
    label: 'Every 12 Hours',
    value: 'FREQ=HOURLY;INTERVAL=12',
    dailyOrFaster: true,
    multiplier: 2 * 30,
    requiresStartDate: false,
  },
  {
    label: 'Daily',
    value: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
    dailyOrFaster: true,
    multiplier: 30,
    requiresStartDate: false,
  },
  {
    label: 'Every Other Day',
    value: 'FREQ=DAILY;BYHOUR=0;BYMINUTE=0;BYSECOND=0;INTERVAL=2',
    dailyOrFaster: false,
    multiplier: 15,
    requiresStartDate: true,
  },
  {
    label: 'Every Monday, Wednesday, and Friday',
    value: 'FREQ=WEEKLY;BYDAY=MO,WE,FR;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
    dailyOrFaster: false,
    multiplier: 13,
    requiresStartDate: false,
  },
  {
    label: 'Weekly on Sunday',
    value: 'FREQ=WEEKLY;BYDAY=SU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
    dailyOrFaster: false,
    multiplier: 4,
    requiresStartDate: false,
  },
  {
    label: 'Weekly on Monday',
    value: 'FREQ=WEEKLY;BYDAY=MO;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
    dailyOrFaster: false,
    multiplier: 4,
    requiresStartDate: false,
  },
  {
    label: 'Weekly on Tuesday',
    value: 'FREQ=WEEKLY;BYDAY=TU;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
    dailyOrFaster: false,
    multiplier: 4,
    requiresStartDate: false,
  },
  {
    label: 'Weekly on Wednesday',
    value: 'FREQ=WEEKLY;BYDAY=WE;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
    dailyOrFaster: false,
    multiplier: 4,
    requiresStartDate: false,
  },
  {
    label: 'Weekly on Thursday',
    value: 'FREQ=WEEKLY;BYDAY=TH;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
    dailyOrFaster: false,
    multiplier: 4,
    requiresStartDate: false,
  },
  {
    label: 'Weekly on Friday',
    value: 'FREQ=WEEKLY;BYDAY=FR;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
    dailyOrFaster: false,
    multiplier: 4,
    requiresStartDate: false,
  },
  {
    label: 'Weekly on Saturday',
    value: 'FREQ=WEEKLY;BYDAY=SA;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
    dailyOrFaster: false,
    multiplier: 4,
    requiresStartDate: false,
  },
  {
    label: 'Twice a month on the 1st and 15th',
    value: 'FREQ=MONTHLY;BYMONTHDAY=1,15;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
    dailyOrFaster: false,
    multiplier: 2,
    requiresStartDate: false,
  },
  {
    label: 'Monthly on the 1st',
    value: 'FREQ=MONTHLY;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
    dailyOrFaster: false,
    multiplier: 1,
    requiresStartDate: false,
  },
  {
    label: 'Monthly on the last day of the month',
    value: `FREQ=MONTHLY;BYMONTHDAY=-1;BYHOUR=0;BYMINUTE=0;BYSECOND=0`,
    dailyOrFaster: false,
    multiplier: 1,
    requiresStartDate: false,
  },
  {
    label: 'Quarterly',
    value:
      'FREQ=YEARLY;BYMONTH=1,4,7,10;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
    dailyOrFaster: false,
    multiplier: 0.333333333333,
    requiresStartDate: false,
  },
  {
    label: 'Every 6 Months',
    value:
      'FREQ=YEARLY;BYMONTH=1,7;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
    dailyOrFaster: false,
    multiplier: 0.1666666666666,
    requiresStartDate: false,
  },
  {
    label: 'Yearly',
    value: 'FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;BYHOUR=0;BYMINUTE=0;BYSECOND=0',
    dailyOrFaster: false,
    multiplier: 0.1666666666666,
    requiresStartDate: false,
  },
  {
    label: 'Custom',
    value: 'custom',
    multiplier: 0,
    dailyOrFaster: true,
    requiresStartDate: false,
  },
] as const
