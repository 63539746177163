import * as React from 'react'
import { useQueryClient } from 'react-query'
import { useDebouncedState } from '../hooks/useDebouncedState'
import Select from './Select'
import { KeywordPb } from '../utils/proto'
import {
  formatKeyword,
  renderKeyword,
  useKeywordSearchQuery,
} from '../hooks/keywords'

type KeywordOption = {
  value: string
  label: string
  meta: any
}

export function KeywordPicker(
  props: {
    projectId: string
    children?: any
    onChange?: (value: string[], keyword?: KeywordPb) => void
    onBlur?: (e: any) => void
    setTouched?: (touched: boolean) => void
    excludeKeywordIds?: string[]
  } & Omit<
    React.ComponentProps<typeof Select>,
    'options' | 'value' | 'onChange'
  >
) {
  const queryClient = useQueryClient()

  const [search, debouncedSearch, setSearch] = useDebouncedState({
    initialValue: '',
  })

  const keywordSearchQuery = useKeywordSearchQuery({
    projectId: props.projectId,
    search: debouncedSearch,
  })

  const isLoading = keywordSearchQuery.isLoading

  const options = React.useMemo((): KeywordOption[] => {
    return (
      keywordSearchQuery.data?.map(keyword => {
        return {
          value: String(keyword.keywordId),
          label: formatKeyword(
            keyword,
            keywordSearchQuery.data.localesById
          ) as string,
          meta: [keyword, keywordSearchQuery.data.localesById],
        }
      }) ?? []
    )
  }, [keywordSearchQuery.data])

  const filteredOptions = React.useMemo(() => {
    if (props.excludeKeywordIds) {
      return options.filter(d => !props.excludeKeywordIds?.includes(d.value))
    }

    return options
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, props.excludeKeywordIds])

  return (
    <Select<KeywordOption>
      {...{
        closeOnSelect: false,
        placeholder: 'Search for a keyword...',
        className: 'inline-block text-sm',
        sort: false,
        ...props,
        isLoading: search !== debouncedSearch || isLoading,
        onInputChange: (event: any) => setSearch(event.target.value),
        options: filteredOptions,
        value: search,
        onChange: (next: any) => {
          const keyword = keywordSearchQuery.data?.find(
            d => d.keywordId === next
          )

          queryClient.setQueryData(['keyword', Number(next)], keyword, {
            updatedAt: Date.now() + 1000 * 60 * 60 * 24,
          })
          props.onChange?.(next, keyword as any)
        },
        onBlur: (e: Event) => {
          props.setTouched?.(true)
          props.onBlur?.(e)
        },
        renderOption: option => {
          return option.meta
            ? renderKeyword(...(option.meta as any))
            : option.label
        },
      }}
    />
  )
}
