import * as React from 'react'
import Input, { InputProps } from '../components/Input'
import LabelWrap, { LabelWrapProps } from '../components/LabelWrap'

//
type Props = InputProps &
  Omit<LabelWrapProps, 'children'> & {
    inputProps?: never
  }

const LabeledInput = React.forwardRef<any, Props>(
  (
    {
      label,
      className,
      style = {},
      children,
      inputProps = {},
      error,
      isValidating,
      value,
      ...rest
    },
    ref
  ) => (
    <LabelWrap
      label={label}
      className={className}
      style={style}
      error={error}
      isValidating={isValidating}
    >
      <Input
        {...rest}
        value={value}
        ref={ref}
        {...inputProps}
        className="w-full"
      />
    </LabelWrap>
  )
)

export default LabeledInput
