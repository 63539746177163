import * as React from 'react'
import { useQueryClient } from 'react-query'
import { useDebouncedState } from '../hooks/useDebouncedState'
import { Locale, renderLocale } from '../utils/locales'
import { useLocalesSearchQuery } from '../utils/locales'
import { useRecentLocales } from '../utils/locales'
import { formatLocale } from '../utils/locales'
import Select from './Select'

type LocaleOption = {
  value: number
  label: string
  meta: any
}

export function LocalePicker(props: {
  children?: any
  onChange?: (value: number[], locale?: Locale) => void
  onBlur?: (e: any) => void
  setTouched?: (touched: boolean) => void
  excludeLocaleIds?: number[]
}) {
  const queryClient = useQueryClient()

  const [search, debouncedSearch, setSearch] = useDebouncedState({
    initialValue: '',
  })

  const localesQuery = useLocalesSearchQuery(debouncedSearch)

  const recentLocales = useRecentLocales()

  const isLoading = localesQuery.isLoading

  const options = React.useMemo(() => {
    return (
      localesQuery.data?.map(locale => ({
        value: locale.locale_id,
        label: formatLocale(locale),
        meta: locale,
      })) ?? []
    )
  }, [localesQuery])

  const filteredOptions = React.useMemo(() => {
    if (props.excludeLocaleIds) {
      return options.filter(d => !props.excludeLocaleIds?.includes(d.value))
    }

    return options
  }, [options, props.excludeLocaleIds])

  return (
    <Select<LocaleOption>
      {...{
        closeOnSelect: false,
        placeholder: 'Search for a locale...',
        className: 'inline-block',
        sort: false,
        ...props,
        isLoading: search !== debouncedSearch || isLoading,
        onInputChange: (event: any) => setSearch(event.target.value),
        options: filteredOptions,
        value: search,
        onChange: (next: any) => {
          recentLocales.setRecentLocaleIds(old => [
            next,
            ...old.filter(id => id !== next),
          ])

          const locale = localesQuery.data?.find(d => d.locale_id === next)

          queryClient.setQueryData(['locale', Number(next)], locale, {
            updatedAt: Date.now() + 1000 * 60 * 60 * 24,
          })
          setSearch('')
          props.onChange?.(next, locale)
        },
        onBlur: (e: Event) => {
          props.setTouched?.(true)
          props.onBlur?.(e)
        },
        renderOption: option => {
          return option.meta ? renderLocale(option.meta) : option.label
        },
      }}
    />
  )
}
