import * as Plot from '@observablehq/plot'
import * as React from 'react'
import { twMerge } from 'tailwind-merge'
import useRect from '../hooks/useRect'
import { dataColorsList } from '../utils/DataColors'
import { useThemeMode } from '../utils/Theme'
import useGetLatest from '../hooks/useGetLatest'
import useStrictEffect from '../hooks/useStrictEffect'

export function ObservablePlot<T>({
  legend,
  legendType = 'color',
  options,
  legendAfter,
  onTipHover,
  onTipClick,
  hideTip,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & {
  legend?: boolean | (Plot.LegendOptions & { style?: React.CSSProperties })
  legendType?: Plot.ScaleName
  options: Plot.PlotOptions
  legendAfter?: boolean
  onTipClick?: (item: T, e: PointerEvent) => void
  onTipHover?: (item?: T) => void
  hideTip?: boolean
}) {
  const [el, setEl] = React.useState<HTMLDivElement>(null!)
  const [legendEl, setLegendEl] = React.useState<HTMLDivElement>(null!)
  const [plotEl, setPlotEl] = React.useState<HTMLDivElement>(null!)
  const { themeMode } = useThemeMode()
  const legendRect = useRect(legendEl)
  const rect = useRect(el)
  // const [hovered, setHovered] = React.useState<T | undefined>()

  const getOnTipHover = useGetLatest((value: T | undefined) => {
    // setHovered(value)
    onTipHover?.(value)
  })

  const getOnTipClick = useGetLatest(onTipClick)

  const width = rect.width
  const height = Math.max(0, rect.height - (legendRect?.height ?? 0))

  const plot = React.useMemo(() => {
    const plot = Plot.plot({
      ...options,
      color: {
        range: dataColorsList,
        ...options.color,
        legend: false,
      },
      style: {
        background: 'transparent',
        color: themeMode === 'dark' ? '#fff' : '#000',
        ...(options.style as any),
      },
      width,
      height,
    })

    return plot
  }, [options, themeMode, width, height])

  const legendPlotEl = React.useMemo(() => {
    const resolvedLegend = typeof legend === 'boolean' ? {} : legend

    return plot.legend(legendType, {
      marginLeft: 5,
      ...resolvedLegend,
      style: {
        background: 'transparent',
        color: themeMode === 'dark' ? '#fff' : '#000',
        ...(resolvedLegend?.style as any),
      },
    })
  }, [legend, legendType, plot, themeMode])

  React.useEffect(() => {
    if (!legendEl) {
      return
    }

    legendEl.innerHTML = ''
    if (legendPlotEl) legendEl.append(legendPlotEl)
  }, [legendEl, legendPlotEl])

  useStrictEffect(() => {
    if (plotEl) {
      plotEl.innerHTML = ''
      plotEl.append(plot)
    }

    let registeredOnLeave = false

    const onTipHoverCb = () => {
      if (!registeredOnLeave) {
        registeredOnLeave = true
        const onLeave = e => {
          if (!plotEl.contains(e.target)) {
            getOnTipHover()?.(undefined!)
            registeredOnLeave = false
            document.removeEventListener('mousemove', onLeave)
          }
        }
        document.addEventListener('mousemove', onLeave)
      }
      getOnTipHover()?.(plot.value as T)
    }

    const onTipClickCb = (e: any) => {
      if (plot.value) getOnTipClick()?.(plot.value as T, e)
    }

    plot.addEventListener('mousemove', onTipHoverCb)
    plot.addEventListener('click', onTipClickCb)

    return () => {
      plot.removeEventListener('mousemove', onTipHoverCb)
      plot.removeEventListener('click', onTipClickCb)
    }
  }, [getOnTipClick, getOnTipHover, width, height, plot, plotEl])

  // React.useEffect(() => {
  //   if (plotEl) {
  //     plotEl.style.cursor = getOnTipClick() && hovered ? 'pointer' : 'default'
  //   }
  // }, [plotEl, getOnTipClick, hovered])

  const legendComp = legend ? (
    <div
      ref={el => {
        setLegendEl(el!)
      }}
      className="legend-container"
    />
  ) : null

  const plotComp = plot ? (
    <div
      ref={el => {
        setPlotEl(el!)
      }}
      className="plot-container w-full"
      style={{
        height,
      }}
      // onPointerLeave={() => {
      //   setHovered(undefined)
      // }}
    />
  ) : null

  return (
    <div
      {...props}
      ref={el => {
        setEl(el!)
      }}
      className={twMerge('', props.className, hideTip && 'hide-tip')}
    >
      {legendAfter ? (
        <>
          {plotComp}
          {legendComp}
        </>
      ) : (
        <>
          {legendComp}
          {plotComp}
        </>
      )}
    </div>
  )
}
