export const matchTypeOptions = [
  // {
  //   label: 'Column Expression',
  //   value: 'expression',
  // },
  {
    label: 'Match All',
    value: 'all',
  },
  {
    label: 'Match Any',
    value: 'any',
  },
  // {
  //   label: 'Number of conditions are met (n)',
  //   value: 'number',
  // },
  // {
  //   label: 'Percentage of conditions are met (%)',
  //   value: 'percentage',
  // },
]
