import * as React from 'react'
import { BiImport } from 'react-icons/bi'
import { FaQuestionCircle } from 'react-icons/fa'
import useModal from '../hooks/useModal'
import { dedupeKeywords } from '../utils/Phrases'
import Button from './Button'
import Caption from './Caption'
import Clickable from './Clickable'
import { GoogleSearchConsoleModal } from './GoogleSearchConsoleModal'
import JsonKeywordsDevices from './JsonKeywordsDevices'
import JsonKeywordsGroups from './JsonKeywordsGroups'
import JsonKeywordsLocales from './JsonKeywordsLocales'
import { KeywordGroupHelp } from './KeywordGroupHelp'
import {
  DevicesHelp,
  LocalesHelp,
  ScheduleHelp,
} from './KeywordSourceKeywordsBasic'
import KeywordsEditorTable from './KeywordsEditorTable'
import SchedulesField from './SchedulesField'
import Tooltip from './Tooltip'
import Card from './Card'

//

export default function JsonPhrases({ keywords, onKeywordsChange }: any) {
  const [metaPanel, metaPanelSet] = React.useState('devices')

  const showModal = useModal()

  const info = React.useMemo(() => dedupeKeywords(keywords), [keywords])

  const onGoogleSubmit = async (selectedRows: any, globalGroups: any) => {
    onKeywordsChange(
      (old: any) =>
        dedupeKeywords([
          ...old,
          ...selectedRows
            .map((d: any) => d.values.key)
            .filter(Boolean)
            .map((line: any) => {
              const [phrase, ...groups] = line
                .split(';')
                .map((d: any) => d.trim())
              return {
                phrase,
                groups: [...groups, ...globalGroups],
                devices: ['d'],
                localeIds: [44249],
              }
            }),
        ]).keywords
    )
  }

  const groupOptions = React.useMemo(
    () =>
      Object.keys(info.groups).map(d => ({
        value: d,
        label: d,
      })),
    [info.groups]
  )

  return (
    <>
      <Card className="space-y-10 p-2">
        <div className="flex flex-col gap-2">
          <div className="flex flex-wrap justify-between gap-2">
            <div className="text-xl font-bold">Schedules</div>
            <ScheduleHelp />
          </div>
          <SchedulesField />
        </div>
      </Card>
      <Card className="space-y-2 p-2">
        <div className="flex flex-wrap items-center justify-between gap-2">
          <div className="text-xl font-bold">Keywords</div>
          <Tooltip
            tooltip={
              <>
                Advanced keywords are unique keyword + device + locale
                combinations. Use this table to add, edit, and assign them to
                keyword groups.
              </>
            }
          >
            <Caption className="font-normal">
              <FaQuestionCircle className="inline" /> What are advanced
              keywords?
            </Caption>
          </Tooltip>
        </div>
        <div className="flex flex-wrap items-center gap-2 p-2">
          <Button
            size="xs"
            color={['gray-100', 'gray-800']}
            className="mb-1 mr-1"
            onClick={() =>
              showModal(() => (
                <GoogleSearchConsoleModal
                  onSubmit={onGoogleSubmit}
                  groupOptions={groupOptions}
                />
              ))
            }
          >
            <BiImport className="inline" />
            Import from Search Console
          </Button>
        </div>
        <KeywordsEditorTable
          keywords={info.keywords}
          onKeywordsChange={onKeywordsChange}
        />
      </Card>
      <Card className="space-y-2 p-2">
        <div className="flex items-center justify-between">
          <div className="text-xl font-bold">
            <Clickable
              onClick={() => metaPanelSet('keywordGroups')}
              className={
                metaPanel === 'keywordGroups'
                  ? 'text-black dark:text-white'
                  : ''
              }
            >
              Keyword Groups
            </Clickable>{' '}
            |{' '}
            <Clickable
              onClick={() => metaPanelSet('devices')}
              className={
                metaPanel === 'devices' ? 'text-black dark:text-white' : ''
              }
            >
              Devices
            </Clickable>{' '}
            |{' '}
            <Clickable
              onClick={() => metaPanelSet('localeIds')}
              className={
                metaPanel === 'localeIds' ? 'text-black dark:text-white' : ''
              }
            >
              Locales
            </Clickable>
          </div>
          {metaPanel === 'keywordGroups' ? (
            <KeywordGroupHelp />
          ) : metaPanel === 'devices' ? (
            <DevicesHelp />
          ) : (
            <LocalesHelp />
          )}
        </div>

        {metaPanel === 'keywordGroups' ? (
          <JsonKeywordsGroups
            keywords={info.keywords}
            onKeywordsChange={onKeywordsChange}
          />
        ) : metaPanel === 'devices' ? (
          <JsonKeywordsDevices
            keywords={info.keywords}
            onKeywordsChange={onKeywordsChange}
          />
        ) : (
          <JsonKeywordsLocales
            keywords={info.keywords}
            onKeywordsChange={onKeywordsChange}
          />
        )}
      </Card>
    </>
  )
}
