import * as React from 'react'
import { Auth } from '../../components/Auth'
import { useProfileQuery } from '../../hooks/profile'
import useAuth from '../../hooks/useAuth'
import Button from '../../components/Button'
import Card from '../../components/Card'

import { useNavigate, useSearch } from '@tanstack/react-router'
import Select from '../../components/Select'
import {
  useActiveWorkspaceId,
  useCreateWorkspace,
  useWorkspaceOptions,
} from '../../hooks/workspaces'
import useLocalState from '../../hooks/useLocalState'
import { formatNumber, formatSlug } from '../../utils/Format'
import { useForm } from '@tanstack/react-form'
import Caption from '../../components/Caption'
import {
  FaAdjust,
  FaAngleRight,
  FaAngleLeft,
  FaCheck,
  FaInfoCircle,
  FaMoon,
  FaSun,
} from 'react-icons/fa'
import LabeledInput from '../../components/LabeledInput'
import Validate, { validateDomain } from '../../utils/Validate'
import { IoIosWarning } from 'react-icons/io'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import Spinner from '../../components/Spinner'
import { useCreateProject, useProject } from '../../hooks/projects'
import Clickable, { ClickablePlain } from '../../components/Clickable'
import Loader from '../../components/Loader'
import Tooltip from '../../components/Tooltip'
import { useThemeMode } from '../../utils/Theme'
import Link from '../../components/Link'
import { ButtonPlain } from '../../components/ButtonPlain'
import { useCreateBrand } from '../../hooks/brands'
import { BRAND_TYPE_OWNED, queryKeyGooglePhrases } from '../../utils/Constants'
import { fetchBrandInfo } from '../../utils/Api'
import { phraseTextToPhraseGroups } from '../../utils/Phrases'
import { LogoSvg } from '../../components/Logo'
import {
  useKeywordSources,
  useSaveKeywordSourceV2,
} from '../../hooks/keywordSources'
import {
  DevicePb,
  KeywordSourceDataModelConfig2Pb,
  KeywordSourcePb,
} from '../../utils/proto'
import { scheduleOptions } from '../../options/scheduleOptions'
import {
  GoogleSearchConsoleKeyword,
  fetchSearchConsoleKeywords,
  fetchSearchConsoleSites,
  revokeGapiToken,
} from '../../utils/gapi'
import QueryGate from '../../components/QueryGate'
import { openHubSpotChat } from '../../utils/Analytics'
import { IoWarning } from 'react-icons/io5'
import { ImportSerpWooProjectsForm } from '../../components/ImportSerpWooProjectsForm'

const limit = 2500

export function SerpwooSignup() {
  const { workspaceId, projectId, continueAs, importer, serpwooUploaded } =
    useSearch()
  const projectQuery = useProject({
    workspaceId,
    id: projectId,
  })
  const keywordSourcesQuery = useKeywordSources({
    workspaceId,
    projectId,
  })
  const { preThemeMode, setThemeMode } = useThemeMode()

  const step = React.useMemo(() => {
    if (!continueAs) {
      return 'auth'
    }

    if (!workspaceId) {
      return 'workspace'
    }

    if (!projectId) {
      return 'project'
    }

    if (projectQuery.isLoading) {
      return 'loading'
    }

    if (!serpwooUploaded) {
      return 'upload'
    }

    // if (!keywordSourcesQuery.data?.some(d => d.keywordCount > 0)) {
    //   return 'keywords'
    // }

    return 'done'
  }, [
    continueAs,
    projectId,
    projectQuery.isLoading,
    serpwooUploaded,
    workspaceId,
  ])

  return (
    <div className="min-h-[100vh] max-w-full bg-white dark:bg-black">
      <Tooltip
        side="bottom"
        tooltip={
          preThemeMode === 'light'
            ? 'Theme: Light'
            : preThemeMode === 'dark'
            ? 'Theme: Dark'
            : 'Theme: System/Auto'
        }
        className="absolute left-0 top-0 z-20"
      >
        <ClickablePlain
          onClick={() =>
            setThemeMode((old: any) =>
              old === 'dark' ? 'auto' : old === 'auto' ? 'light' : 'dark'
            )
          }
          className="p-2 opacity-50 transition duration-100 hover:opacity-100"
        >
          {preThemeMode === 'light' ? (
            <FaSun />
          ) : preThemeMode === 'dark' ? (
            <FaMoon />
          ) : (
            <FaAdjust />
          )}
        </ClickablePlain>
      </Tooltip>
      <div
        alt=""
        className="pointer-events-none fixed inset-0 w-full bg-cover bg-center opacity-60 blur-[3px] dark:hidden"
        style={{
          backgroundImage: 'url(/img/serpwoo-onboarding-light.png)',
        }}
      />
      <div
        alt=""
        className="pointer-events-none fixed inset-0 hidden w-full bg-cover bg-center blur-[5px] dark:block"
        style={{
          backgroundImage: 'url(/img/serpwoo-onboarding-dark.png)',
        }}
      />
      <div className="relative z-10 mx-auto flex w-[800px] max-w-full flex-col items-center space-y-4 px-2 py-4">
        <div className="dark:via-black-80 flex flex-col items-center gap-2 bg-gradient-radial from-white via-white/80 to-transparent p-8 dark:from-black">
          <div className="text-center text-4xl font-black">Import From</div>
          <div className="text-center text-7xl font-black">SERPWoo</div>
          <div className="font-bold opacity-70">to</div>
          <div className="flex items-center gap-4">
            <LogoSvg className="w-[150px] text-blue-500 dark:text-white" />
          </div>
        </div>
        <div className="max-w-full">
          {(() => {
            if (step === 'loading') {
              return <Loader />
            }

            if (step === 'auth') {
              return <StepAuth />
            }

            if (step === 'workspace') {
              return <StepWorkspace />
            }

            if (step === 'project') {
              if (!workspaceId) {
                throw new Error('No workspace ID')
              }

              return <StepUpload />
            }

            return <StepDone />
          })()}
        </div>
      </div>
    </div>
  )
}

function StepAuth() {
  const [auth] = useAuth()
  const profileQuery = useProfileQuery()
  const navigate = useNavigate()

  React.useEffect(() => {
    navigate({
      search: prev => ({
        ...prev,
        workspaceId: undefined,
      }),
    })
  }, [navigate])

  return (
    <div className="flex flex-col items-center space-y-8 divide-y divide-gray-500/20 rounded-lg border-gray-900 bg-white bg-opacity-90 p-2 shadow-xl dark:border dark:bg-black">
      <Auth />
      {auth.stage === 'loggedIn' && profileQuery.data ? (
        <div>
          <Button
            size="base"
            color="blue-500"
            onClick={() => {
              navigate({
                search: prev => ({
                  ...prev,
                  continueAs: profileQuery.data?.id,
                }),
              })
            }}
          >
            Continue as{' '}
            {profileQuery.data.givenName || profileQuery.data.displayName}
          </Button>
        </div>
      ) : null}
    </div>
  )
}

function StepWorkspace() {
  const profileQuery = useProfileQuery()
  const navigate = useNavigate()
  const workspaceOptions = useWorkspaceOptions()

  React.useEffect(() => {
    navigate({
      search: prev => ({
        ...prev,
        projectId: undefined,
      }),
    })
  }, [navigate])

  return (
    <div className="space-y-2">
      <div>
        <Link
          search={prev => ({
            ...prev,
            continueAs: undefined,
          })}
        >
          <ButtonPlain className="inline-flex items-center gap-1 bg-white text-sm font-bold text-blue-500 shadow-md dark:bg-gray-800">
            <FaAngleLeft /> Back
          </ButtonPlain>
        </Link>
      </div>
      {profileQuery.data?.userWorkspaces.length ? (
        <>
          <Card className="divide-y divide-gray-500/20 p-0">
            <div className="relative p-4 text-lg font-bold">
              Choose an existing workspace
            </div>
            <div className="p-4">
              <Select
                options={workspaceOptions}
                inline={workspaceOptions.length < 20}
                onChange={workspace => {
                  navigate({
                    search: prev => ({
                      ...prev,
                      workspaceId: workspace.id,
                    }),
                  })
                }}
              />
            </div>
          </Card>
          <div className="p-2 text-center text-sm font-bold">OR</div>
        </>
      ) : null}
      <Card className="divide-y divide-gray-500/20 p-0">
        <div className="relative p-4 text-lg font-bold">
          Create a new workspace
        </div>
        <CreateWorkspaceForm />
      </Card>
    </div>
  )
}

function StepUpload() {
  // const projectOptionsQuery = useProjectOptionsQuery()
  const navigate = useNavigate()
  const workspaceId = useActiveWorkspaceId()

  // React.useEffect(() => {
  //   navigate({
  //     search: prev => ({
  //       ...prev,
  //       importer: undefined,
  //     }),
  //   })
  // }, [navigate])

  return (
    <div className="space-y-2">
      <div>
        <Link
          search={prev => ({
            ...prev,
            workspaceId: undefined,
          })}
        >
          <ButtonPlain className="inline-flex items-center gap-1 bg-white text-sm font-bold text-blue-500 shadow-md dark:bg-gray-800">
            <FaAngleLeft /> Back
          </ButtonPlain>
        </Link>
      </div>
      {(() => {
        return (
          <>
            <Card className="divide-y divide-gray-500/20 p-0">
              <div className="relative p-4 text-lg font-bold">
                Import SERPWoo Projects
              </div>
              <div className="p-2">
                <ImportSerpWooProjectsForm
                  workspaceId={workspaceId}
                  onImport={() => {
                    console.log('Done!')
                  }}
                />
              </div>
            </Card>
          </>
        )
      })()}
    </div>
  )
}

function StepDone() {
  return (
    <div className="space-y-2">
      <div>
        <Link
          search={prev => ({
            ...prev,
            projectId: undefined,
          })}
        >
          <ButtonPlain className="inline-flex items-center gap-1 bg-white text-sm font-bold text-blue-500 shadow-md dark:bg-gray-800">
            <FaAngleLeft /> Back
          </ButtonPlain>
        </Link>
      </div>
      <Card className="flex w-[320px] flex-col items-center justify-center gap-4 p-6">
        <div className="text-2xl font-bold">You're all set!</div>
        <Link to="/keyword-research/topic-clusters" search>
          <ButtonPlain className="bg-green-500">
            Go to Topic Clusters
          </ButtonPlain>
        </Link>
      </Card>
    </div>
  )
}

function CreateWorkspaceForm() {
  const createWorkspace = useCreateWorkspace({
    silent: true,
  })
  const createProject = useCreateProject({
    silent: true,
  })
  const search = useSearch()
  const navigate = useNavigate()
  const [, setShowOnboarding] = useLocalState('showOnboarding', false)

  const hubspotListIds = search.hubspotListId ? [search.hubspotListId] : [351]

  const createWorkspaceMutation = useMutation(
    async (values: { name: string; slug: string }) => {
      setShowOnboarding(true)

      const newWorkspace = await createWorkspace({
        name: values.name,
        slug: values.slug,
        hubspotListIds,
      })

      const newProject = await createProject({
        workspaceId: newWorkspace.id,
        name: newWorkspace.name,
        slug: newWorkspace.slug,
      })

      navigate({
        search: old => ({
          ...old,
          workspaceId: newWorkspace.id,
          projectId: newProject.id,
        }),
      })
    }
  )

  const form = useForm({
    onSubmit: values => {
      createWorkspaceMutation.mutate(values)
    },
    defaultValues: React.useMemo(
      () => ({
        name: '',
        slug: '',
      }),
      []
    ),
  })

  const name = form.useStore(store => store.values.name)

  const [isAutoSlug, setIsAutoSlug] = React.useState(true)
  const autoSlug = React.useMemo(() => formatSlug(name), [name])

  React.useEffect(() => {
    if (isAutoSlug) {
      form.setFieldValue('slug', autoSlug)
    }
  }, [isAutoSlug, autoSlug, form])

  return (
    <form.Form className="divide-y divide-gray-500/20">
      <div className="space-y-2 p-4">
        <Caption>
          <FaInfoCircle className="inline" /> This will typically be your
          company name. If you are an agency, use your agency's name. You will
          be able to create separate projects for each of your clients later.
        </Caption>
        <form.Field
          name="name"
          children={field => {
            return (
              <div>
                <LabeledInput
                  {...field.getInputProps()}
                  label="Company Name"
                  placeholder="My Company"
                  validate={Validate.required('A name is required.')}
                  className="w-80 flex-none"
                />
              </div>
            )
          }}
        />
        <form.Field
          name="slug"
          children={field => {
            return (
              <div className="space-y-2">
                <LabeledInput
                  {...field.getInputProps()}
                  label={`Slug${isAutoSlug ? ' (Auto Generated)' : ''}`}
                  placeholder="mycompany"
                  validate={Validate.workspaceSlug()}
                  disabled={isAutoSlug}
                  className="w-80 flex-none"
                />
                <Button
                  size="xs"
                  color="blue-800"
                  onClick={() =>
                    setIsAutoSlug(old => {
                      if (!old) {
                        form.setFieldValue('slug', '')
                      }
                      return !old
                    })
                  }
                >
                  {isAutoSlug ? 'Edit Slug' : 'Auto Generate Slug'}
                </Button>
                <Caption>
                  <IoIosWarning className="inline text-yellow-400" /> Workspace
                  slugs cannot be changed after creation.
                </Caption>
              </div>
            )
          }}
        />
      </div>
      <div className="p-4">
        <Button type="submit" size="base" color={'green-500'}>
          {createWorkspaceMutation.isLoading ? (
            <>
              <Spinner color="white" /> Creating Workspace
            </>
          ) : (
            <>
              <FaCheck /> Create Workspace
            </>
          )}
        </Button>
      </div>
    </form.Form>
  )
}

function CreateProjectForm() {
  const { workspaceId } = useSearch()
  const createProject = useCreateProject({
    // silent: true,
  })
  const createBrand = useCreateBrand({
    silent: true,
  })
  const navigate = useNavigate()

  if (!workspaceId) {
    throw new Error('workspaceId is required')
  }

  const createProjectMutation = useMutation(
    async (values: { name: string; slug: string; domain: string }) => {
      const brandInfo = await fetchBrandInfo(values.domain)

      const newProject = await createProject({
        workspaceId,
        name: values.name,
        slug: values.slug,
      })

      await createBrand({
        ...brandInfo,
        workspaceId,
        teamId: newProject.id,
        name: values.domain,
        domain: values.domain,
        type: BRAND_TYPE_OWNED,
        reputationImpact: 0,
      })

      navigate({
        search: old => ({
          ...old,
          projectId: newProject.id,
        }),
      })
    }
  )

  const form = useForm({
    onSubmit: values => {
      createProjectMutation.mutate(values)
    },
    defaultValues: React.useMemo(
      () => ({
        name: '',
        slug: '',
        domain: '',
      }),
      []
    ),
  })

  const domain = form.useStore(store => store.values.domain)

  const [isAutoName, setIsAutoName] = React.useState(true)
  const [isAutoSlug, setIsAutoSlug] = React.useState(true)
  const autoSlug = React.useMemo(() => formatSlug(domain), [domain])

  React.useEffect(() => {
    if (isAutoName) {
      form.setFieldValue('name', domain)
    }
  }, [isAutoName, domain, form])

  React.useEffect(() => {
    if (isAutoSlug) {
      form.setFieldValue('slug', autoSlug)
    }
  }, [isAutoSlug, autoSlug, form])

  return (
    <form.Form className="divide-y divide-gray-500/20">
      <div className="space-y-2 p-4">
        <form.Field
          name="domain"
          validate={validateDomain()}
          children={field => {
            return (
              <div>
                <LabeledInput
                  {...field.getInputProps()}
                  label="Domain"
                  placeholder="my-project.com"
                  className="w-80 flex-none"
                />
              </div>
            )
          }}
        />
        <Caption>
          <FaInfoCircle className="inline" /> You may add as many domains, URLs
          and competitors as you'd like later.
        </Caption>
        <form.Field
          name="name"
          validate={Validate.required('A name is required.')}
          children={field => {
            return (
              <div className="space-y-2">
                <LabeledInput
                  {...field.getInputProps()}
                  label={`Project Name${isAutoName ? ' (Auto Generated)' : ''}`}
                  placeholder="My Project"
                  className="w-80 flex-none"
                  disabled={isAutoName}
                />
                <Button
                  size="xs"
                  color={['gray-100', 'gray-700']}
                  onClick={() =>
                    setIsAutoName(old => {
                      if (!old) {
                        form.setFieldValue('name', '')
                      }
                      return !old
                    })
                  }
                >
                  {isAutoName ? 'Edit Project Name' : 'Auto Generate Name'}
                </Button>
              </div>
            )
          }}
        />
        <form.Field
          name="slug"
          validate={Validate.projectSlug()}
          children={field => {
            return (
              <div className="space-y-2">
                <LabeledInput
                  {...field.getInputProps()}
                  label={`Slug${isAutoSlug ? ' (Auto Generated)' : ''}`}
                  placeholder="myproject"
                  disabled={isAutoSlug}
                  className="w-80 flex-none"
                />
                <Button
                  size="xs"
                  color={['gray-100', 'gray-700']}
                  onClick={() =>
                    setIsAutoSlug(old => {
                      if (!old) {
                        form.setFieldValue('slug', '')
                      }
                      return !old
                    })
                  }
                >
                  {isAutoSlug ? 'Edit Slug' : 'Auto Generate Slug'}
                </Button>
                <Caption>
                  <IoIosWarning className="inline text-yellow-400" /> Project
                  slugs cannot be changed after creation.
                </Caption>
              </div>
            )
          }}
        />
      </div>
      <div className="p-4">
        <Button type="submit" size="base" color={'green-500'}>
          {createProjectMutation.isLoading ? (
            <>
              <Spinner color="white" /> Creating Project
            </>
          ) : (
            <>
              <FaCheck /> Create Project
            </>
          )}
        </Button>
      </div>
    </form.Form>
  )
}

const dailySchedule = scheduleOptions.find(d => d.label === 'Daily')!

function UploadKeywordsForm({
  workspaceId,
  projectId,
}: {
  workspaceId?: string
  projectId?: number
}) {
  const saveKeywordSourceV2 = useSaveKeywordSourceV2({
    silent: true,
    oneTime: true,
  })

  if (!workspaceId || !projectId) {
    throw new Error('workspaceId and projectId required!')
  }

  const saveKeywordsMutation = useMutation(
    async (values: {
      device: DevicePb
      keywords: string
      localeId: number
    }) => {
      const phraseGroups = phraseTextToPhraseGroups(values.keywords)

      const res = await saveKeywordSourceV2(
        new KeywordSourcePb({
          name: `Clustering Keywords (${new Date().toLocaleString()})`,
          workspaceId: BigInt(workspaceId),
          projectId: BigInt(projectId),
          schedules: [
            {
              rrule: dailySchedule.value,
            },
          ],
          config: {
            case: 'keywordSourceConfig',
            value: new KeywordSourceDataModelConfig2Pb({
              phraseTemplates: [
                {
                  phraseTemplate: '{{phrase}}',
                  // mostFrequentSchedule: {
                  //   rrule: dailySchedule.value,
                  // },
                },
              ],
              deviceModels: [
                {
                  device: values.device,
                  // mostFrequentSchedule: {
                  //   rrule: dailySchedule.value,
                  // },
                },
              ],
              localeModels: [
                {
                  localeId: BigInt(values.localeId),
                  // mostFrequentSchedule: {
                  //   rrule: dailySchedule.value,
                  // },
                },
              ],
              keywordSourceDataModel: {
                hierarchy: [
                  {
                    name: 'Phrase',
                    templateSlug: 'phrase',
                  },
                ],
                entries: phraseGroups.map(d => {
                  return {
                    name: d.phrase,
                    values: [
                      {
                        value: d.phrase,
                        keywordGroups: d.groups,
                      },
                    ],
                  }
                }),
              },
            }),
          },
        })
      )
    }
  )

  const form = useForm({
    onSubmit: values => saveKeywordsMutation.mutateAsync(values),
    defaultValues: React.useMemo(
      () => ({
        keywords: '',
        localeId: 44249,
        device: DevicePb.MOBILE_IOS,
      }),
      []
    ),
  })

  const keywordCount = form.useStore(
    d => phraseTextToPhraseGroups(d.values.keywords).length
  )

  const importableKeywordCount = Math.min(keywordCount, limit)

  return (
    <form.Form className="w-[500px] max-w-full divide-y divide-gray-500/20">
      <form.Field
        name="keywords"
        children={field => {
          return (
            <>
              <textarea
                value={field.getValue()}
                onChange={e => {
                  field.setValue(e.target.value)
                }}
                placeholder={[
                  `Enter your keywords...`,
                  'e.g.',
                  ...Array.from({ length: 3 }).map(
                    (d, i) => `Keyword ${i + 1}`
                  ),
                ].join(`\n`)}
                rows={12}
                className="block w-full bg-gray-50/50 p-2 text-sm dark:bg-gray-800"
                autoFocus
              />
              <div className="p-2 text-sm">
                <span className="font-bold">
                  {formatNumber(
                    phraseTextToPhraseGroups(field.getValue()).length
                  )}
                </span>{' '}
                Keywords
              </div>
            </>
          )
        }}
      />
      {keywordCount ? (
        <div className="flex items-center gap-2 p-2">
          <ButtonPlain type="submit" className="bg-green-500">
            {saveKeywordsMutation.isLoading ? (
              <>
                <Spinner color="white" /> Saving Keywords
              </>
            ) : (
              <>
                <FaCheck /> Save {formatNumber(importableKeywordCount)} Keywords
              </>
            )}
          </ButtonPlain>
          {keywordCount > importableKeywordCount ? (
            <>
              <IoWarning className="w-8 animate-pulse text-lg text-yellow-500" />
              <div className="text-sm italic">
                To upload more than <strong>2,500 keywords</strong> during a
                free trial, please{' '}
                <Clickable
                  onClick={openHubSpotChat}
                  className="font-black text-blue-500"
                >
                  contact us
                </Clickable>
                .
              </div>
            </>
          ) : null}
        </div>
      ) : null}
    </form.Form>
  )
}

function GoogleSearchConsoleImporter({
  workspaceId,
  projectId,
}: {
  workspaceId: number
  projectId: number
}) {
  const { googleSearchConsoleSiteUrl } = useSearch()
  const queryClient = useQueryClient()
  const [retryIndex, setRetry] = React.useState(0)
  const sitesQuery = useQuery({
    queryKey: ['googleSearchConsoleSites', retryIndex],
    queryFn: () => fetchSearchConsoleSites(),
  })

  const retry = React.useCallback(async () => {
    await revokeGapiToken()
    queryClient.removeQueries(['googleSearchConsoleSites'])
    setRetry(prev => prev + 1)
  }, [queryClient])

  React.useEffect(() => {
    retry()
  }, [retry])

  return (
    <>
      <QueryGate
        query={sitesQuery}
        className="p-2"
        loader={
          <div className="flex flex-col items-center gap-2 p-4">
            <div className="font-bold">Loading Sites</div>
            <Loader />
            <div className="text-xs font-bold italic opacity-50">
              (A Google login popup should appear shortly)
            </div>
            <ButtonPlain onClick={retry} className="mt-4 text-xs">
              {sitesQuery.isLoading ? 'Retry' : 'Reload Sites'}
            </ButtonPlain>
          </div>
        }
        children={
          <>
            {!sitesQuery.data?.length ? (
              <div className="flex flex-col items-center gap-4 p-4">
                <div className="text-sm font-bold italic opacity-50">
                  No sites were found.
                </div>
                <div className="flex gap-2">
                  <ButtonPlain onClick={retry} className="">
                    Switch Accounts
                  </ButtonPlain>
                  <Link
                    search={prev => ({
                      ...prev,
                      importer: undefined,
                    })}
                  >
                    <ButtonPlain className="bg-gray-200 dark:bg-gray-800">
                      Enter Keywords Manually
                    </ButtonPlain>
                  </Link>
                </div>
              </div>
            ) : googleSearchConsoleSiteUrl ? (
              <>
                <Link
                  search={prev => ({
                    ...prev,
                    googleSearchConsoleSiteUrl: undefined,
                  })}
                >
                  <ButtonPlain className="block w-full rounded-b-none bg-gray-100 p-2 dark:bg-gray-800">
                    Change Site
                  </ButtonPlain>
                </Link>
                <div className="p-2 font-bold">
                  {googleSearchConsoleSiteUrl?.replace('sc-domain:', '')}
                </div>
                <GoogleSearchConsoleKeywords
                  url={googleSearchConsoleSiteUrl}
                  workspaceId={workspaceId}
                  projectId={projectId}
                />
              </>
            ) : (
              <>
                {sitesQuery.data?.map(site => {
                  return (
                    <Link
                      key={site.siteUrl}
                      className="flex items-center justify-between gap-2 p-2"
                      search={prev => ({
                        ...prev,
                        googleSearchConsoleSiteUrl: site.siteUrl,
                      })}
                    >
                      <div>{site.siteUrl?.replace('sc-domain:', '')}</div>
                      <FaAngleRight />
                    </Link>
                  )
                })}{' '}
                <ButtonPlain
                  onClick={retry}
                  className="w-full rounded-t-none bg-gray-100 p-2 dark:bg-gray-800"
                >
                  Switch Accounts
                </ButtonPlain>
              </>
            )}
          </>
        }
      />
    </>
  )
}

function GoogleSearchConsoleKeywords({
  workspaceId,
  projectId,
  url,
}: {
  workspaceId: number
  projectId: number
  url: string
}) {
  const [retryIndex, setRetryIndex] = React.useState(0)

  const keywordsQuery = useQuery({
    queryKey: [queryKeyGooglePhrases, url, retryIndex],
    queryFn: () => fetchSearchConsoleKeywords({ url }),
    keepPreviousData: true,
    enabled: !!url,
  })

  const retry = () => {
    setRetryIndex(prev => prev + 1)
  }

  const importableKeywordCount = Math.min(
    keywordsQuery.data?.length ?? 0,
    limit
  )
  const saveKeywordSourceV2 = useSaveKeywordSourceV2({
    silent: true,
    oneTime: true,
  })

  const saveKeywordsMutation = useMutation(
    async ({ keywords }: { keywords?: GoogleSearchConsoleKeyword[] }) => {
      return saveKeywordSourceV2(
        new KeywordSourcePb({
          name: `GSC Import (${new Date().toLocaleString()})`,
          workspaceId: BigInt(workspaceId),
          projectId: BigInt(projectId),
          schedules: [
            {
              rrule: dailySchedule.value,
            },
          ],
          config: {
            case: 'keywordSourceConfig',
            value: new KeywordSourceDataModelConfig2Pb({
              phraseTemplates: [
                {
                  phraseTemplate: '{{phrase}}',
                  // mostFrequentSchedule: {
                  //   rrule: dailySchedule.value,
                  // },
                },
              ],
              deviceModels: [
                {
                  device: DevicePb.MOBILE_IOS,
                  // mostFrequentSchedule: {
                  //   rrule: dailySchedule.value,
                  // },
                },
              ],
              localeModels: [
                {
                  localeId: BigInt(44249),
                  // mostFrequentSchedule: {
                  //   rrule: dailySchedule.value,
                  // },
                },
              ],
              keywordSourceDataModel: {
                hierarchy: [
                  {
                    name: 'Phrase',
                    templateSlug: 'phrase',
                  },
                ],
                entries:
                  keywords?.slice(0, limit)?.map(d => {
                    return {
                      name: d.keys[0],
                      values: [
                        {
                          value: d.keys[0],
                          keywordGroups: [],
                        },
                      ],
                    }
                  }) ?? [],
              },
            }),
          },
        })
      )
    }
  )

  return (
    <>
      <QueryGate
        query={keywordsQuery}
        className="p-2"
        loader={
          <div className="flex flex-col items-center gap-2 p-4">
            <div className="font-bold">Loading Keywords</div>
            <Loader />
          </div>
        }
        children={
          <>
            <div className="space-y-2 p-2">
              <div>
                <span className="font-bold">
                  {formatNumber(keywordsQuery.data?.length ?? 0)}
                </span>{' '}
                keywords found over the last 90 days
              </div>
              <div className="flex items-center gap-2">
                {importableKeywordCount ? (
                  <ButtonPlain
                    className="bg-green-500"
                    onClick={() => {
                      saveKeywordsMutation.mutate({
                        keywords: keywordsQuery.data,
                      })
                    }}
                  >
                    Import {formatNumber(importableKeywordCount)} Keywords
                  </ButtonPlain>
                ) : null}
                <ButtonPlain
                  onClick={retry}
                  className="bg-gray-100 dark:bg-gray-800"
                >
                  {keywordsQuery.isFetching ? <Spinner /> : 'Refresh'}
                </ButtonPlain>
              </div>
              <div className="flex items-center gap-2">
                <IoWarning className="w-8 animate-pulse text-lg text-yellow-500" />
                <div className="text-sm italic">
                  To import more than <strong>2,500 keywords</strong> or more
                  than <strong>90 days</strong> of history during a free trial,
                  please{' '}
                  <Clickable
                    onClick={openHubSpotChat}
                    className="font-black text-blue-500"
                  >
                    contact us
                  </Clickable>
                  .
                </div>
              </div>
            </div>
            {!keywordsQuery.data?.length ? null : (
              <div className="max-h-[400px] divide-y divide-gray-50 overflow-y-auto dark:divide-gray-850">
                {keywordsQuery.data?.slice(0, 100).map((d, i) => {
                  return (
                    <div key={i} className="px-2 py-1 text-sm">
                      {d.keys?.[0]}
                    </div>
                  )
                })}
              </div>
            )}
          </>
        }
      />
      {keywordsQuery.isLoading ? (
        <ButtonPlain
          onClick={retry}
          className="block w-full rounded-t-none bg-gray-100 p-2 text-sm dark:bg-gray-800"
        >
          Retry
        </ButtonPlain>
      ) : null}
    </>
  )
}
