import * as React from 'react'
import * as Ariakit from '@ariakit/react'
import {
  motion,
  MotionProps,
  MotionConfig,
  AnimatePresence,
  Variants,
} from 'framer-motion'
import { twMerge } from 'tailwind-merge'
import Card from './Card'
import { withProps } from '../utils/withProps'
import { IndeterminateCheckbox } from './IndeterminateCheckbox'
import { PartialByKey } from '../utils/types'
//

const menuVariants = {
  closed: {
    scale: 0.5,
    opacity: 0,
    transition: {
      delay: 0.15,
    },
  },
  open: {
    scale: 1,
    opacity: 1,
    transition: {
      type: 'spring',
      duration: 0.2,
      delayChildren: 0.05,
      staggerChildren: 0.02,
    },
  },
} satisfies Variants

const itemMotionConfig = {
  variants: {
    closed: { x: -16, opacity: 0 },
    open: { x: 0, opacity: 1 },
  },
  transition: { opacity: { duration: 0.2 } },
} satisfies MotionProps

export interface MenuProps extends Ariakit.MenuButtonProps {
  button: React.ReactNode
  children?: React.ReactNode
}

const MotionCard = motion(Card)

export const Menu = React.forwardRef<HTMLDivElement, MenuProps>(function Menu(
  { button, children, ...props },
  ref
) {
  const menu = Ariakit.useMenuStore()
  const currentPlacement = menu.useState('currentPlacement')
  const mounted = menu.useState('mounted')
  return (
    <MotionConfig reducedMotion="user">
      <Ariakit.MenuButton store={menu} ref={ref} {...props}>
        {button}
        {/* <Ariakit.MenuButtonArrow /> */}
      </Ariakit.MenuButton>
      <AnimatePresence>
        {mounted && (
          <Ariakit.Menu
            store={menu}
            alwaysVisible
            // We'll use this data attribute to style the transform-origin
            // property based on the menu's placement. See style.css.
            data-placement={currentPlacement}
            render={
              <MotionCard
                animate={menu.getState().open ? 'open' : 'closed'}
                initial="closed"
                exit="closed"
                variants={menuVariants}
                className="z-[50] overflow-hidden p-1"
              />
            }
          >
            {/* <Ariakit.MenuArrow className={twMerge()} /> */}
            {children}
          </Ariakit.Menu>
        )}
      </AnimatePresence>
    </MotionConfig>
  )
})

export type MenuItemProps = React.ComponentPropsWithoutRef<
  typeof MotionMenuItem
>

// Instead of using the Ariakit `render` prop, we give control to Framer Motion
// so it can process the props before we pass the remainder to
// `Ariakit.MenuItem`.
const MotionMenuItemTemp = motion(Ariakit.MenuItem)
const MotionMenuItem = ({ render, ...props }: any) => (
  <MotionMenuItemTemp {...props} render_={render} />
)

const menuItemClasses = [
  'flex cursor-default items-center gap-2 rounded px-2 py-1 text-sm',
  'data-[active-item]:bg-blue-500 data-[active-item]:text-white',
]

export const MenuItem = React.forwardRef<HTMLDivElement, MenuItemProps>(
  function MenuItem(props, ref) {
    return (
      <MotionMenuItem
        ref={ref}
        {...itemMotionConfig}
        {...props}
        className={twMerge(...menuItemClasses, props.className)}
        render={props.render_}
      />
    ) as any
  }
)

export const MenuSeparator = withProps(Ariakit.MenuSeparator)(props => ({
  ...props,
  className: twMerge('my-1 h-px w-full bg-gray-500/20', props.className),
  render: <div />,
}))

const MotionMenuItemCheckbox = motion(Ariakit.MenuItemCheckbox)

export function MenuItemCheckbox({
  checked,
  indeterminate,
  className,
  name,
  children,
  disabled,
  ...props
}: Omit<
  PartialByKey<
    React.ComponentPropsWithoutRef<typeof MotionMenuItemCheckbox>,
    'name'
  >,
  'checked' | 'defaultChecked' | 'children'
> & {
  checked?: any
  indeterminate?: any
  children?: any
}) {
  return (
    <MotionMenuItemCheckbox
      {...itemMotionConfig}
      {...props}
      name={name as any}
      className={twMerge(...menuItemClasses, className)}
      checked={indeterminate ? 'mixed' : checked}
      render={<label />}
      disabled={disabled}
    >
      <IndeterminateCheckbox
        // {...props}
        checked={checked as any}
        indeterminate={indeterminate}
        onChange={() => {
          //
        }}
        className="pointer-events-none"
      />{' '}
      {children as any}
    </MotionMenuItemCheckbox>
  )
}

export function DropdownMenu() {
  return null
}

export function SubDropdownMenu() {
  return null
}
