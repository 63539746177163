import { Table as TTable } from '@tanstack/react-table'
import * as React from 'react'
import { useMutation, useQueryClient } from 'react-query'
import Table from '../../components/Table'
import { ClusterPb, MappedKeywordPb, TopicPb } from '../../utils/proto'
import { queryKeyClusters } from '../../utils/Constants'
import { Row } from '@tanstack/react-table'
import { ClusterDetails } from './ClusterDetails'
import { FaPlus } from 'react-icons/fa'
import { ButtonPlain } from '../../components/ButtonPlain'
import { formatNumber } from '../../utils/Format'
import { fetchCluster } from '../../utils/clusters'
import { uniqBy } from '../../utils'
import useModal from '../../hooks/useModal'
import { CreateTopicModal } from '../topics/Topic'
import Spinner from '../../components/Spinner'

export function ClustersTable(props: {
  table: TTable<ClusterPb>
  clusters: undefined | ClusterPb[]
  workspaceId: string
  projectId: string
}) {
  const showModal = useModal()
  const { table } = props
  const { workspaceId, projectId } = props

  const queryClient = useQueryClient()

  const rows = props.table.getRowModel().rows

  React.useEffect(() => {
    rows.forEach(row => {
      queryClient.setQueryData(
        [
          queryKeyClusters,
          Number(row.original.clusterId),
          { workspaceId, projectId, clusterId: Number(row.original.clusterId) },
        ],
        row.original,
        { updatedAt: 1 }
      )
    })
  }, [projectId, queryClient, rows, workspaceId])

  const selectedRows = table.getSelectedRowModel().rows

  const getUniqueKeywordIdsFromSelection = async () => {
    const fullClusters = await Promise.all(
      selectedRows.map(row => {
        return fetchCluster({
          workspaceId,
          projectId,
          clusterId: String(row.original.clusterId),
        })
      })
    )

    const keywordMetricsByCluster = fullClusters.map(d => d?.metricsByKeyword)

    return uniqBy(
      keywordMetricsByCluster.flat().map(d => String(d?.keyword?.keywordId))
    )
  }

  const createTopicMutation = useMutation(async () => {
    const uniqueKeywordIds = await getUniqueKeywordIdsFromSelection()

    const modal = showModal(() => (
      <CreateTopicModal
        defaultTopic={
          new TopicPb({
            name: selectedRows[0]?.original.name,
            mappedKeywords: uniqueKeywordIds.map(keywordId => {
              return new MappedKeywordPb({
                keywordId: BigInt(keywordId),
              })
            }),
          })
        }
        onSuccess={modal.close}
      />
    ))
  })

  const addToTopicMutation = useMutation(async () => {
    const uniqueKeywordIds = await getUniqueKeywordIdsFromSelection()

    const modal = showModal(() => (
      <CreateTopicModal
        defaultTab="existing"
        defaultTopic={
          new TopicPb({
            name: selectedRows[0]?.original.name,
            mappedKeywords: uniqueKeywordIds.map(keywordId => {
              return new MappedKeywordPb({
                keywordId: BigInt(keywordId),
              })
            }),
          })
        }
        onSuccess={modal.close}
      />
    ))
  })

  return (
    <Table
      table={props.table}
      beforeTable={
        selectedRows.length ? (
          <div className="flex items-center divide-x divide-gray-500/20">
            <div className="px-3 py-2">
              <strong>{formatNumber(selectedRows.length)}</strong> items
              selected
            </div>
            <div className="flex flex-wrap items-center gap-2 px-3 py-2">
              <ButtonPlain
                className="bg-gray-500/20 text-xs text-black hover:bg-gray-500/30 dark:text-white"
                onClick={() => createTopicMutation.mutate()}
              >
                {createTopicMutation.isLoading ? (
                  <>
                    <Spinner /> Preparing...
                  </>
                ) : (
                  <>
                    <FaPlus /> Create Topic
                  </>
                )}
              </ButtonPlain>
              <ButtonPlain
                className="bg-gray-500/20 text-xs text-black hover:bg-gray-500/30 dark:text-white"
                onClick={() => addToTopicMutation.mutate()}
              >
                {addToTopicMutation.isLoading ? (
                  <>
                    <Spinner /> Preparing...
                  </>
                ) : (
                  <>
                    <FaPlus /> Add to Topic
                  </>
                )}
              </ButtonPlain>
            </div>
          </div>
        ) : null
      }
    />
  )
}
export function ClusterTableDetails({
  table,
  row,
}: {
  table: TTable<ClusterPb>
  row: Row<ClusterPb>
}) {
  return (
    <div className="flex divide-x divide-gray-500/20">
      <ClusterDetails cluster={row.original} />
    </div>
  )
}
