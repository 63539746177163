import moment from 'moment'
import * as React from 'react'
import { FaAngleRight, FaCircle, FaInfoCircle } from 'react-icons/fa'
import { useQuery } from 'react-query'
import { Dashboard } from '.'
import Card from '../../components/Card'
import DashboardContainer from '../../components/DashboardContainer'
import DashboardContent from '../../components/DashboardContent'
import DashboardHeader from '../../components/DashboardHeader'
import KeywordGroupGate from '../../components/DashboardKeywordGroupGate'
import KeywordPicker from '../../components/DashboardKeywordPicker'
import DashboardRollupGate from '../../components/DashboardRollupGate'
import SegmentIdGate from '../../components/SegmentIdGate'
import SegmentPicker from '../../components/SegmentPicker'
import DashboardProjectGate from '../../components/DashboardTeamGate'
import DashboardTimeRange from '../../components/DashboardTimeRange'
import ErrorBoundary from '../../components/ErrorBoundary'
import Head from '../../components/Head'
import Loader from '../../components/Loader'
import NoData from '../../components/NoData'
import { PerformanceMetricsWidget } from '../../components/PerformanceMetricsWidget'
import RollupPicker, { useRollup } from '../../components/RollupPicker'
import { SerpHtml } from '../../components/SerpHtml'
import {
  SerpResultsTables,
  SerpWidgetState,
  resultColumnGroupOptions,
} from '../../components/SerpResultsTables'
import {
  DisplayMode,
  TimeSeriesWidget,
  TimeSeriesWidgetMode,
} from '../../components/TimeSeriesWidget'
import Tooltip from '../../components/Tooltip'
import useTimeRangeState from '../../hooks/useTimeRanges'
import useZustand from '../../hooks/useZustand'
import { useActiveWorkspaceId } from '../../hooks/workspaces'
import { Updater, functionalUpdate } from '../../utils'
import { fetchPulls } from '../../utils/Api'
import {
  DEFAULT_SAMPLES,
  KeywordGroupAllKeywords,
  UTC_FORMAT,
  performanceSingleKeywordSlug,
  queryKeyKeywordPulls,
} from '../../utils/Constants'
import {
  MetricId,
  MetricPermutation,
  MetricPostAggregation,
  metricPermutation,
} from '../../utils/Metrics'
import { allQueryFacets } from '../../utils/queryFacets'
import { ProjectPicker } from '../../components/ProjectPicker'
import {
  useKeywordIdState,
  useMetricIdState,
  useSegmentIdState,
  useSyncMetricIdState,
} from '../../utils/searchParams'
import { useSearchState } from '../../components/useSearchState'
import useWidgetPaginationReset from '../../hooks/useWidgetPaginationReset'
import useSyncMetricsState from '../../hooks/useSyncMetricsState'
import { TableDisplayMode } from '../../utils/tableDisplayMode'

//

const title = 'Performance - Single Keyword'

export const metricIds: MetricId[] = [
  // 'unique_keywords',
  'top_rank',
  'click_through_rate',
  'estimated_traffic',
  'top_pixels_from_top',
  'percentage_of_viewport',
  'percentage_of_dom',
  'results',
  'unique_urls',
  'ppc_value',
  'top_stat_base_rank',
]

export const performanceSingleKeywordDashboard: Dashboard = {
  name: 'Single Keyword',
  id: performanceSingleKeywordSlug,
  icon: <FaCircle className="inline-block scale-75 transform text-xs" />,
}

export default function PerformanceSingleKeyword() {
  const keywordIdState = useKeywordIdState()
  const [rollupBy, rollupValue] = useRollup()
  const rollupFacet = allQueryFacets.find(d => d.id === rollupBy)

  const keywordPickerReadyGate = !rollupBy
    ? 'Please select a rollup.'
    : !rollupValue
    ? `Please select at least one ${rollupFacet?.label}.  You can also change the Rollup to Domain and analyze data for any domain right now.`
    : null

  const dashboardReadyGate =
    keywordPickerReadyGate ||
    (!keywordIdState.state ? 'Please select a keyword.' : null)

  const [, setStore] = useZustand(state => state.helpUrl)
  const openDashDocs = () => {
    setStore(old => {
      old.showHelp = true
      old.helpUrl =
        'https://help.nozzle.io/performance-single-keyword-dashboard'

      return old
    })
  }

  return (
    <DashboardContainer>
      <Head>
        <title>{title} | Dashboards | Nozzle</title>
      </Head>
      <Card className="divide-y divide-gray-500/20 p-0">
        <div className="p-2">
          <DashboardHeader right={<DashboardTimeRange />}>
            <div className="flex flex-wrap items-center gap-2">
              <ProjectPicker />
              <RollupPicker allowDomainsAndUrls />
              <FaAngleRight className="inline" />
              <span>{title}</span>
              <button onClick={openDashDocs} type="button">
                <Tooltip tooltip="Click here to learn more about this dashboard">
                  <FaInfoCircle className="text-sm text-blue-500" />
                </Tooltip>
              </button>
            </div>
          </DashboardHeader>
        </div>
        <div className="flex flex-wrap gap-4 p-2">
          <div className="flex flex-[2] items-center gap-2">
            <KeywordPicker />
          </div>
          <div className="flex flex-1 items-center gap-2">
            <SegmentPicker />
          </div>
        </div>
      </Card>
      <DashboardProjectGate>
        <DashboardRollupGate>
          <SegmentIdGate>
            <KeywordGroupGate>
              <DashboardContent>
                {dashboardReadyGate ? (
                  <div className="col-span-full">
                    <Card>{dashboardReadyGate}</Card>
                  </div>
                ) : (
                  <>
                    <div className="col-span-full">
                      <ErrorBoundary>
                        <Metrics />
                      </ErrorBoundary>
                    </div>
                    <div className="col-span-full">
                      <ErrorBoundary>
                        <History />
                      </ErrorBoundary>
                    </div>
                    <div className="col-span-full">
                      <ErrorBoundary>
                        <SerpsWidget />
                      </ErrorBoundary>
                    </div>
                  </>
                )}
              </DashboardContent>
            </KeywordGroupGate>
          </SegmentIdGate>
        </DashboardRollupGate>
      </DashboardProjectGate>
    </DashboardContainer>
  )
}

export type PerformanceSingleKeywordMetricsWidget = {
  metricId: (typeof metricIds)[number]
  postAggregation: MetricPostAggregation
  samples: number
  limit: number | undefined
}

const useMetricsWidgetState = () => {
  const metricIdState = useMetricIdState(metricIds)

  const searchState = useSearchState<PerformanceSingleKeywordMetricsWidget>({
    path: 'performanceSingleKeywordMetricsWidget',
    useDefaultValue: () => {
      return {
        metricId: metricIdState.state!,
        samples: DEFAULT_SAMPLES,
        limit: 1,
        postAggregation: 'value',
      }
    },
  })

  useSyncMetricIdState(metricIds, searchState.state.metricId)

  return searchState
}

function Metrics() {
  const [rollupBy, rollupValue] = useRollup()
  const segmentIdState = useSegmentIdState()
  const keywordIdState = useKeywordIdState()

  const widgetState = useMetricsWidgetState()

  return (
    <PerformanceMetricsWidget
      {...{
        ...widgetState.state,
        isDirty: widgetState.isDirty,
        metricIds,
        summary: false,
        groupBy: 'keyword',
        rollupBy,
        rollupValues: [rollupValue],
        filters: {
          segmentIds: [segmentIdState.state],
          keywordGroups: [KeywordGroupAllKeywords],
          keywordIds: [keywordIdState.state],
        },
        setMetricsState: widgetState.setState as any,
        onReset: widgetState.reset,
      }}
    />
  )
}

export type PerformanceSingleKeywordHistoryWidget = {
  metricId: (typeof metricIds)[number]
  samples: number
  limit: number
  displayPostAggregation: MetricPostAggregation
  offset: number
  tableDisplayMode: TableDisplayMode
  sortByMetricPermutation: MetricPermutation<MetricId>
  desc: boolean
  expanded: boolean
  displayMode: DisplayMode
  widgetMode: TimeSeriesWidgetMode
  showLegends: boolean
}

const useHistoryWidgetState = () => {
  return useSearchState<PerformanceSingleKeywordHistoryWidget>({
    path: 'performanceSingleKeywordHistoryWidget',
    useDefaultValue: () => {
      const parentState = useMetricsWidgetState()

      return {
        metricId: parentState.state.metricId,
        samples: DEFAULT_SAMPLES,
        desc: true,
        limit: 1,
        offset: 0,
        displayPostAggregation: 'value',
        sortByMetricPermutation: metricPermutation(parentState.state.metricId),
        widgetMode: 'top',
        displayMode: 'summary',
        tableDisplayMode: 'summary',
        expanded: false,
        showLegends: false,
      }
    },
  })
}

function History() {
  const [rollupBy, rollupValue] = useRollup()
  const segmentIdState = useSegmentIdState()

  const metricsState = useMetricsWidgetState()
  const widgetState = useHistoryWidgetState()

  useWidgetPaginationReset(widgetState.state, widgetState.setState)
  useSyncMetricsState({
    parentState: metricsState.state,
    setState: widgetState.setState,
    syncMetric: true,
    syncSortByMetric: true,
    syncPostAggregations: false,
    syncSamples: true,
  })

  return (
    <TimeSeriesWidget
      {...{
        title: 'History',
        description: `The latest rollup data for a given metric over the selected time range.`,
        groupBy: 'keyword',
        rollupBy,
        rollupValues: [rollupValue],
        filters: {
          segmentIds: [segmentIdState.state],
          keywordGroups: [KeywordGroupAllKeywords],
          keywordIds: [useKeywordIdState().state],
        },
        metricIds,
        showWidgetMode: false,
        canTitleDrillThrough: false,
        canExpand: false,
        hidePagination: false,
        state: widgetState.state,
        setState: widgetState.setState,
        isDirty: widgetState.isDirty,
        onReset: widgetState.reset,
      }}
    />
  )
}

export type PerformanceSingleKeywordSerpWidget = SerpWidgetState

const useSerpWidgetState = () => {
  return useSearchState<PerformanceSingleKeywordSerpWidget>({
    path: 'performanceSingleKeywordSerpWidget',
    useDefaultValue: () => {
      return {
        isNozzleVisionEnabled: true,
        resultSearchTerm: '',
        rankingIds: [null],
        zoom: 100,
        sortById: 'result__paid_adjusted_rank',
        desc: true,
        offset: 0,
        limit: 20,
        resultColumnGroup: resultColumnGroupOptions[0]!.value,
        resultColumnIds: resultColumnGroupOptions[0]!.columns,
        serpResultColumnGroup: 'custom',
        serpResultColumnIds: [
          'result__rank',
          'result__measurements__pixels_from_top',
          'result__nozzle_metrics__estimated_traffic',
        ],
        expandItemResults: false,
      }
    },
  })
}

function SerpsWidget() {
  const activeWorkspaceId = useActiveWorkspaceId()
  const timeRangeState = useTimeRangeState()
  const keywordIdState = useKeywordIdState()
  const widgetState = useSerpWidgetState()

  const params = {
    workspaceId: activeWorkspaceId,
    keywordId: keywordIdState.state,
    start: moment.unix(timeRangeState.state[0]!.start).utc().format(UTC_FORMAT),
    end: moment
      .unix(timeRangeState.state[0]!.end)
      .utc()
      .endOf('day')
      .format(UTC_FORMAT),
  }

  const rankingsQuery = useQuery(
    [queryKeyKeywordPulls, params],
    () => fetchPulls(params),
    {
      staleTime: Infinity,
    }
  )

  const rankingOptions = React.useMemo(
    () =>
      rankingsQuery.data?.map(pull => ({
        label: moment.utc(pull.requested).format('lll'),
        value: String(pull.rankingId),
      })),
    [rankingsQuery.data]
  )

  const rankingIds = widgetState.state.rankingIds

  React.useEffect(() => {
    if (rankingIds === null && rankingOptions?.length) {
      widgetState.setState((old: any) => ({
        ...old,
        rankingIds: [rankingOptions?.[0]?.value].filter(Boolean),
      }))
    }
  }, [rankingIds, rankingOptions, widgetState])

  const setZoom = (zoom: Updater<number>) =>
    widgetState.setState((prev: any) => ({
      ...prev,
      zoom: functionalUpdate(zoom, prev.zoom),
    }))

  const setResultColumnGroup = (group: Updater<any>) =>
    widgetState.setState((old: any) => ({
      ...old,
      resultColumnGroup: group,

      resultColumnIds:
        resultColumnGroupOptions.find(d => d.value === group)?.columns ?? [],
    }))

  const setRankingIds = React.useCallback(
    (rankingIds: Updater<(string | null)[]>) =>
      widgetState.setState((prev: any) => ({
        ...prev,
        rankingIds: functionalUpdate(rankingIds, prev.rankingIds),
      })),
    [widgetState]
  )

  const setResultColumnIds = (resultColumnIds: Updater<string[]>) =>
    widgetState.setState((prev: any) => ({
      ...prev,
      resultColumnIds: functionalUpdate(resultColumnIds, prev.resultColumnIds),
    }))

  const setResultSearchTerm = (resultSearchTerm: Updater<string>) =>
    widgetState.setState((state: any) => ({
      ...state,

      resultSearchTerm: functionalUpdate(
        resultSearchTerm,
        state.resultSearchTerm
      ),
    }))

  const setExpandItemResults = (expandItemResults: Updater<boolean>) =>
    widgetState.setState((prev: any) => ({
      ...prev,

      expandItemResults: functionalUpdate(
        expandItemResults,
        prev.expandItemResults
      ),
    }))

  const setOffset = (offset: any) =>
    widgetState.setState((old: any) => ({
      ...old,
      offset: functionalUpdate(offset, old.offset),
    }))

  const setLimit = (limit: any) =>
    widgetState.setState((old: any) => ({
      ...old,
      limit: functionalUpdate(limit, old.limit),
    }))

  const setDesc = (desc: any) =>
    widgetState.setState((old: any) => ({
      ...old,
      desc: functionalUpdate(desc, old.desc),
    }))

  const setSortById = (sortById: any) =>
    widgetState.setState((old: any) => ({
      ...old,
      sortById: functionalUpdate(sortById, old.sortById),
    }))

  const setIsNozzleVisionEnabled = (isNozzleVisionEnabled: any) =>
    widgetState.setState((old: any) => ({
      ...old,

      isNozzleVisionEnabled: functionalUpdate(
        isNozzleVisionEnabled,
        old.isNozzleVisionEnabled
      ),
    }))

  const firstRankingOptionValue = rankingOptions?.[0]?.value

  React.useEffect(() => {
    if (
      !rankingIds?.length &&
      rankingOptions?.length &&
      firstRankingOptionValue
    ) {
      setRankingIds([firstRankingOptionValue])
    }
  }, [
    firstRankingOptionValue,
    rankingIds?.length,
    rankingOptions?.length,
    setRankingIds,
  ])

  React.useEffect(() => {
    if (
      rankingIds?.length &&
      firstRankingOptionValue &&
      rankingIds.some((id: any) => !rankingOptions.find(d => d.value === id))
    ) {
      setRankingIds([firstRankingOptionValue])
    }
  })

  return (
    <div className="space-y-2">
      <Card className="p-0">
        <SerpResultsTables
          {...widgetState.state}
          isLoading={rankingsQuery.isLoading}
          rankingOptions={rankingOptions}
          setDesc={setDesc}
          setExpandItemResults={setExpandItemResults}
          setLimit={setLimit}
          setOffset={setOffset}
          setRankingIds={setRankingIds}
          setResultColumnGroup={setResultColumnGroup}
          setResultColumnIds={setResultColumnIds}
          setResultSearchTerm={setResultSearchTerm}
          setSortById={setSortById}
        />
      </Card>
      <Card className="p-0">
        {rankingsQuery.isLoading ? (
          <div className="flex items-center justify-center p-4">
            <Loader className="text-2xl" />
          </div>
        ) : !rankingIds?.[0] ? (
          <div className="p-2">
            <NoData />
          </div>
        ) : (
          <SerpHtml
            {...widgetState.state}
            rankingOptions={rankingOptions}
            setIsNozzleVisionEnabled={setIsNozzleVisionEnabled}
            setRankingIds={setRankingIds}
            setResultColumnGroup={setResultColumnGroup}
            setResultColumnIds={setResultColumnIds}
            setResultSearchTerm={setResultSearchTerm}
            setZoom={setZoom}
          />
        )}
      </Card>
    </div>
  )
}
