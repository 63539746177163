import { PlainMessage } from '@bufbuild/protobuf'
import { FaQuestionCircle } from 'react-icons/fa'
import { formatNumber } from '../utils/Format'
import { GenerateKeywordsResponsePb } from '../utils/proto'
import Caption from './Caption'
import Loader from './Loader'
import Tooltip from './Tooltip'

export function TotalTrackedKeywords(props: {
  isFetching: boolean
  generatedKeywords?: PlainMessage<
    GenerateKeywordsResponsePb['keywords'][number]
  >[]
}) {
  return (
    <div className="flex flex-1 flex-wrap justify-between gap-2">
      <div className="text-xl font-bold">
        <span>Total Tracked Keywords:</span>{' '}
        <span>
          {props.isFetching ? (
            <Loader className="text-xs" />
          ) : (
            formatNumber(props.generatedKeywords?.length ?? 0)
          )}
        </span>
      </div>
      <Tooltip
        tooltip={
          <>
            The total tracked keywords table contains every unique keyword
            phrase + device + locale combination generated by your current
            keyword source settings.
          </>
        }
      >
        <Caption>
          <FaQuestionCircle className="inline" /> What are total tracked
          keywords?
        </Caption>
      </Tooltip>
    </div>
  )
}
