// @generated by protoc-gen-connect-web v0.11.0 with parameter "target=ts"
// @generated from file nozzle/billing/v1/subscription.proto (package nozzle.billing.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CancelSubscriptionRequest, CancelSubscriptionResponse, ChangeSubscriptionRequest, ChangeSubscriptionResponse, CreateSubscriptionRequest, CreateSubscriptionResponse, EstimateChangeSubscriptionRequest, EstimateChangeSubscriptionResponse, ModifyTrialRequest, ModifyTrialResponse } from "./subscription_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * SubscriptionsService provides APIs for interacting with billing subscriptions.
 *
 * @generated from service nozzle.billing.v1.SubscriptionsService
 */
export const SubscriptionsService = {
  typeName: "nozzle.billing.v1.SubscriptionsService",
  methods: {
    /**
     * CreateSubscription will change the active plan to a new one and/or set max overage amounts.
     *
     * @generated from rpc nozzle.billing.v1.SubscriptionsService.CreateSubscription
     */
    createSubscription: {
      name: "CreateSubscription",
      I: CreateSubscriptionRequest,
      O: CreateSubscriptionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ChangeSubscription will change the active plan to a new one and/or set max overage amounts.
     *
     * @generated from rpc nozzle.billing.v1.SubscriptionsService.ChangeSubscription
     */
    changeSubscription: {
      name: "ChangeSubscription",
      I: ChangeSubscriptionRequest,
      O: ChangeSubscriptionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * EstimateChangePlan will return the estimated cost of changing plans.
     *
     * @generated from rpc nozzle.billing.v1.SubscriptionsService.EstimateChangeSubscription
     */
    estimateChangeSubscription: {
      name: "EstimateChangeSubscription",
      I: EstimateChangeSubscriptionRequest,
      O: EstimateChangeSubscriptionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * CancelSubscription will cancel a given subscription.
     *
     * @generated from rpc nozzle.billing.v1.SubscriptionsService.CancelSubscription
     */
    cancelSubscription: {
      name: "CancelSubscription",
      I: CancelSubscriptionRequest,
      O: CancelSubscriptionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ModifyTrial will extend or modify a trial on a given subscription.
     *
     * @generated from rpc nozzle.billing.v1.SubscriptionsService.ModifyTrial
     */
    modifyTrial: {
      name: "ModifyTrial",
      I: ModifyTrialRequest,
      O: ModifyTrialResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

