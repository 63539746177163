import * as React from 'react'
import { twMerge } from 'tailwind-merge'
import { withProps } from '../utils/withProps'

export const ClickablePlain = withProps(
  ({ type = 'button', disableDefaultStyles, ...rest }) => (
    <button type={type} {...rest} />
  )
)(({ className, ...props }) => {
  return {
    ...props,
    className: twMerge(
      !props.disableDefaultStyles &&
        `
        inline-block cursor-pointer appearance-none border-0
        p-0
      `,
      !props.disabled && `cursor-pointer`,
      className
    ),
  }
})

export const Clickable = withProps(ClickablePlain)(
  ({ className, ...props }) => {
    return {
      ...props,
      className: twMerge(
        `text-blue-600
        dark:text-blue-400
      `,
        className
      ),
    }
  }
)

export default Clickable
