import { SearchIntentTypePb } from '../utils/proto'

export const searchIntentLabels = {
  [SearchIntentTypePb.UNSPECIFIED]: 'Unspecified',
  [SearchIntentTypePb.INFORMATIONAL]: 'Informational',
  [SearchIntentTypePb.NAVIGATIONAL]: 'Navigational',
  [SearchIntentTypePb.TRANSACTIONAL]: 'Transactional',
  [SearchIntentTypePb.COMMERCIAL]: 'Commercial',
  [SearchIntentTypePb.LOCAL]: 'Local',
}

export const searchIntentOptions = [
  {
    value: SearchIntentTypePb.UNSPECIFIED,
    label: searchIntentLabels[SearchIntentTypePb.UNSPECIFIED],
  },
  {
    value: SearchIntentTypePb.INFORMATIONAL,
    label: searchIntentLabels[SearchIntentTypePb.INFORMATIONAL],
  },
  {
    value: SearchIntentTypePb.NAVIGATIONAL,
    label: searchIntentLabels[SearchIntentTypePb.NAVIGATIONAL],
  },
  {
    value: SearchIntentTypePb.TRANSACTIONAL,
    label: searchIntentLabels[SearchIntentTypePb.TRANSACTIONAL],
  },
  {
    value: SearchIntentTypePb.COMMERCIAL,
    label: searchIntentLabels[SearchIntentTypePb.COMMERCIAL],
  },
  {
    value: SearchIntentTypePb.LOCAL,
    label: searchIntentLabels[SearchIntentTypePb.LOCAL],
  },
]

export function formatSearchIntent(value: SearchIntentTypePb) {
  return searchIntentLabels[value]
}
