import { css } from '@emotion/css'
import { twMerge } from 'tailwind-merge'
import { FaCircle } from 'react-icons/fa'
import { UseQueryResult } from 'react-query'
import { useThemeMode } from '../utils/Theme'
import { QueryFacetId, queryFacetsById } from '../utils/queryFacets'

export default function SeriesLegend({
  query,
  groupBy,
  onClickSerie,
  onClickDatum,
}: {
  query: UseQueryResult<any>
  groupBy: QueryFacetId
  onClickSerie?: (serie: any, allSeries: any[]) => void
  onClickDatum?: (datum: any) => void
}) {
  const { themeMode } = useThemeMode()

  return (
    <div className="break-all text-center text-xs ">
      {query.isError
        ? null
        : query.data?.series?.slice(0, 10)?.map((serie: any, index: number) => (
            <span
              className={twMerge(
                'inline-block px-1 py-0.5',
                onClickSerie &&
                  css`
                    cursor: pointer;
                    &.label {
                      opacity: 0.8;
                    }
                    :hover {
                      &.label {
                        opacity: 1;
                      }
                    }
                  `
              )}
              key={index}
              onClick={() => onClickSerie?.(serie, query.data?.series)}
            >
              {themeMode === 'dark' ? (
                <span
                  style={{
                    color: serie.color,
                  }}
                >
                  <FaCircle className="inline-block" />{' '}
                  <span className="label">
                    {queryFacetsById[groupBy].renderSeriesLabel(
                      serie,
                      query.data?.series,
                      {
                        short: true,
                      }
                    )}
                  </span>
                </span>
              ) : (
                <span>
                  <FaCircle
                    className="inline-block"
                    style={{ color: serie.color }}
                  />{' '}
                  <span className="label">
                    {queryFacetsById[groupBy].renderSeriesLabel(
                      serie,
                      query.data?.series,
                      {
                        short: true,
                      }
                    )}
                  </span>
                </span>
              )}
            </span>
          ))}
      {query.data?.series?.length > 10 ? (
        <div className="mt-2 block font-bold">
          + {query.data?.series.length - 10} more
        </div>
      ) : null}
    </div>
  )
}
