import { twMerge } from 'tailwind-merge'
import { withProps } from '../utils/withProps'

const separatorStyles = `
  block h-px bg-gray-200 dark:bg-gray-800
`

//

const Separator = withProps('div')(({ className, ...props }) => ({
  ...props,
  className: twMerge(separatorStyles, className),
}))

export default Separator
