import { DisplayColumnDef } from '@tanstack/react-table'
import * as React from 'react'
import { BsFillCaretRightFill } from 'react-icons/bs'
import { twMerge } from 'tailwind-merge'

export const createExpanderColumn = (opts?: {
  canExpandAll?: boolean
}): DisplayColumnDef<any, any> => ({
  id: 'expander',
  header: opts?.canExpandAll
    ? ({ table }) => (
        <label
          className="flex cursor-pointer items-center px-2 text-center"
          onClick={() => table.toggleAllRowsExpanded()}
        >
          <Expander expanded={table.getIsAllRowsExpanded()} />
        </label>
      )
    : undefined,
  cell: ({ row }) =>
    row.getCanExpand() ? (
      <label
        className="flex cursor-pointer items-center px-2 text-center"
        onClick={() => row.toggleExpanded()}
      >
        <Expander expanded={row.getIsExpanded()} />
      </label>
    ) : null,
  meta: {
    tight: true,
    getHeaderProps: () => ({
      className: 'p-0',
    }),
    getCellProps: () => ({
      className: 'p-0',
    }),
  },
})

export function Expander({
  expanded,
  className,
  ...rest
}: {
  expanded: boolean
} & React.HTMLProps<SVGElement>) {
  return (
    <BsFillCaretRightFill
      {...rest}
      className={twMerge('text-sm', expanded && 'rotate-90', className)}
    />
  )
}
