import * as React from 'react'
import { Updater } from '../utils'
import {
  MetricId,
  MetricPermutation,
  MetricPostAggregation,
  metricPermutation,
} from '../utils/Metrics'
import useOnChange from './useOnChange'

type SyncMetricsState = {
  metricId?: MetricId
  postAggregation?: MetricPostAggregation
  displayPostAggregation?: MetricPostAggregation
  samples?: number
  sortByMetricPermutation?: MetricPermutation<MetricId>
}

export default function useSyncMetricsState<
  TState extends SyncMetricsState
>(opts: {
  parentState: SyncMetricsState
  setState: (updater: Updater<TState>) => void
  syncMetric: boolean
  syncSortByMetric: boolean
  syncPostAggregations: boolean
  syncSamples: boolean
}) {
  const dataKeys: any = []

  if (opts.syncMetric) {
    dataKeys.push(opts.parentState.metricId)
  }

  if (opts.syncPostAggregations) {
    dataKeys.push(opts.parentState.postAggregation)
  }

  if (opts.syncSamples) {
    dataKeys.push(opts.parentState.samples)
  }

  useOnChange(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useMemo(() => dataKeys, dataKeys),
    () => {
      opts.setState(old => ({
        ...old,
        ...(opts.syncMetric
          ? {
              metricId: opts.parentState.metricId,
              ...(opts.syncSortByMetric && opts.parentState.metricId
                ? {
                    sortByMetricPermutation: metricPermutation(
                      opts.parentState.metricId,
                      {
                        postAggregation: opts.syncPostAggregations
                          ? opts.parentState.postAggregation
                          : old.sortByMetricPermutation?.postAggregation,
                      }
                    ),
                  }
                : {}),
            }
          : {}),
        ...(opts.syncPostAggregations
          ? {
              displayPostAggregation: opts.parentState.postAggregation,
            }
          : {}),
        ...(opts.syncSamples
          ? {
              samples: opts.parentState.samples,
            }
          : {}),
      }))
    },
    {
      onMount: false,
    }
  )
}
