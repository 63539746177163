import { dataColors } from '../utils/DataColors'

export const priorityOptions = [
  { value: 0, label: 'Unassigned', color: dataColors.gray },
  { value: 1, label: 'Critical', color: dataColors.red },
  { value: 2, label: 'High', color: dataColors.orange },
  { value: 3, label: 'Medium', color: dataColors.yellow },
  { value: 4, label: 'Low', color: dataColors.green },
  { value: 5, label: 'None', color: dataColors.blue },
]

export function formatPriority(priority: number) {
  return priorityOptions.find(option => option.value === priority)?.label
}
