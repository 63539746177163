import * as React from 'react'
import {
  useAutoParentUrlsMutation,
  useBatchRemoveMonitoredUrlsByIdMutation,
  useBatchUpdateMonitoredUrlsMutation,
  useMonitoredUrlsQuery,
} from '../../utils/monitoredUrls'
import {
  ListMonitoredUrlsFiltersPb,
  MonitoredUrlPb,
  MonitoredUrlViewOptionsPb,
  MonitoredUrlViewPb,
} from '../../utils/proto'
import Card from '../../components/Card'
import Link from '../../components/Link'
import { useSearch } from '@tanstack/react-router'

import { FaCaretDown, FaPlus, FaTrashAlt } from 'react-icons/fa'
import Tooltip from '../../components/Tooltip'
import moment from 'moment'
import {
  customerJourneyStageOptions,
  formatCustomerJourneyStage,
} from '../../options/customerJourneyStage'
import { formatPriority, priorityOptions } from '../../options/priority'
import {
  formatSearchIntent,
  searchIntentOptions,
} from '../../options/searchIntent'
import { ButtonPlain } from '../../components/ButtonPlain'
import { TbPackageImport, TbWand } from 'react-icons/tb'
import useConfirm from '../../hooks/useConfirm'
import useModal from '../../hooks/useModal'
import Table from '../../components/Table'
import { monitoredUrlFiltersList } from './MonitoredUrlsFilters'
import { filtersPbFromSearch } from '../../components/FacetFilters'
import { useTable } from '../../hooks/useTable'
import { createColumnHelper } from '@tanstack/react-table'
import { formatNumber } from '../../utils/Format'
import { useForm } from '@tanstack/react-form'
import { BrandPicker } from '../../components/BrandPicker'
import LabeledCheckbox from '../../components/LabeledCheckbox'
import { Modal, ModalTitle, ModalContent } from '../../components/Modals'
import Spinner from '../../components/Spinner'
import useModalContext from '../../hooks/useModalContext'
import { BRAND_TYPE_OWNED } from '../../utils/Constants'
import { MonitoredUrlPicker } from '../../components/MonitoredUrlPicker'
import Select from '../../components/Select'

export function MonitoredUrlsTable({
  workspaceId,
  projectId,
}: {
  workspaceId: string
  projectId: string
}) {
  const search = useSearch()
  const confirm = useConfirm()
  const showModal = useModal()

  const expandedMonitoredUrlIds = React.useMemo(
    () => search.expandedMonitoredUrlIds ?? {},
    [search.expandedMonitoredUrlIds]
  )

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<MonitoredUrlPb>()

    return [
      columnHelper.accessor(row => row.url?.url ?? '', {
        header: 'Monitored URL',
        cell: props => {
          const row = props.row.original
          const parentRow = props.row.getParentRow()?.original

          const shortUrl = parentRow
            ? row.url?.url?.replace(parentRow.url!.url, '')
            : row.url?.url
          return <Link to={`/monitored-urls/${row.id}`}>{shortUrl}</Link>
        },
        meta: {
          checkbox: true,
          expander: true,
        },
      }),
      columnHelper.accessor(row => formatPriority(row.priority), {
        header: 'Priority',
      }),
      columnHelper.accessor(
        row => formatCustomerJourneyStage(row.customerJourneyStage),
        {
          header: 'Customer Journey Stage',
        }
      ),
      columnHelper.accessor(
        row => formatSearchIntent(row.primarySearchIntent),
        {
          header: 'Primary Search Intent',
        }
      ),
      columnHelper.accessor(
        row => formatSearchIntent(row.secondarySearchIntent),
        {
          header: 'Secondary Search Intent',
        }
      ),
      columnHelper.accessor(row => String(row.id), {
        header: 'ID',
      }),
      columnHelper.accessor(row => moment(row.updatedAt?.toDate()), {
        header: 'Last Updated',
        cell: props => {
          const value = props.getValue()
          return (
            <Tooltip tooltip={value.format('lll')}>{value.fromNow()}</Tooltip>
          )
        },
      }),
      columnHelper.accessor(row => moment(row.createdAt?.toDate()), {
        header: 'Date Created',
        cell: props => {
          const value = props.getValue()
          return (
            <Tooltip tooltip={value.format('lll')}>{value.fromNow()}</Tooltip>
          )
        },
      }),
    ]
  }, [])

  const filtersPb = filtersPbFromSearch<
    Record<string, ListMonitoredUrlsFiltersPb[keyof ListMonitoredUrlsFiltersPb]>
  >(monitoredUrlFiltersList, search.monitoredUrlsFilters || {}, {
    searchKey: 'search',
  })

  const monitoredUrlsQuery = useMonitoredUrlsQuery({
    workspaceId,
    projectId,
    rootOnly: true,
    view: MonitoredUrlViewPb.NESTED,
    viewOptions: new MonitoredUrlViewOptionsPb({
      ancestorDepth: 0,
      descendentDepth: 1,
    }),
    filters: filtersPb,
  })

  // const fullMonitoredUrlsQuery = useQuery({
  //   queryKey: [
  //     MONITORED_URL_KEY,
  //     'fullUrls',
  //     {
  //       workspaceId,
  //       projectId,
  //       view: MonitoredUrlViewPb.NESTED,
  //       expandedMonitoredUrlIds,
  //       updatedAt: monitoredUrlsQuery.dataUpdatedAt,
  //     },
  //   ],
  //   keepPreviousData: true,
  //   queryFn: async () => {
  //     const recurseForData = async (
  //       items: MonitoredUrlPb[]
  //     ): Promise<MonitoredUrlPb[]> => {
  //       return Promise.all(
  //         items.map(async d => {
  //           const isExpanded = expandedMonitoredUrlIds[String(d.id)]

  //           if (!d.children?.length && isExpanded) {
  //             d = await fetchMonitoredUrlById({
  //               monitoredUrlId: String(d.id),
  //               projectId,
  //               workspaceId,
  //               view: MonitoredUrlViewPb.NESTED,
  //               viewOptions: new MonitoredUrlViewOptionsPb({
  //                 ancestorDepth: 0,
  //                 descendentDepth: 1,
  //               }),
  //             }).then(d => d.monitoredUrl!)
  //           }

  //           if (isExpanded) {
  //             d.children = await recurseForData(d.children)
  //           } else {
  //             d.children = []
  //           }

  //           return d
  //         })
  //       )

  //       return items
  //     }

  //     return await recurseForData(monitoredUrlsQuery.data?.monitoredUrls || [])
  //   },
  // })

  const tableData = React.useMemo(() => {
    return monitoredUrlsQuery.data?.monitoredUrls ?? []
  }, [monitoredUrlsQuery.data])

  const table = useTable({
    data: tableData,
    columns,
    getSubRows: d => d.children,
    selectChildrenMode: 'groupedRows',
    showFilters: false,
    showGroupBy: false,
    virtualize: true,
    manualSorting: true,
    manualFiltering: true,
    manualPagination: true,
    enableSortingRemoval: false,
    initialState: {
      sorting: [
        { id: 'type', desc: true },
        { id: 'name', desc: false },
      ],
    },
  })

  const selectedRows = table.getSelectedRowModel().flatRows

  const batchUpdateMonitoredUrlsMutation = useBatchUpdateMonitoredUrlsMutation()

  const batchUpdate = async <T extends keyof MonitoredUrlPb>(
    field: T,
    value: MonitoredUrlPb[T]
  ) => {
    await batchUpdateMonitoredUrlsMutation.mutateAsync({
      monitoredUrls: selectedRows.map(d => ({
        ...d.original,
        [field]: value,
      })),
    })
  }

  const batchRemoveMonitoredUrlsByIdMutation =
    useBatchRemoveMonitoredUrlsByIdMutation()

  const batchDelete = async () => {
    if (
      await confirm({
        message: `Are you sure you want to remove ${formatNumber(
          selectedRows.length
        )} monitored URLs?`,
      })
    ) {
      await batchRemoveMonitoredUrlsByIdMutation.mutateAsync({
        workspaceId: BigInt(workspaceId),
        monitoredUrlIds: selectedRows.map(d => d.original.id),
      })
      // table.resetRowSelection()
    }
  }

  const showAutoParentUrlsModal = () =>
    showModal(() => (
      <AutoParentUrlsModal workspaceId={workspaceId} projectId={projectId} />
    ))

  // const monitoredUrlQuery = useMonitoredUrlQuery({
  //   workspaceId,
  //   projectId,
  //   monitoredUrlId: String(monitoredUrl?.id),
  //   view: MonitoredUrlViewPb.BASIC,
  //   viewOptions: new MonitoredUrlViewOptionsPb({
  //     ancestorDepth: 0,
  //     descendentDepth: 1,
  //   }),
  //   enabled: Boolean(!monitoredUrl?.children?.length && expanded),
  // })

  // const isLoading = monitoredUrlQuery.isLoading

  // const children = React.useMemo(() => {
  //   return sortBy(
  //     monitoredUrlQuery.data?.monitoredUrl?.children ||
  //       monitoredUrl.children ||
  //       [],
  //     d => d.url?.url
  //   )
  // }, [monitoredUrl.children, monitoredUrlQuery.data?.monitoredUrl?.children])

  // React.useEffect(() => {
  //   if (monitoredUrlQuery.data) {
  //     const ancestorIds = monitoredUrlQuery.data.monitoredUrl?.ancestors.map(
  //       d => d.id
  //     )

  //     if (ancestorIds?.some(id => !expandedMonitoredUrlIds[String(id)])) {
  //       navigate({
  //         search: prev => ({
  //           ...prev,
  //           expandedMonitoredUrlIds: {
  //             ...(prev?.expandedMonitoredUrlIds ?? {}),
  //             ...Object.fromEntries(ancestorIds.map(id => [String(id), true])),
  //           },
  //         }),
  //       })
  //     }
  //   }
  // }, [expandedMonitoredUrlIds, monitoredUrlQuery.data, navigate])

  return (
    <Card className="flex min-h-0 flex-1 flex-col divide-y divide-gray-500/20 p-0">
      <div className="flex flex-wrap items-center gap-2 p-2">
        <Link to="/monitored-urls/new">
          <ButtonPlain className="bg-green-500">
            <FaPlus className="inline" /> Monitor URL
          </ButtonPlain>
        </Link>
        <Link to="/monitored-urls/import">
          <ButtonPlain className="bg-blue-500">
            <TbPackageImport className="inline" /> Import
          </ButtonPlain>
        </Link>
        <ButtonPlain
          className="bg-gray-500"
          onClick={() => showAutoParentUrlsModal()}
        >
          <TbWand /> Auto-Parent URLs
        </ButtonPlain>
      </div>
      <Table
        table={table}
        beforeTable={
          selectedRows.length ? (
            <div className="flex items-center divide-x divide-gray-500/20">
              <div className="px-3 py-2">
                <strong>{formatNumber(selectedRows.length)}</strong> items
                selected
              </div>
              <div className="flex flex-wrap items-center gap-2 px-3 py-2">
                <ButtonPlain
                  className="border border-red-500 bg-transparent text-xs text-red-500"
                  onClick={batchDelete}
                >
                  <FaTrashAlt /> Delete
                </ButtonPlain>
                <MonitoredUrlPicker
                  projectId={projectId}
                  onChange={async value => {
                    batchUpdate('parentMonitoredUrlId', BigInt(value))
                  }}
                  children={({ onClick }: any) => (
                    <ButtonPlain
                      className="bg-gray-500/20 text-xs text-black hover:bg-gray-500/30 dark:text-white"
                      onClick={onClick}
                    >
                      Parent URL <FaCaretDown />
                    </ButtonPlain>
                  )}
                />
                <Select
                  options={priorityOptions}
                  onChange={async value => {
                    batchUpdate('priority', value)
                  }}
                  children={({ onClick }) => (
                    <ButtonPlain
                      className="bg-gray-500/20 text-xs text-black hover:bg-gray-500/30 dark:text-white"
                      onClick={onClick}
                    >
                      Priority <FaCaretDown />
                    </ButtonPlain>
                  )}
                />
                <Select
                  options={customerJourneyStageOptions}
                  onChange={async value => {
                    batchUpdate('customerJourneyStage', value)
                  }}
                  children={({ onClick }) => (
                    <ButtonPlain
                      className="bg-gray-500/20 text-xs text-black hover:bg-gray-500/30 dark:text-white"
                      onClick={onClick}
                    >
                      Customer Journey <FaCaretDown />
                    </ButtonPlain>
                  )}
                />
                <Select
                  options={searchIntentOptions}
                  onChange={async value => {
                    batchUpdate('primarySearchIntent', value)
                  }}
                  children={({ onClick }) => (
                    <ButtonPlain
                      className="bg-gray-500/20 text-xs text-black hover:bg-gray-500/30 dark:text-white"
                      onClick={onClick}
                    >
                      Primary Search Intent <FaCaretDown />
                    </ButtonPlain>
                  )}
                />
                <Select
                  options={searchIntentOptions}
                  onChange={async value => {
                    batchUpdate('secondarySearchIntent', value)
                  }}
                  children={({ onClick }) => (
                    <ButtonPlain
                      className="bg-gray-500/20 text-xs text-black hover:bg-gray-500/30 dark:text-white"
                      onClick={onClick}
                    >
                      Secondary Search Intent <FaCaretDown />
                    </ButtonPlain>
                  )}
                />
              </div>
            </div>
          ) : null
        }
      />
      {/* <div className="flex-1 overflow-auto" ref={scrollRef}>
        <TableEl>
          <TableHead className="sticky top-0 z-10">
            <TableRow>
              {columns.map((column, i) => (
                <TableHeader
                  key={column.header}
                  className={twMerge('bg-gray-100 p-2 dark:bg-gray-800')}
                >
                  {column.header}
                </TableHeader>
              ))}
            </TableRow>
          </TableHead>
          <tbody>
            <tr
              style={{
                height: virtualizer.getVirtualItems()[0]?.start ?? 0,
              }}
            >
              <td className="p-0" />
            </tr>
            {virtualizer.getVirtualItems()?.map(item => {
              const monitoredUrl = flatRows[item.index]!

              const expandedKey = monitoredUrl
                ? String(monitoredUrl.id)
                : 'root'

              const expanded = expandedMonitoredUrlIds[expandedKey]

              return (
                <TableRow
                  key={item.index}
                  // data-index={item.index}
                  // ref={virtualizer.measureElement}
                  colorEvenOdd={false}
                  className={twMerge(
                    item.index % 2 === 0 ? '' : '!bg-gray-500/10'
                  )}
                >
                  {columns.map(column => {
                    const showSubRowCount =
                      column.expander && monitoredUrl.children?.length

                    const isAllChildrenSelected = false

                    if (showSubRowCount) {
                      monitoredUrl.children.forEach(d => {
                        if (selectedMonitoredUrlIds[d.id]) {
                          isAllChildrenSelected
                        }
                      })
                    }

                    return (
                      <TableCell
                        key={column.header}
                        className="p-0"
                        style={{
                          height: item.size,
                        }}
                      >
                        <div className={twMerge('flex items-center px-2')}>
                          {column.expander ? (
                            <button
                              onClick={() => {
                                navigate({
                                  search: prev => ({
                                    ...prev,
                                    expandedMonitoredUrlIds: {
                                      ...(prev?.expandedMonitoredUrlIds || {}),
                                      [expandedKey]: !expanded,
                                    },
                                  }),
                                  replace: true,
                                })
                              }}
                              className="pr-2"
                              style={{
                                marginLeft: monitoredUrl.depth * 1.2 + 'rem',
                              }}
                            >
                              <Expander
                                expanded={!!expanded}
                                className={twMerge('text-xs')}
                              />
                            </button>
                          ) : null}
                          {column.checkbox ? (
                            <input
                              type="checkbox"
                              // checked={selectedIds.has(String(monitoredUrl.id))}
                              className="mr-2"
                              onChange={e => {
                                // const checked = e.target.checked
                                // setTableState(prev => {
                                //   const next = new Set(prev.selectedIds)
                                //   if (checked) {
                                //     next.add(String(monitoredUrl.id))
                                //   } else {
                                //     next.delete(String(monitoredUrl.id))
                                //   }
                                //   return {
                                //     ...prev,
                                //     selectedIds: next,
                                //   }
                                // })
                              }}
                            />
                          ) : null}
                          {column.cell(monitoredUrl)}
                          {showSubRowCount ? (
                            <span className="ml-1 flex items-center text-sm">
                              <Tooltip tooltip="Toggle Children Selected">
                                <label
                                  className="flex cursor-pointer items-center gap-[2px]"
                                  onClick={() => {
                                    if (isAllChildrenSelected) {
                                      monitoredUrl.children.forEach(r => {
                                        toggleSelected(d.id, false)
                                      })
                                    } else {
                                      monitoredUrl.children.forEach(r => {
                                        toggleSelected(d.id, true)
                                      })
                                    }
                                  }}
                                >
                                  {isAllChildrenSelected ? (
                                    <FaCheckSquare className="text-blue-500" />
                                  ) : row.getIsSomeSelected() ? (
                                    <FaRegMinusSquare className="text-gray-500" />
                                  ) : (
                                    <FaRegSquare className="opacity-50" />
                                  )}
                                  <span className="text-xs">
                                    {monitoredUrl.children.length}
                                  </span>
                                </label>
                              </Tooltip>
                            </span>
                          ) : null}
                        </div>
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
            <tr
              className="p-0"
              style={{
                height:
                  virtualizer.getTotalSize() -
                  ((last(virtualizer.getVirtualItems())?.start ?? 0) +
                    (last(virtualizer.getVirtualItems())?.size ?? 0)),
              }}
            >
              <td />
            </tr>
          </tbody>
        </TableEl>
      </div> */}
      {/* <div className="flex-1 overflow-auto" ref={scrollRef}>
        <GridTable
          columnCount={columns.length}
          rowCount={flatRows.length}
          style={{
            gridAutoRows: 0,
            height: virtualizer.getTotalSize(),
          }}
        >
          {columns.map((column, i) => (
            <div
              key={column.header}
              className={twMerge(
                'h-[32px] whitespace-nowrap',
                'sticky top-0 z-10 flex items-end bg-white p-2 font-bold dark:bg-gray-800 dark:text-white',
                i !== columns.length - 1 && 'border-r border-gray-500/5'
              )}
              data-index={i}
            >
              {column.header}
            </div>
          ))}
          {virtualizer.getVirtualItems()?.map(item => {
            const monitoredUrl = flatRows[item.index]!

            const expandedKey = monitoredUrl ? String(monitoredUrl.id) : 'root'

            const expanded = expandedMonitoredUrlIds[expandedKey]

            return (
              <React.Fragment key={item.index}>
                {columns.map(column => {
                  return (
                    <div
                      key={column.header}
                      style={{
                        top: 0,
                        height: 0,
                        // overflow: 'hidden',
                      }}
                      // ref={}
                    >
                      <div
                        className={twMerge(
                          'flex items-center gap-1 whitespace-nowrap px-2 py-1',
                          item.index % 2 === 0 ? 'bg-gray-500/5' : ''
                        )}
                        style={{
                          transform: `translateY(${item.start}px)`,
                          height: item.size,
                        }}
                      >
                        {column.expander ? (
                          <button
                            onClick={() => {
                              navigate({
                                search: prev => ({
                                  ...prev,
                                  expandedMonitoredUrlIds: {
                                    ...(prev?.expandedMonitoredUrlIds || {}),
                                    [expandedKey]: !expanded,
                                  },
                                }),
                                replace: true,
                              })
                            }}
                            style={{
                              marginLeft: monitoredUrl.depth * 1.2 + 'rem',
                            }}
                          >
                            <Expander
                              expanded={!!expanded}
                              className={twMerge('text-xs')}
                            />
                          </button>
                        ) : null}
                        {column.checkbox ? (
                          <input
                            type="checkbox"
                            // checked={selectedIds.has(String(monitoredUrl.id))}
                            onChange={e => {
                              // const checked = e.target.checked
                              // setTableState(prev => {
                              //   const next = new Set(prev.selectedIds)
                              //   if (checked) {
                              //     next.add(String(monitoredUrl.id))
                              //   } else {
                              //     next.delete(String(monitoredUrl.id))
                              //   }
                              //   return {
                              //     ...prev,
                              //     selectedIds: next,
                              //   }
                              // })
                            }}
                          />
                        ) : null}
                        {column.cell(monitoredUrl)}
                      </div>
                    </div>
                  )
                })}
              </React.Fragment>
            )

            // return (
            //   <Item
            //     key={String(monitoredUrl.id)}
            //     workspaceId={workspaceId}
            //     projectId={projectId}
            //     monitoredUrl={monitoredUrl}
            //     parentMonitoredUrl={undefined}
            //     columns={columns}
            //     flatIndex={item.index}
            //     top={item.start}
            //     height={item.size}
            //     // measurer={virtualizer.measureElement}
            //     // initialExpanded={
            //     //   expandedMonitoredUrlIds[String(monitoredUrl.id)]
            //     // }
            //   />
            // )
          })}
        </GridTable>
      </div> */}
    </Card>
  )
}

function AutoParentUrlsModal({
  workspaceId,
  projectId,
}: {
  workspaceId: string
  projectId: string
}) {
  const modalContext = useModalContext()

  const autoParentUrlsMutation = useAutoParentUrlsMutation()

  const form = useForm({
    defaultValues: React.useMemo(
      () => ({
        workspaceId,
        projectId,
        brandId: '',
        createVirtualUrls: true,
        overwriteExistingParents: true,
      }),
      [projectId, workspaceId]
    ),
    onSubmit: values => {
      return autoParentUrlsMutation.mutate(
        {
          ...values,
          workspaceId,
          projectId,
        },
        {
          onSuccess: () => {
            modalContext.close()
          },
        }
      )
    },
  })

  return (
    <Modal className="w-[300px]">
      <ModalTitle>Auto-Parent URLs</ModalTitle>
      <ModalContent className="p-2">
        {/* Brand Picker */}
        <form.Form className="space-y-2">
          <form.Field
            name="brandId"
            children={field => {
              return (
                <BrandPicker
                  label="Brand*"
                  placeholder="Select a Brand..."
                  value={field.state.value}
                  onChange={next => field.setValue(next)}
                  filterOptions={options => {
                    return options.filter(
                      option => option.brand.type === BRAND_TYPE_OWNED
                    )
                  }}
                  closeOnSelect
                  className="flex-1"
                />
              )
            }}
          />
          <form.Field
            name="createVirtualUrls"
            children={field => {
              return (
                <LabeledCheckbox
                  label="Create Virtual URLs"
                  checked={field.state.value as any}
                  onChange={field.setValue}
                />
              )
            }}
          />

          <form.Field
            name="overwriteExistingParents"
            children={field => {
              return (
                <LabeledCheckbox
                  label="Overwrite Existing Parents"
                  checked={field.state.value as any}
                  onChange={field.setValue}
                />
              )
            }}
          />

          <div className="flex flex-wrap items-center gap-2">
            <ButtonPlain
              type="submit"
              className="bg-green-500"
              disabled={autoParentUrlsMutation.isLoading}
            >
              {autoParentUrlsMutation.isLoading ? (
                <>
                  <Spinner /> Auto-Parenting URLs...
                </>
              ) : (
                <>
                  <TbWand /> Auto-Parent URLs
                </>
              )}
            </ButtonPlain>
            <ButtonPlain
              className="bg-gray-500 hover:bg-red-500"
              onClick={() => modalContext.close()}
            >
              Cancel
            </ButtonPlain>
          </div>
        </form.Form>
      </ModalContent>
    </Modal>
  )
}
