// @generated by protoc-gen-connect-web v0.11.0 with parameter "target=ts"
// @generated from file nozzle/urls/v1/page.proto (package nozzle.urls.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { BatchGetPageRequest, BatchGetPageResponse, BatchRequestPageRequest, BatchRequestPageResponse, GetPageRequest, GetPageResponse, RequestPageRequest, RequestPageResponse } from "./page_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * EnrichmentService provides APIs for enriching url data.
 *
 * @generated from service nozzle.urls.v1.PageService
 */
export const PageService = {
  typeName: "nozzle.urls.v1.PageService",
  methods: {
    /**
     * RequestPage requests a page to be enriched.
     *
     * @generated from rpc nozzle.urls.v1.PageService.RequestPage
     */
    requestPage: {
      name: "RequestPage",
      I: RequestPageRequest,
      O: RequestPageResponse,
      kind: MethodKind.Unary,
    },
    /**
     * BatchRequestPage requests a batch of pages.
     *
     * @generated from rpc nozzle.urls.v1.PageService.BatchRequestPage
     */
    batchRequestPage: {
      name: "BatchRequestPage",
      I: BatchRequestPageRequest,
      O: BatchRequestPageResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetPage gets an enriched url. This may return an error if the url has not been enriched yet.
     *
     * @generated from rpc nozzle.urls.v1.PageService.GetPage
     */
    getPage: {
      name: "GetPage",
      I: GetPageRequest,
      O: GetPageResponse,
      kind: MethodKind.Unary,
    },
    /**
     * BatchGetPage gets a batch of enriched urls. This may return an error if the url has not been enriched yet.
     *
     * @generated from rpc nozzle.urls.v1.PageService.BatchGetPage
     */
    batchGetPage: {
      name: "BatchGetPage",
      I: BatchGetPageRequest,
      O: BatchGetPageResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

