import { twMerge } from 'tailwind-merge'
import { withProps } from '../utils/withProps'

//

const DashboardContent = withProps('div')<{ maxCols?: number }>(
  ({ className, ...props }) => ({
    ...props,
    className: twMerge(
      `
    grid
    grid-cols-1 gap-2
    pb-[15rem]
    md:grid-cols-2
    xl:grid-cols-3
  `,
      className
    ),
  })
)

export default DashboardContent
