import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import * as React from 'react'
import { FaCircle } from 'react-icons/fa'
import { MdOpenInNew } from 'react-icons/md'
import { twMerge } from 'tailwind-merge'
import { getDataValue, last } from '.'
import Anchor from '../components/Anchor'
import MetricInfo from '../components/MetricInfo'
import Tags from '../components/Tags'
import Tooltip from '../components/Tooltip'
import {
  formatChange,
  formatKeywordV1,
  formatNumber,
  renderLimitedText,
} from './Format'
import {
  Metric,
  MetricId,
  MetricPermutation,
  getMetricChangeFormatter,
  getMetricRenderer,
  metricPermutation,
  metricsById,
  renderMetricDrillthrough,
} from './Metrics'
import { queryFacetsById } from './queryFacets'

type ReportingKeyword = {
  keyword_id: string
  keyword_groups: string[]
}

type KeywordGroupSeries = {
  keyword: ReportingKeyword
  color: string
  data: []
}

const columnHelper = createColumnHelper<KeywordGroupSeries>()

export const makeKeywordTableColumns = ({
  metricIds,
  sortByMetricPermutation,
  isKeywordTable,
  renderSeriesLabel,
}: {
  metricIds: MetricId[]
  sortByMetricPermutation: MetricPermutation<MetricId> | undefined
  isKeywordTable: boolean
  renderSeriesLabel?: (props: { serie: KeywordGroupSeries }) => any
}): ColumnDef<KeywordGroupSeries, any>[] => {
  const getIsActive = (metricId: Metric['id'], isChange: boolean) => {
    return (
      sortByMetricPermutation!.id === metricId &&
      (isChange
        ? sortByMetricPermutation!.postAggregation === 'change'
        : sortByMetricPermutation!.postAggregation === 'value')
    )
  }

  const metricColumn = (metricId: Metric['id'], isChange: any) => {
    return {
      getHeaderProps: ({ className }: any) => ({
        className: twMerge(
          getIsActive(metricId, isChange) && 'font-bold',
          className
        ),
      }),
      getCellProps: ({ className }: any) => ({
        className: twMerge(
          getIsActive(metricId, isChange) && 'font-bold',
          className
        ),
      }),
    }
  }

  const getActiveMetricStyles = (metricId: Metric['id'], isChange: boolean) => {
    return getIsActive(metricId, isChange)
      ? {
          fontWeight: 'bold',
        }
      : {}
  }

  const queryFacet = queryFacetsById['keyword']

  return [
    columnHelper.display({
      id: 'circle',
      cell: props => (
        <Tooltip tooltip={formatKeywordV1(props.row.original.keyword)}>
          <FaCircle
            style={{
              color: props.row.original.color,
            }}
          />
        </Tooltip>
      ),
      meta: {
        getHeaderProps: () => ({
          className: `sticky left-0 z-10`,
        }),
        getCellProps: () => ({
          className: `sticky left-0 z-10`,
        }),
      },
    }),
    // @ts-expect-error  // Argument of type '"keyword.phrase"' is not assigna... Remove this comment to see the full error message
    columnHelper.accessor('keyword.phrase', {
      id: 'phrase',
      header: 'Keyword',
      enableSorting: false,
      cell: props => {
        const value = queryFacet.renderSeriesLabel(
          props.row.original as any,
          props.table.options.data as any,
          {
            short: true,
          }
        )

        if (renderSeriesLabel) {
          return React.cloneElement(renderSeriesLabel(props.row.original), {
            children: value,
          })
        }

        return value
      },
    }),
    columnHelper.accessor(
      d =>
        Number(
          // @ts-expect-error  // Property 'adwords_search_volume' does not exist on... Remove this comment to see the full error message
          d.data[d.data.length - 1]?.adwords_search_volume?.agg_sum?.total.value
        ),
      {
        id: 'adwords_search_volume',
        header: () => (
          <MetricInfo
            metricId={'adwords_search_volume'}
            isKeywordsTable={isKeywordTable}
          >
            Search Volume
          </MetricInfo>
        ),
        cell: props => (
          <div
            style={{
              ...getActiveMetricStyles('adwords_search_volume', false),
            }}
            className="text-right"
          >
            {formatNumber(props.getValue(), { short: false })}
          </div>
        ),
        ...metricColumn('adwords_search_volume', false),
      }
    ),
    columnHelper.accessor(
      d =>
        Number(
          // @ts-expect-error  // Property 'adwords_search_volume' does not exist on... Remove this comment to see the full error message
          d.data[d.data.length - 1]?.adwords_search_volume?.agg_sum?.total
            .change
        ),
      {
        id: `adwords_search_volumeChange`,
        header: '+/-',
        cell: props => (
          <div
            style={{
              ...getActiveMetricStyles('adwords_search_volume', true),
            }}
            className="text-right"
          >
            {formatChange(props.getValue())}
          </div>
        ),
        ...metricColumn('adwords_search_volume', true),
      }
    ),
    columnHelper.accessor(
      d => last(d.data as any[])?.top_ranking_results?.[0],
      {
        id: 'top_ranking_result_url',
        enableSorting: false,
        header: 'Top URL',
        cell: props => {
          const { result__url__url, result__url__domain, result__title__text } =
            props.getValue() ?? {}

          return (
            <div className="flex items-center gap-1">
              <span>
                {renderLimitedText(
                  result__url__url ??
                    result__title__text ??
                    (result__url__domain
                      ? `${result__url__domain}...`
                      : undefined) ??
                    'N/A',
                  50,
                  {
                    // fromPercentage: .5,
                  }
                )}
              </span>
              {result__url__url ? (
                <Anchor
                  href={result__url__url}
                  target="_blank"
                  rel="noreferrer"
                  onClick={e => {
                    e.stopPropagation()
                  }}
                >
                  <MdOpenInNew />
                </Anchor>
              ) : null}
            </div>
          )
        },
      }
    ),
    ...metricIds
      .filter(
        id => id !== 'top_ranking_results' && id !== 'adwords_search_volume'
      )
      .map(metricId => {
        const metric = metricsById[metricId]

        return [
          columnHelper.accessor(
            row =>
              getDataValue(
                row.data[row.data.length - 1],
                metricPermutation(metricId, {
                  postAggregation: 'value',
                })
              ),
            {
              header: () => (
                <MetricInfo
                  metricId={metricId}
                  isKeywordsTable={isKeywordTable}
                >
                  {metric.label}
                </MetricInfo>
              ),
              id: metricId,
              cell: props => (
                <div
                  style={{
                    ...getActiveMetricStyles(metricId, false),
                  }}
                  className="text-right"
                >
                  {renderMetricDrillthrough(
                    metricId,
                    queryFacet,
                    // @ts-expect-error  // Argument of type 'KeywordGroupSeries' is not assig... Remove this comment to see the full error message
                    props.row.original,
                    props.getValue()
                  )(
                    getMetricRenderer(metricId)(props.getValue(), {
                      row: props.row.original,
                      precision: metricId === 'top_rank' ? 0 : undefined,
                    })
                  )}
                </div>
              ),
              ...metricColumn(metricId, false),
            }
          ),
          columnHelper.accessor(
            row =>
              getDataValue(
                row.data[row.data.length - 1],
                metricPermutation(metricId, {
                  postAggregation: 'change',
                })
              ),
            {
              id: `${metricId}Change`,
              header: '+/-',
              cell: props => (
                <div
                  style={{
                    ...getActiveMetricStyles(metricId, true),
                  }}
                  className="text-right"
                >
                  {getMetricChangeFormatter(metricId)(props.getValue(), {
                    precision: metricId === 'top_rank' ? 0 : undefined,
                  })}
                </div>
              ),
              ...metricColumn(metricId, true),
            }
          ),
        ]
      })
      .flat(),
    columnHelper.accessor(d => d.keyword?.keyword_groups || [], {
      id: 'keywordGroups',
      header: 'Keyword Groups',
      enableSorting: false,
      // @ts-expect-error  // Property 'value' does not exist on type 'CellConte... Remove this comment to see the full error message
      cell: ({ value }) => (
        <Tooltip
          tooltip={
            <div>
              <Tags compact tags={[...(value || [])].sort()} />
            </div>
          }
          getTooltipProps={() => ({ className: 'max-w-[vw95] w-[600px]' })}
        >
          {value?.length ? `${value.length} Keyword Groups` : '-'}
        </Tooltip>
      ),
    }),
    columnHelper.accessor(d => d.keyword?.keyword_id, {
      id: 'keywordId',
      header: 'Keyword ID',
      enableSorting: false,
    }),
  ]
}
