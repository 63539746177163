// @generated by protoc-gen-es v1.3.0 with parameter "target=ts"
// @generated from file nozzle/workspaces/v1/data_model.proto (package nozzle.workspaces.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Device } from "../../keywords/v1/keywords_pb.js";

/**
 * ListModelsRequest is the inbound message to retrieve a list of models.
 *
 * @generated from message nozzle.workspaces.v1.ListModelsRequest
 */
export class ListModelsRequest extends Message<ListModelsRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  constructor(data?: PartialMessage<ListModelsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.ListModelsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListModelsRequest {
    return new ListModelsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListModelsRequest {
    return new ListModelsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListModelsRequest {
    return new ListModelsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListModelsRequest | PlainMessage<ListModelsRequest> | undefined, b: ListModelsRequest | PlainMessage<ListModelsRequest> | undefined): boolean {
    return proto3.util.equals(ListModelsRequest, a, b);
  }
}

/**
 * ListModelsResponse is the outbound message to retrieve a list of models.
 *
 * @generated from message nozzle.workspaces.v1.ListModelsResponse
 */
export class ListModelsResponse extends Message<ListModelsResponse> {
  /**
   * @generated from field: repeated nozzle.workspaces.v1.DataModel models = 1;
   */
  models: DataModel[] = [];

  constructor(data?: PartialMessage<ListModelsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.ListModelsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "models", kind: "message", T: DataModel, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListModelsResponse {
    return new ListModelsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListModelsResponse {
    return new ListModelsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListModelsResponse {
    return new ListModelsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListModelsResponse | PlainMessage<ListModelsResponse> | undefined, b: ListModelsResponse | PlainMessage<ListModelsResponse> | undefined): boolean {
    return proto3.util.equals(ListModelsResponse, a, b);
  }
}

/**
 * CreateModelRequest is the inbound message to create a new model.
 *
 * @generated from message nozzle.workspaces.v1.CreateModelRequest
 */
export class CreateModelRequest extends Message<CreateModelRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: nozzle.workspaces.v1.DataModel data_model = 2;
   */
  dataModel?: DataModel;

  constructor(data?: PartialMessage<CreateModelRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.CreateModelRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "data_model", kind: "message", T: DataModel },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateModelRequest {
    return new CreateModelRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateModelRequest {
    return new CreateModelRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateModelRequest {
    return new CreateModelRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateModelRequest | PlainMessage<CreateModelRequest> | undefined, b: CreateModelRequest | PlainMessage<CreateModelRequest> | undefined): boolean {
    return proto3.util.equals(CreateModelRequest, a, b);
  }
}

/**
 * CreateModelResponse is the outbound message to create a new model.
 *
 * @generated from message nozzle.workspaces.v1.CreateModelResponse
 */
export class CreateModelResponse extends Message<CreateModelResponse> {
  /**
   * @generated from field: nozzle.workspaces.v1.DataModel data_model = 1;
   */
  dataModel?: DataModel;

  constructor(data?: PartialMessage<CreateModelResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.CreateModelResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "data_model", kind: "message", T: DataModel },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateModelResponse {
    return new CreateModelResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateModelResponse {
    return new CreateModelResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateModelResponse {
    return new CreateModelResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateModelResponse | PlainMessage<CreateModelResponse> | undefined, b: CreateModelResponse | PlainMessage<CreateModelResponse> | undefined): boolean {
    return proto3.util.equals(CreateModelResponse, a, b);
  }
}

/**
 * GetModelRequest is the inbound message to retrieve a model.
 *
 * @generated from message nozzle.workspaces.v1.GetModelRequest
 */
export class GetModelRequest extends Message<GetModelRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: int64 model_id = 2;
   */
  modelId = protoInt64.zero;

  constructor(data?: PartialMessage<GetModelRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.GetModelRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "model_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetModelRequest {
    return new GetModelRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetModelRequest {
    return new GetModelRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetModelRequest {
    return new GetModelRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetModelRequest | PlainMessage<GetModelRequest> | undefined, b: GetModelRequest | PlainMessage<GetModelRequest> | undefined): boolean {
    return proto3.util.equals(GetModelRequest, a, b);
  }
}

/**
 * GetModelResponse is the outbound message to retrieve a model.
 *
 * @generated from message nozzle.workspaces.v1.GetModelResponse
 */
export class GetModelResponse extends Message<GetModelResponse> {
  /**
   * @generated from field: nozzle.workspaces.v1.DataModel data_model = 1;
   */
  dataModel?: DataModel;

  constructor(data?: PartialMessage<GetModelResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.GetModelResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "data_model", kind: "message", T: DataModel },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetModelResponse {
    return new GetModelResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetModelResponse {
    return new GetModelResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetModelResponse {
    return new GetModelResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetModelResponse | PlainMessage<GetModelResponse> | undefined, b: GetModelResponse | PlainMessage<GetModelResponse> | undefined): boolean {
    return proto3.util.equals(GetModelResponse, a, b);
  }
}

/**
 * DataModel represents a structure of related data.
 *
 * @generated from message nozzle.workspaces.v1.DataModel
 */
export class DataModel extends Message<DataModel> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: int64 workspace_id = 2;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: int64 project_id = 3;
   */
  projectId = protoInt64.zero;

  /**
   * @generated from field: string name = 4;
   */
  name = "";

  /**
   * @generated from field: string template_slug = 5;
   */
  templateSlug = "";

  /**
   * @generated from field: repeated nozzle.workspaces.v1.DataModelValue values = 6;
   */
  values: DataModelValue[] = [];

  /**
   * @generated from field: repeated nozzle.workspaces.v1.DataModel children = 7;
   */
  children: DataModel[] = [];

  /**
   * @generated from field: int64 parent_id = 8;
   */
  parentId = protoInt64.zero;

  constructor(data?: PartialMessage<DataModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.DataModel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "project_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "template_slug", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "values", kind: "message", T: DataModelValue, repeated: true },
    { no: 7, name: "children", kind: "message", T: DataModel, repeated: true },
    { no: 8, name: "parent_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DataModel {
    return new DataModel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DataModel {
    return new DataModel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DataModel {
    return new DataModel().fromJsonString(jsonString, options);
  }

  static equals(a: DataModel | PlainMessage<DataModel> | undefined, b: DataModel | PlainMessage<DataModel> | undefined): boolean {
    return proto3.util.equals(DataModel, a, b);
  }
}

/**
 * DataModelValue holds the values applicable to a data model and what parent values they're allowed on.
 *
 * @generated from message nozzle.workspaces.v1.DataModelValue
 */
export class DataModelValue extends Message<DataModelValue> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: int64 data_model_id = 3;
   */
  dataModelId = protoInt64.zero;

  /**
   * @generated from field: repeated nozzle.workspaces.v1.DataModelValueValue values = 4;
   */
  values: DataModelValueValue[] = [];

  /**
   * @generated from field: repeated string parents = 5;
   */
  parents: string[] = [];

  constructor(data?: PartialMessage<DataModelValue>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.DataModelValue";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data_model_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "values", kind: "message", T: DataModelValueValue, repeated: true },
    { no: 5, name: "parents", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DataModelValue {
    return new DataModelValue().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DataModelValue {
    return new DataModelValue().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DataModelValue {
    return new DataModelValue().fromJsonString(jsonString, options);
  }

  static equals(a: DataModelValue | PlainMessage<DataModelValue> | undefined, b: DataModelValue | PlainMessage<DataModelValue> | undefined): boolean {
    return proto3.util.equals(DataModelValue, a, b);
  }
}

/**
 * DataModelValueValue holds the values applicable to a data model value.
 *
 * @generated from message nozzle.workspaces.v1.DataModelValueValue
 */
export class DataModelValueValue extends Message<DataModelValueValue> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: string value = 2;
   */
  value = "";

  constructor(data?: PartialMessage<DataModelValueValue>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.DataModelValueValue";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DataModelValueValue {
    return new DataModelValueValue().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DataModelValueValue {
    return new DataModelValueValue().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DataModelValueValue {
    return new DataModelValueValue().fromJsonString(jsonString, options);
  }

  static equals(a: DataModelValueValue | PlainMessage<DataModelValueValue> | undefined, b: DataModelValueValue | PlainMessage<DataModelValueValue> | undefined): boolean {
    return proto3.util.equals(DataModelValueValue, a, b);
  }
}

/**
 * KeywordSourceDataModel is a minified data model that works off of IDs and includes extra data like schedules.
 *
 * @generated from message nozzle.workspaces.v1.KeywordSourceDataModel
 */
export class KeywordSourceDataModel extends Message<KeywordSourceDataModel> {
  /**
   * @generated from field: int64 data_model_id = 1;
   */
  dataModelId = protoInt64.zero;

  /**
   * @generated from field: repeated nozzle.workspaces.v1.KeywordSourceDataModelValue values = 2;
   */
  values: KeywordSourceDataModelValue[] = [];

  /**
   * @generated from field: repeated nozzle.workspaces.v1.KeywordSourceDataModel children = 3;
   */
  children: KeywordSourceDataModel[] = [];

  /**
   * @generated from field: nozzle.workspaces.v1.Schedule most_frequent_schedule = 4;
   */
  mostFrequentSchedule?: Schedule;

  /**
   * @generated from field: nozzle.workspaces.v1.KeywordStats keyword_stats = 5;
   */
  keywordStats?: KeywordStats;

  constructor(data?: PartialMessage<KeywordSourceDataModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.KeywordSourceDataModel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "data_model_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "values", kind: "message", T: KeywordSourceDataModelValue, repeated: true },
    { no: 3, name: "children", kind: "message", T: KeywordSourceDataModel, repeated: true },
    { no: 4, name: "most_frequent_schedule", kind: "message", T: Schedule },
    { no: 5, name: "keyword_stats", kind: "message", T: KeywordStats },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): KeywordSourceDataModel {
    return new KeywordSourceDataModel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): KeywordSourceDataModel {
    return new KeywordSourceDataModel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): KeywordSourceDataModel {
    return new KeywordSourceDataModel().fromJsonString(jsonString, options);
  }

  static equals(a: KeywordSourceDataModel | PlainMessage<KeywordSourceDataModel> | undefined, b: KeywordSourceDataModel | PlainMessage<KeywordSourceDataModel> | undefined): boolean {
    return proto3.util.equals(KeywordSourceDataModel, a, b);
  }
}

/**
 * KeywordSourceDataModelValue is a minified data model value that works off of IDs and includes extra data like schedules.
 *
 * @generated from message nozzle.workspaces.v1.KeywordSourceDataModelValue
 */
export class KeywordSourceDataModelValue extends Message<KeywordSourceDataModelValue> {
  /**
   * @generated from field: int64 data_model_value_id = 1;
   */
  dataModelValueId = protoInt64.zero;

  /**
   * @generated from field: nozzle.workspaces.v1.Schedule most_frequent_schedule = 2;
   */
  mostFrequentSchedule?: Schedule;

  /**
   * @generated from field: repeated string keyword_groups = 3;
   */
  keywordGroups: string[] = [];

  /**
   * @generated from field: repeated nozzle.workspaces.v1.KeywordSourceDataModelValueValue values = 4;
   */
  values: KeywordSourceDataModelValueValue[] = [];

  /**
   * @generated from field: nozzle.workspaces.v1.KeywordStats keyword_stats = 5;
   */
  keywordStats?: KeywordStats;

  constructor(data?: PartialMessage<KeywordSourceDataModelValue>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.KeywordSourceDataModelValue";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "data_model_value_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "most_frequent_schedule", kind: "message", T: Schedule },
    { no: 3, name: "keyword_groups", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "values", kind: "message", T: KeywordSourceDataModelValueValue, repeated: true },
    { no: 5, name: "keyword_stats", kind: "message", T: KeywordStats },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): KeywordSourceDataModelValue {
    return new KeywordSourceDataModelValue().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): KeywordSourceDataModelValue {
    return new KeywordSourceDataModelValue().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): KeywordSourceDataModelValue {
    return new KeywordSourceDataModelValue().fromJsonString(jsonString, options);
  }

  static equals(a: KeywordSourceDataModelValue | PlainMessage<KeywordSourceDataModelValue> | undefined, b: KeywordSourceDataModelValue | PlainMessage<KeywordSourceDataModelValue> | undefined): boolean {
    return proto3.util.equals(KeywordSourceDataModelValue, a, b);
  }
}

/**
 * KeywordSourceDataModelValueValue is a minified data model value value that works off of IDs and includes extra data like schedules.
 *
 * @generated from message nozzle.workspaces.v1.KeywordSourceDataModelValueValue
 */
export class KeywordSourceDataModelValueValue extends Message<KeywordSourceDataModelValueValue> {
  /**
   * @generated from field: int64 data_model_value_value_id = 1;
   */
  dataModelValueValueId = protoInt64.zero;

  /**
   * @generated from field: nozzle.workspaces.v1.Schedule most_frequent_schedule = 2;
   */
  mostFrequentSchedule?: Schedule;

  /**
   * @generated from field: repeated string keyword_groups = 3;
   */
  keywordGroups: string[] = [];

  /**
   * @generated from field: nozzle.workspaces.v1.KeywordStats keyword_stats = 4;
   */
  keywordStats?: KeywordStats;

  constructor(data?: PartialMessage<KeywordSourceDataModelValueValue>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.KeywordSourceDataModelValueValue";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "data_model_value_value_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "most_frequent_schedule", kind: "message", T: Schedule },
    { no: 3, name: "keyword_groups", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "keyword_stats", kind: "message", T: KeywordStats },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): KeywordSourceDataModelValueValue {
    return new KeywordSourceDataModelValueValue().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): KeywordSourceDataModelValueValue {
    return new KeywordSourceDataModelValueValue().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): KeywordSourceDataModelValueValue {
    return new KeywordSourceDataModelValueValue().fromJsonString(jsonString, options);
  }

  static equals(a: KeywordSourceDataModelValueValue | PlainMessage<KeywordSourceDataModelValueValue> | undefined, b: KeywordSourceDataModelValueValue | PlainMessage<KeywordSourceDataModelValueValue> | undefined): boolean {
    return proto3.util.equals(KeywordSourceDataModelValueValue, a, b);
  }
}

/**
 * PhraseTemplate is a template for creating phrases.
 *
 * @generated from message nozzle.workspaces.v1.PhraseTemplate
 */
export class PhraseTemplate extends Message<PhraseTemplate> {
  /**
   * @generated from field: string phrase_template = 1;
   */
  phraseTemplate = "";

  /**
   * @generated from field: repeated nozzle.workspaces.v1.KeywordGroupTemplate keyword_group_templates = 2;
   */
  keywordGroupTemplates: KeywordGroupTemplate[] = [];

  /**
   * @generated from field: nozzle.workspaces.v1.Schedule most_frequent_schedule = 3;
   */
  mostFrequentSchedule?: Schedule;

  /**
   * @generated from field: nozzle.workspaces.v1.KeywordStats keyword_stats = 4;
   */
  keywordStats?: KeywordStats;

  constructor(data?: PartialMessage<PhraseTemplate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.PhraseTemplate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "phrase_template", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "keyword_group_templates", kind: "message", T: KeywordGroupTemplate, repeated: true },
    { no: 3, name: "most_frequent_schedule", kind: "message", T: Schedule },
    { no: 4, name: "keyword_stats", kind: "message", T: KeywordStats },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PhraseTemplate {
    return new PhraseTemplate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PhraseTemplate {
    return new PhraseTemplate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PhraseTemplate {
    return new PhraseTemplate().fromJsonString(jsonString, options);
  }

  static equals(a: PhraseTemplate | PlainMessage<PhraseTemplate> | undefined, b: PhraseTemplate | PlainMessage<PhraseTemplate> | undefined): boolean {
    return proto3.util.equals(PhraseTemplate, a, b);
  }
}

/**
 * KeywordGroupTemplate contains template strings for keyword groups.
 *
 * @generated from message nozzle.workspaces.v1.KeywordGroupTemplate
 */
export class KeywordGroupTemplate extends Message<KeywordGroupTemplate> {
  /**
   * @generated from field: string group_template = 1;
   */
  groupTemplate = "";

  constructor(data?: PartialMessage<KeywordGroupTemplate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.KeywordGroupTemplate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "group_template", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): KeywordGroupTemplate {
    return new KeywordGroupTemplate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): KeywordGroupTemplate {
    return new KeywordGroupTemplate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): KeywordGroupTemplate {
    return new KeywordGroupTemplate().fromJsonString(jsonString, options);
  }

  static equals(a: KeywordGroupTemplate | PlainMessage<KeywordGroupTemplate> | undefined, b: KeywordGroupTemplate | PlainMessage<KeywordGroupTemplate> | undefined): boolean {
    return proto3.util.equals(KeywordGroupTemplate, a, b);
  }
}

/**
 * LocaleModel contains a locale and various alias values for it.
 *
 * @generated from message nozzle.workspaces.v1.LocaleModel
 */
export class LocaleModel extends Message<LocaleModel> {
  /**
   * @generated from field: int64 locale_id = 1;
   */
  localeId = protoInt64.zero;

  /**
   * @generated from field: repeated nozzle.workspaces.v1.LocaleModelAlias aliases = 2;
   */
  aliases: LocaleModelAlias[] = [];

  /**
   * @generated from field: nozzle.workspaces.v1.Schedule most_frequent_schedule = 3;
   */
  mostFrequentSchedule?: Schedule;

  /**
   * @generated from field: repeated string keyword_groups = 4;
   */
  keywordGroups: string[] = [];

  /**
   * @generated from field: nozzle.workspaces.v1.KeywordStats keyword_stats = 5;
   */
  keywordStats?: KeywordStats;

  constructor(data?: PartialMessage<LocaleModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.LocaleModel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "locale_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "aliases", kind: "message", T: LocaleModelAlias, repeated: true },
    { no: 3, name: "most_frequent_schedule", kind: "message", T: Schedule },
    { no: 4, name: "keyword_groups", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "keyword_stats", kind: "message", T: KeywordStats },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LocaleModel {
    return new LocaleModel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LocaleModel {
    return new LocaleModel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LocaleModel {
    return new LocaleModel().fromJsonString(jsonString, options);
  }

  static equals(a: LocaleModel | PlainMessage<LocaleModel> | undefined, b: LocaleModel | PlainMessage<LocaleModel> | undefined): boolean {
    return proto3.util.equals(LocaleModel, a, b);
  }
}

/**
 * LocalModelAlias contains alias data for a local model.
 *
 * @generated from message nozzle.workspaces.v1.LocaleModelAlias
 */
export class LocaleModelAlias extends Message<LocaleModelAlias> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: nozzle.workspaces.v1.Schedule most_frequent_schedule = 2;
   */
  mostFrequentSchedule?: Schedule;

  /**
   * @generated from field: repeated string keyword_groups = 3;
   */
  keywordGroups: string[] = [];

  /**
   * @generated from field: nozzle.workspaces.v1.KeywordStats keyword_stats = 4;
   */
  keywordStats?: KeywordStats;

  constructor(data?: PartialMessage<LocaleModelAlias>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.LocaleModelAlias";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "most_frequent_schedule", kind: "message", T: Schedule },
    { no: 3, name: "keyword_groups", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "keyword_stats", kind: "message", T: KeywordStats },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LocaleModelAlias {
    return new LocaleModelAlias().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LocaleModelAlias {
    return new LocaleModelAlias().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LocaleModelAlias {
    return new LocaleModelAlias().fromJsonString(jsonString, options);
  }

  static equals(a: LocaleModelAlias | PlainMessage<LocaleModelAlias> | undefined, b: LocaleModelAlias | PlainMessage<LocaleModelAlias> | undefined): boolean {
    return proto3.util.equals(LocaleModelAlias, a, b);
  }
}

/**
 * DeviceModel contains device data.
 *
 * @generated from message nozzle.workspaces.v1.DeviceModel
 */
export class DeviceModel extends Message<DeviceModel> {
  /**
   * @generated from field: nozzle.keywords.v1.Device device = 1;
   */
  device = Device.UNSPECIFIED;

  /**
   * @generated from field: nozzle.workspaces.v1.Schedule most_frequent_schedule = 2;
   */
  mostFrequentSchedule?: Schedule;

  /**
   * @generated from field: nozzle.workspaces.v1.KeywordStats keyword_stats = 3;
   */
  keywordStats?: KeywordStats;

  constructor(data?: PartialMessage<DeviceModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.DeviceModel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "device", kind: "enum", T: proto3.getEnumType(Device) },
    { no: 2, name: "most_frequent_schedule", kind: "message", T: Schedule },
    { no: 3, name: "keyword_stats", kind: "message", T: KeywordStats },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeviceModel {
    return new DeviceModel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeviceModel {
    return new DeviceModel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeviceModel {
    return new DeviceModel().fromJsonString(jsonString, options);
  }

  static equals(a: DeviceModel | PlainMessage<DeviceModel> | undefined, b: DeviceModel | PlainMessage<DeviceModel> | undefined): boolean {
    return proto3.util.equals(DeviceModel, a, b);
  }
}

/**
 * Schedule contains a schedule name and rrule.
 *
 * @generated from message nozzle.workspaces.v1.Schedule
 */
export class Schedule extends Message<Schedule> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string rrule = 2;
   */
  rrule = "";

  /**
   * @generated from field: nozzle.workspaces.v1.KeywordStats keyword_stats = 3;
   */
  keywordStats?: KeywordStats;

  constructor(data?: PartialMessage<Schedule>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.Schedule";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "rrule", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "keyword_stats", kind: "message", T: KeywordStats },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Schedule {
    return new Schedule().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Schedule {
    return new Schedule().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Schedule {
    return new Schedule().fromJsonString(jsonString, options);
  }

  static equals(a: Schedule | PlainMessage<Schedule> | undefined, b: Schedule | PlainMessage<Schedule> | undefined): boolean {
    return proto3.util.equals(Schedule, a, b);
  }
}

/**
 * KeywordStats are the generation stats for a keyword.
 *
 * @generated from message nozzle.workspaces.v1.KeywordStats
 */
export class KeywordStats extends Message<KeywordStats> {
  /**
   * @generated from field: int32 keyword_count = 1;
   */
  keywordCount = 0;

  /**
   * @generated from field: int32 serp_count_30_days = 2;
   */
  serpCount30Days = 0;

  /**
   * @generated from field: int32 serp_count_365_days = 3;
   */
  serpCount365Days = 0;

  constructor(data?: PartialMessage<KeywordStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.KeywordStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "keyword_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "serp_count_30_days", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "serp_count_365_days", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): KeywordStats {
    return new KeywordStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): KeywordStats {
    return new KeywordStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): KeywordStats {
    return new KeywordStats().fromJsonString(jsonString, options);
  }

  static equals(a: KeywordStats | PlainMessage<KeywordStats> | undefined, b: KeywordStats | PlainMessage<KeywordStats> | undefined): boolean {
    return proto3.util.equals(KeywordStats, a, b);
  }
}

/**
 * DataModel2 represents a hierarchy and values of the data model.
 *
 * @generated from message nozzle.workspaces.v1.DataModel2
 */
export class DataModel2 extends Message<DataModel2> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: int64 workspace_id = 2;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: int64 project_id = 3;
   */
  projectId = protoInt64.zero;

  /**
   * @generated from field: int64 version_id = 4;
   */
  versionId = protoInt64.zero;

  /**
   * this defines each level of the hierarchy, e.g. make, model, year
   *
   * @generated from field: repeated nozzle.workspaces.v1.DataModelHierarchy hierarchy = 5;
   */
  hierarchy: DataModelHierarchy[] = [];

  /**
   * @generated from field: repeated nozzle.workspaces.v1.DataModelEntry entries = 6;
   */
  entries: DataModelEntry[] = [];

  constructor(data?: PartialMessage<DataModel2>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.DataModel2";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "project_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "version_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "hierarchy", kind: "message", T: DataModelHierarchy, repeated: true },
    { no: 6, name: "entries", kind: "message", T: DataModelEntry, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DataModel2 {
    return new DataModel2().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DataModel2 {
    return new DataModel2().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DataModel2 {
    return new DataModel2().fromJsonString(jsonString, options);
  }

  static equals(a: DataModel2 | PlainMessage<DataModel2> | undefined, b: DataModel2 | PlainMessage<DataModel2> | undefined): boolean {
    return proto3.util.equals(DataModel2, a, b);
  }
}

/**
 * DataModelHierarchy represents a single level of hierarchy in a data model.
 *
 * @generated from message nozzle.workspaces.v1.DataModelHierarchy
 */
export class DataModelHierarchy extends Message<DataModelHierarchy> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string template_slug = 2;
   */
  templateSlug = "";

  constructor(data?: PartialMessage<DataModelHierarchy>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.DataModelHierarchy";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "template_slug", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DataModelHierarchy {
    return new DataModelHierarchy().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DataModelHierarchy {
    return new DataModelHierarchy().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DataModelHierarchy {
    return new DataModelHierarchy().fromJsonString(jsonString, options);
  }

  static equals(a: DataModelHierarchy | PlainMessage<DataModelHierarchy> | undefined, b: DataModelHierarchy | PlainMessage<DataModelHierarchy> | undefined): boolean {
    return proto3.util.equals(DataModelHierarchy, a, b);
  }
}

/**
 * DataModelEntry represents a single entry in a data model hierarchy.
 *
 * @generated from message nozzle.workspaces.v1.DataModelEntry
 */
export class DataModelEntry extends Message<DataModelEntry> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * Normally, there will only be one value, but this does allow for
   *   nicknames or other variants, e.g.
   * - Chevrolet (primary entry)
   * - Chevy (alias)
   *
   * @generated from field: repeated nozzle.workspaces.v1.DataModelEntryValue values = 2;
   */
  values: DataModelEntryValue[] = [];

  /**
   * this represents the next level of the hierarchy. If this is empty, then
   * this is the end of the hierarchy. If the current value represents a car make,
   * then the children would be the models of that make.
   *
   * @generated from field: repeated nozzle.workspaces.v1.DataModelEntry children = 3;
   */
  children: DataModelEntry[] = [];

  constructor(data?: PartialMessage<DataModelEntry>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.DataModelEntry";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "values", kind: "message", T: DataModelEntryValue, repeated: true },
    { no: 3, name: "children", kind: "message", T: DataModelEntry, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DataModelEntry {
    return new DataModelEntry().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DataModelEntry {
    return new DataModelEntry().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DataModelEntry {
    return new DataModelEntry().fromJsonString(jsonString, options);
  }

  static equals(a: DataModelEntry | PlainMessage<DataModelEntry> | undefined, b: DataModelEntry | PlainMessage<DataModelEntry> | undefined): boolean {
    return proto3.util.equals(DataModelEntry, a, b);
  }
}

/**
 * DataModelEntryValue represents a single value in a data model entry.
 *
 * @generated from message nozzle.workspaces.v1.DataModelEntryValue
 */
export class DataModelEntryValue extends Message<DataModelEntryValue> {
  /**
   * @generated from field: string value = 1;
   */
  value = "";

  /**
   * these are only set when mapping into a keyword source
   *
   * @generated from field: nozzle.workspaces.v1.Schedule most_frequent_schedule = 2;
   */
  mostFrequentSchedule?: Schedule;

  /**
   * @generated from field: repeated string keyword_groups = 3;
   */
  keywordGroups: string[] = [];

  /**
   * @generated from field: nozzle.workspaces.v1.KeywordStats keyword_stats = 4;
   */
  keywordStats?: KeywordStats;

  constructor(data?: PartialMessage<DataModelEntryValue>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.DataModelEntryValue";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "most_frequent_schedule", kind: "message", T: Schedule },
    { no: 3, name: "keyword_groups", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "keyword_stats", kind: "message", T: KeywordStats },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DataModelEntryValue {
    return new DataModelEntryValue().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DataModelEntryValue {
    return new DataModelEntryValue().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DataModelEntryValue {
    return new DataModelEntryValue().fromJsonString(jsonString, options);
  }

  static equals(a: DataModelEntryValue | PlainMessage<DataModelEntryValue> | undefined, b: DataModelEntryValue | PlainMessage<DataModelEntryValue> | undefined): boolean {
    return proto3.util.equals(DataModelEntryValue, a, b);
  }
}

