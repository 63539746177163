import { createColumnHelper } from '@tanstack/react-table'
import { twMerge } from 'tailwind-merge'
import * as React from 'react'
import { useQuery } from 'react-query'
import Card from '../../../components/Card'
import Table from '../../../components/Table'
import { useTable } from '../../../hooks/useTable'
import {
  ClusterPb,
  ClusterUrlPb,
  EntityMetricsPb,
  EntityPb,
  Entity_TypePb,
  PageClient,
} from '../../../utils/proto'
import {
  formatNumber,
  formatPercentage,
  formatTitle,
} from '../../../utils/Format'
import { queryKeyPages } from '../../../utils/Constants'
import { sortBy, uniqBy } from '../../../utils'
import useLocalState from '../../../hooks/useLocalState'
import { useForm } from '@tanstack/react-form'
import { useNavigate, useSearch } from '@tanstack/react-router'

import LabeledInput from '../../../components/LabeledInput'
import { FaCaretDown, FaTimes } from 'react-icons/fa'
import { ButtonPlain } from '../../../components/ButtonPlain'
import Select from '../../../components/Select'
import { MdCompare } from 'react-icons/md'
import QueryGate from '../../../components/QueryGate'
import Loader from '../../../components/Loader'
import { extent } from 'd3-array'
import { scaleDiverging, scaleLinear } from 'd3-scale'
import Tooltip from '../../../components/Tooltip'
import Input from '../../../components/Input'
import Spinner from '../../../components/Spinner'
// import * as venn from '@upsetjs/venn.js'

const entityColumnHelper = createColumnHelper<EntityMetricsPb>()
const entityColumns = [
  entityColumnHelper.accessor('entity', {
    header: 'Entity',
    filterFn: 'fuzzy',
  }),
  entityColumnHelper.accessor(
    d => formatTitle(Entity_TypePb[d.type].toLowerCase()),
    {
      header: 'Type',
      filterFn: 'arrIncludesSome',
    }
  ),
  entityColumnHelper.accessor(d => d.salience?.avg?.value, {
    header: 'Avg Salience',
    filterFn: 'inNumberRange',
    cell: cellProps => {
      return formatNumber(cellProps.getValue()!, {
        precision: 3,
        forcePrecision: true,
      })
    },
    meta: {
      getCellProps: () => {
        return {
          className: 'text-right',
        }
      },
    },
  }),
  entityColumnHelper.group({
    header: 'Sentiment',
    columns: [
      entityColumnHelper.accessor(d => d.sentimentScore?.avg?.value, {
        header: 'Avg Score',
        filterFn: 'inNumberRange',
        cell: cellProps => {
          return formatNumber(cellProps.getValue(), {
            precision: 1,
            forcePrecision: true,
          })
        },
        meta: {
          getCellProps: () => {
            return {
              className: 'text-right',
            }
          },
        },
      }),
      entityColumnHelper.accessor(d => d.sentimentMagnitude?.avg?.value, {
        header: 'Avg Total Magnitude',
        filterFn: 'inNumberRange',
        cell: cellProps => {
          return formatNumber(cellProps.getValue(), {
            precision: 1,
            forcePrecision: true,
          })
        },
        meta: {
          getCellProps: () => {
            return {
              className: 'text-right',
            }
          },
        },
      }),
    ],
  }),
]

const comparisonColumnHelper = createColumnHelper<{
  name: string
  type: Entity_TypePb
  maxSalience?: number
  cluster?: EntityMetricsPb
  clusterUrls?: {
    clusterUrl: ClusterUrlPb
    entity?: EntityPb
  }[]
  url?: EntityPb
}>()

export function Entities(props: { cluster: ClusterPb; focusDomain?: string }) {
  const { url } = useSearch()
  const entities = props.cluster.metricsByEntity
  const navigate = useNavigate()

  const table = useTable({
    data: entities,
    columns: entityColumns,
    initialState: React.useMemo(() => {
      return {
        pagination: {
          pageSize: 50,
        },
        sorting: [
          {
            id: 'salience',
            desc: true,
          },
        ],
      }
    }, []),
  })

  const pageQuery = useQuery(
    [queryKeyPages, url],
    () =>
      PageClient.requestPage({
        url,
        includeEntities: true,
        waitForCompletion: true,
      }),
    {
      enabled: !!url,
      keepPreviousData: true,
    }
  )

  const joinedEntities = React.useMemo(() => {
    return sortBy(
      uniqBy(
        [
          ...entities.map(d => [d.entity, d.type] as const),
          ...(pageQuery.data?.page?.entities.map(
            d => [d.name, d.type] as const
          ) ?? []),
        ],
        d => d.join('')
      ).map(([name, type]) => {
        const cluster = entities.find(d => d.entity === name && d.type === type)
        const clusterUrls = props.cluster.clusterUrls.map(d => ({
          clusterUrl: d,
          entity: d.page?.entities.find(
            dd => dd.name === name && dd.type === type
          ),
        }))
        const url = pageQuery.data?.page?.entities.find(
          d => d.name === name && d.type === type
        )

        return {
          name,
          type,
          maxSalience:
            cluster?.salience?.avg?.value && url?.salience
              ? Math.max(cluster?.salience?.avg?.value, url?.salience)
              : cluster?.salience?.avg?.value ?? url?.salience ?? 0,
          cluster,
          clusterUrls,
          url,
        }
      }),
      d => -d.maxSalience
    )
  }, [entities, pageQuery.data])

  const [history, setHistory] = useLocalState<string[]>(
    'urlExplorerHistory',
    []
  )

  const urlOptions = React.useMemo(() => {
    return props.cluster.clusterUrls?.map(d => ({
      label: d.url?.url ?? '',
      value: d.url?.url ?? '',
    }))
  }, [props.cluster.clusterUrls])

  const historyOptions = React.useMemo(() => {
    return history.map(d => ({
      label: d,
      value: d,
    }))
  }, [history])

  const defaultValues = React.useMemo(
    () => ({
      url: url || '',
    }),
    [url]
  )

  const setUrl = React.useCallback(
    (url?: string) => {
      navigate({
        search: prev => ({
          ...prev,
          url,
        }),
      })

      if (url) {
        setHistory(prev => {
          return [url, ...prev.filter(d => d !== url)].slice(0, 500)
        })
      }

      // navigate({
      //   search: prev => ({
      //     ...prev,
      //     url,
      //   }),
      // })
    },
    [navigate, setHistory]
  )

  const form = useForm({
    defaultValues,
    onSubmit: values => {
      setUrl(values.url)
    },
  })

  const salienceExtent = extent(joinedEntities, d => d.maxSalience) as [
    number,
    number
  ]
  const salienceScale = scaleLinear(salienceExtent, [0, 100])
  salienceScale.clamp(true)
  const sentimentScale = scaleDiverging(
    [-1, 0, 1],
    ['#f44336', 'rgba(150, 150, 150, .5)', '#4caf50']
  )

  const split = false

  const comparisonColumns = React.useMemo(
    () => [
      comparisonColumnHelper.accessor('name', {
        header: 'Name',
        filterFn: 'fuzzy',
      }),
      comparisonColumnHelper.accessor(
        d => formatTitle(Entity_TypePb[d.type]?.toLowerCase()),
        {
          id: 'type',
          header: 'Type',
          filterFn: 'arrIncludesSome',
        }
      ),
      comparisonColumnHelper.group({
        header: 'Cluster Average',
        columns: [
          comparisonColumnHelper.accessor(
            d => d.cluster?.salience?.avg?.value ?? 0,
            {
              id: 'clusterSalience',
              header: 'Salience',
              cell: cellProps =>
                formatPercentage(cellProps.getValue(), {
                  precision: 1,
                  forcePrecision: true,
                }),
              meta: {
                tight: true,
                getCellProps: () => ({
                  className: 'text-right',
                }),
              },
            }
          ),
          comparisonColumnHelper.accessor(
            d => d.cluster?.sentimentMagnitude?.avg?.value ?? 0,
            {
              id: 'clusterMagnitude',
              header: 'Magnitude',
              cell: cellProps =>
                formatNumber(cellProps.getValue(), {
                  precision: 1,
                  forcePrecision: true,
                }),
              meta: {
                tight: true,
                getCellProps: () => ({
                  className: 'text-right',
                }),
              },
            }
          ),
          comparisonColumnHelper.accessor(
            d => d.cluster?.salience?.avg?.value ?? 0,
            {
              id: 'clusterSentiment',
              header: 'Sentiment',
              filterFn: 'inNumberRange',
              sortUndefined: 1,
              cell: cellProps => {
                const {
                  row: { original },
                } = cellProps

                if (split) {
                  return (
                    <div className="flex w-36 flex-col items-end space-y-1 px-1 py-4">
                      {original.clusterUrls?.map((row, i) => (
                        <Tooltip
                          key={i}
                          tooltip={
                            <div className="space-y-1">
                              <div className="text-xs">
                                {row.clusterUrl.url?.url}
                              </div>
                              <table className="w-auto">
                                <tbody>
                                  <tr>
                                    <td>Salience</td>
                                    <td className="px-2 font-bold">
                                      {formatPercentage(
                                        row.entity?.salience ?? 0,
                                        {
                                          precision: 1,
                                          forcePrecision: true,
                                        }
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Score</td>
                                    <td className="px-2 font-bold">
                                      {formatNumber(
                                        row.entity?.sentiment?.score ?? 0,
                                        {
                                          precision: 3,
                                          forcePrecision: true,
                                        }
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Magnitude</td>
                                    <td className="px-2 font-bold">
                                      {formatNumber(
                                        row.entity?.sentiment?.magnitude ?? 0,
                                        {
                                          precision: 1,
                                          forcePrecision: true,
                                        }
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          }
                          className="group flex w-full justify-end"
                        >
                          <div
                            className={twMerge(
                              'h-2 min-w-[2px] group-hover:border-2 group-hover:border-gray-500'
                            )}
                            style={{
                              width: `${salienceScale(
                                row.entity?.salience ?? 0
                              )}%`,
                              backgroundColor: sentimentScale(
                                row.entity?.sentiment?.score ?? 0
                              ),
                            }}
                          />
                        </Tooltip>
                      ))}
                    </div>
                  )
                }

                const salience = original.cluster?.salience?.avg?.value ?? 0
                const sentiment =
                  original.cluster?.sentimentScore?.avg?.value ?? 0

                return (
                  <div className="flex w-36 justify-end p-1">
                    <div
                      className={twMerge('h-3')}
                      style={{
                        width: `${salienceScale(salience)}%`,
                        backgroundColor: sentimentScale(sentiment),
                      }}
                    />
                  </div>
                )
              },
              meta: {
                tight: true,
                getCellProps: () => ({
                  className: 'p-0',
                }),
              },
            }
          ),
        ],
      }),
      comparisonColumnHelper.group({
        header: 'URL',
        columns: [
          comparisonColumnHelper.accessor(d => d.url?.salience ?? 0, {
            id: 'urlSentiment',
            header: 'Sentiment',
            filterFn: 'inNumberRange',
            cell: cellProps => {
              const {
                row: { original },
              } = cellProps

              const salience = original.url?.salience ?? 0
              const sentiment = original.url?.sentiment?.score ?? 0

              return (
                <div className="w-36 p-1">
                  <div
                    className={twMerge('h-3')}
                    style={{
                      width: `${salienceScale(salience)}%`,
                      backgroundColor: sentimentScale(sentiment),
                    }}
                  />
                </div>
              )
            },
            meta: {
              tight: true,
              getCellProps: () => ({
                className: 'p-0',
              }),
            },
          }),
          comparisonColumnHelper.accessor(d => d.url?.salience ?? 0, {
            id: 'urlSalience',
            header: 'Salience',
            cell: cellProps =>
              formatPercentage(cellProps.getValue(), {
                precision: 1,
                forcePrecision: true,
              }),
            meta: {
              tight: true,
            },
          }),
          comparisonColumnHelper.accessor(
            d => d.url?.sentiment?.magnitude ?? 0,
            {
              id: 'urlMagnitude',
              header: 'Magnitude',
              cell: cellProps =>
                formatNumber(cellProps.getValue(), {
                  precision: 1,
                  forcePrecision: true,
                }),
            }
          ),
        ],
      }),
    ],
    [salienceScale, sentimentScale, split]
  )

  const comparisonTable = useTable({
    data: joinedEntities,
    columns: comparisonColumns,
    enableSortingRemoval: true,
    initialState: React.useMemo(() => {
      return {
        pagination: {
          pageSize: 50,
        },
      }
    }, []),
  })

  const preferredUrl = props.cluster.clusterUrls.find(
    d => d.url?.domain === props.focusDomain
  )?.url?.url

  React.useEffect(() => {
    if (!props.focusDomain) {
      return
    }

    if (preferredUrl && !url) {
      navigate({
        search: prev => ({
          ...prev,
          url: preferredUrl,
        }),
        replace: true,
      })
    }
  }, [navigate, preferredUrl, props.focusDomain, url])

  return (
    <div className="space-y-4">
      <Card className="divide-y divide-gray-500/20 p-0">
        <div className="p-2 text-lg font-bold">Entities</div>
        <div className="p-2">
          <form.Form className="space-y-2">
            <form.Field
              name="url"
              children={field => {
                return (
                  <div className="flex items-center gap-2">
                    {url && !preferredUrl ? (
                      <ButtonPlain
                        className="bg-gray-500/30 py-1.5 text-black hover:bg-red-500 hover:text-white dark:text-white"
                        onClick={() => {
                          setUrl('')
                        }}
                      >
                        <FaTimes className="text-base" />
                      </ButtonPlain>
                    ) : null}
                    <Select
                      options={urlOptions}
                      onChange={value => {
                        form.setFieldValue('url', value)
                        setUrl(value)
                      }}
                      children={({ onClick }) => {
                        return (
                          <ButtonPlain
                            className="bg-blue-500"
                            onClick={onClick}
                          >
                            Select a URL <FaCaretDown />
                          </ButtonPlain>
                        )
                      }}
                    />
                    {pageQuery.isFetching ? <Spinner className="" /> : null}
                    <Input
                      {...field.getInputProps()}
                      placeholder="https://example.com"
                      className="flex-1 py-1"
                      type="url"
                    />
                    <ButtonPlain type="submit" className="bg-green-500">
                      <MdCompare /> Compare
                    </ButtonPlain>
                    <Select
                      options={historyOptions}
                      onChange={value => {
                        form.setFieldValue('url', value)
                        setUrl(value)
                      }}
                      children={({ onClick }) => {
                        return (
                          <ButtonPlain
                            onClick={onClick}
                            className="bg-gray-100 text-gray-700 dark:bg-gray-800 dark:text-white"
                          >
                            History <FaCaretDown />
                          </ButtonPlain>
                        )
                      }}
                    />
                  </div>
                )
              }}
            />
          </form.Form>
        </div>
        <div className="divide-y divide-gray-100 dark:divide-gray-850">
          {!url ? (
            !entities?.length ? (
              <div className="p-2">No entities found.</div>
            ) : (
              <Table table={table} />
            )
          ) : (
            <QueryGate
              query={pageQuery}
              loader={
                <>
                  <div className="space-y-2 p-4 text-center">
                    <div className="text-sm font-bold">
                      Loading Comparison URL
                    </div>
                    <Loader className="text-2xl" />
                    <div className="text-xs italic opacity-50">
                      (This can take up to 2 minutes)
                    </div>
                  </div>
                  <Table table={table} />
                </>
              }
            >
              <Table table={comparisonTable} />
            </QueryGate>
          )}
        </div>
      </Card>
    </div>
  )
}
