// import { useMatch } from '@tanstack/react-router'
// import Tooltip from '../components/Tooltip'
// import useMetricInfoQuery from '../hooks/useMetricInfoQuery'
// import {
//   competitiveShareOfVoiceSlug,
//   performanceByKeywordGroupSlug,
//   performanceByKeywordSlug,
//   performanceBySegmentSlug,
//   performanceByUrlSlug,
//   performanceSingleKeywordSlug,
//   serpFeaturedSnippetsSlug,
// } from '../utils/Constants'

export default function MetricInfo({
  // metricId,
  children,
}: // isKeywordsTable,
{
  metricId: string
  children: any
  isKeywordsTable?: boolean
}) {
  // const metricDescriptionQuery = useMetricInfoQuery()
  // const {
  //   route: { path },
  // } = useMatch()

  // const field = isKeywordsTable
  //   ? 'byKeywordTableDescription'
  //   : path === performanceByKeywordGroupSlug
  //   ? 'byKeywordGroupDescription'
  //   : path === performanceBySegmentSlug
  //   ? 'bySegmentDescription'
  //   : path === performanceByUrlSlug
  //   ? 'byUrlDescription'
  //   : path === performanceByKeywordSlug
  //   ? 'byKeywordDescription'
  //   : path === performanceSingleKeywordSlug
  //   ? 'bySingleKeywordDescription'
  //   : path === competitiveShareOfVoiceSlug
  //   ? 'shareOfVoiceDescription'
  //   : path === serpFeaturedSnippetsSlug
  //   ? 'featuredSnippetsDescription'
  //   : 'shortDescription'

  // const metricDescription = metricDescriptionQuery.data?.metrics?.find(
  //   (m: any) => m?.fields.fieldId === metricId
  // )?.fields[field]

  // const defaultDescription = metricDescriptionQuery.data?.metrics?.find(
  //   (m: any) => m?.fields.fieldId === metricId
  // )?.fields.shortDescription
  return children

  // return (
  //   <Tooltip
  //     tooltip={
  //       metricDescription ? (
  //         <div className="whitespace-pre-line">{metricDescription}</div>
  //       ) : defaultDescription ? (
  //         <div className="whitespace-pre-line">{defaultDescription} </div>
  //       ) : (
  //         'No Description Available.'
  //       )
  //     }
  //   >
  //     {children}
  //   </Tooltip>
  // )
}
