import * as React from 'react'
import useIsMounted from './useIsMounted'

type EffectCallback = void | (() => void)

export default function useOnChange<TVal>(
  value: TVal,
  fn: (previous: undefined | TVal, next: TVal) => EffectCallback,
  options: { onMount?: boolean } = {}
) {
  const isMounted = useIsMounted()
  const previousRef = React.useRef<TVal>(value)

  if (!isMounted) {
    if (options.onMount ?? true) {
      fn(undefined, value)
    }
  } else if (previousRef.current !== value) {
    fn(previousRef.current, value)
  }

  React.useEffect(() => {
    previousRef.current = value
  })
}
