import React from 'react'
import { useQuery } from 'react-query'
import { getSleekToken } from '../utils/Api'
import Loader from './Loader'

export function SleekPlanReroute() {
  const referrer = document.referrer || 'https://sleekplan.nozzle.io/'

  const tokenQuery = useQuery(['sleekToken'], () => getSleekToken())

  if (tokenQuery.data) {
    // @ts-expect-error  // Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
    window.location = `${referrer}?sso=${tokenQuery.data}`
  }

  return (
    <div className="flex h-full items-center justify-center">
      <Loader className="text-5xl" />{' '}
    </div>
  )
}
