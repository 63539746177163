import * as React from 'react'
import { useField, useForm } from 'react-form'
import { FaInfoCircle, FaPlus, FaTimesCircle } from 'react-icons/fa'
import Button from '../components/Button'
import SelectField from '../components/SelectField'
import TextAreaField from '../components/TextAreaField'
import useModalContext from '../hooks/useModalContext'
import { dedupePhraseGroups } from '../utils/Phrases'
import Validate from '../utils/Validate'
import { ModalContent, ModalTitle } from './Modals'
import Tooltip from './Tooltip'

//

const defaultValue: any = []
const defaultAddFormValues = {
  phrases: '',
  groups: [],
}

export default function PhraseGroupsModal({ field = 'phraseGroups' }) {
  const { close } = useModalContext()

  const { value: phraseGroups, setValue: setPhraseGroups } = useField(field, {
    defaultValue,
  })

  const info = React.useMemo(
    () => dedupePhraseGroups(phraseGroups),
    [phraseGroups]
  )

  const groupOptions = React.useMemo(
    () =>
      Object.keys(info.groups).map(d => ({
        value: d,
        label: d,
      })),
    [info.groups]
  )

  const {
    Form,
    // @ts-expect-error  // Property 'handleSubmit' does not exist on type '{ ... Remove this comment to see the full error message
    handleSubmit,
    meta: { canSubmit },
  } = useForm({
    defaultValues: defaultAddFormValues,
    // @ts-expect-error  // Type '({ phrases, groups: globalGroups }: any, ins... Remove this comment to see the full error message
    onSubmit: ({ phrases, groups: globalGroups }: any, instance: any) => {
      //   setIsDirty(false)
      setPhraseGroups(
        dedupePhraseGroups([
          ...info.phraseGroups,
          ...phrases
            .split('\n')
            .map((d: any) => d.trim())
            .filter(Boolean)
            .map((line: any) => {
              const [phrase, ...groups] = line
                .split(';')
                .map((d: any) => d.trim())
              return {
                phrase,
                groups: [...groups, ...globalGroups],
              }
            }),
        ]).phraseGroups
      )
      instance.reset()
      close()
    },
  })

  return (
    <>
      <ModalTitle>Add Keyword Phrases</ModalTitle>
      <ModalContent>
        <div className="p-8">
          <div className="text-lg">
            Add Keyword Phrases{' '}
            <Tooltip
              tooltip={
                <div>
                  <FaInfoCircle className="inline" /> Paste your keyword phrases
                  into the Keyword Phrases box. You can assign them all to a
                  keyword group by using the Keyword Group bar below. Or assign
                  various keyword phrases to different groups using semicolons,
                  as shown below.
                </div>
              }
            >
              <FaInfoCircle className="inline" />{' '}
            </Tooltip>
          </div>

          <Form>
            <TextAreaField
              label="Keyword Phrases"
              field="phrases"
              placeholder={[
                'keyword phrase 1; keyword group 1; keyword group 2',
                '',
                'eggs; Breakfast; Eggs',
                'hamburger; Lunch; Dinner',
                'sandwich; Lunch',
                'fried eggs; Breakfast; Eggs',
                'toast',
              ].join(`\n`)}
              rows={6}
              // @ts-expect-error  // Argument of type '{ required: boolean; }' is not a... Remove this comment to see the full error message
              validate={Validate.keywordSourcePhrases({ required: true })}
            />
            <SelectField
              field="groups"
              label="Keyword Groups"
              options={groupOptions}
              multi
              adjusting
              create
              placeholder="Assign your keyword phrases to a keyword group..."
            />
            <div className="h-4" />

            <Button
              size="base"
              color="green-500"
              onClick={() => handleSubmit()}
              className="mr-1"
              disabled={!canSubmit}
            >
              <FaPlus className="inline" /> Add Keyword Phrases
            </Button>

            <Button
              size="base"
              color="gray-500"
              hoverColor="red-500"
              onClick={close}
            >
              <FaTimesCircle className="inline" /> Cancel
            </Button>
          </Form>
        </div>
      </ModalContent>
    </>
  )
}
