import * as React from 'react'
import { twMerge } from 'tailwind-merge'

const bgRx = /bg-([^\s]+)-(\d+)/g
const bgOpacityRx = /bg-([^\s]+)-(\d+)\/(\d+)/g

export function ButtonPlain({
  asChild,
  ...props
}: { asChild?: boolean } & React.ButtonHTMLAttributes<HTMLButtonElement>) {
  let shouldDarkText = false

  if (!shouldDarkText && props.className?.includes('bg-white')) {
    shouldDarkText = true
  }

  if (
    !shouldDarkText &&
    Array.from((props.className ?? '').matchAll(bgRx)).some(match => {
      return Number(match[2]) <= 400
    })
  ) {
    shouldDarkText = true
  }

  if (
    !shouldDarkText &&
    Array.from((props.className ?? '').matchAll(bgOpacityRx)).some(match => {
      return Number(match[3]) <= 50
    })
  ) {
    shouldDarkText = true
  }

  const allProps = {
    ...props,
    className: twMerge(
      'bg-blue-500 px-2 py-1 text-sm font-bold',
      `inline-flex
          flex-initial items-center
          justify-center gap-1.5
          whitespace-nowrap rounded-md bg-opacity-95
          shadow-sm transition duration-100
          ease-out hover:bg-opacity-100
      `,
      shouldDarkText ? 'text-black dark:text-white' : 'text-white',
      props.className
    ),
  }

  if (asChild) {
    return React.cloneElement(props.children as any, allProps)
  }

  return <button type="button" {...allProps} />
}

// const makeRxPair = (
//   rx: RegExp,
//   condition: (match: RegExpMatchArray) => any
// ): [RegExp, (match: RegExpMatchArray) => any] => [rx, condition]

// const bgRx = makeRxPair(
//   /(dark:)?(hover:)?(dark:)?(hover:)?bg-([^\s]+)-(\d+)/g,
//   (match: RegExpMatchArray) => {
//     if (match[1] || match[2] || match[3] || match[4]) {
//       return false
//     }
//     return Number(match[6]) >= 400
//   }
// )

// const hoverBgRx = makeRxPair(
//   /(dark:)?hover:(dark:)?bg-([^\s]+)-(\d+)/g,
//   (match: RegExpMatchArray) => {
//     if (match[1] || match[2]) {
//       return false
//     }
//     return Number(match[4]) >= 400
//   }
// )

// const darkBgRx = makeRxPair(
//   /(hover:)?dark:(hover:)?bg-([^\s]+)-(\d+)/g,
//   (match: RegExpMatchArray) => {
//     if (match[1] || match[2]) {
//       return false
//     }
//     return Number(match[4]) >= 400
//   }
// )

// const darkHoverBgRx = makeRxPair(
//   /(dark:hover:|hover:dark:)bg-([^\s]+)-(\d+)/g,
//   (match: RegExpMatchArray) => {
//     return Number(match[3]) >= 400
//   }
// )

// const testClasses = (
//   className: string | undefined,
//   rxPairs: [RegExp, (match: RegExpMatchArray) => any][]
// ) => {
//   return rxPairs.some(r => {
//     const [rx, condition] = r
//     return Array.from((className ?? '').matchAll(rx)).some(condition)
//   })
// }

// export function ButtonPlain(
//   props: React.ButtonHTMLAttributes<HTMLButtonElement> & { debug?: boolean }
// ) {
//   const { themeMode } = useThemeMode()

//   const shouldLightText = testClasses(props.className, [bgRx])
//   const shouldLightTextHover = testClasses(props.className, [hoverBgRx])
//   const shouldLightTextDark = testClasses(props.className, [darkBgRx])
//   const shouldLightTextHoverDark = testClasses(props.className, [darkHoverBgRx])

//   if (props.debug) {
//     console.info({
//       shouldLightText,
//       shouldLightTextHover,
//       shouldLightTextDark,
//       shouldLightTextHoverDark,
//     })
//   }

//   return (
//     <button
//       type="button"
//       {...props}
//       className={twMerge(
//         'bg-blue-500 px-2 py-1 text-sm font-bold uppercase',
//         `inline-flex
//           flex-initial items-center
//           justify-center gap-1.5
//           whitespace-nowrap rounded-md bg-opacity-95
//           shadow-sm transition duration-100
//           ease-out hover:bg-opacity-100
//       `,
//         shouldLightText ? 'text-white' : 'text-black',
//         shouldLightTextHover ? 'hover:text-white' : 'hover:text-black',
//         ...(themeMode === 'dark'
//           ? [
//               shouldLightTextDark ? 'dark:text-white' : 'dark:text-black',
//               shouldLightTextHoverDark
//                 ? 'dark:hover:text-white'
//                 : 'dark:hover:text-black',
//             ]
//           : []),
//         props.className
//       )}
//     />
//   )
// }
