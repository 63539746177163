import { createColumnHelper } from '@tanstack/react-table'
import * as React from 'react'
import { BiLinkExternal } from 'react-icons/bi'
import Anchor from '../../../components/Anchor'
import { resultMetricsList } from '../metrics'
import { RollupMetricsPb } from '../../../utils/proto'

const domainsColumnHelper = createColumnHelper<RollupMetricsPb>()
export const domainsColumns = [
  domainsColumnHelper.accessor('label', {
    header: 'Domain',
    cell: cellProps => (
      <Anchor
        href={`https://${cellProps.getValue()}`}
        target="_blank"
        className="flex items-center gap-1"
      >
        {cellProps.getValue()} <BiLinkExternal />
      </Anchor>
    ),
    meta: {
      getHeaderProps: () => ({
        className: `sticky left-0 z-10 bg-white dark:bg-gray-900`,
      }),
      getCellProps: () => ({
        className: `sticky left-0 z-10 bg-white dark:bg-gray-900`,
      }),
    },
  }),
  ...resultMetricsList
    .filter(
      d =>
        ![
          'serpCount',
          'keywordCount',
          'urlCount',
          'titleCount',
          'descriptionCount',
        ].includes(d.id)
    )
    .map(metric => {
      return domainsColumnHelper.accessor(
        d => metric.getValue(d.metrics![metric.id]!),
        {
          header: metric.label,
          id: metric.id,
          cell: cellProps => metric.renderValue(cellProps.getValue()),
          meta: {
            getCellProps: () => {
              return {
                className: 'text-right',
              }
            },
          },
        }
      )
    }),
]
