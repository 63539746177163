import { FaGoogle, FaQuestionCircle } from 'react-icons/fa'
import Tooltip from '../components/Tooltip'
import { EnginePb } from './proto'

export const engineOptions = [
  {
    label: 'Google',
    value: 'g',
    Icon: <FaGoogle className="text-google" />,
  },
]
export function formatEngine(input: string) {
  let label = '-'

  if (['g', 'google', EnginePb.GOOGLE].includes(input)) {
    label = 'Google'
  }

  if (!input) {
    return ''
  }
  return label
}

export function renderEngine(input: any) {
  let label = `Unknown Engine (${input})`
  let icon = <FaQuestionCircle />

  if (['g', 'google', EnginePb.GOOGLE].includes(input)) {
    label = 'Google'
    icon = <FaGoogle className="inline-block text-google" />
  }

  return <Tooltip tooltip={label}>{icon}</Tooltip>
}
