'use client'
import * as React from 'react'
import { BiCaretRight } from 'react-icons/bi'
import { BsFillCaretRightFill } from 'react-icons/bs'
import { twMerge } from 'tailwind-merge'
import useToast from '../hooks/useToast'
import copyToClipboard from '../utils/copyToClipboard'
import { withProps } from '../utils/withProps'
import Button from './Button'

export const Entry = withProps('div')(props => {
  return {
    ...props,
    className: twMerge(
      'monospace break-word text-[.9rem] leading-6',
      props.className
    ),
  }
})

export const Label = withProps('span')(props => {
  return {
    ...props,
    className: twMerge('', props.className),
  }
})

export const LabelButton = withProps('button')(props => {
  return {
    ...props,
    className: twMerge('', props.className),
  }
})

export const CopyButton = ({ value }: { value: unknown }) => {
  const toast = useToast()

  return (
    <Button
      size="xs"
      color="gray-500"
      onClick={() => {
        copyToClipboard(JSON.stringify(value))
        toast({
          color: 'green-500',
          message: 'Copied to clipboard',
        })
      }}
    >
      Copy to Clipboard
    </Button>
  )
}

export const Value = withProps('span')(props => ({
  ...props,
  className: twMerge('text-red-500', props.className),
}))

export const SubEntries = withProps('div')(props => ({
  ...props,
  className: twMerge('ml-2 border-l-2 border-gray-500/5 pl-2', props.className),
}))

export const Info = withProps('span')(props => ({
  ...props,
  className: twMerge('text-xs text-gray-500', props.className),
}))

function Expander(props: { expanded: boolean }) {
  return (
    <BsFillCaretRightFill
      className={twMerge(
        'm-1 transform transition',
        props.expanded && 'rotate-90'
      )}
    />
  )
}

type Entry = {
  label: string
}

type RendererProps = {
  handleEntry: (entry: Entry) => JSX.Element
  label?: string
  value: unknown
  subEntries: Entry[]
  subEntryPages: Entry[][]
  type: string
  expanded: boolean
  copyable: boolean
  toggleExpanded: () => void
  pageSize: number
}

/**
 * Chunk elements in the array by size
 *
 * when the array cannot be chunked evenly by size, the last chunk will be
 * filled with the remaining elements
 *
 * @example
 * chunkArray(['a','b', 'c', 'd', 'e'], 2) // returns [['a','b'], ['c', 'd'], ['e']]
 */
export function chunkArray<T>(array: T[], size: number): T[][] {
  if (size < 1) return []
  let i = 0
  const result: T[][] = []
  while (i < array.length) {
    result.push(array.slice(i, i + size))
    i = i + size
  }
  return result
}

type Renderer = (props: RendererProps) => JSX.Element

export const DefaultRenderer: Renderer = ({
  handleEntry,
  label,
  value,
  subEntries = [],
  subEntryPages = [],
  type,
  expanded = false,
  copyable = false,
  toggleExpanded,
  pageSize,
}) => {
  const [expandedPages, setExpandedPages] = React.useState<number[]>([])

  return (
    <Entry key={label}>
      {subEntryPages.length ? (
        <>
          <button
            onClick={() => toggleExpanded()}
            className="flex items-center gap-1"
          >
            <Expander expanded={expanded} /> {label}{' '}
            <Info>
              {String(type).toLowerCase() === 'iterable' ? '(Iterable) ' : ''}
              {subEntries.length} {subEntries.length > 1 ? `items` : `item`}
            </Info>
          </button>
          {copyable ? <CopyButton value={value} /> : null}
          {expanded ? (
            subEntryPages.length === 1 ? (
              <SubEntries>{subEntries.map(handleEntry)}</SubEntries>
            ) : (
              <SubEntries>
                {subEntryPages.map((entries, index) => (
                  <div key={index}>
                    <Entry>
                      <LabelButton
                        onClick={() =>
                          setExpandedPages(old =>
                            old.includes(index)
                              ? old.filter(d => d !== index)
                              : [...old, index]
                          )
                        }
                      >
                        <Expander expanded={expanded} /> [{index * pageSize} ...{' '}
                        {index * pageSize + pageSize - 1}]
                      </LabelButton>
                      {expandedPages.includes(index) ? (
                        <SubEntries>{entries.map(handleEntry)}</SubEntries>
                      ) : null}
                    </Entry>
                  </div>
                ))}
              </SubEntries>
            )
          ) : null}
        </>
      ) : (
        <>
          <Label>{label}:</Label> <Value>{JSON.stringify(value)}</Value>
        </>
      )}
    </Entry>
  )
}

type ExplorerProps = Partial<RendererProps> & {
  renderer?: Renderer
  defaultExpanded?: boolean | Record<string, boolean>
  copyable?: boolean
}

type Property = {
  defaultExpanded?: boolean | Record<string, boolean>
  label: string
  value: unknown
}

function isIterable(x: any): x is Iterable<unknown> {
  return Symbol.iterator in x
}

export default function Explorer({
  value,
  defaultExpanded,
  renderer = DefaultRenderer,
  pageSize = 100,
  copyable = false,
  ...rest
}: ExplorerProps) {
  const [expanded, setExpanded] = React.useState(Boolean(defaultExpanded))
  const toggleExpanded = React.useCallback(() => setExpanded(old => !old), [])

  let type: string = typeof value
  let subEntries: Property[] = []

  const makeProperty = (sub: { label: string; value: unknown }): Property => {
    const subDefaultExpanded =
      defaultExpanded === true
        ? { [sub.label]: true }
        : defaultExpanded === false
        ? false
        : defaultExpanded?.[sub.label]
    return {
      ...sub,
      defaultExpanded: subDefaultExpanded,
    }
  }

  if (Array.isArray(value)) {
    type = 'array'
    subEntries = value.map((d, i) =>
      makeProperty({
        label: i.toString(),
        value: d,
      })
    )
  } else if (
    value !== null &&
    typeof value === 'object' &&
    isIterable(value) &&
    typeof value[Symbol.iterator] === 'function'
  ) {
    type = 'Iterable'
    subEntries = Array.from(value, (val, i) =>
      makeProperty({
        label: i.toString(),
        value: val,
      })
    )
  } else if (typeof value === 'object' && value !== null) {
    type = 'object'
    subEntries = Object.entries(value).map(([key, val]) =>
      makeProperty({
        label: key,
        value: val,
      })
    )
  }

  const subEntryPages = chunkArray(subEntries, pageSize)

  return renderer({
    handleEntry: entry => (
      <Explorer
        key={entry.label}
        value={value}
        renderer={renderer}
        copyable={copyable}
        {...rest}
        {...entry}
      />
    ),
    type,
    subEntries,
    subEntryPages,
    value,
    expanded,
    copyable,
    toggleExpanded,
    pageSize,
    ...rest,
  })
}
