import { css } from '@emotion/css'
import { twMerge } from 'tailwind-merge'
import { withProps } from '../utils/withProps'

//

const Caption = withProps('small')(({ className, ...props }) => ({
  ...props,
  className: twMerge(
    'm-[.2rem] inline-block max-w-[500px] text-[.9rem] opacity-70 hover:opacity-90',
    css({ transition: 'all 0.5s ease' }),
    className
  ),
}))

export default Caption
