import * as React from 'react'
import Button from '../components/Button'
import Link from '../components/Link'

export default function NotFound() {
  return (
    <div className="flex flex-col items-center justify-center gap-2 p-8 text-xl font-light">
      <div>
        <span aria-label="Sad Emoji" role="img">
          😢
        </span>{' '}
        This page does not exist.
      </div>
      <div>
        <Link to="/">
          <Button size="lg" color="blue-500">
            Take me home!
          </Button>
        </Link>
      </div>
    </div>
  )
}
