import * as React from 'react'
import { UseQueryResult } from 'react-query'
import useControlledTable from '../hooks/useControlledTable'
import { Updater } from '../utils'
import { GroupByQueryResponse } from '../utils/Api'
import {
  MetricId,
  metricPermutation,
  MetricPermutation,
} from '../utils/Metrics'
import ErrorComp from './Error'
import Table from './Table'
import TablePlaceholder from './TablePlaceholder'

//

export interface ControlledQueryTableState {
  sortByMetricPermutation: MetricPermutation<MetricId>
  desc: boolean
  metricId: MetricId
}

export default function ControlledQueryTable<
  TState extends ControlledQueryTableState
>({
  dataQuery,
  sortByMetricPermutation,
  desc,
  columns,
  metricIds,
  setState,
  compact,
  getRowProps,
  limit,
}: {
  dataQuery: UseQueryResult<GroupByQueryResponse>
  sortByMetricPermutation: MetricPermutation<MetricId>
  desc: boolean
  columns: {
    id: string
  }[]
  metricIds: MetricId[]
  setState: (updater: Updater<TState>) => void
  compact: boolean
  limit: number
  getRowProps?: (row: any) => any
}) {
  const sorting = React.useMemo(() => {
    return [
      {
        id: `${sortByMetricPermutation.id}${
          sortByMetricPermutation.postAggregation === 'change' ? 'Change' : ''
        }`,
        desc,
      },
    ]
  }, [
    sortByMetricPermutation.id,
    sortByMetricPermutation.postAggregation,
    desc,
  ])

  const onSortingChange = React.useCallback(
    (updater: any) => {
      setState(old => {
        const { id, desc } = updater(sorting)?.[0]

        const [sortByMetricId, isChange] = id.split('Chang')
        const newPostAggregation = isChange ? 'change' : 'value'

        const focusMetricId = metricIds.includes(sortByMetricId)
          ? sortByMetricId
          : old?.metricId

        return {
          ...old,
          sortByMetricPermutation: metricPermutation(sortByMetricId, {
            postAggregation: newPostAggregation,
          }),
          desc,
          metricId: focusMetricId,
        }
      })
    },
    [metricIds, setState, sorting]
  )

  const tableData = React.useMemo(
    () => dataQuery.data?.series ?? [],
    [dataQuery.data?.series]
  )

  const table = useControlledTable({
    data: tableData,
    columns,
    state: {
      sorting,
    },
    onSortingChange,
    compact,
    getRowProps,
    tight: true,
    showToolbar: false,
    isControlled: true,
  })

  if (dataQuery.isError) {
    return (
      <div className="p-4">
        <ErrorComp error={dataQuery.error} />
      </div>
    )
  }

  if (dataQuery.isLoading) {
    return (
      <TablePlaceholder
        style={{
          borderWidth: 0,
          borderTopWidth: 1,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          rows: limit,
        }}
      />
    )
  }

  return (
    <>
      <Table
        table={table}
        style={{
          opacity: dataQuery.isFetching ? 0.5 : 1,
        }}
      />
    </>
  )
}
