import { twMerge } from 'tailwind-merge'
import moment from 'moment'
import * as React from 'react'
import { FaCircle } from 'react-icons/fa'
import { UseQueryResult } from 'react-query'
import useGetLatest from '../hooks/useGetLatest'
import {
  TableDisplayMode,
  tableDisplayModeOptions,
} from '../utils/tableDisplayMode'
import { Updater, getDataValue, last } from '../utils'
import { GroupByQueryResponse, GroupByQuerySeries } from '../utils/Api'
import { getDataColor } from '../utils/DataColors'
import { formatPostAggregation } from '../utils/Format'
import {
  MetricId,
  MetricPostAggregation,
  getMetricChangeFormatter,
  getMetricRenderer,
  metricsById,
} from '../utils/Metrics'
import { QueryFacetId, allQueryFacets } from '../utils/queryFacets'
import AspectRatio from './AspectRatio'
import Button from './Button'
import Clickable from './Clickable'
import ControlledQueryTable, {
  ControlledQueryTableState,
} from './ControlledQueryTable'
import { Counter } from './Counter'
import Link from './Link'
import Pager from './Pager'
import SeriesLegend from './SeriesLegend'
import TimeSeriesChart from './TimeSeriesChart'
import Tooltip from './Tooltip'
import { render } from 'react-dom'

export type SummaryWidgetState = ControlledQueryTableState & {
  tableDisplayMode: TableDisplayMode
}

export function SummaryWidget<TState extends SummaryWidgetState>({
  metricIds,
  setState,
  dataQuery,
  groupBy,
  hidePagination,
  onClickSerie,
  desc,
  metricId: focusMetricId,
  limit,
  offset,
  sortByMetricPermutation,
  displayPostAggregation,
  tableDisplayMode,
  showLegends,
  renderSeriesLabel,
}: SummaryWidgetState & {
  metricIds: MetricId[]
  setState: (updater: Updater<TState>) => void
  dataQuery: UseQueryResult<GroupByQueryResponse>
  groupBy: QueryFacetId
  hidePagination?: boolean
  onClickSerie?: (series: GroupByQuerySeries) => void
  displayPostAggregation: MetricPostAggregation
  renderSeriesLabel?: (serie: GroupByQuerySeries) => React.ReactElement
  showLegends: boolean
  limit: number
  offset: number
}) {
  const [showAllSeries, setShowAllSeries] = React.useState(false)
  const softMaxSeries = 20
  const groupByFacet = allQueryFacets.find(d => d.id === groupBy)

  const formatSeriesLabel = groupByFacet?.formatSeriesLabel

  dataQuery.data?.series?.forEach(serie => {
    if (dataQuery.data?.series?.length === 1) {
      serie.color = getDataColor(metricIds.indexOf(focusMetricId))
    }
  })

  return (
    <>
      <div className="flex flex-wrap gap-4 p-2">
        {(dataQuery.data?.series?.length ?? 0) > softMaxSeries &&
        !showAllSeries ? (
          <div className="flex items-center justify-between rounded bg-gray-100 p-2 text-xs dark:bg-gray-800 dark:text-white">
            <div>
              <strong>NOTE</strong>: Only {softMaxSeries} of{' '}
              {dataQuery.data?.series?.length} series are currently shown in
              this chart for performance reasons.
            </div>
            <Button
              size="xs"
              color="gray-500"
              onClick={() => setShowAllSeries(true)}
            >
              Show All
            </Button>
          </div>
        ) : null}
        <AspectRatio
          ratio={[5, 1]}
          className="min-h-48 overflow-auto"
          style={{
            opacity: dataQuery.isFetching ? 0.5 : 1,
          }}
        >
          <TimeSeriesChart
            {...{
              isLoading: dataQuery.isLoading,
              isFetching: dataQuery.isFetching,
              isError: dataQuery.isError,
              error: dataQuery.error,
              displayPostAggregation,
              metricId: focusMetricId,
              limit,
              subAggregation: 'total',
              onClickDatum: chartDatum => {
                if (onClickSerie) {
                  // @ts-expect-error  // Object is possibly 'null'.
                  onClickSerie(chartDatum.originalSeries as any) // UPSTREAM: fix react-charts generics here
                }
              },
              data: React.useMemo(() => {
                return dataQuery.data?.series
                  ?.map(series => {
                    return {
                      ...series,
                      label: formatSeriesLabel?.(
                        series,
                        dataQuery.data?.series
                      ),
                    }
                  })
                  .slice(
                    0,
                    showAllSeries
                      ? dataQuery.data?.series?.length ?? softMaxSeries
                      : softMaxSeries
                  )
              }, [dataQuery.data?.series, formatSeriesLabel, showAllSeries]),
            }}
          />
        </AspectRatio>
        {showLegends ? (
          <>
            <SeriesLegend
              {...{
                query: dataQuery,
                formatSeriesLabel,
                groupBy,
                onClickSerie,
                // onClickDatum,
              }}
            />
          </>
        ) : null}
      </div>
      <div className="divide-x divide-gray-500/20 bg-gray-50 dark:bg-gray-900">
        {tableDisplayModeOptions.map(option => {
          return (
            <button
              key={option.value}
              onClick={() => {
                setState(old => {
                  return {
                    ...old,
                    tableDisplayMode: option.value,
                  }
                })
              }}
              className={twMerge(
                `px-2 py-1 text-sm`,
                'hover:bg-blue-500 hover:text-white',
                option.value === tableDisplayMode
                  ? 'bg-white font-bold dark:bg-gray-800'
                  : ''
              )}
            >
              {option.label}
            </button>
          )
        })}
      </div>
      {tableDisplayMode === 'summary' ? (
        <SummaryTable
          {...{
            dataQuery,
            metricIds,
            displayPostAggregation,
            sortByMetricPermutation,
            desc,
            setState,
            metricId: focusMetricId,
            renderSeriesLabel,
            label: groupByFacet?.label,
            groupBy,
            limit,
            onClickSerie,
          }}
        />
      ) : tableDisplayMode === 'history' ? (
        <HistoryTable
          {...{
            dataQuery,
            metricIds,
            groupBy,
            displayPostAggregation,
            sortByMetricPermutation,
            desc,
            setState,
            metricId: focusMetricId,
            renderSeriesLabel,
            label: groupByFacet?.label,
            limit,

            onClickSerie,
          }}
        />
      ) : null}
      {!hidePagination ? (
        <>
          <div className="p-1">
            <Counter
              {...{
                compact: true,
                isLoading: dataQuery.isFetching,
                count: dataQuery.data?.series?.length,
                totalCount: dataQuery.data?.rowCount,

                totalName: groupByFacet?.labelPlural,
              }}
            />
          </div>
          <div className="p-1">
            <Pager
              compact
              offset={offset}
              limit={limit}
              size={dataQuery.data?.rowCount ?? 0}
              setOffset={offset =>
                setState(old => ({
                  ...old,
                  offset,
                }))
              }
              setLimit={limit =>
                setState(old => ({
                  ...old,
                  limit,
                }))
              }
            />
          </div>
        </>
      ) : null}
    </>
  )
}

function SummaryTable<TState extends ControlledQueryTableState>({
  dataQuery,
  metricIds,
  displayPostAggregation,
  sortByMetricPermutation,
  desc,
  setState,
  metricId: focusMetricId,
  label,
  groupBy,
  limit,
  onClickSerie,
  renderSeriesLabel,
}: ControlledQueryTableState & {
  dataQuery: UseQueryResult<any>
  metricIds: MetricId[]
  displayPostAggregation: any
  setState: (updater: Updater<TState>) => void
  label: string | undefined
  groupBy: QueryFacetId
  limit: number
  onClickSerie?: (serie: GroupByQuerySeries) => void
  renderSeriesLabel?: (serie: GroupByQuerySeries) => React.ReactElement
}) {
  const focusMetric = metricsById[focusMetricId]
  const focusMetricChangeFormatter = getMetricChangeFormatter(focusMetricId)
  const focusMetricRender = getMetricRenderer(focusMetricId)
  const queryFacet = allQueryFacets.find(d => d.id === groupBy)

  const getOnClickSerie = useGetLatest(onClickSerie)

  const columns = React.useMemo(
    () => [
      {
        id: 'circle',
        enableSorting: false,
        cell: (props: any) => {
          const value = queryFacet?.renderSeriesLabel(props.row.original, [
            props.row.original,
          ])

          return (
            <Tooltip
              tooltip={value}
              getTooltipProps={() => ({ className: 'max-w-full' })}
              onClick={() => getOnClickSerie()?.(props.row.original)}
            >
              <FaCircle
                style={{
                  color: props.row.original.color,
                }}
              />
            </Tooltip>
          )
        },
        tight: true,
        getHeaderProps: () => ({
          className: `sticky left-0 z-10`,
        }),
        getCellProps: () => ({
          className: `sticky left-0 z-10`,
        }),
      },
      {
        id: 'label',
        header: label,
        enableSorting: false,
        cell: (props: any) => {
          const value = queryFacet?.renderSeriesLabel(
            props.row.original,
            [props.row.original],
            {
              short: true,
            }
          )

          if (renderSeriesLabel) {
            return React.cloneElement(renderSeriesLabel(props.row.original), {
              children: value,
            })
          }

          return value
        },
      },
      ...focusMetric.postAggregations.map(postAggregation => {
        const isActive = postAggregation === displayPostAggregation
        return {
          id: `postAggregation-${postAggregation}`,
          header: formatPostAggregation(postAggregation),
          enableSorting: false,
          tight: true,
          getHeaderProps: ({ className }: any) => ({
            className: twMerge(isActive && `font-black`, className),
          }),
          getCellProps: ({ className }: any) => ({
            className: twMerge(isActive && `font-bold`, className),
          }),
          cell: (props: any) => (
            <div>
              {(postAggregation === 'change'
                ? focusMetricChangeFormatter
                : focusMetricRender)(
                getDataValue(last(props.row.original.data), {
                  id: focusMetricId,
                  aggregation: focusMetric.aggregations[0],
                  subAggregation: 'total',
                  postAggregation,
                })
              )}
            </div>
          ),
        }
      }),
    ],
    [
      label,
      focusMetric.postAggregations,
      focusMetric.aggregations,
      queryFacet,
      getOnClickSerie,
      renderSeriesLabel,
      displayPostAggregation,
      focusMetricChangeFormatter,
      focusMetricRender,
      focusMetricId,
    ]
  )

  return (
    <div className="w-full">
      <div className="overflow-auto">
        <ControlledQueryTable
          {...{
            dataQuery,
            sortByMetricPermutation,
            desc,
            setState,
            columns,
            metricIds,
            limit,
            compact: true,
          }}
        />
      </div>
    </div>
  )
}

function HistoryTable<TState extends ControlledQueryTableState>({
  dataQuery: preDataQuery,
  metricIds,
  displayPostAggregation,
  sortByMetricPermutation,
  desc,
  setState,
  metricId: focusMetricId,
  label,
  groupBy,
  limit,
  renderSeriesLabel,
}: ControlledQueryTableState & {
  dataQuery: UseQueryResult<any>
  metricIds: MetricId[]
  displayPostAggregation: any
  setState: (updater: Updater<TState>) => void
  label: string | undefined
  groupBy: QueryFacetId
  limit: number
  renderSeriesLabel?: (serie: GroupByQuerySeries) => React.ReactElement
}) {
  const focusMetric = metricsById[focusMetricId]
  const queryFacet = allQueryFacets.find(d => d.id === groupBy)

  const dataQuery = React.useMemo(() => {
    return {
      ...preDataQuery,
      data: preDataQuery.data
        ? {
            ...preDataQuery.data,
            series: [
              ...(preDataQuery.data?.series?.map((serie: any) => ({
                ...serie,

                data: serie.data.map((d: any) => ({
                  ...d,

                  value: getDataValue(d, {
                    id: focusMetricId,
                    aggregation: focusMetric.aggregations[0],
                    subAggregation: 'total',
                    postAggregation: displayPostAggregation,
                  }),
                })),
              })) ?? []),
            ],
          }
        : undefined,
    }
  }, [
    displayPostAggregation,
    focusMetric.aggregations,
    focusMetricId,
    preDataQuery,
  ])

  const columns = React.useMemo(
    () => [
      {
        id: 'circle',
        enableSorting: false,
        cell: (props: any) => {
          const value = queryFacet?.renderSeriesLabel(props.row.original, [
            props.row.original,
          ])

          return (
            <Tooltip
              tooltip={value}
              getTooltipProps={() => ({ className: 'max-w-full' })}
            >
              <FaCircle
                style={{
                  color: props.row.original.color,
                }}
              />
            </Tooltip>
          )
        },
        tight: true,
        getHeaderProps: () => ({
          className: `sticky left-0 z-10`,
        }),
        getCellProps: () => ({
          className: `sticky left-0 z-10`,
        }),
      },
      {
        header: label,
        enableSorting: false,
        cell: (props: any) => {
          const value = queryFacet?.renderSeriesLabel(
            props.row.original,
            [props.row.original],
            { short: true }
          )

          if (renderSeriesLabel) {
            return React.cloneElement(renderSeriesLabel(props.row.original), {
              children: value,
            })
          }

          return value
        },
        // getHeaderProps: ({ className }) => ({
        //   className: twMerge(`sticky left-0 z-10`, className),
        // }),
        // getCellProps: ({ className }) => ({
        //   className: twMerge(`sticky left-0 z-10`, className),
        // }),
      },
      // @ts-expect-error  // Parameter 'd' implicitly has an 'any' type.
      ...(dataQuery.data?.series?.[0]?.data.map((d, i, all) => ({
        id: `${moment.utc(d.requested).format('l')}`,
        header: `${moment.utc(d.requested).format('l')}`,
        accessorFn: (d: any) => d.data[i]?.value,
        enableSorting: false,
        meta: { tight: true },
        cell: (props: any) => (
          <div
            className={twMerge(
              `text-right`,
              i === all.length - 1 && `font-bold`
            )}
          >
            {(displayPostAggregation === 'change'
              ? getMetricChangeFormatter(focusMetricId)
              : getMetricRenderer(focusMetricId))(props.getValue())}
          </div>
        ),
      })) || []),
    ],
    [
      label,
      dataQuery.data?.series,
      queryFacet,
      renderSeriesLabel,
      displayPostAggregation,
      focusMetricId,
    ]
  )

  return (
    <div className="w-full">
      <div className="overflow-x-auto">
        <ControlledQueryTable
          {...{
            dataQuery,
            sortByMetricPermutation,
            desc,
            setState,
            columns,
            metricIds,
            compact: true,
            limit,
          }}
        />
      </div>
    </div>
  )
}
