// @generated by protoc-gen-connect-web v0.11.0 with parameter "target=ts"
// @generated from file nozzle/workspaces/v1/topic.proto (package nozzle.workspaces.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { BatchDeleteTopicsRequest, BatchDeleteTopicsResponse, BatchGetTopicsRequest, BatchGetTopicsResponse, BatchUpdateTopicsRequest, BatchUpdateTopicsResponse, DeleteTopicRequest, DeleteTopicResponse, GetTopicRequest, GetTopicResponse, ListTopicsRequest, ListTopicsResponse, UpdateTopicRequest, UpdateTopicResponse } from "./topic_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * TopicsService is a service for managing keyword sources.
 *
 * @generated from service nozzle.workspaces.v1.TopicsService
 */
export const TopicsService = {
  typeName: "nozzle.workspaces.v1.TopicsService",
  methods: {
    /**
     * UpdateTopic will create/update a topic.
     *
     * @generated from rpc nozzle.workspaces.v1.TopicsService.UpdateTopic
     */
    updateTopic: {
      name: "UpdateTopic",
      I: UpdateTopicRequest,
      O: UpdateTopicResponse,
      kind: MethodKind.Unary,
    },
    /**
     * BatchUpdateTopic will perform a batch update of topics.
     *
     * @generated from rpc nozzle.workspaces.v1.TopicsService.BatchUpdateTopics
     */
    batchUpdateTopics: {
      name: "BatchUpdateTopics",
      I: BatchUpdateTopicsRequest,
      O: BatchUpdateTopicsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetTopic will get a topic.
     *
     * @generated from rpc nozzle.workspaces.v1.TopicsService.GetTopic
     */
    getTopic: {
      name: "GetTopic",
      I: GetTopicRequest,
      O: GetTopicResponse,
      kind: MethodKind.Unary,
    },
    /**
     * BatchGetTopics will get a topic.
     *
     * @generated from rpc nozzle.workspaces.v1.TopicsService.BatchGetTopics
     */
    batchGetTopics: {
      name: "BatchGetTopics",
      I: BatchGetTopicsRequest,
      O: BatchGetTopicsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ListTopics will list topics.
     *
     * @generated from rpc nozzle.workspaces.v1.TopicsService.ListTopics
     */
    listTopics: {
      name: "ListTopics",
      I: ListTopicsRequest,
      O: ListTopicsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * DeleteTopic will delete a topic.
     *
     * @generated from rpc nozzle.workspaces.v1.TopicsService.DeleteTopic
     */
    deleteTopic: {
      name: "DeleteTopic",
      I: DeleteTopicRequest,
      O: DeleteTopicResponse,
      kind: MethodKind.Unary,
    },
    /**
     * BatchDeleteTopic will batch delete given topics.
     *
     * @generated from rpc nozzle.workspaces.v1.TopicsService.BatchDeleteTopics
     */
    batchDeleteTopics: {
      name: "BatchDeleteTopics",
      I: BatchDeleteTopicsRequest,
      O: BatchDeleteTopicsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

