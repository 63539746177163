import * as React from 'react'
import { FaPlus, FaTimes } from 'react-icons/fa'
import useGetLatest from '../hooks/useGetLatest'
import { useMostFrequentScheduleOptions } from '../hooks/useMostFrequentScheduleOptions'
import { deviceOptionsV2 } from '../options/deviceOptions'
import { Updater } from '../react-form'
import { formatDevice } from '../utils/Format'
import { DeviceModelNz } from '../utils/devices'
import { ScheduleConfig } from '../utils/schedules'
import Clickable from './Clickable'
import ErrorBoundary from './ErrorBoundary'
import { PlainInput } from './Input'
import Select from './Select'
import { TableCell, TableEl, TableHead, TableHeader, TableRow } from './Table'

//

export function DevicesModelInput({
  value,
  onChange,
  schedules,
}: {
  value: DeviceModelNz[]
  onChange?: (value: Updater<DeviceModelNz[]>) => void
  schedules?: ScheduleConfig[]
}) {
  const mostFrequentScheduleOptions = useMostFrequentScheduleOptions({
    schedules,
  })

  const getMostFrequentScheduleOptions = useGetLatest(
    mostFrequentScheduleOptions
  )

  type Column = {
    header?: string
    id?: string
    cell: React.FC<{ row: any; index: number }>
    meta?: {
      tight?: boolean
    }
    cellProps?: any
  }

  const columns = React.useMemo(
    (): Column[] =>
      [
        {
          header: 'Device',
          cell: ({ row }: any) =>
            formatDevice(row.device, { v2: true, short: false }),
          meta: {
            tight: true,
          },
        },
        mostFrequentScheduleOptions.length > 1
          ? {
              header: 'Most Frequent Schedule',
              cell: ({ row, index }: any) => {
                React.useEffect(() => {
                  if (
                    !mostFrequentScheduleOptions.find(
                      d => d.value === row.mostFrequentScheduleId
                    )
                  ) {
                    onChange?.(prev => {
                      const next = [...prev]

                      next[index].mostFrequentScheduleId = null
                      return next
                    })
                  }
                }, [index, row.mostFrequentScheduleId])

                return (
                  <Select
                    options={mostFrequentScheduleOptions}
                    value={row.mostFrequentScheduleId}
                    onChange={value => {
                      onChange?.(prev => {
                        const next = [...prev]

                        next[index].mostFrequentScheduleId = value
                        return next
                      })
                    }}
                    inputProps={{
                      Input: PlainInput,
                    }}
                    placeholder="Select a Schedule..."
                  />
                )
              },
              meta: {
                tight: true,
              },
              cellProps: {
                className: 'p-0',
              },
            }
          : (null as any),
        {
          header: '',
          id: 'actions',
          cell: ({ row, index }: any) => {
            return (
              <button
                className="text-gray-500 hover:text-red-500"
                onClick={() => {
                  onChange?.(prev => prev.filter((_, i) => i !== index))
                }}
              >
                <FaTimes />
              </button>
            )
          },
        },
      ].filter(Boolean),
    [schedules, getMostFrequentScheduleOptions]
  )

  const rows = React.useMemo(() => value ?? [], [value])

  const visibleDeviceOptions = React.useMemo(
    () =>
      deviceOptionsV2.filter(d => {
        return !rows.some(r => r.device === d.value)
      }),
    [rows]
  )

  return (
    <ErrorBoundary>
      <div className="divide-y divide-gray-500/20 rounded border border-gray-100 text-sm dark:border-gray-800">
        <TableEl>
          <TableHead>
            <TableRow>
              {columns.map(column => {
                return (
                  <TableHeader key={column.header || column.id} className="p-2">
                    {column.header ?? null}
                  </TableHeader>
                )
              })}
            </TableRow>
          </TableHead>
          <tbody>
            {rows.length ? (
              rows.map((row, index) => {
                return (
                  <TableRow key={row.device}>
                    {columns.map(column => {
                      return (
                        <TableCell
                          key={column.header || column.id}
                          tight={column.meta?.tight}
                          className="p-2"
                          {...column.cellProps}
                        >
                          <column.cell row={row} index={index} />
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length}>
                  <div className="text-center text-gray-500">
                    No devices added
                  </div>
                </TableCell>
              </TableRow>
            )}
          </tbody>
        </TableEl>
        <Select
          options={visibleDeviceOptions}
          onChange={value => {
            // @ts-expect-error  // Argument of type '(prev: DeviceModelNz[]) => (Devi... Remove this comment to see the full error message
            onChange?.(prev => [
              ...(prev ?? []),
              { device: value, mostFrequentSchedule: null },
            ])
          }}
          className="block"
          renderOption={option => {
            return formatDevice(option.value, { v2: true, short: false })
          }}
        >
          {({ onClick }: any) => {
            return (
              <Clickable
                onClick={onClick}
                className="flex items-center gap-1 p-2 !font-bold text-blue-500"
              >
                <FaPlus /> Add Device
              </Clickable>
            )
          }}
        </Select>
      </div>
    </ErrorBoundary>
  )
}
