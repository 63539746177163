import { createColumnHelper } from '@tanstack/react-table'
import * as React from 'react'
import Card from '../../../components/Card'
import Table from '../../../components/Table'
import { useTable } from '../../../hooks/useTable'
import { featuresMetricsList } from '../metrics'
import { ClusterPb, RollupMetricsPb } from '../../../utils/proto'

const featureColumnHelper = createColumnHelper<RollupMetricsPb>()
const featureColumns = [
  featureColumnHelper.accessor('label', {
    header: 'Feature',
  }),
  ...featuresMetricsList
    .filter(d => d.id !== 'keywordCount')
    .map(metric => {
      return featureColumnHelper.accessor(
        d => metric.getValue(d.metrics![metric.id]!),
        {
          header: metric.label,
          id: metric.id,
          cell: cellProps => metric.renderValue(cellProps.getValue()),
          meta: {
            getCellProps: () => {
              return {
                className: 'text-right',
              }
            },
          },
        }
      )
    }),
]
export function SerpFeatures(props: { cluster: ClusterPb }) {
  const table = useTable({
    data: props.cluster.metricsBySerpFeature,
    columns: featureColumns,
    initialState: React.useMemo(
      () => ({
        pagination: {
          pageSize: 50,
        },
      }),
      []
    ),
  })

  return (
    <Card className="divide-y divide-gray-500/20 p-0">
      <div className="p-2 text-lg font-bold">Features</div>
      <div className="divide-x divide-gray-100 dark:divide-gray-850">
        <div className="col-span-2 min-w-0 flex-1">
          <Table table={table} />
        </div>
      </div>
    </Card>
  )
}
