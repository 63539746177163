import { css, cx } from '@emotion/css'
import * as React from 'react'
import { twMerge } from 'tailwind-merge'
import { getTextColor } from '../utils'
import { ColorifyOptions, colorify, twConfig } from '../utils/tailwind'

type Size = 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl'

const paddingBySize = {
  xs: 'px-2 py-2',
  sm: 'px-2 py-2',
  base: 'px-3 py-2',
  lg: 'px-4 py-2',
  xl: 'px-5 py-3',
  '2xl': 'px-6 py-4',
  '3xl': 'px-7 py-5',
  '4xl': 'px-8 py-6',
}

const shadowBySize = {
  xs: 'shadow-sm',
  sm: 'shadow-sm',
  base: 'shadow-md',
  lg: 'shadow-lg',
  xl: 'shadow-lg',
  '2xl': 'shadow-xl',
  '3xl': 'shadow-2xl',
  '4xl': 'shadow-2xl',
}

export type ButtonProps = ColorifyOptions & {
  size: Size
  type?: 'button' | 'submit' | 'reset'
  children?: React.ReactNode
  onClick?: () => void
  disabled?: boolean
  className?: string
  autoFocus?: boolean
  tabIndex?: string
  id?: string
}

const Button = ({
  children,
  type = 'button',
  className,
  ...props
}: ButtonProps) => {
  const [
    { color, lightColor, darkColor, hoverLightColor, hoverDarkColor },
    other,
  ] = colorify(props)

  // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const fontSize = twConfig.theme.fontSize?.[props.size]?.[0]
  const padding = paddingBySize?.[props.size]
  const shadow = shadowBySize?.[props.size]

  const missing = Object.entries({
    color,
    size: props.size,
    lightColor,
    darkColor,
    fontSize,
    padding,
    shadow,
  })
    .filter(([, value]) => !value)
    .map(([key]) => key)

  if (missing.length) {
    console.warn(props)
    console.warn(
      `Required props/options are missing on a Button: ${missing.join(', ')}.
  
  Original props: ${JSON.stringify(
    {
      color,
      size: props.size,
      lightColor,
      darkColor,
      fontSize,
      padding,
      shadow,
    },
    null,
    2
  )}
        
  Parent Component 👇`
    )
  }

  return (
    // @ts-expect-error  // Type '{ children: ReactNode; size: Size; onClick?:... Remove this comment to see the full error message
    <button
      type={type}
      className={twMerge(
        css({
          lineHeight: '1',
          background: lightColor,
          color: getTextColor(lightColor, 'white', 'black'),
          '.dark &': {
            background: darkColor,
            color: getTextColor(darkColor, 'white', 'black'),
          },
          fontSize,
          '&:hover, &:focus': {
            background: hoverLightColor,
            color: getTextColor(hoverLightColor, 'white', 'black'),
            '.dark &': {
              background: hoverDarkColor,
              color: getTextColor(hoverDarkColor, 'white', 'black'),
            },
          },
          '&:active': {
            boxShadow: 'none',
          },
        }),
        twMerge(
          `
          inline-flex
          flex-initial items-center
          justify-center gap-1.5
          whitespace-nowrap rounded-md font-medium
          shadow-lg transition duration-100
          ease-out
        `,
          shadow,
          padding,
          className
        )
      )}
      {...other}
    >
      {children}
    </button>
  )
}

export default Button
