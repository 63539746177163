import { createColumnHelper } from '@tanstack/react-table'
import * as React from 'react'
import { FaCaretDown, FaTimesCircle } from 'react-icons/fa'
import Button from '../../components/Button'
import Loader from '../../components/Loader'
import QueryGate from '../../components/QueryGate'
import Separator from '../../components/Separator'
import Table from '../../components/Table'
import useGetLatest from '../../hooks/useGetLatest'
import { renderLocale, useLocalesQuery } from '../../utils/locales'
import { useTable } from '../../hooks/useTable'
import { deviceOptions } from '../../options/deviceOptions'
import { Keyword } from '../../utils/Api'
import Excel from '../../utils/Excel'
import { formatDevice, formatOption } from '../../utils/Format'
import { formatLocale } from '../../utils/locales'
import { keywordsToExcelShorthand } from '../../utils/Phrases'

const columnHelper = createColumnHelper<Keyword>()

export function GeneratedKeywordsTable(props: {
  generatedKeywords: Keyword[]
  isFetching: boolean
  isOpen?: boolean
}) {
  const [showKeywords, setShowKeywords] = React.useState(props.isOpen ?? false)
  const downloadKeywordsShorthand = () => {
    Excel.saveToFile(
      'keywords',
      keywordsToExcelShorthand(props.generatedKeywords)
    )
  }

  const localesByIdQuery = useLocalesQuery({
    localeIds: props.generatedKeywords.map(d => d.localeId),
  })

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('phrase', {
        header: 'Keyword Phrase',
      }),
      columnHelper.accessor('device', {
        header: 'Device',
        cell: cellProps =>
          formatDevice(cellProps.getValue(), {
            short: false,
          }),
      }),
      columnHelper.accessor(
        (d, i) =>
          localesByIdQuery.data
            ? formatLocale(localesByIdQuery.data[i])
            : d.localeId,
        {
          header: 'Locale',
          cell: props => {
            return localesByIdQuery.data ? (
              renderLocale(localesByIdQuery.data[props.row.index]!)
            ) : (
              <Loader />
            )
          },
        }
      ),
      columnHelper.accessor(d => (d.groups ? d.groups.join(', ') : '-'), {
        id: 'groups',
        header: 'Keyword Groups',
        cell: props => props.getValue(),
      }),
    ],
    [localesByIdQuery.data]
  )

  const table = useTable({
    data: props.generatedKeywords,
    columns,
  })

  return showKeywords ? (
    <>
      {props.isFetching ? (
        <div className="italic opacity-50">Generating Keywords...</div>
      ) : null}
      {props.generatedKeywords?.length ? (
        <div className="rounded-lg border border-gray-200 dark:border-gray-800">
          <Table table={table} />
          <Separator />
          <div className="p-2">
            <Button
              onClick={downloadKeywordsShorthand}
              size="sm"
              color={['gray-400', 'gray-600']}
            >
              Download Shorthand Keywords
            </Button>
          </div>
        </div>
      ) : (
        <div className="italic opacity-50">
          No keywords were generated. Check your settings to verify you are
          supplying at least one valid keyword phrase, device and locale.
        </div>
      )}
      <div className="mt-2">
        <Button
          onClick={() => setShowKeywords(false)}
          color="gray-500"
          hoverColor="red-500"
          size="xs"
        >
          <FaTimesCircle className="inline" /> Close
        </Button>
      </div>
    </>
  ) : (
    <Button
      onClick={() => setShowKeywords(true)}
      color={['gray-100', 'gray-800']}
      hoverColor="blue-500"
      size="xs"
    >
      <FaCaretDown className="inline" /> View Total Tracked Keywords
    </Button>
  )
}
