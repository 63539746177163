import { Route } from '@tanstack/react-router'
import * as React from 'react'
import { useForm } from 'react-form'
import { FaCheck, FaUser } from 'react-icons/fa'
import { LocationGenerics } from '../../LocationGenerics'
import Button from '../../components/Button'
import Card from '../../components/Card'
import Container from '../../components/Container'
import Spinner from '../../components/Spinner'
import TextField from '../../components/TextField'
import { useProfileQuery, useSaveProfile } from '../../hooks/profile'
import useLogout from '../../hooks/useLogout'
import Validate from '../../utils/Validate'

export function Profile() {
  const profileQuery = useProfileQuery()

  const saveProfile = useSaveProfile()

  const {
    Form,
    meta: { isSubmitting },
  } = useForm({
    defaultValues: profileQuery.data,
    // @ts-expect-error  // Argument of type 'Profile | undefined' is not assi... Remove this comment to see the full error message
    onSubmit: values => saveProfile(values),
  })

  const logout = useLogout()

  return (
    <Container>
      <Card>
        <h3 className="flex items-center gap-2 text-2xl">
          <FaUser className="inline" />
          <div>My Profile</div>
          <Button
            size="sm"
            color="blue-500"
            onClick={() => {
              logout()
            }}
            className="ml-auto"
          >
            Log Out
          </Button>
        </h3>

        <div className="h-4" />
        <Form>
          <TextField
            field="givenName"
            label="First Name"
            placeholder="Enter First Name..."
            validate={Validate.required('First name is required.')}
          />
          <div className="h-2" />
          <TextField
            field="familyName"
            label="Last Name"
            placeholder="Enter Last Name..."
            validate={Validate.required('Last name is required.')}
          />
          <div className="h-2" />
          <TextField
            field="displayName"
            label="Display Name"
            placeholder="Enter a Display Name..."
          />
          <div className="h-2" />
          <TextField
            field="email"
            label="Email"
            placeholder="Enter an Email..."
            disabled
          />
          <div className="h-4" />
          <Button type="submit" color="green-500" size="base">
            {isSubmitting ? (
              <span>
                <Spinner color="white" /> Saving Profile
              </span>
            ) : (
              <span>
                <FaCheck className="inline" /> Save Profile
              </span>
            )}
          </Button>{' '}
        </Form>
      </Card>
    </Container>
  )
}
