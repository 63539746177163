// @generated by protoc-gen-connect-web v0.11.0 with parameter "target=ts"
// @generated from file nozzle/workspaces/v1/keyword_sources.proto (package nozzle.workspaces.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateKeywordSourceRequest, CreateKeywordSourceResponse, GenerateKeywordsRequest, GenerateKeywordsResponse, GetKeywordSourceRequest, GetKeywordSourceResponse, ListKeywordSourcesRequest, ListKeywordSourcesResponse, RefreshKeywordSourceRequest, RefreshKeywordSourceResponse, SuggestKeywordsRequest, SuggestKeywordsResponse, UpdateKeywordSourceRequest, UpdateKeywordSourceResponse } from "./keyword_sources_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * KeywordSourcesService is a service for managing keyword sources.
 *
 * @generated from service nozzle.workspaces.v1.KeywordSourcesService
 */
export const KeywordSourcesService = {
  typeName: "nozzle.workspaces.v1.KeywordSourcesService",
  methods: {
    /**
     * GenerateKeywords will take in a data model and phrase template and outputs all permutations.
     *
     * @generated from rpc nozzle.workspaces.v1.KeywordSourcesService.GenerateKeywords
     */
    generateKeywords: {
      name: "GenerateKeywords",
      I: GenerateKeywordsRequest,
      O: GenerateKeywordsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetKeywordSource will return a keyword source.
     *
     * @generated from rpc nozzle.workspaces.v1.KeywordSourcesService.GetKeywordSource
     */
    getKeywordSource: {
      name: "GetKeywordSource",
      I: GetKeywordSourceRequest,
      O: GetKeywordSourceResponse,
      kind: MethodKind.Unary,
    },
    /**
     * CreateKeywordSource will create a keyword source.
     *
     * @generated from rpc nozzle.workspaces.v1.KeywordSourcesService.CreateKeywordSource
     */
    createKeywordSource: {
      name: "CreateKeywordSource",
      I: CreateKeywordSourceRequest,
      O: CreateKeywordSourceResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ListKeywordSources will return a list of keyword sources.
     *
     * @generated from rpc nozzle.workspaces.v1.KeywordSourcesService.ListKeywordSources
     */
    listKeywordSources: {
      name: "ListKeywordSources",
      I: ListKeywordSourcesRequest,
      O: ListKeywordSourcesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * UpdateKeywordSource will update a keyword source.
     *
     * @generated from rpc nozzle.workspaces.v1.KeywordSourcesService.UpdateKeywordSource
     */
    updateKeywordSource: {
      name: "UpdateKeywordSource",
      I: UpdateKeywordSourceRequest,
      O: UpdateKeywordSourceResponse,
      kind: MethodKind.Unary,
    },
    /**
     * RefreshKeywordSource performs a onetime pull for serps.
     *
     * @generated from rpc nozzle.workspaces.v1.KeywordSourcesService.RefreshKeywordSource
     */
    refreshKeywordSource: {
      name: "RefreshKeywordSource",
      I: RefreshKeywordSourceRequest,
      O: RefreshKeywordSourceResponse,
      kind: MethodKind.Unary,
    },
    /**
     * SuggestKeywords takes a keyword and suggests variations of the inbound keyword.
     *
     * @generated from rpc nozzle.workspaces.v1.KeywordSourcesService.SuggestKeywords
     */
    suggestKeywords: {
      name: "SuggestKeywords",
      I: SuggestKeywordsRequest,
      O: SuggestKeywordsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

