import * as React from 'react'
import { groupByFacets } from '../utils/queryFacets'
import Select from './Select'
import { useGroupByState } from '../utils/searchParams'

export default function GroupByPicker() {
  const groupByState = useGroupByState()
  const groupByFacetQueriesById = {}

  groupByFacets.forEach(groupByFacet => {
    // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    groupByFacetQueriesById[groupByFacet.id] = groupByFacet.useOptionsQuery({
      enabled: groupByFacet.id === groupByState.state,
    })
  })

  const allowedPickerOptions = React.useMemo(
    () => groupByFacets.map(d => ({ label: d.label, value: d.id })),
    []
  )

  return (
    <Select
      options={allowedPickerOptions}
      value={groupByState.state}
      onChange={value =>
        groupByState.setState(value, {
          replace: true,
        })
      }
      placeholder={`Select a groupBy...`}
      className="text-sm"
      inlineLabel="Rollup"
    />
  )
}
