import * as React from 'react'
import { twMerge } from 'tailwind-merge'
import { inputTextStyles } from './Input'

//

const InlineLabeledInput = ({
  label,
  className,
  style,
  error,
  renderInput,
  ...rest
}: Omit<React.HTMLProps<HTMLInputElement>, 'label'> & {
  label: React.ReactNode
  error?: any
  renderInput?: JSX.Element
}) => {
  const inputProps = {
    ...rest,
    className: twMerge(
      inputTextStyles,
      `w-full flex-auto border-l border-gray-500/20 bg-transparent`
    ),
  }

  return (
    <span
      className={twMerge(
        `relative inline-flex flex-1 items-center rounded-md border border-gray-100 bg-gray-50
      dark:border-gray-700 dark:bg-gray-800
      `,
        error && 'text-red-500',
        className
      )}
      style={style}
    >
      <div
        className={twMerge(
          'flex-none px-2 font-bold',
          rest.disabled && 'opacity-30'
        )}
      >
        {label}
      </div>
      {renderInput
        ? React.cloneElement(renderInput, inputProps)
        : React.createElement('input', inputProps)}
      {typeof error === 'string' ? (
        <div className="absolute bottom-0 right-0 top-0 flex items-center rounded-md bg-red-500 px-2 text-white">
          {error}
        </div>
      ) : null}
    </span>
  )
}

export default InlineLabeledInput
