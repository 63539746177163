import axios from 'axios'
import * as React from 'react'
import { useForm } from 'react-form'
import {
  FaPaperPlane,
  FaPlus,
  FaTimesCircle,
  FaUser,
  FaUserPlus,
} from 'react-icons/fa'
import Anchor from '../../components/Anchor'
import Button from '../../components/Button'
import Separator from '../../components/Separator'
import Spinner from '../../components/Spinner'
import TextField from '../../components/TextField'
import UserInviteModal from '../../components/UserInviteModal'
import useModal from '../../hooks/useModal'
import usePopup from '../../hooks/usePopup'
import { useCreateUser } from '../../hooks/users'
import { getHubSpotIDFromCookie } from '../../utils'
import Validate from '../../utils/Validate'

export default function UserInviteForm() {
  const popup = usePopup()
  const showModal = useModal()
  const createUser = useCreateUser({ silent: true })
  // const [status, setStatus] = React.useState<'ready' | 'success'>('ready')

  const defaultValues = React.useMemo(() => {
    return { users: [{}] }
  }, [])

  const {
    Form,
    meta: { isSubmitting },
    pushFieldValue,
    removeFieldValue,
    // @ts-expect-error  // Property 'getFieldValue' does not exist on type '{... Remove this comment to see the full error message
    getFieldValue,
    setFieldValue,
    values,
    // @ts-expect-error  // Property 'reset' does not exist on type '{ Form: (... Remove this comment to see the full error message
    reset,
  } = useForm({
    defaultValues,
    onSubmit: async ({ users }) => {
      try {
        // @ts-expect-error  // Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
        await Promise.all(users.map(user => createUser(user)))
        await popup({
          title: `${users.length} Users Invited 🎉`,
          message: (
            <>
              Your new users' invitation emails are on their way! To sign-in,
              they can click the invitation link in their email or visit{' '}
              <Anchor href="https://app.nozzle.io">
                https://app.nozzle.io
              </Anchor>
              .
              {/* The invitation link can also be manually copied to your clipboard
                by clicking the "Copy" button. */}
            </>
          ),
        })
        reset()
      } catch (err: any) {
        if (err.response.status === 404) {
          await popup({
            color: 'red-500',
            title: 'User has already been invited',
            message: `The user that you have invited has already been invited to this workspace.`,
          })
        } else {
          await popup({
            color: 'red-500',
            title: 'Something went wrong! 😢',
            message: `An error occurred while inviting one or more users to your workspace. Please try again or contact support for more help.`,
          })
        }
      }
    },
  })

  return (
    <Form>
      <div className="space-y-6">
        {values.users.map((user, index) => {
          return (
            <div
              className="flex flex-wrap items-center gap-x-2 gap-y-1"
              key={index}
            >
              <div className="px-2">
                <FaUser className="opacity-70" />
              </div>
              <TextField
                field={`users.${index}.givenName`}
                validate={value =>
                  value
                    ? Validate.required('A first name is required')(value)
                    : ''
                }
                placeholder="First Name"
                autoComplete="given-name"
                className="w-52 flex-none"
              />
              <TextField
                field={`users.${index}.familyName`}
                validate={value =>
                  value
                    ? Validate.required('A last name is required')(value)
                    : ''
                }
                placeholder="Last Name"
                autoComplete="family-name"
                className="w-52 flex-none"
              />
              <div className="flex items-center gap-2">
                <TextField
                  field={`users.${index}.email`}
                  validate={value =>
                    // @ts-expect-error  // Property 'displayName' does not exist on type '{}'... Remove this comment to see the full error message
                    user.displayName ? Validate.email()(value) : ''
                  }
                  placeholder="email@provider.com"
                  autoComplete="email"
                  className="w-72"
                />
                {values.users.length > 1 ? (
                  <FaTimesCircle
                    className="inline cursor-pointer text-lg text-red-500 hover:text-red-700"
                    onClick={() => removeFieldValue('users', index)}
                  />
                ) : null}
              </div>
            </div>
          )
        })}
      </div>
      <div className="h-4" />
      <div className="flex gap-2">
        <Button
          size="sm"
          color="blue-500"
          onClick={() => pushFieldValue('users', {})}
        >
          <FaPlus /> Invite Another User
        </Button>
        <Button
          size="sm"
          color="gray-500"
          onClick={() =>
            showModal(() => (
              <UserInviteModal
                onUsersAdd={(values: any) => {
                  if (!Object.entries(getFieldValue('users')[0]).length) {
                    setFieldValue('users', values)
                  } else {
                    values.map((value: any) => pushFieldValue('users', value))
                  }
                }}
              />
            ))
          }
        >
          <FaPlus /> Import List of Users
        </Button>
      </div>
      <div className="h-4" />
      <Button type="submit" size="base" color="green-500">
        {isSubmitting ? (
          <>
            <Spinner color="white" /> Inviting Users
          </>
        ) : (
          <>
            <FaPaperPlane className="inline" /> Invite Users
          </>
        )}
      </Button>
    </Form>
  )
}
