import * as React from 'react'
import ReactDOM from 'react-dom'
import '../utils/BroadcastChannel-polyfill'

type StorageEntry = {
  channel?: BroadcastChannel
  state?: any
  subscribers: Subscriber[]
}

type Subscriber = () => void

type UpdaterFn<TInput, TOutput = TInput> = (input: TInput) => TOutput
type Updater<TInput, TOutput = TInput> = TOutput | UpdaterFn<TInput, TOutput>

//

const storageByKey: Record<string, StorageEntry> = {}

export function useSessionState<TData>(
  key: string,
  initialValue?: TData | (() => TData),
  options?: {
    syncOnWindowFocus?: boolean
  }
) {
  return useLocalState(key, initialValue, {
    ...options,
    storage: window.sessionStorage,
  })
}

export default function useLocalState<TData>(
  key: string,
  initialValue?: TData | (() => TData),
  options?: {
    storage?: Storage
    syncOnWindowFocus?: boolean
  }
) {
  const storage = options?.storage ?? window.localStorage
  const rerender_ = React.useReducer(() => ({}), {})[1]
  const mountedRef = React.useRef(false)
  const rerender = React.useCallback(() => {
    if (mountedRef.current) {
      rerender_()
    }
  }, [rerender_])

  React.useEffect(() => {
    mountedRef.current = true

    return () => {
      mountedRef.current = false
    }
  }, [])

  const safeSetState = React.useCallback(
    updater => {
      const newState = replaceEqualDeep(
        storageByKey[key].state,

        functionalUpdate(updater, storageByKey[key].state)
      )

      if (newState !== storageByKey[key].state) {
        storageByKey[key].state = newState
        return true
      }
    },
    [key]
  )

  const getInitialState = () => {
    let initialState = functionalUpdate(initialValue, undefined)

    try {
      const item = storage.getItem(key)
      // @ts-expect-error  // Type 'unknown' is not assignable to type 'TData | ... Remove this comment to see the full error message
      initialState = item ? JSON.parse(item) : initialState
    } catch {
      // nothing
    }

    return initialState
  }

  if (!storageByKey[key]) {
    storageByKey[key] = {
      subscribers: [],
      state: getInitialState(),
    }
  }

  const notify = React.useCallback(() => {
    Promise.resolve().then(() => {
      ReactDOM.unstable_batchedUpdates(() => {
        storageByKey[key].subscribers.forEach(sub => sub())
      })
    })
  }, [key])

  const setState = React.useCallback<(updater: Updater<TData>) => void>(
    updater => {
      const updated = safeSetState(updater)

      if (updated) {
        try {
          storage.setItem(key, JSON.stringify(storageByKey[key].state))
        } catch (error) {
          console.error(error)
        }

        notify()

        storageByKey[key].channel?.postMessage({
          type: 'sync',

          state: JSON.parse(JSON.stringify(storageByKey[key].state)),
        })
      }
    },
    [key, notify, safeSetState, storage]
  )

  const onSync = React.useCallback(
    newState => {
      if (storageByKey[key].subscribers[0] === rerender) {
        const updated = safeSetState(newState)

        if (updated) {
          notify()
        }
      }
    },
    [key, notify, rerender, safeSetState]
  )

  useOnWindowFocus(() => {
    onSync(getInitialState())
    // @ts-expect-error  // Argument of type 'boolean | undefined' is not assi... Remove this comment to see the full error message
  }, options?.syncOnWindowFocus)

  React.useEffect(() => {
    storageByKey[key].subscribers.push(rerender)

    // Update from other tabs
    const onBroadcastMessage = (event: any) => {
      if (event.data.type === 'sync') {
        onSync(event.data.state)
      }
    }

    if (!storageByKey[key].channel) {
      storageByKey[key].channel = new BroadcastChannel('useLocalState_' + key)
    }

    storageByKey[key].channel.addEventListener('message', onBroadcastMessage)

    return () => {
      storageByKey[key].subscribers = storageByKey[key].subscribers.filter(
        d => d !== rerender
      )

      storageByKey[key].channel.removeEventListener(
        'message',
        onBroadcastMessage
      )

      if (!storageByKey[key].subscribers.length) {
        storageByKey[key].channel.close()

        delete storageByKey[key].channel
      }
    }
  }, [initialValue, key, onSync, rerender, safeSetState])

  return [storageByKey[key].state as TData, setState] as const
}

function useOnWindowFocus(cb: () => void, enabled: boolean) {
  const ref = React.useRef(cb)
  ref.current = cb

  React.useEffect(() => {
    if (!enabled) {
      return
    }

    const callback = () => ref.current()

    window.addEventListener('focus', callback, false)

    return () => {
      // Be sure to unsubscribe if a new handler is set
      window.removeEventListener('focus', callback)
    }
  }, [cb, enabled])
}

export function functionalUpdate<TInput, TOutput = TInput>(
  updater: Updater<TInput, TOutput>,
  input: TInput
): TOutput {
  return typeof updater === 'function'
    ? (updater as UpdaterFn<TInput, TOutput>)(input)
    : updater
}

/**
 * This function returns `a` if `b` is deeply equal.
 * If not, it will replace any deeply equal children of `b` with those of `a`.
 * This can be used for structural sharing between JSON values for example.
 */
export function replaceEqualDeep(a: any, b: any) {
  if (a === b) {
    return a
  }

  const array = Array.isArray(a) && Array.isArray(b)

  if (array || (isPlainObject(a) && isPlainObject(b))) {
    const aSize = array ? a.length : Object.keys(a).length
    const bItems = array ? b : Object.keys(b)
    const bSize = bItems.length
    const copy = array ? [] : {}

    let equalItems = 0

    for (let i = 0; i < bSize; i++) {
      const key = array ? i : bItems[i]
      // @ts-expect-error  // Type 'unknown' cannot be used as an index type.
      copy[key] = replaceEqualDeep(a[key], b[key])
      // @ts-expect-error  // Type 'unknown' cannot be used as an index type.
      if (copy[key] === a[key]) {
        equalItems++
      }
    }

    return aSize === bSize && equalItems === aSize ? a : copy
  }

  return b
}

// Copied from: https://github.com/jonschlinkert/is-plain-object
function isPlainObject(o: any) {
  if (!hasObjectPrototype(o)) {
    return false
  }

  // If has modified constructor
  const ctor = o.constructor
  if (typeof ctor === 'undefined') {
    return true
  }

  // If has modified prototype
  const prot = ctor.prototype
  if (!hasObjectPrototype(prot)) {
    return false
  }

  // If constructor does not have an Object-specific method
  if (!prot.hasOwnProperty('isPrototypeOf')) {
    return false
  }

  // Most likely a plain Object
  return true
}

function hasObjectPrototype(o: any) {
  return Object.prototype.toString.call(o) === '[object Object]'
}
