import * as React from 'react'
import ReactDOM from 'react-dom'
import {
  FaArrowLeft,
  FaArrowRight,
  FaCheck,
  FaTimesCircle,
  FaUndo,
} from 'react-icons/fa'
import { twMerge } from 'tailwind-merge'
import Button from '../components/Button'
import {
  CalendarHeaderStyles,
  CalendarStyles,
  DatePickerMask,
  DatePickerStyles,
  DayStyles,
  MonthNameStyles,
  QuickPickOptionStyles,
  QuickPickStyles,
  QuickPickerStyles,
  RangeInputs,
  WeekStyles,
  WeekdayStyles,
  WeekdaysStyles,
} from '../components/DatePickerStyles'
import LabeledInput from '../components/LabeledInput'
import useClickOutside from '../hooks/useClickOutside'
import useDateRangePicker from '../hooks/useDateRangePicker'

//

const monthNamesShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]
const weekdayNamesShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

const id = 'nozzle-date-picker'

export default function useDateRangePickerUI({
  range,
  align,
  quickPicks,
  ...rest
}: any) {
  const elRef = React.useRef()
  if (typeof document !== 'undefined') {
    let el = document.getElementById(id)
    if (!el) {
      el = document.createElement('div')
      el.id = id
      document.body.appendChild(el)
    }
    // @ts-expect-error  // Type 'HTMLElement' is not assignable to type 'unde... Remove this comment to see the full error message
    elRef.current = el
  }

  const state = useDateRangePicker({
    range,
    quickPicks,
    ...rest,
  })

  const { textValue, getInputProps } = state

  const Render = React.useCallback(() => {
    const {
      isOpen,
      value,
      selectedQuickPick,
      dirty,
      calendars,
      submitInput,
      setIsOpen,
      revert,
      apply: preApply,
      setQuickPick,
      getCalendarsProps,
      getPreviousMonthProps,
      getNextMonthProps,
      inputs,
      // @ts-expect-error  // Property '__myState' does not exist on type '() =>... Remove this comment to see the full error message
    } = Render.__myState

    const cancel = () => {
      revert()
      setIsOpen(false)
    }

    // eslint-disable-next-line
    const clickOutsideRef = React.useRef()
    // eslint-disable-next-line
    useClickOutside(clickOutsideRef, (!value || !dirty) && cancel)

    return ReactDOM.createPortal(
      <DatePickerMask isOpen={isOpen}>
        <DatePickerStyles
          // @ts-expect-error  // Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
          ref={clickOutsideRef}
          hasQuickPicks={quickPicks && quickPicks.length}
          single={!range}
          isOpen={isOpen}
        >
          {isOpen ? (
            <>
              {quickPicks && quickPicks.length && (
                <QuickPickerStyles>
                  {quickPicks.map((quickPickGroup: any, i: any) => (
                    <QuickPickStyles key={i} index={i}>
                      {quickPickGroup.map((quickPick: any, i: any) => (
                        <QuickPickOptionStyles
                          key={i}
                          active={
                            selectedQuickPick &&
                            quickPick.label === selectedQuickPick.label
                          }
                          role="none"
                          color="gray-500"
                          onClick={() => setQuickPick(quickPick)}
                        >
                          {quickPick.label}
                        </QuickPickOptionStyles>
                      ))}
                    </QuickPickStyles>
                  ))}
                </QuickPickerStyles>
              )}
              <div
                className={twMerge(
                  `flex flex-auto flex-col flex-wrap items-center justify-center p-4`,
                  `lg:order-0 order-2 border-r-2 border-gray-100 dark:border-gray-800`
                )}
              >
                <div
                  className={twMerge(
                    `flex flex-wrap items-center justify-center gap-2`
                  )}
                >
                  {calendars.map((calendar: any, i: any) => (
                    <CalendarStyles {...calendar.getCalendarProps()} key={i}>
                      <CalendarHeaderStyles>
                        <MonthNameStyles>
                          {monthNamesShort[calendar.month]} {calendar.year}
                        </MonthNameStyles>
                      </CalendarHeaderStyles>
                      <WeekdaysStyles>
                        {weekdayNamesShort.map(weekday => (
                          <WeekdayStyles key={`${weekday}`}>
                            {weekday}
                          </WeekdayStyles>
                        ))}
                      </WeekdaysStyles>
                      {calendar.weeks.map((week: any, i: any) => (
                        <WeekStyles isFirst={i === 0} key={i} week={week}>
                          {week.days.map(
                            ({
                              date,
                              getDayProps,
                              selected,
                              selectable,
                              today,
                              isOldSelection,
                              isNewSelection,
                              rangeIndex,
                            }: any) => (
                              <DayStyles
                                {...getDayProps({
                                  selected,
                                  selectable,
                                  today,
                                  isOldSelection,
                                  isNewSelection,
                                  rangeIndex,
                                })}
                              >
                                {date ? date.format('D') : null}
                              </DayStyles>
                            )
                          )}
                        </WeekStyles>
                      ))}
                    </CalendarStyles>
                  ))}
                </div>

                <div className="flex items-center justify-center gap-2">
                  <Button
                    {...getPreviousMonthProps({
                      size: 'sm',
                      color: 'gray-500',
                    })}
                  >
                    <FaArrowLeft className="inline" /> Prev Month
                  </Button>
                  <Button
                    {...getNextMonthProps({
                      size: 'sm',
                      color: 'gray-500',
                    })}
                  >
                    Next Month <FaArrowRight className="inline" />
                  </Button>
                </div>
              </div>
              {range && (
                <RangeInputs>
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      submitInput()
                    }}
                    className="p-2"
                  >
                    <LabeledInput
                      {...inputs[0].getInputProps({
                        label: 'From Date',
                      })}
                    />
                    <div className="h-2" />
                    <LabeledInput
                      {...inputs[1].getInputProps({
                        label: 'To Date',
                      })}
                    />
                    <button type="submit" style={{ display: 'none' }} />
                  </form>
                  {dirty ? (
                    <div className="items-around flex flex-wrap gap-2 px-2">
                      <Button
                        size="base"
                        color="gray-500"
                        className="flex-1"
                        onClick={revert}
                      >
                        <FaUndo className="inline" /> Revert
                      </Button>
                      <Button
                        size="base"
                        color="red-500"
                        className="flex-1"
                        onClick={cancel}
                      >
                        <FaTimesCircle className="inline" /> Cancel
                      </Button>
                      <Button
                        size="base"
                        color="green-500"
                        className="flex-1"
                        onClick={preApply}
                      >
                        <FaCheck className="inline" /> Apply
                      </Button>
                    </div>
                  ) : (
                    <div className="flex px-2">
                      <Button
                        size="base"
                        color="red-500"
                        className="flex-1"
                        onClick={cancel}
                      >
                        <FaTimesCircle className="inline" /> Cancel
                      </Button>
                    </div>
                  )}
                </RangeInputs>
              )}
            </>
          ) : null}
        </DatePickerStyles>
      </DatePickerMask>,

      elRef.current
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // @ts-expect-error  // Property '__myState' does not exist on type '() =>... Remove this comment to see the full error message
  Render.__myState = state

  return {
    getInputProps,
    textValue,
    Render,
  }
}
