import * as React from 'react'
import { FaCheck, FaEye, FaEyeSlash } from 'react-icons/fa'
import { IoIosWarning } from 'react-icons/io'
import { MdError, MdLoop } from 'react-icons/md'
import { VscDebugStepOver } from 'react-icons/vsc'
import { openHubSpotChat } from '../utils/Analytics'
import Clickable from './Clickable'
import Tooltip from './Tooltip'

type Props = {
  error?: any
  small?: boolean
  onRetry?: (e?: any) => void
} & React.HTMLAttributes<HTMLDivElement>

export default function ErrorComp({ error, small, onRetry, ...rest }: Props) {
  const [show, setShow] = React.useState(import.meta.env.DEV)

  const errorMessage = error?.message

  const errorInfo = (
    <div>
      <div className="rounded border border-green-500 p-2 text-sm dark:border-green-800 dark:text-white">
        <div className="flex items-center gap-2 text-green-700 dark:text-green-400">
          <FaCheck className="inline text-lg" />
          <strong>
            The Nozzle dev team has been notified of this bug and will fix it as
            soon as possible.
          </strong>
        </div>
        <div className="h-2" />
        <hr className="opacity-50" />
        <div className="h-2" />
        <div className="flex items-center gap-2 font-bold">
          <IoIosWarning className="inline scale-125 transform text-xl text-yellow-500" />
          <div className="text-sm opacity-70">
            If you need mission critical assistance,{' '}
            <Clickable onClick={() => openHubSpotChat()}>
              click here to start a new chat
            </Clickable>{' '}
            with support (or use the chat bubble at the bottom-right of the
            screen).
          </div>
        </div>
      </div>
      <div className="h-2" />
      <div className="rounded border border-red-500 bg-red-100 px-2 py-1 dark:bg-black">
        <pre className="overflow-hidden whitespace-normal text-base font-bold text-red-500">
          Error: {errorMessage}
        </pre>
        <div className="h-2" />
        <pre className="max-h-64 text-xs opacity-70">{error?.stack}</pre>
      </div>
    </div>
  )

  React.useEffect(() => {
    if (show) {
      if (import.meta.env.PROD) {
        openHubSpotChat()
      }
    }
  }, [show])

  return (
    <div {...rest}>
      {error ? (
        small ? (
          <span className="flex items-center justify-center gap-1">
            <Tooltip
              tooltip={
                show ? (
                  <div>
                    <div className="text-lg font-bold">
                      <MdError className="inline-block text-red-500" /> Oh no!
                      You found a bug!
                    </div>
                    <div className="h-2" />
                    {errorInfo}
                  </div>
                ) : (
                  <span>
                    <MdError className="inline-block text-red-500" /> Oh no, you
                    found a bug! Click here to get help and more info.
                  </span>
                )
              }
            >
              <MdError
                className="text-red-500"
                onClick={e => {
                  e.preventDefault()
                  setShow(old => !old)
                }}
              />
            </Tooltip>
            {onRetry ? (
              <Tooltip tooltip="Retry">
                <button
                  onClick={e => {
                    e.persist()
                    e.stopPropagation()
                    onRetry(e)
                  }}
                >
                  <VscDebugStepOver className="inline-block" />
                </button>
              </Tooltip>
            ) : null}
            <Tooltip tooltip="Reload Window">
              <button
                onClick={e => {
                  e.persist()
                  e.stopPropagation()
                  window.location.reload()
                }}
              >
                <MdLoop className="inline-block" />
              </button>
            </Tooltip>
          </span>
        ) : (
          <>
            <div className="font-bold">
              <MdError className="inline-block text-red-500" /> Oh no! You found
              a bug!
            </div>
            <div className="h-1" />
            <div className="flex items-center gap-2 text-sm">
              <span>
                {show ? (
                  <FaEyeSlash className="inline" />
                ) : (
                  <FaEye className="inline" />
                )}{' '}
                <Clickable
                  onClick={(e: any) => {
                    e.stopPropagation()
                    setShow(old => !old)
                  }}
                >
                  {show ? 'Hide' : 'Report bug!'}
                </Clickable>
              </span>
              {onRetry ? (
                <span>
                  <VscDebugStepOver className="inline-block" />{' '}
                  <Clickable
                    onClick={(e: any) => {
                      e.stopPropagation()
                      onRetry()
                    }}
                  >
                    Retry
                  </Clickable>
                </span>
              ) : null}
              <span>
                <MdLoop className="inline-block" />{' '}
                <Clickable
                  onClick={(e: any) => {
                    e.stopPropagation()
                    window.location.reload()
                  }}
                >
                  Reload Window
                </Clickable>
              </span>
            </div>
            {show ? (
              <div>
                <div className="h-2" />
                {errorInfo}
              </div>
            ) : null}
          </>
        )
      ) : null}
    </div>
  )
}
