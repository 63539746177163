import { rankItem, rankings } from '@tanstack/match-sorter-utils'
import {
  CellContext,
  Column,
  ColumnDefTemplate,
  FilterFn,
  HeaderContext,
  Row,
  RowData,
  Table,
  TableOptions,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import * as React from 'react'
import { CounterProps } from '../components/Counter'
import { TableHeader, TableRow, TableWrapInner } from '../components/Table'
import { PartialByKey } from '../utils/types'

//

declare module '@tanstack/react-table' {
  interface TableMeta<TData extends RowData> {
    options: UseTableOptions<TData>
  }
  interface ColumnMeta<TData extends RowData, TValue> {
    tight?: boolean
    checkbox?: boolean
    expander?: boolean
    showQuickFilter?: boolean
    getHeaderProps?: (
      opts: HeaderContext<TData, TValue>
    ) => React.ComponentProps<typeof TableHeader>
    getCellProps?: (
      opts: CellContext<TData, TValue>
    ) => React.ComponentProps<'div'>
    getFilterLabel?: (
      value: unknown,
      context: { column: Column<TData, TValue>; table: Table<TData> }
    ) => string
  }
  interface FilterFns {
    fuzzy: FilterFn<unknown>
  }
}

export type UseTableOptions<TData extends RowData> = PartialByKey<
  Omit<TableOptions<TData>, 'getCoreRowModel'>,
  'filterFns'
> &
  UseTableMetaOptions<TData>

export type UseTableMetaOptions<TData extends RowData> = {
  isLoading?: boolean
  pagination?: UseTablePaginationOptions
  virtualize?: boolean
  counter?: UseTableCounterOptions
  compact?: boolean
  getRowProps?: (opts: {
    row: Row<TData>
    table: Table<TData>
  }) => React.ComponentProps<typeof TableRow>
  getTableWrapProps?: () => React.ComponentProps<typeof TableWrapInner>
  showToolbar?: boolean
  tight?: boolean
  showFilters?: boolean
  showGroupBy?: boolean
  subComponent?: ColumnDefTemplate<{ table: Table<TData>; row: Row<TData> }>
  selectChildrenMode?: 'subRows' | 'groupedRows' | 'all'
  isControlled?: boolean
}

export type UseTableCounterOptions = Pick<
  CounterProps,
  'showingName' | 'totalName'
> & {
  show?: boolean
}

export type UseTablePaginationOptions = {
  show?: boolean
  fill?: boolean
  pageSizeOptions?: number[]
}

// A fuzzy filter impl for table filtering
export const fuzzyFilterFn: FilterFn<unknown> = (
  row,
  columnId,
  value,
  addMeta
) => {
  const itemRank = rankItem(row.getValue(columnId), value, {
    threshold: rankings.MATCHES,
  })
  addMeta(itemRank)
  return itemRank.passed
}
fuzzyFilterFn.autoRemove = val => !val

export function useTable<TData extends RowData>(
  options: UseTableOptions<TData>
): Table<TData> {
  const initialState = React.useMemo(
    () => ({
      pageSize: 10,
      ...options.initialState,
    }),
    [options.initialState]
  )

  const columns = React.useMemo(
    () => [
      // createExpanderColumn(),[
      ...options.columns,
      {
        id: 'spacer',
      },
    ],
    [options.columns]
  )

  return useReactTable({
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    getGroupedRowModel: getGroupedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    globalFilterFn: 'fuzzy',
    enableSortingRemoval: false,
    filterFromLeafRows: true,
    // groupedColumnMode: 'remove',
    ...options,
    initialState,
    columns,
    filterFns: {
      fuzzy: fuzzyFilterFn,
      ...options.filterFns,
    },
    meta: {
      options: options as UseTableOptions<TData>,
      ...options.meta,
    },
  })
}
