import { useQuery } from 'react-query'
import { fetchColumns } from '../utils/Api'
import { queryKeyColumns } from './Constants'
import {
  formatBool,
  formatNumber,
  formatPercentage,
  formatRank,
  formatTitle,
} from './Format'

//

let promise: any

// bqId: "workspace_id"
// dataType: "integer"
// hidden: false
// id: "workspace_id"
// inverted: false
// name: "workspace id"
// nullable: false
// stage: "keyword"

const rollups = {
  result__rank: {
    precision: 0,
  },
  result__item_rank: {
    precision: 0,
  },
  result__measurements__pixels_from_top: {
    precision: 1,
  },
  result__measurements__percentage_of_viewport: {
    idLabel: 'result__measurements__above_the_fold_%',
    precision: 1,
    isPercentage: true,
  },
  result__measurements__percentage_of_dom: {
    idLabel: 'result__measurements__serp_%',
    precision: 2,
    isPercentage: true,
  },
  result__nozzle_metrics__click_through_rate: {
    precision: 2,
    isPercentage: true,
  },
  result__nozzle_metrics__estimated_traffic: {
    precision: 0,
  },
  result__nozzle_metrics__ppc_value: {
    precision: 2,
  },
  search_intent__traditional__version: {
    isPercentage: true,
    precision: 1,
  },
  search_intent__traditional__informational__score: {
    isPercentage: true,
    precision: 1,
  },
  search_intent__traditional__navigational__score: {
    isPercentage: true,
    precision: 1,
  },
  search_intent__traditional__commercial__score: {
    isPercentage: true,
    precision: 1,
  },
  search_intent__traditional__transactional__score: {
    isPercentage: true,
    precision: 1,
  },
  result__search_intent__traditional__informational__score: {
    isPercentage: true,
    precision: 1,
  },
  result__search_intent__traditional__navigational__score: {
    isPercentage: true,
    precision: 1,
  },
  result__search_intent__traditional__commercial__score: {
    isPercentage: true,
    precision: 1,
  },
  result__search_intent__traditional__transactional__score: {
    isPercentage: true,
    precision: 1,
  },
}

export function getColumns() {
  if (!promise) {
    promise = (async () => {
      try {
        const rawColumns = await fetchColumns()

        const columns = rawColumns
          .filter((d: any) => d.hidden !== true)
          .map(decorateColumn)

        const columnsByStage = {}
        columns.forEach((column: any) => {
          // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          columnsByStage[column.stage] = columnsByStage[column.stage] ?? []
          // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          columnsByStage[column.stage].push(column)
        })

        const getStageByColumnId = (columnId: any) =>
          columns.find((d: any) => d.id == columnId)?.stage

        // @ts-expect-error  // Property 'columns' does not exist on type 'Window ... Remove this comment to see the full error message
        window.columns = {
          columns,
          columnsByStage,
          getStageByColumnId,
        }

        // @ts-expect-error  // Property 'columns' does not exist on type 'Window ... Remove this comment to see the full error message
        return window.columns
      } catch (err) {
        promise = null
        throw err
      }
    })()
  }

  return promise
}

export function useColumnsQuery() {
  return useQuery([queryKeyColumns], () => getColumns(), {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  })
}

function decorateColumn(column: any) {
  column = {
    ...column,
    // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    ...rollups[column.id],
  }

  const formatterFn =
    column.id === 'result__rank'
      ? formatRank
      : column.isPercentage
      ? formatPercentage
      : column.dataType === 'float' || column.dataType === 'integer'
      ? formatNumber
      : column.dataType === 'bool'
      ? formatBool
      : (d: any) => d ?? ''

  const path = column.id.split('__')

  const render = (d: any, options = {}) =>
    // @ts-expect-error  // Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    formatterFn(d, {
      precision: column.precision,
      forcePrecision: true,
      ...options,
    })

  const format = (d: any, options = {}) =>
    // @ts-expect-error  // Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    formatterFn(d, {
      precision: column.precision,
      forcePrecision: true,
      string: true,
      ...options,
    })

  const accessor = (ranking: any) =>
    path.reduce((value: any, key: any) => value?.[key], ranking)

  const labels = (column.idLabel ?? column.id)
    .split('__')
    .map((d: any) => formatTitle(d.split('_').join(' ')))

  const label = labels.join(' › ')

  const value = column.id

  return {
    ...column,
    path,
    labels,
    label,
    value,
    render,
    format,
    accessor,
    // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    ...rollups[column.id],
  }
}
