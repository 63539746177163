import { useMutation, useQuery, useQueryClient } from 'react-query'
import useLoading from '../hooks/useLoading'
import useToast from '../hooks/useToast'
import { useActiveWorkspaceId } from '../hooks/workspaces'
import {
  User,
  deleteUser,
  fetchUser,
  fetchUsers,
  onUsersChangeSubscription,
  patchUser,
  postUser,
} from '../utils/Api'
import { queryKeyProfile, queryKeyUsers } from '../utils/Constants'
import useErrorPopup from './useErrorPopup'
import { useQuerySubscription } from './useQuerySubscription'

export function useUsers() {
  const workspaceId = useActiveWorkspaceId()

  useQuerySubscription({
    kind: queryKeyUsers,
    label: 'User',
    subscriber: onUsersChangeSubscription,
  })

  return useQuery([queryKeyUsers, workspaceId], () => fetchUsers(workspaceId), {
    enabled: !!workspaceId,
  })
}

export function useUser({ id }: any) {
  const workspaceId = useActiveWorkspaceId()

  useQuerySubscription({
    kind: queryKeyUsers,
    label: 'User',
    subscriber: onUsersChangeSubscription,
  })

  return useQuery(
    [queryKeyUsers, id, workspaceId],
    () => fetchUser(id, workspaceId),
    {
      enabled: !!(workspaceId && id),
    }
  )
}

//

export function useRemoveUserById(
  options: {
    workspaceId?: string
  } = {}
) {
  const toast = useToast()
  const errorPopup = useErrorPopup()
  const workspaceId = useActiveWorkspaceId()
  const queryClient = useQueryClient()

  return useMutation(
    (userId: string) => deleteUser(userId, options.workspaceId ?? workspaceId),
    {
      onSuccess: async () => {
        toast({
          message: 'User Deleted',
          color: 'green-500',
        })
        await queryClient.invalidateQueries(queryKeyUsers)
      },
      onError: err => {
        console.error(err)
        errorPopup('Failed to remove user.')
      },
    }
  )
}

export function useSaveUser(options: { workspaceUser?: boolean } = {}) {
  const activeWorkspaceId = useActiveWorkspaceId()
  const toast = useToast()
  const errorPopup = useErrorPopup()
  const [, setLoading] = useLoading()
  const queryClient = useQueryClient()

  const { mutateAsync } = useMutation(
    (newUser: User) =>
      patchUser(
        newUser,
        // @ts-expect-error  // Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
        options.workspaceUser ? newUser.workspaceId : activeWorkspaceId
      ),
    {
      onMutate: () => {
        setLoading(true)
      },
      onSuccess: async data => {
        toast({
          message: 'User Saved',
          color: 'green-500',
        })
        await queryClient.invalidateQueries(queryKeyUsers)
        await queryClient.invalidateQueries(queryKeyProfile)
        queryClient.setQueryData([queryKeyUsers, data.id], data)
      },
      onError: err => {
        console.error(err)
        errorPopup('Failed to save user.')
      },
      onSettled: () => {
        setLoading(false)
      },
    }
  )

  return mutateAsync
}

//

export function useCreateUser(props: { silent?: boolean } = {}) {
  const toast = useToast()
  const errorPopup = useErrorPopup()
  const [, setLoading] = useLoading()
  const queryClient = useQueryClient()
  const activeWorkspaceId = useActiveWorkspaceId()

  const { mutateAsync } = useMutation(
    (newUser: User) => postUser(newUser, activeWorkspaceId),
    {
      onMutate: () => {
        setLoading(true)
      },
      onSuccess: async () => {
        if (!props.silent) {
          toast({
            message: 'User Created',
            color: 'green-500',
          })
        }
        await queryClient.invalidateQueries(queryKeyUsers)
      },
      onError: err => {
        console.error(err)
        if (!props.silent) {
          errorPopup('Failed to create user.')
        }
      },
      onSettled: () => {
        setLoading(false)
      },
    }
  )

  return mutateAsync
}
