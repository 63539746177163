import * as React from 'react'

//
import usePopup from '../hooks/usePopup'
import { openHubSpotChat } from '../utils/Analytics'

export default function useErrorPopup() {
  const popup = usePopup()

  return React.useCallback(
    async message => {
      const confirmed = await popup({
        title: 'Something went wrong! 😢',
        message: message
          ? `${message} Please try again or contact support for more help.`
          : 'An error occurred while trying to process your request. Please try again or contact support for more help.',
        color: 'red-500',
        confirmText: 'Start New Support Chat',
        cancelText: 'Dismiss',
        isConfirm: true,
      })
      if (confirmed) {
        openHubSpotChat()
      }
    },
    [popup]
  )
}
