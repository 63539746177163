export const rankGroupOptions = [
  {
    label: '1',
    value: 'rank_group_1',
  },
  {
    label: '2',
    value: 'rank_group_2',
  },
  {
    label: '3',
    value: 'rank_group_3',
  },
  {
    label: '4 - 6',
    value: 'rank_group_4_6',
  },
  {
    label: '7 - 10',
    value: 'rank_group_7_10',
  },
  {
    label: '11 - 15',
    value: 'rank_group_11_15',
  },
  {
    label: '16 - 20',
    value: 'rank_group_16_20',
  },
  {
    label: '21 - 50',
    value: 'rank_group_21_50',
  },
  {
    label: '51+',
    value: 'rank_group_51',
  },
] as const
