import * as React from 'react'
import { useQueryClient } from 'react-query'
import { useDebouncedState } from '../hooks/useDebouncedState'
import Select from './Select'
import { TopicPb } from '../utils/proto'
import { useTopicSearchQuery, useTopicsByIdQuery } from '../utils/topics'

type TopicOption = {
  value: string
  label: string
  meta: any
}

export function TopicPicker({
  projectId,
  value,
  onChange,
  onBlur,
  setTouched,
  excludeTopicIds,
  allowEmpty,
  ...rest
}: {
  projectId: string
  value?: string
  onChange?: (value: string, topic?: TopicPb) => void
  onBlur?: (e: any) => void
  setTouched?: (touched: boolean) => void
  excludeTopicIds?: string[]
  allowEmpty?: boolean
} & Omit<
  React.ComponentProps<typeof Select>,
  'options' | 'value' | 'onChange'
>) {
  const queryClient = useQueryClient()

  const [search, debouncedSearch, setSearch] = useDebouncedState({
    initialValue: '',
  })

  const topicsByIdQuery = useTopicsByIdQuery({
    projectId,
    topicIds: value ? [value] : [],
  })

  const topicSearchQuery = useTopicSearchQuery({
    projectId,
    search: debouncedSearch,
  })

  const options = React.useMemo((): TopicOption[] => {
    const options = [
      ...(topicSearchQuery.data ?? []),
      value && !topicSearchQuery.data?.find(d => String(d.id) === value)
        ? topicsByIdQuery.data?.[value]
        : undefined,
    ]
      ?.filter(Boolean)
      .map(topic => ({
        value: String(topic.id),
        label: topic.name ?? 'N/A',
        meta: topic,
      }))

    if (allowEmpty && (!search || !value)) {
      options?.unshift({
        value: '',
        label: 'None',
        meta: undefined!,
      })
    }

    return options
  }, [allowEmpty, search, topicSearchQuery.data, topicsByIdQuery.data, value])

  const filteredOptions = React.useMemo(() => {
    if (excludeTopicIds) {
      return options.filter(d => !excludeTopicIds?.includes(d.value))
    }

    return options
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, excludeTopicIds])

  // const showModal = useModal()

  // const showAddUrlModal = () => {
  //   const modal = showModal(() => (
  //     <AddTopicModal
  //       defaultValues={{
  //         brandId: BigInt(projectId),
  //       }}
  //       onSuccess={res => {
  //         modal.close()
  //         onChange?.(String(res.topic?.id), res.topic)
  //       }}
  //       onCancel={() => modal.close()}
  //     />
  //   ))
  // }

  // const showImportUrlsModal = () => {
  //   const modal = showModal(() => (
  //     <AddTopicModal onSuccess={modal.close} onCancel={modal.close} />
  //   ))
  // }

  return (
    <Select<TopicOption>
      {...{
        closeOnSelect: rest.closeOnSelect ?? (value ? true : false),
        placeholder: 'Search for a Topic...',
        className: 'inline-block',
        sort: false,
        ...rest,
        isLoading:
          search !== debouncedSearch ||
          topicSearchQuery.isFetching ||
          topicsByIdQuery.isLoading,
        onInputChange: (event: any) => setSearch(event.target.value),
        options: filteredOptions,
        value,
        onChange: (next: any) => {
          const topic = topicSearchQuery.data?.find(d => d.id === next)

          queryClient.setQueryData(['topic', Number(next)], topic, {
            updatedAt: Date.now() + 1000 * 60 * 60 * 24,
          })
          // setSearch('')
          onChange?.(next, topic as any)
        },
        onBlur: (e: Event) => {
          setTouched?.(true)
          onBlur?.(e)
        },
        renderOption: option => {
          return option.label
        },
        // afterOptions: (
        //   <div className="flex gap-1 p-2">
        //     <ButtonPlain
        //       className="bg-gray-100 text-xs hover:bg-blue-500 hover:text-white dark:bg-gray-800"
        //       onClick={showAddUrlModal}
        //     >
        //       <FaPlus /> Add URL
        //     </ButtonPlain>
        //     <ButtonPlain
        //       className="bg-gray-100 text-xs hover:bg-blue-500 hover:text-white dark:bg-gray-800"
        //       onClick={showImportUrlsModal}
        //     >
        //       <TbPackageImport /> Import URLs
        //     </ButtonPlain>
        //   </div>
        // ),
      }}
    />
  )
}
