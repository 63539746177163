import * as React from 'react'

export default function DashboardHeader({
  children,
  right,
}: {
  children: any
  right?: any
}) {
  return (
    <div
      className="
      flex flex-wrap items-center justify-between gap-2
      text-xl font-bold
    "
    >
      <div>{children}</div>
      {right ? <div>{right}</div> : null}
    </div>
  )
}
