import { Locale, renderLocale } from '../../utils/locales'
import { twMerge } from 'tailwind-merge'
import {
  formatCurrency,
  formatDevice,
  formatNumber,
  formatPercentage,
  formatRank,
} from '../../utils/Format'
import { formatLocale } from '../../utils/locales'
import { DevicePb, EnginePb, KeywordPb, MetricPb } from '../../utils/proto'

export type Aggregation = 'sum' | 'avg' | 'min' | 'p25' | 'p50' | 'p75' | 'max'
export type PostAggregation =
  | 'value'
  | 'change'
  | 'stepChange'
  | 'best'
  | 'worst'

type MetricRenderOpts = {
  aggregation?: Aggregation
  postAggregation?: PostAggregation
  isPercentage?: boolean
}

function createMetric<TId extends string>({
  id,
  label,
  defaultAggregation,
  formatter,
  renderer,
  precision,
  getDefaultValue,
  inverted,
  tooltip,
}: {
  id: TId
  label: string
  defaultAggregation: Aggregation
  formatter?: (value: number, opts: { precision: number }) => string
  renderer?: (
    value: number,
    opts?: { precision?: number; forcePrecision?: boolean }
  ) => any
  precision?: number | [number, number] | Record<Aggregation, [number, number]>
  inverted?: boolean
  forcePrecision?: boolean
  getDefaultValue?: () => number
  tooltip?: any
}) {
  inverted = inverted ?? false

  const getValue = (metric?: MetricPb, opts: MetricRenderOpts = {}) => {
    return ((metric as any)?.[opts.aggregation || defaultAggregation]?.[
      opts.postAggregation || 'value'
    ] ?? getDefaultValue?.()) as number
  }

  const getPrecision = (opts: Omit<MetricRenderOpts, 'postAggregation'>) => {
    if (typeof precision === 'number') {
      return precision
    }

    if (Array.isArray(precision)) {
      return opts?.isPercentage ? precision[1] : precision[0]
    }

    return (
      precision?.[opts.aggregation || defaultAggregation][
        opts?.isPercentage ? 1 : 0
      ] ?? 0
    )
  }

  const formatValue = (value: number, opts: MetricRenderOpts = {}) => {
    return (formatter || formatNumber)(value, {
      precision: getPrecision(opts),
      forcePrecision: true,
    })
  }

  const renderValue = (value: number, opts: MetricRenderOpts = {}) => {
    if (!renderer) {
      return formatValue(value, opts)
    }

    return renderer(value, {
      precision: getPrecision(opts),
      forcePrecision: true,
    })
  }

  const getFormattedValue = (metric: MetricPb, opts: MetricRenderOpts = {}) => {
    return formatValue(getValue(metric, opts), opts)
  }

  const getRenderedValue = (metric: MetricPb, opts: MetricRenderOpts = {}) => {
    return renderValue(getValue(metric, opts), opts)
  }

  return {
    id,
    label,
    getPrecision,
    getValue,
    getFormattedValue,
    formatValue,
    getRenderedValue,
    renderValue,
    inverted,
    tooltip,
  }
}

export function formatEngine(engine: EnginePb) {
  return engine === EnginePb.GOOGLE ? 'Google' : 'Other'
}

export function renderKeyword(
  keyword: KeywordPb | undefined,
  localesById: Record<string, Locale> | undefined,
  opts?: {
    wrap?: boolean
    short?: boolean
  }
) {
  if (!keyword) {
    return 'Unknown'
  }

  const localeId = Number(keyword.localeId)

  const singleLocale =
    (opts?.short ?? true) &&
    !Object.values(localesById ?? {}).some(d => d !== localesById?.[localeId])

  const locale = singleLocale ? undefined : localesById?.[localeId]
  const wrap = opts?.wrap ?? true

  return (
    <span className={twMerge('flex items-center gap-x-2', wrap && 'flex-wrap')}>
      {[
        keyword.phrase,
        formatDevice(keyword.device, { v2: true }),
        locale ? renderLocale(locale) : null,
      ].filter(Boolean)}
    </span>
  )
}

export const keywordColumns = {
  phrase: {
    id: 'phrase',
    label: 'Phrase',
    getValue: (keyword: KeywordPb) => keyword.phrase,
  },
  device: (() => {
    return {
      id: 'device',
      label: 'Device',
      getFormattedValue: (device: DevicePb | undefined) => {
        return formatDevice(device, { v2: true, string: true })
      },
      getRenderedValue: (device: DevicePb | undefined) => {
        return formatDevice(device, { v2: true })
      },
    }
  })(),
  locale: (() => {
    const getValue = (
      id: number | undefined,
      localesById: Record<string, Locale> | undefined
    ) => {
      return id ? localesById?.[String(id)] : undefined
    }

    return {
      id: 'locale',
      label: 'Locale',
      getValue,
      getFormattedValue: (
        id: number | undefined,
        localesById: Record<string, Locale> | undefined
      ) => {
        const value = getValue(id, localesById)
        if (!value) {
          return id
        }
        return formatLocale(value)
      },
      getRenderedValue: (
        id: number | undefined,
        localesById: Record<string, Locale> | undefined
      ) => {
        const value = getValue(id, localesById)
        if (!value) {
          return id
        }
        return renderLocale(value)
      },
    }
  })(),
} as const

export const metrics = {
  aboveTheFoldPercentage: createMetric({
    id: 'aboveTheFoldPercentage',
    label: 'Above The Fold Percentage',
    defaultAggregation: 'avg',
    formatter: formatPercentage,
    precision: 2,
    tooltip:
      'The average above-the-fold-percentage across keywords for the focus domain in the cluster',
  }),
  adsHighTopOfPageBid: createMetric({
    id: 'adsHighTopOfPageBid',
    label: 'Ads High Top Of Page Bid',
    defaultAggregation: 'avg',
    formatter: formatCurrency,
    precision: 2,
  }),
  adsLowTopOfPageBid: createMetric({
    id: 'adsLowTopOfPageBid',
    label: 'Ads Low Top Of Page Bid',
    defaultAggregation: 'avg',
    formatter: formatCurrency,
    precision: 2,
  }),
  adwordsCompetition: createMetric({
    id: 'adwordsCompetition',
    label: 'Adwords Competition',
    defaultAggregation: 'avg',
    precision: 2,
  }),
  availableTraffic: createMetric({
    id: 'availableTraffic',
    label: 'Available Traffic',
    defaultAggregation: 'sum',
    precision: 0,
    tooltip:
      'The total organic clicks available for the cluster (available traffic = search volume - no click - ads)',
  }),
  cpc: createMetric({
    id: 'cpc',
    label: 'CPC',
    defaultAggregation: 'avg',
    formatter: formatCurrency,
    precision: 2,
    tooltip: 'The average CPC for the keywords in the cluster',
  }),
  ctr: createMetric({
    id: 'ctr',
    label: 'CTR',
    defaultAggregation: 'avg',
    formatter: formatPercentage,
    precision: 1,
    tooltip:
      'The average of the the total sum CTR for ranking results that match your focus domain across the cluster',
  }),
  descriptionCount: createMetric({
    id: 'descriptionCount',
    label: 'Description Count',
    defaultAggregation: 'sum',
    precision: 0,
  }),
  estimatedTraffic: createMetric({
    id: 'estimatedTraffic',
    label: 'Estimated Traffic',
    defaultAggregation: 'sum',
    precision: 0,
    tooltip:
      'The current total estimated traffic for the focus domain across the cluster',
  }),
  keywordCount: createMetric({
    id: 'keywordCount',
    label: 'Keyword Count',
    defaultAggregation: 'sum',
    precision: 0,
    tooltip: 'The total number of unique keywords in the cluster',
  }),
  maxEstimatedTraffic: createMetric({
    id: 'maxEstimatedTraffic',
    label: 'Max Estimated Traffic',
    defaultAggregation: 'sum',
    precision: 0,
    tooltip:
      'The highest amount of attainable traffic if you were to rank #1 for every keyword in the cluster',
  }),
  noClickCtr: createMetric({
    id: 'noClickCtr',
    label: 'No Click CTR',
    defaultAggregation: 'avg',
    formatter: formatPercentage,
    precision: 1,
  }),
  paidCtr: createMetric({
    id: 'paidCtr',
    label: 'Paid CTR',
    defaultAggregation: 'avg',
    formatter: formatPercentage,
    precision: 1,
  }),
  pixelsFromTop: createMetric({
    id: 'pixelsFromTop',
    label: 'Pixels From Top',
    defaultAggregation: 'avg',
    precision: 0,
    inverted: true,
    tooltip:
      'The average pixels-from-top across keywords for the focus domain in the cluster',
  }),
  resultCount: createMetric({
    id: 'resultCount',
    label: 'Result Count',
    defaultAggregation: 'sum',
    precision: 0,
  }),
  searchVolume: createMetric({
    id: 'searchVolume',
    label: 'Search Volume',
    defaultAggregation: 'sum',
    precision: 0,
    tooltip: 'The combined search volume of all the keywords in the cluster',
  }),
  serpCount: createMetric({
    id: 'serpCount',
    label: 'SERP Count',
    defaultAggregation: 'sum',
    precision: 0,
  }),
  serpPercentage: createMetric({
    id: 'serpPercentage',
    label: 'SERP Percentage',
    defaultAggregation: 'avg',
    formatter: formatPercentage,
    precision: 2,
    tooltip:
      'The average SERP-percentage across keywords for the focus domain in the cluster',
  }),
  titleCount: createMetric({
    id: 'titleCount',
    label: 'Title Count',
    defaultAggregation: 'sum',
    precision: 0,
  }),
  topPaidAdjustedRank: createMetric({
    id: 'topPaidAdjustedRank',
    label: 'Paid Adjusted Rank',
    defaultAggregation: 'avg',
    renderer: formatRank,
    precision: 1,
    inverted: true,
  }),
  topRank: createMetric({
    id: 'topRank',
    label: 'Rank',
    defaultAggregation: 'avg',
    renderer: formatRank,
    precision: 1,
    inverted: true,
    getDefaultValue: () => 101,
    tooltip:
      'The average result rank that matches your focus domain across the cluster',
  }),
  trafficOpportunity: createMetric({
    id: 'trafficOpportunity',
    label: 'Traffic Opportunity',
    defaultAggregation: 'sum',
    precision: 0,
    tooltip:
      'The max estimated traffic minus current estimated traffic for the focus domain across the cluster',
  }),
  unpaidCtr: createMetric({
    id: 'unpaidCtr',
    label: 'Unpaid CTR',
    defaultAggregation: 'avg',
    formatter: formatPercentage,
    precision: 1,
  }),
  urlCount: createMetric({
    id: 'urlCount',
    label: 'URL Count',
    defaultAggregation: 'sum',
    precision: 0,
    tooltip:
      'The number of unique URLs from the top ten results (exluding ads) that the keywords in the cluster have in common',
  }),
  categoryConfidence: createMetric({
    id: 'confidence',
    label: 'Confidence',
    defaultAggregation: 'avg',
    formatter: formatPercentage,
    precision: 0,
  }),
  salience: createMetric({
    id: 'salience',
    label: 'Salience',
    defaultAggregation: 'avg',
    precision: 1,
  }),
  sentimentScore: createMetric({
    id: 'sentimentScore',
    label: 'Sentiment Score',
    defaultAggregation: 'avg',
    precision: 1,
  }),
  sentimentMagnitude: createMetric({
    id: 'sentimentMagnitude',
    label: 'Sentiment Magnitude',
    defaultAggregation: 'avg',
    precision: 1,
  }),
} as const

export const serpMetricsList = [
  metrics.searchVolume,
  metrics.availableTraffic,
  metrics.maxEstimatedTraffic,
  metrics.cpc,
  metrics.adsHighTopOfPageBid,
  metrics.adsLowTopOfPageBid,
  metrics.adwordsCompetition,
  metrics.noClickCtr,
  metrics.paidCtr,
  metrics.unpaidCtr,
]

export const resultMetricsList = [
  metrics.serpCount,
  metrics.keywordCount,
  metrics.resultCount,
  metrics.urlCount,
  metrics.topRank,
  metrics.topPaidAdjustedRank,
  metrics.ctr,
  metrics.estimatedTraffic,
  metrics.trafficOpportunity,
  metrics.pixelsFromTop,
  metrics.aboveTheFoldPercentage,
  metrics.serpPercentage,
  metrics.titleCount,
  metrics.descriptionCount,
] as const

export const entityMetricsList = [
  metrics.salience,
  metrics.sentimentScore,
  metrics.sentimentMagnitude,
]

// Filtered Lists

export const clusterMetricsList = [
  metrics.searchVolume,
  metrics.availableTraffic,
  metrics.maxEstimatedTraffic,
  metrics.cpc,
]

export const clusterFocusMetricsList = [
  metrics.topRank,
  metrics.trafficOpportunity,
  metrics.ctr,
  metrics.estimatedTraffic,
  metrics.pixelsFromTop,
  metrics.aboveTheFoldPercentage,
  metrics.serpPercentage,
]

export const urlMetricsList = [
  metrics.topRank,
  metrics.topPaidAdjustedRank,
  metrics.ctr,
  metrics.estimatedTraffic,
  metrics.pixelsFromTop,
  metrics.aboveTheFoldPercentage,
  metrics.serpPercentage,
  metrics.titleCount,
  metrics.descriptionCount,
] as const

export const keywordMetricsList = [
  keywordColumns.phrase,
  keywordColumns.device,
  keywordColumns.locale,
]

export const relatedPhrasesMetricsList = [
  metrics.serpCount,
  metrics.keywordCount,
]

export const featuresMetricsList = [
  metrics.serpCount,
  metrics.keywordCount,
  metrics.topRank,
]

// export const domainMetrics = [
//   metrics.topRank,
//   metrics.topPaidAdjustedRank,
//   metrics.trafficOpportunity,
//   metrics.ctr,
//   metrics.estimatedTraffic,
//   metrics.pixelsFromTop,
//   metrics.aboveTheFoldPercentage,
//   metrics.serpPercentage,
// ] as const

// export const featureMetrics = [
//   // metrics.topRank,
//   metrics.topRank,
//   metrics.topPaidAdjustedRank,
// ] as const
