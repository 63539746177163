import { css, cx } from '@emotion/css'
import * as React from 'react'
import { FaCheck, FaExclamationTriangle, FaInfo } from 'react-icons/fa'
import { IoIosFlash } from 'react-icons/io'
import { twMerge } from 'tailwind-merge'
import useZustand from '../hooks/useZustand'
import { getTextColor } from '../utils'
import { colorify } from '../utils/tailwind'

//

export default function Toasts() {
  const [toasts] = useZustand(state => state.toasts)

  return (
    <div
      className={twMerge(
        `pointer-events-none fixed bottom-0 right-0 top-0 z-[20000]`
      )}
    >
      <div className="h-full w-[400px] max-w-full space-y-2 overflow-auto p-4">
        {toasts.map(toast => {
          const [{ lightColor, darkColor }] = colorify(toast as any)

          return (
            <div
              key={toast.id}
              onClick={() => {
                // @ts-expect-error  // Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
                toast.onClick()
              }}
              className={twMerge(
                `pointer-events-auto flex w-full cursor-pointer flex-row items-center gap-2 rounded-md p-2
              font-bold text-white shadow-lg shadow-black/50
            `,
                css({
                  background: lightColor,
                  color: getTextColor(lightColor, 'white', 'black'),

                  '.dark &': {
                    background: darkColor,
                    color: getTextColor(darkColor, 'white', 'black'),
                  },
                })
              )}
            >
              <div className="px-2">
                {toast.color === 'success' ? (
                  <FaCheck />
                ) : toast.color === 'danger' ? (
                  <FaExclamationTriangle />
                ) : toast.color === 'warning' ? (
                  <IoIosFlash />
                ) : (
                  <FaInfo />
                )}
              </div>
              {toast.message ? (
                <span className="message">{toast.message}</span>
              ) : (
                ''
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}
