import { css } from '@emotion/css'
import * as React from 'react'
import { useField } from 'react-form'
import { twMerge } from 'tailwind-merge'
import { twConfig } from '../utils/tailwind'
import { withProps } from '../utils/withProps'

//

const FieldErrorStyles = withProps('div')(({ className, ...props }) => ({
  ...props,
  className: twMerge(
    css({
      'font-size': '0.9rem',

      color: `${twConfig.theme.colors.red['500']}`,
    }),
    className
  ),
}))

type Props = {
  field: string
  show?: any
  children?: any
}

export default function FieldError({ field, show, children, ...rest }: Props) {
  const fieldInfo = useField(field)

  return fieldInfo.meta.error && (show || fieldInfo.meta.isTouched) ? (
    <FieldErrorStyles {...rest}>
      {typeof fieldInfo.meta.error === 'string'
        ? fieldInfo.meta.error
        : children}
    </FieldErrorStyles>
  ) : null
}
