import { PlainMessage, Timestamp } from '@bufbuild/protobuf'
import * as Plot from '@observablehq/plot'
import { createColumnHelper } from '@tanstack/react-table'
import { extent, range, sum } from 'd3-array'
import { interpolateRgb } from 'd3-interpolate'
import { scaleLinear } from 'd3-scale'
import moment from 'moment'
import * as React from 'react'
import { IoWarning } from 'react-icons/io5'
import { UseQueryResult } from 'react-query'
import { twMerge } from 'tailwind-merge'
import Loader from '../../components/Loader'
import { ObservablePlot } from '../../components/ObservablePlot'
import Table, { TableCell, TableEl, TableRow } from '../../components/Table'
import Tooltip from '../../components/Tooltip'
import { useAccount } from '../../hooks/account'
import { renderLocale, useLocalesQuery } from '../../utils/locales'
import { Locale } from '../../utils/locales'
import { useTable } from '../../hooks/useTable'
import { uniqBy } from '../../utils'
import { formatDevice, formatNumber } from '../../utils/Format'
import { formatLocale } from '../../utils/locales'
import { formatSchedule } from '../../utils/schedules'
import {
  AccountViewPb,
  GenerateKeywordsResponsePb,
  PeriodPb,
} from '../../utils/proto'

type GeneratedKeywordRow = PlainMessage<
  GenerateKeywordsResponsePb['keywords'][number]
>

const columnHelper = createColumnHelper<GeneratedKeywordRow>()

export function GeneratedKeywordsTableV2({
  generatedKeywords,
  isFetching,
}: {
  generatedKeywords?: GeneratedKeywordRow[]
  isFetching: boolean
}) {
  // const downloadKeywordsShorthand = () => {
  //   Excel.saveToFile('keywords', keywordsToExcelShorthand(generatedKeywords))
  // }

  const accountQuery = useAccount({ view: AccountViewPb.FULL })
  const account = accountQuery.data
  const subscription = account?.subscriptions[0]
  const meteredSubscription = account?.subscriptions[0]?.meteredSubscriptions[0]

  const localesByIdQuery = useLocalesQuery({
    localeIds: uniqBy(
      generatedKeywords?.map(d => Number(d.keyword?.localeId)) ?? []
    ),
  })

  const executionTimesExtent = React.useMemo(
    () =>
      extent(
        generatedKeywords?.flatMap(d =>
          d.times.map(d => new Timestamp(d).toDate())
        ) ?? []
      ),
    [generatedKeywords]
  )

  const executionTimesColorScale = React.useMemo(
    () =>
      scaleLinear()
        // @ts-expect-error  // Argument of type '[undefined, undefined] | [Date, ... Remove this comment to see the full error message
        .domain(executionTimesExtent)
        // @ts-expect-error  // Argument of type 'string[]' is not assignable to p... Remove this comment to see the full error message
        .range(['#5ed25d', '#339dc0'])
        // @ts-expect-error  // No overload matches this call.
        .interpolate(interpolateRgb.gamma(2.2)),
    [executionTimesExtent]
  )

  const columns = React.useMemo(
    () => [
      columnHelper.accessor(d => d.keyword?.phrase, {
        header: 'Keyword Phrase',
        filterFn: 'includesString',
      }),
      columnHelper.accessor('phraseTemplate', {
        header: 'Phrase Template',
        filterFn: 'arrIncludesSome',
        // cell: props => formatNumber(props.getValue()),
      }),
      columnHelper.accessor(
        d =>
          formatDevice(d.keyword?.device, {
            v2: true,
            string: true,
            short: false,
          }),
        {
          header: 'Device',
          filterFn: 'arrIncludesSome',
          cell: cellProps =>
            formatDevice(cellProps.row.original.keyword?.device, {
              v2: true,
              short: false,
            }),
        }
      ),
      columnHelper.accessor(
        (d, i) =>
          localesByIdQuery.data
            ? formatLocale(
                localesByIdQuery.data?.find(
                  locale => locale?.locale_id === Number(d.keyword!.localeId)
                )
              )
            : Number(d.keyword?.localeId),
        {
          header: 'Locale',
          filterFn: 'arrIncludesSome',
          cell: props => {
            return localesByIdQuery.data ? (
              renderLocale(
                localesByIdQuery.data?.find(
                  locale =>
                    locale?.locale_id ===
                    Number(props.row.original.keyword!.localeId)
                )
              )
            ) : (
              <Loader />
            )
          },
        }
      ),
      columnHelper.accessor(d => d.localeAlias || '', {
        header: 'Locale Alias',
        filterFn: 'arrIncludesSome',
      }),
      columnHelper.accessor(
        d =>
          d.keywordGroups ? d.keywordGroups.map(d => d.group).join(', ') : '-',
        {
          id: 'groups',
          header: 'Keyword Groups',
          filterFn: 'arrIncludesSome',
          getUniqueValues: d => d.keywordGroups.map(d => d.group),
          cell: props => props.getValue(),
        }
      ),
      columnHelper.accessor('serpCount30Days', {
        header: '30 Day Pulls',
        filterFn: 'inNumberRange',
        cell: props => formatNumber(props.getValue()),
      }),
      columnHelper.accessor('serpCount365Days', {
        header: '365 Day Pulls',
        filterFn: 'inNumberRange',
        cell: props => formatNumber(props.getValue()),
      }),
      columnHelper.accessor(
        d => formatSchedule(d.mostFrequentSchedule?.rrule),
        {
          header: 'Most Frequent Schedule',
          filterFn: 'arrIncludesSome',
          // cell: props => formatNumber(props.getValue()),
        }
      ),
      ...range(5).map(index => {
        return columnHelper.accessor(d => d.times[index], {
          id: `times.${index}`,
          header: !index ? 'Next Execution Times' : '',
          meta: {
            getHeaderProps: () =>
              !index
                ? {
                    colSpan: 5,
                  }
                : {
                    style: {
                      display: 'none',
                    },
                  },
            getCellProps: () => ({
              className: 'p-1',
            }),
          },
          cell: props => {
            const value = new Timestamp(props.getValue()).toDate()

            return (
              <div className="flex items-center">
                <div
                  className="h-5 w-4 rounded-l"
                  style={{
                    background: executionTimesColorScale(value),
                  }}
                />
                <Tooltip
                  className="rounded-r border border-l-0 border-gray-100 bg-white px-2 py-px text-xs dark:border-gray-800 dark:bg-black"
                  tooltip={
                    <div className="space-y-1 text-center">
                      <div>
                        {formatSchedule(
                          props.row.original.mostFrequentSchedule?.rrule
                        )}
                      </div>
                      <div>{moment(value).format('lll')}</div>
                    </div>
                  }
                >
                  {moment(value)
                    .fromNow(true)
                    .replace('a ', '1 ')
                    .replace('an ', '1 ')}
                  {/* {formatDateDifference(moment().toDate(), value)} */}
                </Tooltip>
              </div>
            )
          },
        })
      }),
      columnHelper.accessor(
        d =>
          d.modelSources
            .map(d => [d.model, d.value, d.alias].filter(Boolean).join(':'))
            .join(' - '),
        {
          header: 'Model Sources',
          filterFn: 'fuzzy',
          // cell: props => formatNumber(props.getValue()),
        }
      ),
    ],
    [localesByIdQuery.data, executionTimesColorScale]
  )

  const table = useTable({
    data: React.useMemo(() => generatedKeywords || [], [generatedKeywords]),
    columns,
  })

  const tableRows = table.getPrePaginationRowModel().rows

  const filteredGeneratedKeywords = React.useMemo(
    () => tableRows.map(d => d.original),
    [tableRows]
  )

  const totalSerpCount30Days = React.useMemo(
    () => sum(filteredGeneratedKeywords, d => d.serpCount30Days),
    [filteredGeneratedKeywords]
  )

  const totalSerpCount365Days = React.useMemo(
    () => sum(filteredGeneratedKeywords, d => d.serpCount365Days),
    [filteredGeneratedKeywords]
  )

  const [panel, setPanel] = React.useState<
    'keywords' | 'serpCount30Days' | 'serpCount365Days'
  >('keywords')

  const projectedUsagePct =
    ((subscription?.basePeriod === PeriodPb.YEARLY
      ? totalSerpCount365Days
      : totalSerpCount30Days) /
      Number(meteredSubscription?.includedQuantity)) *
    100

  const availablePct =
    (Number(meteredSubscription?.includedQuantity) /
      (subscription?.basePeriod === PeriodPb.YEARLY
        ? totalSerpCount365Days
        : totalSerpCount30Days)) *
    100

  const projectedOveragePct =
    projectedUsagePct > 100 ? Math.min(100 - availablePct, 90) : 0

  const tableEl = React.useMemo(
    () => (
      <TableEl>
        <tbody>
          <TableRow onClick={() => setPanel('keywords')} tabIndex="0">
            <TableCell>Unique Keywords</TableCell>
            <TableCell>
              {formatNumber(filteredGeneratedKeywords.length)}
            </TableCell>
            <TableCell
              className={twMerge(
                'cursor-pointer hover:bg-gray-50',
                panel === 'keywords'
                  ? '!bg-blue-500 !bg-opacity-100 text-white '
                  : ''
              )}
            >
              <button type="button" className="text-sm font-bold">
                View Details
              </button>
            </TableCell>
          </TableRow>
          <TableRow onClick={() => setPanel('serpCount30Days')} tabIndex="0">
            <TableCell>30 Day Pulls</TableCell>
            <TableCell>{formatNumber(totalSerpCount30Days)}</TableCell>
            <TableCell
              className={twMerge(
                'cursor-pointer hover:bg-gray-50',
                panel === 'serpCount30Days'
                  ? '!bg-blue-500 !bg-opacity-100 text-white '
                  : ''
              )}
            >
              <button type="button" className="text-sm font-bold">
                View Details
              </button>
            </TableCell>
          </TableRow>
          <TableRow onClick={() => setPanel('serpCount365Days')} tabIndex="0">
            <TableCell>365 Day Pulls</TableCell>
            <TableCell>{formatNumber(totalSerpCount365Days)}</TableCell>
            <TableCell
              className={twMerge(
                'cursor-pointer hover:bg-gray-50',
                panel === 'serpCount365Days'
                  ? '!bg-blue-500 !bg-opacity-100 text-white '
                  : ''
              )}
            >
              <button type="button" className="text-sm font-bold">
                View Details
              </button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Projected Usage</TableCell>
            <TableCell
              className={
                projectedUsagePct > 100
                  ? 'animate-pulse font-bold text-red-500 [animation-duration:2s]'
                  : ''
              }
            >
              {formatNumber(projectedUsagePct, {
                precision: 2,
              })}{' '}
              %
            </TableCell>
            <TableCell>
              {(() => {
                return (
                  <Tooltip
                    tooltip={
                      <>
                        It's estimated that this keyword source configuration
                        will consume{' '}
                        <strong>
                          {formatNumber(projectedUsagePct, {
                            precision: 2,
                          })}
                          %
                        </strong>{' '}
                        of your total budget.
                      </>
                    }
                  >
                    {projectedUsagePct > 100 ? (
                      <div className="relative h-[8px] w-[120px] animate-pulse rounded-full bg-blue-500 bg-opacity-100 [animation-duration:2s]">
                        <div>
                          {' '}
                          <div
                            style={{
                              minWidth: '5%',
                              width: `${projectedOveragePct}%`,
                            }}
                            className="absolute right-0 top-1/2 h-[8px] -translate-y-1/2 rounded-r bg-red-500"
                          />
                          <div
                            style={{
                              right: `${projectedOveragePct}%`,
                            }}
                            className="absolute top-1/2 h-[8px] w-[2px] -translate-y-1/2 bg-white"
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="w-[120px] rounded-full bg-gray-500 bg-opacity-40">
                        <div
                          style={{
                            minWidth: '5%',
                            width: `${projectedUsagePct}%`,
                          }}
                          className="h-[8px] rounded-full bg-blue-500"
                        />
                      </div>
                    )}
                  </Tooltip>
                )
              })()}
            </TableCell>
          </TableRow>
        </tbody>
      </TableEl>
    ),
    [
      filteredGeneratedKeywords.length,
      panel,
      projectedOveragePct,
      projectedUsagePct,
      totalSerpCount30Days,
      totalSerpCount365Days,
    ]
  )

  const charts = React.useMemo(() => {
    if (!filteredGeneratedKeywords?.length) return null

    if (panel === 'keywords') {
      return (
        <Keywords
          generatedKeywords={filteredGeneratedKeywords}
          // @ts-expect-error  // Type 'UseQueryResult<(Locale | undefined)[], unkno... Remove this comment to see the full error message
          localesByIdQuery={localesByIdQuery}
        />
      )
    }

    if (panel === 'serpCount30Days') {
      return (
        <Pulls
          accessor="serpCount30Days"
          generatedKeywords={filteredGeneratedKeywords}
          // @ts-expect-error  // Type 'UseQueryResult<(Locale | undefined)[], unkno... Remove this comment to see the full error message
          localesByIdQuery={localesByIdQuery}
        />
      )
    }

    if (panel === 'serpCount365Days') {
      return (
        <Pulls
          accessor="serpCount365Days"
          generatedKeywords={filteredGeneratedKeywords}
          // @ts-expect-error  // Type 'UseQueryResult<(Locale | undefined)[], unkno... Remove this comment to see the full error message
          localesByIdQuery={localesByIdQuery}
        />
      )
    }

    return null
  }, [filteredGeneratedKeywords, localesByIdQuery, panel])

  return (
    <div className="space-y-4">
      {isFetching ? (
        <div className="flex items-center gap-2">
          <div className="italic opacity-50">Generating Keywords</div>
          <Loader />
        </div>
      ) : null}
      {generatedKeywords?.length ? (
        <div className="rounded-lg border border-gray-200 dark:border-gray-800">
          <Table table={table} />
        </div>
      ) : (
        <div className="italic opacity-50">
          No keywords were generated. Check your settings to verify you are
          supplying at least one valid keyword phrase, device and locale.
        </div>
      )}
      <div className="space-y-2">
        <div className="text-xl font-bold">Stats</div>
        {generatedKeywords?.length !== filteredGeneratedKeywords.length ? (
          <div className="flex items-center gap-2">
            <IoWarning className="text-yellow-500" />
            <div className="text-sm italic">
              Only showing filtered data. To view all data, remove filters from
              the table above.
            </div>
          </div>
        ) : null}
        <div className="inline-flex overflow-hidden rounded-lg border border-gray-100 dark:border-gray-800">
          {tableEl}
        </div>
      </div>
      {charts}
    </div>
  )
}

function Keywords({
  generatedKeywords,
  localesByIdQuery,
}: {
  generatedKeywords: GeneratedKeywordRow[]
  localesByIdQuery: UseQueryResult<Locale[]>
}) {
  return (
    <div className="grid gap-2 md:grid-cols-2 xl:grid-cols-4">
      <div className="min-w-[320px] flex-1 space-y-2 rounded-lg bg-white p-2 shadow-lg dark:border dark:border-gray-800 dark:bg-gray-850">
        <div className="text-center text-sm">Keywords by Keyword Group</div>
        <ByKeywordGroupPlot generatedKeywords={generatedKeywords} />
      </div>
      <div className="min-w-[320px] flex-1 space-y-2 rounded-lg bg-white p-2 shadow-lg dark:border dark:border-gray-800 dark:bg-gray-850">
        <div className="text-center text-sm">Keywords by Locale</div>
        <ByLocalePlot
          generatedKeywords={generatedKeywords}
          localesByIdQuery={localesByIdQuery}
        />
      </div>
      <div className="min-w-[320px] flex-1 space-y-2 rounded-lg bg-white p-2 shadow-lg dark:border dark:border-gray-800 dark:bg-gray-850">
        <div className="text-center text-sm">Keywords by Template</div>
        <ByTemplatePlot generatedKeywords={generatedKeywords} />
      </div>
      <div className="min-w-[320px] flex-1 space-y-2 rounded-lg bg-white p-2 shadow-lg dark:border dark:border-gray-800 dark:bg-gray-850">
        <div className="text-center text-sm">Pulls by Device</div>
        <ByDevicePlot
          generatedKeywords={generatedKeywords}
          // accessor={accessor}
        />
      </div>
    </div>
  )
}

function Pulls({
  accessor,
  generatedKeywords,
  localesByIdQuery,
}: {
  accessor: 'serpCount30Days' | 'serpCount365Days'
  generatedKeywords: GeneratedKeywordRow[]
  localesByIdQuery: UseQueryResult<Locale[]>
}) {
  return (
    <div className="grid gap-4 md:grid-cols-2 xl:grid-cols-4">
      <div className="min-w-[320px] flex-1 space-y-2 rounded-lg bg-white p-2 shadow-lg dark:border dark:border-gray-800 dark:bg-gray-850">
        <div className="text-center text-sm">Pulls by Keyword Group</div>
        <ByKeywordGroupPlot
          generatedKeywords={generatedKeywords}
          accessor={accessor}
        />
      </div>
      <div className="min-w-[320px] flex-1 space-y-2 rounded-lg bg-white p-2 shadow-lg dark:border dark:border-gray-800 dark:bg-gray-850">
        <div className="text-center text-sm">Pulls by Locale</div>
        <ByLocalePlot
          localesByIdQuery={localesByIdQuery}
          generatedKeywords={generatedKeywords}
          accessor={accessor}
        />
      </div>
      <div className="min-w-[320px] flex-1 space-y-2 rounded-lg bg-white p-2 shadow-lg dark:border dark:border-gray-800 dark:bg-gray-850">
        <div className="text-center text-sm">Keywords by Template</div>
        <ByTemplatePlot
          generatedKeywords={generatedKeywords}
          accessor={accessor}
        />
      </div>
      <div className="min-w-[320px] flex-1 space-y-2 rounded-lg bg-white p-2 shadow-lg dark:border dark:border-gray-800 dark:bg-gray-850">
        <div className="text-center text-sm">Keywords by Device</div>
        <ByDevicePlot
          generatedKeywords={generatedKeywords}
          accessor={accessor}
        />
      </div>
    </div>
  )
}

function ByKeywordGroupPlot({
  generatedKeywords,
  accessor,
}: {
  generatedKeywords: GeneratedKeywordRow[]
  accessor?: 'serpCount30Days' | 'serpCount365Days'
}) {
  const generatedKeywordsFlatByGroup = React.useMemo(() => {
    return generatedKeywords?.flatMap(d => {
      return d.keywordGroups?.map(group => {
        return {
          ...d,
          group: group.group,
        }
      })
    })
  }, [generatedKeywords])

  return (
    <ObservablePlot
      className="h-48"
      legend
      legendAfter
      options={{
        // marginLeft: 75,
        marginLeft: 5,
        marginRight: 50,
        y: {
          axis: null,
          paddingInner: 0.5,
          label: null,
        },
        x: {
          label: `⇢ ${accessor ? 'Pulls' : 'Keywords'}`,
        },
        color: {
          label: 'Keyword Group',
        },
        marks: [
          Plot.ruleX([0]),
          Plot.barX(
            generatedKeywordsFlatByGroup,
            Plot.groupY(
              { x: accessor ? 'sum' : 'count' },
              {
                x: accessor,
                y: 'group',
                fill: 'group',
                tip: true,
              }
            )
          ),
          Plot.textX(
            generatedKeywordsFlatByGroup,
            Plot.groupY(
              {
                x: accessor ? 'sum' : 'count',
                text: accessor ? 'sum' : 'count',
              },
              {
                x: accessor,
                y: 'group',
                text: accessor,
                textAnchor: 'start',
                dx: 4,
              }
            )
          ),
          // Plot.tip({
          //   x: 1,
          //   y: 1,
          // }),
        ],
      }}
    />
  )
}

function ByLocalePlot({
  generatedKeywords,
  localesByIdQuery,
  accessor,
}: {
  generatedKeywords: GeneratedKeywordRow[]
  localesByIdQuery: UseQueryResult<Locale[]>
  accessor?: 'serpCount30Days' | 'serpCount365Days'
}) {
  return (
    <ObservablePlot
      className="h-48"
      legend
      legendAfter
      options={{
        marginLeft: 5,
        marginRight: 50,
        color: {
          tickFormat: d =>
            formatLocale(
              localesByIdQuery.data?.find(locale => locale.locale_id == d)
            ),
        },
        y: {
          axis: null,
          paddingInner: 0.5,
        },
        x: {
          label: `⇢ ${accessor ? 'Pulls' : 'Keywords'}`,
        },
        marks: [
          Plot.ruleX([0]),
          Plot.barX(
            generatedKeywords,
            Plot.groupY(
              {
                x: accessor ? 'sum' : 'count',
                text: accessor ? 'sum' : 'count',
              },
              {
                x: accessor,
                y: d => d.keyword.localeId,
                fill: d => `${d.keyword.localeId}`,
                tip: true,
              }
            )
          ),
          Plot.textX(
            generatedKeywords,
            Plot.groupY(
              {
                x: accessor ? 'sum' : 'count',
                text: accessor ? 'sum' : 'count',
              },
              {
                x: accessor,
                y: d => d.keyword.localeId,
                text: accessor,
                textAnchor: 'start',
                dx: 4,
              }
            )
          ),
        ],
      }}
    />
  )
}

function ByTemplatePlot({
  generatedKeywords,
  accessor,
}: {
  generatedKeywords: GeneratedKeywordRow[]
  accessor?: 'serpCount30Days' | 'serpCount365Days'
}) {
  return (
    <ObservablePlot
      className="h-48"
      legend
      legendAfter
      options={{
        // marginLeft: 275,
        marginLeft: 5,
        marginRight: 50,
        y: {
          axis: null,
          paddingInner: 0.5,
        },
        x: {
          label: `⇢ ${accessor ? 'Pulls' : 'Keywords'}`,
        },
        color: {
          label: 'Template',
        },
        marks: [
          Plot.ruleX([0]),
          Plot.barX(
            generatedKeywords,
            Plot.groupY(
              {
                x: accessor ? 'sum' : 'count',
                text: accessor ? 'sum' : 'count',
              },
              {
                x: accessor,
                y: d => d.phraseTemplate || 'No Template',
                fill: d => `${d.phraseTemplate || 'No Template'}`,
                tip: true,
              }
            )
          ),
          Plot.textX(
            generatedKeywords,
            Plot.groupY(
              {
                x: accessor ? 'sum' : 'count',
                text: accessor ? 'sum' : 'count',
              },
              {
                x: accessor,
                y: d => d.phraseTemplate || 'No Template',
                text: accessor,
                textAnchor: 'start',
                dx: 4,
              }
            )
          ),
        ],
      }}
    />
  )
}

function ByDevicePlot({
  generatedKeywords,
  accessor,
}: {
  generatedKeywords: GeneratedKeywordRow[]
  accessor?: 'serpCount30Days' | 'serpCount365Days'
}) {
  return (
    <ObservablePlot
      className="h-48"
      legend
      legendAfter
      options={{
        marginLeft: 5,
        marginRight: 50,
        y: {
          axis: null,
          paddingInner: 0.5,
        },
        x: {
          label: '⇢ Pulls',
        },
        color: {
          label: 'Device',
        },
        marks: [
          Plot.ruleX([0]),
          Plot.barX(
            generatedKeywords,
            Plot.groupY(
              {
                x: accessor ? 'sum' : 'count',
                text: accessor ? 'sum' : 'count',
              },
              {
                x: accessor,
                y: d =>
                  formatDevice(d.keyword.device, {
                    v2: true,
                    string: true,
                  }),
                fill: d =>
                  formatDevice(d.keyword.device, {
                    v2: true,
                    string: true,
                  }),
                tip: true,
              }
            )
          ),
          Plot.textX(
            generatedKeywords,
            Plot.groupY(
              {
                x: accessor ? 'sum' : 'count',
                text: accessor ? 'sum' : 'count',
              },
              {
                x: accessor,
                y: d =>
                  formatDevice(d.keyword.device, {
                    v2: true,
                    string: true,
                  }),
                text: accessor,
                textAnchor: 'start',
                dx: 4,
              }
            )
          ),
        ],
      }}
    />
  )
}

function formatDateDifference(date1: Date, date2: Date): string {
  const momentDate1 = moment.utc(date1)
  const momentDate2 = moment.utc(date2)
  const cursor = momentDate1.clone()

  const timeUnits = ['year', 'month', 'day', 'hour', 'minute'] as const

  const unitMeasurements: {
    unit: (typeof timeUnits)[number]
    value: number
  }[] = []

  timeUnits.forEach(unit => {
    const diff = momentDate2.diff(cursor, unit)
    if (diff) {
      unitMeasurements.push({
        unit,
        value: diff,
      })
      cursor.add(unit, diff)
    }
  })

  const formattedDifference = unitMeasurements
    .filter(unit => unit.value > 0)
    .map(unit => `${unit.value} ${unit.unit}${unit.value > 1 ? 's' : ''}`)
    .join(', ')

  return formattedDifference
}
