// @generated by protoc-gen-connect-web v0.11.0 with parameter "target=ts"
// @generated from file nozzle/workspaces/v1/serpwoo.proto (package nozzle.workspaces.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { ImportSerpwooRequest, ImportSerpwooResponse } from "./serpwoo_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * SerpwooService imports SERPWoo export zip files
 *
 * @generated from service nozzle.workspaces.v1.SerpwooService
 */
export const SerpwooService = {
  typeName: "nozzle.workspaces.v1.SerpwooService",
  methods: {
    /**
     * ImportSerpwoo imports a SERPWoo export zip file
     *
     * @generated from rpc nozzle.workspaces.v1.SerpwooService.ImportSerpwoo
     */
    importSerpwoo: {
      name: "ImportSerpwoo",
      I: ImportSerpwooRequest,
      O: ImportSerpwooResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

