import * as React from 'react'
import Card from '../../../components/Card'
import Table from '../../../components/Table'
import { useTable } from '../../../hooks/useTable'
import { ClusterPb, RollupMetricsPb } from '../../../utils/proto'
import { relatedPhraseMetricsColumns } from './PAA'

export function FAQ(props: { cluster: ClusterPb }) {
  const data = React.useMemo(
    (): RollupMetricsPb[] =>
      props.cluster.metricsByRelatedPhraseType?.faq || [],
    [props.cluster.metricsByRelatedPhraseType?.faq]
  )

  const table = useTable({
    data,
    columns: relatedPhraseMetricsColumns,
    initialState: React.useMemo(
      () => ({
        pagination: {
          pageSize: 50,
        },
      }),
      []
    ),
  })

  return (
    <Card className="divide-y divide-gray-500/20 p-0">
      <div className="p-2 text-lg font-bold">FAQ</div>
      <Table table={table} />
    </Card>
  )
}
