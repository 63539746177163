import React, { ChangeEvent } from 'react'
import { twMerge } from 'tailwind-merge'
import { withProps } from '../utils/withProps'
import { useDebouncedState } from '../hooks/useDebouncedState'

//

export const inputTextStyles = `
  leading-none text-sm
  px-2 py-1
  text-black dark:text-white
  placeholder:text-gray-400
  overflow-ellipsis
`

export type InputProps = React.HTMLAttributes<HTMLInputElement> & {
  value?: any
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void
  validate?: any
  ref?: any
  error?: any
  inline?: never
  inputStyles?: string
  enableDraft?: boolean
  debounce?: number
}

export const RawInput = React.forwardRef(
  ({ enableDraft, debounce, ...props }: InputProps, ref) => {
    const [draft, setDraft] = React.useState(props.value)
    const [undebouncedValue, debouncedValue, setInputValue] = useDebouncedState(
      {
        initialValue: props.value,
        onChange: value => {
          if (debounce) {
            props.onChange?.({
              target: { value },
              currentTarget: { value },
            } as any)
          }
        },
      }
    )

    React.useEffect(() => {
      setDraft(props.value)
    }, [props.value])

    return (
      <input
        {...props}
        ref={ref as any}
        value={
          debounce ? undebouncedValue : enableDraft ? draft : props.value ?? ''
        }
        onChange={
          debounce
            ? e => setInputValue(e.target.value)
            : enableDraft
            ? e => setDraft(e.target.value)
            : props.onChange
        }
        onBlur={
          enableDraft
            ? e => {
                props.onChange?.(e)
                props.onBlur?.(e)
              }
            : props.onBlur
        }
        onKeyDown={e => {
          if (e.key === 'Enter' && enableDraft) {
            props.onChange?.(e)
            e.preventDefault()
          }
          props.onKeyDown?.(e)
        }}
        className={twMerge(`bg-transparent`, props.className)}
      />
    )
  }
)

export const inputStyles = `
  ${inputTextStyles}
  max-w-full flex-auto
  rounded-md border
  border-solid
  border-gray-100 bg-gray-500/5
  focus:bg-white 
  dark:border-gray-800
  dark:bg-gray-850
  dark:focus:bg-gray-800
`

export const Input = withProps(RawInput)<
  React.InputHTMLAttributes<HTMLInputElement> & InputProps
>(
  ({
    className,
    error,
    inline: _inline,
    inputStyles: _userInputStyles,
    ...props
  }) => ({
    type: 'text',
    ...props,
    className: twMerge(inputStyles, error && `border-red-500`, className),
  })
)

export const PlainInput = withProps(RawInput)<
  React.InputHTMLAttributes<HTMLInputElement> & InputProps
>(({ className, error, inline: _inline, inputStyles, ...props }) => ({
  type: 'text',
  ...props,
  className: twMerge(
    `${inputTextStyles}
      max-w-full
      flex-auto bg-opacity-50
      focus:bg-white focus:dark:bg-gray-850
      ${error && `border-red-500`};
    `,
    className
  ),
}))

export default Input
