import { scheduleOptions } from '../options/scheduleOptions'

export type ScheduleId = (typeof scheduleOptions)[number]['value']

export type ScheduleConfig = {
  id: ScheduleId
  customRRule?: string
  startDate?: string
  endDate?: string
  hasStart?: boolean
  hasEnd?: boolean
}

export function isCustomRrule(rrule: string) {
  return !scheduleOptions.find(option => option.value === rrule)
}

export function rruleToScheduleConfig(rrule: string): ScheduleConfig {
  const rruleSplit = rrule.split(';')
  const startDate = rruleSplit.filter(o => o.indexOf('DTSTART') !== -1)
  const endDate = rruleSplit.filter(o => o.indexOf('UNTIL') !== -1)

  const baseRRrule = rruleSplit
    .filter(o => o !== startDate[0] && o !== endDate[0])
    .join(';')

  if (isCustomRrule(baseRRrule)) {
    return {
      id: 'custom',
      customRRule: rrule,
    }
  }

  return {
    id: baseRRrule as ScheduleId,
    startDate: startDate[0]
      ?.replace('DTSTART=', '')
      .replace('T000000Z', '')
      .replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3'),
    endDate: endDate[0]
      ?.replace('UNTIL=', '')
      .replace('T000000Z', '')
      .replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3'),
    hasStart: !!startDate[0],
    hasEnd: !!endDate[0],
  }
}

export function scheduleConfigToFullRRule(schedule: ScheduleConfig) {
  if (schedule.id === 'custom') {
    return schedule.customRRule!
  }

  let rrule = schedule.id

  if (schedule.hasStart && schedule.startDate) {
    const date = schedule.startDate.split('-')
    rrule += (';DTSTART=' + date[0] + date[1] + date[2] + 'T000000Z') as any
  }

  if (schedule.hasEnd && schedule.endDate) {
    const date = schedule.endDate.split('-')
    rrule += (';UNTIL=' + date[0] + date[1] + date[2] + 'T000000Z') as any
  }

  return rrule
}

export function scheduleConfigFromOption(
  option: (typeof scheduleOptions)[number]
): ScheduleConfig {
  return {
    id: option.value,
  }
}

export function formatSchedule(rrule?: string | null) {
  return (
    scheduleOptions.find(d => d.value === rrule)?.label ||
    (rrule ? `Custom - ${rrule}` : 'No schedule')
  )
}
