import * as React from 'react'
import { useForm } from 'react-form'
import { FaInfoCircle } from 'react-icons/fa'
import Button from '../components/Button'
import Caption from '../components/Caption'
import TextAreaField from '../components/TextAreaField'
import { range } from '../utils'
import {
  dedupePhraseGroups,
  phraseGroupsToPhraseText,
  phraseTextToPhraseGroups,
} from '../utils/Phrases'
import Validate from '../utils/Validate'

//

type Props = {
  phraseGroups: any
  onChange: any
  onCancel: any
}

export function PhraseGroupsAsTextPhrasesForm({
  phraseGroups,
  onChange,
  onCancel,
}: Props) {
  const onSubmit = (values: any) => {
    onChange(phraseTextToPhraseGroups(values.text))
  }

  const defaultValues = React.useMemo(() => {
    const text = phraseGroupsToPhraseText(phraseGroups)

    return {
      text,
    }
  }, [phraseGroups])

  const {
    Form,
    values,
    // @ts-expect-error  // Property 'handleSubmit' does not exist on type '{ ... Remove this comment to see the full error message
    handleSubmit,
    meta: { canSubmit },
  } = useForm({
    defaultValues,
    // @ts-expect-error  // Type '(values: any) => void' is not assignable to ... Remove this comment to see the full error message
    onSubmit,
  })

  const newPhraseGroups = React.useMemo(
    () => phraseTextToPhraseGroups(values.text),
    [values.text]
  )

  const groupOptions = React.useMemo(
    () => dedupePhraseGroups(newPhraseGroups).groupOptions,
    [newPhraseGroups]
  )

  return (
    <Form>
      <TextAreaField
        label={'Phrases as Text'}
        field="text"
        placeholder={[
          'keyword phrase 1',
          'keyword phrase 2',
          'keyword phrase 3',
        ].join(
          `${range(2000)
            .map(() => '')
            .join(' ')}\n`
        )}
        rows={13}
        // @ts-expect-error  // Type '{ label: string; field: string; placeholder:... Remove this comment to see the full error message
        className="h-[29.5rem]"
        validate={Validate.keywordSourcePhrases()}
      />
      <Caption>
        <FaInfoCircle color="gray-500" className="inline" />{' '}
        {newPhraseGroups.length} keyword phrases found with{' '}
        {groupOptions.length} groups
      </Caption>
      <div className="h-2" />
      <div>
        <Button size="sm" color="red-500" onClick={() => onCancel()}>
          Cancel
        </Button>{' '}
        <Button
          onClick={() => handleSubmit()}
          size="sm"
          color="green-500"
          disabled={!canSubmit}
        >
          Apply
        </Button>
      </div>
    </Form>
  )
}
