import { createColumnHelper } from '@tanstack/react-table'
import * as React from 'react'
// @ts-expect-error  // Module '"react-form"' has no exported member 'useF... Remove this comment to see the full error message
import { useForm, useFormContext } from 'react-form'
import {
  FaCheck,
  FaEdit,
  FaPlus,
  FaTimesCircle,
  FaTrashAlt,
} from 'react-icons/fa'
import Button from '../components/Button'
import Clickable from '../components/Clickable'
import PhraseGroupsModal from '../components/PhraseGroupsModal'
import Select from '../components/Select'
import TextField from '../components/TextField'
import useConfirm from '../hooks/useConfirm'
import useModal from '../hooks/useModal'
import { useTable } from '../hooks/useTable'
import { PhraseGroup } from '../utils/Api'
import { dedupePhraseGroups } from '../utils/Phrases'
import Validate from '../utils/Validate'
import Table from './Table'
import { createCheckboxColumn } from './CheckboxColumn'
import { Modal } from './Modals'

type Props = {
  phraseGroups: any
  onPhraseGroupsChange: any
}

//

const columnHelper = createColumnHelper<PhraseGroup>()

export default function PhraseGroupsFieldPhrases({
  phraseGroups,
  onPhraseGroupsChange,
}: Props) {
  const confirm = useConfirm()

  const info = React.useMemo(
    () => dedupePhraseGroups(phraseGroups),
    [phraseGroups]
  )

  const autoResetPageIndexRef = React.useRef(true)

  const columns = React.useMemo(
    () => [
      createCheckboxColumn(),
      columnHelper.accessor('phrase', {
        header: 'Keyword Phrase',
        filterFn: 'includesString',
        cell: function Cell(props) {
          const initialValue = props.getValue()
          const [isEditing, setIsEditing] = React.useState(false)
          const [value, setEditingValue] = React.useState(initialValue)

          React.useEffect(() => {
            setEditingValue(initialValue)
          }, [initialValue])

          const onCancel = () => {
            setEditingValue(initialValue)
            setIsEditing(false)
          }

          const handleSubmit = (e: any) => {
            e.preventDefault()
            e.stopPropagation()
            if (!isEditing) {
              return
            }

            autoResetPageIndexRef.current = false

            onPhraseGroupsChange(
              phraseGroups.map((phraseGroup: any) => {
                if (phraseGroup.phrase === initialValue) {
                  return {
                    phrase: value,
                    groups: phraseGroup.groups,
                  }
                }
                return phraseGroup
              })
            )
            setIsEditing(false)
          }

          const {
            Form,
            // @ts-expect-error  // Property 'isValid' does not exist on type '{ isSub... Remove this comment to see the full error message
            meta: { isValid },
            // @ts-expect-error  // Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
          } = useForm({})

          return (
            <Form className="flex items-center justify-between">
              <div className="flex-auto">
                {isEditing ? (
                  <TextField
                    field="phrase"
                    // @ts-expect-error  // Type '{ field: string; value: string; onChange: (e... Remove this comment to see the full error message
                    value={value}
                    onChange={e => setEditingValue(e.target.value)}
                    className="w-full"
                    validate={Validate.keywordSourcePhrases()}
                  />
                ) : (
                  initialValue
                )}
              </div>
              <div className="ml-2">
                {isEditing ? (
                  <>
                    <Clickable
                      onClick={onCancel}
                      className={` px-2 text-[1.2rem] text-red-600 hover:text-red-500`}
                    >
                      <FaTimesCircle className="inline" />
                    </Clickable>
                    <Clickable
                      onClick={handleSubmit}
                      disabled={!isValid}
                      className={` px-2 text-[1.2rem] text-green-600 hover:text-green-500`}
                    >
                      <FaCheck className="inline" />
                    </Clickable>
                  </>
                ) : (
                  <div>
                    <Clickable
                      onClick={() => setIsEditing(true)}
                      className={` px-1 text-gray-200 hover:text-blue-500`}
                    >
                      <FaEdit className="inline" />
                    </Clickable>
                    <Clickable
                      onClick={() => {
                        autoResetPageIndexRef.current = false

                        onPhraseGroupsChange(
                          phraseGroups.filter(
                            (phraseGroup: any) =>
                              phraseGroup.phrase !== initialValue
                          )
                        )
                      }}
                      className={` px-1 text-gray-200 hover:text-red-500`}
                    >
                      <FaTrashAlt className="inline" />
                    </Clickable>
                  </div>
                )}
              </div>
            </Form>
          )
        },
      }),
      columnHelper.accessor('displayGroups', {
        header: 'Keyword Groups',
        filterFn: 'arrIncludesAll',
        // @ts-expect-error  // Type '(row: PhraseGroup) => string[] | undefined' ... Remove this comment to see the full error message
        getUniqueValues: row => row.displayGroups,
        meta: {
          showQuickFilter: true,
        },
        cell: function Cell(props) {
          const initialValue = props.getValue()
          const original = props.row.original
          const [isEditing, setIsEditing] = React.useState(false)
          const [valueDraft, setEditingValue] = React.useState(
            original.groups || []
          )

          React.useEffect(() => {
            setEditingValue(original.groups || [])
          }, [original.groups])

          const onCancel = () => {
            setEditingValue(original.groups || [])
            setIsEditing(false)
          }

          const handleSubmit = (e: any) => {
            e.preventDefault()
            e.stopPropagation()
            if (!isEditing) {
              return
            }

            autoResetPageIndexRef.current = false

            onPhraseGroupsChange(
              phraseGroups.map(({ phrase, groups }: any) => {
                if (phrase === original.phrase) {
                  return {
                    phrase,
                    groups: valueDraft,
                  }
                }
                return {
                  phrase,
                  groups,
                }
              })
            )
            setIsEditing(false)
          }

          return (
            <form className="flex items-center justify-between">
              <div className="flex-auto">
                {isEditing ? (
                  <Select
                    value={valueDraft}
                    options={info.groupOptions}
                    multi
                    create
                    onChange={setEditingValue}
                  />
                ) : (
                  (original.groups || []).join(', ')
                )}
              </div>
              <div className="ml-2">
                {isEditing ? (
                  <>
                    <Clickable
                      onClick={onCancel}
                      className={` px-2 text-[1.2rem] text-red-600 hover:text-red-500`}
                    >
                      <FaTimesCircle className="inline" />
                    </Clickable>
                    <Clickable
                      onClick={handleSubmit}
                      className={` px-2 text-[1.2rem] text-green-600 hover:text-green-500`}
                    >
                      <FaCheck className="inline" />
                    </Clickable>
                  </>
                ) : (
                  <div>
                    <Clickable
                      onClick={() => setIsEditing(true)}
                      className={` px-1 text-gray-200 hover:text-blue-500`}
                    >
                      <FaEdit className="inline" />
                    </Clickable>
                  </div>
                )}
              </div>
            </form>
          )
        },
      }),
    ],
    [info.groupOptions, onPhraseGroupsChange, phraseGroups]
  )

  const table = useTable({
    data: info.phraseGroups,
    // @ts-expect-error  // Type '((ColumnDefBase<unknown, unknown> & StringHe... Remove this comment to see the full error message
    columns,
    showToolbar: true,

    autoResetPageIndex: autoResetPageIndexRef.current,
    // showToolbar: true,
    initialState: {
      pagination: {
        pageSize: 10,
      },
      sorting: [{ id: 'phrase', desc: false }],
    },
    counter: {
      totalName: 'keyword phrases',
    },
    globalFilterFn: 'includesString',
  })

  React.useEffect(() => {
    autoResetPageIndexRef.current = true
  })

  const groupArray: string[] = []

  const selectedRows = table.getSelectedRowModel().rows

  selectedRows.map(row =>
    // @ts-expect-error  // Object is of type 'unknown'.
    row.original.groups.forEach((group: any) => {
      groupArray.push(group)
    })
  )

  const removeOptions = info.groupOptions.filter(groupOption =>
    groupArray.includes(groupOption.label)
  )
  const showModal = useModal()

  const { FieldScope } = useFormContext()

  return (
    <div className="divide-y divide-gray-500/20 rounded-lg border border-gray-500/20">
      <Table table={table} />
      <div className="flex flex-wrap items-center gap-1 p-2">
        <Button
          size="xs"
          color="blue-500"
          className="mr-1"
          onClick={() =>
            showModal(() => (
              <Modal>
                <FieldScope>
                  <PhraseGroupsModal />
                </FieldScope>
              </Modal>
            ))
          }
        >
          <FaPlus className="inline" />
          Add Keyword Phrases
        </Button>{' '}
        <span disabled={!selectedRows.length} className="pr-1">
          <strong>{selectedRows.length}</strong> keyword phrases selected
        </span>
        {selectedRows.length ? (
          <>
            <Select
              options={info.groupOptions}
              onChange={value => {
                const newPhraseGroups = phraseGroups.map((phraseGroup: any) => {
                  if (
                    selectedRows.find(
                      // @ts-expect-error  // Object is of type 'unknown'.
                      d => d.original.phrase === phraseGroup.phrase
                    )
                  ) {
                    const newGroups = phraseGroup.groups || []
                    if (!newGroups.includes(value)) {
                      newGroups.push(value)
                    }

                    return {
                      ...phraseGroup,
                      groups: newGroups,
                    }
                  }
                  return phraseGroup
                })
                onPhraseGroupsChange(newPhraseGroups)
              }}
              create
              defaultValue={
                table.getState().columnFilters.find(d => d.id === 'phrase')
                  ?.value
              }
              value={''}
              className="inline-flex"
            >
              {({ onClick }: any) => (
                <Button
                  size="xs"
                  color={['gray-100', 'gray-800']}
                  hoverColor="blue-500"
                  onClick={onClick}
                >
                  <FaPlus className="inline" /> Add to Keyword Group
                </Button>
              )}
            </Select>{' '}
            <Select
              options={removeOptions}
              onChange={value => {
                const newPhraseGroups = phraseGroups.map((phraseGroup: any) => {
                  if (
                    selectedRows.find(
                      // @ts-expect-error  // Object is of type 'unknown'.
                      d => d.original.phrase === phraseGroup.phrase
                    )
                  ) {
                    let newGroups = phraseGroup.groups || []
                    if (newGroups.includes(value)) {
                      newGroups = newGroups.filter(
                        (group: any) => group !== value
                      )
                    }
                    return {
                      ...phraseGroup,
                      groups: newGroups,
                    }
                  }
                  return phraseGroup
                })
                onPhraseGroupsChange(newPhraseGroups)
              }}
              create
              value={''}
              className="inline-flex"
            >
              {({ onClick }: any) => (
                <Button
                  size="xs"
                  color={['gray-100', 'gray-800']}
                  hoverColor="red-500"
                  onClick={onClick}
                >
                  <FaTimesCircle className="inline" /> Remove from Keyword Group
                </Button>
              )}
            </Select>{' '}
            <Button
              size="xs"
              color="red-500"
              onClick={async () => {
                const confirmed = await confirm({
                  message: `Are you sure you want to delete all selected keyword phrases?`,
                })

                if (confirmed) {
                  onPhraseGroupsChange(
                    phraseGroups.filter(
                      (phraseGroup: any) =>
                        !selectedRows.find(
                          selectedRow =>
                            // @ts-expect-error  // Object is of type 'unknown'.
                            selectedRow.original.phrase === phraseGroup.phrase
                        )
                    )
                  )
                }
              }}
            >
              <FaTrashAlt className="inline" /> Delete
            </Button>
          </>
        ) : null}
      </div>
    </div>
  )
}
