import * as React from 'react'
import { twMerge } from 'tailwind-merge'
import ErrorBoundary from '../components/ErrorBoundary'
import useModalContext, { modalContext } from '../hooks/useModalContext'
import useZustand from '../hooks/useZustand'
import { withProps } from '../utils/withProps'
import { last } from '../utils'

export const ModalTitle = withProps('div')(({ className, ...props }) => ({
  ...props,
  className: twMerge(
    'flex flex-wrap items-center gap-2 border-b border-gray-500/20 bg-gray-100 px-3 py-2 text-lg font-bold dark:border-0 dark:bg-gray-800 dark:text-white',
    className
  ),
}))

export const ModalContent = withProps('div')(({ className, ...props }) => ({
  ...props,
  className: twMerge(
    'h-full overflow-auto bg-white dark:bg-gray-900',
    className
  ),
}))

export default function Modals() {
  const [modals] = useZustand(state => state.modals)

  const modal = last(modals)

  const contextValue = React.useMemo(() => {
    if (modal) {
      return {
        ...modal,
        active: modal.index === modals.length - 1,
      }
    }
    return null
  }, [modal, modals.length])

  return modal ? (
    <div>
      <modalContext.Provider value={contextValue}>
        <div
          className={twMerge(`absolute left-0 top-0 z-[1001] h-full w-full`)}
        >
          <div
            className={twMerge(
              `absolute left-0 top-0 flex h-full w-full items-center justify-center`
            )}
          >
            <div
              role="none"
              onClick={() => modal.maskClose && modal.close()}
              className="pointer-events-auto absolute inset-0 z-0 bg-black/50"
            />
            <ErrorBoundary>
              <modal.Component />
            </ErrorBoundary>
          </div>
        </div>
      </modalContext.Provider>
    </div>
  ) : null
}

export const ModalClose = ({ children = 'Close', ...rest }) => {
  const { close } = useModalContext()
  return (
    <button
      role="none"
      type="button"
      {...rest}
      onClick={() => close()}
      className={twMerge(
        `flex h-[40px] w-full items-center justify-center bg-gray-500 text-white`,
        `m-0 cursor-pointer font-normal transition-all duration-200 ease-in-out`,
        'hover:bg-red-500',
        rest.className
      )}
    >
      <span>
        <i className="icon icon-cancel mr-1" /> {children}
      </span>
    </button>
  )
}

export const Modal = withProps('div')(({ className, ...props }) => ({
  ...props,
  className: twMerge(
    `pointer-events-all z-1 relative flex max-h-[90%] w-[1000px] max-w-[90%] flex-col
     overflow-y-auto overflow-x-hidden rounded-md shadow-xl`,
    className
  ),
}))
