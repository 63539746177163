import * as React from 'react'

const matchDark = '(prefers-color-scheme: dark)'

let win
if (typeof document !== 'undefined') {
  win = window
}

const matcher = win?.matchMedia ? win.matchMedia(matchDark) : false

export default function useDarkMode() {
  // Keep track of the preference in state, start with the current match
  // @ts-expect-error  // Property 'matches' does not exist on type 'false |... Remove this comment to see the full error message
  const [isDark, setIsDark] = React.useState(() => matcher?.matches)

  // Watch for changes
  React.useEffect(() => {
    if (!matcher) {
      return
    }

    // Create our handler
    const onChange = ({ matches }: any) => setIsDark(matches)

    // Listen for changes
    matcher.addListener(onChange)

    return () => {
      // Stop listening for changes
      matcher.removeListener(onChange)
    }
  }, [isDark, setIsDark])

  return isDark
}
