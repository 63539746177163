import { GroupByOverTimeQueryOptions, GroupByQueryFilters } from '../utils/Api'
import {
  MetricId,
  MetricPermutation,
  metricsById,
  safeMetricPermutation,
} from '../utils/Metrics'
import { QueryFacetId, allQueryFacets } from '../utils/queryFacets'
import { useActiveWorkspaceId } from './workspaces'
import { RequiredByKey } from '@tanstack/react-form'
import { useActiveProjectIdState } from '../utils/searchParams'

export type UseGroupByOverTimeQueryOptions = {
  filters: RequiredByKey<
    Partial<GroupByQueryFilters>,
    'segmentIds' | 'keywordGroups'
  >
  start: number
  end: number
  metricPermutations: MetricPermutation<MetricId>[]
  groupBy: QueryFacetId
  projectId?: string
  rollupBy: QueryFacetId
  rollupValues?: string[]
  sortByMetricPermutation: MetricPermutation<MetricId>
  limit: number
  offset: number
  desc: boolean
  samples: number
}

export function useGroupByOverTimeQueryOptions({
  start,
  end,
  limit,
  offset,
  desc,
  samples,
  groupBy,
  sortByMetricPermutation,
  metricPermutations,
  filters,
  projectId,
  rollupBy,
  rollupValues,
}: UseGroupByOverTimeQueryOptions) {
  const projectIdState = useActiveProjectIdState()
  projectId = projectId || projectIdState.state

  if (!rollupBy) {
    throw new Error('rollup is required')
  }

  const activeWorkspaceId = useActiveWorkspaceId()

  if (!metricsById[sortByMetricPermutation!.id!]) {
    throw new Error(
      'A valid sortByMetricPermutation.id is required. Received: ' +
        sortByMetricPermutation?.id
    )
  }

  sortByMetricPermutation = safeMetricPermutation(sortByMetricPermutation!)

  // }

  if (!groupBy) {
    throw new Error('groupBy is required')
  }

  if (!rollupBy) {
    throw new Error('rollupBy is required')
  }

  const groupByFacet = allQueryFacets.find(
    d => d.id === groupBy || d.id === `${groupBy}s`
  )!

  const rollupByFacet = allQueryFacets.find(
    d => d.id === rollupBy || d.id === `${rollupBy}s`
  )!

  const filtersAsAny = { ...(filters as any) }

  // Add in any rollupValues
  if (rollupValues?.length) {
    filtersAsAny[rollupByFacet.filterKey] = [
      ...(filtersAsAny[rollupByFacet.filterKey] ?? []),
      ...rollupValues.filter(Boolean),
    ]
  }

  // Split brand properties into objects
  filtersAsAny.brandProperties = filtersAsAny.brandProperties
    ? filtersAsAny.brandProperties.map((value: any) => {
        const [brandId, propertyName] = String(value).split('__')

        return {
          brandId: Number(brandId),
          propertyName,
        }
      })
    : undefined

  // Remove empty filters
  Object.keys(filtersAsAny).forEach(key => {
    if (filtersAsAny[key]) {
      if (
        ['brandIds', 'domainIds', 'hostIds', 'urlIds', 'segmentIds'].includes(
          key
        )
      ) {
        filtersAsAny[key] = filtersAsAny[key]?.map((d: string) => Number(d))
      }

      filtersAsAny[key] = filtersAsAny[key]?.filter(Boolean)

      if (!filtersAsAny[key].length) {
        delete filtersAsAny[key]
      }
    }
  })

  switch (rollupBy) {
    case 'brandType':
      filtersAsAny.brandIds = []
      filtersAsAny.brandProperties = []
      if (groupBy !== 'brandType') {
        filtersAsAny.brandTypes =
          filtersAsAny.brandTypes?.length === 1 ? filtersAsAny.brandTypes : []
      }
      filtersAsAny.domainIds = []
      filtersAsAny.hostIds = []
      break

    case 'brand':
      filtersAsAny.brandTypes = []
      filtersAsAny.brandProperties = []
      if (groupBy !== 'brand') {
        filtersAsAny.brandIds =
          filtersAsAny.brandIds?.length === 1 ? filtersAsAny.brandIds : []
      }
      filtersAsAny.domainIds = []
      filtersAsAny.hostIds = []
      break

    case 'brandProperty':
      filtersAsAny.brandTypes = []
      filtersAsAny.brandIds = []
      if (groupBy !== 'brandProperty') {
        filtersAsAny.brandProperties =
          filtersAsAny.brandProperties?.length === 1
            ? filtersAsAny.brandProperties
            : []
      }
      filtersAsAny.domainIds = []
      filtersAsAny.hostIds = []
      break

    case 'domain':
      filtersAsAny.brandTypes = []
      filtersAsAny.brandIds = []
      filtersAsAny.brandProperties = []
      filtersAsAny.hostIds = []
      break

    case 'host':
      filtersAsAny.brandTypes = []
      filtersAsAny.brandIds = []
      filtersAsAny.brandProperties = []
      filtersAsAny.domainIds = []
      break

    case 'url':
      filtersAsAny.brandTypes = []
      filtersAsAny.brandIds = []
      filtersAsAny.brandProperties = []
      filtersAsAny.domainIds = []
      filtersAsAny.hostIds = []
      break

    default:
      // Not supported column; handle it as needed
      break
  }

  // validate that the non-group by filters are set to exactly one
  switch (groupBy) {
    case 'brandType':
    case 'brand':
    case 'brandProperty':
    case 'domain':
    case 'host':
    case 'url':
      filtersAsAny.segmentIds =
        filtersAsAny.segmentIds?.length === 1 ? filtersAsAny.segmentIds : []
      filtersAsAny.keywordGroups =
        filtersAsAny.keywordGroups?.length === 1
          ? filtersAsAny.keywordGroups
          : []
      break

    case 'segment':
      filtersAsAny.keywordGroups =
        filtersAsAny.keywordGroups?.length === 1
          ? filtersAsAny.keywordGroups
          : []
      break

    case 'keywordGroup':
      filtersAsAny.segmentIds =
        filtersAsAny.segmentIds?.length === 1 ? filtersAsAny.segmentIds : []
      break

    case 'keyword':
      filtersAsAny.segmentIds =
        filtersAsAny.segmentIds?.length === 1 ? filtersAsAny.segmentIds : []
      filtersAsAny.keywordGroups =
        filtersAsAny.keywordGroups?.length === 1
          ? filtersAsAny.keywordGroups
          : []
      break

    default:
      // Not supported groupBy; handle it as needed
      break
  }

  const queryOptions: GroupByOverTimeQueryOptions = {
    workspaceId: activeWorkspaceId,
    teamId: Number(projectId),
    start,
    end,
    rollupBy: rollupByFacet.rollupId,
    groupBy: groupByFacet.rollupId,
    samples,
    filters: filtersAsAny,
    orderBy: [
      {
        metric: sortByMetricPermutation,
        limit,
        offset: limit! * offset!,
        desc,
      },
    ],
    includedMetrics: [sortByMetricPermutation, ...metricPermutations],
  }

  // Get the data
  return queryOptions
}
