export const stringOperatorOptions = [
  {
    label: '= Equals',
    value: '=',
  },
  {
    label: '!= Does not equal',
    value: '!=',
  },
  {
    label: '~ Contains/Includes',
    value: '~',
  },
  {
    label: '!~ Does not contain/include',
    value: '!~',
  },
  {
    label: '^ Begins with',
    value: '^',
  },
  {
    label: '!^ Does not begin with',
    value: '!^',
  },
  {
    label: '$ Ends with',
    value: '$',
  },
  {
    label: '!$ Does not end with',
    value: '!$',
  },
  {
    label: 're - Matches Regex',
    value: 're',
  },
  {
    label: '!re - Does not match Regex',
    value: '!re',
  },
  {
    label: 'is',
    value: 'is',
  },
  {
    label: '!is',
    value: '!is',
  },
]
