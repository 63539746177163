import immer from 'immer'
import zustand, { StateSelector } from 'zustand'
import LocalStorage from '../utils/LocalStorage'
import { UpdaterFn } from '../utils/index'
import { Toast } from './useToast'

export type ZustandState = {
  lastWorkspaceId: string
  themeMode: 'dark' | 'light' | 'auto'
  banners: unknown[]
  modals: any[]
  toasts: Toast[]
  popups: unknown[]
  alerts: unknown[]
  quickOptions: Record<string, unknown>
  isLoading: boolean
  navbarHeight: number
  entityLeases: Record<string, Record<string, number[]>>
  showAlerts: boolean
  showJobs: boolean
  isUserMenuOpen: boolean
  showHelp: boolean
  maximizeHelp: boolean
  helpUrl: string
  auth: {
    stage: 'loggedOut' | 'loading' | 'loggedIn'
    claims: null | {
      token: string
      expirationTime: string
    }
  }
  setState: (updater: UpdaterFn<ZustandState>) => void
}

const useZustandStore = zustand<ZustandState>(set => {
  return {
    lastWorkspaceId: LocalStorage.get('lastWorkspaceId'),
    themeMode: LocalStorage.get('themeMode') || 'auto',
    banners: [],
    modals: [],
    toasts: [],
    popups: [],
    alerts: [],
    quickOptions: {},
    isLoading: false,
    navbarHeight: 0,
    entityLeases: {},
    showAlerts: false,
    showJobs: false,
    showHelp: false,
    maximizeHelp: false,
    helpUrl: 'https://help.nozzle.io/',
    isUserMenuOpen: false,
    auth: {
      claims: null,
      stage: 'loading',
    },

    setState: fn => set(old => immer(old, fn)),
  }
})

export default function useZustand<TReturn>(
  selector?: StateSelector<ZustandState, TReturn>
) {
  const slice = useZustandStore(selector!)
  const setState = useZustandStore(state => state.setState)

  return [slice, setState] as const
}
