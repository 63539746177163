import { useMatch, useNavigate } from '@tanstack/react-router'
import * as React from 'react'
import { FaAngleRight } from 'react-icons/fa'
import { useMutation, useQueryClient } from 'react-query'
import { CreatePlanForm } from '../../../components/CreatePlanForm'
import Link from '../../../components/Link'
import QueryGate from '../../../components/QueryGate'
import { usePlansQuery } from '../../../hooks/plans'
import useLoading from '../../../hooks/useLoading'
import usePopup from '../../../hooks/usePopup'
import useToast from '../../../hooks/useToast'
import { queryKeyBillingPlans } from '../../../utils/Constants'
import { PlanPb, PlansClient } from '../../../utils/proto'

export function AdminEditPlan() {
  const {
    params: { planId },
  } = useMatch()

  const plansQuery = usePlansQuery({
    includeCustom: true,
  })

  const toast = useToast()
  const [, setLoading] = useLoading()
  const queryClient = useQueryClient()
  const popup = usePopup()
  const navigate = useNavigate()

  const savePlanMutation = useMutation({
    mutationFn: (plan: PlanPb) =>
      PlansClient.updatePlan({
        allowMissing: true,
        plan,
      }),
    onMutate: () => {
      setLoading(true)
    },
    onSuccess: async () => {
      toast({
        message: 'Plan Created',
        color: 'green-500',
      })
      await queryClient.invalidateQueries(queryKeyBillingPlans)
      navigate({
        to: '../',
      })
    },
    onError: async (err: any) => {
      console.error(err)
      await popup({
        color: 'red-500',
        title: 'Failed to save plan!',
        message: (
          <pre className="overflow-hidden whitespace-normal text-left text-sm font-bold text-red-500">
            <code>{JSON.stringify(err, null, 2)}</code>
          </pre>
        ),
      })
    },
    onSettled: () => {
      setLoading(false)
    },
  })

  const plan = React.useMemo(
    () => plansQuery.data?.plans.find(d => d.planId === planId),
    [planId, plansQuery.data?.plans]
  )

  return (
    <div>
      <div className="dark:divide-y-800 divide-y divide-gray-100">
        <div className="flex items-center p-2 text-2xl font-bold">
          <Link to="../">Plans</Link>
          <FaAngleRight />
          <div>
            <QueryGate query={plansQuery}>{() => plan?.name}</QueryGate>
          </div>
        </div>
        <div className="p-2">
          <QueryGate query={plansQuery}>
            {() => (
              <CreatePlanForm
                defaultValue={plan}
                onSubmit={savePlanMutation.mutate}
              />
            )}
          </QueryGate>
        </div>
      </div>
    </div>
  )
}
