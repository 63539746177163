import moment from 'moment'
import * as React from 'react'
import { FaInfoCircle, FaPlus, FaTimes } from 'react-icons/fa'
import { scheduleOptions } from '../options/scheduleOptions'
import { ScheduleConfig } from '../utils/schedules'
import Caption from './Caption'
import Checkbox from './Checkbox'
import Clickable from './Clickable'
import ErrorBoundary from './ErrorBoundary'
import Input from './Input'
import Select from './Select'
import TextArea from './TextAreaInput'
import { Updater } from '../utils'

export function SchedulesInput(props: {
  value: ScheduleConfig[]
  required?: boolean
  onboarding?: boolean
  onChange?: (value: Updater<ScheduleConfig[]>) => void
}) {
  return React.useMemo(
    () => (
      <ErrorBoundary>
        <div
          className="flex flex-col divide-y divide-gray-100 rounded border border-gray-100
        text-sm dark:divide-gray-800 dark:border-gray-800"
        >
          {props.value.map((schedule, i) => {
            const scheduleOption = scheduleOptions.find(
              d => d.value === schedule.id
            )

            return (
              <div key={i} className="flex divide-x divide-gray-500/20">
                <div className="flex flex-1 flex-wrap justify-between gap-2 whitespace-nowrap p-2">
                  <div>{scheduleOption?.label || 'Custom'}</div>
                  {schedule.id !== 'custom' &&
                  schedule.id &&
                  !props.onboarding ? (
                    <div className="flex items-center gap-2">
                      <label className="flex items-center gap-1 text-sm">
                        <Checkbox
                          checked={props.value[i]?.hasStart || false}
                          onChange={checked =>
                            props.onChange?.(prev => {
                              const next = [...prev]
                              next[i]!.hasStart = checked
                              return next
                            })
                          }
                        />
                        <span>Start Date</span>
                        {props.value[i]!.hasStart ? (
                          <Input
                            enableDraft
                            value={props.value[i]?.startDate}
                            type="date"
                            onChange={e => {
                              props.onChange?.(prev => {
                                const next = [...prev]
                                // @ts-expect-error  // Property 'value' does not exist on type 'EventTarg... Remove this comment to see the full error message
                                next[i]!.startDate = e.target.value
                                return next
                              })
                            }}
                          />
                        ) : null}
                      </label>
                      <label className="flex items-center gap-1 text-sm">
                        <Checkbox
                          checked={props.value[i]?.hasEnd || false}
                          onChange={checked =>
                            props.onChange?.(prev => {
                              const next = [...prev]
                              next[i]!.hasEnd = checked
                              return next
                            })
                          }
                        />
                        <span>End Date</span>
                        {props.value[i]!.hasEnd ? (
                          <Input
                            value={props.value[i]?.endDate}
                            type="date"
                            onChange={e => {
                              props.onChange?.(prev => {
                                const next = [...prev]
                                // @ts-expect-error  // Property 'value' does not exist on type 'EventTarg... Remove this comment to see the full error message
                                next[i]!.endDate = e.target.value
                                return next
                              })
                            }}
                          />
                        ) : null}
                      </label>
                    </div>
                  ) : schedule.id === 'custom' ? (
                    <TextArea
                      value={props.value[i]?.customRRule || ''}
                      placeholder="Enter a Custom RRULE..."
                      onChange={e => {
                        props.onChange?.(prev => {
                          const next = [...prev]
                          next[i]!.customRRule = e.target.value
                          return next
                        })
                      }}
                    />
                  ) : null}
                  {scheduleOption?.requiresStartDate ? (
                    <div className="flex-1">
                      <Caption>
                        <FaInfoCircle className="inline" /> "Every Other Day"
                        schedules require a custom start date.
                      </Caption>
                    </div>
                  ) : null}
                </div>
                {!props.required && props.value.length > 0 ? (
                  <div>
                    <Clickable
                      className="p-2 opacity-30 hover:text-red-500 hover:opacity-100 dark:text-white"
                      onClick={() => {
                        props.onChange?.(props.value.filter((_, j) => j !== i))
                      }}
                    >
                      <FaTimes />
                    </Clickable>
                  </div>
                ) : null}
              </div>
            )
          })}
          <Select
            // @ts-expect-error  // The type 'readonly [{ readonly label: "Hourly"; re... Remove this comment to see the full error message
            options={scheduleOptions}
            onChange={(next: any) => {
              const scheduleOption = scheduleOptions.find(d => d.value === next)

              if (!scheduleOption) throw new Error('Invalid schedule option')

              props.onChange?.([
                ...props.value,
                {
                  id: next,
                  hasStart: !!scheduleOption.requiresStartDate,
                  startDate: scheduleOption.requiresStartDate
                    ? moment().format('YYYY-MM-DD')
                    : undefined,
                },
              ])
            }}
            className="inline-flex"
          >
            {({ onClick }: any) => (
              <Clickable
                onClick={onClick}
                className="flex items-center gap-1 p-2 !font-bold text-blue-500"
              >
                <FaPlus /> Add Schedule
              </Clickable>
            )}
          </Select>
        </div>
      </ErrorBoundary>
    ),
    [props.onboarding, props.required, props.value]
  )
}
