import * as React from 'react'
import { ChartOptions, Chart as ReactChart } from 'react-charts'
import { twMerge } from 'tailwind-merge'
import { useThemeMode } from '../utils/Theme'
import NoData from './NoData'

//

export default function Chart<TDatum>(
  props: {
    options: ChartOptions<TDatum>
  } & React.ComponentProps<'div'>
) {
  const { themeMode } = useThemeMode()

  if (!props.options.data?.length) {
    return <NoData />
  }

  return false ? null : (
    <ReactChart
      className={twMerge('relative z-0 hover:z-[1]')}
      options={{
        memoizeSeries: true,
        ...props.options,
        dark: themeMode === 'dark',
      }}
    />
  )
}
