import * as React from 'react'
import { JobUrl } from '../utils/Api'
import useLocalState from './useLocalState'
import { useActiveWorkspaceId } from './workspaces'
import useZustand from './useZustand'

export type JobOptions = {
  exportToken: string
  workspaceId: number
  teamId: number
  name: string
  rowCount: number
  pollInterval?: number
}

export type Job = JobOptions & {
  status: 'pending' | 'completed' | 'failed'
  checked: boolean
  alerted: boolean
  createdAt: number
  completedAt?: number
  failedAt?: number
  urls?: (JobUrl & {
    downloaded: boolean
  })[]
}

const initialJobsState: Job[] = []

export function useJobs() {
  const workspaceId = useActiveWorkspaceId()

  const [jobs, setJobs] = useLocalState(
    'nozzle_jobs_' + workspaceId,
    initialJobsState
  )

  return [jobs, setJobs] as const
}

export function useCreateJob() {
  const [, setJobs] = useJobs()
  const [, setStore] = useZustand()
  return React.useCallback(
    (jobOptions: JobOptions) => {
      const job: Job = {
        pollInterval: 5000,
        ...jobOptions,
        createdAt: Date.now(),
        status: 'pending',
        checked: true,
        alerted: false,
      }

      setJobs(prev => {
        return [...prev, job]
      })
      setStore(old => {
        old.showJobs = true
      })
    },
    [setJobs, setStore]
  )
}
