import moment from 'moment'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Reward } from '../../openapi'
import { NozzleClient, onTeamsChangeSubscription } from '../utils/Api'
import { queryKeyRewards } from '../utils/Constants'
import useErrorPopup from './useErrorPopup'
import useLoading from './useLoading'
import { useQuerySubscription } from './useQuerySubscription'
import useToast from './useToast'
import { useActiveWorkspaceId } from './workspaces'

export function useRewardsQuery(options?: { enabled?: boolean }) {
  useQuerySubscription({
    kind: queryKeyRewards,
    label: 'Reward',
    subscriber: onTeamsChangeSubscription,
  })

  const query = useQuery(
    [queryKeyRewards],
    () => NozzleClient.fetchRewards().then(res => res.data.data),
    {
      enabled: options?.enabled ?? true,
    }
  )

  return query
}

export function useSubmitRewardMutation(options?: { silent?: boolean }) {
  const activeWorkspaceId = useActiveWorkspaceId()
  const toast = useToast()
  const errorPopup = useErrorPopup()
  const [, setLoading] = useLoading()
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (vars: { rewardId: number; value: string }) => {
      if (!activeWorkspaceId) throw new Error('')

      return NozzleClient.submitReward(vars.rewardId, {
        inputValue: vars.value,
        workspaceId: activeWorkspaceId,
      }).then(res => res.data.data)
    },
    {
      onMutate: () => {
        setLoading(true)
      },
      onSuccess: async () => {
        if (!options?.silent) {
          toast({
            message: 'Reward submitted for approval',
            color: 'green-500',
          })
        }
        await queryClient.invalidateQueries(queryKeyRewards)
      },
      onError: err => {
        console.error(err)
        if (!options?.silent) {
          errorPopup('Failed to submit Reward for approval')
        }
      },
      onSettled: () => {
        setLoading(false)
      },
    }
  )

  return mutation
}

export function useCancelRewardMutation(options?: { silent?: boolean }) {
  const activeWorkspaceId = useActiveWorkspaceId()
  const toast = useToast()
  const errorPopup = useErrorPopup()
  const [, setLoading] = useLoading()
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (vars: { rewardId: number }) => {
      if (!activeWorkspaceId) throw new Error('')

      return NozzleClient.cancelReward(vars.rewardId, {
        workspaceId: activeWorkspaceId,
      }).then(res => res.data.data)
    },
    {
      onMutate: () => {
        setLoading(true)
      },
      onSuccess: async () => {
        if (!options?.silent) {
          toast({
            message: 'Reward submission cancelled',
            color: 'green-500',
          })
        }
        await queryClient.invalidateQueries(queryKeyRewards)
      },
      onError: err => {
        console.error(err)
        if (!options?.silent) {
          errorPopup('Failed to cancel Reward submission')
        }
      },
      onSettled: () => {
        setLoading(false)
      },
    }
  )

  return mutation
}

export function getRewardAvailability(reward: Reward) {
  return reward.availableAt && moment().isBefore(moment(reward.availableAt))
    ? 'coming-soon'
    : reward.availableUntil && moment().isAfter(moment(reward.availableUntil))
    ? 'expired'
    : 'available'
}
//
