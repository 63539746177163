import * as React from 'react'
import { FaCheck, FaTrash } from 'react-icons/fa'
import Spinner from './Spinner'
import { ButtonPlain } from './ButtonPlain'

export function KeywordSourceButtons(props: {
  canSubmit: boolean
  hasPhrases: boolean
  isSubmitting: boolean
  onRemove?: () => void
}) {
  return (
    <>
      <ButtonPlain
        type="submit"
        disabled={!props.canSubmit || !props.hasPhrases}
        className="bg-green-500"
      >
        {props.isSubmitting ? (
          <>
            <Spinner color="white" /> Saving Keywords
          </>
        ) : (
          <>
            <FaCheck /> Save Keywords
          </>
        )}
      </ButtonPlain>
      {props.onRemove ? (
        <ButtonPlain
          className="bg-gray-500 hover:bg-red-500"
          onClick={() => props.onRemove?.()}
        >
          {props.isSubmitting ? (
            <>
              <Spinner /> Deleting Keyword Source
            </>
          ) : (
            <>
              <FaTrash /> Delete Keyword Source
            </>
          )}
        </ButtonPlain>
      ) : null}
    </>
  )
}
