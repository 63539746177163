import { useNavigate, useSearch } from '@tanstack/react-router'
import moment from 'moment'
import React from 'react'
import { useQuery } from 'react-query'
import { twMerge } from 'tailwind-merge'
import { SerpsWidgetState } from '../Router'
import { serpMetricsList } from '../containers/topic-clusters/metrics'
import { useActiveWorkspaceId } from '../hooks/workspaces'
import { Updater, functionalUpdate } from '../utils'
import { fetchPulls, fetchSerps } from '../utils/Api'
import {
  UTC_FORMAT,
  queryKeyKeywordPulls,
  queryKeyReportsKeywordSerp,
} from '../utils/Constants'
import { getDataColor } from '../utils/DataColors'
import { KeywordMetricsPb, KeywordPb } from '../utils/proto'
import Card from './Card'
import Loader from './Loader'
import NoData from './NoData'
import { SerpHtml } from './SerpHtml'
import { resultColumnGroupOptions } from './SerpResultsTables'

type PanelId = undefined

const panels: { label: string; id: PanelId }[] = [
  { label: 'Info', id: undefined },
]

export function KeywordMetricsComp(props: {
  keywordMetrics: KeywordMetricsPb
}) {
  const { keywordMetrics } = props
  const search = useSearch()
  const keywordMetricsPanelId = search.keywordMetricsPanelId as PanelId

  const { keyword } = keywordMetrics

  return (
    <div className="space-y-4 pb-[400px]">
      <div className="grid items-stretch gap-2 font-bold sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {(
          [
            ...serpMetricsList.map(d => ({
              label: d.label,
              value: d.getRenderedValue(keywordMetrics.serpMetrics![d.id]!),
            })),
          ] as const
        ).map((metric, i) => {
          return (
            <Card
              key={metric.label}
              className={twMerge(
                'flex flex-1 items-center justify-between gap-2 p-1 px-2',
                'md:p-2',
                // 'lg:text-center lg:flex-col',
                '!border-y-0 !border-l-4 !border-r-0 dark:shadow dark:shadow-gray-800'
              )}
              style={{
                borderColor: getDataColor(i),
              }}
            >
              <div className="text-sm uppercase opacity-80">{metric.label}</div>
              <div className="text-lg md:text-xl lg:text-2xl">
                {metric.value}
              </div>
            </Card>
          )
        })}
      </div>
      {/* <Tabs>
        {panels.map(panel => {
          return (
            <Link
              key={panel.id}
              search={d => ({
                ...d,
                keywordMetricsPanelId: panel.id,
              })}
            >
              <Tab
                className={twMerge(
                  panel.id === keywordMetricsPanelId && 'active'
                )}
              >
                {panel.label}
              </Tab>
            </Link>
          )
        })}
      </Tabs> */}
      <div>
        {(() => {
          if (!keywordMetricsPanelId) {
            return (
              <Card className="divide-y divide-gray-500/20 p-0">
                <div className="p-2 text-lg font-bold">SERP</div>
                <Serp keyword={keyword} />
              </Card>
            )
          } else {
            return 'Invalid Panel! Please select a panel from the tabs above.'
          }
        })()}
      </div>
    </div>
  )
}

export function Serp(props: { keyword?: KeywordPb }) {
  const { keyword } = props

  const activeWorkspaceId = useActiveWorkspaceId()!

  const rankingsParams = {
    workspaceId: activeWorkspaceId,
    keywordId: Number(keyword?.keywordId),
    start: moment.utc().subtract(30, 'day').startOf('day').format(UTC_FORMAT),
    end: moment.utc().endOf('day').format(UTC_FORMAT),
  }

  const rankingsQuery = useQuery(
    [queryKeyKeywordPulls, rankingsParams],
    () => fetchPulls(rankingsParams),
    {
      staleTime: Infinity,
    }
  )
  const rankingParams = {
    workspaceId: activeWorkspaceId,
    rankingId: Number(rankingsQuery.data?.[0]?.rankingId),
    // rankingId: 1291860529,
  }

  const serpQuery = useQuery(
    [queryKeyReportsKeywordSerp, rankingParams],
    ({ signal, queryKey }) => {
      const started = Date.now()

      const tryFetch = async (): Promise<any> => {
        try {
          return fetchSerps(rankingParams)
        } catch (err: any) {
          if (
            err.response?.status === 404 ||
            err.response?.data?.message === 'storage: object name is empty'
          ) {
            if (started + 1000 * 1000 < Date.now()) {
              throw new Error('SERP not found!')
            }
            if (signal?.aborted) {
              throw new Error('Aborted')
            }
            await new Promise(resolve => setTimeout(resolve, 5000))
            return tryFetch()
          }

          throw err
        }
      }

      return tryFetch()
    },
    {
      useErrorBoundary: true,
      retry: false,
      staleTime: Infinity,
      enabled: !!rankingParams.rankingId,
    }
  )

  const rankingIds = React.useMemo(
    () => (rankingParams.rankingId ? [rankingParams.rankingId] : []),
    [rankingParams.rankingId]
  )

  const serpWidget = useSearch().serpWidget as SerpsWidgetState | undefined
  const navigate = useNavigate()

  const updateSerpWidget = (updater: Updater<SerpsWidgetState>) => {
    navigate({
      search: (prev: any) => ({
        ...prev,
        serpWidget: functionalUpdate(updater, prev.serpWidget),
      }),
      replace: true,
    })
  }

  const setZoom = (updater: Updater<number>) =>
    updateSerpWidget((prev: any) => ({
      ...prev,
      zoom: functionalUpdate(updater, prev?.zoom),
    }))

  const setResultColumnGroup = (updater: Updater<any>) =>
    updateSerpWidget((prev: any) => {
      const next = functionalUpdate(updater, prev?.resultColumnGroup)
      return {
        ...prev,
        resultColumnGroup: next,
        resultColumnIds:
          resultColumnGroupOptions.find(d => d.value === next)?.columns ?? [],
      }
    })

  const setRankingIds = (updater: Updater<(number | null)[]>) => {
    updateSerpWidget((prev: any) => {
      const next = functionalUpdate(updater, prev?.rankingIds)
      return {
        ...prev,
        rankingIds: next,
      }
    })
  }

  const setResultColumnIds = (updater: Updater<string[]>) =>
    updateSerpWidget((prev: any) => ({
      ...prev,
      resultColumnIds: functionalUpdate(updater, prev?.resultColumnIds),
    }))

  const setResultSearchTerm = (updater: Updater<string>) =>
    updateSerpWidget(prev => ({
      ...prev,
      resultSearchTerm: functionalUpdate(updater, prev.resultSearchTerm),
    }))

  // const setExpandItemResults = (expandItemResults: Updater<boolean>) =>
  //   updateSerpWidget((prev: any) => ({
  //     ...prev,
  //     expandItemResults: functionalUpdate(
  //       expandItemResults,
  //       prev.expandItemResults
  //     ),
  //   }))

  // const setOffset = offset =>
  //   updateSerpWidget((prev: any) => ({
  //     ...prev,
  //     offset: functionalUpdate(offset, prev.offset),
  //   }))

  // const setLimit = limit =>
  //   updateSerpWidget((prev: any) => ({
  //     ...prev,
  //     limit: functionalUpdate(limit, prev.limit),
  //   }))

  // const setDesc = desc =>
  //   updateSerpWidget((prev: any) => ({
  //     ...prev,
  //     desc: functionalUpdate(desc, prev.desc),
  //   }))

  // const setSortById = sortById =>
  //   updateSerpWidget((prev: any) => ({
  //     ...prev,
  //     sortById: functionalUpdate(sortById, prev.sortById),
  //   }))

  const setIsNozzleVisionEnabled = (updater: Updater<boolean>) =>
    updateSerpWidget((prev: any) => ({
      ...prev,
      isNozzleVisionEnabled: functionalUpdate(
        updater,
        prev.isNozzleVisionEnabled
      ),
    }))

  // const localesQuery = useLocalesQuery({
  //   localeIds: [keyword.localeId],
  // })

  // const keywordInfoTable = (
  //   <TableEl className="w-auto inline">
  //     <tbody>
  //       <TableRow>
  //         <TableCell className="font-bold">Phrase</TableCell>
  //         <TableCell className="text-right">{props.keyword.phrase}</TableCell>
  //       </TableRow>
  //       <TableRow>
  //         <TableCell className="font-bold">Device</TableCell>
  //         <TableCell className="text-right">
  //           {formatDevice(props.keyword.device, {
  //             short: false,
  //             v2: true,
  //           })}
  //         </TableCell>
  //       </TableRow>
  //       <TableRow>
  //         <TableCell className="font-bold">Locale</TableCell>
  //         <TableCell className="text-right">
  //           {localesQuery.isLoading ? (
  //             <Loader />
  //           ) : (
  //             formatLocale(localesQuery.data![0])
  //           )}
  //         </TableCell>
  //       </TableRow>
  //     </tbody>
  //   </TableEl>
  // )

  const rankingOptions = React.useMemo(
    () =>
      rankingsQuery.data?.map(pull => ({
        label: moment.utc(pull.requested).format('lll'),
        value: pull.rankingId,
      })),
    [rankingsQuery.data]
  )

  return (
    <>
      {rankingsQuery.isLoading || serpQuery.isLoading ? (
        <div className="col-span-full space-y-4">
          <Card className="divide-y divide-gray-500/20 p-0">
            <div className="flex items-center gap-2 p-2">
              <Loader className="text-sm" />
              <div className="font-bold">Fetching SERP</div>
            </div>
          </Card>
          {/* <Card className="p-0 min-w-sm w-fit text-lg divide-y divide-gray-500/20">
            {keywordInfoTable}
          </Card> */}
        </div>
      ) : (
        <Card className="p-0">
          {serpQuery.isLoading ? (
            <div className="flex items-center justify-center p-4">
              <Loader className="text-2xl" />
            </div>
          ) : !rankingIds?.[0] ? (
            <div className="p-2">
              <NoData />
            </div>
          ) : (
            <SerpHtml
              rankingIds={serpWidget?.rankingIds}
              rankingOptions={rankingOptions}
              zoom={serpWidget?.zoom}
              isNozzleVisionEnabled={serpWidget?.isNozzleVisionEnabled}
              setIsNozzleVisionEnabled={setIsNozzleVisionEnabled}
              resultColumnIds={serpWidget?.resultColumnIds}
              resultSearchTerm={serpWidget?.resultSearchTerm}
              setRankingIds={setRankingIds}
              setResultColumnGroup={setResultColumnGroup}
              setResultColumnIds={setResultColumnIds}
              setResultSearchTerm={setResultSearchTerm}
              setZoom={setZoom}
              resultColumnGroup={serpWidget?.resultColumnGroup}
            />
          )}
        </Card>
      )}
    </>
  )
}
