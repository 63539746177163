// @ts-expect-error  // Could not find a declaration file for module 'md5'... Remove this comment to see the full error message
import md5 from 'md5'
import Color from '../utils/Color'
import { MetricId, MetricPermutation } from './Metrics'
import { RecursivePartial } from './types'
import { rankGroupOptions } from '../options/rankGroupOptions'

type RequestIdleCallbackHandle = any
type RequestIdleCallbackOptions = {
  timeout: number
}
type RequestIdleCallbackDeadline = {
  readonly didTimeout: boolean
  timeRemaining: () => number
}

declare global {
  interface Window {
    // @ts-expect-error  // Subsequent property declarations must have the sam... Remove this comment to see the full error message
    requestIdleCallback: (
      callback: (deadline: RequestIdleCallbackDeadline) => void,
      opts?: RequestIdleCallbackOptions
    ) => RequestIdleCallbackHandle
    // @ts-expect-error  // Subsequent property declarations must have the sam... Remove this comment to see the full error message
    cancelIdleCallback: (handle: RequestIdleCallbackHandle) => void
  }
}
export function regPluck(reg: any, d: any) {
  const matches = d.match(reg)
  if (matches && matches.length) {
    return matches[1]
  }
  return d
}

export function regTest(reg: any, d: any) {
  const match = d.match(reg)
  return match && match[0] === d
}

export function sum<T = number>(
  arr: T[],
  by?: (d: T, index: number) => number
): number {
  return arr.reduce(
    (sum, current, index) => sum + Number(by ? by(current, index) : current),
    0
  )
}

export function uniqBy<T>(arr: T[], fn?: (d: T) => any): T[] {
  const seen = new Set()
  const deduped: T[] = []
  arr.forEach(d => {
    const rd = fn?.(d) ?? d
    if (seen.has(rd)) {
      return
    }
    seen.add(rd)
    deduped.push(d)
  })
  return deduped
}

export function range(num: any) {
  return Array.from(new Array(num), (d, i) => i)
}

export function deepEqual(one: any, two: any): any {
  if (typeof one !== typeof two) {
    return false
  }
  if (Array.isArray(one) && Array.isArray(two)) {
    if (one.length !== two.length) {
      return false
    }
    return one.every((d, i) => deepEqual(d, two[i]))
  }
  if (isObject(one) && one !== null && isObject(two) && two !== null) {
    const keys = Object.keys(one)
    if (keys.length !== Object.keys(two).length) {
      return false
    }
    return keys.every(key => deepEqual(one[key], two[key]))
  }
  return one === two
}

export function isObject(obj: any) {
  return typeof obj === 'object' && !Array.isArray(obj) && obj !== null
}

export type ImmerUpdaterFn<T> = UpdaterFn<T>

export type UpdaterFn<T> = (prev: T) => T

export type Updater<T> = UpdaterFn<T> | T

export function functionalUpdate<T>(updater: Updater<T>, input: T): T {
  return typeof updater === 'function'
    ? (updater as UpdaterFn<T>)(input)
    : updater
}

/**
 * This function returns `a` if `b` is deeply equal.
 * If not, it will replace any deeply equal children of `b` with those of `a`.
 * This can be used for structural sharing between JSON values for example.
 */
export function replaceEqualDeep(a: any, b: any) {
  if (a === b) {
    return a
  }

  const array = Array.isArray(a) && Array.isArray(b)

  if (array || (isPlainObject(a) && isPlainObject(b))) {
    const aSize = array ? a.length : Object.keys(a).length
    const bItems = array ? b : Object.keys(b)
    const bSize = bItems.length
    const copy = array ? [] : {}

    let equalItems = 0

    for (let i = 0; i < bSize; i++) {
      const key = array ? i : bItems[i]
      // @ts-expect-error  // Type 'unknown' cannot be used as an index type.
      copy[key] = replaceEqualDeep(a[key], b[key])
      // @ts-expect-error  // Type 'unknown' cannot be used as an index type.
      if (copy[key] === a[key]) {
        equalItems++
      }
    }

    return aSize === bSize && equalItems === aSize ? a : copy
  }

  return b
}

// Copied from: https://github.com/jonschlinkert/is-plain-object
function isPlainObject(o: any) {
  if (!hasObjectPrototype(o)) {
    return false
  }

  // If has modified constructor
  const ctor = o.constructor
  if (typeof ctor === 'undefined') {
    return true
  }

  // If has modified prototype
  const prot = ctor.prototype
  if (!hasObjectPrototype(prot)) {
    return false
  }

  // If constructor does not have an Object-specific method
  if (!prot.hasOwnProperty('isPrototypeOf')) {
    return false
  }

  // Most likely a plain Object
  return true
}

function hasObjectPrototype(o: any) {
  return Object.prototype.toString.call(o) === '[object Object]'
}

// @ts-expect-error  // 'deepIncludes' implicitly has return type 'any' be... Remove this comment to see the full error message
export function deepIncludes(a: any, b: any) {
  if (a === b) {
    return true
  }

  if (typeof a !== typeof b) {
    return false
  }

  if (typeof a === 'object') {
    return !Object.keys(b).some(key => !deepIncludes(a[key], b[key]))
  }

  return false
}

export function getTextColor(color: string, white: string, black: string) {
  return Color(color).getLuminance() > 0.4 ? black : white
}

export function loopObj<T>(
  obj: T,
  mapper: (key: keyof T, value: T[keyof T], obj: T) => void
) {
  // @ts-expect-error
  Object.entries(obj).forEach(([key, value]) => {
    // @ts-expect-error
    mapper(key as keyof T, value, obj)
  })
}

export function mapObjEntries<T>(
  obj: T,
  mapper: (key: keyof T, value: T[keyof T], all: T) => [keyof T, T[keyof T]]
) {
  const newObj = {} as T

  loopObj(obj, (key, value, all) => {
    const [newKey, newValue] = mapper(key, value, all)
    newObj[newKey] = newValue
  })

  return newObj
}

export function mapObjValues<T>(
  obj: T,
  mapper: (value: T[keyof T], key: keyof T, all: T) => T[keyof T]
): T {
  return mapObjEntries(obj, (key, value, all) => {
    return [key, mapper(value, key, all)]
  })
}

export function someObj<T>(
  obj: T,
  predicate: (value: T[keyof T], key: keyof T, all: T) => any
) {
  let found = false

  loopObj(obj, (key, value, all) => {
    if (found) return
    found = !!predicate(value, key, all)
  })

  return found
}

export function filterObj<T extends object>(
  obj: T | undefined,
  predicate: (value: T[keyof T], key: keyof T, all: T) => any
) {
  if (!obj) {
    return {}
  }

  const newObj = {} as T

  loopObj(obj, (key, value, all) => {
    if (predicate(value, key, all)) {
      newObj[key] = value
    }
  })

  return newObj
}

export function last<T>(item: T[] | undefined): T | undefined {
  return item?.[item?.length - 1]
}

export function getDataValue<T extends MetricId>(
  data: any,
  metricPermutation: MetricPermutation<T>
) {
  return data?.[metricPermutation.id]?.[
    `agg_${metricPermutation.aggregation}`
  ]?.[metricPermutation.subAggregation]?.[metricPermutation.postAggregation]
}

export function sortBy<T>(arr: T[], accessor: (d: T) => any = d => d): T[] {
  return arr
    .map((d: any, i: any) => [d, i])
    .sort(([a, ai], [b, bi]) => {
      a = accessor(a)
      b = accessor(b)

      if (typeof a === 'undefined') {
        if (typeof b === 'undefined') {
          return 0
        }
        return 1
      }

      a = isNumericString(a) ? Number(a) : a
      b = isNumericString(b) ? Number(b) : b

      return a === b ? ai - bi : a > b ? 1 : -1
    })
    .map((d: any) => d[0])
}

export function multiSortBy<T>(
  arr: T[],
  accessors: ((item: T) => any)[] = [d => d]
): T[] {
  return arr
    .map((d, i) => [d, i] as const)
    .sort(([a, ai], [b, bi]) => {
      for (const accessor of accessors) {
        const ao = accessor(a)
        const bo = accessor(b)

        if (typeof ao === 'undefined') {
          if (typeof bo === 'undefined') {
            continue
          }
          return 1
        }

        const an = isNumericString(ao as string) ? Number(ao) : ao
        const bn = isNumericString(bo as string) ? Number(bo) : bo

        if (an === bn) {
          continue
        }

        return an > bn ? 1 : -1
      }

      return ai - bi
    })
    .map(([d]) => d)
}

export function max<TItem>(arr: TItem[], fn: (item: TItem) => number) {
  let max = 0
  let maxItem = arr?.[0]

  arr.forEach(nextItem => {
    const nextValue = fn(nextItem)
    if (nextValue > max) {
      max = nextValue
      maxItem = nextItem
    }
  })

  return maxItem
}

// export function sortSeriesBy(series, metricInfo) {
//   const sorted = [...series]
//     .map((d, index) => ({ ...d, index }))
//     .sort((a, b) => {
//       const aValue = getLastSeriesValue(a, metricInfo)
//       const bValue = getLastSeriesValue(b, metricInfo)
//       return aValue > bValue ? 1 : aValue === bValue ? b.index - a.index : -1
//     })

//   if (metricInfo.desc) {
//     sorted.reverse()
//   }

//   return sorted
// }

export function getRankGroupFromRank(
  rank: any,
  relativeTotal?: number
): number {
  if (typeof relativeTotal === 'number') {
    rank = Math.floor((rank / relativeTotal) * 100)
  }

  if (rank < 2) {
    return 0
  }
  if (rank < 3) {
    return 1
  }
  if (rank < 4) {
    return 2
  }
  if (rank < 7) {
    return 3
  }
  if (rank < 11) {
    return 4
  }
  if (rank < 16) {
    return 5
  }
  if (rank < 21) {
    return 6
  }
  if (rank < 51) {
    return 7
  }
  return 8
}

export function formatRankGroup(rankGroup: number) {
  return rankGroupOptions[rankGroup]?.label
}

export function isNumericString(str: string): boolean {
  if (typeof str !== 'string') {
    return false // we only process strings!
  }

  return (
    !isNaN(str as unknown as number) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ) // ...and ensure strings of whitespace fail
}

export function getEmailGravatar(email?: string) {
  return email
    ? `https://www.gravatar.com/avatar/${md5(email.trim().toLowerCase())}?d=mp`
    : ''
}

export function getHubSpotIDFromCookie() {
  if (typeof document !== 'undefined') {
    return document.cookie.replace(
      /(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/,
      '$1'
    )
  }
}

export function parseLastHrefPath(href: string) {
  if (!href) {
    return
  }
  try {
    const url = new URL(href)
    return url.pathname
      .replace(/^\/|\/$/g, '')
      .split('/')
      .reverse()[0]
  } catch (err) {
    return href
      .replace(/^\/|\/$/g, '')
      .split('/')
      .reverse()[0]
  }
}

export function parsePropertyUrlUsername(href: string) {
  const path = parseLastHrefPath(href)

  if (path.startsWith('@')) {
    return path.substring(1)
  }

  return path
}

export const deepMerge = function <TObj>(
  defaults: RecursivePartial<TObj>,
  obj: TObj
) {
  const nonObjDefaults = {}
  const objDefaults = {}

  Object.keys(defaults).forEach(key => {
    // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const value = defaults[key]
    if (isObject(value) && isObject(obj)) {
      // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      objDefaults[key] = deepMerge(value, obj[key])
    } else {
      // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      nonObjDefaults[key] = value
    }
  })

  return {
    ...nonObjDefaults,
    ...obj,
    ...objDefaults,
  }
}

export function deepDiff(a: any, b: any): any {
  if (Array.isArray(a)) {
    const changes = a

      .map((item, index) => deepDiff(item, b[index]))
      .filter(Boolean)
    return changes.length ? changes : undefined
  }

  if (isObject(a)) {
    const changes: any = {}
    Object.keys(a).forEach(key => {
      const diff = deepDiff(a[key], b[key])
      if (diff) {
        changes[key] = diff
      }
    })
    return Object.keys(changes).length ? changes : undefined
  }

  return a !== b ? ([a, b] as const) : undefined
}

export function round(num: number, precision = 1) {
  return Math.round(num * precision) / precision
}

export function objWithoutKeys<TObj, TKey extends keyof TObj>(
  obj: TObj,
  keys: TKey[]
): Omit<TObj, TKey> {
  const newObj = { ...obj }
  keys.forEach(key => delete newObj[key])
  return newObj
}

// string => binary
export function encodeToBinary(str: string): string {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
      return String.fromCharCode(parseInt(p1, 16))
    })
  )
}

// binary => string
export function decodeFromBinary(str: string): string {
  return decodeURIComponent(
    Array.prototype.map
      .call(atob(str), function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )
}

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function parseNumber(str: string) {
  return parseFloat(str.replace(/,/g, ''))
}

export function getFloatPrecision(num: number) {
  return (num + '').split('.')[1]?.length || 0
}

export function pick<TObj extends object, TKeys extends (keyof TObj)[]>(
  obj: TObj,
  keys: TKeys
): [Pick<TObj, TKeys[number]>, Omit<TObj, TKeys[number]>] {
  const newObj: any = {}
  const rest: any = {}
  Object.keys(obj).forEach((key: any) => {
    if (keys.includes(key)) {
      newObj[key] = (obj as any)[key]
    } else {
      rest[key] = (obj as any)[key]
    }
  })
  return [newObj, rest]
}

export function log<T>(value: T, label?: string): T {
  if (label) {
    console.info(label, value)
  } else {
    console.info(value)
  }
  return value
}

export function flattenBy<T, K extends keyof T>(arr: T[], key: K): T[] {
  // recurse down the array, adding each item to the result
  // if the item has a property of 'key', recurse on that property

  const result: T[] = []

  arr.forEach(item => {
    result.push(item)
    if (item[key]) {
      // @ts-ignore
      result.push(...(flattenBy(item[key], key) as any))
    }
  })

  return result
}

/**
 * Get a value from an object using a path, including dot notation.
 */
export function getBy(obj: any, path: any) {
  const pathArray = makePathArray(path)
  const pathObj = pathArray
  return pathObj.reduce((current: any, pathPart: any) => {
    if (typeof current !== 'undefined') {
      return current[pathPart]
    }
    return undefined
  }, obj)
}

/**
 * Set a value on an object using a path, including dot notation.
 */
export function setBy(obj: any, _path: any, updater: Updater<any>) {
  const path = makePathArray(_path)

  function doSet(parent?: any): any {
    if (!path.length) {
      return functionalUpdate(updater, parent)
    }

    const key = path.shift()

    if (typeof key === 'string') {
      if (typeof parent === 'object') {
        return {
          ...parent,
          [key]: doSet(parent[key]),
        }
      }
      return {
        [key]: doSet(),
      }
    }

    if (typeof key === 'number') {
      if (Array.isArray(parent)) {
        const prefix = parent.slice(0, key)
        return [
          ...(prefix.length ? prefix : new Array(key)),
          doSet(parent[key]),
          ...parent.slice(key + 1),
        ]
      }
      return [...new Array(key), doSet()]
    }

    throw new Error('Uh oh!')
  }

  return doSet(obj)
}

const reFindNumbers0 = /^(\d*)$/gm
const reFindNumbers1 = /\.(\d*)\./gm
const reFindNumbers2 = /^(\d*)\./gm
const reFindNumbers3 = /\.(\d*$)/gm
const reFindMultiplePeriods = /\.{2,}/gm

const intPrefix = '__int__'
const intReplace = `${intPrefix}$1`

function makePathArray(str: string) {
  if (Array.isArray(str)) {
    return str
  }
  if (typeof str !== 'string') {
    throw new Error('Path must be a string.')
  }

  return str
    .replace('[', '.')
    .replace(']', '')
    .replace(reFindNumbers0, intReplace)
    .replace(reFindNumbers1, `.${intReplace}.`)
    .replace(reFindNumbers2, `${intReplace}.`)
    .replace(reFindNumbers3, `.${intReplace}`)
    .replace(reFindMultiplePeriods, '.')
    .split('.')
    .map(d => {
      if (d.indexOf(intPrefix) === 0) {
        return parseInt(d.substring(intPrefix.length), 10)
      }
      return d
    })
}

export function toggleArray<T>(arr: T[], values: T[]) {
  const newArr = [...arr]
  values.forEach(value => {
    const index = newArr.indexOf(value)
    if (index === -1) {
      newArr.push(value)
    } else {
      newArr.splice(index, 1)
    }
  })
  return newArr
}

export function toggleSet<T>(set: Set<T>, values: T[]) {
  const newSet = new Set(set)
  values.forEach(value => {
    if (newSet.has(value)) {
      newSet.delete(value)
    } else {
      newSet.add(value)
    }
  })
  return newSet
}
