import { useSearch } from '@tanstack/react-router'
import * as React from 'react'
import { useField } from 'react-form'
import { BiImport } from 'react-icons/bi'
import { FaEdit, FaPlus, FaQuestionCircle } from 'react-icons/fa'
import Button from '../components/Button'
import Caption from '../components/Caption'
import useModal from '../hooks/useModal'
import { ImportSourceOptions } from '../options/ImportSourceOptions'
import { Keyword, KeywordSource } from '../utils/Api'
import { dedupePhraseGroups } from '../utils/Phrases'
import { AccurankerModal } from './AccurankerModal'
import DevicesField from './DevicesField'
import { GoogleSearchConsoleModal } from './GoogleSearchConsoleModal'
import { KeywordDotComModal } from './KeywordDotComModal'
import { KeywordGroupHelp } from './KeywordGroupHelp'
import { LocalesField } from './LocalesField'
import { PhraseGroupsAsTextPhrasesForm } from './PhraseGroupsByPhraseText'
import PhraseGroupsFieldGroups from './PhraseGroupsFieldGroups'
import PhraseGroupsFieldPhrases from './PhraseGroupsFieldPhrases'
import PhraseGroupsModal from './PhraseGroupsModal'
import { RankWatchModal } from './RankWatchModal'
import SchedulesField from './SchedulesField'
import Select from './Select'
import { SeoMonitorModal } from './SeoMonitorModal'
import { SerpWooModal } from './SerpWooModal'
import { SerpstatModal } from './SerpstatModal'
import Tooltip from './Tooltip'
import { Modal } from './Modals'
import Card from './Card'

//

const defaultValue = {
  phraseMode: 'phrases',
  phraseGroups: [],
  devices: ['d'],
  localeIds: [44249],
}

export function KeywordSourceBasic(props: {
  keywordSource?: KeywordSource
  generatedKeywords: Keyword[]
  isGeneratingKeywords: boolean
}) {
  // const { keywordSource: searchKeywordSource } = useSearch()
  // const [keywordSource, setKeyWordSource] = React.useState(
  //   props.keywordSource || searchKeywordSource
  // )

  // @ts-expect-error  // Property 'FieldScope' does not exist on type 'Fiel... Remove this comment to see the full error message
  const { FieldScope } = useField('config', {
    defaultValue,
  })

  const [showTextEditor, setShowTextEditor] = React.useState(false)

  const { value: phraseGroups, setValue: setPhraseGroups } = useField(
    'config.phraseGroups',
    {
      defaultValue: defaultValue.phraseGroups,
    }
  )

  const info = React.useMemo(
    () => dedupePhraseGroups(phraseGroups),
    [phraseGroups]
  )

  const groupOptions = React.useMemo(
    () =>
      Object.keys(info.groups).map(d => ({
        value: d,
        label: d,
      })),
    [info.groups]
  )
  const showModal = useModal()

  const onSubmit = async (
    selectedRows: any,
    globalGroups: any,
    source: any
  ) => {
    setPhraseGroups(
      // @ts-expect-error  // Argument of type '(old: never[]) => PhraseGroup[]'... Remove this comment to see the full error message
      old =>
        dedupePhraseGroups([
          ...old,
          ...selectedRows.map(row => ({
            phrase: row.phrase,
            groups: [...globalGroups, ...row.groups],
          })),
          // ...selectedRows.map((row: any) => {
          // return {
          // phrase:
          // source === 'Keyword.com'
          //   ? row.original.kw
          //   : source === 'SEOmonitor'
          //   ? row.original.keyword_data.name
          //   : source === 'GSC'
          //   ? row.original.key
          //   : row.original.keyword,
          // groups: globalGroups,
          // }
          // }),
        ]).phraseGroups
    )
  }

  return (
    <>
      <div className="grid grid-cols-1 gap-2 lg:grid-cols-2">
        <FieldScope>
          <Card className="flex flex-col gap-2">
            <div className="flex flex-wrap items-center gap-2">
              <div className="text-xl font-bold">Keyword Phrases</div>
              <div className="ml-auto">
                <Tooltip
                  tooltip={
                    <>
                      This is where you can add keyword phrases, edit them, and
                      assign them to keyword groups.
                    </>
                  }
                >
                  <Caption className="font-normal">
                    <FaQuestionCircle className="inline" /> What are keyword
                    phrases?
                  </Caption>
                </Tooltip>
              </div>
            </div>
            <div className="flex flex-wrap items-center gap-2">
              <Select
                options={ImportSourceOptions}
                className="inline-flex"
                onChange={value =>
                  showModal(() => (
                    <Modal>
                      <FieldScope>
                        {
                          // value === 'rankWatch' ? (
                          //   <RankWatchModal
                          //     onSubmit={onSubmit}
                          //     isKeywords={true}
                          //     groupOptions={groupOptions}
                          //   />
                          // ) : value === 'accuranker' ? (
                          //   <AccurankerModal
                          //     onSubmit={onSubmit}
                          //     isKeywords={true}
                          //     groupOptions={groupOptions}
                          //   />
                          // ) :
                          value === 'googleSearchConsole' ? (
                            <GoogleSearchConsoleModal
                              onSubmit={onSubmit}
                              groupOptions={groupOptions}
                            />
                          ) : null
                          // : value === 'serpstat' ? (
                          //   <SerpstatModal
                          //     onSubmit={onSubmit}
                          //     groupOptions={groupOptions}
                          //   />
                          // ) : value === 'seoMonitor' ? (
                          //   <SeoMonitorModal
                          //     onSubmit={onSubmit}
                          //     groupOptions={groupOptions}
                          //   />
                          // ) : value === 'keyword' ? (
                          //   <KeywordDotComModal
                          //     onSubmit={onSubmit}
                          //     groupOptions={groupOptions}
                          //   />
                          // ) : (
                          //   <SerpWooModal
                          //     onSubmit={onSubmit}
                          //     groupOptions={groupOptions}
                          //   />
                          // )
                        }
                      </FieldScope>
                    </Modal>
                  ))
                }
              >
                {({ onClick }: any) => (
                  <Button
                    size="xs"
                    color={['gray-100', 'gray-800']}
                    onClick={onClick}
                  >
                    <BiImport className="inline" />
                    Import from Source
                  </Button>
                )}
              </Select>
              <Button
                size="xs"
                color={['gray-100', 'gray-800']}
                onClick={() => setShowTextEditor(true)}
              >
                <FaEdit className="inline" /> Edit as Text
              </Button>
            </div>

            {showTextEditor ? (
              <PhraseGroupsAsTextPhrasesForm
                phraseGroups={phraseGroups}
                onChange={(newPhraseGroups: any) => {
                  setPhraseGroups(newPhraseGroups)
                  setShowTextEditor(false)
                }}
                onCancel={() => setShowTextEditor(false)}
              />
            ) : (
              <>
                <PhraseGroupsFieldPhrases
                  phraseGroups={phraseGroups}
                  onPhraseGroupsChange={setPhraseGroups}
                />
              </>
            )}
          </Card>
        </FieldScope>
        <div className="flex flex-col gap-2">
          <Card className="flex flex-col gap-2">
            <div className="flex flex-wrap justify-between gap-2">
              <div className="text-xl font-bold">Schedules</div>
              <ScheduleHelp />
            </div>
            <SchedulesField />
          </Card>
          <FieldScope>
            <Card className="flex flex-col gap-2">
              <div className="flex flex-wrap justify-between gap-2">
                <div className="text-xl font-bold">Devices</div>
                <DevicesHelp />
              </div>
              <DevicesField label="" defaultValue={defaultValue.devices} />
            </Card>

            <Card className="flex flex-col gap-2">
              <div className="flex flex-wrap justify-between gap-2">
                <div className="text-xl font-bold">Locales</div>
                <LocalesHelp />
              </div>
              <LocalesField
                label=""
                defaultValue={defaultValue.localeIds}
                required
              />
            </Card>
          </FieldScope>
        </div>
      </div>
      {/* 
      <div className="h-8" />
      <div className="text-xl font-bold">
        Total Tracked Keywords:{' '}
        {isGeneratingKeywords ? (
          <Loader />
        ) : (
          formatNumber(generatedKeywords.length)
        )}
      </div>
      <Caption className="font-normal">
        <FaInfoCircle className="inline" /> A keyword represents the unique
        combination of the search phrase, device, and locale.
      </Caption>
      <div className="h-8" /> */}
      <FieldScope>
        <Card className="flex flex-col gap-2 p-2">
          <div className="flex flex-wrap justify-between gap-2">
            <div className="text-xl font-bold">Keyword Groups</div>
            <KeywordGroupHelp />
          </div>
          <PhraseGroupsFieldGroups
            phraseGroups={phraseGroups}
            onPhraseGroupsChange={setPhraseGroups}
          />
        </Card>
      </FieldScope>
    </>
  )
}

export function ScheduleHelp() {
  return (
    <Tooltip
      tooltip={
        <>
          Schedules determines the frequency of when new data is pulled for your
          keywords. Increasing the frequency will consume more usage, but offer
          higher fidelity data.
        </>
      }
    >
      <Caption>
        <FaQuestionCircle className="inline" /> What are schedules?
      </Caption>
    </Tooltip>
  )
}

export function DevicesHelp() {
  return (
    <Tooltip
      tooltip={
        <>
          Every keyword phrase will be pulled once for each device you select.
          Most people pick desktop and a mobile device. Unless you’re tracking
          app-related phrases, you typically only need one mobile device.
        </>
      }
    >
      <Caption>
        <FaQuestionCircle className="inline" /> Which devices should I track?
      </Caption>
    </Tooltip>
  )
}

export function LocalesHelp() {
  return (
    <Tooltip
      tooltip={
        <>
          A locale is a combination of search engine, language, and location. To
          add locales, enter a search term and select a locale. The locales you
          select will be used as search origins for all keywords in this keyword
          source. Every keyword phrase will be pulled once for each locale you
          select.
        </>
      }
    >
      <Caption>
        <FaQuestionCircle className="inline" /> What are locales?
      </Caption>
    </Tooltip>
  )
}
