import * as React from 'react'
import { RiEmotionSadLine } from 'react-icons/ri'

export default function NoData() {
  return (
    <div className="flex h-full w-full flex-auto items-center justify-center gap-1 text-xs opacity-30">
      <div>No data available</div>
      <RiEmotionSadLine className="inline text-base" />
    </div>
  )
}
