import { css } from '@emotion/css'
import { useVirtualizer, Virtualizer } from '@tanstack/react-virtual'
import { matchSorter } from 'match-sorter'
import * as React from 'react'
import {
  FaCaretDown,
  FaCheck,
  FaCheckSquare,
  FaMinusCircle,
  FaPlusCircle,
  FaRegSquare,
  FaTimes,
} from 'react-icons/fa'
import { IoCopyOutline, IoFilter, IoInvertModeSharp } from 'react-icons/io5'
import { TiCancel } from 'react-icons/ti'
import { twMerge } from 'tailwind-merge'
import Card from '../components/Card'
import Input, { InputProps, RawInput } from '../components/Input'
import useRect from '../hooks/useRect'
import { SelectOption, useSelect } from '../hooks/useSelect'
import { Sticker } from '../hooks/useSticker'
import { multiSortBy, objWithoutKeys, pick } from '../utils'
import copyToClipboard from '../utils/copyToClipboard'
import { formatNumber } from '../utils/Format'
import { withProps } from '../utils/withProps'
import LabelWrap from './LabelWrap'
import Loader from './Loader'
import Tooltip from './Tooltip'
import useGetPrevious from '../hooks/useGetPrevious'
import { NoInfer } from '@tanstack/react-store'

//

const Styles = withProps('div')<{ inline?: boolean }>(
  ({ className, inline, ...props }) => ({
    ...props,
    className: twMerge('relative flex', inline && 'z-index[0]', className),
  })
)

type DropdownStylesProps = {
  inline?: boolean
  isOpen?: boolean
}

const DropdownStyles = withProps(Card)<DropdownStylesProps>(
  ({ className, inline, isOpen, ...props }) => ({
    ...props,
    className: twMerge(
      `flex flex min-h-0 w-[500px] max-w-[95vw] flex-1 flex-col flex-col border border-gray-100 p-0 shadow-xl`,
      inline ? `` : `mt-1`,
      css`
        z-index: 9999;
        overflow-x: auto;

        ${inline &&
        `
          width: 100%;
          min-width: initial;
          position: relative;
          z-index: initial;
          box-shadow: none;
        `};

        ${isOpen &&
        `
          z-index: 9999;
        `};
      `,
      className
    ),
  })
)

export const SelectInput = React.forwardRef(
  (
    {
      multi,
      Input: InputComp = Input,
      label,
      inlineLabel,
      error,
      ...props
    }: InputProps & {
      multi: boolean
      selectedOption?: any[]
      clearValue: () => void
      Input?: React.ComponentType<any>
      label?: string
      inlineLabel?: any
      error?: any
    },
    ref
  ) => {
    const labelRef = React.useRef<HTMLButtonElement>(null!)
    const sizeRef = React.useRef<HTMLButtonElement>(null!)
    const labelRect = useRect(labelRef.current, true)
    const sizeRect = useRect(sizeRef.current, true)
    return (
      <LabelWrap label={label} error={error}>
        <div className="relative inline-flex flex-1 items-center gap-2">
          <div className="absolute left-1 flex items-center justify-center">
            {inlineLabel ? (
              <div
                // @ts-expect-error  // Type 'MutableRefObject<HTMLButtonElement>' is not ... Remove this comment to see the full error message
                ref={labelRef}
                className="border-r border-gray-200 px-2 py-1 dark:border-gray-700"
              >
                {typeof inlineLabel === 'string' ? (
                  <div className="text-sm font-bold">{inlineLabel}</div>
                ) : (
                  inlineLabel
                )}
              </div>
            ) : null}
            {multi && props.selectedOption?.length ? (
              <div
                // @ts-expect-error  // Type 'MutableRefObject<HTMLButtonElement>' is not ... Remove this comment to see the full error message
                ref={sizeRef}
                className="border-r border-gray-200 px-2 py-1 text-xs font-bold text-gray-500 dark:border-gray-700"
              >
                {formatNumber(props.selectedOption?.length ?? 0, {
                  short: true,
                })}
              </div>
            ) : null}
          </div>
          <InputComp
            {...props}
            className={twMerge('w-full cursor-pointer pr-6', props.className)}
            style={{
              paddingLeft:
                8 +
                (multi && props.selectedOption?.length ? sizeRect.width : 0) +
                (inlineLabel ? labelRect.width : 0),
            }}
            ref={ref}
          />
          <FaCaretDown className="pointer-events-none absolute right-3 top-1/2 -translate-y-1/2 opacity-70" />
        </div>
      </LabelWrap>
    )
  }
)

export type Chip = {
  label: string
  value: unknown
}

export default React.forwardRef(function Select<
  TOption extends { label: string | number; value: any; meta?: any } = {
    label: any
    value: any
  }
>(
  {
    multi,
    value: userValue,
    options: userOptions,
    onChange: userOnChange,
    pageSize = 10,
    inline,
    create,
    closeOnSelect,
    CustomDropdownInput,
    CustomInput,
    dropdownInputProps: userDropdownInputProps = {},
    inputProps: userInputProps = {},
    children,
    afterOptions,
    beforeOptions,
    label,
    inlineLabel,
    inputValue,
    onInputChange,
    isLoading,
    error,
    renderOption = d => d.label,
    className,
    style,
    defaultValue,
    optionProps: userOptionProps = {},
    enableDraft,
    placeholder,
    sort,
    ...rest
  }: (
    | {
        multi?: false
        value?: TOption['value']
        onChange?: (value: TOption['value']) => void
      }
    | {
        multi: true
        value?: TOption['value'][]
        onChange?: (value: TOption['value'][]) => void
      }
  ) & {
    label?: any
    error?: any
    options?: TOption[]
    create?: boolean
    renderChip?: (chip: Chip) => any
    closeOnSelect?: boolean
    enableDraft?: boolean
    placeholder?: string
    renderOption?: (option: NoInfer<TOption>) => any
    sort?: boolean
    CustomInput?: React.ComponentType<any>
    className?: string
    inlineLabel?: any
    inline?: boolean
    inputProps?: Record<string, any>
    children?:
      | React.ReactNode
      | ((renderProps: {
          onClick: () => void
          selectedOption: SelectOption
        }) => any)
    afterOptions?: React.ReactNode
    beforeOptions?: React.ReactNode
    dropdownInputProps?: Record<string, any>
  },
  ref: any
) {
  const [showType, setShowType] = React.useState<
    'all' | 'selected' | 'unselected'
  >('all')
  const optionsRef = React.useRef()
  const virtualizerDivRef = React.useRef<HTMLDivElement>(null!)

  const shiftAmount = pageSize

  const defaultMultiValue = React.useMemo(() => [], [])
  const defaultOptions = React.useMemo(() => [], [])

  const [draftValue, setDraftValue] = React.useState(userValue)

  let value = enableDraft ? draftValue : userValue

  const onChange = (value: any) => {
    if (enableDraft) {
      setDraftValue(value)
    } else {
      userOnChange?.(value)
    }
  }

  React.useEffect(() => {
    if (enableDraft) {
      setDraftValue(userValue)
    }
  }, [enableDraft, userValue])

  if (multi && typeof value === 'undefined') {
    value = defaultMultiValue as any
  }

  let options = userOptions

  if (!options) {
    options = defaultOptions
  }

  options = React.useMemo(() => {
    if (multi && create) {
      const createValues = value?.filter(
        (d: any) => !options?.find(opt => opt.value === d)
      )

      return [
        // @ts-expect-error
        ...options,
        ...createValues.map((d: any) => ({
          label: d,
          value: d,
        })),
      ]
    }

    return options
  }, [create, multi, options, value])

  const virtualizerRef = React.useRef<Virtualizer<any, any>>()

  const filterFn = React.useCallback(
    (options, search) => {
      const preFilteredOptions =
        showType === 'all'
          ? options
          : multiSortBy(options, [
              d => {
                if (showType === 'selected') {
                  return value.includes(d.value) ? -1 : 1
                } else {
                  return !value.includes(d.value) ? -1 : 1
                }
              },
            ])

      // .filter(d => {
      //     if (showType === 'selected') {
      //       return value.includes(d.value)
      //     } else {
      //       return !value.includes(d.value)
      //     }
      //   })

      return !search
        ? preFilteredOptions
        : sort ?? true
        ? matchSorter(preFilteredOptions, search, {
            keys: ['label', 'value'],
            baseSort: (a, b) => (a.index < b.index ? -1 : 1),
            threshold: multi ? matchSorter.rankings.NO_MATCH : undefined,
          })
        : preFilteredOptions
    },
    [multi, showType, value]
  )

  const {
    visibleOptions,
    selectedOption,
    highlightedIndex,
    searchValue,
    getInputProps,
    getOptionProps,
    isOpen,
    setOpen,
    setSearch,
    selectIndex,
  } = useSelect({
    multi,
    create,
    // @ts-expect-error  // Type '{ label: string; value: TValue; }[] | undefi... Remove this comment to see the full error message
    options,
    value,
    onChange,
    scrollToIndex: i => {
      try {
        virtualizerRef.current?.scrollToIndex(i)
      } catch {}
    },
    shiftAmount,
    filterFn,
    optionsRef,
    closeOnSelect,
  })

  const mountedRef = React.useRef(false)

  const previousOpen = useGetPrevious(isOpen)

  React.useEffect(() => {
    if (mountedRef.current && previousOpen() && !isOpen && enableDraft) {
      userOnChange?.(draftValue as any)
    }
    mountedRef.current = true
  }, [isOpen])

  let resolvedNotFound

  const virtualizer = useVirtualizer({
    count: visibleOptions.length,
    estimateSize: () => 30,
    getScrollElement: () => virtualizerDivRef.current,
    overscan: 5,
  })

  virtualizerRef.current = virtualizer

  if (multi) {
    if (create) {
      if (value.includes(searchValue)) {
        resolvedNotFound = (
          <span>
            "<strong>{searchValue}</strong>" has already been entered.
          </span>
        )
      } else {
        resolvedNotFound = (
          <span>Enter a value, or select an existing one...</span>
        )
      }
    } else {
      resolvedNotFound = value.includes(searchValue) ? (
        <span>
          "<strong>{searchValue}</strong>" has already been entered.
        </span>
      ) : (
        <span>Enter a value, or select an existing one...</span>
      )
    }
  } else {
    resolvedNotFound = searchValue ? (
      isLoading ? (
        <span>
          Searching for "<strong>{searchValue}</strong>"... <Loader />
        </span>
      ) : (
        <span>
          No options were found for "<strong>{searchValue}</strong>"
        </span>
      )
    ) : (
      <span>Type to search, or select an option...</span>
    )
  }

  React.useEffect(() => {
    if (create && defaultValue) {
      setSearch(defaultValue)
    }
  }, [create, defaultValue, setSearch])

  const DropdownInput = CustomDropdownInput || RawInput
  const TargetInput = CustomInput || SelectInput

  const getInputPropsProps = {
    ref,
    placeholder:
      placeholder || (multi ? 'Select multiple...' : 'Select one...'),
    selectedOption,
    label,
    inlineLabel,
    error,
    onChange: onInputChange,
  }

  const inputProps = getInputProps({
    ...rest,
    ...getInputPropsProps,
    // @ts-expect-error  // Argument of type '{ onRemove: ((index: any) => voi... Remove this comment to see the full error message
    onRemove: multi
      ? (index: any) => onChange(value.filter((d: any, i: any) => i !== index))
      : undefined,
  })

  const dropdownInputProps = {
    ...pick(inputProps, ['value', 'onChange', 'ref', 'onKeyDown'])[0],
    onPaste: (e: ClipboardEvent) => {
      let pasted = e.clipboardData?.getData('text') ?? ''
      if (!pasted.startsWith('copypaste:')) {
        return
      }

      e.preventDefault()

      pasted = pasted.substring('copypaste:'.length)

      const pastedValues = (() => {
        if (multi) {
          if (pasted.includes(',; ')) {
            return pasted.split(',; ').map(d => d.trim())
          }
          if (pasted.includes('; ')) {
            return pasted.split('; ').map(d => d.trim())
          }
          if (pasted.includes(', ')) {
            return pasted.split(',').map(d => d.trim())
          }
        }
        // return [pasted]
      })()

      if (multi) {
        onChange(
          pastedValues.map(
            d => options?.find(option => option.value == d)?.value ?? d
          )
        )
      }
      //  else {
      //   onChange(
      //     options?.find(option => option.value == pastedValues[0])?.value ??
      //       pastedValues[0]
      //   )
      // }
    },
    ...userDropdownInputProps,
  }

  const targetInputProps = {
    ...pick(inputProps, [
      'placeholder',
      'selectedOption',
      'onClick',
      'onChange',
      'onFocus',
      'onBlur',
      'error',
      'disabled',
    ])[0],
    multi,
    value: multi
      ? selectedOption.map((d: any) => d.label).join(', ')
      : selectedOption.label,
    clearValue: () => onChange(multi ? [] : null),
    selectedOption,
    label,
    inlineLabel,
    ...userInputProps,
  }

  const toggleShowType = () => {
    if (showType === 'all') {
      setShowType('selected')
    } else if (showType === 'selected') {
      setShowType('unselected')
    } else {
      setShowType('all')
    }
  }

  const selectFiltered = () => {
    onChange(
      Array.from(
        // @ts-expect-error  // Type '(TMulti extends true ? TValue[] : TValue) | ... Remove this comment to see the full error message
        new Set([...value, ...visibleOptions.map(d => d.value)]).values()
      )
    )
  }

  const selectAll = () => {
    onChange(Array.from(new Set([...options.map(d => d.value)]).values()))
  }

  const removeFiltered = () => {
    onChange(
      // @ts-expect-error
      value.filter((d: any) => !visibleOptions.map(d => d.value).includes(d))
    )
  }

  const clearAll = () => {
    onChange([])
  }

  const selectInverse = () => {
    const values: any = []

    options.forEach(d => {
      if (!value.includes(d.value)) {
        values.push(d.value)
      }
    })
    onChange(values)
  }

  const copyValues = () => {
    let clipboardValue

    if (multi) {
      const mvalue = value as string[]

      if (mvalue?.some(d => `${d}`.includes(', '))) {
        clipboardValue = mvalue.join('; ')
      } else if (mvalue?.some(d => `${d}`.includes('; '))) {
        clipboardValue = mvalue.join(',; ')
      } else {
        clipboardValue = mvalue.join(', ')
      }
      copyToClipboard(`copypaste:${clipboardValue}`)
    }
    //  else {
    //   clipboardValue = value
    // }
  }

  const dropdownContent = (
    <>
      <div className="relative flex w-full items-center divide-x divide-gray-200 border-b border-gray-200 dark:divide-gray-800 dark:border-gray-800">
        <div className="flex flex-1 items-center">
          <DropdownInput
            autoFocus
            placeholder="Search..."
            {...dropdownInputProps}
            className={twMerge(
              inputProps.className,
              'min-w-0 flex-1 px-2 py-2 text-sm',
              dropdownInputProps.className
            )}
          />
          {isLoading ? <Loader className="ml-auto mr-2 text-[.6rem]" /> : null}
        </div>
        {multi ? (
          <>
            {userOptions?.length ? (
              <button
                className={
                  'flex items-center gap-1 px-2 py-1 text-sm font-bold'
                }
                onClick={toggleShowType}
              >
                <IoFilter className="text-gray-500" />{' '}
                <div
                  className={
                    showType === 'selected'
                      ? 'text-green-500'
                      : showType === 'unselected'
                      ? 'text-red-500'
                      : ''
                  }
                >
                  {showType === 'all'
                    ? 'All'
                    : showType === 'selected'
                    ? 'Selected'
                    : 'Unselected'}
                </div>
              </button>
            ) : null}
            <div className="flex items-center px-2 text-sm font-bold text-gray-500">
              <Tooltip tooltip={`Select All (${formatNumber(options.length)})`}>
                <button
                  className="flex items-center gap-1 px-1 py-1 text-blue-400 opacity-70 hover:opacity-100"
                  onClick={() => selectAll()}
                >
                  <FaCheckSquare />
                </button>
              </Tooltip>
              <Tooltip tooltip={`Clear All (${formatNumber(value.length)})`}>
                <button
                  className="flex items-center px-1 py-1 text-red-500 opacity-70 hover:opacity-100"
                  onClick={clearAll}
                >
                  <TiCancel className="text-xl" />
                </button>
              </Tooltip>
              <Tooltip
                tooltip={`Add Filtered (${formatNumber(
                  visibleOptions.length
                )})`}
              >
                <button
                  className="flex items-center px-1 py-1 text-green-500 opacity-70 hover:opacity-100"
                  onClick={selectFiltered}
                >
                  <FaPlusCircle />
                </button>
              </Tooltip>
              <Tooltip
                tooltip={`Remove Filtered (${formatNumber(
                  visibleOptions.length
                )})`}
              >
                <button
                  className="flex items-center px-1 py-1 text-orange-500 opacity-70 hover:opacity-100"
                  onClick={removeFiltered}
                >
                  <FaMinusCircle />
                </button>
              </Tooltip>
              <Tooltip tooltip="Invert Selection">
                <button
                  className="flex items-center gap-px px-1 py-1 opacity-70 hover:opacity-100"
                  onClick={() => selectInverse()}
                >
                  <IoInvertModeSharp className="text-base" />
                </button>
              </Tooltip>
              <Tooltip tooltip="Copy Values">
                <button
                  className="flex items-center gap-px px-1 py-1 text-black opacity-70 hover:opacity-100 dark:text-white"
                  onClick={() => copyValues()}
                >
                  <IoCopyOutline className="text-base" />
                </button>
              </Tooltip>
            </div>
          </>
        ) : null}
      </div>
      {beforeOptions}
      {visibleOptions.length ? (
        <div ref={virtualizerDivRef} className="min-h-0 flex-1 overflow-y-auto">
          <div
            className="relative divide-y divide-gray-500/20"
            style={{
              height: virtualizer.getTotalSize(),
            }}
          >
            {virtualizer.getVirtualItems().map(item => {
              const option = visibleOptions[item.index]
              const isSelected =
                value && multi
                  ? // @ts-expect-error  // Property 'includes' does not exist on type 'TMulti... Remove this comment to see the full error message
                    value.includes(option.value)
                  : value === option.value
              const isHighlighted = highlightedIndex === item.index

              const optionProps = getOptionProps({
                ...userOptionProps,
                index: item.index,
                className: twMerge(
                  `whitespace-no-wrap absolute flex w-auto min-w-[100%] cursor-pointer items-center gap-2 bg-white px-2 py-1 text-left
                    text-sm leading-tight text-black dark:bg-gray-850 dark:text-white
                    [&:hover>.--show]:opacity-100
                    `,
                  isSelected && `bg-gray-50 font-bold dark:bg-gray-800`,
                  isHighlighted &&
                    `bg-gray-100 text-black dark:bg-gray-700 dark:text-white`,
                  option.disabled &&
                    `pointer-events-none opacity-50 dark:bg-gray-500`,
                  userOptionProps.className
                ),
                style: {
                  transform: `translateY(${item.start}px)`,
                  ...userOptionProps.style,
                },
              })

              if (multi) {
                optionProps.onClick = () => {
                  if (!isSelected) {
                    selectIndex(item.index)
                  } else {
                    onChange(value.filter((d: any) => d !== option.value))
                  }
                }
              }

              return (
                <button
                  data-index={item.index}
                  ref={virtualizer.measureElement}
                  {...optionProps}
                >
                  {multi ? (
                    isSelected ? (
                      isHighlighted ? (
                        <div>
                          <FaTimes className="rounded bg-red-500 p-px text-white" />
                        </div>
                      ) : (
                        <div>
                          <FaCheckSquare
                            className={twMerge(
                              'text-blue-600 dark:text-blue-400'
                            )}
                          />
                        </div>
                      )
                    ) : (
                      <div>
                        <FaRegSquare />
                      </div>
                    )
                  ) : null}
                  {renderOption(option)}
                  {!multi && isSelected ? (
                    <FaCheck className="ml-auto" />
                  ) : null}
                  {multi ? (
                    <div
                      className="--show ml-auto rounded-lg px-2 py-1 text-xs font-bold text-blue-600 opacity-0 hover:bg-blue-500 hover:!text-white dark:text-blue-400"
                      onClick={e => {
                        onChange([option.value])
                        e.stopPropagation()
                      }}
                    >
                      Only
                    </div>
                  ) : null}
                </button>
              )
            })}
          </div>
        </div>
      ) : (
        <div
          disabled
          className="bg-white px-2 py-1 text-sm text-black dark:bg-gray-900 dark:text-white"
        >
          {resolvedNotFound}
        </div>
      )}
      <div onClick={() => setOpen(false)}>{afterOptions}</div>
    </>
  )

  if (inline) {
    return (
      <Styles
        {...{ inline, className: twMerge('overflow-auto', className), style }}
      >
        <DropdownStyles inline={inline} ref={optionsRef}>
          {dropdownContent}
        </DropdownStyles>
      </Styles>
    )
  }

  return (
    <Sticker
      show={isOpen}
      side={['bottom left', 'top left', 'right top', 'bottom right']}
      render={({ getProps }) => (
        <DropdownStyles
          {...getProps({
            inline,
            ref: optionsRef,
            className: twMerge('max-h-[300px]'),
            style: {
              pointerEvents: 'all',
            },
          })}
        >
          {dropdownContent}
        </DropdownStyles>
      )}
    >
      {({ getProps }: any) => (
        <Styles
          {...getProps({
            style,
            className,
          })}
        >
          {typeof children === 'function' ? (
            children(targetInputProps)
          ) : (
            <TargetInput
              {...objWithoutKeys(targetInputProps, [
                'selectedOption',
                'clearValue',
              ])}
            />
          )}
        </Styles>
      )}
    </Sticker>
  )
})
