import { FaAngleRight, FaCircle, FaInfoCircle } from 'react-icons/fa'
import { Dashboard } from '.'

import Card from '../../components/Card'
import DashboardContainer from '../../components/DashboardContainer'
import DashboardContent from '../../components/DashboardContent'
import DashboardHeader from '../../components/DashboardHeader'
import KeywordGroupGate from '../../components/DashboardKeywordGroupGate'
import KeywordGroupPicker from '../../components/KeywordGroupPicker'
import DashboardRollupGate from '../../components/DashboardRollupGate'
import SegmentIdGate from '../../components/SegmentIdGate'
import SegmentPicker from '../../components/SegmentPicker'
import DashboardProjectGate from '../../components/DashboardTeamGate'
import DashboardTimeRange from '../../components/DashboardTimeRange'
import ErrorBoundary from '../../components/ErrorBoundary'
import { ExpandableWidget } from '../../components/ExpandableWidget'
import { GroupByFiltersWidget } from '../../components/GroupByFiltersWidget'
import Head from '../../components/Head'
import { PerformanceMetricsWidget } from '../../components/PerformanceMetricsWidget'
import RollupPicker, { useRollup } from '../../components/RollupPicker'
import {
  DisplayMode,
  TimeSeriesWidget,
  TimeSeriesWidgetMode,
} from '../../components/TimeSeriesWidget'
import Tooltip from '../../components/Tooltip'
import useZustand from '../../hooks/useZustand'
import {
  DEFAULT_SAMPLES,
  performanceByKeywordSlug,
} from '../../utils/Constants'
import {
  MetricId,
  MetricPermutation,
  MetricPostAggregation,
  metricPermutation,
} from '../../utils/Metrics'
import { allQueryFacets, queryFacetsById } from '../../utils/queryFacets'
import { ProjectPicker } from '../../components/ProjectPicker'
import { useKeywordOptionsQuery } from '../../hooks/keywords'
import { KeywordsWidget } from '../../components/KeywordsWidget'
import { useSearchState } from '../../components/useSearchState'
import {
  useKeywordGroupState,
  useMetricIdState,
  useSegmentIdState,
  useSyncMetricIdState,
} from '../../utils/searchParams'
import { TableDisplayMode } from '../../utils/tableDisplayMode'
import useWidgetPaginationReset from '../../hooks/useWidgetPaginationReset'
import useSyncMetricsState from '../../hooks/useSyncMetricsState'
import Link from '../../components/Link'
import { useNavigate } from '@tanstack/react-router'

//

const title = 'Performance by Keyword'

export const metricIds: MetricId[] = [
  'top_rank',
  'click_through_rate',
  'estimated_traffic',
  'top_pixels_from_top',
  'percentage_of_viewport',
  'percentage_of_dom',
  'results',
  'unique_urls',
  'ppc_value',
  'adwords_search_volume',
  'top_stat_base_rank',
]

export const performanceByKeywordDashboard: Dashboard = {
  name: 'By Keyword',
  id: performanceByKeywordSlug,
  icon: <FaCircle className="inline-block scale-75 transform text-xs" />,
}

export function PerformanceByKeyword() {
  const [rollupBy, rollupValue] = useRollup()

  const rollupFacet = allQueryFacets.find(d => d.id === rollupBy)

  const dashboardReadyGate = !rollupBy
    ? 'Please select a rollup.'
    : !rollupValue
    ? `Please select at least one ${rollupFacet?.label}. You can also change the Rollup to Domain and analyze data for any domain right now.`
    : null

  const canShowUrlsAndKeywords = !['domainIds', 'hostIds', 'urlIds'].includes(
    rollupBy
  )

  const [, setStore] = useZustand(state => state.helpUrl)
  const openDashDocs = () => {
    setStore(old => {
      old.showHelp = true
      old.helpUrl = 'https://help.nozzle.io/performance-by-keyword-dashboard'

      return old
    })
  }

  return (
    <DashboardContainer>
      <Head>
        <title>{title}</title>
      </Head>
      <Card className="divide-y divide-gray-500/20 p-0 ">
        <div className="p-2">
          <DashboardHeader right={<DashboardTimeRange />}>
            <div className="flex flex-wrap items-center gap-2">
              <ProjectPicker />
              <RollupPicker allowDomainsAndUrls />
              <FaAngleRight className="inline" />
              <span>{title}</span>
              <button onClick={openDashDocs} type="button">
                <Tooltip tooltip="Click here to learn more about this dashboard">
                  <FaInfoCircle className="text-sm text-blue-500" />
                </Tooltip>
              </button>
            </div>
          </DashboardHeader>
        </div>
        {dashboardReadyGate ? null : (
          <div className="flex flex-wrap gap-4 p-2">
            <div className="flex flex-auto items-center gap-2">
              <SegmentPicker />
            </div>
            <div className="flex flex-auto items-center gap-2">
              <KeywordGroupPicker />
            </div>
          </div>
        )}
      </Card>
      <DashboardProjectGate>
        <DashboardRollupGate>
          <SegmentIdGate>
            <KeywordGroupGate>
              {dashboardReadyGate ? (
                <Card>{dashboardReadyGate}</Card>
              ) : !canShowUrlsAndKeywords ? (
                <Card className="p-0">
                  <div className="divide-y divide-gray-500/20">
                    <div className="m-0 p-2 text-lg">Keywords</div>
                    <div className="p-2 text-sm italic opacity-80">
                      <FaInfoCircle className="inline-block" /> Metrics by
                      Keyword are only supported in{' '}
                      <strong>Brand, Brand Type</strong> and{' '}
                      <strong>Brand Property</strong> rollups.
                    </div>
                  </div>
                </Card>
              ) : (
                <DashboardContent>
                  <div className="col-span-full">
                    <ErrorBoundary>
                      <Metrics />
                    </ErrorBoundary>
                  </div>
                  <div className="col-span-full">
                    <ErrorBoundary>
                      <Filters />
                    </ErrorBoundary>
                  </div>
                  <Top />
                  <Winners />
                  <Losers />
                  <div className="col-span-full">
                    <ErrorBoundary>
                      <KeywordsTable />
                    </ErrorBoundary>
                  </div>
                </DashboardContent>
              )}
            </KeywordGroupGate>
          </SegmentIdGate>
        </DashboardRollupGate>
      </DashboardProjectGate>
    </DashboardContainer>
  )
}

const queryFacet = queryFacetsById.keyword

export type PerformanceByKeywordMetricsWidget = {
  metricId: (typeof metricIds)[number]
  postAggregation: MetricPostAggregation
  samples: number
  limit: number | undefined
}

const useMetricsWidgetState = () => {
  const metricIdState = useMetricIdState(metricIds)

  const searchState = useSearchState<PerformanceByKeywordMetricsWidget>({
    path: 'performanceByKeywordMetricsWidget',
    useDefaultValue: () => {
      return {
        metricId: metricIdState.state!,
        samples: DEFAULT_SAMPLES,
        limit: 1,
        postAggregation: 'value',
      }
    },
  })

  useSyncMetricIdState(metricIds, searchState.state.metricId)

  return searchState
}

function Metrics() {
  const [rollupBy, rollupValue] = useRollup()
  const segmentIdState = useSegmentIdState()
  const keywordGroupState = useKeywordGroupState()
  const widgetState = useMetricsWidgetState()

  return (
    <PerformanceMetricsWidget
      {...{
        ...widgetState.state,
        isDirty: widgetState.isDirty,
        metricIds,
        summary: false,
        groupBy: rollupBy,
        rollupBy,
        rollupValues: [rollupValue],
        filters: {
          segmentIds: [segmentIdState.state],
          keywordGroups: [keywordGroupState.state],
        },
        setMetricsState: widgetState.setState as any,
        onReset: widgetState.reset,
      }}
    />
  )
}

export type PerformanceByKeywordKeywordIds = string[]

const useKeywordIdsState = () => {
  return useSearchState<PerformanceByKeywordKeywordIds>({
    path: 'keywordIds',
    useDefaultValue: () => [],
  })
}

const useUrlIdsState = () => {
  return useSearchState<PerformanceByKeywordKeywordIds>({
    path: 'urlIds',
    useDefaultValue: () => [],
  })
}

export function Filters() {
  const keywordOptionsQuery = useKeywordOptionsQuery()
  const keywordIdsState = useKeywordIdsState()

  return (
    <Card>
      <GroupByFiltersWidget
        filterValues={keywordIdsState.state}
        labelPlural={queryFacet.labelPlural}
        optionsQuery={keywordOptionsQuery}
        onFilterValuesChange={keywordIdsState.setState}
      />
    </Card>
  )
}

export type PerformanceByKeywordTopWidget = {
  metricId: (typeof metricIds)[number]
  samples: number
  limit: number
  displayPostAggregation: MetricPostAggregation
  offset: number
  tableDisplayMode: TableDisplayMode
  sortByMetricPermutation: MetricPermutation<MetricId>
  desc: boolean
  expanded: boolean
  displayMode: DisplayMode
  widgetMode: TimeSeriesWidgetMode
  showLegends: boolean
}

const useTopWidgetState = () => {
  return useSearchState<PerformanceByKeywordTopWidget>({
    path: 'performanceByKeywordTopWidget',
    useDefaultValue: () => {
      const parentState = useMetricsWidgetState()

      return {
        metricId: parentState.state.metricId,
        samples: DEFAULT_SAMPLES,
        desc: true,
        limit: 5,
        offset: 0,
        displayPostAggregation: 'value',
        sortByMetricPermutation: metricPermutation(parentState.state.metricId),
        widgetMode: 'top',
        displayMode: 'summary',
        tableDisplayMode: 'summary',
        expanded: false,
        showLegends: false,
      }
    },
  })
}

function Top() {
  const [rollupBy, rollupValue] = useRollup()
  const keywordIdsState = useKeywordIdsState()
  const urlIdsState = useUrlIdsState()
  const keywordGroupState = useKeywordGroupState()
  const segmentIdState = useSegmentIdState()
  const navigate = useNavigate()

  const metricsState = useMetricsWidgetState()
  const widgetState = useTopWidgetState()

  useWidgetPaginationReset(widgetState.state, widgetState.setState)
  useSyncMetricsState({
    parentState: metricsState.state,
    setState: widgetState.setState,
    syncMetric: true,
    syncSortByMetric: true,
    syncPostAggregations: false,
    syncSamples: true,
  })

  return (
    <ExpandableWidget expanded={widgetState.state.expanded}>
      <TimeSeriesWidget
        {...{
          title: 'Top',
          description: `The best performing results for a given metric as of the last datetime for the selected time range.`,
          groupBy: 'keyword',
          rollupBy,
          rollupValues: [rollupValue],
          filters: {
            segmentIds: [segmentIdState.state],
            keywordIds: keywordIdsState.state,
            keywordGroups: [keywordGroupState.state],
            urlIds: urlIdsState.state,
          },
          metricIds,
          showWidgetMode: false,
          canTitleDrillThrough: false,
          canExpand: true,
          state: widgetState.state,
          setState: widgetState.setState,
          isDirty: widgetState.isDirty,
          onReset: widgetState.reset,
          onClickSerie: serie => {
            return navigate({
              to: `/dashboards/performance-single-keyword`,
              search: {
                keywordId: queryFacetsById.keyword.getSerieValue(serie),
              },
            })
          },
          renderSeriesLabel: serie => {
            return (
              <Link
                to={`/dashboards/performance-single-keyword`}
                search={{
                  keywordId: queryFacetsById.keyword.getSerieValue(serie),
                }}
              />
            )
          },
        }}
      />
    </ExpandableWidget>
  )
}

export type PerformanceByKeywordWinnersWidget = {
  metricId: (typeof metricIds)[number]
  samples: number
  limit: number
  displayPostAggregation: MetricPostAggregation
  offset: number
  tableDisplayMode: TableDisplayMode
  sortByMetricPermutation: MetricPermutation<MetricId>
  desc: boolean
  expanded: boolean
  displayMode: DisplayMode
  widgetMode: TimeSeriesWidgetMode
  showLegends: boolean
}

const useWinnersWidgetState = () => {
  return useSearchState<PerformanceByKeywordWinnersWidget>({
    path: 'performanceByKeywordWinnersWidget',
    useDefaultValue: () => {
      const parentState = useMetricsWidgetState()

      return {
        metricId: parentState.state.metricId,
        samples: DEFAULT_SAMPLES,
        desc: true,
        limit: 5,
        offset: 0,
        displayPostAggregation: 'change',
        sortByMetricPermutation: metricPermutation(parentState.state.metricId, {
          postAggregation: 'change',
        }),
        widgetMode: 'winners',
        displayMode: 'summary',
        tableDisplayMode: 'summary',
        expanded: false,
        showLegends: false,
      }
    },
  })
}

function Winners() {
  const [rollupBy, rollupValue] = useRollup()
  const keywordIdsState = useKeywordIdsState()
  const keywordGroupState = useKeywordGroupState()
  const segmentIdState = useSegmentIdState()
  const metricsState = useMetricsWidgetState()
  const widgetState = useWinnersWidgetState()
  const navigate = useNavigate()

  useWidgetPaginationReset(widgetState.state, widgetState.setState)
  useSyncMetricsState({
    parentState: metricsState.state,
    setState: widgetState.setState,
    syncMetric: true,
    syncSortByMetric: true,
    syncPostAggregations: false,
    syncSamples: true,
  })

  return (
    <ExpandableWidget expanded={widgetState.state.expanded}>
      <TimeSeriesWidget
        {...{
          title: 'Winners',
          description: `The results with the most positive change for a given metric over the selected time range.`,
          groupBy: 'keyword',
          rollupBy,
          rollupValues: [rollupValue],
          filters: {
            segmentIds: [segmentIdState.state],
            keywordIds: keywordIdsState.state,
            keywordGroups: [keywordGroupState.state],
          },
          metricIds,
          showWidgetMode: false,
          canTitleDrillThrough: false,
          canExpand: true,
          state: widgetState.state,
          setState: widgetState.setState,
          isDirty: widgetState.isDirty,
          onReset: widgetState.reset,
          onClickSerie: serie => {
            return navigate({
              to: `/dashboards/performance-single-keyword`,
              search: {
                keywordId: queryFacetsById.keyword.getSerieValue(serie),
              },
            })
          },
          renderSeriesLabel: serie => {
            return (
              <Link
                to={`/dashboards/performance-single-keyword`}
                search={{
                  keywordId: queryFacetsById.keyword.getSerieValue(serie),
                }}
              />
            )
          },
        }}
      />
    </ExpandableWidget>
  )
}

export type PerformanceByKeywordLosersWidget = {
  metricId: (typeof metricIds)[number]
  samples: number
  limit: number
  displayPostAggregation: MetricPostAggregation
  offset: number
  tableDisplayMode: TableDisplayMode
  sortByMetricPermutation: MetricPermutation<MetricId>
  desc: boolean
  expanded: boolean
  displayMode: DisplayMode
  widgetMode: TimeSeriesWidgetMode
  showLegends: boolean
}

const useLosersWidgetState = () => {
  return useSearchState<PerformanceByKeywordLosersWidget>({
    path: 'performanceByKeywordLosersWidget',
    useDefaultValue: () => {
      const parentState = useMetricsWidgetState()

      return {
        metricId: parentState.state.metricId,
        samples: DEFAULT_SAMPLES,
        desc: false,
        limit: 5,
        offset: 0,
        displayPostAggregation: 'change',
        sortByMetricPermutation: metricPermutation(parentState.state.metricId, {
          postAggregation: 'change',
        }),
        widgetMode: 'losers',
        displayMode: 'summary',
        tableDisplayMode: 'summary',
        expanded: false,
        showLegends: false,
      }
    },
  })
}

function Losers() {
  const [rollupBy, rollupValue] = useRollup()
  const keywordIdsState = useKeywordIdsState()
  const keywordGroupState = useKeywordGroupState()
  const segmentIdState = useSegmentIdState()
  const metricsState = useMetricsWidgetState()
  const widgetState = useLosersWidgetState()
  const navigate = useNavigate()

  useWidgetPaginationReset(widgetState.state, widgetState.setState)
  useSyncMetricsState({
    parentState: metricsState.state,
    setState: widgetState.setState,
    syncMetric: true,
    syncSortByMetric: true,
    syncPostAggregations: false,
    syncSamples: true,
  })

  return (
    <ExpandableWidget expanded={widgetState.state.expanded}>
      <TimeSeriesWidget
        {...{
          title: 'Losers',
          description: `The results with the most negative change for a given metric over the selected time range.`,
          groupBy: 'keyword',
          rollupBy,
          rollupValues: [rollupValue],
          filters: {
            segmentIds: [segmentIdState.state],
            keywordIds: keywordIdsState.state,
            keywordGroups: [keywordGroupState.state],
          },
          metricIds,
          showWidgetMode: false,
          canTitleDrillThrough: false,
          canExpand: true,
          state: widgetState.state,
          setState: widgetState.setState,
          isDirty: widgetState.isDirty,
          onReset: widgetState.reset,
          onClickSerie: serie => {
            return navigate({
              to: `/dashboards/performance-single-keyword`,
              search: {
                keywordId: queryFacetsById.keyword.getSerieValue(serie),
              },
            })
          },
          renderSeriesLabel: serie => {
            return (
              <Link
                to={`/dashboards/performance-single-keyword`}
                search={{
                  keywordId: queryFacetsById.keyword.getSerieValue(serie),
                }}
              />
            )
          },
        }}
      />
    </ExpandableWidget>
  )
}

export type PerformanceByKeywordKeywordsWidget = {
  metricId: (typeof metricIds)[number]
  samples: number
  limit: number
  displayPostAggregation: MetricPostAggregation
  offset: number
  tableDisplayMode: TableDisplayMode
  sortByMetricPermutation: MetricPermutation<MetricId>
  desc: boolean
  expanded: boolean
  displayMode: DisplayMode
  widgetMode: TimeSeriesWidgetMode
  showLegends: boolean
}

const useKeywordsWidgetState = () => {
  return useSearchState<PerformanceByKeywordKeywordsWidget>({
    path: 'performanceByKeywordKeywordsWidget',
    useDefaultValue: () => {
      const parentState = useMetricsWidgetState()

      return {
        metricId: parentState.state.metricId,
        samples: DEFAULT_SAMPLES,
        desc: true,
        limit: 50,
        offset: 0,
        displayPostAggregation: 'value',
        sortByMetricPermutation: metricPermutation(parentState.state.metricId),
        widgetMode: 'top',
        displayMode: 'summary',
        tableDisplayMode: 'summary',
        expanded: false,
        showLegends: false,
      }
    },
  })
}

function KeywordsTable() {
  const [rollupBy, rollupValue] = useRollup()
  const segmentIdState = useSegmentIdState()
  const keywordGroupState = useKeywordGroupState()

  const metricsState = useMetricsWidgetState()
  const keywordIdsState = useKeywordIdsState()
  const widgetState = useKeywordsWidgetState()

  useWidgetPaginationReset(widgetState.state, widgetState.setState)
  useSyncMetricsState({
    parentState: metricsState.state,
    setState: widgetState.setState,
    syncMetric: true,
    syncSortByMetric: true,
    syncPostAggregations: true,
    syncSamples: false,
  })

  return (
    <KeywordsWidget
      {...{
        rollupBy,
        rollupValues: [rollupValue],
        filters: {
          segmentIds: [segmentIdState.state],
          keywordGroups: [keywordGroupState.state],
          keywordIds: keywordIdsState.state,
        },
        metricIds,
        state: widgetState.state,
        setState: widgetState.setState,
        isDirty: widgetState.isDirty,
        onReset: widgetState.reset,
      }}
    />
  )
}
