import { format } from 'path'
import * as React from 'react'
import { twMerge } from 'tailwind-merge'
import { parseNumber, round } from '../utils'
import { formatNumber } from '../utils/Format'
import Input from './Input'

export interface NumberInputProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'onChange' | 'value'
  > {
  value: any
  enableDraft?: boolean
  enableIncrementDraft?: boolean
  onChange?: (value: number | undefined) => void
  allowUndefined?: boolean
  precision?: number
  Input?: any
}

export default function NumberInput({
  value: userValue,
  enableDraft,
  enableIncrementDraft,
  onChange: userOnChange,
  allowUndefined = false,
  step: _step,
  precision: userPrecision,
  Input: ResolvedInput = Input,
  onKeyDown,
  ...rest
}: NumberInputProps) {
  const step = Number(_step || 1)
  const min = typeof rest.min !== 'undefined' ? Number(rest.min) : null
  const max = typeof rest.max !== 'undefined' ? Number(rest.max) : null
  const [value, setDraft] = React.useState<number>(() => userValue as number)
  const precision = userPrecision ?? 1

  const format = React.useCallback(
    (val: any) => {
      if (allowUndefined && (val === undefined || val === '')) {
        return ''
      }

      return (
        formatNumber(val, {
          defaultValue: '',
          precision: precision ?? 'auto',
        }) ?? ''
      )
    },
    [allowUndefined, precision]
  )

  const onChange = (val: any, fromIncrement?: boolean, fromBlur?: boolean) => {
    // eslint-disable-next-line eqeqeq
    if (val == undefined || isNaN(val)) {
      if (!allowUndefined) return
      val = undefined
    }

    if (typeof min === 'number' && val < min) {
      val = min
    } else if (typeof max === 'number' && val > max) {
      val = max
    }

    val =
      val === undefined
        ? val
        : Math.round(val * Math.pow(10, precision)) / Math.pow(10, precision)

    if (fromBlur) {
      setStr(format(value))
      userOnChange?.(val)
    } else if (fromIncrement) {
      setStr(format(val))

      if (enableIncrementDraft ?? enableDraft) {
        setDraft(val)
      } else {
        userOnChange?.(val)
      }
    } else {
      if (enableDraft) {
        setDraft(val)
      } else {
        userOnChange?.(val)
      }
    }
  }

  const [str, setStr] = React.useState<string>(format(value as number))

  const error =
    str.endsWith('.') ||
    (() => {
      const parsed = parseNumber(str)
      return (
        isNaN(parsed) ||
        (typeof min === 'number' && parsed < min) ||
        (typeof max === 'number' && parsed > max)
      )
    })()

  const handleChange = (str: string) => {
    setStr(str)

    if (str.endsWith('.')) {
      return
    }

    const num = parseNumber(str) as any

    onChange(num)
  }

  React.useEffect(() => {
    setDraft(userValue as number)
    setStr(format(userValue))
  }, [format, userValue])

  return (
    <>
      <ResolvedInput
        {...rest}
        value={str}
        onBlur={() => {
          onChange(value, true, true)
        }}
        onKeyDown={(e: any) => {
          // use up, down arrow keys to increment/decrement and shift as a multiplier of 10
          if (e.key === 'Enter') {
            if (enableDraft || enableIncrementDraft) {
              onChange(value, true, true)
            }
          } else if (e.key === 'ArrowUp') {
            const initVal =
              (typeof value === 'number' && !isNaN(value)
                ? value
                : undefined) ??
              min ??
              0
            e.preventDefault()

            const val =
              (e.metaKey || e.ctrlKey) && max !== null
                ? max
                : initVal +
                  (e.altKey ? step / 10 : e.shiftKey ? step * 10 : step)

            onChange(val, true)
          } else if (e.key === 'ArrowDown') {
            const initVal =
              (typeof value === 'number' && !isNaN(value)
                ? value
                : undefined) ??
              max ??
              0
            e.preventDefault()

            const val =
              (e.metaKey || e.ctrlKey) && min !== null
                ? min
                : initVal -
                  (e.altKey ? step / 10 : e.shiftKey ? step * 10 : step)

            onChange(val, true)
          }

          onKeyDown?.(e)
        }}
        onChange={(e: any) => {
          handleChange(e.currentTarget.value)
        }}
        className={twMerge(error ? '!text-red-500' : '', rest.className)}
      />
    </>
  )
}
