import { createColumnHelper } from '@tanstack/react-table'
import * as React from 'react'
import { BsThreeDots } from 'react-icons/bs'
import { FaCog, FaPlus, FaTimes } from 'react-icons/fa'

import Button from '../../components/Button'
import Card from '../../components/Card'
import { Menu, MenuItem } from '../../components/Menu'
import Link from '../../components/Link'
import QueryGate from '../../components/QueryGate'
import Table from '../../components/Table'
import { usePlansQuery } from '../../hooks/plans'
import useConfirm from '../../hooks/useConfirm'
import { useTable } from '../../hooks/useTable'
import { PlanPb, ProductFamilyPb } from '../../utils/proto'
import { useNavigate } from '@tanstack/react-router'

const columnHelper = createColumnHelper<PlanPb>()

export function AdminPlans() {
  const plansQuery = usePlansQuery({
    includeCustom: true,
  })
  const confirm = useConfirm()
  // const removePlanById_ = useRemovePlanById()

  const removePlanById = React.useCallback(
    async id => {
      const confirmed = await confirm({
        color: 'red-500',
        message: 'This Plan will be deleted!',
        subMessage: 'Plans cannot be restored after they have been deleted.',
      })

      if (confirmed) {
        // await removePlanById_.mutateAsync(id)
      }
    },
    [confirm]
  )

  const columns = React.useMemo(
    () => [
      columnHelper.display({
        id: 'actions',
        meta: { tight: true },
        cell: function Cell(props) {
          const navigate = useNavigate()
          const original = props.row.original

          return (
            <Menu
              button={
                <button type="button">
                  <BsThreeDots className="inline text-lg" />
                </button>
              }
            >
              <MenuItem
                onClick={() =>
                  navigate({
                    to: `./${original.planId}`,
                  })
                }
              >
                <FaCog className="inline" /> Edit
              </MenuItem>
              <MenuItem onClick={() => removePlanById(original.planId)}>
                <FaTimes /> Remove
              </MenuItem>
            </Menu>
          )
        },
      }),
      columnHelper.accessor('name', {
        header: 'Plan Name',
        filterFn: 'fuzzy',
        cell: props => (
          <Link to={`./${props.row.original.planId}`}>{props.getValue()}</Link>
        ),
      }),
      columnHelper.accessor('planId', {
        header: 'Plan ID',
        filterFn: 'fuzzy',
        cell: props => (
          <Link to={`./${props.row.original.planId}`}>{props.getValue()}</Link>
        ),
      }),
      columnHelper.accessor('productFamily', {
        header: 'Product Family',
        cell: props => {
          if (props.getValue() === ProductFamilyPb.STANDARD) {
            return 'Standard'
          }

          return 'N/A'
        },
      }),

      // columnHelper.accessor(d => d.updatedAt?.toDate(), {
      //   header: 'Last Updated',
      //   cell: props =>
      //     !props.row.original.teamId
      //       ? null
      //       : moment(props.getValue()).fromNow(),
      // }),
      // columnHelper.accessor(d => d.createdAt?.toDate(), {
      //   header: 'Date Created',
      //   cell: props =>
      //     !props.row.original.teamId ? null : (
      //       <Tooltip tooltip={moment(props.getValue()).format('lll')}>
      //         {moment(props.getValue()).fromNow()}
      //       </Tooltip>
      //     ),
      // }),
    ],
    [removePlanById]
  )

  const tableData = React.useMemo(
    () => plansQuery.data?.plans ?? [],
    [plansQuery.data?.plans]
  )

  const table = useTable({
    data: tableData,
    columns,
    initialState: {
      pagination: {
        pageSize: 20,
      },
      sorting: [{ id: 'name', desc: false }],
    },
  })

  return (
    <Card className="dark:divide-y-800 divide-y divide-gray-100 p-0">
      <div className="flex items-center p-2 text-2xl font-bold">Plans</div>
      <div className="flex items-center justify-between gap-4 p-4">
        <Link to="new">
          <Button size="base" color="green-500">
            <FaPlus className="inline" /> Create New Plan
          </Button>
        </Link>
      </div>
      <QueryGate query={plansQuery}>
        {() => (
          <>
            {!tableData.length ? (
              <div className="p-4 italic opacity-50">
                No plans have been created yet.
              </div>
            ) : (
              <Table table={table} />
            )}
          </>
        )}
      </QueryGate>
    </Card>
  )
}
