import * as React from 'react'
import { FaUndo } from 'react-icons/fa'
import Button from './Button'
import Tooltip from './Tooltip'

export function WidgetStateResetter({
  isDirty,
  onReset,
}: {
  isDirty: boolean
  onReset: () => void
}) {
  const isDev = import.meta.env.DEV

  return isDirty ? (
    <Tooltip
      tooltip={
        isDev ? (
          <pre>{JSON.stringify(isDirty, null, 2)}</pre>
        ) : (
          <>
            Reset this widget to its original state.
            <div className="h-1" />
            <hr className="opacity-50" />
            <div className="h-1" />
            <small className="opacity-70">
              NOTE: This will remove all user changes including metrics,
              pagination, display modes, etc.
            </small>
          </>
        )
      }
      getTooltipProps={
        isDev
          ? () => ({
              className: 'max-w-full',
            })
          : undefined
      }
    >
      <Button size="xs" color="yellow-500" onClick={() => onReset()}>
        <FaUndo /> Reset
      </Button>
    </Tooltip>
  ) : null
}
