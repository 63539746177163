import * as React from 'react'
import {
  TimeRangeQuickPicks,
  convertQuickPickToRange,
} from '../utils/TimeRangeQuickPicks'
import { useSearchState } from '../components/useSearchState'

//

export type TimeRange = {
  start: number
  end: number
}

//

export const makeDefaultTimeRange = (): TimeRange => {
  const range = convertQuickPickToRange(TimeRangeQuickPicks[0][2])

  return {
    start: range[0],
    end: range[1],
  }
}

export default function useTimeRangeState() {
  return useSearchState<[TimeRange, ...TimeRange[]]>({
    path: 'timeRanges',
    useDefaultValue: () => React.useMemo(() => [makeDefaultTimeRange()], []),
    defaultReplace: true,
  })
}
