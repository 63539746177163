import { useQuery } from 'react-query'
import { queryKeyGoogleSites } from './Constants'
import { sortBy } from '.'
import moment from 'moment'

declare global {
  interface Window {
    gapi: {
      load?: any
      auth2?: never
      client?: Record<string, any>
    }
    google: any
    gapiLoadPromise: Promise<any>
    gisLoadPromise: Promise<any>
  }
}

const GAPI_CLIENT_ID =
  '873065328857-b0g0mdsm8bannnr59h2dr1tonn30rs8l.apps.googleusercontent.com'

const GAPI_SCOPE = [
  'https://www.googleapis.com/auth/webmasters.readonly',
  // 'https://www.googleapis.com/auth/spreadsheets',
  'https://www.googleapis.com/auth/drive.file',
].join(' ')

let tokenClient: any

async function init() {
  // First, load and initialize the gapi.client
  await window.gapiLoadPromise

  await new Promise((resolve, reject) => {
    window.gapi.load('client', { callback: resolve, onerror: reject })
  })

  await window.gapi.client?.init({})

  // Discovery docs
  await Promise.all([
    window.gapi.client?.load(
      'https://content.googleapis.com/discovery/v1/apis/searchconsole/v1/rest'
    ),
    window.gapi.client?.load(
      'https://sheets.googleapis.com/$discovery/rest?version=v4'
    ),
  ])

  // Now load the GIS client
  await window.gisLoadPromise
  await new Promise<void>((resolve, reject) => {
    try {
      tokenClient = window.google.accounts.oauth2.initTokenClient({
        client_id: GAPI_CLIENT_ID,
        scope: GAPI_SCOPE,
        prompt: 'consent',
        callback: '', // defined at request time in await/promise scope.
      })
      resolve()
    } catch (err) {
      reject(err)
    }
  })
}

// Wrap gapi.client calls in this function to handle authorization errors.
async function gapiTry<T>(cb: () => Promise<T>): Promise<T> {
  await init()
  return cb().catch((err: any) => getToken(err).then(cb)) // for authorization errors obtain an access token
}

export async function getGapiToken() {
  return window.gapi.client?.getToken() as {
    access_token: string
    expires_in: number
    scope: string
    token_type: string
  }
}

export async function revokeGapiToken() {
  await init()
  const cred: any = window.gapi.client?.getToken()
  if (cred !== null) {
    window.google.accounts.oauth2.revoke(cred.access_token)
    window.gapi.client?.setToken('')
  }
}

// Obtain an access token and retry the failed request.
async function getToken(err?: any) {
  await init()

  if (
    err?.result?.error?.code === 401 ||
    (err?.result?.error?.code === 403 &&
      err?.result?.error?.status === 'PERMISSION_DENIED')
  ) {
    // The access token is missing, invalid, or expired, prompt for user consent to obtain one.
    await new Promise((resolve, reject) => {
      try {
        // Settle this promise in the response callback for requestAccessToken()
        tokenClient.callback = (resp: any) => {
          if (resp?.error !== undefined) {
            return reject(resp)
          }
          resolve(resp)
        }
        tokenClient.requestAccessToken()
      } catch (err) {
        console.error(err)
      }
    })
  } else {
    // Errors unrelated to authorization: server errors, exceeding quota, bad requests, and so on.
    throw new Error(err)
  }
}

export async function createGoogleSpreadsheet({ title }: { title: string }) {
  const res = await gapiTry(async () => {
    const { result } = await window.gapi.client?.sheets.spreadsheets.create({
      properties: {
        title,
      },
    })

    return result as {
      spreadsheetId: string
    }
  })

  return res
}

export async function fetchSearchConsoleSites() {
  const res = await gapiTry(() =>
    window.gapi.client?.webmasters.sites
      .list()
      .then((d: any) => d.result.siteEntry ?? [])
  )

  return sortBy(
    res as { siteUrl: string; permissionLevel: string }[],
    d => d.siteUrl
  )
}

export type GoogleSearchConsoleKeyword = {
  keys: string[]
}

export async function fetchSearchConsoleKeywords({ url }: { url: string }) {
  return gapiTry(async () => {
    let allRows: any = []

    const pageSize = 25000
    const rowLimit = 500000

    const tryPage = async (page: number) => {
      const { result } =
        await window.gapi.client?.webmasters.searchanalytics.query({
          siteUrl: url,
          resource: {
            startDate: moment()
              .startOf('day')
              .subtract(90, 'days')
              .format('YYYY-MM-DD'),
            endDate: moment().startOf('day').format('YYYY-MM-DD'),
            rowLimit: pageSize,
            dataState: 'all',
            startRow: pageSize * page,
            dimensions: ['QUERY'],
            // dimensionFilterGroups,
          },
        })

      allRows = allRows.concat(result.rows)

      if (result?.rows?.length === pageSize && allRows.length < rowLimit) {
        await tryPage(page + 1)
      }
    }

    await tryPage(0)

    return (allRows[0] ? allRows : []) as GoogleSearchConsoleKeyword[]
  })
}

export function useSearchConsoleSites(opts?: {
  workspaceId?: string
  projectId?: number
}) {
  return useQuery({
    queryKey: [
      queryKeyGoogleSites,
      {
        workspaceId: opts?.workspaceId,
        projectId: opts?.projectId,
      },
    ],
    queryFn: () => fetchSearchConsoleSites(),
  })
}
