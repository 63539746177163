// @generated by protoc-gen-connect-web v0.11.0 with parameter "target=ts"
// @generated from file nozzle/workspaces/v1/keyword.proto (package nozzle.workspaces.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { ListKeywordsFacetsRequest, ListKeywordsFacetsResponse, ListKeywordsRequest, ListKeywordsResponse } from "./keyword_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * KeywordsService is a service for managing keyword sources.
 *
 * @generated from service nozzle.workspaces.v1.KeywordsService
 */
export const KeywordsService = {
  typeName: "nozzle.workspaces.v1.KeywordsService",
  methods: {
    /**
     * ListKeywords will list keywords.
     *
     * @generated from rpc nozzle.workspaces.v1.KeywordsService.ListKeywords
     */
    listKeywords: {
      name: "ListKeywords",
      I: ListKeywordsRequest,
      O: ListKeywordsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ListKeywordsFacets will return facets for the list of urls.
     *
     * @generated from rpc nozzle.workspaces.v1.KeywordsService.ListKeywordsFacets
     */
    listKeywordsFacets: {
      name: "ListKeywordsFacets",
      I: ListKeywordsFacetsRequest,
      O: ListKeywordsFacetsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

