import * as React from 'react'
import { FaQuestionCircle } from 'react-icons/fa'
import Caption from './Caption'
import Tooltip from './Tooltip'

export function KeywordGroupHelp() {
  return (
    <Tooltip
      tooltip={
        <>
          Keyword groups allow you to organize individual phrases into groups
          that are both visualized and segmented on dashboards. Keyword groups
          also make it easier to rename and remove keywords in bulk.
        </>
      }
    >
      <Caption>
        <FaQuestionCircle className="inline" /> What are keyword groups?
      </Caption>
    </Tooltip>
  )
}
