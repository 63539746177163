import React from 'react'
import { FaCaretDown, FaCaretUp } from 'react-icons/fa'
import { twMerge } from 'tailwind-merge'

export function NumberInputWrap({
  children,
  className,
  label,
  ...rest
}: React.HTMLProps<HTMLDivElement>) {
  return (
    <div {...rest} className={twMerge('inline-flex items-center', className)}>
      {label ? (
        <div
          className={twMerge(
            'flex-none px-2 font-bold',
            rest.disabled && 'opacity-30'
          )}
        >
          {label}
        </div>
      ) : null}
      <div className="inline-flex flex-1 items-center">
        {children}
        <div className="pointer-events-none flex w-0 -translate-x-3 flex-col items-center justify-center opacity-50">
          <FaCaretUp className="translate-y-[20%]" />
          <FaCaretDown className="-translate-y-[20%]" />
        </div>
      </div>
    </div>
  )
}
