import * as React from 'react'
import { useForm } from 'react-form'
import { FaCheck, FaTrashAlt } from 'react-icons/fa'
import Button from '../../components/Button'
import Spinner from '../../components/Spinner'
import TextField from '../../components/TextField'
import usePauseEvents from '../../hooks/usePauseEvents'
import { User } from '../../utils/Api'
import Validate from '../../utils/Validate'

export default function UserForm({
  user,
  onSubmit,
  onRemove,
}: {
  user?: User
  onSubmit: (newUser: User) => void
  onRemove?: () => void
}) {
  usePauseEvents('user', user ? user.id : null, !!user)

  const {
    Form,
    meta: { isSubmitting },
  } = useForm({
    // @ts-expect-error  // Type 'User | undefined' is not assignable to type ... Remove this comment to see the full error message
    defaultValues: user,
    // @ts-expect-error  // Type '(newUser: User) => void' is not assignable t... Remove this comment to see the full error message
    onSubmit,
  })

  return (
    <Form>
      <div className="flex flex-wrap gap-2">
        <TextField
          field="givenName"
          validate={Validate.required('A name is required')}
          label="First Name"
          placeholder="John"
          autoComplete="given-name"
          className="w-80 flex-none"
        />
        <TextField
          field="familyName"
          validate={Validate.required('A name is required')}
          label="Last Name"
          placeholder="Smith"
          autoComplete="family-name"
          className="w-80 flex-none"
        />
      </div>
      <div className="h-2" />
      <TextField
        field="displayName"
        validate={Validate.required('A display name is required')}
        label="Display Name"
        placeholder="John Smith"
        autoComplete="name"
        className="w-80 flex-none"
      />
      <div className="h-2" />
      <TextField
        field="id"
        label="User ID"
        placeholder="-"
        disabled
        className="w-80"
      />
      <div className="h-2" />
      <TextField
        field="email"
        validate={Validate.email()}
        label="Email"
        placeholder="johnsmith@gmail.com"
        disabled={!!user}
        autoComplete="email"
        className="w-80"
      />
      <div className="h-4" />
      <Button type="submit" size="base" color="green-500">
        {isSubmitting ? (
          <>
            <Spinner color="white" /> Saving User
          </>
        ) : (
          <>
            <FaCheck className="inline" /> Save User
          </>
        )}
      </Button>{' '}
      {onRemove ? (
        <Button
          type="button"
          size="base"
          color="gray-500"
          hoverColor="red-500"
          onClick={onRemove}
        >
          <FaTrashAlt className="inline" /> Remove User
        </Button>
      ) : null}
    </Form>
  )
}
