import * as React from 'react'
import { twMerge } from 'tailwind-merge'

export function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & React.HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!)

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate, rest.checked])

  return (
    <input
      type="checkbox"
      ref={ref}
      className={twMerge(
        `h-3.5 w-3.5 cursor-pointer`,
        className
        // `rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600`
        // appearance-none rounded border border-gray-500 bg-gray-500/10 checked:border-transparent checked:bg-blue-400 indeterminate:border-2 indeterminate:border-blue-400 indeterminate:bg-transparent`
      )}
      {...rest}
    />
  )
}
