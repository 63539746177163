import { Outlet } from '@tanstack/react-router'
import moment from 'moment'
import * as React from 'react'
import { BiCalculator, BiCreditCard, BiDetail } from 'react-icons/bi'
import { BsStars } from 'react-icons/bs'
import { FaChartBar, FaCog, FaFileInvoice, FaInfoCircle } from 'react-icons/fa'
import { RiCoupon3Line } from 'react-icons/ri'
import Anchor from '../../../components/Anchor'
import { formatCardType, useAccount } from '../../../hooks/account'
import { openHubSpotChat } from '../../../utils/Analytics'
import { formatCurrency, formatNumber } from '../../../utils/Format'
import { moneyToFloat } from '../../../utils/money'
import { datePbToDate } from '../../../utils/protoDate'
import Button from '../../../components/Button'
import Card from '../../../components/Card'
import Clickable from '../../../components/Clickable'
import Container from '../../../components/Container'
import ErrorComp from '../../../components/Error'
import Link from '../../../components/Link'
import Loader from '../../../components/Loader'
import { MaxOverageModal } from '../../../components/MaxOverageModal'
import StripeCard from '../../../components/StripeCard'
import { ButtonBar, tabStyles } from '../../../components/ButtonBar'
import Usage from '../../../components/Usage'
import useModal from '../../../hooks/useModal'
import {
  AccountViewPb,
  PeriodPb,
  SubscriptionStatePb,
} from '../../../utils/proto'
import { useProfileQuery } from '../../../hooks/profile'

export function BillingRoot() {
  const showModal = useModal()
  const accountQuery = useAccount({
    view: AccountViewPb.FULL,
  })
  const profileQuery = useProfileQuery()

  if (accountQuery.isLoading) {
    return (
      <Container>
        <Loader />
      </Container>
    )
  }

  if (accountQuery.isError) {
    return (
      <Container className="space-y-2">
        <Card className="flex items-center gap-2 text-lg font-bold">
          <BiCreditCard /> Billing
        </Card>
        <Card>
          Nozzle billing is currently undergoing brief maintenance and will be
          back shortly. If you have any time-sensitive questions, please{' '}
          <Clickable onClick={() => openHubSpotChat()}>
            start a new chat with us
          </Clickable>
          .
        </Card>
        {profileQuery.data?.isAdmin ? (
          <Card className="divide-y divide-gray-500/20 p-0">
            <div className="p-2">This error is only visible to admins.</div>
            <div className="p-2">
              <ErrorComp error={accountQuery.error} />
            </div>
          </Card>
        ) : null}
      </Container>
    )
  }

  const account = accountQuery.data

  const subscription = accountQuery.data?.subscriptions[0]

  if (!subscription) {
    throw new Error('No subscription found')
  }

  const isTrial = subscription?.state === SubscriptionStatePb.TRIAL

  const trialEnd = moment
    .utc(datePbToDate(subscription?.trialEnd))
    .startOf('day')
  const planStarts = trialEnd.add(1, 'day')
  const planStartsFormatted = planStarts.format('ll')

  return subscription.state !== SubscriptionStatePb.UNSPECIFIED ? (
    <Container>
      <Card className="p-4">
        <div className="flex items-center gap-2">
          <div>
            <div className="flex items-center gap-2 text-2xl font-bold">
              <BiCreditCard /> Billing
            </div>
          </div>
          <div className="ml-auto">
            <Link
              to="../subscription"
              search={old => ({
                ...old,
                activeStep: 0,
              })}
            >
              {isTrial ? (
                <Button size="xl" color="green-500" className="font-bold">
                  <BsStars /> Upgrade Trial
                </Button>
              ) : (
                <Button size="base" color="blue-500">
                  <FaCog /> Manage Subscription
                </Button>
              )}
            </Link>
          </div>
        </div>
      </Card>
      <div className="h-4" />
      {/* 
      {subscription.state === SubscriptionStatePb.CANCELED ||
      subscription.canceledAt ? (
        <>
          <Card className="flex items-center gap-2 !bg-red-500 font-bold text-white">
            <FaInfoCircle />{' '}
            <span>
              Your account was canceled on{' '}
              {moment(subscription?.canceledAt?.toDate())
                .startOf('day')
                .format('MMMM Do, YYYY')}
              .
            </span>
          </Card>
          <div className="h-4" />
        </>
      ) : subscription.cancelRequestedAt ? (
        <>
          <Card className="flex items-center gap-2 !bg-red-500 font-bold">
            <FaInfoCircle />{' '}
            <span>
              Your account is scheduled to be canceled on{' '}
              {moment(subscription.cancelRequestedAt?.toDate())
                .startOf('day')
                .format('MMMM Do, YYYY')}
              .
            </span>
          </Card>
          <div className="h-4" />
        </>
      ) : isTrial ? (
        account?.paymentMethod?.paymentMethodId ? (
          <>
            <Card className="flex items-center gap-2 border-2 border-blue-500 bg-white font-bold">
              <FaInfoCircle />{' '}
              <span>
                Your free trial will automatically convert{' '}
                {subscription.trialEnd
                  ? `to the ${subscription?.name} ${
                      subscription?.basePeriod === PeriodPb.MONTHLY
                        ? '(Monthly)'
                        : '(Yearly)'
                    } plan`
                  : ''}{' '}
                on {planStartsFormatted}
              </span>
            </Card>
            <div className="h-4" />
          </>
        ) : (
          <>
            <Card className="flex items-center gap-2 border-2 border-blue-500 bg-white font-bold">
              <FaInfoCircle />{' '}
              <span>
                Your free trial will end on {planStartsFormatted}. Upgrade your
                trial to keep data flowing!
              </span>
            </Card>
            <div className="h-4" />
          </>
        )
      ) : null} */}
      <div className="flex flex-wrap gap-2 [&>*]:w-full md:[&>*]:flex-1">
        <Card className="divide-y divide-gray-500/20 p-0">
          <div className="px-2 py-1 font-bold">Current Plan </div>
          <div className="flex items-center gap-2 p-2 text-xl font-bold">
            {accountQuery.isLoading ? (
              <Loader />
            ) : (
              subscription?.name || subscription?.planId
            )}{' '}
            <div className="rounded-lg bg-gray-300 px-2 py-1 text-sm font-black uppercase text-white dark:bg-gray-800">
              {subscription?.basePeriod === PeriodPb.MONTHLY
                ? 'Monthly'
                : 'Yearly'}
            </div>
            {isTrial ? (
              <div className="flex items-center gap-1 rounded-lg bg-green-500 px-2 py-1 text-sm font-black uppercase text-white">
                <BsStars /> Trial
              </div>
            ) : null}
          </div>
          <div className="p-2 text-sm">
            Included SERPs:{' '}
            {formatNumber(
              Number(
                subscription?.meteredSubscriptions[0]?.includedQuantity ?? 0
              )
            )}
          </div>
        </Card>
        {subscription ? (
          <>
            <Card className="divide-y divide-gray-500/20 p-0">
              <div className="px-2 py-1 font-bold">Plan Price</div>
              <div className="p-2 text-xl font-bold">
                {formatCurrency(moneyToFloat(subscription.basePrice), {
                  forcePrecision: true,
                  precision: 2,
                })}
              </div>
              <div className="p-2 text-sm">
                {subscription.basePeriod === PeriodPb.MONTHLY
                  ? 'Billed monthly'
                  : subscription.basePeriod === PeriodPb.YEARLY
                  ? 'Billed yearly'
                  : ''}
              </div>
            </Card>
            <Card className="divide-y divide-gray-500/20 p-0">
              <div className="flex items-center gap-2 px-2 py-1 font-bold">
                <div>Overages</div>
                <Button
                  size="sm"
                  color={['gray-100', 'gray-800']}
                  className="px-2 py-1"
                  onClick={() => showModal(() => <MaxOverageModal />)}
                >
                  Edit
                </Button>
              </div>
              {!moneyToFloat(account?.maxOverageAmount) ? (
                <div className="p-3 text-sm font-bold italic">
                  Overages are disabled
                </div>
              ) : (
                <div className="p-2 text-xl font-bold">
                  <span className="">
                    $
                    {formatNumber(moneyToFloat(account?.currentOverageAmount), {
                      precision: 2,
                      forcePrecision: true,
                    })}
                  </span>{' '}
                  /{' '}
                  <span className="opacity-60">
                    $
                    {formatNumber(moneyToFloat(account?.maxOverageAmount), {
                      precision: 2,
                      forcePrecision: true,
                    })}
                  </span>
                </div>
              )}
              <div className="p-2 text-sm opacity-70">
                $
                {formatNumber(
                  moneyToFloat(
                    subscription?.meteredSubscriptions[0]?.unitPrice
                  ) * 1000,
                  {
                    precision: 2,
                  }
                )}{' '}
                / 1000 SERPs
              </div>
            </Card>
          </>
        ) : null}
        {/* {subscription.state === SubscriptionStatePb.CANCELED ? (
          <Card className="divide-y divide-gray-500/20 p-0">
            <div className="px-2 py-1 font-bold text-red-500">Canceled On </div>
            <div className="p-2 text-xl font-bold">
              {moment(subscription?.canceledAt?.toDate())
                .startOf('day')
                .format('MMMM Do, YYYY')}
            </div>
            <div className="p-2">
              <Link to="./invoices">View Invoices</Link>
            </div>
          </Card>
        ) : account?.nextBillingAt ? (
          <Card className="divide-y divide-gray-500/20 p-0">
            <div className="px-2 py-1 font-bold">Renews On </div>
            <div className="whitespace-nowrap p-2 text-lg font-bold">
              {moment(datePbToDate(account?.nextBillingAt))
                .startOf('day')
                .format('MMMM Do, YYYY')}
            </div>
            <div className="p-2">
              <Link to="./invoices">View Invoices</Link>
            </div>
          </Card>
        ) : null} */}
        <Card className="divide-y divide-gray-500/20 p-0">
          <div className="px-2 py-1 font-bold">Payment Method</div>
          <div className="p-2">
            {account?.paymentMethod?.details.case === 'card' ? (
              <div>
                <div className="flex items-center gap-2 whitespace-nowrap">
                  <BiCreditCard className="text-blue-500" />
                  <span className="">
                    {formatCardType(
                      account?.paymentMethod.details.value.type
                    ).toUpperCase()}
                  </span>
                  <span className="text-sm">**** **** ****</span>
                  <span className="font-bold">
                    {account?.paymentMethod.details.value.last4}
                  </span>
                </div>
              </div>
            ) : (
              <div className="italic opacity-50">No card added yet.</div>
            )}
          </div>
          <div className="p-2">
            <StripeCard
              button={
                <Anchor className="cursor-pointer font-bold text-blue-500">
                  Update Credit Card
                </Anchor>
              }
            />
          </div>
        </Card>
      </div>
      <div className="h-4" />
      <ButtonBar>
        <Link className={tabStyles} activeOptions={{ exact: true }} to=".">
          <FaChartBar className="icon inline" /> Overview
        </Link>
        <Link className={tabStyles} to="invoices">
          <FaFileInvoice className="icon inline " /> Invoices
        </Link>
        {/* <Link className={tabStyles} to="payments">
          <MdPayments className="inline icon " /> Payments
        </Link> */}
        <Link className={tabStyles} to="details">
          <BiDetail className="icon inline " /> Billing Details
        </Link>
        {subscription?.coupons.length ? (
          <Link className={tabStyles} to="coupons">
            <RiCoupon3Line className="icon inline text-lg " /> Coupons
          </Link>
        ) : null}
        <Link className={tabStyles} to="usage-calculator">
          <BiCalculator className="icon inline " /> Usage Calculator
        </Link>
      </ButtonBar>
      <div className="h-4" />
      <Outlet />
      <div className="h-8" />
    </Container>
  ) : (
    <Container>
      <div className="h-4" />
      <Card className="p-4">
        <div className="flex items-center gap-2 text-2xl font-bold">
          <FaCog /> Billing Settings
        </div>
        <div className="flex flex-col items-center text-center">
          <div className="p-8 text-2xl">
            Self-service billing is coming soon!
          </div>
          <div className="flex flex-col items-center text-lg">
            {/* <div>
              Your current plan: <strong>{subscription.planName}</strong>
            </div> */}
            <div>
              Questions?{' '}
              <Clickable onClick={() => openHubSpotChat()}>
                Contact Us.
              </Clickable>
            </div>
          </div>
        </div>
      </Card>
      <div className="h-4" />
      <Usage hideCost={true} />
    </Container>
  )
}
