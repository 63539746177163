import { twMerge } from 'tailwind-merge'
import { FaCog } from 'react-icons/fa'
import Tooltip from './Tooltip'
import { ButtonPlain } from './ButtonPlain'

type Props = {
  showControls: boolean
  onToggle: () => void
}

export default function WidgetControlsToggle({
  showControls,
  onToggle,
}: Props) {
  return (
    <Tooltip
      tooltip={showControls ? 'Hide Widget Controls' : 'Show Widget Controls'}
    >
      <ButtonPlain
        className={twMerge(
          'bg-gray-500/10',
          showControls && 'bg-blue-600',
          'hover:bg-blue-500 hover:text-white'
        )}
        onClick={() => onToggle()}
      >
        <FaCog className="inline" />
      </ButtonPlain>
    </Tooltip>
  )
}
