import { Route, useNavigate } from '@tanstack/react-router'
import { useVirtualizer } from '@tanstack/react-virtual'
import { twMerge } from 'tailwind-merge'
import { matchSorter } from 'match-sorter'
import moment from 'moment'
import * as React from 'react'
import {
  FaCaretDown,
  FaCheck,
  FaCircle,
  FaInfoCircle,
  FaPlus,
  FaSearch,
  FaTachometerAlt,
} from 'react-icons/fa'
import { FiTarget } from 'react-icons/fi'
import { UseQueryResult } from 'react-query'

import BoxPlaceholder from '../../components/BoxPlaceholder'
import Card from '../../components/Card'
import Container from '../../components/Container'
import SegmentPicker from '../../components/SegmentPicker'
import DashboardTimeRange from '../../components/DashboardTimeRange'
import ErrorComp from '../../components/Error'
import Link from '../../components/Link'
import Loader from '../../components/Loader'
import NoData from '../../components/NoData'
import QueryGate from '../../components/QueryGate'
import TimeSeriesChart from '../../components/TimeSeriesChart'
import WidgetControls from '../../components/WidgetControls'
import { useBrands } from '../../hooks/brands'
import { useKeywordSources } from '../../hooks/keywordSources'
import { useProfileQuery } from '../../hooks/profile'
import { useProjects } from '../../hooks/projects'
import useGroupByOverTimeQuery from '../../hooks/useGroupByOverTimeQuery'
import { useGroupByOverTimeQueryOptions } from '../../hooks/useGroupByOverTimeQueryOptions'
import useLocalState from '../../hooks/useLocalState'
import useTimeRangeState from '../../hooks/useTimeRanges'
import { useUsage } from '../../hooks/useUsage'
import useZustand from '../../hooks/useZustand'
import { rankGroupOptions } from '../../options/rankGroupOptions'
import { getDataValue, last } from '../../utils'
import { GroupByQueryResponse, Project } from '../../utils/Api'
import {
  BRAND_TYPE_OWNED,
  DEFAULT_SAMPLES,
  competitiveShareOfVoiceSlug,
  performanceByKeywordGroupSlug,
  performanceByKeywordSlug,
  performanceOverviewSlug,
} from '../../utils/Constants'
import { getDataColor, getRankGroupColor } from '../../utils/DataColors'
import {
  formatKeywordV1,
  formatNumber,
  renderLimitedText,
} from '../../utils/Format'
import {
  MetricId,
  getMetricChangeFormatter,
  getMetricFormatter,
  getMetricRenderer,
  metricPermutation,
  metricsById,
  safeMetricId,
} from '../../utils/Metrics'
import { allQueryFacets } from '../../utils/queryFacets'
import { GetUsageResponsePb, UsageViewPb } from '../../utils/proto'
import { ButtonPlain } from '../../components/ButtonPlain'
import Select from '../../components/Select'
import InlineLabeledInput from '../../components/InlineLabeledInput'
import {
  useMetricIdState,
  useSegmentIdState,
  useSyncMetricIdState,
} from '../../utils/searchParams'
import { useKeywordGroupState } from '../../utils/searchParams'
import { useSearchState } from '../../components/useSearchState'

//

type ProjectWithPulls = Project & { pullCount?: number }

//

const keywordGroupLimit = 35
const brandLabelLimit = 20
const queryStaleTime = 1000 * 60 * 5 // 5 Minutes

export const metricIds = [
  'unique_keywords',
  'top_rank',
  'estimated_traffic',
  'top_pixels_from_top',
  'percentage_of_viewport',
] satisfies MetricId[]

export type ProjectsOverviewWidget = {
  focusMetricId: (typeof metricIds)[number]
  samples: number
}

const useProjectsOverviewWidgetState = () => {
  const metricIdState = useMetricIdState(metricIds)

  const searchState = useSearchState<ProjectsOverviewWidget>({
    path: 'projectsOverviewWidget',
    useDeps: () => [],
    useDefaultValue: () => {
      return {
        samples: DEFAULT_SAMPLES,
        focusMetricId: metricIdState.state!,
      }
    },
  })

  useSyncMetricIdState(metricIds, searchState.state.metricId)

  return searchState
}

export function Projects() {
  const profileQuery = useProfileQuery()
  const preProjectsQuery = useProjects()
  const start = React.useMemo(() => moment().subtract(30, 'days').unix(), [])
  const end = React.useMemo(() => moment().unix(), [])
  const usageQuery = useUsage({
    view: UsageViewPb.BASIC,
    timeRange: {
      start,
      end,
    },
  })

  const pullUsageQuery = useUsage({
    view: UsageViewPb.BASIC,
    timeRange: {
      start: React.useMemo(() => moment().subtract(1, 'year').unix(), []),
      end: React.useMemo(() => moment().unix(), []),
    },
  })

  const projectsQuery: UseQueryResult<ProjectWithPulls[]> =
    React.useMemo(() => {
      if (usageQuery.data && preProjectsQuery.data) {
        return {
          ...preProjectsQuery,
          data: (preProjectsQuery.data ?? []).map(project => {
            let projectPullCount = 0

            const chosenUsageTeam =
              usageQuery.data.usageByWorkspace?.usageByProject.filter(
                usageTeam => String(usageTeam.projectId) === project.id
              )[0]

            if (chosenUsageTeam) {
              projectPullCount = Number(
                chosenUsageTeam.usageTotals[0]?.quantity ?? 0
              )
            }

            return {
              ...project,
              pullCount: projectPullCount,
            }
          }),
        } as typeof preProjectsQuery
      }

      return preProjectsQuery
    }, [preProjectsQuery, usageQuery.data])

  const [, setStore] = useZustand(state => state.helpUrl)
  const [showOnboarding, setShowOnboarding] = useLocalState<boolean | null>(
    'showOnboarding',
    null
  )

  const openDashboardDocs = () => {
    setStore(old => {
      old.showHelp = true
      old.maximizeHelp = true
      old.helpUrl = 'https://help.nozzle.io/nozzle-dashboards'
    })
  }

  const openKeywordDocs = () => {
    setStore(old => {
      old.showHelp = true
      old.maximizeHelp = true
      old.helpUrl = 'https://help.nozzle.io/getting-started-with-your-workspace'
    })
  }

  const hideOnboarding = () => {
    setStore(old => {
      old.showHelp = false
      old.maximizeHelp = false
      old.helpUrl = 'https://help.nozzle.io'
    })
    setShowOnboarding(false)
  }

  const [searchValue, setSearchValue] = React.useState('')

  const filterdProjects = React.useMemo(() => {
    if (!projectsQuery.data) {
      return []
    }

    if (!searchValue) {
      return projectsQuery.data
    }

    return matchSorter(projectsQuery.data, searchValue, {
      keys: [d => d.name],
    })
  }, [searchValue, projectsQuery.data])

  const virtualParentRef = React.useRef<HTMLDivElement>(null!)

  const virtualizer = useVirtualizer({
    getScrollElement: () => virtualParentRef.current!,
    count: filterdProjects.length,
    estimateSize: React.useCallback(() => 260, []),
    overscan: 0,
  })

  const widgetState = useProjectsOverviewWidgetState()

  return (
    <Container className="flex max-w-none flex-1 flex-col gap-2 overflow-x-auto overflow-y-visible p-2">
      {showOnboarding ? (
        <>
          <Card className="p-4">
            <div className="flex flex-wrap items-center justify-between gap-4">
              <div className="flex flex-wrap items-center gap-4">
                <div className="flex items-center gap-2 text-2xl">
                  <div>
                    <span
                      aria-label="hand-waving"
                      role="img"
                      className="mx-2 text-4xl"
                    >
                      🎉
                    </span>
                  </div>
                  <div>
                    Welcome to Nozzle,{' '}
                    <span className="font-bold">
                      {profileQuery.data?.displayName}
                    </span>
                    !
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap gap-2">
                <ButtonPlain
                  className="bg-blue-600"
                  onClick={openDashboardDocs}
                >
                  <FaTachometerAlt /> Learn About Dashboards
                </ButtonPlain>
                <ButtonPlain className="bg-blue-600" onClick={openKeywordDocs}>
                  <FiTarget /> Keywords & Brands Guide
                </ButtonPlain>
                <ButtonPlain className="bg-gray-600" onClick={hideOnboarding}>
                  <FaCheck /> Hide
                </ButtonPlain>
              </div>
            </div>
          </Card>
        </>
      ) : null}
      <Card className="sticky left-0 divide-y divide-gray-500/20 p-0">
        <div className="flex flex-wrap items-center gap-2 p-2">
          <div className="text-xl font-bold">Projects</div>
          <Link to="/projects/new">
            <ButtonPlain className="bg-blue-500">
              <FaPlus className="inline" /> Add Project
            </ButtonPlain>
          </Link>
          <InlineLabeledInput
            label={<FaSearch />}
            className="min-w-52 text-sm"
            placeholder="Search Projects... "
            value={searchValue}
            // @ts-expect-error  // Property 'value' does not exist on type 'EventTarg... Remove this comment to see the full error message
            onChange={e => setSearchValue(e.target.value)}
          />
          {/* <Link to="/projects/import">
            <ButtonPlain className="bg-gray-500">
              <BiImport className="inline" /> Import Project
            </ButtonPlain>
          </Link> */}
          <SegmentPicker className="flex-1 text-sm" />
          <WidgetControls
            {...{
              metricIds,
              focusMetricId: widgetState.state.focusMetricId,
              allowSamples: true,
              samples: widgetState.state.samples,
              setState: widgetState.setState,
              allowMetrics: true,
              allowPostAggregation: false,
              isLoading: false,
              allowExport: false,
              allowLegends: false,
              allowSorting: false,
            }}
          />
          <DashboardTimeRange className="ml-auto" />
        </div>
      </Card>
      <QueryGate query={projectsQuery}>
        {() => (
          <div
            ref={virtualParentRef}
            className="w-[calc(100% + 1rem)] flex-1 overflow-y-auto"
          >
            <div
              className="relative pr-2"
              style={{
                height: virtualizer.getTotalSize(),
              }}
            >
              <>
                {virtualizer.getVirtualItems().map(item => {
                  const project = filterdProjects[item.index]!
                  return (
                    <div
                      data-index={item.index}
                      ref={virtualizer.measureElement}
                      key={project.id}
                      className="y-0 absolute min-w-[calc(100%_+_-1rem)] transform pb-2"
                      style={{
                        transform: `translateY(${item.start}px)`,
                      }}
                    >
                      <TeamCard
                        project={project}
                        widgetState={widgetState.state}
                        setWidgetState={widgetState.setState}
                        pullUsageQuery={pullUsageQuery}
                        // ref={item.measureRef}
                      />
                    </div>
                  )
                })}
              </>
            </div>
          </div>
        )}
      </QueryGate>
    </Container>
  )
}

export function useManageProjectOptions() {
  const navigate = useNavigate()

  return React.useMemo(
    () => [
      {
        label: 'Project Info',
        value: (projectId: string) => {
          navigate({
            to: `/projects/${projectId}`,
          })
        },
      },
      {
        label: 'Manage Keywords',
        value: (projectId: string) => {
          navigate({
            to: '/keyword-manager',
            search: {
              projectId,
            },
          })
        },
      },
      {
        label: 'Manage Brands',
        value: (projectId: string) => {
          navigate({
            to: '/brands',
            search: {
              projectId,
            },
          })
        },
      },
      {
        label: 'Manage Segments',
        value: (projectId: string) => {
          navigate({
            to: '/segments',
            search: {
              projectId,
            },
          })
        },
      },
    ],
    [navigate]
  )
}

const TeamCard = React.forwardRef(function TeamCard(
  {
    project,
    widgetState,
    setWidgetState,
    pullUsageQuery,
    className,
    ...rest
  }: {
    project: ProjectWithPulls
    widgetState: any
    setWidgetState: any
    pullUsageQuery: UseQueryResult<GetUsageResponsePb>
  } & React.ComponentProps<typeof Card>,
  ref
) {
  const keywordSourcesQuery = useKeywordSources({
    projectId: project.id,
    staleTime: queryStaleTime,
  })
  const brandsQuery = useBrands({
    projectId: project.id,
    staleTime: queryStaleTime,
  })

  const hasKeywords =
    keywordSourcesQuery.data?.length &&
    keywordSourcesQuery.data.some(
      keywordSource => keywordSource.keywordCount > 0
    ) &&
    pullUsageQuery.data?.usageByWorkspace?.usageByProject.some(
      project => Number(project.usageTotals[0]?.quantity ?? 0) > 0
    )

  // const rankMetrics = React.useMemo(
  //   () =>
  //     [
  //       'top_rank',
  //       'top_pixels_from_top',
  //       'percentage_of_viewport',
  //       'estimated_traffic',
  //       'unique_urls',
  //       'results',
  //     ].map((metricId, i) => ({
  //       ...metrics.find(d => d.id === metricId),
  //       color: getDataColor(i),
  //     })),
  //   []
  // )

  const manageOptions = useManageProjectOptions()
  // const navigate = useNavigate()
  // const dashboardOptions = React.useMemo(
  //   () =>
  //     .dashboards.map(dashboard => ({
  //       label: dashboard.name,
  //       value: (projectId: string) => {
  //         navigate({
  //           to: dashboard.route?.path,
  //           search: prev => ({
  //             ...prev,
  //             ...dashboard.route?.search?.(prev as any),
  //             projectId,
  //           }),
  //         })
  //       },
  //     })),
  //   [navigate]
  // )

  return (
    <Card
      className={twMerge(
        'flex w-[max-content] min-w-full divide-x divide-gray-500/20 p-0',
        className
      )}
      {...rest}
      ref={ref}
    >
      <div className="z-20 flex min-w-56 max-w-56 flex-col divide-y divide-gray-500/20 rounded-l-lg border-r border-gray-500/20 bg-white shadow-lg dark:bg-gray-900 md:sticky md:left-0">
        <div className="space-y-2 p-2">
          <div>
            <div className="flex justify-between text-xl font-bold">
              <Link
                to={`/dashboards/${performanceOverviewSlug}`}
                search={{
                  projectId: project.id,
                }}
              >
                {project.name}
              </Link>
            </div>
            <div className="text-sm italic opacity-30">{project.slug}</div>
          </div>
          <div className="space-y-2">
            {hasKeywords && brandsQuery.data?.length ? (
              <div>
                {/* <Select
                  options={dashboardOptions}
                  onChange={value => value(project.id)}
                  children={({ onClick }) => (
                    <ButtonPlain
                      className="bg-gray-500/20 text-xs text-black hover:bg-blue-500 hover:text-white dark:text-white"
                      onClick={onClick}
                    >
                      View Dashboard <FaCaretDown />
                    </ButtonPlain>
                  )}
                /> */}
                <Link
                  to="/dashboards/performance-overview"
                  search={prev => ({
                    projectId: project.id,
                  })}
                >
                  <ButtonPlain className="bg-gray-500/20 text-xs text-black hover:bg-blue-500 hover:text-white dark:text-white">
                    Overview Dashboard
                  </ButtonPlain>
                </Link>
              </div>
            ) : null}
          </div>
          <div>
            <Select
              options={manageOptions}
              onChange={value => value(project.id)}
              children={({ onClick }) => (
                <ButtonPlain
                  className="bg-gray-500/20 text-xs text-black hover:bg-blue-500 hover:text-white dark:text-white"
                  onClick={onClick}
                >
                  Manage <FaCaretDown />
                </ButtonPlain>
              )}
            />
          </div>
        </div>

        <div className="mt-auto divide-y divide-gray-500/20 border-gray-100 text-sm">
          <div className="p-1 font-bold">Pulls</div>
          <div className="p-1 ">
            <div>
              Current Billing Period:{' '}
              <strong>{formatNumber(project.currentPulls)}</strong>
            </div>
            <div>
              {' '}
              {/* @ts-expect-error  // Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message */}
              Last 30 Days: <strong>{formatNumber(project.pullCount)}</strong>{' '}
            </div>
          </div>
        </div>
      </div>
      {brandsQuery.isLoading || keywordSourcesQuery.isLoading ? (
        <div className="flex items-center justify-center p-6">
          <Loader />
        </div>
      ) : brandsQuery.isError || keywordSourcesQuery.isError ? (
        <div className="flex items-center justify-center p-6">
          <ErrorComp error={brandsQuery.error || keywordSourcesQuery.error} />
        </div>
      ) : !hasKeywords || !brandsQuery.data?.length ? (
        <div className="w-full space-y-1 p-4">
          <div className="text-sm font-bold opacity-60">
            <FaInfoCircle className="inline" /> Set up this project to see data:
          </div>
          <div className="text-sm">
            {(
              [
                {
                  done: hasKeywords,
                  to: `/keyword-manager`,
                  search: (prev: any) => ({
                    projectId: project.id,
                  }),
                  label: 'Add Keywords & Schedules',
                },
                {
                  done: brandsQuery.data?.length,

                  to: '/brands',
                  search: (prev: any) => ({
                    projectId: project.id,
                  }),
                  label: 'Add Brands',
                },
              ] as const
            ).map(({ done, to, search, label }, index) => (
              <div key={label}>
                <Link to={to} search={search}>
                  {index + 1}. {label}
                </Link>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <>
          <HomeTeamRankMetrics project={project} />
          <TopKeywordGroups project={project} />
          <RankingKeywords project={project} />
          <TopBrands project={project} />
          <TopKeywords project={project} />
        </>
      )}
    </Card>
  )
})

function HomeTeamRankMetrics({ project }: { project: Project }) {
  const timeRangeState = useTimeRangeState()
  const segmentIdState = useSegmentIdState()
  const keywordGroupState = useKeywordGroupState()
  const widgetState = useProjectsOverviewWidgetState()

  const dataQueryOptions = useGroupByOverTimeQueryOptions({
    projectId: project.id,
    start: timeRangeState.state[0]?.start,
    end: timeRangeState.state[0]?.end,
    groupBy: 'brandType',
    rollupBy: 'brandType',
    filters: {
      segmentIds: [segmentIdState.state],
      keywordGroups: [keywordGroupState.state],
      brandTypes: [BRAND_TYPE_OWNED],
    },
    sortByMetricPermutation: metricPermutation('unique_keywords'),
    metricPermutations: [
      ...metricIds.map(metricId => metricPermutation(metricId)),
      ...metricIds.map(metricId =>
        metricPermutation(metricId, {
          postAggregation: 'change',
        })
      ),
    ],
    samples: widgetState.state.samples,
    limit: 1,
    offset: 0,
    desc: true,
  })

  const dataQuery = useGroupByOverTimeQuery(dataQueryOptions, {
    staleTime: queryStaleTime,
  })

  return (
    <table>
      <tbody className="divide-y divide-gray-500/20">
        {metricIds.map((metricId, i) => {
          return (
            <HomeTeamMetric
              {...{
                key: metricId,
                widgetState: widgetState.state,
                dataQuery,
                metricId,
                setWidgetState: widgetState.setState,
                color: getDataColor(i),
              }}
            />
          )
        })}
      </tbody>
    </table>
  )
}

function HomeTeamMetric({
  dataQuery,
  metricId,
  color,
}: {
  metricId: (typeof metricIds)[number]
  dataQuery: UseQueryResult<GroupByQueryResponse>
  color: undefined | string
}) {
  const widgetState = useProjectsOverviewWidgetState()
  const focusMetricId = widgetState.state.focusMetricId
  const metric = metricsById[metricId]

  return (
    <tr
      className={twMerge(
        'cursor-pointer divide-x divide-gray-50 whitespace-nowrap bg-gray-50 bg-opacity-50 text-sm dark:divide-gray-850 dark:bg-gray-900 dark:bg-opacity-100',
        focusMetricId === metricId &&
          `relative z-10 divide-gray-500/20 bg-white bg-opacity-50 font-bold shadow-lg dark:bg-gray-800`
      )}
      onClick={() => {
        if (focusMetricId === metricId) {
          // navigate({ to: `/dashboards/${performanceOverviewSlug}` ,
          //   search: old =>
          //     setPerformanceOverviewQueryParams(old, { projectId: project.id }),
          // })
        } else {
          widgetState.setState(old => ({
            ...old,
            focusMetricId: metricId,
          }))
        }
      }}
    >
      <td
        className={twMerge(
          'h-6 w-[100px] divide-x divide-gray-500/20 border-l-8 border-gray-50 px-2 text-sm dark:border-gray-800',
          focusMetricId === metricId && `border-blue-400 dark:border-blue-500`
        )}
      >
        {metric.label}
      </td>
      <td className="h-6 w-[70px] px-2 text-right">
        {dataQuery.isLoading ? (
          <BoxPlaceholder />
        ) : dataQuery.data?.series?.length ? (
          (() => {
            return getMetricRenderer(metricId)(
              getDataValue(
                last(dataQuery.data.series[0]?.data),
                metricPermutation(metricId)
              )
            )
          })()
        ) : (
          '-'
        )}
      </td>
      <td className="h-6 w-[70px] px-2 text-right">
        {dataQuery.isLoading ? (
          <BoxPlaceholder />
        ) : dataQuery.data?.series?.length ? (
          getMetricChangeFormatter(metricId)(
            getDataValue(
              last(dataQuery.data.series[0]?.data),
              metricPermutation(metricId, { postAggregation: 'change' })
            )
          )
        ) : (
          '-'
        )}
      </td>
      <td className="relative h-6 w-[80px] min-w-20 hover:relative hover:z-10">
        <div className="absolute inset-x-0 inset-y-1">
          <HomeTeamMetricChart
            {...{
              widgetState: widgetState.state,
              dataQuery,
              metricId,
              color,
            }}
          />
        </div>
      </td>
    </tr>
  )
}

function HomeTeamMetricChart({ dataQuery, metricId, color }: any) {
  // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const metric = metricsById[metricId]

  const data = React.useMemo(() => {
    return dataQuery?.data?.series?.[0]
      ? [
          {
            ...dataQuery?.data?.series?.[0],
            label: metric.label,
            color,
          },
        ]
      : []
  }, [color, dataQuery?.data?.series, metric.label])

  return (
    <TimeSeriesChart
      {...{
        data,
        isLoading: dataQuery.isLoading,
        isError: dataQuery.isError,
        error: dataQuery.error,
        metricId,
        subAggregation: 'total',
        displayPostAggregation: 'value',
        sparkline: true,
        tooltip: false,
        limit: 1,
      }}
    />
  )
}

function TopKeywordGroups({ project }: { project: Project }) {
  const timeRangeState = useTimeRangeState()
  const segmentIdState = useSegmentIdState()
  const keywordGroupState = useKeywordGroupState()
  const widgetState = useProjectsOverviewWidgetState()

  const bestDataQueryOptions = useGroupByOverTimeQueryOptions({
    projectId: project.id,
    start: timeRangeState.state[0]?.start,
    end: timeRangeState.state[0]?.end,
    groupBy: 'keywordGroup',
    rollupBy: 'brandType',
    sortByMetricPermutation: metricPermutation(
      widgetState.state.focusMetricId,
      {
        postAggregation: 'change',
      }
    ),
    limit: 4,
    offset: 0,
    desc: true,
    metricPermutations: [
      metricPermutation(widgetState.state.focusMetricId, {
        postAggregation: 'value',
      }),
    ],
    filters: {
      segmentIds: [segmentIdState.state],
      keywordGroups: [keywordGroupState.state],
      brandTypes: [BRAND_TYPE_OWNED],
    },
    samples: widgetState.state.samples,
  })

  const worstDataQueryOptions = useGroupByOverTimeQueryOptions({
    ...widgetState,
    projectId: project.id,
    start: timeRangeState.state[0]?.start,
    end: timeRangeState.state[0]?.end,
    groupBy: 'keywordGroup',
    rollupBy: 'brandType',
    sortByMetricPermutation: metricPermutation(
      widgetState.state.focusMetricId,
      {
        postAggregation: 'change',
      }
    ),
    limit: 4,
    offset: 0,
    desc: false,
    metricPermutations: [
      metricPermutation(widgetState.state.focusMetricId, {
        postAggregation: 'value',
      }),
    ],
    filters: {
      segmentIds: [segmentIdState.state],
      keywordGroups: [keywordGroupState.state],
      brandTypes: [BRAND_TYPE_OWNED],
    },
    samples: widgetState.state.samples,
  })

  const bestDataQuery = useGroupByOverTimeQuery(bestDataQueryOptions, {
    staleTime: queryStaleTime,
  })
  const worstDataQuery = useGroupByOverTimeQuery(worstDataQueryOptions, {
    staleTime: queryStaleTime,
  })

  const getQueryRows = (query: any, i: any) => {
    const rows = (
      query.data?.series
        ? i
          ? query.data?.series
          : [...query.data?.series].reverse()
        : []
    )
      .map((serie: any) => {
        const value = getDataValue(last(serie.data), {
          id: widgetState.state.focusMetricId,
          aggregation: focusMetric.aggregations[0],
          subAggregation: 'total',
          postAggregation: 'value',
        })

        const change = getDataValue(last(serie.data), {
          id: widgetState.state.focusMetricId,
          aggregation: focusMetric.aggregations[0],
          subAggregation: 'total',
          postAggregation: 'change',
        })

        return {
          serie,
          value,
          change,
        }
      })
      .filter((d: any) =>
        i
          ? d.change * (focusMetric.inverted ? -1 : 1) > 0
          : d.change * (focusMetric.inverted ? -1 : 1) < 0
      )

    return rows
  }

  const focusMetric = metricsById[widgetState.state.focusMetricId!]

  const renderQueryRows = (query: any, i: any) => {
    const rows = getQueryRows(query, i)

    return (
      <React.Fragment key={i}>
        {query.isFetching ? (
          <tr>
            <td>
              {[1, 2, 3, 4].map(d => (
                <BoxPlaceholder key={d} className="my-1 h-[1.4rem]" />
              ))}
            </td>
          </tr>
        ) : query.isError ? (
          <tr>
            <td colSpan={3}>
              <ErrorComp error={query.error} />
            </td>
          </tr>
        ) : !rows.length ? (
          <tr>
            <td colSpan={3} className="p-2 text-center italic">
              No {i ? 'winners' : 'losers'} found.
            </td>
          </tr>
        ) : (
          rows.map(({ serie, value, change }: any) => {
            return (
              <tr key={serie.label}>
                <td className="h-7 px-1 tracking-tighter">
                  {renderLimitedText(serie.label, keywordGroupLimit, {
                    fromPercentage: 0.5,
                  })}
                </td>
                <td className="h-7 px-1 text-right opacity-60">
                  {getMetricFormatter(widgetState.state.focusMetricId)(value)}
                </td>
                <td className="h-7 px-1 text-right leading-tight">
                  {getMetricChangeFormatter(widgetState.state.focusMetricId)(
                    change
                  )}
                </td>
              </tr>
            )
          })
        )}
      </React.Fragment>
    )
  }

  return (
    <div className="flex w-[300px] flex-col divide-y divide-gray-50 dark:divide-gray-800">
      <div className="px-2 py-1">
        <Link
          to={`/dashboards/${performanceByKeywordGroupSlug}`}
          search={{
            projectId: project.id,
          }}
        >
          Keyword Groups
        </Link>{' '}
        <span className="opacity-50">- Winners / Losers</span>
      </div>

      <div
        className={twMerge(
          `flex-1 px-2 py-1 font-normal`,
          !getQueryRows(bestDataQuery, 1).length &&
            !getQueryRows(worstDataQuery, 2).length
            ? 'flex items-center justify-center'
            : ''
        )}
      >
        <table className="w-full text-sm tracking-tight">
          <tbody className="divide-y divide-gray-50 dark:divide-gray-800">
            {renderQueryRows(bestDataQuery, 1)}
            <tr className="border-none">
              <td colSpan={4} className="bg-gray-200 dark:bg-gray-800" />
            </tr>
            {renderQueryRows(worstDataQuery, 0)}
          </tbody>
        </table>
      </div>
    </div>
  )
}

function RankingKeywords({ project }: { project: Project }) {
  const timeRangeState = useTimeRangeState()
  const segmentIdState = useSegmentIdState()
  const keywordGroupState = useKeywordGroupState()

  const focusMetricId = 'unique_keywords'

  const queryOptions = useGroupByOverTimeQueryOptions({
    projectId: project.id,
    start: timeRangeState.state[0]?.start,
    end: timeRangeState.state[0]?.end,
    groupBy: 'brandType',
    rollupBy: 'brandType',
    sortByMetricPermutation: metricPermutation(focusMetricId, {
      postAggregation: 'change',
    }),
    limit: 4,
    offset: 0,
    desc: true,
    samples: 1,
    metricPermutations: [
      metricPermutation(focusMetricId, {
        postAggregation: 'value',
      }),
      ...rankGroupOptions.map(option =>
        metricPermutation(focusMetricId, {
          subAggregation: option.value,
          postAggregation: 'value',
        })
      ),
      ...rankGroupOptions.map(option =>
        metricPermutation(focusMetricId, {
          subAggregation: option.value,
          postAggregation: 'change',
        })
      ),
    ],
    filters: {
      segmentIds: [segmentIdState.state],
      keywordGroups: [keywordGroupState.state],
      brandTypes: [BRAND_TYPE_OWNED],
    },
  })

  const query = useGroupByOverTimeQuery(queryOptions, {
    staleTime: queryStaleTime,
  })

  return (
    <div className="flex w-[230px] flex-col divide-y divide-gray-50 whitespace-nowrap dark:divide-gray-800">
      <div className="flex items-center justify-between gap-4 px-2 py-1">
        <span className="font-bold">Ranking Keywords</span>
        {query.isFetching ? (
          <Loader />
        ) : query.isError ? null : (
          (() => {
            if (!query.data?.series?.[0]?.data?.length) {
              return null
            }

            const value = getDataValue(
              last(query.data?.series?.[0]?.data),
              metricPermutation(focusMetricId, {
                postAggregation: 'value',
              })
            )

            const change = getDataValue(
              last(query.data?.series?.[0]?.data),
              metricPermutation(focusMetricId, {
                postAggregation: 'change',
              })
            )

            return (
              <div className="flex items-center gap-2">
                {getMetricFormatter(focusMetricId)(value)}
                {getMetricChangeFormatter(focusMetricId)(change)}
              </div>
            )
          })()
        )}
      </div>

      <div
        className={twMerge(
          'flex-1 px-2 py-1 font-normal',

          !query.data?.series?.[0]?.data?.length &&
            'flex items-center justify-center'
        )}
      >
        <table className="w-full text-sm tracking-tight">
          <tbody className="divide-y divide-gray-50 dark:divide-gray-800">
            {query.isFetching ? (
              <>
                <tr>
                  <td>
                    {rankGroupOptions.map((d, i) => (
                      <BoxPlaceholder key={i} className="my-1 h-[1.3rem]" />
                    ))}
                  </td>
                </tr>
              </>
            ) : query.isError ? (
              <tr>
                <td>
                  <ErrorComp error={query.error} />
                </td>
              </tr>
            ) : !query.data?.series?.[0]?.data?.length ? (
              <NoData />
            ) : (
              rankGroupOptions.map((option, i) => {
                const value = getDataValue(
                  last(query.data?.series[0]?.data),
                  metricPermutation(focusMetricId, {
                    subAggregation: option.value,
                    postAggregation: 'value',
                  })
                )

                const change = getDataValue(
                  last(query.data?.series[0]?.data),
                  metricPermutation(focusMetricId, {
                    subAggregation: option.value,
                    postAggregation: 'change',
                  })
                )

                return (
                  <tr key={option.value}>
                    <td className="h-6 px-1 tracking-tighter">
                      <FaCircle
                        className="inline text-xs"
                        style={{ color: getRankGroupColor(i) }}
                      />{' '}
                      {option.label}
                    </td>
                    <td className="h-6 px-1 text-right opacity-60">
                      {getMetricFormatter(focusMetricId)(value)}
                    </td>
                    <td className="h-6 px-1 text-right leading-tight">
                      {getMetricChangeFormatter(focusMetricId)(change)}
                    </td>
                  </tr>
                )
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

function TopBrands({ project }: { project: Project }) {
  const timeRangeState = useTimeRangeState()
  const segmentIdState = useSegmentIdState()
  const keywordGroupState = useKeywordGroupState()
  const widgetState = useProjectsOverviewWidgetState()

  const groupByFacet = allQueryFacets.find(d => d.id === 'brand')!

  // useWidgetPaginationReset(widgetState, setWidgetState)

  const dataQueryOptions = useGroupByOverTimeQueryOptions({
    projectId: project.id,
    start: timeRangeState.state[0]?.start,
    end: timeRangeState.state[0]?.end,
    groupBy: 'brand',
    rollupBy: 'brand',
    sortByMetricPermutation: metricPermutation(
      widgetState.state.focusMetricId,
      {
        postAggregation: 'value',
      }
    ),
    limit: 5,
    offset: 0,
    desc: true,
    metricPermutations: [
      metricPermutation(widgetState.state.focusMetricId, {
        postAggregation: 'change',
      }),
    ],
    filters: {
      segmentIds: [segmentIdState.state],
      keywordGroups: [keywordGroupState.state],
    },
    samples: widgetState.state.samples,
  })

  const dataQuery = useGroupByOverTimeQuery(dataQueryOptions, {
    staleTime: queryStaleTime,
  })

  const data = React.useMemo(() => {
    return dataQuery.data?.series?.map(series => {
      return {
        ...series,

        label: groupByFacet.formatSeriesLabel(series, dataQuery.data?.series),
        data: series.data.map(datum => {
          return {
            ...datum,
            // @ts-expect-error  // No overload matches this call.
            primary: new Date(datum.requested),
            secondary: getDataValue(
              datum,
              metricPermutation(widgetState.state.focusMetricId)
            ),
          }
        }),
      }
    })
  }, [dataQuery.data?.series, groupByFacet, widgetState.state.focusMetricId])

  // const focusMetric = metrics.find(d => d.id === widgetState.focusMetricId)
  // const sortByMetric = metrics.find(d => d.id === widgetState.sortByMetricId)

  // const navigate = useNavigate()

  // const onDrillthrough = value => {
  //   navigate({ to: `../${performanceOverviewSlug}` ,
  //     search: prev =>
  //       setPerformanceOverviewQueryParams(prev, {
  //         rollupBy: dashboardState.groupBy,
  //         filters: {
  //           ...prev.dashboard.filters,
  //           [dashboardState.groupBy]: [value],
  //         },
  //       }),
  //   })
  // }

  // const onDatumClick = datum => onDrillthrough(datum.originalSeries)
  // const onSerieClick = serie => onDrillthrough(serie)

  return (
    <div className="flex w-[260px] flex-col whitespace-nowrap">
      <Link
        to={`/dashboards/${competitiveShareOfVoiceSlug}`}
        search={{
          projectId: project.id,
          groupBy: 'brand',
        }}
      >
        <div className="px-2 py-1">Top Brands</div>
      </Link>
      <div
        className="flex-1"
        style={{ opacity: dataQuery.isFetching ? 0.5 : 1 }}
      >
        <TimeSeriesChart
          {...{
            data,
            isLoading: dataQuery.isFetching,
            isFetching: dataQuery.isFetching,
            isError: dataQuery.isError,
            error: dataQuery.error,
            subAggregation: 'total',
            displayPostAggregation: 'value',
            metricId: widgetState.state.focusMetricId,
            sparkline: true,
            primaryCursor: {
              showLabel: false,
            },
          }}
        />
      </div>
      <div className="h-2" />
      <table
        className="w-full text-sm tracking-tight"
        style={{ opacity: dataQuery.isFetching ? 0.5 : 1 }}
      >
        <tbody className="divide-y divide-gray-50 dark:divide-gray-800">
          {dataQuery.isFetching ? (
            <>
              {[1, 2, 3, 4].map(d => (
                <tr key={d}>
                  <td>
                    <BoxPlaceholder />
                  </td>
                </tr>
              ))}
            </>
          ) : dataQuery.isError ? (
            <tr>
              <td>
                <ErrorComp error={dataQuery.error} />
              </td>
            </tr>
          ) : (
            dataQuery.data?.series?.map(serie => {
              const value = getDataValue(
                last(serie.data),
                metricPermutation(widgetState.state.focusMetricId, {
                  postAggregation: 'value',
                })
              )

              const change = getDataValue(
                last(serie.data),
                metricPermutation(widgetState.state.focusMetricId, {
                  postAggregation: 'change',
                })
              )

              return (
                <tr key={(serie.brand as any).brand_id}>
                  <td className="h-7 px-1 tracking-tighter">
                    <FaCircle
                      className="inline text-xs"
                      style={{ color: serie.color }}
                    />{' '}
                    {renderLimitedText(serie.label, brandLabelLimit)}
                  </td>
                  <td className="h-7 px-1 text-right opacity-60">
                    {getMetricFormatter(widgetState.state.focusMetricId)(value)}
                  </td>
                  <td className="h-7 px-1 text-right leading-tight">
                    {getMetricChangeFormatter(widgetState.state.focusMetricId)(
                      change
                    )}
                  </td>
                </tr>
              )
            })
          )}
        </tbody>
      </table>
    </div>
  )
}

function TopKeywords({ project }: { project: Project }) {
  const timeRangeState = useTimeRangeState()
  const segmentIdState = useSegmentIdState()
  const keywordGroupState = useKeywordGroupState()
  const widgetState = useProjectsOverviewWidgetState()

  const focusMetricId = safeMetricId(
    widgetState.state.focusMetricId,
    metricIds.filter(d => d !== 'unique_keywords'),
    'top_rank'
  )

  const bestDataQueryOptions = useGroupByOverTimeQueryOptions({
    projectId: project.id,
    start: timeRangeState.state[0]?.start,
    end: timeRangeState.state[0]?.end,
    groupBy: 'keyword',
    rollupBy: 'brandType',
    sortByMetricPermutation: metricPermutation(focusMetricId, {
      postAggregation: 'change',
    }),
    limit: 4,
    offset: 0,
    desc: true,
    samples: 1,
    metricPermutations: [
      metricPermutation(focusMetricId, {
        postAggregation: 'value',
      }),
    ],
    filters: {
      segmentIds: [segmentIdState.state],
      keywordGroups: [keywordGroupState.state],
      brandTypes: [BRAND_TYPE_OWNED],
    },
  })

  const worstDataQueryOptions = useGroupByOverTimeQueryOptions({
    projectId: project.id,
    start: timeRangeState.state[0]?.start,
    end: timeRangeState.state[0]?.end,
    groupBy: 'keyword',
    rollupBy: 'brandType',
    sortByMetricPermutation: metricPermutation(focusMetricId, {
      postAggregation: 'change',
    }),
    limit: 4,
    offset: 0,
    desc: false,
    samples: 1,
    metricPermutations: [
      metricPermutation(focusMetricId, {
        postAggregation: 'value',
      }),
    ],
    filters: {
      segmentIds: [segmentIdState.state],
      keywordGroups: [keywordGroupState.state],
      brandTypes: [BRAND_TYPE_OWNED],
    },
  })

  const bestDataQuery = useGroupByOverTimeQuery(bestDataQueryOptions, {
    staleTime: queryStaleTime,
  })
  const worstDataQuery = useGroupByOverTimeQuery(worstDataQueryOptions, {
    staleTime: queryStaleTime,
  })

  const getQueryRows = (query: any, i: any) => {
    const rows = (
      query.data?.series
        ? i
          ? query.data?.series
          : [...query.data?.series].reverse()
        : []
    ).map((serie: any) => {
      const value = getDataValue(
        last(serie.data),
        metricPermutation(focusMetricId, {
          postAggregation: 'value',
        })
      )

      const change = getDataValue(
        last(serie.data),
        metricPermutation(focusMetricId, {
          postAggregation: 'change',
        })
      )

      return { serie, value, change }
    })
    return rows
  }

  const renderQueryRows = (query: any, i: any) => {
    const rows = getQueryRows(query, i)

    return (
      <React.Fragment key={i}>
        {query.isFetching ? (
          <tr>
            <td>
              {[1, 2, 3, 4].map(d => (
                <BoxPlaceholder key={d} className="my-1 h-[1.4rem] w-64" />
              ))}
            </td>
          </tr>
        ) : query.isError ? (
          <tr>
            <td colSpan={3}>
              <ErrorComp error={query.error} />
            </td>
          </tr>
        ) : !rows.length ? (
          <tr>
            <td colSpan={3} className="p-2 text-center italic">
              No {i ? 'winners' : 'losers'} found.
            </td>
          </tr>
        ) : (
          rows.map(({ serie, value, change }: any) => {
            return (
              <tr key={serie.label}>
                <td className="h-7 px-1 tracking-tighter">
                  {formatKeywordV1(serie.keyword, { short: true })}
                </td>
                <td className="h-7 px-1 text-right opacity-60">
                  {getMetricRenderer(focusMetricId)(value)}
                </td>
                <td className="h-7 px-1 text-right leading-tight">
                  {getMetricChangeFormatter(focusMetricId)(change)}
                </td>
              </tr>
            )
          })
        )}
      </React.Fragment>
    )
  }

  return (
    <div className="flex flex-col divide-y divide-gray-50 whitespace-nowrap dark:divide-gray-800">
      <div className="px-2 py-1">
        <Link
          to={`/dashboards/${performanceByKeywordSlug}`}
          search={{
            projectId: project.id,
          }}
        >
          Keywords
        </Link>{' '}
        <span className="opacity-50">- Winners / Losers</span>
      </div>

      <div
        className={twMerge(
          `flex-1 px-2 py-1 font-normal`,
          !getQueryRows(bestDataQuery, 1).length &&
            !getQueryRows(worstDataQuery, 2).length
            ? 'flex items-center justify-center'
            : ''
        )}
      >
        <table className="w-full text-sm tracking-tight">
          <tbody className="divide-y divide-gray-50 dark:divide-gray-800 ">
            {renderQueryRows(bestDataQuery, 1)}
            <tr className="border-none">
              <td colSpan={4} className="bg-gray-200 dark:bg-gray-800" />
            </tr>
            {renderQueryRows(worstDataQuery, 0)}
          </tbody>
        </table>
      </div>
    </div>
  )
}
