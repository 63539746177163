import * as d3 from 'd3'

export const dataColors = {
  red: '#ff4e4e',
  redAlt: '#f33232',
  orange: '#FAA43A',
  orangeAlt: '#de7c00',
  yellow: '#DECF3F',
  yellowAlt: '#d7af00',
  green: '#a2d925',
  greenAlt: '#53c200',
  blue: '#0f83ab',
  blueAlt: '#006d92',
  teal: '#53cfc9',
  tealAlt: '#3f9a80',
  purple: '#734fe9',
  purpleAlt: '#4c26c9',
  pink: '#cd82ad',
  pinkAlt: '#d44d99',
  gray: '#778899',
  grayAlt: '#6688AA',
}

export const rankGroupColors = [
  '#08769c', // 0
  '#11a3c3', // 1
  '#1ac5cc', // 2
  '#56BA5E', // 3
  '#90CF3C', // 4
  '#F0DD24', // 5
  '#F0CA24', // 6
  '#FAA43A', // 7
  '#fc6868', // 8
]

export const dataColorsList = [
  dataColors.blue,
  dataColors.orange,
  dataColors.red,
  dataColors.teal,
  dataColors.green,
  dataColors.yellow,
  dataColors.purple,
  dataColors.pink,
  dataColors.blueAlt,
  dataColors.orangeAlt,
  dataColors.redAlt,
  dataColors.tealAlt,
  dataColors.greenAlt,
  dataColors.yellowAlt,
  dataColors.purpleAlt,
  dataColors.pinkAlt,
]

export function getDataColor(i: number) {
  return dataColorsList[i % dataColorsList.length]
}

export function getRankGroupColor(i: number) {
  if (i < 0 || i > rankGroupColors.length) {
    throw new Error(`No rank group found for index: ${i}`)
  }

  return rankGroupColors[i]
}

const rankColors = [
  '#08769c', // 0
  '#11a3c3', // 1
  '#1ac5cc', // 2
  '#56BA5E', // 3
  '#90CF3C', // 4
  '#F0DD24', // 5
  '#F0CA24', // 6
  '#FAA43A', // 7
  '#fc6868', // 8
]

const rankColorsInterpolater = d3.interpolateRgbBasis(rankColors)

export function getRankColor(rank: number, relativeTotal: number) {
  if (typeof rank !== 'number') return undefined
  return rankColorsInterpolater((rank - 1) / (relativeTotal - 1))
}
