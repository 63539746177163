import { createColumnHelper } from '@tanstack/react-table'
import * as React from 'react'
import Card from '../../../components/Card'
import Table from '../../../components/Table'
import { useTable } from '../../../hooks/useTable'
import { relatedPhrasesMetricsList } from '../metrics'
import { ClusterPb, RollupMetricsPb } from '../../../utils/proto'

const relatedPhraseMetricsColumnHelper = createColumnHelper<RollupMetricsPb>()
export const relatedPhraseMetricsColumns = [
  relatedPhraseMetricsColumnHelper.accessor('label', {
    header: 'Phrase',
    meta: {
      tight: true,
    },
  }),
  ...relatedPhrasesMetricsList.map(metric => {
    return relatedPhraseMetricsColumnHelper.accessor(
      d => metric.getValue(d.metrics![metric.id]!),
      {
        header: metric.label,
        id: metric.id,
        cell: cellProps => metric.renderValue(cellProps.getValue()),
        meta: {
          getCellProps: () => {
            return {
              className: 'text-right',
            }
          },
          tight: true,
        },
      }
    )
  }),
]
export function PAA(props: { cluster: ClusterPb }) {
  const data = React.useMemo(
    (): RollupMetricsPb[] =>
      props.cluster.metricsByRelatedPhraseType?.peopleAlsoAsk || [],
    [props.cluster.metricsByRelatedPhraseType?.peopleAlsoAsk]
  )

  const table = useTable({
    data,
    columns: relatedPhraseMetricsColumns,
    initialState: React.useMemo(
      () => ({
        pagination: {
          pageSize: 50,
        },
      }),
      []
    ),
  })

  return (
    <Card className="divide-y divide-gray-500/20 p-0">
      <div className="p-2 text-lg font-bold">People Also Ask</div>
      <Table table={table} />
    </Card>
  )
}
