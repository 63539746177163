import { createColumnHelper } from '@tanstack/react-table'
import moment from 'moment'
import * as React from 'react'
import { FaCheck, FaEdit, FaPlus, FaTimesCircle } from 'react-icons/fa'
import { UserWorkspace } from '../../../openapi'
import Card from '../../components/Card'
import Clickable from '../../components/Clickable'
import Container from '../../components/Container'
import Input from '../../components/Input'
import Link from '../../components/Link'
import Table from '../../components/Table'
import Tooltip from '../../components/Tooltip'
import { useProfileQuery } from '../../hooks/profile'
import useConfirm from '../../hooks/useConfirm'
import useNavigate from '../../hooks/useNavigate'
import { useTable } from '../../hooks/useTable'
import useZustand from '../../hooks/useZustand'
import { useSaveUser } from '../../hooks/users'
import { ButtonPlain } from '../../components/ButtonPlain'

const columnHelper = createColumnHelper<UserWorkspace>()

export function Workspaces() {
  const profileQuery = useProfileQuery()
  const confirm = useConfirm()
  const navigate = useNavigate()
  const [, setStore] = useZustand(state => state.helpUrl)

  const saveUser = useSaveUser({ workspaceUser: true })

  const columns = React.useMemo(
    () => [
      columnHelper.accessor(d => d.workspace.name, {
        header: 'Workspace Name',
        id: 'name',
        filterFn: 'fuzzy',
        cell: props => (
          <Link
            to="/"
            search={{
              workspaceId: props.row.original?.workspace.id,
            }}
          >
            {props.row.original?.workspace.name}
          </Link>
        ),
      }),
      columnHelper.accessor(d => d.workspace.slug, {
        header: 'Slug',
        id: 'slug',
        filterFn: 'fuzzy',
      }),
      columnHelper.accessor('givenName', {
        header: 'User First Name',
        filterFn: 'fuzzy',
        cell: function Cell({ row, getValue }) {
          const initialValue = getValue()
          const [isEditing, setIsEditing] = React.useState(false)
          const [value, setEditingValue] = React.useState(initialValue)

          React.useEffect(() => {
            setEditingValue(initialValue)
          }, [initialValue])

          const onCancel = () => {
            setEditingValue(initialValue)
            setIsEditing(false)
          }

          const handleSubmit = (e: SubmitEvent) => {
            e.preventDefault()
            e.stopPropagation()
            if (!isEditing) {
              return
            }

            const userValues = {
              ...row.original,
              id: row.original?.userId,
              givenName: value,
            }

            // @ts-expect-error  // Argument of type '{ id: number; givenName: string ... Remove this comment to see the full error message
            saveUser(userValues)
            setIsEditing(false)
          }

          return (
            <form className="flex items-center justify-between">
              <div className="flex-auto">
                {isEditing ? (
                  <Input
                    value={value}
                    onChange={e => setEditingValue(e.currentTarget.value)}
                    className="w-full"
                  />
                ) : (
                  initialValue
                )}
              </div>
              <div className="ml-2">
                {isEditing ? (
                  <>
                    <Clickable
                      onClick={onCancel}
                      className={`px-2 text-[1.2rem] text-red-600 hover:text-red-500`}
                    >
                      <FaTimesCircle className="inline" />
                    </Clickable>
                    <Clickable
                      onClick={handleSubmit}
                      className={`px-2 text-[1.2rem] text-green-600 hover:text-green-500`}
                    >
                      <FaCheck className="inline" />
                    </Clickable>
                  </>
                ) : (
                  <div>
                    <Clickable
                      onClick={() => setIsEditing(true)}
                      className={`px-1 text-gray-200 hover:text-blue-500 dark:text-gray-300`}
                    >
                      <FaEdit className="inline" />
                    </Clickable>
                  </div>
                )}
              </div>
            </form>
          )
        },
      }),
      columnHelper.accessor('familyName', {
        header: 'User Last Name',
        filterFn: 'fuzzy',
        cell: function Cell({ row, getValue }) {
          const initialValue = getValue()
          const [isEditing, setIsEditing] = React.useState(false)
          const [value, setEditingValue] = React.useState(initialValue)

          React.useEffect(() => {
            setEditingValue(initialValue)
          }, [initialValue])

          const onCancel = () => {
            setEditingValue(initialValue)
            setIsEditing(false)
          }

          const handleSubmit = (e: SubmitEvent) => {
            e.preventDefault()
            e.stopPropagation()
            if (!isEditing) {
              return
            }

            const userValues = {
              ...row.original,
              id: row.original?.userId,
              familyName: value,
            }
            // @ts-expect-error  // Argument of type '{ id: number; familyName: string... Remove this comment to see the full error message
            saveUser(userValues)

            setIsEditing(false)
          }

          return (
            <form className="flex items-center justify-between">
              <div className="flex-auto">
                {isEditing ? (
                  <Input
                    value={value}
                    onChange={e => setEditingValue(e.currentTarget.value)}
                    className="w-full"
                  />
                ) : (
                  initialValue
                )}
              </div>
              <div className="ml-2">
                {isEditing ? (
                  <>
                    <Clickable
                      onClick={onCancel}
                      className={`px-2 text-[1.2rem] text-red-600 hover:text-red-500`}
                    >
                      <FaTimesCircle className="inline" />
                    </Clickable>
                    <Clickable
                      onClick={handleSubmit}
                      className={`px-2 text-[1.2rem] text-green-600 hover:text-green-500`}
                    >
                      <FaCheck className="inline" />
                    </Clickable>
                  </>
                ) : (
                  <div>
                    <Clickable
                      onClick={() => setIsEditing(true)}
                      className={`px-1 text-gray-200 hover:text-blue-500 dark:text-gray-300`}
                    >
                      <FaEdit className="inline" />
                    </Clickable>
                  </div>
                )}
              </div>
            </form>
          )
        },
      }),
      columnHelper.accessor('displayName', {
        header: 'User Display Name',
        filterFn: 'fuzzy',
        cell: function Cell({ row, getValue }) {
          const initialValue = getValue()
          const [isEditing, setIsEditing] = React.useState(false)
          const [value, setEditingValue] = React.useState(initialValue)

          React.useEffect(() => {
            setEditingValue(initialValue)
          }, [initialValue])

          const onCancel = () => {
            setEditingValue(initialValue)
            setIsEditing(false)
          }

          const handleSubmit = (e: SubmitEvent) => {
            e.preventDefault()
            e.stopPropagation()
            if (!isEditing) {
              return
            }
            const userValues = {
              ...row.original,
              id: row.original?.userId,
              displayName: value,
            }
            // @ts-expect-error  // Argument of type '{ id: number; displayName: strin... Remove this comment to see the full error message
            saveUser(userValues)
            setIsEditing(false)
          }

          return (
            <form className="flex items-center justify-between">
              <div className="flex-auto">
                {isEditing ? (
                  <Input
                    value={value}
                    onChange={e => setEditingValue(e.currentTarget.value)}
                    className="w-full"
                  />
                ) : (
                  initialValue
                )}
              </div>
              <div className="ml-2">
                {isEditing ? (
                  <>
                    <Clickable
                      onClick={onCancel}
                      className={`px-2 text-[1.2rem] text-red-600 hover:text-red-500`}
                    >
                      <FaTimesCircle className="inline" />
                    </Clickable>
                    <Clickable
                      onClick={handleSubmit}
                      className={`px-2 text-[1.2rem] text-green-600 hover:text-green-500`}
                    >
                      <FaCheck className="inline" />
                    </Clickable>
                  </>
                ) : (
                  <div>
                    <Clickable
                      onClick={() => setIsEditing(true)}
                      className={`px-1 text-gray-200 hover:text-blue-500 dark:text-gray-300`}
                    >
                      <FaEdit className="inline" />
                    </Clickable>
                  </div>
                )}
              </div>
            </form>
          )
        },
      }),
      columnHelper.accessor('email', {
        header: 'User Email',
        filterFn: 'fuzzy',
      }),
      // columnHelper.accessor(d => new Date(d.lastLoggedInAt), {
      //   header: 'Last Login',
      //   cell: props => (
      //     <Tooltip tooltip={moment(props.getValue()).format('lll')}>
      //       {props.getValue() ? moment(props.getValue()).fromNow() : '-'}
      //     </Tooltip>
      //   ),
      // }),
      // columnHelper.accessor(d => new Date(d.firstLoggedInAt), {
      //   header: 'Joined On',
      //   cell: props => (
      //     <Tooltip tooltip={moment(props.getValue()).format('lll')}>
      //       {props.getValue() ? moment(props.getValue()).fromNow() : '-'}
      //     </Tooltip>
      //   ),
      // }),
      columnHelper.accessor(d => new Date(d.workspace.createdAt), {
        header: 'Date Created',
        cell: props => (
          <Tooltip tooltip={moment(props.getValue()).format('lll')}>
            {props.getValue() ? moment(props.getValue()).fromNow() : '-'}
          </Tooltip>
        ),
      }),
    ],
    [saveUser]
  )

  const tableData = React.useMemo(
    () => profileQuery.data?.userWorkspaces ?? [],
    [profileQuery.data?.userWorkspaces]
  )

  const table = useTable({
    data: tableData,
    columns,
    initialState: {
      pagination: {
        pageSize: 20,
      },
      sorting: [{ id: 'name', desc: false }],
    },
  })

  const openWorkspaceDocs = () => {
    setStore(old => {
      old.showHelp = true
      old.helpUrl =
        'https://help.nozzle.io/what-is-the-difference-between-a-workspace-and-a-team'
    })
  }

  const addWorkspace = async () => {
    const confirmed = await confirm({
      title: `Are you sure you want a new workspace? `,
      message: (
        <div>
          Check out{' '}
          <span
            className="text-blue-500 hover:cursor-pointer hover:underline"
            onClick={openWorkspaceDocs}
          >
            this article
          </span>{' '}
          to learn the difference between a project and a workspace.
        </div>
      ),
    })

    if (confirmed) {
      navigate({ to: '/sign-up', search: { isNewWorkspace: true } })
    }
  }

  return (
    <Container className="space-y-2">
      <Card className="flex items-center gap-3">
        <div className="pl-2 text-xl font-bold">Workspaces</div>
        <ButtonPlain className="bg-green-500" onClick={addWorkspace}>
          <FaPlus className="inline" /> Create Workspace
        </ButtonPlain>
      </Card>
      <Card className="p-0">
        <Table
          table={table}
          className="border-t border-gray-100 dark:border-gray-850"
        />
      </Card>
    </Container>
  )
}
