import * as React from 'react'
import { twMerge } from 'tailwind-merge'
import useRect from '../hooks/useRect'

export default function AspectRatio({
  ratio,
  style = {},
  className,
  children,
  ...rest
}: any) {
  const ref = React.useRef()
  const rect = useRect(ref.current)

  const ratioPercentage = ratio[1] / ratio[0]
  const height = (rect?.width ?? 1) * ratioPercentage

  return (
    <div
      ref={ref}
      className={twMerge(`block w-full`, className)}
      style={{
        height,
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  )
}
