import { useNavigate } from '@tanstack/react-router'
import { twMerge } from 'tailwind-merge'
import * as React from 'react'
import * as d3 from 'd3'
import Card from '../../components/Card'
import Select from '../../components/Select'
import {
  MonitoredUrlPb,
  MonitoredUrlViewOptionsPb,
  MonitoredUrlViewPb,
} from '../../utils/proto'
import { formatCustomerJourneyStage } from '../../options/customerJourneyStage'
import { formatSearchIntent } from '../../options/searchIntent'
import { formatPriority, priorityOptions } from '../../options/priority'
import { useProject } from '../../hooks/projects'
import ForceDirectedGraph from '../../components/ForceDirectedGraph'
import { Tabs, Tab } from '../../components/Tabs'
import { useMonitoredUrlsQuery } from '../../utils/monitoredUrls'
// import { MonitoredUrlsBubbleChart } from './MonitoredUrlsBubbleChart'

export function MonitoredUrlsOverview({
  workspaceId,
  projectId,
}: {
  workspaceId: string
  projectId: string
}) {
  return (
    <div>
      {/* <MonitoredUrlsBubbleChart
        workspaceId={workspaceId}
        projectId={projectId}
      /> */}
      {/* <MonitoredUrlsTreeMap workspaceId={workspaceId} projectId={projectId} /> */}
      {/* <MonitoredUrlsForceTree workspaceId={workspaceId} projectId={projectId} /> */}
    </div>
  )
}

export function MonitoredUrlsTreeMap({
  workspaceId,
  projectId,
}: {
  workspaceId: string
  projectId: string
}) {
  const projectQuery = useProject({
    workspaceId,
    id: projectId,
  })

  const monitoredUrlsQuery = useMonitoredUrlsQuery({
    workspaceId,
    projectId,
    rootOnly: true,
    view: MonitoredUrlViewPb.SEARCH,
    viewOptions: new MonitoredUrlViewOptionsPb({
      ancestorDepth: 0,
      descendentDepth: 1,
    }),
    // filters: {
    //   search: 'blog',
    // },
  })

  const children = monitoredUrlsQuery.data?.monitoredUrls

  const parentMonitoredUrl: MonitoredUrlPb | undefined = React.useMemo(() => {
    return undefined as MonitoredUrlPb | undefined
  }, [])

  const root = React.useMemo(
    () =>
      d3.hierarchy(
        children?.length === 1
          ? children[0]!
          : parentMonitoredUrl
          ? {
              ...parentMonitoredUrl,
              children,
            }
          : ({
              url: {
                url: projectQuery.data?.name ?? 'Root',
              },
              children,
            } as MonitoredUrlPb)
      ),
    [children, parentMonitoredUrl, projectQuery.data?.name]
  )

  const navigate = useNavigate()

  const fillMetricOptions = React.useMemo(
    () =>
      [
        {
          id: 'priority',
          label: 'Priority',
          accessor: d => formatPriority(d.priority),
          scale: 'ordinal',
          domain: priorityOptions.map(d => d.label),
          range: priorityOptions.map(d => d.color),
        },
        {
          id: 'customerJourneyStage',
          label: 'Customer Journey',
          accessor: d => formatCustomerJourneyStage(d.customerJourneyStage),
        },
        {
          id: 'primarySearchIntent',
          label: 'Primary Search Intent',
          accessor: d => formatSearchIntent(d.primarySearchIntent),
        },
      ] satisfies {
        id: string
        label: string
        accessor: (d: MonitoredUrlPb) => number | string | undefined
        scale?: 'ordinal'
        domain?: any[]
        range?: any[]
      }[],
    []
  )

  const radiusMetricOptions = React.useMemo(
    () =>
      [
        {
          id: '',
          label: 'None',
          accessor: undefined,
          // accessor: d => d.children.length,
          // scale: 'ordinal',
          // domain: priorityOptions.map(d => d.label),
          // range: priorityOptions.map(d => d.color),
        },
        {
          id: 'children',
          label: 'Child URL Count',
          accessor: d => d.children.length,
          // scale: 'ordinal',
          // domain: priorityOptions.map(d => d.label),
          // range: priorityOptions.map(d => d.color),
        },
        {
          id: 'random',
          label: 'Random! 😜',
          accessor: d =>
            deterministicNumber(d.url?.url ?? '') *
            deterministicNumber(d.url?.url ?? ''),
          // scale: 'ordinal',
          // domain: priorityOptions.map(d => d.label),
          // range: priorityOptions.map(d => d.color),
        },
        // {
        //   id: 'customerJourneyStage',
        //   label: 'Customer Journey',
        //   accessor: d => formatCustomerJourneyStage(d.customerJourneyStage),
        // },
        // {
        //   id: 'primarySearchIntent',
        //   label: 'Primary Search Intent',
        //   accessor: d => formatSearchIntent(d.primarySearchIntent),
        // },
      ] satisfies {
        id: string
        label: string
        accessor:
          | ((d: MonitoredUrlPb) => number | string | undefined)
          | undefined
        scale?: 'ordinal'
        domain?: any[]
        range?: any[]
      }[],
    []
  )

  const [fillMetricId, setFillMetricId] =
    React.useState<(typeof fillMetricOptions)[number]['id']>('priority')

  const [radiusMetricId, setRadiusMetricId] =
    React.useState<(typeof radiusMetricOptions)[number]['id']>('')

  const fillMetric = React.useMemo(
    () => fillMetricOptions.find(d => d.id === fillMetricId)!,
    [fillMetricId, fillMetricOptions]
  )

  const radiusMetric = React.useMemo(
    () => radiusMetricOptions.find(d => d.id === radiusMetricId)!,
    [radiusMetricId, radiusMetricOptions]
  )

  return (
    <Card className="divide-y divide-gray-500/20 p-0">
      <div className="flex flex-wrap items-center gap-2 p-2">
        <div className="font-bold">Monitored URL Structure</div>
        <Tabs className="!bg-gray-500/10 !shadow-none">
          {fillMetricOptions.map(({ id, label }) => (
            <Tab
              key={id}
              className={twMerge(id === fillMetricId && 'active')}
              onClick={() => {
                setFillMetricId(id)
              }}
            >
              {label}
            </Tab>
          ))}
        </Tabs>
        <Select
          value={radiusMetricId}
          options={React.useMemo(
            () =>
              radiusMetricOptions.map(d => ({ value: d.id, label: d.label })),
            [radiusMetricOptions]
          )}
          onChange={d => setRadiusMetricId(d)}
          inlineLabel="Radius"
        />
      </div>
      <div className="aspect-[2/1]">
        <ForceDirectedGraph
          key={workspaceId + projectId}
          root={root}
          label={d =>
            d === root.data ? ['Root', d.url?.url] : ['URL', d.url?.url]
          }
          className="h-full w-full"
          nodeRadius={
            radiusMetric.accessor
              ? d => [radiusMetric.label, radiusMetric.accessor(d)]
              : undefined
          }
          nodeFill={d => [fillMetric.label, fillMetric.accessor(d)]}
          colorScaleType={fillMetric.scale}
          colorScaleDomain={fillMetric.domain}
          colorScaleRange={fillMetric.range}
          onDatumClick={d => {
            navigate({
              to: `/monitored-urls/${d.id}`,
            })
          }}
        />
      </div>
    </Card>
  )
}

export function MonitoredUrlsForceTree({
  workspaceId,
  projectId,
}: {
  workspaceId: string
  projectId: string
}) {
  const projectQuery = useProject({
    workspaceId,
    id: projectId,
  })

  const monitoredUrlsQuery = useMonitoredUrlsQuery({
    workspaceId,
    projectId,
    rootOnly: true,
    view: MonitoredUrlViewPb.SEARCH,
    viewOptions: new MonitoredUrlViewOptionsPb({
      ancestorDepth: 0,
      descendentDepth: 1,
    }),
    // filters: {
    //   search: 'blog',
    // },
  })

  const children = monitoredUrlsQuery.data?.monitoredUrls

  const parentMonitoredUrl: MonitoredUrlPb | undefined = React.useMemo(() => {
    return undefined as MonitoredUrlPb | undefined
  }, [])

  const root = React.useMemo(
    () =>
      d3.hierarchy(
        children?.length === 1
          ? children[0]!
          : parentMonitoredUrl
          ? {
              ...parentMonitoredUrl,
              children,
            }
          : ({
              url: {
                url: projectQuery.data?.name ?? 'Root',
              },
              children,
            } as MonitoredUrlPb)
      ),
    [children, parentMonitoredUrl, projectQuery.data?.name]
  )

  const navigate = useNavigate()

  const fillMetricOptions = React.useMemo(
    () =>
      [
        {
          id: 'priority',
          label: 'Priority',
          accessor: d => formatPriority(d.priority),
          scale: 'ordinal',
          domain: priorityOptions.map(d => d.label),
          range: priorityOptions.map(d => d.color),
        },
        {
          id: 'customerJourneyStage',
          label: 'Customer Journey',
          accessor: d => formatCustomerJourneyStage(d.customerJourneyStage),
        },
        {
          id: 'primarySearchIntent',
          label: 'Primary Search Intent',
          accessor: d => formatSearchIntent(d.primarySearchIntent),
        },
      ] satisfies {
        id: string
        label: string
        accessor: (d: MonitoredUrlPb) => number | string | undefined
        scale?: 'ordinal'
        domain?: any[]
        range?: any[]
      }[],
    []
  )

  const radiusMetricOptions = React.useMemo(
    () =>
      [
        {
          id: '',
          label: 'None',
          accessor: undefined,
          // accessor: d => d.children.length,
          // scale: 'ordinal',
          // domain: priorityOptions.map(d => d.label),
          // range: priorityOptions.map(d => d.color),
        },
        {
          id: 'children',
          label: 'Child URL Count',
          accessor: d => d.children.length,
          // scale: 'ordinal',
          // domain: priorityOptions.map(d => d.label),
          // range: priorityOptions.map(d => d.color),
        },
        {
          id: 'random',
          label: 'Random! 😜',
          accessor: d =>
            deterministicNumber(d.url?.url ?? '') *
            deterministicNumber(d.url?.url ?? ''),
          // scale: 'ordinal',
          // domain: priorityOptions.map(d => d.label),
          // range: priorityOptions.map(d => d.color),
        },
        // {
        //   id: 'customerJourneyStage',
        //   label: 'Customer Journey',
        //   accessor: d => formatCustomerJourneyStage(d.customerJourneyStage),
        // },
        // {
        //   id: 'primarySearchIntent',
        //   label: 'Primary Search Intent',
        //   accessor: d => formatSearchIntent(d.primarySearchIntent),
        // },
      ] satisfies {
        id: string
        label: string
        accessor:
          | ((d: MonitoredUrlPb) => number | string | undefined)
          | undefined
        scale?: 'ordinal'
        domain?: any[]
        range?: any[]
      }[],
    []
  )

  const [fillMetricId, setFillMetricId] =
    React.useState<(typeof fillMetricOptions)[number]['id']>('priority')

  const [radiusMetricId, setRadiusMetricId] =
    React.useState<(typeof radiusMetricOptions)[number]['id']>('')

  const fillMetric = React.useMemo(
    () => fillMetricOptions.find(d => d.id === fillMetricId)!,
    [fillMetricId, fillMetricOptions]
  )

  const radiusMetric = React.useMemo(
    () => radiusMetricOptions.find(d => d.id === radiusMetricId)!,
    [radiusMetricId, radiusMetricOptions]
  )

  return (
    <Card className="divide-y divide-gray-500/20 p-0">
      <div className="flex flex-wrap items-center gap-2 p-2">
        <div className="font-bold">Monitored URL Structure</div>
        <Tabs className="!bg-gray-500/10 !shadow-none">
          {fillMetricOptions.map(({ id, label }) => (
            <Tab
              key={id}
              className={twMerge(id === fillMetricId && 'active')}
              onClick={() => {
                setFillMetricId(id)
              }}
            >
              {label}
            </Tab>
          ))}
        </Tabs>
        <Select
          value={radiusMetricId}
          options={React.useMemo(
            () =>
              radiusMetricOptions.map(d => ({ value: d.id, label: d.label })),
            [radiusMetricOptions]
          )}
          onChange={d => setRadiusMetricId(d)}
          inlineLabel="Radius"
        />
      </div>
      <div className="aspect-[2/1]">
        <ForceDirectedGraph
          key={workspaceId + projectId}
          root={root}
          label={d =>
            d === root.data ? ['Root', d.url?.url] : ['URL', d.url?.url]
          }
          className="h-full w-full"
          nodeRadius={
            radiusMetric.accessor
              ? d => [radiusMetric.label, radiusMetric.accessor(d)]
              : undefined
          }
          nodeFill={d => [fillMetric.label, fillMetric.accessor(d)]}
          colorScaleType={fillMetric.scale}
          colorScaleDomain={fillMetric.domain}
          colorScaleRange={fillMetric.range}
          onDatumClick={d => {
            navigate({
              to: `/monitored-urls/${d.id}`,
            })
          }}
        />
      </div>
    </Card>
  )
}
// function getLongestMeasuredText(arr: string[]) {
//   const svg = d3.select('body').append('svg')
//   // let longestText = ''
//   let longestLength = 0
//   for (const text of arr) {
//     const textNode = svg.append('text').text(text)
//     const length = textNode.node()?.getComputedTextLength() ?? 0
//     if (length > longestLength) {
//       console.log(text, length)
//       longestLength = length
//     }
//     textNode.remove()
//   }
//   svg.remove()
//   return longestLength
// }
function deterministicNumber(input: string): number {
  let hash = 0
  for (let i = 0; i < input.length; i++) {
    const char = input.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash = hash & hash
  }
  return (hash & 2147483647) / 2147483647
}
