import moment from 'moment'
import { useQuery } from 'react-query'
import { useActiveWorkspaceId } from '../hooks/workspaces'
import { queryKeyUsage } from '../utils/Constants'
import { dateToDatePb } from '../utils/protoDate'
import { TimeRange } from './useTimeRanges'
import { UsageClient, UsageViewPb } from '../utils/proto'

export function useUsage(options: { view: UsageViewPb; timeRange: TimeRange }) {
  const workspaceId = useActiveWorkspaceId()
  const end = dateToDatePb(moment(options.timeRange?.end * 1000).toDate())
  const start = dateToDatePb(moment(options.timeRange?.start * 1000).toDate())

  return useQuery(
    [queryKeyUsage, workspaceId, start, end],
    () =>
      UsageClient.getUsage({
        // @ts-expect-error  // Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
        workspaceId: BigInt(workspaceId),
        view: options.view,
        start,
        end,
      }),
    {
      enabled: !!workspaceId,
      staleTime: 6 * 60 * 60 * 1000,
      refetchOnWindowFocus: false,
    }
  )
}
