import * as React from 'react'
import { functionalUpdate } from '../utils'
import useZustand from './useZustand'

export default function useAuth() {
  const [state, setState] = useZustand(d => d.auth)

  return [
    state,
    React.useCallback(
      (updater: (old: typeof state) => typeof state) => {
        setState(old => ({
          ...old,
          auth: functionalUpdate(updater, old.auth),
        }))
      },
      [setState]
    ),
  ] as const
}
