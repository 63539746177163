import { Route } from '@tanstack/react-router'
import * as React from 'react'
import { LocationGenerics } from '../../LocationGenerics'
import Card from '../../components/Card'
import Container from '../../components/Container'
import Link from '../../components/Link'

//

export type Dashboard = {
  id: string
  name: string
  icon: React.ReactNode
  comingSoon?: string
  status?: 'new' | 'alpha' | 'beta' | 'comingSoon'
  fullName?: string
  route?: Route
  getSearch?: (search: LocationGenerics['Search']) => LocationGenerics['Search']
}

export function NoDashboardFound() {
  return (
    <Container>
      <Card>
        Dashboard does not exist.{' '}
        <Link to=".." className="font-bold">
          Go back to Projects.
        </Link>
      </Card>
    </Container>
  )
}
